import { ReducerFunctions } from '../../utils';
import { APPROVE_PAYMENT, CREATE_PAYMENT_REQUEST, DELETE_PAYMENT, EDIT_PAYMENT, GET_PAYMENT_DETAILS_BY_ID, Payments, RECALL_PAYMENT, REJECT_PAYMENT, SUBMIT_PAYMENT, UPDATE_PAYMENT } from './payments';

export const payments_reducer = ReducerFunctions(Payments, {});
export const create_payment_reducer = ReducerFunctions(CREATE_PAYMENT_REQUEST, {});
export const get_payment_reducer = ReducerFunctions(GET_PAYMENT_DETAILS_BY_ID, {});
export const submit_payment_reducer = ReducerFunctions(SUBMIT_PAYMENT, {});
export const recall_payment_reducer = ReducerFunctions(RECALL_PAYMENT, {});
export const approve_payment_reducer = ReducerFunctions(APPROVE_PAYMENT, {});
export const reject_payment_reducer = ReducerFunctions(REJECT_PAYMENT, {});
export const edit_payment_reducer = ReducerFunctions(EDIT_PAYMENT, {});
export const update_payment_reducer = ReducerFunctions(UPDATE_PAYMENT, {});
export const delete_payment_reducer = ReducerFunctions(DELETE_PAYMENT, {});