import { Button, Modal, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useTranslation } from "react-i18next";
const Text = Typography.Text;

const DeleteConfirm = ({
  open,
  loading,
  onConfirm,
  onCancel,
  name,
  text,
  isReject,
  headerTitle,
}) => {
  const [reasonValue, setReasonValue] = React.useState("");
  const { t } = useTranslation();
  return (
    <Modal open={open} onCancel={onCancel}>
      {isReject ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onConfirm(reasonValue);
            }}
          >
            <Text>{headerTitle ? headerTitle : t("rejection_reason")}</Text>
            <br />
            <TextArea
              rows={4}
              placeholder="Enter Reason"
              className="reasonTextArea"
              onChange={(e) => setReasonValue(e.target.value)}
              required={true}
            />
            <br />
            <Button
              key="1"
              className="formButton mt1"
              htmlType="submit"
              disabled={loading}
            >
              {t("confirm")}
            </Button>
            <Button
              key="2"
              htmlType="button"
              className="cancelButton ml1 mt1"
              onClick={() => onCancel()}
            >
              {t("cancel")}
            </Button>
          </form>
        </>
      ) : (
        <>
          <Typography.Text>
            Are you sure you want to {text ?? "delete"} {name} ?
          </Typography.Text>
          <br />
          <Button
            key="1"
            className="formButton mt1"
            disabled={loading}
            onClick={onConfirm}
          >
            Confirm
          </Button>
          <Button
            key="2"
            htmlType="button"
            className="cancelButton ml1 mt1"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </>
      )}
    </Modal>
  );
};

export default DeleteConfirm;
