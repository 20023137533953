import { Button, Card, Col, Row } from "antd";
import React from "react";
import { amountFormat } from "../../utils";
import plus_circle from "../../assets/images/plus_circle.png";
import right_arr from "../../assets/images/right_arrow.png";
const AccountCard = ({ data, onClick }) => {
  return (
    <div className="send-payment card" style={{ padding: "20px" }}>
      <Row gutter={24}>
        {/* Account Info Card */}
        {data?.map((ele) => (
          <Col span={7}>
            <Card
              bordered
              style={{
                borderRadius: "8px",
              }}
            >
              <div className="item">
                <span className="label">Available</span>
                <span className="value font-big">
                  $ {amountFormat(ele?.balances?.[0]?.availableBalanceAmount)}
                </span>
                <img
                  src={right_arr}
                  width={10}
                  className="right"
                  onClick={() => onClick(ele)}
                />
              </div>
              <div className="item">
                <span className="label">Account</span>
                <span className="value">{ele.accountName}</span>
              </div>
              <div className="item">
                <span className="label">Account Number</span>
                <span className="value">
                  {ele.accountNumbers?.[0]?.accountNumber?.value}
                </span>
              </div>
              <div className="item">
                <span className="label">Status</span>
                <span
                  className={`value status status_${ele?.statusCode?.toLowerCase()}`}
                >
                  {ele?.statusCode}
                </span>
              </div>
            </Card>
          </Col>
        ))}
        {/* Add Account Card */}
        <Col span={7}>
          <Card
            bordered
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              minHeight: "20rem",
            }}
          >
            <Button
              type="link"
              icon={<img src={plus_circle} width={17} />}
              size="large"
            >
              Add account
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AccountCard;
