import { Table } from "antd";
import React, { useState } from "react";

const ReportTable = ({
  columns,
  data,
  scroll,
  total_records,
  payload,
  paginationCall,
  filterObj,
}) => {
  const [pageSize, setPageSize] = useState(10);
  return (
    <div className="report-table report-table-width">
      <Table
        columns={columns}
        dataSource={data}
        scroll={scroll}
        pagination={{
          total: total_records || 0,
          showSizeChanger: false,
          onChange: (page_number, per_page) => {
            setPageSize(per_page);
            paginationCall({params : {
              page_number: page_number,
              per_page,
              ...payload,
            }, filters:{...filterObj}});
          },
          pageSize: pageSize,
        }}
      />
    </div>
  );
};

export default ReportTable;
