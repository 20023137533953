import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { amountFormat } from "../../utils";

export const columns = [
  {
    lable: "Item & Description",
    key: "item_name",
    width: 25,
  },
  {
    lable: "Unit",
    key: "units",
    sub_key: "unit_name",
    width: 10,
  },
  {
    lable: "Qty",
    key: "quantity",
    width: 15,
  },
  {
    lable: "Unit Price",
    key: "unit_price",
    width: 25,
  },
  {
    lable: "Discount",
    key: "discount_percentage",
    width: 15,
    suffix: "%",
  },
  {
    lable: "Total Amount",
    key: "total_amount",
    width: 30,
  },
];
export const columnsOrder = [
  {
    lable: "Item & Description",
    key: "Item Name",
    width: 30,
  },
  {
    lable: "Qty",
    key: "Quantity",
    width: 15,
  },
  {
    lable: "Unit Price",
    key: "Unit Price",
    width: 30,
  },
  {
    lable: "Total Amount",
    key: "Amount",
    width: 30,
  },
];

const invPDF2 = (props) => {
  const {
    billingAddressCity,
    billingAddressState,
    billingAddresszip,
    billingCountry,
    billPhone,
    billAddress,
    shippingAddressCity,
    shippingAddressCountry,
    shippingAddressPhone,
    shippingAddressState,
    shippingAddressZip,
    shipAddress,
    companyAddr,
    companyCity,
    companyState,
    companyCountry,
    companyPin,
    deliveryCharges,
  } = props;

  const companyAddress = () => {
    const addressComponents = [
      companyAddr,
      companyCity,
      companyState,
      companyCountry,
      companyPin,
    ];

    // Filter out undefined or empty values
    const filteredCompanyComponents = addressComponents.filter(Boolean);
    const address = filteredCompanyComponents.join(", ");

    return address;
  };

  const compAddr = companyAddress();

  // Function to form the address
  const formatAddress = () => {
    const addressComponents = [
      billAddress,
      billingAddressCity,
      billingAddressState,
      billingAddresszip,
      billPhone,
      billingCountry,
    ];

    // Filter out undefined or empty values
    const filteredAddressComponents = addressComponents.filter(Boolean);
    const address = filteredAddressComponents.join(", ");

    return address;
  };

  const formAddress = formatAddress();

  const shipToAddress = () => {
    const addressComponents = [
      shipAddress,
      shippingAddressCity,
      shippingAddressState,
      shippingAddressCountry,
      shippingAddressPhone,
      shippingAddressZip,
    ];

    // Filter out undefined or empty values
    const filteredAddressComponents = addressComponents.filter(Boolean);
    const address = filteredAddressComponents.join(", ");

    return address;
  };

  const shipTo = shipToAddress();

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#ffffff",
      padding: 20,
    },
    borderedContainer: {
      border: "1pt solid #000000",
      flex: 1,
    },
    Menu: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: "15px",
      width: "98%",
    },
    display: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 20,
      marginTop: 10,
      width: "40%",
    },
    displayTwo: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 20,
      width: "80%",
    },
    container3: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 20,
      width: "80%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    container2: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center", // Center the content vertically
      justifyContent: "space-between", // Add space around the vertical line
    },
    content: {
      flex: 1, // Fill available space
      paddingHorizontal: 10, // Add some horizontal spacing
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
      color: "black",
      fontWeight: "bold",
    },
    textOne: {
      fontSize: 12,
      marginBottom: 5,
      color: "black",
      fontWeight: "bold",
      marginTop: 5,
    },
    textTwo: {
      fontSize: 12,
      marginBottom: 5,
      color: "black",
    },
    Menu1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 60,
    },
    table: {
      display: "table",
      width: "auto",
      marginTop: 30,
    },
    tableHeadRow: {
      backgroundColor: "#ccc",
      flexDirection: "row",
    },
    tableRow: {
      flexDirection: "row",
    },
    mainTableCellOne: {
      borderStyle: "solid",
      borderWidth: 1,
      padding: 5,
      backgroundColor: "grey",
      color: "white",
      fontSize: "12px",
    },
    dataTableCellOne: {
      borderBottomStyle: "solid",
      padding: 5,
      // color: "grey",
      fontSize: "12px",
      borderBottom: "1px",
    },
    image: {
      width: "50px",
      height: "50px",
      marginTop: "2px",
      marginRight: "10px",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      marginTop: "5px",
    },
  });
  const loop =
    props?.useDummyData === true ? props?.obj?.lineItems : props?.lineItems;
  const currency =
    props?.useDummyData === true ? props?.obj?.currency : props?.currency;
  const taxRates =
    props?.useDummyData === true ? props?.obj?.taxRates : props?.taxRates;

  return (
    <div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          <Page size="A4" style={styles.page} wrap={false}>
            <View style={styles.borderedContainer}>
              <View style={styles.Menu}>
                <View style={styles.flex}>
                  {props.image && (
                    <View>
                      <Image src={props.image} style={styles.image} />
                    </View>
                  )}
                  <View>
                    <Text
                      style={{
                        marginTop: "5px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        maxWidth: "410px",
                      }}
                    >
                      {props?.useDummyData === true
                        ? props?.obj?.companyName
                        : props?.orgName}
                    </Text>
                    <Text
                      style={{
                        marginTop: "5px",
                        maxWidth: "150px",
                        fontSize: "11px",
                      }}
                    >
                      {props?.useDummyData === true
                        ? props?.obj?.address
                        : compAddr}
                    </Text>
                  </View>
                </View>
                <View style={styles.header}>
                  {/* <Text
                    style={{
                      fontSize: "30px",
                      color: "grey",
                      marginTop: "20px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.title
                      : props?.title}
                  </Text> */}
                </View>
              </View>
              <View>
                <Text
                  style={{
                    borderBottom: "1px solid black",
                    paddingTop: "30px",
                  }}
                ></Text>
              </View>
              <View style={{ display: "flex" }}>
                <View style={styles.display}>
                  <View style={styles.container}>
                    <View
                      style={{
                        fontSize: 10,
                        marginTop: "10px",
                        width: 200,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          minWidth: "150px",
                          fontSize: 12,
                          marginBottom: 5,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        <Text style={styles}>
                          {props?.useDummyData === true
                            ? props?.obj?.numTitle
                            : props?.numTitle}
                        </Text>
                      </Text>
                      <Text>
                        {props?.invoiceNo || props?.useDummyData === true ? (
                          <Text style={styles.text}>
                            {props?.useDummyData === true
                              ? props?.obj?.invoiceNo
                              : props?.invoiceNo}
                          </Text>
                        ) : null}
                      </Text>
                    </View>
                    <View
                      style={{
                        fontSize: 10,
                        marginTop: "2px",
                        width: 200,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          minWidth: "150px",
                          fontSize: 12,
                          marginBottom: 5,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        <Text style={styles.text}>
                          {props?.useDummyData === true
                            ? props?.obj?.creditTitle
                            : props?.creditTitle}
                        </Text>
                      </Text>
                      <Text>
                        {props?.invoiceDate || props?.useDummyData === true ? (
                          <Text style={styles.text}>
                            {props?.useDummyData === true
                              ? props?.obj?.CreditNo
                              : props?.creditNo}
                          </Text>
                        ) : null}
                      </Text>
                    </View>
                    <View
                      style={{
                        fontSize: 10,
                        marginTop: "2px",
                        width: 200,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          minWidth: "150px",
                          fontSize: 12,
                          marginBottom: 5,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        <Text style={styles.text}>
                          {props?.useDummyData === true
                            ? props?.obj?.dateTitle
                            : props?.dateTitle}
                        </Text>
                      </Text>
                      <Text>
                        {props?.invoiceDate || props?.useDummyData === true ? (
                          <Text style={styles.text}>
                            {props?.useDummyData === true
                              ? moment(props?.obj?.invoiceDate).format(
                                  "MMMM DD, YY"
                                )
                              : moment(props?.invoiceDate).format(
                                  "MMMM DD, YY"
                                )}
                          </Text>
                        ) : null}
                      </Text>
                    </View>

                    <View
                      style={{
                        fontSize: 10,
                        marginTop: "5px",
                        width: 200,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {props?.type === "PURCHASE_ORDER" ||
                      props?.type === "SALES_ORDER" ||
                      props?.type === "INVOICE" ||
                      props?.type === "BILL" ||
                      props?.obj?.termsFlag === true ? (
                        <Text
                          style={{
                            minWidth: "150px",
                            fontSize: 12,
                            marginBottom: 5,
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          Payment Terms
                        </Text>
                      ) : null}
                      <Text>
                        {props?.type === "PURCHASE_ORDER" ||
                        props?.type === "SALES_ORDER" ||
                        props?.type === "INVOICE" ||
                        props?.type === "BILL" ||
                        props?.obj?.termsFlag === true ? (
                          <Text style={styles.text}>
                            {props?.useDummyData === true
                              ? props?.obj?.terms
                              : props?.terms}
                          </Text>
                        ) : null}
                      </Text>
                    </View>
                    <View
                      style={{
                        fontSize: 10,
                        marginTop: "2px",
                        width: 200,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          minWidth: "150px",
                          fontSize: 12,
                          marginBottom: 5,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {props?.obj?.dueTitle
                          ? props?.obj?.dueTitle
                          : props?.dueTitle}
                      </Text>
                      <Text>
                        {props?.obj?.poDue || props?.poDue ? (
                          <Text style={styles.text}>
                            {props?.useDummyData == true
                              ? moment(props?.obj?.poDue).format("MMMM DD, YY")
                              : moment(props?.poDue).format("MMMM DD, YY")}
                          </Text>
                        ) : null}
                      </Text>
                    </View>
                  </View>
                  {/* <View style={styles.container}>

                    {props?.invoiceDate || props?.useDummyData === true ? <Text style={styles.text}>{props?.useDummyData === true ? moment(props?.obj?.invoiceDate).format("MMMM DD, YY") : moment(props?.invoiceDate).format("MMMM DD, YY")}</Text> : null}
                    {props?.terms || props?.useDummyData === true ? <Text style={styles.text}>{props?.useDummyData === true ? props?.obj?.terms : props?.terms}</Text> : null}
                    {props?.poDue || props?.useDummyData === true ? <Text style={styles.text}>{props?.useDummyData === true ? moment(props?.obj?.poDue).format("DD-MM-YYYY") : moment(props?.poDue).format("DD-MM-YYYY")}</Text> : null}
                  </View> */}
                </View>
                <View>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      paddingTop: "10px",
                    }}
                  ></Text>
                </View>
              </View>
              <View style={styles.displayTwo}>
                <View style={{ width: "50%" }}>
                  <Text style={{ fontSize: "12px", paddingTop: "10px" }}>
                    Bill To
                  </Text>
                </View>
                <View style={{ width: "50%" }}>
                  <Text
                    style={{
                      fontSize: "12px",
                      paddingTop: "10px",
                      maxWidth: "180px",
                    }}
                  >
                    Ship To
                  </Text>
                </View>
              </View>

              <View>
                <Text
                  style={{ borderBottom: "1px solid black", paddingTop: "6px" }}
                ></Text>
              </View>
              <View style={styles.container3}>
                <View style={{ width: "50%" }}>
                  <Text style={{ fontSize: "12px", paddingTop: "5px" }}>
                    {props?.useDummyData === true
                      ? props?.obj?.beniName
                      : props?.benefiName}
                  </Text>
                  <Text style={{ fontSize: "12px", paddingTop: "5px" }}>
                    {props?.useDummyData === true
                      ? props?.obj?.beniEmail
                      : props?.benefiEmail}
                  </Text>
                  <Text
                    style={{
                      fontSize: "12px",
                      paddingTop: "8px",
                      maxWidth: "180px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj.address
                      : formAddress}
                  </Text>
                  <Text
                    style={{
                      fontSize: "12px",
                      paddingTop: "8px",
                      maxWidth: "180px",
                    }}
                  >
                    TAX ID :
                    {props?.useDummyData === true
                      ? props?.obj.gst
                      : props?.benficiaryGST}
                  </Text>
                </View>
                <View style={{ width: "50%" }}>
                  <Text
                    style={{
                      fontSize: "12px",
                      paddingTop: "10px",
                      maxWidth: "150px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.beniName
                      : props?.benefiName}
                  </Text>
                  <Text
                    style={{
                      fontSize: "12px",
                      paddingTop: "7px",
                      maxWidth: "150px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.beniEmail
                      : props?.benefiEmail}
                  </Text>
                  <Text
                    style={{
                      fontSize: "12px",
                      paddingTop: "8px",
                      maxWidth: "180px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.address
                      : shipTo}
                  </Text>
                </View>
              </View>
              <View style={styles.table}>
                <View style={styles.tableHeadRow}>
                  <View style={{ ...styles.mainTableCellOne, width: "10%" }}>
                    <Text>#</Text>
                  </View>
                  {columns.map((ele) => {
                    return (
                      <View
                        style={{
                          ...styles.mainTableCellOne,
                          width: ele.width + "%",
                        }}
                      >
                        <Text>{ele.lable}</Text>
                      </View>
                    );
                  })}
                </View>
                {loop?.map((ele, index) => (
                  <View style={styles.tableRow} key={index}>
                    <View style={{ ...styles.dataTableCellOne, width: "10%" }}>
                      <Text>{index + 1}</Text>
                    </View>
                    {columns.map((itm) => {
                      return (
                        <View
                          style={{
                            ...styles.dataTableCellOne,
                            width: itm.width + "%",
                          }}
                        >
                          {itm.key === "discount_percentage" && itm.suffix ? (
                            <Text>
                              {ele[itm.key]}
                              {itm.suffix}
                            </Text>
                          ) : itm.key === "unit_price" ||
                            itm.key === "total_amount" ? (
                            <Text>
                              {currency} {""}
                              {amountFormat(ele[itm.key])}
                            </Text>
                          ) : (
                            <Text>
                              {itm.key === "units"
                                ? ele?.[itm.key]?.[itm.sub_key]
                                : ele[itm.key]}
                            </Text>
                          )}
                        </View>
                      );
                    })}
                  </View>
                ))}
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.dataTableCellOne, width: "80%" }}>
                  <Text style={{ color: "black", paddingLeft: "85%" }}>
                    Sub Total
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.dataTableCellOne,
                    width: "20%",
                    paddingLeft: "5%",
                  }}
                >
                  <Text>
                    {props?.subTotal
                      ? currency + " " + props?.subTotal
                      : currency + " " + 0}
                  </Text>
                </View>
              </View>
              <View style={{ display: "flex", flexDirection: "row-reverse" }}>
                <View
                  style={{
                    ...styles.display,
                    marginTop: 0,
                    borderColor: "black",
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderBottomWidth: 1,
                    borderLeftWidth: 1,
                    borderWidth: 1,
                    backgroundColor: "grey",
                  }}
                >
                  <View style={{ ...styles.container, marginRight: "12px" }}>
                    {Object.keys(taxRates).length && (
                      <Text style={styles.textOne}>Tax Rate</Text>
                    )}

                    <View>
                      {props?.type === "PURCHASE_ORDER" ||
                      props?.type === "BILL" ||
                      props?.obj?.tdsFlag === true ? (
                        <Text style={styles.text}>TDS Amount</Text>
                      ) : null}
                      <Text style={styles.textTwo}>Total Discount</Text>

                      {props?.type === "INVOICE" ? (
                        <Text style={styles.textTwo}>Delivery Charges</Text>
                      ) : null}

                      {props?.type === "INVOICE" ||
                      props?.type === "BILL" ||
                      props?.obj?.balanceFlag === true ? (
                        <Text style={styles.textTwo}>Balance Due</Text>
                      ) : null}
                      <Text style={styles.textTwo}>Total</Text>
                    </View>
                  </View>
                  <View style={styles.container}>
                    <View style={{ ...styles.taxRateContainer }}>
                      {Object.entries(taxRates).map(([taxRate, total]) => (
                        <Text
                          style={styles.textOne}
                        >{`${currency} ${amountFormat(
                          total
                        )}(${taxRate}%)`}</Text>
                      ))}
                      {props?.type === "PURCHASE_ORDER" ||
                      props?.type === "BILL" ||
                      props?.obj?.tdsFlag === true ? (
                        <Text style={styles.text}>
                          {`${props?.currency}`}{" "}
                          {props?.useDummyData === true
                            ? props?.obj?.tdsAmt
                            : props?.TDSAmt
                            ? props?.TDSAmt
                            : 0}
                        </Text>
                      ) : null}
                      <Text style={styles.text}>
                        {props.discount
                          ? currency + " " + props.discount
                          : currency + " " + 0}
                      </Text>
                      {props?.type === "INVOICE" ? (
                        <Text style={styles.text}>
                          {`${props?.currency}`} {deliveryCharges}{" "}
                        </Text>
                      ) : null}
                      {props?.type === "INVOICE" ||
                      props?.type === "BILL" ||
                      props?.obj?.balanceFlag ? (
                        <Text style={styles.text}>
                          {`${props?.currency}`}{" "}
                          {props.due_balance ? props.due_balance : 0}
                        </Text>
                      ) : null}
                      <Text style={styles.text}>
                        {props.totalAmount
                          ? currency + " " + props.totalAmount
                          : currency + " " + 0}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View>
                <Text style={{ fontSize: "14px", paddingHorizontal: 10 }}>
                  Thanks for your business
                </Text>
              </View>
              <View style={{ marginBottom: "40%" }}>
                <Text
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    paddingHorizontal: 10,
                  }}
                >
                  Terms & Conditions
                </Text>
                <Text
                  style={{
                    fontSize: "12px",
                    marginTop: "5px",
                    paddingHorizontal: 10,
                    maxWidth: "250px",
                  }}
                >
                  Full payment due upon receipt on this invoice.Late Payments
                  may incur additonal charges or intrests as per applicable
                  laws.
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default invPDF2;
