import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

const useHandleResponse = (actionType, successMessage, failureMessage, response, successCall, noReset) => {
  const dispatch = useDispatch();
  console.log({ response });
  useEffect(() => {
    if (response?.success === true) {
      message.success(<span className="messageText">{successMessage}</span>);
      if (successCall) {
        successCall()
      }
      if (!noReset)
        dispatch({
          type: actionType.RESET,
        });
    } else if (response?.success === false) {
      message.error(<span className="messageText">{failureMessage}</span>);
      dispatch({
        type: actionType.RESET,
      });
    }
    // eslint-disable-next-line
  }, [response]);
};

export default useHandleResponse;