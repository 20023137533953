import TableWrap from "../Common/Table/Table";
import { message, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UserAdvances, userAdvances } from "../../actions/advances/advances";
import { userReportLinkAdv } from "../../actions/reports/reports";
import { objectToFormData } from "../../utils";
import React from "react";
// import moment from "moment";
import { useTranslation } from "react-i18next";

import "./report.css";
import BaseCurrency from "../Common/BaseCurrency";
import moment from "moment";

message.config({
  maxCount: 3,
});

const LinkAdvances = (props) => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = React.useState([]);
  const advancesResponse = useSelector(
    (state) => state.advances.userAdvances?.data || {}
  );
  const userReportLinkAdvResponse = useSelector(
    (state) => state.reports.userReportLinkAdv || {}
  );

  const deleteExpResponse = useSelector(
    (state) => state.reports.deleteExpense || {}
  );

  const onMountCall = () => {
    dispatch(
      userAdvances({
        added_to_report: false,
        filters: { status: ["approved"] },
        params: { report_link_status: true },
      })
    );
  };
  const { data: advances = [], total_count: total_records = 0 } =
    advancesResponse;
  // React.useEffect(() => {
  //   onMountCall()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  React.useEffect(() => {
    if (props.from === "report") {
      onMountCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkAdvResponse, deleteExpResponse]);

  // React.useEffect(()=>{
  //   onMountCall()
  // },[])

  // React.useEffect(()=>{

  // },[])

  const { t } = useTranslation();

  const columns = [
    {
      title: t("advance_id"),
      dataIndex: "advance_number",
      key: "advance_number",
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("date"),
      dataIndex: "advance_date",
      key: "advance_date",
      render: (text) => <div>{moment(text).format("DD MMM, YYYY hh:mm A")}</div>,
    },
    {
      title: t("Amount"),
      dataIndex: "amount_cents",
      key: "amount_cents",
      render: (text) => (
        <div>
          <BaseCurrency /> {text}
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedData,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedData(selectedRowKeys);
    },
  };

  const handleClearList = () => {
    setSelectedData([]);
  };

  const handleAddExp = () => {
    const payload = {};
    payload.advance_ids = selectedData;
    payload.report_id = props.report_id;
    dispatch(userReportLinkAdv({ ...payload }));
    setSelectedData([]);
  };

  return (
    <div className="advances-table">
      {selectedData.length ? (
        <>
          <Button
            type="default"
            ghost
            className="approve-add-btn mb1"
            onClick={() => handleClearList()}
          >
            {t("clear_selection")}
          </Button>
          <Button
            onClick={() => handleAddExp()}
            className="floatRight ml1 primaryButton"
          >
            {t("add")} {selectedData.length > 1 ? selectedData.length : ""}{" "}
            {t("advances")}
          </Button>
        </>
      ) : null}
      <TableWrap
        rowKey="id"
        columns={columns}
        data={advances}
        className="tableStyled"
        pagination={{
          total: total_records,
          onChange: (pagee) => {
            dispatch(
              userAdvances({
                added_to_report: false,
                params: { page_number: pagee },
              })
            );
          },
        }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
    </div>
  );
};

export default LinkAdvances;
