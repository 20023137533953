import {
  Col,
  Menu,
  Row,
  Modal,
  Typography,
  message,
  Button,
  Form,
  Input,
} from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  FetchAllBillingDetails,
  fetchAllBillingDetails,
} from "../../../actions/bills";
import { headerTitles, SideMenu } from "../../../actions/masterdata/masterdata";
import { amountFormat } from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import TableComponent from "../../Common/TableComp/TableComp";
import TableHeader from "../../Sales/TableHeader";
import usePayAxios from "../../../config/useAxios";
import { apis } from "../../../config/APIs";
import { useTranslation } from "react-i18next";

const BillsApprovals = ({ isPO, isSales, isEstimates }) => {
  const { onCall: BulkBills } = usePayAxios({
    api: apis.bulkBillAPI,
    method: "post",
  });
  const { onCall: BulkRejects } = usePayAxios({
    api: apis.bulkRejectBillAPI,
    method: "post",
  });
  var alphanumeric = /^[ \t\r\n,.]*$/;
  const { Title, Text } = Typography;
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [reason, setReason] = useState("");
  const { t } = useTranslation();
  const billsResponse = useSelector(
    (state) => state.bills.fetchAllBillingDetails || {}
  );
  const totalRecords = useSelector(
    (state) => state.bills.fetchAllBillingDetails?.data?.total_count
  );
  const is_admin = useSelector(
    (state) => state?.auth?.localVariables?.is_admin
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(vendors());
    if (location?.state?.filter) {
      dispatch(
        fetchAllBillingDetails({
          view: "admin",
          module_type: isPO ? "PURCHASE_ORDER" : "BILL",
          page_number: page,
          filters: { invoice_status: [location?.state?.filter] },
        })
      );
      setFilters({
        filters: { invoice_status: [location?.state?.filter] },
      });
    } else {
      dispatch(
        fetchAllBillingDetails({
          view: "admin",
          module_type: isPO ? "PURCHASE_ORDER" : "BILL",
          page_number: page,
        })
      );
      setFilters({});
    }
    dispatch(
      headerTitles({
        title: "apprvolas",
        description: isPO ? "approve_purchase" : "approve_bills",
      })
    );
    let locationSplit = location?.pathname?.split("/");
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: "/" + locationSplit?.[2],
        showChildren: true,
        childrenMenu: "/approvals",
      },
    });
  }, [location.pathname]);

  const sortOptions = [
    {
      label: isPO ? t("purchase_date") : t("date"),
      filter: "billing_date",
    },
    // {
    //   label: isPO ? t('po_number') : t('no_bill'),
    //   filter: "billing_no"
    // },
    {
      label: t("vendor_name"),
      filter: "vendor_name",
    },
    {
      label: t("Amount"),
      filter: "total_payable_amount",
    },
    isPO
      ? {
        label: isPO ? t("delivery_date") : t("expected_delivery_date"),
        filter: "due_date",
      }
      : isSales
        ? {
          label: t("sales_order_date"),
          filter: "due_date",
        }
        : {
          label: t("due_date"),
          filter: "due_date",
        },
    // {
    //   label: "Import Purchase Orders",
    //   filter: "import_purchase_orders"
    // },
    // {
    //   label: "Export Purchase Orders",
    //   filter: "export_purchase_orders"
    // },
  ];

  const columns = [
    {
      title: isSales
        ? t("order_salee")
        : isEstimates
          ? t("estimate_hash")
          : isPO
            ? t("purchase_no")
            : t("bill_no"),
      dataIndex: "billing_no",
      key: "billing_no",
    },
    {
      title: isEstimates || isSales ? t("customer_name") : t("vendor_name"),
      dataIndex: "vendor_name",
      key: "vendor_name",
    },
    isPO
      ? {}
      : {
        title:
          isEstimates || isSales ? t("reference_hash") : t("purchase_order"),
        dataIndex: "ref_purchase_order_no",
        key: "ref_purchase_order_no",
      },
    {
      title: isSales
        ? t("sales_order_date")
        : isEstimates
          ? t("estimate_date")
          : isPO
            ? t("purchase_date")
            : t("date"),
      dataIndex: "billing_date",
      key: "billing_date",
      render: (text) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
    },
    {
      title: isSales
        ? t("expected_shipment_date")
        : isEstimates
          ? t("expiry_date")
          : isPO
            ? t("delivery_date")
            : t("due_date"),
      dataIndex: "due_date",
      key: "due_date",
      render: (text) => (
        <div className={moment().diff(text) <= 0 ? "" : "table-due-date"}>
          {moment(text).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: t("Amount"),
      dataIndex: "total_payable_amount",
      key: "total_payable_amount",
      render: (text, record) => (
        <div>
          <BaseCurrency symbol_code={record?.currency_info?.iso_code} />
          {amountFormat(text, record?.currency_info?.decimal_scale)}
        </div>
      ),
    },
    isPO || isEstimates || isSales
      ? {}
      : {
        title: t("balance_due"),
        dataIndex: "due_balance_amount",
        key: "due_balance_amount",
        render: (text, record) => (
          <div>
            <BaseCurrency symbol_code={record?.currency_info?.iso_code} />
            {amountFormat(text, record?.currency_info?.decimal_scale)}
          </div>
        ),
      },
    {
      title: t("status"),
      dataIndex: "role_status",
      key: "role_status",
      render: (text, record) => (
        <div
          className={`status status_${text === "approved" && record?.flow_status
            ? record?.flow_status
            : text
            }`}
        >
          {text === "approved" && record?.flow_status
            ? record?.flow_status?.replace?.(/_/g, " ")
            : text?.replace?.(/_/g, " ")}
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      // render: (_, record) => (
      //   <Space size="middle">
      //     <Button
      //       shape="default"
      //       icon={<WarningOutlined />}
      //       style={{ border: "none", color: "#E24C4B" }}
      //     />
      //     <Button
      //       shape="default"
      //       icon={<MessageOutlined />}
      //       style={{ border: "none", color: "#5B87F9" }}
      //     />
      //     <Button
      //       shape="default"
      //       icon={<MoreOutlined />}
      //       style={{ border: "none", color: "#5B87F9" }}
      //     />
      //   </Space>
      // ),
    },
  ];

  useMemo(() => {
    if (billsResponse?.success && !billsResponse?.data?.error) {
      setData(billsResponse.data.data);
    }
  }, [billsResponse]);

  const sort_opts = (
    <Menu onClick={() => { }}>
      {sortOptions.map((ele, idx) => (
        <Menu.Item key={idx}>{ele.label}</Menu.Item>
      ))}
    </Menu>
  );

  const handleSelectRow = (event, record) => {
    history.push(
      isSales
        ? "/sales/approve"
        : isEstimates
          ? "/sales/estimate/approve"
          : isPO
            ? `/purchases/approvepo`
            : `/purchases/approvebill`,
      {
        id: record.id,
        from: location?.pathname,
      }
    );
  };

  const handleSearch = (searchKey) => {
    dispatch(
      fetchAllBillingDetails({
        ...filters,
        view: "admin",
        search_key: searchKey,
        module_type: isEstimates
          ? "ESTIMATE"
          : isSales
            ? "SALES_ORDER"
            : isPO
              ? "PURCHASE_ORDER"
              : "BILL",
        page_number: 1,
      })
    );
    setPage(1);
    setFilters({
      ...filters,
      search_key: searchKey,
    });
  };

  const handleFilter = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }
    if (value === "all") {
      dispatch(
        fetchAllBillingDetails({
          view: "admin",
          module_type: isEstimates
            ? "ESTIMATE"
            : isSales
              ? "SALES_ORDER"
              : isPO
                ? "PURCHASE_ORDER"
                : "BILL",
          page_number: 1,
        })
      );
      setFilters({
        module_type: isEstimates
          ? "ESTIMATE"
          : isSales
            ? "SALES_ORDER"
            : isPO
              ? "PURCHASE_ORDER"
              : "BILL",
      });
    } else {
      dispatch({
        type: FetchAllBillingDetails.RESET,
      });
      dispatch(
        fetchAllBillingDetails({
          ...filters,
          view: "admin",
          filters: { invoice_status: [value] },
          isFilter: true,
          module_type: isEstimates
            ? "ESTIMATE"
            : isSales
              ? "SALES_ORDER"
              : isPO
                ? "PURCHASE_ORDER"
                : "BILL",
          page_number: 1,
        })
      );
      setFilters({
        ...filters,
        filters: { invoice_status: [value] },
      });
    }
    setPage(1);
  };

  const handleSort = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }
    if (value) {
      dispatch({
        type: FetchAllBillingDetails.RESET,
      });
      dispatch(
        fetchAllBillingDetails({
          view: "admin",
          ...filters,
          sort: {
            sort_key: value,
            sort_order: "1",
          },
          isFilter: true,
          module_type: isEstimates
            ? "ESTIMATE"
            : isSales
              ? "SALES_ORDER"
              : isPO
                ? "PURCHASE_ORDER"
                : "BILL",
          page_number: 1,
        })
      );
      setFilters({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
      });
    }
    setPage(1);
  };

  const filterOptions = isPO
    ? [
      { label: t("all"), filter: "all" },
      { label: t("pending_approval"), filter: "pending_approval" },
      { label: t("approved"), filter: "approved" },
      { label: t("rejected"), filter: "rejected" },
      { label: t("overDue"), filter: "overdue" },
      { label: t("decline"), filter: "declined" },
      { label: t("accept"), filter: "accepted" },
      // { label: t('partillay_paid'), filter: "partially_paid" },
    ]
    : [
      { label: t("all"), filter: "all" },
      { label: t("pending_approval"), filter: "pending_approval" },
      { label: t("approved"), filter: "approved" },
      { label: t("rejected"), filter: "rejected" },
      { label: t("overDue"), filter: "overdue" },
      // { label: t('partillay_paid'), filter: "partially_paid" },
    ];
  const renderBulkOption = (type) => {
    return (
      <>
        <Text>
          {t("want_bulk")} {type} ?
        </Text>
        <br />
        {type === "reject" ? (
          <Form.Item>
            <Input.TextArea
              placeholder="Enter reason"
              className="mt1"
              value={reason}
              onChange={(e) => {
                console.log(e.target.value);
                setReason(e.target.value);
              }}
              required
            />
          </Form.Item>
        ) : null}
        <Button
          disabled={
            type === "reject" && reason?.match(alphanumeric) ? true : false
          }
          key="1"
          className="formButton mt1"
          onClick={() => {
            if (type === "reject") {
              let invoiceDetailsArray = [];
              let invoiceDetails = selectedRows
                ?.map(
                  (ele) => ele.invoice_status === "pending_approval" && ele.id
                )
                .filter((ele) => invoiceDetailsArray.push({ id: ele }));
              if (isPO) {
                BulkRejects({
                  data: {
                    BillingDetailsArray: invoiceDetailsArray,
                    reject_reason: reason,
                  },
                  params: {
                    module_type: "PURCHASE_ORDER",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isPO
                          ? "Purchace Orders Rejected Successfully"
                          : "Bills Rejected Successfully"}
                      </span>
                    );
                    dispatch(
                      fetchAllBillingDetails({
                        view: "admin",
                        module_type: isPO ? "PURCHASE_ORDER" : "BILL",
                        page_number: page,
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                    setReason("");
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              } else {
                BulkRejects({
                  data: {
                    BillingDetailsArray: invoiceDetailsArray,
                    reject_reason: reason,
                  },
                  params: {
                    module_type: "BILL",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isPO
                          ? "Purchace Orders Rejected Successfully"
                          : "Bills Rejected Successfully"}
                      </span>
                    );
                    dispatch(
                      fetchAllBillingDetails({
                        view: "admin",
                        module_type: isPO ? "PURCHASE_ORDER" : "BILL",
                        page_number: page,
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                    setReason("");
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              }
            } else {
              let invoiceDetailsArray = [];
              let invoiceDetails = selectedRows
                ?.map(
                  (ele) => ele.invoice_status === "pending_approval" && ele.id
                )
                .filter((ele) => invoiceDetailsArray.push({ id: ele }));
              if (isPO) {
                BulkBills({
                  data: { BillingDetailsArray: invoiceDetailsArray },
                  params: {
                    module_type: "PURCHASE_ORDER",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isPO
                          ? "Purchace Orders Approved Successfully"
                          : "Bills Approved Successfully"}
                      </span>
                    );
                    dispatch(
                      fetchAllBillingDetails({
                        view: "admin",
                        module_type: isPO ? "PURCHASE_ORDER" : "BILL",
                        page_number: page,
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              } else {
                BulkBills({
                  data: { BillingDetailsArray: invoiceDetailsArray },
                  params: {
                    module_type: "BILL",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isPO
                          ? "Purchace Orders Approved Successfully"
                          : "Bills Approved Successfully"}
                      </span>
                    );
                    dispatch(
                      fetchAllBillingDetails({
                        view: "admin",
                        module_type: isPO ? "PURCHASE_ORDER" : "BILL",
                        page_number: page,
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              }
            }
          }}
        >
          {type}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => {
            setIsBulkModalVisible(false);
            setReason("");
          }}
        >
          {t("cancel")}
        </Button>
      </>
    );
  };

  const approveClick = () => {
    setType("approve");
    setIsBulkModalVisible(true);
  };
  const rejectClick = () => {
    setIsBulkModalVisible(true);
    setType("reject");
  };
  const onChangeCheckBox = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };
  console.log(filters?.filters?.invoice_status);
  return (
    <>
      {/* <NewBill
        open={openAddNew}
        onClose={() => {
          setOpenAddNew(false);
          dispatch(fetchAllBillingDetails());
        }}
      /> */}

      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              noDD={true}
              defaultFilterValue={filters?.filters?.invoice_status?.[0]}
              filterMenu={filterOptions}
              handleFilter={handleFilter}
              setOpenAddNew={setOpenAddNew}
              noAddNewBtn={true}
              sortOptions={sortOptions}
              handleSort={handleSort}
              onSearch={handleSearch}
              approveClick={approveClick}
              rejectClick={rejectClick}
              isBulkAction={selectedRows?.length > 0}
            />
            <Modal
              title={<Title level={4}>{t("bulk_action")}</Title>}
              visible={isBulkModalVisible}
              onCancel={() => {
                setIsBulkModalVisible(false);
                setReason("");
              }}
              footer={[]}
              className="center-alinged-modal"
            >
              {renderBulkOption(type)}
            </Modal>

            <div
              className={billsResponse?.loading ? "shimmer" : ""}
              style={{ paddingTop: "22px" }}
            >
              <TableComponent
                columns={columns}
                loading={billsResponse?.loading}
                data={Array.isArray(data) ? data : []}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                hasCheckbox={true}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleRowClick={handleSelectRow}
                rowSelection={{
                  type: "checkbox",
                  // ...rowSelection,
                  onChange: onChangeCheckBox,
                  getCheckboxProps: (record) => ({
                    disabled:
                      !is_admin || record.role_status !== "pending_approval",
                    style: {
                      display: is_admin ? "flex" : "none",
                    },
                  }),
                  selectedRowKeys: selectedRowKeys,
                }}
                rowKey="id"
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(
                      fetchAllBillingDetails({
                        ...filters,
                        view: "admin",
                        page_number: pagee,
                        module_type: isPO ? t("purchase") : t("BIL"),
                      })
                    );
                    setPage(pagee);
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BillsApprovals;
