import { Button, Upload } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
const UseFormUpload = ({
  value,
  label,
  required,
  errorMessage,
  errors,
  horizontal = false,
  beforeUpload,
  defaultFileList,
  handleChange,
  listType,
  multiple = false,
  fileName,
  accept,
  ...restOfProps
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Controller
        {...restOfProps}
        shouldUnregister={true}
        render={({ field: { onChange, value, name } }) => (
          <div
            style={{
              alignItems: horizontal && "center",
              display: horizontal && "flex",
            }}
          >
            <span style={{ fontSize: "14px", color: "#3B3B3B" }}>
              {label}
              {required && (
                <sup
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "-1px",
                  }}
                >
                  *
                </sup>
              )}
            </span>
            <div
              style={{
                paddingTop: !horizontal && "0px",
                paddingLeft: horizontal && "10px",
              }}
            >
              <Upload
                beforeUpload={beforeUpload}
                className="upload_file"
                onChange={(e) => {
                  onChange(e);
                }}
                name={name}
                value={value ? value : ""}
                listType={listType}
                // maxCount={1}
                multiple={multiple}
                defaultFileList={defaultFileList}
                accept={accept}
              >
                <Button style={{ color: "black" }} className="upload_buttom">
                  {fileName ?? t("upload_excel")}
                </Button>
              </Upload>
              {errors?.[restOfProps?.name]?.message || errorMessage ? (
                <div className="errorMsg">
                  {errors?.[restOfProps?.name]?.message || errorMessage}
                </div>
              ) : null}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default UseFormUpload;
