import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reports,
  userReportLinkExp,
  userReports,
} from "../../actions/reports/reports";
import { apis } from "../../config/APIs";
import endpoint from "../../config/Axios";
import { objectToFormData } from "../../utils";
import { useTranslation } from "react-i18next";
import {
  ExpenseViolations,
  validateExpense,
} from "../../actions/expenses/expenses";
import endpointPayApiBeta from "../../config/AxiosPayApiBeta";
import endpointPayExpenseBeta from "../../config/AxiosPayExpense";
import { useHistory } from "react-router-dom";

const AddToReportDropdown = ({
  hasId,
  addReport,
  setViolations,
  setShowViolations,
  personvalue,
  reportId,
  showViolations,
  setReport,
  report,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const reportsResponse = useSelector((state) => state.reports.reports || {});
  const { data: { data: reports1 = [] } = {} } = reportsResponse;
  const reportData = useSelector(
    (state) => state?.reports?.userReportShow?.data || {}
  );
  const validateExpenseData = useSelector(
    (state) => state?.expenses?.validateExpense?.data?.data?.transactions
  );

  useEffect(() => {
    dispatch(
      reports({
        filters: { status: ["unsubmitted"] },
        module_type: "REPORT",
        is_admin: false,
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (reportData?.error === false) {
      setReport(reportData?.data?.id);
    }
    // eslint-disable-next-line
  }, [reportData]);

  // useEffect(() => {
  //   if (hasId && report) {
  //     const payload = {};
  //     payload.transaction_ids = [hasId];
  //     payload.report_id = report;
  //     let timeError = setTimeout(() => {
  //       endpointPayExpenseBeta.post(apis.validate_expense, {
  //         transaction_ids: payload.transaction_ids
  //       }).then((res) => {
  //         let txns = res.data?.data?.transactions || [];
  //         let policies = []
  //         txns?.forEach(ele => {
  //           policies = [...policies, ...ele?.policies?.map(e => (e.policy_action === "BLOCKING" || e.policy_action === "WARNING") && e.errors?.length)]
  //         })

  //         console.log(txns,"transactionDetails==>");

  //         if (policies?.filter(ele => ele)?.length) {
  //           setShowViolations && setShowViolations(true)
  //           setViolations && setViolations(txns)
  //           dispatch({
  //             type: ExpenseViolations,
  //             payload: {
  //               policyViolations: txns,
  //               policyModal: true,
  //             }
  //           })
  //         } else {
  //           reportId &&
  //           dispatch(userReportLinkExp((payload)));
  //         }
  //         clearTimeout(timeError)
  //       })
  //     }, 5000);

  //   }
  //   // eslint-disable-next-line
  // }, [hasId])

  useEffect(() => {
    if (hasId && report) {
      const payload = {};
      payload.transaction_ids = [hasId];
      payload.report_id = report;
      dispatch(validateExpense({ transaction_ids: payload.transaction_ids }));
    }
  }, [hasId]);
  React.useEffect(() => {
    if (hasId && report && Array.isArray(validateExpenseData)) {
      const payload = {};
      payload.transaction_ids = [hasId];
      payload.report_id = report;
      let policies = [];
      console.log(validateExpenseData, "validatedData==>");
      validateExpenseData.forEach((ele) => {
        policies = [
          ...policies,
          ...ele?.policies?.map(
            (e) =>
              (e.policy_action === "BLOCKING" ||
                e.policy_action === "WARNING") &&
              e.errors?.length
          ),
        ];
      });

      if (policies?.filter((ele) => ele)?.length) {
        setShowViolations(true);
        setViolations(validateExpenseData);
        // dispatch({
        //   type: ExpenseViolations,
        //   payload: {
        //     policyViolations: validateExpenseData,
        //     policyModal: true,
        //   },
        // });
      } else {
        reportId && dispatch(userReportLinkExp(payload));
        history.push("/expenses");
      }
    }
  }, [validateExpenseData]);
  React.useEffect(() => {
    return () => {
      setReport("");
    };
  }, []);
  const { t } = useTranslation();

  return (
    <>
      <Select
        className="w100"
        showSearch
        allowClear={true}
        value={personvalue === "personal" ? "" : report}
        disabled={personvalue === "personal" ? true : false}
        placeholder={t("select_report")}
        filterOption={(input, option) =>
          (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
        }
        options={
          reports1?.length > 0
            ? reports1?.map((ele) => ({
                label: (ele?.number ?? "") + " - " + ele.title,
                value: ele.id,
              }))
            : []
        }
        onChange={(val) => {
          setReport(val);
        }}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <button className={"add-report"} onClick={addReport}>
                + {t("add_report")}
              </button>
            </>
          );
        }}
      />
    </>
  );
};

export default AddToReportDropdown;
