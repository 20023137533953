import { View, StyleSheet, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { amountFormat } from "../../utils";
import Item from "antd/lib/list/Item";

const TemplateTable = ({
  columns,
  lineItems,
  currency,
  tableHeadRow,
  tableRow,
}) => {
  const styles = StyleSheet.create({
    table: {
      display: "table",
      width: "auto",
      //border:"1px",
      // fontFamily:"Roboto",
      //  borderStyle: ' solid black',
      //	borderColor: 'black',
      marginTop: 50,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableHeadRow: tableHeadRow
      ? {
          ...tableHeadRow,
          flexDirection: "row",
        }
      : {
          backgroundColor: "#ccc",
          flexDirection: "row",
        },
    tableRow: tableRow
      ? {
          ...tableRow,
          flexDirection: "row",
        }
      : {
          flexDirection: "row",
        },
    mainTableCellOne: tableHeadRow
      ? {
          padding: 5,
          fontSize: "12px",
        }
      : {
          borderStyle: "solid",
          borderWidth: 1,
          padding: 5,
          fontSize: "12px",
        },
    dataTableCellOne: tableRow
      ? {
          borderBottom: "1px",
          ...tableRow,
          //textAlign:"center"
        }
      : {
          borderBottomStyle: "solid",
          padding: 5,
          //color: "grey",
          fontSize: "12px",
          borderBottom: "1px",
        },
  });
  return (
    <View style={styles.table}>
      <View style={styles.tableHeadRow}>
        {columns.map((ele) => {
          return (
            <View
              style={{ ...styles.mainTableCellOne, width: ele.width + "%" }}
            >
              <Text>{ele.lable}</Text>
            </View>
          );
        })}
      </View>

      {lineItems?.map((ele) => (
        <View style={styles.tableRow}>
          {columns.map((itm) => {
            return (
              <View
                style={{ ...styles.dataTableCellOne, width: itm.width + "%" }}
              >
                {itm.key === "discount_percentage" && itm.suffix ? (
                  <Text>
                    {ele[itm.key]}
                    {itm.suffix}
                  </Text>
                ) : itm?.key === "unit_price" ||
                  itm?.key === "total_amount" ||
                  itm?.key === "invoice_amount" ||
                  itm?.key === "payment_amount" ||
                  itm?.key === "Unit Price" ||
                  itm?.key === "Amount" ? (
                  <Text>
                    {currency} {amountFormat(ele[itm.key])}
                  </Text>
                ) : (
                  <Text>
                    {itm.sub_key ? ele?.[itm.key]?.[itm.sub_key] : ele?.[itm.key]}
                  </Text>
                )}
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
};

export default TemplateTable;
