import React, { useEffect, useState } from "react";
import { Button, message, Modal, Space, Table, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  card_policy_delete,
  CardLimitSet,
  CardPoliciesDelete,
  get_card_policies,
  show_card_policy,
  ShowCardPolicy,
} from "../../../../actions/cards/cards";
import { ErrorModal } from "../../../../actions/dashboard/dashboard";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import DownloadIcon from "../../../../assets/icons/receipt_icon.png";
import { hasAccess } from "../../../../utils";
import "./../styles/policy.css";
import { AddCards } from "./AddCard";
const { Title } = Typography;

const Cards = ({ hasWriteAccess }) => {
  const cardPoliciesRes = useSelector(
    (state) => state?.cards?.get_card_policies
  );
  const saveCardPolicyRes = useSelector(
    (state) => state?.cards?.cartSetPolicies
  );

  const [selectedRow, setSelectedRow] = useState({})
  const [selectedId, setSelectedId] = React.useState('');
  const { t } = useTranslation();
  const show_policy = useSelector(state => state?.cards?.show_card_policy?.data?.data || {})
  const delete_policy = useSelector(state => state?.cards?.delete_card_policy || {})
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState('center');
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRow({})
    dispatch({
      type: ShowCardPolicy.RESET
    })
  };

  useEffect(() => {
    dispatch(get_card_policies());
    dispatch(headerTitles({
      title: 'polocies_limits',
      description: ""
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(show_policy,"policyDetails===>");

  useEffect(() => {
    if (saveCardPolicyRes?.data?.status) {
      message.success(
        <span className="messageText">{`Bulk Card policy limit ${selectedRow?.id ? "updated" : "created"} successfully.`}</span>
      );
      dispatch(get_card_policies());
      handleCancel();
      dispatch({
        type: CardLimitSet.RESET,
      });
    }
    if (saveCardPolicyRes?.data?.status === false) {
      message.error(
        <span className="messageText">{saveCardPolicyRes?.data?.message}</span>
      );
      dispatch({
        type: ErrorModal.SUCCESS,
        payload: {
          open: true,
          errMsg: typeof saveCardPolicyRes?.data?.errors === "string" ? saveCardPolicyRes?.data?.errors : saveCardPolicyRes?.data?.errors?.message
        }
      })
      dispatch({
        type: CardLimitSet.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCardPolicyRes]);

  useEffect(() => {
    if (delete_policy?.data?.status) {
      message.success(
        <span className="messageText">{delete_policy?.data?.message}</span>
      );
      dispatch(get_card_policies());
      handleCancel();
      dispatch({
        type: CardPoliciesDelete.RESET,
      });
      setIsModalType(false);
      setSelectedId("");
    }
    if (delete_policy?.data?.status === false) {
      message.error(
        <span className="messageText">{delete_policy?.data?.message}</span>
      );
      dispatch({
        type: CardPoliciesDelete.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delete_policy]);

  const showEditModal = (id) => {
    setIsModalVisible(true)
    setIsModalType('right');
    dispatch(show_card_policy({
      id: id
    }))
  }

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType('center');
  };

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t('delete_sure_field')}</Text><br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() => dispatch(card_policy_delete({id:selectedId}))}
        >{t('delete')}</Button>
        <Button key="2" className="cancelButton ml1 mt1" onClick={() => handleCancel()}>{t('cancel')}</Button>
      </>
    );
  }

  function handleButtonClick(e) {
    setSelectedId('');
    setIsModalVisible(true);
    setIsModalType('right');
  }


  const columns = [
    // {
    //   title: "Type",
    //   dataIndex: "role_name",
    //   key: "role_name",
    //   render: (text) => <div className="typeDiv">{text}</div>,
    // },
    {
      title: t('name'),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t('desc'),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t('status'),
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            disabled={!hasWriteAccess}
            onClick={() => {
              setSelectedRow(record)
              showEditModal(record.id)
            }}
          >
            <img src={EditIcon} alt={"logo"} className="action-logo" />
          </button>
          {record.key !== "1" ? (
            <button
              disabled={!hasWriteAccess}
              onClick={() => {showDeleteModal(record.id)}}
            >
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />
            </button>
          ) : null}
          {record.file_status_url ? (
            <button
              disabled={!hasWriteAccess}
              onClick={() => {
                window.open(record.file_status_url, "_blank")
              }}
            >
              <img src={DownloadIcon} alt={"logo"} className="action-logo" />
            </button>
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <>
      {isModalVisible && <Modal
        title={
          modalType === 'center' ?
          <><img src={DeleteIcon} alt={"logo"} className="action-logo" />{t('delete_field')}</> :
          <Title level={4}>
            {selectedRow?.id ? "Edit" : "Add"} {t('card_policy')} &nbsp;
            <span className="text-small">{t('enter_details')}</span>
          </Title>
        }
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={modalType === "center" ? "center-aligned-modal":"right-alinged-modal lg"}
        width={650}
      >
        {modalType === "center" ? renderDeleteOption() :<AddCards handleCancel={handleCancel} selectedRow={{ ...show_policy }} />}
      </Modal>}
      {<Button disabled={!hasWriteAccess} onClick= {handleButtonClick} className="mb1 addNew">
        <img src={AddNewIcon} alt={"logo"} className="addNew-logo" />
        {t('add_new_policy')}
      </Button>}
      <Table
        rowKey="id"
        columns={columns}
        dataSource={cardPoliciesRes?.data?.data}
        className="settingsTable"
      />
    </>
  );
};

export default Cards;
