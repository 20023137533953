import { Button, Col, message, Modal, Row, Space } from "antd";
import { useEffect, useState } from "react";
import {
  default as React
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { get_cmp_accounts } from "../../actions/accounts/accounts";
import { fetchBillingDetailsById } from "../../actions/bills/bills";
import { get_currencies } from "../../actions/masterdata/masterdata";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import { convertCurrency, uploadFile } from "../../utils";
import { FormInput, FormSelect } from "../inputs";
import DateInput from "../inputs/DateInput";
import UploadField from "../inputs/UploadField";
import { useTranslation } from "react-i18next";
import PaymentModeFields from "./PaymentModeFields";
import moment from "moment";
import { GetCompanyBaseCurrency, getCompanyBaseCurrency } from "../../actions/companySettings";
import useDocumentAxios from "../../config/useDocument";

const RecordPayment = ({ open, onClose, id, amount, isInvoice, type, file_type, beneficiary_id, onSuccess, invoiceInfo }) => {
  const dispatch = useDispatch()
  const companyBaseCurrency = useSelector((state) => state?.companySetting?.getCompanyBaseCurrency?.data)


  useEffect(() => {
    if (companyBaseCurrency?.error) {
      message.error(<span className="messageText">{companyBaseCurrency?.message}</span>)
      dispatch({
        type: GetCompanyBaseCurrency.RESET
      })
    }
    // dispatch({
    //   type: GetCompanyBaseCurrency.RESET
    // })
  }, [companyBaseCurrency])


  const currencies = useSelector((state) =>
    state?.currencies?.data?.response?.base_currencies?.length
      ? state?.currencies?.data?.response?.base_currencies?.map((ele) => ({
        label: ele.iso_code,
        value: ele.iso_code,
      }))
      : []
  );

  // console.log('beneficiary_id=',beneficiary_id,'isInvoice=',isInvoice,'id=',id,'invoiceINfo=',invoiceInfo,'amount=',amount,'iso=',invoiceInfo?.currency_iso)

  const [listFiles, setListFiles] = useState([]);

  const { onCall } = usePayAxios({
    api: isInvoice ? apis.createInvoiceReceipt : apis.createReceipt,
    method: "post",
  })
  const { onCall: UploadDocuments, data: UploadDocumentsData, reset: UploadDocumentsRest, } = useDocumentAxios({
    api: apis?.profileUpload,
    method: "post",
  });
  console.log("upload document file value", listFiles)
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues
  } = useForm({
    defaultValues: {
      // beneficiary_id: "",
      currency: invoiceInfo?.currency_iso,
      amount_paid: amount,
      payment_date: moment(),
      payment_mode: "Cash",
      paid_through: "-",
      reference_number: "",
      notes: "",
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    const obj = {}
    const { live_exchange_rates, manual_exchange_rates } = companyBaseCurrency?.data;
    obj.bc_amount_paid = convertCurrency(amount, companyBaseCurrency?.data);
    obj.exchange_rate = live_exchange_rates?.rate || manual_exchange_rates?.rate;
    obj.currency_datetime = invoiceInfo?.currency_datetime;
    obj.company_currency_id = invoiceInfo?.company_currency_id;

    if (isInvoice) {
      obj.invoice_id = id
      obj.beneficiary_id = beneficiary_id ?? ''
    }
    else
      obj.billing_id = id

    data.amount_paid = parseFloat(data.amount_paid)
    data.currency = data.currency
    onCall({
      data: {
        ...obj,
        ...data
      },
      params: {
        module_type: type
      }
    }).then(res => {
      if (!res.error) {
        message.success(<span className="messageText">{t('receipt_created')}</span>)
        onClose()
        reset()
        if (listFiles.length) {
          const formData = new FormData();
          Array.from(listFiles).forEach((ele) => {
            formData.append("files", ele);
          });

          formData.append("name", "documents")
          formData.append("record_type", file_type);
          formData.append("record_id", res?.data?.id);
          UploadDocuments({ data: formData })
        }
        if (onSuccess) {
          onSuccess()
        } else {
          dispatch(fetchBillingDetailsById({ isInvoice, [isInvoice ? "invoice_id" : "billing_id"]: id, module_type: type }));
        }

      }
    }).catch(err => {
      message.error(<span className="messageText">{t('failed_receipt')} {err?.response?.data?.message}</span>)
    })
  }
  const { t } = useTranslation();

  const paymentMode = watch("payment_mode");
  const amount_paid = watch("amount_paid");

  useEffect(() => {
    dispatch(get_currencies());
    dispatch(get_cmp_accounts())
    watch("currency");
  }, [])

  useEffect(() => {
    setValue("amount_paid", amount)
  }, [amount])

  useEffect(() => {
    if (invoiceInfo?.company_currency_id) {
      setValue("currency", invoiceInfo?.currency_iso)
      dispatch(getCompanyBaseCurrency({ company_currency_id: invoiceInfo?.company_currency_id }
      ))
    }
  }, [invoiceInfo?.company_currency_id])

  const payment_mode = [{
    label: "Cheque",
    value: "Cheque",
  }, {
    label: "DD",
    value: "DD",
  }, {
    label: "Cash",
    value: "Cash",
  }, {
    label: "UPI",
    value: "UPI",
  },
  {
    label: "Card",
    value: "Card",
  }, {
    label: "Wallet",
    value: "Wallet",
  }]

  // const payment_through = [{
  //   label: "Credit Card",
  //   value: "Credit Card",
  // }, {
  //   label: "Petty Cash",
  //   value: "Petty Cash",
  // }, {
  //   label: "Coupons",
  //   value: "Coupons",
  // }]

  return (
    <>
      <Modal
        onCancel={() => {
          onClose();
          reset()
        }}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">{t('manual_payment')}</span>
            </div>
          </div>
        }
        visible={open}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="p1">
                <div className="pp-form-item">
                  <Row align={"bottom"} justify={"space-between"} gutter={16}>
                    {invoiceInfo?.company_currency_id ?
                      <div className="justify-center" style={{ alignItems: 'baseline', width: '95%' }}>
                        <Col span={14}>
                          <h5>Currency Exchange Rate:</h5>
                        </Col>
                        <Col>
                          <p key={''} style={{ fontSize: '14px', color: '#333', fontWeight: '600' }}>
                            1 {invoiceInfo?.currency_iso} = {convertCurrency(1, companyBaseCurrency?.data)} {companyBaseCurrency?.data?.base_currency}
                            {/* 1 {exchange?.convert_currency} = {convertCurrency(1, exchange)} {exchange?.base_currency} */}
                          </p>
                        </Col>
                      </div> : null}
                    <Col span={8}>
                      <FormSelect
                        inline
                        control={control}
                        {...register("currency")}
                        errors={errors}
                        required
                        label={t('fund_transfer_type')}
                        options={currencies}
                        disabled={true}
                        hideCreateOption
                      />
                    </Col>

                    <Col span={16}>
                      <FormInput
                        control={control}
                        {...register("amount_paid")}
                        errors={errors}
                        label=""
                        placeholder={t('enter_amount')}
                        errorMessage={getValues("amount_paid") > amount ? t('less_amount') : ""}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="pp-form-item">
                  <DateInput
                    control={control}
                    {...register("payment_date")}
                    errors={errors}
                    label={t('payment_date')}
                  />
                </div>
                <div className="pp-form-item">
                  <FormSelect
                    inline
                    control={control}
                    {...register("payment_mode", { required: true })}
                    required
                    errors={errors}
                    label={t('payment_mode')}
                    placeholder={t('select')}
                    options={payment_mode}
                    hideCreateOption
                  />
                </div>
                <PaymentModeFields
                  payment_mode={paymentMode}
                  amount={Number(amount_paid)}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  register={register}
                />
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("reference_number")}
                    errors={errors}
                    label={t('reference_hash')}
                  />
                </div>

                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("notes")}
                    errors={errors}
                    label={t('notes')}
                  />
                </div>

                <div style={{ marginTop: "40px" }}>
                  <UploadField
                    title={t('attach_payments')}
                    desc={t('material_desc')}
                    subDescripcion={t('inv_desc')}
                    acceptType={".jpg,.pdf,.png"}
                    onSelected={(fileInfo) => {
                      let listAux = [...listFiles];
                      listAux.push(fileInfo);
                      setListFiles(listAux);
                    }}
                    filesList={listFiles}
                    onRemove={(index) => {
                      let listAux = [...listFiles];
                      listAux.splice(index, 1);
                      setListFiles(listAux);
                    }}
                  />
                </div>
              </div>
            </div>

            <Space
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "6rem 47px 6rem 0px",
                justifyContent: "end",
              }}
            >
              <Button
                className="pp-secondary-button"
                style={{ marginLeft: "2rem" }}
                onClick={() => {
                  onClose();
                  reset()
                }}
              >
                <span>{t('cancel')}</span>
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                className="pp-main-button"
              >
                <span>{t('save')}</span>
              </Button>
            </Space>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default RecordPayment;
