import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, Table } from 'antd';
import { Formik, useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { one_way_columns, round_trip_columns } from './TripTables';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import "./trips.css"
const Option = Select.Option

const flightValidation = yup.lazy((vals) => {
  return yup.object().shape({
    mode: yup.string().required("Flight mode is required."),
    time_preference: yup.string().required("Time Preference is required."),
    seat_preference: yup.string().required("Seat Preference is required."),
    meal_preference: yup.string().required("Meal Preference is required."),
    description: yup.string().required("Description is required."),
    trips: yup.array().of(yup.object().shape({
      departure_date: yup.string().required("Departure date is required."),
      from_city: yup.string().required("From city is required."),
      to_city: yup.string().required("To city is required."),
      return_date: yup.string().test("return_date", "Return date is required.", () => {
        if (vals.mode === "round_trip" && !vals.trips?.[0].return_date) {
          return false
        };
        return true
      })
    }))
  })
})
const FlightData = ({ tripForm, isModalVisible, setIsModalVisible }) => {
  const { t } = useTranslation();
  const { flight_reservations = {} } = tripForm?.values;
  const flightFormik = useFormik({
    initialValues: {
      mode: "one_way",
      time_preference: "",
      seat_preference: "",
      meal_preference: "",
      description: "",
      trips: [{
        departure_date: "",
        from_city: "",
        to_city: "",
        return_date: ""
      }]
    },
    // enableReinitialize: true,
    validationSchema: flightValidation,
    onSubmit: (vals) => {
      tripForm.setFieldValue("flight_reservations", vals)
      handleModalCancelButton()
    }
  })

  useEffect(() => {
    if (isModalVisible)
      flight_reservations?.mode ?
        flightFormik.setValues({
          mode: flight_reservations?.mode ?? "one_way",
          time_preference: "",
          seat_preference: "",
          meal_preference: "",
          description: "",
          trips: [{
            departure_date: "",
            from_city: "",
            to_city: "",
            return_date: ""
          }],
          ...flight_reservations
        }) : flightFormik.setValues({
          mode: flight_reservations.mode ?? "one_way",
          time_preference: "",
          seat_preference: "",
          meal_preference: "",
          description: "",
          trips: [{
            departure_date: "",
            from_city: "",
            to_city: "",
            return_date: ""
          }],
        })
    // eslint-disable-next-line
  }, [isModalVisible])

  const handleModalCancelButton = () => {
    setIsModalVisible(false);
    flightFormik.resetForm();
  }


  const onNewRowChange = (type, idx) => {
    if (type === "remove") {
      if (idx > -1) {
        let trips = flightFormik?.values?.trips
        trips.splice(idx, 1)
        flightFormik.setFieldValue("trips", trips)
      }
    } else {
      let trips = flightFormik?.values?.trips;
      trips = [...trips, {
        departure_date: "",
        from_city: "",
        to_city: "",
        return_date: ""
      }]
      flightFormik.setFieldValue("trips", trips)
    }
  }

  useEffect(() => {
    if (flightFormik.values?.mode === flight_reservations?.mode && flight_reservations?.mode) {
      let trips = flightFormik?.values?.trips
      trips = flight_reservations?.trips;
      flightFormik.setFieldValue("trips", trips)
    } else {
      let trips = flightFormik?.values?.trips
      trips = [{
        departure_date: "",
        from_city: "",
        to_city: "",
        return_date: ""
      }];
      flightFormik.setFieldValue("trips", trips)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightFormik.values?.mode])

  const renderFlightForm = () => {
    return (
      <form
        name="flight-form"
        onSubmit={flightFormik.handleSubmit}
        className="modal-form"
      >
        <Row>
          <Col span={8}>
            <Form.Item label={t("flight_mode")} required>
              <Radio.Group
                name="mode"
                value={flightFormik?.values?.mode}
                onChange={(e) => {
                  flightFormik.setFieldValue("mode", e.target.value);
                }}
              >
                <Radio value="one_way"
                  id={"mode"}
                >{t("one_way")}</Radio>
                <Radio value="round_trip">{t("round_trip")}</Radio>
                <Radio value="multi_city">{t("multi_city")}</Radio>
              </Radio.Group>
              {flightFormik.touched.mode && flightFormik.errors.mode && (
                <div className="errorMsg">{flightFormik.errors.mode}</div>
              )}
            </Form.Item>
          </Col>
          <Col span={5}></Col>
          <Col span={5}>
            <Form.Item label={t("seat_preference")} required>
              <Select
                style={{ width: 200 }}
                placeholder={t("seat_preference")}
                name="seat_preference"
                id="seat_preference"
                value={flightFormik.values?.seat_preference}
                onChange={(val) => {
                  flightFormik.setFieldValue("seat_preference", val);
                }}
              >
                <Option key="45" value="Any">
                  {t("none")}
                </Option>
                <Option key="46" value="Window">
                  {t("window")}
                </Option>
                <Option key="47" value="Middle">
                  {t("middle")}
                </Option>
                <Option key="48" value="Aisle">
                  {t("aisle")}
                </Option>
              </Select>
              {flightFormik.touched.seat_preference &&
                flightFormik.errors.seat_preference && (
                  <div className="errorMsg">
                    {flightFormik.errors.seat_preference}
                  </div>
                )}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label={t("meal_preference")} required>
              <Select
                style={{ width: 200 }}
                placeholder={t("meal_preference")}
                name="meal_preference"
                id="meal_preference"
                value={flightFormik?.values?.meal_preference}
                onChange={(val) => {
                  flightFormik.setFieldValue("meal_preference", val);
                }}
              >
                <Option key="54" value="Vegetarian">
                  {t("veg")}
                </Option>
                <Option key="56" value="Non - Vegetarian">
                  {t("non_veg")}
                </Option>
              </Select>
              {flightFormik.touched.meal_preference &&
                flightFormik.errors.meal_preference && (
                  <div className="errorMsg">
                    {flightFormik.errors.meal_preference}
                  </div>
                )}
            </Form.Item>
          </Col>
        </Row>
        <div className="trips-detail detail-card p1">
          {flightFormik.values?.trips?.map((item, index) => (
            <Row key={`flightrows_${index}`} className="mt2">
              <Col span={4}>
                <Form.Item label={t("depart_from")} required>
                  <Input
                    placeholder={t("enter")}
                    value={flightFormik.values?.trips?.[index]?.from_city}
                    name={`fli_from_city_key${index}`}
                    onChange={(e) => {
                      let trips = flightFormik.values.trips;
                      trips[index].from_city = e.target.value;
                      flightFormik.setFieldValue("trips", trips);
                    }}
                  />
                  {flightFormik.touched.trips?.[index]?.from_city &&
                    flightFormik.errors.trips?.[index]?.from_city && (
                      <div className="errorMsg">
                        {flightFormik.errors.trips?.[index]?.from_city}
                      </div>
                    )}
                </Form.Item>
              </Col>
              <Col span={4} className="ml1">
                <Form.Item label={t("arrived_at")} required>
                  <Input
                    placeholder={t("enter")}
                    value={flightFormik.values?.trips?.[index]?.to_city}
                    name={`fli_to_city_key${index}`}
                    onChange={(e) => {
                      let trips = flightFormik.values.trips;
                      trips[index].to_city = e.target.value;
                      flightFormik.setFieldValue("trips", trips);
                    }}
                  />
                  {flightFormik.touched.trips?.[index]?.to_city &&
                    flightFormik.errors.trips?.[index]?.to_city && (
                      <div className="errorMsg">
                        {flightFormik.errors.trips?.[index]?.to_city}
                      </div>
                    )}
                </Form.Item>
              </Col>
              <Col span={4} className="ml1">
                <Form.Item label={t("departure_date")} required>
                  <DatePicker
                    format={"DD/MM/YYYY HH:mm:ss"}
                    showTime
                    value={
                      flightFormik.values?.trips?.[index]?.departure_date
                        ? moment(
                          flightFormik.values?.trips?.[index]?.departure_date,
                          "DD/MM/YYYY HH:mm:ss a"
                        )
                        : undefined
                    }
                    id='departure_date'
                    onChange={(date) => {
                      let trips = flightFormik.values.trips;
                      trips[index].departure_date = date
                        ? moment(date).format("DD/MM/YYYY HH:mm:ss a")
                        : "";
                      trips[index].return_date = "";
                      flightFormik.setFieldValue("trips", trips);
                    }}
                    name={`fli_departure_date_key${index}`}
                  />
                  {flightFormik.touched.trips?.[index]?.departure_date &&
                    flightFormik.errors.trips?.[index]?.departure_date && (
                      <div className="errorMsg">
                        {flightFormik.errors.trips?.[index]?.departure_date}
                      </div>
                    )}
                </Form.Item>
              </Col>
              {flightFormik.values?.mode === "round_trip" ? (
                <Col span={4} className="ml1">
                  <Form.Item label={t("return_date")} required>
                    <DatePicker
                      format={"DD/MM/YYYY HH:mm:ss"}
                      showTime
                      name={`fli_return_date_key${index}`}
                      value={
                        flightFormik.values?.trips?.[index]?.return_date
                          ? moment(
                            flightFormik.values?.trips?.[index]?.return_date,
                            "DD/MM/YYYY HH:mm:ss a"
                          )
                          : undefined
                      }
                      onChange={(date) => {
                        let trips = flightFormik.values.trips;
                        trips[index].return_date = date
                          ? moment(date).format("DD/MM/YYYY HH:mm:ss a")
                          : "";
                        flightFormik.setFieldValue("trips", trips);
                      }}
                      disabledDate={(current) => {
                        let customDate = moment(
                          flightFormik.values?.trips?.[index]?.departure_date,
                          "DD/MM/YYYY HH:mm:ss a"
                        );
                        return current && current < moment(customDate);
                      }}
                    />
                    {flightFormik.touched.trips?.[index]?.return_date &&
                      flightFormik.errors.trips?.[index]?.return_date && (
                        <div className="errorMsg">
                          {flightFormik.errors.trips?.[index]?.return_date}
                        </div>
                      )}
                  </Form.Item>
                </Col>
              ) : null}
              <Col span={1}>
                {index >= 1 ? (
                  <MinusCircleOutlined
                    className="mt3"
                    onClick={() => onNewRowChange("remove", index)}
                  />
                ) : null}
              </Col>
            </Row>
          ))}
          {flightFormik.values?.mode === "multi_city" &&
            flightFormik.values?.trips?.length <= 5 ? (
            <>
              <hr />
              <div
                className="p1 cursor type-link-button"
                onClick={() => onNewRowChange("add")}
              >
                {" "}
                + {t("another_line")}
              </div>
            </>
          ) : null}
        </div>
        <div className="p1">
          <Row className="mt1">
            <Col span={6}>
              <Form.Item label={t("time_preference")} required>
                <Select
                  style={{ width: 200 }}
                  placeholder={t("select")}
                  name="time_preference"
                  value={flightFormik.values?.time_preference}
                  onChange={(val) => {
                    flightFormik.setFieldValue("time_preference", val);
                  }}
                >
                  <Option key="64e" value="Morning (Before 11 AM)">
                    {t("morning")} (Before 11 AM)
                  </Option>
                  <Option key="66e" value="Afternoon (11 AM - 5 PM)">
                    {t("afternoon")} (11 AM - 5 PM)
                  </Option>
                  <Option key="67e" value="Evening (5 PM - 9 PM)">
                    {t("evening")} (5 PM - 9 PM)
                  </Option>
                  <Option key="68e" value="Night (After 9 PM)">
                    {t("night")} (After 9 PM)
                  </Option>
                </Select>
                {flightFormik.touched.time_preference &&
                  flightFormik.errors.time_preference && (
                    <div className="errorMsg">
                      {flightFormik.errors.time_preference}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={6} className="ml1">
              <Form.Item label={t("desc")} required>
                <Input
                  placeholder={t("enter_desc")}
                  name={`description`}
                  value={flightFormik.values?.description}
                  onChange={(e) => {
                    let description = e.target.value;
                    flightFormik.setFieldValue("description", description);
                  }}
                />
                {flightFormik.touched?.description &&
                  flightFormik.errors?.description && (
                    <div className="errorMsg">
                      {flightFormik.errors?.description}
                    </div>
                  )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="mb3">
          <Button
            key="2"
            className="cancelButton ml1 mt3 floatRight cancel-button"
            onClick={() => handleModalCancelButton()}
          >
            {t("cancel")}
          </Button>
          <Button
            key="1"
            htmlType="submit"
            className="formButton mt3 primaryButton floatRight save-button"
          >
            {t("save")}
          </Button>
        </div>
      </form>
    );
  };
  return (
    <>
      <div className="trips-detail detail-card mb2">
        <Modal
          title={t('add_flight_details')}
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false)
          }}
          footer={[]}
          className="center-aligned-modal-big trips-modal"
        >
          {renderFlightForm()}
        </Modal>
        <div className='mt1'>
          <Button
            className="primary-btn floatRight mr1"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            {(flight_reservations?.trips?.length && flight_reservations?.trips?.length && flight_reservations?.trips[0]?.from_city)
              ? t('edit') : t('add_flight_details')}
          </Button>
        </div>
        <div className='pl1'>
          {t('flight_type')}: <b className='capitalize'>
            {flight_reservations?.mode?.replace(/_/g, " ") || t('no_data')}
          </b> &nbsp;| &nbsp;
          {t('meal_preference')} <b className='capitalize'>
            {flight_reservations?.meal_preference?.replace(/_/g, " ") || t('no_data')}
          </b> &nbsp;| &nbsp;
          {t('seat_preference')}<b className='capitalize'>
            {flight_reservations?.seat_preference?.replace(/_/g, " ") || t('no_data')}
          </b>
        </div>
        <Table
          rowKey="id"
          columns={flight_reservations?.mode === 'round_trip' ? round_trip_columns : one_way_columns}
          dataSource={
            flight_reservations?.trips?.length
              ? flight_reservations.trips : []}
          className="settingsTable tripsTable"
          pagination={false}
        />
        <div className='p1'>
          {/* {t('time_preference')}&nbsp; */}
          {t('departure_time')} <b className='capitalize'>{flight_reservations?.time_preference?.replace(/_/g, " ") || t('no_data')}</b>  &nbsp;| &nbsp;
          {/* {t('return_time')} <b className='capitalize'>{flight_reservations?.return_time_preference?.replace(/_/g, " ") || t('no_data')}</b> &nbsp;| &nbsp; */}
          {t('desc')}: <b className='capitalize'>{flight_reservations?.description || t('no_data')}</b>
        </div>
      </div>
    </>
  );
};

export default FlightData;