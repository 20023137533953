import { Button, message } from 'antd';
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userReportLinkTrip } from "../../actions/trips";
import { arrrayOfObjectToArray, objectToFormData } from "../../utils";
import TableWrap from "../Common/Table/Table";
import { changeReports, reports } from '../../actions/reports';
import moment from 'moment';

message.config({
  maxCount: 3,
});

const LinkTripsToReport = (props) => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = React.useState([]);
  const [reportsData, setReportsData] = React.useState([]);
  const reportsResponse = useSelector(state => state.reports.reports || {});
  const tripData = useSelector(
    (state) => state.trips.tripsShow?.data?.data || {}
  );
  const { t } = useTranslation();
  React.useEffect(() => {
    const { data: { data:  reports = [] , status = false } = {} } = reportsResponse;
    if (status) {
      setReportsData(reports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsResponse]);

  const columns = [
    {
      title: t("report_id"),
      dataIndex: "report_number",
      key: "report_number",
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("dateAndTime"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedData,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedData(selectedRowKeys);
    }
  };

  const handleClearList = () => {
    setSelectedData([]);
  }

  const handleAddExp = () => {
    const payload = {};
    payload.trip_ids = [props.trip_id];
    payload.report_id = selectedData[0];
    tripData.reports && tripData.reports
      ? dispatch(
          changeReports({
            params: { module_type: "TRIP" },
            id: tripData.id,
            report_id: selectedData[0],
          })
        )
      : dispatch(userReportLinkTrip({ ...payload }));
  }
  const tripsData = useSelector(
    (state) => state?.trips?.tripsShow?.data?.response?.trips?.[0] || {}
  );

  console.log(tripData,"tripdetailss==>");
  return (
    <div className="expenses-table">
      {selectedData.length ? (
        <>
          <Button
            type="default"
            ghost
            className="approve-add-btn mb1"
            onClick={() => handleClearList()}
          >
            {t("clear_selection")}
          </Button>
          <Button
            onClick={() => handleAddExp()}
            className="floatRight ml1 primaryButton"
          >
            {t("add_to")} {selectedData.length > 1 ? selectedData.length : ""}{" "}
            {t("report")}
          </Button>
        </>
      ) : null}
      <TableWrap
        rowKey="id"
        columns={columns}
        // data={reportsData.slice(0, 9)}
        data={reportsData?.filter(item => !arrrayOfObjectToArray(tripsData?.report, "id")?.includes(item.id)).slice(0, 9)}
        // data={[tripData.reports]}
        className="tableStyled"
        pagination={{
          total: reportsResponse?.data?.total_count || 0,
          onChange: (pagee) => {
            dispatch(
              reports({
                module_type: "REPORT",
                filters: { status: ["unsubmitted"] },
                params: { page_number: pagee ,report_link_status:true},
              })
            );
          },
        }}
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
      />
    </div>
  );
};

export default LinkTripsToReport;
