import { Button, Form, Input, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  addProjectsField,
  updateProjectsField,
} from "../../../../actions/companySettings";
import { findLodash } from "../../../../utils";
import "./../Custom.css";
const { TextArea } = Input;

const ValidationSchema = yup.object().shape({
  name: yup.string().matches(/^[a-zA-Z]+[a-zA-Z0-9\s]*$/, 'Please Enter Proper Name').required("Please provide category name"),
  code: yup.string(),
  description: yup.string(),
});

const AddProjectsField = (props) => {
  const { t } = useTranslation();
  const { id: currentId, employeesData = [] } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const projectsFieldResponse = useSelector((state) => state.companySetting.getProjectsField?.data?.data || []);
  // const addProjectsFieldResponse = useSelector(state => state.companySetting.addProjectsField || {});
  console.log("projectsFieldResponse", projectsFieldResponse);

  React.useEffect(() => {
    form.resetFields();
    if (projectsFieldResponse.length && currentId) {
      const editValues = findLodash(projectsFieldResponse, { id: currentId }) || {};
      form.setFieldsValue({
        name: editValues.name,
        code: editValues.code,
        description: editValues.description,
        user_id: editValues.user_id,
        is_enable: editValues.is_enable,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleSubmit = (values) => {
    const payload = {};
    ["code", "name", "description", "user_id", "is_enable"].forEach((item) => {
      payload[item] = values[item] || false;
    });

    payload.is_enable = true;

    ValidationSchema.validate(payload)
      .then(function ({ ...value }) {
        Object.keys(payload).forEach((key) => {
          if (!payload[key] && key !== "is_enable") {
            delete payload[key];
          }
        });
        currentId ?
          dispatch(updateProjectsField(({ ...payload, id: currentId.toString() }))) :
          dispatch(addProjectsField(({ ...payload })));
        form.resetFields();
      })
      .catch(function (err) { });
  };

  const handleCancelButton = () => {
    form.resetFields();
    props.handleCancel();
  };

  return (
    <>
      <Form
        layout='vertical'
        form={form}
        onFinish={handleSubmit}
        className='modal-form custom-form small-width'
      >
        <Form.Item
          label={t("projects_name")}
          name='name'
          rules={[{ required: true, message: "Please enter name" }, { pattern: /^[a-zA-Z]+[a-zA-Z0-9\s]*$/, message:"Please Enter Proper Name"}]}
        >
          <Input placeholder={t("enter_project_name")} />
        </Form.Item>
        <Form.Item label={t("projects_code")} name='code'>
          <Input placeholder={t("enter_project_code")} />
        </Form.Item>
        <Form.Item name='user_id' label={t("project_head")}>
          <Select
            placeholder={t("select_project_head")}
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toLowerCase?.() ?? "")?.includes(
                input?.toLowerCase?.()
              )
            }
            options={
              employeesData &&
              employeesData
                .filter(
                  (item) =>
                    item.roles.length > 1 ||
                    (item.roles.length === 1 &&
                      !item.roles.includes("Employee"))
                )
                .map(({ user_id, name }) => ({ label: name, value: user_id }))
            }
          />
        </Form.Item>
        <Form.Item label={t("desc")} name='description'>
          <TextArea
            placeholder={t("enter_desc")}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>

        <Button key='1' htmlType='submit' className='formButton mt1'>
          {currentId ? t("update") : t("save")}
        </Button>
        <Button
          key='2'
          className='cancelButton ml1 mt1'
          onClick={() => handleCancelButton()}
        >
          {t("cancel")}
        </Button>
      </Form>
    </>
  );
};

export default AddProjectsField;
