import { Document, Page, View, StyleSheet, Text, PDFViewer, page } from "@react-pdf/renderer";
import React from "react";
import moment from "moment";
import TemplateTable from "./TemplateTable";
import { amountFormat } from "../../utils";
import { line } from "d3";





const PaymentReceived = (props) => {



  const columns = [{
    lable: props?.type === "INVOICE_RECEIPT" || props?.useDummyData === true && props?.obj?.type === "INVOICE_RECEIPT" ? "Invoice#" : "Bill#",
    key: "billNo",
    width: 40
  }, {
    lable: props?.type === "INVOICE_RECEIPT" || props?.useDummyData === true && props?.obj?.type === "INVOICE_RECEIPT" ? "Invoice Date" : "Bill Date",
    key: "invoiceDate",
    width: 30
  }, {
    lable: props?.type === "INVOICE_RECEIPT" || props?.useDummyData === true && props?.obj?.type === "INVOICE_RECEIPT" ? "Invoice Amount" : "Bill Amount",
    key: "invoice_amount",
    width: 40,

  },
    // {
    //   lable: "Payment Amount",
    //   key: "payment_amount",
    //   width: 20,
    //   //suffix: "%"
    // },

  ]
  const {
    billingAddressCity,
    billingAddressState,
    billingAddresszip,
    billingCountry,
    billAddress,
    companyAddr,
    companyCity,
    companyState,
    companyCountry,
    companyPin
  } = props;

  const formatAddress = () => {


    const addressComponents = [

      billAddress,
      billingAddressCity,
      billingAddressState,
      billingAddresszip,
      billingCountry,
    ];

    // Filter out undefined or empty values
    const filteredAddressComponents = addressComponents.filter(Boolean);
    const address = filteredAddressComponents.join(', ');

    return address;
  };

  const formAddress = formatAddress()

  const companyAddress = () => {
    const addressComponents = [

      companyAddr,
      companyCity,
      companyState,
      companyCountry,
      companyPin
    ];

    // Filter out undefined or empty values
    const filteredCompanyComponents = addressComponents.filter(Boolean);
    const address = filteredCompanyComponents.join(', ');

    return address;
  };

  const compAddr = companyAddress()

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      paddingTop: 45,
      paddingLeft: 35,
      paddingRight: 35
    },
    borderedContainer: {
      border: '1px solid #000000',
      // flex: 1,
    },
    Menu: {
      display: "flex",
      flexDirection: 'row',
      justifyContent: "space-between",
      paddingLeft: "10px",
      width: "98%",

    },
    display: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      marginTop: 10,
      width: "40%"
    },
    displayTwo: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      width: "70%",
    },
    container3: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      width: "80%"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    container2: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center", // Center the content vertically
      justifyContent: "space-between", // Add space around the vertical line
    },
    content: {
      flex: 1, // Fill available space
      paddingHorizontal: 10, // Add some horizontal spacing
    },
    text: {
      fontSize: 10,
      marginBottom: 5,
      color: "black",
      fontWeight: "bold",
    },
    textOne: {
      fontSize: 12,
      marginBottom: 5,
      color: "black",
      fontWeight: "bold",
      marginTop: 5
    },
    textTwo: {
      fontSize: 12,
      marginBottom: 5,
      color: "black",

    },
    Menu1: {
      display: "flex",
      flexDirection: 'row',
      justifyContent: "space-between",
      marginTop: 60,
    },
    table: {
      display: 'table',
      width: 'auto',
      marginTop: 10,
    },
    tableHeadRow: {
      //  backgroundColor: "#ccc",
      flexDirection: 'row',

    },
    tableRow: {
      flexDirection: 'row',
    },
    mainTableCellOne: {
      borderStyle: 'solid',
      borderWidth: 1,
      padding: 5,
      // backgroundColor: "grey",
      color: "black",
      fontSize: "12px"
    },
    dataTableCellOne: {
      // borderBottomStyle: 'solid',
      padding: 5,
      // color: "grey",
      fontSize: "12px",
      //borderBottom: "1px"
    },
  })

  const lineItems = props?.lineItems?.map((item) => {
    return ({
      billNo: props?.useDummyData === true ? props?.obj?.invoiceNo : props?.invoiceNo,
      invoiceDate: props?.useDummyData === true ? props?.obj?.invoiceDate : moment(props?.invoiceDate).format("DD-MM-YYYY"),
      invoice_amount: props?.useDummyData === true ? props?.obj?.totalAmount : item?.total_amount,
      // payment_amount: props?.useDummyData === true ? props?.obj?.totalAmount : props?.amountPaid
    })
  })

  // const lineItems = [
  //   {
  //     billNo: props?.useDummyData === true ? props?.obj?.invoiceNo : props?.invoiceNo,
  //     invoiceDate: props?.useDummyData === true ? props?.obj?.invoiceDate : moment(props?.invoiceDate).format("DD-MM-YYYY"),
  //     invoice_amount: props?.useDummyData === true ? props?.obj?.totalAmount : props?.totalAmount,
  //     payment_amount: props?.useDummyData === true ? props?.obj?.totalAmount : props?.amountPaid,

  //   }


  // ]
  const currency = props?.useDummyData === true ? props?.obj?.currency : props?.currency

  return (
    <div>
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <Document>
          <Page size="A4" style={styles.page}  >
            <View style={styles.borderedContainer}>
              <View style={styles.Menu}>
                <View>
                  <Text style={{ marginTop: "5px", fontWeight: "bold", fontSize: "12px" }}>{props?.useDummyData === true ? props?.obj?.companyName : props?.companyName}</Text>
                  {/* <Text style={{ marginTop: "5px", maxWidth: "150px", fontSize: "11px" }}>Telanganaa</Text> */}
                  <Text style={{ marginTop: "5px", maxWidth: "150px", fontSize: "11px" }}>{props?.useDummyData === true ? props?.obj?.address : compAddr}</Text>\
                  <Text style={{ marginTop: "5px", maxWidth: "150px", fontSize: "11px" }}>TAX ID : {props?.useDummyData === true ? "GST24AAACC4563F" : props?.gstNo}</Text>





                </View>
                <View style={styles.header}>
                  <Text style={{ fontSize: "20px", paddingTop: "40px" }}>PAYMENT VOUCHER</Text>
                </View>
              </View>
              {/* <View>
                <Text style={{ borderBottom: "1px solid black", paddingTop: "5px" }}></Text>
              </View> */}
              <View style={{ display: "flex", paddingBottom: "0px" }}>

                <View style={{ ...styles.display, width: "100%", borderBottom: "1px solid black", borderTop: "1px solid black" }}>
                  <View style={{ ...styles.container, width: "50%", paddingTop: "5px", paddingBottom: "15px" }}>
                    <View style={{ fontSize: 5, marginTop: "5px", width: 200, display: "flex", flexDirection: "row" }}>
                      <Text style={{
                        minWidth: "100px",
                        fontSize: 10,
                        color: "black",
                        fontWeight: "bold",
                      }}>
                        Payment Date </Text>
                      <Text>
                        <Text style={styles.text}> : {props?.useDummyData === true ? props?.obj?.invoiceDate : moment(props?.paymentDate).format("DD-MM-YYYY")}</Text>
                      </Text>
                    </View>
                    <View style={{ fontSize: 5, marginTop: "5px", width: 200, display: "flex", flexDirection: "row" }}>
                      <Text style={{
                        minWidth: "100px",
                        fontSize: 10,
                        // marginBottom: 5,
                        color: "black",
                        fontWeight: "bold",

                      }}>Reference Number   </Text>
                      <Text>
                        <Text style={styles.text}> : {props?.useDummyData === true ? props?.obj?.refNo : props?.refNo}</Text>

                      </Text>
                    </View>

                  </View>

                  <View style={{ ...styles.container, width: "50%", borderLeft: "1px solid black", paddingLeft: "15px", paddingTop: "5px", paddingBottom: "15px" }}>
                    <View style={{ fontSize: 5, marginTop: "5px", width: 200, display: "flex", flexDirection: "row" }}>
                      <Text style={{
                        minWidth: "100px",
                        fontSize: 10,
                        color: "black",
                        fontWeight: "bold",
                      }}>
                        {props?.type === "BILL_RECEIPT" || props?.obj?.type === "BILL_RECEIPT" ? "Place Of Supply" : ""}</Text>
                      <Text>
                        {props?.type === "BILL_RECEIPT" || props?.obj?.type === "BILL_RECEIPT" ? <Text style={styles.text}>  {props?.useDummyData === true ? props?.obj?.bankBranch : props?.placeOfSupply}</Text> : null}
                      </Text>
                    </View>


                  </View>

                </View>
                {/* <View>
                  <Text style={{ borderBottom: "1px solid black", paddingTop: "10px" }}></Text>
                </View> */}
              </View>
              <View style={styles.displayTwo}>
                <View style={{ width: "50%" }}>
                  <Text style={{ fontSize: "10px", paddingTop: "5px" }} >Paid To</Text>
                </View>

              </View>

              <View>
                <Text style={{ borderBottom: "1px solid black", paddingTop: "2px" }}></Text>
              </View>
              <View style={styles.container3}>
                <View style={{ width: "50%" }}>
                  <Text style={{ fontSize: "10px", paddingTop: "5px", fontWeight: "bold" }} >{props?.useDummyData === true ? props?.obj?.beniName : props?.benefiName}</Text>
                  <Text style={{ fontSize: "11px", paddingTop: "5px" }} >{props?.useDummyData === true ? props?.obj?.beniEmail : props?.benifiEmail}</Text>

                  <Text style={{ fontSize: "11px", paddingTop: "5px", maxWidth: "180px" }} >{props?.useDummyData === true ? props?.obj?.address : formAddress}</Text>
                  <Text style={{ fontSize: "11px", paddingTop: "5px", maxWidth: "180px" }} >TAX ID : {props?.useDummyData === true ? props?.obj?.gst : props?.benficiaryGST}</Text>

                  {/* <Text style={{ fontSize: "11px", paddingTop: "5px", maxWidth: "180px" }} >Chennai</Text> */}
                </View>

              </View>
              <View style={styles.table}>
                <View style={styles.tableHeadRow}>

                  {columns.map(ele => {
                    return <View style={{ ...styles.mainTableCellOne, width: ele.width + "%" }}>
                      <Text>{ele.lable}</Text>
                    </View>
                  })}
                </View>

                {lineItems?.map((ele, index) => (


                  <View style={styles.tableRow} key={index}>
                    {lineItems?.length === index + 1 ? styles.dataTableCellOne.borderBottom = "0px" : styles.dataTableCellOne.borderBottom = "1px solid grey"}

                    {columns.map(itm => {
                      return <View style={{ ...styles.dataTableCellOne, width: itm.width + "%" }}>
                        {itm.key === 'discount_percentage' && itm.suffix ? (
                          <Text>{ele[itm.key]}{itm.suffix}</Text>
                        ) :
                          (itm.key === "invoice_amount" || itm.key === "payment_amount" ? (
                            <Text>{currency} {''} {amountFormat(ele[itm.key])}</Text>
                          ) : (
                            <Text>{ele[itm.key]}</Text>
                          ))}

                      </View>
                    })}
                  </View>
                ))}



              </View>
              <View style={{ display: "flex", justifyContent: "space-between" }}>
                <View style={{ ...styles.display, width: "100%", borderTop: "1px solid grey" }}>
                  <View style={{ ...styles.container, width: "60%", paddingBottom: "10px", paddingTop: "5px" }}>

                    <View style={{ fontSize: 5, display: "flex", flexDirection: "row", paddingBottom: "60px" }}>
                      <Text style={{
                        //  minWidth: "130px",
                        fontSize: 10,
                        color: "black",
                        fontWeight: "bold",
                        marginRight: "10px"

                      }}>
                        <Text style={styles.text}>Amount of tax subject  to Reverse Charge : </Text>

                      </Text>

                      <Text>
                        <Text style={styles.text}>{props?.useDummyData === true ? currency + ' ' + props?.obj?.totalAmount : currency + ' ' + props?.taxAmount}</Text>

                      </Text>
                    </View>
                  </View>
                  <View style={{ ...styles.container, width: "40%", borderLeft: "1px solid black", paddingLeft: "5px", paddingBottom: "10px", paddingTop: "5px" }}>

                    <View style={{ fontSize: 5, width: 200, display: "flex", flexDirection: "row", paddingBottom: "60px", justifyContent: "space-between" }}>
                      <Text style={{
                        // minWidth: "130px",
                        fontSize: 10,
                        color: "black",
                        fontWeight: "bold",

                      }}>
                        <Text style={styles.text}>Amount  Paid : </Text>

                      </Text>

                      <Text >
                        <Text style={styles.text}>{props?.useDummyData === true ? currency + ' ' + props?.obj?.totalAmount : currency + ' ' + amountFormat(props?.amountPaid)}</Text>

                      </Text>
                    </View>



                  </View>

                </View>
              </View>
            </View>

          </Page>
        </Document>
      </PDFViewer>
    </div>
  )
}

export default PaymentReceived;

