import { pdf } from "@react-pdf/renderer";
import { Divider } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from 'json-2-csv';
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  analytics_cards
} from "../../actions/cards/cards";
import { apis } from "../../config/APIs";
import endpointAnalyticsApi from "../../config/AxiosAnalyticsApi";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import MISExpenses from "../Common/PDF/MISPDF/MISExpenses";
import ReportTable from "./ReportTable";


const Advances = ({ pdfURL, csvData, ...props }) => {
  const { t } = useTranslation();
  const location = useLocation()
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.analytics.analytics_cards);
  // const pdfData = useSelector((state) => state.analytics.analytics_cards_all?.data?.data);

  useEffect(() => {
    dispatch(analytics_cards({ type: "advances" }));
    // dispatch(analytics_cards_all({ type: "advances", params: { isCsv: true } }));
    props.setFilterObj({});
    // eslint-disable-next-line
  }, []);
  const columnsKeys = [
    { label: "Advance ID", key: "advance_id", type: "string" },
    { label: "Purpose / Title", key: "title", type: "string" },
    { label: "Date and Time", key: "created_at", type: "date" },
    { label: "Recorded By", key: "submitted_by", type: "string" },
    { label: "Reference", key: "reference_number", type: "string" },
    { label: "Amount", key: "amount", type: "amount" },
    { label: "Cost Center", key: "cost_center", type: "string" },
    { label: "Project", key: "project", type: "string" },
    { label: "Status", key: "status", type: "string" },
  ];

  useEffect(() => {
    pdfURL(downloadPdf)

  }, [pdfURL])

  const downloadPdf = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.analytics_advances + '?isCsv=true', { filters: filters });
    if (response) {
      const blob = await pdf((
        <MISExpenses data={response?.data?.data} columnsKeys={columnsKeys} />
      )).toBlob();
      saveAs(blob, "MISAdvances.pdf");
    }
  }

  ///// Dowloading CSV file method ////////
  const downloadCsv = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.analytics_advances + '?isCsv=true', { filters: filters });
    if (response) {
      const prod = response?.data?.data.map(ele => ({
        "Advance ID": ele.advance_id,
        "Purpose / Title": ele.title,
        "Date and Time": moment(ele.created_at).format("DD MMM, YYYY hh:mm A"),
        "Recorded By": ele.submitted_by,
        "Reference": ele.reference_number,
        "Amount": ele.amount,
        "Cost Cente": ele.cost_center,
        "Project": ele.project,
        "Status ": ele.status,
      }))

      json2csv(prod).then((csv) => {
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, 'Advances.csv');
      });
    }
  }

  useEffect(() => {
    csvData(downloadCsv)
  }, [csvData])

  const getObjs = () => {
    if (cards?.data?.data?.report?.length > 0) {
      return cards?.data?.data?.report?.[0]?.custom_fields?.map(ele => ({
        title: ele.key?.replace("_", " "),
        dataIndex: "custom_fields",
        key: "custom_fields",
        render: (text, data) => <div>{data?.custom_fields?.find(itm => itm.key === ele.key)?.value}</div>,
      }))
    }

    return []
  }

  const columns = [
    {
      title: t('advance_id'),
      dataIndex: "advance_id",
      key: "advance_id",
    },
    {
      title: t('purpose_title'),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t('dateAndTime'),
      dataIndex: "created_at",
      key: "created_at",
      render: (record) => moment(record).format("DD MMM, YYYY hh:mm A")

    },
    {
      title: t('recorded_by'),
      dataIndex: "submitted_by",
      key: "submitted_by",
    },
    {
      title: t('reference'),
      dataIndex: "reference_number",
      key: "reference_number",
    },
    {
      title: t('Amount'),
      dataIndex: "amount",
      key: "amount",
      render: (text) => <div> {text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')}</div>,
    },
    ...getObjs(),
    {
      title: t('cost_center'),
      dataIndex: "cost_center",
      key: "cost_center",
    },
    {
      title: t('project'),
      dataIndex: "project",
      key: "project",
    },
    {
      title: t('status'),
      dataIndex: "status",
      key: "status",
      render: (text) => <div className={`status status_${text}`}>{text ? text?.replace(/_/g, ' ') : ""}</div>,
    },
  ];

  return (
    <>
      <div className="reports">
        <Divider />
      </div>
      <ReportTable
        columns={columns}
        data={Array.isArray(cards?.data?.data) ? cards?.data?.data : []}
        total_records={cards?.data?.total_count ?? 0}
        paginationCall={(vals) => {
          vals.type = "advances";
          dispatch(analytics_cards(vals));
          // dispatch(analytics_cards_export({ pdf: true, csv: true, ...vals }));
        }}
        filterObj={props.filterObj}
        payload={{ ...props.filterObj, ...props.apiFilters }}
        scroll={{ x: 1400 }}
      />
    </>
  );
};

export default Advances;
