import React, { useEffect, useState } from 'react'
import KYCHeader from '../../CommonScreens/KYCHeader'
import KYCSafe from "../../assets/icons/kyc-safe-icon.svg";
import { EditFilled } from '@ant-design/icons';
import moment from 'moment';
import preview_icon from "../../assets/icons/preview_icon.svg";
import { Modal } from 'antd';
import { createOnBoardForm, onboardStep, onboardStepPreview } from '../../action/OnBoardForm';
import { useDispatch, useSelector } from 'react-redux';
import { getFileExtension, getTotalSections, incrementIfNumber } from '../../../../utils';
import png_icon from "../../../../assets/icons/pdf_file_icon.png"
const PreviewPage = ({ setFormStep, formStep = "", country, setIsPreview, allCountries }) => {
	const dispatch = useDispatch()
	const [isPreviewOpen, setIsPreviewOpen] = useState({
		open: false,
		url: ""
	})
	const onboardStepPreviewResponse = useSelector((state) => state?.on_board?.onboardStepPreview?.data)
	const onboardStepResponse = useSelector((state) => state?.on_board?.onboardStep)
	const onboardPageDetails = useSelector((state) => state?.on_board?.getOnboardFields)
	useEffect(() => {
		if (onboardStepResponse?.data?.data?.step && onboardPageDetails?.data?.data && incrementIfNumber(onboardStepResponse?.data?.data?.step) == getTotalSections(onboardPageDetails?.data?.data)) {
			dispatch(onboardStepPreview({ preview: true, country: country }))
		}

	}, [onboardStepResponse, onboardPageDetails])
	useEffect(() => {
		setIsPreview(false)
	}, [onboardStepPreviewResponse])
	console.log("preview page data onboardStepResponse", onboardStepResponse)
	return (
		<>
			{/* <Modal
				visible={isPreviewOpen?.open}
				onCancel={() => {
					setIsPreviewOpen({
						open: false,
						url: ""
					})
				}}
				footer={null}
				width="80%"
				centered
				className='preview_section_modal'
			>
				<img src={isPreviewOpen?.url} alt="preview image" style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }} />
			</Modal> */}
			<Modal
				visible={isPreviewOpen?.open}
				onCancel={() => {
					setIsPreviewOpen({
						open: false,
						url: "",
					});
				}}
				footer={null}
				width="80%"
				centered
				className="preview_section_modal"
			>
				{
					isPreviewOpen?.fileType == "pdf" ? <div style={{ width: "100%", height: "100vh" }}>
						<iframe src={isPreviewOpen?.url} allow="fullscreen" style={{ width: "100%", height: "100%", margin: "auto" }} title="description"></iframe>
					</div> :
						<img
							src={isPreviewOpen?.url}
							alt="preview image"
							style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
						/>
				}
			</Modal>
			<div className='preview_section'>
				<div className="top-section">
					<KYCHeader Header={"Review your information"} SubHeader={"Take a moment to review your details below before submitting your verification."} />
				</div>

				<div className='preview_container w60'>
					{/* <div className='preview_info'>
                        <div className='info_image'>
                            <img src={KYCSafe} alt='info image' className='w100 h100' />
                        </div>
                        <span className='info_text'>Video KYC completed successfully, please review the information.</span>
                    </div> */}
					{Array.isArray(onboardStepPreviewResponse?.data) &&
						onboardStepPreviewResponse?.data?.map((fieldsElement, fieldsIndex) => (
							// previewData && previewData?.data?.map((fieldsElement, fieldsIndex) => (
							<div className='preview_card mt2' key={fieldsIndex}>
								<div className='header_section d-flex'>
									<h4 className='mb0 mr1'>{fieldsElement?.section_title}</h4>
									{
										onboardStepResponse?.data?.data?.kyc_status !=
											"kyc_approved" ? (
											<div className='preview_edit' onClick={() => {
												dispatch(createOnBoardForm(fieldsElement?.section_order))
												dispatch(onboardStep({ step: fieldsElement?.section_order, country: country }))
												setFormStep(fieldsElement?.section_order)
											}}><EditFilled /></div>
										) : null
									}
								</div>
								<div className='header_line'></div>
								<div className='preview_data'>
									{
										fieldsElement?.fields_list?.map((listElement, listIndex) => {

											if (listElement?.data_type == "date") {
												if (listElement?.entity_column == "year_of_incorporation") {
													return (
														<div className='key_value_div' key={listIndex}>
															<div className='d-flex flex-col' >
																<span className='title'>{listElement?.entity_column_label}</span>
																<span className='value'>{listElement?.value ? listElement?.value : "-"}</span>
															</div>
														</div>
													)
												} else {
													return (
														<div className='key_value_div' key={listIndex}>
															<div className='d-flex flex-col' >
																<span className='title'>{listElement?.entity_column_label}</span>
																<span className='value'>{listElement?.value ? moment(listElement?.value)?.format("DD-MM-YYYY") : "-"}</span>
															</div>
														</div>
													)
												}
											} else if (listElement?.data_type == "dropdown") {
												if (listElement?.entity_column == "business_country") {
													const dropdownValue = allCountries?.find(ele => ele?.country_iso_code == listElement?.value)
													return (
														<div className='key_value_div' key={listIndex}>
															<div className='d-flex flex-col'>
																<span className='title'>{listElement?.entity_column_label}</span>
																<span className='value'>{listElement?.value ? dropdownValue?.country_name : "-"}</span>
															</div>
														</div>
													)
												} else {
													const dropdownValue = listElement?.dropdown_fields?.find(ele => ele?.value == listElement?.value)
													return (
														<div className='key_value_div' key={listIndex}>
															<div className='d-flex flex-col'>
																<span className='title'>{listElement?.entity_column_label}</span>
																<span className='value'>{listElement?.value ? dropdownValue?.label : "-"}</span>
															</div>
														</div>
													)
												}
											} else if (listElement?.data_type == "document") {
												return (
													<div className={`prevew_document_section ${listElement?.data_type == "document" && listIndex > 0 ? "mt1" : ""}`} key={listIndex}>
														<h4 className='file_name'>{listElement?.entity_column_label}</h4>
														<div className='prevew_document_container flex-between mb1'>
															<div className='left_section d-flex'>
																<div className='image_section mr1'>
																	{(() => {
																		if (["pdf"]?.includes(getFileExtension(listElement?.file_properties?.filename)?.toLowerCase())) {
																			return <img src={png_icon} className='w100 h100' />;
																		} else {
																			return <img src={listElement?.value} className='w100 h100' />;
																		}
																	})()}
																</div>
																<div className='image_name justify-center flex-col'>
																	<h4 className='file_name'>{listElement?.file_properties?.filename}</h4>
																	{/* <span className='file_verify'>Verify</span> */}
																</div>
															</div>
															<div className='right_section'>
																<div className='active_section d-flex'>
																	<div className='preview_section' onClick={() => {
																		setIsPreviewOpen(() => ({ open: true, url: listElement?.value, fileType: getFileExtension(listElement?.file_properties?.filename) }))
																	}}>
																		<img src={preview_icon} alt="preview-icon" className='preview_img w100 h100' />
																	</div>
																</div>
															</div>
														</div>
													</div>
												)
											} else {
												return (
													<div className='key_value_div' key={listIndex}>
														<div className='d-flex flex-col'>
															<span className='title'>{listElement?.entity_column_label}</span>
															<span className='value'>{listElement?.value ? listElement?.value : "-"}</span>
														</div>
													</div>
												)
											}
										})
									}
								</div>
							</div>
						))
					}
				</div>
			</div>
		</>
	)
}

export default PreviewPage