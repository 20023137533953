
import React from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import "./../Custom.css";
import { objectToFormData, findLodash } from '../../../../utils';
import { addMerchantsField, getMerchantsField, updateMerchantsField } from '../../../../actions/companySettings';
import { Input, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
//  const { Option } = Select;
const { TextArea } = Input;

const ValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Please provide category name'),
  code: yup.string(),
  description: yup.string(),
});

const AddMerchantsField = (props) => {
  const { t } = useTranslation();
  const { id: currentId,
    // employeesData = [],
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const merchantsFieldResponse = useSelector(state => state.companySetting.getMerchantsField.data?.data || {});
  const addMerchantsFieldResponse = useSelector(state => state.companySetting?.addMerchantsField || {});
  const updateMerchantsFieldResponse = useSelector(state => state.companySetting.updateMerchantsField || {});



  React.useEffect(() => {
    form.resetFields();
    if (merchantsFieldResponse.length && currentId) {
      const editValues = findLodash(merchantsFieldResponse, { id: currentId }) || {};
      form.setFieldsValue({
        name: editValues.name,
        code: editValues.code,
        description: editValues.description,
        is_enable: editValues.is_enable
        // user_id: editValues.user_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  React.useEffect(() => {
    dispatch(
      getMerchantsField({

        type: props.type,
      })
    );
  }, [addMerchantsFieldResponse, updateMerchantsFieldResponse])

  const handleSubmit = (values) => {
    const payload = {};
    [
      'code',
      'name',
      'description',
      'is_enable'
    ].forEach(item => {
      payload[item] = values[item];
    });

    payload.enable = true;

    ValidationSchema.validate(payload)
      .then(function ({ ...value }) {
        form.resetFields();
        // Object.keys(payload).forEach(key => {
        //   if (!payload[key] && key !== "is_enable") {
        //     delete payload[key];
        //   }
        // });
        if (currentId) {
          dispatch(updateMerchantsField(({ ...payload, id: currentId.toString() })))
        } else {

          dispatch(addMerchantsField(({ ...payload })));
        }
      })
      .catch(function (err) {
      });
  }

  const handleCancelButton = () => {
    form.resetFields();
    props.handleCancel();
  }

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        className="modal-form custom-form small-width"
      >
        <Form.Item label={t('merchant_name')} name="name" rules={[{ required: true, message: t('please_enter_name') }, { pattern: /^[a-zA-Z]+[a-zA-Z0-9\s]*$/, message: "Please Enter Proper Name" }]}>
          <Input placeholder={t('enter_merchant_name')} />
        </Form.Item>
        <Form.Item label={t('merchant_code')} name="code">
          <Input placeholder={t('enter_merchant_code')} />
        </Form.Item>
        {/* <Form.Item name="user_id" label="Merchant Head">
              <Select
                  placeholder="Select Merchant Head"
              >
                {employeesData && employeesData.map(({ user_id, name }) => <Option key={user_id} value={user_id}>{name}</Option>)}
              </Select>
          </Form.Item> */}
        <Form.Item label={t('desc')} name="description">
          <TextArea
            placeholder={t('enter_desc')}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>

        <Button key="1" htmlType="submit" className="formButton mt1">{currentId ? t('update') : t('save')}</Button>
        <Button key="2" className="cancelButton ml1 mt1" onClick={() => handleCancelButton()}>{t('cancel')}</Button>
      </Form>
    </>
  );
}

export default AddMerchantsField;

