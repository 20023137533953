import React from 'react';
import { FormInput, FormSelect } from '../inputs';
import usePayAxios from '../../config/useAxios';
import { apis } from '../../config/APIs';
import { useRef } from 'react';
import DateInput from '../inputs/DateInput';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

const PaymentModeFields = ({ control, register, errors, payment_mode, setValue, amount }) => {
  const { onCall } = usePayAxios({ api: apis.getIfscDetails, method: "get" });
  const ifscRef = useRef()
const {t} = useTranslation()

  const dd_type = [{
    label: t('sight_demand_draft'),
    value: "sight_demand_draft",
  },
  {
    label: t('time_demand_draft'),
    value: "time_demand_draft",
  }]

  const card_type = [{
    label: t('credit_Card'),
    value: "credit_card",
  },
  {
    label: t('debit_Card'),
    value: "debit_card",
  }]

  return (
    <>
      {(payment_mode === "Cheque" || payment_mode === "DD") && <>
        <div className="pp-form-item">
          <FormInput
            control={control}
            {...register("payee_name", {
              required: t('payee_name_required')
            })}
            errors={errors}
            required={true}
            label={t('payee_name')}
          />
        </div>
        <Row align={"bottom"} justify={"space-between"} gutter={16}>
          <Col span={12}> <div className="pp-form-item">
            <FormInput
              control={control}
              {...register("ifsc_code", {
                required: t('ifsc_code_required'),
                pattern: {
                  value: /^[A-Z]{4}0[A-Z0-9]{6}$/i,
                  message: "Enter valid ifsc"
                },
                onChange: (e) => {
                  if (ifscRef.current) {
                    clearTimeout(ifscRef.current)
                  }
                  ifscRef.current = setTimeout(() => {
                    onCall({
                      params: {
                        ifsc_code: e.target.value
                      }
                    }).then(res => {
                      if (!res?.error) {
                        setValue(`bank_name`, res?.data?.bank_name)
                      } else {
                        setValue(`bank_name`, '')
                      }
                    }).catch(() => {
                      setValue(`bank_name`, '')
                    })
                  }, 700)
                }
              })}
              errors={errors}
              required={true}
              label={t('ifsc_code')}
            />
          </div></Col>
          <Col span={12}>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("bank_name", {
                  required: t('bank_required'),
                })}
                errors={errors}
                required={true}
                label={t('bank_name')}
              />
            </div>
          </Col>
        </Row>

        <Row align={"bottom"} justify={"space-between"} gutter={16}>
          <Col span={12}>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("bank_account_no", {
                  required: "Bank A/C No. is required.",
                })}
                errors={errors}
                required={true}
                label={t('bank_account_num')}
              />
            </div>
          </Col>
          <Col span={12}>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("receiver_name", {
                  required: t('receiver_required'),
                })}
                errors={errors}
                required={true}
                label={t('receiver_name')}
              />
            </div>
          </Col>
        </Row>

        {payment_mode == "DD" ? <>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register("pan_number", {
                required: amount >= 50000 ? t('pan_number_required') : false,
              })}
              errors={errors}
              required={amount >= 50000}
              label={t('pan_no')}
              // number={true}
            />
          </div>
          <Row align={"bottom"} justify={"space-between"} gutter={16}>
            <Col span={12}>
              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("dd_number", {
                    required: t('dd_number_required'),
                  })}
                  errors={errors}
                  label={t('dd_number')}
                  // number={true}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="pp-form-item">
                <FormSelect
                  control={control}
                  {...register("dd_type", {
                    required: t('dd_type_required'),
                  })}
                  options={dd_type}
                  errors={errors}
                  label={t('dd_type')}
                  hideCreateOption
                />
              </div>
            </Col>
          </Row>
        </> : <> <Row align={"bottom"} justify={"space-between"} gutter={16}>
          <Col span={12}>
            <div className="pp-form-item">
              <DateInput
                control={control}
                {...register("cheque_date", {
                  required: t('cheque_date_required'),
                })}
                errors={errors}
                required={true}
                label={t('cheque_date')}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("cheque_number", {
                  required: t('cheque_no_required'),
                })}
                errors={errors}
                required={true}
                label={t('cheque_no')}
              />
            </div>
          </Col>
        </Row>
        </>}
      </>}
      {payment_mode === "Card" && <>
        <div className="pp-form-item">
          <FormInput
            control={control}
            {...register("card_no", {
              required: t('card_number_required')
            })}
            required={true}
            errors={errors}
            label={t('card_number')}
          />
        </div>
        <Row align={"bottom"} justify={"space-between"} gutter={16}>
          <Col span={12}>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("bank_name", {
                  required: t('bank_name_required')
                })}
                required={true}
                errors={errors}
                label={t('bank_name')}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="pp-form-item">
              <FormSelect
                control={control}
                {...register("card_type", {
                  required: t('card_type_required')
                })}
                errors={errors}
                options={card_type}
                required={true}
                label={t("card_type")}
                hideCreateOption
              />
            </div>
          </Col>
        </Row>
      </>}
      {payment_mode === "Wallet" && <>
        <div className="pp-form-item">
          <FormInput
            control={control}
            {...register("wallet_service_provider", {
              required: t('wallet_service_required')
            })}
            required={true}
            errors={errors}
            label={t('wallet_service')}
          />
        </div>
        <div className="pp-form-item">
          <FormInput
            control={control}
            {...register("mobile_no", {
              required: t('phone_Required')
            })}
            errors={errors}
            number={true}
            required={true}
            label={t('mobile_number')}
          />
        </div>
      </>
      }
      {payment_mode === "UPI" && <>
        <div className="pp-form-item">
          <FormInput
            control={control}
            {...register("upi_id", {
              required: t('upi_id_required'),
            })}
            errors={errors}
            required={true}
            label={t('upi_id')}
          />
        </div>
        <div className="pp-form-item">
          <FormInput
            control={control}
            {...register("transaction_id", {
              required: t('transaction_required')
            })}
            errors={errors}
            required={true}
            label={t('trans_id')}
          />
        </div>
        <Row align={"bottom"} justify={"space-between"} gutter={16}>
          <Col span={12}>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("receiver_name", {
                  required: t('receiver_required'),
                })}
                errors={errors}
                required={true}
                label={t('receiver_name')}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="pp-form-item">
              <DateInput
                control={control}
                {...register("transaction_date", {
                  required: t('transaction_date_required')
                })}
                errors={errors}
                label={t('transaction_date')}
                required={true}
              />
            </div>
          </Col>
        </Row>
      </>}
    </>
  );
};

export default PaymentModeFields;