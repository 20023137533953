import { MinusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { car_columns } from "./TripTables";

const carsValidation = yup.object().shape({
  car_rentals: yup.array().of(
    yup.object().shape({
      duration_from: yup.string().required("Check In is required"),
      duration_to: yup.string().required("Check Out is required"),
      city: yup.string().required("City is required"),
      description: yup.string().required("Description is required"),
      cab_type: yup.string().required("Cab Type is required"),
    })
  ),
});

const CarRentals = ({
  tripForm,
  isEdit,
  isModalVisible,
  setIsModalVisible,
}) => {
  const { car_rentals = [] } = tripForm?.values;
  const carFormik = useFormik({
    initialValues: {
      car_rentals: [
        {
          duration_from: "",
          duration_to: "",
          city: "",
          description: "",
          cab_type: "",
        },
      ],
    },
    validationSchema: carsValidation,
    onSubmit: (vals) => {
      tripForm.setFieldValue("car_rentals", [...vals.car_rentals]);
      handleModalCancelButton();
    },
  });
  const handleModalCancelButton = () => {
    carFormik.setFieldValue("car_rentals", [
      {
        duration_from: "",
        duration_to: "",
        city: "",
        description: "",
        cab_type: "",
      },
    ]);
    setIsModalVisible(false);
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (isModalVisible && car_rentals?.length)
      carFormik.setFieldValue("car_rentals", car_rentals);
    // eslint-disable-next-line
  }, [isModalVisible]);

  const onNewRowChange = (type, idx) => {
    if (type === "remove") {
      if (idx > -1) {
        let trips = carFormik.values.car_rentals;
        trips.splice(idx, 1);
        carFormik.setFieldValue("car_rentals", trips);
      }
    } else {
      let hr = carFormik.values.car_rentals;
      hr = [
        ...hr,
        {
          city: "",
          duration_from: "",
          duration_to: "",
          description: "",
          cab_type: "",
        },
      ];
      carFormik.setFieldValue("car_rentals", hr);
    }
  };

  const renderCarForm = () => (
    <form onSubmit={carFormik.handleSubmit} className='modal-form'>
      <div className='trips-detail detail-card p1'>
        {carFormik.values?.car_rentals.map((item, index) => (
          <Row className='mt1' key={`hotelrows_${index}`}>
            <Col span={5}>
              <Form.Item label={t("city")} required>
                <Input
                  placeholder={t("enter")}
                  name={`city_key${index}`}
                  value={carFormik.values?.car_rentals[index]?.city}
                  onChange={(e) => {
                    let fr = carFormik.values?.car_rentals;
                    fr[index].city = e.target.value;
                    carFormik.setFieldValue("car_rentals", fr);
                  }}
                />
                {carFormik.touched.car_rentals?.[index]?.city &&
                  carFormik.errors.car_rentals?.[index]?.city && (
                    <div className='errorMsg'>
                      {carFormik.errors.car_rentals?.[index]?.city}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={5} className='ml1'>
              <Form.Item label={t("pick_up")} required>
                <DatePicker
                  format={"DD/MM/YYYY HH:mm:ss a"}
                  showTime
                  name={`hot_duration_from_key${index}`}
                  value={
                    carFormik.values?.car_rentals[index]?.duration_from
                      ? moment(
                          carFormik.values?.car_rentals[index]?.duration_from,
                          "DD/MM/YYYY HH:mm:ss"
                        )
                      : undefined
                  }
                  onChange={(date) => {
                    let fr = carFormik.values?.car_rentals;
                    fr[index].duration_from = date
                      ? moment(date).format("DD/MM/YYYY HH:mm:ss")
                      : "";
                    fr[index].duration_to = "";
                    carFormik.setFieldValue("car_rentals", fr);
                  }}
                />
                {carFormik.touched.car_rentals?.[index]?.duration_from &&
                  carFormik.errors.car_rentals?.[index]?.duration_from && (
                    <div className='errorMsg'>
                      {carFormik.errors.car_rentals?.[index]?.duration_from}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={5} className='ml1'>
              <Form.Item label={t("drop_off")} required>
                <DatePicker
                  format={"DD/MM/YYYY HH:mm:ss"}
                  showTime
                  name={`hot_duration_to_key${index}`}
                  onChange={(date) => {
                    let fr = carFormik.values?.car_rentals;
                    fr[index].duration_to = date
                      ? moment(date).format("DD/MM/YYYY HH:mm:ss")
                      : "";
                    carFormik.setFieldValue("car_rentals", fr);
                  }}
                  value={
                    carFormik.values?.car_rentals[index]?.duration_to
                      ? moment(
                          carFormik.values?.car_rentals[index]?.duration_to,
                          "DD/MM/YYYY HH:mm:ss"
                        )
                      : undefined
                  }
                  disabledDate={(current) => {
                    let customDate = moment(
                      carFormik.values?.car_rentals[index]?.duration_from,
                      "DD/MM/YYYY HH:mm:ss"
                    );
                    return current && current < moment(customDate);
                  }}
                />
                {carFormik.touched.car_rentals?.[index]?.duration_to &&
                  carFormik.errors.car_rentals?.[index]?.duration_to && (
                    <div className='errorMsg'>
                      {carFormik.errors.car_rentals?.[index]?.duration_to}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={5} className='ml1'>
              <Form.Item
                label={t("cab_type")}
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                required
              >
                <Select
                  name={`cab_type_key${index}`}
                  value={carFormik.values?.car_rentals?.[index]?.cab_type}
                  onChange={(val) => {
                    let fr = carFormik.values?.car_rentals;
                    fr[index].cab_type = val;
                    carFormik.setFieldValue("car_rentals", fr);
                  }}
                  style={{
                    width: "12rem",
                  }}
                >
                  <Select.Option value={"Ola"}>{t("ola")}</Select.Option>
                  <Select.Option value={"Uber"}>{t("uber")}</Select.Option>
                  <Select.Option value={"Rapido"}>{t("rapido")}</Select.Option>
                  <Select.Option value={"Public transport"}>
                    {t("public_transport")}
                  </Select.Option>
                  <Select.Option value={"Private transport"}>
                    {t("private_transport")}
                  </Select.Option>
                  <Select.Option value={"Others"}>{t("others")}</Select.Option>
                </Select>
                {carFormik.touched.car_rentals?.[index]?.cab_type &&
                  carFormik.errors.car_rentals?.[index]?.cab_type && (
                    <div className='errorMsg'>
                      {carFormik.errors.car_rentals?.[index]?.cab_type}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label={t("desc")} required>
                <Input.TextArea
                  placeholder={t("enter_desc")}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  maxLength={250}
                  name={`hot_description_key${index}`}
                  value={carFormik.values?.car_rentals?.[index]?.description}
                  onChange={(e) => {
                    let fr = carFormik.values?.car_rentals;
                    fr[index].description = e.target.value;
                    carFormik.setFieldValue("car_rentals", fr);
                  }}
                />
                {carFormik.touched.car_rentals?.[index]?.description &&
                  carFormik.errors.car_rentals?.[index]?.description && (
                    <div className='errorMsg'>
                      {carFormik.errors.car_rentals?.[index]?.description}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={1}>
              {index >= 1 ? (
                <MinusCircleOutlined
                  className='mt3'
                  onClick={() => onNewRowChange("remove", index)}
                />
              ) : null}
            </Col>
          </Row>
        ))}
        {car_rentals.length <= 5 ? (
          <>
            <hr />
            <div
              className='p1 cursor type-link-button'
              onClick={() => onNewRowChange("add")}
            >
              {" "}
              + {t("another_line")}
            </div>
          </>
        ) : null}
      </div>

      <div className='mb3 mt1'>
        <Button
          key='2'
          className='cancelButton ml1 mt3 floatRight cancel-button'
          onClick={() => handleModalCancelButton()}
        >
          {t("cancel")}
        </Button>
        <Button
          key='1'
          htmlType='submit'
          className='formButton mt3 primaryButton floatRight save-button'
        >
          {t("save")}
        </Button>
      </div>
    </form>
  );
  return (
    <>
      <Modal
        title={t("car_rental_details")}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        footer={[]}
        className='center-aligned-modal-big'
      >
        {renderCarForm()}
      </Modal>
      <div className='trips-detail detail-card mb2'>
        <div className='mt1'>
          <Button
            className='primary-btn floatRight mr1'
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            {car_rentals?.length ? t("edit") : t("add_car_details")}
          </Button>
        </div>
        <Table
          rowKey='id'
          columns={car_columns}
          dataSource={car_rentals?.length ? car_rentals : []}
          className='settingsTable tripsTable'
          pagination={false}
        />
      </div>
    </>
  );
};

export default CarRentals;
