import { Col, Divider, Row, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { menuPaids, optionsBills } from '../Common';
import PageHeader from '../PageHeader';
import BillHeader from './BillHeader';
import ViewBillDetails from './ViewBillDetails';
import { useTranslation } from 'react-i18next';

const ViewBill = () => {
  const history = useHistory();
const {t} = useTranslation();
  const docView = useRef();

  const items = [
    {
      key: '1',
      label: t('details'),
      children: <ViewBillDetails />,
    },
    {
      key: '2',
      label: t('history'),
      children: `Content of Tab Pane 2`,
    },
    {
      key: '3',
      label: t('comments'),
      children: `Content of Tab Pane 3`,
    },
  ];
  // /purchases/newbill
  useEffect(() => {
    if (docView.current) {
    }
  }, [docView.current]);

  return (
    <>
      <Row wrap={false} style={{ flexGrow: 1, display: 'flex'}}>
        <Col flex="auto" style={{  display: 'flex', flexGrow: '1' }}>
          <div style={{ flexGrow: 1 }}>
            <div style={{ paddingLeft: '13px' }}>
              <PageHeader
                title={`Bills`}
                description='bill_document'
              />
            </div>

            <div style={{ flexGrow: 1, paddingTop: '22px' }}>
              <div
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  border: '1px solid #DBDBDB',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                  flexDirection: 'column',
                }}
              >
                <div style={{ flexGrow: 1, padding: '45px 60px 33px 75px' }}>
                  <BillHeader />
                </div>
                <Divider style={{ margin: '0px !important' }} />
                <section style={{ padding: '60px 39px', display: 'flex', flexGrow: 1, direction: 'row' }}>
                  <Row gutter={[32]}>
                    <Col span={14}>
                      <section
                        style={{
                          border: '1px solid #DBDBDB',
                          backgroundColor: '#F7F7F7',
                          padding: '4rem 2rem',
                          borderRadius: '9px',
                        }}
                      >
                        <img
                          src="https://files.jotform.com/jotformapps/utility-bill-template-6de9446af4f1724a198ee76f1ad6e470_og.png"
                          style={{ width: '100%' }}
                        />
                      </section>
                    </Col>
                    <Col span={10}>
                      <section>
                        <Tabs defaultActiveKey="1" items={items} className="pp-tabs" />
                      </section>
                    </Col>
                  </Row>
                </section>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ViewBill;
