import { ActionTypesFactory } from '../../utils';

export const GetPageDetails = ActionTypesFactory("GetPageDetails", "GetPageDetails");
export const GetSummary = ActionTypesFactory("GetSummary", "GetSummary")
export const ImportApi = ActionTypesFactory("ImportApi", "ImportApi")


export const getPageDetails = (payload) => {
    return {
        type: GetPageDetails.REQUEST,
        payload,
    }
}

export const getSummary = (payload) => {
    return {
        type: GetSummary.REQUEST,
        payload,
    }
}

export const importApi = (payload) => {
    return {
        type: ImportApi.REQUEST,
        payload,
    }
}