import { t } from "i18next";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTdransferLogs, quick_logs } from "../../actions/cards/cards";
import { headerTitles } from "../../actions/masterdata/masterdata";
import AddFile from "../../assets/icons/add-file.png";
import DelFile from "../../assets/icons/delete-file.png";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import Table from "../Common/Table/Table";

const statuses = {
  success: "Completed",
  failed: "Failed",
  pending: "In Progress",
};

const statuses_classes = {
  success: "",
  failed: "rejected",
  pending: "pending_approval",
};

const TransferLog = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const [isQuick, setIsQuick] = useState(false);
  const tranferlogs = useSelector(
    (state) => state?.cards?.transferLogs?.data?.response
  );
  const quicklogs = useSelector(
    (state) => state?.cards?.quick_logs?.data?.response
  );


  const quick_columns = [
    {
      title: t('transaction_date'),
      dataIndex: "Added Date",
      key: "Added Date",
      render: (text, data) => {
        return <div className={`status-line`}>{text}</div>;
      },
    },
    {
      title: t('card_ref_no'),
      dataIndex: "Card Reference No",
      key: "Card Reference No",
    },
    {
      title: t('wallet_name'),
      dataIndex: "Wallet Name",
      key: "Wallet Name",
      // render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    },
    {
      title: t('loaded_amount'),
      dataIndex: "Total Amount of load",
      key: "Total Amount of load",
      render: (text) => <div><BaseCurrency /> {amountFormat(text)}</div>,
    },
    {
      title: t('status'),
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <div className={`status status_${statuses_classes[text]}`}>
          {statuses[text]}
        </div>
      ),
    },
    {
      title: t('remarks'),
      dataIndex: "Remarks",
      key: "Remarks"
    },
    // {
    //   title: "Action",
    //   dataIndex: "title",
    //   key: "title",
    //   render: (text, record) => (
    //     <>
    //       {record["Success File URL"] && (
    //         <a href={record["Success File URL"]} className="mr1" download>
    //           <img src={AddFile} alt={"success_doc"} width={18} />
    //         </a>
    //       )}
    //       {record["Error File URL"] && (
    //         <a href={record["Error File URL"]} download>
    //           <img src={DelFile} alt={"fail_doc"} width={18} />
    //         </a>
    //       )}
    //     </>
    //   ),
    // },
  ];

  const columns = [
    {
      title: t('added_date'),
      dataIndex: "Added Date",
      key: "Added Date",
      render: (text, data) => {
        return <div className={`status-line`}>{text}</div>;
      },
    },
    {
      title: t('no_of_cards'),
      dataIndex: "Total no of cards",
      key: "Total no of cards",
    },
    {
      title: t('loaded_amount'),
      dataIndex: "Total Amount of load",
      key: "Total Amount of load",
      render: (text) => <div><BaseCurrency />{amountFormat(text)}</div>,
    },
    {
      title: t('remarks'),
      dataIndex: "Remarks",
      key: "Remarks",
      // render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    },
    {
      title: t('status'),
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <div className={`status status_${statuses_classes[text]}`}>
          {statuses[text]}
        </div>
      ),
    },
    {
      title: t('action'),
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <>
          {record["Success File URL"] && (
            <a href={record["Success File URL"]} className="mr1" download>
              <img src={AddFile} alt={"success_doc"} width={18} />
            </a>
          )}
          {record["Error File URL"] && (
            <a href={record["Error File URL"]} download>
              <img src={DelFile} alt={"fail_doc"} width={18} />
            </a>
          )}
        </>
      ),
    },
  ];

  React.useEffect(() => {
    dispatch(getTdransferLogs());
    dispatch(headerTitles({
      title: 'transfer_log',
      description: ""
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        rowKey="id"
        columns={isQuick ? quick_columns : columns}
        data={isQuick ? quicklogs : tranferlogs}
        isTopFilter={true}
        scroll={{ x: 0 }}
        filter={true}
        isSearch={true}
        isMore={true}
        pagination={{
          total: isQuick ? quicklogs?.length : tranferlogs?.length,
          showSizeChanger: false
        }}
        btnsComponent={
          <>
            <button
              disabled={!hasWriteAccess}
              className={`mr1 ${!isQuick ? "primary-btn" : "nm-btn"}`}
              onClick={() => {
                setIsQuick(false);
                dispatch(getTdransferLogs());
              }}
            >
              {t('bulk_transfer')}
            </button>
            <button
              disabled={!hasWriteAccess}
              className={isQuick ? "primary-btn" : "nm-btn"}
              onClick={() => {
                setIsQuick(true);
                dispatch(quick_logs());
              }}
            >
              {t('quick_load')}
            </button>
          </>
        }
      />
    </>
  );
};

export default TransferLog;
