import { ActionTypesFactory } from '../../utils';

export const OcrDocuments = ActionTypesFactory('OCR', 'OcrDocuments');
export const OcrDocumentDelete = ActionTypesFactory('OCR', 'OcrDocumentDelete');

export const ocrDocuments = (payload) => {
  return {
    type: OcrDocuments.REQUEST,
    payload,
  };
};


export const ocrDocumentDel = (payload) => {
  return {
    type: OcrDocumentDelete.REQUEST,
    payload,
  };
};
