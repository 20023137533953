import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { Affirmative } from "../../assets/icons/loans";
import {
  BusinessLoanImg,
  InvoiceDiscountImg,
  LoanAgainstPropertyImg,
  MyLoanImg,
} from "../../assets/images/loans";
import "./Loan.css";
import { useTranslation } from "react-i18next";

const ApplyLoan = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      headerTitles({
        title: t("apply_loan"),
        description: "",
      })
    );
  }, []);

  const CardDetailsJson = [
    {
      cardTitle: t("business_loan"),
      cardDescription: t("fuel_your_busin"),
      img: (
        <img
          src={BusinessLoanImg}
          alt='Business Loan Image'
          className='card-image'
        />
      ),
      pointsArray: [
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("avail_a_loan"),
        },
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("flexible_repay"),
        },
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("check_your_busine"),
        },
      ],
    },
    {
      cardTitle: t("work_cap_loan"),
      cardDescription: t("keep_your_business"),
      img: <img src={MyLoanImg} alt='My Loan Image' className='card-image' />,
      pointsArray: [
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("enjoy_credit"),
        },
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("access_pre"),
        },
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("get_loans_against"),
        },
      ],
    },
    {
      cardTitle: t("invoice_discountind"),
      cardDescription: t("drive_your_business"),
      img: (
        <img
          src={InvoiceDiscountImg}
          alt='Invoice Account Image'
          className='card-image'
        />
      ),
      pointsArray: [
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("get_up_loans"),
        },
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("access_up"),
        },
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("pay_at_your"),
        },
      ],
    },
    {
      cardTitle: t("loan_against"),
      cardDescription: t("get_instant"),
      img: (
        <img
          src={LoanAgainstPropertyImg}
          alt='Loan Against Property Image'
          className='card-image'
        />
      ),
      pointsArray: [
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("get_loans_up"),
        },
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("enjoy_longer"),
        },
        {
          pointsImage: <img src={Affirmative} alt='Affirmative' />,
          pointsDescription: t("both_residential"),
        },
      ],
    },
  ];
  return (
    <section className='d-flex flex-col'>
      <div className='tabsSection'>
        <span className='active mt1'>{t("business_loan")}</span>
      </div>
      <div className='mt2 applyLoanCards-container'>
        {CardDetailsJson?.map((card) => {
          return (
            <div className='card d-flex flex-col'>
              <div className='card-header'>
                <span>{card.cardTitle}</span>
                <p className='card-description'>{card.cardDescription}</p>
              </div>
              <div className='card-with-image-points'>
                <span>{card.img}</span>

                <div>
                  {card.pointsArray?.map((point) => (
                    <div className='d-flex flex-col'>
                      <div className='card-points d-flex gap1'>
                        <div className='icon'>{point.pointsImage}</div>
                        <p className='card-points-description'>
                          {point.pointsDescription}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='button-container mt1'>
                <button>{t("apply_online")}</button>
                <button className='outlineButton'>{t("know_more")}</button>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ApplyLoan;
