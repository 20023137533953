import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { PulseLoader } from "react-spinners";
import {
  getSectionPageFields,
  getTotalSections,
  incrementIfNumber,
} from "../../../../utils";
import {
  OnboardCreate,
  OnboardStep,
  createOnBoardForm,
  getOnboardFields,
  onboardCreate,
  onboardFinalSubmit,
  onboardStep,
  OnboardFinalSubmit,
  onboardStepPreview
} from "../../action/OnBoardForm";
import KYCSafe from "../../assets/icons/kyc-safe-icon.svg";
import BusinessKYCFields from "./BusinessKYCFields";
import SuccessPage from "./SuccessPage";
import PreviewPage from "./PreviewPage";
import moment from "moment";
import { getAllCountries, getAllCurrency } from "../../../../actions/companySettings";

const BusinessMainKYC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [fieldRenderData, setFieldRenderData] = useState("");
  const [formStep, setFormStep] = useState();
  const [fileData, setFileData] = useState({});
  const [isPreview, setIsPreview] = useState(false)
  const onboardPageDetails = useSelector(
    (state) => state?.on_board?.getOnboardFields
  );
  const onboardStepResponse = useSelector(
    (state) => state?.on_board?.onboardStep
  );
  const onboardFinalResponse = useSelector(
    (state) => state?.on_board?.onboardFinalSubmit?.data
  );
  const onboardCreateResponse = useSelector(
    (state) => state?.on_board?.onboardCreate
  );
  const allCountries = useSelector((state) => state?.companySetting?.getAllCountries?.data?.data?.map((item, index) => ({
    ...item,
    value: item?.country_iso_code,
    label: <span style={{
      color: "#212121",
      fontSize: 15
    }}>
      <img src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_iso_code?.toLowerCase()}.svg`} className='mr1' width={25} style={{
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)"
      }} />
      {`${item?.country_name} (${item?.country_iso_code})`}
    </span>
  })))
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {},
    shouldUseNativeValidation: false,
  });
  useEffect(() => {
    // dispatch(onboardStep({ country: location?.state?.country }));
    dispatch(onboardStep({ step: 0, country: location?.state?.country }))
    dispatch(
      getOnboardFields({
        country: location?.state?.country,
      })
    );
    dispatch(getAllCountries());
    return () => {
      dispatch({ type: OnboardStep.RESET });
      setFormStep();
    };
  }, []);
  useEffect(() => {
    if (
      !formStep &&
      formStep != 0 &&
      onboardStepResponse?.data?.error == false && onboardStepResponse?.data?.data?.fields
    ) {
      setFormStep(incrementIfNumber(onboardStepResponse?.data?.data?.step));
    }
  }, [onboardStepResponse])

  useEffect(() => {
    if (onboardFinalResponse?.error == false) {
      dispatch(onboardStep({ country: location?.state?.country }));
      message.success(
        <span className="messageText">{onboardFinalResponse?.message}</span>
      );
    }

    return () => {
      dispatch({
        type: OnboardFinalSubmit.RESET
      })
    }
  }, [onboardFinalResponse])
  useEffect(() => {
    if (onboardCreateResponse?.data?.error == false) {
      reset({});
      if (isPreview ||
        (formStep &&
          formStep == getTotalSections(onboardPageDetails?.data?.data))
      ) {
        dispatch(
          onboardStepPreview({ preview: true, country: location?.state?.country })
        );
        setFormStep(parseInt(onboardStepResponse?.data?.data?.fields?.[0]?.step))
        dispatch(onboardStep({ country: location?.state?.country }));
        // dispatch(onboardStep({ step: formStep + 1, country: location?.state?.country }));
      }
      // else if (
      //   (onboardStepResponse?.data?.data?.fields?.[0]?.step &&
      //     onboardStepResponse?.data?.data?.fields?.[0]?.step > formStep) ||
      //   (onboardStepResponse?.data?.data?.step &&
      //     onboardStepResponse?.data?.data?.step > formStep)
      // ) {
      //   dispatch(onboardStep({ step: formStep + 1, country: location?.state?.country }));
      else {
        dispatch(onboardStep({ step: formStep + 1, country: location?.state?.country }));
      }
      // dispatch(onboardStep({ step: formStep + 1, country: location?.state?.country }));
      setFieldRenderData("");
      dispatch({ type: OnboardCreate.RESET });
      dispatch(createOnBoardForm(formStep + 1));
      setFormStep((preValue) => preValue + 1);
      setFileData({});
      reset({});
    } else if (onboardCreateResponse?.data?.error == true) {
      message.error(
        <span className="messageText">
          {onboardCreateResponse?.data?.message}
        </span>
      );
      dispatch({ type: OnboardCreate.RESET });
    }
  }, [onboardCreateResponse]);
  useEffect(() => {
    if (onboardPageDetails?.data?.error == false) {
      // setFieldRenderData(getSectionPageFields(BusinessKYC, `${formStep}`))
      setFieldRenderData(
        getSectionPageFields(onboardPageDetails?.data?.data, `${formStep}`)
      );
      dispatch(createOnBoardForm(formStep));
    }
    setFileData({})
  }, [formStep, onboardPageDetails]);
  const handleAddMore = (fileListValue) => {
    const fileList = fileListValue?.[0]?.fields_list;
    if (fileList?.length > 0) {
      setFieldRenderData((prevData) => {
        const newData = JSON.parse(JSON.stringify(prevData));
        newData?.section_page_fields?.fields?.push({
          fields_list: fileList,
        });
        return newData;
      });
    }
  };
  function getObjectByFieldNameAndValue(obj, targetKey, targetValue) {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        const targetObject = obj[key].find(
          (item) => item[targetKey] === targetValue
        );

        if (targetObject) {
          return targetObject;
        }
      }
    }

    return null;
  }
  useEffect(() => {
    if (
      onboardStepResponse?.data?.error == false &&
      Object.keys(onboardStepResponse?.data?.data)?.includes("fields")
    ) {
      onboardStepResponse?.data?.data?.fields?.forEach(
        (fieldsElement, fieldsIndex) => {
          if (onboardStepResponse?.data?.data?.fields?.length > 1) {
            onboardStepResponse?.data?.data?.is_multiple &&
              fieldsIndex > 0 &&
              fieldRenderData?.section_page_fields?.fields?.length <=
              fieldsIndex &&
              handleAddMore(fieldRenderData?.section_page_fields?.fields);
            fieldsElement?.fields_list?.forEach((listElement, listIndex) => {
              if (listElement?.data_type === "date") {
                setValue(
                  `${onboardStepResponse?.data?.data?.section_entity_column}.${fieldsIndex}.${listElement?.entity_column}`,
                  listElement?.value ? moment(listElement?.value) : null
                );
              } else if (listElement?.data_type === "document") {
                if (
                  !getObjectByFieldNameAndValue(
                    fileData,
                    "attachment_id",
                    listElement?.file_properties?.attachment_id
                  )?.attachment_id
                ) {
                  setFileData((prevData) => {
                    const newData = { ...prevData };
                    const columnName =
                      onboardStepResponse?.data?.data?.section_entity_column;
                    const currentArray = newData[columnName] || [];
                    currentArray.push({
                      fieldName: listElement?.entity_column,
                      attachment_id:
                        listElement?.file_properties?.attachment_id,
                      uuid: listElement?.file_properties?.file_id,
                      url: listElement?.value,
                      uploadFileName: listElement?.file_properties?.filename,
                    });
                    newData[columnName] = currentArray;
                    return newData;
                  });
                  setValue(
                    `${onboardStepResponse?.data?.data?.section_entity_column}.${fieldsIndex}.${listElement?.entity_column}`,
                    listElement?.file_properties?.attachment_id
                  );
                }
              } else {
                setValue(
                  `${onboardStepResponse?.data?.data?.section_entity_column}.${fieldsIndex}.${listElement?.entity_column}`,
                  listElement?.value
                );
              }
            });
          } else {
            fieldsElement?.fields_list?.forEach((listElement, listIndex) => {
              if (listElement?.data_type === "date") {
                setValue(
                  `${onboardStepResponse?.data?.data?.section_entity_column}.${fieldsIndex}.${listElement?.entity_column}`,
                  listElement?.value ? moment(listElement?.value) : null
                );
              } else if (listElement?.data_type === "document") {
                if (
                  !getObjectByFieldNameAndValue(
                    fileData,
                    "attachment_id",
                    listElement?.file_properties?.attachment_id
                  )?.attachment_id
                ) {
                  setFileData((prevData) => {
                    const newData = { ...prevData };
                    const columnName =
                      onboardStepResponse?.data?.data?.section_entity_column;
                    const currentArray = newData[columnName] || [];
                    currentArray.push({
                      fieldName: listElement?.entity_column,
                      attachment_id:
                        listElement?.file_properties?.attachment_id,
                      uuid: listElement?.file_properties?.file_id,
                      url: listElement?.value,
                      uploadFileName: listElement?.file_properties?.filename,
                    });
                    newData[columnName] = currentArray;
                    return newData;
                  });
                }
                setValue(
                  `${onboardStepResponse?.data?.data?.section_entity_column}.${fieldsIndex}.${listElement?.entity_column}`,
                  listElement?.file_properties?.attachment_id
                );
              } else {
                setValue(
                  `${onboardStepResponse?.data?.data?.section_entity_column}.${fieldsIndex}.${listElement?.entity_column}`,
                  listElement?.value
                );
              }
            });
          }
        }
      );
    }
  }, [formStep, onboardStepResponse]);
  const handleDecrementStep = () => {
    if (
      incrementIfNumber(onboardStepResponse?.data?.data?.step) <= 0 &&
      formStep == 0
    ) {
      setFormStep(0);
    } else {
      dispatch(createOnBoardForm(formStep - 1));
      dispatch(
        onboardStep({ step: formStep - 1, country: location?.state?.country })
      );
      setFormStep((preValue) => preValue - 1);
      setFileData({});
    }
  };
  const onSubmit = (data, event) => {
    if (event.nativeEvent?.submitter?.id === "preview") {
      setIsPreview(true)
    }
    let arr = data[fieldRenderData?.section_entity_column]?.map((obj) =>
      Object.fromEntries(
        Object.entries(obj).filter(
          ([key, value]) => value !== null && value !== ""
        )
      )
    );
    if (
      arr?.length <= 0 ||
      Object.keys(data)?.length <= 0 ||
      !fieldRenderData
    ) {
      dispatch(
        onboardFinalSubmit({
          id: onboardStepResponse?.data?.data?.id,
          params: { country: location?.state?.country },
        })
      );
    } else {
      data = {};
      if (!fieldRenderData?.section_page_fields?.is_multiple) {
        data = arr?.[0];
      } else {
        data[fieldRenderData?.section_entity_column] = arr?.filter(items => Object.keys(items)?.length);
      }
      if (
        (onboardStepResponse?.data?.data?.fields?.[0]?.step &&
          onboardStepResponse?.data?.data?.fields?.[0]?.step >= formStep) ||
        onboardStepResponse?.data?.data?.step >= formStep
      ) {
        data.edit_step = fieldRenderData?.section_order;
      } else {
        data.step = fieldRenderData?.section_order;
      }
      if (data.year_of_incorporation) {
        data.year_of_incorporation = moment(data.year_of_incorporation).format(
          "YYYY"
        );
      }
      dispatch(
        onboardCreate({
          ...data,
          params: { country: location?.state?.country },
        })
      );
    }
  };
  console.log("business main kyc fieldRenderData", fieldRenderData)
  return (
    <>
      {(onboardPageDetails?.loading || onboardCreateResponse?.loading) && (
        <div className="text-center loadingDiv">
          <PulseLoader color="#fff" />
        </div>
      )}
      <div className="business-main-section">
        <div className="business-main-container">
          <div className="form-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-section">
                {onboardStepResponse?.data?.data?.kyc_status ==
                  "kyc_submitted" ? (
                  <SuccessPage />
                ) : fieldRenderData ? (
                  <BusinessKYCFields
                    fieldObject={fieldRenderData}
                    control={control}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    setFieldRenderData={setFieldRenderData}
                    fieldRenderData={fieldRenderData}
                    watch={watch}
                    setFileData={setFileData}
                    fileData={fileData}
                    clearErrors={clearErrors}
                    allCountries={allCountries}
                  />
                ) : (
                  <PreviewPage
                    setFormStep={setFormStep}
                    country={location?.state?.country}
                    formStep={formStep}
                    setIsPreview={setIsPreview}
                    allCountries={allCountries}
                  />
                )}
                {fieldRenderData?.section_page_fields?.is_multiple ? (
                  <div className="add-more-div mt1">
                    <button
                      type="button"
                      className="add-more-button"
                      onClick={() => {
                        handleAddMore(
                          fieldRenderData?.section_page_fields?.fields
                        );
                      }}
                    >
                      + Add another director
                    </button>
                  </div>
                ) : null}
                {onboardStepResponse?.data?.data?.kyc_status ==
                  "kyc_submitted" || onboardStepResponse?.data?.data?.kyc_status ==
                  "kyc_approved" ? null : fieldRenderData ? (
                    <div className="form-button mt5 flex-between">
                      <div className="button-div">
                        {formStep > 0 ? (
                          <Button
                            className="pp-secondary-button"
                            onClick={() => {
                              handleDecrementStep();
                            }}
                            style={{ marginRight: "2rem" }}
                          >
                            <span>Back</span>
                          </Button>) : (null)}
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="pp-main-button "
                          id="save"
                          disabled={onboardStepResponse?.data?.kyc_status == "kyc_approved"}
                        >
                          <span>Save & Next</span>
                        </Button>
                        {
                          getTotalSections(onboardPageDetails?.data?.data) == incrementIfNumber(onboardStepResponse?.data?.data?.step ?? onboardStepResponse?.data?.data?.fields?.[0]?.step) ? <Button
                            type="primary"
                            htmlType="submit"
                            className="pp-main-button "
                            id="preview"
                            disabled={onboardStepResponse?.data?.kyc_status == "kyc_approved"}
                            style={{ marginLeft: "2rem" }}
                          >
                            <span>Save & Preview</span>
                          </Button> : null
                        }
                      </div>
                      <div className="text-div">
                        <img src={KYCSafe} alt="kyc-safe-icon" />
                        <span className="text fs-20 fw-M" >We take data seriously and your info is safe with us</span>
                      </div>
                    </div>
                  ) : (
                  <div className="form-button mt5 flex-between">
                    <div className="button-div">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="pp-main-button "
                        id="save"
                        disabled={onboardStepResponse?.data?.kyc_status == "kyc_approved"}
                      >
                        <span>{getTotalSections(onboardPageDetails?.data?.data) == incrementIfNumber(onboardStepResponse?.data?.data?.step ?? onboardStepResponse?.data?.data?.fields?.[0]?.step) ? "Save & Submit" : "Save & Next"}</span>
                      </Button>
                    </div>
                    <div className="text-div">
                      <img src={KYCSafe} alt="kyc-safe-icon" />
                      <span className="text fs-20 fw-M" >We take data seriously and your info is safe with us</span>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default BusinessMainKYC;
