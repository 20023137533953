import { Button, Checkbox, Modal, Radio, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { CloseCircleOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import "../Currencies/currencies.css";
import "./RootFi.css";


const ConfigureModal = ({ open, onCancel }) => {
    const dispatch = useDispatch();
    const { Title } = Typography;
    const [dataModelVisible, setDataModelVisible] = useState(false);
    const [forgoCheckedList, setForgoCheckedList] = useState([]);


    const handleClose = () => {
        onCancel();
    }

    const radioData = [
        { label: 'Accounts', value: 'ACCOUNTS' },
        { label: 'Bills', value: 'BILLS' },
        { label: 'Expenses', value: 'EXPENSES' },
    ];

    const forgoLabels = [{ label: 'First Name', value: 'first_name' }]
    const zohoLabels = [
        { label: 'First Name', value: 'first_name' },
        { label: 'Middle Name', value: 'middle_name' },
        { label: 'Last Name', value: 'last_name' },
    ]

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
    };
    const onForgoCheckboxChange = (list) => {
        setForgoCheckedList(list);
    };

    return (
        <>
            <div className='add-currency-container'>
                <Modal
                    title={
                        <Title level={4}>{t('configure')}</Title>
                    }
                    open={open}
                    onCancel={() => handleClose()}
                    footer={[]}
                    className="right-alinged-modal add-currency-modal"
                    width={600}
                    closeIcon={<CloseCircleOutlined style={{ fontSize: '1.5rem' }} />}
                >
                    <div className='p2 gap1' >
                        <Title level={5}>What do you want to configure ?</Title>
                        <div className='user-info p2 mt2'>
                            <p>Data Models and Field Types</p>
                            <button className="primaryButton pl1 pr1 w-5 mt1"
                                style={{ padding: '0.5rem 1.5rem' }}
                                onClick={() => setDataModelVisible(true)} >
                                <span>{t('update')}</span>
                            </button>
                        </div>
                        <div className='user-info p2 mt2'>
                            <p>Sync Time</p>
                            <button className="primaryButton pl1 pr1 w-5 mt1" style={{ padding: '0.5rem 1.5rem' }}>
                                <span>{t('update')}</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>

            <div className='add-currency-container'>
                <Modal
                    title={
                        <Title level={4}>Data Models & Field Types</Title>
                    }
                    open={dataModelVisible}
                    onCancel={() => setDataModelVisible(false)}
                    footer={[
                        <Button type='primary'>
                            Sync
                        </Button>,
                        <Button type='primary'>
                            Cancel
                        </Button>
                    ]}
                    className="right-alinged-modal add-currency-modal data-model"
                    width={600}
                    closeIcon={<CloseCircleOutlined style={{ fontSize: '1.5rem' }} />}
                >
                    <div className='d-flex flex-col'>
                        <div className='p3'>
                            <Title level={5}>Granting Bluerack to access Zoho’s data</Title>
                            <p>Please select data models you want to sync</p>
                            <Radio.Group onChange={onChange}>
                                <Space direction="vertical">
                                    {radioData.map(item => (
                                        <Radio value={item?.value}>
                                            <span style={{ color: 'black' }}>{item?.label}</span>
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>

                        </div>
                        <div style={{ background: '#E4E4E4', textAlign: 'center' }} className='p1'>
                            Please map the respective Fields between Bluerack and Zoho
                        </div>
                        <div className='d-flex p2' style={{ justifyContent: 'space-around', alignItems: 'flex-start' }}>
                            <div className='d-flex flex-col'>
                                <h5>Bluerack</h5>
                                <Checkbox.Group value={forgoCheckedList} onChange={onForgoCheckboxChange} className='d-flex flex-col' style={{ gap: '1rem' }}>
                                    {forgoLabels.map((label) => (
                                        <div>
                                            <Checkbox key={label.value} value={label.value} style={{ marginRight: '0.7rem' }} />
                                            <label style={{ color: 'black' }}>{label?.label}</label>
                                        </div>
                                    ))}
                                </Checkbox.Group>
                            </div>
                            <div className='d-flex flex-col'>
                                <h5>Zoho</h5>
                                <Checkbox.Group value={forgoCheckedList} onChange={onForgoCheckboxChange} className='d-flex flex-col' style={{ gap: '1rem' }}>
                                    {zohoLabels.map((label) => (
                                        <div>
                                            <Checkbox key={label.value} value={label.value} style={{ marginRight: '0.7rem' }} />
                                            <label style={{ color: 'black' }}>{label?.label}</label>
                                        </div>
                                    ))}
                                </Checkbox.Group>
                            </div>
                        </div>
                        <div className='datamodels-warning d-flex gap1' style={{alignItems:'baseline'}}>
                        <InfoCircleOutlined color='black' style={{marginTop:'0.2rem'}}/>
                            <p>Please make sure all the data fields are mapped correctly.
                                If yes Click on Sync or else Click on Back
                            </p>
                        </div>
                        <div className='p2'
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                // position: 'absolute',
                                // bottom: '0'
                            }}>
                            <Button
                                type="primary"
                                className="pp-main-button"
                            // onClick={handleSyncData}
                            // disabled={syncDataResponse?.loading}
                            >
                                <span>Sync</span>
                            </Button>
                            <Button
                                className="pp-secondary-button"
                                style={{ marginLeft: "2rem" }}
                                onClick={() => setDataModelVisible(false)}
                            >
                                <span>{t('cancel')}</span>
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default ConfigureModal;