
import React, { useState } from 'react';
import * as yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import "./../userControl.css";
import { objectToFormData } from '../../../../utils';
import {
  Input, Form,
  Button
} from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { addDesignations, getSingleDesignations,updateDesignations } from '../../../../actions/companySettings';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';


const ValidationSchema = yup.object().shape({
  title: yup
    .string()
    .required('Please provide department name'),
  grades_attributes: yup.array().of(yup.object().shape({
    // id: yup.string().required(),
    level: yup.string().required()
  })).required().min(1)
});

const AddGrades = (props) => {
  const { t } = useTranslation()
  const { id: currentId = '' } = props;
  const [grades,setGrades] = useState([])
  const formik = useFormik({
    initialValues: {
      title: "",
      grades_attributes: [{ id: '', level: '' }]
    },
    validationSchema: ValidationSchema,
    onSubmit: (payload) => {
      const filteredArray = payload.grades_attributes.map(({ id, ...rest }) => id.length > 1 ? ({ id, ...rest }) : ({ ...rest }));

      currentId ?
        dispatch(updateDesignations({ ...payload, id: currentId.toString(), grades_attributes: filteredArray })) :
        dispatch(addDesignations(({ ...payload, grades_attributes: filteredArray })));
    }
  })

  const dispatch = useDispatch();
  const getSingleDesignationsResponse = useSelector(state => state.companySetting.getSingleDesignations || {});

  React.useEffect(() => {
    if(props.id){
      
      dispatch(getSingleDesignations({ id: currentId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  React.useEffect(() => {

    if (getSingleDesignationsResponse.success && currentId) {
      const { data: { data: [{title = '',grades=[]}] = {} } = {} } = getSingleDesignationsResponse;
      
      formik.setValues({
        title: title,
        grades_attributes: grades
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSingleDesignationsResponse]);

  const handleCancelButton = () => {
    formik.resetForm();
    props.handleCancel();
  }

  const handleLevels = (type, key) => {
    if (type === 'add') {
      formik.setFieldValue("grades_attributes", [...formik.values.grades_attributes, { id: formik.values.grades_attributes.length + 1, level: '' }])
    } 
    else if(currentId === "") {
      let levelsData = formik.values.grades_attributes
      let l1 =levelsData.findIndex(val=>val.id === key) 
      levelsData.splice(l1,1)
      const filterArray = levelsData.map(v => v.id === key ? ({ ...v, _destroy: 1 }) : ({ ...v }));
      formik.setFieldValue("grades_attributes", filterArray)
    }
    else{
      let levelsData = formik.values.grades_attributes
      const filterArray = levelsData.map(v => v.id === key ? ({ ...v, _destroy: 1 }) : ({ ...v }));
      formik.setFieldValue("grades_attributes", filterArray)
    }
    
  }

  const handleInputs = (evt, key) => {
    let levelsData = formik.values.grades_attributes
    console.log(levelsData,"dataLevels==>")
    levelsData[levelsData.findIndex((obj => obj.id === key))].level = evt.target.value.trimStart();
    formik.setFieldValue("grades_attributes", [...levelsData])

  }


  return (
    <>
      <Form layout="vertical" className="modal-form" onFinish={formik.handleSubmit}>
        <Form.Item label={t('user_title')} rules={[{ required: true, message: t('Please enter department name') }]} required={true}>
          <Input placeholder={t('Department Name')} name="title" value={formik.values.title} onChange={formik.handleChange} />
          <div className="errorMsg">{formik.touched.title && formik.errors.title}</div>
        </Form.Item>
        <Form.Item name={"grader"} label={t('user_grade_degree')} required={true}>
          {formik.values?.grades_attributes?.filter(obj => obj._destroy !== 1)?.map((item, index) =>
            <div key={item.id} className='levelsDiv mt1'>
              <span className='inlineItem levelTitle'>{t('options')} {index + 1}</span>
              <Input className='inlineItem levelInputs' onChange={(e) => {
                handleInputs(e, item.id)
              }} value={item.level} />
              {
                index > 0 &&
                <MinusCircleOutlined className='minusButton inlineItem mtHalf cursor ml2' onClick={() => handleLevels('remove', item.id)} />
              }
            </div>)}
          <div className="errorMsg">{formik.touched.grades_attributes && formik.errors.grades_attributes?.length > 0 && t('grade_options')}</div>
        </Form.Item>
        <Button type="link" className='addLevelButton' onClick={() => handleLevels('add')}>
          <PlusCircleOutlined /> {t('add_option')}
        </Button>
        <div>
          <Button key="1" htmlType="submit" className="formButton mt3 primaryButton">{currentId ? t('update') : t('submit')}</Button>
          <Button key="2" className="cancelButton ml1 mt3 " onClick={() => handleCancelButton()}>{t('cancel')}</Button>
        </div>
      </Form>
    </>
  );
}

export default AddGrades;

