import { useEffect } from 'react';

const useScrollToError = (errorFieldName, errors, submitCount) => {
    useEffect(() => {
        const scrollToError = (errorFieldName) => {
            const errorField = document.querySelector(`[name="${errorFieldName}"]`);
            if (errorField) {
                errorField.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        };

        if (Object.keys(errors).length >= 1 || submitCount > 0) {
            scrollToError(errorFieldName);
        }
    }, [submitCount]);
};

export default useScrollToError;
