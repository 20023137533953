import { MessageOutlined, MoreOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Col, Menu, Row, Space } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { headerTitles } from '../../../actions/masterdata/masterdata';
import TableComponent from '../../Common/TableComp/TableComp';
import TableHeader from '../../Sales/TableHeader';
import CreatePayment from './CreatePayment';
import { useTranslation } from 'react-i18next';
import { payout_txns } from '../../../actions/accounts/accounts';
import moment from 'moment';

const History = ({ hasWriteAccess }) => {
  const [openAddNew, setOpenAddNew] = useState(false);
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(state => state?.banks?.payout_txns?.data?.data ?? [])
  const totalRecords = useSelector(state => state?.banks?.payout_txns?.data?.total_count ?? [])
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(headerTitles({
      title: 'history',
      description: 'see_ur_txns'
    }))
    dispatch(payout_txns())
  }, [])

  const sortOptions = [
    {
      label: t('created_history'),
    },
    {
      label: t('creation_date'),
    },
    {
      label: t('po_number'),
    },
    {
      label: t('vendor_name'),
    },
    {
      label: t('Amount'),
    },
    {
      label: t('expected_delivery_date'),
    },
    {
      label: t('import_purchase_orders'),
    },
    {
      label: t('export_purchase_orders'),
    },
  ];


  const columns = [
    {
      title: t('Transaction Id'),
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: t('beneficiary_name'),
      dataIndex: 'payee_name',
      key: 'payee_name',
    },
    {
      title: t('beneficiary account'),
      dataIndex: 'payee_account_no',
      key: 'payee_account_no',
      render: (text) => <div>XXXX{text?.substr(4)}</div>,
    },
    {
      title: t('Mode of Payment'),
      dataIndex: 'mode_of_payment',
      key: 'mode_of_payment',
      render: () => "IMPS / RTGS"
    },

    {
      title: t('dateAndTime'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (text, r) => <div >{r.currency_code}{" "}{text}</div>,
    },
    {
      title: t('status'),
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: text =>
        <div className={`status status_${text}`}>{text.replace(/_/g, " ")}</div>

    }
  ];

  const sort_opts = (
    <Menu onClick={() => { }}>
      {sortOptions.map((ele, idx) => <Menu.Item key={idx}>
        {ele.label}
      </Menu.Item>)}
    </Menu>
  );
  return (
    <Row wrap={false} className="table-wrapper">
      <Col flex="auto" className="table-wrapper-col">
        <div className='flex-grow'>
          <TableHeader noDD={true}
            noFilter={true}
            setOpenAddNew={setOpenAddNew} buttonLabel={t('payment_create')} sortOptions={sort_opts} hasWriteAccess={hasWriteAccess} />
          <div style={{ paddingTop: '22px' }}>
            <TableComponent
              columns={columns}
              data={data}
              hasCheckbox={false}
              // selectedRows={selectedRows}
              // setSelectedRows={setSelectedRows}
              pagination={{
                total: totalRecords || 0,
                current: page,
                onChange: (pagee) => {
                  dispatch(
                    payout_txns({
                      page_number: pagee,
                    })
                  );
                  setPage(pagee)
                },
              }}
              rowKey="bill_no"
            />
          </div>
          <CreatePayment open={openAddNew && hasWriteAccess} onClose={() => setOpenAddNew(false)} />
        </div>
      </Col>
    </Row>
  );
};

export default History;