import { ReducerFunctions } from '../../utils';
import { ApproveBillingDetails, BillingComment, BillingCommentHistory, CreateBillingComment, CreateBillingDetail, FetchAllBillingDetails, FetchBillingDetailsById, RejectBillingDetails, SubmitBillingDetails, UpdateBillAttachment, UpdateBillingDetail } from './bills';

export const create_billing_detail_reducer = ReducerFunctions(CreateBillingDetail, {});
export const update_billing_detail_reducer = ReducerFunctions(UpdateBillingDetail, {});
export const update_bill_attachment_reducer = ReducerFunctions(UpdateBillAttachment, {});
export const fetch_all_billing_details_reducer = ReducerFunctions(FetchAllBillingDetails, {});
export const fetch_billing_details_by_id_reducer = ReducerFunctions(FetchBillingDetailsById, {});
export const submit_billing_details_reducer = ReducerFunctions(SubmitBillingDetails, {});
export const approve_billing_details_reducer = ReducerFunctions(ApproveBillingDetails, {});
export const reject_billing_details_reducer = ReducerFunctions(RejectBillingDetails, {});
export const create_billing_comment_reducer = ReducerFunctions(CreateBillingComment, {});
export const billing_comment_reducer = ReducerFunctions(BillingComment, {});
export const billing_comment_history_reducer = ReducerFunctions(BillingCommentHistory, {});