import { pdf } from "@react-pdf/renderer";
import { Divider } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from 'json-2-csv';
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  analytics_cards
} from "../../actions/cards/cards";
import { apis } from "../../config/APIs";
import endpointAnalyticsApi from "../../config/AxiosAnalyticsApi";
import MISExpenses from "../Common/PDF/MISPDF/MISExpenses";
import BarGraph from "./BarGraph";
import ReportTable from "./ReportTable";


const Trips = ({ showGraph, apiFilters, setFilterObj, setpdfURL,pdfURL,csvData,...props }) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.analytics.analytics_cards);
  const pdfData = useSelector((state) => state.analytics.analytics_cards_all?.data?.data);
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(analytics_cards({ type: "trips" }));
    // dispatch(analytics_cards_all({ type: "trips",params:{isCsv:true} }));
    setFilterObj({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    pdfURL(downloadPdf)
  }, [pdfData])

  const columnsKeys = [
    { label: "Trip ID", key: "trip_number", type: "string" },
    { label: "Trip Name", key: "title", type: "string" },
    { label: "Employee Name", key: "employee_name", type: "string" },
    { label: "Purpose", key: "business_purpose", type: "string"},
    { label: "Submitted On", key: "submitted_on", type: "date",},
    { label: "Cost Center", key: "cost_center", type: "string" },
    { label: "Project", key: "project", type: "string" },
    { label: "Status", key: "status", type: "string" },
  ];
  
  const downloadPdf = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.analytics_trips + '?isCsv=true', { filters: filters });
    if(response){
      const blob = await pdf((
        <MISExpenses data={response?.data?.data}  columnsKeys={columnsKeys}/>
        )).toBlob();
        saveAs(blob, "MISTrips.pdf");
      }
  }

  ///// Dowloading CSV file method ////////
  const downloadCsv = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.analytics_trips + '?isCsv=true', { filters: filters });
    if(response){
      const prod = response?.data?.data?.map(ele => ({
      "Trip ID": ele.trip_number,
      "Trip  Name": ele.title,
      "Employee Name": ele.employee_name,
      "Purpose": ele.business_purpose,
      "Submitted On": moment(ele.submitted_on).format("DD MMM, YYYY"),
      "Cost Cente": ele.cost_center,
      "Project": ele.project,
      "Status ": ele.status,
    }))

    json2csv(prod).then((csv) => {
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      saveAs(blob, 'Trips.csv');
    });
  }
  }

  useEffect(() => {
    csvData(downloadCsv)
  }, [pdfData])

  const getObjs = () => {
    if (cards?.data?.response?.report?.length > 0) {
      return cards?.data?.response?.report?.[0]?.custom_fields?.map(ele => ({
        title: ele?.key?.replace("_", " "),
        dataIndex: "custom_fields",
        key: "custom_fields",
        render: (text, data) => <div>{data?.custom_fields?.find(itm => itm.key === ele.key)?.value}</div>,
      }))
    }

    return []
  }


  const columns = useMemo(() => [
    {
      title: t('trip_id'),
      dataIndex: "trip_number",
      key: "trip_number",
    },
    {
      title: t('trip_name'),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t('employee_name'),
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: t('purpose'),
      dataIndex: "business_purpose",
      key: "business_purpose",
    },
    {
      title: t('submittedOn'),
      dataIndex: "submitted_on",
      key: "submitted_on",
      render: (record) => record ? moment(record).format("DD MMM, YYYY hh:mm A") : "-"

    },
    ...getObjs(),

    {
      title: t('cost_center'),
      dataIndex: "cost_center",
      key: "cost_center",
      render: (text) => text ?? '-'
    },
    {
      title: t('project'),
      dataIndex: "project",
      key: "project",
      render: (text) => text ?? '-'
    },
    {
      title: t('status'),
      dataIndex: "status",
      key: "status",
      render: (text) => <div className={`status status_${text}`}>{text ? text?.replace(/_/g, ' ') : ""}</div>,
    },
    // eslint-disable-next-line
  ], [cards]);

  return (
    <>

      <div className="reports">
        {showGraph && (
          <>
            <Divider />
            <div
              className="mt1"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BarGraph
                width={1000}
                height={350}
                id={"lineAreaGraph"}
                data={[
                  { day: "Jan", txnValue: 39 },
                  { day: "Feb", txnValue: 49 },
                  { day: "Mar", txnValue: 69 },
                  { day: "Apr", txnValue: 59 },
                ]}
                barColor={"#151424"}
              />
            </div>
          </>
        )}
        <Divider />
      </div>
      <ReportTable
        columns={columns}
        // scroll={{ x: 1400 }}
        data={Array.isArray(cards?.data?.data) ? cards?.data?.data : []}
        total_records={cards?.data?.total_count}
        paginationCall={(vals) => {
          vals.type = "trips";
          dispatch(analytics_cards(vals));
          // dispatch(analytics_cards_export({ pdf: true, csv: true, ...vals }));
        }}
        filterObj={props.filterObj}
        payload={{ ...props.filterObj, ...props.apiFilters }}
      />
    </>
  );
};

export default Trips;
