import { combineReducers } from "redux";
import {
  changePassword_reducer,
  forgotPassword_reducer,
  local_varaibles,
  login_reducer,
  logout_reducer,
  notifications_reducer,
  request_demo_reducer,
  resetPassword_reducer,
} from "./actions/auth/reducer";
import {
  account_txns_reducer,
  add_categories_reducer,
  categories_reducer,
  categories_status_reducer,
  delete_categories_reducer,
} from "./actions/categories/reducer";
import {
  dashboard_reducer,
  error_modal_reducer,
  invoice_dashboard_reducer,
  pay_dashboard_reducer,
} from "./actions/dashboard/reducer";
import {
  add_employees_reducer,
  delete_employees_document_reducer,
  delete_employees_reducer,
  download_link_reducer,
  employee_by_id_reducer,
  employees_reducer,
  employees_status_reducer,
  get_employee_reducer,
  get_user_fields_reducer,
  update_employees_reducer,
  upload_bulk_employees_reducer,
  upload_employee_reducer,
} from "./actions/employees/reducer";
import {
  addUserReports_reducer,
  changereport_reducer,
  forward_approval_reducer,
  reportdetails_pdf_reducer,
  reportsAdvance_reducer,
  reportsApprove_reducer,
  reportsBulkApproveReject_reducer,
  reportsCommentsAdd_reducer,
  reportsComments_reducer,
  reportsExpAddComments_reducer,
  reportsExpComments_reducer,
  reportsExpDetails_reducer,
  reportsExpHistory_reducer,
  reportsHistory_reducer,
  reportsReimburse_reducer,
  reportsReject_reducer,
  reportsTrips_reducer,
  reports_count_reducer,
  reports_reducer,
  transactionsPartialApprove_reducer,
  transactionsReject_reducer,
  userReportFields_reducer,
  userReportLinkAdv_reducer,
  userReportLinkExp_reducer,
  userReportRecall_reducer,
  userReportSubmit_reducer,
  userReports_reducer,
  user_report_reducer,
} from "./actions/reports/reducer";

import {
  addExpense_reducer,
  create_expensePolicies_reducer,
  delete_expensePolicies_reducer,
  delete_expense_reducer,
  edit_expensePolicies_reducer,
  expenseDetails_customFields_reducer,
  expenseDetails_reducer,
  expenseFields_reducer,
  expense_comments_reducer,
  expenses_reducer,
  list_expensePolicies_reducer,
  prefix_expenseFields_Reducer,
  show_expensePolicies_reducer,
  updateExpense_reducer,
  validateExpense_reducer,
} from "./actions/expenses/reducer";

import {
  addUserTrip_reducer,
  attach_files_to_trips_reducer,
  download_trip_attachment_reducer,
  trip_detail_pdf_reducer,
  trip_recall_reducer,
  tripsAddComments_reducer,
  tripsApprove_reducer,
  tripsComments_reducer,
  tripsDelete_reducer,
  tripsHistory_reducer,
  tripsReject_reducer,
  trips_close_reducer,
  trips_reducer,
  trips_show_reducer,
  updateUserTrip_reducer,
  userReportLinkTrips_reducer,
  userTripFields_reducer,
  userTripsSubmit_reducer,
  userTrips_reducer,
} from "./actions/trips/reducer";

import {
  analytics_cards_all_reducer,
  analytics_cards_export_reducer,
  analytics_cards_reducer,
  analytics_estimates_reducer,
  block_reasons_reducer,
  block_reducer,
  card_edit_set_policies_reducer,
  card_enable_limit_reducer,
  card_policies_reducer,
  card_set_policies_reducer,
  cardsDetails_reducer,
  cards_reducer,
  corporate_budget_reducer,
  createInviteLink_reducer,
  delete_card_policy_reducer,
  disconnectCompany_reducer,
  download_bulk_reducer,
  getAllOrganisations_reducer,
  getOneCompany_reducer,
  getSyncHistory_reducer,
  get_card_limit,
  get_card_policies,
  quick_logs_reducer,
  remind_card_activation_reducer,
  replacement_reducer,
  send_otp_reducer,
  set_card_policy_reducer,
  set_pin_reducer,
  show_card_policy_reducer,
  syncData_reducer,
  topup_reducer,
  transactions_reducer,
  transfer_logs_reducer,
  update_mobile_reducer,
  upload_bulk_reducer,
  userCards_reducer,
  verify_otp_reducer,
} from "./actions/cards/reducer";

import {
  all_templates,
  default_template,
  old_template,
  update_templates,
} from "./actions/Templates";
import {
  all_budgets_reducer,
  beneficiary_accounts_reducer,
  budgets_back_reducer,
  company_accounts_reducer,
  create_budgets_reducer,
  initiate_payout_reducer,
  master_account_history_reducer,
  master_pool_account_reducer,
  payout_txns_reducer,
  primary_account_reducer,
} from "./actions/accounts/reducer";
import {
  addUserAdvance_reducer,
  advance_pdf_reducer,
  advance_show_reducer,
  advancesApprove_reducer,
  advancesCommentsAdd_reducer,
  advancesComments_reducer,
  advancesExpAddComments_reducer,
  advancesExpComments_reducer,
  advancesExpDetails_reducer,
  advancesExpHistory_reducer,
  advancesHistory_reducer,
  advancesReject_reducer,
  advances_reducer,
  updateUserAdvance_reducer,
  userAdvanceDelete_reducer,
  userAdvanceFields_reducer,
  userAdvancesSubmit_reducer,
  userAdvances_reducer,
} from "./actions/advances/reducer";
import {
  approve_billing_details_reducer,
  billing_comment_history_reducer,
  billing_comment_reducer,
  create_billing_comment_reducer,
  create_billing_detail_reducer,
  fetch_all_billing_details_reducer,
  fetch_billing_details_by_id_reducer,
  reject_billing_details_reducer,
  submit_billing_details_reducer,
  update_billing_detail_reducer,
} from "./actions/bills/reducer";
import { activate_card } from "./actions/cards/cards";
import {
  add_advancesField_reducer,
  add_companySetting_reducer,
  add_costCenterField_reducer,
  add_designations_reducer,
  add_merchantsField_reducer,
  add_projectsField_reducer,
  add_reportField_reducer,
  add_roles_reducer,
  add_transactionField_reducer,
  add_tripsField_reducer,
  add_userFields_reducer,
  advanceApproval_reducer,
  bill_approval_reducer,
  companyCities_reducer,
  companyCountries_reducer,
  companyProfile_Images_reducer,
  companyProfile_reducer,
  companySetting_reducer,
  companyStates_reducer,
  companyTzs_reducer,
  create_company_multiple_currency_reducer,
  create_exchange_rates_reducer,
  create_multi_branch_reducer,
  create_reminder_api_reducer,
  delete_advancesField_reducer,
  delete_costCenterField_reducer,
  delete_designations_reducer,
  delete_merchantsField_reducer,
  delete_projectsField_reducer,
  delete_reportField_reducer,
  delete_role_reducer,
  delete_transactionField_reducer,
  delete_tripsField_reducer,
  delete_userFields_reducer,
  enable_disable_costCenter_reducer,
  enable_disable_marchentField_reducer,
  enable_disable_projectField_reducer,
  get_advancesField_reducer,
  get_all_branch_header_reducer,
  get_all_branch_list_reducer,
  get_all_branch_reducer,
  get_all_company_details_reducer,
  get_all_countries_reducer,
  get_all_currency_reducer,
  get_all_exchange_rates_reducer,
  get_branch_id_reducer,
  get_card_venders_reducer,
  get_company_base_currency_reducer,
  get_company_multiple_currency_reducer,
  get_costCenterField_reducer,
  get_custom_fields_reducer,
  get_designations_reducer,
  get_merchantsField_reducer,
  get_portal_permission_reducer,
  get_projectsField_reducer,
  get_reminder_api_reducer,
  get_reportField_reducer,
  get_rolesPermissions_reducer,
  get_roles_reducer,
  get_singleRoles_reducer,
  get_singleUserFields_reducer,
  get_single_designations_reducer,
  get_transactionField_reducer,
  get_tripsField_reducer,
  get_userFieldsColumnTypes_reducer,
  get_userFields_reducer,
  orgs_reducer,
  reportsApproval_reducer,
  switch_brach_reducer,
  switch_brach_ruby_reducer,
  toggle_exchange_rate_reducer,
  tripApproval_reducer,
  updateAdvanceApproval_reducer,
  updateCompanyProfile_reducer,
  updateCompanyTrips_reducer,
  updateReportsApproval_reducer,
  updateTripApproval_reducer,
  update_advancesField_reducer,
  update_advancesreport_reducer,
  update_bill_approval_reducer,
  update_companyProfile_imgae_reducer,
  update_companyreport_reducer,
  update_companytransaction_reducer,
  update_costCenterField_reducer,
  update_designations_reducer,
  update_merchantsField_reducer,
  update_projectsField_reducer,
  update_reportField_reducer,
  update_roles_reducer,
  update_transactionField_reducer,
  update_tripsField_reducer,
  update_userFields_reducer,
} from "./actions/companySettings/reducer";
import {
  create_customer_reducer,
  create_invoice_reducer,
  customers_reducer,
  invoices_reducer,
  portal_dashboard_company_list_reducer,
  portal_dashboard_reducer,
  portal_record_details_reducer,
  portal_table_data_reducer,
  show_profile_reducer,
  submit_invoice_reducer,
  upload_profile_reducer,
  view_customer_reducer,
} from "./actions/customers";
import {
  get_page_details_reducer,
  get_summary_reducer,
  import_api_reducer,
} from "./actions/imports/reducer";
import {
  change_policy_priority_reducer,
  create_policy_reducer,
  currencies_reducer,
  delete_policy_reducer,
  enable_policy_reducer,
  get_policies_reducer,
  get_policy_meta_reducer,
  get_policy_reducer,
  seed_data_reducer,
} from "./actions/masterdata/reducer";
import {
  ocr_document_delete_reducer,
  ocr_documents_reducer,
} from "./actions/ocr/reducer";
import {
  customer_add_addresses_reducer,
  customer_add_to_cart_reducer,
  customer_billing_addresses_reducer,
  customer_cart_reducer,
  customer_create_order_reducer,
  customer_delete_cart_reducer,
  customer_login_reducer,
  customer_logout_reducer,
  customer_my_orders_reducer,
  customer_orders_cancel_reducer,
  customer_orders_destroy_reducer,
  customer_orders_reducer,
  customer_orders_show_reducer,
  customer_orders_submit_reducer,
  customer_orders_update_quantity_reducer,
  customer_orders_update_reducer,
  customer_product_reducer,
  customer_products_reducer,
  customer_profile_reducer,
  customer_remove_cart_item_reducer,
  customer_shipping_addresses_reducer,
  customer_update_item_quantity_reducer,
  order_approve_reducer,
  order_comments_reducer,
  order_get_comments_reducer,
  order_reject_reducer,
} from "./actions/orders/reducer";
import {
  approve_payment_reducer,
  create_payment_reducer,
  delete_payment_reducer,
  edit_payment_reducer,
  get_payment_reducer,
  payments_reducer,
  recall_payment_reducer,
  reject_payment_reducer,
  submit_payment_reducer,
  update_payment_reducer,
} from "./actions/payments/reducer";
import {
  get_items_reducer,
  get_receipts_reducer,
  get_tax_items_reducer,
  payment_made_reducer,
  place_of_supplies_reducer,
  receipts_by_id_reducer,
  receipts_create_reducer,
  receipts_get_reducer,
  receipts_submit_reducer,
  save_beneficiary,
  save_place_supplies,
  update_beneficiary,
  vendors_by_id_reducer,
  vendors_by_name_reducer,
  vendors_reducer,
  verdors_payment_terms,
  verdors_tds_reducer,
} from "./actions/purchases/reducer";
import {
  delete_payment_link_reducer,
  download_bulk_upload_reducer,
  fetch_bulk_uploads_reducer,
  fetch_payment_links_reducer,
  save_bulk_upload_payment_link_reducer,
  save_bulk_uploads,
} from "./actions/transfers";
import {
  create_on_board_form_reducer,
  get_onboard_fields_reducer,
  onboard_create_reducer,
  onboard_final_submit_reducer,
  onboard_step_preview_reducer,
  onboard_step_reducer,
  post_onboard_api,
} from "./components/OnBoarding/action/OnBoardForm";
import {
  inventory_items_get_reducer,
  inventory_items_reducer,
} from "./actions/inventory/reducer";
import {
  all_payments_reducer,
  get_purposes_reducer,
  initialize_payment_reducer,
  master_account_txns_reducer,
  person_accounts_reducer,
} from "./actions/z_accounts";

export default combineReducers({
  banks: combineReducers({
    accounts: primary_account_reducer,
    cmp_accs: company_accounts_reducer,
    beneficiary_accounts: beneficiary_accounts_reducer,
    master_pool_account: master_pool_account_reducer,
    all_budgets: all_budgets_reducer,
    create_budgets: create_budgets_reducer,
    budgets_back: budgets_back_reducer,
    masterHistory: master_account_history_reducer,
    initiate_payout: initiate_payout_reducer,
    payout_txns: payout_txns_reducer,
  }),
  approvals: combineReducers({
    bills: bill_approval_reducer,
    update_bills: update_bill_approval_reducer,
  }),
  auth: combineReducers({
    login: login_reducer,
    customer_login: customer_login_reducer,
    customer_logout: customer_logout_reducer,
    logout: logout_reducer,
    resetPassword: resetPassword_reducer,
    forgotPassword: forgotPassword_reducer,
    changePassword: changePassword_reducer,
    localVariables: local_varaibles,
    notifications: notifications_reducer,
    requestDemo: request_demo_reducer,
  }),
  dashboard: combineReducers({
    dashboard: dashboard_reducer,
    pay: pay_dashboard_reducer,
    inv: invoice_dashboard_reducer,
  }),
  expenses: combineReducers({
    expenses: expenses_reducer,
    expenseDetails: expenseDetails_reducer,
    expenseCustomDetails: expenseDetails_customFields_reducer,
    addExpense: addExpense_reducer,
    updateExpense: updateExpense_reducer,
    validateExpense: validateExpense_reducer,
    expenseFields: expenseFields_reducer,
    prefixexpenseFields: prefix_expenseFields_Reducer,
    policies: list_expensePolicies_reducer,
    create_policies: create_expensePolicies_reducer,
    edit_policies: edit_expensePolicies_reducer,
    show_policy: show_expensePolicies_reducer,
    delete_policy: delete_expensePolicies_reducer,
    comments: expense_comments_reducer,
  }),
  purchases: combineReducers({
    vendors: vendors_reducer,
    placesOfSupplies: place_of_supplies_reducer,
    vendorsByName: vendors_by_name_reducer,
    vendorsById: vendors_by_id_reducer,
    vendorTDS: verdors_tds_reducer,
    paymentTerms: verdors_payment_terms,
    saveBeneficiary: save_beneficiary,
    updateBeneficiary: update_beneficiary,
    savePlaceSupplies: save_place_supplies,
    beneficiary: view_customer_reducer,

    // Receipts reducer
    receiptsGet: receipts_get_reducer,
    receiptsCreate: receipts_create_reducer,
    receiptsBYID: receipts_by_id_reducer,
    receiptsSubmit: receipts_submit_reducer,
  }),
  bills: combineReducers({
    createBillingDetail: create_billing_detail_reducer,
    updateBillingDetail: update_billing_detail_reducer,
    fetchAllBillingDetails: fetch_all_billing_details_reducer,
    fetchBillingDetailsById: fetch_billing_details_by_id_reducer,
    submitBillingDetails: submit_billing_details_reducer,
    approveBillingDetails: approve_billing_details_reducer,
    rejectBillingDetails: reject_billing_details_reducer,
    createBillingComment: create_billing_comment_reducer,
    billingComment: billing_comment_reducer,
    billingCommentHistory: billing_comment_history_reducer,
    items: get_items_reducer,
    tax_items: get_tax_items_reducer,
  }),
  reports: combineReducers({
    reports: reports_reducer,
    forward_approval: forward_approval_reducer,
    changeReports: changereport_reducer,
    reportsReject: reportsReject_reducer,
    transactionsReject: transactionsReject_reducer,
    deleteExpense: delete_expense_reducer,
    transactionsPartialApprove: transactionsPartialApprove_reducer,
    reportsApprove: reportsApprove_reducer,
    reportsExpDetails: reportsExpDetails_reducer,
    reportsExpComments: reportsExpComments_reducer,
    reportsExpAddComments: reportsExpAddComments_reducer,
    reportsComments: reportsComments_reducer,
    reportsCommentsAdd: reportsCommentsAdd_reducer,
    reportsHistory: reportsHistory_reducer,
    reportsAdvance: reportsAdvance_reducer,
    reportsTrips: reportsTrips_reducer,
    reportsExpHistory: reportsExpHistory_reducer,
    reportsReimburse: reportsReimburse_reducer,
    userReports: userReports_reducer,
    userReportFields: userReportFields_reducer,
    userReportLinkExp: userReportLinkExp_reducer,
    userReportLinkAdv: userReportLinkAdv_reducer,
    userReportSubmit: userReportSubmit_reducer,
    userReportRecall: userReportRecall_reducer,
    userReportShow: user_report_reducer,
    addUserReports: addUserReports_reducer,
    bulkApprove: reportsBulkApproveReject_reducer,
    reportdetailsPdf: reportdetails_pdf_reducer,
  }),

  trips: combineReducers({
    trips: trips_reducer,
    tripsClose: trips_close_reducer,
    tripsRecall: trip_recall_reducer,
    userTrips: userTrips_reducer,
    userTripsSubmit: userTripsSubmit_reducer,
    addUserTrip: addUserTrip_reducer,
    updateUserTrip: updateUserTrip_reducer,
    userTripFields: userTripFields_reducer,
    tripsReject: tripsReject_reducer,
    tripsDelete: tripsDelete_reducer,
    tripsApprove: tripsApprove_reducer,
    tripsComments: tripsComments_reducer,
    tripsCommentsAdd: tripsAddComments_reducer,
    tripsHistory: tripsHistory_reducer,
    tripsShow: trips_show_reducer,
    tripDetailPDF: trip_detail_pdf_reducer,
    userReportLinkTrips: userReportLinkTrips_reducer,
    download_trip_attachment: download_trip_attachment_reducer,
    attach_files_to_trip: attach_files_to_trips_reducer,
  }),
  cards: combineReducers({
    userCards: userCards_reducer,
    cards: cards_reducer,
    setPin: set_pin_reducer,
    cardsDetails: cardsDetails_reducer,
    transactions: transactions_reducer,
    topup: topup_reducer,
    block: block_reducer,
    uploadBulk: upload_bulk_reducer,
    downloadBulk: download_bulk_reducer,
    replacement: replacement_reducer,
    corporate_budget: corporate_budget_reducer,
    transferLogs: transfer_logs_reducer,
    quick_logs: quick_logs_reducer,
    cartSetPolicies: card_set_policies_reducer,
    updateSetPolicies: card_edit_set_policies_reducer,
    cardPolicies: card_policies_reducer,
    card_limit: get_card_limit,
    get_card_policies: get_card_policies,
    card_enable: card_enable_limit_reducer,
    show_card_policy: show_card_policy_reducer,
    delete_card_policy: delete_card_policy_reducer,
    set_card_policy: set_card_policy_reducer,
    reports_count: reports_count_reducer,
    update_mobile: update_mobile_reducer,
    send_otp: send_otp_reducer,
    verify_otp: verify_otp_reducer,
    activate_card: activate_card,
    remind_card_activation: remind_card_activation_reducer,
    analytics_estimates: analytics_estimates_reducer,
    block_reasons: block_reasons_reducer,
    /// RootiFi ///
    createInvite_Link: createInviteLink_reducer,
    getOne_company: getOneCompany_reducer,
    disconnect_company: disconnectCompany_reducer,
    getAll_organisations: getAllOrganisations_reducer,
    sync_data: syncData_reducer,
    get_sync_history: getSyncHistory_reducer,
  }),

  advances: combineReducers({
    userAdvances: userAdvances_reducer,
    userAdvancesSubmit: userAdvancesSubmit_reducer,
    userAdvanceFields: userAdvanceFields_reducer,
    addUserAdvance: addUserAdvance_reducer,
    advances: advances_reducer,
    advancesApprove: advancesApprove_reducer,
    advancesReject: advancesReject_reducer,
    userAdvanceDelete: userAdvanceDelete_reducer,
    advancesExpDetails: advancesExpDetails_reducer,
    advancesExpComments: advancesExpComments_reducer,
    advancesExpAddComments: advancesExpAddComments_reducer,
    advancesComments: advancesComments_reducer,
    advancesCommentsAdd: advancesCommentsAdd_reducer,
    advancesHistory: advancesHistory_reducer,
    advancesExpHistory: advancesExpHistory_reducer,
    advance_pdf: advance_pdf_reducer,
    updateUserAdvance: updateUserAdvance_reducer,
    show_advance: advance_show_reducer,
  }),
  account: combineReducers({
    txns: account_txns_reducer,
  }),
  categories: combineReducers({
    categories: categories_reducer,
    categoriesStatus: categories_status_reducer,
    addCategories: add_categories_reducer,
    deleteCategories: delete_categories_reducer,
  }),

  employees: combineReducers({
    employees: employees_reducer,
    employeesStatus: employees_status_reducer,
    employeebyId: employee_by_id_reducer,
    addEmployees: add_employees_reducer,
    deleteEmployees: delete_employees_reducer,
    updateEmployees: update_employees_reducer,
    uploadEmployeeDocument: upload_employee_reducer,
    getEmployeeDocument: get_employee_reducer,
    deleteEmployeeDocument: delete_employees_document_reducer,
  }),
  users: combineReducers({
    downloadLink: download_link_reducer,
    upload: upload_bulk_employees_reducer,
    user_fields: get_user_fields_reducer,
  }),
  analytics: combineReducers({
    analytics_cards: analytics_cards_reducer,
    analytics_cards_all: analytics_cards_all_reducer,
    analytics_cards_export: analytics_cards_export_reducer,
  }),
  companySetting: combineReducers({
    orgs: orgs_reducer,
    companyProfile: companyProfile_reducer,
    companyProfileImages: companyProfile_Images_reducer,
    updateCompanyProfile: updateCompanyProfile_reducer,
    updateCompanyProfilefImages: update_companyProfile_imgae_reducer,
    reportsApproval: reportsApproval_reducer,
    updateReportsApproval: updateReportsApproval_reducer,
    advanceApproval: advanceApproval_reducer,
    updateAdvanceApproval: updateAdvanceApproval_reducer,
    tripApproval: tripApproval_reducer,
    updateTripApproval: updateTripApproval_reducer,
    companySetting: companySetting_reducer,
    updateCompanySetting: add_companySetting_reducer,
    updateTransactionReport: update_companytransaction_reducer,
    getTransactionField: get_transactionField_reducer,
    getSingleTransactionField: get_transactionField_reducer,
    addTransactionField: add_transactionField_reducer,
    deleteTransactionField: delete_transactionField_reducer,
    updateTransactionField: update_transactionField_reducer,
    updateCompanyReport: update_companyreport_reducer,
    getReportField: get_reportField_reducer,
    addReportField: add_reportField_reducer,
    deleteReportField: delete_reportField_reducer,
    updateReportField: update_reportField_reducer,
    updateAdvancesReport: update_advancesreport_reducer,
    getAdvancesField: get_advancesField_reducer,
    addAdvancesField: add_advancesField_reducer,
    deleteAdvancesField: delete_advancesField_reducer,
    updateAdvancesField: update_advancesField_reducer,
    getCostCenterField: get_costCenterField_reducer,
    addCostCenterField: add_costCenterField_reducer,
    deleteCostCenterField: delete_costCenterField_reducer,
    updateCostCenterField: update_costCenterField_reducer,
    enableDisableCostCenter: enable_disable_costCenter_reducer,
    getMerchantsField: get_merchantsField_reducer,
    addMerchantsField: add_merchantsField_reducer,
    deleteMerchantsField: delete_merchantsField_reducer,
    updateMerchantsField: update_merchantsField_reducer,
    enabledisableMarchent: enable_disable_marchentField_reducer,
    getProjectsField: get_projectsField_reducer,
    addProjectsField: add_projectsField_reducer,
    deleteProjectsField: delete_projectsField_reducer,
    updateProjectsField: update_projectsField_reducer,
    enableDisableProjectField: enable_disable_projectField_reducer,
    getTripsField: get_tripsField_reducer,
    addTripsField: add_tripsField_reducer,
    deleteTripsField: delete_tripsField_reducer,
    updateTripsField: update_tripsField_reducer,
    updateCompanyTrips: updateCompanyTrips_reducer,
    getRolesPermissions: get_rolesPermissions_reducer,
    getRoles: get_roles_reducer,
    getSingleRoles: get_singleRoles_reducer,
    addRoles: add_roles_reducer,
    updateRoles: update_roles_reducer,
    getDesignations: get_designations_reducer,
    addDesignations: add_designations_reducer,
    delete_role: delete_role_reducer,
    deleteDesignations: delete_designations_reducer,
    updateDesignations: update_designations_reducer,
    getSingleDesignations: get_single_designations_reducer,
    getUserFields: get_userFields_reducer,
    getCustomFields: get_custom_fields_reducer,
    getUserFieldsColumnTypes: get_userFieldsColumnTypes_reducer,
    getSingleUserFields: get_singleUserFields_reducer,
    addUserFields: add_userFields_reducer,
    deleteUserFields: delete_userFields_reducer,
    updateUserFields: update_userFields_reducer,
    companyCities: companyCities_reducer,
    companyStates: companyStates_reducer,
    companyCountries: companyCountries_reducer,
    companyTzs: companyTzs_reducer,
    getReminderData: get_reminder_api_reducer,
    createCompanyMultipleCurrency: create_company_multiple_currency_reducer,
    getCompanyMultipleCurrency: get_company_multiple_currency_reducer,
    getAllCurrency: get_all_currency_reducer,
    getAllCountries: get_all_countries_reducer,
    createExchangeRate: create_exchange_rates_reducer,
    getAllExchangeRates: get_all_exchange_rates_reducer,
    toggleExchangeRate: toggle_exchange_rate_reducer,
    createReminderData: create_reminder_api_reducer,
    getAllCompanyDetails: get_all_company_details_reducer,
    createMultiBranch: create_multi_branch_reducer,
    getAllBranch: get_all_branch_reducer,
    getAllBranchList: get_all_branch_list_reducer,
    getAllBranchHeader: get_all_branch_header_reducer,
    getBranchID: get_branch_id_reducer,
    switchBrach: switch_brach_reducer,
    switchBrachRuby: switch_brach_ruby_reducer,
    getCompanyBaseCurrency: get_company_base_currency_reducer,
    getCardVenders: get_card_venders_reducer,
    getPortalPermission: get_portal_permission_reducer,
  }),

  customers: combineReducers({
    all: customers_reducer,
    create: create_customer_reducer,
    create_invoice: create_invoice_reducer,
    invoices: invoices_reducer,
    submit_invoice: submit_invoice_reducer,
    upload_profile: upload_profile_reducer,
    show_profile: show_profile_reducer,
    portalTableData: portal_table_data_reducer,
    portalRecordDetails: portal_record_details_reducer,
    portalDashboard: portal_dashboard_reducer,
    portalCompanyList: portal_dashboard_company_list_reducer,
  }),
  seed_data: seed_data_reducer,
  currencies: currencies_reducer,
  errorModal: error_modal_reducer,
  transfers: combineReducers({
    fetch_payment_links: fetch_payment_links_reducer,
    delete_payment_link: delete_payment_link_reducer,
    save_bulk_uploads: save_bulk_uploads,
    fetch_bulk_uploads: fetch_bulk_uploads_reducer,
    save_bulk_upload_payment_link: save_bulk_upload_payment_link_reducer,
    download_bulk_uploads: download_bulk_upload_reducer,
  }),
  ocr: combineReducers({
    docs: ocr_documents_reducer,
    delete: ocr_document_delete_reducer,
  }),
  on_board: combineReducers({
    create_on_board_form: create_on_board_form_reducer,
    postOnboardapi: post_onboard_api,
    getOnboardFields: get_onboard_fields_reducer,
    onboardCreate: onboard_create_reducer,
    onboardStep: onboard_step_reducer,
    onboardStepPreview: onboard_step_preview_reducer,
    onboardFinalSubmit: onboard_final_submit_reducer,
  }),
  policies: combineReducers({
    create: create_policy_reducer,
    enalbe: enable_policy_reducer,
    delete: delete_policy_reducer,
    policy: get_policy_reducer,
    policies: get_policies_reducer,
    metadata: get_policy_meta_reducer,
    change_priority: change_policy_priority_reducer,
  }),
  templates: combineReducers({
    allTemplates: all_templates,
    updateTemplates: update_templates,
    defaultTemplate: default_template,
    oldTemplate: old_template,
  }),
  payments: combineReducers({
    payments: payments_reducer,
    get_payment: get_payment_reducer,
    create_payment: create_payment_reducer,
    submit_payment: submit_payment_reducer,
    recall_payment: recall_payment_reducer,
    approve_payment: approve_payment_reducer,
    reject_payment: reject_payment_reducer,
    made: get_receipts_reducer,
    payment_made: payment_made_reducer,
    edit_payment: edit_payment_reducer,
    update_payment: update_payment_reducer,
    delete_payment: delete_payment_reducer,
  }),
  imports: combineReducers({
    getPageDetails: get_page_details_reducer,
    getSummary: get_summary_reducer,
    importApi: import_api_reducer,
  }),
  orders: combineReducers({
    customer_products: customer_products_reducer,
    customer_product: customer_product_reducer,
    customer_profile: customer_profile_reducer,
    add_to_cart: customer_add_to_cart_reducer,
    cart: customer_cart_reducer,
    delete_cart: customer_delete_cart_reducer,
    remove_cart_item: customer_remove_cart_item_reducer,
    update_item_quantity: customer_update_item_quantity_reducer,
    orders: customer_orders_reducer,
    orders_show: customer_orders_show_reducer,
    my_orders: customer_my_orders_reducer,
    orders_update: customer_orders_update_reducer,
    orders_submit: customer_orders_submit_reducer,
    orders_cancel: customer_orders_cancel_reducer,
    orders_approve: order_approve_reducer,
    orders_reject: order_reject_reducer,
    orders_update_quantity: customer_orders_update_quantity_reducer,
    orders_destroy: customer_orders_destroy_reducer,
    shipping_addresses: customer_shipping_addresses_reducer,
    billing_addresses: customer_billing_addresses_reducer,
    create_order: customer_create_order_reducer,
    get_order_comments: order_get_comments_reducer,
    order_comments: order_comments_reducer,
    customerAddAddress: customer_add_addresses_reducer,
  }),
  inventory: combineReducers({
    items: inventory_items_reducer,
    itemsGet: inventory_items_get_reducer,
  }),
  z_accs: combineReducers({
    accounts: person_accounts_reducer,
    txns: master_account_txns_reducer,
    initialize_payment: initialize_payment_reducer,
    purposes: get_purposes_reducer,
    all_payments: all_payments_reducer,
  }),
});
