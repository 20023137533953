import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { headerTitles } from '../../actions/masterdata/masterdata';
import ub_1 from "../../assets/images/utility_bills_1.png"
import ub_2 from "../../assets/images/ub_2.png"
import { useTranslation } from 'react-i18next';
const UtilityBills = () => {
  const dispatch = useDispatch();
const {t} = useTranslation()
  useEffect(() => {
    dispatch(headerTitles({
      title: 'utility_bill',
      description: 'manage_pay'
    }))
  }, [])

  return (
    <div className='utility-bills'>
      <div className='d-flex'>
        <div className='w35'>
          <h4>{t('bill_payments')}</h4>
          <p>
            {t('pay_bill')}
          </p>
          <button className='pp-main-button'>
            <span>
              {t('add_bill')}
            </span>
          </button>
          <div className='line'></div>
          <label>{t('bill_preference')}</label>
          <span className='link'>{t('set_approvals')}</span>
          <span className='link'>{t('shared_bill')}</span>
        </div>
        <img src={ub_1} alt="" className='w65' />
      </div>
      <div className='center'>
        <h4>
          {t('how_it')}
        </h4>
        <img src={ub_2} alt="" className='w65' />
      </div>
    </div>
  );
};

export default UtilityBills;