import React from 'react';

const DashboardSVG = ({ scale, fill, opacity }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{
      scale: scale ? scale : 1,
      zoom: scale ? scale : 1
    }}>
      <g id="Group_16937" data-name="Group 16937" transform="translate(-6042 -11006)">
        <g id="Group_16255" data-name="Group 16255" transform="translate(6039.946 11003.947)" opacity={opacity || "0.5"}>
          <rect id="Rectangle_4934" data-name="Rectangle 4934" width="11" height="8" rx="1" transform="translate(2.054 2.053)" fill={fill || "#fff"} />
          <rect id="Rectangle_4935" data-name="Rectangle 4935" width="11" height="14" rx="1" transform="translate(2.054 12.053)" fill={fill || "#fff"} />
          <rect id="Rectangle_4936" data-name="Rectangle 4936" width="11" height="8" rx="1" transform="translate(15.054 18.053)" fill={fill || "#fff"} />
          <rect id="Rectangle_4937" data-name="Rectangle 4937" width="11" height="14" rx="1" transform="translate(15.054 2.053)" fill={fill || "#fff"} />
        </g>
      </g>
    </svg>

  );
};

export default DashboardSVG;