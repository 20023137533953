import { takeLatest } from 'redux-saga/effects';
import { apis } from '../../config/APIs';
import { sagaFunctions } from '../../utils';
import {
  GetItems,
  GetReceipts,
  GetTaxItems,
  PaymentMade,
  PaymentTerms,
  PlaceOfSupplies,
  ReceiptsBYID,
  ReceiptsCreate,
  ReceiptsGet,
  ReceiptsSubmit,
  SaveBeneficiary,
  SavePlaceSupplies,
  UpdateBeneficiary,
  Vendors,
  VendorsById,
  VendorsByName,
  VendorTDS,
} from './purchases';

function getVendorBills(req) {
  const page = req.payload?.page_number
  let request = apis.vendorsAll + `?type=${req.payload?.type}`
  if (page) {
    request = request + `&page_number=${page}`
  }
  delete req?.payload?.type;
  delete req?.payload?.page_number;
  return sagaFunctions(Vendors, "post", request, req.payload, {}, true)();
}

function getPlaceOfSupplies(req) {
  const request = `${apis.placeOfSypplies}?company_id=${localStorage.getItem(
    'company_id'
  )}&seed_type=place_of_supply&seed_value=${req?.payload?.name ?? ''}`;
  return sagaFunctions(PlaceOfSupplies, 'get', request, {}, {}, true)();
}

function getVendorsByName(req) {
  const request = `${apis.vendorsByName}?company_id=${localStorage.getItem('company_id')}`;
  return sagaFunctions(VendorsByName, 'get', request, req.payload, {}, true)();
}

function getVendorsById(req) {
  const request = `${apis.vendorsById}?beneficiary_id=${req.payload}`;
  return sagaFunctions(VendorsById, 'get', request, {}, {}, true)();
}

function getVendorTDS(req) {
  const request = `${apis.fetchVendorTDS}?company_id=${localStorage.getItem('company_id')}`;
  return sagaFunctions(VendorTDS, 'get', request, {}, {}, true)();
}

function getPaymentTerms(req) {
  const request = `${apis.fetchPaymentTerms}?company_id=${localStorage.getItem('company_id')}`;
  return sagaFunctions(PaymentTerms, 'get', request, {}, {}, true)();
}

function saveBeneficiary(req) {
  return sagaFunctions(
    SaveBeneficiary,
    'post',
    apis.saveBeneficiary,
    req.payload,
    { 'Content-Type': 'application/json' },
    true
  )();
}

function updateBeneficiary(req) {
  const isVendor = req?.payload?.is_vendor
  delete req.payload.is_vendor
  return sagaFunctions(
    UpdateBeneficiary,
    'post',
    isVendor ? apis?.vendorPortalProfileUpdateAPI :
      apis.updateBeneficiary,
    req.payload,
    { 'Content-Type': 'application/json' },
    true
  )();
}

function savePlaceSupply(req) {
  return sagaFunctions(
    SavePlaceSupplies,
    'post',
    apis.savePlaceSupplies,
    req.payload,
    { 'Content-Type': 'application/json' },
    true
  )();
}

function getReceipts(req) {
  const isPaymentsReceived = req.payload?.isPaymentsReceived
  const page_number = req.payload?.page_number
  let request = isPaymentsReceived ? apis.getPaymentsReceivedFilters : apis.getReceipts;
  const methods = "post"
  if (page_number) {
    request = request + `?page_number=${page_number}`
    delete req?.payload?.page_number;
  }
  delete req.payload?.isPaymentsReceived
  delete req.payload?.isFilters
  return sagaFunctions(GetReceipts, methods, request, req.payload, {}, true)();
}

function getItems(req) {
  return sagaFunctions(GetItems, 'post', apis.getItems, req.payload, {}, true)();
}


function getTaxItems(req) {
  return sagaFunctions(GetTaxItems, 'get', apis.companyTaxTypes, req.payload, {}, 'settings')();
}


function paymentMade(req) {
  let request = apis?.filterPayment;
  const page = req.payload?.page_number
  delete req.payload?.isFilter;
  if (page) {
    request = request + `?page_number=${page}`
  }
  return sagaFunctions(PaymentMade, "post", request, req.payload, {}, true)();
}

// Receipts saga start
function receiptsget(req) {
  let requestAPI;
  if (req.payload.params.module_type === "DELIVERY_CHALLAN") {
    requestAPI = apis?.challanGetAPI
  } else if (req.payload.params.module_type === "QUALITY_CHECK") {
    requestAPI = apis?.qcCheckGETAPI
  } else if (req.payload.params.module_type === "RETURN_CHECK") {
    requestAPI = apis?.returnsGETAPI
  } else {
    requestAPI = apis?.receiptsGetAPI
  }
  return sagaFunctions(ReceiptsGet, "post", requestAPI, req.payload, {}, true)();
}
function receiptsCreate(req) {
  const isDelivery = req.payload.params.module_type === "DELIVERY_CHALLAN"
  const isUpdate = req?.payload?.id
  let requestAPI = isDelivery ? isUpdate ? apis?.challanUpdateAPI : apis?.challanCreateAPI : isUpdate ? apis?.receiptsUpdateAPI : apis.receiptsCreateAPI
  return sagaFunctions(ReceiptsCreate, "post", requestAPI, req.payload, {}, true)();
}
function receiptsBYID(req) {
  let requestAPI;
  if (req.payload.module_type === "DELIVERY_CHALLAN") {
    requestAPI = apis?.challaBYIDAPI
  } else if (req.payload.module_type === "QUALITY_CHECK") {
    requestAPI = apis?.qcCheckBYIDAPI
  } else if (req.payload.module_type === "RETURN_CHECK") {
    requestAPI = apis?.returnsBYIDAPI
  } else {
    requestAPI = apis?.receiptsBYIDAPI
  }
  return sagaFunctions(ReceiptsBYID, "get", requestAPI, req.payload, {}, true)();
}
function receiptsSubmit(req) {
  const isDelivery = req?.payload?.params?.module_type === "DELIVERY_CHALLAN"
  let requestAPI = isDelivery ? apis?.challanSubmitAPI : apis?.receiptsSubmitAPI
  return sagaFunctions(ReceiptsSubmit, "post", requestAPI, req.payload, {}, true)();
}

export function* purchasesWatcher() {
  yield takeLatest(Vendors.REQUEST, getVendorBills);
  yield takeLatest(PlaceOfSupplies.REQUEST, getPlaceOfSupplies);
  yield takeLatest(VendorsByName.REQUEST, getVendorsByName);
  yield takeLatest(VendorsById.REQUEST, getVendorsById);
  yield takeLatest(VendorTDS.REQUEST, getVendorTDS);
  yield takeLatest(PaymentTerms.REQUEST, getPaymentTerms);
  yield takeLatest(SaveBeneficiary.REQUEST, saveBeneficiary);
  yield takeLatest(UpdateBeneficiary.REQUEST, updateBeneficiary);
  yield takeLatest(SavePlaceSupplies.REQUEST, savePlaceSupply);
  yield takeLatest(GetReceipts.REQUEST, getReceipts);
  yield takeLatest(GetItems.REQUEST, getItems);
  yield takeLatest(GetTaxItems.REQUEST, getTaxItems);
  yield takeLatest(PaymentMade.REQUEST, paymentMade);

  yield takeLatest(ReceiptsGet.REQUEST, receiptsget);
  yield takeLatest(ReceiptsCreate.REQUEST, receiptsCreate);
  yield takeLatest(ReceiptsBYID.REQUEST, receiptsBYID);
  yield takeLatest(ReceiptsSubmit.REQUEST, receiptsSubmit);

}
