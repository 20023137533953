import React from "react";

import { Button, message, Modal, Space, Table, Typography } from 'antd';
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AddDesignations, deleteDesignations, DeleteDesignations, getDesignations
} from '../../../../actions/companySettings';
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { objectToFormData } from '../../../../utils';
import AddGrades from "./AddGrade";

const { Title } = Typography;


message.config({
  maxCount: 3,
});

const Grades = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState('center');
  const [selectedId, setSelectedId] = React.useState('');
  // const [permissionsData, setPermissionsData] = React.useState({});
  const [designationsData, setDesignationsData] = React.useState([]);
  // const rolesPermissionResponse = useSelector(state => state.companySetting.getGradesPermissions || {});
  const getDesignationsResponse = useSelector(state => state.companySetting.getDesignations || {});
  const addDesignationsResponse = useSelector(state => state.companySetting.addDesignations || {});
  const deleteDesignationsResponse = useSelector(state => state.companySetting.deleteDesignations || {});
  const { t } = useTranslation()

  const totalRecords = useSelector(state=>state.companySetting.getDesignations?.data?.total_count)

  

  const columns = [
    {
      title: t('user_title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('user_grade_degree'),
      dataIndex: 'grades',
      key: 'grades',
      render: (val) =>val&&val.length ? val.map((item)=>item.level).join(","):"",
    },
  ];

  const tableAction = [
    {
      title: t('actions'),
      key: 'action',
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button disabled={!hasWriteAccess} onClick={(e) => { showEditModal(record.id) }}><img src={EditIcon} alt={"logo"} className="action-logo" /></button>
          <button disabled={!hasWriteAccess} onClick={(e) => { showDeleteModal(record.id) }}><img src={DeleteIcon} alt={"logo"} className="action-logo" /></button>
        </Space>
      ),
    },
  ];

  React.useEffect(() => {

    // dispatch(getGradesPermissions({}));
    dispatch(getDesignations({}));
    dispatch(headerTitles({
      title: 'grade_designations',
      description: ""
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  React.useEffect(() => {
    const { data: { data: designations = [] , status = false } = {} } = getDesignationsResponse;
    
    if (status) {
      setDesignationsData(designations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDesignationsResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = '', status = "", error = "" } = {} } = addDesignationsResponse;
    if (error === false) {
      if (selectedId)
        message.success(<span className="messageText">{res_message?.replace("saved", "updated")}</span>);
      else
        message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getDesignations({}));
      dispatch({
        type: AddDesignations.RESET
      })
    }
    if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddDesignations.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDesignationsResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = '', status = '' } = {} } = deleteDesignationsResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getDesignations({}));
      dispatch({
        type: DeleteDesignations.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDesignationsResponse]);


  const showModal = () => {
    setSelectedId('');
    setIsModalVisible(true);
    setIsModalType('right');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType('center');
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType('right');
  };

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t('delete_grade_are_u_sure')}</Text><br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() => dispatch(deleteDesignations({ id: selectedId }))}
        >{t('delete')}</Button>
        <Button key="2" className="cancelButton ml1 mt1" onClick={() => handleCancel()}>{t('cancel')}</Button>
      </>
    );
  }



  function handleButtonClick(e) {
    showModal();
  }


  return (
    <>
      {isModalVisible && <Modal
        title={modalType === 'center' ?
          <><img src={DeleteIcon} alt={"logo"} className="action-logo" />{t('delete_grade')}</> :
          <Title level={4}>{selectedId ? t('Edit Department') : t('New Department')} &nbsp;<span className="text-small">{t('enter_details')}</span></Title>
        }
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={modalType === 'center' ? "center-aligned-modal" : "right-alinged-modal-small"}
      >
        {modalType === 'center' ? renderDeleteOption() : <AddGrades id={selectedId} handleCancel={handleCancel} />}
      </Modal>}
      {
        <Button disabled={!hasWriteAccess} onClick={handleButtonClick} className="mb1 ml1 addNew primaryButton">
          <img src={AddNewIcon} alt={"logo"} className="addNew-logo" />{t('addNew')}
        </Button>
      }
      <Table rowKey="id" columns={[...columns, ...tableAction]} dataSource={designationsData} className="settingsTable" 
      pagination={{
        total: totalRecords || 0,
        onChange: (pagee) =>
          dispatch(
            getDesignations({  page_number: pagee  })
          ),
      }}
      /></>
  );
};

export default Grades;


