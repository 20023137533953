import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  get_currencies,
  get_seed_data,
} from "../../actions/masterdata/masterdata";
import AddPayTermsModal from "../Common/AddPayTerms";
import AddSource from "../Common/AddSource";
import { FormInput, FormSelect } from "../inputs";

export const GST_TREATMENT = [
  {
    label: (
      <p>
        Registered Business Regular
        <p
          style={{
            color: "#33333380",
            maxWidth: "100%",
          }}
        >
          Business that is registered under GST
        </p>
      </p>
    ),
    value: "GST01",
  },
  {
    label: (
      <p>
        Registered Business Composition
        <p
          style={{
            color: "#33333380",
          }}
        >
          Business that is registered under the Composition Scheme in GST
        </p>
      </p>
    ),
    value: "GST02",
  },
  {
    label: (
      <p>
        Overseas
        <p
          style={{
            color: "#33333380",
            width: "400px",
          }}
        >
          Person with whom you do import or export of Supplies outside India
        </p>
      </p>
    ),
    value: "GST03",
  },
];

const AddVendorOtherInformation = ({ control, register, errors, setError, watch, id, isCustomerPortal }) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [isAddSource, setIsAddSource] = useState(false);
  const { t } = useTranslation()
  const place_of_supply = useSelector((state) =>
    state?.seed_data?.place_of_supply?.data?.length
      ? state?.seed_data?.place_of_supply?.data?.map((ele) => ({
        label: ele.seed_value,
        value: ele.seed_value,
      }))
      : []
  );
  const payment_terms = useSelector((state) =>
    state?.seed_data?.payment_terms?.data?.length
      ? state?.seed_data?.payment_terms?.data?.map((ele) => ({
        label: ele.term_name,
        value: ele.term_name,
      }))
      : []
  );
  // const tds_types = useSelector((state) =>
  //   state?.seed_data?.tds_types?.data?.length
  //     ? state?.seed_data?.tds_types?.data?.map((ele) => ({
  //       label: ele.tax_name,
  //       value: ele.tax_name,
  //     }))
  //     : []
  // );
  const currenciesRes = useSelector((state) =>
    state?.currencies?.data?.data?.length
      ? state?.currencies?.data?.data?.map((ele) => ({
        label: ele.iso_code,
        value: ele.iso_code,
      }))
      : []
  );

  const currencies = Array.from(new Set(currenciesRes.map(obj => obj.value))).map(value => currenciesRes.find(obj => obj.value === value));

  useEffect(() => {
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "place_of_supply",
      })
    );

    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "payment_terms",
      })
    );
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "tds_types",
      })
    );
    dispatch(get_currencies());
  }, []);

  const handleAddPayment = () => {
    setIsVisible(true);
  };
  const handleAddSource = () => {
    setIsAddSource(true);
  };

  const gst_treatment = watch('gst_treatment')
  useEffect(() => {
    if (gst_treatment === "UnRegistered Business" || gst_treatment === "Overseas" || !gst_treatment) {
      setError('gstin', '')
    }
  }, [gst_treatment])
  console.log({ errors });
  return (
    <>
      <AddSource
        companyId={localStorage.getItem("company_id")}
        isVisible={isAddSource}
        onCancel={() => {
          setIsAddSource(false);
        }}
      />
      <AddPayTermsModal
        companyId={localStorage.getItem("company_id")}
        isVisible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
      />
      <div>
        <FormSelect
          control={control}
          {...register('gst_treatment', {
            required: !gst_treatment ? false : "GST Treatment is required."
          })}
          errors={errors}
          label={t('gst_treatment')}
          required={!gst_treatment ? false : true}
          hideCreateOption
          options={[
            {
              value: 'Registered Business Regular',
              label: 'Registered Business Regular',
              description: 'Business that is registered under GST',
            },
            {
              value: 'Registered Business - Composition',
              label: 'Registered Business - Composition',
              description: 'Business that is registered under the Composition Scheme in GST',
            },
            {
              value: 'Overseas',
              label: 'Overseas',
              description: 'Person with whom you do import or export of Supplies outside India',
            },
            {
              value: 'UnRegistered Business',
              label: 'UnRegistered Business',
              // description: 'Business that is registered under the Composition Scheme in GST',
            },
            {
              value: 'Special Economic Zone',
              label: 'Special Economic Zone',
              // description: 'Business that is registered under the Composition Scheme in GST',
            }, {
              value: 'Deemed Export',
              label: 'Deemed Export',
              // description: 'Business that is registered under the Composition Scheme in GST',
            }, {
              value: 'Tax Deductor',
              label: 'Tax Deductor',
              // description: 'Business that is registered under the Composition Scheme in GST',
            },
            {
              value: 'SEZ Developer',
              label: 'SEZ Developer',
              // description: 'Business that is registered under the Composition Scheme in GST',
            },
          ]}
          disabled={isCustomerPortal}
        />
      </div>
      <div className="pp-form-item">
        <FormInput
          control={control}
          {...register('gstin', {
            required: gst_treatment === "UnRegistered Business" || gst_treatment === "Overseas" || !gst_treatment ? false : "GSTIN / UIN is required"
          })}
          required={gst_treatment === "UnRegistered Business" || gst_treatment === "Overseas" || !gst_treatment ? false : true}
          errors={errors}
          label={t('gst_uin')}
          placeholder="Enter Tax ID (GSTIN / UIN / VAT)"
          disabled={isCustomerPortal}
        />
      </div>
      <div className="pp-form-item">
        <FormInput
          control={control}
          {...register('pan_number', { required: !gst_treatment ? false : "Pan Number is required" })}
          required={!gst_treatment ? false : true}
          errors={errors}
          label={t('pan_no')}
          placeholder="Enter PAN"
          disabled={isCustomerPortal}
        />
      </div>

      <div className="pp-form-item">
        <FormSelect
          control={control}
          {...register("tax_settings.place_of_supply", { required: !gst_treatment ? false : "Source of Supply is required" })}
          required={!gst_treatment ? false : true}
          errors={errors}
          label={t('source_of_supply')}
          hideCreateOption={true}
          options={place_of_supply}
          errorMessage={errors?.tax_settings?.place_of_supply?.message}
          onAddItem={handleAddSource}
          disabled={isCustomerPortal}
        />
      </div>

      <div className="pp-form-item">
        <FormSelect
          control={control}
          {...register("configurations.currency", {
            required: "Currency is required."
          })}
          errors={errors}
          placeholder={"Select Currency"}
          hideCreateOption={true}
          required={true}
          label={t('currency')}
          options={currencies}
          errorMessage={errors?.configurations?.currency?.message}
          disabled={!!id || isCustomerPortal}
        />
      </div>

      <div className="pp-form-item">
        <FormSelect
          control={control}
          {...register("configurations.payment_terms")}
          errors={errors}
          label={t('payment_term')}
          options={payment_terms}
          onAddItem={handleAddPayment}
          disabled={isCustomerPortal}
        />
      </div>
      {/* <div className="pp-form-item">
        <FormSelect control={control} {...register('tds')} errors={errors} label="TDS" options={tds_types} />
      </div> */}
    </>
  );
};

export default AddVendorOtherInformation;
