import {
  FileTextOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Typography, message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Topup,
  cards,
  corporateBudget,
  topup,
} from "../../actions/cards/cards";
import { headerTitles } from "../../actions/masterdata/masterdata";
import uparrow from "../../assets/icons/arrow-up.svg";
import { capitalize, objectToFormData } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import Table from "../Common/Table/Table";
import data from "./transfers.json";
const { Title } = Typography;

const Transfers = ({ hasWriteAccess }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [recordData, setRecordData] = React.useState({});
  const [modalData, setModalData] = React.useState([]);
  const [topupValue, setTopupValue] = React.useState({});
  const [topupType, setTopupType] = React.useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cardsResponse = useSelector((state) => state.cards.cards || {});
  const topupResponse = useSelector((state) => state.cards.topup || {});
  // eslint-disable-next-line
  const { data: { response: { cards: cardsList = [] } = {} } = {} } =
    cardsResponse;

  const columns = [
    {
      title: t('card_ref_no'),
      dataIndex: "card_reference_no",
      key: "card_reference_no",
    },
    {
      title: t('name'),
      dataIndex: "title",
      key: "title",
      render: (text, record) => <div>{record.employee_details?.name}</div>,
    },
    // {
    //   title: "Total Loaded",
    //   dataIndex: "title",
    //   key: "title",
    //   render: (text) => <div>Rs. 1,15,000</div>,
    // },
    {
      title: t('available_balance'),
      dataIndex: "cardBalance",
      key: "cardBalance",
      render: (text, record) => <div>{record?.data?.cardBalance}</div>,
    },
    // {
    //   title: "Available Limit",
    //   dataIndex: "title",
    //   key: "title",
    //   // render: (text) => <div>Rs. 85,000</div>,
    // },
    {
      title: t('email'),
      dataIndex: "title",
      key: "title",
      render: (text, record) => <div>{record.employee_details.email}</div>,
    },
    {
      title: t('phone'),
      dataIndex: "title",
      key: "title",
      render: (text, record) => <div>{record.employee_details.mobile}</div>,
    },
    // {
    //   title: "Topup Wallets",
    //   dataIndex: "title",
    //   key: "title",
    //   render: (text, record) => (
    //     <div>{record.wallets?.map((item) => item.wallet_name).join(", ")}</div>
    //   ),
    // },
    {
      title: t('status'),
      dataIndex: "status",
      key: "status",
      render: (text) => <div className={`status ${text}`}>{text}</div>,
    },
    {
      title: <div className="table-placeholder">{t('load_money')}</div>,
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <button
          className="arrowBackground flex-center"
          onClick={() => setIsModalVisible(true)}
          disabled={!hasWriteAccess}
        >
          <img src={uparrow} alt="" width={18} />
        </button>
      ),
    },
    // {
    //   title: <div className="table-placeholder">Withdraw Money</div>,
    //   dataIndex: "title",
    //   key: "title",
    //   render: (text) => <div className="arrowBackground" onClick={() => setIsModalVisible(true)}><ArrowDownOutlined /></div>,
    // },
  ];

  const modalColumns = [
    {
      title: t('wallet_type'),
      dataIndex: "wallet_name",
      key: "wallet_name",
      width: 200,
      render: (text) => (
        <div>
          <FileTextOutlined /> &nbsp;{capitalize(text)}
        </div>
      ),
    },
    // {
    //   title: "Remaining Limit",
    //   dataIndex: "remaining",
    //   key: "remaining",
    //   // render: (text) => <div>₹ 11,200.00</div>,
    // },
    {
      title: t('available_balance'),
      dataIndex: "balance",
      key: "balance",
      render: (text) => <div><BaseCurrency /> {text}</div>,
    },
    {
      title: t('Amount'),
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <Input
          style={{ width: "100px" }}
          key={record.id}
          value={topupValue[record.wallet_identifier] || ""}
          onChange={(e) => ontopupChange(e, record.wallet_identifier)}
        />
      ),
    },
    {
      title: t('action'),
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <button
          className="arrowBackground flex-center"
          disabled={!(topupType === record.wallet_identifier)}
          onClick={() => handleTopupSubmit(record.wallet_identifier)}
        >
          <img src={uparrow} alt="" width={18} />
        </button>
      ),
    },
  ];

  React.useEffect(() => {
    dispatch(cards({ status: "A" }));
    dispatch(headerTitles({
      title: 'transfer',
      description: ""
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const {
      data: {
        message: res_message1 = "",
        status = "",
        errors: { message: res_message = "" } = {},
      } = {},
    } = topupResponse;
    if (status) {
      message.success(<span className="messageText">{res_message1}</span>);
      dispatch(corporateBudget());
      dispatch(
        cards({
          status: "A",
        })
      );
      setTopupValue("");
      setTopupType("");
      dispatch({
        type: Topup.RESET,
      });
      handleCancel();
    } else {
      if (status === false) {
        res_message &&
          message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: Topup.RESET,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topupResponse]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRowClick = (evt, record) => {
    evt.preventDefault();
    if (record) {
      setModalData([...modalData, record]);
      setRecordData(record);
    }
  };

  const ontopupChange = (e, type) => {
    let value = e.target.value;

    if (value === "" || !Number.isNaN(Number(value))) {
      console.log("@123=====", value);
      setTopupType(type);
      setTopupValue({ [type]: value });
    }
  };

  const handleTopupSubmit = (val) => {
    const payload = {};
    payload["card_id"] = recordData.id;
    payload["amount"] = topupValue[topupType];
    payload["wallet_identifier"] = topupType;

    dispatch(topup(objectToFormData({ ...payload })));
  };

  const renderQuickLoad = () => {
    return (
      <>
        <div className="quickLoadHead">
          <Row>
            <Col span={7}>
              <p>{t('card_holder_name')}</p>
              <p>{t('card_holder_email')}</p>
              <p>{t('card_kit_number')}</p>
            </Col>
            <Col span={16}>
              <span>{recordData?.employee_details?.name}</span>
              <br />
              <span title={recordData?.employee_details?.email}>{recordData?.employee_details?.email}</span>
              <br />
              <span>{recordData?.card_reference_no}</span>
            </Col>
          </Row>
        </div>
        <br />

        <Table rowKey="id" columns={modalColumns} data={recordData?.wallets} />
        <div>
          <Button
            key="2"
            className="cancelButton  mt1"
            onClick={() => handleCancel()}
          >
            {t('cancel')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        title={
          <Title level={4}>
            {t('quick_load')} &nbsp;
            <span className="text-small">{t('enter_details')}</span>
          </Title>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className="right-alinged-modal"
      >
        {renderQuickLoad()}
      </Modal>
      <Table
        rowKey="id"
        handleRowClick={handleRowClick}
        placeholder={t('search_by_ref')}

        columns={columns}
        data={Array.isArray(data) ? data : []}
        // cardsList}
        handleSearch={(value) => {
          dispatch(cards({ status: "A", search: value }));
        }}
        isTopFilter={true}
        filter={true}
        isMore={true}
        scroll={{ x: 0 }}
        className="tableStyled"
      />
    </>
  );
};

export default Transfers;
