import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  getAllOrganisations,
  getOneCompany,
} from "../../../actions/cards/cards";
import "./RootFi.css";
import { roofiFiData } from "./RootFiData";
import useIntegration from "../../../config/useIntegration";
import { apis } from "../../../config/APIs";
import { message } from "antd";

function RootFiMain() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { onCall: createQuickBooks } = useIntegration({
    api: apis.disconnectQuickbooks,
    method: "get",
  });
  // const getOneCompanyResponse = useSelector(
  //   (state) => state?.cards?.getOne_company
  // );
  const organisationConnectionResponse = useSelector(
    (state) => state?.cards?.getAll_organisations?.data?.data || []
  );
  console.log({ organisationConnectionResponse });
  // useEffect(() => {
  //   if (getOneCompanyResponse?.data?.error === false) {
  //     localStorage.setItem(
  //       "integrationStatus",
  //       getOneCompanyResponse?.data?.data?.data?.Connections[0]?.status
  //     );
  //   }
  // }, [getOneCompanyResponse]);

  useEffect(() => {
    dispatch(getAllOrganisations({}));
  }, []);

  const integration_status = localStorage.getItem("integrationStatus");
  // console.log('ConnectionStatus=', connectionStatus, 'IntegrationStaus=',)

  useEffect(() => {
    if (location.state?.reloadRootiFiMain) {
      dispatch(getOneCompany({ url: location?.state?.company_id }));
      const stateWithoutReload = { ...location.state };
      delete stateWithoutReload.reloadRootiFiMain;
      delete stateWithoutReload.delete;

      history.replace({ ...location, state: stateWithoutReload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.reloadRootiFiMain]);

  return (
    <section className="contentWrapper">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1.5rem",
          flexWrap: "wrap",
        }}
      >
        {roofiFiData.map((item) => {
          const connectionStatus = organisationConnectionResponse?.find((ele) =>
            ele?.integrations?.includes(item.company_name)
          )?.status;
          const ordData = organisationConnectionResponse?.find((ele) =>
            ele?.integrations?.includes(item.company_name)
          );

          return (
            <div className="integration">
              <div
                className="integration-card"
                key={item?.id}
                onClick={() => {
                  if (connectionStatus === "DISCONNECTED" && item?.id === 2) {
                    createQuickBooks({
                      params: {
                        status: "RE_CONNECT",
                        client_secret: ordData?.client_secret,
                        client_id: ordData?.client_id,
                        company_id: ordData?.company_id,
                      },
                    })
                      .then((res) => {
                        window.open(
                          res?.data,
                          "intergration",
                          "height=600,width=850"
                        );
                      })
                      .catch((err) => {
                        message.error(
                          <span className="messageText">
                            {err?.response?.data?.message}
                          </span>
                        );
                      });
                  } else {
                    history.push({
                      pathname: `/settings/rootFi-integration/${item?.id}/connections`,
                      state: {
                        recordData: {
                          ...item,
                          ...ordData,
                          id: item?.id,
                          _id: ordData?.id,
                        },
                        status: connectionStatus,
                      },
                    });
                  }
                }}
              >
                {connectionStatus === "HEALTHY" && (
                  <span className="banner active">Active</span>
                )}
                {connectionStatus === "DISCONNECTED" && (
                  <span className="banner inactive">In-Active</span>
                )}
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <img
                    src={item?.cardImageSrc}
                    alt={item?.cardTitle}
                    style={{
                      objectFit: "contain",
                      maxWidth: "10rem",
                      marginTop: "1rem",
                      maxHeight: "3.5rem",
                    }}
                  />
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <h2>{item?.cardTitle}</h2>
                  <p>{item?.cardDescription}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default RootFiMain;
