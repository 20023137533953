import React from 'react';

const statementsSVG = ({ scale, opacity, fill }) => {
  return (
    <svg style={{
      scale: scale ? scale : 1,
      zoom: scale ? scale : 1
    }} xmlns="http://www.w3.org/2000/svg" width={"22"} height={"22"} viewBox="0 0 456.095 478.34">
      <path id="noun-invoice-4025686" d="M233.65,40.832A45.249,45.249,0,0,0,188.6,85.879l0,176.64H133.662a11.668,11.668,0,0,0-11.711,11.621V408.05a45.153,45.153,0,0,0,42.77,44.82,11.462,11.462,0,0,0,2.277.23h88.273v54.438a11.663,11.663,0,0,0,11.711,11.621H544.2a33.987,33.987,0,0,0,33.836-33.836l.008-410.55A34.088,34.088,0,0,0,544.21,40.82Zm0,23.355a21.356,21.356,0,0,1,21.625,21.691v343.89H206.1a44.407,44.407,0,0,0,5.855-21.715V85.873a21.411,21.411,0,0,1,21.691-21.691Zm87.41,64.145H514.15a11.678,11.678,0,1,1,0,23.355H321.06a11.678,11.678,0,0,1,0-23.355Zm0,70.023H514.15a11.678,11.678,0,1,1,0,23.356H321.06a11.678,11.678,0,0,1,0-23.355Zm0,70.023H514.15a11.666,11.666,0,1,1,0,23.332H321.06a11.666,11.666,0,0,1,0-23.332Zm0,70.023H514.15a11.621,11.621,0,1,1,0,23.242H321.06a11.621,11.621,0,0,1,0-23.242Zm0,69.934,193.09,0a11.668,11.668,0,1,1,0,23.336H321.06a11.668,11.668,0,0,1,0-23.336Z" transform="translate(-121.951 -40.82)" fill={fill || "#fff"} opacity={opacity || "0.497"} />
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width="23.188" height="21.564" viewBox="0 0 23.188 21.564" style={{
    //   scale: scale ? scale : 1,
    //   zoom: scale ? scale : 1
    // }}>
    //   <path id="Path_18973" data-name="Path 18973" d="M23.188,7.635A4.147,4.147,0,0,0,19.052,3.5H4.135a4.134,4.134,0,0,0-.067,8.267V23.209a1.86,1.86,0,0,0,1.855,1.855h6.714V5.819h2.319V25.064h2.309a1.86,1.86,0,0,0,1.855-1.855V11.767A4.146,4.146,0,0,0,23.188,7.635Zm-20.869,0A1.817,1.817,0,0,1,4.068,5.822V9.448A1.817,1.817,0,0,1,2.319,7.635Zm16.8,1.813V5.822a1.814,1.814,0,0,1,0,3.626Z" transform="translate(0 -3.5)" fill={fill || "#fff"} opacity={opacity || "0.497"} />
    // </svg>
  );
};

export default statementsSVG;