import { Form, Input, Typography, message } from "antd";
import jwt_decode from "jwt-decode";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import * as yup from "yup";
import { Is_Login, Local_Variables, Login } from "../../actions/auth";
import endpointOms from "../../config/AxiosOMS";
import { RouteConstants } from "../../utils";
import { login } from "./../../actions/auth";
import { LOGO } from "../../config/Images";
const { Text, Title } = Typography;

message.config({
  maxCount: 3,
});

const LoginForm = () => {
  // let [isLoading, setIsLoading] = React.useState(false);
  let [isEmp, setIsEmp] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loginResponse = useSelector((state) => state.auth.login || {});
  const responseLogin = useSelector((state) => state?.auth?.localVariables);

  React.useEffect(() => {
    if (loginResponse?.data?.error == false) {
      if (
        loginResponse?.data?.data?.auth_token &&
        loginResponse?.data?.data?.web_access_allowed !== false
      ) {
        console.log("login response note", loginResponse);
        message.success(
          <span className="messageText">{loginResponse.data.message}</span>
        );
        localStorage.setItem(
          "tokenPayApi",
          loginResponse?.data?.data?.auth_token
        );
        localStorage.setItem("country", loginResponse?.data?.data?.country);
        localStorage.setItem("token", loginResponse?.data?.data?.auth_token);
        localStorage.setItem("name", loginResponse?.data?.data?.name);
        localStorage.setItem("role", loginResponse?.data?.data?.role);
        localStorage.setItem(
          "portal_access",
          loginResponse?.data?.data?.portal_access
        );
        localStorage.setItem("is_admin", loginResponse?.data?.data?.is_admin);
        localStorage.setItem("user", loginResponse?.data?.data?.user);
        localStorage.setItem(
          "employee_id",
          loginResponse?.data?.data?.employee_id
        );
        localStorage.setItem("user_id", loginResponse?.data?.data?.user_id);
        localStorage.setItem(
          "time_format",
          loginResponse?.data?.data?.time_format
        );
        localStorage.setItem(
          "is_super_admin",
          loginResponse?.data?.data?.is_super_admin
        );
        localStorage.setItem(
          "is_kyb_completed",
          loginResponse?.data?.data?.is_kyb_completed
        );
        localStorage.setItem(
          "isSalesOrderView",
          loginResponse?.data?.data?.view_sales_orders
        );
        localStorage.setItem(
          "isCreditNotesView",
          loginResponse?.data?.data?.display_credit_notes
        );

        localStorage.setItem(
          "menu",
          JSON.stringify(
            loginResponse?.data?.data?.menu
              ? loginResponse?.data?.data?.menu
              : {}
          )
        );
        localStorage.setItem(
          "base_currency",
          JSON.stringify(loginResponse?.data?.data?.base_currency)
        );
        try {
          const token = jwt_decode(loginResponse?.data?.data?.auth_token);

          localStorage.setItem("user_id_payApi", token.user_id);
          localStorage.setItem("company_id", token.company_id);
          localStorage.setItem("company_name", token.company_name);
          // localStorage.setItem("switchBranch", token.company_id)
          // .get(
          //   `v1/ems/0/check/generateToken?user_id=${token.user_id}&company_id=${token.company_id}&session_id=${token.session_key}`
          // )
          endpointOms
            .post(
              `/api/admin/generate_token?user_id=${token.user_id}&company_id=${token.company_id}`
            )
            .then((responseToken) => {
              console.log("login response", responseToken);
              if (
                responseToken?.status === 200 &&
                responseToken?.data?.auth_token
              ) {
                const payApiToken = responseToken?.data?.auth_token;
                const payApiTokenDecoded = jwt_decode(payApiToken);
                localStorage.setItem("token", payApiToken);
                localStorage.setItem("user_id", payApiTokenDecoded.user_id);
                localStorage.setItem(
                  "switchBranch",
                  payApiTokenDecoded.company_id
                );
              }
            });
        } catch (error) {
          console.error(error);
        }

        dispatch({
          type: Local_Variables,
          payload: {
            is_admin:
              loginResponse?.data?.data?.is_admin ||
              loginResponse?.data?.data?.is_super_admin,
          },
        });
        dispatch({
          type: Is_Login,
          payload: {
            is_login: true,
          },
        });
      } else {
        loginResponse.data.web_access_allowed === false
          ? message.error(
              <span className="messageText">
                {"You are not authorized to access web portal."}
              </span>
            )
          : message.error(
              <span className="messageText">
                {loginResponse?.data?.errors ?? loginResponse?.data?.message}
              </span>
            );
      }

      dispatch({
        type: Login.RESET,
      });
    } else {
      if (
        loginResponse?.data?.errors ||
        loginResponse?.data?.message ||
        loginResponse?.message
      ) {
        message.error(
          <span className="messageText">
            {loginResponse?.data?.errors ??
              loginResponse?.data?.message ??
              loginResponse?.message}
          </span>
        );
        dispatch({
          type: Login.RESET,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse]);
  React.useEffect(() => {
    if (responseLogin?.is_login) {
      history.push("/two-step-verify");
      // if (localStorage?.getItem("portal_access")?.split(",")?.length <= 1) {
      //   if (
      //     localStorage?.getItem("portal_access")?.split(",")?.includes("web")
      //   ) {
      //     history.push("/organisation");
      //   } else if (
      //     localStorage
      //       ?.getItem("portal_access")
      //       ?.split(",")
      //       ?.includes("customer")
      //   ) {
      //     localStorage.setItem("isCustomer", true);
      //     history.push("/customer/dashboard");
      //   } else if (
      //     localStorage?.getItem("portal_access")?.split(",")?.includes("vendor")
      //   ) {
      //     localStorage.setItem("isVendor", true);
      //     history.push("/vendor/dashboard");
      //   }
      // } else {
      //   history.push("/portal");
      // }
      dispatch({
        type: Is_Login,
        payload: {
          is_login: false,
        },
      });
    }
  }, [responseLogin?.is_login]);

  const handleSubmit = (values) => {
    const payload = {};
    console.log({ values });

    ["email", "password"].forEach((item) => {
      payload[item] = values[item];
    });

    if (window.JSEncrypt) {
      let encrypt = new window.JSEncrypt();
      let public_key = `-----BEGIN PUBLIC KEY-----
          MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxzNtwSrdb2cev3VIDbo6
          s84F+goHm1YqwXdw2ToyI97WETmTgeRVauPZGivjwuRskBGGQwu25G5CfpHLXS3P
          /iC2XsQj1qa+2HzGMwY198IKs0I66LAxYNRo/0ARg4z3ow8X28qLX9E4LnhUqni4
          BNVN0SIOZrMaqrllAEgxZglihBlY3EMVfZG7rsV6Vgz8INKJczCWUbcTg2NHl7r1
          ZF2AACO4guM5oKgf/QKvAxZnc4T+0VhD2rTE2uF3+s9x0TV3UINyw2/rXyF1dW9J
          CG5cE2dzRm45dKVAJUiT85jpEEi4v9DqOqUf6flUpIrA8y6WeDeuWyL3+574CCMy
          WQIDAQAB
          -----END PUBLIC KEY-----`;
      encrypt.setPublicKey(public_key);
      payload.password = encrypt.encrypt(payload.password);
      dispatch(login(payload));
    }
    // setIsLoading(true);
  };

  return (
    <div className="login-form">
      <div className="head-sec rtl-text-right mb2">
        <img src={LOGO} alt={"logo"} className="logo mb2" />
        <Title level={3}>{t("welcomeBack")}</Title>
        <Text>{t("signIntoAcc")}</Text>
      </div>
      <Form onFinish={handleSubmit} layout="vertical">
        {isEmp ? (
          <Form.Item
            label={t("empId")}
            name="emp_id"
            rules={[
              {
                required: true,
                message: "Please input your employee id",
              },
              {
                pattern: /^[A-Za-z0-9]{1,25}$/,
                message: "Enter valid employee id",
              },
            ]}
          >
            <Input placeholder="Enter employee id" autoComplete="off" />
          </Form.Item>
        ) : (
          <Form.Item
            label={t("emailAddr")}
            name="email"
            rules={[
              {
                type: "email",
                message: t("email_valid"),
                validateTrigger: ["onSubmit"],
              },
              {
                required: true,
                message: t("email_required"),
                validateTrigger: ["onSubmit"],
              },
            ]}
          >
            <Input placeholder={t("enter_email")} autoComplete="off" />
          </Form.Item>
        )}

        <Form.Item
          label={t("password")}
          name="password"
          placeholder={t("enter_pwd")}
          rules={[
            {
              required: true,
              message: t("password_required"),
            },
          ]}
        >
          <Input.Password placeholder={t("enter_pwd")} />
        </Form.Item>
        <Form.Item>
          <button type="submit" className="loginButton primaryButton">
            {loginResponse?.loading ? (
              <ClipLoader
                loading={loginResponse?.loading}
                size={20}
                color="#ffffff"
              />
            ) : (
              t("login")
            )}
          </button>
          <Link to={RouteConstants.FORGOTPASSWORD}>
            <span className="text-dim ml2 cursor">{t("forgotPassword")}?</span>
          </Link>
        </Form.Item>
      </Form>
      <div className=""></div>

      <div className="mt5 d-flex-center mx-auto">
        <div className="text-dim footer-note login-footer t-center">
          <div className="mb2">
            <span className="or fw-500">
              {t("by_logging")}{" "}
              <span className="cursor login_terms or">
                {t("terms_conditions")}{" "}
              </span>{" "}
              {t("privacy_policy")}
            </span>
          </div>
          <div>
            <span className="or dim-login-text fw-500">
              {t("Zenus_footer_text")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
