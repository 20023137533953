import { Button, Modal } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { notifications } from "../../actions/auth";
import desktop from "../../assets/icons/desktop.png";
import documents from "../../assets/icons/documents.png";
import endpoint from "../../config/Axios";
import { apis } from "../../config/APIs";
import endpointNotificationApi from "../../config/AxiosNotificationsAPI";
import moment from "moment";

const NotificationsUI = ({ onClose, isVisible }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notifications_res = useSelector(
    (state) => state?.auth?.notifications?.data?.data?.result
  );

  useEffect(() => {
    dispatch(notifications());
  }, []);

  const goToPage = (page, id) => {
    switch (page) {
      case "advance":
        history.push("/expenses/advance-details/" + id)
        break;
      case "report":
        history.push("/expenses/report-details/" + id)
        break;
      case "trip":
        history.push("/expenses/trip-details/" + id)
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Modal
        onCancel={() => onClose()}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span
                className="title"
                style={{
                  fontSize: "1.25rem",
                  color: "#363636",
                  fontWeight: "600"
                }}
              >
                Notifications
              </span>
            </div>
          </div>
        }
        visible={isVisible}
      >
        <div className="notifications_ui">
          {/* <div className="Desktop">
            <div className="icon">
              <img src={desktop} />
              <span>Desktop Notifications</span>
            </div>
            <Button type="text"></Button>
          </div> */}
          <div>
            {notifications_res?.length
              ? notifications_res?.map((ele) => {
                return (
                  <div className={`${ele.seen ? "seen" : "not-seen"} loop_div`} onClick={() => {
                    const formData = new FormData()
                    formData.append("id", ele.id)
                    endpointNotificationApi.post(apis.update_notification, formData)
                    goToPage(ele?.data?.page, ele?.data?.id)
                    onClose();
                  }}>
                    <div className="container">
                      <div className="left_part">
                        <div className="left_part_icon">
                          <img src={documents} />
                        </div>
                      </div>
                      <div className="right_part">
                        <h4>{ele.title}</h4>
                        <span>{ele.body}</span>
                        <br/>
                        <span>{moment(ele.created_at).format("DD-MM-YYYY HH:mm:ss")}</span>
                      </div>
                    </div>
                    <div className="bottom_line"></div>
                  </div>
                );
              })
              : <div className="no-data">
                <span>No Data</span>
              </div>}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotificationsUI;
