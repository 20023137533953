import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import RootFiConnections from "./RootFiConnections";
import RootFiDetails from "./RootFiDetails";
import RootFiMain from "./RootFiMain";

function RootFiIntegration() {
  const [tab, setTab] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      headerTitles({
        title: t("integration"),
        description: t("integration_description"),
      })
    );
  }, []);

  const tabItems = [
    {
      key: 1,
      label: t("all_integrations"),
    },
    // {
    //   key: 2,
    //   label: "History",
    // },
  ];

  let { path, url } = useRouteMatch();

  return (
    <section>
      <div>
        <ul className="dashboard-tabs table-tabs ant-tabs">
          {tabItems?.map((ele) => (
            <li
              className={`ant-tabs-tab-btn ${
                ele.key === tab ? "tab-active" : ""
              }`}
              onClick={() => {
                setTab(ele.key);
                if (ele.key === 1) {
                  history.push("/settings/rootFi-integration");
                }
              }}
            >
              {ele.label}
            </li>
          ))}
        </ul>
        <Switch>
          <Route
            exact
            path={path}
            component={() => {
              return tab === 1 ? <RootFiMain /> : null;
            }}
          />
          {/* <Route path={`${path}/:id`} component={() => {
            return tab === 1 ? <RootFiDetails /> : null
          }} /> */}
          <Route
            path={`${path}/:id/connections/:status`}
            component={() => {
              return tab === 1 ? <RootFiDetails /> : null;
            }}
          />
          <Route
            path={`${path}/:id/connections`}
            component={() => {
              return tab === 1 ? <RootFiConnections /> : null;
            }}
          />
        </Switch>
      </div>
    </section>
  );
}

export default RootFiIntegration;
