import { useState } from "react";
import endpointPayApi from "./AxiosPdf";

const usePdfApi = ({ api, key }) => {
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const reset = () => {
    setPdfUrl(null);
    setLoading(false);
  };

  const onCall = async ({ headers, params }) => {
    setLoading(true);
    try {
      let result;
      result = await endpointPayApi.get(api, { headers, params });
      setLoading(false);
      setPdfUrl(result.data?.response?.[key]);
      return Promise.resolve(result.data?.response?.[key]);
    } catch (err) {
      setPdfUrl("");
      setLoading(false);
      return Promise.reject(err);
    }
  };

  return {
    loading,
    pdfUrl,
    reset,
    onCall,
  };
};

export default usePdfApi;
