import { take } from "lodash";
import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import { FetchPaymentLinks, SaveBulkUploads, FetchBulkUploads, SaveBulkUploadPaymentLink, DownloadBulkUploads, DeletePaymentLink } from "./transfers";

function fetchPaymentLinks(req) {
    return sagaFunctions(FetchPaymentLinks, "post", apis.getPaymentLink, req.payload, {}, true)();
}

function deletePaymentLink(req) {
    return sagaFunctions(DeletePaymentLink, "get", apis.deletePaymentLink, req.payload, {}, true)();
}

function saveBulkUploads(req) {
    return sagaFunctions(SaveBulkUploads, "post", apis.saveBulkUploads, req.payload, {}, true)();
}

function fetchBulkUploads(req) {
    let required;
    let type = req?.payload?.type
    if (type === "bulk_payment_history") {
        required = apis.fetchBulkUploadPaymentLink
    } else {
        required = apis.fetchBulkUploads
    }
    delete req?.payload?.type
    return sagaFunctions(FetchBulkUploads, type === "bulk_payment_history" ? "get" : "post", required, req.payload, {}, true)();
}

function saveBulkUploadPaymentLink(req) {
    return sagaFunctions(SaveBulkUploadPaymentLink, "post", apis.saveBulkUploadPaymentLink, req.payload, {}, true)();
}
function downloadBulkUploads(req) {
    return sagaFunctions(DownloadBulkUploads, "post", apis.downloadBulkUploads, req.payload, {}, true)();
}
export function* transfersWatcher() {
    yield takeLatest(FetchPaymentLinks.REQUEST, fetchPaymentLinks);
    yield takeLatest(DeletePaymentLink.REQUEST, deletePaymentLink);
    yield takeLatest(SaveBulkUploads.REQUEST, saveBulkUploads);
    yield takeLatest(FetchBulkUploads.REQUEST, fetchBulkUploads);
    yield takeLatest(SaveBulkUploadPaymentLink.REQUEST, saveBulkUploadPaymentLink)
    yield takeLatest(DownloadBulkUploads.REQUEST, downloadBulkUploads)
}