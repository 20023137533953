import { Button, Form, Modal } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../inputs';
import "../Currencies/currencies.css";

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';

const AddOrganisation = ({ open, onCancel, record }) => {
    const dispatch = useDispatch();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            owner_name: "",
            organisation_name: "",
            owner_email: "",
        },
        shouldUseNativeValidation: false,
    });

    const onSubmit = (data) => {
        console.log('Data=',data)
    }

    useEffect(() => {
        if (record) {
            setValue("owner_name", record?.owner);
            setValue("organisation_name", record?.organisation);
            setValue("owner_email", record?.owner_email);
        }
    }, [record])


    const handleClose = () => {
        reset();
        onCancel();
    }


    return (
        <>
            <div className='add-currency-container'>
                <Modal
                    title={!!record ? t('edit_organisation') : t('add_organisation')}
                    open={open}
                    onCancel={() => handleClose()}
                    footer={[]}
                    className="right-alinged-modal add-currency-modal"
                    width={650}
                >
                    <Form onFinish={handleSubmit(onSubmit)} className='h100'>
                        <div className='form-wrapper'>
                            <div className='form-fields'>
                                <div className='pp-form-item form-select'>
                                    <FormInput
                                        control={control}
                                        {...register("owner_name", {
                                            required: {
                                                value: true,
                                                message: t('owner_name_required')
                                            },
                                        }
                                        )}
                                        readOnly
                                        placeholder={t('owner_name_placeholder')}
                                        errors={errors}
                                        label={t('owner_name')}
                                    />

                                </div>
                                <div className='pp-form-item form-select'>
                                    <FormInput
                                        control={control}
                                        {...register("organisation_name", {
                                            required: {
                                                value: true,
                                                message: t('organisation_name_required')
                                            },
                                        }
                                        )}
                                        placeholder={t('organisation_name_placeholder')}
                                        errors={errors}
                                        label={t('organisation_name')}
                                    />
                                </div>
                                <div className='pp-form-item form-select'>
                                    <FormInput
                                        control={control}
                                        {...register("owner_email", {
                                            required: {
                                                value: true,
                                                message: t('owner_email_required')
                                            },
                                        }
                                        )}
                                        readOnly
                                        placeholder={t('owner_email_placeholder')}
                                        errors={errors}
                                        label={t('owner_email')}
                                    />
                                </div>
                            </div>
                            <div className='form-btm'
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="pp-main-button "
                                    id="save"
                                >
                                    <span>{!!record ? t('update') : t('save')}</span>
                                </Button>
                                <Button
                                    className="pp-secondary-button"
                                    style={{ marginLeft: "2rem" }}
                                    onClick={() => handleClose()}
                                >
                                    <span>{t('cancel')}</span>
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Modal>
            </div>
        </>
    )
}

export default AddOrganisation;