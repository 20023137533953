import React, { useEffect, useMemo } from 'react'
import TableComponent from '../Common/TableComp/TableComp';
import { useTranslation } from 'react-i18next';
import BaseCurrency from '../Common/BaseCurrency';
import moment from 'moment';
import { amountFormat } from '../../utils';
import { Button, Col, Modal, Row, Select, message } from 'antd';
import TableHeader from '../Sales/TableHeader';
import { formatDate } from '../Sales/Common';
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { headerTitles } from '../../actions/masterdata/masterdata';
import { portalTableData } from '../../actions/customers';
import usePayAxios from '../../config/useAxios';
import { apis } from '../../config/APIs';

const CustomerPortalTableView = ({ isSales, isEstimates, isRetainer, isCN, isPayment, isVendorPurchses, isVendorInvoice, isVendorPayment, moduleType }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const tableData = useSelector((state) => state?.customers?.portalTableData?.data)
  const { onCall: paymentFecth, data: paymentData, reset: paymentReset, loading: paymentLoading } = usePayAxios({ api: apis.portalInvoicePaymentAPI, method: "post" });
  const [page, setPage] = React.useState(1);
  const [filters, setFilters] = React.useState({ type: moduleType });
  const [partialPaymentsModal, setPartialPaymentsModal] = React.useState(false)
  useEffect(() => {
    let title = '';
    let description = '';

    if (isSales) {
      title = 'Sales Order';
      description = 'A Sales Order is a document sent to your customers confirming the items and prices of a sale';
    } else if (isEstimates) {
      title = 'Estimates';
      description = 'An Estimate is a proposal that you send to your customers';
    } else if (isRetainer) {
      title = 'Retainers';
      description = 'Track and manage your retainer agreements';
    } else if (isCN) {
      title = 'Credit Notes';
      description = 'An Invoice is a document that indicates the amount your customers owe.';
    } else if (isPayment) {
      title = 'Payment Made';
      description = '';
    } else if (isVendorPurchses) {
      title = 'Purchase Order';
      description = 'Buyer issues document to a seller information about the items they want to buy';
    } else {
      title = 'Invoices';
      description = 'An Invoice is a document that indicates the amount your customers owe.';
    }

    dispatch(headerTitles({ title, description }));
    dispatch(portalTableData({
      params: {
        module_type: moduleType,
        page_number: 1
      }
    }))
  }, [isSales, isEstimates, isRetainer, isCN, isPayment, isVendorInvoice, isVendorPayment, isVendorPurchses]);
  useEffect(() => {
    if (paymentData?.error == false) {
      message.success(<span className='messageText'>{paymentData?.message}</span>);
      window.open(paymentData?.data, '_blank');
      paymentReset()
    } else if (paymentData?.error == true) {
      message.error(<span className='messageText'>{paymentData?.message}</span>);
      paymentReset()
    }
  }, [paymentData])
  const sortOptions = [
    // {
    //   label: isSales ? t('sales_no') : isEstimates ? t('estimate_no') : isCN ? t('Credit Note#') : isPayment ? t('invoice_no') : isVendorPurchses ? "Purchses No" : t('invoice_no'),
    //   filter: isVendorPurchses || isVendorInvoice || isVendorPayment ? "billing_no" : "invoice_no"
    // },
    {
      label: isVendorPurchses ? "Purchase Date" : t('date'),
      filter: isVendorPurchses || isVendorInvoice || isVendorPayment ? "billing_date" : "po_due_date"
    },
    {
      label: t('Amount'),
      filter: "total_amount"
    },
  ];

  const filterOptions = isEstimates || isVendorPurchses ? [
    { label: t('All'), filter: "all" },
    { label: t('Approved'), filter: "approved" },
    { label: t('Accepted'), filter: "accepted" },
    { label: t('Declined'), filter: "declined" },
  ] : isSales ? [
    { label: t('All'), filter: "all" },
    { label: t('Not Invoiced'), filter: "not_invoiced" },
    { label: t('Invoiced'), filter: "closed" },
    { label: t('Partial Invoiced'), filter: "partially_invoiced" },
  ] : isCN ? [
    { label: t('All'), filter: "all" },
    { label: t('Open'), filter: "open" },
    { label: t('Closed'), filter: "closed" },
  ] : isPayment ? [
    { label: t('All'), filter: "all" },
    { label: t('Paid'), filter: "paid" },
    { label: t('Partially Paid'), filter: "partially_paid" },
  ] : [
    { label: t('All'), filter: "all" },
    { label: t('Out Standing'), filter: "out_standing" },
    { label: t('Overdue'), filter: "overdue" },
  ]

  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: isSales ? t('Sales No') : isEstimates ? t('Estimate No') : isRetainer ? t('Retainer Invoice') : isCN ? t("Credit Note") : isPayment ? t("Invoice No") : isVendorPurchses ? t("Purchase No") : t("Invoice No"),
        dataIndex: isVendorPurchses || isVendorInvoice || isVendorPayment ? "billing_no" : 'invoice_no',
        key: isVendorPurchses || isVendorInvoice || isVendorPayment ? "billing_no" : 'invoice_no',
      },
      {
        title: t('Company name'),
        dataIndex: "company_name",
        key: "company_name",
      },
      {
        title: "Reference Number",
        dataIndex: isPayment ? "reference_number" : isVendorInvoice ? "ref_purchase_order_no" : 'ref_po_no',
        key: isPayment ? "reference_number" : isVendorInvoice ? "ref_purchase_order_no" : 'ref_po_no',
      },
      {
        title: t('date'),
        dataIndex: isPayment ? "payment_date" : isVendorInvoice ? "billing_date" : "invoice_date",
        key: isPayment ? "payment_date" : isVendorInvoice ? "billing_date" : "invoice_date",
        render: (text) => (
          text && <div className={moment().diff(text) <= 0 ? "" : "table-due-date"}>{formatDate(text)}</div>
        ),
      },
      {
        title: t('Amount'),
        dataIndex: 'total_amount',
        key: 'total_amount',
        render: (text, record) => <div><BaseCurrency symbol_code={record?.currency_info?.iso_code ? record?.currency_info?.iso_code : record?.currency} />{amountFormat(text, record?.currency_info?.decimal_scale)}</div>,
      },
      {
        title: t('status'),
        dataIndex: 'invoice_status',
        key: 'invoice_status',
        render: (text, record) => {
          if (text && isVendorPurchses && text == "approved") {
            return <div className={`status status_${text}`}>{"Approved"}</div>
          } else {
            return <div className={`status status_${text}`}>{text ? isSales ? record?.status == "closed" ? "Invoiced" : record?.status == "partially_invoiced" ? "Partial Invoiced" : text == "approved" ? "Not Invoiced" : "Partial Invoiced" : text?.replace(/_/g, " ") : ""}</div>
          }
        },
      }
    ];

    if (moduleType == "INVOICE") {
      baseColumns.push(
        {
          title: t('payment_status'),
          dataIndex: 'payment_status',
          key: 'payment_status',
          render: (text, record) => {
            return <div className={`status status_${text}`}>{text?.replace(/_/g, " ")}</div>
          },
        },
        {
          title: "Pay Now",
          dataIndex: '',
          key: '',
          render: (text, record) => (
            <div className='customer-invoice-action'>
              {
                record?.invoice_status == "approved" && record?.payment_status !== "paid" ? (
                  <>
                    <button
                      className='paynow-button'
                      disabled={paymentLoading}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (record?.invoice_status == "approved" && record?.payment_status !== "paid") {
                          paymentFecth({
                            data: {
                              id: record?.id,
                              beneficiary_id: record?.beneficiary_id
                            },
                            params: {
                              module_type: moduleType
                            }
                          })
                        }
                      }}
                    >
                      Pay Now
                    </button>
                  </>
                ) : (null)
              }
            </div >
          )
        });
    }
    if (isEstimates) {
      return baseColumns.filter(column => column.key !== 'ref_po_no');
    } else if (isPayment) {
      return [
        ...baseColumns.slice(0, 1),
        {
          title: t('Company name'),
          dataIndex: "company_name",
          key: "company_name",
        },
        ...baseColumns.slice(3, 5),
        // {
        //   title: t('Due Amount'),
        //   dataIndex: 'due_balance_amount',
        //   key: 'due_balance_amount',
        //   render: (text, record) => <div><BaseCurrency symbol_code={record?.currency_info?.iso_code ? record?.currency_info?.iso_code : record?.currency} />{amountFormat(text, record?.currency_info?.decimal_scale)}</div>,
        // },
        {
          title: t('Amount Paid'),
          dataIndex: 'amount_paid',
          key: 'amount_paid',
          render: (text, record) => <div><BaseCurrency symbol_code={record?.currency_info?.iso_code ? record?.currency_info?.iso_code : record?.currency} />{amountFormat(text, record?.currency_info?.decimal_scale)}</div>,
        },
        {
          title: t('Mode'),
          dataIndex: 'payment_mode',
          key: 'payment_mode',
        },
        ...baseColumns.slice(5),
        {
          title: t('Payment Status'),
          dataIndex: 'payment_status',
          key: 'payment_status',
          render: (text, record) => (
            <div className={`status status_${text}`}>{text?.replace(/_/g, " ")}</div>
          ),
        },
      ];
    } else if (isCN) {
      return [
        ...baseColumns.slice(0, 4),
        {
          title: t('Balance'),
          dataIndex: 'total_amount',
          key: 'total_amount',
          render: (text, record) => <div><BaseCurrency symbol_code={record?.currency_info?.iso_code} />{amountFormat(text, record?.currency_info?.decimal_scale)}</div>,
        },
        ...baseColumns.slice(4),
      ];
    }

    if (isVendorPurchses) {
      return [
        ...baseColumns.slice(0, 1),
        {
          title: t('Company name'),
          dataIndex: "company_name",
          key: "company_name",
        },
        {
          title: t('Purchase Date'),
          dataIndex: "created_at",
          key: "created_at",
          render: (text) => (
            text && <div>{formatDate(text)}</div>
          ),
        },
        {
          title: t('Delivery Date'),
          dataIndex: "updated_at",
          key: "updated_at",
          render: (text) => (
            text && <div>{formatDate(text)}</div>
          ),
        },
        ...baseColumns.slice(4),
      ]
    } else if (isVendorInvoice) {
      return [
        ...baseColumns.slice(0, 6),
        {
          title: t('Payment Status'),
          dataIndex: 'payment_status',
          key: 'payment_status',
          render: (text, record) => (
            <div className={`status status_${text}`}>{text?.replace(/_/g, " ")}</div>
          ),
        },
      ]
    }
    return baseColumns;
  }, [isSales, isEstimates, isRetainer, isCN, isPayment, isVendorPurchses, isVendorInvoice, isVendorPayment]);
  const handleSearch = (searchKey) => {
    dispatch(
      portalTableData({
        ...filters,
        search_key: searchKey,
        type: moduleType,
        params: {
          module_type: moduleType,
          page_number: 1
        }
      })
    );
    setPage(1)
    setFilters({
      ...filters,
      search_key: searchKey
    })
  };

  const handleFilter = (value) => {
    if (value === "all") {
      dispatch(portalTableData({
        params: {
          module_type: moduleType,
          page_number: 1
        }
      }))
      setFilters({ type: moduleType })
    } else {
      if (value == "closed" || value == "partially_invoiced") {
        dispatch(
          portalTableData({
            ...filters,
            filters: { status: [value], invoice_status: ["approved"] },
            isFilter: true,
            params: {
              module_type: moduleType,
              page_number: 1
            }
          })
        );
        setFilters({
          ...filters,
          filters: { status: [value], invoice_status: ["approved"] }
        });
      } else if (isPayment) {
        dispatch(
          portalTableData({
            ...filters,
            filters: { payment_status: [value] },
            isFilter: true,
            params: {
              module_type: moduleType,
              page_number: 1
            }
          })
        );
        setFilters({
          ...filters,
          filters: { payment_status: [value] }
        });
      } else {
        dispatch(
          portalTableData({
            ...filters,
            filters: { invoice_status: [value] },
            isFilter: true,
            params: {
              module_type: moduleType,
              page_number: 1
            }
          })
        );
        setFilters({
          ...filters,
          filters: { invoice_status: [value] }
        });
      }

    }
    setPage(1);
  };

  const handleSort = (value) => {
    dispatch(
      portalTableData({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
        isFilter: true,
        params: {
          module_type: moduleType,
          page_number: 1
        }
      })
    );

    setFilters({
      ...filters,
      sort: {
        sort_key: value,
        sort_order: "1",
      },
    });
    setPage(1);
  };


  const handleSelectRow = (evt, record) => {
    history.push(`/customer/portal/detail/${record?.id}`, {
      module_type: moduleType,
      id: record?.id,
      from: location?.pathname
    })
  }
  return (
    <>
      <Modal
        title={t('data_formats')}
        visible={partialPaymentsModal}
        onCancel={() => {
          setPartialPaymentsModal(false)
        }}
        className={"center-aligned-modal"}
      >
        <>
          <div className='decimal_body d-flex flex-col'>
            <div className='table_headers flex-between'>
              <h4 className='w50 border_right p1'>Date Type</h4>
              <h4 className='w50 p1'>Default Format</h4>
            </div>
            <div className='table_body flex-between'>
              <span className='w50 p1 border_right'>Decimal Foramt</span>
              <Select
                className="w50 p1"
                options={[
                  { label: "1234567.89", value: "1" },
                  { label: "1,234,567.89", value: "2" },
                  { label: "1 234 567.89", value: "3" },
                  { label: "1234567,89", value: "4" },
                  { label: "1.234.567,89", value: "5" },
                  { label: "1 234 567,89", value: "6" },
                ]}
                onChange={(e) => {
                  // setDateSelect(e);
                }}
              />
            </div>
          </div>
          <br />
          <Button
            key="1"
            className="formButton mt1"
          >
            {t('save')}
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
            onClick={() => {
              setPartialPaymentsModal(false)
            }}
          >
            {t('cancel')}
          </Button>
        </>
      </Modal>
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              noDD={true}
              filterMenu={filterOptions}
              handleFilter={handleFilter}
              // setOpenAddNew={setOpenAddNew}
              sortOptions={sortOptions}
              handleSort={handleSort}
              onSearch={handleSearch}
              noAddNewBtn={true}
            // menu={menu} 
            />
            <div className="shimmer" style={{ flexGrow: 1, paddingTop: '22px' }}>
              <TableComponent
                rowKey="id"
                columns={columns}
                data={tableData?.data || []}
                // hasCheckbox={false}
                // loading={vendorsResponse?.loading}
                // scroll={{ x: 1400 }}
                // selectedRows={selectedRows}
                hasCheckbox={false}
                // setSelectedRows={setSelectedRows}
                handleRowClick={handleSelectRow}
                pagination={{
                  total: tableData?.total_count || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(portalTableData({
                      ...filters,
                      params: {
                        module_type: moduleType,
                        page_number: pagee,
                      }
                    }))
                    setPage(pagee)
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default CustomerPortalTableView