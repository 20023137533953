import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Menu, message, Modal, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  AddExpense,
  expenses,
  expenses_delete,
  prefixexpenseFields,
} from "../../actions/expenses/expenses";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { UserReportLinkExp } from "../../actions/reports";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import duplicate from "../../assets/icons/duplicate.png";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import { sort_key } from "../Common/Table/Table";
import TableComponent from "../Common/TableComp/TableComp";
import TableHeader from "../Sales/TableHeader";
// import data from './data.json';
import { apis } from "../../config/APIs";
import useOcrAxios from "../../config/useOcr";
import OcrUpload from "../OCR/OcrUpload";
import "./expenses.css";
// import { ocr_socket } from "../../config/AxiosOcrApi";
import { OcrDocumentDelete, ocrDocuments } from "../../actions/ocr/ocr";

const Expenses = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [expensesData, setExpensesData] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [fileData, setFileData] = React.useState([]);
  const [possibleDuplicatesModal, setPossibleDuplicatesModal] =
    React.useState(false);
  const [ocrModal, setOcrModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState("");
  const [possibleDuplicates, setPossibleDuplicates] = React.useState([]);
  const expensesResponse = useSelector(
    (state) => state.expenses.expenses || {}
  );
  const addExpenseResponse = useSelector(
    (state) => state.expenses.addExpense || {}
  );
  const [filters, setFilters] = React.useState({});
  const is_admin = useSelector(
    (state) => state?.auth?.localVariables?.is_admin
  );
  const [page, setPage] = React.useState(1);
  const userReportLinkExpResponse = useSelector(
    (state) => state.reports.userReportLinkExp || {}
  );
  const updateExpenseResponse = useSelector(
    (state) => state.expenses.updateExpense || {}
  );
  const ocr_delete = useSelector((state) => state?.ocr?.delete || []);
  const location = useLocation();
  const { onCall, loading, progress, reset } = useOcrAxios({
    api: apis.ocrSave,
    method: "post",
  });
  const columns = [
    {
      title: t("expensesId"),
      dataIndex: "transaction_number",
      key: "transaction_number",
      render: (text, record) => (
        <div className={record?.is_duplicate ? "duplicate" : ""}>
          {text}
          {record?.is_duplicate ? (
            <span>
              <img src={duplicate} alt={"duplicate"} width={14} />

              <div className="duplicate-hover">
                {t("duplicate_account")}
                <span
                  className="resolve-now"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPossibleDuplicatesModal(true);
                    setPossibleDuplicates(record?.duplicate_of);
                  }}
                >
                  {t("resolve")}
                </span>
              </div>
            </span>
          ) : null}
        </div>
      ),
    },
    {
      title: t("dateAndTime"),
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text) => <div>{moment(text).format("DD-MM-YYYY hh:mm:ss")}</div>,
    },
    {
      title: t("merchant"),
      dataIndex: "merchant",
      key: "merchant_name",
    },
    {
      title: t("Amount"),
      dataIndex: "amount_cents",
      key: "amount_cents",
      render: (text, record) => (
        <div>
          <img
            src={record?.transaction_type === "credit" ? credit : debit}
            width={10}
            alt="credit"
          />{" "}
          <BaseCurrency />
          {`${amountFormat(text)}`}
        </div>
      ),
    },
    // {
    //   title: "Purpose",
    //   dataIndex: "purpose",
    //   key: "purpose",
    // },
    {
      title: t("category"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("isReimbursable"),
      dataIndex: "claim_reimbursement",
      key: "claim_reimbursement",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: t("cardRefNumber"),
      dataIndex: "card_reference_number",
      key: "card_reference_number",
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          className={
            record.report_status === "pending_approval" && text !== "rejected"
              ? `status status_${record.report_status}`
              : `status status_${text}`
          }
        >
          {record.report_status === "pending_approval" && text !== "rejected"
            ? record.report_status?.replace(/_/g, " ")
            : text?.replace(/_/g, " ")}
        </div>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "status",
    //   key: "status",
    //   fixed: "right",
    //   render: (text, record) => (
    //     <Space key={record.id} size="middle">
    //       <button ><img src={EditIcon} alt={"logo"} className="action-logo" /></button>
    //       <button ><img src={DeleteIcon} alt={"logo"} className="action-logo" /></button>
    //     </Space>
    //   ),
    // },
  ];
  React.useEffect(() => {
    const { data: { status = "" } = {} } = userReportLinkExpResponse;
    if (status) {
      dispatch(
        expenses({
          module_type: "EXPENSE",
        })
      );
      dispatch({
        type: AddExpense.RESET,
      });
      dispatch({
        type: UserReportLinkExp.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkExpResponse]);

  React.useEffect(() => {
    if (
      is_admin !== "" &&
      !location.state?.isEdit &&
      !location.state?.createNew
    ) {
      if (location.state?.status) {
        dispatch(expenses({ status: location.state?.status }));
        setFilters({
          status: location.state?.status,
        });
      } else {
        dispatch(expenses());
      }
    }
    dispatch(
      headerTitles({
        title: "Expenses",
        description: "",
      })
    );
    dispatch(ocrDocuments());
    if (location.state?.createNew) {
      history.replace("/expenses");
      handleButtonClick();
    }
    // dispatch(getMerchantsField({
    //   company_id: localStorage.getItem("company_id"),
    // }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_admin]);

  // React.useEffect(() => {
  //   const { data: { data = [], error = "", res_message = "" } = {} } =
  //     updateExpenseResponse;
  //     history.push("/expenses");
  //     if (error === false) {
  //     message.success(<span className="messageText">{res_message}</span>);
  //     dispatch({ type: ExpenseDetails.RESET });
  //     dispatch({ type: ExpenseCustomDetails.RESET});
  //   }
  // }, [updateExpenseResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      userReportLinkExpResponse;
    status !== "" &&
      message.success(<span className="messageText">{res_message}</span>);
    if (status) {
      dispatch(expenses());
      dispatch({
        type: UserReportLinkExp.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkExpResponse]);

  React.useEffect(() => {
    if (!expensesResponse?.data?.error) {
      setExpensesData(expensesResponse?.data?.data);
      setTotalRecords(expensesResponse?.data?.total_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expensesResponse]);

  React.useEffect(() => {
    const { data: { status = false } = {} } = addExpenseResponse;
    if (status) {
      dispatch(expenses());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExpenseResponse]);
  React.useEffect(() => {
    if (ocr_delete?.data?.error === false) {
      dispatch(ocrDocuments());
      message.success(
        <span className="messageText">OCR Doc deleted successfully</span>
      );
      dispatch({
        type: OcrDocumentDelete.RESET,
      });
    }
    if (ocr_delete?.data?.error === true) {
      message.error(
        <span className="messageText">Failed to delete OCR Doc</span>
      );
      dispatch({
        type: OcrDocumentDelete.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocr_delete]);
  const handleSearch = (value) => {
    dispatch(expenses({ ...filters, search_key: value }));
    setFilters({
      ...filters,
      search_key: value,
    });
    setPage(1);
  };

  const handleRowClick = (evt, record) => {
    evt.preventDefault();
    if (record) {
      history.push({
        pathname: `/expense-details/${record.id}`,
        state: {
          expenseData: { ...record, isFromExpense: true },
          status: filters.status,
        },
      });
    }
  };

  function handleButtonClick() {
    // showModal();
    dispatch(prefixexpenseFields({ params: { module_type: "EXPENSE" } }));
    hasWriteAccess && history.push("/expense-add");
  }

  const handleFilter = (value) => {
    if (value === "") {
      dispatch(expenses());
      setFilters({});
    } else {
      dispatch(expenses({ ...filters, filters: { status: [value] } }));
      setFilters({
        ...filters,
        filters: { status: [value] },
      });
    }
    setPage(1);
  };


  // const sortOptions = ["amount_cents", "transaction_date", "merchant_name"]
  const sortOptions = [
    { label: "amount", key: "amount_cents" },
    { label: "transaction_date", key: "transaction_date" },
    { label: "merchant_name", key: "merchant_name" },
  ];
  const options = [
    { label: t("all_expenses"), filter: "" },
    { label: t("saved"), filter: "saved" },
    { label: t("reported"), filter: "reported" },
    { label: t("pending_reimburse"), filter: "pending_reimbursement" },
    { label: t("rejected"), filter: "rejected" },
    { label: t("reimbursed"), filter: "reimbursed" },
    // { label: "Partial Reimbursed", filter: "partial_reimbursed" },
    // { label: "Partial Approved", filter: "partial_approved" },
    { label: t("approval_pending"), filter: "pending_approval" },
  ];

  const sort_opts = sortOptions.map((ele) => ({
    label: (
      <span
        style={{
          textTransform: "capitalize",
          color: "#333",
        }}
      >
        {sort_key[ele?.label]
          ? sort_key[ele?.label]
          : ele?.label.replace("_", " ")}
      </span>
    ),
    filter: ele?.key,
  }));
  const possibleDuplicatesColumns = [
    {
      title: "Expense ID",
      dataIndex: "transaction_number",
      key: "transaction_number",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {/* <button className="pp-main-button mr1">
          <span>Not a duplicate</span>
        </button> */}
          <DeleteOutlined
            color="#E0272B"
            onClick={() => {
              setSelectedId(text);
              setDeleteModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const menu_options = [
    {
      label: (
        <div
          className="table-menu-item"
          onClick={() => {
            document.getElementById("file").click();
          }}
        >
          <span className="menu-label">{t("upload")}</span>
        </div>
      ),
      key: "1",
      icon: (
        <div>
          <PlusCircleFilled className="pp-icon-18" />
        </div>
      ),
    },
    {
      label: (
        <div
          className="table-menu-item"
          onClick={() => {
            setOcrModal(true);
          }}
        >
          <span className="menu-label">{t("ocr_documents")}</span>
        </div>
      ),
      key: "1",
    },
  ];

  const menu = (
    <Menu onClick={() => {}}>
      {menu_options.map((ele) => (
        <Menu.Item
          key={ele.key}
          icon={ele.icon}
          className="flex-around"
          disabled={!hasWriteAccess}
        >
          {ele.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <input
        type={"file"}
        id={"file"}
        className="d-none"
        onChange={(e) => {
          setFileData(e.target.files);
          const data = new FormData();
          data.append("file", e.target.files[0]);
          data.append("company_id", localStorage.getItem("company_id"));
          onCall({
            data,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }}
      />
      {possibleDuplicatesModal ? (
        <Modal
          visible={possibleDuplicatesModal}
          title="Possible Duplicates"
          onCancel={() => {
            setPossibleDuplicatesModal(false);
          }}
          footer={[]}
          className="right-alinged-modal"
        >
          <TableComponent
            rowKey="id"
            columns={possibleDuplicatesColumns}
            data={possibleDuplicates}
            hasCheckbox={false}
          />
        </Modal>
      ) : null}
      <Modal
        title={<Typography.Title level={4}>{t("alert")}</Typography.Title>}
        visible={deleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        footer={[]}
        width={450}
      >
        <div>
          <Typography.Title level={5}>
            {t("are_u_sure")} ? &nbsp;
          </Typography.Title>
          <div className="top-filter mt1">
            <div className="flt-btns">
              <button
                onClick={() => {
                  setDeleteModal(false);
                }}
              >
                <span>{t("cancel")}</span>
              </button>
              <button
                className="active"
                onClick={() => {
                  dispatch(
                    expenses_delete({
                      id: selectedId,
                      reason: "Duplicate",
                    })
                  );
                  setDeleteModal(false);
                  setSelectedId("");
                }}
              >
                <span>{t("delete")}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <TableHeader
        menu={menu}
        defaultFilterValue={filters?.status}
        filterMenu={options}
        handleFilter={handleFilter}
        handleAddButton={handleButtonClick}
        noAddNewBtn={false}
        sortOptions={sort_opts}
        onSearch={handleSearch}
        placeholder={t("search")}
        hasWriteAccess={hasWriteAccess}
        handleSort={(key) => {
          dispatch(expenses({ ...filters, sort: { sort_key: key } }));
          setFilters({
            ...filters,
            sort: { sort_key: key },
          });
          setPage(1);
        }}
      />

      <div style={{ paddingTop: "15px" }}>
        <TableComponent
          rowKey="id"
          columns={columns}
          data={expensesData}
          hasCheckbox={false}
          handleRowClick={handleRowClick}
          scroll={{ x: 1500 }}
          pagination={{
            total: totalRecords || 0,
            current: page,
            onChange: (pagee) => {
              dispatch(
                expenses({ params: { page_number: pagee }, ...filters })
              );
              setPage(pagee);
            },
          }}
        />
        <OcrUpload
          fileData={fileData}
          loading={loading}
          progress={progress}
          reset={reset}
          ocrModal={ocrModal}
          setOcrModal={setOcrModal}
          onAdd={(data, id) => {
            let obj = {
              amount_cents: data?.find((ele) =>
                ele.label?.toLowerCase()?.includes("amount")
              )?.ocr_text,
              transaction_date: new Date(
                data?.find((ele) =>
                  ele.label?.toLowerCase()?.includes("date")
                )?.ocr_text
              ),
              transaction_merchant_id: data?.find(
                (ele) => ele.label === "seller_name"
              )?.ocr_text,
              ocr_id: id,
            };
            history.push("/expense-add", {
              expense: obj,
            });
          }}
        />
      </div>
    </>
  );
};

export default Expenses;
