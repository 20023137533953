import { useState } from "react";
import endpointPayApi from "./AxiosPayApi";
import endpointGridApi from "./AxiosGridApi";

const usePayAxios = ({ api, method, type }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const reset = () => {
    setData(null);
    setLoading(false);
  };

  const onCall = async ({ data, headers, params }) => {
    setLoading(true);
    try {
      let result;
      if (method === "get")
        if (type === "grid") {
          result = await endpointGridApi[method](api, { headers, params });
        } else result = await endpointPayApi[method](api, { headers, params });
      else {
        if (type === "grid")
          result = await endpointGridApi[method](api, data, {
            headers,
            params,
          });
        else
          result = await endpointPayApi[method](api, data, { headers, params });
      }
      setLoading(false);
      setData(result.data);
      return Promise.resolve(result.data);
    } catch (err) {
      setLoading(false);
      setData(err);
      return Promise.reject(err);
    }
  };

  return {
    loading,
    data,
    reset,
    onCall,
  };
};

export default usePayAxios;
