import React from "react";
import Address from "./Address";
import Products from "./Products";

const Summary = ({ step, isAggree, setIsAggree, setShippingAddresses }) => {
  return (
    <div className="summary">
      <Products step={step} />
      <br />
      <Address
        step={step}
        isAggree={isAggree}
        setIsAggree={setIsAggree}
        setShippingAddresses={setShippingAddresses}
      />
    </div>
  );
};

export default Summary;
