import { useSelector } from "react-redux";
import { incrementIfNumber } from "../../../../utils";
import ArrowDown from "../../assets/icons/arrow-down.svg";
import CheckIcon from "../../assets/icons/check-Icon.svg";
import "./onBoardLeftNavigation.css";
const OnBoardLeftNavigation = () => {
  const menuStatus = useSelector((state) => state?.on_board?.create_on_board_form?.step)
  const onboardPageDetails = useSelector((state) => state?.on_board?.getOnboardFields)
  const onboardStepResponse = useSelector((state) => state?.on_board?.onboardStep)
  return (
    <>
      <div className="navigation-section">
        <div className="navigation-conatiner">
          {
            onboardPageDetails?.data?.data?.page_details?.map((element_page_details, page_details_index) => {
              return (
                <ul className="list-section">
                  <li>
                    {/* {activeMenu === true ? <img src={ArrowUp} alt="arrow-up" /> : <img src={ArrowDown} alt="arrow-down" />} */}
                    <img src={ArrowDown} alt="arrow-down" />
                    <span className="header-text">{element_page_details?.title}</span>
                    <ul className="nested-list">
                      {
                        element_page_details?.sections?.map((section_element, section_index) => {
                          return (
                            // <li className={`${incrementIfNumber(onboardStepResponse?.data?.data?.step) == section_element?.section_order ? "highlight" : " "}`} onClick={(e) => {
                            //   e.stopPropagation()
                            // }}>
                            <li className={`${menuStatus == section_element?.section_order ? "highlight" : ""}`} onClick={(e) => {
                              e.stopPropagation()
                            }}>
                              {incrementIfNumber(onboardStepResponse?.data?.data?.step ?? onboardStepResponse?.data?.data?.fields?.[0]?.step) > section_element?.section_order ? <img src={CheckIcon} alt="check-icon" className="check-icon" /> : null}
                              {section_element?.section_title}
                            </li>
                          )
                        })
                      }
                    </ul>
                  </li>
                </ul>
              )
            })
          }
        </div>
      </div>
    </>
  )
}
export default OnBoardLeftNavigation;