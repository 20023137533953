import { all } from "redux-saga/effects";
import { templatesWatcher } from "./actions/Templates";
import { accountsWatcher } from "./actions/accounts/saga";
import { advancesWatcher } from "./actions/advances/saga";
import { loginWatcher } from "./actions/auth/saga";
import { billsWatcher } from "./actions/bills/saga";
import { cardsWatcher } from "./actions/cards/saga";
import { categoriesWatcher } from "./actions/categories/saga";
import { companySettingWatcher } from "./actions/companySettings/saga";
import { customersWatcher } from "./actions/customers";
import { dashboardWatcher } from "./actions/dashboard/saga";
import { employeesWatcher } from "./actions/employees/saga";
import { expensesWatcher } from "./actions/expenses/saga";
import { masterdataWatcher } from "./actions/masterdata/saga";
import { ocrWatcher } from "./actions/ocr/saga";
import { paymentsWatcher } from "./actions/payments/saga";
import { purchasesWatcher } from './actions/purchases/saga';
import { reportsWatcher } from "./actions/reports/saga";
import { transfersWatcher } from "./actions/transfers";
import { tripsWatcher } from "./actions/trips/saga";
import { onboardWarcher } from "./components/OnBoarding/action/OnBoardForm/saga";
import { importWatcher } from "./actions/imports/saga";
import { ordersWatcher } from "./actions/orders/saga";
import { InventoryWatcher } from "./actions/inventory/saga";
import { z_accountsWatcher } from "./actions/z_accounts";

export function* rootSagas() {
  yield all([
    loginWatcher(),
    dashboardWatcher(),
    categoriesWatcher(),
    reportsWatcher(),
    expensesWatcher(),
    tripsWatcher(),
    cardsWatcher(),
    advancesWatcher(),
    employeesWatcher(),
    companySettingWatcher(),
    purchasesWatcher(),
    billsWatcher(),
    accountsWatcher(),
    masterdataWatcher(),
    customersWatcher(),
    transfersWatcher(),
    ocrWatcher(),
    templatesWatcher(),
    onboardWarcher(),
    paymentsWatcher(),
    importWatcher(),
    ordersWatcher(),
    InventoryWatcher(),
    z_accountsWatcher()
  ]);
}
