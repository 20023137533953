import {
  Button,
  Col,
  Input,
  Menu,
  Modal,
  Row,
  Skeleton,
  Steps,
  Typography,
  message
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { InitiatePayout, initiate_payout } from "../../actions/accounts/accounts";
import { CreateBillingComment, createBillingComment } from "../../actions/bills/bills";
import { SideMenu, headerTitles } from "../../actions/masterdata/masterdata";
import { APPROVE_PAYMENT, DELETE_PAYMENT, RECALL_PAYMENT, REJECT_PAYMENT, SUBMIT_PAYMENT, approvePayment, deletePayment, getPaymentDetailsByID, get_all_payments, recallPayment, rejectPayment, submitPayment } from "../../actions/payments";
import {
  UserReport,
  reportsExportExcel,
  reportsHistory
} from "../../actions/reports";
import block from "../../assets/icons/block.png";
import Close from "../../assets/icons/close.png";
import Comment from "../../assets/icons/comment.png";
import History from "../../assets/icons/time_icon.png";
import warn from "../../assets/icons/warning.png";
import {
  amountFormat,
  hasAccess
} from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import { downloadAndZip } from "../Common/DownloadAll";
import NoCommentsComponent from "../Common/NoComentsComponent";
import NodataComponent from "../Common/NodataComponent";
import ShareModal from "../Reports/ShareModal";
import "../Reports/new-report.css";
import { CloseCircleOutlined } from "@ant-design/icons";
import CreatePayment from "../Transfers/BankTransfers/CreatePayment";
const { TextArea } = Input;
const Title = Typography.Title;
const Text = Typography.Text;

const NewPaymentDetail = ({ }) => {
  const location = useLocation();
  const hasWriteAccess = location?.state?.user ? true : hasAccess("reports_approvals", "write")
  const history = useHistory();
  const { t } = useTranslation();
  const [input, setInput] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [deletePaymentModal, setDeletePaymentModal] = useState(false);
  const [editPaymentModal, setEditPaymentModal] = useState(false);
  const [paymentDetailsPage, setPaymentDetailsPage] = useState(false);
  const [record, setRecord] = useState({});
  const [edit, setEdit] = useState(false);
  const [shareReport, setShareReport] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [violations, setViolations] = React.useState([]);
  const [showViolations, setShowViolations] = React.useState(false);
  const [openAddNew, setOpenAddNew] = useState(false);

  const [isReportModalVisible, setIsReportModalVisible] = React.useState(false);
  const [reasonValue, setReasonValue] = React.useState("");

  const reportData = useSelector(
    (state) => state?.payments?.get_payment?.data?.data
  );

  React.useEffect(() => {
    if (reportData) {
      setRecord(reportData)
    }
  }, [reportData])

  // console.log('ReportData=',reportData)
  // console.log('Record=',record)
  const reportLoading = useSelector(
    (state) => state?.payments?.get_payment?.loading
  );
  const reportPDFURL = useSelector(
    (state) => state.reports.reportdetailsPdf?.data?.response?.report_url || "#"
  );

  const reportsRejectResponse = useSelector(
    (state) => state.payments.reject_payment || {}
  );
  const reportsApproveResponse = useSelector(
    (state) => state.payments.approve_payment || {}
  );
  const userReportSubmitResponse = useSelector(
    (state) => state.payments.submit_payment || {}
  );
  const userReportRecallResponse = useSelector(
    (state) => state.payments.recall_payment || {}
  );

  const [tab, setTab] = useState("details");
  const expensesResponse = useSelector(
    (state) => state.reports.reportsExpDetails || {}
  );

  const { data: { response: { transactions = [] } = {} } = {} } =
    expensesResponse;
  const createBillingCommentRes = useSelector(
    (state) => state.bills.createBillingComment || {}
  );

  const deletePaymentResponse = useSelector(state => state?.payments?.delete_payment)


  React.useEffect(() => {
    if (deletePaymentResponse?.data?.error === true) {
      message.error(
        <span className="messageText">{deletePaymentResponse?.data?.message}</span>
      );
      dispatch({
        type: DELETE_PAYMENT.RESET
      });

    }
    else if (deletePaymentResponse?.data?.error === false) {
      message.success(
        <span className="messageText">{'Payment Deleted Successfully' || deletePaymentResponse?.data?.message}</span>
      );
      setDeletePaymentModal(false);
      dispatch({
        type: DELETE_PAYMENT.RESET
      })
      history.goBack();
      // dispatch(get_all_payments({
      //   // view: !!user ? "" : "admin",
      //   type: "COMPANY_PAYMENTS",
      //   page_number: 1
      // }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePaymentResponse])

  const handleConfirm = () => {
    dispatch(deletePayment({ id: params?.id, type: 'COMPANY_PAYMENTS' }))
  }

  const onCancel = () => {
    setDeletePaymentModal(false);
  }
  const reportHistory = reportData?.history
  const inputComment = React.useRef(null);
  const resetFormRef = React.useRef();
  const payoutRes = useSelector((state) =>
    state?.banks?.initiate_payout?.data
  );

  const handleAddComment = () => {
    if (input?.trim() && params.id) {
      dispatch(
        createBillingComment({
          body: input,
          commentable_id: params.id,
          commentable_type: "payment"
        })
      );
      setInput("")
    } else {
      message.error(<span className="messageText">{t('comments_required')}</span>);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setReasonValue("")
    setIsReportModalVisible(false);
    if (resetFormRef.current) {
      resetFormRef.current();
    }
  };

  const dispatch = useDispatch();

  const params = useParams();

  const callOnMount = () => {
    if (params.id) {
      dispatch(getPaymentDetailsByID({ id: params.id, bulk: location?.state?.bulk, view: location?.state?.user ? "" : "admin", module_type: location?.state?.bulk ? "COMPANY_BULK_PAYMENTS" : "COMPANY_PAYMENTS" }))
    }
  };

  React.useEffect(() => {
    callOnMount();
    setPaymentDetailsPage(true);
    dispatch(
      headerTitles({
        title: 'payment_details',
        description: 'payment_desc',
      })
    );
    return () => {
      dispatch({
        type: UserReport.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (location.state?.bulk) {
      let locationSplit = location.state?.pathname?.split("/");
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
          childrenMenu: locationSplit?.[3] ? "/" + locationSplit?.[3] : "",
        },
      });
    } else
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/transfers",
          showChildren: true,
          secondNav: "/beneficiary",
          childrenMenu: location?.state?.user ? "/payments" : ""
        },
      });
  }, [location.state])

  React.useEffect(() => {
    if (payoutRes?.error === false) {
      message.success(<span className="messageText">{payoutRes?.data?.message}</span>);
      dispatch({
        type: InitiatePayout.RESET
      })
      callOnMount()
      setOpenAddNew(false)
    } else if (payoutRes?.error) {
      message.error(<span className="messageText">{payoutRes?.message}</span>);
      dispatch({
        type: InitiatePayout.RESET
      })
    }
  }, [payoutRes]);


  React.useEffect(() => {

    if (userReportSubmitResponse?.data?.error === false) {
      message.success(<span className="messageText">{t("pay_submit_success")}</span>);
      setIsModalVisible(false);
      dispatch({
        type: SUBMIT_PAYMENT.RESET,
      });
      callOnMount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportSubmitResponse]);

  React.useEffect(() => {

    if (userReportRecallResponse?.data?.error === false) {
      message.success(<span className="messageText">{"Payment recalled successfully."}</span>);
      setIsModalVisible(false);
      callOnMount()
      dispatch({
        type: RECALL_PAYMENT.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportRecallResponse]);

  React.useEffect(() => {

    if (reportsRejectResponse?.data?.error === false) {
      message.success(<span className="messageText">{"Payment rejected successfully."}</span>);
      setIsModalVisible(false);
      // dispatch(reports(objectToFormData({ status: true })));
      dispatch({ type: REJECT_PAYMENT.RESET });
      setReasonValue("")
      callOnMount()
    } else if (reportsRejectResponse?.data?.error) {
      message.success(<span className="messageText">{reportsRejectResponse?.data?.message}</span>);
      dispatch({ type: REJECT_PAYMENT.RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsRejectResponse]);

  React.useEffect(() => {
    if (reportsApproveResponse?.data?.error === false) {
      message.success(<span className="messageText">{"Payment approved successfully."}</span>);
      dispatch({ type: APPROVE_PAYMENT.RESET });
      callOnMount()
    } else if (reportsApproveResponse?.data?.error) {
      reportsApproveResponse?.data?.message && message.success(<span className="messageText">{reportsApproveResponse?.data?.message}</span>);
      dispatch({ type: APPROVE_PAYMENT.RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsApproveResponse]);

  React.useEffect(() => {
    if (createBillingCommentRes?.data?.error) {
      message.error(
        <span className="messageText">{createBillingCommentRes?.data?.message}</span>
      );
      dispatch({
        type: CreateBillingComment.RESET,
      });
    }

    if (createBillingCommentRes?.data?.error === false) {
      dispatch({
        type: CreateBillingComment.RESET,
      });
      callOnMount()
    }
  }, [createBillingCommentRes]);

  function handleMenuClick(e) {
    switch (e.key) {
      case "3":
        dispatch(reportsExportExcel({ id: params.id }));
        break;
      case "5":
        setShareReport(true);
        break;
      default:
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <a href={reportPDFURL} download target={"_blank"} rel="noreferrer">
        <Menu.Item key="1">{t('download_pdf')}</Menu.Item>
      </a>
      <Menu.Item key="5">{t('share_report')}</Menu.Item>
      {!location.state?.user && <Menu.Item key="6">{t('forward_approval')}</Menu.Item>}
      {transactions?.filter((ele) => ele?.files?.length > 0)?.length > 0 && (
        <Menu.Item
          key="2"
          onClick={() => {
            let arr = [];
            transactions?.forEach((ele) => {
              if (ele?.files?.length) arr = [...arr, ...ele.files];
            });
            if (arr.length) downloadAndZip(arr?.map(ele => ele?.file));
          }}
        >
          {t('download_receipts')}
        </Menu.Item>
      )}
      {/* <Menu.Item key="3">Export to Excel</Menu.Item>
      <Menu.Item key="4">Print</Menu.Item> */}
    </Menu>
  );


  const renderModalTitle = (type) => {
    switch (type) {
      case "delete":
        return "Reject Report";
      default:
        return "";
    }
  };

  const renderModalClass = (type) => {
    switch (type) {
      case "delete":
        return "center-aligned-modal";
      case "addNewExp":
        return "center-aligned-modal-big expense";
      case "addUnrepExp":
        return "right-alinged-modal";
      case "addUnrepAdvance":
        return "right-alinged-modal";
      case "addUnrepTrip":
        return "right-alinged-modal";
      default:
        return "";
    }
  };

  const renderModalData = (type) => {
    switch (type) {
      case "delete":
        return renderDeleteOption();
      default:
        return "";
    }
  };

  const renderDeleteOption = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            rejectPayment(
              { id: params.id, reject_reason: reasonValue, type: location?.state?.bulk ? "COMPANY_BULK_PAYMENTS" : "COMPANY_PAYMENTS" }
            )
          );
        }}
      >
        <Text>{t('rejection_reason')}</Text>
        <br />
        <TextArea
          rows={4}
          placeholder="Enter Reason"
          className="reasonTextArea"
          onChange={(e) => setReasonValue(e.target.value)}
          required={true}
        />
        <br />
        <Button
          key="1"
          className="formButton mt1"
          htmlType="submit"
          disabled={reportsRejectResponse?.loading}
        >
          {t('confirm')}
        </Button>
        <Button
          key="2"
          htmlType="button"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t('cancel')}
        </Button>
      </form>
    );
  };



  const secs = [
    "Details",
    "Comments",
    "History",
  ];
  return (
    <>
      <ShareModal
        open={shareReport && hasWriteAccess}
        id={reportData?.id}
        onClose={() => {
          setShareReport(false);
        }}
      />
      {openAddNew && <CreatePayment
        setRecord={setRecord}
        source={"PAYMENT"}
        payData={reportData}
        from={"details"}
        onSubmit={() => {
          dispatch(initiate_payout({ id: params.id }))
        }}
        open={openAddNew && hasWriteAccess}
        onClose={() => {
          setOpenAddNew(false)
        }} />}
      <Modal
        title={renderModalTitle(modalType)}
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={renderModalClass(modalType)}
      >
        {renderModalData(modalType)}
      </Modal>
      <Modal
        title={<Title level={4}>{t('policy_violation')}</Title>}
        visible={showViolations && hasWriteAccess}
        onCancel={() => {
          setShowViolations(false);
        }}
        footer={[]}
      >
        <div className="violations">
          {violations?.map((ele, idx) => (
            <div className="item">
              {idx + 1}. {ele.report_number} -
              {ele.policies?.map((itm) =>
                itm.errors.map((e) => (
                  <span className={itm.is_warning ? "warn" : ""}>
                    <img
                      src={itm.is_warning ? warn : block}
                      alt=""
                      width={12}
                    />{" "}
                    {e}
                  </span>
                ))
              )}
            </div>
          ))}
        </div>
      </Modal>

      {reportData?.reject_reason && <div className="strip red">{t('reason_reject')}: {reportData?.reject_reason}</div>}
      <div className="report-btns">
        {reportData?.flow_status === "approved" && reportData?.payment_status === "initiated" && !location?.state?.bulk && <button
          className="approve"
          onClick={() => {
            setOpenAddNew(true)
          }}
        >
          {t('pay')}
        </button>}
        {!location?.state?.user ? (
          <>
            {
              reportData?.is_editable &&
                reportData?.flow_status === "pending_approval" ? (
                <>
                  <button
                    className="approve"
                    disabled={reportsApproveResponse?.loading || !hasWriteAccess}
                    onClick={() => {
                      dispatch(
                        approvePayment({ id: params.id, type: location?.state?.bulk ? "COMPANY_BULK_PAYMENTS" : "COMPANY_PAYMENTS" })
                      );
                    }}
                  >
                    {t('approve')}
                  </button>
                  <button
                    disabled={!hasWriteAccess}
                    className="reject"
                    onClick={() => {
                      setIsModalVisible(true);
                      setModalType("delete");
                    }}
                  >
                    {t('reject')}
                  </button>
                </>
              ) : null}
          </>
        ) : (
          <>
            {reportData?.role_status === "unsubmitted" &&
              <>
                <button
                  className="approve"
                  disabled={
                    userReportSubmitResponse?.loading || !hasWriteAccess
                  }
                  onClick={() => {
                    dispatch(
                      submitPayment({
                        type: location?.state?.bulk ? "COMPANY_BULK_PAYMENTS" : "COMPANY_PAYMENTS",
                        id: params.id
                      })
                    );
                  }}
                >
                  {t('submit')}
                </button>
                <button
                  disabled={!hasWriteAccess}
                  className="reject"
                  onClick={() => {
                    setEditPaymentModal(true);
                    setEdit(true);
                  }}
                >
                  {t('edit')}
                </button>
                <button
                  disabled={!hasWriteAccess}
                  className="delete"
                  onClick={() => { setDeletePaymentModal(true) }}
                >
                  {t('delete')}
                </button>
              </>
            }
            {reportData?.recallable && reportData?.flow_status !== "unsubmitted" && reportData?.flow_status !== "rejected" && location?.state?.user ? (
              <>
                <button
                  className="reject"
                  disabled={userReportRecallResponse?.loading || !hasWriteAccess}
                  onClick={() =>
                    dispatch(
                      recallPayment({
                        id: params.id,
                        type: location?.state?.bulk ? "COMPANY_BULK_PAYMENTS" : "COMPANY_PAYMENTS"
                      })
                    )
                  }
                >
                  {t('recall')}
                </button>
              </>
            ) : null}
          </>
        )}

        {/* <Dropdown overlay={menu} placement="bottomRight" >
          <button className="more">
            {t('more')}&nbsp;
            <DownOutlined />
          </button>
        </Dropdown> */}
        <span
          className="close cursor"


          // onClick={() => {
          //   history.push(location?.state?.from, {
          //     status: location?.state?.status,
          //   });
          // }}
          onClick={() => {
            if (location?.state?.bulk) {
              history.goBack()
            } else
              history.push({
                pathname: location?.state?.user ? "/transfers/beneficiary/payments" : `/transfers/beneficiary`,
              });
          }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div >
      <div className="new-report-detail">
        <div className="flex-between">
          <div className="report-head">
            <div className="title-sec">
              <h3>{reportLoading ? <Skeleton.Button style={{ width: "20rem" }} /> : (reportData?.payee_name ?? reportData?.payment_beneficiary_name)}</h3>
              {reportLoading ? null : <span className={`status status_${location?.state?.user ? reportData?.flow_status : reportData?.role_status}`}>
                {location?.state?.user ? reportData?.flow_status?.replace(/_/g, " ") : reportData?.role_status?.replace(/_/g, " ")}
              </span>}
            </div>
            <p>
              <strong>
                {t('submitted_by')}{" "}{reportLoading ? <Skeleton.Button style={{ width: "5rem", height: 15, marginLeft: "10px", marginTop: 5 }} /> : reportData?.submitted_by?.name} <br />
                <span className="sm">{t('on')} {reportLoading ? <Skeleton.Button style={{ width: "5rem", height: 15, marginLeft: "10px", marginTop: 5 }} /> : moment(reportData?.created_at).format("DD MMM,YYYY HH:mm a")}</span>
                <br />
                <span className="sm">
                  {t('to')}{" "}
                  {reportLoading ? <Skeleton.Button style={{ width: "7rem", height: 15, marginLeft: "10px", marginTop: 5 }} /> : (reportData?.submitted_to?.name)}
                </span>
              </strong>
            </p>
          </div>
          <div className="report-total">
            <p>
              <label>{t('Amount')}</label>
              <span className="lg">
                <BaseCurrency symbol_code={reportData?.currency ?? reportData?.currency_code} /> {amountFormat(location?.state?.bulk ? reportData?.payment_amount : reportData?.amount, reportData?.currency?.decimal_scale) || 0}
              </span>
              {((reportData?.payment_status && reportData?.payment_status !== "initiated") || (reportData?.status && reportData?.status !== "initiated")) && <div style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <label>{t('Payment status')}</label>
                <span className={`ml1 status status_${reportData?.payment_status ?? reportData?.status}`} style={{ border: "none", fontSize: 10 }}>
                  {(reportData?.payment_status ?? reportData?.status)?.replace(/_/g, " ")}
                </span>
              </div>}
            </p>

          </div>
        </div>

        <div className="tabs-sec">
          <ul>
            {secs.map((ele) => (
              <li
                onClick={() => {
                  setTab(ele.toLowerCase());
                  if (ele === "History") {
                    dispatch(
                      reportsHistory(
                        { report_id: params.id },
                        { is_admin: !location?.state?.user }
                      )
                    );
                  }
                }}
                className={`${tab.toLowerCase() === ele.toLowerCase() ? "active" : ""
                  }`}
              >
                {ele}
              </li>
            ))}
          </ul>
        </div>

        <div className="selected-tab">
          {tab === "details" ? ((
            <>
              <Row>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">
                      {t('submitted_by')}
                    </span>
                    <span className="details_span name">
                      {reportData?.submitted_by?.name}
                    </span>
                    <span className="details_span secondary sm">
                      {reportData?.submitted_by?.email}
                    </span>
                  </p>
                </Col>
                <Col span={6}>
                  {reportData?.submitted_to?.name && <p className="details_p">
                    <span className="details_span secondary">
                      {t('submitted_to')}
                    </span>
                    <span className="details_span name">
                      {reportData?.submitted_to?.name}
                    </span>
                    <span className="details_span secondary sm">
                      {reportData?.submitted_to?.email}
                    </span>
                    <span className="details_span secondary sm">{reportData?.submitted_on}</span>
                  </p>}
                </Col>
                {(reportData?.flow_status === "pending_approval" && reportData?.pending_with?.name) && <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">
                      {t("pendingApprovals")} :
                    </span>
                    <span className="details_span name">
                      {reportData?.pending_with?.name}
                    </span>
                    <span className="details_span secondary sm">
                      {reportData?.pending_with?.email}
                    </span>
                    {/* <button style={{ textAlign: "left", color: "#e99b39" }}>
                        View Approval Flow
                      </button> */}
                  </p>
                </Col>}
              </Row>
              <hr />
              <Row>
                <Col>
                  <p className="details_p">
                    <span className="details_span secondary">
                      {"description"} :
                    </span>
                    <span className="details_span name">
                      {reportData?.description}
                    </span>
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                {reportData?.custom_fields?.map(({ key, value }) => {
                  return (
                    <>
                      <Col span={24}>
                        <p className="details_p">
                          <span className="details_span secondary">
                            {key.split("_").join(" ").toUpperCase()}
                          </span>
                          {key !== "report1" ?
                            <span className="details_span name">{value}</span> : <>
                              {value.map((eachImg) => (
                                <img style={{ width: "18rem", height: "13rem" }} src={eachImg.url} alt="" />
                              )
                              )}
                            </>
                          }
                        </p>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </>
          )
          ) : null}

          {tab === "comments" ? (
            <>
              <div className="addComment mb1">
                <Input
                  placeholder={t('enter_comment')}
                  ref={inputComment}
                  value={input}
                  onInput={(e) => setInput(e.target.value.trimStart())}
                />
                <Button
                  disabled={!hasWriteAccess}
                  key="1"
                  className="formButton ml1"
                  onClick={() => hasWriteAccess && handleAddComment()}
                >
                  {t('send')}
                </Button>
              </div>

              {reportData?.comments?.length ? (
                <>
                  <div className="commentsSection">
                    <Steps
                      direction="vertical"
                      size="small"
                      current={reportData?.comments?.length + 1}
                    >
                      {reportData?.comments &&
                        reportData?.comments.map(({ body, created_at, name, id }) => (
                          <Steps.Step
                            key={id}
                            title={body}
                            // description={created_at + "/" + user_name}
                            description={`on ${moment(created_at).format("DD MMM YYYY, HH:mm a")} | Commented by: ${name}`}
                            icon={
                              <img src={Comment} alt="history" width={18} />
                            }
                          />
                        ))}
                    </Steps>
                  </div>
                </>
              ) : (
                <NoCommentsComponent />
              )}
            </>
          ) : null}

          {tab === "history" ? (
            reportHistory?.length ? (
              <div className="commentsSection">
                <Steps
                  direction="vertical"
                  size="small"
                  current={reportHistory?.length + 1 || 100}
                >
                  {reportHistory &&
                    reportHistory?.map(
                      ({ body, created_at, name, id, curr_status }) => (
                        <Steps.Step
                          key={id}
                          title={body}
                          description={
                            created_at +
                            " | " +
                            name +
                            " | Action: " +
                            curr_status?.replace(/_/g, " ")
                          }
                          icon={<img src={History} alt="history" width={18} />}
                        />
                      )
                    )}
                </Steps>
              </div>
            ) : (
              <NodataComponent noBtns={true} />
            )
          ) : null}
        </div>
        {deletePaymentModal &&
          <Modal title="Delete" className="disconnect-modal" width={470} open={deletePaymentModal}
            onCancel={onCancel} maskClosable={false} closeIcon={<CloseCircleOutlined style={{ fontSize: '1.5rem' }} />}>
            <>
              <p className='modal-text'>{t('deletePayment')}</p>
              <div className='form-btm mt3'
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                <Button
                  type="primary"
                  className="pp-main-button cursor"
                  onClick={handleConfirm}
                  disabled={deletePaymentResponse?.loading}
                >
                  <span>{t('confirm')}</span>
                </Button>
                <Button
                  className="pp-secondary-button cursor"
                  style={{ marginLeft: "2rem" }}
                  onClick={onCancel}
                >
                  <span>{t('cancel')}</span>
                </Button>
              </div>
            </>
          </Modal>
        }
        {editPaymentModal && <CreatePayment setRecord={setRecord} source={"PAYMENT"} open={editPaymentModal && hasWriteAccess} onClose={() => {
          setEditPaymentModal(false);
          setEdit(false);
        }} record={record ? record : ''} isEdit={edit} callOnMount={callOnMount} />}
      </div>
    </>
  );
};

export default NewPaymentDetail;
