import { Typography } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllBranchHeader } from '../../../actions/companySettings';
import { headerTitles } from '../../../actions/masterdata/masterdata';
import Header from '../../Header/Header';
import icon from "../../../assets/icons/org_icon.svg"
import company_icon from "../../../assets/icons/company_portal_icon.svg"
import customer_icon from "../../../assets/icons/customer_portal_icon.svg"
import vendor_icon from "../../../assets/icons/vendor_portal_icon.svg"
import moment from 'moment';
import { portalDashboardCumpanyList } from '../../../actions/customers';
const Portal = () => {
	const dispatch = useDispatch()
	const history = useHistory();
	const companyListData = useSelector((state) => state?.customers?.portalCompanyList?.data?.data)
	React.useEffect(() => {
		dispatch(headerTitles({
			title: `Hi ${localStorage.getItem("name")}`,
			description: 'You are a part of the following portals. Go to the portals which you wish to access now.'
		}))
	}, [])
	React.useEffect(() => {
		if (localStorage.getItem("isCustomer")) {
			localStorage.setItem("company_id", companyListData?.[0]?.id)
			history.push("/customer/dashboard")
		} else if (localStorage.getItem("isVendor")) {
			localStorage.setItem("company_id", companyListData?.[0]?.id)
			history.push("/vendor/dashboard")
		}
	}, [companyListData])
	const data = {
		web: {
			name: "Company Portal",
			buttonName: "View as Employee",
			note: "Mauris sed nisl elementum, hendrerit nunc sit amet, pharetra diam. Nullam suscipit sagittis ex, sit amet volutpat lacus mattis a. Nunc lacinia, metus vitae ornare facilisis, neque enim fringilla nisi, non convallis arcu ante vel nulla.",
			image: <img src={company_icon} alt="Company Portal Icon" className='w100 h100' />
		},
		customer: {
			name: "Customer Portal",
			buttonName: "View as Customer",
			note: "Mauris sed nisl elementum, hendrerit nunc sit amet, pharetra diam. Nullam suscipit sagittis ex, sit amet volutpat lacus mattis a. Nunc lacinia, metus vitae ornare facilisis, neque enim fringilla nisi, non convallis arcu ante vel nulla.",
			image: <img src={customer_icon} alt="Company Portal Icon" className='w100 h100' />
		},
		vendor: {
			name: "Vendor Portal",
			buttonName: "View as Vendor",
			note: "Mauris sed nisl elementum, hendrerit nunc sit amet, pharetra diam. Nullam suscipit sagittis ex, sit amet volutpat lacus mattis a. Nunc lacinia, metus vitae ornare facilisis, neque enim fringilla nisi, non convallis arcu ante vel nulla.",
			image: <img src={vendor_icon} alt="Company Portal Icon" className='w100 h100' />
		},
	}

	const handlePortal = (access) => {
		if (access == "web") {
			history.push("/organisation")
			localStorage.setItem("isCompany", true)
		} else if (access == "customer") {
			localStorage.setItem("isCustomer", true)
			dispatch(portalDashboardCumpanyList({ type: "customer" }))
			// history.push("/customer/dashboard")
		} else if (access == "vendor") {
			localStorage.setItem("isVendor", true)
			dispatch(portalDashboardCumpanyList({ type: "vendor" }))
			// history.push("/vendor/dashboard")
		}
	}

	return (
		<>
			<div className='organisation'>
				<Header isPortal={true} />
				<div className='organisation_section'>
					<div className='organisation_container'>

						<div className='organisation_cards_section'>
							{
								localStorage?.getItem("portal_access")?.split(",")?.map((portalElement, index) => (
									<div className='organisation_card'>
										<div className='organisation_logo'>
											{/* <img src={icon} alt='portal logo' /> */}
											{data?.[portalElement]?.image}
										</div>
										<div className='organisation_details'>
											<h4 className='organisation_name mb0'>{data?.[portalElement]?.name}</h4>
											<h5 className='user_name'>{localStorage?.getItem("name")}</h5>
										</div>
										<div className='button_setion mt1'>
											<button className='go_to_organisation primarycolor' onClick={() => {
												handlePortal(portalElement)
											}}>{data?.[portalElement]?.buttonName}</button>
										</div>
										{/* <div className='mt1'>
                                            <span className='organisation_admin'><span className='role'>Note:</span> {data?.[portalElement]?.note}</span>
                                        </div> */}
									</div>
								)
								)
							}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Portal