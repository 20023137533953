import { Button, Checkbox, message } from "antd";
import React, { useEffect } from "react";
import {
  LoginOutlined,
  BankFilled,
  FileFilled,
  AmazonSquareFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import useAdminAxios from "../../../../config/useAdmin";
import { apis } from "../../../../config/APIs";

const UserPlans = ({ register, setValue, watch, onSuccess, handleSubmit }) => {
  const history = useHistory();
  const { onCall, data } = useAdminAxios({
    api: "/v1/admin/0/company/fetchCompanyPlanMasters",
    method: "get",
  });
  useEffect(() => {
    onCall({});
  }, []);

  const { onCall: createAccount, loading } = useAdminAxios({
    api: apis.onboardPostAPI,
    method: "post",
  });
  const onSubmit = (data) => {
    data.business_email = data.email;
    createAccount({
      data,
    })
      .then((customer) => {
        if (customer.error === false) {
          onSuccess();
          message.success(
            <span className="messageText">{customer?.message}</span>
          );
        }
        if (customer.error) {
          message.error(
            <span className="messageText">{customer?.message}</span>
          );
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        }
      });
  };

  const plans = watch("plan_data");

  const getIcon = (icon) => {
    switch (icon) {
      case "bank":
        return <BankFilled />;
      case "currency":
        return <FileFilled />;
      case "amazon":
        return <AmazonSquareFilled />;

      default:
        return <LoginOutlined />;
    }
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="check-box-div">
          <div className="check-box-button">
            <input type="hidden" {...register("plan_data")} />
            <Checkbox.Group
              style={{
                width: "100%",
              }}
              className="checkbox-group"
            >
              {data?.data?.map((ele, i) => (
                <Checkbox
                  value={ele.id}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue("plan_data", [...plans, ele.id]);
                    } else {
                      plans.splice(i, 1);
                      setValue("plan_data", plans);
                    }
                  }}
                >
                  {getIcon(ele.icons)}
                  <div className="text-div">{ele.description}</div>
                </Checkbox>
              ))}
            </Checkbox.Group>
          </div>
        </div>
        <div className="mt1">
          <span className="footer-note">
            I agree to the
            <b
              className="cursor"
              style={{ color: "#3F81F4" }}
              onClick={() => {
                history.push("/");
              }}
            >
              {" "}
              Terms & Conditions &nbsp;
            </b>
            and
            <b
              className="cursor"
              style={{ color: "#3F81F4" }}
              onClick={() => {
                history.push("/");
              }}
            >
              &nbsp; Policies
            </b>
          </span>
        </div>
        <div className={`pp-form-item field_length mt2 margin-auto justify-center`}>
          <Button
            type="primary"
            htmlType="submit"
            className="pp-main-button"
            id="create"
            loading={loading}
          >
            Create Account
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default UserPlans;
