import { Button, message, Modal } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { get_items } from "../../../actions/purchases/purchases";
import { apis } from "../../../config/APIs";
import usePayAxios from "../../../config/useAxios";
import { FormInput, FormSelect } from "../../inputs";
import { useTranslation } from "react-i18next";
import useDocumentAxios from "../../../config/useDocument";

const AddItemModal = ({ isVisible, onCancel, onSuccess }) => {
  const { onCall } = usePayAxios({ api: apis.createItem, method: "post" });
  const { onCall: unitType, data: unitTypeData } = useDocumentAxios({
    api: apis?.unitTypeGETAPI,
    method: "post",
    baseURL: "settings",
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      item_name: "",
      hsn_code: "",
      unit_price: "",
      sku: "",
      item_type: "",
      tax_type: "",
      total_amount: "",
      discount_percentage: "",
      tax_amount: "",
      quantity: 1,
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload = {
      item_name: data.item_name,
      hsn_code: data.hsn_code,
      unit_price: Number(data.unit_price),
      sku: data.sku,
      quantity: Number(data.quantity),
      item_type: data.item_type,
      discount_percentage: Number(data.discount_percentage),
      total_amount: Number(data.quantity) * Number(data.unit_price),
    };

    onCall({
      data: payload,
    })
      .then((res) => {
        dispatch(get_items());
        message.success(<span className="messageText">{res?.message}</span>);
        onSuccess &&
          onSuccess({
            id: res?.data?.id,
            item_name: res?.data?.item_name,
            hsn_code: res?.data?.hsn_code,
            unit_price: Number(res?.data?.unit_price),
            sku: res?.data?.sku,
            quantity: Number(res?.data?.quantity),
            item_type: res?.data?.item_type,
            stock_on_hand: res?.data?.stock_on_hand,
          });
        reset();
        onCancel();
      })
      .catch((err) => {
        message.error(<span className="messageText">{err.message}</span>);
      });
  };

  useEffect(() => {
    unitType({
      params: {
        isCsv: true,
        is_enable: true,
      },
    });
  }, []);
  return (
    <>
      <Modal title="Add Item" visible={isVisible} onCancel={onCancel}>
        <div>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(onSubmit)(e);
            }}
          >
            <div className="pp-form-item p0">
              <FormInput
                control={control}
                {...register("item_name", {
                  required: "Item Name is required",
                })}
                errors={errors}
                label={t("item_name")}
                placeholder={t("enter_item_name")}
                required={true}
              />
            </div>
            <div className="pp-form-item p0">
              <FormSelect
                inline
                control={control}
                {...register("item_type", {
                  required: "Item type is required",
                })}
                required={true}
                errors={errors}
                label={t("item_type")}
                placeholder={t("select")}
                hideCreateOption={true}
                //   onSearch={onVendorSearch}
                options={[
                  { label: "Goods", value: "Goods" },
                  { label: "Services", value: "Services" },
                ]}
                //   onAddItem={handlerNewVendor}
                labelColor={false}
              />
            </div>
            <div className="pp-form-item p0">
              <FormSelect
                inline
                control={control}
                {...register("unit_type", {
                  onChange: (e) => {},
                })}
                errors={errors}
                label={t("Unit")}
                placeholder="Select"
                options={
                  Array.isArray(unitTypeData?.data)
                    ? unitTypeData?.data?.map((item) => ({
                        value: item?.id,
                        label: item?.unit_name,
                      }))
                    : []
                }
                hideCreateOption={true}
                noPaddingTop={true}
              />
            </div>
            <div className="pp-form-item p0">
              <FormInput
                inline
                control={control}
                {...register("sku")}
                errors={errors}
                label={"SKU (Stock Keeping Unit)"}
                placeholder={t("enter_sku")}
                //   onSearch={onVendorSearch}
                //   options={customer}
                //   onAddItem={handlerNewVendor}
              />
            </div>
            <div className="pp-form-item p0">
              <FormInput
                control={control}
                {...register("hsn_code")}
                errors={errors}
                label={t("hsn_code")}
                placeholder={t("enter_hsn_code")}
              />
            </div>
            <div className="pp-form-item p0">
              <FormInput
                control={control}
                {...register("unit_price")}
                errors={errors}
                label={t("unit_price")}
                placeholder={t("enter_unit_price")}
                number={true}
              />
            </div>
            <div className="d-flex gap1 mt2">
              <button
                type="submit"
                className="pp-main-button"
                id="item-btn"
                style={{
                  color: "#fff",
                }}
              >
                {t("save")}
              </button>
              <Button className="pp-secondary-button" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddItemModal;
