import React from 'react';
import { Page, Document, Text, StyleSheet, View } from '@react-pdf/renderer';

function PdfDocument({ pdfDocumentData }) {
    const styles = StyleSheet.create({
        page: { 
          flexDirection: 'row',
          backgroundColor: '#E4E4E4'
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
        },
        title: {
          fontSize: 24,
          textAlign: 'center',
          marginBottom: 10
        },
        subTitle: {
          fontSize: 18,
          marginBottom: 5
        },
        text: {
          fontSize: 12,
          marginBottom: 10
        }
      });
  return (
        <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.text}>
            {pdfDocumentData}
            </View>
          </View>
        </Page>
      </Document>
  );
}

export default PdfDocument;
