import { EyeOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { advanceApproval, getRoles } from "../../../actions/companySettings";
import { ChangePriority, CreatePolicy, DeletePolicy, EnablePolicy, GetPolicy, changePolicyPriority, deletePolicy, enablePolicy, getPolicies, getPoliciesMeta, getPolicy } from "../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../assets/icons/delete.png";
import EditIcon from "../../../assets/icons/edit.png";
import AddAdvancedApproval from "../../Common/AddAdvancedApproval";
import DraggableTable from "./DraggableTable";

const AdvancedApproval = (
  { type, inv_type, approvalData, hasWriteAccess }
) => {
  const [showAdvancedApproval, setShowAdvancedApproval] = useState(false)
  const [delModal, setDelModal] = useState(false)
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const policies = useSelector(state => state.policies.policies || {});
  const { data: { data: demoData = [] } = {} } = policies
  const create = useSelector(state => state.policies.create || {});
  const enalbe = useSelector(state => state.policies.enalbe || {});
  const delete_policy = useSelector(state => state.policies.delete || {});
  const get_policy = useSelector(state => state.policies.policy?.data?.data || {});
  const change_priority = useSelector(state => state.policies.change_priority || {});
  const [selectedData, setSelectedData] = useState({});
  const [priorities, setPriorities] = useState([]);

  const columns = [
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      //   render: text => <a href="/">{text}</a>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];
  const tableAction = [{
    title: 'Enable',
    dataIndex: 'is_enable',
    key: 'is_enable',
    render: (is_enable, record) =>
      <Checkbox key={record.id}
        onChange={() => dispatch(enablePolicy({ id: record.id, enableFlag: !record.is_enable, module_type: type }))}
        checked={is_enable}
        disabled={!hasWriteAccess}
      ></Checkbox >
  },

  hasWriteAccess ? {
    title: "Actions",
    key: "action",
    className: "actions",
    render: (text, record) => (
      record.role_name === "Admin" ? null :
        //   hasAccess("users_and_controls", "write") && record.role_name !== "Employee" && 
        <Space key={record.id} size="middle">
          <button
            onClick={() => {
              dispatch(getPolicy({
                id: record?.id,
                module_type: type
              }));
              setShowAdvancedApproval(true)
            }}
            disabled={!hasWriteAccess}
          >
            <img src={EditIcon} alt={"logo"} className="action-logo" />
          </button>
          {/* {record.role_name !== "Employee" && record.role_name !== "Reporting Manager" &&  */}
          <button
            onClick={() => {
              setDelModal(true)
              setSelectedData(record)
            }}
            disabled={!hasWriteAccess}
          >
            <img src={DeleteIcon} alt={"logo"} className="action-logo" />
          </button>
          {/* } */}
        </Space>
    ),
  } : {
    title: "Actions",
    key: "action",
    render: (text, record) => (
      record.role_name === "Admin" ? null :
        //   hasAccess("users_and_controls", "write") && record.role_name !== "Employee" && 
        <Space key={record.id} size="middle">
          <button
            onClick={() => {
              dispatch(getPolicy({
                id: record?.id,
                module_type: type
              }));
              setShowAdvancedApproval(true)
            }}
          // disabled={!hasWriteAccess}
          >
            <EyeOutlined />
          </button>
        </Space>
    ),
  },
  ];
  useEffect(() => {
    dispatch(getPolicies({ module_type: type }))
    dispatch(advanceApproval({ module_type: inv_type }));
    dispatch(getRoles({}));
    dispatch(getPoliciesMeta({
      module_type: type
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (get_policy?.name) {
      setSelectedData(get_policy)
      dispatch({
        type: GetPolicy.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_policy])
  useEffect(() => {
    if (create?.data?.error === false) {
      message.success(<span className="messageText">Advanced approval policy {selectedData?.id ? 'updated' : 'created'}.</span>)
      dispatch({
        type: CreatePolicy.RESET
      })
      dispatch(getPolicies({ module_type: type }))
      setShowAdvancedApproval(false)
      setSelectedData({})
    }
    if (create?.data?.error) {
      message.error(<span className="messageText">Advanced approval policy failed to {selectedData?.id ? 'update' : 'create'}.</span>)
      dispatch({
        type: CreatePolicy.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create])

  useEffect(() => {
    if (enalbe?.data?.error === false) {
      message.success(<span className="messageText">{enalbe?.data?.message}</span>)
      dispatch({
        type: EnablePolicy.RESET
      })
      dispatch(getPolicies({ module_type: type }))
    }
    if (enalbe?.data?.error) {
      message.error(<span className="messageText">{enalbe?.data?.message}</span>)
      dispatch({
        type: EnablePolicy.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enalbe])

  useEffect(() => {
    if (change_priority?.data?.error === false) {
      dispatch({
        type: ChangePriority.RESET
      })
      dispatch(getPolicies({ module_type: type }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change_priority])

  useEffect(() => {
    if (delete_policy?.data?.error === false) {
      message.success(<span className="messageText">Deleted Policy.</span>)
      dispatch({
        type: DeletePolicy.RESET
      })
      dispatch(getPolicies({ module_type: type }))
      setDelModal(false)
      dispatch(changePolicyPriority({
        changingPriority: priorities,
        module_type: type
      }))
      setSelectedData({})
    }
    if (delete_policy?.data?.error) {
      message.error(<span className="messageText">Delete Policy Failed</span>)
      dispatch({
        type: DeletePolicy.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delete_policy])
  return (
    <>
      {showAdvancedApproval && <AddAdvancedApproval hasWriteAccess={hasWriteAccess} approvalData={approvalData} type={type} open={showAdvancedApproval} selectedData={selectedData} onClose={() => {
        setShowAdvancedApproval(false)
        setSelectedData({})
      }} />}
      <Modal
        title="Delete Policy"
        footer={[]}
        visible={delModal}
        onCancel={() => {
          setDelModal(false)
          setSelectedData({})
        }}
      >
        Are you sure you want to delete policy ?
        <br />
        <Button
          key="1"
          className="formButton mt1"
          disabled={delete_policy?.loading || !hasWriteAccess}
          onClick={() => {
            dispatch(deletePolicy({ id: selectedData.id, module_type: type }))
            setPriorities(demoData?.filter(ele => ele.id !== selectedData.id)?.map((ele, index) => ({ id: ele.id, priority: index + 1 })))
          }}
        >
          {t('confirm')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => {
            setDelModal(false)
            setSelectedData({})
          }}
        >
          {t('cancel')}
        </Button>
      </Modal>
      <div className="approval-section">
        <div className="approval-section">
          <div className="header-section">
            <h4 className="header-text">Advanced Approval</h4>
            <span className="sub-header-text">You can set up an approval flow based on one or more criteria. These criteria can be chosen by you.</span>
          </div>
          <div className="table-button flex-end">
            {/* <Button className="mb1 approval-change-btn">
               <img src={AddNewIcon} alt={"logo"} className="addNew-logo " /> 
              <SwapOutlined />
              Change Priority
            </Button> */}
            {
              <Button disabled={!hasWriteAccess} onClick={() => setShowAdvancedApproval(true)} className="mb1 addNew primaryButton">
                <img src={AddNewIcon} alt={"logo"} className="addNew-logo " />
                Add Policy
              </Button>}
          </div>
          <div className="table-section settingsTable draggable">
            <DraggableTable
              columns={[...columns, ...tableAction]}
              data={demoData}
              className="settingsTable"

              inv_type={type}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default AdvancedApproval;