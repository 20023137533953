import { ActionTypesFactory } from "../../utils";


export const Advances = ActionTypesFactory("Advances", "Advances");
export const AdvancesReject = ActionTypesFactory("AdvancesReject", "Advances");
export const TransactionsReject = ActionTypesFactory("TransactionsReject", "Advances");
export const TransactionsPartialApprove = ActionTypesFactory("TransactionsPartialApprove", "Advances");
export const AdvancesApprove = ActionTypesFactory("AdvancesApprove", "Advances");
export const AdvancesExpDetails = ActionTypesFactory("AdvancesExpDetails", "Advances");
export const AdvancesExpComments = ActionTypesFactory("AdvancesExpComments", "Advances");
export const AdvancesExpAddComments = ActionTypesFactory("AdvancesExpAddComments", "Advances");
export const AdvancesComments = ActionTypesFactory("AdvancesComments", "Advances");
export const AdvancesCommentsAdd = ActionTypesFactory("AdvancesCommentsAdd", "Advances");
export const AdvancesExportExcel = ActionTypesFactory("AdvancesExportExcel", "Advances");
export const AdvancesHistory = ActionTypesFactory("AdvancesHistory", "Advances");
export const AdvancesExpHistory = ActionTypesFactory("AdvancesExpHistory", "Advances");
export const AdvancesDetailPDF = ActionTypesFactory("AdvancesDetailPDF", "Advances");


export const UserAdvances = ActionTypesFactory("UserAdvances", "Advances");
export const UserAdvancesSubmit = ActionTypesFactory("UserAdvancesSubmit", "Advances");
export const UserAdvanceDelete = ActionTypesFactory("UserAdvanceDelete", "Advances");
export const UserAdvancesSubmitReset = ActionTypesFactory("UserAdvancesSubmitReset", "Advances");
export const AddUserAdvance = ActionTypesFactory("AddUserAdvance", "Advances");
export const UpdateUserAdvance = ActionTypesFactory("UpdateUserAdvance", "Advances");
export const UserAdvanceFields = ActionTypesFactory("UserAdvanceFields", "Advances");
export const AdvanceShow = ActionTypesFactory("AdvanceShow", "Advances");

export const userAdvances = (payload) => {
  return {
    type: UserAdvances.REQUEST,
    payload,
  };
};

export const showAdvance = (payload) => {
  return {
    type: AdvanceShow.REQUEST,
    payload,
  };
};

export const advance_pdf = (payload) => {
  return {
    type: AdvancesDetailPDF.REQUEST,
    payload,
  };
};


export const userAdvancesSubmit = (payload) => {
  return {
    type: UserAdvancesSubmit.REQUEST,
    payload,
  };
};

export const userAdvanceDelete = (payload) => {
  return {
    type: UserAdvanceDelete.REQUEST,
    payload,
  };
};

export const userAdvancesSubmitReset = (payload) => {
  return {
    type: UserAdvancesSubmit.RESET,
    payload,
  };
};

export const advances = (payload) => {
  return {
    type: Advances.REQUEST,
    payload,
  };
};

export const advancesReject = (payload) => {
  return {
    type: AdvancesReject.REQUEST,
    payload,
  };
};

export const transactionsReject = (payload) => {
  return {
    type: TransactionsReject.REQUEST,
    payload,
  };
};

export const transactionsPartialApprove = (payload) => {
  return {
    type: TransactionsPartialApprove.REQUEST,
    payload,
  };
};

export const advancesApprove = (payload) => {
  return {
    type: AdvancesApprove.REQUEST,
    payload,
  };
};

export const advanceApproveReset = (payload) => {
  return {
    type: AdvancesApprove.RESET,
    payload,
  };
};

export const advanceRejectReset = (payload) => {
  return {
    type: AdvancesReject.RESET,
    payload,
  };
};

export const advancesExpDetails = (payload) => {
  return {
    type: AdvancesExpDetails.REQUEST,
    payload,
  };
};

export const advancesExpComments = (payload, otherParams) => {
  return {
    type: AdvancesExpComments.REQUEST,
    payload,
    otherParams
  };
};

export const advancesExpAddComments = (payload, otherParams) => {
  return {
    type: AdvancesExpAddComments.REQUEST,
    payload,
    otherParams
  };
};

export const advancesComments = (payload, otherParams) => {
  return {
    type: AdvancesComments.REQUEST,
    payload,
    otherParams
  };
};

export const advancesCommentsAdd = (payload, otherParams) => {
  return {
    type: AdvancesCommentsAdd.REQUEST,
    payload,
    otherParams
  };
};

export const advancesExportExcel = (payload) => {
  return {
    type: AdvancesExportExcel.REQUEST,
    payload,
  };
};

export const advancesHistory = (payload, otherParams) => {
  return {
    type: AdvancesHistory.REQUEST,
    payload,
    otherParams
  };
};

export const advancesExpHistory = (payload, otherParams) => {
  return {
    type: AdvancesExpHistory.REQUEST,
    payload,
    otherParams
  };
};

export const addUserAdvance = (payload) => {
  return {
    type: AddUserAdvance.REQUEST,
    payload,
  };
};

export const updateUserAdvance = (payload) => {
  return {
    type: UpdateUserAdvance.REQUEST,
    payload,
  };
};

export const userAdvanceFields = (payload) => {
  return {
    type: UserAdvanceFields.REQUEST,
    payload,
  };
};

