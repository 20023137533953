import { ActionTypesFactory } from "../../utils";

export const AllTemplates = ActionTypesFactory("AllTemplates", "AllTemplates");
export const UpdateTemplates = ActionTypesFactory("UpdateTemplates", "UpdateTemplates");
export const DefaultTemplate = ActionTypesFactory("DefaultTemplate", "DefaultTemplate");
export const OldTemplate =  ActionTypesFactory("OldTemplate", "OldTemplate");

export const allTemplates = (payload) => {
    return {
        type : AllTemplates.REQUEST,
        payload,
    }
}

export const updateTemplates = (payload) => {
    return {
        type : UpdateTemplates.REQUEST,
        payload,
    }
}

export const defaultTemplate = (payload) => {
    return {
        type : DefaultTemplate.REQUEST,
        payload,
    }
}



export const oldTemplate = (payload) => {
    return {
        type : OldTemplate.REQUEST,
        payload,
    }
}