import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions, sagaFunctionsAnalytics, sagaFunctionsRootiFiIntegration } from "../../utils";
import {
  ActivateCard,
  AnalyticsCards,
  AnalyticsCardsAll,
  AnalyticsCardsExport,
  AnalyticsEstimates,
  Block,
  BlockReasons,
  CardLimitEnable,
  CardLimitSet,
  UpdateCardLimitSet,
  CardPolicies,
  CardPoliciesDelete,
  CardTxnType,
  Cards,
  CardsDetails,
  CorporateBudget,
  CreateInviteLink,
  DisconnectCompany,
  DownloadBulk,
  GetAllOrganisations,
  GetCardLimit,
  GetCardPolicies,
  GetOneCompany,
  GetSyncHistory,
  QucikLogs,
  RemindCardActivation,
  Replacement,
  SendOTP,
  SetCardPolicy,
  SetPin,
  ShowCardPolicy,
  SyncData,
  Topup,
  Transactions,
  TransferLogs,
  UpdateMobileNumber,
  UploadBulk,
  UserCards,
  VerifyOTP,
} from "./cards";

function getCards(req) {
  return sagaFunctions(Cards, "get", apis.cards, req.payload)();
}

function cardsPoliciesDelete(req) {
  return sagaFunctions(CardPoliciesDelete, "post", apis.cardPolicyDelete, req.payload,{},true)();
}

function getQuickLogs(req) {
  return sagaFunctions(QucikLogs, "get", apis.qucikLoad, req.payload)();
}

function getCardPoliciesNLimits(req) {
  return sagaFunctions(GetCardPolicies, "get", apis.cardPoliciesNLimits,req.payload,{},true)();
}

function getCardLimits(req) {
  return sagaFunctions(GetCardLimit, "get", apis.cardLimit, req.payload)();
}

function getCardPolicies(req) {
  if (req?.payload?.module_type === "EXPENSE") {
    return sagaFunctions(CardPolicies, "get", apis.fetch_expense_policy_data, req.payload,{},true)();
  }
  return sagaFunctions(CardPolicies, "get", apis.cardPolicies, req.payload,{},true)();
}

function setCardPolicies(req) {
  return sagaFunctions(CardLimitSet, "post", apis.cardLimitSet, req.payload, {
    Authorization: localStorage.getItem("token"),
    "Content-Type": "application/json;charset=UTF-8",
  },true)();
}
function editCardPolicies(req) {
  return sagaFunctions(UpdateCardLimitSet, "post", apis.updatecardLimitSet, req.payload, {
    Authorization: localStorage.getItem("token"),
    "Content-Type": "application/json;charset=UTF-8",
  },true)();
}

function cardEnableLimit(req) {
  return sagaFunctions(
    CardLimitEnable,
    "post",
    apis.enableTxnType,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json; charset=UTF-8",
    }
  )();
}

function cardTxnTypeLimit(req) {
  return sagaFunctions(CardTxnType, "post", apis.enableCardLimit, req.payload, {
    Authorization: localStorage.getItem("token"),
    "Content-Type": "application/json; charset=UTF-8",
  })();
}

function downloadBulk(req) {
  return sagaFunctions(DownloadBulk, "get", apis.downloadBulk, req.payload)();
}

function getTransferlog(req) {
  return sagaFunctions(TransferLogs, "get", apis.tranferlog, null)();
}

function setPin(req) {
  return sagaFunctions(SetPin, "post", apis.set_pin, req.payload)();
}

function verify_otp(req) {
  return sagaFunctions(VerifyOTP, "post", apis.verify_otp, req.payload)();
}

function activate_card(req) {
  return sagaFunctions(ActivateCard, "post", apis.activate, req.payload)();
}

function sendOtp(req) {
  return sagaFunctions(SendOTP, "post", apis.send_otp, req.payload)();
}

function update_mobile_number(req) {
  return sagaFunctions(UpdateMobileNumber, "post", apis.update_mobile_number, req.payload)();
}

function remind_card_activation(req) {
  return sagaFunctions(RemindCardActivation, "post", apis.remind_card_activation, req.payload)();
}

function analyticsCards(req) {
  let type = req.payload.type;
  delete req.payload.type;
  if (type === "reports") {
    return sagaFunctionsAnalytics(
      AnalyticsCards,
      "post",
      apis.analytics_reports,
      req.payload,
      {
        Authorization: localStorage.getItem("token"),
      }
    )();
  } else if (type === "expenses") {
    return sagaFunctionsAnalytics(
      AnalyticsCards,
      "post",
      apis.analytics_expenses,
      req.payload,
      {
        Authorization: localStorage.getItem("token"),
      }
    )();
  } else if (type === "trips") {
    return sagaFunctionsAnalytics(
      AnalyticsCards,
      "post",
      apis.analytics_trips,
      req.payload
    )();
  } else if (type === "advances") {
    return sagaFunctionsAnalytics(
      AnalyticsCards,
      "post",
      apis.analytics_advances,
      req.payload
    )();
  }
  return sagaFunctionsAnalytics(
    AnalyticsCards,
    "get",
    apis.analytics_cards,
    req.payload,
  )();
}

function analyticsCardsAll(req) {
  let type = req.payload.type;
  delete req.payload.type;
  if (type === "reports") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsAll,
      "post",
      apis.analytics_reports,
      req.payload,
    )();
  } else if (type === "expenses") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsAll,
      "post",
      apis.analytics_expenses,
      req.payload,
    )();
  } else if (type === "trips") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsAll,
      "post",
      apis.analytics_trips,
      req.payload
    )();
  } else if (type === "advances") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsAll,
      "post",
      apis.analytics_advances,
      req.payload
    )();
  }
  else if(type ===  "sales"){
    return sagaFunctionsAnalytics(
      AnalyticsCardsAll,
      "post",
      apis.getAnalyticsInvoices,
      req.payload
    )();
  }
  else if(type ===  "purchases"){
    return sagaFunctionsAnalytics(
      AnalyticsCardsAll,
      "post",
      apis.getAnalyticsEstimates,
      req.payload
    )();
  }
  else if(type ===  "payement_received"){
    return sagaFunctionsAnalytics(
      AnalyticsCardsAll,
      "post",
      apis.getAnalyticsPaymentReceived,
      req.payload
    )();
  }
  else if(type ===  "payment_made"){
    return sagaFunctionsAnalytics(
      AnalyticsCardsAll,
      "post",
      apis.getAnalyticsPaymentMade,
      req.payload
    )();
  }
  return sagaFunctionsAnalytics(
    AnalyticsCardsAll,
    "get",
    apis.analytics_cards,
    req.payload,
  )();
}

function analyticsCardsExport(req) {
  let type = req.payload?.type;
  delete req.payload?.type;
  if (type === "reports") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsExport,
      "get",
      apis.txn_report_export,
      req.payload,
      {
        Authorization: localStorage.getItem("token"),
      }
    )();
  } else if (type === "expenses") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsExport,
      "get",
      apis.analytics_expenses_export,
      req.payload,
      {
        Authorization: localStorage.getItem("token"),
      }
    )();
  } else if (type === "trips") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsExport,
      "get",
      apis.analytics_trips_export,
      req.payload,
      {
        Authorization: localStorage.getItem("token"),
      }
    )();
  } else if (type === "advances") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsExport,
      "get",
      apis.analytics_advances_export,
      req.payload,
      {
        Authorization: localStorage.getItem("token"),
      }
    )();
  } else if (type === "isModuleType") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsExport,
      "get",
      apis.pdfAnalyticsEstimates,
      req.payload,
    )();
  } else if (type === "analytics_invoices") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsExport,
      "get",
      apis.pdfAnalyticsInvoices,
      req.payload,
    )();
  } else if (type === "analytics_payment_made") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsExport,
      "get",
      apis.pdfAnalyticsPaymentMade,
      req.payload,
    )();
  } else if (type === "analytics_payment_received") {
    return sagaFunctionsAnalytics(
      AnalyticsCardsExport,
      "get",
      apis.pdfAnalyticsPaymentReceived,
      req.payload,
    )();
  }
  return sagaFunctionsAnalytics(
    AnalyticsCardsExport,
    "get",
    apis.txn_card_export,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getUserCards(req) {
  return sagaFunctions(UserCards, "get", apis.userCards, req.payload)();
}

function block_reasons(req) {
  return sagaFunctions(BlockReasons, "post", apis.blockReasons, req.payload)();
}

function showCardPolicy(req) {
  return sagaFunctions(ShowCardPolicy, "post", apis.show_card_policy, req.payload,{},true)();
}

function setCardPolicy(req) {
  return sagaFunctions(SetCardPolicy, "post", apis.setCardLimit, req.payload)();
}

function getCardsDetails(req) {
  return sagaFunctions(CardsDetails, "get", apis.cardsDetails, req.payload)();
}

function getTransactions(req) {
  return sagaFunctions(
    Transactions,
    "get",
    apis.cardTransactions,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getTopup(req) {
  return sagaFunctions(Topup, "post", apis.topup, req.payload, {
    "Content-Type": "multipart/formdata",
    Authorization: localStorage.getItem("token"),
  })();
}

function uploadBulk(req) {
  return sagaFunctions(UploadBulk, "post", apis.uploadBulk, req.payload, {
    "Content-Type": "multipart/formdata",
    Authorization: localStorage.getItem("token"),
  })();
}

function block_card(req) {
  return sagaFunctions(Block, "post", apis.block, req.payload)();
}

function replacement(req) {
  return sagaFunctions(Replacement, "post", apis.replacement, req.payload)();
}

function corporate_budget(req) {
  return sagaFunctions(CorporateBudget, "post", apis.corporate_budget, req.payload, {}, true)();
}

function analyticsEstimates(req) {
  let type = req?.payload?.type
  let requested;
  if(type === "analytics_invoices"){
    requested = apis.getAnalyticsInvoices + `?module_type=`+req?.payload?.module_name
  }else if(type === "analytics_payment_received"){
    requested = apis.getAnalyticsPaymentReceived + `?module_type=SALES_ORDER`
  }else if(type === "analytics_payment_made"){
    requested = apis.getAnalyticsPaymentMade+ `?module_type=BILL`
  }
  else{
    requested = apis.getAnalyticsEstimates;
  } 
  delete req?.payload?.type
  return sagaFunctionsAnalytics(AnalyticsEstimates, "post", requested, req.payload)();
}
////// Rootifi /////
function createInviteLink(req) {
  return sagaFunctionsRootiFiIntegration(CreateInviteLink, "post", apis.createInviteLink, req.payload, {})();
}

function getOneCompany(req) {
  let url  = req?.payload?.url;
  return sagaFunctionsRootiFiIntegration(GetOneCompany, "get", apis.getOneCompany + `${url}`, "", {})();
}

function getAllOrganisations(req) {
  return sagaFunctionsRootiFiIntegration(GetAllOrganisations, "post", apis.getAllOrganisations, req.payload, {})();
}

function syncData(req) {
  return sagaFunctionsRootiFiIntegration(SyncData, "post", apis.syncData, req.payload, {})();
}

function disconnectCompany(req) {
  return sagaFunctionsRootiFiIntegration(DisconnectCompany, "post", apis.disconnectCompany, req.payload, {})();
}

function getSyncHistory(req) {
  return sagaFunctionsRootiFiIntegration(GetSyncHistory, "post", apis.syncHistory, req.payload, {})();
}

export function* cardsWatcher() {
  yield takeLatest(Cards.REQUEST, getCards);
  yield takeLatest(SendOTP.REQUEST, sendOtp);
  yield takeLatest(VerifyOTP.REQUEST, verify_otp);
  yield takeLatest(UpdateMobileNumber.REQUEST, update_mobile_number);
  yield takeLatest(ActivateCard.REQUEST, activate_card);
  yield takeLatest(ShowCardPolicy.REQUEST, showCardPolicy);
  yield takeLatest(SetCardPolicy.REQUEST, setCardPolicy);
  yield takeLatest(CardPoliciesDelete.REQUEST, cardsPoliciesDelete);
  yield takeLatest(CardLimitEnable.REQUEST, cardEnableLimit);
  yield takeLatest(CardTxnType.REQUEST, cardTxnTypeLimit);
  yield takeLatest(QucikLogs.REQUEST, getQuickLogs);
  yield takeLatest(GetCardLimit.REQUEST, getCardLimits);
  yield takeLatest(GetCardPolicies.REQUEST, getCardPoliciesNLimits);
  yield takeLatest(CardPolicies.REQUEST, getCardPolicies);
  yield takeLatest(CardLimitSet.REQUEST, setCardPolicies);
  yield takeLatest(UpdateCardLimitSet.REQUEST, editCardPolicies);
  yield takeLatest(UserCards.REQUEST, getUserCards);
  yield takeLatest(CardsDetails.REQUEST, getCardsDetails);
  yield takeLatest(Transactions.REQUEST, getTransactions);
  yield takeLatest(Topup.REQUEST, getTopup);
  yield takeLatest(Block.REQUEST, block_card);
  yield takeLatest(DownloadBulk.REQUEST, downloadBulk);
  yield takeLatest(UploadBulk.REQUEST, uploadBulk);
  yield takeLatest(CorporateBudget.REQUEST, corporate_budget);
  yield takeLatest(Replacement.REQUEST, replacement);
  yield takeLatest(TransferLogs.REQUEST, getTransferlog);
  yield takeLatest(AnalyticsCards.REQUEST, analyticsCards);
  yield takeLatest(AnalyticsCardsAll.REQUEST, analyticsCardsAll);
  yield takeLatest(AnalyticsCardsExport.REQUEST, analyticsCardsExport);
  yield takeLatest(SetPin.REQUEST, setPin);
  yield takeLatest(RemindCardActivation.REQUEST, remind_card_activation);
  yield takeLatest(AnalyticsEstimates.REQUEST, analyticsEstimates);
  yield takeLatest(BlockReasons.REQUEST, block_reasons);
  //// Rootifi /////
  yield takeLatest(CreateInviteLink.REQUEST, createInviteLink);
  yield takeLatest(GetOneCompany.REQUEST, getOneCompany);
  yield takeLatest(GetAllOrganisations.REQUEST, getAllOrganisations);
  yield takeLatest(SyncData.REQUEST, syncData);
  yield takeLatest(DisconnectCompany.REQUEST, disconnectCompany);
  yield takeLatest(GetSyncHistory.REQUEST, getSyncHistory);

}
