import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { show_profile, view_customer } from "../../actions/customers/customers";
import { headerTitles, SideMenu } from "../../actions/masterdata/masterdata";
import ViewDetails from "./ViewDetails";
import { useTranslation } from "react-i18next";
import { companyProfileImages } from "../../actions/companySettings";

const CustomerViewDetails = ({ isVendor, hasWriteAccess }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const location = useLocation();
  const [uploadProfile, setUploadProfile] = useState(false);
  const beneficiary = useSelector(
    (state) => state?.purchases?.beneficiary?.data?.data
  );
  const showResponse = useSelector(
    (state) => state?.customers?.show_profile?.data?.response
  );
  const profileResponse = useSelector(state => state?.companySetting?.companyProfileImages?.data?.data)

  const params = useParams();

  useEffect(() => {
    dispatch(view_customer({
      beneficiary_id: params.id
    }))
    // dispatch(show_profile({
    //   id: params?.id,
    //   record_type: "beneficiary"
    // }))
  }, [uploadProfile]);

  useEffect(() => {
    dispatch(companyProfileImages({record_id:params?.id}))
    dispatch(
      headerTitles({
        title: isVendor ? t('vendors') : t('customers'),
        description: isVendor ? 'add_vendor' : 'add_customer',
      })
    );
    if (location?.state?.setSideMenu) {
      let locationSplit = location?.pathname?.split("/");
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
        },
      });
    }
  }, []);

  return (
    <div>

      <ViewDetails callOnMount={() => {
        dispatch(view_customer({
          beneficiary_id: params.id
        }))
        // dispatch(show_profile({
        //   id: params?.id,
        //   record_type: "beneficiary"
        // }))
      }} hasWriteAccess={hasWriteAccess} beneficiary={beneficiary || {}} isVendor={isVendor} profilePic={profileResponse || []} setUploadProfile={setUploadProfile} updateOnSuccess={() => {
        dispatch(view_customer({
          beneficiary_id: params.id
        }))
        // dispatch(show_profile({
        //   id: params?.id,
        //   record_type: "beneficiary"
        // }))

      }} />
    </div>
  );
};

export default CustomerViewDetails;
