import React, { useLayoutEffect, useState } from "react";
import {
  Card,
  DatePicker,
  Select,
  Button,
  Space,
  Typography,
  Row,
  Col,
  Tag,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  get_master_account_txns,
  get_person_accounts,
} from "../../actions/z_accounts";

const { Option } = Select;
const { Title, Text } = Typography;

const CustomStatement = ({
  setSelectedAccount,
  account,
  setAccount,
  setAccountObj,
  setFilterDetails,
}) => {
  const [dates, setDates] = useState([null, null]);
  const dispatch = useDispatch();
  const [selectedTag, setSelectedTag] = useState("");
  const bAcc = useSelector((state) => state?.z_accs?.accounts?.data?.data);
  const bankAcc = useSelector(
    (state) =>
      state?.z_accs?.accounts?.data?.data?.responseData?.data?.values?.filter(
        (ele) => ele.accountTypeCode !== "VIRTUAL"
      ) || []
  );
  const handleDateChange = (dates) => {
    setDates(dates);
  };

  const handleQuickSelect = (range) => {
    setSelectedTag(range);
    const today = moment();
    switch (range) {
      case "today":
        setDates([today, today]);
        break;
      case "thisWeek":
        setDates([today.startOf("week"), today.clone().endOf("week")]);
        break;
      case "lastWeek":
        setDates([
          today.subtract(1, "week").startOf("week"),
          today.clone().endOf("week"),
        ]);
        break;
      case "thisMonth":
        setDates([today.startOf("month"), today.clone().endOf("month")]);
        break;
      case "lastMonth":
        setDates([
          today.subtract(1, "month").startOf("month"),
          today.clone().endOf("month"),
        ]);
        break;
      case "thisYear":
        setDates([today.startOf("year"), today.clone().endOf("year")]);
        break;
      case "lastYear":
        setDates([
          today.subtract(1, "year").startOf("year"),
          today.clone().endOf("year"),
        ]);
        break;
      default:
        break;
    }
  };

  const handleAccountChange = (value) => {
    setAccount(value);
    setAccountObj(bankAcc?.find((ele) => ele.accountId === value));
  };

  useLayoutEffect(() => {
    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
  }, []);

  useLayoutEffect(() => {
    if (bankAcc?.length) {
      setAccount(bankAcc?.[0]?.accountId);
      setAccountObj(bankAcc?.[0]);
    }
  }, [bAcc]);

  return (
    <Card className="select-statement">
      <div className="w60">
        <Title level={4}>Generate a custom statement</Title>
        <Text>
          Select dates to generate a statement. Dates must fall within the
          current month.
        </Text>
        <br />
        <br />
        <Row gutter={16}>
          <Col span={12}>
            <Text className="label">From</Text>
            <DatePicker
              value={dates[0]}
              onChange={(date) => handleDateChange([date, dates[1]])}
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
              placeholder="From Date"
            />
          </Col>
          <Col span={12}>
            <Text className="label">To</Text>
            <DatePicker
              value={dates[1]}
              onChange={(date) => handleDateChange([dates[0], date])}
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
              placeholder="End Date"
            />
          </Col>
        </Row>
        <br />
        <Text className="label">Account</Text>
        <Select
          value={account}
          onChange={handleAccountChange}
          style={{ width: "100%" }}
        >
          {bankAcc?.map((itm) => (
            <Option value={itm.accountId}>
              {itm?.accountName?.length > 25
                ? `${itm?.accountName?.substr(0, 25)}...`
                : itm?.accountName}{" "}
              - {itm?.accountNumbers?.[0]?.accountNumber?.value}
            </Option>
          ))}
        </Select>
        <br />
        <br />
        <div className="hr mb3" />
        <Space className="mt1">
          <Tag
            onClick={() => handleQuickSelect("today")}
            className={selectedTag === "today" ? "active" : ""}
          >
            Today
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("thisWeek")}
            className={selectedTag === "thisWeek" ? "active" : ""}
          >
            This week
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("lastWeek")}
            className={selectedTag === "lastWeek" ? "active" : ""}
          >
            Last week
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("thisMonth")}
            className={selectedTag === "thisMonth" ? "active" : ""}
          >
            This month
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("lastMonth")}
            className={selectedTag === "lastMonth" ? "active" : ""}
          >
            Last month
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("thisYear")}
            className={selectedTag === "thisYear" ? "active" : ""}
          >
            This year
          </Tag>
          <Tag
            onClick={() => handleQuickSelect("lastYear")}
            className={selectedTag === "lastYear" ? "active" : ""}
          >
            Last year
          </Tag>
        </Space>
        <br />
        <br />
        <Space className="mt2">
          <button
            className="primary-btn"
            onClick={() => {
              setSelectedAccount(true);
              setFilterDetails({
                account_id: account,
                // account_id: "ID-1165",
                date_from: dates[0].format("YYYY-MM-DD"),
                date_to: dates[1].format("YYYY-MM-DD"),
                date_type: "VALUE_DATE",
                page_number: 1,
                page_size: 50,
              });
              dispatch(
                get_master_account_txns({
                  account_id: account,
                  // account_id: "ID-1165",
                  date_from: dates[0].format("YYYY-MM-DD"),
                  date_to: dates[1].format("YYYY-MM-DD"),
                  date_type: "VALUE_DATE",
                  page_number: 1,
                  page_size: 50,
                })
              );
            }}
          >
            View
          </button>
          {/* <button className="secondary-btn">Cancel</button> */}
        </Space>
      </div>
    </Card>
  );
};

export default CustomStatement;
