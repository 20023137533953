import React from "react";
import no_data from "../../assets/icons/no_records.png";

const NodataComponent = ({ msg, module, noBtns, primaryText, noSecBtn, onPrimaryClick, onSecondaryClick, noPrimaryBtn, secondaryText, hasWriteAccess }) => {
  return (
    <div className="d-flex-center flex-col mt1">
      <img
        src={no_data}
        alt=""
        style={{
          width: 120,
        }}
      />
      <p className="text-center mt1 no-records">
        {msg ? msg : "No records found"}
      </p>
      {noBtns ? null :
        <div className="d-flex gap2">
          {!noPrimaryBtn && <button disabled={!hasWriteAccess} className="primary-btn" onClick={onPrimaryClick}>+ {primaryText || `Add New ${module}`}</button>}
          {!noSecBtn && <button disabled={!hasWriteAccess} className="secondary-btn" onClick={onSecondaryClick}>{secondaryText}</button>}
        </div>}
    </div>
  );
};

export default NodataComponent;
