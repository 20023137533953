import { Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import checkmark from "../../assets/icons/checkmark.svg";
import close from "../../assets/icons/close.png";
import trash from "../../assets/icons/ocr_trash.svg";
import { getSymbolCode } from '../../config/helper';
import { getSize } from '../../utils';
import { ocrDocumentDel } from '../../actions/ocr/ocr';

const OcrUpload = ({ loading, fileData, progress, reset, ocrModal, setOcrModal, onAdd }) => {
  const ocr_documents = useSelector((state) => state?.ocr?.docs?.data?.data || [])
  const ocr_delete = useSelector((state) => state?.ocr?.delete || [])
  const dispatch = useDispatch()
  return (
    <>
      {progress > 0 && <div className='ocr-upload'>
        <div className='flex-between title-sec'>
          <span>{loading ? "Uploading" : "Uploaded"} 1 receipt
            <span className='link' onClick={() => setOcrModal(true)}>View</span>
          </span>
          <img src={close} alt='Close Icon' className="cursor" width={12} onClick={() => {
            reset()
          }} />
        </div>
        <ul className='file-list'>
          {Array.from(fileData).map(ele => {
            return <li className={progress === 100 && !loading ? "done" : ""}>
              <p className='flex-between'>
                {ele.name} ({getSize(ele.size)})
                {loading ? null : <img src={checkmark} alt='CheckMark Image' width={12} />}
              </p>
              <div className='upload-status' style={{
                width: "100%",
                position: "relative"
              }}>
                <div className='upload-bg-status' style={{
                  width: progress + "%"
                }}></div>
              </div>
            </li>
          })}
        </ul>
      </div>}
      {ocrModal ?
        <Modal className='right-alinged-modal' title={<h3>OCR Documents</h3>} onCancel={() => setOcrModal(false)} visible={ocrModal}>
          <div className='orc-modal'>
            {/* <ul className='head-sec'>
            <li className='ocr-progress active'>
              Scan In Progress
              <span className='badge'>1</span>
            </li>
            <li className='failed'>
              Scan Failed
              <span className='badge'>10</span>
            </li>
          </ul> */}
            <div className='scans'>
              {ocr_documents?.map(ele => ele.status === "completed" &&
                <div className='item done' key={ele.id}>
                  <div className='img-sec'>
                    {Array.isArray(ele.extracted_data) && ele.extracted_data?.map(itm => {
                      return itm.label?.toLowerCase()?.includes("amount") || itm.label === "table" ? null : <div className='scan-item'>
                        <span>{itm.label?.replace(/_/g, " ")}</span>
                        <span>{itm.ocr_text}</span>
                      </div>
                    })}
                  </div>
                  <div className='flex-between hr'>
                    <div>
                      <button className='man-btn' onClick={() => {
                        onAdd(ele.extracted_data, ele.id)
                      }}>Add</button>
                      {/* <p className='dim'>Uploaded Date : 20 February 2023</p>
                    <p className='category'>Uncategorized</p> */}
                    </div>
                    <div>
                      <p>{Array.isArray(ele.extracted_data) && <>
                        <span dangerouslySetInnerHTML={{
                          __html: ele.extracted_data?.find(ele => ele.label === "currency")?.ocr_text ?? getSymbolCode()
                        }} style={{
                          marginRight: 3,
                          color: "#262626"
                        }}></span>
                        {ele.extracted_data?.find(ele => ele.label?.toLowerCase()?.includes("amount"))?.ocr_text}
                      </>}
                      </p>
                    </div>
                  </div>
                  <div className='flex-between'>
                    <p className='success'>Scan Completed</p>
                    <span className='d-flex'>
                      <img src={checkmark} width={12} alt='CheckMark Image' />
                      <span className='trash cursor ml1' onClick={() => {
                        if (!ocr_delete?.loading)
                          dispatch(ocrDocumentDel({
                            id: ele.id
                          }))
                      }}>
                        <img src={trash} alt="" width={12} />
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal> : null}
    </>
  );
};

export default OcrUpload;