import { Col, Menu, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { headerTitles, SideMenu } from "../../../actions/masterdata/masterdata";
import { paymentMade } from "../../../actions/purchases";
import { amountFormat } from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import TableComponent from "../../Common/TableComp/TableComp";
import TableHeader from "../../Sales/TableHeader";
import CreatePayment from "./CreatePayment";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";


const PendingPayments = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);

  const [filters, setFilters] = useState({
    filters: {
      payment_status: ["payment_pending", "partially_paid"],
      invoice_status: "approved",
    },
  });
  const [page, setPage] = useState(1);
  const pendingBillsResponse = useSelector(
    (state) => state.payments.payment_made || {}
  );

  const totalRecords = useSelector(
    (state) => state.payments.payment_made?.data?.total_count || {}
  );


  const columns = [
    {
      title: t('date'),
      dataIndex: "billing_date",
      key: "billing_date",
      render: (text) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
    },
    {
      title: t('vendor_name'),
      dataIndex: "vendor_name",
      key: "vendor_name",
    },
    {
      title: t('bill_no'),
      dataIndex: "billing_no",
      key: "billing_no",
    },
    {
      title: t('Amount'),
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text, record) => <div><BaseCurrency symbol_code={record?.currency_info?.iso_code} />{amountFormat(text, record?.currency_info?.decimal_scale)}</div>,
    },
    {
      title: t('amount_paid'),
      dataIndex: "amount_paid",
      key: "amount_paid",
      render: (text, rec) => (
        <div>
          {" "}
          <BaseCurrency symbol_code={rec?.currency_info?.iso_code} />
          {text ? amountFormat(text) : amountFormat(0)}
        </div>
      ),
    },
    {
      title: t('status'),
      dataIndex: "payment_status",
      key: "payment_status",
      render: (text) => (
        <div className={`status status_${text}`}>{text?.replace(/_/g, " ")}</div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      paymentMade({
        ...filters
      })
    );

    dispatch(
      headerTitles({
        title: 'payment_made',
        description: "",
        // 'bill_document',
      })
    );
    if (location?.state?.create) {
      setOpenAddNew(true)
    }

    if (location?.state?.setSideMenu) {
      let locationSplit = location?.pathname?.split("/")
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
          childrenMenu: "/pending"
        }
      })
    }

  }, []);

  const sortOptions = [
    {
      label: t('no_bill'),
      filter: "billing_no",
    },
    {
      label: t('vendor_name'),
      filter: "vendor_name",
    },
    // {
    //   label: t('Amount'),
    //   filter: "total_amount",
    // },
    {
      label: t('date'),
      filter: "billing_date",
    },
  ];

  const handleSelectRow = (event, record) => {
    history.push("/purchases/Viewpendingbills", {
      id: record?.billing_id,
      bill_id: record?.billing_id,
      from: location.pathname,
      amountPaid: record?.amount_paid,
      placeOfSupply: record?.place_of_supply,
      refNo: record?.reference_number,
      paymentMode: record?.payment_mode


    });
  };

  const handleSearch = (searchKey) => {
    dispatch(
      paymentMade({
        ...filters,
        search_key: searchKey,
      })
    );
    setFilters({
      ...filters,
      search_key: searchKey,
    });
  };



  const handleSort = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }
    if (value) {
      dispatch(
        paymentMade({
          ...filters,
          sort: {
            sort_key: value,
            sort_order: "1",
          },
          isFilter: true,
        })
      );
      setFilters({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
      });
    }
  };

  const callOnPageChange = useMemo(() => {
    return (pagee) => dispatch(
      paymentMade({
        ...filters,
        page_number: pagee
      })
    );
  }, [filters])

  console.log({ pendingBillsResponse });

  return (
    <>
      <CreatePayment
        isVisible={openAddNew}
        onClose={() => {
          setOpenAddNew(false);
        }}
        onSuccess={() => {
          dispatch(
            paymentMade({
              filters: {
                invoice_status: "approved",
                payment_status: ["payment_pending", "partially_paid"],
              }
            })
          );
        }}
      />

      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              noDD={true}
              noFilter={true}
              noAddNewBtn={true}
              setOpenAddNew={setOpenAddNew}
              sortOptions={sortOptions}
              onSearch={handleSearch}
              handleSort={handleSort}
            />

            <div
              className={pendingBillsResponse?.loading ? "shimmer" : ""}
              style={{ paddingTop: "22px" }}
            >
              <TableComponent
                columns={columns}
                loading={pendingBillsResponse?.loading}
                data={Array.isArray(pendingBillsResponse?.data?.data) ? pendingBillsResponse?.data?.data : []}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                hasCheckbox={false}
                handleRowClick={handleSelectRow}
                rowKey="id"
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    callOnPageChange(pagee)
                    setPage(pagee)
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PendingPayments;
