import { Button, Modal } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { get_seed_data } from "../../actions/masterdata/masterdata";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import { FormInput } from "../inputs";
import { useTranslation } from "react-i18next";

const AddPayTermsModal = ({ isVisible, onCancel, companyId, callBack }) => {
  const { onCall, loading } = usePayAxios({ api: apis.AddPayTerms, method: "post" });
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      company_id: companyId,
      term_name: "",
      number_of_days: "",
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload = {
      company_id: companyId,
      term_name: data?.term_name,
      number_of_days: Number(data?.number_of_days),
    };

    onCall({
      data: payload,
    }).then(data => {
      reset()
      dispatch(
        get_seed_data({
          company_id: localStorage.getItem("company_id"),
          seed_type: "payment_terms",
        })
      );
      onCancel()
      callBack && callBack()
    }).catch(err => console.log(err.message));
  };

  return (
    <>
      <Modal title={t('payment_add_terms')} visible={isVisible} onCancel={onCancel}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pp-form-item p0">
              <FormInput
                control={control}
                {...register("term_name", { required: t('term_list') })}
                errors={errors}
                label={t('term_name')}
                placeholder={t('enter_term_name')}
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("number_of_days", { required: "Number of days is required" })}
                errors={errors}
                label={t('number_of_days')}
                placeholder={t('enter_number_days')}
              />
            </div>
            <div className="d-flex gap1 mt2">
              <button
                disabled={loading} type="submit" className="pp-main-button" style={{
                  color: "#fff"
                }}>
                {t('save')}
              </button>
              <Button className="pp-secondary-button" onClick={onCancel}>
                {t('cancel')}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddPayTermsModal;
