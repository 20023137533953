import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import InventoryFormFields from "./InventoryFormFields";
import { useLocation, useHistory } from "react-router-dom";
import { Button, message } from "antd";
import { useTranslation } from "react-i18next";
import "./inventory.css";
import { vendorsByName } from "../../actions/purchases";
import { useDispatch, useSelector } from "react-redux";
import { customers } from "../../actions/customers";
import {
  InventoryItemsCreate,
  inventoryItemsCreate,
} from "../../actions/inventory";
import useDocumentAxios from "../../config/useDocument";
import { apis } from "../../config/APIs";
import { getMerchantsField } from "../../actions/companySettings";
import usePayAxios from "../../config/useAxios";
const InventoryForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const vendorsByNameData = useSelector(
    (state) => state.purchases.vendorsByName || {}
  );
  const accountResponse = useSelector(
    (state) => state.customers?.all?.data?.data || []
  );
  const inventoryCreateResponse = useSelector(
    (state) => state.inventory.items?.data
  );
  const warehouseData = useSelector(
    (state) => state.companySetting.getMerchantsField || {}
  );
  const { onCall: unitType, data: unitTypeData } = useDocumentAxios({
    api: apis?.unitTypeGETAPI,
    method: "post",
    baseURL: "settings",
  });
  const { onCall: getSalesAccounts, data: sales } = usePayAxios({
    api: apis.getAPARAccounts,
    method: "get",
  });
  const { onCall: getPRAccounts, data: pr } = usePayAxios({
    api: apis.getAPARAccounts,
    method: "get",
  });
  const { onCall: taxType, data: taxTypeData } = useDocumentAxios({
    api: apis?.companyTaxTypes,
    method: "get",
    baseURL: "settings",
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, submitCount },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {},
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    data.information[0].type = "SALES";
    data.information[1].type = "PURCHASE";

    data?.tax_rates?.forEach((element) => {
      element.tax_type = element?.tax_name;
    });
    data.id = location?.state?.record?.id;
    data.track_inventory = data.track_inventory?.[0];
    dispatch(inventoryItemsCreate(data));
  };
  useEffect(() => {
    dispatch(vendorsByName({ type: "vendor", status: "active" }));
    dispatch(customers({ type: "individual" }));
    dispatch(getMerchantsField({ type: "warehouse" }));
    unitType({
      params: {
        isCsv: true,
        is_enable: true,
      },
    });
    getSalesAccounts({
      params: {
        type: "SALES",
      },
    });
    getPRAccounts({
      params: {
        type: "PURCHASE",
      },
    });
    taxType({});
  }, []);
  useEffect(() => {
    if (inventoryCreateResponse?.error === false) {
      message.success(
        <span className="messageText">{inventoryCreateResponse?.message}</span>
      );
      dispatch({ type: InventoryItemsCreate?.RESET });
      history.push("/inventory/items");
    } else if (inventoryCreateResponse?.error === true) {
      message.error(
        <span className="messageText">{inventoryCreateResponse?.message}</span>
      );
      dispatch({ type: InventoryItemsCreate?.RESET });
    }
  }, [inventoryCreateResponse]);

  useEffect(() => {
    if (location?.state?.record?.id) {
      const fieldValues = location?.state?.record;
      console.log({ fieldValues });
      setValue("item_type", fieldValues?.item_type);
      setValue("item_name", fieldValues?.item_name);
      setValue("sku", fieldValues?.sku);
      setValue("unit_type", fieldValues?.unit_type);
      setValue("hsn_code", fieldValues?.hsn_code);
      setValue("tax_preference", fieldValues?.tax_preference);
      setValue("item_track_inventory", fieldValues?.track_inventory);
      setValue(
        `information.${[0]}.currency`,
        fieldValues?.taxSalePurchaseInfo?.[0]?.currency_info?.iso_code
      );
      setValue(
        `information.${[0]}.selling_price`,
        fieldValues?.taxSalePurchaseInfo?.[0]?.price
      );
      setValue(
        `information.${[0]}.account_id`,
        fieldValues?.taxSalePurchaseInfo?.[0]?.account_id
      );
      setValue(
        `information.${[0]}.description`,
        fieldValues?.taxSalePurchaseInfo?.[0]?.description
      );
      setValue(
        `information.${[1]}.currency`,
        fieldValues?.taxSalePurchaseInfo?.[1]?.currency_info?.iso_code
      );
      setValue(
        `information.${[1]}.selling_price`,
        fieldValues?.taxSalePurchaseInfo?.[1]?.price
      );
      setValue(
        `information.${[1]}.account_id`,
        fieldValues?.taxSalePurchaseInfo?.[1]?.account_id
      );
      setValue(
        `information.${[1]}.description`,
        fieldValues?.taxSalePurchaseInfo?.[1]?.description
      );
      setValue(
        `tax_rates.${[0]}.company_tax_type_id`,
        fieldValues?.taxRates?.[0]?.company_tax_type_id
      );
      setValue(
        `tax_rates.${[1]}.company_tax_type_id`,
        fieldValues?.taxRates?.[1]?.company_tax_type_id
      );
      setValue("track_inventory", [{ ...fieldValues?.taxInventory }]);
    }
  }, [location?.state?.record]);
  return (
    <div>
      <form className="new-bill-form" onSubmit={handleSubmit(onSubmit)}>
        <InventoryFormFields
          control={control}
          register={register}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          errors={errors}
          vendorsByNameData={vendorsByNameData}
          accountResponse={accountResponse}
          fieldValues={location?.state?.record}
          warehouseData={warehouseData}
          unitTypeData={unitTypeData}
          taxTypeData={taxTypeData}
          saleAccs={sales?.data}
          prAccs={pr?.data}
        />

        <div className="bottom">
          <Button
            className="pp-secondary-button"
            style={{ marginLeft: "2rem" }}
            htmlType="button"
            onClick={() => {
              history.push("/inventory/items");
            }}
          >
            <span>{t("cancel")}</span>
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="pp-main-button"
            id="save"
          >
            {/* <span>{location?.state?.isEdit ? t("update") : t("save")}</span> */}
            <span>{t("save")}</span>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InventoryForm;
