import { pdf } from "@react-pdf/renderer";
import { Badge, Divider } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from 'json-2-csv';
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  analyticsEstimates
} from "../../actions/cards/cards";
import { apis } from "../../config/APIs";
import endpointAnalyticsApi from "../../config/AxiosAnalyticsApi";
import { amountFormat, convertString } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import MISExpenses from "../Common/PDF/MISPDF/MISExpenses";
import BarGraph from "./BarGraph";
import ReportTable from "./ReportTable";


const _ = require("lodash");


const SalesOrders = ({ showGraph, apiFilters, filterObj, setFilterObj, pdfURL, csvData, ...props }) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.cards?.analytics_estimates);
  const pdfData = useSelector((state) => state.analytics.analytics_cards_all?.data?.data);

  const { t } = useTranslation()
  useEffect(() => {
    dispatch(analyticsEstimates({ module_name: "SALES_ORDER", type: "analytics_invoices" }))
    // dispatch(analytics_cards_all({ params:{ module_type:"SALES_ORDER", isCsv:true}, type:"sales" }));
    setFilterObj({});
  }, []);

  ///// Dowload pdf code ///
  useEffect(() => {
    pdfURL(downloadPdf)
  }, [pdfURL])

  const columnsKeys = [
    { label: "Customer Name", key: "customer_name", type: "string" },
    { label: "Sales Order No", key: "invoice_no", type: "string" },
    { label: "Sales Order Date", key: "invoice_date", type: "date" },
    { label: "Reference No", key: "ref_po_no", type: "string" },
    { label: "Payment Terms", key: "payment_terms", type: "string" },
    { label: "Expected Shipment Date", key: "po_due_date", type: "date" },
    { label: "Tax Amount", key: "bc_total_tax_amount", type: "amount" },
    { label: "Discount", key: "bc_total_discount", type: "amount" },
    { label: "Total Amount", key: "bc_total_amount", type: "amount" },
    { label: "Status", key: "invoice_status", type: "string" },
  ];


  const downloadPdf = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.getAnalyticsInvoices + '?module_type=SALES_ORDER&isCsv=true', { filters: filters });
    if (response) {
      const blob = await pdf((
        <MISExpenses data={response?.data?.data} columnsKeys={columnsKeys} />
      )).toBlob();
      saveAs(blob, "MISSales_Order.pdf");
    }
  }

  ///// Dowloading CSV file method ////////
  const downloadCsv = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.getAnalyticsInvoices + '?module_type=SALES_ORDER&isCsv=true', { filters: filters });
    if (response) {
      const prod = response?.data?.data?.map(ele => ({
        "Customer Name": ele.customer_name,
        "Sales Order No": ele.invoice_no,
        "Sales Order Date": moment(ele.invoice_date).format("DD MMM, YYYY"),
        "Reference No": ele.ref_po_no,
        "Payment Terms": ele.payment_terms,
        "Expected Shipment Date": moment(ele.po_due_date).format("DD MMM, YYYY"),
        "Tax Amount": ele.bc_total_tax_amount,
        "Discount": ele.bc_total_discount,
        "Total Amount": ele.bc_total_amount,
        "Status ": ele.invoice_status,
      }))

      json2csv(prod).then((csv) => {
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, 'Sales Order.csv');
      });
    }
  }

  useEffect(() => {
    csvData(downloadCsv)
  }, [csvData])



  const columns = useMemo(() => [
    {
      title: t('customer_name'),
      dataIndex: "customer_name",
      key: "vendor_name",
      render: (text, record) => (
        <>
          {record?.integration_type &&
            <div className="base_badge_sales">
              <Badge.Ribbon text={convertString(record?.integration_type)} placement={"start"} color="#3f81f4" />
            </div>
          }
          {text}
        </>
      ),
    },
    {
      title: t('sales_order_no'),
      dataIndex: "invoice_no",
      key: "invoice_no",
    },
    {
      title: t('sales_order_date'),
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (record) => record ? moment(record).format("DD MMM, YYYY") : "-"

    },
    {
      title: t('reference_no'),
      dataIndex: "ref_po_no",
      key: "ref_po_no",
    },
    {
      title: t('payment_term'),
      dataIndex: "payment_terms",
      key: "payment_terms",
      render: (text) => text ?? '-'
    },
    {
      title: t('expected_shipment_date'),
      dataIndex: "po_due_date",
      key: "po_due_date",
      render: (record) => record ? moment(record).format("DD MMM, YYYY") : "-"

    },
    {
      title: t('tax_amount'),
      dataIndex: "bc_total_tax_amount",
      key: "bc_total_tax_amount",
      render: (text) => text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')
    },
    {
      title: t('discount'),
      dataIndex: "bc_total_discount",
      key: "bc_total_discount",
      render: (text) => text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')
    },
    {
      title: t('total_amount'),
      dataIndex: "bc_total_amount",
      key: "bc_total_amount",
      render: (text) => text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')
    },
    {
      title: t('status'),
      dataIndex: "invoice_status",
      key: "invoice_status",
      render: (text) => <div className={`status status_${text}`}>{text}</div>,
    },
  ], [cards]);

  cards?.data?.response?.invoice_data?.forEach(({ custom_fields }) => {
    custom_fields.forEach(ele => {
      columns.push({
        title: ele?.display_name,
        dataIndex: "custom_fields",
        key: ele?.field_id,
        render: (text) => text.map((service) => {
          if (service?.field_id === ele?.field_id) {
            return service?.selected?.value
          }
        }),

      })
    })
  })
  let tableColumns = _.uniqBy(columns, "title")
  return (
    <>
      <div className="reports">
        {showGraph && (
          <>
            <Divider />
            <div
              className="mt1"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BarGraph
                width={1000}
                height={350}
                id={"lineAreaGraph"}
                data={[
                  { day: "Jan", txnValue: 39 },
                  { day: "Feb", txnValue: 49 },
                  { day: "Mar", txnValue: 69 },
                  { day: "Apr", txnValue: 59 },
                ]}
                barColor={"#151424"}
              />
            </div>
          </>
        )}
        <Divider />
      </div>
      <ReportTable
        columns={tableColumns}
        data={Array.isArray(cards?.data?.data) ? cards?.data?.data : []}
        total_records={cards?.data?.total_count}
        scroll={{ x: 2000 }}
        paginationCall={(vals) => {
          vals.type = "analytics_invoices";
          vals.module_name = "SALES_ORDER";
          dispatch(analyticsEstimates(vals, { type: "analytics_invoices" }))
        }}
        filterObj={filterObj}
        payload={{ ...filterObj, ...apiFilters }}
      />
    </>
  );
};

export default SalesOrders;
