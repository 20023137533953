import { Select, Steps, Checkbox } from "antd";
import React from "react";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const { Step } = Steps;
const { Option } = Select;

const HirarchicalApproval = ({
  selectedRoles,
  approvalData,
  setApprovalData,
  handleList,
  editMode,
  setSelectedRoles,
  rolesData,
  hirarchicalApprovalCheckBoxTitle,
  hasWriteAccess,
}) => {
  const { t } = useTranslation();
  const handleInputs = (val, key, level, value) => {
    let idx = approvalData.findIndex((obj) => obj.level === level);
    approvalData[idx] = {
      can_approve_or_reject: false,
      level: level,
      can_reimburse: false,
      role_id: value?.id,
      role_name: value?.role_name,
      is_active: true,
    };
    setApprovalData([...approvalData]);
    if (key === "role_id") {
      setSelectedRoles([...selectedRoles, val]);
    }
  };

  // Extract role IDs from approvers data
  const selectedRoleIds = approvalData
    ?.filter((ele) => ele.is_active !== false)
    ?.map((approver) => approver.role_id);

  // Filter roles based on non-selected approvers
  const nonSelectedRoles = rolesData?.filter(
    (role) => !selectedRoleIds?.includes(role.id) && role.role_name !== "Admin"
  );

  const stepRender = (item) => (
    <>
      {item.level === 0 || item.level === 1 || item.role_name ? (
        <div className="disabledDiv">{item.role_name}</div>
      ) : (
        <Select
          disabled={!editMode || !hasWriteAccess}
          placeholder={t("select_role")}
          className="select-role"
          onChange={(val) => {
            handleInputs(
              val,
              "role_id",
              item.level,
              rolesData?.find((ele) => ele.id === val)
            );
          }}
          value={item.role_id}
        >
          {nonSelectedRoles?.map(({ id, role_name }) => (
            <Option key={id} value={id}>
              {role_name}
            </Option>
          ))}
        </Select>
      )}

      {item.level !== 0 ? (
        <div className="approvalsCheckbox">
          {/* <Checkbox
                  defaultChecked={item.can_reimburse}
                  onChange={(e) => handleInputs(e.target.checked, 'can_reimburse', item.level)}
                >Can Reimburse</Checkbox> */}
          <Checkbox
            disabled={!editMode || !hasWriteAccess}
            defaultChecked={item.can_approve_or_reject}
            onChange={(e) =>
              handleInputs(
                e.target.checked,
                "can_approve_or_reject",
                item.level,
                rolesData?.find((ele) => ele.id === item.role_id)
              )
            }
          >
            {t("can_perform")} {hirarchicalApprovalCheckBoxTitle}
          </Checkbox>
        </div>
      ) : null}

      {item.level !== 0 && item.level !== 1 && editMode ? (
        <MinusCircleOutlined
          className="minusButton inlineItem mtHalf cursor ml2"
          onClick={() => handleList("remove", item?.level)}
        />
      ) : null}
    </>
  );

  return (
    <Steps
      direction="vertical"
      size="small"
      current={approvalData.length + 1 || 100}
    >
      {approvalData &&
        approvalData
          .filter((item) => item.is_active !== false)
          .map((item, index) => (
            <Step
              key={item.id}
              title={stepRender(item)}
              icon={<div className="stepsNumber">{index + 1}</div>}
            />
          ))}
    </Steps>
  );
};

export default HirarchicalApproval;
