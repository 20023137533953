import { ReducerFunctions } from "../../utils";
import {
  GetMasterAccountTxns,
  GetPayments,
  GetPersonAccounts,
  GetPurposes,
  InitializePayment,
} from "./accounts";

export const person_accounts_reducer = ReducerFunctions(GetPersonAccounts, {});
export const get_purposes_reducer = ReducerFunctions(GetPurposes, {});
export const all_payments_reducer = ReducerFunctions(GetPayments, {});
export const initialize_payment_reducer = ReducerFunctions(
  InitializePayment,
  {}
);
export const master_account_txns_reducer = ReducerFunctions(
  GetMasterAccountTxns,
  {}
);
