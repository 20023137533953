import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AnalyticsNav = ({ activeNav, setActiveNav }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const storedMenu = localStorage.getItem("activeNav");
    setActiveNav(storedMenu ? storedMenu : "expenses");
  }, []);

  useEffect(() => {
    localStorage.setItem("activeNav", activeNav);
  }, [activeNav]);

  const handleNavItemClick = (nav, path, subNav) => {
    setActiveNav(nav);
    history.push(path, { subNav });
  };

  const renderNavItems = () => {
    switch (activeNav) {
      case "expenses":
        return (
          <div className="analytics-nav expenses">
            <ul>
              <li
                className={location.pathname === "/analytics/expenses" ? "active" : ""}
                onClick={() => handleNavItemClick("expenses", "/analytics/expenses", "Expenses")}
              >
                {t('Expenses')}
              </li>
              <li
                className={location.pathname === "/analytics/reports" ? "active" : ""}
                onClick={() => handleNavItemClick("expenses", "/analytics/reports", "Expense Reports")}
              >
                {t('expense_reports')}
              </li>
              <li
                className={location.pathname === "/analytics/trips" ? "active" : ""}
                onClick={() => handleNavItemClick("expenses", "/analytics/trips", "Trips")}
              >
                {t('trips')}
              </li>
              <li
                className={location.pathname === "/analytics/cards" ? "active" : ""}
                onClick={() => handleNavItemClick("expenses", "/analytics/cards", "Card Transactions")}
              >
                {t('card_transactions')}
              </li>
              <li
                className={location.pathname === "/analytics/advances" ? "active" : ""}
                onClick={() => handleNavItemClick("expenses", "/analytics/advances", "Advances")}
              >
                {t('advances')}
              </li>
            </ul>
          </div>
        );

      case "sales":
        return (
          <div className="analytics-nav sales">
            <ul>
              <li
                className={location.pathname === "/analytics/estimates" ? "active" : ""}
                onClick={() => handleNavItemClick("sales", "/analytics/estimates", "Estimates")}
              >
                {t('estimate')}
              </li>
              <li
                className={location.pathname === "/analytics/salesorders" ? "active" : ""}
                onClick={() => handleNavItemClick("sales", "/analytics/salesorders", "Sales Order")}
              >
                {t('sale_order')}
              </li>
              <li
                className={location.pathname === "/analytics/invoices" ? "active" : ""}
                onClick={() => handleNavItemClick("sales", "/analytics/invoices", "Invoices")}
              >
                {t('invoices')}
              </li>
              <li
                className={location.pathname === "/analytics/paymentsreceived" ? "active" : ""}
                onClick={() => handleNavItemClick("sales", "/analytics/paymentsreceived", "Payment Received")}
              >
                {t('received_payment')}
              </li>
              <li
                className={location.pathname === "/analytics/invoice_aging" ? "active" : ""}
                onClick={() => handleNavItemClick("sales", "/analytics/invoice_aging", "Invoice Aging")}
              >
                {t('aging_invoice')}
              </li>
            </ul>
          </div>
        );

      case "purchases":
        return (
          <div className="analytics-nav purchases">
            <ul>
              <li
                className={location.pathname === "/analytics/purchasesorders" ? "active" : ""}
                onClick={() => handleNavItemClick("purchases", "/analytics/purchasesorders", "Purchase Orders")}
              >
                {t('purchases_orders')}
              </li>
              <li
                className={location.pathname === "/analytics/bills" ? "active" : ""}
                onClick={() => handleNavItemClick("purchases", "/analytics/bills", "Bills")}
              >
                {t('bills')}
              </li>
              <li
                className={location.pathname === "/analytics/paymentmade" ? "active" : ""}
                onClick={() => handleNavItemClick("purchases", "/analytics/paymentmade", "Payments Made")}
              >
                {t('made_payments')}
              </li>
              <li
                className={location.pathname === "/analytics/bills_aging" ? "active" : ""}
                onClick={() => handleNavItemClick("purchases", "/analytics/bills_aging", "Bills Aging")}
              >
                {t('bills_aging')}
              </li>
            </ul>
          </div>
        );


      default:
        return null;
    }
  };

  return (
    <>
      <div className="analytics-nav header">
        <ul style={{ marginBottom: "0rem" }}>
          <li
            className={activeNav === "expenses" ? "active" : ""}
            onClick={() => handleNavItemClick("expenses", "/analytics/expenses", "Expenses")}
          >
            {t('Expenses')}
          </li>
          <li
            className={activeNav === "sales" ? "active" : ""}
            onClick={() => handleNavItemClick("sales", "/analytics/estimates", "Estimates")}
          >
            {t('Sales')}
          </li>
          <li
            className={activeNav === "purchases" ? "active" : ""}
            onClick={() => handleNavItemClick("purchases", "/analytics/purchasesorders", "Purchases")}
          >
            {t('Purchases')}
          </li>
        </ul>
      </div>
      {renderNavItems()}
    </>
  );
};

export default AnalyticsNav;
