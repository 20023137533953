import { Col, Row, Select, Space } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { advances as advancesCall } from "../../actions/advances";
import { fetchAllBillingDetails } from "../../actions/bills";
import { invoices } from "../../actions/customers/customers";
import {
  dashboard,
  invoice_dashboard,
  pay_dashboard,
} from "../../actions/dashboard/dashboard";
import { reports as reportsCall } from "../../actions/reports";
import { trips as tripsCall } from "../../actions/trips/trips";
import {
  Approved,
  Pending_Approval,
  Pending_Reimbursement,
  Recovered,
  Total,
  Unreported_Expenses,
} from "../../assets/icons/Dashboard";
import { getSymbolCode } from "../../config/helper";
import { amountFormat, hasAccess } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import Verification from "../OnBoarding/CommonScreens/Verification";
import BankAccount from "./BankAccountSec";
import CardDashboard from "./CardDashboard";
import DashBoardFoorter from "./DashBoardFoorter";
import TxnTable from "./TxnTable";
import AccTxnTable from "./AccTxnTable";
var Highcharts = require("highcharts");

const CompanyDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reportsResponse = useSelector((state) => state.reports.reports || {});
  const pay = useSelector((state) => state.dashboard?.pay?.data?.data || {});
  const inv = useSelector((state) => state.dashboard?.inv?.data?.data || {});
  const billsCount = useSelector(
    (state) => state.bills.fetchAllBillingDetails?.data?.total_count || 0
  );
  const invCount = useSelector(
    (state) => state.customers.invoices?.data?.total_count || 0
  );
  const dashboardRes = useSelector(
    (state) => state?.dashboard?.dashboard?.first?.data || {}
  );
  const dashboardThird = useSelector(
    (state) => state?.dashboard?.dashboard?.["3"]?.data || {}
  );
  const dashboardFourth = useSelector(
    (state) => state?.dashboard?.dashboard?.["4"]?.data || {}
  );
  const customers_res = useSelector((state) =>
    Array.isArray(state.customers?.all?.data?.data)
      ? state.customers?.all?.data?.data?.map((ele) => ({
          ...ele,
          label: ele.beneficiary_name,
          value: ele.id,
        }))
      : []
  );
  const vendors_res = useSelector((state) =>
    Array.isArray(state.purchases?.vendorsByName?.data?.data)
      ? state.purchases.vendorsByName?.data?.data?.map((ele) => ({
          ...ele,
          label: ele.beneficiary_name,
          value: ele.id,
        }))
      : []
  );
  const corporate_budget = useSelector(
    (state) => state.cards.corporate_budget?.data?.response
  );
  const [key, setKey] = useState("report");
  const [donut_key, setDonutKey] = useState("spend_by_cost_center");
  const { data: { total_count: reportTotal = 0 } = {} } = reportsResponse;
  const tripsResp = useSelector((state) => state.trips.trips || {});
  const { data: { total_count: tripsTotal = 0 } = {} } = tripsResp;
  const advancesResp = useSelector((state) => state.advances.advances || {});
  const { data: { total_count: advancesTotal = 0 } = {} } = advancesResp;
  const [tab, setTab] = useState(1);

  const [dashboardData, setDashboardData] = useState({
    report: [
      {
        label: t("approval_pending"),
        value: "",
      },
      {
        label: t("unreported_expense"),
        value: "",
      },
      {
        label: t("pending_reimbursement"),
        value: "",
      },
    ],
    advance: [
      {
        label: t("approval_pending"),
        value: "",
      },
      {
        label: t("pending_recovery"),
        value: "",
      },
      {
        label: t("approved"),
        value: "",
      },
      {
        label: t("recovered"),
        value: "",
      },
    ],
  });

  const tableFilters = [
    {
      key: 1,
      label: `${t("reports")} (${reportTotal})`,
    },
    {
      key: 2,
      label: `${t("bills")} (${billsCount})`,
    },
    {
      key: 3,
      label: `${t("invoices")} (${invCount})`,
    },
    {
      key: 4,
      label: `${t("trips")} (${tripsTotal})`,
    },
    {
      key: 5,
      label: `${t("advances")} (${advancesTotal})`,
    },
  ];
  const payapi = localStorage.getItem("tokenPayApi");
  useEffect(() => {
    if (payapi) {
      dispatch(pay_dashboard({ module_type: "BILL" }));
      dispatch(invoice_dashboard());
    }
    dispatch(dashboard());
    dispatch(
      dashboard({
        dashboard: "3",
      })
    );
    dispatch(
      dashboard({
        dashboard: "4",
      })
    );
  }, [payapi]);

  useEffect(() => {
    if (dashboardThird?.spend_by_invoices_bills)
      Highcharts.chart("container", {
        colors: ["#1c83fc", "#ed3b41"],
        chart: {
          type: "column",
        },
        title: {
          text: " ",
        },
        xAxis: {
          categories: [
            ...Object.keys(
              dashboardThird?.spend_by_invoices_bills?.invoice
            ).map((key) => moment(key).format("MMM")),
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.series.name +
              `</b><br/>` +
              `${getSymbolCode()}` +
              amountFormat(this.point.y)
            );
          },
        },
        series: [
          {
            name: "Invoice",
            data: Object.values(
              dashboardThird?.spend_by_invoices_bills?.invoice
            ),
          },
          {
            name: "Bills",
            data: Object.values(
              dashboardThird?.spend_by_invoices_bills?.vendor_bill
            ),
          },
        ],
      });
  }, [dashboardThird]);

  const optionDonut = useMemo(
    () => ({
      colors: ["#1c83fc", "#2ea700", "#ed3b41", "#4a4a4a", "#272727"],
      title: {
        text: " ",
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      tooltip: {
        formatter: function () {
          return (
            "<b>" +
            this.point.name +
            `</b><br/>` +
            `${getSymbolCode()}` +
            amountFormat(this.point.y)
          );
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          startAngle: 0,
          endAngle: 360,
          center: ["50%", "55%"],
          size: "100%",
          showInLegend: true,
        },
      },
      series: [
        {
          type: "pie",
          name: donut_key,
          innerSize: "60%",
          data: dashboardFourth?.[donut_key]
            ? Object.keys(dashboardFourth?.[donut_key]).map((ele) => [
                ele,
                dashboardFourth?.[donut_key]?.[ele],
              ])
            : [],
        },
      ],
    }),
    [donut_key, dashboardFourth]
  );

  useEffect(() => {
    if (dashboardFourth?.[donut_key])
      Highcharts.chart("container1", optionDonut);
  }, [optionDonut]);
  // const optionLine = useMemo(() => ({
  //   tooltip: {
  //     trigger: 'item'
  //   },
  //   xAxis: {
  //     type: 'category',
  //     boundaryGap: false,
  //     data: Object.keys(dashboardThird?.spend_by_invoice_bills?.invoice)?.map(ele => moment(ele).format("MMM"))
  //   },
  //   yAxis: {
  //     type: 'value',
  //   },
  //   series: [
  //     {
  //       data: Object.values(dashboardThird?.spend_by_invoice_bills?.invoice),
  //       type: 'bar',
  //       areaStyle: {}
  //     },
  //     {
  //       data: Object.values(dashboardThird?.spend_by_invoice_bills?.vendor_bill),
  //       type: 'bar',
  //       areaStyle: {}
  //     }
  //   ]
  // }), [dashboardThird]);
  useEffect(() => {
    if (Object.keys(dashboardRes).length > 0) {
      setDashboardData({
        report: [
          {
            label: t("total"),
            value: dashboardRes?.total_report_amount[0]?.total_amount,
            moduleIcon: <img src={Total} alt="Total Icon" />,
          },
          {
            label: t("approval_pending"),
            value: dashboardRes?.total_pending_approved_amount[0]?.total_amount,
            moduleIcon: (
              <img src={Pending_Approval} alt="Pending Approval Icon" />
            ),
          },
          {
            label: t("Reimbursed"),
            value: dashboardRes?.total_reimbursed_amount?.[0]?.total_amount,
            moduleIcon: <img src={Unreported_Expenses} alt="Reimbursed Icon" />,
          },
          {
            label: t("pending_reimbursement"),
            value:
              dashboardRes?.total_pending_reimbursed_amount?.[0]?.total_amount,
            moduleIcon: (
              <img
                src={Pending_Reimbursement}
                alt="Pending Reimbursement Icon"
              />
            ),
          },
        ],
        advance: [
          {
            label: t("total"),
            value: dashboardRes?.total_advance_amount?.[0]?.total_amount,
            moduleIcon: <img src={Total} alt="Total Icon" />,
          },
          {
            label: t("approval_pending"),
            value:
              dashboardRes?.total_advance_pending_approved_amount?.[0]
                ?.total_amount,
            moduleIcon: (
              <img src={Pending_Approval} alt="Pending Approval Icon" />
            ),
          },
          {
            label: t("pending_recovery"),
            value:
              dashboardRes?.total_advance_pending_recovered_amount?.[0]
                ?.total_amount,
            moduleIcon: (
              <img src={Pending_Approval} alt="Pending Approval Icon" />
            ),
          },
          {
            label: t("approved"),
            value:
              dashboardRes?.total_advance_approved_amount?.[0]?.total_amount,
            moduleIcon: <img src={Approved} alt="Approved Icon" />,
          },
          {
            label: t("recovered"),
            value:
              dashboardRes?.total_advance_recovered_amount?.[0]?.total_amount,
            moduleIcon: <img src={Recovered} alt="Recovered Icon" />,
          },
        ],
      });
    }
  }, [dashboardRes]);

  useEffect(() => {
    dispatch(
      fetchAllBillingDetails({
        view: "admin",
        filters: { invoice_status: ["pending_approval"] },
        module_type: "BILL",
      })
    );
    dispatch(
      tripsCall({
        view: "admin",
        module_type: "TRIP",
        // status: "pending_approval",
        filters: { status: ["pending_approval"] },
      })
    );
    dispatch(
      reportsCall({
        view: "admin",
        filters: { status: ["pending_approval"] },
        module_type: "REPORT",
      })
    );
    dispatch(
      invoices({
        view: "admin",
        filters: { invoice_status: ["pending_approval"] },
        type: "INVOICE",
      })
    );
    dispatch(
      advancesCall({
        view: "admin",
        // status: "pending_approval",
        module_type: "ADVANCE",
        filters: { status: ["pending_approval"] },
      })
    );
  }, []);
  const is_kyc_completed = localStorage.getItem("is_kyb_completed")
    ? JSON.parse(localStorage.getItem("is_kyb_completed"))
    : true;
  return (
    <div className="dashboard mb3">
      {!is_kyc_completed && <Verification />}

      <BankAccount />

      <Row style={{ marginTop: "20px" }}>
        <Col >
          <CardDashboard
            className={"recent-txn"}
            title={t("recent_transactions")}
            style={{ minHeight: "340px", padding: 0 }}
            tooltipText={t("recent_transactions")}
          >
            <AccTxnTable dash={true} account_number={"500000009"} />
          </CardDashboard>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col style={{ width: "63%" }}>
          <CardDashboard title={t("cashFlow")} tooltipText={""}>
            {dashboardThird?.spend_by_invoices_bills && (
              <div className="chartContainer mt1">
                <div
                  id="container"
                  className="dashChart"
                  style={{ width: "100%", height: "288px" }}
                ></div>
              </div>
            )}
          </CardDashboard>
        </Col>

        <Col style={{ width: "37%" }}>
          <CardDashboard
            title={
              <div className="title-summary">
                <p>{t("top_expenses")}</p>
                <Select
                  value={donut_key}
                  onChange={(val) => {
                    setDonutKey(val);
                  }}
                  options={[
                    { label: t("cost_center"), value: "spend_by_cost_center" },
                    { label: t("project"), value: "spend_by_project" },
                  ]}
                />
              </div>
            }
            className="summary-wrap"
          >
            {
              <div className="chartContainer mt1">
                <div
                  id="container1"
                  className="dashChart"
                  style={{ width: "100%", height: "288px" }}
                ></div>
              </div>
            }
          </CardDashboard>
        </Col>
      </Row>

      <DashBoardFoorter />
    </div>
  );
};

export default CompanyDashboard;
