import { ReducerFunctions } from "../../utils";
import {
  Reports,
  ReportsReject,
  ReportsApprove,
  ReportsExpDetails,
  ReportsExpComments,
  ReportsExpAddComments,
  ReportsComments,
  ReportsCommentsAdd,
  ReportsExportExcel,
  TransactionsReject,
  TransactionsPartialApprove,
  ReportsHistory,
  ReportsAdvance,
  ReportsTrips,
  ReportsExpHistory,
  ReportsReimburse,
  UserReports,
  UserReportSubmit,
  UserReportRecall,
  AddUserReports,
  UserReportFields,
  UserReportLinkExp,
  UserReportLinkAdv,
  ReportsBulkApproveReject,
  ReportDetailsPDF,
  ReportsCount,
  UserReport,
  ForwardApproval,
  ChangeReports,
} from "./reports";

export const reports_reducer = ReducerFunctions(Reports, {});
export const user_report_reducer = ReducerFunctions(UserReport, {});
export const reports_count_reducer = ReducerFunctions(ReportsCount, {});
export const reportsReject_reducer = ReducerFunctions(ReportsReject, {});
export const reportsBulkApproveReject_reducer = ReducerFunctions(
  ReportsBulkApproveReject,
  {}
);
export const transactionsReject_reducer = ReducerFunctions(
  TransactionsReject,
  {}
);
export const transactionsPartialApprove_reducer = ReducerFunctions(
  TransactionsPartialApprove,
  {}
);
export const reportsApprove_reducer = ReducerFunctions(ReportsApprove, {});
export const reportsReimburse_reducer = ReducerFunctions(ReportsReimburse, {});
export const reportsExpDetails_reducer = ReducerFunctions(
  ReportsExpDetails,
  {}
);
export const reportsExpComments_reducer = function (state = {}, action) {
  switch (action?.type) {
    case ReportsExpComments.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ReportsExpComments.SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        success: true,
      };
    }
    case ReportsExpComments.FAILED:
      return {
        ...state,
        ...action.payload,
        loading: false,
        success: false,
      };
    case ReportsExpComments.RESET:
      return {
        loading: false,
      };

    default:
      return state;
  }
};
export const reportsExpAddComments_reducer = ReducerFunctions(
  ReportsExpAddComments,
  {}
);
export const reportsComments_reducer = ReducerFunctions(ReportsComments, {});
export const reportsCommentsAdd_reducer = ReducerFunctions(
  ReportsCommentsAdd,
  {}
);
export const reportsExportExcel_reducer = ReducerFunctions(
  ReportsExportExcel,
  {}
);
export const reportsHistory_reducer = ReducerFunctions(ReportsHistory, {});
export const reportsAdvance_reducer = ReducerFunctions(ReportsAdvance, {});
export const reportsTrips_reducer = ReducerFunctions(ReportsTrips, {});
export const reportsExpHistory_reducer = ReducerFunctions(
  ReportsExpHistory,
  {}
);

export const userReports_reducer = ReducerFunctions(UserReports, {});
export const userReportSubmit_reducer = ReducerFunctions(UserReportSubmit, {});
export const userReportRecall_reducer = ReducerFunctions(UserReportRecall, {});
export const addUserReports_reducer = ReducerFunctions(AddUserReports, {});
export const userReportFields_reducer = ReducerFunctions(UserReportFields, {});
export const reportdetails_pdf_reducer = ReducerFunctions(ReportDetailsPDF, {});
export const forward_approval_reducer = ReducerFunctions(ForwardApproval, {});
export const changereport_reducer = ReducerFunctions(ChangeReports,{});
export const userReportLinkExp_reducer = ReducerFunctions(
  UserReportLinkExp,
  {}
);
export const userReportLinkAdv_reducer = ReducerFunctions(
  UserReportLinkAdv,
  {}
);
