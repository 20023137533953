import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import endpoint from "../../config/Axios";
import {
  objectToFormData,
  sagaFunctionsSettings,
  sagaFunctionsExpense,
} from "../../utils";
import { sagaFunctions } from "../../utils";
import {
  Reports,
  ReportsReject,
  TransactionsReject,
  TransactionsPartialApprove,
  ReportsApprove,
  ReportsExpDetails,
  ReportsExpComments,
  ReportsExpAddComments,
  ReportsComments,
  ReportsCommentsAdd,
  ReportsExportExcel,
  ReportsHistory,
  ReportsAdvance,
  ReportsTrips,
  ReportsExpHistory,
  ReportsReimburse,
  UserReports,
  UserReportSubmit,
  UserReportRecall,
  AddUserReports,
  UserReportFields,
  UserReportLinkExp,
  UserReportLinkAdv,
  ReportsBulkApproveReject,
  ReportDetailsPDF,
  ReportsCount,
  UserReport,
  ForwardApproval,
  ChangeReports,
} from "./reports";

function getReports(req) {
  let obj = {
    module_type: req.payload.module_type,
  };
  // if (req.payload.status) {
  //   obj.status = req.payload.status;
  //   delete req.payload.status;
  // }
  if (req.payload.view) {
    obj.view = req.payload.view;
    delete req.payload.view;
  }
  if (req.payload.page_number) {
    obj.page_number = req.payload.page_number;
    delete req.payload.page_number;
  }
  delete req.payload.view;
  delete req.payload.module_type;
  if (!obj?.view) {
    delete obj?.view;
  }

  let urlParams = new URLSearchParams(obj);
  return sagaFunctionsExpense(
    Reports,
    "post",
    apis.reports + `?${urlParams}`,
    req.payload
  )();
}

function getForwardApproval(req) {
  return sagaFunctionsExpense(
    ForwardApproval,
    "post",
    apis.forwardApproval,
    req.payload,
    {
      params: {
        module_type: req.payload?.module_type,
      },
    }
  )();
}

function getchangeReport(req) {
  return sagaFunctionsExpense(
    ChangeReports,
    "post",
    apis.changeReport,
    req.payload
  )();
}

function getUserReport(req) {
  return sagaFunctionsExpense(
    UserReport,
    "get",
    req.payload.is_admin ? apis.showReport : apis.userShowReport,
    req.payload
  )();
}

function getReportsCount(req) {
  return sagaFunctionsExpense(
    ReportsCount,
    "get",
    apis.report_counts,
    req.payload
  )();
}

function getReportsReject(req) {
  const isDelete = req.payload.isDelete;
  delete req.payload.isDelete;
  let url = `/api/reports/delete`;
  return sagaFunctionsExpense(
    ReportsReject,
    "post",
    isDelete ? url : apis.reportsReject,
    isDelete ? objectToFormData(req.payload) : req.payload
  )();
}

function getTransactionsReject(req) {
  return sagaFunctionsExpense(
    TransactionsReject,
    "post",
    apis.transactionsReject,
    req.payload
    // {
    //   "Content-Type": "multipart/formdata",
    // }
  )();
}

function getTransactionsPartialApprove(req) {
  return sagaFunctions(
    TransactionsPartialApprove,
    "post",
    apis.transactionsPartialApprove,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
    }
  )();
}

function getReportsApprove(req) {
  return sagaFunctionsExpense(
    ReportsApprove,
    "post",
    apis.reportsApprove,
    req.payload
  )();
}

function reportDetailsPDF(req) {
  return sagaFunctions(
    ReportDetailsPDF,
    "get",
    apis.reportDetailsPDF,
    req.payload
  )();
}

function reportsBulkApproveReject(req) {
  let type = req.payload.type;
  delete req.payload.type;
  return sagaFunctions(
    ReportsBulkApproveReject,
    "post",
    type === "approve" ? apis.reportsApproveBulk : apis.reportsRejectBulk,
    req.payload,
    {
      "Content-Type": "application/json; charset=utf-8",
    }
  )();
}

function getReportsExpDetails(req) {
  return sagaFunctionsExpense(
    ReportsExpDetails,
    "get",
    req.otherParams?.is_admin
      ? apis.getReportTransactionField
      : apis.userTransactions,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
    }
  )();
}

export function* getReportsExpComments(req) {
  let res = null;
  try {
    res = yield call(
      endpoint.get,
      req.otherParams?.is_admin
        ? apis.getReportTransactionComments
        : apis.getUserTransactionComments,
      {
        params: req.payload,
      }
    );

    if (res.status < 400) {
      yield put({
        type: ReportsExpComments.SUCCESS,
        payload: { [req.payload?.transaction_id]: res.data },
      });
    } else {
      yield put({
        type: ReportsExpComments.FAILED,
        payload: { [req.payload?.transaction_id]: res.data },
      });
    }
  } catch (err) {
    yield put({
      type: ReportsExpComments.FAILED,
      payload: err?.response?.data,
    });
  }
}

function getReportsExpAddComments(req) {
  return sagaFunctions(
    ReportsExpAddComments,
    "post",
    req.otherParams?.is_admin
      ? apis.getReportTransactionComments
      : apis.getUserTransactionComments,
    req.payload,
    {},
    true
    // {
    //   "Content-Type": "multipart/formdata",

    // }
  )();
}

function getReportsComments(req) {
  return sagaFunctions(
    ReportsComments,
    "get",
    req.otherParams?.is_admin ? apis.reportsComments : apis.userReportsComments,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
    }
  )();
}

function getReportsCommentsAdd(req) {
  let is_admin = req.payload?.is_admin;
  delete req.payload?.is_admin;
  return sagaFunctions(
    ReportsCommentsAdd,
    "post",
    is_admin ? apis.reportsComments : apis.userReportsComments,
    req.payload,
    {},
    true
  )();
}

function getReportsExportExcel(req) {
  return sagaFunctions(
    ReportsExportExcel,
    "post",
    req.otherParams?.is_admin
      ? apis.reportsExportExcel
      : apis.userReportsExportExcel,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
    }
  )();
}

function getReportsHistory(req) {
  return sagaFunctions(
    ReportsHistory,
    "get",
    req.otherParams?.is_admin ? apis.reportsHistory : apis.userReportsHistory,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
    }
  )();
}

function getReportsAdvance(req) {
  return sagaFunctions(
    ReportsAdvance,
    "get",
    req.otherParams?.is_admin ? apis.reportsAdvance : apis.userReportsAdvance,
    req.payload
  )();
}

function getReportsTrips(req) {
  return sagaFunctionsExpense(
    ReportsTrips,
    "post",
    req.otherParams?.is_admin ? apis.trips : apis.userTrips,
    req.payload,
    {
      // "Content-Type": "multipart/formdata",
    }
  )();
}

function getReportsExpHistory(req) {
  return sagaFunctions(
    ReportsExpHistory,
    "get",
    req.otherParams?.is_admin
      ? apis.transactionsHistory
      : apis.userTransactionsHistory,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
    }
  )();
}

function getReportsReimburse(req) {
  return sagaFunctionsExpense(
    ReportsReimburse,
    "post",
    apis.reportsReimburse,
    req.payload,
    {
      "Content-Type": "application/json;charset=UTF-8",
    }
  )();
}

function getUserReports(req) {
  return sagaFunctionsExpense(
    UserReports,
    "post",
    apis.userReports,
    req.payload,
    {
      // "Content-Type": "multipart/formdata",
    }
  )();
}

function getUserReportSubmit(req) {
  return sagaFunctionsExpense(
    UserReportSubmit,
    "post",
    apis.userReportSubmit,
    req.payload
  )();
}

function getUserReportRecall(req) {
  return sagaFunctionsExpense(
    UserReportRecall,
    "post",
    apis.userReportRecall,
    req.payload
  )();
}

function getAddUserReports(req) {
  // let formData = new FormData()
  // req.payload?.field_values?.forEach((ele, i) => {
  //   formData.append(`field_values[${i}][id]`, ele.id)
  //   formData.append(`field_values[${i}][value]`, ele.value)
  // });

  return sagaFunctionsExpense(
    AddUserReports,
    "post",
    req.payload?.id ? apis.updateUserReport : apis.userReports,
    req.payload
  )();
}

function getUserReportFields(req) {
  return sagaFunctionsSettings(
    UserReportFields,
    "get",
    apis.userReportFields,
    req.payload
  )();
}

function getUserReportLinkExp(req) {
  return sagaFunctionsExpense(
    UserReportLinkExp,
    "post",
    apis.userReportLinkExp,
    req.payload
    // {
    //   "Content-Type": "multipart/formdata",
    // }
  )();
}

function getUserReportLinkAdv(req) {
  return sagaFunctionsExpense(
    UserReportLinkAdv,
    "post",
    apis.userReportLinkAdv,
    req.payload
  )();
}

export function* reportsWatcher() {
  yield takeLatest(Reports.REQUEST, getReports);
  yield takeLatest(UserReport.REQUEST, getUserReport);
  yield takeLatest(ReportsCount.REQUEST, getReportsCount);
  yield takeLatest(ReportsReject.REQUEST, getReportsReject);
  yield takeLatest(ReportsBulkApproveReject.REQUEST, reportsBulkApproveReject);
  yield takeLatest(TransactionsReject.REQUEST, getTransactionsReject);
  yield takeLatest(
    TransactionsPartialApprove.REQUEST,
    getTransactionsPartialApprove
  );
  yield takeLatest(ReportsApprove.REQUEST, getReportsApprove);
  yield takeLatest(ReportsExpDetails.REQUEST, getReportsExpDetails);
  yield takeEvery(ReportsExpComments.REQUEST, getReportsExpComments);
  yield takeLatest(ReportsExpAddComments.REQUEST, getReportsExpAddComments);
  yield takeLatest(ReportsComments.REQUEST, getReportsComments);
  yield takeLatest(ReportsCommentsAdd.REQUEST, getReportsCommentsAdd);
  yield takeLatest(ReportsExportExcel.REQUEST, getReportsExportExcel);
  yield takeLatest(ReportsHistory.REQUEST, getReportsHistory);
  yield takeLatest(ReportsAdvance.REQUEST, getReportsAdvance);
  yield takeLatest(ReportsTrips.REQUEST, getReportsTrips);
  yield takeLatest(ReportsExpHistory.REQUEST, getReportsExpHistory);
  yield takeLatest(ReportsReimburse.REQUEST, getReportsReimburse);
  yield takeLatest(ForwardApproval.REQUEST, getForwardApproval);
  yield takeLatest(ChangeReports.REQUEST, getchangeReport);
  yield takeLatest(UserReports.REQUEST, getUserReports);
  yield takeLatest(UserReportSubmit.REQUEST, getUserReportSubmit);
  yield takeLatest(UserReportRecall.REQUEST, getUserReportRecall);
  yield takeLatest(AddUserReports.REQUEST, getAddUserReports);
  yield takeLatest(UserReportFields.REQUEST, getUserReportFields);
  yield takeLatest(UserReportLinkExp.REQUEST, getUserReportLinkExp);
  yield takeLatest(UserReportLinkAdv.REQUEST, getUserReportLinkAdv);
  yield takeLatest(ReportDetailsPDF.REQUEST, reportDetailsPDF);
}
