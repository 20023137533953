import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { countries as country } from "./country";
import { apis } from "../../../config/APIs";
import { PulseLoader } from "react-spinners";
import pdf_icon from "../../../assets/icons/pdf_icon.png";
import { getFileExtension } from "../../../utils";
import { useSelector } from "react-redux";
const { Title, Text } = Typography;
const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;
const AddNewOrgForm = ({
  formik,
  beforeUpload,
  beforeUploadLogo,
  handleFileChange,
  uploadData,
  branchDetails,
  getCardVendersResponse,
  allCurrencyList,
}) => {
  const { t } = useTranslation();
  const searchRef = useRef();
  const ifscRef = useRef();
  const tzs = useSelector(
    (state) => state?.companySetting?.companyTzs?.data?.data || []
  );
  const [output, setOutput] = useState({});
  const [pincodeLoder, setPincodeLoder] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
    fileType: "",
  });

  useEffect(() => {
    formik?.setFieldValue("state", output?.state);
    formik?.setFieldValue("city", output?.district);
  }, [output]);

  const handleCountryData = (value) => {
    formik.setFieldValue("country", value);
  };
  const handleCurrencyData = (value) => {
    formik.setFieldValue("base_currency_id", value);
  };
  const handleSearchPincode = (value) => {
    formik.setFieldValue("pincode", value);
    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }
    searchRef.current = setTimeout(() => {
      setPincodeLoder(true);
      axios
        .get(
          "https://api.worldpostallocations.com/pincode?postalcode=" +
            value +
            "&countrycode=" +
            formik?.values?.country +
            ""
        )
        .then((response) => {
          setPincodeLoder(false);
          setOutput(
            response?.data?.result?.[0] ? response?.data?.result?.[0] : {}
          );
        })
        .catch((err) => {
          message.error(<span className="messageText">{err?.message}</span>);
          setPincodeLoder(false);
        });
    }, 500);
  };
  return (
    <>
      {pincodeLoder && (
        <div className="text-center loadingDiv">
          <PulseLoader color="#fff" />
        </div>
      )}
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
            fileType: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex-center"
          >
            <img
              src={isPreviewOpen?.url}
              alt="preview image"
              style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
            />
          </div>
        )}
      </Modal>
      <Row>
        <Col span={12}>
          <Form.Item label={t("organisation_name")} required={true}>
            <Input
              placeholder={t("organisation_name")}
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.name}
            </div>
          </Form.Item>
          <Form.Item label={t("Organisation_register_no")} required={true}>
            <Input
              placeholder={t("Organisation_register_no")}
              name="registration_no"
              onChange={formik.handleChange}
              value={formik.values.registration_no}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.registration_no}
            </div>
          </Form.Item>
          <Form.Item label={t("org_primary_email")} required={true}>
            <Input
              placeholder={t("org_primary_email")}
              name="primary_email"
              onChange={formik.handleChange}
              value={formik.values.primary_email}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.primary_email}
            </div>
          </Form.Item>
          <Form.Item label={t("address")} required={true}>
            <TextArea
              name="address"
              onChange={formik.handleChange}
              placeholder={t("enter_address")}
              autoSize={{ minRows: 3, maxRows: 5 }}
              value={formik.values.address}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.address}
            </div>
          </Form.Item>
        </Col>
        <Col span={12} className="uploadSection">
          {<Text>{t("upload_ur_organisation_logo")}</Text>}
          {uploadData?.logo?.attachId === formik.values?.logo &&
            formik.values?.logo && (
              <div
                style={{ width: "15rem", height: "10rem" }}
                onClick={() => {
                  setIsPreviewOpen({
                    open: true,
                    url: `${uploadData?.logo?.url}`,
                    fileType: getFileExtension(uploadData?.logo?.fileName),
                  });
                }}
              >
                <img
                  alt="logo_url"
                  src={uploadData?.logo?.url}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            )}
          <Dragger
            maxCount={1}
            beforeUpload={beforeUploadLogo}
            showUploadList={false}
            name="logo"
            onChange={(evt) => handleFileChange(evt, "logo")}
          >
            <p className="ant-upload-text">
              <InboxOutlined />
              {uploadData?.logo?.attachId ? t("drag_change") : t("drag_png")}
              <br />
              {uploadData?.logo?.attachId
                ? t("click_here_to_change")
                : t("click_here_to")}
            </p>
          </Dragger>
        </Col>
      </Row>
      <Row gutter={[6]}>
        <Col span={6}>
          <Form.Item label={t("country")} required={true}>
            <Select
              style={{ width: 200 }}
              placeholder={t("select_country")}
              options={country}
              showSearch
              name="country"
              onChange={(val, data) => {
                handleCountryData(data.code);
              }}
              value={
                country?.find((ele) => ele.code === formik.values.country)
                  ?.value
              }
              allowClear
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.country}
            </div>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("pincode")} required={true}>
            <Input
              placeholder={t("pincode")}
              name="pincode"
              onChange={(e) => {
                handleSearchPincode(e.target.value);
              }}
              value={formik.values.pincode}
              disabled={!formik.values.country}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.pincode}
            </div>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("city")}>
            <Input
              placeholder={t("city")}
              name="city"
              onChange={formik.handleChange}
              value={formik.values.city}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("state")}>
            <Input
              placeholder={t("state")}
              name="state"
              onChange={formik.handleChange}
              value={formik.values.state}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[6]}>
        <Col span={8}>
          <Form.Item label={t("office_number")} required={true}>
            <Input
              placeholder={t("Office Number")}
              name="office_no"
              onChange={formik.handleChange}
              value={formik.values.office_no}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.office_no}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("gstin_no")} required={true}>
            <Input
              placeholder={t("gstin_no")}
              name="gst_no"
              onChange={formik.handleChange}
              value={formik.values.gst_no}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.gst_no}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("tan_no")} required={true}>
            <Input
              placeholder={t("tan_no")}
              name="tan_no"
              onChange={formik.handleChange}
              value={formik.values.tan_no}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.tan_no}
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Text>{t("upload_organisation_documents")}</Text>
      <Row className="mt1">
        <Col span={12} className="uploadSectionSmall">
          <Text>{t("certificate_incorporation")}</Text>
          {uploadData?.incorporation?.attachId ===
            formik?.values?.incorporation &&
          ["pdf"]?.includes(
            getFileExtension(uploadData?.incorporation?.fileName)?.toLowerCase()
          ) ? (
            <>
              {/* <a href={branchDetails?.data?.data?.incorporation?.url} target="_blank"> */}
              <div
                style={{ width: "6rem", margin: "auto" }}
                onClick={() => {
                  setIsPreviewOpen({
                    open: true,
                    url: `${uploadData?.incorporation?.url}`,
                    fileType: "pdf",
                  });
                }}
              >
                <img
                  alt="incorporation_url"
                  src={pdf_icon}
                  style={{ width: "100%" }}
                />
              </div>
              {/* <h4>{uploadData?.incorporation?.fileName}</h4> */}
              {/* </a> */}
            </>
          ) : uploadData?.incorporation?.attachId ===
              formik?.values?.incorporation && formik?.values?.incorporation ? (
            <div
              style={{ width: "15rem", height: "10rem" }}
              onClick={() => {
                setIsPreviewOpen({
                  open: true,
                  url: `${uploadData?.incorporation?.url}`,
                  fileType: getFileExtension(
                    uploadData?.incorporation?.fileName
                  ),
                });
              }}
            >
              <img
                alt="incorporation_url"
                src={uploadData?.incorporation?.url}
                className="w100 h100"
              />
            </div>
          ) : null}
          <Dragger
            maxCount={1}
            beforeUpload={beforeUpload}
            showUploadList={false}
            name="incorporation"
            onChange={(evt) => handleFileChange(evt, "incorporation")}
          >
            <p className="ant-upload-text">
              <InboxOutlined />
              {uploadData?.incorporation?.attachId
                ? t("drag_change")
                : t("drag_upload")}
              <br />
              {uploadData?.incorporation?.attachId
                ? t("click_here_to_change")
                : t("click_here_to")}
            </p>
          </Dragger>
        </Col>
        <Col span={12} className="uploadSectionSmall">
          <Text>
            {t("other_documents")} {t("gst_tan")}
          </Text>
          {uploadData?.gst_certificate?.attachId ===
            formik?.values?.gst_certificate &&
          ["pdf"]?.includes(
            getFileExtension(
              uploadData?.gst_certificate?.fileName
            )?.toLowerCase()
          ) ? (
            <>
              {/* <a href={branchDetails?.data?.data?.gst_certificate?.url} target="_blank"> */}
              <div
                style={{ width: "6rem", margin: "auto" }}
                onClick={() => {
                  setIsPreviewOpen({
                    open: true,
                    url: `${uploadData?.gst_certificate?.url}`,
                    fileType: "pdf",
                  });
                }}
              >
                <img
                  alt="gst_certificate_url"
                  src={pdf_icon}
                  style={{ width: "100%" }}
                />
              </div>
              {/* <h4>{uploadData?.gst_certificate?.file_name}</h4> */}
              {/* </a> */}
            </>
          ) : uploadData?.gst_certificate?.attachId ===
              formik?.values?.gst_certificate &&
            formik?.values?.gst_certificate ? (
            <div
              style={{ width: "15rem", height: "10rem" }}
              onClick={() => {
                setIsPreviewOpen({
                  open: true,
                  url: `${uploadData?.gst_certificate?.url}`,
                  fileType: getFileExtension(
                    uploadData?.gst_certificate?.fileName
                  ),
                });
              }}
            >
              <img
                alt="gst_certificate_url"
                src={uploadData?.gst_certificate?.url}
                className="w100 h100"
              />
            </div>
          ) : null}
          <Dragger
            maxCount={1}
            beforeUpload={beforeUpload}
            showUploadList={false}
            name="gst_certificate"
            onChange={(evt) => handleFileChange(evt, "gst_certificate")}
          >
            <p className="ant-upload-text">
              <InboxOutlined />
              {uploadData?.gst_certificate?.attachId
                ? t("drag_change")
                : t("drag_upload")}
              <br />
              {uploadData?.gst_certificate?.attachId
                ? t("click_here_to_change")
                : t("click_here_to")}
            </p>
          </Dragger>
        </Col>
      </Row>
      <Title level={5} className="mt2">
        {t("organisation_ids")}
      </Title>
      <Divider />
      <Row gutter={[6]}>
        <Col span={8}>
          <Form.Item label={t("card_endor")}>
            <Select
              name="vendor_id"
              placeholder={t("card_endor")}
              value={formik.values.vendor_id}
              onChange={(val) => {
                formik.setFieldValue("vendor_id", val);
              }}
              options={getCardVendersResponse}
              allowClear
            ></Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("mo_id")}>
            <Input
              disabled={branchDetails?.data?.data?.mo_id}
              placeholder={t("mo_id")}
              name="mo_id"
              onChange={formik.handleChange}
              value={formik.values.mo_id}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.mo_id}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("virtual_id")}>
            <Input
              disabled={branchDetails?.data?.data?.virtual_id}
              placeholder={t("virtual_id")}
              name="virtual_id"
              onChange={formik.handleChange}
              value={formik.values.virtual_id}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.virtual_id}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("outlet_id")}>
            <Input
              disabled={branchDetails?.data?.data?.outlet_id}
              placeholder={t("outlet_id")}
              name="outlet_id"
              onChange={formik.handleChange}
              value={formik.values.outlet_id}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.outlet_id}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("fiscal_year")}>
            <Select
              placeholder={t("fiscal_year")}
              onChange={(val) => {
                formik.setFieldValue("fiscal_year", val);
              }}
              value={formik.values.fiscal_year}
              name="fiscal_year"
              allowClear
            >
              <Option key={"financial_year"} value={"financial_year"}>
                {"Financial Year"}
              </Option>
              <Option key={"calendar_year"} value={"calendar_year"}>
                {"Calendar Year"}
              </Option>
            </Select>
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.fiscal_year}
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Title level={5} className="mt2">
        {t("primary_contact")}
      </Title>
      <Divider />
      <Row gutter={[6]}>
        {/* <Col span={3}>
                    <Form.Item label={t('title')} required={true}>
                        <Select
                            disabled={branchDetails?.data?.data?.title}
                            placeholder={t('title')}
                            onChange={(val) => {
                                formik.setFieldValue("title", val)
                            }}
                            value={formik.values.title}
                            name="title"
                            style={{ opacity: branchDetails?.data?.data?.title ? 0.6 : 1 }}
                        >
                            <Option key={"mr"} value={"mr"}>{"Mr"}</Option>
                            <Option key={"ms"} value={"ms"}>{"Ms"}</Option>
                        </Select>
                        <div className="errorMsg">
                            {formik.submitCount > 0 && formik.errors.title}
                        </div>
                    </Form.Item>
                </Col> */}
        <Col span={8}>
          <Form.Item label={t("primary_contact_name")} required={true}>
            <Input
              disabled={branchDetails?.data?.data?.primary_contact_name}
              placeholder={t("primary_contact_name")}
              name="primary_contact_name"
              onChange={formik.handleChange}
              value={formik.values.primary_contact_name}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.primary_contact_name}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("primary_contact_email_Address")}
            name="email"
            required={true}
          >
            <Input
              disabled={branchDetails?.data?.data?.email}
              placeholder={t("primary_contact_email")}
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.email}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("primary_contact_number")} required={true}>
            <Input
              disabled={branchDetails?.data?.data?.primary_phone}
              placeholder={t("primary_contact_number")}
              name="primary_phone"
              onChange={formik.handleChange}
              value={formik.values.primary_phone}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.primary_phone}
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Title level={5} className="mt2">
        {t("regional_ettings")}
      </Title>
      <Divider />
      <Row gutter={8}>
        <Col span={8}>
          <Form.Item label={t("base_currency")} required={true}>
            <Select
              name="base_currency_id"
              placeholder={t("base_currency")}
              onChange={(val, data) => {
                handleCurrencyData(data?.value);
              }}
              showSearch={true}
              value={
                allCurrencyList?.find(
                  (ele) => ele.value === formik.values.base_currency_id
                )?.value
              }
              options={allCurrencyList}
              filterOption={true}
              optionFilterProp={"country_name"}
              disabled={branchDetails?.data?.data?.base_currency_id}
              style={{
                opacity: branchDetails?.data?.data?.base_currency_id ? 0.6 : 1,
              }}
              allowClear
            ></Select>
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.base_currency_id}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("Timezone")}>
            <Select
              // value={country?.find(ele => ele.code === countryData)?.value}
              value={formik?.values?.time_zone_id}
              placeholder={t("Select timezone")}
              options={tzs?.map((value) => ({
                label: value?.time_zone + " " + value?.offset,
                value: value?.id,
              }))}
              filterOption={(input, option) =>
                (option?.label?.toLowerCase?.() ?? "")?.includes(
                  input?.toLowerCase?.()
                )
              }
              showSearch
              onChange={(val, data) => {
                formik?.setFieldValue("time_zone_id", val);
              }}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.time_zone_id}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("Time Format")}>
            <Select
              // value={country?.find(ele => ele.code === countryData)?.value}
              value={formik?.values?.time_format}
              filterOption={(input, option) =>
                (option?.label?.toLowerCase?.() ?? "")?.includes(
                  input?.toLowerCase?.()
                )
              }
              placeholder={t("Select time format")}
              options={[
                {
                  label: "12-hr format",
                  value: "12",
                },
                {
                  label: "24-hr format",
                  value: "24",
                },
              ]}
              showSearch
              onChange={(val, data) => {
                formik?.setFieldValue("time_format", val);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title level={5} className="mt2">
        {t("bank_details")}
      </Title>
      <Divider />
      <Row gutter={[6]}>
        <Col span={8}>
          <Form.Item label={t("account_ifsc")} required={true}>
            <Input
              placeholder={t("account_ifsc")}
              name="account_ifsc"
              onChange={(e) => {
                if (ifscRef.current) {
                  clearTimeout(ifscRef.current);
                }
                ifscRef.current = setTimeout(() => {
                  setPincodeLoder(true);
                  axios
                    .get(apis.getIfscDetails, {
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                      params: {
                        ifsc_code: e.target.value?.toUpperCase(),
                      },
                    })
                    .then((res) => {
                      if (!res?.data?.error) {
                        formik.setFieldValue(
                          `bank_name`,
                          res?.data?.data?.bank_name
                        );
                        formik.setFieldValue(
                          `bank_branch`,
                          res?.data?.data?.branch
                        );
                        formik.setFieldValue(
                          `bank_address`,
                          res?.data?.data?.address
                        );
                      } else {
                        formik.setFieldValue(`bank_name`, "");
                        formik.setFieldValue(`bank_branch`, "");
                        formik.setFieldValue(`bank_address`, "");
                        if (e.target.value)
                          message.error(
                            <span className="messageText">
                              Bank Details not found.
                            </span>
                          );
                      }
                      setPincodeLoder(false);
                    })
                    .catch(() => {
                      if (e.target.value)
                        message.error(
                          <span className="messageText">
                            Bank Details not found.
                          </span>
                        );
                      formik.setFieldValue(`bank_name`, "");
                      formik.setFieldValue(`bank_branch`, "");
                      formik.setFieldValue(`bank_address`, "");
                      setPincodeLoder(false);
                    });
                }, 700);
                formik.setFieldValue(
                  `account_ifsc`,
                  e.target.value?.toUpperCase()
                );
              }}
              value={formik.values.account_ifsc}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.account_ifsc}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("account_name")}>
            <Input
              placeholder={t("account_name")}
              name="account_name"
              onChange={formik.handleChange}
              value={formik.values.account_name}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("account_number")} required={true}>
            <Input
              placeholder={t("account_number")}
              name="account_number"
              onChange={formik.handleChange}
              value={formik.values.account_number}
            />
            <div className="errorMsg">
              {formik.submitCount > 0 && formik.errors.account_number}
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("bank_name")}>
            <Input
              placeholder={t("bank_name")}
              name="bank_name"
              onChange={formik.handleChange}
              value={formik.values.bank_name}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("bank_branch")}>
            <Input
              placeholder={t("bank_branch")}
              name="bank_branch"
              onChange={formik.handleChange}
              value={formik.values.bank_branch}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("bank_address")}>
            <Input
              placeholder={t("bank_address")}
              name="bank_address"
              onChange={formik.handleChange}
              value={formik.values.bank_address}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default AddNewOrgForm;
