import React from "react";
import no_data from "./no_table_data.png";

const NoTableData = ({ title, onAddNew }) => {
  const contentMap = {
    "No Transactions yet.": `Make your first transaction.`,
    Applicants: `You haven't added any applicants yet. Please add applicants by clicking on "Add New."`,
  };
  return (
    <div className="no-table-data">
      <div className="icon">
        <img src={no_data} alt="Applications Icon" />
      </div>
      <h2>{title}</h2>
      <p>{contentMap[title]}</p>
      {onAddNew && (
        <button className="primary-btn" onClick={onAddNew}>
          Add New
        </button>
      )}
    </div>
  );
};

export default NoTableData;
