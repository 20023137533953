import React, { useState } from "react";
import { Modal, Input, Button, Form } from "antd";
import { useForm } from "react-hook-form";
import { FormInput } from "../inputs";
import usePayAxios from "../../config/useAxios";
import { apis } from "../../config/APIs";
import { useTranslation } from "react-i18next";

const AddSource = ({ isVisible, onCancel, companyId }) => {
  const {t} = useTranslation()
    const { onCall } = usePayAxios({api: apis.AddCategory,method:"post"});
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
        company_id: "",
        seed_code: "",
        seed_value: "",
        seed_type: "",
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload = {
        company_id: companyId,
        seed_code: "POS_1",
        seed_value: data.seed_value,
        seed_type: "place_of_supply",
    };

    onCall({
      data: payload,
    }).then(data => {
    reset()
    onCancel()
    }).catch(err => err.message);

  };

  return (
    <>
      <Modal title={t('payment_add_terms')} visible={isVisible} onCancel={onCancel}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("seed_value")}
                errors={errors}
                label={t('source_of_supply')}
                placeholder={t('enter_source_apply')}
              />
            </div>
            {/* <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("number_of_days")}
                errors={errors}
                label="Number of days#"
                placeholder="Enter Number of days"
              />
            </div> */}
            <Button className="pp-secondary-button" onClick={onCancel}>
              {t('cancel')}
            </Button>
            <Button type="primary" htmlType="submit" className="pp-main-button">
              {t('submit')}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddSource;