import React, { useEffect, useState } from "react";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Divider, Form, Typography, message } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import useSettings from "../../config/useSettings";
import { apis } from "../../config/APIs";
import { getportalPermissions } from "../../actions/companySettings";

const Portal = ({ isCustomer, isVendor, hasWriteAccess }) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const portalPermissionData = useSelector(
    (state) => state?.companySetting?.getPortalPermission?.data
  );
  useEffect(() => {
    dispatch(getportalPermissions({ isCustomer: isCustomer }));
    dispatch(
      headerTitles({
        title: isCustomer ? "Customer Portal" : "Vendor",
        description: isCustomer ? "" : "",
      })
    );
  }, [isCustomer, isVendor]);

  const onCallMount = () => {
    if (isCustomer) {
      formik.setFieldValue(
        "notify_portal_activity",
        portalPermissionData?.data?.notify_portal_activity
      );
      formik.setFieldValue(
        "email_on_comment",
        portalPermissionData?.data?.email_on_comment
      );
      formik.setFieldValue(
        "allow_upload_edit",
        portalPermissionData?.data?.allow_upload_edit
      );
      formik.setFieldValue(
        "allow_forward_docs",
        portalPermissionData?.data?.allow_forward_docs
      );
      formik.setFieldValue(
        "enable_reviews",
        portalPermissionData?.data?.enable_reviews
      );
      formik.setFieldValue(
        "view_sales_orders",
        portalPermissionData?.data?.view_sales_orders
      );
      formik.setFieldValue(
        "display_credit_notes",
        portalPermissionData?.data?.display_credit_notes
      );
      formik.setFieldValue(
        "allow_contact_update",
        portalPermissionData?.data?.allow_contact_update
      );
    } else if (isVendor) {
      formik.setFieldValue(
        "notify_portal_activity",
        portalPermissionData?.data?.notify_portal_activity
      );
      formik.setFieldValue(
        "notify_comments",
        portalPermissionData?.data?.notify_comments
      );
      formik.setFieldValue(
        "notify_reject_docs",
        portalPermissionData?.data?.notify_reject_docs
      );
      formik.setFieldValue(
        "allow_contact_update",
        portalPermissionData?.data?.allow_contact_update
      );
      formik.setFieldValue(
        "allow_document_upload",
        portalPermissionData?.data?.allow_document_upload
      );
      formik.setFieldValue(
        "allow_po_acceptance",
        portalPermissionData?.data?.allow_po_acceptance
      );
    }
  };

  useEffect(() => {
    onCallMount();
  }, [portalPermissionData]);
  const {
    onCall: permissionsFetch,
    data: permissionsData,
    reset: permissionsReset,
  } = useSettings({
    api: isCustomer ? apis.permissionAPIsUpdate : apis.permissionAPIVendor,
    method: "post",
  });
  // const ValidationSchema = yup.object().shape({});
  const initialValues = isCustomer
    ? {
      notify_portal_activity: false,
      email_on_comment: false,
      allow_upload_edit: false,
      allow_forward_docs: false,
      enable_reviews: false,
      view_sales_orders: false,
      display_credit_notes: false,
      allow_contact_update: false,
    }
    : {
      notify_portal_activity: false,
      notify_comments: false,
      notify_reject_docs: false,
      allow_contact_update: false,
      allow_document_upload: false,
      allow_po_acceptance: false,
    };
  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: ValidationSchema,
    onSubmit: (values) => {
      permissionsFetch({ data: values });
    },
  });

  useEffect(() => {
    console.log("permissionsData permissionsData", permissionsData);
    if (permissionsData?.error == false) {
      message.success(
        <span className="messageText">{permissionsData?.message}</span>
      );
      dispatch(getportalPermissions({ isCustomer: isCustomer }));
      permissionsReset();
      formik?.resetForm({});
      setEditMode(false);
    } else if (permissionsData?.error == true) {
      message.error(
        <span className="messageText">{permissionsData?.message}</span>
      );
      permissionsReset();
    }
  }, [permissionsData]);

  const fieldsData = isCustomer
    ? [
      {
        title: "Notify me about Customer Portal activity via email",
        description:
          "Receive email notifications about your customers' portal activity, such as payments, comments, and transaction approvals.",
        name: "notify_portal_activity",
      },
      {
        title:
          "Send an email notification to customers when I comment on transactions.",
        description:
          "Your customers will receive an email notification whenever you comment on their transactions with the Show in Portal option enabled.",
        name: "email_on_comment",
      },
      {
        title: "Allow customers to upload documents",
        description: "Your customers will be able to upload documents",
        name: "allow_upload_edit",
      },
      {
        title: "Allow customers to edit their information in the portal",
        description:
          "Your customers will be able to edit their basic details, such as their address and display name.",
        name: "allow_contact_update",
      },
      {
        title: "Allow customers to forward documents from the portal",
        description:
          "Your customers can share invoices with their contact persons via email, right from the portal.",
        name: "allow_forward_docs",
      },
      {
        title: "Enable customer reviews for my service",
        description:
          "Your customers can rate your service and provide feedback.These reviews are not public.",
        name: "enable_reviews",
      },
      {
        title: "Allow customers to view Sales Orders",
        description:
          "This option allows your customers to view Sales Orders in the portal.",
        name: "view_sales_orders",
      },
      {
        title: "Display credit notes in the portal",
        description:
          "Your customers will be able to view all of their credit notes, the invoices to which they were applied, and details of refunds.",
        name: "display_credit_notes",
      },
    ]
    : [
      {
        title: "Notify me for every activity that takes place in the portal",
        description:
          "You will receive an email and an in -app notification whenever your vendor adds comments",
        name: "notify_portal_activity",
      },
      {
        title: "Notify my vendor when I comment the documents",
        description:
          "Your vendor will receive an email notification whenever you add a comment the documents they uploaded.",
        name: "notify_comments",
      },
      {
        title: "Notify my vendor when I reject the documents",
        description:
          "Your vendor will receive an email notification whenever you add a reject the documents they uploaded.",
        name: "notify_reject_docs",
      },
      {
        title: "Allow vendors to update their contact details in the portal",
        description:
          "Vendors have the ability to add or modify their shipping / billing addresses, custom fields, and other contact details.",
        name: "allow_contact_update",
      },
      {
        title: "Allow vendors to upload documents",
        description:
          "Vendors have the option to upload invoices that support your purchases.Upon uploading, you can review and convert them into bills within Bluerack.",
        name: "allow_document_upload",
      },
      {
        title: "Allow vendors to accept / reject purchase orders",
        description:
          "The purchase orders that you generate and send will be accessible within the portal.Vendors have the ability to review these orders and either accept or reject them.",
        name: "allow_po_acceptance",
      },
    ];

  return (
    <>
      <div className="contentWrapper">
        <Title level={5}>
          {isCustomer ? "Customer Portal" : "Vendor Portal"}
        </Title>
        <Divider />

        <Form onFinish={formik.handleSubmit}>
          {fieldsData?.map((fieldElement, index) => (
            <>
              <div className="portal-checkbox d-flex">
                {/* <Form.Item label={fieldElement?.title} > */}
                <Checkbox
                  style={{ float: "left" }}
                  checked={formik?.values?.[fieldElement?.name]}
                  name={fieldElement?.name}
                  onChange={formik?.handleChange}
                  disabled={!editMode}
                ></Checkbox>
                <div className="portal-field-label">
                  <h4 className="main-text">{fieldElement?.title}</h4>
                  <span className="sub-text">{fieldElement?.description}</span>
                </div>
                {/* </Form.Item> */}
              </div>
            </>
          ))}
          {
            <>
              {editMode ? (
                <>
                  <Button
                    key="1"
                    htmlType="submit"
                    className="formButton mt1 primaryButton"
                  >
                    {t("save")}
                  </Button>
                  <Button
                    key="2"
                    className="cancelButton ml1 mt1"
                    onClick={() => {
                      setEditMode(false);
                      onCallMount()
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => {
                    setEditMode(true);
                  }}
                  className="formButton mt1 primaryButton"
                  disabled={!hasWriteAccess}
                >
                  {t("edit")}
                </Button>
              )}
            </>
          }
        </Form>
      </div>
    </>
  );
};

export default Portal;
