import {
  InfoCircleOutlined,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Input, Select, Steps, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/icons/delete.png";
import NoCommentsComponent from "../../Common/NoComentsComponent";
import TableComponent from "../../Common/TableComp/TableComp";
const QCcheckDetails = ({
  hasWriteAccess,
  inputComment,
  handleAddComment,
  input,
  setInput,
  receiptDetails,
  qcStatus,
  setQCStatus,
  isReturns,
  returnCount,
  setReturnCount,
  iserror,
  iserrorRet,
  setIsError,
  setIsErrorRet,
  setSelectedOptions,
  selectedOptions,
  isCompleted,
  recordID,
  setRecordID,
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(isReturns ? "returns" : "qc check");

  const [tableData, setTableData] = useState([]);

  let secs = ["QC Check"];
  if (isReturns) {
    secs = ["Returns"].concat(secs.filter((sec) => sec !== "QC Check"));
  }

  const handleMenuClick = (e, record) => {
    if (receiptDetails?.invoice_status !== "completed" && hasWriteAccess) {
      let invoiceStatus;
      if (e === "1") {
        invoiceStatus = "PASS";
      } else if (e === "2") {
        invoiceStatus = "FAILED";
      } else if (e === "3") {
        invoiceStatus = "ONHOLD";
      }
      const existingIndex = qcStatus?.findIndex(
        (item) => item?.id === record?.id
      );

      if (existingIndex !== -1) {
        setQCStatus((prevData) => {
          const newData = [...prevData];
          newData[existingIndex] = {
            ...newData[existingIndex],
            qc_status: invoiceStatus,
          };
          return newData;
        });
      } else {
        setQCStatus((prevData) => [
          ...prevData,
          {
            id: record?.id,
            qc_status: invoiceStatus,
          },
        ]);
      }
    }
  };
  // const tagRender = (props) => {
  //   const { label, value, closable, onClose } = props;
  //   const onPreventMouseDown = (event) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   };
  //   return (
  //     <Tag
  //       color={value}
  //       onMouseDown={onPreventMouseDown}
  //       closable={closable}
  //       onClose={onClose}
  //       style={{
  //         marginRight: 3,
  //       }}
  //     >
  //       {label}
  //     </Tag>
  //   );
  // };
  const handleReturnMenuClick = (value, record) => {
    setSelectedOptions((prevOptions) => {
      const existingIndex = prevOptions[record?.id];

      if (existingIndex) {
        const updatedOptions = { ...prevOptions };
        updatedOptions[record?.id] = value;
        return { ...updatedOptions };
      } else {
        return { ...prevOptions, [record?.id]: value };
      }
    });
    setIsErrorRet((err) => {
      return {
        ...err,
        [record.id]: value?.length ? false : "Please select Return Reason",
        isButtonDisable: value?.length ? false : true,
      };
    });
  };

  const isNumber = (value) => {
    if (Number.isNaN(Number(value))) {
      return false;
    }
    return true;
  };
  const totalSum = (quantity, id, firstValue, secondValue, thirdValue) => {
    const item = returnCount?.find((ele) => ele?.id === id);
    if (item) {
      const sum =
        (parseInt(firstValue) || 0) +
        (parseInt(secondValue) || 0) +
        (parseInt(thirdValue) || 0);
      if (sum > quantity) {
        setIsError((prevData) => {
          return {
            ...prevData,
            [id]: true,
            isButtonDisable: true,
          };
        });
      } else {
        setIsError((prevData) => {
          return {
            ...prevData,
            [id]: false,
            isButtonDisable: false,
          };
        });
      }
    }
  };

  const inputValues = (value, id, type) => {
    setReturnCount((prevData) => {
      const existingIndex = prevData?.findIndex((item) => item.id === id);
      const updatedData = [...prevData];

      if (existingIndex !== -1) {
        // Update existing item
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          [type]: value,
        };
      } else {
        const newItem = {
          id: id,
          qc_failed_count: "",
        };
        // const newItem = {
        //   id: id,
        //   qc_passed_count: "",
        //   qc_failed_count: "",
        //   qc_onhold_count: ""
        // };
        newItem[type] = value;
        updatedData.push(newItem);
      }

      return updatedData;
    });
  };

  const options = [
    { label: "DAMAGED", value: "DAMAGED" },
    { label: "WRONG PRODUCT", value: "WRONG PRODUCT" },
    { label: "WRONG VENDOR", value: "WRONG VENDOR" },
  ];

  const columns = useMemo(() => {
    let baseColumns = [
      {
        title: `${t("Serial No")}`,
        dataIndex: "",
        key: "",
        render: (text, record, index) => <div>{index + 1}</div>,
      },
      {
        title: `${t("Items Name")}`,
        dataIndex: "item_name",
        key: "item_name",
      },
      {
        title: `${t("Material Recipt#")}`,
        dataIndex: "mr_no",
        key: "mr_no",
        render: () => <div>{receiptDetails?.mr_no}</div>,
      },
      {
        title: `${t("Quantity")}`,
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: `${t("Total Amount")}`,
        dataIndex: "total_amount",
        key: "total_amount",
      },
      {
        title: `${t("Purchase Order")}`,
        dataIndex: "",
        key: "",
        render: () => <div>{receiptDetails?.po_no}</div>,
      },
      {
        title: `${t("QC Status")}`,
        dataIndex: "qc_status",
        key: "qc_status",
        render: (text, record) => (
          <>
            <div
              className="d-flex"
              style={{
                gap: "0.5rem",
              }}
            >
              {/* <div
                className={`qc-status-text_${
                  qcStatus?.find((ele) => ele?.id == record?.id)?.qc_status
                    ? qcStatus
                        ?.find((ele) => ele?.id == record?.id)
                        ?.qc_status?.toLowerCase()
                    : text
                    ? text?.toLowerCase()
                    : ""
                }`}
              >
                {}
              </div> */}
              <div className="dropdown">
                <Select
                  placeholder="Select"
                  disabled={receiptDetails?.invoice_status === "completed"}
                  options={[
                    { label: "Pass", value: "1" },
                    { label: "Failed", value: "2" },
                    { label: "On Hold", value: "3" },
                  ]}
                  onChange={(e) => {
                    handleMenuClick(e, record);
                  }}
                  value={
                    qcStatus?.find((ele) => ele?.id == record?.id)?.qc_status ??
                    text ??
                    null
                  }
                />
                {/* <Dropdown
                  disabled={receiptDetails?.invoice_status === "completed"}
                  overlay={menu(record)}
                  placement="bottomRight"
                >
                  <img
                    src={DropdownIcon}
                    alt={"logo"}
                    className="action-logo"
                  />
                </Dropdown> */}
              </div>
            </div>
          </>
        ),
      },
    ];
    if (isReturns) {
      let cols = [
        ...baseColumns?.slice(0, 3),
        {
          title: `${t("quantity")}`,
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: `${t("Return Quantity")}`,
          dataIndex: "qc_failed_count",
          key: "qc_failed_count",
          render: (text, record) => (
            <div className={`${iserror[record?.id] ? "count_error" : ""}`}>
              <Input
                disabled={receiptDetails?.invoice_status === "completed"}
                placeholder={t("enter_val")}
                style={{ maxWidth: "10rem" }}
                value={
                  returnCount?.find((ele) => ele?.id === record?.id)
                    ?.qc_failed_count
                }
                onChange={(e) => {
                  let inputValue = e?.target?.value;
                  let item = returnCount?.find((ele) => ele?.id === record?.id);
                  totalSum(
                    record?.quantity,
                    record?.id,
                    inputValue,
                    item?.qc_passed_count,
                    item?.qc_onhold_count
                  );
                  if (isNumber(inputValue) || inputValue === "") {
                    inputValues(
                      e?.target?.value,
                      record?.id,
                      "qc_failed_count"
                    );
                  }
                }}
              />
              {iserror[record?.id] ? (
                <Tooltip
                  title={
                    typeof iserror[record?.id] === "string"
                      ? iserror[record?.id]
                      : `The quantity entered is greater than the available quantity. Please adjust your quantity and try again.`
                  }
                >
                  <InfoCircleOutlined
                    style={{
                      color: "red",
                      position: "relative",
                      left: "-1.5rem",
                    }}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          ),
        },
        {
          title: `${t("Return Reason")}`,
          dataIndex: "return_reason",
          key: "return_reason",
          render: (text, record) => (
            <>
              <div
                className="d-flex"
                style={{
                  gap: "0.5rem",
                }}
              >
                {/* <div className={`qc-status-text_${qcStatus?.find(ele => ele?.id == record?.id)?.qc_status
                  ? qcStatus?.find(ele => ele?.id == record?.id)?.qc_status?.toLowerCase() : text ? text?.toLowerCase() : ""}`}>
                  {qcStatus?.find(ele => ele?.id == record?.id)?.qc_status ?? text ?? ""}
                </div> */}
                <div
                  className={`${
                    iserrorRet[record?.id] ? "count_error" : ""
                  } dropdown w100`}
                >
                  <Select
                    disabled={receiptDetails?.invoice_status === "completed"}
                    style={{ width: "15rem" }}
                    mode="multiple"
                    showArrow
                    // tagRender={tagRender}
                    value={
                      selectedOptions[record?.id]
                        ? selectedOptions[record?.id]
                        : []
                    }
                    options={options}
                    onChange={(_e, data) => {
                      handleReturnMenuClick(data, record);
                    }}
                    placeholder={"select"}
                  />
                  {iserrorRet[record?.id] ? (
                    <Tooltip title={iserrorRet[record?.id]}>
                      <InfoCircleOutlined
                        style={{
                          color: "red",
                          position: "relative",
                          left: "1rem",
                        }}
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          ),
        },
        {
          title: "Action",
          dataIndex: "",
          key: "action",
          render: (_text, record, idx) => {
            return (
              <>
                <button
                  disabled={!hasWriteAccess}
                  onClick={() => {
                    const newTableData = [...tableData];
                    newTableData.splice(idx, 1);
                    setTableData(newTableData);
                    setRecordID((prevData) => {
                      return [...prevData, record?.id];
                    });
                  }}
                >
                  <img src={DeleteIcon} alt={"logo"} className="action-logo" />
                </button>
              </>
            );
          },
        },
      ];
      if ((isCompleted && isReturns) || tableData.length < 2)
        cols.splice(cols.length - 1);
      return cols;
    }

    return baseColumns;
  }, [
    returnCount,
    iserror,
    qcStatus,
    receiptDetails?.invoice_status,
    isCompleted,
    tableData,
    selectedOptions,
  ]);

  useEffect(() => {
    if (receiptDetails?.return_items && returnCount?.length == 0) {
      receiptDetails?.return_items?.forEach((element) => {
        setSelectedOptions((prevData) => {
          return {
            ...prevData,
            [element?.id]: element.return_reason,
          };
        });
        setReturnCount((prevData) => {
          return [
            ...prevData,
            {
              id: element?.id,
              // qc_passed_count: element?.qc_passed_count,
              qc_failed_count: element?.qc_failed_count,
              // qc_onhold_count: element?.qc_onhold_count,
            },
          ];
        });
      });
    }
  }, [receiptDetails?.return_items]);

  // useEffect(() => {
  //   if (isReturns) {
  //     if (tableData) {
  //       let tableData = receiptDetails?.return_items?.filter((ele) => !recordID?.includes(ele?.id))
  //       setTableData(() => {
  //         return [...tableData]
  //       })
  //     }
  //   } else {
  //     if (receiptDetails?.quality_items) {
  //       setTableData([...receiptDetails?.quality_items])
  //     }
  //   }
  // }, [receiptDetails?.return_items, receiptDetails?.quality_items, recordID])

  useEffect(() => {
    if (isReturns) {
      if (receiptDetails && receiptDetails.return_items) {
        const filteredData = receiptDetails.return_items.filter(
          (ele) => !recordID?.includes(ele?.id)
        );
        setTableData([...filteredData]);
      }
    } else {
      if (receiptDetails && receiptDetails.quality_items) {
        setTableData([...receiptDetails.quality_items]);
      }
    }
  }, [isReturns, receiptDetails, recordID]);

  return (
    <div>
      <div className="new-report-detail">
        <div className="flex-between">
          <div className="report-head">
            <div className="title-sec">
              <h3>
                {isReturns ? "Returns" : "QC Check -"}[
                {receiptDetails?.qc_no
                  ? receiptDetails?.qc_no
                  : receiptDetails?.ret_no}
                ]
              </h3>
              <span className={`status ${receiptDetails?.invoice_status}`}>
                {receiptDetails?.invoice_status?.replace(/_/g, " ")}
              </span>
            </div>
            <p className="duration"></p>
            <p>
              <strong>
                Submitted by : {receiptDetails?.userInfo?.name} <br />
                <span className="sm">
                  on{" "}
                  {moment(receiptDetails?.created_at).format(
                    "DD MMM YYYY, HH:mm a"
                  )}
                </span>
                <br />
              </strong>
            </p>
          </div>
          {/* <div className="report-total">
                        <p>
                            <label>{t("Amount")}</label>
                            <span className="lg">
                                <BaseCurrency /> {amountFormat(receiptDetails?.amount_cents)}
                            </span>
                        </p>
                    </div> */}
        </div>
        <div className="tabs-sec">
          <ul>
            {secs.map((ele) => (
              <li
                onClick={() => setTab(ele.toLowerCase())}
                className={`${
                  tab.toLowerCase() === ele.toLowerCase() ? "active" : ""
                }`}
              >
                {ele}
              </li>
            ))}
          </ul>
        </div>
        <div className="selected-tab">
          {tab === "qc check" || tab === "returns" ? (
            <>
              <TableComponent
                // columns={isReturns ? returnsColumns : qcColumns}
                columns={columns}
                // data={isReturns ? receiptDetails?.return_items?.slice(sliceValue?.start, sliceValue?.end) : receiptDetails?.quality_items?.slice(sliceValue?.start, sliceValue?.end) || []}
                data={tableData || []}
                hasCheckbox={false}
                // pagination={{
                //   total: isReturns ? receiptDetails?.return_items?.length : receiptDetails?.quality_items?.length,
                //   current: page,
                //   onChange: (pagee) => {
                //     setSliceValue(() => {
                //       return {
                //         start: (parseInt(pagee) * 10) - 10,
                //         end: (parseInt(pagee) * 10) + 1,
                //       }
                //     })
                //     setPage(pagee);
                //   },
                // }}
              />
            </>
          ) : null}
          {tab === "comments" ? (
            <>
              <div className="addComment mt2 mb1">
                <Input
                  placeholder="Enter Comment"
                  ref={inputComment}
                  value={input}
                  onInput={(e) => setInput(e.target.value.trimStart())}
                />
                <Button
                  disabled={!hasWriteAccess}
                  key="1"
                  className="formButton ml1"
                  onClick={() => handleAddComment(receiptDetails)}
                >
                  {t("send")}
                </Button>
              </div>
              {receiptDetails?.comments?.length ? (
                <>
                  <div className="commentsSection mt2">
                    <Steps
                      direction="vertical"
                      size="small"
                      current={receiptDetails?.comments?.length + 1 || 100}
                    >
                      {receiptDetails?.comments &&
                        receiptDetails?.comments?.map(
                          ({ body, created_at, name, id }) => (
                            <Steps
                              key={id}
                              title={body}
                              description={`on ${moment(created_at).format(
                                "DD MM YYYY, HH:mm a"
                              )} | Commented by: ${name}`}
                              icon={<MessageOutlined />}
                            />
                          )
                        )}
                    </Steps>
                  </div>
                </>
              ) : (
                <NoCommentsComponent />
              )}
            </>
          ) : null}

          {tab === "history" && receiptDetails?.history?.length ? (
            <div className="commentsSection mt2">
              <Steps
                direction="vertical"
                size="small"
                current={receiptDetails?.history?.length + 1 || 100}
              >
                {receiptDetails?.history &&
                  receiptDetails?.history?.map(
                    ({ body, created_at, name, id, curr_status }) => (
                      <Steps
                        key={id}
                        title={body}
                        description={
                          moment(created_at).format("DD MM YYYY, HH:mm a") +
                          " | " +
                          name +
                          " | Action: " +
                          curr_status?.replace(/_/g, " ")
                        }
                        icon={<UserOutlined />}
                      />
                    )
                  )}
              </Steps>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default QCcheckDetails;
