import { ActionTypesFactory } from "../../utils";

export const FetchPaymentLinks = ActionTypesFactory('FetchPaymentLinks', 'FetchPaymentLinks');
export const DeletePaymentLink = ActionTypesFactory('DeletePaymentLink', 'DeletePaymentLink');
export const SaveBulkUploads = ActionTypesFactory('SaveBulkUploads', 'SaveBulkUploads');
export const FetchBulkUploads = ActionTypesFactory('FetchBulkUploads', 'FetchBulkUploads');
export const SaveBulkUploadPaymentLink = ActionTypesFactory('SaveBulkUploadPaymentLink', 'SaveBulkUploadPaymentLink');
export const DownloadBulkUploads = ActionTypesFactory('DownloadBulkUploads', 'DownloadBulkUploads');



export const fetchPaymentLinks = (payload) =>{
    return{
        type : FetchPaymentLinks.REQUEST,
        payload,
    }
}

export const deletePaymentLink = (payload) =>{
    return{
        type : DeletePaymentLink.REQUEST,
        payload,
    }
}

export const saveBulkUploads = (payload) =>{
    return {
        type : SaveBulkUploads.REQUEST,
        payload,
    }
}
export const fetchBulkUploads = (payload) =>{
    return {
        type : FetchBulkUploads.REQUEST,
        payload,
    }
}
export const saveBulkUploadPaymentLink = (payload) =>{
    return{
        type : SaveBulkUploadPaymentLink.REQUEST,
        payload
    }
}
export const downloadBulkUploads = (payload) =>{
    return{
        type : DownloadBulkUploads.REQUEST,
        payload
    }
}

