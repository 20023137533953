import { ActionTypesFactory } from "../../utils";

export const Reports = ActionTypesFactory("Reports", "Reports");
export const UserReport = ActionTypesFactory("Reports", "UserReport");
export const ReportsCount = ActionTypesFactory("ReportsCount", "Reports");
export const ReportsReject = ActionTypesFactory("ReportsReject", "Reports");
export const ForwardApproval = ActionTypesFactory("ForwardApproval", "Reports");
export const TransactionsReject = ActionTypesFactory(
  "TransactionsReject",
  "Reports"
);
export const TransactionsPartialApprove = ActionTypesFactory(
  "TransactionsPartialApprove",
  "Reports"
);
export const ReportsApprove = ActionTypesFactory("ReportsApprove", "Reports");
export const ReportsBulkApproveReject = ActionTypesFactory(
  "ReportsBulkApproveReject",
  "Reports"
);
export const ReportsReimburse = ActionTypesFactory(
  "ReportsReimburse",
  "Reports"
);
export const ReportsExpDetails = ActionTypesFactory(
  "ReportsExpDetails",
  "Reports"
);
export const ReportsExpComments = ActionTypesFactory(
  "ReportsExpComments",
  "Reports"
);
export const ReportsExpAddComments = ActionTypesFactory(
  "ReportsExpAddComments",
  "Reports"
);
export const ReportsComments = ActionTypesFactory("reportsComments", "Reports");
export const ReportsCommentsAdd = ActionTypesFactory(
  "reportsCommentsAdd",
  "Reports"
);
export const ReportsExportExcel = ActionTypesFactory(
  "reportsExportExcel",
  "Reports"
);
export const ReportsHistory = ActionTypesFactory("reportsHistory", "Reports");
export const ReportsAdvance = ActionTypesFactory("reportsAdvance", "Reports");
export const ReportsTrips = ActionTypesFactory("ReportsTrips", "Reports");
export const ReportDetailsPDF = ActionTypesFactory(
  "ReportDetailsPDF",
  "Reports"
);
export const ReportsExpHistory = ActionTypesFactory(
  "reportsExpHistory",
  "Reports"
);

export const UserReports = ActionTypesFactory("UserReports", "Reports");
export const ChangeReports = ActionTypesFactory("ChangeReports","Reports");
export const UserReportSubmit = ActionTypesFactory(
  "UserReportSubmit",
  "Reports"
);
export const UserReportRecall = ActionTypesFactory(
  "UserReportRecall",
  "Reports"
);
export const AddUserReports = ActionTypesFactory("AddUserReports", "Reports");
export const UserReportFields = ActionTypesFactory(
  "UserReportFields",
  "Reports"
);
export const UserReportLinkExp = ActionTypesFactory(
  "UserReportLinkExp",
  "Reports"
);
export const UserReportLinkAdv = ActionTypesFactory(
  "UserReportLinkAdv",
  "Reports"
);

export const reports = (payload) => {
  return {
    type: Reports.REQUEST,
    payload,
  };
};

export const changeReports = (payload) => {
  return {
    type: ChangeReports.REQUEST,
    payload,
  };
};

export const forward_approval = (payload) => {
  return {
    type: ForwardApproval.REQUEST,
    payload,
  };
};

export const userReport = (payload) => {
  return {
    type: UserReport.REQUEST,
    payload,
  };
};


export const reportsReset = (payload) => {
  return {
    type: Reports.RESET,
    payload,
  };
};

export const reportsCount = (payload) => {
  return {
    type: ReportsCount.REQUEST,
    payload,
  };
};
export const userReportsReset = (payload) => {
  return {
    type: UserReports.RESET,
    payload,
  };
};

export const reportsReject = (payload) => {
  return {
    type: ReportsReject.REQUEST,
    payload,
  };
};

export const transactionsReject = (payload) => {
  return {
    type: TransactionsReject.REQUEST,
    payload,
  };
};

export const transactionsPartialApprove = (payload) => {
  return {
    type: TransactionsPartialApprove.REQUEST,
    payload,
  };
};

export const reportsApprove = (payload) => {
  return {
    type: ReportsApprove.REQUEST,
    payload,
  };
};

export const reportsBulkApproveReject = (payload) => {
  return {
    type: ReportsBulkApproveReject.REQUEST,
    payload,
  };
};

export const reportsReimburse = (payload) => {
  return {
    type: ReportsReimburse.REQUEST,
    payload,
  };
};

export const reportsReimburseReset = (payload) => {
  return {
    type: ReportsReimburse.RESET,
    payload,
  };
};

export const reportsApproveReset = (payload) => {
  return {
    type: ReportsApprove.RESET,
    payload,
  };
};

export const reportsRejectReset = (payload) => {
  return {
    type: ReportsReject.RESET,
    payload,
  };
};

export const reportsExpDetails = (payload, otherParams) => {
  return {
    type: ReportsExpDetails.REQUEST,
    payload,
    otherParams,
  };
};

export const reportsExpComments = (payload, otherParams) => {
  return {
    type: ReportsExpComments.REQUEST,
    payload,
    otherParams,
  };
};

export const reportsExpAddComments = (payload, otherParams) => {
  return {
    type: ReportsExpAddComments.REQUEST,
    payload,
    otherParams,
  };
};

export const reportsComments = (payload, otherParams) => {
  return {
    type: ReportsComments.REQUEST,
    payload,
    otherParams,
  };
};

export const reportsCommentsAdd = (payload, otherParams) => {
  return {
    type: ReportsCommentsAdd.REQUEST,
    payload,
    
  };
};

export const reportsExportExcel = (payload) => {
  return {
    type: ReportsExportExcel.REQUEST,
    payload,
  };
};

export const reportsHistory = (payload, otherParams) => {
  return {
    type: ReportsHistory.REQUEST,
    payload,
    otherParams,
  };
};

export const reportsAdvance = (payload, otherParams) => {
  return {
    type: ReportsAdvance.REQUEST,
    payload,
    otherParams,
  };
};

export const reportsTrips = (payload, otherParams) => {
  return {
    type: ReportsTrips.REQUEST,
    payload,
    otherParams,
  };
};

export const reportsExpHistory = (payload, otherParams) => {
  return {
    type: ReportsExpHistory.REQUEST,
    payload,
    otherParams,
  };
};

export const userReports = (payload) => {
  return {
    type: UserReports.REQUEST,
    payload,
  };
};

export const userReportSubmit = (payload) => {
  return {
    type: UserReportSubmit.REQUEST,
    payload,
  };
};

export const userReportRecall = (payload) => {
  return {
    type: UserReportRecall.REQUEST,
    payload,
  };
};

export const addUserReports = (payload) => {
  return {
    type: AddUserReports.REQUEST,
    payload,
  };
};

export const userReportFields = (payload) => {
  return {
    type: UserReportFields.REQUEST,
    payload,
  };
};

export const userReportLinkExp = (payload) => {
  return {
    type: UserReportLinkExp.REQUEST,
    payload,
  };
};

export const userReportLinkAdv = (payload) => {
  return {
    type: UserReportLinkAdv.REQUEST,
    payload,
  };
};

export const userReportLinkAdvReset = (payload) => {
  return {
    type: UserReportLinkAdv.RESET,
    payload,
  };
};

export const reportDetailsPDF = (payload) => {
  return {
    type: ReportDetailsPDF.REQUEST,
    payload,
  };
};
