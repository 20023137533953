import { useState } from "react";
import endpointIntegrations from "./AxiosRootiFiIntegrationApi";

const useIntegration = ({ api, method }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [progress, setProgress] = useState(0);

  const reset = () => {
    setData(null);
    setLoading(false);
    setProgress(0);
  };

  const onCall = async ({ data, headers, params }) => {
    setLoading(true);
    try {
      let result;
      if (method === "get")
        result = await endpointIntegrations[method](api, { headers, params });
      else
        result = await endpointIntegrations[method](api, data, {
          headers,
          params,
          onUploadProgress: (progressEvent) => {
            setProgress((progressEvent.loaded / progressEvent.total) * 100);
          },
        });
      setData(result.data);
      setLoading(false);
      return Promise.resolve(result.data);
    } catch (err) {
      setData(err);
      setLoading(false);
      return Promise.reject(err);
    }
  };

  return {
    loading,
    data,
    progress,
    reset,
    onCall,
  };
};

export default useIntegration;
