import { ReducerFunctions } from '../../utils';
import { ChangePriority, CreatePolicy, Currencies, DeletePolicy, EnablePolicy, GetPolicies, GetPolicy, PoliciesMetadata, SeedData } from './masterdata';

export const seed_data_reducer = (state = {}, action) => {
  switch (action.type) {
    case SeedData.REQUEST:
      return {
        ...state,
        loading: true
      }
    case SeedData.SUCCESS:
      return {
        ...state,
        [action.seed_type]: action.payload,
        loading: false
      }
    case SeedData.FAILED:
      return {
        ...state,
        [action.seed_type]: action.payload,
        loading: false
      }
    default:
      return state;
  }
};

export const currencies_reducer = ReducerFunctions(Currencies, {})
export const create_policy_reducer = ReducerFunctions(CreatePolicy, {})
export const enable_policy_reducer = ReducerFunctions(EnablePolicy, {})
export const delete_policy_reducer = ReducerFunctions(DeletePolicy, {})
export const get_policies_reducer = ReducerFunctions(GetPolicies, {})
export const get_policy_reducer = ReducerFunctions(GetPolicy, {})
export const get_policy_meta_reducer = ReducerFunctions(PoliciesMetadata, {})
export const change_policy_priority_reducer = ReducerFunctions(ChangePriority, {})