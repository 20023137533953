import { OnBoardEnglish } from "../Language/English";
const KYCCommon = () => {
    return (
        <>
            <div className="kyc-common-section">
                <h4 className="main-header">{OnBoardEnglish?.kycCommonMainHeader}</h4>
                <div className="kyc-common-wraper">
                    <h5 className="header-text mb0">{OnBoardEnglish?.kycCommonHeaderOne}</h5>
                    <span className="sub-header-text">{OnBoardEnglish?.kycCommonSubHeadrOne}</span>
                </div>
                <div className="kyc-common-wraper">
                    <h5 className="header-text mb0">{OnBoardEnglish?.kycCommonHeaderTwo}</h5>
                    <span className="sub-header-text">{OnBoardEnglish?.kycCommonSubHeadrTwo}</span>
                </div>
                <div className="kyc-common-wraper">
                    <h5 className="header-text mb0">{OnBoardEnglish?.kycCommonHeaderThree}</h5>
                    <span className="sub-header-text">{OnBoardEnglish?.kycCommonSubHeadrThree}</span>
                </div>
            </div>
        </>
    )
}
export default KYCCommon;