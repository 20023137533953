import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions, sagaFunctionsSettings, sagaFunctionsExpense } from "../../utils";
import {
  Trips,
  TripsComments,
  TripsAddComments,
  TripsHistory,
  TripsApprove,
  TripsReject,
  TripsDelete,
  UserTrips,
  UserTripsSubmit,
  AddUserTrip,
  UpdateUserTrip,
  UserTripFields,
  TripsShow,
  TripsClose,
  TripDetailsPDF,
  UserReportLinkTrip,
  AttachTripFile,
  DownloadTripAttachments,
  RecallTrip
} from "./trips";

function getTrips(req) {
  let obj = {
    module_type: req.payload.module_type,
  }
  if (req.payload.status) {
    obj.status = req.payload.status
    delete req.payload.status
  }
  if (req.payload.view) {
    obj.view = req.payload.view
    delete req.payload.status
  }
  if (req.payload.page_number) {
    obj.page_number = req.payload.page_number
    delete req.payload.page_number
  }
  delete req.payload.view
  delete req.payload.module_type
  let urlParams = new URLSearchParams(obj)
  return sagaFunctionsExpense(Trips, "post", apis.trips + `?${urlParams}`, req.payload)();
}

function downloadTripPDF(req) {
  return sagaFunctions(TripDetailsPDF, "get", apis.export_trip_details, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}
function attachFilesToTrip(req) {
  return sagaFunctions(AttachTripFile, "post", apis.attach_trip_file, req.payload, {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: localStorage.getItem("token"),
  })();
}

function download_trip_attachments(req) {
  return sagaFunctions(DownloadTripAttachments, "get", apis.download_trip_attachments, req.payload)();
}
function tripsClose(req) {
  return sagaFunctionsExpense(TripsClose, "post", apis.tripClose, req.payload, {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: localStorage.getItem("token"),
  })();
}

function getTripsComments(req) {
  return sagaFunctions(
    TripsComments,
    "get",
    req.otherParams?.is_admin ? apis.tripsComments : apis.userTripsComments,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getTripsAddComments(req) {
  return sagaFunctions(
    TripsAddComments,
    "post",
    req.otherParams?.is_admin ? apis.tripsComments : apis.userTripsComments,
    req.payload,
    {},
    true,
    {
      // "Content-Type": "multipart/formdata",
      // Authorization: localStorage.getItem("token"),
    }
  )();
}

function getTripsHistory(req) {
  return sagaFunctions(
    TripsHistory,
    "get",
    req.otherParams?.is_admin ? apis.tripsHistory : apis.userTripsHistory,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getTripsApprove(req) {
  return sagaFunctionsExpense(TripsApprove, "post", apis.tripsApprove, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function getTripsReject(req) {
  return sagaFunctionsExpense(TripsReject, "post", req?.others?.isDelete ? apis.tripsDelete : apis.tripsReject, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function getTripsDelete(req) {
  return sagaFunctionsExpense(
    TripsDelete,
    "post",
    apis.tripsDelete,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getUserTrips(req) {
  return sagaFunctionsExpense(UserTrips, "post", apis.userTrips, req.payload)();
}

function getUserTripsSubmit(req) {
  return sagaFunctionsExpense(
    UserTripsSubmit,
    "post",
    apis.userTripsSubmit,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function tripsShow(req) {
  return sagaFunctionsExpense(
    TripsShow,
    "get",
    apis.tripsShow,
    req.payload,
  )();
}

function getAddUserTrip(req) {
  return sagaFunctionsExpense(AddUserTrip, "post", req.payload.id ? apis.update_trip : apis.userTrips, req.payload)();
}

function updateUserTrip(req) {
  return sagaFunctionsExpense(
    UpdateUserTrip,
    "post",
    apis.update_trip,
    req.payload
  )();
}

function getUserReportLinkTrip(req) {
  return sagaFunctionsExpense(UserReportLinkTrip, "post", apis.userTripLinkReport, req.payload, {

    Authorization: localStorage.getItem("token"),
  })();
}

function recallTrip(req) {
  let isCancel = req.payload.isCancel;
  delete req.payload.isCancel;
  return sagaFunctionsExpense(RecallTrip, "post", isCancel ? apis.userTripCancel : apis.userTripRecall, req.payload)();
}

function getUserTripFields(req) {
  return sagaFunctionsSettings(
    UserTripFields,
    "get",
    apis.userTripFields,
    req.payload,
    {
      // "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

export function* tripsWatcher() {
  yield takeLatest(Trips.REQUEST, getTrips);
  yield takeLatest(DownloadTripAttachments.REQUEST, download_trip_attachments);
  yield takeLatest(AttachTripFile.REQUEST, attachFilesToTrip);
  yield takeLatest(TripsComments.REQUEST, getTripsComments);
  yield takeLatest(TripsAddComments.REQUEST, getTripsAddComments);
  yield takeLatest(TripsHistory.REQUEST, getTripsHistory);
  yield takeLatest(TripsApprove.REQUEST, getTripsApprove);
  yield takeLatest(TripsReject.REQUEST, getTripsReject);
  yield takeLatest(TripsDelete.REQUEST, getTripsDelete);
  
  yield takeLatest(UserTrips.REQUEST, getUserTrips);
  yield takeLatest(UserTripsSubmit.REQUEST, getUserTripsSubmit);
  yield takeLatest(AddUserTrip.REQUEST, getAddUserTrip);
  yield takeLatest(UpdateUserTrip.REQUEST, updateUserTrip);
  yield takeLatest(UserTripFields.REQUEST, getUserTripFields);
  yield takeLatest(UserReportLinkTrip.REQUEST, getUserReportLinkTrip);
  yield takeLatest(TripsShow.REQUEST, tripsShow);
  yield takeLatest(TripsClose.REQUEST, tripsClose);
  yield takeLatest(RecallTrip.REQUEST, recallTrip);
  yield takeLatest(TripDetailsPDF.REQUEST, downloadTripPDF);
}
