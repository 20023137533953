import {
  BellFilled,
  UserSwitchOutlined
} from '@ant-design/icons';
import { Badge, Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Select, Typography, message } from "antd";
import { useFormik } from "formik";
import jwt_decode from 'jwt-decode';
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from "yup";
import { ChangeLanguage, ChangePassword, changePassword, logout, notifications } from "../../actions/auth";
import { SwitchBrach, SwitchBrachRuby, getAllBranchHeader, switchBrach, switchBrachRuby } from "../../actions/companySettings";
import { customer_cart } from '../../actions/orders/orders';
import arrow_icon from "../../assets/icons/arrow_icon.svg";
import checkIcon from "../../assets/icons/check_icon.svg";
import icon from "../../assets/icons/org_icon.svg";
import cart from "../../assets/icons/shopping-cart.png";
import { DOWN } from "../../config/Images";
import NotificationsUI from "../Common/NotificationsUI";
import PasswordCheck from "../Common/PasswordCheck";
import PageHeader from '../Sales/PageHeader';
import "./header.css";

const { Text, Title } = Typography;

const ValidationSchema = yup.object().shape({
  old_password: yup.string().trim().required("Current Password is required").min(8),
  new_password: yup.string().trim().required("Password is required").min(8).notOneOf([yup.ref('old_password'), null], 'Current Password and Password must not match'),
  password_confirmation: yup.string().trim().required("Password Confirmation is required").min(8).oneOf([yup.ref('new_password'), null], 'Passwords must match'),
});

message.config({
  maxCount: 3,
});

const Header = ({ isOrganisation, isPortal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory()
  const is_admin = useSelector((state) => state?.auth?.localVariables?.is_admin);
  const location = useLocation()
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const language = useSelector((state) => state.auth.localVariables?.language);
  const [isNoficationVisible, setIsNoficationVisible] = React.useState(false);
  const [passwordCheck, setPasswordCheck] = React.useState(true);
  const [branchOpen, setBranchOpen] = React.useState(false);
  //const [languageSelect, setLanguageSelect] = useState("en");

  // useEffect(() => {
  //   if(localStorage.getItem("language")) {
  //     setLanguageSelect(localStorage.getItem("language"));
  //   } else {
  //     setLanguageSelect(language);
  //   }

  // }, [language,localStorage.getItem("language")]);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      password_confirmation: ""
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (vals) => {
      console.log("header change password", vals)
      dispatch(changePassword(vals));

    }
  });
  const changePasswordResponse = useSelector((state) => state.auth.changePassword || {});
  const page_headers = useSelector((state) => state?.auth?.localVariables?.titles);

  const cart_count = useSelector((state) => state?.orders?.cart?.data?.line_items?.length || 0);


  const unseen_count = useSelector(state => state?.auth?.notifications?.data?.data?.unseen_notifications_count)
  const getAllBranchdata = useSelector((state) => state?.companySetting?.getAllBranchHeader)
  const switchBrachToken = useSelector((state) => state?.companySetting?.switchBrach?.data)
  const switchBrachRubyToken = useSelector((state) => state?.companySetting?.switchBrachRuby)
  const token = localStorage.getItem("token")
  const isCustomer = localStorage?.getItem("isCustomer") === "true"

  React.useEffect(() => {

    if (switchBrachToken?.data) {
      const payApiTokenDecoded = jwt_decode(switchBrachToken?.data);
      localStorage.setItem('tokenPayApi', switchBrachToken?.data);
      localStorage.setItem('user_id_payApi', payApiTokenDecoded.user_id);
      localStorage.setItem('company_name', payApiTokenDecoded?.company_name)
      localStorage.setItem("base_currency", JSON.stringify(payApiTokenDecoded?.base_currency));
      localStorage.setItem("menu", JSON.stringify(switchBrachToken?.menu ? switchBrachToken?.menu : {}));
    }
    if (switchBrachRubyToken?.data?.auth_token) {
      localStorage.setItem("token", switchBrachRubyToken?.data?.auth_token);
      localStorage.setItem("name", switchBrachRubyToken?.data?.name);
      localStorage.setItem("role", switchBrachRubyToken?.data?.role);
      localStorage.setItem("is_admin", switchBrachRubyToken?.data?.is_admin);
      localStorage.setItem("user", switchBrachRubyToken?.data?.user);
      localStorage.setItem("employee_id", switchBrachRubyToken?.data?.employee_id);
      localStorage.setItem("is_super_admin", switchBrachRubyToken?.data?.is_super_admin);
      localStorage.setItem("is_kyb_completed", switchBrachRubyToken?.data?.is_kyb_completed);
    }
    if (switchBrachToken?.data && switchBrachRubyToken?.data?.auth_token) {
      history.push("/dashboard")
      localStorage.setItem("activeMenu", "/dashboard");
      localStorage.setItem("activeSubMenu", "");
      localStorage.setItem("showChildren", false);
      localStorage.setItem("childrenMenu", "");
      window.location.reload();
      dispatch({ type: SwitchBrach.RESET })
      dispatch({ type: SwitchBrachRuby.RESET })
    }

  }, [switchBrachToken?.data, switchBrachRubyToken?.data]);

  React.useEffect(() => {
    if (token && isCustomer)
      dispatch(customer_cart({ company_id: localStorage?.getItem("company_id") }));
  }, [token, isCustomer])

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      changePasswordResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch({
        type: ChangePassword.RESET
      })
      formik.resetForm()
    } else if (status === false) {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: ChangePassword.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordResponse]);
  React.useEffect(() => {
    if (!localStorage.getItem("isCustomer") && !localStorage?.getItem("isVendor")) {
      dispatch(getAllBranchHeader());
      dispatch(notifications());
    }
  }, [])

  const handleCancel = () => {
    setIsModalVisible(false);
    setBranchOpen(false)
    formik.resetForm()
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        localStorage.getItem("isCustomer") || localStorage.getItem("isVendor") ? (
          <Menu.Item key="5">Change Password</Menu.Item>
        ) : (null)
      }
      <Menu.Item key="1">Logout</Menu.Item>
      {/* <Menu.Item key="2">Download Receipts</Menu.Item> */}
      {/* <Menu.Item key="3">Export to Excel</Menu.Item> */}
      {/* <Menu.Item key="4">Print</Menu.Item>  */}
    </Menu>
  );
  function handleMenuClick(e) {
    switch (e.key) {
      case "1": {
        localStorage.clear()
        dispatch(logout());
        break;
      }
      case "5": {
        setIsModalVisible(true)
        break;
      }
      default:
    }
  }


  const renderChangePassword = () => {
    return (
      <div className="psd_content">
        <Title level={3} className="mt1">
          {t('set_ur_pwd')}
        </Title>
        <Text>
          {t('please_create_new_pwd')}
        </Text>
        <br />
        <br />
        <Form onFinish={formik.handleSubmit} className="mt2" layout="vertical">
          <Form.Item
            label={t('current_pwd')}
            placeholder={t('enter_current_pwd')}
            help={formik.touched.old_password && formik.errors.old_password}
          >
            <Input.Password placeholder="Enter password" value={formik.values.old_password} onChange={(e) => {
              formik.setFieldValue("old_password", e.target.value?.trim())
            }} name="old_password" />
          </Form.Item>
          <Form.Item
            label={t('new_pwd')}
            placeholder={t('enter_new_pwd')}
            help={formik.touched.new_password && formik.errors.new_password}
          >
            <Input.Password placeholder={t('enter_pwd')} value={formik.values.new_password} onChange={(e) => {
              formik.setFieldValue("new_password", e.target.value?.trim())
            }} name="new_password" />
            <PasswordCheck password={formik.values.new_password} setPasswordCheck={setPasswordCheck} />
          </Form.Item>
          <Form.Item
            label={t('confirm_new_pwd')}
            placeholder={t('enter_new_pwd')}
            help={formik.touched.password_confirmation && formik.errors.password_confirmation}
          >
            <Input.Password placeholder={t('enter_pwd')} value={formik.values.password_confirmation} onChange={(e) => {
              formik.setFieldValue("password_confirmation", e.target.value?.trim())
            }} name="password_confirmation" />
          </Form.Item>

          <Button htmlType="submit" className="primaryButton mt1" disabled={passwordCheck || changePasswordResponse?.loading}>
            Change Password
          </Button>
          <Button
            className="cancelButton ml1 mt1"
            onClick={() => handleCancel()}
          >
            {t('cancel')}
          </Button>

        </Form>
      </div>
    );
  };

  return (
    <div className={`header-div mb1 ${isOrganisation || isPortal ? "width80" : null}`}>
      {isModalVisible && <Modal
        title="Change Password"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className="center-aligned-modal"
      >
        {renderChangePassword()}
      </Modal>}

      <Modal
        title="My Organization"
        visible={branchOpen}
        onCancel={handleCancel}
        footer={[]}
        className="right-alinged-modal organisation_modal"
      >
        <>
          <div className="branch_list">
            {getAllBranchdata?.data?.data?.map((ele) => {
              return (
                <div className={`branch_list_div ${localStorage?.getItem("switchBranch") === ele?.company_id ? "branch_active_bg" : null}`}>
                  <div className="branch_list_select flex-between" onClick={() => {
                    dispatch(switchBrach({ branch_id: ele?.company_id, user_id: ele?.user_id }))
                    dispatch(switchBrachRuby({ company_id: ele?.company_id, user_id: ele?.user_id }))
                    localStorage.setItem("switchBranch", ele?.company_id)
                    localStorage.setItem('company_id', ele?.company_id);
                    localStorage.setItem("user_id", ele?.user_id);
                    localStorage.setItem("parentID", ele?.parent_id)
                  }}>
                    <div className="branch_name_container d-flex-center">
                      <div className="branch_logo">
                        <img src={ele?.logo?.url ? ele?.logo?.url : icon} alt="Branch logo" />
                      </div>
                      <div className="branch_name d-flex flex-col">
                        <h4 className="mb0">{ele?.name}</h4>
                        <span className='organisation_create'>Organization created on {moment(ele?.created_at).format("D MMMM YYYY")}</span>
                        <span className='organisation_id'>Organization ID: {ele?.series}</span>
                      </div>
                    </div>
                    {
                      localStorage?.getItem("switchBranch") === ele?.company_id ? (<div className="check_mark">
                        <img src={checkIcon} alt="select icon" />
                      </div>) : null
                    }
                  </div>
                </div>
              )
            })}
          </div>
        </>
      </Modal>
      <Row>
        <Col span={localStorage.getItem("is_admin") === "true" ? 12 : 12} className="header-title">
          <PageHeader title={page_headers?.title} description={page_headers?.description} />
        </Col>
        <Col span={localStorage.getItem("is_admin") == "true" ? 12 : 12} className="header-right">
          {
            localStorage?.getItem("isCustomer") || localStorage?.getItem("isVendor") || location?.pathname === "/portal" ? null :
              !isOrganisation ? (<div className="parent_login" onClick={() => {
                setBranchOpen(true)
              }}>
                <h4 className="branch_name" title={`${getAllBranchdata?.data?.data?.find(ele => ele?.company_id === localStorage?.getItem("switchBranch"))?.name}`}>{getAllBranchdata?.data?.data?.find(ele => ele?.company_id === localStorage?.getItem("switchBranch"))?.name}</h4>
                <div className="arrow_icon">
                  <img src={arrow_icon} alt="arrow icon" />
                </div>
              </div>) : null
          }

          {/* <Select
            className="lang"
            onChange={(val) => {
              dispatch({
                type: ChangeLanguage,
                payload: val
              })
            }}
            value={language}
            options={[{
              label: "English",
              value: "en"
            }, {
              label: "عربي",
              value: "ar"
            }]}
          /> */}
          {
            (localStorage.getItem("isCompany") || localStorage.getItem("isCustomer") || localStorage.getItem("isVendor")) && localStorage?.getItem("portal_access")?.split(",")?.length > 1 ?
              <div className='switch-portal' onClick={() => {
                history.push("/portal")
                localStorage.removeItem("isCustomer");
                localStorage.removeItem("isVendor");
                localStorage.removeItem("isCompany");
              }} title='Swetch to Portal'>
                <h4 className='d-flex-center'><UserSwitchOutlined style={{ width: "2rem" }} /></h4>
              </div> : (null)
          }
          {!isOrganisation && <div className="circle" onClick={() => {
            setIsNoficationVisible(bool => !bool)
          }}>
            <Badge className="cursor" count={unseen_count ?? 0} overflowCount={99}>
              <BellFilled
                style={{ color: "#122549", fontSize: "15px" }}
              />
            </Badge>
          </div>}
          {localStorage.getItem("isCustomer") === "true" && <div className="circle" onClick={() => {
            history.push("/cart")
          }}>
            <Badge className="cursor cart" count={cart_count} overflowCount={99}>
              <img src={cart} width={15} />
            </Badge>
          </div>}
          {isNoficationVisible && !isOrganisation &&
            <NotificationsUI isVisible={isNoficationVisible} onClose={() => {
              setIsNoficationVisible(false)
            }} />}
          {/* {isNoficationVisible &&
            <Notifications onClose={() => {
              setIsNoficationVisible(false)
            }} />} */}

          <div className="user-drop">
            <Dropdown
              overlay={menu}
              placement="bottomLeft"
            >
              <div className="d-flex align-items-center cursor">
                <div className="circle">
                  <span className="first-letter">
                    {localStorage.getItem("is_admin") === "true"
                      ? "A"
                      : "E"}
                  </span>
                </div>
                <span className="user-name"> {localStorage.getItem("name")}
                </span>
                <img src={DOWN} alt="" className="mlHalf down" />
              </div>
            </Dropdown>
          </div>
        </Col>
      </Row >
    </div >
  );
};

export default Header;
