import { ActionTypesFactory } from '../../utils';

export const Payments = ActionTypesFactory('Payments', 'Payments');

export const GET_PAYMENT_DETAILS_BY_ID = ActionTypesFactory('Payments', 'GET_PAYMENT_DETAILS_BY_ID');
export const CREATE_PAYMENT_REQUEST = ActionTypesFactory('Payments', 'CREATE_PAYMENT_REQUEST');
export const SUBMIT_PAYMENT = ActionTypesFactory('Payments', 'SUBMIT_PAYMENT');
export const REJECT_PAYMENT = ActionTypesFactory('Payments', 'REJECT_PAYMENT');
export const APPROVE_PAYMENT = ActionTypesFactory('Payments', 'APPROVE_PAYMENT');
export const RECALL_PAYMENT = ActionTypesFactory('Payments', 'RECALL_PAYMENT');
export const EDIT_PAYMENT = ActionTypesFactory('Payments', 'EDIT_PAYMENT');
export const UPDATE_PAYMENT = ActionTypesFactory('Payments', 'UPDATE_PAYMENT');
export const DELETE_PAYMENT = ActionTypesFactory('Payments', 'DELETE_PAYMENT');

export const get_all_payments = (payload) => {
  return {
    type: Payments.REQUEST,
    payload,
  };
};


export const getPaymentDetailsByID = (payload) => {
  return {
    type: GET_PAYMENT_DETAILS_BY_ID.REQUEST,
    payload,
  };
};

export const createPaymentRequest = (payload) => {
  return {
    type: CREATE_PAYMENT_REQUEST.REQUEST,
    payload,
  };
};

export const submitPayment = (payload) => {
  return {
    type: SUBMIT_PAYMENT.REQUEST,
    payload,
  };
};

export const rejectPayment = (payload) => {
  return {
    type: REJECT_PAYMENT.REQUEST,
    payload,
  };
};

export const approvePayment = (payload) => {
  return {
    type: APPROVE_PAYMENT.REQUEST,
    payload,
  };
};

export const recallPayment = (payload) => {
  return {
    type: RECALL_PAYMENT.REQUEST,
    payload,
  };
}
export const editPayment = (payload) => {
  return {
    type: EDIT_PAYMENT.REQUEST,
    payload,
  };
};
export const updatePayment = (payload) => {
  return {
    type: UPDATE_PAYMENT.REQUEST,
    payload,
  };
};

export const deletePayment = (payload) => {
  return {
    type: DELETE_PAYMENT.REQUEST,
    payload,
  };
}