import React from 'react';

const CardSVG = ({ scale, opacity, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.188" height="21.564" viewBox="0 0 23.188 21.564" style={{
      scale: scale ? scale : 1,
      zoom: scale ? scale : 1
    }}>
      <path id="Path_18973" data-name="Path 18973" d="M23.188,7.635A4.147,4.147,0,0,0,19.052,3.5H4.135a4.134,4.134,0,0,0-.067,8.267V23.209a1.86,1.86,0,0,0,1.855,1.855h6.714V5.819h2.319V25.064h2.309a1.86,1.86,0,0,0,1.855-1.855V11.767A4.146,4.146,0,0,0,23.188,7.635Zm-20.869,0A1.817,1.817,0,0,1,4.068,5.822V9.448A1.817,1.817,0,0,1,2.319,7.635Zm16.8,1.813V5.822a1.814,1.814,0,0,1,0,3.626Z" transform="translate(0 -3.5)" fill={fill || "#fff"} opacity={opacity || "0.497"} />
    </svg>
  );
};

export default CardSVG;