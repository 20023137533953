import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import close from "../../assets/icons/close.png";

import { Table, message } from "antd";
import { amountFormat } from "../../utils";
import { CustomerAddToCart, CustomerRemoveCartItem, CustomerUpdateItemQuantity, customer_cart, customer_remove_cart_item, customer_update_item_quantity } from "../../actions/orders/orders";
import useHandleResponse from "./useHandleResponse";
import BaseCurrency from "../Common/BaseCurrency";


export const Quantity = ({
  data,
  isEdit,
  isEditParent,
  setIsEdit
}) => {
  const [clicked, setClicked] = useState({});
  const [val, setVal] = useState(data.Quantity ? data.Quantity : "");
  const updateCart = useSelector((state) => state?.orders?.update_cart);
  const cart = useSelector((state) => state?.orders?.cart?.data);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (data?.Quantity) {
      setVal(data?.Quantity);
    }
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (!updateCart?.loading) {
      setClicked({});
    }
  }, [updateCart]);

  useEffect(() => {
    if (!isEdit) {
      setVal(data.Quantity);
    }
    // eslint-disable-next-line
  }, [isEdit]);
  return (
    <div className="quantity">
      {isEdit ? (
        <>
          <input
            className="action-td"
            value={val}
            onChange={(e) => {
              if (Number.isNaN(Number(e.target.value?.trimStart()))) {
                return;
              }
              if (parseInt(e.target.value) || e.target.value === "")
                setVal(e.target.value ? parseInt(e.target.value) : e.target.value)
            }}
          />
          <span
            className="link"
            onClick={() => {
              if (!val) {
                return
              }
              let obj = {
                id: data.Id,
                quantity: val,
              };
              dispatch(customer_update_item_quantity(obj));
              setIsEdit(false);
              setClicked({ [data.id]: true });
            }}
          >
            Update
          </span>
          <img
            src={close}
            width={14}
            onClick={() => setIsEdit(false)}
            alt="edit"
          />
        </>
      ) : (
        <span
          className={`${updateCart?.loading && clicked[data.id] ? "loading" : ""
            }`}
        >
          <span>{data.Quantity}</span>
          {isEditParent ? (
            <span className="link" onClick={() => setIsEdit(true)}>
              Edit
            </span>
          ) : null}
        </span>
      )}
    </div>
  );
};

const Products = ({ step, isEdit: isEditParent }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state?.orders?.cart?.data);
  const [isEdit, setIsEdit] = useState(false);
  const addToCart = useSelector((state) => state?.orders?.add_to_cart);
  const updateCart = useSelector((state) => state?.orders?.update_item_quantity);
  const removeItemFromCart = useSelector((state) => state?.orders?.remove_cart_item);
  // const [tableScroll] = useState({ x: 1500 });

  useEffect(() => {
    dispatch(customer_cart({ company_id: localStorage?.getItem("company_id") }));
    // eslint-disable-next-line
  }, []);

  const successCall = () => {
    dispatch(customer_cart({ company_id: localStorage?.getItem("company_id") }));
  }

  useHandleResponse(
    CustomerAddToCart,
    'Product added to cart.',
    'Add to cart failed.',
    addToCart,
    successCall
  );

  useHandleResponse(
    CustomerUpdateItemQuantity,
    'Quantity updated successfully.',
    'Failed to update quantity.',
    updateCart,
    successCall
  );

  useHandleResponse(
    CustomerRemoveCartItem,
    'Item removed successfully.',
    'Failed to remove Item.',
    removeItemFromCart,
    successCall
  );
  const columns = [
    {
      title: "Company Name",
      dataIndex: "Company Name",
    },
    {
      title: "SKU",
      dataIndex: "Sku",
    },

    {
      title: "Unit Price",
      dataIndex: "Unit Price",
      // width: 10,
      render: (_, row) => <><BaseCurrency symbol_code={row?.currency_info?.iso_code} /> {`${amountFormat(row?.["Unit Price"])}`}</>,
    },
    {
      title: "Quantity Ordered",
      dataIndex: "Quantity",
      // width: 11,
      fixed: "right",
      render: (_, row) => <Quantity data={row} isEditParent={isEditParent} setIsEdit={(bool) => setIsEdit({ [row.id]: bool })} isEdit={isEdit[row.id]} />,
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      // width: 11,
      fixed: "right",
      render: (_, row) => <p className="flex-start" style={{ color: "#262626" }}>
        <><BaseCurrency symbol_code={row?.currency_info?.iso_code} />{`${amountFormat(row?.Amount)}`}</>
        <img
          className="cancel"
          src={close}
          alt={"close"}
          width={14}
          style={{ marginLeft: "0.5rem" }}
          onClick={() => {
            dispatch(
              customer_remove_cart_item({
                id: row.Id
              })
            );
          }}
        />
      </p>,
    },
  ];
  return (
    <>
      {step === 1 && (
        <div className="header">
          {/* <p className="early-bird">
            <img src={Tick} alt="tick" width={20} />
            <b>Early bird</b>
            <span>
              <strong> Congratulations,</strong> with this order eligible for
              the early bird program.
            </span>
          </p> */}
        </div>
      )}
      <div className="product-card">
        <Table
          columns={columns}
          dataSource={cart?.line_items ? cart?.line_items : []}
          total_length={cart?.line_items?.length || 0}
          pagination={false}
          locale={{
            emptyText: "Your cart is empty!"
          }}
        />
      </div>
      <div className="total mt2">
        <span className="note">
          Total Quantity: <strong>{cart?.total_quantity} pcs</strong>
        </span>
        <span className="note">
          Total Amount: <strong><BaseCurrency symbol_code={cart?.currency_info?.iso_code} /> {cart?.total_price ? amountFormat(cart?.total_price) : 0}</strong>
        </span>
      </div>
    </>
  );
};

export default Products;
