import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NodataComponent from "../Common/NodataComponent";
import TableWrap from "../Common/Table/Table";
import { useTranslation } from "react-i18next";
import moment from "moment";

const TripTypeTable = (props) => {
  const tripsData = useSelector(
    (state) => state?.trips?.tripsShow?.data?.data || {}
  );
  const [flightData, setFlightData] = useState([]);
  const { t } = useTranslation();
  const {
    flight_reservations = [],
    car_rentals = [],
    hotel_reservations = [],
    other_bookings = [],
    meal_preference = "",
  } = props.tripsData;

  const columns = [
    {
      title: t('ex_id'),
      dataIndex: "ex_id",
      key: "ex_id",
    },
    {
      title: t('date_time'),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t('merchant'),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t('category'),
      dataIndex: "from_date",
      key: "from_date",
    },
    {
      title: t('payment_mode'),
      dataIndex: "to_date",
      key: "to_date",
    },
    {
      title: t('bill'),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t('Amount'),
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      dataIndex: "status",
      key: "status",
    },
  ];

  const flightColumns = [
    {
      title: t("departure_date"),
      dataIndex: "departure_date",
      key: "departure_date",
      render: (text) => (
        // <div>{moment(text).format("DD MMM YYYY HH:mm:ss")}</div>
        <>
          <div>{moment(text).utc().format("DD MMM YYYY, hh:mm a")}</div>
        </>
      ),
    },
    {
      title: t("From"),
      dataIndex: "from_city",
      key: "from_city",
    },
    {
      title: t("too"),
      dataIndex: "to_city",
      key: "to_city",
    },
    {
      title: t("desc"),
      dataIndex: "flight_reservation_description",
      key: "flight_reservation_description",
    },
    {
      title: t("meal_preference"),
      dataIndex: "meal_preference",
      key: "meal_preference",
    },
    {
      title: t("preference_seat"),
      dataIndex: "seat_preference",
      key: "seat_preference",
    },
  ];

  const hotelColumns = [
    {
      title: t("hotel_name"),
      dataIndex: "hotel_name",
      key: "hotel_name",
    },
    {
      title: t("location"),
      dataIndex: "location",
      key: "location",
    },
    {
      title: t("From"),
      dataIndex: "duration_from",
      key: "duration_from",
      render: (text) => (
        <div>{moment(text).utc().format("DD MMM YYYY, hh:mm a")}</div>
      ),
    },
    {
      title: t("To"),
      dataIndex: "duration_to",
      key: "duration_to",
      render: (text) => (
        <div>{moment(text).utc().format("DD MMM YYYY, hh:mm a")}</div>
      ),
    },
    {
      title: t("desc"),
      dataIndex: "description",
      key: "description",
    },
  ];

  const carColumns = [
    {
      title: t("cab_type"),
      dataIndex: "cab_type",
      key: "cab_type",
    },
    {
      title: t("departure_date"),
      dataIndex: "duration_from",
      key: "duration_from",
      render: (text) => (
        <div>{moment(text).utc().format("DD MMM YYYY, hh:mm a")}</div>
      ),
    },
    {
      title: t("arrival_date"),
      dataIndex: "duration_to",
      key: "duration_to",
      render: (text) => (
        <div>{moment(text).utc().format("DD MMM YYYY, hh:mm a")}</div>
      ),
    },
    {
      title: t("pick_up"),
      dataIndex: "city",
      key: "city",
    },
    {
      title: t("desc"),
      dataIndex: "description",
      key: "description",
    },
  ];

  const othersColumns = [
    {
      title: t("departure_date"),
      dataIndex: "departure_date",
      key: "departure_date",
      render: (text) => (
        <div>{moment(text).utc().format("DD MMM YYYY, hh:mm a")}</div>
      ),
    },
    {
      title: t("travel_mode"),
      dataIndex: "travel_mode",
      key: "travel_mode",
    },
    {
      title: t("From"),
      dataIndex: "from_city",
      key: "from_city",
    },
    {
      title: t("To"),
      dataIndex: "to_city",
      key: "to_city",
    },
    {
      title: t("desc"),
      dataIndex: "description",
      key: "description",
    },
  ];

  useEffect(() => {
    // if (flight_reservations) {
    //   let arr = [];
    //   let obj = { ...flight_reservations };
    //   delete obj.trips;
    //   flight_reservations?.forEach((ele) => {
    //     arr.push({
    //       ...ele,
    //       ...obj,
    //       flight_reservation_description:
    //         tripsData.flight_reservation_description,
    //     });
    //   });
    //   setFlightData(arr);
    // }
    const updatedFlightReservations = flight_reservations.map(
      (reservation) => ({
        ...reservation,
        flight_reservation_description:
          tripsData.flight_reservation_description,
        meal_preference: tripsData.meal_preference,
        seat_preference: tripsData.seat_preference,
      })
    );
    setFlightData(updatedFlightReservations);
  }, [flight_reservations]);

  const getColumns = (types) => {
    switch (types) {
      case "trip":
        return columns;
      case "flight":
        return flightColumns;
      case "hotel":
        return hotelColumns;
      case "car":
        return carColumns;
      case "others":
        return othersColumns;
      default:
      // code block
    }
  };

  const getData = (types) => {
    switch (types) {
      case "trip":
        return [];
      case "flight":
        return flightData;
      case "hotel":
        return hotel_reservations;
      case "car":
        return car_rentals;
      case "others":
        return other_bookings;
      default:
        return [];
    }
  };

  console.log({ flightData: flightData, tripsData: tripsData }, "flightDetails===>");

  return (
    <div className="expenses-table">
      <TableWrap
        columns={getColumns(props?.type)}
        data={getData(props?.type)}
        pagination={false}
        renderEmpty={() => <NodataComponent noBtns={true} />}
      />
    </div>
  );
};

export default TripTypeTable;
