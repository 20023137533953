import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";

const LoanCalculator = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Loan Calculator",
        description: "",
      })
    );
  }, []);
  return <div>LoanCalculator</div>;
};

export default LoanCalculator;
