import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReceiptsFormFields from "./ReceiptsFormFields";
import { useDispatch, useSelector } from "react-redux";
import {
  ReceiptsCreate,
  receiptsCreate,
  vendorsByName,
} from "../../../actions/purchases";
import endpointSettingsApi from "../../../config/AxiosSettingsApi";
import { apis } from "../../../config/APIs";
import { Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import usePayAxios from "../../../config/useAxios";
import { SideMenu, headerTitles } from "../../../actions/masterdata/masterdata";
import { getMerchantsField } from "../../../actions/companySettings";
import { showFile, uploadFile } from "../../../utils";
import useForgoCardsAxios from "../../../config/useForgoCardsAxios";
const ReceiptsForm = ({ isDelivery, isMaterial }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [exchange, setExchange] = useState({});
  const [hasExchange, setHasExchange] = useState(false);
  const [noExError, setNoExError] = useState("");
  const [cmp_cur_id, setCmpCurId] = useState("");
  const [listFiles, setListFiles] = useState([]);
  const [removedItemsData, setRemovedItemsData] = useState([]);
  const [loadingRef, setLoadingRef] = useState(false);
  const vendorsByNameData = useSelector(
    (state) => state.purchases.vendorsByName || {}
  );
  const receiptsCreateData = useSelector(
    (state) => state?.purchases?.receiptsCreate?.data
  );
  const warehouseData = useSelector(
    (state) => state.companySetting.getMerchantsField || {}
  );
  const {
    onCall: prefix,
    data: prefixData,
    reset: prefixReset,
  } = usePayAxios({
    api: isMaterial ? apis.receiptsPrefixAPI : apis.challanPrefixAPI,
    method: "post",
  });
  const { onCall: customFields, data: custom_fields_values } =
    useForgoCardsAxios({
      api: apis.customFieldValues,
      method: "get",
      payApi: "settings",
    });
  const { onCall: bill_series_check } = usePayAxios({
    api: apis.billSeriesCheck,
    method: "post",
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, submitCount },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {},
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    if (isMaterial) {
      data.vendor_name = vendorsByNameData.data?.data?.find(
        (ele) => ele?.id === data?.beneficiary_id
      )?.beneficiary_name;
      data.currency = vendorsByNameData.data?.data?.find(
        (ele) => ele?.id === data?.beneficiary_id
      )?.currency_info?.iso_code;
      data.company_currency_id = vendorsByNameData.data?.data?.find(
        (ele) => ele?.id === data?.beneficiary_id
      )?.company_info?.id;
      delete data.items;
      delete data.sub_total_amount;
    } else if (isDelivery) {
      data.customer_name = vendorsByNameData.data?.data?.find(
        (ele) => ele?.id === data?.beneficiary_id
      )?.beneficiary_name;
      data.currency = vendorsByNameData.data?.data?.find(
        (ele) => ele?.id === data?.beneficiary_id
      )?.currency_info?.iso_code;
      data.company_currency_id = vendorsByNameData.data?.data?.find(
        (ele) => ele?.id === data?.beneficiary_id
      )?.company_info?.id;
      data.total_tax_amount = parseFloat(data?.total_tax_amount);
      data.total_amount = parseFloat(data?.total_amount);
      data.total_discount = parseFloat(data?.total_discount);
      delete data.items;
      delete data.sub_total_amount;
    }
    if (isMaterial) {
      if (location?.state?.record?.id) {
        data.id = location?.state?.record?.id;
        data.company_id = location?.state?.record?.company_id;
        data.material_receipt_items =
          data?.material_receipt_items?.concat(removedItemsData);
      }
      dispatch(
        receiptsCreate({
          ...data,
          params: {
            module_type: "MATERIAL_RECEIPT",
          },
        })
      );
    } else if (isDelivery) {
      if (location?.state?.record?.id) {
        data.id = location?.state?.record?.id;
        data.company_id = location?.state?.record?.company_id;
        data.delivery_challan_items =
          data?.delivery_challan_items?.concat(removedItemsData);
      }
      dispatch(
        receiptsCreate({
          ...data,
          params: {
            module_type: "DELIVERY_CHALLAN",
          },
        })
      );
    }
  };
  useEffect(() => {
    if (isMaterial) {
      dispatch(vendorsByName({ type: "vendor", status: "active" }));
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/purchases",
          secondNav: "/material",
          childrenMenu: "/receipt",
          showChildren: true,
        },
      });
    } else if (isDelivery) {
      dispatch(vendorsByName({ type: "customer", status: "active" }));
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/sales",
          secondNav: "/delivery",
          childrenMenu: "/receipt",
          showChildren: true,
        },
      });
    }
    prefix({
      params: {
        module_type: isMaterial ? "MATERIAL_RECEIPT" : "DELIVERY_CHALLAN",
      },
    });

    dispatch(getMerchantsField({ type: "warehouse" }));

    let title = "";
    let description = "";
    let params = {};
    if (isMaterial) {
      title = t("material_receipt");
      description = t("material_rec_desc");
      params = {
        module_type: "MATERIAL_RECEIPT",
      };
    } else if (isDelivery) {
      title = t("delivery_challan");
      description = "";
      params = {
        module_type: "DELIVERY_CHALLAN",
      };
    }
    dispatch(
      headerTitles({
        title: title,
        description: description,
      })
    );
  }, []);

  useEffect(() => {
    if (location?.state?.OrderNO) {
      if (isMaterial) {
        setValue("ref_purchase_order_no", location?.state?.OrderNO);
        setValue(
          "beneficiary_id",
          location?.state?.orderDetails?.beneficiaries?.id
        );
      } else if (isDelivery) {
        setValue("ref_po_no", location?.state?.OrderNO);
        setValue(
          "beneficiary_id",
          location?.state?.orderDetails?.beneficiaries?.id
        );
      }
    } else {
      if (isMaterial) {
        if (location?.state?.record?.id) {
          let fieldsValues = location?.state?.record;
          setValue("beneficiary_id", fieldsValues?.beneficiary_id);
          setValue(
            "movement_date",
            fieldsValues?.movement_date
              ? moment(fieldsValues?.movement_date)
              : null
          );
          setValue(
            "account_date",
            fieldsValues?.account_date
              ? moment(fieldsValues?.account_date)
              : null
          );
          setValue("mr_no", fieldsValues?.mr_no);
          setValue(
            "receipt_date",
            fieldsValues?.receipt_date
              ? moment(fieldsValues?.receipt_date)
              : null
          );
          setValue(
            "ref_purchase_order_no",
            fieldsValues?.ref_purchase_order_no
          );
          setValue("ware_house_detail_id", fieldsValues?.warehouseInfo?.id);
          setValue(
            "material_receipt_items",
            fieldsValues?.material_receipt_items
          );
          setValue("notes", fieldsValues?.notes);
        }
      } else if (isDelivery) {
        if (location?.state?.record?.id) {
          let fieldsValues = location?.state?.record;
          setValue("beneficiary_id", fieldsValues?.beneficiary_id);
          setValue(
            "challan_date",
            fieldsValues?.challan_date
              ? moment(fieldsValues?.challan_date)
              : null
          );
          setValue("dc_no", fieldsValues?.dc_no);
          setValue("ref_po_no", fieldsValues?.ref_po_no);
          setValue("ware_house_detail_id", fieldsValues?.warehouseInfo?.id);
          setValue("challan_type", fieldsValues?.challan_type);
          setValue(
            "delivery_challan_items",
            fieldsValues?.delivery_challan_items
          );
          setValue("notes", fieldsValues?.notes);
          console.log("in form", fieldsValues?.delivery_challan_items);
        }
      }
    }

    if (location?.state?.record?.id) {
      try {
        showFile({
          record_id: location?.state?.record?.id,
        }).then((dt) => {
          setListFiles(
            Array.isArray(dt.data)
              ? dt?.data?.map((ele, i) => ({
                  uid: ele.id,
                  name: ele.filename,
                  url: ele.link,
                  status: "done",
                  thumbUrl: ele.link,
                  isEdit: true,
                }))
              : []
          );
        });
      } catch (err) {
        console.error(err.message);
      }

      customFields({
        params: {
          module_id: location?.state?.record?.id,
          module_type: isMaterial ? "MATERIAL_RECEIPT" : "DELIVERY_CHALLAN",
          pagination: false,
        },
      });
    } else {
      customFields({
        params: {
          module_type: isMaterial ? "MATERIAL_RECEIPT" : "DELIVERY_CHALLAN",
          pagination: false,
          custom_flag: true,
        },
      });
    }
  }, [location?.state?.record?.id, location?.state?.OrderNO]);

  useEffect(() => {
    if (!location?.state?.record?.id) {
      if (isMaterial) {
        setValue("mr_no", prefixData?.data);
      } else if (isDelivery) {
        setValue("dc_no", prefixData?.data);
      }
    }
  }, [prefixData]);

  useEffect(() => {
    if (receiptsCreateData?.error === false) {
      message.success(
        <span className="messageText">{receiptsCreateData?.message}</span>
      );
      dispatch({ type: ReceiptsCreate.RESET });

      if (listFiles.length) {
        const formData = new FormData();
        Array.from(listFiles).forEach((ele) => {
          formData.append("files", ele);
        });

        formData.append("name", "documents");
        formData.append("record_type", "Vendor Billing Detail");
        formData.append("record_id", receiptsCreateData?.data);
        uploadFile(formData);
      }
      if (isMaterial) {
        history.push("/purchases/material/receipt");
      } else if (isDelivery) {
        history.push("/sales/delivery/receipt");
      }
    } else if (receiptsCreateData?.error === true) {
      dispatch({ type: ReceiptsCreate.RESET });
      message.error(
        <span className="messageText">{receiptsCreateData?.message}</span>
      );
    }
  }, [receiptsCreateData]);

  const setExchangeCurrency = (id) => {
    const vendor = vendorsByNameData.data?.data?.find((ele) => ele.id === id);
    setNoExError("");
    if (vendor?.currency_info?.id) {
      setHasExchange(
        vendor?.company_info?.currency_id !== vendor?.currency_info?.currency_id
      );
      setCmpCurId(vendor?.currency_info?.id);
      endpointSettingsApi
        .get(apis.getCompanyBaseCurrency, {
          params: { company_currency_id: vendor?.currency_info?.id },
        })
        .then((res) => {
          if (
            !res?.data?.data?.live_exchange_rates?.rate &&
            !res?.data?.data?.manual_exchange_rates?.rate
          ) {
            message.error(
              <span className="messageText">{res?.data?.message}</span>
            );
            setHasExchange(false);
            setNoExError(res?.data?.message);
          }
          setHasExchange(true);
          setExchange(res?.data?.data);
        })
        .catch((e) => {
          message.error(
            <span className="messageText">{e?.response?.data?.message}</span>
          );
        });
    } else {
      setHasExchange(false);
      setExchange({});
    }
  };

  const c_fields = custom_fields_values?.data;

  return (
    <div>
      <>
        <form className="new-bill-form" onSubmit={handleSubmit(onSubmit)}>
          <ReceiptsFormFields
            control={control}
            register={register}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            errors={errors}
            vendorsByNameData={vendorsByNameData}
            setExchangeCurrency={setExchangeCurrency}
            exchange={exchange}
            hasExchange={hasExchange}
            listFiles={listFiles}
            setListFiles={setListFiles}
            setRemovedItemsData={setRemovedItemsData}
            isDelivery={isDelivery}
            fieldsData={
              location?.state?.record
                ? location?.state?.record
                : location?.state?.orderDetails
            }
            warehouseData={warehouseData}
            c_fields={c_fields}
            setLoadingRef={setLoadingRef}
          />

          <div className="bottom">
            <Button
              className="pp-secondary-button"
              style={{ marginLeft: "2rem" }}
              htmlType="button"
              onClick={() => {
                if (isMaterial) {
                  history.push("/purchases/material/receipt");
                } else if (isDelivery) {
                  history.push("/sales/delivery/receipt");
                }
              }}
            >
              <span>{t("cancel")}</span>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="pp-main-button"
              id="save"
            >
              {/* <span>{location?.state?.isEdit ? t("update") : t("save")}</span> */}
              <span>{t("save")}</span>
            </Button>
          </div>
        </form>
      </>
    </div>
  );
};

export default ReceiptsForm;
