import { ReducerFunctions } from "../../utils";
import { Advances,
    AdvancesReject,
    AdvancesApprove,
    AdvancesExpDetails,
    AdvancesExpComments,
    AdvancesExpAddComments,
    AdvancesComments,
    AdvancesCommentsAdd,
    AdvancesExportExcel,
    TransactionsReject,
    TransactionsPartialApprove,
    AdvancesHistory,
    AdvancesExpHistory,
    UserAdvances,
    UserAdvancesSubmit,
    AddUserAdvance,
    UpdateUserAdvance,
    UserAdvanceFields,
    AdvancesDetailPDF,
    UserAdvanceDelete,
    AdvanceShow
} from "./advances";

export const advances_reducer = ReducerFunctions(Advances, {});
export const advance_show_reducer = ReducerFunctions(AdvanceShow, {});
export const advance_pdf_reducer = ReducerFunctions(AdvancesDetailPDF, {});
export const advancesReject_reducer = ReducerFunctions(AdvancesReject, {});
export const transactionsReject_reducer = ReducerFunctions(TransactionsReject, {});
export const transactionsPartialApprove_reducer = ReducerFunctions(TransactionsPartialApprove, {});
export const advancesApprove_reducer = ReducerFunctions(AdvancesApprove, {});
export const advancesExpDetails_reducer = ReducerFunctions(AdvancesExpDetails, {});
export const advancesExpComments_reducer = ReducerFunctions(AdvancesExpComments, {});
export const advancesExpAddComments_reducer = ReducerFunctions(AdvancesExpAddComments, {});
export const advancesComments_reducer = ReducerFunctions(AdvancesComments, {});
export const advancesCommentsAdd_reducer = ReducerFunctions(AdvancesCommentsAdd, {});
export const advancesExportExcel_reducer = ReducerFunctions(AdvancesExportExcel, {});
export const advancesHistory_reducer = ReducerFunctions(AdvancesHistory, {});
export const advancesExpHistory_reducer = ReducerFunctions(AdvancesExpHistory, {});

export const userAdvances_reducer = ReducerFunctions(UserAdvances, {});
export const userAdvancesSubmit_reducer = ReducerFunctions(UserAdvancesSubmit, {});
export const userAdvanceDelete_reducer = ReducerFunctions(UserAdvanceDelete, {});
export const addUserAdvance_reducer = ReducerFunctions(AddUserAdvance, {});
export const updateUserAdvance_reducer = ReducerFunctions(UpdateUserAdvance, {});
export const userAdvanceFields_reducer = ReducerFunctions(UserAdvanceFields, {});