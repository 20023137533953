import React from 'react';

const SalesSVG = ({ fill, scale, opacity }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20.344" viewBox="0 0 21 20.344" style={{
      scale: scale ? scale : 1,
      zoom: scale ? scale : 1,
      opacity: opacity ? opacity : "0.5"
    }}>
      <path id="e639c1f1554024c30c1704a1d60d1112" d="M12,4V5h9V4ZM6,5V6H7V20H6v1H16v.281l-2.344,2.375.688.688L16,22.687V23.5h1v-.781l1.656,1.625.688-.688L17,21.281V21H27V20H26V6h1V5ZM20.125,7.781l1.813,1.907-.72.687-.655-.656a7.95,7.95,0,0,1-9.47,7.437l.188-.968a6.967,6.967,0,0,0,5.875-1.563,7.045,7.045,0,0,0,2.438-4.938l-.688.626-.687-.72Zm-8.188.938L13,9.78l1.063-1.062.718.719L13.72,10.5l1.062,1.063-.719.687L13,11.187,11.937,12.25l-.718-.688L12.28,10.5,11.22,9.437l.719-.718ZM22,15a2,2,0,1,1-2,2A2.007,2.007,0,0,1,22,15Zm0,1a1,1,0,1,0,1,1A.993.993,0,0,0,22,16Z" transform="translate(-6 -4)" fill={fill ? fill : "#fff"} />
    </svg>
  );
};

export default SalesSVG;