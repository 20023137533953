import React from 'react'

const CusOverview = ({ scale, fill, opacity }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" style={{
      scale: scale ? scale : 1,
      zoom: scale ? scale : 1
    }}>
      <path id="c789de68982a12724435710742c274bb" d="M11.5,2.3a9.211,9.211,0,0,1,9.2,9.2H13.117l4.36-4.165L15.887,5.672,9.788,11.5H2.3a9.211,9.211,0,0,1,9.2-9.2m0-2.3A11.5,11.5,0,1,0,23,11.5,11.5,11.5,0,0,0,11.5,0" fill={fill || "#fff"} fill-rule="evenodd" opacity={opacity || 0.5} />
    </svg>
  )
}

export default CusOverview
