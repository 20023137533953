import {
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  Row,
  Space,
  Steps,
  message,
} from "antd";
import { EyeOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import BillsTemplates from "../Bills/BillsTemplates";
import { useTranslation } from "react-i18next";
import BaseCurrency from "../../Common/BaseCurrency";
import { amountFormat, showFile } from "../../../utils";
import BillData from "../Bills/BillData";
import NoCommentsComponent from "../../Common/NoComentsComponent";
import TableComponent from "../../Common/TableComp/TableComp";
import NodataComponent from "../../Common/NodataComponent";
import Comment from "../../../assets/icons/comment.png";
import History from "../../../assets/icons/time_icon.png";
import User from "../../../assets/icons/new_dash/address-book.png";
import ItemsTableShow from "../../Common/itemsTableShow";
import Close from "../../../assets/icons/close.png";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ReceiptsSubmit,
  receiptsBYID,
  receiptsSubmit,
} from "../../../actions/purchases";
import { createBillingComment } from "../../../actions/bills";
import usePayAxios from "../../../config/useAxios";
import { apis } from "../../../config/APIs";
import DeleteConfirm from "../../Common/DeleteConfirm";
import QCcheckDetails from "./QCcheckDetails";
import { use } from "i18next";
import useDocumentAxios from "../../../config/useDocument";
const ReceiptsDetails = ({ user = true }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tab, setTab] = useState("details");
  const [input, setInput] = useState("");
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState([]);
  const [rejectConfirm, setRejectConfirm] = useState(false);
  const [qcStatus, setQCStatus] = useState([]);
  const [returnCount, setReturnCount] = useState([]);
  const [type, setType] = useState({});
  const [iserror, setIsError] = useState({});
  const [iserrorRet, setIsErrorRet] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [recordID, setRecordID] = useState([]);

  const inputComment = useRef(null);
  let hasWriteAccess = location?.state?.hasWriteAccess;
  const receiptDetails = useSelector(
    (state) => state?.purchases?.receiptsBYID?.data?.data
  );
  const receiptSubmitData = useSelector(
    (state) => state?.purchases?.receiptsSubmit?.data
  );
  const {
    onCall: receiptsApproval,
    data: approvalData,
    reset: approvalReset,
  } = usePayAxios({
    api:
      location?.state?.isDeliveryApp || location?.state?.isDelivery
        ? apis?.challaApproveAPI
        : apis?.receiptsApproveAPI,
    method: "post",
  });
  const {
    onCall: receiptsReject,
    data: rejectData,
    reset: rejectReset,
    loading: rejectLoading,
  } = usePayAxios({
    api:
      location?.state?.isDeliveryApp || location?.state?.isDelivery
        ? apis?.challaRejectAPI
        : apis.receiptsRejectAPI,
    method: "post",
  });
  const {
    onCall: qcCheckPrefix,
    data: qcCheckPrefixData,
    reset: qcCheckPrefixReset,
    loading: qcCheckPrefixLoading,
  } = usePayAxios({ api: apis.qcCheckPrefixAPI, method: "post" });
  const {
    onCall: returnsPrefix,
    data: returnsPrefixData,
    reset: returnsPrefixReset,
    loading: returnsPrefixLoading,
  } = usePayAxios({ api: apis.returnsPrefixAPI, method: "post" });
  const {
    onCall: qcCheckCreate,
    data: qcCheckCreateData,
    reset: qcCheckCreateReset,
    loading: qcCheckCreateLoading,
  } = usePayAxios({ api: apis.qcCheckCreateAPI, method: "post" });
  const {
    onCall: returnsCreate,
    data: returnsCreateData,
    reset: returnsCreateReset,
    loading: returnsCreateLoading,
  } = usePayAxios({ api: apis.returnsCreateAPI, method: "post" });
  const {
    onCall: qcCheckUpdate,
    data: qcCheckUpdateData,
    reset: qcCheckReset,
    loading: qcCheckUploadLoading,
  } = usePayAxios({ api: apis.qcCheckUpdateAPI, method: "post" });
  const {
    onCall: returnUpdate,
    data: returnUpdateData,
    reset: returnReset,
    loading: returnUploadLoading,
  } = usePayAxios({ api: apis.returnsUpdateAPI, method: "post" });
  const { onCall: customFields, data: custom_fields } = useDocumentAxios({
    api: apis.customFieldValues,
    method: "get",
    baseURL: "settings",
  });
  useEffect(() => {
    if (location?.state?.isMaterial) {
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "MATERIAL_RECEIPT",
          commentable_type: "material_receipt",
          id_name: "mr_id",
        };
      });
    } else if (location?.state?.isMaterialApp) {
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "MATERIAL_RECEIPT",
          commentable_type: "material_receipt",
          id_name: "mr_id",
        };
      });
    } else if (location?.state?.isDelivery) {
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "DELIVERY_CHALLAN",
          commentable_type: "delivery_challan",
          id_name: "dc_id",
        };
      });
    } else if (location?.state?.isDeliveryApp) {
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "DELIVERY_CHALLAN",
          commentable_type: "delivery_challan",
          id_name: "dc_id",
        };
      });
    }
  }, [location?.state?.record?.id]);
  useEffect(() => {
    if (location?.state?.record?.id) {
      if (location?.state?.isDelivery || location?.state?.isDeliveryApp) {
        dispatch(
          receiptsBYID({
            module_type: "DELIVERY_CHALLAN",
            dc_id: location?.state?.record?.id,
          })
        );
        customFields({
          params: {
            module_id: location?.state?.record?.id,
            module_type: "DELIVERY_CHALLAN",
            custom_flag: true,
          },
        });
      } else if (
        location?.state?.isMaterialApp ||
        location?.state?.isMaterial
      ) {
        dispatch(
          receiptsBYID({
            module_type: "MATERIAL_RECEIPT",
            mr_id: location?.state?.record?.id,
          })
        );
        customFields({
          params: {
            module_id: location?.state?.record?.id,
            module_type: "MATERIAL_RECEIPT",
            custom_flag: true,
          },
        });
      } else if (location?.state?.isQCcheck) {
        dispatch(
          receiptsBYID({
            module_type: "QUALITY_CHECK",
            qc_id: location?.state?.record?.id,
          })
        );
      } else if (location?.state?.isReturns) {
        dispatch(
          receiptsBYID({
            module_type: "RETURN_CHECK",
            ret_id: location?.state?.record?.id,
          })
        );
      }
      async function getShowData() {
        const showData = {
          record_id: location?.state?.record?.id,
        };

        try {
          const data = await showFile(showData);
          setFile(data?.data);
        } catch (err) {
          console.error(err.message);
        }
      }
      getShowData();
    }
  }, [location?.state?.record?.id]);
  useEffect(() => {
    if (
      receiptSubmitData?.error === false ||
      approvalData?.error === false ||
      rejectData?.error === false
    ) {
      message.success(
        <span className="messageText">
          {receiptSubmitData?.message
            ? receiptSubmitData?.message
            : approvalData?.message
            ? approvalData?.message
            : rejectData?.message}
        </span>
      );
      if (location?.state?.isDelivery || location?.state?.isDeliveryApp) {
        dispatch(
          receiptsBYID({
            module_type: "DELIVERY_CHALLAN",
            dc_id: location?.state?.record?.id,
          })
        );
      } else if (
        location?.state?.isMaterialApp ||
        location?.state?.isMaterial
      ) {
        dispatch(
          receiptsBYID({
            module_type: "MATERIAL_RECEIPT",
            mr_id: location?.state?.record?.id,
          })
        );
      }
      async function getShowData() {
        const showData = {
          record_id: location?.state?.record?.id,
        };

        try {
          const data = await showFile(showData);
          console.log("receipts details values", data);
          setFile(data?.data);
        } catch (err) {
          console.error(err.message);
        }
      }
      getShowData();
      dispatch({ type: ReceiptsSubmit.RESET });
      approvalReset();
      rejectReset();
      setRejectConfirm(false);
    } else if (
      receiptSubmitData?.error === true ||
      approvalData?.error === true ||
      rejectData?.error === true
    ) {
      message.error(
        <span className="messageText">
          {receiptSubmitData?.message
            ? receiptSubmitData?.message
            : approvalData?.message
            ? approvalData?.message
            : rejectData?.message}
        </span>
      );
    }
  }, [receiptSubmitData, approvalData, rejectData]);

  useEffect(() => {
    if (qcCheckPrefixData?.error === false) {
      qcCheckCreate({
        data: {
          vendor_id: receiptDetails?.beneficiary_id,
          material_id: receiptDetails?.id,
          mr_no: receiptDetails?.mr_no,
          po_no: receiptDetails?.ref_purchase_order_no,
          qc_no: qcCheckPrefixData?.data,
          created_at: receiptDetails?.created_at,
        },
        params: {
          module_type: "QUALITY_CHECK",
        },
      })
        .then((res) => {
          console.log("receipts details res", res);
          if (res?.error == false) {
            message.success(
              <span className="messageText">{res?.message}</span>
            );
            qcCheckCreateReset();
            history.push("/purchases/material/qc-check");
          } else if (res?.data?.error == true) {
            message.error(<span className="messageText">{res?.message}</span>);
          }
        })
        .catch((error) => {
          message.error(<span className="messageText">{error?.message}</span>);
        });
      qcCheckPrefixReset();
    } else if (qcCheckPrefixData?.error === false) {
      message.error(
        <span className="messageText">{qcCheckPrefixData?.message}</span>
      );
    }
  }, [qcCheckPrefixData]);
  useEffect(() => {
    if (returnsPrefixData?.error === false) {
      returnsCreate({
        data: {
          vendor_id: receiptDetails?.beneficiary_id,
          material_id: receiptDetails?.id,
          mr_no: receiptDetails?.mr_no,
          ret_no: returnsPrefixData?.data,
        },
        params: {
          module_type: "RETURN_CHECK",
        },
      })
        .then((res) => {
          if (res?.error == false) {
            message.success(
              <span className="messageText">{res?.message}</span>
            );
            returnsCreateReset();
            history.push("/purchases/material/returns");
          } else if (res?.data?.error == true) {
            message.error(<span className="messageText">{res?.message}</span>);
          }
        })
        .catch((error) => {
          message.error(<span className="messageText">{error?.message}</span>);
        });
      returnsPrefixReset();
    } else if (returnsPrefixData?.error === false) {
      message.error(
        <span className="messageText">{returnsPrefixData?.message}</span>
      );
    }
  }, [returnsPrefixData]);
  useEffect(() => {
    if (qcCheckUpdateData?.error == false) {
      message.success(
        <span className="messageText">{qcCheckUpdateData?.message}</span>
      );
      dispatch(
        receiptsBYID({
          module_type: "QUALITY_CHECK",
          qc_id: receiptDetails?.id,
        })
      );
      qcCheckReset();
    } else if (qcCheckUpdateData?.error == true) {
      message.error(
        <span className="messageText">{qcCheckUpdateData?.message}</span>
      );
    }
  }, [qcCheckUpdateData]);
  useEffect(() => {
    if (returnUpdateData?.error == false) {
      message.success(
        <span className="messageText">{returnUpdateData?.message}</span>
      );
      dispatch(
        receiptsBYID({
          module_type: "RETURN_CHECK",
          ret_id: receiptDetails?.id,
        })
      );
      returnReset();
      setReturnCount([]);
    } else if (returnUpdateData?.error == true) {
      message.error(
        <span className="messageText">{returnUpdateData?.message}</span>
      );
    }
  }, [returnUpdateData]);

  const items = useMemo(() => {
    let baseItems = [
      {
        key: "1",
        label: t("details"),
      },
      {
        key: "2",
        label: t("Items"),
      },
      {
        key: "5",
        label: t("history"),
      },
      {
        key: "4",
        label: t("comments"),
      },
    ];

    if (location?.state?.isReturns) {
      return [
        {
          key: "2",
          label: t("Items"),
        },
      ];
    }

    return baseItems;
  }, [t]);
  const related = [
    {
      step: t("created_history"),
      name: receiptDetails?.created_by?.name,
      email: receiptDetails?.created_by?.email,
      date: moment(receiptDetails?.created_at).format("DD/MM/YYYY"),
    },
    {
      step: t("submitted_to"),
      name: receiptDetails?.submitted_to?.name,
      email: receiptDetails?.submitted_to?.email,
      date: receiptDetails?.submitted_date
        ? moment(receiptDetails?.submitted_date).format("DD/MM/YYYY")
        : "",
      // role: receiptDetails?.submitted_to?.role_name,
    },
    {
      step: t("approval_pending"),
      name: receiptDetails?.pending_with?.name,
      email: receiptDetails?.pending_with?.email,
      // role: receiptDetails?.pending_with?.role_name,
    },
  ];

  const menuProps = (
    <Menu>
      <Menu.Item key="1" onClick={() => {}}>
        Download PDF
      </Menu.Item>
      <Menu.Item key="2" onClick={() => {}}>
        Download Receipts
      </Menu.Item>
      <Menu.Item key="3" onClick={() => {}}>
        Delete
      </Menu.Item>
      <Menu.Item key="4" onClick={() => {}}>
        Print
      </Menu.Item>
    </Menu>
  );
  const handleAddComment = (event) => {
    if (input?.trim() && location?.state?.record?.id) {
      dispatch(
        createBillingComment({
          commentable_id: location?.state?.record?.id,
          body: input,
          module_type: type?.module_type,
          commentable_type: type?.commentable_type,
        })
      );
      setInput("");
      const data = {
        module_type: type?.module_type,
      };
      data[type.id_name] = location?.state?.record?.id;

      dispatch(receiptsBYID(data));
    } else {
      message.error(
        <span className="messageText">{t("comments_required")}</span>
      );
    }
  };
  const handleActions = (type) => {
    switch (type) {
      case "submit":
        if (location?.state?.isMaterial) {
          dispatch(
            receiptsSubmit({
              id: location?.state?.record?.id,
              params: {
                module_type: "MATERIAL_RECEIPT",
              },
            })
          );
        } else if (location?.state?.isDelivery) {
          dispatch(
            receiptsSubmit({
              id: location?.state?.record?.id,
              params: {
                module_type: "DELIVERY_CHALLAN",
              },
            })
          );
        }

        break;
      case "edit":
        if (location?.state?.isMaterial) {
          history.push("/purchases/material/form", {
            record: receiptDetails,
            isEdit: true,
          });
        } else if (location?.state?.isDelivery) {
          history.push("/sales/delivery/form", {
            record: receiptDetails,
            isEdit: true,
          });
        }
        break;
      case "recall":
        break;
      case "approve":
        receiptsApproval({
          data: {
            id: location?.state?.record?.id,
          },
          params: {
            module_type: location?.state?.isMaterialApp
              ? "MATERIAL_RECEIPT"
              : "DELIVERY_CHALLAN",
          },
        });
        break;
      case "reject":
        setRejectConfirm(true);
        break;
    }
  };

  function findItemsWithNullQCStatus(qualityItems) {
    return qualityItems?.filter((item) => item.qc_status === null);
  }

  const validateInputs = (item) => {
    let errorMsg = "";
    if (item.is_active === false) {
      return false;
    }
    if (!item.qc_failed_count || !item.return_reason) {
      errorMsg = "Invalid Return Quantity";
      if (!item.return_reason) {
        setIsErrorRet((err) => {
          return {
            ...err,
            [item.id]: "Please select Return Reason",
            isButtonDisable: true,
          };
        });
      }
      setIsError((err) => {
        return {
          ...err,
          [item.id]: errorMsg,
          isButtonDisable: true,
        };
      });
      return errorMsg;
    }

    return false;
  };
  return (
    <>
      {receiptDetails?.reject_reason && (
        <div className="strip red">
          {t("reason_reject")}: {receiptDetails?.reject_reason}
        </div>
      )}
      <DeleteConfirm
        open={rejectConfirm}
        onCancel={() => {
          setRejectConfirm(false);
        }}
        onConfirm={(val) => {
          receiptsReject({
            data: {
              id: location?.state?.record?.id,
              reject_reason: val,
            },
            params: {
              module_type: location?.state?.isMaterialApp
                ? "MATERIAL_RECEIPT"
                : "DELIVERY_CHALLAN",
            },
          });
        }}
        loading={rejectLoading}
        name={`material Receipts`}
        text={"Reject"}
        isReject={true}
        headerTitle={"Please specify an appropriate reason for reject."}
      />
      <div className="report-btns">
        {receiptDetails?.invoice_status !== "completed" &&
        (location?.state?.isQCcheck || location?.state?.isReturns) ? (
          <>
            <button
              type="primary"
              htmlType="submit"
              className="pp-main-button "
              disabled={
                !hasWriteAccess ||
                iserror?.isButtonDisable ||
                iserrorRet?.isButtonDisable
              }
              onClick={() => {
                if (location?.state?.isQCcheck) {
                  qcCheckUpdate({
                    data: {
                      id: receiptDetails?.id,
                      quality_items: [...qcStatus],
                    },
                    params: {
                      module_type: "QUALITY_CHECK",
                    },
                  });
                } else if (location?.state?.isReturns) {
                  const mergedArray = returnCount.map((obj1) => {
                    return { ...obj1, return_reason: selectedOptions[obj1.id] };
                  });

                  mergedArray?.forEach((item) => {
                    if (recordID?.includes(item?.id)) {
                      item.is_active = false;
                    }
                  });

                  // Check if any ids in compareIds are not present in data and add them with is_active false
                  recordID?.forEach((compareId) => {
                    if (!mergedArray?.some((item) => item?.id === compareId)) {
                      mergedArray?.push({ id: compareId, is_active: false });
                    }
                  });
                  const validate = mergedArray?.map((ele) =>
                    validateInputs(ele)
                  );
                  console.log(validate);
                  if (validate?.filter((ele) => ele).length) {
                    return;
                  } else
                    returnUpdate({
                      data: {
                        id: receiptDetails?.id,
                        quality_items: [...mergedArray],
                      },
                      params: {
                        module_type: "RETURN_CHECK",
                      },
                    });
                }
              }}
            >
              <span>{t("save")}</span>
            </button>
            <button
              disabled={
                findItemsWithNullQCStatus(receiptDetails?.quality_items)
                  ?.length ||
                !hasWriteAccess ||
                iserror?.isButtonDisable ||
                iserrorRet?.isButtonDisable
              }
              className="approve"
              onClick={() => {
                if (location?.state?.isQCcheck) {
                  qcCheckUpdate({
                    data: {
                      id: receiptDetails?.id,
                      invoice_status: "completed",
                      quality_items: [...qcStatus],
                    },
                    params: {
                      module_type: "QUALITY_CHECK",
                    },
                  });
                } else if (location?.state?.isReturns) {
                  const mergedArray = returnCount.map((obj1) => {
                    return { ...obj1, return_reason: selectedOptions[obj1.id] };
                  });
                  mergedArray?.forEach((item) => {
                    if (recordID?.includes(item?.id)) {
                      item.is_active = false;
                    }
                  });

                  // Check if any ids in compareIds are not present in data and add them with is_active false
                  recordID?.forEach((compareId) => {
                    if (!mergedArray?.some((item) => item?.id === compareId)) {
                      mergedArray?.push({ id: compareId, is_active: false });
                    }
                  });

                  const validate = mergedArray?.map((ele) =>
                    validateInputs(ele)
                  );
                  if (validate?.filter((ele) => ele).length) {
                    return;
                  } else
                    returnUpdate({
                      data: {
                        id: receiptDetails?.id,
                        invoice_status: "completed",
                        quality_items: [...mergedArray],
                      },
                      params: {
                        module_type: "RETURN_CHECK",
                      },
                    });
                }
              }}
            >
              <span>
                {location?.state?.isReturns ? "Mark as Done" : t("Complete")}
              </span>
            </button>
          </>
        ) : null}
        {receiptDetails &&
        (location?.state?.isMaterial || location?.state?.isDelivery) ? (
          receiptDetails?.invoice_status ===
          "approved" ? null : receiptDetails?.invoice_status ===
            "pending_approval" ? null : receiptDetails?.invoice_status === // </button> //     <span>{t('recall')}</span> // <button disabled={!hasWriteAccess} className="pp-secondary-button" onClick={() => { }}>
            "rejected" ? null : (
            <>
              <button
                className="pp-secondary-button"
                disabled={!hasWriteAccess}
                onClick={() => {
                  handleActions("edit");
                }}
              >
                <span>{t("edit")}</span>
              </button>
              <button
                disabled={!hasWriteAccess}
                type="primary"
                htmlType="submit"
                className="pp-main-button "
                onClick={() => {
                  handleActions("submit");
                }}
              >
                <span>{t("submit")}</span>
              </button>
            </>
          )
        ) : null}

        {receiptDetails?.role_status === "pending_approval" &&
        (location?.state?.isMaterialApp || location?.state?.isDeliveryApp) ? (
          <>
            <button
              disabled={!hasWriteAccess}
              className="approve"
              onClick={() => {
                handleActions("approve");
              }}
            >
              <span>{t("approve")}</span>
            </button>

            <button
              disabled={!hasWriteAccess}
              className="reject"
              onClick={() => handleActions("reject")}
            >
              <span>{t("reject")}</span>
            </button>
          </>
        ) : null}
        {receiptDetails?.invoice_status === "approved" &&
        (location?.state?.isMaterial || location?.state?.isMaterialApp) ? (
          <>
            <button
              disabled={!hasWriteAccess}
              className="approve"
              onClick={() => {
                returnsPrefix({ params: { module_type: "RETURN_CHECK" } });
              }}
            >
              <span>{t("Returns")}</span>
            </button>

            <button
              disabled={!hasWriteAccess}
              className="reject"
              onClick={() => {
                qcCheckPrefix({ params: { module_type: "QUALITY_CHECK" } });
              }}
            >
              <span>{t("Move to QC Check")}</span>
            </button>
          </>
        ) : null}

        {/* <Dropdown overlay={menuProps} disabled={!hasWriteAccess}>
                    <Button className="pp-secondary-button">
                        <Space>
                            {t("more")}
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown> */}
        <span
          className="close cursor"
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div>
      {location?.state?.isQCcheck || location?.state?.isReturns ? (
        <QCcheckDetails
          inputComment={inputComment}
          handleAddComment={handleAddComment}
          input={input}
          setInput={setInput}
          receiptDetails={receiptDetails}
          setQCStatus={setQCStatus}
          qcStatus={qcStatus}
          isReturns={location?.state?.isReturns ? true : false}
          returnCount={returnCount}
          setReturnCount={setReturnCount}
          hasWriteAccess={hasWriteAccess}
          iserror={iserror}
          iserrorRet={iserrorRet}
          setIsError={setIsError}
          setIsErrorRet={setIsErrorRet}
          setSelectedOptions={setSelectedOptions}
          selectedOptions={selectedOptions}
          setRecordID={setRecordID}
          recordID={recordID}
          isCompleted={receiptDetails?.invoice_status === "completed"}
        />
      ) : (
        <>
          <div>
            <Row
              wrap={false}
              className="new-report-detail"
              style={{ flexGrow: 1, display: "flex" }}
            >
              <Col
                flex="auto"
                style={{ width: "100%", display: "flex", flexGrow: "1" }}
              >
                <div style={{ flexGrow: 1 }}>
                  <Row>
                    <Col span={24}>
                      <div className="flex-between">
                        <div className="report-head">
                          <div className="title-sec">
                            <h3>
                              {location?.state?.isMaterialApp ||
                              location?.state?.isMaterial
                                ? "Material Receipt -"
                                : location?.state?.isReturns
                                ? "Returns"
                                : "Delivery Challan -"}
                              [
                              {receiptDetails?.mr_no
                                ? receiptDetails?.mr_no
                                : receiptDetails?.dc_no
                                ? receiptDetails?.dc_no
                                : receiptDetails?.ret_no}
                              ]
                            </h3>
                            <span
                              className={`status status_${
                                location?.state?.isMaterialApp ||
                                location?.state?.isDeliveryApp
                                  ? receiptDetails?.role_status
                                  : receiptDetails?.invoice_status
                              }`}
                            >
                              {location?.state?.isMaterialApp ||
                              location?.state?.isDeliveryApp
                                ? receiptDetails?.role_status?.replace(
                                    /_/g,
                                    " "
                                  )
                                : receiptDetails?.invoice_status?.replace(
                                    /_/g,
                                    " "
                                  )}
                            </span>
                          </div>
                          <p className="duration">
                            {t("creation_date")} :{" "}
                            {moment(receiptDetails?.created_at)?.format(
                              "DD MMM, YYYY hh.mm A"
                            )}
                          </p>
                          <p>
                            <strong>
                              {user ? t("created_byy") : t("by_submitted")}
                              {user
                                ? receiptDetails?.created_by?.name ??
                                  receiptDetails?.userInfo?.name
                                : receiptDetails?.userInfo?.name}
                              <span
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: "normal",
                                  color: "#1C2B36",
                                  opacity: "0.51",
                                }}
                              >
                                (
                                {user
                                  ? receiptDetails?.created_by?.email ??
                                    receiptDetails?.userInfo?.email
                                  : receiptDetails?.userInfo?.email}
                                )
                              </span>
                            </strong>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <BillsTemplates
                        template={"template_01"}
                        invoiceInfo={{}}
                      />
                    </Col>

                    <Col
                      span={12}
                      style={{
                        paddingRight: "10px",
                      }}
                    >
                      <div>
                        <div className="tabs-sec mb1">
                          <ul>
                            {items.map(
                              (ele) =>
                                ele?.label && (
                                  <li
                                    onClick={() =>
                                      setTab(ele.label?.toLowerCase())
                                    }
                                    className={`${
                                      tab.toLowerCase() ===
                                      ele.label?.toLowerCase()
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    {ele.label}
                                  </li>
                                )
                            )}
                          </ul>
                        </div>

                        {tab === "details" ? (
                          <div>
                            <div
                              style={{
                                backgroundColor: "#F7F7F7",
                                border: "1px solid #EDF0F5",
                                borderRadius: "9px",
                                display: "flex",
                                flexDirection: "column",
                                padding: "25px 36px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1rem",
                                  lineHeight: "20px",
                                  color: "#58666E",
                                  fontWeight: "normal",
                                }}
                              >
                                {t("Amount")}
                              </span>

                              <span
                                style={{
                                  fontSize: "1.7rem",
                                  lineHeight: "34px",
                                  color: "#1C2B36",
                                  fontWeight: "600",
                                }}
                              >
                                <BaseCurrency
                                  symbol_code={
                                    receiptDetails?.currency_info?.iso_code
                                  }
                                />{" "}
                                {amountFormat(
                                  receiptDetails?.total_amount,
                                  receiptDetails?.currency_info?.decimal_scale
                                )}
                              </span>
                            </div>

                            <div
                              style={{
                                backgroundColor: "#F7F7F7",
                                border: "1px solid #EDF0F5",
                                borderRadius: "9px",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "20px",
                                padding: "25px 36px",
                              }}
                            >
                              <BillData
                                style={{
                                  fontSize: "1.1rem",
                                }}
                                label={
                                  location?.state?.isMaterialApp ||
                                  location?.state?.isMaterial
                                    ? t("material_receipt_hash")
                                    : t("delivery_challan_hash")
                                }
                                value={
                                  location?.state?.isMaterialApp ||
                                  location?.state?.isMaterial
                                    ? receiptDetails?.mr_no
                                    : receiptDetails?.dc_no
                                }
                              />
                              <BillData
                                style={{
                                  fontSize: "1.1rem",
                                }}
                                label={
                                  moment(receiptDetails?.account_date)?.format(
                                    "DD MMM, YYYY hh.mm A"
                                  )
                                    ? t("movement_date")
                                    : t("Date")
                                }
                                value={
                                  moment(receiptDetails?.account_date)?.format(
                                    "DD MMM, YYYY hh.mm A"
                                  )
                                    ? moment(
                                        receiptDetails?.movement_date
                                      )?.format("DD MMM, YYYY hh.mm A")
                                    : moment(
                                        receiptDetails?.challan_date
                                      )?.format("DD MMM, YYYY hh.mm A")
                                }
                              />
                              {(location?.state?.isMaterialApp ||
                                location?.state?.isMaterial) && (
                                <BillData
                                  style={{
                                    fontSize: "1.1rem",
                                  }}
                                  label={t("account_date")}
                                  value={moment(
                                    receiptDetails?.account_date
                                  )?.format("DD MMM, YYYY hh.mm A")}
                                />
                              )}
                              <BillData
                                style={{
                                  fontSize: "1.1rem",
                                }}
                                label={
                                  location?.state?.isMaterialApp ||
                                  location?.state?.isMaterial
                                    ? t("PO Number")
                                    : t("SO Number")
                                }
                                value={
                                  location?.state?.isMaterialApp ||
                                  location?.state?.isMaterial
                                    ? receiptDetails?.ref_purchase_order_no
                                    : receiptDetails?.ref_po_no
                                }
                              />
                              <BillData
                                style={{
                                  fontSize: "1.1rem",
                                }}
                                label={t("warehouse")}
                                value={
                                  receiptDetails?.warehouseInfo?.ware_house_name
                                }
                              />
                              <BillData
                                style={{
                                  fontSize: "1.1rem",
                                }}
                                label={t("Tax")}
                                value={receiptDetails?.total_tax_amount}
                              />
                              <BillData
                                style={{
                                  fontSize: "1.1rem",
                                }}
                                label={t("Discount")}
                                value={receiptDetails?.total_discount}
                              />
                              {/* <BillData
                                style={{
                                  fontSize: "1.1rem",
                                }}
                                label={t("TDS")}
                                value={receiptDetails?.tds_amount}
                              /> */}
                              {custom_fields?.data?.map((ele) =>
                                ele.column_type === "document" ? (
                                  <BillData
                                    label={ele.column_name}
                                    value={ele?.attachments}
                                    documenttype={ele.column_type}
                                  />
                                ) : (
                                  <BillData
                                    label={ele.column_name}
                                    value={ele.value}
                                    documenttype={ele.column_type}
                                  />
                                )
                              )}
                              <BillData
                                style={{
                                  fontSize: "1.1rem",
                                }}
                                label={t("Notes")}
                                value={receiptDetails?.notes}
                              />
                            </div>
                            <div
                              style={{
                                backgroundColor: "#F7F7F7",
                                border: "1px solid #EDF0F5",
                                borderRadius: "9px",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "20px",
                                padding: "25px 36px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "1.1rem",
                                  lineHeight: "22px",
                                  color: "#3C4853",
                                  marginBottom: "30px",
                                  fontWeight: "600",
                                }}
                              >
                                {t("approvers")}
                              </div>

                              <Row>
                                <Space direction="vertical">
                                  {receiptDetails?.approvers?.map((data) => (
                                    <div
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#000000",
                                          fontSize: "1.1rem",
                                          lineHeight: "23px",
                                          fontWeight: "medium",
                                        }}
                                      >
                                        {data?.level + 1}
                                      </span>

                                      <div
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          border: "1px solid #D6D6D6",
                                          borderRadius: "50px",
                                          height: "40px",
                                          justifyContent: "center",
                                          margin: "0px 10px",
                                          width: "40px",
                                        }}
                                      >
                                        <img
                                          src={User}
                                          alt="history"
                                          width={18}
                                        />
                                        <span
                                          style={{
                                            color: "rgba(0, 0, 0, 0.32)",
                                            fontSize: "1rem",
                                            lineHeight: "21px",
                                            fontWeight: "medium",
                                          }}
                                        >
                                          {data?.init}
                                        </span>
                                      </div>

                                      <span
                                        style={{
                                          color: "#4A4A4ACC",
                                          fontSize: "1.1rem",
                                          lineHeight: "18px",
                                          fontWeight: "medium",
                                        }}
                                      >
                                        {data.role_name === "Employee"
                                          ? receiptDetails?.created_by?.name
                                          : data?.name}
                                        <span
                                          style={{
                                            fontSize: "1rem",
                                            color: "#4A4A4ACC",
                                            fontWeight: "medium",
                                          }}
                                        >
                                          ({data?.role_name})
                                        </span>
                                      </span>
                                    </div>
                                  ))}
                                </Space>
                              </Row>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#F7F7F7",
                                border: "1px solid #EDF0F5",
                                borderRadius: "9px",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "20px",
                                padding: "25px 36px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "1.1rem",
                                  lineHeight: "22px",
                                  color: "#3C4853",
                                  marginBottom: "30px",
                                  fontWeight: "600",
                                }}
                              >
                                {t("attachments")}
                              </div>

                              <div>
                                {file &&
                                  file?.map((element, index) => {
                                    return (
                                      <>
                                        <div style={{ marginBottom: "0.5rem" }}>
                                          {index + 1}. {element.filename} (
                                          <a
                                            href={element?.link}
                                            target="_blank"
                                            style={{ color: "black" }}
                                          >
                                            {t("download_text")}
                                          </a>
                                          )
                                          {element.filename &&
                                            element.filename?.includes &&
                                            (element.filename?.includes(
                                              ".jpg"
                                            ) ||
                                              element.filename?.includes(
                                                ".png"
                                              ) ||
                                              element.filename?.includes(
                                                ".jpeg"
                                              )) && (
                                              <EyeOutlined
                                                color="#333"
                                                onClick={() => {
                                                  setUrl(element?.link);
                                                  setVisible(true);
                                                }}
                                                className="ml1"
                                              />
                                            )}
                                        </div>
                                      </>
                                    );
                                  })}
                                {url && (
                                  <Image
                                    width={200}
                                    style={{
                                      display: "none",
                                    }}
                                    src={url}
                                    preview={{
                                      visible,
                                      scaleStep: 0.5,
                                      src: url,
                                      onVisibleChange: (value) => {
                                        setVisible(value);
                                      },
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#F7F7F7",
                                border: "1px solid #EDF0F5",
                                borderRadius: "9px",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "1rem",
                                marginBottom: "1rem",
                                padding: "25px 36px",
                              }}
                            >
                              <Space direction="vertical">
                                {related.map(
                                  (data) =>
                                    data && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          marginBottom: "30px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "#3C4853",
                                            fontSize: "1rem",
                                            lineHeight: "22px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {data.step}
                                        </span>

                                        <span
                                          style={{
                                            color: "#3C4853",
                                            fontSize: "1rem",
                                            lineHeight: "28px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {data.name}
                                        </span>

                                        <span
                                          style={{
                                            color: "#8B8B8B",
                                            fontSize: "0.95rem",
                                            lineHeight: "23px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {data.email}
                                        </span>

                                        <span
                                          style={{
                                            color: "#8B8B8B",
                                            fontSize: "0.95rem",
                                            lineHeight: "23px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {data.date}
                                        </span>

                                        {data.role && (
                                          <span
                                            style={{
                                              color: "#3C4853",
                                              fontSize: "0.8rem",
                                              lineHeight: "28px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {data.role}
                                          </span>
                                        )}
                                      </div>
                                    )
                                )}
                              </Space>
                            </div>
                          </div>
                        ) : null}

                        {tab === "items" ? (
                          receiptDetails?.billing_items?.length ||
                          receiptDetails?.delivery_challan_items?.length ||
                          receiptDetails?.return_items?.length ? (
                            <div className="commentsSection">
                              <ItemsTableShow
                                itemsTableShowData={
                                  location?.state?.isMaterialApp ||
                                  location?.state?.isMaterial
                                    ? receiptDetails?.billing_items
                                    : location?.state?.isReturns
                                    ? receiptDetails?.return_items
                                    : receiptDetails?.delivery_challan_items
                                }
                              />
                            </div>
                          ) : (
                            <NodataComponent noBtns={true} />
                          )
                        ) : null}

                        {tab === "history" ? (
                          receiptDetails?.history?.length ? (
                            <div className="commentsSection pl1">
                              <Steps
                                direction="vertical"
                                size="small"
                                current={
                                  receiptDetails?.history?.length + 1 || 100
                                }
                              >
                                {receiptDetails?.history &&
                                  receiptDetails?.history?.map(
                                    ({
                                      body,
                                      created_at,
                                      id,
                                      curr_status,
                                      name,
                                    }) => (
                                      <Steps.Step
                                        key={id}
                                        title={body}
                                        description={`on ${moment(
                                          created_at
                                        ).format(
                                          "DD MMM YYYY, HH:mm:ss a"
                                        )} | Action: ${curr_status} by ${name}`}
                                        icon={
                                          <img
                                            src={History}
                                            alt="history"
                                            width={18}
                                          />
                                        }
                                      />
                                    )
                                  )}
                              </Steps>
                            </div>
                          ) : (
                            <NodataComponent noBtns={true} />
                          )
                        ) : null}

                        {tab === "comments" ? (
                          <>
                            <div
                              className="addComment mb1 pl1"
                              style={{ display: "flex" }}
                            >
                              <Input
                                placeholder={t("enter_comment")}
                                ref={inputComment}
                                value={input}
                                onInput={(e) =>
                                  setInput(e.target.value.trimStart())
                                }
                              />
                              <Button
                                disabled={!hasWriteAccess}
                                key="1"
                                className="formButton ml1"
                                onClick={() => handleAddComment()}
                              >
                                {t("send")}
                              </Button>
                            </div>

                            {receiptDetails?.comments?.length ? (
                              <>
                                <div className="commentsSection pl1">
                                  <Steps
                                    direction="vertical"
                                    size="small"
                                    current={
                                      receiptDetails?.comments?.length + 1 ||
                                      100
                                    }
                                  >
                                    {receiptDetails?.comments &&
                                      receiptDetails?.comments?.map(
                                        ({ body, created_at, id, name }) => (
                                          <Steps.Step
                                            key={id}
                                            title={body}
                                            description={`on ${moment(
                                              created_at
                                            ).format(
                                              "DD MMM YYYY, HH:mm:ss a"
                                            )} | Commented by: ${name}`}
                                            icon={
                                              <img
                                                src={Comment}
                                                alt="history"
                                                width={18}
                                              />
                                            }
                                          />
                                        )
                                      )}
                                  </Steps>
                                </div>
                              </>
                            ) : (
                              <NoCommentsComponent />
                            )}
                          </>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default ReceiptsDetails;
