import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Select, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { portalDashboard, portalDashboardCumpanyList } from '../../actions/customers';
import { headerTitles } from '../../actions/masterdata/masterdata';
import { customer_cart } from '../../actions/orders/orders';
import { amountFormat } from '../../utils';
import BaseCurrency from '../Common/BaseCurrency';
import "./customerPortal.css";
import SendEmail from './SendEmail';
const { Title } = Typography;
const CustomerPortalDashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch()
  const [emailModalOpen, setEmailModalOpen] = useState(false)
  const [showMoreContact, setShowMoreContact] = useState(false)
  // const [selectCompany, setSelectCompany] = useState()
  const companyListData = useSelector((state) => state?.customers?.portalCompanyList?.data?.data)
  const dashboardData = useSelector((state) => state?.customers?.portalDashboard?.data?.data)
  const handleCancel = () => {
    setEmailModalOpen(false)
  }
  useEffect(() => {
    dispatch(headerTitles({ title: "Overview", description: "Overview of Accounts" }));
    dispatch(portalDashboard({ company_id: localStorage?.getItem("company_id") }))
    dispatch(portalDashboardCumpanyList({ type: localStorage.getItem("isCustomer") ? "customer" : "vendor" }))
  }, [])

  // useEffect(() => {
  //   setSelectCompany(() => {
  //     return dashboardData?.company_details?.[0]?.id
  //   })
  // }, [dashboardData])
  return (
    <>
      <Modal
        title={
          <Title level={4}>
            {t("Mail To")} &nbsp;
            {/* <span className="text-small">{t("enter_details")}</span> */}
          </Title>
        }
        open={emailModalOpen}
        onCancel={handleCancel}
        footer={[]}
        className="right-alinged-modal"
      >
        <SendEmail handleCancel={handleCancel} />
      </Modal>
      <div className='customer-portal-section'>
        <div className='customer-portal-container'>
          <div className='balance-summary mb1'>
            <div className="header-title flex-between">
              <span>
                <span className='card-title'>
                  {"Balance Summary"}
                </span>
                <span className='help-icon'>
                  <Tooltip placement='top' title={"Overview of customer invoices, highlighting the equilibrium between total invoices, Outstanding due, and Overdue due."}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>

              </span>
              <div className="w20">
                <section className="w100 mb1">
                  <Select
                    className="w100"
                    onChange={(e) => {
                      dispatch(portalDashboard({
                        company_id: e
                      }))
                      dispatch(customer_cart({ company_id: e }));
                      localStorage?.setItem("company_id", e)
                    }}
                    value={localStorage?.getItem("company_id")}
                    options={companyListData?.map(listElement => (
                      {
                        label: listElement?.name,
                        value: listElement?.id,
                      }
                    ))}
                    placeholder={"select"}
                  />
                </section>
              </div>
            </div>
            <div className='card-section'>
              <div className='card-container d-flex'>
                <div className='paid-on card-container-child justify-between flex-col mr1'>
                  <div className='v-center'>
                    <div className="card_circle" style={{ background: "#1890ff" }}></div>
                    <span className='title'>Total Invoice</span>
                  </div>
                  <span className='amount-total amount'><BaseCurrency symbol_code={dashboardData?.beneficiary_currency?.iso_code} /><CountUp style={{ color: "#333333" }} end={dashboardData?.invoice_details?.[0]?.total} start={0} useEasing={true} duration={4} decimals={dashboardData?.invoice_details?.[0]?.decimal_scale} /></span>
                  <span style={{ color: "#3F81F4", fontSize: "1.2rem" }} ><span className='title total-count'>Total of Invoices</span>{<CountUp style={{ color: "#3F81F4", fontWeight: "500", fontSize: "1.1rem" }} end={dashboardData?.invoice_details?.[0]?.total_count} start={0} useEasing={true} duration={4} />}</span>
                </div>
                <div className='card-container-child justify-between flex-col'>
                  <div className='v-center'>
                    <div className="card_circle" style={{ background: "#48b553" }}></div>
                    <span className='title'>Out Standing</span>
                  </div>
                  <span className='amount-total amount'><BaseCurrency symbol_code={dashboardData?.beneficiary_currency?.iso_code} /><CountUp style={{ color: "#333333" }} end={dashboardData?.invoice_details?.[0]?.pending} start={0} useEasing={true} duration={4} decimals={dashboardData?.invoice_details?.[0]?.decimal_scale} /></span>
                  <span style={{ color: "#3F81F4", fontSize: "1.2rem" }} ><span className='title total-count'>Total of Invoices</span>{<CountUp style={{ color: "#3F81F4", fontWeight: "500", fontSize: "1.1rem" }} end={dashboardData?.invoice_details?.[0]?.pending_count} start={0} useEasing={true} duration={4} />}</span>
                </div>
                <div className='paid-amoun card-container-child justify-between flex-col'>
                  <div className='v-center'>
                    <div className="card_circle" style={{ background: "#efa42c" }}></div>
                    <span className='title'> Over Due</span>
                  </div>
                  <span className='amount-total amount'><BaseCurrency symbol_code={dashboardData?.beneficiary_currency?.iso_code} /><CountUp style={{ color: "#333333" }} end={dashboardData?.invoice_details?.[0]?.overdue} start={0} useEasing={true} duration={4} decimals={dashboardData?.invoice_details?.[0]?.decimal_scale} /></span>
                  <span style={{ color: "#3F81F4", fontSize: "1.2rem" }}><span className='title total-count'>Total of Invoices</span>{<CountUp style={{ color: "#3F81F4", fontWeight: "500", fontSize: "1.1rem" }} end={dashboardData?.invoice_details?.[0]?.overdue_count} start={0} useEasing={true} duration={4} />}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='payment-made d-flex gap1 mb1'>
            <div className='payment-made-left w50'>
              <div className="header-title">
                <span>
                  <span className='card-title'>
                    {localStorage?.getItem("isCustomer") ? "Last Payment Made" : "Last Payment Received"}
                  </span>
                  <span className='help-icon'>
                    <Tooltip placement='top' title={localStorage?.getItem("isCustomer") ? "Most recent payment transaction made." : " Most recent payment transaction received."}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>

                </span>
              </div>
              <div className='card-section'>
                <div className='card-container d-flex'>
                  <div className='total-amount card-container-child mr1 '>
                    <h3 className='amount-total'><BaseCurrency symbol_code={dashboardData?.beneficiary_currency?.iso_code} />{amountFormat(dashboardData?.transactions?.amount_paid, dashboardData?.beneficiary_currency?.decimal_scale)}</h3>
                    <span className='paid-for'>
                      {"Paid for"}{" "}<span style={{ color: "#3F81F4" }}>{dashboardData?.transactions?.invoice_no ? dashboardData?.transactions?.invoice_no : dashboardData?.transactions?.billing_no}</span>
                    </span>
                  </div>
                  <div className='paid-on card-container-child justify-between flex-col mr1'>
                    <span className='title'>Paid On</span>
                    <span className='paid-date'>{dashboardData?.transactions?.payment_date ? moment(dashboardData?.transactions?.payment_date).format("DD MMMM YYYY") : "-"}</span>
                  </div>
                  <div className='paid-amoun card-container-child justify-between flex-col'>
                    <span className='title'>Payment Mode</span>
                    <span style={{ color: "#3F81F4" }}>{dashboardData?.transactions?.payment_mode}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='payment-made-right w50'>
              <div className="header-title">
                <span>
                  <span className='card-title'>
                    {"Pending Approvals"}
                  </span>
                  <span className='help-icon'>
                    <Tooltip placement='top' title={"List of actions awaiting approval or confirmation."}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>

                </span>
              </div>
              <div className='card-section'>
                <div className='card-container d-flex'>
                  <div className='total-amount card-container-child mr1 '>
                    <h3 className='amount-total'>{localStorage?.getItem("isCustomer") ? dashboardData?.estimate_details?.count || 0 : dashboardData?.purchase_order?.count || 0}</h3>
                    <span className='paid-for'>{localStorage?.getItem("isCustomer") ? "Estimates" : "Purchase Order"}</span>
                  </div>
                  {/* <div className='paid-on card-container-child justify-between flex-col mr1'>
                                        <span className='title'>Paid On</span>
                                        <span className='paid-date'>{dashboardData?.transactions?.payment_date ? moment(dashboardData?.transactions?.payment_date).format("DD MMMM YYYY") : "-"}</span>
                                    </div>
                                    <div className='paid-amoun card-container-child justify-between flex-col'>
                                        <span className='title'>Payment #</span>
                                        <span style={{ color: "#3F81F4" }}> <BaseCurrency symbol_code={dashboardData?.beneficiary_currency?.iso_code} />{amountFormat(dashboardData?.transactions?.amount_paid, dashboardData?.beneficiary_currency?.format)}</span>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='details d-flex gap1 mb3'>
            <div className='details-left w50'>
              <div className="header-title flex-between">
                <span>
                  <span className='card-title'>
                    {"My Details"}
                  </span>
                  <span className='help-icon'>
                    <Tooltip placement='top' title={"Summary of personal information and particulars."}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                </span>
                <span className='link' onClick={() => {
                  history.push("/customer/portal/profile", {
                    customerData: dashboardData?.beneficiary_details
                  })
                }}>View More</span>
              </div>
              <div className='card-section'>
                <div className='card-container d-flex flex-col'>
                  <h3 className='customer-full-name'>{dashboardData?.beneficiary_details?.beneficiary_name}</h3>
                  <span className='customer-name'>{dashboardData?.beneficiary_details?.email}</span>
                </div>
              </div>
            </div>
            {/* <div className='details-right w50'>
                            <div className="header-title flex-between">
                                <span>
                                    <span className='card-title'>
                                        {"Shared Documents"}
                                    </span>
                                    <span className='help-icon'>
                                        <Tooltip placement='top' title={""}>
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </span>
                                </span>
                                <span className='link'>Upload Documents</span>
                            </div>
                            <div className='card-section'>
                                <div className='card-container d-flex flex-col'>
                                    <h3 className='customer-full-name'>Sri Harsha Pingalli Enterprises</h3>
                                    <span className='customer-name'>Harsha Pingalli</span>
                                </div>
                            </div>
                        </div> */}
          </div>
          {/* <div className='contact-details'>
            {
              dashboardData?.company_details?.map((companyElement, companyIndex) => (
                <>
                  {
                    showMoreContact ? (
                      <div className='d-flex mb2'>
                        <div key={companyIndex} className='contact-left d-flex flex-col w40'>
                          <h3>{companyElement?.name}</h3>
                          <span className='contact-address'>{companyElement?.state}</span>
                          <span className='contact-address'>{companyElement?.country}</span>
                          <span className='contact-address'>GSTIN  <span className='contact-address'>{companyElement?.gst_no}</span></span>
                        </div>
                        <div className='contact-right w60 d-flex'>
                          <div className="contact w60">
                            <img src={user_icon} alt="" className="user-img" />
                            <div>
                              <p className='mb0'>
                                {companyElement?.primary_contact_name}
                              </p>
                              <p className="dim mb0">
                                <img src={email} width={16} />  {companyElement?.primary_email}
                              </p>
                              <div className="flex-between gap2">
                                {<p className="dim mb0">
                                  <img src={phone} width={10} /> {" "}
                                  {companyElement?.primary_phone}
                                </p>}
                              </div>
                            </div>
                          </div>
                          <div className="send-email">
                            <Button
                              style={{ color: "#000", borderRadius: "5px" }}
                              type="primary"
                              onClick={() => {
                                setEmailModalOpen(true)
                              }}
                            >
                              send Email
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : companyIndex < 2 ? (
                      <div className='d-flex mb2'>
                        <div key={companyIndex} className='contact-left d-flex flex-col w40'>
                          <h3>{companyElement?.name}</h3>
                          <span className='contact-address'>{companyElement?.state}</span>
                          <span className='contact-address'>{companyElement?.country}</span>
                          <span className='contact-address'>GSTIN  <span className='contact-address'>{companyElement?.gst_no}</span></span>
                        </div>
                        <div className='contact-right w60 d-flex'>
                          <div className="contact w60">
                            <img src={user_icon} alt="" className="user-img" />
                            <div>
                              <p className='mb0'>
                                {companyElement?.primary_contact_name}
                              </p>
                              <p className="dim mb0">
                                <img src={email} width={16} />  {companyElement?.primary_email}
                              </p>
                              <div className="flex-between gap2">
                                {<p className="dim mb0">
                                  <img src={phone} width={10} /> {" "}
                                  {companyElement?.primary_phone}
                                </p>}
                              </div>
                            </div>
                          </div>
                          <div className="send-email">
                            <Button
                              style={{ color: "#000", borderRadius: "5px" }}
                              type="primary"
                              onClick={() => {
                                setEmailModalOpen(true)
                              }}
                            >
                              send Email
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }

                </>
              ))
            }

          </div>
          {
            dashboardData?.company_details?.length > 2 ?
              <div className='contact-more-btn' onClick={() => {
                setShowMoreContact(!showMoreContact)
              }}>
                {showMoreContact ? <CaretUpOutlined /> : <CaretDownOutlined />}
                <span style={{ color: "#000", marginLeft: "0.5rem" }}>MORE</span>
              </div> : null
          } */}

        </div>
      </div>
    </>
  )
}

export default CustomerPortalDashboard