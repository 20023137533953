import React, { useMemo, useState } from "react";
import InvPDF from "../../../PDFTemplates/invPDF";
import InvPDF2 from "../../../PDFTemplates/invPDF2";
import InvoicePDF from "../../../PDFTemplates/InvoicePDF";
import CreditPdf from "../../../PDFTemplates/CreditPdf";
import PaymentReceivedTwo from "../../../PDFTemplates/PaymentReceivedTwo";
import PaymentReceived from "../../../PDFTemplates/PaymentReceived";
import Payments from "../../../PDFTemplates/Payment";
import CreditImg from "../../../../assets/images/credit.jpg";
import Purpleinv from "../../../PDFTemplates/Purpleinv"
import { allTemplates } from "../../../../actions/Templates";
import { Popover, message, Dropdown } from "antd";
import successIcon from "../../../../assets/images/successIcon.png";
import settingIcon from "../../../../assets/images/settings.png";
import { useEffect } from 'react';
import { Modal, Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateTemplates } from "../../../../actions/Templates";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import { updateTemplates } from "../../../../actions/Templates";
import bill1 from "../../../../assets/images/BILL01.png";
import bill2 from "../../../../assets/images/BILL04.png"
import bill3 from "../../../../assets/images/BILL02.png"
import bill4 from "../../../../assets/images/BILL3.png"

import sales1 from "../../../../assets/images/SALES0001.png"
import sales2 from "../../../../assets/images/SO002.png"
import sales3 from "../../../../assets/images/SALES03.png"
import sales4 from "../../../../assets/images/SALES3.png"
import Template1 from "../../../../assets/images/EST0001.png"
import Template2 from "../../../../assets/images/EST03.png"
import Template3 from "../../../../assets/images/EST3.png"
import Template4 from "../../../../assets/images/EST0002.png"
import credit1 from "../../../../assets/images/CREDIT1.png"
import credit2 from "../../../../assets/images/CREDIT2.png"
import credit3 from "../../../../assets/images/CREDIT3.png"

import invoice1 from "../../../../assets/images/INV0001.png"
import invoice2 from "../../../../assets/images/INV002.png"
import invoice3 from "../../../../assets/images/INV04.png"
import invoice4 from "../../../../assets/images/INV3.png"
import EST4 from "../../../../assets/images/EST005.png"
import BLL4 from "../../../../assets/images/BILL05.png"
import INV4 from "../../../../assets/images/INV005.png"
import PURCHASE4 from "../../../../assets/images/PO03.png"
import SALES4 from "../../../../assets/images/SALES005.png"
import Temp6INv from "../../../../assets/images/INV0101.png"
import Temp6B from "../../../../assets/images/BILL0101.png"

import Temp7inv from "../../../../assets/images/INV0202.png"
import Temp7B from "../../../../assets/images/BILL0202.png"
import Temp8 from "../../../../assets/images/INV003REC.png"
import po1 from "../../../../assets/images/PO01.png"
import po2 from "../../../../assets/images/PO02.png"
import po3 from "../../../../assets/images/PO05.png"
import po4 from "../../../../assets/images/PO3.png"
import { index } from "d3"
import { getSymbol } from "../../../../config/helper";




const Templates = ({ type, tempaltesData = [], moduleName, hasWriteAccess = false }) => {
  console.log("type", type)
  const [selectedTemplateId] = useState(type);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewId, setPreviewId] = useState('template_01')
  const dispatch = useDispatch()
  const [obj, setObj] = useState(localStorage.getItem("templateMap") ? JSON.parse(localStorage.getItem("templateMap")) : {})
  const tempRes = useSelector(state => state.templates?.updateTemplates?.data);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handlePreviewClick = (templateId) => {
    setDropdownVisible(!dropdownVisible);
    setPreviewId(templateId)
    setIsModalVisible(true);

  };

  useEffect(() => {
    if (tempRes?.message === 'Success') {

      message.success(<span className={"messageText"}>Selected Template has been set as default</span>)
      dispatch({ type: UpdateTemplates.RESET })
      dispatch(allTemplates({ module_type: moduleName }))
    }
    else if (tempRes?.error) {
      message.error(<span className={"messageText"}>{tempRes?.messages}</span>)
      dispatch({ type: UpdateTemplates.RESET })

    }
  }
    , [tempRes?.data])



  // console.log("previewid==",previewId)
  //   const handlePreviewClick = (templateId) => {
  //    setPreviewId(templateId)
  //     setIsModalVisible(true);
  //   };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  // useEffect(() => {
  //   dispatch(headerTitles({
  //     title: 'Templates',
  //     description: ""
  //   }))
  // }, [])

  // const dropdownOptions = [
  //   { value: 'credit_note', label: 'Credit Note Templates' },
  //   { value: 'sales', label: 'Sales Order Templates' },
  //   { value: 'po', label: 'Purchase Order Templates' },
  //   { value: 'estimates', label: 'Estimate Templates' },
  //   { value: 'bills', label: 'Bill Templates' },
  //   { value: 'invoice', label: 'Invoice Templates' },

  // ];


  // const templates = useMemo(() => [
  //   { id: "credit", image: CreditImg, allow: true },
  //   { id: "inv2", image: invImgTwo, allow: true },
  //   { id: "inv", image: invImgThree, allow: true },
  //   { id: "inv1", image: invImgFour, allow: true },
  //   {id:"inv3",image:invoicenew,allow:['invoice']}
  // ], [obj]);


  const useDummyData = true;
  const useDummy = true;
  const object = {
    invoiceNo: "INV3001",
    invoiceDate: "18-08-2023",
    Address: "cokarma,madhapur-500081",
    lineItems: [
      {
        item_name: "laptop",
        total_amount: "100000",
        tax_amount: "1000",
        quantity: 1,
        unit_price: "1000"
      }
    ],
    totalAmount: "1000",
    totalTax: "1000",
    taxPer: "10%",
    gst: 'GST24AAACC4175D',
    title: "Credit Note",
    creditNo: "1234",
    subTotal: "345",
    balance: "Balance Due",
    balanceAmt: "2344",
    totalDiscount: "234",
    creditTitle: "CreditNote#1234",
    invoiceTitle: "Invoice No",
    invoiceDateTitle: "Invoice Date",
    billNo: "PO_1234",
    bankName: "STANDARD CHARTARED",
    bankAccount: "32630542608",
    companyName: "Purple Plum Digital Solutions",
    poTitle: "PURCHASE ORDER",
    invTitle: "INVOICE",
    terms: "monthly",
    mobile: "9848111222",
    ifscCode: "SCBN000999",
    email: "purple@digitalsolutions",
    subTotal: "10000"



  }

  const getObjData = (moduleName) => {
    switch (true) {

      case (moduleName === 'CREDIT'):
        return {
          CreditNo: 'CN0001',
          balanceAmount: '2000',
          discount: "100",
          creditNoteDate: '10-09-2023',
          invoiceNo: 'INV123',
          invoiceDate: '10-09-2023',
          subTotal: '100000',
          discount: '1000',
          totalTax: '20',
          totalAmount: '1000',
          currency: getSymbol(),
          lineItems: [
            {
              item_name: "laptop",
              total_amount: "100000",
              tax_amount: "1000",
              quantity: 1,
              unit_price: "1000"
            }
          ],
          title: "Credit Note",
          noteTitle: "Credit Note#",
          creditTitle: "Credit No",
          benefiCompany: "Purpleplum",
          balanceTitle: 'Balance Due',
          numTitle: 'Invoice#',
          dateTitle: "Invoice Date",
          gst: 'GST24AAACC4175D',
          address: 'Jubilee Hills, Hyderabad, Telangana 500033',
          terms: '',
          poDue: '',
          companyName: 'Purpleplum Digital',
          mobile: "8790292167",
          email: "purpleplum@design.com",
          bankName: "STANDARD CHARTARED",
          bankAccount: "32620542807",
          ifscCode: "SCBN000999",
          bankBranch: "Hyderabd",
          mobile: "8790292167",
          email: "purpleplum@design.com",
          beniName: "Sanjay",
          beniEmail: "sanjay.adapa@gmail.com",
          taxRates: {
            '6': 6000,
            '7': 7000
          },
          tdsFlag: false,
          balanceFlag: false,
          termsFlag: true



        }
      case (moduleName === 'SALES_ORDER'):
        return {
          // CreditNo: 'CN0001',
          balanceAmount: '2000',
          discount: "100",
          creditNoteDate: '10-09-2023',
          invoiceNo: 'SO019',
          invoiceDate: '10-09-2023',
          subTotal: '100000',
          discount: '1000',
          totalTax: '20',
          totalAmount: '1000',
          lineItems: [
            {
              item_name: "laptop",
              total_amount: "100000",
              tax_amount: "1000",
              quantity: 1,
              unit_price: "1000"
            }
          ],
          title: "Sales Order",
          noteTitle: "Sales Order#",
          benefiCompany: "Purpleplum",
          balanceTitle: 'Balance Due',
          numTitle: 'Sales No',
          dateTitle: "Date",
          gst: 'GST24AAACC4175D',
          address: 'Jubilee Hills, Hyderabad, Telangana 500033',
          terms: 'mothly',
          poDue: '10/09/2023',
          companyName: 'Purpleplum',
          mobile: "8790292167",
          email: "purpleplum@design.com",
          bankName: "STANDARD CHARTARED",
          bankAccount: "32620542807",
          ifscCode: "SCBN000999",
          bankBranch: "Hyderabd",
          currency: getSymbol(),
          mobile: "8790292167",
          email: "purpleplum@design.com",
          beniName: "Sanjay",
          beniEmail: "sanjay.adapa@gmail.com",
          taxRates: {
            '6': 6000,
            '7': 7000
          },
          tdsFlag: false,
          balanceFlag: false,
          termsFlag: true,
          dueTitle: "Expected Shipment Date"
        }
      case (moduleName === 'ESTIMATE'):
        return {
          // CreditNo: 'EST001',
          discount: "100",
          balanceAmount: '1000',
          creditNoteDate: '20-09-2023',
          invoiceNo: 'EST001',
          invoiceDate: '03-09-2023',
          subTotal: '1000',
          totalDiscount: '10%',
          currency: getSymbol(),
          totalTax: '2000',
          totalAmount: '1000',
          lineItems: [
            {
              item_name: "laptop",
              total_amount: "100000",
              tax_amount: "1000",
              quantity: 1,
              unit_price: "1000"
            }
          ],
          title: 'ESTIMATE',
          noteTitle: "Estimate#",
          balanceTitle: 'Balance Due',
          numTitle: 'Estimate No',
          dateTitle: "Date",
          dueTitle: "Expiry Date",
          gst: 'GST24AAACC4175D',
          address: 'Jubilee Hills, Hyderabad, Telangana 500033',
          benefiCompany: "Purpleplum",
          terms: 'monthly',
          poDue: '09-12-2023',
          companyName: 'Purpleplum',
          bankName: "STANDARD CHARTARED",
          bankAccount: "32620542807",
          ifscCode: "SCBN000999",
          bankBranch: "Hyderabd",
          benefiCompany: "Purpleplum",
          discount: "100",
          mobile: "8790292167",
          email: "purpleplum@design.com",
          beniName: "Sanjay",
          beniEmail: "sanjay.adapa@gmail.com",
          taxRates: {
            '6': 6000,
            '7': 7000
          },
          tdsFlag: false,
          balanceFlag: false,
          termsFlag: false
        }
      case (moduleName === 'INVOICE' || moduleName === 'INVOICE_RECEIPT'):
        return {
          // CreditNo: 'INV001',
          discount: "100",
          balanceAmount: '1000',
          creditNoteDate: '20-10-2023',
          currency: getSymbol(),
          type: "INVOICE_RECEIPT",
          invoiceNo: 'INV001',
          discount: "100",
          invoiceDate: '10-09-2023',
          subTotal: '1000',
          totalDiscount: '10%',
          totalTax: '1000',
          totalAmount: '1000',
          lineItems: [
            {
              item_name: "laptop",
              total_amount: "100000",
              tax_amount: "1000",
              quantity: 1,
              unit_price: "1000"
            }
          ],
          title: 'INVOICE',
          noteTitle: "Invoice#",
          balanceTitle: 'Balance Due',
          numTitle: 'Invoice No',
          dateTitle: "Invoice Date",
          gst: 'GST24AAACC4175D',
          address: 'Jubilee Hills, Hyderabad, Telangana 500033',
          terms: 'monthly',
          poDue: '10-09-2023',
          companyName: 'Purpleplum Digital',
          bankName: "STANDARD CHARTARED",
          bankAccount: "32620542807",
          benefiCompany: "Purpleplum",
          ifscCode: "SCBN000999",
          bankBranch: "Hyderabd",
          discount: "100",
          mobile: "8790292167",
          email: "purpleplum@design.com",
          receiptNo: "123",
          beniName: "Sanjay",
          beniEmail: "sanjay.adapa@gmail.com",
          taxRates: {
            '6': 6000,
            '7': 7000
          },
          tdsFlag: false,
          balanceFlag: true,
          termsFlag: true,
          refNo: "111111",
          paymentMode: "Cash",
          dueTitle: "Due Date",

        }
      case (moduleName === 'BILL' || moduleName === 'BILL_RECEIPT'):
        return {
          // CreditNo: 'bill0001',
          balanceAmount: '2000',
          currency: getSymbol(),
          creditNoteDate: '10-09-2023',
          invoiceNo: 'BILLS055',
          invoiceDate: '10-10-2023',
          discount: "100",
          benefiCompany: "Purpleplum",
          subTotal: '1000',
          totalDiscount: '10%',
          totalTax: '1000',
          totalAmount: '1000',
          tdsAmt: "2343",
          lineItems: [
            {
              item_name: "laptop",
              total_amount: "100000",
              tax_amount: "1000",
              quantity: 1,
              unit_price: "1000"
            }
          ],
          title: 'BILL',
          noteTitle: "Bill#",
          balanceTitle: 'Balance Due',
          numTitle: 'Bill No',
          dateTitle: "Bill Date",
          gst: 'GST24AAACC4175D',
          address: 'Jubilee Hills, Hyderabad, Telangana 500033',
          terms: 'monthly',
          poDue: '11-11-2023',
          companyName: 'Purpleplum Digital',
          mobile: "8790292167",
          email: "purpleplum@design.com",
          bankName: "STANDARD CHARTARED",
          bankAccount: "32620542807",
          ifscCode: "SCBN000999",
          bankBranch: "Hyderabd",
          mobile: "8790292167",
          email: "purpleplum@design.com",
          gst: 'GST24AAACC4175D',
          address: 'Jubilee Hills, Hyderabad, Telangana 500033',
          taxRates: {
            '6': 6000,
            '7': 7000
          },
          tdsFlag: true,
          balanceFlag: true,
          termsFlag: true,
          refNo: "111111",
          paymentMode: "Cash",
          type: "BILL_RECEIPT",
          tdsAmut: 100,
          dueTitle: "Due Date",
        };
      case (moduleName === 'PURCHASE_ORDER'):
        return {
          // CreditNo: 'po0001',
          balanceAmount: '1000',
          creditNoteDate: '10-10-2023',
          invoiceNo: 'PO02',
          invoiceDate: '10-10-2023',
          currency: getSymbol(),
          subTotal: '1000',
          totalDiscount: '10%',
          totalTax: '1000',
          discount: "100",
          totalAmount: '1000',
          tdsAmt: "2343",
          lineItems: [
            {
              item_name: "laptop",
              total_amount: "100000",
              tax_amount: "1000",
              quantity: 1,
              unit_price: "1000"
            }
          ],
          title: 'PURCHASE ORDER',
          noteTitle: "Purchase Order#",
          balanceTitle: 'Balance Due',
          numTitle: 'PO No',
          dateTitle: "PO Date",
          gst: 'GST24AAACC4175D',
          address: 'Jubilee Hills, Hyderabad, Telangana 500033',
          terms: 'monthly',
          poDue: '10-10-203',
          companyName: 'purplepum',
          benefiCompany: "Purpleplum",
          mobile: "8790292167",
          email: "purpleplum@design.com",
          bankName: "STANDARD CHARTARED",
          bankAccount: "32620542807",
          ifscCode: "SCBN000999",
          bankBranch: "Hyderabd",
          tdsAmt: "2343",
          beniName: "Sanjay",
          beniEmail: "sanjay.adapa@gmail.com",
          taxRates: {
            '6': 6000,
            '7': 7000
          },
          tdsFlag: true,
          balanceFlag: false,
          termsFlag: true,
          tdsAmut: 100,
          dueTitle: "Expected Delivery Date"
        }
      default:
        return {
          CreditNo: '',
          balanceAmount: '',
          creditNoteDate: '',
          invoiceNo: '',
          invoiceDate: '',
          subTotal: '',
          totalDiscount: '',
          totalTax: '',
          totalAmount: '',
          lineItems: '',
          title: 'Credit Note',
          noteTitle: "Credit Note#",
          balanceTitle: 'Balance',
          numTitle: 'Invoice No',
          dateTitle: 'Invoice Date',
          gst: '',
          address: '',
          terms: '',
          poDue: '',
          companyName: '',
          tdsFlag: '',
          balanceFlag: '',
          termsFlag: ''


        }
    }
  };
  const objData = getObjData(moduleName);
  const moduleImageMap = {
    INVOICE: {
      template_01: invoice1,
      template_02: invoice2,
      template_03: invoice4,
      template_04: invoice3,
      template_05: INV4,
    },
    ESTIMATE: {
      template_01: Template1,
      template_02: Template4,
      template_03: Template3,
      template_04: Template2,
      template_05: EST4,
    },
    SALES_ORDER: {
      template_01: sales1,
      template_02: sales2,
      template_03: sales4,
      template_04: sales3,
      template_05: SALES4,
    },
    PURCHASE_ORDER: {
      template_01: po1,
      template_02: po3,
      template_03: po4,
      template_04: po2,
      template_05: PURCHASE4,
    },
    BILL: {
      template_01: bill1,
      template_02: bill3,
      template_03: bill4,
      template_04: bill2,
      template_05: BLL4,
    },
    BILL_RECEIPT: {
      template_06: Temp6B,
      template_07: Temp7B,
      template_08: Temp8
    },
    INVOICE_RECEIPT: {
      template_06: Temp6INv,
      template_07: Temp7inv,
      template_08: Temp8
    },
    CREDIT: {
      template_03: credit1,
      template_04: credit2,
      template_05: credit3,
    }
  };

  // Determine the image source based on the moduleName and previewId
  const imageSrc = moduleImageMap[moduleName]

  return (
    <div>
      {/* <div >
        <Select

          placeholder="Select"
          options={dropdownOptions}
          onChange={(selectedOption, data) => {
            console.log("selectedoptions", selectedOption,)
            setSelectedTemplateId(selectedOption)
            const selectedTemplateTitle = data.label;
            
            console.log("selectedoptions", selectedTemplateTitle)
            dispatch(headerTitles({
              title: selectedTemplateTitle,
              description: ""
            }));


          }}
        />
      </div> */}


      <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "30px", marginTop: "30px", width: "95%", gap: "50px" }}>

        {tempaltesData?.map((template, index) => (

          //  template?.allow === true || template?.allow?.includes(selectedTemplateId) ?
          <Template
            key={template?.template_id}
            obj={obj}
            setObj={setObj}
            selectedTemplateId={selectedTemplateId}
            templateId={template?.template_id}
            imageSrc={moduleImageMap[moduleName]?.[template?.template_name] || CreditImg}
            templateName={template?.template_name.split('_')[0].charAt(0).toUpperCase() + template?.template_name.split('_')[0].slice(1) + " " + (index + 1).toString()}
            tempaltesData={tempaltesData}
            moduleName={moduleName}
            isDefault={template?.is_default}
            handlePreviewClick={() => handlePreviewClick(template?.template_name)}
            hasWriteAccess={hasWriteAccess}
          />

        ))}
      </div>
      <Modal
        title="Preview"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose
      >
        {previewId === 'template_01' && (
          <CreditPdf
            useDummyData={useDummyData}
            tdsFlag={obj?.tdsFlag}
            obj={objData}
          />
        )}
        {previewId === 'template_02' && (
          <InvPDF
            useDummyData={useDummyData}
            tdsFlag={obj?.tdsFlag}
            obj={objData}
          />
        )}
        {previewId === 'template_03' && (
          <InvPDF2
            tdsFlag={obj?.tdsFlag}
            useDummyData={useDummyData}
            obj={objData}
          />
        )}
        {previewId === 'template_04' && (
          <InvoicePDF
            useDummyData={useDummyData}
            tdsFlag={obj?.tdsFlag}
            obj={objData}
          />

        )}
        {previewId === 'template_05' && (
          <Purpleinv
            useDummyData={useDummyData}
            tdsFlag={obj?.tdsFlag}
            obj={objData}
          />)}

        {previewId === 'template_06' && (
          <PaymentReceivedTwo
            useDummyData={useDummyData}
            tdsFlag={obj?.tdsFlag}
            obj={objData}
          />)}


        {previewId === 'template_07' && (
          <PaymentReceived
            useDummyData={useDummyData}
            tdsFlag={obj?.tdsFlag}
            obj={objData}
          />)}
        {previewId === 'template_08' && (
          <Payments
            useDummyData={useDummyData}
            tdsFlag={obj?.tdsFlag}
            obj={objData}
          />)}

      </Modal>
    </div>

  );
};



const Template = ({ selectedTemplateId, templateName, templateId, imageSrc, handlePreviewClick, obj, isDefault, setObj, moduleName, hasWriteAccess }) => {
  const dispatch = useDispatch()
  //var obj = localStorage.getItem("templateMap") ? JSON.parse(localStorage.getItem("templateMap")) : {};
  const [popoverVisible, setPopoverVisible] = useState(false);
  const handlePopoverVisibleChange = (visible) => {
    // Close the Popover when an option is selected

    setPopoverVisible(visible);

  };
  const handleIconClick = () => {
    setPopoverVisible(!popoverVisible);
    // setIsSettingClick(!isSettingClick)
  };

  return (
    <div

      className={`${isDefault ? "template_active" : "template_border"} ${popoverVisible ? "hover" : ""}`}
      style={{ position: "relative", cursor: "pointer", border: "1px solid grey" }}>

      {isDefault ? (
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        >
          <img src={successIcon} />
        </div>
      ) : null}

      <img style={{ cursor: "pointer" }} src={imageSrc} alt={`${templateId} PDF`} />
      <div style={{ marginTop: "10px", textAlign: "center" }}>{templateName}</div>
      <div
        className="footer"
        style={{
          border: "#353A40",
          position: "absolute",
          bottom: 0,
          background: "#353A40",
          height: "15%",
          width: "100%",
          transition: "opacity 0.3s ease-in-out",
        }}
      >

        <Popover
          content={
            <>
              <Button
                className={`option set_default`}
                style={{
                  padding: "8px 4px 4px",
                  borderRadius: "8px",
                  display: "block"
                }}
                onClick={() => {
                  handlePreviewClick();
                  setPopoverVisible(false); // Close the Popover after selecting an option
                }}
              >
                Preview
              </Button>
              <Button
                className={`option set_default`}
                style={{
                  padding: '5px',
                  color: "#000"
                }}
                disabled={!hasWriteAccess}
                onClick={() => {
                  // message.success(<span className={"messageText"}>Selected Template has been set as default</span>)
                  obj[selectedTemplateId] = templateId;
                  setObj({ ...obj })
                  //localStorage.setItem("templateMap", JSON.stringify(obj));
                  setPopoverVisible(false);
                  dispatch(updateTemplates({ module_type: moduleName, id: templateId, is_default: true }))
                }}
              >
                Set as Default
              </Button>
            </>
          }
          trigger="click"
          placement="bottomLeft"
          open={popoverVisible}
          onOpenChange={handlePopoverVisibleChange}
        >
          <img src={settingIcon} style={{ height: "60%", width: "20%" }} alt="Preview" onClick={handleIconClick} />
        </Popover>

      </div>
    </div>
  );

}
export default Templates;

















