import { ActionTypesFactory } from '../../../../utils'
export const OnBoardPost = ActionTypesFactory("OnBoardPost", "OnBoardPost")
export const STATUS = "STATUS"  
export const GetOnboardFields = ActionTypesFactory("GetOnboardFields", "GetOnboardFields")
export const OnboardCreate = ActionTypesFactory("OnboardCreate", "OnboardCreate")
export const OnboardStep = ActionTypesFactory("OnboardStep", "OnboardStep")
export const OnboardStepPreview = ActionTypesFactory("OnboardStepPreview", "OnboardStepPreview")
export const OnboardFinalSubmit = ActionTypesFactory("OnboardFinalSubmit", "OnboardFinalSubmit")

export const postOnBoard = (payload) => {
    return {
        type: OnBoardPost.REQUEST,
        payload
    }
}
export const createOnBoardForm = (payload) => {
    return {
        type: STATUS,
        step: payload
    }
}

export const onboardCreate = (payload) => {
    return {
        type: OnboardCreate.REQUEST,
        payload
    }
}
export const onboardStep = (payload) => {
    return {
        type: OnboardStep.REQUEST,
        payload
    }
}
export const onboardStepPreview = (payload) => {
    return {
        type: OnboardStepPreview.REQUEST,
        payload
    }
}
export const getOnboardFields = (payload) => {
    return {
        type: GetOnboardFields.REQUEST,
        payload
    }
}
export const onboardFinalSubmit = (payload) => {
    return {
        type: OnboardFinalSubmit.REQUEST,
        payload
    }
}