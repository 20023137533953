import { takeLatest } from "redux-saga/effects";
import { apis } from "../../../../config/APIs";
import { sagaFunctions } from "../../../../utils";
import { GetOnboardFields, OnBoardPost, OnboardCreate, OnboardFinalSubmit, OnboardStep, OnboardStepPreview } from "./onboard";


function postOnBoard(req) {
    return sagaFunctions(
        OnBoardPost,
        "post",
        apis.postOnBoard,
        req.payload,
        {},
        true,
    )();
}
function getOnboardFields(req) {
    return sagaFunctions(GetOnboardFields, "get", apis.getOnboardFields, req.payload, {}, "settings")();
}
function onboardCreate(req) {
    return sagaFunctions(OnboardCreate, "post", apis.onboardCreate, req.payload, {}, "settings")();
}
function onboardStep(req) {
    return sagaFunctions(OnboardStep, "get", apis.onboardStep, req.payload, {}, "settings")();
}
function onboardStepPreview(req) {
    return sagaFunctions(OnboardStepPreview, "get", apis.onboardStep, req.payload, {}, "settings")();
}
function onboardFinalSubmit(req) {
    return sagaFunctions(OnboardFinalSubmit, "post", apis.onboardFinalSubmit, req.payload, {}, "settings")();
}

export function* onboardWarcher() {
    yield takeLatest(OnBoardPost.REQUEST, postOnBoard)
    yield takeLatest(GetOnboardFields.REQUEST, getOnboardFields)
    yield takeLatest(OnboardCreate.REQUEST, onboardCreate)
    yield takeLatest(OnboardStep.REQUEST, onboardStep)
    yield takeLatest(OnboardStepPreview.REQUEST, onboardStepPreview)
    yield takeLatest(OnboardFinalSubmit.REQUEST, onboardFinalSubmit)
}