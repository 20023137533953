import React, { Fragment } from 'react'
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import { amountFormat } from '../../utils';
import BaseCurrency from '../Common/BaseCurrency';

const TxnPDF = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      paddingTop: 60,
      paddingLeft: 10,
      paddingRight: 10,
      lineHeight: 1.5,
      flexDirection: 'column',
    },
    mainContainer: {
      width: '100%',
      flexDirection: "column",
    },
    Headercontainer: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
      alignItems: 'center',
      fontStyle: 'bold',
      paddingBottom: '5px',
      paddingTop: '10px',
      paddingLeft: '15px'
    },
    text: {
      width: "12.5%",
      marginRight: "5px",
      fontSize: "9px",
      color: "#333333"
    },
    Datacontainer: {
      flexDirection: 'row',
      alignItems: 'center',
      fontStyle: 'bold',
      paddingBottom: '5px',
      paddingTop: '10px',
      paddingLeft: '15px',
      border: "1px solid #E4E4E4",
      borderRadius: '5px',
      width: '100%',
      marginTop: '5px',
    },
    data: {
      width: "12.5%",
      marginRight: "5px",
      fontSize: "9px",
      color: "#333333"
    },
  });
  const Columns = ["Transaction ID", "Date & Time", "Terminal ID", "Merchant Name", "Amount", "Wallet Name", "Transaction Nature"]
  const wrapText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    const words = text.split('');
    let wrappedText = '';
    let line = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (line.length + word.length <= maxLength) {
        line += word + '';
      } else {
        wrappedText += line.trim() + '\n';
        line = word + '';
      }
    }

    return wrappedText + line.trim();
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.mainContainer}>
          <View style={styles.Headercontainer}>
            {
              Columns.map((item, index) => {
                return <Text key={index} style={styles.text}>{item}</Text>
              })
            }
          </View>
          {
            data?.map((items, index) => {
              return (
                <View style={styles.Datacontainer}>
                  <Text style={styles.data}>{items?.vendor_transaction_id ? wrapText(items?.vendor_transaction_id, 7) : "-"}</Text>
                  <Text style={styles.data}>{items?.transaction_date ? items?.transaction_date : "-"}</Text>
                  <Text style={styles.data}>{items?.terminalId ? items?.terminalId : "-"}</Text>
                  <Text style={styles.data}>{items?.merchant ? items?.merchant : "-"}</Text>
                  <Text style={styles.data}><BaseCurrency />{items?.amount ? amountFormat(items?.amount) : "-"}</Text>
                  <Text style={styles.data}>{items?.wallet_name ? items?.wallet_name : "-"}</Text>
                  <Text style={styles.data}>{items?.channelType ? items?.channelType : "-"}</Text>
                </View>

              )
            })
          }
        </View>
      </Page>
    </Document>
  )
}

export default TxnPDF;