import { ReducerFunctions } from "../../utils";
import {
  AnalyticsCards,
  AnalyticsCardsAll,
  AnalyticsCardsExport,
  Block,
  CardLimitEnable,
  CardLimitSet,
  UpdateCardLimitSet,
  CardPolicies,
  Cards,
  CardsDetails,
  CardTxnType,
  CorporateBudget,
  DownloadBulk,
  GetCardLimit,
  GetCardPolicies,
  QucikLogs,
  Replacement,
  Topup,
  Transactions,
  TransferLogs,
  UploadBulk,
  UserCards,
  ShowCardPolicy,
  CardPoliciesDelete,
  SetCardPolicy,
  SetPin,
  ActivateCard,
  UpdateMobileNumber,
  VerifyOTP,
  SendOTP,
  RemindCardActivation,
  AnalyticsEstimates,
  BlockReasons,
  CreateInviteLink,
  GetOneCompany,
  GetAllOrganisations,
  SyncData,
  DisconnectCompany,
  GetSyncHistory
} from "./cards";

export const cards_reducer = ReducerFunctions(Cards, {});
export const send_otp_reducer = ReducerFunctions(SendOTP, {});
export const verify_otp_reducer = ReducerFunctions(VerifyOTP, {});
export const update_mobile_reducer = ReducerFunctions(UpdateMobileNumber, {});
export const activate_card_reducer = ReducerFunctions(ActivateCard, {});
export const set_pin_reducer = ReducerFunctions(SetPin, {});
export const show_card_policy_reducer = ReducerFunctions(ShowCardPolicy, {});
export const set_card_policy_reducer = ReducerFunctions(SetCardPolicy, {});
export const delete_card_policy_reducer = ReducerFunctions(CardPoliciesDelete, {});
export const card_enable_limit_reducer = ReducerFunctions(CardLimitEnable, {});
export const card_txn_type_reducer = ReducerFunctions(CardTxnType, {});
export const quick_logs_reducer = ReducerFunctions(QucikLogs, {});
export const get_card_limit = ReducerFunctions(GetCardLimit, {});
export const get_card_policies = ReducerFunctions(GetCardPolicies, {});
export const card_set_policies_reducer = ReducerFunctions(CardLimitSet, {});
export const card_edit_set_policies_reducer = ReducerFunctions(UpdateCardLimitSet, {});
export const card_policies_reducer = ReducerFunctions(CardPolicies, {});
export const analytics_cards_reducer = ReducerFunctions(AnalyticsCards, {});
export const analytics_cards_all_reducer = ReducerFunctions(AnalyticsCardsAll, {});
export const analytics_cards_export_reducer = ReducerFunctions(
  AnalyticsCardsExport,
  {}
);
export const transfer_logs_reducer = ReducerFunctions(TransferLogs, {});
export const replacement_reducer = ReducerFunctions(Replacement, {});
export const corporate_budget_reducer = ReducerFunctions(CorporateBudget, {});
export const userCards_reducer = ReducerFunctions(UserCards, {});
export const cardsDetails_reducer = ReducerFunctions(CardsDetails, {});
export const transactions_reducer = ReducerFunctions(Transactions, {});
export const topup_reducer = ReducerFunctions(Topup, {});
export const block_reducer = ReducerFunctions(Block, {});
export const download_bulk_reducer = ReducerFunctions(DownloadBulk, {});
export const upload_bulk_reducer = ReducerFunctions(UploadBulk, {});
export const remind_card_activation_reducer = ReducerFunctions(RemindCardActivation, {});
export const analytics_estimates_reducer = ReducerFunctions(AnalyticsEstimates, {});
export const block_reasons_reducer = ReducerFunctions(BlockReasons, {});


/// RootiFi ////
export const createInviteLink_reducer = ReducerFunctions(CreateInviteLink,{});
export const getOneCompany_reducer = ReducerFunctions(GetOneCompany,{});
export const getAllOrganisations_reducer = ReducerFunctions(GetAllOrganisations,{});
export const syncData_reducer = ReducerFunctions(SyncData,{});
export const disconnectCompany_reducer = ReducerFunctions(DisconnectCompany,{});
export const getSyncHistory_reducer = ReducerFunctions(GetSyncHistory,{});
