import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import React, { useRef } from "react";
import { useFieldArray } from "react-hook-form";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import { FormInput } from "../inputs";
import { useTranslation } from "react-i18next";

const AddVendorPaymentDetails = ({ control, errors, register, setValue }) => {
  const { onCall } = usePayAxios({ api: apis.getIfscDetails, method: "get" });
  const ifscRef = useRef();
  const { append, fields, remove } = useFieldArray({
    control,
    name: "bank_account_details",
  });

  const handleDelete = (index) => {
    remove(index);
    if (fields.length === 0) {
      append({
        bank_name: "",
        account_number: "",
        swift_code: "",
        bank_country: "",
        ifsc_code: "",
        account_status: true,
      });
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {fields.map((personData, index) => {
        return (
          <div key={personData.id}>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`bank_account_details[${index}].ifsc_code`, {
                  pattern: {
                    value: /^[A-Z]{4}0[A-Z0-9]{6}$/i,
                    message: "Enter valid ifsc",
                  },
                  onChange: (e) => {
                    if (ifscRef.current) {
                      clearTimeout(ifscRef.current);
                    }
                    ifscRef.current = setTimeout(() => {
                      if (e.target.value) {
                        onCall({
                          params: {
                            ifsc_code: e.target.value,
                          },
                        })
                          .then((res) => {
                            if (!res?.error) {
                              setValue(
                                `bank_account_details[${index}].bank_name`,
                                res.data.bank_name
                              );
                              setValue(
                                `bank_account_details[${index}].branch`,
                                res.data.branch
                              );
                            } else {
                              if (e.target.value)
                                message.error(
                                  <span className="messageText">
                                    {t("bank_account_Details")}
                                  </span>
                                );
                              setValue(
                                `bank_account_details[${index}].bank_name`,
                                ""
                              );
                              setValue(
                                `bank_account_details[${index}].branch`,
                                ""
                              );
                            }
                          })
                          .catch(() => {
                            if (e.target.value)
                              message.error(
                                <span className="messageText">
                                  {t("bank_account_Details")}
                                </span>
                              );
                            setValue(
                              `bank_account_details[${index}].bank_name`,
                              ""
                            );
                            setValue(
                              `bank_account_details[${index}].branch`,
                              ""
                            );
                          });
                      }
                    }, 1000);
                  },
                })}
                label="IFSC Code"
                errorMessage={
                  errors.bank_account_details?.[index]?.["ifsc_code"]?.[
                  "message"
                  ]
                }
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`bank_account_details[${index}].bank_name`)}
                label={t("bank_name")}
                // value={personData?.bank_name}
                errorMessage={
                  errors.bank_account_details?.[index]?.["bank_name"]?.[
                  "message"
                  ]
                }
              />
            </div>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`bank_account_details[${index}].account_number`, {
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                  },
                })}
                label={t("account_num")}
                errorMessage={
                  errors.bank_account_details?.[index]?.["account_number"]?.[
                  "message"
                  ]
                }
              />
            </div>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`bank_account_details[${index}].branch`, {
                  pattern: {
                    value: /^[a-zA-Z0-9 ]+$/,
                  },
                })}
                // value={personData?.branch}
                label={t("branch")}
                errorMessage={
                  errors.bank_account_details?.[index]?.["branch"]?.["message"]
                }
              />
            </div>

            <div>
              <span></span>
            </div>

            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {fields.length > 0 && index === fields.length - 1 ? (
                <Button
                  type="text"
                  className="pp-dropdown-add-new"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    append({
                      bank_name: "",
                      account_number: "",
                      ifsc_code: "",
                      branch: "",
                    });
                  }}
                >
                  <span>{t("add_more_details")}</span>
                </Button>
              ) : (
                <Button
                  type="text"
                  className="pp-dropdown-add-new"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  <span>{t("remove")}</span>
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AddVendorPaymentDetails;
