
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup';
import { addAdvancesField, updateAdvancesField } from '../../../../actions/companySettings';
import { findLodash } from '../../../../utils';
import "./../../styles/setting.css";


const { Option } = Select;

const ValidationSchema = yup.object().shape({
  column_name: yup
    .string().matches(/^[a-zA-Z]+[a-zA-Z0-9\s]*$/, 'Please Enter Proper Name')
    .required('Please provide Field name'),
  column_type: yup.string().required('Please provide Column type'),
  default_values: yup.mixed().when('column_type', {
    is: 'dropdown',
    then: yup.array().of(yup.object().shape({
      id: yup.string().required(),
      level: yup.string().required(),
    })).required().min(1),
    otherwise: yup.mixed()
  }),
  data: yup.mixed().when("column_type", {
    is: "document",
    then: yup.object().shape({
      max_size: yup.string().required('Max Size is required').min(2),
      doc_formats: yup.array().required('Document Formats are required').min(1).of(yup.string())
    })
  }),
});

const AddAdvancesField = (props) => {
  const { t } = useTranslation();
  const { id: currentId } = props;
  const dispatch = useDispatch();
  const advancesFieldResponse = useSelector(state => state.companySetting.getAdvancesField || {});
  // const addAdvancesFieldResponse = useSelector(state => state.companySetting.addAdvancesField || {});
  const [uploadLogoData, setUploadLogoData] = useState({})
  const formik = useFormik({
    initialValues: {
      column_name: "",
      column_type: "",
      is_required: true,
      is_enable: true,
      is_editable: true,
      view_in_pdf: true,
      default_values: [{ id: 1, level: '' }],
      data: {
        doc_formats: [],
        max_size: ""
      }
    },

    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      const payload = JSON.parse(JSON.stringify({ ...values }));
      if (values.column_type !== "document") {
        [
          'column_name',
          'column_type',
          'is_required',
          'is_enable',
          'is_editable',
          'view_in_pdf',
          'default_values',
        ].forEach(item => {
          payload[item] = values[item] || false;
        });
        delete payload.data
      }
      else {
        payload.data = {
          max_size: values.data.max_size || "",
          doc_formats: values.data.doc_formats.join(",") || ""
        }
      }

      const default_values = values?.default_values?.map(item => item.level) || [];

      if (values.column_type === 'dropdown') {
        payload.default_values = [...default_values];
      }
      else {
        payload.default_values = []
      }

      Object.keys(payload).forEach(key => {
        if (!payload[key] && key !== "is_required" && key !== "is_enable" && key !== "view_in_pdf" && key !== "is_editable" && key !== "default_values") {
          delete payload[key];
        }
      });
      payload.display_name = values.column_name
      currentId ?
        dispatch(updateAdvancesField(({ ...payload,params:{module_type:props.type}, id: currentId.toString() }))) :
        dispatch(addAdvancesField(({ ...payload, params: { module_type:props.type} })));


    }
  });

  React.useEffect(() => {
    formik.resetForm();
    if (advancesFieldResponse.success && currentId) {
      const editValues = findLodash(advancesFieldResponse.data.data, { id: currentId });
      formik.setValues({
        column_name: editValues.column_name,
        column_type: editValues.column_type,
        is_required: editValues.is_required,
        is_enable: editValues.is_enable,
        is_editable: editValues.is_editable,
        view_in_pdf: editValues.view_in_pdf,
        default_values: editValues.default_values?.map((ele, idx) => ({ id: idx + 1, level: ele })),
        data: {
          doc_formats: editValues.data?.doc_formats?.split(",")?.map((ele) => ele) || [],
          max_size: editValues.data?.max_size
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleCancelButton = () => {
    formik.resetForm();
    props.handleCancel();
  }

  const handleFields = (type, key) => {
    const fieldsData = formik.values.default_values ? formik.values.default_values : []

    if (type === 'add') {
      formik.setFieldValue('default_values', [...fieldsData, { id: fieldsData.length + 1, level: '' }]);

    } else {
      //  setFieldsDeleteData([...fieldsDeleteData, key])
      const filterArray = fieldsData.filter(obj => obj.id !== key);
      // filterArray.forEach((item, index) => { item['id'] = index + 1 });
      //  setFieldsData([...filterArray]);
      formik.setFieldValue('default_values', [...filterArray]);

    }
  }

  const handleInputs = (evt, key) => {
    const fieldsData = formik.values.default_values

    fieldsData[fieldsData.findIndex((obj => obj.id === key))].level = evt.target.value;
    //  setFieldsData([...fieldsData]);
    formik.setFieldValue('default_values', [...fieldsData]);

  }

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }

  };


  return (
    <>
      <Form
        layout="vertical"
        onFinish={formik.handleSubmit}
        className="modal-form custom-form"
      >
        <Form.Item
          label={t("custom_name")}
          required={true}
          rules={[{ required: true, message: t("please_enter_name") }]}
        >
          <Input
            placeholder={t("custom_name")}
            name="column_name"
            onChange={(e) => {
              formik.setFieldValue("column_name", e.target.value.trimStart());
            }}
            value={formik.values.column_name}
          />
          {formik.touched.column_name && (
            <div className="errorMsg">{formik.errors.column_name}</div>
          )}
        </Form.Item>
        <Form.Item
          label={t("column_type")}
          required={true}
          rules={[{ required: true, message: t("please_select_value") }]}
        >
          <Select
            name="column_type"
            value={formik.values.column_type}
            placeholder={t("column_type")}
            // onChange={(val) => setSelectedDataType(val)}
            onChange={(val) => {
              formik.setFieldValue("column_type", val);
            }}
          >
            <Option value="string">
              {t("text_box")}({t("single_line")})
            </Option>
            <Option value="integer">{t("number")} (123..)</Option>
            <Option value="dropdown">{t("drop_down")}</Option>
            <Option value="document">{t("Document")}</Option>
            <Option value="datetime">{t("DateTime")}</Option>
          </Select>
          {formik.touched.column_type && (
            <div className="errorMsg">{formik.errors.column_type}</div>
          )}
        </Form.Item>
        {formik.values.column_type === "dropdown" ? (
          <Form.Item label={t("default_value")}>
            <div className="optionsContainer">
              <Row>
                {formik.values?.default_values?.map((item, index) => (
                  <div key={item.id} className="fieldsDiv mt1">
                    <span className="inlineItem levelTitle">
                      {t("options")} {index + 1}
                    </span>
                    <Input
                      className="inlineItem"
                      onChange={(e) => handleInputs(e, item.id)}
                      value={item.level}
                      onKeyDown={handleKeyDown}
                    />
                    <MinusCircleOutlined
                      className="minusButton inlineItem mtHalf cursor ml2"
                      onClick={() => handleFields("remove", item.id)}
                    />
                  </div>
                ))}
              </Row>
              <Button
                type="link"
                className="addFieldButton mt2"
                onClick={() => handleFields("add")}
              >
                <PlusCircleOutlined /> {t("add_option")}
              </Button>
            </div>
            {formik.touched.default_values &&
              formik.errors.default_values?.length && (
                <div className="errorMsg">{t("options_are_required")}</div>
              )}
          </Form.Item>
        ) : null}
        {formik.values.column_type === "document" ? (
          <>
            {/* <Form.Item label={t('Document_Type')}> */}
            <div className="nameItem">
              <label className={`inline-block required`}>
                {t("Document Type")}
              </label>
              <br />
              <div className="fieldsDiv mt1">
                <Row>
                  <Select
                    name="doc_formats"
                    mode="multiple"
                    placeholder={t("Select doc_type")}
                    style={{ width: "30rem" }}
                    value={formik.values.data.doc_formats}
                    onChange={(val) =>
                      formik.setFieldValue(`data['doc_formats']`, val)
                    }
                  >
                    <Option value="jpg">{t('JPG')}</Option>
                    <Option value="jpeg">{t('JPEG')}</Option>
                    <Option value="png">{t('PNG')}</Option>
                    <Option value="pdf">{t('PDF')}</Option>
                    <Option value="csv">{t('CSV')}</Option>
                    <Option value="xls">{t('XLS')}</Option>
                    <Option value="xlsx">{t('XLSX')}</Option>
                    <Option value="tiff">{t('TIFF')}</Option>
                    <Option value="pptx">{t('PPT')}</Option>
                  </Select>
                  {formik.touched?.data?.doc_formats && formik.errors?.data?.doc_formats && <div className='errorMsg'>
                    {t('Document Type required')}
                  </div>}
                </Row>
              </div>
            </div>
            {/* </Form.Item> */}
            {/* <Form.Item label={t('Max_Size')}> */}
            <div className="nameItem">
              <label className={`inline-block required`}>{t("Max Size")}</label>
              <br />
              <Select
                name="max_size"
                placeholder={t("max_size")}
                value={formik.values.data.max_size}
                style={{ width: "30rem" }}
                onChange={(val) =>
                  formik.setFieldValue(`data['max_size']`, val)
                }
              >
                <Option value="500">{t("500KB")}</Option>
                <Option value="1000">{t('1 MB')}</Option>
                <Option value="2000">{t('2 MB')}</Option>
                <Option value="3000">{t('3 MB')}</Option>
              </Select>
              {formik.touched.data?.max_size && formik.errors.data?.max_size && <div className='errorMsg'>
                {t('Max Size required')}
              </div>}
            </div>
            {/* </Form.Item> */}
          </>
        ) : null}
        {/* {formik.values.column_type === 'document' ? 
          <>
          <Row>
            <img  src="" alt="text"/>
          <div className="optionsContainer">
            <Dragger>
              <p className="ant-upload-text">{t('drag_png')}<br />{t('click_here_to')}</p>
            </Dragger>
          </div>
          </Row>
          </>:null} */}
        <Row>
          <Col span={24}>
            <Form.Item valuePropName="checked">
              <Checkbox
                name="is_required"
                checked={formik.values.is_required}
                onChange={(e) =>
                  formik.setFieldValue("is_required", e.target.checked)
                }
              >
                {t("is_mandatory")}
              </Checkbox>
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item valuePropName="checked">
              <Checkbox name="view_in_pdf" checked={formik.values.view_in_pdf} onChange={(e) => formik.setFieldValue('view_in_pdf', e.target.checked)}>{t('show_in_pdf')}</Checkbox>
            </Form.Item>
          </Col> */}
        </Row>
        {/* <div className='previewDiv'>
            <span>Preview</span>
            <Form.Item label="Invoice Number" name="invoice_number">
                <Input placeholder="Invoice Number" />
            </Form.Item>
          </div> */}
        <Button key="1" htmlType="submit" className="formButton mt1">
          {currentId ? t("update") : t("save")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancelButton()}
        >
          {t("cancel")}
        </Button>
      </Form>
    </>
  );
}

export default AddAdvancesField;

