import { PlusCircleFilled, CloudUploadOutlined } from "@ant-design/icons";
import { Badge, Col, Menu, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Invoices, invoices } from "../../../actions/customers/customers";
import {
  SideMenu,
  get_seed_data,
  headerTitles
} from "../../../actions/masterdata/masterdata";
import { amountFormat, convertString } from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import TableComponent from "../../Common/TableComp/TableComp";
import { formatDate, optionsBills } from "../Common";
import TableHeader from "../TableHeader";

const MyInvoices = ({ isEstimates, isSales, isCN, user, hasWriteAccess }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  // const [openAddNew, setOpenAddNew] = useState(false);
  const data = useSelector(
    (state) => state.customers?.invoices?.data?.data || []
  );
  const invLoading = useSelector((state) => state.customers?.invoices?.loading);
  const totalRecords = useSelector(
    (state) => state.customers?.invoices?.data?.total_count
  );

  const createOptions = optionsBills(history);
  const dispatch = useDispatch();
  const [filters, setFilters] = React.useState({
    type: isCN ? "CREDIT" : isEstimates ? "ESTIMATE" : isSales ? "SALES_ORDER" : "INVOICE",
  });
  const [moduleName, setModuleName] = React.useState({
    type: isCN ? "credit Notes" : isEstimates ? "Estimates" : isSales ? "Sales Order" : "Invoice"
  })
  const [page, setPage] = React.useState(1);

  const sortOptions = [
    {
      label: t('customer_name'),
      filter: "customer_name",
    },
    // {
    //   label: isSales ? t('sales_no') : isEstimates ? t('estimate_no') : t('invoice_no'),
    //   filter: "invoice_no",
    // },
    {
      label: t('date'),
      filter: "invoice_date",
    },
    {
      label: isSales
        ? t('shipment_date')
        : isEstimates
          ? t('expiry_date')
          : t('due_date'),
      filter: "po_due_date",
    },
    {
      label: t('Amount'),
      filter: "total_amount",
    },
  ];

  const columns = [
    {
      title: isCN ? t('credit_note') : isSales ? t('sales_hash') : isEstimates ? t('estimate_hash') : t('invoice_hashh'),
      dataIndex: isCN ? "credit_debit_note_number" : "invoice_no",
      key: isCN ? "credit_debit_note_number" : "invoice_no",
      render: (text, record) => (
        <>
          {record?.integration_type &&
            <div className="base_badge_sales">
              <Badge.Ribbon text={convertString(record?.integration_type)} placement={"start"} color="#3f81f4" />
            </div>
          }
          {text}
        </>
      ),
    },
    {
      title: t('customer_name'),
      dataIndex: isCN ? "beneficiary_name" : "customer_name",
      key: isCN ? "beneficiary_name" : "customer_name",
    },
    isSales
      ? {
        title: t('estimate_hash'),
        dataIndex: "ref_po_no",
        key: "ref_po_no",
      }
      : isEstimates
        ? {}
        : {
          title: isCN ? t('invoice_hashh') : t('so_no'),
          dataIndex: isCN ? "invoice_ref_number" : "ref_po_no",
          key: isCN ? "invoice_ref_number" : "ref_po_no",
        },
    {
      title: t('date'),
      dataIndex: isCN ? "date" : "invoice_date",
      key: isCN ? "date" : "invoice_date",
      render: (text) => <div>{formatDate(text)}</div>,
    },
    isCN ? {} : {
      title: isSales
        ? t('shipment_date')
        : isEstimates
          ? t('expiry_date')
          : t('due_date'),
      dataIndex: "po_due_date",
      key: "po_due_date",
      render: (text) => (text &&
        <div className={moment().diff(text) <= 0 ? "" : "table-due-date"}>
          {formatDate(text)}
        </div>
      ),
    },
    {
      title: t('Amount'),
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text, record) => (
        <div>
          <BaseCurrency symbol_code={record?.currency_info?.iso_code} />{amountFormat(text, record?.currency_info?.decimal_scale)}
        </div>
      ),
    },
    isCN ? {
      title: t('balance_due'),
      dataIndex: isCN ? 'balance_amount' : 'balance_due',
      key: isCN ? 'balance_amount' : 'balance_due',
      render: (text, record) => <div><BaseCurrency symbol_code={record?.currency_info?.iso_code} />
        {amountFormat(text, record?.currency_info?.decimal_scale)}
      </div>,
    } : {},
    {
      title: t('status'),
      dataIndex: isCN ? "status" : "invoice_status",
      key: isCN ? "status" : "invoice_status",
      render: (text, record) => (
        <div
          className={`status status_${text === "approved" && record?.flow_status
            ? record?.flow_status?.replace(/_/g, " ")
            : text?.replace(/_/g, " ")
            }`}
        >
          {record?.status ? record?.status?.replace(/_/g, " ") : text === "approved" && record?.flow_status
            ? record?.flow_status?.replace(/_/g, " ")
            : text?.replace(/_/g, " ")}
        </div>
      ),
    },
    isEstimates || isSales || isCN ? {} : {
      title: t('payment_status'),
      dataIndex: "payment_status",
      key: "payment_status",
      render: (text, record) => (
        <div className={`status status_${text}`}>
          {text?.replace(/_/g, " ")}
        </div>
      ),
    }
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button
    //         shape="default"
    //         icon={<WarningOutlined />}
    //         style={{ border: "none", color: "#E24C4B" }}
    //       />
    //       <Button
    //         shape="default"
    //         icon={<MessageOutlined />}
    //         style={{ border: "none", color: "#5B87F9" }}
    //       />
    //       <Button
    //         shape="default"
    //         icon={<MoreOutlined />}
    //         style={{ border: "none", color: "#5B87F9" }}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  const options = [
    {
      label: (
        <div className='table-menu-item'>
          <span className='menu-label'>
            {` Add New ${moduleName?.type}`}
          </span>
        </div>
      ),
      key: '1',
      icon: (
        <div >
          <PlusCircleFilled className="pp-icon-18" />
        </div>
      ),
    },
    {
      label: (
        <div className='table-menu-item'>
          <span
            className='menu-label'
          >
            {`Import ${moduleName?.type}`}
          </span>
        </div>
      ),
      key: '2',
      icon: (
        <div>
          <CloudUploadOutlined className="pp-icon-18" />
        </div>
      ),
    },
    // {
    //   label: (
    //     <div className='table-menu-item'>
    //       <span
    //         className='menu-label'
    //       >
    //         {`Upload ${moduleName?.type}`}
    //       </span>
    //     </div>
    //   ),
    //   key: '3',
    //   icon: (
    //     <div>
    //       <CloudUploadOutlined className="pp-icon-18" />
    //     </div>
    //   ),
    // },
  ];

  const menu = (
    <Menu onClick={(e) => {
      if (hasWriteAccess) {
        if (e?.key == 1) {
          history.push(
            isCN ? "/sales/credit-note/create-new-credit" :
              isEstimates
                ? "/sales/estimates/create-new-estimate"
                : isSales
                  ? "/sales/orders/create-new-sale"
                  : "/sales/invoices/create-new-invoice",
            { from: location.pathname }
          );
        } else if (e?.key == 2) {
          history.push(
            isCN ? "/credit/notes/upload/section" :
              isEstimates
                ? "/estimate/upload/section"
                : isSales
                  ? "/sales/order/upload/section"
                  : "/invoice/upload/section",
          );
        } else if (e?.key == 3) {
          history.push(
            isCN ? "/credit/notes/upload/section" :
              isEstimates
                ? "/estimate/upload/section"
                : isSales
                  ? "/sales/order/upload/section"
                  : "/invoice/upload/section",
          );
        }
      }
    }}>
      {options?.map((ele) => (
        <Menu.Item key={ele.key} icon={ele.icon} className="flex-around">
          {ele.label}
        </Menu.Item>
      ))}
    </Menu>
  );


  useEffect(() => {
    dispatch(
      headerTitles({
        title: isCN ? 'credit_notes' : isSales ? 'order_sale' : isEstimates ? 'estimate' : 'invoices',
        description:
          isSales ? 'sale_order_document' : isEstimates ? 'an_estimate' : 'a_invoice',
      })
    );
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "payment_terms",
      })
    );
    let time = setTimeout(() => {
      dispatch(
        invoices({
          type: isCN ? "CREDIT" : isEstimates ? "ESTIMATE" : isSales ? "SALES_ORDER" : "INVOICE",
        })
      );
      setFilters({
        type: isCN ? "CREDIT" : isEstimates ? "ESTIMATE" : isSales ? "SALES_ORDER" : "INVOICE",
      })
    }, 300);

    let locationSplit = location?.pathname?.split("/")
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: isCN || isEstimates || isSales ? "/" + locationSplit?.[2] : "",
        showChildren: isEstimates || isSales ? true : false,
        childrenMenu: isCN ? "" : isEstimates ? "/my-estimates" : isSales ? "/my-sales" : "/invoices"
      }
    })

    return () => {
      clearTimeout(time);
      dispatch({
        type: Invoices.RESET,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleSearch = (searchKey) => {
    dispatch(
      invoices({
        ...filters,
        search_key: searchKey,
        type: isCN ? "CREDIT" : isEstimates ? "ESTIMATE" : isSales ? "SALES_ORDER" : "INVOICE",
      })
    );
    setPage(1)
    setFilters({
      ...filters,
      search_key: searchKey
    })
  };

  const handleFilter = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }
    if (value === "all") {
      dispatch(invoices({
        type: isCN ? "CREDIT" : isEstimates ? "ESTIMATE" : isSales ? "SALES_ORDER" : "INVOICE",
      }));
      setFilters({
        type: isCN ? "CREDIT" : isEstimates ? "ESTIMATE" : isSales ? "SALES_ORDER" : "INVOICE",
      });
    } else {
      dispatch(
        invoices({
          ...filters,
          filters: value === "closed" ? { status: [value] } :
            value === "declined" || value === "accepted" || value === "confirmed"
              ? { flow_status: [value] }
              : { invoice_status: [value], flow_status: null },
          isFilter: true,
        })
      );
      setFilters({
        ...filters,
        filters: value === "closed" ? { status: [value] } :
          value === "declined" || value === "accepted" || value === "confirmed"
            ? { flow_status: [value] }
            : { invoice_status: [value], flow_status: null },
      });
    }
    setPage(1);
  };

  const handleSort = (value) => {
    dispatch(
      invoices({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
        isFilter: true,
      })
    );

    setFilters({
      ...filters,
      sort: {
        sort_key: value,
        sort_order: "1",
      },
    });
    setPage(1);
  };

  const filterOptions = isEstimates
    ? [
      { label: t('all'), filter: "all" },
      { label: t('pending_approval'), filter: "pending_approval" },
      { label: t('approved'), filter: "approved" },
      { label: t('overDue'), filter: "overdue" },
      { label: t('rejected'), filter: "rejected" },
      { label: t('un_submitted'), filter: "unsubmitted" },
      { label: t('decline'), filter: "declined" },
      { label: t('accept'), filter: "accepted" },
    ]
    : [
      { label: t('all'), filter: "all" },
      { label: t('pending_approval'), filter: "pending_approval" },
      { label: t('approved'), filter: "approved" },
      { label: t('overDue'), filter: "overdue" },
      { label: t('rejected'), filter: "rejected" },
      { label: t('un_submitted'), filter: "unsubmitted" },
    ];

  const setOpenAddNew = () => {
    if (hasWriteAccess) {
      history.push(
        isCN ? "/sales/credit-note/create-new-credit" :
          isEstimates
            ? "/sales/estimates/create-new-estimate"
            : isSales
              ? "/sales/orders/create-new-sale"
              : "/sales/invoices/create-new-invoice",
        { from: location.pathname }
      );
    }
  };
  return (
    <>
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              menu={menu}
              noDD={false}
              setOpenAddNew={setOpenAddNew}
              sortOptions={sortOptions}
              hasWriteAccess={hasWriteAccess}
              filterMenu={isSales ? [...filterOptions,
              { label: t('closed'), filter: "closed" },
              ] : filterOptions}
              handleFilter={handleFilter}
              onSearch={handleSearch}
              handleSort={handleSort}
            />
            <div
              className={invLoading ? "shimmer" : ""}
              style={{ paddingTop: "22px" }}
            >
              <TableComponent
                rowKey="id"
                columns={columns}
                scroll={{
                  x: 1200
                }}
                hasCheckbox={false}
                loading={invLoading}
                data={Array.isArray(data) ? data : []}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleRowClick={(e, record) => {
                  history.push(
                    isCN ? "/sales/cn" :
                      isEstimates
                        ? `/sales/estimate`
                        : isSales
                          ? `/sales/so`
                          : `/sales/invoice`,
                    {
                      id: record.id,
                      benId: record?.beneficiary_id,
                      from: location.pathname,
                      isInvoice: true,
                    }
                  );
                }}
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(
                      invoices({
                        ...filters,
                        type: isCN ? "CREDIT" : isEstimates
                          ? "ESTIMATE"
                          : isSales
                            ? "SALES_ORDER"
                            : "INVOICE",
                        page_number: pagee,
                      })
                    );
                    setPage(pagee);
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MyInvoices;
