import { pdf } from '@react-pdf/renderer';
import { Col, Modal, Row, Typography, Button, Input, Form, message, Badge } from "antd";
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import { downloadBulkUploads } from "../../../actions/transfers";
import ConvertPDF from "../../Common/ConvertPDF";
import PdfDocument from "../../Common/PdfDocument";
import TableComponent from "../../Common/TableComp/TableComp";
import TableHeader from "../../Sales/TableHeader";
import { useHistory } from 'react-router-dom';
import { amountFormat } from '../../../utils';
import BaseCurrency from '../../Common/BaseCurrency';
import usePayAxios from '../../../config/useAxios';
import { apis } from '../../../config/APIs';
const { Title, Text } = Typography;
var alphanumeric = /^[ \t\r\n,.]*$/

const BulkHistoryDetails = ({ type }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation()
	const [page, setPage] = useState(1);
	const history = useHistory()
	const [selectedRows, setSelectedRows] = useState([]);
	const [reason, setReason] = useState("");
	const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
	const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
	const [type1, setType] = useState("");
	const [search, setSearch] = React.useState("");
	const historyReponse = useSelector((state) => state?.transfers?.download_bulk_uploads?.data?.data)
	const { onCall: bulkApprovePayment, loading: bulkAppproveLoading } = usePayAxios({
		api: apis.bulkApprovePayment,
		method: "post"
	})
	const { onCall: bulkRejectPayment, loading: bulkRejectLoading } = usePayAxios({
		api: apis.bulkRejectPayment,
		method: "post"
	})
	useEffect(() => {
		dispatch(
			headerTitles({
				title: location?.state?.user ? 'bulk_payments' : "approvals",
				description: 'access_transfer',
			})
		)
		callOnMount()
	}, [])

	const callOnMount = () => {
		dispatch(downloadBulkUploads({
			params: {
				id: location.state?.fileId,
				module_type: "COMPANY_BULK_PAYMENTS",
				view: location?.state?.user ? "" : "admin",
			}
		}))
	}

	const handleSort = async (key, fileName = "bulk_uploads.pdf") => {
		if (key === "download") {
			try {
				const blob = await pdf((
					<PdfDocument
						title={t('my_pdf')}
						pdfDocumentData={<ConvertPDF headers={type ? headers : transferHeaders} rows={rows} dataMapKey={type ? dataMapKey : transferDataMapKey} />}
					/>
				)).toBlob();

				saveAs(blob, fileName);
			} catch (error) {
				console.error('Error generating PDF:', error);
			}
		}
	};
	const sortOptions = [
		{
			label: t('download_text'),
			filter: "download",

		},
	];

	const columnsTransfers = [
		// {
		//     title: "Beneficiary Name & ID",
		//     dataIndex: "payment_beneficiary_name",
		// },
		{
			title: t('bank_name'),
			dataIndex: "payment_bank_name",
			render: (text, record) => (
				<>
					{record?.is_editable === false &&
						<div className="base_badge">
							<Badge.Ribbon text={"Under review by another approver."} placement={"start"} />
						</div>
					}
					<div className='blur'>
						{text}
					</div>
				</>
			),
		},
		{
			title: t('bank_account_num'),
			dataIndex: "payment_bank_account_no",
			render: (text) => <div className='blur'>
				{text}
			</div>
		},
		{
			title: t("ifsc_code"),
			dataIndex: "payment_bank_ifsc_number",
			render: (text) => <div className='blur'>
				{text}
			</div>
		},
		{
			title: t('Amount'),
			dataIndex: "payment_amount",
			render: (text, rec) => <div className='blur'><BaseCurrency symbol_code={rec.currency} /> {amountFormat(text)}</div>
		},
		{
			title: t('status'),
			dataIndex: "role_status",
			key: "role_status",
			render: (text, record) => (
				<div className={`blur status status_${location?.state?.user ? record?.flow_status : text}`}>{location?.state?.user ? record?.flow_status?.replace?.(/_/g, " ") : text?.replace?.(/_/g, " ")}</div>
			)
		},

	]
	const columnsPayments = [
		// {
		//     title: t('name'),
		//     dataIndex: "beneficiary_name",
		//   },
		{
			title: t('email'),
			dataIndex: "email",
		},
		{
			title: t('phone_no'),
			dataIndex: "phone",
		},
		{
			title: t('payment_amount'),
			dataIndex: "payment_amount",
		},
		{
			title: t('status'),
			dataIndex: "status",
			key: "status",
			render: (text) => (
				<div className={`status status_${text}`}>
					{text === "InProgress" ? "Progress" : text}
				</div>
			)
		},

	]
	// const sort_opts = (
	//     <Menu onClick={() => { handleDownloadPDf() }}>
	//       {sortOptions.map((ele, idx) => (
	//         <Menu.Item key={idx}>{ele.label}</Menu.Item>
	//       ))}
	//     </Menu>
	//   );
	const rowClassName = (record) => {
		if (record.is_editable === false) {
			return 'blurred-row';
		}
		return '';
	};
	const renderBulkOption = (type1) => {
		return (
			<>
				<Text>{t('want_bulk')} {" "}{type1} ?</Text>
				<br />
				{
					type1 === "reject" ? (
						<Form.Item >
							<Input.TextArea placeholder="Enter reason" className="mt1" value={reason} onChange={(e) => {
								setReason(e.target.value)
							}} required />
						</Form.Item>
					) : (null)
				}
				<Button
					disabled={bulkRejectLoading || bulkAppproveLoading || (type === "reject" && reason?.match(alphanumeric)) ? true : false}
					key="1"
					className="formButton mt1"
					onClick={() => {
						if (type1 === "reject") {
							bulkRejectPayment({
								data: selectedRowKeys?.map(ele => ({ id: ele, reject_reason: reason }))
							}).then(res => {
								callOnMount()
								setSelectedRowKeys([])
								setSelectedRows([])
								setIsBulkModalVisible(false)
								setReason("")
								message.success(<span className='messageText'>Bulk payments rejected Successfully.</span>)
							}).catch(err => {
								message.error(<span className='messageText'>{err?.response?.data?.message}</span>)
							})
						} else {
							bulkApprovePayment({
								data: selectedRowKeys?.map(ele => ({ id: ele }))
							}).then(res => {
								callOnMount()
								setSelectedRowKeys([])
								setSelectedRows([])
								message.success(<span className='messageText'>Bulk payments approved Successfully.</span>)
								setIsBulkModalVisible(false)
							}).catch(err => {
								message.error(<span className='messageText'>{err?.response?.data?.message}</span>)
							})

						}
					}}
				>
					{type1}
				</Button>
				<Button
					key="2"
					className="cancelButton ml1 mt1"
					onClick={() => {
						setIsBulkModalVisible(false);
						setReason("")
					}}
				>
					{t('cancel')}
				</Button>
			</>
		);
	};
	const onChangeCheckBox = (selectedRowKeys, selectedRows) => {
		setSelectedRows(selectedRows);
		setSelectedRowKeys(selectedRowKeys)
	}

	const approveClick = () => {
		setType("approve")
		setIsBulkModalVisible(true)
	}
	const rejectClick = () => {
		setIsBulkModalVisible(true)
		setType("reject")
	}

	const handleSearch = (search_key) => {
		dispatch(
			downloadBulkUploads({
				params: {
					id: location.state?.fileId,
					module_type: "COMPANY_BULK_PAYMENTS",
					view: location?.state?.user ? "" : "admin",
					page_number: page
				},
				search_key
			})
		);
		setSearch(search_key)
	}
	const headers = ['Name', 'Email', "Phone No", "Payment Amount", "Status"];
	const dataMapKey = ["beneficiary_name", "email", "primary_contact", "payment_amount", "status"]
	const transferHeaders = ["Bank Name", "Bank account No", "IFSC Code", "Amount", "Status",]
	const transferDataMapKey = ["payment_bank_name", "payment_bank_account_no", "payment_bank_ifsc_number", "payment_amount", "status"]
	const rows = historyReponse;
	return (
		<>
			<Modal
				title={<Title level={4}>{t('bulk_action')}</Title>}
				visible={isBulkModalVisible}
				onCancel={() => {
					setIsBulkModalVisible(false);
					setReason("")
				}}
				footer={[]}
				className="center-alinged-modal"
			>
				{renderBulkOption(type1)}
			</Modal>
			<Row wrap={false} className="table-wrapper">
				<Col flex="auto" className="table-wrapper-col">
					<div className="flex-grow">
						<TableHeader
							noAddNewBtn={true}
							noSort={true}
							noFilter={true}
							sortOptions={sortOptions}
							handleSort={handleSort}
							approveClick={approveClick}
							rejectClick={rejectClick}
							isBulkAction={selectedRows?.length > 0}
							noMoreButton={false}
							onSearch={handleSearch}

						/>
						<div className="" style={{ paddingTop: "22px" }}>
							<TableComponent
								columns={type ? columnsPayments : columnsTransfers}
								loading=""
								data={historyReponse?.data}
								rowClassName={rowClassName}
								rowKey="id"
								handleRowClick={(_e, row) => {
									if (!type && (row.is_editable || location.state?.user))
										history.push("/payment-details/" + row.id, {
											parentId: location.state.fileId,
											bulk: true,
											user: location.state?.user,
											pathname: location?.state?.pathname
										})
								}}
								selectedRows={selectedRows}
								setSelectedRows={setSelectedRows}
								rowSelection={{
									type: "checkbox",
									// ...rowSelection,
									onChange: onChangeCheckBox,
									getCheckboxProps: ((record) => ({
										disabled: location.state?.user || record.role_status !== "pending_approval" || record.is_editable === false,
										style: {
											display: !location.state?.user ? "flex" : "none"
										},
									})),
									selectedRowKeys: selectedRowKeys
								}}
								pagination={{
									total: historyReponse?.total_count || 0,
									current: page,
									onChange: (pagee) => {
										dispatch(
											downloadBulkUploads({
												params: {
													id: location.state?.fileId,
													module_type: "COMPANY_BULK_PAYMENTS",
													view: location?.state?.user ? "" : "admin",
													page_number: pagee
												},
												search_key: search
											})
										);
										setPage(pagee);
									},
								}}
							/>
						</div>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default BulkHistoryDetails;