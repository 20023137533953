import { Button, Modal } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { get_seed_data } from "../../actions/masterdata/masterdata";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import { FormInput } from "../inputs";
import { useTranslation } from "react-i18next";

const AddTaxModal = ({ isVisible, onCancel, companyId, callBack }) => {
  const { onCall } = usePayAxios({ api: apis.AddTax, method: "post" });
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      company_id: "",
      tax_name: "",
      rate: "",
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload = {
      company_id: companyId,
      tax_name: data.tax_name,
      rate: Number(data.rate),
    };

    onCall({
      data: payload,
    }).then(data => {
      reset()
      dispatch(
        get_seed_data({
          company_id: localStorage.getItem("company_id"),
          seed_type: "tds_types",
        })
      )
      callBack && callBack()
      onCancel()
    }).catch(err => err.message);
  };

  return (
    <>
      <Modal title={t('payment_add_terms')} visible={isVisible} onCancel={onCancel}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("tax_name")}
                errors={errors}
                label={t('tax_hash')}
                placeholder={t('enter_tax_name')}
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("rate")}
                errors={errors}
                label={t('rate_hash')}
                placeholder={t('enter_rate')}
              />
            </div>
            <div className="d-flex gap1 mt2">
              <button type="submit" className="pp-main-button" style={{
                color: "#fff"
              }}>
                {t('save')}
              </button>
              <Button className="pp-secondary-button" onClick={onCancel}>
                {t('cancel')}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddTaxModal;
