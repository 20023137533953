import { Switch } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import BillingAddress from './AddBillingAddress';
import ShippingAddress from './AddShippingAddress';
import { useTranslation } from 'react-i18next';

const AddVendorAddress = ({ control, register, watch, errors, billing_address, shipping_address }) => {
  const shippingAddressSame = watch('shippingAddressSame');
  const { t } = useTranslation()

  console.log({
    shippingAddressSame
  });
  return (
    <>
      <BillingAddress control={control} errors={errors} register={register} billing_address={billing_address} />

      <div style={{ paddingTop: '25px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <span style={{ color: '#3B3B3B' }}>{t('ship_address')}</span>
        <div style={{ paddingLeft: '1rem' }}>
          <Controller
            control={control}
            shouldUnregister={true}
            name="shippingAddressSame"
            render={({ field: { onChange } }) => (
              <Switch
                color="#3F81F4"
                size="small"
                defaultChecked
                checked={shippingAddressSame}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            )}
          />
        </div>
      </div>

      {!shippingAddressSame ? <ShippingAddress control={control} register={register} errors={errors} shipping_address={shipping_address} /> : null}
    </>
  );
};

export default AddVendorAddress;
