import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sort_key } from "../Common/Table/Table";
import {
  advances,
  Advances as AdvancesType,
  userAdvanceFields,
  userAdvances,
  UserAdvances,
} from "./../../actions/advances/advances";
// import moment from "moment";
import { Menu, message, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { headerTitles, SideMenu } from "../../actions/masterdata/masterdata";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import TableComponent from "../Common/TableComp/TableComp";
import TableHeader from "../Sales/TableHeader";
import AddAdvance from "./AddAdvance";
import moment from "moment";
import { prefixexpenseFields } from "../../actions/expenses/expenses";

const { Title } = Typography;

const Advances = ({ user, hasWriteAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [advancesData, setAdvancesData] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(1);
  const { t } = useTranslation();

  const advancesResponse = useSelector((state) => state.advances.advances);
  const userAdvancesResponse = useSelector(
    (state) => state.advances.userAdvances
  );
  const addUserAdvanceResponse = useSelector(
    (state) => state.advances.addUserAdvance || {}
  );

  const location = useLocation();

  const columns = [
    {
      title: t("advance_id"),
      dataIndex: "advance_number",
      key: "advance_number",
    },
    {
      title: t("purpose_title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("dateAndTime"),
      dataIndex: "created_at",
      key: "date",
      // render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
      render: (text) => {
        return (
          text && <div> {moment(text).format("DD MMM, YYYY hh:mm A")}</div>
        );
      },
    },
    {
      title: t("recorded_by"),
      dataIndex: "submitted_by",
      key: "submitted_by",
      render: (text) => <div>{text?.name}</div>,
    },
    // {
    //   title: "Reference",
    //   dataIndex: "reference_no",
    //   key: "reference_no",
    // },
    {
      title: t("Amount"),
      dataIndex: "amount_cents",
      key: "amount_cents",
      render: (text) => (
        <div>
          <BaseCurrency />
          {amountFormat(text)}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: user ? "status" : "role_status",
      key: user ? "status" : "role_status",
      render: (text) => (
        <div className={`status status_${text}`}>
          {text?.replace(/_/g, " ")}
        </div>
      ),
    },
  ];
  const updateUserAdvanceResponse = useSelector(
    (state) => state.advances.updateUserAdvance || {}
  );

  React.useEffect(() => {
    const obj = {
      view: "admin",
      module_type: "ADVANCE",
      page: 1,
      per_page: 10,
    };
    if (user) {
      delete obj.view;
    }
    if (location.state?.createNew) {
      setIsModalVisible(true);
    }
    if (location.state?.filter) {
      // handleFilter(location.state?.filter);
      dispatch(advances({ ...obj, filters: { status: [location.state?.filter] } }));
      setFilters({
        ...filters,
        filters: { status: [location.state?.filter] },
      });
    } else {
      dispatch(advances(obj));
    }

    dispatch(
      headerTitles({
        title: "advances",
        description: "",
      })
    );
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/expenses",
        childrenMenu: user ? "/my-advances" : "",
        showChildren: true,
        secondNav: "/advances",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    const { data: { data = [], status = false, total_count = 0 } = {} } =
      advancesResponse;
    if (status) {
      setAdvancesData([...data]);
      setTotalRecords(total_count);
      dispatch({
        type: AdvancesType.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancesResponse]);

  React.useEffect(() => {
    const {
      data: {
        response: { advances = [] } = {},
        status = false,
        total_records = 0,
      } = {},
    } = userAdvancesResponse;
    if (status) {
      setAdvancesData([...advances]);
      setTotalRecords(total_records);
      // message.success(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UserAdvances.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAdvancesResponse]);

  React.useEffect(() => {
    const { data: { status = false, message: res_message = "" } = {} } =
      addUserAdvanceResponse;
    if (status) {
      handleCancel();
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(advances({ module_type: "ADVANCE" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserAdvanceResponse]);

  const handleSearch = (value) => {
    const obj = {
      view: "admin",
      module_type: "ADVANCE",
      per_page: 10,
      page: page,
      ...filters,
      search_key: value,
    };
    if (user) {
      delete obj.view;
    }
    dispatch(advances(obj));
    setFilters({
      ...filters,
      search_key: value,
    });
    setPage(1);
  };

  const handleAddButton = (evt, record) => {
    // handleModal();
    if (hasWriteAccess) {
      setIsModalVisible(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRowClick = (evt, record) => {
    evt.preventDefault();
    if (record) {
      history.push({
        pathname: `/expenses/advance-details/${record.id}`,
        state: {
          advanceData: record, from: location?.pathname,
          user
        },
      });
    }
  };

  const handleFilter = (value) => {
    if (value === "true") {
      let obj = {
        view: "admin",
        module_type: "ADVANCE",
        page: 1,
        per_page: 10,
      };
      if (user) {
        delete obj.view;
      }
      dispatch(advances(obj));
      setFilters({
        ...filters,
        status: "",
      });
    } else {
      let obj = {
        view: "admin",
        module_type: "ADVANCE",
        per_page: 10,
        page: page,
        filters: { status: [value] },
        ...filters,
      };
      if (user) {
        delete obj.view;
      }
      dispatch(advances({ ...obj, filters: { status: [value] } }));
      setFilters({
        ...filters,
        filters: { status: [value] },
      });
    }
    setPage(1);
  };

  const options = !user
    ? [
      { label: t("all_advances"), filter: "true" },
      { label: t("approval_pending"), filter: "pending_approval" },
      { label: t("recovered"), filter: "recovered" },
      { label: t("pending_recovery"), filter: "pending_recovery" },

      { label: t("approved"), filter: "approved" },
      { label: t("rejected"), filter: "rejected" },
      // { label: "More", isMore: true },
    ]
    : [
      { label: t("all_advances"), filter: "true" },
      { label: t("approval_pending"), filter: "pending_approval" },
      { label: t("recovered"), filter: "recovered" },
      { label: t("pending_recovery"), filter: "pending_recovery" },
      { label: t("approved"), filter: "approved" },
      { label: t("rejected"), filter: "rejected" },
      { label: t("un_submitted"), filter: "unsubmitted" },
    ];

  const sortOptions = !user
    ? [
      { label: "amount", key: "amount_cents" },
      { label: "recorded_by", key: "submitted_by" },
      { label: "title", key: "title" },
    ]
    : [
      { label: "title", key: "title" },
      { label: "date", key: "created_at" },
      // { label: "reference", key: "reference" },
      { label: "amount", key: "amount_cents" },
    ];

  const menu = (
    <Menu onClick={() => { }}>
      {options.map((ele) => (
        <Menu.Item key={ele} icon={ele.icon} className="flex-around">
          {ele?.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  //0
  const sort_opts = sortOptions.map((ele) => ({
    label: (
      <span
        style={{
          textTransform: "capitalize",
          color: "#333",
        }}
      >
        {sort_key[ele?.label]
          ? sort_key[ele?.label]
          : ele?.label?.replace("_", " ")}
      </span>
    ),
    filter: ele,
  }));

  return (
    <>
      {isModalVisible && (
        <Modal
          title={
            <Title level={4}>
              {t("new_advance")} &nbsp;
              <span className="text-small">{t("enter_and_save")}</span>
            </Title>
          }
          open={isModalVisible}
          onCancel={handleCancel}
          footer={[]}
          className="right-alinged-modal"
        >
          <AddAdvance handleCancel={handleCancel} />
        </Modal>
      )}
      <TableHeader
        noDD={true}
        defaultFilterValue={filters?.filters?.status?.[0]}
        filterMenu={options}
        handleFilter={handleFilter}
        noAddNewBtn={!user}
        handleAddButton={handleAddButton}
        sortOptions={sort_opts}
        onSearch={handleSearch}
        placeholder={t("search_by_advance")}
        hasWriteAccess={hasWriteAccess}
        handleSort={(key) => {
          const sort = {
            sort_key: key?.key,
            sort_order: "1",
          };
          const obj = {
            view: "admin",
            module_type: "ADVANCE",
            per_page: 10,
            page: 1,
            ...filters,
            sort,
          };
          if (user) {
            delete obj.view;
          }
          dispatch(advances(obj));
          setFilters({
            ...filters,
            sort,
          });
          setPage(1);
        }}
      />
      <div style={{ paddingTop: "15px" }}>
        <TableComponent
          rowKey="id"
          columns={columns}
          data={(advancesData && advancesData) || []}
          hasCheckbox={false}
          handleRowClick={handleRowClick}
          hasWriteAccess={hasWriteAccess}
          // selectedRows={selectedRows}
          // setSelectedRows={setSelectedRows}
          pagination={{
            total: totalRecords || 0,
            current: page,
            onChange: (pagee) => {
              !user
                ? dispatch(
                  advances({
                    view: "admin",
                    module_type: "ADVANCE",
                    params: { page_number: pagee },
                    ...filters,
                  })
                )
                : dispatch(
                  advances({
                    module_type: "ADVANCE",
                    params: { page_number: pagee },
                    ...filters,
                  })
                );
              setPage(pagee);
            },
          }}
        />
      </div>
    </>
  );
};

export default Advances;
