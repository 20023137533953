import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tabs,
  Upload,
} from "antd";
import Title from "antd/lib/typography/Title";
import pdf_icon from "../../assets/icons/pdf_icon.png";
import excel_icon from "../../assets/icons/excel1.png";
import tiff_icon from "../../assets/icons/tifficon.png";
import ppt_icon from "../../assets/icons/ppticon.png";
import { useFormik } from "formik";
import * as yup from "yup";
import _, { uniqBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getCostCenterField,
  getMerchantsField,
  getProjectsField,
} from "../../actions/companySettings";
import {
  AddExpense,
  addExpense,
  ExpenseCustomDetails,
  expenseCustomDetails,
  ExpenseDetails,
  expenseDetails,
  ExpenseFields,
  expenseFields,
  expensesReset,
  prefixexpenseFields,
  UpdateExpense,
  updateExpense,
} from "../../actions/expenses/expenses";
import ImageSwiper from "../Common/ImageSwiper";
import AddReport from "../Reports/AddReport";
import AddToReportDropdown from "./AddToReportDropdown";
import "./expenses.css";
import { ocrDocumentDel } from "../../actions/ocr/ocr";
import {
  findLodash,
  getFileExtension,
  hasUpToOneDecimal,
  notNumber,
} from "../../utils";
import { SideMenu } from "../../actions/masterdata/masterdata";
import { InboxOutlined } from "@ant-design/icons";
import { deleteEmployeeDocument } from "../../actions/employees";

import { apis } from "../../config/APIs";
import useDocumentAxios from "../../config/useDocument";
import { AddCategories, categories } from "../../actions/categories";
import { reports, userReportLinkExp } from "../../actions/reports";
import useScrollToError from "../../config/useScrollToError";
import AddMerchantsField from "../Settings/Customisation/Merchants/AddMerchantsField.js";
import { AddMerchantsField as AddMerchanFieldReset } from "../../actions/companySettings/companySettings.js";
import AddCategory from "../Settings/Categories/AddCategory";
const { TabPane } = Tabs;
const { Dragger } = Upload;
// const { TextArea } = Input;
const AddExpenses = (props) => {
  const addExpenseResponse = useSelector(
    (state) => state.expenses.addExpense || {}
  );
  const updateExpenseResponse = useSelector(
    (state) => state.expenses.updateExpense || {}
  );
  const expenseFieldsResponse = useSelector(
    (state) => state.expenses.expenseFields || {}
  );
  const prefixResponse = useSelector(
    (state) => state.expenses?.prefixexpenseFields?.data?.["EXPENSE"]
  );

  const location = useLocation();
  const history = useHistory();
  const { data: { data: expensesFieldsData = [] } = {} } = ({} =
    expenseFieldsResponse);

  const dispatch = useDispatch();
  const [uploadFileData, setUploadFileData] = React.useState([]);
  const [dataURIs, setDataURIs] = React.useState([]);
  const [delIds, setDelIds] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isModalVisibleReport, setIsModalVisibleReport] = React.useState(false);
  const [checkDisabled, setCheckDisabled] = React.useState(false);
  const [validationSchema, setValidationSchema] = React.useState(null);
  const [image, setImage] = useState("");
  const [uploadDetails, setUploadDetails] = React.useState({});
  const [uploadData, setUploadData] = React.useState({});
  const [violations, setViolations] = React.useState([]);
  const [showViolations, setShowViolations] = React.useState(false);
  const [report, setReports] = React.useState(null);
  const [addModal, setAddModal] = React.useState({ open: false, module: "" })
  const marchentsResponse = useSelector(
    (state) => state?.companySetting?.getMerchantsField?.data?.data || []
  );
  const costCenterFieldResponse = useSelector(
    (state) => state?.companySetting?.getCostCenterField?.data?.data || []
  );
  const projectsResponse = useSelector(
    (state) => state?.companySetting?.getProjectsField?.data?.data || []
  );
  const categoriesResponse = useSelector(
    (state) => state?.categories?.categories?.data?.data || []
  );
  const expenseDetailsByIdResponse = useSelector(
    (state) => state?.expenses?.expenseDetails || {}
  );

  const expenseCustomData = useSelector(
    (state) => state?.expenses?.expenseCustomDetails?.data?.data || []
  );

  const deleteEmployeeResponse = useSelector(
    (state) => state?.employees?.deleteEmployeeDocument
  );

  const validateExpenseData = useSelector(
    (state) => state?.expenses?.validateExpense?.data || {}
  );

  const { onCall, loading, data } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
  });
  const { onCall: DocumentDelete } = useDocumentAxios({
    api: apis.deleteDocument,
    method: "delete",
    baseURL: "document",
  });
  const validationSchema1 = yup.object().shape({
    amount_cents: yup
      .string()
      .required("Amount is required")
      .test(
        "greaterThanZero",
        "Amount must be greater than 0",
        (value) => parseFloat(value) > 0
      ),
    transaction_date: yup.string().required("Date And Time is required"),
    description: yup.string().required("Description is required"),
    transaction_merchant_id: yup
      .string()
      .nullable()
      .required("Marchent is Required"),
    category_id: yup.string().nullable().required("Cateory is Required"),
    payment_method: yup.string().required("PaymentMethod is Required"),
    purpose: yup.string().required("Purpose is Required"),
    ...validationSchema,
  });
  const formik = useFormik({
    initialValues: {
      claim_reimbursement: true,
      amount_cents: "",
      category_id: null,
      transaction_date: "",
      payment_method: "",
      purpose: "",
      description: "",
      transaction_merchant_id: null,
      cost_center_id: "",
      project_id: "",
      transaction_number: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema1,
    onSubmit: (vals) => {
      let payload = { ...vals };
      const marchentName = findLodash(marchentsResponse, {
        id: vals.transaction_merchant_id,
      });
      payload["merchant_name"] = marchentName.name;

      if (location?.state?.isEdit === true) {
        const custom_fields = expenseCustomData.map(
          ({ field_id, column_name, column_type }) => {
            let master_custom_field_id = field_id;
            let module_name = "EXPENSE";
            let field_type = column_type;
            let value1 = formik.values[field_id];
            let value =
              column_type !== "datetime" ? value1 : value1 ? value1 : null;
            let field_name = column_name;
            delete payload?.[field_id];
            // return { id, value, field_name };
            if (column_type === "document") {
              let attachment_id = uploadDetails[field_id]?.attachmentId;
              return {
                master_custom_field_id,
                field_name,
                field_type,
                attachment_id,
                module_name,
              };
            } else {
              return {
                master_custom_field_id,
                value,
                field_name,
                field_type,
                module_name,
              };
            }
          }
        );
        payload["custom_fields"] = custom_fields.filter(Boolean) || [];
      } else {
        const custom_fields = expensesFieldsData?.map(
          ({ id, column_name, column_type }) => {
            let master_custom_field_id = id;
            let module_name = "EXPENSE";
            let field_type = column_type;
            let value1 = formik.values[id];
            let value =
              column_type !== "datetime"
                ? value1
                : value1
                  ? moment(value1).format("DD-MM-YYYY HH:mm:ss")
                  : null;
            let field_name = column_name;
            delete payload?.[id];
            // return { id, value, field_name };
            if (column_type === "document") {
              let attachment_id = uploadDetails[id]?.attachmentId;
              return {
                master_custom_field_id,
                field_name,
                value,
                field_type,
                attachment_id,
                module_name,
              };
            } else {
              return {
                master_custom_field_id,
                value,
                field_name,
                field_type,
                module_name,
              };
            }
          }
        );
        payload["custom_fields"] = custom_fields.filter(Boolean) || [];
      }

      if (location?.state?.id) {
        dispatch(
          updateExpense({
            // files: uploadFileData?.map(ele => ele?.originFileObj),
            // field_values: arr,
            // delete_file_ids: delIds,
            params: { module_type: "EXPENSE" },
            id: location?.state?.id,
            ...payload,
          })
        );
      } else {
        dispatch(addExpense(payload));
      }
    },
  });
  // Use the custom hook
  useScrollToError(Object.keys(formik?.errors)[0], formik?.errors, formik?.submitCount);
  React.useEffect(() => {
    const { data: { data = [], error = "", message: res_message = "" } = {} } =
      addExpenseResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      // dispatch(expenses());
      const formData = new FormData();

      uploadFileData.map((ele) => formData.append("files", ele?.originFileObj));
      formData.append("name", "attachments");
      formData.append("record_type", "Transations");
      formData.append("record_id", data?.id);
      if (uploadFileData.length > 0) {
        const attachId = onCall({
          data: formData,
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
      }
      if (location?.state?.ocr_id)
        dispatch(
          ocrDocumentDel({
            id: location?.state?.ocr_id,
          })
        );
      if (!props?.reportId) {
        history.push("/expenses");
      }
      // if (!report) {
      //   dispatch({
      //     type: AddExpense.RESET,
      //   });
      // }
      dispatch({
        type: ExpenseCustomDetails.RESET,
      });
      dispatch({
        type: ExpenseFields.RESET,
      });
      dispatch({
        type: AddExpense.RESET,
      });
      setImage([]);
      formik.resetForm({});
      setDelIds([]);
    } else if (error) {
      if (res_message) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: AddExpense.RESET,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExpenseResponse]);

  React.useEffect(() => {
    const { data: { data = "", error = "", message: res_message = "" } = {} } =
      updateExpenseResponse;
    if (error === false) {
      const formData = new FormData();
      // formData.append("files", uploadFileData?.map(ele => ele?.originFileObj));
      uploadFileData &&
        uploadFileData.map(
          (ele) =>
            ele?.originFileObj && formData.append("files", ele?.originFileObj)
        );
      formData.append("name", "attachments");
      formData.append("record_type", "Transations");
      formData.append("record_id", data);
      if (uploadFileData.length > 0) {
        const attachId = onCall({
          data: formData,
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
      }
      // message.success(<span className="messageText">{res_message}</span>);
      if (!props.reportId) {
        history.push("/expenses/");
      }
      dispatch({
        type: UpdateExpense.RESET,
      });
      dispatch({ type: ExpenseCustomDetails.RESET });
      dispatch({ type: ExpenseDetails.RESET });
      formik.resetForm({});
    }
  }, [updateExpenseResponse]);

  const { data: { data: expenseData = {}, error = "" } = {} } =
    expenseDetailsByIdResponse;

  React.useEffect(() => {
    let obj = {};
    expenseCustomData?.forEach((ele) => {
      if (ele.column_type === "document") {
        setUploadDetails((prevUploadDetails) => {
          return {
            ...prevUploadDetails,
            [ele?.field_id]: {
              attachmentId: ele?.attachments?.map((ele) => ele.blob_id),
              recordId: ele?.field_id,
            },
          };
        });
      }
    });

    expenseCustomData.forEach((ele) => {
      if (ele.column_type === "document") {
        setUploadData((b) => {
          return {
            ...b,
            [ele.field_id]: {
              attachmentId: ele?.attachments?.map((ele) => ele.blob_id),
              recordId: ele?.field_id,
            },
          };
        });
      }
    });

    expenseCustomData?.forEach((each_custom_field) => {
      // obj[each_custom_field.field_id] = each_custom_field?.value;
      let blobids = each_custom_field?.attachments?.map(
        (item) => item?.blob_id
      );
      if (each_custom_field.column_type === "document") {
        blobids?.length
          ? formik.setFieldValue(each_custom_field?.field_id, blobids)
          : formik.setFieldValue(each_custom_field?.field_id, "");
      } else {
        formik.setFieldValue(
          each_custom_field?.field_id,
          each_custom_field?.value
        );
      }
    });
    if (expenseDetailsByIdResponse?.data?.error === false) {
      [
        "description",
        "amount_cents",
        "claim_reimbursement",
        "category_id",
        "payment_method",
        "transaction_merchant_id",
        "project_id",
        "transaction_date",
        "purpose",
        "cost_center_id",
        "transaction_number",
      ]?.forEach((ele) => {
        if (ele === "transaction_date") {
          formik.setFieldValue(ele, moment(expenseData[`${ele}`]));
        } else {
          formik.setFieldValue(ele, expenseData[`${ele}`]);
        }
      });
    }
  }, [expenseDetailsByIdResponse]);

  React.useEffect(() => {
    dispatch(getMerchantsField({ pagination: false }));
    dispatch(categories({ pagination: false }));
    dispatch(getCostCenterField({ pagination: false }));
    dispatch(getProjectsField({ pagination: false }));
    if (!props?.reportId) {
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/expenses",
          secondNav: "",
        },
      });
    }
    if (!location?.state?.isEdit) {
      dispatch(prefixexpenseFields({ params: { module_type: "EXPENSE" } }));
    }
    // dispatch(expenseFields({ module_type: "EXPENSE",static_columns:true }))
    if (location.state?.isEdit) {
      dispatch(
        expenseDetails({
          expense_id: location?.state?.id,
          module_type: "EXPENSE",
        })
      );
    } else {
      // dispatch(expenseFields({ module_type: "EXPENSE", custom_flag: true }));
    }
    if (props.resetForm)
      // props.resetForm(formik.resetForm)
      message.config({
        maxCount: 1,
      });
    return () => {
      dispatch(expensesReset());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (prefixResponse) {
      formik.setFieldValue("transaction_number", prefixResponse);
    } else if (prefixResponse === false) {
      formik.setFieldError("transaction_number", "error");
    }
  }, [prefixResponse]);

  React.useEffect(() => {
    if (formik.values?.category_id) {
      dispatch(
        expenseFields({
          module_type: "EXPENSE",
          custom_flag: true,
          category_id: formik.values?.category_id,
        })
      );
      dispatch(
        expenseCustomDetails({
          module_type: "EXPENSE",
          module_id: location?.state?.id,
          // custom_flag: true,
          category_id: formik.values?.category_id
        })
      );
    }
  }, [formik.values?.category_id]);

  useEffect(() => {
    let arr = {};

    if (expensesFieldsData && expensesFieldsData?.length) {
      let obj = {};
      let obj2 = {};
      expensesFieldsData?.forEach((item) => {
        if (item.is_required) {
          obj2[item.id] =
            location?.state?.expense?.[item?.id] ||
            formik.values[item.id] ||
            "";
          if (item.column_type === "document") {
            obj[item.id] = yup
              .array()
              .of(yup.string())
              .required(`${item.display_name} is required`);
          } else {
            obj[item.id] = yup
              .string()
              .required(`${item.display_name} is required`);
          }
        }
      });

      if (!location?.state?.isEdit) {
        Object.keys(obj2)?.map((ele) => {
          formik.setFieldValue(ele, obj2[ele]);
        });
        // formik.setValues({
        //   ...obj2,
        //   ...arr,
        // });
      }

      setValidationSchema(obj);
    }
    // eslint-disable-next-line
  }, [expensesFieldsData]);

  useEffect(() => {
    if (expenseCustomData && expenseCustomData?.length) {
      let obj = {};
      let obj2 = {};
      expenseCustomData?.forEach((item) => {
        if (item.is_required) {
          obj2[item?.id] =
            location?.state?.expense?.[item?.id] ||
            formik.values[item?.id] ||
            "";
          if (item.column_type === "document") {
            obj[item?.id] = yup
              .array()
              .of(yup.string())
              .required(`${item.display_name} is required`);
          } else {
            obj[item?.id] = yup
              .string()
              .required(`${item.display_name} is required`);
          }
        }
      });

      if (!location?.state?.isEdit) {
        Object.keys(obj2)?.map((ele) => {
          formik.setFieldValue(ele, obj2[ele]);
        });
        // formik.setValues({
        //   ...obj2,
        //   ...arr,
        // });
      }
      console.log("add expenses value isEdit", obj)
      setValidationSchema(obj);
    }
  }, [expenseCustomData]);

  useEffect(() => {
    if (location.state?.isEdit) {
      if (Array.isArray(expenseDetailsByIdResponse?.data?.data?.attachments)) {
        setUploadFileData(
          expenseDetailsByIdResponse?.data?.data?.attachments?.map(
            (ele, i) => ({
              uid: (i + 1) * -1,
              name: ele.name,
              url: ele.url,
              status: "done",
              thumbUrl: ele.url,
              blob_id: ele.id,
            })
          )
        );
        setDataURIs(
          expenseDetailsByIdResponse?.data?.data?.attachments?.map(
            (ele, i) => ({
              src: ele.url,
              blob_id: ele.id,
              uid: (i + 1) * -1,
            })
          )
        );
      }
    }
    // if (!props.reportId) {
    //   dispatch(expenseFields({ module_type: "EXPENSE", static_columns: true }))
    // }
    // if (!props.reportId) {
    //   dispatch(getCustomFields({}))
    // }
    // eslint-disable-next-line
    return () => {
      dispatch({ type: ExpenseFields?.RESET })
      dispatch({ type: ExpenseCustomDetails?.RESET })
    }
  }, []);

  useEffect(() => {
    if (location?.state?.id) {
      expensesFieldsData?.map((item) => {
        if (item?.field_type === "document" && item?.selected?.value) {
          const existImage = image?.findIndex(
            (eachImg) => eachImg.id === item.field_id
          );
          if (existImage !== -1) {
            setImage((prev) => {
              const updateImg = [...prev];
              updateImg[existImage].url = item?.selected?.value;
              return updateImg;
            });
          } else {
            setImage((b) => [
              ...b,
              { url: item?.selected?.value, id: item.field_id },
            ]);
          }
        }
      });
    }
  }, [expensesFieldsData]);

  // add merchants 
  const addMerchantsFieldResponse = useSelector(
    (state) => state.companySetting.addMerchantsField || {}
  );
  const addCategoriesResponse = useSelector(state => state.categories.addCategories || {});

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = addMerchantsFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setAddModal({ open: false, module: "" });
      getMerchantsField({
        company_id: localStorage.getItem("company_id"),
        type: "",
      })
      dispatch({
        type: AddMerchanFieldReset.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddMerchanFieldReset.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMerchantsFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "", error = "" } = {},
    } = addCategoriesResponse;
    if (error === false) {
      message.success(<span className='messageText'>{res_message}</span>);
      setAddModal({ open: false, module: "" });
      dispatch(categories());
      dispatch({
        type: AddCategories.RESET,
      });
    } else if (error) {
      message.error(<span className='messageText'>{res_message}</span>);
      dispatch({
        type: AddCategories.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCategoriesResponse]);


  const handleChange = (val, id) => {
    formik.setFieldValue(id, val);
  };

  const onDocumentFileChange = async (info, name) => {
    if (info.file.status === "removed") {
      expenseDetailsByIdResponse?.data?.data?.id && info.file.blob_id
        ? dispatch(
          deleteEmployeeDocument({
            record_id: info?.file?.record_id,
            ids: [info?.file?.id],
          })
        )
        : dispatch(
          deleteEmployeeDocument({
            record_id: uploadData[info?.file?.uid].recordId,
            ids: [uploadData[info?.file?.uid].attachment_id],
          })
        );

      let recordfile = "";

      expenseDetailsByIdResponse?.data?.data?.id && info.file.blob_id
        ? (recordfile = info.file.blob_id)
        : (recordfile = uploadData[info?.file?.uid].attachment_id);

      const data = {
        ...uploadDetails[name],
        attachmentId: uploadDetails[name].attachmentId.filter(
          (val) => val !== recordfile
        ),
      };

      setUploadDetails((b) => ({
        ...b,
        [name]: {
          attachmentId: [
            ...b[name].attachmentId?.filter((val) => val !== recordfile),
          ],
        },
      }));

      console.log(
        { upload: uploadDetails[name], data: data, recordfile: info },
        "uploadedData===>"
      );

      formik.setFieldValue(
        name,
        data?.attachmentId?.length ? data?.attachmentId : ""
      );
    } else {
      const nextState = {};
      nextState.selectedFile = info.file;
      nextState.selectedFileList = [info.file];
      let uniqueNo = expensesFieldsData.id
        ? uploadDetails[name]?.recordId
        : uuidv4();
      const formData = new FormData();
      formData.append("files", info?.file);
      formData.append("name", info.file.name);
      formData.append("record_type", "Document");
      formData.append("record_id", uniqueNo);

      const attachId = await onCall({
        data: formData,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });

      setUploadDetails((b) => {
        if (Object.keys(b).includes(name)) {
          return {
            ...b,
            [name]: {
              ...b[name],
              attachmentId: [...b[name].attachmentId, attachId?.data?.[0]],
            },
          };
        } else {
          return {
            ...b,
            [name]: {
              attachmentId: [attachId?.data?.[0]],
            },
          };
        }
      });

      setUploadData((b) => {
        return {
          ...b,
          [info?.file?.uid]: {
            attachment_id: attachId?.data?.[0],
            recordId: uniqueNo,
          },
        };
      });
      if (uploadDetails[name] && uploadDetails[name].attachmentId) {
        formik.setFieldValue(name, [
          ...uploadDetails[name].attachmentId,
          attachId?.data?.[0],
        ]);
      } else {
        formik.setFieldValue(name, [attachId?.data?.[0]]);
      }
    }
  };

  const renderDataTypes = (
    value,
    default_values,
    id,
    name,
    attachment,
    attachmentType,
    attachment_size
  ) => {
    switch (value) {
      case "string":
        return (
          <>
            <Input
              placeholder={t("enter_val")}
              value={formik.values?.[id]}
              id={id}
              style={{ minWidth: "216px" }}
              onChange={(e) => {
                handleChange(e.target.value, id);
              }}
            />
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "integer":
        return (
          <>
            <Input
              placeholder={t("enter_val")}
              type="text"
              id={id}
              value={formik.values?.[id]}
              style={{ minWidth: "216px" }}
              onChange={(e) => {
                if (
                  e.target.value?.split?.(".")?.length > 0 &&
                  e.target.value?.split?.(".")?.[1]?.length > 2
                ) {
                  return;
                }

                if (notNumber(e.target.value?.trimStart())) {
                  return;
                }
                handleChange(e.target.value.trimStart(), id);
              }}
            />
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "dropdown":
        return (
          <>
            <Select
              placeholder={t("enter_val")}
              id={id}
              value={formik.values?.[id]}
              style={{ minWidth: "216px" }}
              onChange={(e) => handleChange(e, id)}
            >
              {default_values.map((eachValue) => (
                <option key={eachValue} value={eachValue}>
                  {eachValue}
                </option>
              ))}
            </Select>
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "checkbox":
        return (
          <>
            <Checkbox
              style={{ float: "left" }}
              checked={formik.values?.[id]}
              id={id}
              disabled={checkDisabled}
              onChange={(e) => {
                handleChange(e.target.checked, id);
              }}
            >
              {_.startCase(name)}
            </Checkbox>
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "datetime":
        return (
          <>
            <DatePicker
              // disabled={is_disabled}
              id={id}
              value={
                formik.values?.[id]
                  ? moment(formik.values?.[id], "YYYY-MM-DD HH:mm:ss")
                  : null
              }
              onChange={(date, dateString) => {
                handleChange(dateString, id);
              }}
              showTime
            />
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "document":
        return (
          <>
            <Dragger
              name={id}
              listType="picture"
              defaultFileList={attachment}
              beforeUpload={(file) => {
                const fileName = getFileExtension(file?.name);
                const maxSize = attachment_size;
                const allowedImages = attachmentType.split(",");
                const uppercaseImages = allowedImages.map((image) =>
                  image.trim().toUpperCase()
                );
                const imagetype = uppercaseImages.join(",  ");
                if (!allowedImages?.includes(fileName)) {
                  message.error(
                    <span className="messageText">
                      You can only upload {imagetype}.
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                } else if (file.size > maxSize * 1024) {
                  message.error(
                    <span className="messageText">
                      File size must be smaller than {attachment_size} KB
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
              disabled={loading || deleteEmployeeResponse.loading}
              onChange={(evt) => onDocumentFileChange(evt, id)}
              value={formik.values?.[id] ? formik.values?.files?.id : ""}
            >
              {image ? (
                <img
                  src={image}
                  alt="testing"
                  style={{ width: "150px", height: "100px" }}
                />
              ) : (
                <p className="ant-upload-text">
                  <InboxOutlined />
                  {t("drag_attachment")}
                  <br />
                  {t("click_here_to")}
                </p>
              )}
              {/* <p className="ant-upload-text"><InboxOutlined />{t('drag_png')}<br />{t('click_here_to')}</p> */}
            </Dragger>
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      default:
      // code block
    }
  };

  const handleAddExp = () => {
    const payload = {};
    payload.transaction_ids = [addExpenseResponse?.data?.data?.id];
    payload.report_id = report;
    dispatch(userReportLinkExp({ ...payload }));
    history.push("/expenses");
  };

  const readAsDataURL = (file, index) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = function () {
        resolve({
          src: fr.result,
          type: fr.result.includes("image") ? "image" : "pdf",
          uid: index,
        });
      };
      fr.readAsDataURL(file);
    });
  };

  const onFileRemove = (info) => {
    let idx = uploadFileData?.findIndex((ele) => ele.uid === info.uid);
    let idx2 = dataURIs?.findIndex((ele) => ele.uid === info.uid);
    if (idx > -1) {
      let fileData = [...uploadFileData];
      setDelIds((d) => [...d, fileData[idx].name]);
      fileData.splice(idx, 1);
      setUploadFileData(fileData);
    }
    if (idx2 > -1) {
      let uris = [...dataURIs];
      uris.splice(idx2, 1);
      setDataURIs(uris);
    }
  };

  const onFileChange = async (info) => {
    if (info.fileList?.length > 5) {
      message.error(<span className="messageText">{t("attachment")}</span>);
      return Upload.LIST_IGNORE;
    }
    if (info?.file?.status === "removed") {
      if (info.file.blob_id) {
        await DocumentDelete({
          data: {
            ids: [info?.file?.blob_id],
          },
          params: { record_id: expenseData?.id },
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
      }
    }
    setUploadFileData((list) => uniqBy([...list, ...info.fileList], "uid"));
    if (info.fileList?.length > 0) {
      let arr = [];
      info.fileList.forEach((ele) => {
        if (ele?.originFileObj) {
          arr.push(
            new Promise((resolve, reject) => {
              readAsDataURL(ele?.originFileObj, ele?.originFileObj?.uid)
                .then((res) => {
                  resolve(res);
                })
                .catch((err) => {
                  reject(err);
                });
            })
          );
        }
      });
      Promise.all(arr).then((res) => {
        let arr = dataURIs;
        // if (location.state && location.state.files) {
        //   arr = location.state.files?.map((ele, i) => ({ src: ele.file, uid: (i + 1) * -1 }))
        // }
        res.forEach((ele) => arr.push(ele));
        setDataURIs(uniqBy([...arr], "src"));
      });
    } else {
      setDataURIs([]);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setImage([]);
  };
  const { t } = useTranslation();
  const handleCancelAdd = () => {
    setAddModal({ open: false, module: "" })
  }
  return (
    <>
      {showViolations && (
        <Modal
          title={<Title level={4}>{t("policy_violation")}</Title>}
          wrapClassName="exp-table"
          visible={showViolations}
          onCancel={() => {
            history.push("/expenses");
          }}
          footer={
            violations
              ?.map((ele) =>
                ele.policies.some(
                  (policy) =>
                    policy.errors.length > 0 &&
                    policy.policy_action === "WARNING"
                )
              )
              .filter((ele) => ele)?.length
              ? [
                <Button type="primary" onClick={handleAddExp}>
                  Continue
                </Button>,
                <Button
                  className="cancelButton ml1 mt1"
                  onClick={() => setShowViolations(false)}
                >
                  Cancel
                </Button>,
              ]
              : []
          }
        >
          <div className="violations">
            {violations?.map((ele, idx) =>
              ele.policies.some((policy) => policy.errors.length > 0) ? (
                <div className="item">
                  {idx + 1}. {ele.transaction_number} -
                  {ele.policies?.map((itm) =>
                    itm.errors.map((e) => (
                      <span
                        className={
                          itm?.policy_action === "WARNING" ? "warn" : ""
                        }
                      >
                        <img
                          // src={itm?.policy_action === "WARNING" ? warn : block}
                          alt=""
                          width={12}
                        />{" "}
                        {e}
                      </span>
                    ))
                  )}
                </div>
              ) : null
            )}
          </div>
        </Modal>
      )}

      <Modal
        title={
          <Title level={4}>
            {t("new_report")}&nbsp;
            <span className="text-small">{t("enter_details")}</span>
          </Title>
        }
        visible={isModalVisibleReport}
        onCancel={() => {
          setIsModalVisibleReport(false);
        }}
        footer={[]}
        className="right-alinged-modal"
      >
        {isModalVisibleReport && (
          <AddReport
            handleCancel={() => {
              setIsModalVisibleReport(false);
            }}
            from={"EXPENSE"}
          />
        )}
      </Modal>
      <Modal
        title={
          <Title level={4}>
            {t('new_merchant')} &nbsp;
            <span className="text-small">{t('enter_details')}</span>
          </Title>
        }
        visible={addModal?.open}
        onCancel={handleCancelAdd}
        footer={[]}
        className={"right-alinged-modal-small"}
      >
        {(() => {
          switch (addModal?.module) {
            case "merchant":
              return <AddMerchantsField handleCancel={handleCancelAdd} />;
            case "category":
              return <AddCategory handleCancel={handleCancelAdd} />;
            default:
              return null; // handle other cases or return nothing
          }
        })()}
      </Modal>


      <Tabs type="card" size="large" className="custom-tabs">
        <TabPane tab={t("expense_add")} key="preferences">
          <div className="tabContentWrapper userContentWrap">
            <form className="modal-form" onSubmit={formik.handleSubmit}>
              <Row className="">
                <Col span={14}>
                  <ImageSwiper
                    fileList={uploadFileData}
                    onFileChange={onFileChange}
                    imgs={dataURIs}
                    onFileRemove={onFileRemove}
                  />
                </Col>
                <Col span={10} className="p3 overflowCol">
                  <div className="nameItem">
                    <label
                      htmlFor="transaction_number"
                      className="inline-block required"
                    >
                      Expense Id
                    </label>
                    <Input
                      placeholder={t("enter_val")}
                      value={formik.values?.transaction_number}
                      style={{ minWidth: "216px" }}
                      disabled={true}
                    />
                    <div className="error">
                      {formik.errors?.transaction_number && (
                        <span style={{ color: "red" }}>
                          Please specify the prefix value in Expense
                          customization
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="nameItem">
                    <label
                      htmlFor="amount_cents"
                      className="inline-block required"
                    >
                      Amount
                    </label>
                    <Input
                      name={"amount_cents"}
                      placeholder={t("enter_val")}
                      value={formik.values?.amount_cents}
                      style={{ minWidth: "216px" }}
                      onChange={(e) => {
                        if (
                          e.target.value?.split?.(".")?.length > 0 &&
                          e.target.value?.split?.(".")?.[1]?.length > 2
                        ) {
                          return;
                        }

                        if (notNumber(e.target.value?.trimStart())) {
                          return;
                        }
                        handleChange(e.target.value, "amount_cents");
                      }}
                    />
                    <div className="error">
                      {formik.submitCount > 0 && formik.errors?.amount_cents}
                    </div>
                  </div>
                  <div className="nameItem">
                    <label
                      htmlFor="transaction_date"
                      className="inline-block required"
                    >
                      Date And Time
                    </label>
                    <DatePicker
                      placeholder={t("enter_val")}
                      value={formik.values?.transaction_date}
                      style={{ minWidth: "216px" }}
                      onChange={(evt) => {
                        handleChange(evt, "transaction_date");
                      }}
                      showTime
                    />
                    <div className="error">
                      {formik.submitCount > 0 &&
                        formik.errors?.transaction_date}
                    </div>
                  </div>
                  <div className="nameItem">
                    <Checkbox
                      style={{ float: "left" }}
                      checked={
                        formik?.values?.purpose === "personal"
                          ? false
                          : formik.values?.claim_reimbursement
                      }
                      onChange={(e) => {
                        handleChange(e.target.checked, "claim_reimbursement");
                      }}
                      disabled={formik?.values?.purpose === "personal"}
                    >
                      Claim Reimbursement
                    </Checkbox>
                  </div>
                  <div className="nameItem" id="transaction_merchant_id">
                    <label
                      htmlFor="transaction_merchant_id"
                      className="inline-block required"
                    >
                      Merchant
                    </label>
                    <Select
                      placeholder={t("Select")}
                      value={formik.values?.transaction_merchant_id}
                      style={{ minWidth: "20rem" }}
                      onChange={(e) =>
                        handleChange(e, "transaction_merchant_id")
                      }
                      dropdownRender={(menu) => (
                        <>
                          <div className="pp-dropdown-menu">{menu}</div>
                          {
                            marchentsResponse?.length ? null : (
                              <>
                                <Divider className="pp-dropdown-divider" />
                                {(
                                  <Button
                                    type="text"
                                    htmlType="button"
                                    className="pp-dropdown-add-new"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                      setAddModal({ open: true, module: "merchant" });
                                    }}
                                  >
                                    <span>Add Merchant</span>
                                  </Button>
                                )}
                              </>
                            )
                          }
                        </>
                      )}
                    >
                      {marchentsResponse.map((eachMarchent) => (
                        <option key={eachMarchent.id} value={eachMarchent.id}>
                          {eachMarchent.name}
                        </option>
                      ))}
                    </Select>
                    <div className="error">
                      {formik.submitCount > 0 &&
                        formik.errors?.transaction_merchant_id}
                    </div>
                  </div>
                  <div className="nameItem" id="category_id">
                    <label
                      htmlFor="category_id"
                      className="inline-block required"
                    >
                      Category
                    </label>
                    <Select
                      placeholder={t("Select")}
                      value={formik.values?.category_id}
                      style={{ minWidth: "20rem" }}
                      onChange={(e) => handleChange(e, "category_id")}

                      dropdownRender={(menu) => (
                        <>
                          <div className="pp-dropdown-menu">{menu}</div>
                          {
                            categoriesResponse?.filter((eachCategory) => eachCategory?.is_enable)?.length ? null : (
                              <>
                                <Divider className="pp-dropdown-divider" />
                                {(
                                  <Button
                                    type="text"
                                    htmlType="button"
                                    className="pp-dropdown-add-new"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                      setAddModal({ open: true, module: "category" });
                                    }}
                                  >
                                    <span>Add Category</span>
                                  </Button>
                                )}
                              </>
                            )
                          }
                        </>
                      )}
                    >
                      {categoriesResponse &&
                        categoriesResponse
                          ?.filter((eachCategory) => eachCategory?.is_enable)
                          ?.map((eachCategory) => (
                            <option
                              key={eachCategory.id}
                              value={eachCategory.id}
                            >
                              {eachCategory.name}
                            </option>
                          ))}
                    </Select>
                    <div className="error">
                      {formik.submitCount > 0 && formik.errors?.category_id}
                    </div>
                  </div>
                  <div className="nameItem" id="payment_method">
                    <label
                      htmlFor="payment_method"
                      className="inline-block required"
                    >
                      PaymentMethod
                    </label>
                    <Select
                      placeholder={t("enter_val")}
                      value={formik.values?.payment_method}
                      style={{ minWidth: "20rem" }}
                      onChange={(e) => handleChange(e, "payment_method")}
                    >
                      <option value="Card">Card</option>
                      <option value="Cash">Cash</option>
                      <option value="Wallet">Wallet</option>
                    </Select>
                    <div className="error">
                      {formik.submitCount > 0 && formik.errors?.payment_method}
                    </div>
                  </div>
                  <div className="nameItem" id="purpose">
                    <label htmlFor="purpose" className="inline-block required">
                      Purpose
                    </label>
                    <Select
                      placeholder={t("enter_val")}
                      value={formik.values?.purpose}
                      style={{ minWidth: "20rem" }}
                      onChange={(e) => handleChange(e, "purpose")}
                    >
                      <option value="company">Company</option>
                      <option value="personal">Personal</option>
                    </Select>
                    <div className="error">
                      {formik.submitCount > 0 && formik.errors?.purpose}
                    </div>
                  </div>
                  <div className="nameItem">
                    <label
                      htmlFor="description"
                      className="inline-block required"
                    >
                      Description
                    </label>
                    <Input
                      placeholder={t("enter_val")}
                      value={formik.values?.description}
                      style={{ minWidth: "216px" }}
                      onChange={(e) =>
                        handleChange(e.target.value, "description")
                      }
                    />
                    <div className="error">
                      {formik.submitCount > 0 && formik.errors?.description}
                    </div>
                  </div>
                  <div className="nameItem" id="cost_center_id">
                    <label htmlFor="cost_center_id" className="inline-block">
                      CostCenter
                    </label>
                    <Select
                      placeholder={t("enter_val")}
                      name="cost_center_id"
                      value={formik.values?.cost_center_id}
                      style={{ minWidth: "20rem" }}
                      onChange={(e) => handleChange(e, "cost_center_id")}
                    >
                      {costCenterFieldResponse?.map((eachcostCenter) => (
                        <option
                          key={eachcostCenter.id}
                          value={eachcostCenter.id}
                        >
                          {eachcostCenter.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="nameItem" id="project_id">
                    <label htmlFor="project_id" className="inline-block">
                      Project
                    </label>
                    <Select
                      placeholder={t("enter_val")}
                      name="project_id"
                      value={formik.values?.project_id}
                      style={{ minWidth: "20rem" }}
                      onChange={(e) => handleChange(e, "project_id")}
                    >
                      {projectsResponse?.map((eachProject) => (
                        <option key={eachProject.id} value={eachProject.id}>
                          {eachProject.name}
                        </option>
                      ))}
                    </Select>
                  </div>

                  {location?.state?.isEdit === true
                    ? expenseCustomData.length > 0 &&
                    expenseCustomData.map((item) => (
                      <div className="nameItem">
                        {item.column_type !== "checkbox" && (
                          <label
                            className={`inline-block ${item.is_required ? "required" : ""
                              }`}
                          >
                            {item.column_type !== "checkbox" && (
                              <label
                                className={`inline-block ${item.is_required ? "required" : ""
                                  }`}
                              >
                                {item.display_name}
                              </label>
                            )}
                          </label>
                        )}
                        <br />
                        {renderDataTypes(
                          item.column_type,
                          item.default_values,
                          item.field_id,
                          item.column_name,
                          item.attachments?.map((ele) => ({
                            ...ele,
                            url: ele.attachment_url,
                            key: ele.blob_id,
                            name: ele.filename,
                            thumbUrl: ele?.content_type?.includes("image/")
                              ? null
                              : ele.content_type === "application/pdf"
                                ? pdf_icon
                                : ele?.content_type ===
                                  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                  ? ppt_icon
                                  : excel_icon,
                          })),
                          item.data.doc_formats,
                          item.data.max_size
                        )}
                      </div>
                    ))
                    : expensesFieldsData.length > 0 &&
                    expensesFieldsData.map((item) =>
                      item.display_name === "cost center" ? null : (
                        <div className="nameItem">
                          {item.column_type !== "checkbox" && (
                            <label
                              className={`inline-block ${item.is_required ? "required" : ""
                                }`}
                            >
                              {item.column_type !== "checkbox" && (
                                <label
                                  className={`inline-block ${item.is_required ? "required" : ""
                                    }`}
                                >
                                  {item.display_name}
                                </label>
                              )}
                            </label>
                          )}
                          <br />
                          {renderDataTypes(
                            item.column_type,
                            item.default_values,
                            item.id,
                            item.column_name,
                            item.attachment,
                            item.data.doc_formats,
                            item.data.max_size
                          )}
                        </div>
                      )
                    )}

                  <div className="nameItem">
                    <label className={`inline-block`}>{"Add to Report"}</label>
                    <AddToReportDropdown
                      hasId={
                        addExpenseResponse?.data?.data?.id ||
                        updateExpenseResponse?.data?.data
                      }
                      reportId={!props.reportId}
                      addReport={() => {
                        setIsModalVisibleReport(true);
                      }}
                      personvalue={formik.values.purpose}
                      setViolations={props.setViolations ?? setViolations}
                      setShowViolations={setShowViolations}
                      showViolations={showViolations}
                      setReport={setReports}
                      report={report}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="submitRow">
                <Button
                  key="2"
                  className="cancelButton mt1"
                  type="button"
                  onClick={() => {
                    setImage([]);
                    dispatch({ type: ExpenseDetails.RESET });
                    if (props.reportId) {
                      formik.resetForm();
                      props.handleCancel();
                    } else {
                      history.push("/expenses");
                    }
                  }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  key="15"
                  htmlType="submit"
                  className="formButton ml1 mt1 primaryButton"
                  disabled={
                    addExpenseResponse.loading ||
                    loading ||
                    deleteEmployeeResponse.loading
                  }
                >
                  {t("save_close")}
                </Button>
              </Row>
            </form>
          </div>
        </TabPane>
      </Tabs>
      <Modal
        title={
          <Title level={4}>
            {t("new_merchant")} &nbsp;
            <span className="text-small">{t("enter_details")}</span>
          </Title>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={"right-alinged-modal-small"}
      >
        {isModalVisible && <AddMerchantsField handleCancel={handleCancel} />}
      </Modal>
    </>
  );
};

export default AddExpenses;
