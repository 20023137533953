import { pdf } from "@react-pdf/renderer";
import { Divider } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from 'json-2-csv';
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  analyticsEstimates,
  analytics_cards
} from "../../actions/cards/cards";
import { apis } from "../../config/APIs";
import endpointAnalyticsApi from "../../config/AxiosAnalyticsApi";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import MISExpenses from "../Common/PDF/MISPDF/MISExpenses";
import BarGraph from "./BarGraph";
import ReportTable from "./ReportTable";

const PaymentsReceived = ({
  showGraph,
  apiFilters,
  filterObj,
  setFilterObj,
  csvData,
  pdfURL,
  ...props
}) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state?.cards?.analytics_estimates);
  const pdfData = useSelector((state) => state.analytics.analytics_cards_all?.data?.data);


  const { t } = useTranslation()

  useEffect(() => {
    dispatch(analyticsEstimates({ type: "analytics_payment_received" }));
    // dispatch(analytics_cards_all({ params:{ module_type:"SALES_ORDER", isCsv:true}, type:"payement_received" }));
    setFilterObj({});
  }, []);

  const columnsKeys = [
    { label: "Invoice Date", key: "invoice_date", subKey: "invoices", type: "date" },
    { label: "Customer Name", key: "customer_name", subKey: "invoices", type: "string" },
    { label: "Invoice No", key: "invoice_no", subKey: "invoices", type: "string" },
    { label: "Total Amount", key: "total_amount", subKey: "invoices", type: "amount" },
    { label: "Due Balance Amount", key: "due_balance_amount", subKey: "invoices", type: "amount" },
    { label: "Status", key: "invoice_status", subKey: "invoices", type: "string" },
  ];

  ///// Dowload pdf code ///
  useEffect(() => {
    pdfURL(downloadPdf)
  }, [pdfURL])

  const downloadPdf = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.getAnalyticsPaymentReceived + '?module_type=SALES_ORDER&isCsv=true', { filters: filters });
    if (response) {
      const blob = await pdf((
        <MISExpenses data={response?.data?.data} columnsKeys={columnsKeys} />
      )).toBlob();
      saveAs(blob, "MISPayments_Received.pdf");
    }
  }

  ///// Dowloading CSV file method ////////
  const downloadCsv = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.getAnalyticsPaymentReceived + '?module_type=SALES_ORDER&isCsv=true', { filters: filters });
    if (response) {
      const prod = response?.data?.data?.map(ele => ({
        "Invoice Date": moment(ele.invoices[0].invoice_date).format("DD MMM, YYYY"),
        "Customer Name": ele.invoices[0].customer_name,
        "Invoice No": ele.invoices[0].invoice_no,
        "Total Amount": ele.invoices[0].total_amount,
        "Due Balance Amount": ele.invoices[0].due_balance_amount,
        "Status ": ele.invoices[0].invoice_status,
      }))

      json2csv(prod).then((csv) => {
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, 'Payments Received.csv');
      });
    }
  }

  useEffect(() => {
    csvData(downloadCsv)
  }, [csvData])

  const columns = useMemo(
    () => [
      {
        title: t('invoice_date'),
        dataIndex: "invoices",
        key: "",
        render: (text) => {
          if (Array.isArray(text) && text.length > 0) {
            return text.map((item, index) => (
              <div key={index}>{moment(item?.invoice_date).format("DD MMM, YYYY")}</div>
            ));
          }
          return null;
        }


      },
      {
        title: t('customer_name'),
        dataIndex: "invoices",
        key: "",
        render: (text) => {
          if (text) {
            return text.map((item, index) => (
              <div key={index}>{item.customer_name}</div>
            ));
          }
          return null;
        }

      },
      {
        title: t('invoice_no'),
        dataIndex: "invoices",
        key: "",
        render: (text) => {
          if (Array.isArray(text) && text.length > 0) {
            return text.map((item, index) => (
              <div key={index}>{item?.invoice_no}</div>
            ));
          }
          return null;
        }
      },
      {
        title: t('total_amount'),
        dataIndex: "invoices",
        key: "",
        render: (text) => {
          if (text) {
            return text.map((item, index) => (
              <div key={index}>{item?.total_amount !== null ? (<><BaseCurrency />{amountFormat(item?.total_amount)}</>) : ('-')}</div>
            ));
          }
          return null;
        }
      },
      {
        title: t('due_balance_amount'),
        dataIndex: "invoices",
        key: "",
        render: (text) => {
          if (text) {
            return text.map((item, index) => (
              <div key={index}>{item?.due_balance_amount !== null ? (<><BaseCurrency />{amountFormat(item?.due_balance_amount)}</>) : ('-')}</div>
            ));
          }
          return null;
        }
      },
      {
        title: t('payment_mode'),
        dataIndex: "payment_mode",
        key: "payment_mode",
      },
      {
        title: t('payment_status'),
        dataIndex: "invoices",
        key: "",
        render: (text) => {
          if (text) {
            return text.map((item, index) => (
              <div key={index} className={`status status_${item?.payment_status}`}>{item?.payment_status}</div>
            ));
          }
          return null;
        }
        // render: (text) => {
        //   return text.payment_status;
        // },
        //render: ({ payment_status }) => <div className={`status status_${payment_status}`}>{payment_status.replace(/_/g, " ")}</div>,
      },
    ],
    [cards]
  );

  return (
    <>
      <div className="reports">
        {showGraph && (
          <>
            <Divider />
            <div
              className="mt1"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BarGraph
                width={1000}
                height={350}
                id={"lineAreaGraph"}
                data={[
                  { day: "Jan", txnValue: 39 },
                  { day: "Feb", txnValue: 49 },
                  { day: "Mar", txnValue: 69 },
                  { day: "Apr", txnValue: 59 },
                ]}
                barColor={"#151424"}
              />
            </div>
          </>
        )}
        <Divider />
      </div>
      <ReportTable
        columns={columns}
        data={Array.isArray(cards?.data?.data) ? cards?.data?.data : []}
        total_records={cards?.data?.total_count}
        scroll={{ x: 2000 }}
        paginationCall={(vals) => {
          vals.type = "analytics_payment_received";
          dispatch(analytics_cards(vals));
        }}
        filterObj={filterObj}
        payload={{ ...filterObj, ...apiFilters }}
      />
    </>
  );
};

export default PaymentsReceived;
