import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import { InboxOutlined } from '@ant-design/icons';
import pdf_icon from "../../../../assets/icons/pdf_icon.png";
import excel_icon from "../../../../assets/icons/excel1.png"
import ppt_icon from "../../../../assets/icons/ppticon.png"
import Dragger from 'antd/lib/upload/Dragger';
import { useSelector } from "react-redux";
import moment from 'moment';
const { TabPane } = Tabs;
const { Option } = Select;
const { Upload } = Dragger;



const AddUserFirstStep = ({
  form,
  validateMessages1,
  handleSubmit1,
  editValues,
  projectsResponse,
  handleDesignation,
  approversData,
  loading,
  advanceApproversData,
  rolesData,
  costCentersResponse,
  userfieldsData,
  mobile,
  handleBackButton,
  renderDataTypes,
  setMobile, ...props }) => {
  const { t } = useTranslation();

  const deleteEmployeeResponse = useSelector(
    (state) => state?.employees?.deleteEmployeeDocument
  )

  return (
    <React.Fragment>
      <Form
        onSubmitCapture={() => {
          form
            .validateFields().then(res => {
              handleSubmit1(res)
            }).catch(() => { })
        }}
        form={form}
        name="step1"
        layout="vertical"
        className="modal-form"
        validateMessages={validateMessages1}
      >
        <Row>
          <Col span={12}>
            <Form.Item name="title" label={t('title')} className="titleItem" rules={[
              {
                required: true,
                message: t('title_required'),
              },
            ]}>
              <Select value="Mr" style={{ width: 60 }} onChange={(e) => {
                form.setFields([{
                  name: "title",
                  value: e
                }])
              }}>
                <Option value="Mr">{t('mr')}</Option>
                <Option value="Mrs">{t('mrs')}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="name"
              label={t('name')}
              className="nameItem"
              rules={[{ required: true }]}
            >
              <Input placeholder={t('enter_first_name')}
                onChange={(e) => {
                  form.setFields([{
                    name: "name",
                    value: e.target.value
                  }])
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={t('email')}
              rules={[
                {
                  type: "email",
                  message: t('valid_email'),
                },
                {
                  required: true,
                  message: t('input_email'),
                },
              ]}
            >
              <Input placeholder={t('enter_email')}
                // disabled={props.selectedId}
                disabled={editValues?.email}
                onChange={(e) => {
                  form.setFields([{
                    name: "email",
                    value: e.target.value
                  }])
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="width-80">
          <Col span={8}>
            <Form.Item
              label={t('mobile_number')}
              name="primary_phone"
              rules={[
                { required: true, message: t('please_input'), }
              ]}
            >
              <span className="ant-input-group-wrapper">
                <span className="ant-input-wrapper ant-input-group">
                  <span className="ant-input-group-addon">+91</span>
                  <input disabled={editValues?.primary_phone} placeholder={t('enter_num')} maxlength="10" type="text"
                    className="ant-input"
                    value={mobile}
                    onChange={(e) => {
                      if (new RegExp(/^[0-9]*$/).test(e.target.value)) {
                        setMobile(e.target.value)
                        form.setFields([{
                          name: "primary_phone",
                          value: e.target.value,
                        }])
                      } else if (e.target.value === "") {
                        setMobile(e.target.value)
                      }
                    }} />
                </span>
              </span>
            </Form.Item>
          </Col>
          <Col span={8} className="ml1">
            <Form.Item name="roles" label="Role" rules={[{ required: true }]}>
              <Select
                style={{ width: 200 }}
                placeholder={t('select_role')}
                mode="multiple"
                maxTagCount={2}
                onChange={(e) => {
                  form.setFields([{
                    name: "roles",
                    value: e
                  }])
                }}
              >
                {rolesData &&
                  rolesData.map(({ id, role_name }) => (
                    <Option key={id} value={role_name}>
                      {role_name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="width-80">
          <Col span={8}>
            <Form.Item
              name="emp_id"
              label={t('employee_id')}
              rules={[{ required: true }]}
            >
              <Input placeholder={t('enter_employee_id')}
                // disabled={props.selectedId && editValues?.emp_id}
                disabled={editValues?.emp_id}
                onChange={(e) => {
                  form.setFields([{
                    name: "emp_id",
                    value: e.target.value
                  }])
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item name="project_id" label={t('project')}>
              <Select
                style={{ width: 200 }}
                placeholder={t('select_project')}
                value={form.getFieldValue("project_id")}
              >
                {projectsResponse &&
                  projectsResponse.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} className="ml1">
            <Form.Item name="cost_center_id" label={t('cost_center')}>
              <Select
                style={{ width: 200 }}
                placeholder={t('select_cost_center')}
                value={form.getFieldValue("cost_center_id")}
              >
                {costCentersResponse &&
                  costCentersResponse.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="width-80">
          {approversData &&
            approversData?.map(({ role_name, options, selected }, index) => {
              return (
                <Col span={8} key={index}>
                  <Form.Item
                    label={role_name}
                    name={role_name}
                    className="dynamicValues"
                  >
                    <Select placeholder={t('select_approver')} value={form?.getFieldValue(role_name)}
                    // onChange={(e) => {
                    //   console.log("target",{e:e, value :form?.getFieldValue(role_name)})
                    //   form.setFields([{
                    //     role_name,
                    //     value: e
                    //   }])
                    // }}
                    >
                      {options &&
                        options.map(({ id, name }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              );
            })}
        </Row>
        {/* <Row className="width-80">
          {advanceApproversData &&
            advanceApproversData.map(({ name, options }, index) => (
              <Col span={8} key={index}>
                <Form.Item
                  name={name}
                  label={name}
                  className="dynamicValues"
                // rules={[{ required: true }]}
                >
                  <Select placeholder={t('select_advance_approver')} onChange={(e) => {
                    form.setFields([{
                      name,
                      value: e
                    }])
                  }}>
                    {options &&
                      options.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            ))}
        </Row> */}
        <Row className="w100">
          {userfieldsData &&
            userfieldsData?.map(
              ({
                field_id,
                display_name,
                column_name,
                is_required,
                column_type,
                data,
                default_values,
                selected,
              }) => (
                <Col span={8} key={display_name}>
                  <Form.Item
                    name={field_id}
                    label={display_name}
                    className="dynamicValues"
                    rules={column_type === "integer" ? [{ required: is_required, message: `${display_name} is required` }, {
                      pattern: /^[0-9]*$/,
                      message: " Only Numbers are allowed"

                    }] : [{ required: is_required, message: `${display_name} is required` }]}
                  >
                    {renderDataTypes(column_type, default_values, field_id, data, selected?.value, selected?.attachments?.map(ele => ({ ...ele, name: ele.filename, url: ele?.key, uid: ele.filename, thumbUrl: ele?.content_type?.includes("image/") ? null : ele.content_type === "application/pdf" ? pdf_icon : ele?.content_type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? ppt_icon : excel_icon })))}
                  </Form.Item>
                </Col>
              )
            )}
        </Row>

        <Button
          key="1"
          className="formButton mt1 primaryButton"
          htmlType="submit"
          disabled={loading||deleteEmployeeResponse.loading}
        >
          {t('next')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => {
            form.resetFields();
            props.handleAdd();
            // setImage([])
          }}
        >
          {t('cancel')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

export default AddUserFirstStep