export const OnBoardEnglish = {
    // button section start 
    cancleButton: "Cancel",
    saveProceedButton: "Save & Next",
    backButton: "Back",
    confirmButton: "Confirm",

    dashboardHeader: "Welcome to Bluerack!",
    dashboardSubHeader: "To unlock the full power of the borderless banking, there are couple of quick tasks for you to actions",
    emailVerificationHeader: "Verify your email address",
    emailVerificationSubHeader: "Check your inbox to verify your email address",
    activeHeader: "Activate your account",
    activeSubHeader: "Tell us more about your business",

    // kyc text 
    kycHeader: "Unlock the full power of Bluerack",
    kycSubHeader: "We’re excited to have you on board. Help us understand and verify your business in order To activate your account.",
    kycCardHeader: "Business registration in",
    kycCardSubHeader: "Please make sure you have chosen the correct country or region where your Business is registered. You will not be allowed to change it after you proceed.",
    kycCommonMainHeader: "Upcoming steps",
    kycCommonHeaderOne: "Get started",
    kycCommonHeaderTwo: "Business profile",
    kycCommonHeaderThree: "Business owner",
    kycCommonSubHeadrOne: "Find your business and confirm the details including the entity type, business name, ABN and address.",
    kycCommonSubHeadrTwo: "Provide details about your business on industry, product, website, monthly Turnover, and location.",
    kycCommonSubHeadrThree: "Confirm and verify your business owner(s) with their government-issued ID details.",
    kycNotifyHeader: "Get notified about Bluerack in United States",
    kycNotifyText: "Bluerack is currently not available in your region. Click below and we’ll notify you when there are updates about Bluerack in India at reachus@purpleplum.design",
    kycNotifyButtonText: "Notify me",
    kycForm: {
        kycNeed: "Need help?",
        kycContact: "Contact us",
        kycBusinessRegistrationHeader: "Business PAN",
        kycBusinessRegistrationSubHeader: "We need your PAN details associated with your business to verify your account. If you have an unregistered business or you are a sole proprietor, enter your personal PAN details.",
        kycSafe: "We take data seriously and your info is safe with us",
        kycCheckBoxLabel: "GSTIN",
        kycIhaveGSTIN: "I have GSTIN",
        kycIdonthaveGSTN: "I don’t have GSTN",
        kycBusinessDetails: "My business doesn’t have a website",
        kycBusinessAddeess: "My operational address is same as my registered address",
        kycBankAccountDetailsHeader: "Bank Account Details",
        kycBankAccountDetailsSubHeader: "We need your bank account details to process settlements and to recharge your payout wallet.",
        kycBusinessAddressHeader: "Business Address",
        kycBusinessAddressSubHeader: "We require this information to verify your business.",
        kycBusinessDetailsHeader: "Business Details",
        kycBusinessDetailsSubHeader: "We need this information to serve you better with the payment products suited for your business.",
        kycYourPANDetailsHeader: "Your PAN Details",
        kycYourPANDetailsSubHeader: "We need the ID proof of the business owner for verification purposes.",
        kycPANUpload: "Upload Proprietor PAN",
        kycAadharDetailsHeader: "Your Aadhar Details",
        kycAadharDetailsSubHeader: "We need the ID proof of the business owner for verification purposes.",
        kycMobileNumber: "Mobile number not linked to Aadhaar card?",
        kycTimer: "Verified instantly and verification may take upto 48hrs",
        kycUploadDocumentsHeader: "Upload Documents",
        kycUploadDocumentsSubHeader: "We need the proof of the business for verification purposes.",
        kycUploadText: "Upload Company Incorporation",
        kycUploadGstText: "Upload GST Cerficate",
        kycUploadHint: "Drop your files here or",
        kycUploadButton: "Choose file",
        kycDropText: "Drop your files here or",
        kycAadharfront: "Upload Aadhaar card front side",
        kycAadharback: "Upload Aadhaar card back side",
        kycAttachmentPANHeader: "Attach Proprietor PAN",
        kycAttachmentPANSubHeader: "Please refer to the guidelines of uploading your Proprietor PAN as per bank regulations",
        kycAttachmentAadharHeader: "Attach Aadhaar Details",
        kycAttachmentAadharSubHeader: "Please refer to the guidelines of uploading your Proprietor Aadhaar as per bank regulations",
        kycPopUpAadharHeader: "Your Aadhaar Details",
        kycPopUpPANHeader: "Your PAN Details",



    }
}