import { ActionTypesFactory } from '../../utils';

export const SeedData = ActionTypesFactory('MasterData', 'SeedData');
export const Currencies = ActionTypesFactory('MasterData', 'Currencies');
export const CreatePolicy = ActionTypesFactory('Policies', 'CreatePolicy');
export const UpdatePolicy = ActionTypesFactory('Policies', 'UpdatePolicy');
export const GetPolicy = ActionTypesFactory('Policies', 'GetPolicy');
export const GetPolicies = ActionTypesFactory('Policies', 'GetPolicies');
export const DeletePolicy = ActionTypesFactory('Policies', 'DeletePolicy');
export const EnablePolicy = ActionTypesFactory('Policies', 'EnablePolicy');
export const ChangePriority = ActionTypesFactory('Policies', 'ChangePriority');
export const PoliciesMetadata = ActionTypesFactory('Policies', 'Metadata');
export const HeaderTitles = "HEADER_TITLES";
export const SideMenu = "Side_Menu";

export const get_seed_data = (payload) => {
  return {
    type: SeedData.REQUEST,
    payload,
  };
};

export const headerTitles = (payload) => {
  return {
    type: HeaderTitles,
    payload,
  };
};

export const sideMenuNavigate = (payload) => {
  return {
    type: SideMenu,
    payload,
  };
};

export const createPolicy = (payload) => {
  return {
    type: CreatePolicy.REQUEST,
    payload,
  };
};

export const get_currencies = () => {
  return {
    type: Currencies.REQUEST,
  };
};

export const enablePolicy = (payload) => {
  return {
    type: EnablePolicy.REQUEST,
    payload,
  };
};

export const changePolicyPriority = (payload) => {
  return {
    type: ChangePriority.REQUEST,
    payload,
  };
};


export const deletePolicy = (payload) => {
  return {
    type: DeletePolicy.REQUEST,
    payload,
  };
};

export const getPolicy = (payload) => {
  return {
    type: GetPolicy.REQUEST,
    payload,
  };
};

export const getPolicies = (payload) => {
  return {
    type: GetPolicies.REQUEST,
    payload,
  };
};

export const getPoliciesMeta = (payload) => {
  return {
    type: PoliciesMetadata.REQUEST,
    payload
  };
};