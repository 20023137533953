import React, { useRef } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./assets/css/Purchases.css";
import Login from "./components/Auth/Login";
import Header from "./components/Header/Header";
import Loader from "./components/Loader";
import { RouteConstants, getBoolVal, hasAccess } from "./utils";
// import Footer from "./components/Footer/Footer";
import { ConfigProvider, Layout, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Local_Variables, Logout } from "./actions/auth";
import Account from "./components/Account/Account";
import AccountTransaction from "./components/Account/AccountTransaction";
import Advances from "./components/Advances/Advances";
import AdvanceDetail from "./components/Advances/NewAdvancesDetail";
import Analytics from "./components/Analytics/Analytics";
import Cards from "./components/Cards/Card";
// import CardDetails from "./components/Cards/CardDetails";
import { useTranslation } from "react-i18next";
import CardInventory from "./components/Cards/CardInventory";
import CardTransactions from "./components/Cards/CardTransactions";
import CardDetail from "./components/Common/CardDetail";
import SetIn from "./components/Common/SetIn";
import Success from "./components/Common/Success";
import Dashboard from "./components/Dashboard_New/Dashboard";
import Expenses from "./components/Expenses/Expenses";
import LeftNavigation from "./components/LeftNavigation/LeftNavigation";
import ExpensesDetails from "./components/Reports/NewExpensesDetails";
import Reports from "./components/Reports/Report";
import Settings from "./components/Settings/Setting";
import AddBulkUsers from "./components/Settings/UsersControls/Users/AddBulkUsers";
import BulkTransfers from "./components/Transfers/BulkTransfers";
import BulkHistory from "./components/Transfers/BulkTransfers/BulkHistory";
import BulkHistoryDetails from "./components/Transfers/BulkTransfers/BulkHistoryDetail";
import UploadPayments from "./components/Transfers/BulkTransfers/UploadPayments";
import TransferLog from "./components/Transfers/TransferLog";
import Transfers from "./components/Transfers/Transfers";
// import AddTrip from "./components/Trips/AddTrip";
import PrimaryAcc from "./components/Account/PrimaryAcc";
import VirtualAcc from "./components/Account/VirtualAcc";
import Banks from "./components/Accounting/Banks";
import BanksCards from "./components/Accounting/Cards";
import Summary from "./components/Accounting/Summary";
import EmailAction from "./components/Common/EmailAction";
import ReportAction from "./components/Common/ReportAction";
import SendEmail from "./components/Common/SendEmail";
import NewDashboard from "./components/Dashboard2/Dashboard";
import ErrorBoundary from "./components/ErrorBoundary";
import AddExpenses from "./components/Expenses/AddExpenses";
import AddBulkExpense from "./components/Expenses/BulkAddExpense";
import BusinessMainKYC from "./components/OnBoarding/Auth/BusinessKYC/BusinessMainKYC";
import KYCScreens from "./components/OnBoarding/Auth/KYCScreen";
import OnBoardLeftNavigation from "./components/OnBoarding/Auth/OnBoardLeftNavigation/OnBoardLeftNavigation";
import NewPaymentDetail from "./components/Payments/NewPaymentDetails";
import Payroll from "./components/Payroll/Payroll";
import BillsApprovals from "./components/Purchases/Bills/Approvals";
import ApproveBill from "./components/Purchases/Bills/ApproveBill";
import MyBills from "./components/Purchases/Bills/MyBills";
import NewBill from "./components/Purchases/Bills/NewBill";
import NewFileBill from "./components/Purchases/Bills/NewFileBill";
import ViewBill from "./components/Purchases/Bills/ViewBill";
import CompletePayments from "./components/Purchases/Payments/CompletePayments";
import PendingPayments from "./components/Purchases/Payments/PendingPayments";
import ViewPaidBills from "./components/Purchases/Payments/ViewPaidBills";
import ViewPendingBills from "./components/Purchases/Payments/ViewPendingBills";
import PaymentsMade from "./components/Purchases/PaymentsMade";
import Purchases from "./components/Purchases/Purchases";
import UtilityBills from "./components/Purchases/UtilityBills";
import Vendors from "./components/Purchases/Vendors";
import BankingRedirect from "./components/Redirect";
import NewReportDetail from "./components/Reports/NewReportDetail";
import CustomerViewDetails from "./components/Sales/CustomerViewDetails";
import Customers from "./components/Sales/Customers";
import InvoiceApprovals from "./components/Sales/Invoices/Approvals";
import MyInvoices from "./components/Sales/Invoices/MyInvoices";
import NewInvoice from "./components/Sales/Invoices/NewInvoice";
import Sales from "./components/Sales/Sales";
import Organisation from "./components/Settings/Organisation/Organisation";
import BenificiaryTransfers from "./components/Transfers/BankTransfers/BenificiaryTransfers";
import CompanyTransfers from "./components/Transfers/BankTransfers/CompanyTransfers";
import History from "./components/Transfers/BankTransfers/History";
import ManageBeneficiary from "./components/Transfers/ManageBeneficiary";
import PaymentLinks from "./components/Transfers/PaymentLinks";
import AddTripComponent from "./components/Trips/AddTripComponent";
import TripsDetail from "./components/Trips/NewTripDetail";
import Trips from "./components/Trips/Trips";
import ErrorModal from "./config/ErrorModal";
import { resetToken } from "./config/helper";
import history from "./history";
import i18n from "./i18n";
import { ar } from "./languages/arabic";
import { en } from "./languages/english";
// import PaymentReceived from "./components/PDFTemplates/PaymentReceived";
// import PaymentReceivedTwo from "./components/PDFTemplates/PaymentReceivedTwo";
// import Payments from "./components/PDFTemplates/Payment";
import {
  ApplyLoan,
  FreeCreditScore,
  LoanCalculator,
  MyLoans,
} from "./components/Loans";
import Loans from "./components/Loans/Loan";
import Cart from "./components/Cart/Cart";
import CustomerPortalDashboard from "./components/CustomerPortal/CustomerPortalDashboard";
import CustomerPortalDetailView from "./components/CustomerPortal/CustomerPortalDetailView";
import CustomerPortalProfile from "./components/CustomerPortal/CustomerPortalProfile";
import CustomerPortalTableView from "./components/CustomerPortal/CustomerPortalTableView";
import CustomerLeftNavigation from "./components/LeftNavigation/CustomerLeftNavigation";
import VendorLeftNavigation from "./components/LeftNavigation/VendorLeftNavigation";
import Shop from "./components/Products/Shop";
import OrderPageDetails from "./components/Purchases/OrderPagDetails";
import OrdersPage from "./components/Purchases/OrdersPage";
import Portal from "./components/Settings/Organisation/Portal";
import UploadMain from "./components/Upload_Components/UploadMain";
import Receipt from "./components/Purchases/Receipt/Receipt.js";
import ReceiptsDetails from "./components/Purchases/Receipt/ReceiptsDetails.js";
import ReceiptsForm from "./components/Purchases/Receipt/ReceiptsForm.js";
import QuickBookHandler from "./components/Common/QuickBookHandler";
import InventoryTable from "./components/Inventory/InventoryTable.js";
import InventoryForm from "./components/Inventory/InventoryForm.js";
import SendMoney from "./components/Payments/SendMoney/SendMoney.js";
import LeftNavProvider from "./LeftNavProvider.js";
import AccountDetail from "./components/Account/AccountDetail.js";

const Content = Layout.Content;

export const HeaderFooterWrap = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const collapsed = useSelector(
    (state) => state.auth.localVariables?.collapsed
  );
  React.useEffect(() => {
    dispatch({
      type: Local_Variables,
      payload: {
        is_admin: getBoolVal(localStorage.getItem("is_admin")),
      },
    });

    // eslint-disable-next-line
  }, []);

  const menu = localStorage.getItem("menu")
    ? Object.keys(JSON.parse(localStorage.getItem("menu")))
    : [];

  return (
    <Layout
      className="flex-row"
      style={{
        minHeight: "100vh",
      }}
    >
      {localStorage?.getItem("isCustomer") ? (
        <CustomerLeftNavigation />
      ) : localStorage?.getItem("isVendor") ? (
        <VendorLeftNavigation />
      ) : (
        <LeftNavigation />
      )}
      <Layout
        className={`site-layout main-content ${collapsed ? "collapsed" : ""}`}
      >
        <ErrorBoundary>
          <Header />
          <Content>
            {/* {props.role && !isSuperAdmin() && !hasAccess(props.role, "read") ? <div>
              This user do not have permissions for {props.name}
            </div> :
              props.children} */}
            {props.children}
          </Content>
        </ErrorBoundary>
      </Layout>
    </Layout>
  );
};

export const OnBoardingHeaderFooterWrap = (props) => {
  return (
    <>
      <Layout
        className="flex-row onBoard-layout"
        style={{
          minHeight: "100vh",
          background: "#FFFFFF",
          padding: " 2.5rem 2.1rem",
        }}
      >
        <OnBoardLeftNavigation />
        <Layout>
          <Content>{props.children}</Content>
        </Layout>
      </Layout>
    </>
  );
};

function PrivateRoute({ children, path, exact }) {
  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        return localStorage.getItem("token") ? children : <Redirect to="/" />;
      }}
    />
  );
}

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const logoutResponse = useSelector((state) => state.auth.logout || {});
  const topup = useSelector((state) => state.cards.topup);
  const uploadData = useSelector((state) => state?.cards?.uploadBulk);
  const is_admin = useSelector(
    (state) => state?.auth?.localVariables?.is_admin
  );
  const cards_loading = useSelector(
    (state) => state.analytics.analytics_cards?.loading
  );
  const { t } = useTranslation();

  const estimates_loading = useSelector(
    (state) => state.cards?.analytics_estimates?.loading
  );
  const blockCardResponseLoading = useSelector(
    (state) => state.cards.block?.loading
  );
  const replacementResponseLoading = useSelector(
    (state) => state.cards.replacement?.loading
  );
  const card_enalbe_loading = useSelector(
    (state) => state.cards.card_enable?.loading
  );
  const language = useSelector((state) => state.auth.localVariables?.language);
  const corporate_budget_response = useSelector(
    (state) => state.cards.corporate_budget?.data?.response
  );
  const dispatch = useDispatch();
  const intervalRef = useRef();

  React.useEffect(() => {
    hideLoader();
    if (localStorage.getItem("token")) {
      intervalRef.current = setInterval(() => {
        resetToken();
      }, 15 * 60 * 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const langMap = {
    en: en,
    ar: ar,
  };

  React.useEffect(() => {
    if (language && langMap[language]) {
      i18n.addResourceBundle(language, "translation", langMap[language]);
      i18n.changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [language]);
  React.useEffect(() => {
    setIsLoading(
      topup?.loading ||
        uploadData?.loading ||
        cards_loading ||
        estimates_loading ||
        card_enalbe_loading ||
        blockCardResponseLoading ||
        replacementResponseLoading
    );
  }, [
    topup?.loading,
    uploadData?.loading,
    cards_loading,
    estimates_loading,
    card_enalbe_loading,
    blockCardResponseLoading,
    replacementResponseLoading,
  ]);

  const hideLoader = () => {
    const proc = new Promise((resolve) => {
      setTimeout(() => resolve(), 50);
    });
    proc.then(() => setIsLoading(false));
  };

  React.useEffect(() => {
    if (logoutResponse.success) {
      message.success(
        <span className="messageText">{logoutResponse.data.message}</span>
      );
      localStorage.clear();
      history.push(RouteConstants.ROOT);
      window.location.reload(false);
      dispatch({
        type: Logout.RESET,
      });
    } else if (logoutResponse.success === false) {
      dispatch({
        type: Logout.RESET,
      });
      localStorage.clear();
      history.push(RouteConstants.ROOT);
      window.location.reload(false);
      // logoutResponse.message &&
      //   message.error(
      //     <span className="messageText">{logoutResponse.message}</span>
      //   );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutResponse]);

  React.useEffect(() => {
    if (corporate_budget_response?.success) {
    } else {
      if (corporate_budget_response?.message) {
        message.error(
          <span className="messageText">{logoutResponse.message}</span>
        );
        localStorage.clear();
        history.push(RouteConstants.ROOT);
        window.location.reload(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corporate_budget_response]);

  return (
    <ConfigProvider direction={language === "en" ? "ltr" : "rtl"}>
      <LeftNavProvider>
        <div className={language === "en" ? "ltr" : "rtl"}>
          <div className="App">
            {isLoading ? <Loader /> : null}
            <Router history={history}>
              <Switch>
                <Route path="/" component={Login} exact />
                <Route path="/request-demo" component={Login} />
                <Route path="/reset-password" component={Login} />
                <Route path="/two-step-verify" component={Login} />
                <Route path="/set-password" component={Login} />
                <Route path="/forgot-password/" component={Login} />
                <Route path="/confirm_email/" component={Login} />
                <Route path="/sign-up/" component={Login} />
                {/* <PrivateRoute path="/new-dashboard">
            <NewDashboard />
          </PrivateRoute> */}

                <PrivateRoute path="/organisation">
                  <Organisation />
                </PrivateRoute>
                <PrivateRoute path="/portal">
                  <Portal />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/">
                  <HeaderFooterWrap>
                    {/* {is_admin ? <AdminDashboard /> : <Dashboard />} */}
                    {is_admin ? <NewDashboard /> : <Dashboard />}
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/settings">
                  <HeaderFooterWrap>
                    <Settings />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/products">
                  <HeaderFooterWrap>
                    <Shop />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/expenses/reports/">
                  <HeaderFooterWrap role="reports_approvals" name="Reports">
                    <Reports
                      hasWriteAccess={hasAccess("reports_approvals", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/expenses/reports/my-reports">
                  <HeaderFooterWrap name="Reports">
                    <Reports
                      user={true}
                      hasWriteAccess={
                        hasAccess("expense_reports", "write") || true
                      }
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/cart">
                  <HeaderFooterWrap name="Cart">
                    <Cart />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/expenses/">
                  <HeaderFooterWrap name="Expenses">
                    <Expenses
                      hasWriteAccess={hasAccess("expenses", "write") || true}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/expenses/trips/">
                  <HeaderFooterWrap name="Trips">
                    <Trips
                      hasWriteAccess={hasAccess("trips_approvals", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/expenses/trips/my-trips">
                  <HeaderFooterWrap name="Trips">
                    <Trips
                      user={true}
                      hasWriteAccess={hasAccess("trips", "write") || true}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/expenses/add-trip/">
                  <HeaderFooterWrap name="Trips">
                    <AddTripComponent
                      hasWriteAccess={hasAccess("trips", "write") || true}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/expenses/trip-details/:id">
                  <HeaderFooterWrap name="Trips">
                    <TripsDetail
                      hasWriteAccess={
                        hasAccess("trips_approvals", "read")
                          ? hasAccess("trips_approvals", "write")
                          : true
                      }
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/expenses/advances/">
                  <HeaderFooterWrap name="Advances">
                    <Advances
                      hasWriteAccess={hasAccess("advances_approvals", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/expenses/advances/my-advances">
                  <HeaderFooterWrap name="Advances">
                    <Advances
                      user={true}
                      hasWriteAccess={hasAccess("advances", "write") || true}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/expenses/advance-details/:id">
                  <HeaderFooterWrap name="Advances">
                    <AdvanceDetail
                      hasWriteAccess={
                        hasAccess("advances_approvals", "read")
                          ? hasAccess("advances_approvals", "write")
                          : true
                      }
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/expenses/report-details/:id">
                  <HeaderFooterWrap name="Reports">
                    <NewReportDetail
                      hasWriteAccess={
                        hasAccess("reports_approvals", "read")
                          ? hasAccess("reports_approvals", "write")
                          : true
                      }
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/expense-details/:id">
                  <HeaderFooterWrap name="Expenses">
                    <ExpensesDetails
                      hasWriteAccess={hasAccess("expenses", "write") || true}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/expense-add">
                  <HeaderFooterWrap name="Expenses">
                    <AddExpenses />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/bulk-expense-add">
                  <HeaderFooterWrap name="Expenses">
                    <AddBulkExpense />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/cards/">
                  <HeaderFooterWrap name="Cards">
                    <Cards hasWriteAccess={hasAccess("cards", "write")} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/cards/my-cards">
                  <HeaderFooterWrap>
                    <Cards
                      user={true}
                      hasWriteAccess={hasAccess("all_cards", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/card-details/">
                  <HeaderFooterWrap role="cards" name="Cards">
                    <CardDetail
                      hasWriteAccess={hasAccess("all_cards", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/cards/transactions/all-transactions"
                >
                  <HeaderFooterWrap role="all_card_transactions" name="Cards">
                    <CardTransactions
                      hasWriteAccess={
                        hasAccess("all_card_transactions", "write") || true
                      }
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/cards/transactions/my-transactions">
                  <HeaderFooterWrap name="Cards">
                    <CardTransactions
                      user={true}
                      hasWriteAccess={
                        hasAccess("my_card_transactions", "write") || true
                      }
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/cards/inventory/">
                  <HeaderFooterWrap name="Cards">
                    <CardInventory
                      hasWriteAccess={hasAccess("inventory", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/cards/transfers/">
                  <HeaderFooterWrap role="quick_transfers" name="Transfers">
                    <Transfers
                      hasWriteAccess={hasAccess("quick_transfers", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/cards/transfers/bulk-transfers/"
                >
                  <HeaderFooterWrap role="bulk_transfers" name="Transfers">
                    <BulkTransfers
                      hasWriteAccess={hasAccess("bulk_transfers", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute path="/transfers/bulk">
                  <HeaderFooterWrap>
                    <BulkHistory
                      hasWriteAccess={hasAccess(
                        "bulk_transfers_history",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/transfers/send_money">
                  <HeaderFooterWrap>
                    <SendMoney hasWriteAccess={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/transfers/bulk/my-bulk-payments">
                  <HeaderFooterWrap>
                    <BulkHistory
                      hasWriteAccess={hasAccess(
                        "bulk_transfers_history",
                        "write"
                      )}
                      user={true}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/transfers/bulkpayment/uploadpaymentlinks"
                >
                  <HeaderFooterWrap>
                    <UploadPayments
                      type={"bulk_payment"}
                      hasWriteAccess={hasAccess(
                        "bulk_upload_payment_links",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/transfers/bulkpayment/history"
                >
                  <HeaderFooterWrap>
                    <BulkHistory
                      type={"bulk_payment_history"}
                      hasWriteAccess={hasAccess(
                        "bulk_payment_history",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/transfers/bulk/detailpage">
                  <HeaderFooterWrap>
                    <BulkHistoryDetails />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/transfers/bulkpayment/detailpage"
                >
                  <HeaderFooterWrap>
                    <BulkHistoryDetails type={"bulk_payment_detail"} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/transfers/payment_links">
                  <HeaderFooterWrap>
                    <PaymentLinks
                      hasWriteAccess={hasAccess("payment_links", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/transfers/manage_beneficiary">
                  <HeaderFooterWrap>
                    <ManageBeneficiary
                      hasWriteAccess={hasAccess(
                        "manage_beneficiaries",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/transfers/bank/">
                  <HeaderFooterWrap>
                    <CompanyTransfers
                      hasWriteAccess={hasAccess("company_accounts", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/transfers/beneficiary/approvals"
                >
                  <HeaderFooterWrap>
                    <BenificiaryTransfers
                      hasWriteAccess={hasAccess(
                        "beneficiary_accounts",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/transfers/beneficiary/payments"
                >
                  <HeaderFooterWrap>
                    <AccountTransaction
                      hasWriteAccess={hasAccess("account_transactions", "read")}
                      sendMoney={true}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/payment-details/:id">
                  <HeaderFooterWrap>
                    <NewPaymentDetail
                      hasWriteAccess={hasAccess(
                        "beneficiary_accounts",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/transfers/bank/history">
                  <HeaderFooterWrap>
                    <History hasWriteAccess={hasAccess("history", "write")} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/cards/transfers/transfers-log/"
                >
                  <HeaderFooterWrap name="Transfers">
                    <TransferLog
                      hasWriteAccess={hasAccess("transfer_logs", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/transfers/transfers-reports/">
                  <HeaderFooterWrap role="transfers" name="Transfers">
                    <BulkTransfers />
                  </HeaderFooterWrap>
                </PrivateRoute>
                {/* <PrivateRoute exact={true} path="/account">
                <HeaderFooterWrap role="company_master_account" name="Account">
                  <Account
                    hasWriteAccess={hasAccess(
                      "company_master_account",
                      "write"
                    )}
                  />
                </HeaderFooterWrap>
              </PrivateRoute> */}
                <PrivateRoute exact={true} path="/account">
                  <HeaderFooterWrap role="primary_accounts">
                    <PrimaryAcc
                      hasWriteAccess={hasAccess("primary_accounts", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/account/detail">
                  <HeaderFooterWrap role="primary_accounts">
                    <AccountDetail
                      hasWriteAccess={hasAccess("primary_accounts", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/account/accs/virtual">
                  <HeaderFooterWrap>
                    <VirtualAcc />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/upload-bulk-users/">
                  <HeaderFooterWrap>
                    <AddBulkUsers />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/upload-bulk-beneficiaries/">
                  <HeaderFooterWrap>
                    <AddBulkUsers isBeneficiary={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/analytics">
                  <HeaderFooterWrap role="analytics" name="Analytics">
                    <Analytics />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/transactions/">
                  <HeaderFooterWrap role="account_transactions" name="Account">
                    <AccountTransaction
                      hasWriteAccess={hasAccess("account_transactions", "read")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <Route path={"/success"} component={Success} />
                <Route path={"/pinset"} component={SetIn} />
                <Route path={"/quick-books"} component={QuickBookHandler} />
                <PrivateRoute exact={true} path="/orders/">
                  <HeaderFooterWrap>
                    <OrdersPage fromOrder={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/order-detail/:id">
                  <HeaderFooterWrap>
                    <OrderPageDetails fromOrder={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/orderspage/">
                  <HeaderFooterWrap>
                    <OrdersPage />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/sales/orderspage/:id">
                  <HeaderFooterWrap>
                    <OrderPageDetails />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales">
                  <HeaderFooterWrap>
                    <Sales />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/customers/">
                  <HeaderFooterWrap>
                    <Customers
                      hasWriteAccess={hasAccess("customers", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/sales/customers/:id">
                  <HeaderFooterWrap role="customers">
                    <CustomerViewDetails
                      hasWriteAccess={hasAccess("customers", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/orders/">
                  <HeaderFooterWrap>
                    <OrdersPage fromOrder={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/order-detail/:id">
                  <HeaderFooterWrap>
                    <OrderPageDetails fromOrder={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/orderspage/">
                  <HeaderFooterWrap>
                    <OrdersPage />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/sales/orderspage/:id">
                  <HeaderFooterWrap>
                    <OrderPageDetails />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/approvals">
                  <HeaderFooterWrap>
                    <InvoiceApprovals
                      hasWriteAccess={hasAccess("invoices_approvals", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/invoices">
                  <HeaderFooterWrap>
                    <MyInvoices
                      hasWriteAccess={hasAccess("invoices", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute exact={true} path="/sales/orders/approvals">
                  <HeaderFooterWrap>
                    <InvoiceApprovals
                      isSales={true}
                      hasWriteAccess={hasAccess(
                        "sales_orders_approvals",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/orders/my-sales">
                  <HeaderFooterWrap>
                    <MyInvoices
                      isSales={true}
                      user={true}
                      hasWriteAccess={hasAccess("sales_orders", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute exact={true} path="/sales/estimates/approvals">
                  <HeaderFooterWrap>
                    <InvoiceApprovals
                      isEstimates={true}
                      hasWriteAccess={hasAccess("estimates_approvals", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/estimates/my-estimates">
                  <HeaderFooterWrap>
                    <MyInvoices
                      isEstimates={true}
                      user={true}
                      hasWriteAccess={hasAccess("estimates", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/credit-notes">
                  <HeaderFooterWrap>
                    <MyInvoices
                      isCN={true}
                      user={true}
                      hasWriteAccess={hasAccess("credit_notes", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute exact={true} path="/sales/payments-received/">
                  <HeaderFooterWrap>
                    <PaymentsMade received={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/approveinvoice">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"INVOICE"}
                      title={t("invoice")}
                      isInvoiceView={true}
                      moduleName={"INVOICE_MODULE"}
                      module={"INVOICE"}
                      hasWriteAccess={hasAccess("invoices_approvals", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/invoice/">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"INVOICE"}
                      title={t("invoice")}
                      user={true}
                      moduleName={"INVOICE_MODULE"}
                      module={"INVOICE"}
                      hasWriteAccess={hasAccess("invoices", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute exact={true} path="/sales/approveso">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"SALES_ORDER"}
                      title={t("order_sale")}
                      isSales={true}
                      isInvoiceView={true}
                      moduleName={"SALES_ORDER_MODULE"}
                      module={"SALES_ORDER"}
                      hasWriteAccess={hasAccess(
                        "sales_orders_approvals",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/so/">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"SALES_ORDER"}
                      title={t("order_sale")}
                      isSales={true}
                      user={true}
                      moduleName={"SALES_ORDER_MODULE"}
                      module={"SALES_ORDER"}
                      hasWriteAccess={hasAccess("sales_orders", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute exact={true} path="/sales/approveestimate">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"ESTIMATE"}
                      title={t("estimate")}
                      isEstimates={true}
                      isInvoiceView={true}
                      moduleName={"ESTIMATE_MODULE"}
                      module={"ESTIMATE"}
                      hasWriteAccess={hasAccess("estimates_approvals", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/estimate/">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"ESTIMATE"}
                      title={t("estimate")}
                      isEstimates={true}
                      user={true}
                      moduleName={"ESTIMATE_MODULE"}
                      module={"ESTIMATE"}
                      hasWriteAccess={hasAccess("estimates", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/cn/">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"CREDIT"}
                      title={t("credit_notes")}
                      isCN={true}
                      user={true}
                      hasWriteAccess={hasAccess("credit_notes", "write")}
                      module={"CREDIT"}
                      moduleName={"CREDIT_MODULE"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/credit-note/approve-cn">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"CREDIT"}
                      title={t("credit_notes")}
                      isCN={true}
                      isInvoiceView={true}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/credit-note/">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"CREDIT"}
                      title={t("credit_notes")}
                      isCN={true}
                      user={true}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute exact={true} path="/purchases">
                  <HeaderFooterWrap>
                    <Purchases />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/vendors/">
                  <HeaderFooterWrap>
                    <Vendors hasWriteAccess={hasAccess("vendors", "write")} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/purchases/vendors/:id">
                  <HeaderFooterWrap>
                    <CustomerViewDetails
                      isVendor={true}
                      hasWriteAccess={hasAccess("vendors", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/sales/payments-received/view-payment">
                  <HeaderFooterWrap>
                    <ApproveBill
                      isPayment={true}
                      type={"INVOICE_RECEIPT"}
                      title={t("received_payment")}
                      user={true}
                      isRecieved={true}
                      hasWriteAccess={hasAccess("payments_received", "write")}
                      module={"INVOICE_RECEIPT"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/bills/approvals">
                  <HeaderFooterWrap>
                    <BillsApprovals user={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/orders/approvals">
                  <HeaderFooterWrap>
                    <BillsApprovals user={true} isPO={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute path="/purchases/approvebill">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"BILL"}
                      title={t("bill")}
                      moduleName={"BILL_MODULE"}
                      module={"BILL"}
                      hasWriteAccess={hasAccess("bill_approvals", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute path="/purchases/approvepo">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"PURCHASE_ORDER"}
                      title={t("purchase_order")}
                      isPO={true}
                      moduleName={"PURCHASE_ORDER_MODULE"}
                      module={"PURCHASE_ORDER"}
                      hasWriteAccess={hasAccess(
                        "purchase_order_approvals",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute path="/sales/estimate/approve">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"ESTIMATE"}
                      isEstimates={true}
                      title={t("est")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/sales/credit-note/approve">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"CREDIT"}
                      isCN={true}
                      title={t("credit_notes")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute path="/sales/approve">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"SALES_ORDER"}
                      isSales={true}
                      title={t("order_sale")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute path="/purchases/bills/bill/">
                  <HeaderFooterWrap>
                    <ViewBill />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/bills/my-bills">
                  <HeaderFooterWrap>
                    <MyBills
                      user={true}
                      moduleName={"BILL_MODULE"}
                      hasWriteAccess={hasAccess("my_bills", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/purchases/bills/create-new-bill"
                >
                  <HeaderFooterWrap>
                    <NewBill />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/orders/my-bills">
                  <HeaderFooterWrap>
                    <MyBills
                      user={true}
                      isPO={true}
                      hasWriteAccess={hasAccess("my_purchases", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/utility-bills">
                  <HeaderFooterWrap>
                    <UtilityBills />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/payroll">
                  <HeaderFooterWrap>
                    <Payroll />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/purchases/orders/create-new-bill"
                >
                  <HeaderFooterWrap>
                    <NewBill isPO={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/sales/invoices/create-new-invoice"
                >
                  <HeaderFooterWrap>
                    <NewInvoice />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/orders/create-new-sale">
                  <HeaderFooterWrap>
                    <NewInvoice isSales={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/sales/estimates/create-new-estimate"
                >
                  <HeaderFooterWrap>
                    <NewInvoice isEstimates={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/sales/credit-note/create-new-credit"
                >
                  <HeaderFooterWrap>
                    <NewInvoice isCN={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/purchases/newbill">
                  <HeaderFooterWrap>
                    <NewFileBill />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute exact={true} path="/purchases/editbill">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"BILL"}
                      title="Bill"
                      user={true}
                      module={"BILL"}
                      hasWriteAccess={hasAccess("my_bills", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/purchases/orders/editbill">
                  <HeaderFooterWrap>
                    <ApproveBill
                      type={"PURCHASE_ORDER"}
                      title={t("purchase_order")}
                      isPO={true}
                      user={true}
                      moduleName={"PURCHASE_ORDER_MODULE"}
                      module={"PURCHASE_ORDER"}
                      hasWriteAccess={hasAccess("my_purchases", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/purchases/payments/pending">
                  <HeaderFooterWrap>
                    <PendingPayments />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute path="/purchases/payments/completed">
                  <HeaderFooterWrap>
                    <CompletePayments />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute path="/purchases/viewpaidbills">
                  <HeaderFooterWrap>
                    <ViewPaidBills
                      type={"BILL_RECEIPT"}
                      module={"BILL_RECEIPT"}
                      hasWriteAccess={hasAccess("payments_made_paid", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute path="/purchases/Viewpendingbills">
                  <HeaderFooterWrap>
                    <ViewPendingBills
                      type={"BILL"}
                      hasWriteAccess={hasAccess(
                        "payments_made_pending",
                        "write"
                      )}
                      module={"BILL"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sendemail">
                  <HeaderFooterWrap>
                    <SendEmail />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/accounting/summary">
                  <HeaderFooterWrap>
                    <Summary />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/statements">
                  <HeaderFooterWrap>
                    <Banks hasWriteAccess={hasAccess("banks", "read")} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/accounting/cards">
                  <HeaderFooterWrap>
                    <BanksCards />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/customer/upload/section">
                  <HeaderFooterWrap>
                    <UploadMain type={"customers"} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/vendor/upload/section">
                  <HeaderFooterWrap>
                    <UploadMain type={"vendors"} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/estimate/upload/section">
                  <HeaderFooterWrap>
                    <UploadMain type={"estimate"} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/invoice/upload/section">
                  <HeaderFooterWrap>
                    <UploadMain type={"invoice"} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/order/upload/section">
                  <HeaderFooterWrap>
                    <UploadMain type={"sales_order"} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/credit/notes/upload/section">
                  <HeaderFooterWrap>
                    <UploadMain type={"credit_notes"} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/purchase/order/upload/section"
                >
                  <HeaderFooterWrap>
                    <UploadMain type={"purchase_order"} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/bill/notes/upload/section">
                  <HeaderFooterWrap>
                    <UploadMain type={"bill"} />
                  </HeaderFooterWrap>
                </PrivateRoute>

                {/* customer Protal routes */}
                <PrivateRoute exact={true} path="/customer/dashboard">
                  <HeaderFooterWrap>
                    <CustomerPortalDashboard />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/customer/estimates">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView
                      isEstimates={true}
                      moduleType={"ESTIMATE"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/customer/sales">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView
                      isSales={true}
                      moduleType={"SALES_ORDER"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/customer/invoice">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView moduleType={"INVOICE"} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/cutomer/credit">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView
                      isCN={true}
                      moduleType={"CREDIT"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/customer/invoice/retainer">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView
                      isRetainer={true}
                      moduleType={"INVOICE"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/customer/credit">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView
                      isCN={true}
                      moduleType={"ESTIMATE"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/customer/payments">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView
                      isPayment={true}
                      moduleType={"INVOICE_RECEIPT"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/customer/portal/detail/:id">
                  <HeaderFooterWrap>
                    <CustomerPortalDetailView />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/customer/portal/profile">
                  <HeaderFooterWrap>
                    <CustomerPortalProfile />
                  </HeaderFooterWrap>
                </PrivateRoute>

                {/* vendor portal */}
                <PrivateRoute exact={true} path="/vendor/dashboard">
                  <HeaderFooterWrap>
                    <CustomerPortalDashboard />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/vendor/purchases">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView
                      isVendorPurchses={true}
                      moduleType={"PURCHASE_ORDER"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/vendor/invoice">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView
                      isVendorInvoice={true}
                      moduleType={"BILL"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/vendor/payments">
                  <HeaderFooterWrap>
                    <CustomerPortalTableView
                      isVendorPayment={true}
                      isPayment={true}
                      moduleType={"BILL_RECEIPT"}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute exact={true} path="/vendor/portal/detail/:id">
                  <HeaderFooterWrap>
                    <CustomerPortalDetailView />
                  </HeaderFooterWrap>
                </PrivateRoute>

                {/* Material Receipt */}
                <PrivateRoute exact={true} path="/purchases/material/approvals">
                  <HeaderFooterWrap>
                    <Receipt
                      isMaterialApp={true}
                      hasWriteAccess={hasAccess(
                        "material_receipt_approvals",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/material/receipt">
                  <HeaderFooterWrap>
                    <Receipt
                      isMaterial={true}
                      hasWriteAccess={hasAccess(
                        "my_material_receipts",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/material/qc-check">
                  <HeaderFooterWrap>
                    <Receipt
                      isQCcheck={true}
                      hasWriteAccess={hasAccess("quality_check", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/material/returns">
                  <HeaderFooterWrap>
                    <Receipt
                      isReturns={true}
                      hasWriteAccess={hasAccess("returns", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/receipts/details">
                  <HeaderFooterWrap>
                    <ReceiptsDetails />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/purchases/material/form">
                  <HeaderFooterWrap>
                    <ReceiptsForm isMaterial={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/delivery/approvals">
                  <HeaderFooterWrap>
                    <Receipt
                      isDeliveryApp={true}
                      hasWriteAccess={hasAccess(
                        "delivery_challan_approvals",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/delivery/receipt">
                  <HeaderFooterWrap>
                    <Receipt
                      isDelivery={true}
                      hasWriteAccess={hasAccess(
                        "my_delivery_challans",
                        "write"
                      )}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sales/delivery/form">
                  <HeaderFooterWrap>
                    <ReceiptsForm isDelivery={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>

                {/* onboarding screens */}
                <PrivateRoute exact={true} path="/onboard/kyc">
                  {/* <HeaderFooterWrap> */}
                  <KYCScreens />
                  {/* </HeaderFooterWrap> */}
                </PrivateRoute>

                <PrivateRoute exact={true} path="/onboard/businesskyc">
                  <OnBoardingHeaderFooterWrap>
                    <BusinessMainKYC />
                  </OnBoardingHeaderFooterWrap>
                </PrivateRoute>

                {/* {inventory start} */}
                <PrivateRoute exact={true} path="/inventory/items">
                  <HeaderFooterWrap>
                    <InventoryTable
                      isItems={true}
                      hasWriteAccess={hasAccess("product_inventory", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/inventory/inventory-report">
                  <HeaderFooterWrap>
                    <InventoryTable
                      isINReport={true}
                      hasWriteAccess={hasAccess("product_inventory", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/inventory/inventory-log">
                  <HeaderFooterWrap>
                    <InventoryTable
                      isINLogs={true}
                      hasWriteAccess={hasAccess("product_inventory", "write")}
                    />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/inventory/items/create">
                  <HeaderFooterWrap>
                    <InventoryForm isINLogs={true} />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <PrivateRoute exact={true} path="/loans">
                  <HeaderFooterWrap>
                    <Loans />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/loans/apply-loans">
                  <HeaderFooterWrap>
                    <ApplyLoan />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/loans/my-loans">
                  <HeaderFooterWrap>
                    <MyLoans />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/loans/free-credit-score">
                  <HeaderFooterWrap>
                    <FreeCreditScore />
                  </HeaderFooterWrap>
                </PrivateRoute>
                <PrivateRoute exact={true} path="/loans/loan-calculator">
                  <HeaderFooterWrap>
                    <LoanCalculator />
                  </HeaderFooterWrap>
                </PrivateRoute>

                <Route path={"/consent"} component={BankingRedirect} />

                <Route
                  path={"/report-approve/:id"}
                  render={() => <ReportAction type="approve" />}
                />
                <Route
                  path={"/report-reject/:id"}
                  render={() => <ReportAction type="reject" />}
                />
                {/* sales and purchases modules */}
                <Route
                  path={"/email-approve"}
                  render={() => <EmailAction type="approve" />}
                />
                <Route
                  path={"/email-reject"}
                  render={() => <EmailAction type="reject" />}
                />
              </Switch>
            </Router>
            <ErrorModal />
            <ToastContainer />
            <div className="shimmer"></div>
          </div>
        </div>
      </LeftNavProvider>
    </ConfigProvider>
  );
}

export default App;
