import { Document, Page, View, StyleSheet, Text, PDFViewer, Font } from "@react-pdf/renderer";
import React from "react";
import moment from "moment";
import TemplateTable from "./TemplateTable";
import { columns, columnsOrder } from "./invPDF2";



const CreditPdf = (props) => {
	const {
		billingAddressCity,
		billingAddressState,
		billingAddresszip,
		billingCountry,
		billPhone,
		billAddress,
	} = props;

	// Function to form the address
	const formatAddress = () => {
		const addressComponents = [

			billAddress,
			billingAddressCity,
			billingAddressState,
			billingAddresszip,
			billPhone,
			billingCountry,
		];

		// Filter out undefined or empty values
		const filteredAddressComponents = addressComponents.filter(Boolean);
		const address = filteredAddressComponents.join(', ');

		return address;
	};

	const formAddress = formatAddress()


	function calculatePercentage(total, taxPrice) {
		let p = taxPrice / total;
		return p * 100;
	}



	function getTaxRates(items) {
		const taxRates = {}

		for (let i = 0; i < items.length; i++) {
			let per = calculatePercentage(parseFloat(items[i].total_amount), parseFloat(items[i].tax_amount))

			if (taxRates[per]) {
				taxRates[per] = taxRates[per] + parseFloat(items[i].tax_amount)

			} else {
				taxRates[per] = parseFloat(items[i].tax_amount)
			}
		}

		return taxRates;
	}





	const styles = StyleSheet.create({

		page: {
			paddingTop: 60,
			paddingLeft: 50,
			paddingRight: 50,
			lineHeight: 1.5,
			//  fontFamily:"Roboto"
		},

		data: {
			color: "grey",
			fontSize: "12px",
			marginTop: "5px",
			maxWidth: "180px"
		},

		main: {
			display: "flex",
			flexDirection: 'row',
			justifyContent: "space-between",
		},
		main1: {
			display: "flex",
			flexDirection: 'row',
			justifyContent: "space-between",
			marginTop: 60
		},

		main3: {
			marginTop: 50,
			flexDirection: 'row',
		},
		test: {
			flexDirection: "column",
			alignItems: 'flex-end',
			fontSize: "15px",
		},
		subMenu: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginTop: 10,
			width: "60%"
		},
		display: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			//paddingHorizontal: 20,
			marginTop: 10,
			minWidth: "30%"
		},
		container: {
			display: "flex",
			alignItems: 'flex-end',
			marginTop: 10,
		},
		text: {
			fontSize: 12,
			marginBottom: 5,
			//color: "grey"
		},

	});
	const currency = props?.useDummyData === true ? props?.obj?.currency : props?.currency

	return (
		<div>
			<PDFViewer style={{ width: '100%', height: '100vh' }}>
				<Document initialScale={1.0}>
					<Page style={styles.page} wrap={false}>
						<View style={styles.main}>
							<View>
								<Text style={{ fontSize: "10px" }} >TAX ID : {props?.useDummyData === true ? "GST24AAACC4563F" : props?.gst}</Text>
							</View>
							<View style={styles.test}>
								<Text style={{ fontSize: "25px", color: "#808080" }}> {props?.useDummyData === true ? props?.obj?.title : props?.title}</Text>

								<Text style={{ fontSize: "12px" }}> {props?.useDummyData === true ? props?.obj?.noteTitle : props?.noteTitle} {props?.useDummyData === true ? props?.obj?.CreditNo : props?.creditNoteNo}</Text>

								{props?.type === "INVOICE" || props?.type === "BILL" || props?.obj?.balanceFlag === true ? <Text style={styles.textTwo}>Balance Due</Text> : null}

								{props?.balanceAmount && props?.type === "INVOICE" || props?.type === "BILL" || props?.obj?.balanceFlag === true ?
									<Text style={{ fontSize: "12px", color: "black" }}> {props?.useDummyData === true ? currency + ' ' + props?.obj?.balanceAmount : currency + ' ' + props?.balanceAmount} </Text> : null}

							</View>
						</View>

						<View style={styles.main1}>
							<View>
								<Text style={{ color: "grey", fontSize: "14px" }}>BILL TO</Text>
								<Text style={{ marginTop: "5px", color: "grey", maxWidth: "180px", fontSize: "12px" }}>{props?.useDummyData === true ? props?.obj?.beniName : props?.benefiName}</Text>
								<Text style={{ marginTop: "5px", color: "grey", maxWidth: "180px", fontSize: "12px" }}>{props?.useDummyData === true ? props?.obj?.beniEmail : props?.benefiEmail}</Text>
								<Text style={{ marginTop: "5px", color: "grey", maxWidth: "180px", fontSize: "12px" }}>{props?.useDummyData === true ? props?.obj?.address : formAddress}</Text>
								<Text style={{ marginTop: "10px", fontSize: "12px" }}>TAX ID : {props?.useDummyData === true ? props?.obj?.gst : props?.benficiaryGST}</Text>
							</View>
							<View style={{ display: "flex", flexDirection: "row-reverse" }}>
								<View style={styles.subMenu}>
									<View style={{ ...styles.container, marginRight: "10px" }}>

										<Text style={{ color: "grey", fontSize: 12 }}>{props?.useDummyData === true ? props?.obj?.numTitle : props?.numTitle} </Text>
										<Text style={{ color: "grey", fontSize: 12 }}>{props?.useDummyData === true ? props?.obj?.dateTitle : props?.dateTitle}</Text>

									</View>
									<View style={styles.container}>

										{props?.invoiceNo || props?.useDummyData === true ? <Text style={{ color: "black", marginLeft: 4, fontSize: 12 }}>{props?.useDummyData == true ? props?.obj?.invoiceNo : props?.invoiceNo}</Text> : null}
										{props?.invoiceDate || props?.useDummyData === true ? <Text style={{ color: "black", marginLeft: 4, fontSize: 12 }}>{moment(props?.invoiceDate).format("MMMM DD, YY")}</Text> : null}
									</View>
								</View>
							</View>


						</View>

						<TemplateTable lineItems={props?.useDummyData === true ? props?.obj?.lineItems : props.lineItems} columns={props?.isOrder ? columnsOrder : columns} currency={currency} tableRow={{
							borderBottom: "0px", color: "grey", fontSize: "12px", padding: 5

						}} />
						<View >
							<Text style={{ borderBottom: "1px solid black", paddingTop: "30px" }} ></Text>
						</View>
						<View style={{ display: "flex", flexDirection: "row-reverse" }}>
							<View style={styles.display} break>
								<View style={{ ...styles.container, marginRight: "10px" }} >
									{props?.isOrder ? null : <Text style={styles.text}>Sub Total</Text>}
									{props?.isOrder ? null : <Text style={styles.text}>Total Discount</Text>}
									{props?.isOrder ? null : <Text style={styles.text}>Total Tax</Text>}
									{props?.type === "PURCHASE_ORDER" || props?.type === "BILL" || props?.obj?.tdsFlag === true ? <Text style={styles.text}>TDS Amount</Text> : null}

									<Text style={styles.text}>Total Amount</Text>
								</View>
								<View style={{ ...styles.container, marginBottom: "90%" }}>
									{props?.isOrder ? null : <Text style={styles.text}> {props?.useDummyData === true ? currency + ' ' + props?.obj?.subTotal : props?.subTotal ? currency + ' ' + props?.subTotal : currency + ' ' + 0}</Text>}
									{props?.isOrder ? null : <Text style={styles.text}> {props?.discount ? props?.currency + ' ' + props?.discount : props?.obj?.currency + ' ' + 0}</Text>}
									{props?.isOrder ? null : <Text style={styles.text}> {props?.useDummyData === true ? currency + ' ' + props?.obj?.totalTax : props?.taxAmount ? currency + ' ' + props?.taxAmount : currency + ' ' + 0}</Text>}
									{props?.type === "PURCHASE_ORDER" || props?.type === "BILL" || props?.obj?.tdsFlag === true ? <Text style={styles.text}>{props?.useDummyData === true ? currency + ' ' + props?.obj?.tdsAmt : props?.TDSAmt ? currency + ' ' + props?.TDSAmt : currency + ' ' + 0}</Text> : null}

									<Text style={styles.text}>{props?.totalAmount ? currency + ' ' + props?.totalAmount : currency + ' ' + 0}</Text>

								</View>
							</View>
						</View>


					</Page>
				</Document>
			</PDFViewer>
		</div>
	)

}

export default CreditPdf;