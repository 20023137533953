import React from 'react';

const SettingsSVG = ({ scale, opacity, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.048" height="23" viewBox="0 0 23.048 23" style={{
      scale: scale ? scale : 1,
      opacity: opacity ? opacity : "0.7",
      zoom: scale ? scale : 1
    }}>
      <g id="Group_16961" data-name="Group 16961" transform="translate(-72.439 -747.999)">
        <g id="Group_16267" data-name="Group 16267" transform="translate(70.039 745.399)" opacity={opacity || "0.7"}>
          <g id="Group_16266" data-name="Group 16266" transform="translate(2.4 2.6)">
            <path id="Path_18981" data-name="Path 18981" d="M24.964,11.713,23.1,11.349a.55.55,0,0,1-.436-.388,5.005,5.005,0,0,0-.364-.824.559.559,0,0,1,.048-.582L23.412,7.98a.587.587,0,0,0-.073-.751L20.8,4.684a.587.587,0,0,0-.751-.073L18.468,5.678a.624.624,0,0,1-.582.048c-.267-.121-.557-.242-.824-.339a.636.636,0,0,1-.388-.436l-.364-1.866A.576.576,0,0,0,15.73,2.6H12.119a.594.594,0,0,0-.582.485l-.364,1.866a.592.592,0,0,1-.388.436,8.372,8.372,0,0,0-.824.339.559.559,0,0,1-.582-.048L7.8,4.612a.587.587,0,0,0-.751.073L4.509,7.229a.619.619,0,0,0-.073.751L5.5,9.556a.618.618,0,0,1,.048.582c-.121.267-.242.557-.364.824a.636.636,0,0,1-.436.388l-1.866.364a.6.6,0,0,0-.485.582v3.611a.576.576,0,0,0,.485.582l1.866.364a.55.55,0,0,1,.436.388,5.005,5.005,0,0,0,.364.824.559.559,0,0,1-.048.582L4.436,20.219a.587.587,0,0,0,.073.751l2.545,2.545a.587.587,0,0,0,.751.073L9.38,22.522a.624.624,0,0,1,.582-.048c.267.121.557.242.824.339a.636.636,0,0,1,.388.436l.364,1.866a.576.576,0,0,0,.582.485H15.73a.594.594,0,0,0,.582-.485l.364-1.866a.592.592,0,0,1,.388-.436,8.372,8.372,0,0,0,.824-.339.559.559,0,0,1,.582.048l1.575,1.066a.587.587,0,0,0,.751-.073l2.545-2.545a.619.619,0,0,0,.073-.751l-1.066-1.575a.618.618,0,0,1-.048-.582c.121-.267.242-.557.364-.824a.636.636,0,0,1,.436-.388l1.866-.364a.6.6,0,0,0,.485-.582V12.294A.576.576,0,0,0,24.964,11.713ZM13.936,19.88a5.78,5.78,0,1,1,5.792-5.792A5.792,5.792,0,0,1,13.936,19.88Z" transform="translate(-2.4 -2.6)" fill={fill || "#fff"} />
            <path id="Path_18982" data-name="Path 18982" d="M37.423,33.4a4.023,4.023,0,1,0,4.023,4.023A4.029,4.029,0,0,0,37.423,33.4Z" transform="translate(-25.887 -25.935)" fill={fill || "#fff"} />
          </g>
        </g>
      </g>
    </svg>

  );
};

export default SettingsSVG;