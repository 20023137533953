import React from "react";
import { useRef } from "react";
import { useState } from "react";
import search from "../../assets/icons/search.svg";

const SearchInput = (props) => {
  const [input, setInput] = useState("");
  const searchRef = useRef()

  return (
    <div className="search-input">
      <form onSubmit={(e) => {
        e.preventDefault();
        props.handleSearch && props.handleSearch(input);
      }}>
        <input
          placeholder={props.placeholder || "Search by ID, Name"}
          value={input}
          onInput={(e) => {
            setInput(e.target.value.trimStart())
            if (searchRef.current) {
              clearTimeout(searchRef.current)
            }
            searchRef.current = setTimeout(() => {
              props.handleSearch && props.handleSearch(e.target.value.trimStart());
            }, 500)
          }}
        />
        <img
          src={search}
          alt="search"
          className="cursor"
          onClick={() => {
            props.handleSearch && props.handleSearch(input);
          }}
        />
      </form>
    </div>
  );
};

export default SearchInput;
