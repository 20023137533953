import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useNotificationAxios from "../../config/useNotification";
import { apis } from "../../config/APIs";

const OTPComponent = (props) => {
  const { t } = useTranslation();
  const { onCall, data, reset } = useNotificationAxios({
    api: apis.resend_otp,
    method: "post"
  });
  const [otpIndex, setOtpIndex] = useState(0);
  // const [isEdit, setIsEdit] = useState(false);
  const numKeyCodes = Array.from({ length: 10 }, (_, i) => i + 48);
  const numPadKeyCodes = Array.from({ length: 10 }, (_, i) => i + 96);

  const otpRefs = useRef([]);

  const [state, setState] = useState({
    timeLeft: 300,
    resendMessage: false,
  });

  useEffect(() => {
    if (!state.timeLeft) return;
    const intervalId = setInterval(() => {
      setState({ ...state, timeLeft: state.timeLeft - 1 });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [state.timeLeft]);

  useEffect(() => {
    otpRefs.current[0].focus();
    otpRefs.current[0].onkeyup = (e) => {
      if (e.keyCode === 39) {
        otpRefs.current[1].focus();
        setOtpIndex(1);
      }
    };
    if (props.value) {
      setOtpIndex(props.value.length - 1);
      props.value.split("").forEach((val, idx) => {
        otpRefs.current[idx].value = val;
      });
      otpRefs.current[props.value.length - 1].focus();
    }
  }, []);

  useEffect(() => {
    let idx = otpIndex,
      len = otpRefs.current.length;
    let keyCount = 0;
    otpRefs.current[idx].onkeyup = (e) => {
      if (e.keyCode === 37 && idx >= 1) {
        otpRefs.current[idx - 1].select();
        setOtpIndex(idx - 1);
      }

      if (e.keyCode === 39 && idx >= 0 && idx + 1 < len) {
        otpRefs.current[idx + 1].select();
        setOtpIndex(idx + 1);
      }

      if (e.keyCode === 8 || e.keyCode === 46) {
        if (idx - 1 >= 0) {
          otpRefs.current[idx].value = "";
          if (keyCount === 1) {
            otpRefs.current[idx - 1].select();
            setOtpIndex(idx - 1);
            keyCount = 0;
          }
          keyCount++;
        }
        if (idx === 0) {
          otpRefs.current[idx].value = "";
        }
      } else {
        if (
          idx + 1 < len &&
          (numKeyCodes.includes(e.keyCode) ||
            numPadKeyCodes.includes(e.keyCode ? e.keyCode : e.which))
        ) {
          otpRefs.current[idx].value = e.key;
          otpRefs.current[idx + 1].select();
          setOtpIndex(idx + 1);
        } else {
          if (
            numKeyCodes.includes(e.keyCode) ||
            numPadKeyCodes.includes(e.keyCode ? e.keyCode : e.which)
          ) {
            otpRefs.current[idx].value = e.key;
            // props.onChange(
            //   otpRefs.current.map((item) => item?.value).join("")
            // );
          }
        }
        keyCount = 0;
      }
      props.onChange(otpRefs.current.map((item) => item?.value).join(""));
    };
  }, [otpIndex]);

  return (
    <>
      <b className="alignCenter mt-2 text-center mb-1">
        {props.label ? props.label : t("enterOtp")}
      </b>
      {/* <Field name="otp" autoComplete="off" maxLength={6} /> */}
      <div className="otpInputBlock">
        <div className="otpInputField">
          <input
            disabled={props.loading}
            name="otp"
            autoComplete="off"
            maxLength={1}
            value={otpRefs.current[0]?.value}
            ref={(ref) => (otpRefs.current[0] = ref)}
          />
        </div>
        <div className="otpInputField">
          <input
            disabled={props.loading}
            name="otp"
            autoComplete="off"
            maxLength={1}
            value={otpRefs.current[1]?.value}
            ref={(ref) => (otpRefs.current[1] = ref)}
          />
        </div>
        <div className="otpInputField">
          <input
            disabled={props.loading}
            name="otp"
            autoComplete="off"
            maxLength={1}
            value={otpRefs.current[2]?.value}
            ref={(ref) => (otpRefs.current[2] = ref)}
          />
        </div>
        <div className="otpInputField">
          <input
            disabled={props.loading}
            name="otp"
            autoComplete="off"
            maxLength={1}
            value={otpRefs.current[3]?.value}
            ref={(ref) => (otpRefs.current[3] = ref)}
          />
        </div>
        {!props.isFourDigit && (
          <>
            <div className="otpInputField">
              <input
                disabled={props.loading}
                name="otp"
                autoComplete="off"
                maxLength={1}
                value={otpRefs.current[4]?.value}
                ref={(ref) => (otpRefs.current[4] = ref)}
              />
            </div>
            <div className="otpInputField">
              <input
                disabled={props.loading}
                name="otp"
                autoComplete="off"
                maxLength={1}
                value={otpRefs.current[5]?.value}
                ref={(ref) => (otpRefs.current[5] = ref)}
              />
            </div>
          </>
        )}
      </div>
      <div className="timeLeft">
        {state.timeLeft > 0 && <div className="alignCenter">
          {t("otpExpirein")}{" "}
          {state.timeLeft}{" "}
          {t("paySec")}
        </div>}

        {state.timeLeft === 0 && !data?.message && (
          <h5
            className="otpMsg alignCenter mt-2 text-center mb-1"
            onClick={() => {
              onCall({
                data: {
                  user_id: localStorage.getItem("user_id"),
                  email_id: localStorage.getItem("user"),
                  source: props.source
                }
              }).then(() => {
                let timeout = setTimeout(() => {
                  reset()
                  clearTimeout(timeout)
                }, 6000)
                props.onChange("")
                otpRefs.current?.forEach(ele => {
                  ele.value = ""
                })
              }).catch(() => {
                let timeout = setTimeout(() => {
                  reset()
                  clearTimeout(timeout)
                }, 6000)
              })
              setState({
                ...state,
                timeLeft: 300,
                resendMessage: true,
              });
            }}
          >
            {t("ididntReceiveTxtMsg")}{" "}
            <span className="Cursor resendOtp">
              {t("resendOtp")}
            </span>
          </h5>
        )}
        {state.resendMessage && data?.message && (
          <h4 className="text-center">{t("otpSuccess")}</h4>
        )}

        {state.timeLeft === 0 && !data?.message && (
          <h4 className="alignCenter text-center">
            {t("otpExpired")}
          </h4>
        )}
      </div>
    </>
  );
};

export default OTPComponent;
