import { Col, Popover, Row } from "antd";
import React from "react";
import TableComponent from "../../Common/TableComp/TableComp";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";

const Bentable = ({ data, headers, errors }) => {
  const { t } = useTranslation();

  const columnsTransfers = [
    {
      title: t("Beneficiary Name"),
      dataIndex: "beneficiary_name",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["beneficiary_name"] && (
            <Popover content={errors[index]?.["beneficiary_name"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("Beneficiary Email"),
      dataIndex: "email",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["email"] && (
            <Popover content={errors[index]?.["email"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("bank_name"),
      dataIndex: "branch",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["branch"] && (
            <Popover content={errors[index]?.["branch"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("bank_account_num"),
      dataIndex: "account_number",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {parseFloat(record).toString()}
          {errors[index]?.["account_number"] && (
            <Popover content={errors[index]?.["account_number"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("ifsc_code"),
      dataIndex: "ifsc_code",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["ifsc_code"] && (
            <Popover content={errors[index]?.["ifsc_code"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("Currency"),
      dataIndex: "currency",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["currency"] && (
            <Popover content={errors[index]?.["currency"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "primary_contact",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["primary_contact"] && (
            <Popover content={errors[index]?.["primary_contact"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="upload_payment_table">
        <div className="" style={{ paddingTop: "22px", width: "100%" }}>
          <TableComponent
            columns={headers ? headers : columnsTransfers}
            loading=""
            data={data}
            scroll={{
              x: "100%",
            }}
            hasCheckbox={false}
            rowKey="id"
          />
        </div>
      </div>
    </>
  );
};

export default Bentable;
