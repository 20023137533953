import React from 'react'
import { useForm } from 'react-hook-form';
import { FormInput } from '../inputs';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

const SendEmail = ({ handleCancel }) => {
    const { t } = useTranslation();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
        getValues
    } = useForm({
        defaultValues: {},
        shouldUseNativeValidation: false,
    });
    const onSubmit = (data) => {

    }
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pp-form-item">
                    <FormInput
                        control={control}
                        {...register("from_email")}
                        errors={errors}
                        label={t('From Email')}
                        placeholder={t('Enter From Email')}
                    />
                </div>
                <div className="pp-form-item">
                    <FormInput
                        control={control}
                        {...register("subject")}
                        errors={errors}
                        label={t('Subject')}
                        placeholder={t('Enter Subject')}
                    />
                </div>
                <div className="pp-form-item">
                    <FormInput
                        control={control}
                        {...register("message")}
                        errors={errors}
                        label={t('Message')}
                        placeholder={t('Enter Message')}
                        type="textarea"
                    />
                </div>
                <div>
                    <Button
                        key="1"
                        htmlType="submit"
                        className="formButton mt3 primaryButton"
                    >
                        {t("Send")}
                    </Button>
                    <Button
                        key="2"
                        className="cancelButton ml1 mt3 "
                        onClick={() => handleCancel()}
                    >
                        {t("cancel")}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default SendEmail