import { put, takeLatest, call } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import {
  sagaFunctions,
  sagaFunctionsSettings,
  sagaFunctionsExpense,
} from "../../utils";
import {
  Expenses,
  AddExpense,
  UpdateExpense,
  ValidateExpense,
  ExpenseDetails,
  ExpenseCustomDetails,
  ExpenseFields,
  PrefixExpenseFields,
  ListExpensePolicies,
  EditExpensePolicies,
  DeleteExpensePolicies,
  CreateExpensePolicies,
  ShowExpensePolicies,
  ExpenseComments,
  DeleteExpense,
} from "./expenses";
import endpointPayApiBeta from "../../config/AxiosPayApiBeta";
import endpointPayExpenseBeta from "../../config/AxiosPayExpense";

export function getExpenses(req) {
  return sagaFunctionsExpense(
    Expenses,
    "post",
    apis.userTransactions + `?module_type=EXPENSE`,
    req.payload
  )();
}
export function getExpenseComments(req) {
  return sagaFunctions(
    ExpenseComments,
    "get",
    apis.expenseComments,
    req.payload
  )();
}

export function addExpenses(req) {
  if (req.payload.isBulk) {
    return sagaFunctions(
      AddExpense,
      "post",
      apis.bulk_expenses,
      req.payload.vals
    )();
  } else {
    return sagaFunctionsExpense(
      AddExpense,
      "post",
      apis.addTransactions,
      req.payload
    )();
  }
}

export function updateExpenses(req) {
  return sagaFunctionsExpense(
    UpdateExpense,
    "post",
    apis.userUpdateTransactions,
    req.payload
  )();
}

export function validateExpense(req) {
  return sagaFunctionsExpense(
    ValidateExpense,
    "post",
    apis.validate_expense,
    req.payload
  )();
}

export function getExpenseDetails(req) {
  return sagaFunctionsExpense(
    ExpenseDetails,
    "get",
    apis.expenseDetails,
    req.payload
  )();
}

export function getExpenseCustomDetails(req) {
  return sagaFunctionsSettings(
    ExpenseCustomDetails,
    "get",
    apis.customFieldDetails,
    req.payload
  )();
}

export function getExpensePolicies(req) {
  return sagaFunctions(
    ListExpensePolicies,
    "get",
    apis.list_expense_policies,
    null,
    {},
    true
  )();
}

export function createExpensePolicies(req) {
  return sagaFunctions(
    CreateExpensePolicies,
    "post",
    apis.create_expense_policy,
    req.payload,
    {},
    true
  )();
}

export function deleteExpensePolicies(req) {
  return sagaFunctions(
    DeleteExpensePolicies,
    "post",
    apis.delete_expense_policy,
    req.payload,
    {},
    true
  )();
}

export function deleteExpense(req) {
  let unlink = req.payload.unlink;
  let trip = req.payload.trip;
  let advance = req.payload.advance;
  delete req.payload.unlink;
  delete req.payload.trip;
  delete req.payload.advance;
  let url = unlink ? apis.transactionsUnlink : apis.deleteTransactions;
  if (advance) {
    url = apis.unlinkAdvance;
  }
  if (trip) {
    url = apis.unlinkTrips;
  }
  return sagaFunctionsExpense(DeleteExpense, "post", url, req.payload)();
}

export function editExpensePolicies(req) {
  return sagaFunctions(
    EditExpensePolicies,
    "post",
    apis.edit_expense_policy,
    req.payload,
    {},
    true
  )();
}

export function showExpensePolicies(req) {
  return sagaFunctions(
    ShowExpensePolicies,
    "post",
    apis.show_expense_policy,
    req.payload,
    {},
    true
  )();
}

export function getExpenseFields(req) {
  return sagaFunctions(
    ExpenseFields,
    "get",
    apis.userTransactionsFields,
    req.payload,
    { "Content-Type": "application/json; charset=UTF-8" },
    "settings"
  )();
}

export function* getPrefixExpenseFields(req) {
  let res = null,
    method = "post",
    data = req.payload;

  try {
    res = yield call(
      endpointPayExpenseBeta[method],
      apis.userPrefixTransactionField,
      {},
      {
        params: data.params,
      }
    );

    if (res.status < 400) {
      yield put({
        type: PrefixExpenseFields.SUCCESS,
        payload: res.data,
        module_type: data.params?.module_type,
      });
    } else {
      yield put({
        type: PrefixExpenseFields.FAILED,
        module_type: data.params?.module_type,
        payload: res.data,
      });
    }
  } catch (err) {
    yield put({
      type: PrefixExpenseFields.FAILED,
      payload: err?.response?.data,
    });
  }
}

export function* expensesWatcher() {
  yield takeLatest(Expenses.REQUEST, getExpenses);
  yield takeLatest(ExpenseComments.REQUEST, getExpenseComments);
  yield takeLatest(AddExpense.REQUEST, addExpenses);
  yield takeLatest(UpdateExpense.REQUEST, updateExpenses);
  yield takeLatest(ValidateExpense.REQUEST, validateExpense);
  yield takeLatest(ExpenseDetails.REQUEST, getExpenseDetails);
  yield takeLatest(ExpenseCustomDetails.REQUEST, getExpenseCustomDetails);
  yield takeLatest(ExpenseFields.REQUEST, getExpenseFields);
  yield takeLatest(PrefixExpenseFields.REQUEST, getPrefixExpenseFields);
  yield takeLatest(CreateExpensePolicies.REQUEST, createExpensePolicies);
  yield takeLatest(DeleteExpensePolicies.REQUEST, deleteExpensePolicies);
  yield takeLatest(DeleteExpense.REQUEST, deleteExpense);
  yield takeLatest(EditExpensePolicies.REQUEST, editExpensePolicies);
  yield takeLatest(ShowExpensePolicies.REQUEST, showExpensePolicies);
  yield takeLatest(ListExpensePolicies.REQUEST, getExpensePolicies);
}
