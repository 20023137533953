import { Button } from 'antd';
import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';

const BillHeader = () => {
  return (
    <>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '30px',
                lineHeight: '37px',
                color: '#4B5660',
                whiteSpace: 'nowrap',
              }}
            >
             {t('po_32')}
            </span>
            <span style={{ fontSize: '16px', lineHeight: '20px', color: '#2B2B2B', whiteSpace: 'nowrap' }}>
                {t('created_by')}
            </span>
            <span style={{ fontSize: '16px', lineHeight: '20px', color: '#2B2B2B', whiteSpace: 'nowrap' }}>
              {t('created_date')}
            </span>
          </div>

          <div style={{ marginLeft: '2rem' }}>
            <div
              style={{
                color: '#F6A907',
                backgroundColor: '#FEF4DE',
                borderRadius: '7px',
                padding: '9px 17px',
                whiteSpace: 'nowrap',
              }}
            >
              {t('pending_approval')}
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', height: 'fit-content', alignItems: 'center' }}>
          <Button className="pp-secondary-button">
            <span>{t('edit')}</span>
          </Button>
          <Button type="primary" className="pp-main-button " style={{ marginLeft: '1rem' }}>
            <span>{t('recall')}</span>
          </Button>
          <Button className="pp-secondary-button" style={{ marginLeft: '1rem' }}>
            <span>{t('more')}</span>
          </Button>
          <Button
            className="pp-icon-button"
            shape="circle"
            icon={<CloseCircleOutlined style={{ fontSize: '33px', marginLeft: '1rem' }} />}
          />
        </div>
      </div>
    </>
  );
};

export default BillHeader;
