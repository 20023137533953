import { ReducerFunctions } from "../../utils";
import {
  Employees,
  EmployeeById,
  AddEmployees,
  DeleteEmployees,
  UploadEmployeeDocument,
  GetEmployeeDocument,
  DeleteEmployeeDocument,
  EmployeesStatus,
  UpdateEmployees,
  UploadBulk,
  GetUserFields,
  DownloadBulkLink,
} from "./employees";

export const upload_bulk_employees_reducer = ReducerFunctions(UploadBulk, {});
export const download_link_reducer = ReducerFunctions(DownloadBulkLink, {});
export const get_user_fields_reducer = ReducerFunctions(GetUserFields, {});
export const employees_reducer = ReducerFunctions(Employees, {});
export const employee_by_id_reducer = ReducerFunctions(EmployeeById,{});
export const employees_status_reducer = ReducerFunctions(EmployeesStatus, {});
export const add_employees_reducer = ReducerFunctions(AddEmployees, {});
export const update_employees_reducer = ReducerFunctions(UpdateEmployees, {});
export const delete_employees_reducer = ReducerFunctions(DeleteEmployees, {});
export const upload_employee_reducer = ReducerFunctions(UploadEmployeeDocument,{});
export const get_employee_reducer = ReducerFunctions(GetEmployeeDocument,{});
export const delete_employees_document_reducer = ReducerFunctions(DeleteEmployeeDocument,{})