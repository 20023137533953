import React from "react";

import {
  Button, Checkbox, Col, Divider, Form, Input, message,
  Modal, Row, Space, Table, Tabs, Typography
} from "antd";
import Text from "antd/lib/typography/Text";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import {
  AddReportField,
  companySetting, DeleteReportField, deleteReportField, getReportField, updateCompanyReport, UpdateCompanyReport, UpdateReportField, updateReportField
} from "../../../../actions/companySettings";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { objectToFormData } from "../../../../utils";
import "./../../styles/setting.css";
import "./../Custom.css";
import AddReportsField from "./AddReportsField";
const { TabPane } = Tabs;
const { Title } = Typography;

message.config({
  maxCount: 3,
});

const Reports = ({ hasWriteAccess }) => {
  // const [activeTab, setActiveTab] = React.useState('profile');
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState("center");
  const [selectedId, setSelectedId] = React.useState("");
  const [reportFields, setReportFields] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const { t } = useTranslation()

  const columns = [
    {
      title: t('field'),
      dataIndex: "column_name",
      key: "column_name",
    },
    {
      title: t('column_type'),
      dataIndex: "column_type",
      key: "column_type",
      render: (text, render) => {
        if (text == "integer") {
          return "Number"
        } else if (text === "string") {
          return "Text Box"
        }
        else if (text === "datetime") {
          return "Date & Time"
        }
        else if (text === "document") {
          return "Document"
        }
        else if (text === "dropdown") {
          return "Drop Down"
        }
        else {
          return text
        }
      }
    },
  ];
  const validationSchema = Yup.object().shape({
    module_type_approver_reminder_days: Yup.number()
      .typeError('Invalid input')
      .test('is-positive', 'Reminder days must be greater than 0', function (value) {
        const { path, createError } = this;
        console.log("report validation ", value)
        if ((value === '' || value <= 0 || value === undefined || value === null) && checkBox) {
          return createError({
            path,
            message: 'Reminder days must be greater than 0',
          });
        }
        return true;
      }),
  });

  const formik = useFormik({
    initialValues: {
      module_type_approver_reminder: "",
      module_type_approver_reminder_days: "",
      module_type_attach_pdf: "",
      module_type_comments_pdf: "",
      module_type_emp_details_pdf: "",
      module_type_include_logo_pdf: "",
      module_type_include_receipts_pdf: "",
      module_type_notify_approver_pdf: "",
      module_type_report_history_pdf: "",

      module_type_reminder: "",
      module_type_reminder_days: "",
      module_type_prefix: "",
      module_type_starts_with: "",
      module_type_display_starts_with: "",
      module_approval_on_email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.module_type_starts_with = values?.module_type_display_starts_with
      dispatch(updateCompanyReport({ ...values, params: { module_type: "REPORT" } }));
    }
  })


  // const [companyValues, setCompanyValues] = React.useState({});
  const companySettingResponse = useSelector(
    (state) => state.companySetting.companySetting || {}
  );
  const updateCompanySettingResponse = useSelector(
    (state) => state.companySetting.updateCompanySetting || {}
  );
  const reportFieldResponse = useSelector(
    (state) => state.companySetting.getReportField || {}
  );
  const deleteReportFieldResponse = useSelector(
    (state) => state.companySetting.deleteReportField || {}
  );
  const addReportFieldResponse = useSelector(
    (state) => state.companySetting.addReportField || {}
  );
  const updateReportFieldResponse = useSelector(
    (state) => state.companySetting.updateReportField || {}
  );
  const updateCompanyReportResponse = useSelector(
    (state) => state.companySetting.updateCompanyReport || {}
  );

  const totalRecords = reportFieldResponse?.data?.total_count
  const { data: { data: { result: company_setting } = {} } = {} } =
    companySettingResponse;
  const setTab = () => { };

  React.useEffect(() => {
    dispatch(companySetting({ module_type: "REPORT" }));
    dispatch(getReportField({}));
    dispatch(headerTitles({
      title: 'reports',
      description: ""
    }))
    // setIsModalVisible(false,);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [checkBox, setCheckBox] = React.useState(company_setting?.rep_approver_reminder);
  React.useEffect(() => {
    formik.resetForm()
    // const { data: { data: { company_setting = {} } = {} } = {} } =
    //   companySettingResponse;
    const { data: { data: { result: company_setting = {} } = {} } = {} } = companySettingResponse;
    // setCompanyValues({...company_setting});

    formik.setValues({
      module_type_approver_reminder: company_setting.rep_approver_reminder,
      module_type_approver_reminder_days: company_setting.rep_approver_reminder_days ? company_setting.rep_approver_reminder_days : "",
      module_type_attach_pdf: company_setting.rep_attach_pdf,
      module_type_comments_pdf: company_setting.rep_comments_pdf,
      module_type_emp_details_pdf: company_setting.rep_emp_details_pdf,
      module_type_include_logo_pdf: company_setting.rep_include_logo_pdf,
      module_type_include_receipts_pdf: company_setting.rep_include_receipts_pdf,
      module_type_notify_approver_pdf: company_setting.rep_notify_approver_pdf,
      module_type_report_history_pdf: company_setting.rep_report_history_pdf,
      module_type_display_starts_with: company_setting?.report_module_display_starts_with,
      module_type_starts_with: company_setting?.report_starts_with,
      module_type_prefix: company_setting?.report_prefix,
      module_approval_on_email: company_setting?.report_approval_on_email,
      remainders: []
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettingResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      updateCompanyReportResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(companySetting({ module_type: "REPORT" }));
      handleSubmit();
      dispatch({
        type: UpdateCompanyReport.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanyReportResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = updateCompanySettingResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UpdateCompanyReport.RESET
      })
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: UpdateCompanyReport.RESET
      })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanySettingResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = updateReportFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getReportField({}));
      dispatch({
        type: UpdateReportField.RESET
      })
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: UpdateReportField.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateReportFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = deleteReportFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getReportField({}));
      dispatch({
        type: DeleteReportField.RESET
      })
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: DeleteReportField.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteReportFieldResponse]);

  React.useEffect(() => {

    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = addReportFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getReportField({}));
      dispatch({
        type: AddReportField.RESET
      })
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddReportField.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addReportFieldResponse]);

  React.useEffect(() => {
    const { data: { data: fields = [], status = false } = {} } =
      reportFieldResponse;
    if (status) {
      setReportFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportFieldResponse]);



  const tableAction = [
    {
      title: t('mandatory'),
      dataIndex: "is_required",
      key: "is_required",
      render: (status, record) => (
        <Checkbox
          key={record.id}
          disabled={!hasWriteAccess}
          onChange={(e) =>
            dispatch(
              updateReportField(
                ({
                  // ...record,
                  column_name: record.column_name,
                  column_type: record.column_type,
                  id: record.id,
                  is_enable: record.is_enable,
                  view_in_pdf: record.view_in_pdf,
                  is_required: e.target.checked,
                  data: {
                    max_size: record.data.max_size || "",
                    doc_formats: record.data.doc_formats || ""
                  },
                  default_values: record.default_values || []
                })
              )
            )
          }
          checked={status}
        ></Checkbox>
      ),
    },
    // {
    //   title: "Show in PDF",
    //   dataIndex: "view_in_pdf",
    //   key: "view_in_pdf",
    //   render: (status, record) => (
    //     <Checkbox
    //       disabled={!hasWriteAccess}
    //       key={record.id}
    //       onChange={(e) => dispatch(updateReportField(objectToFormData({ ...record, view_in_pdf: e.target.checked, })))}
    //       checked={status}
    //     ></Checkbox>
    //   ),
    // },
    {
      title: t('enable'),
      dataIndex: "is_enable",
      key: "is_enable",
      render: (status, record) => (
        <Checkbox
          key={record.id}
          disabled={!hasWriteAccess}
          onChange={(e) => dispatch(updateReportField(({ ...record, is_enable: e.target.checked })))}
          checked={status}
        ></Checkbox>
      ),
    },
    {
      title: t('actions'),
      key: "action",
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            disabled={!hasWriteAccess}
            onClick={() => showEditModal(record.id)}>
            <img src={EditIcon} alt={"logo"} className="action-logo" />
          </button>
          <button
            disabled={!hasWriteAccess}
            onClick={() => showDeleteModal(record.id)}>
            <img src={DeleteIcon} alt={"logo"} className="action-logo" />
          </button>
        </Space>
      ),
    },
  ];

  const handleEdit = () => {

    setEditMode(true);
  }

  const handleSubmit = () => {
    setEditMode(false);
  }

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("center");
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t('delete_sure_field')}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() =>
            dispatch(deleteReportField(({ id: selectedId })))
          }
        >
          {t('delete')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t('cancel')}
        </Button>
      </>
    );
  };

  return (
    <>
      {isModalVisible && <Modal
        title={
          modalType === "center" ? (
            <>
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
              {t('delete_field')}
            </>
          ) : (
            <Title level={4}>
              {selectedId ? t('edit_fields') : t('add_fields')} &nbsp;
              <span className="text-small">{t('enter_details')}</span>
            </Title>
          )
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={
          modalType === "center"
            ? "center-aligned-modal"
            : "right-alinged-modal-small"
        }
      >
        {modalType === "center" ? (
          renderDeleteOption()
        ) : (
          <AddReportsField id={selectedId} handleCancel={handleCancel} />
        )}
      </Modal>}
      <Tabs onChange={setTab} type="card" size="large" className="custom-tabs">
        <TabPane tab={t('preference')} key="preferences">
          <div className="tabContentWrapper">
            <Title level={5}>{t('auto_generate_report')}</Title>
            <Divider />
            <Form
              layout="vertical"
              className="custom-form"
              onFinish={formik.handleSubmit}
            // validateMessages={validateMessages1}
            >
              <Row>
                <Col span={8}>
                  <Form.Item
                    label={t('prefix')}
                  >
                    <Input
                      name="module_type_prefix"
                      onChange={formik.handleChange}
                      value={formik.values.module_type_prefix}
                      placeholder=""
                      disabled={!editMode || company_setting?.report_prefix}
                    //disabled={company_setting.report_prefix}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="ml1">
                  <Form.Item
                    label={t('start_with')}
                  >
                    <Input
                      name="module_type_display_starts_with"
                      //onChange={formik.handleChange}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) {
                          return;
                        }
                        formik.setFieldValue("module_type_display_starts_with", e.target.value)
                      }}
                      placeholder=""
                      value={formik?.values?.module_type_display_starts_with}
                      disabled={!editMode || company_setting?.report_module_display_starts_with}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Title level={5} className="customTitle">
                PDF Options
              </Title>
              <Divider />
              <Row>
                <Col span={24}>
                  <Form.Item name="rep_emp_details_pdf" valuePropName="checked">
                    <Checkbox>Show Employee Details in PDF</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="rep_report_history_pdf"
                    valuePropName="checked"
                  >
                    <Checkbox>Show Report History in PDF</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="rep_comments_pdf" valuePropName="checked">
                    <Checkbox>Show Report Comments in PDF</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="rep_include_receipts_pdf"
                    valuePropName="checked"
                  >
                    <Checkbox>Include receipts/bills in report PDF</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="rep_include_logo_pdf"
                    valuePropName="checked"
                  >
                    <Checkbox>Include company logo in report PDF</Checkbox>
                  </Form.Item>
                </Col>
              </Row> */}

              <Title level={5} className="customTitle">
                {t('expense_report_submission')}
              </Title>
              <Divider />
              <Row>
                <Col span={24}>
                  <Form.Item
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={!editMode}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) {
                          return;
                        }
                        formik.setFieldValue("module_approval_on_email", e.target.value)
                      }}
                      value={formik?.values?.module_approval_on_email}
                    >
                      {t('notify_approver')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
                  <Form.Item name="rep_attach_pdf" valuePropName="checked">
                    <Checkbox>
                      Attach report as a PDF file to the notification email
                    </Checkbox>
                  </Form.Item>
                </Col> */}
              </Row>

              <Title level={5} className="customTitle">
                {t('notifications')}
              </Title>
              <Divider />
              <Row>
                <Col span={24}>
                  <Form.Item
                    valuePropName="checked"
                    style={{ marginBottom: "0px" }}
                  >
                    <Checkbox checked={formik.values.module_type_approver_reminder} name="module_type_approver_reminder" disabled={!editMode} onChange={(e) => {
                      formik.setFieldValue("module_type_approver_reminder", e.target.checked)
                      formik.setFieldValue("module_type_approver_reminder_days", "")
                      setCheckBox(e.target.checked)
                    }}>
                      <span className="flexDisplay">
                        {t('reminder_expense_reports')} &nbsp;
                        <Form.Item
                          className="shortNumberInput"
                        >
                          <Input className="shortInput"
                            name="module_type_approver_reminder_days"
                            disabled={!editMode || !formik.values.module_type_approver_reminder}
                            onChange={(e) => {
                              if (Number.isNaN(Number(e.target.value))) {
                                return;
                              }
                              formik.setFieldValue("module_type_approver_reminder_days", e.target.value)
                            }}
                            value={formik.values.module_type_approver_reminder_days}
                          />
                        </Form.Item>{" "}
                        &nbsp;{t('days')}
                      </span>
                    </Checkbox>

                  </Form.Item>
                  {formik.touched.module_type_approver_reminder_days && formik.errors.module_type_approver_reminder_days ? (
                    <div className="errorMsg">{formik.errors.module_type_approver_reminder_days}</div>
                  ) : null}
                </Col>
              </Row>
              {!editMode &&
                <Button type="button" className="formButton mt3" onClick={() => handleEdit()} disabled={!hasWriteAccess}>{t("edit")}</Button>}
              {editMode &&
                <Button key="5" className="formButton mt3" htmlType="submit" disabled={updateCompanyReportResponse?.loading}>
                  {t('save')}
                </Button>}
              {/* <Button key="6" className="cancelButton ml1 mt3" onClick={() => history.push(RouteConstants.SETTING_USERS)}></Button> */}
            </Form>
          </div>
        </TabPane>
        <TabPane tab={t('fields')} key="fields">
          <div className="tabContentWrapper">
            {<Button onClick={showModal} className="mb1 addNew" disabled={!hasWriteAccess}>
              <img src={AddNewIcon} alt={"logo"} className="addNew-logo" /> {t('new_field')}
            </Button>}
            <Table
              rowKey="id"
              columns={[...columns, ...tableAction]}
              dataSource={reportFields}
              className="settingsTable"
              pagination={{
                total: totalRecords || 0,
                onChange: (pagee) => {
                  dispatch(getReportField({ page_number: pagee, }))
                }
              }}
            />
          </div >
        </TabPane >
      </Tabs >
    </>
  );
};

export default Reports;