import { Avatar, Button, Col, Divider, Row } from "antd";
import {
  FileTextOutlined,
  DownloadOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const DetailSection = ({ children, ...restOfProps }) => {
  return (
    <div
      style={{
        border: "1px solid #EDF0F5",
        width: "100%",
        borderRadius: "9px",
        backgroundColor: "#F7F7F7",
        padding: "28px 36px",
        ...(restOfProps?.style ?? {}),
      }}
    >
      {children}
    </div>
  );
};

const DetailLabel = ({ children, ...restOfProps }) => {
  return (
    <div
      style={{
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "medium",
        color: "#262626",
        ...(restOfProps?.style ?? {}),
      }}
    >
      {children}
    </div>
  );
};

const DetailValue = ({ children, ...restOfProps }) => {
  return (
    <div
      style={{
        fontSize: "20px",
        lineHeight: "24px",
        color: "#333333",
        ...(restOfProps?.style ?? {}),
      }}
    >
      {children}
    </div>
  );
};

const Approver = ({ index, name, position }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <span style={{ color: "#000000", fontSize: "19px", lineHeight: "23px" }}>
        {index}
      </span>
      <Avatar style={{ marginLeft: "16px" }} />
      <span
        style={{
          color: "#4A4A4ACC",
          fontSize: "20px",
          lineHeight: "18px",
          fontWeight: "medium",
          marginLeft: "11px",
        }}
      >
        {name}{" "}
        <span
          style={{
            color: "#4A4A4ACC",
            fontSize: "17px",
            lineHeight: "18px",
            fontWeight: "medium",
          }}
        >
          ({position})
        </span>
      </span>
    </div>
  );
};

const ApproversSection = () => {
  const {t} = useTranslation()
  return (
    <DetailSection style={{ marginTop: "20px" }}>
      <div>
        <div>
          <span
            style={{ color: "#3C4853", fontSize: "20px", lineHeight: "22px" }}
          >
            {t('approvers')}
          </span>
        </div>

        <div style={{ marginTop: "23px" }}>    
          <Row gutter={[0, 14]}>
            <Col span={24}>
              <Approver
                index={1}
                name="Arun Kumar"
                position="Reporting Manager"
              />
            </Col>
            <Col span={24}>
              <Approver index={2} name="Krishna Kanth" position="Finance" />
            </Col>
            <Col span={24}>
              <Approver index={3} name="Sathish Kumar" position="Director" />
            </Col>
            <Col span={24}>
              <Approver index={4} name="KRao" position="Accounts" />
            </Col>
          </Row>
        </div>
      </div>
    </DetailSection>
  );
};

const AttachmentsSection = () => {
  const {t} =useTranslation()
  return (
    <DetailSection style={{ marginTop: "20px" }}>
      <div>
        <div>
          <span
            style={{
              color: "#3C4853",
              fontSize: "18px",
              lineHeight: "22px",
              fontWeight: "600",
            }}
          >
           {t('attachments')}
          </span>
        </div>

        <div style={{ marginTop: "23px" }}>
          <Row gutter={[16, 14]}>
            <Col span={12}>
              <Attachment />
            </Col>
            <Col span={12}>
              <Attachment />
            </Col>
            <Col span={12}>
              <Attachment />
            </Col>
            <Col span={12}>
              <Attachment />
            </Col>
            <Col span={12}>
              <Attachment />
            </Col>
          </Row>
        </div>
      </div>
    </DetailSection>
  );
};

const HistorySection = () => {
  return (
    <DetailSection style={{ marginTop: "20px" }}>
      <div>
        <HistoryDetail
          event="Created By"
          name="Jeevan Kumar"
          email="jeevanking@jeevantechnologies.com"
          date="18/03/2022"
        />
        <Divider />
        <HistoryDetail
          event="Submitted to"
          name="Arun Kumar"
          email="Arunkumar@jeevantechnologies.com"
          date="18/03/2022"
        />
        <Divider />
        <HistoryDetail
          event="Pending Approval"
          name="Krishna Kanth"
          email="Krishnakanth@jeevantechnologies.com"
          date="18/03/2022"
          position="Finance Manager"
        />
      </div>
    </DetailSection>
  );
};

const HistoryDetail = ({ event, name, email, date, position }) => {
  return (
    <div>
      <div>
        <span className="bold fs20 lh22 color-3C4853">{event}</span>
      </div>
      <div>
        <span className="fs20 lh28 color-3C4853">{name}</span>
      </div>
      <div>
        <span className="fs16 lh23 color-8B8B8B">
          {email}
          <br />
          {date}
        </span>
      </div>
      {position ? (
        <div>
          <span className="color-3C4853 fs14 lh28">({position})</span>
        </div>
      ) : null}
    </div>
  );
};

const Attachment = () => {
  const {t} = useTranslation()
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          justifyContent: "space-between",
          backgroundColor: "white",
          padding: "10px 16px",
          borderRadius: "9px",
          minHeight: "51px",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FileTextOutlined style={{ color: "#BCBCBC", fontSize: "18px" }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10px",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: "11px",
                lineHeight: "17px",
                fontWeight: "medium",
                color: "#515665",
                fontFamily: "Poppins",
              }}
            >
              PO_12. PDF
            </span>
            <span
              style={{
                fontSize: "8px",
                lineHeight: "9px",
                fontWeight: "medium",
                color: "#515665",
                fontFamily: "Poppins",
              }}
            >
              File Size: 3.5 mb
            </span>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              className="pp-icon-button"
              shape="circle"
              icon={
                <DownloadOutlined
                  style={{ fontSize: "20px", color: "#F6A907" }}
                />
              }
            />
          </div>
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              className="pp-icon-button"
              shape="circle"
              icon={
                <DeleteOutlined
                  style={{ fontSize: "20px", color: "#F6A907" }}
                />
              }
            />
          </div>
        </div>

        <div style={{ position: "absolute", top: "-7px", right: "-7px" }}>
          <Button
            className="pp-icon-button"
            shape="circle"
            style={{ verticalAlign: "0px" }}
            icon={
              <CloseCircleOutlined
                style={{ fontSize: "14px", color: "#7E7E7E" }}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

const ViewBillDetails = () => {
  const {t} = useTranslation()
  return (
    <>
      <div style={{ width: "100%" }}>
        <DetailSection>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{ fontSize: "16px", lineHeight: "20px", color: "#58666E" }}
            >
              {t('Amount')}
            </span>
            <span
              style={{
                fontSize: "28px",
                lineHeight: "35px",
                color: "#1C2B36",
                marginTop: "5px",
              }}
            >
              Rs. 11,300.00
            </span>
            <span
              style={{
                fontSize: "18px",
                lineHeight: "22px",
                color: "#333333",
                marginTop: "5px",
              }}
            >
              {t('tax_amount')}- Rs. 750.00
            </span>
          </div>
        </DetailSection>

        <DetailSection style={{ marginTop: "20px" }}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DetailLabel>{t('invoice_hash')}</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>IN-000123</DetailValue>
            </Col>

            <Col span={12}>
              <DetailLabel>{t('order_purchase')}</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>PO-00001</DetailValue>
            </Col>

            <Col span={12}>
              <DetailLabel>{t('invoice_date')}</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>{t('march_24')}</DetailValue>
            </Col>

            <Col span={12}>
              <DetailLabel>{t('payment_due_date')}</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>{t('march_24')}</DetailValue>
            </Col>

            <Col span={12}>
              <DetailLabel>{t('payment_term')}</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>{t('receipt_due')}</DetailValue>
            </Col>

            <Col span={12}>
              <DetailLabel>{t('tax')}</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>GST 5 [5%]</DetailValue>
            </Col>

            <Col span={12}>
              <DetailLabel>{t('Amounts')}</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>Rs.350.00</DetailValue>
            </Col>

            <Col span={12}>
              <DetailLabel>CGST %</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>Rs.350.00</DetailValue>
            </Col>

            <Col span={12}>
              <DetailLabel>SGST %</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>Rs.350.00</DetailValue>
            </Col>

            <Col span={12}>
              <DetailLabel>TDS %</DetailLabel>
            </Col>
            <Col span={12}>
              <DetailValue>Rs.7000.00</DetailValue>
            </Col>
          </Row>
        </DetailSection>
        <ApproversSection />
        <AttachmentsSection />
        <HistorySection />
      </div>
    </>
  );
};

export default ViewBillDetails;
