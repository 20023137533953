import {
  CaretDownFilled,
  EllipsisOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Row, Upload } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import card_chip from "../../assets/icons/card_chip.png";
import card_name from "../../assets/icons/card_name.png";
import card_wifi from "../../assets/icons/card_wifi.png";
import Close from "../../assets/icons/close.png";
import BaseCurrency from "./BaseCurrency";
import sync from "../../assets/icons/sync.png";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import { useTranslation } from "react-i18next";
import { Switch } from "antd";
import Table from "./Table/Table";
import CardSettingsAccordion from "../Cards/cardSettingsAccordion";
import cardDetail from "../Cards/card_detail.json";
import { cardEnalbeLimit, transactions } from "../../actions/cards/cards";
import "../Cards/card.css";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const CardDetails = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tab, setTab] = useState("details");
  const [editRow, setEditRow] = useState({});
  const [kycStatus] = useState();
  const { t } = useTranslation()
  const [selectedRow, setSelectedRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const transactionsResponse = useSelector(
    (state) => state.cards.transactions || {}
  );
  const cardsDetailsResponse = useSelector((state) => state.cards.cardsDetails);
  // const { data: { response: { card_details: cardData = cardDetail } = {} } = {} } =
  //   cardsDetailsResponse;
  const cardData = cardDetail;
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const card_data = "";

  const secs = [
    { label: t('details'), key: "details" },
    { label: t('Settings'), key: "settings" },
    { label: t('transactions'), key: "transactions" },
  ];

  const modalColumns = [
    {
      title: t('trans_id'),
      dataIndex: "vendor_transaction_id",
      key: "vendor_transaction_id",
    },
    {
      title: t('dateAndTime'),
      dataIndex: "transaction_date",
      key: "transaction_date",
    },
    {
      title: t('term_id'),
      dataIndex: "terminalId",
      key: "terminalId",
    },
    // {
    //   title: "Wallet Type",
    //   dataIndex: "remaining",
    //   key: "remaining",
    //   render: (text) => <div>Meal Wallet</div>,
    // },
    {
      title: t('merchant_name'),
      dataIndex: "merchant",
      key: "merchant",
    },
    {
      title: t('Amount'),
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <div>
          <img
            src={record?.transaction_type === "credit" ? credit : debit}
            width={10}
            alt="credit"
          />{" "}
          <BaseCurrency /> {text}
        </div>
      ),
    },
    {
      title: t('wallet_name'),
      dataIndex: "wallet_name",
      key: "wallet_name",
    },
    {
      title: t('transaction_nature'),
      dataIndex: "channelType",
      key: "channelType",
      render: (text, record) => <div>{record?.data?.txnType}</div>,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },
  ];
  const channels = cardData?.data?.card_limits?.map((row) => row.channelType);
  const card_id = card_data?.id;
  const [txnEnable, setTxnEnable] = useState({
    ECOM: false,
    ATM: false,
    POS: false,
    CTLS: false,
  });

  // useEffect(() => {
  //   if (channels) {
  //     setTxnEnable({
  //       ECOM: channels?.includes("ECOM"),
  //       ATM: channels?.includes("ATM"),
  //       POS: channels?.includes("POS"),
  //       CTLS: channels?.includes("CTLS"),
  //     });
  //   }
  // }, [channels]);
  const menu = (
    <Menu onClick="">
      <a href="" download target={"_blank"} rel="noreferrer">
        <Menu.Item key="1">{t('download_pdf')}</Menu.Item>
      </a>
      <Menu.Item key="5">{t('share_report')}</Menu.Item>
      <Menu.Item key="3">{t('excel_export')}</Menu.Item>
      <Menu.Item key="4">{t('print')}</Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="view-details">
        <div className="new-report-detail">
          <div className="flex-between">
            <div className="report-head w100">
              <div className="title-sec">
                <div className="d-flex w100 flex-between">
                  <div className="d-flex" style={{ gap: "1.5rem" }}>
                    <h3>{cardData.employee_details?.name}</h3>
                    {
                      <span
                        className={`filter-btn v-center`}
                        style={{ color: "#F6A907", fontSize: "1.1rem" }}
                      >
                        {cardData.status}
                      </span>
                    }
                    {
                      <span
                        className={`nm-btn`}
                        style={{ color: "#3f81f4", cursor: "text" }}
                      >
                        {cardData.kyc_flag ? cardData.kyc_flag : ""}
                      </span>
                    }
                  </div>
                  <div className="d-flex" style={{ gap: "1rem" }}>
                    <button disabled={!hasWriteAccess} className="pp-secondary-button">
                      <span
                        style={{
                          padding: "10px ",
                          color: "#4B5660",
                          fontWeight: "bold",
                          background: "#FAFAFA",
                          border: "1px solid #D6D6D6",
                          borderRadius: "10px",
                        }}
                      >
                        {t('card_block')}
                      </span>
                    </button>
                    <span
                      className="close cursor"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <img src={Close} width={20} alt={"close"} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mtHalf">
                <span
                  style={{
                    color: "#2B2B2B",
                    fontSize: "1rem",
                    fontWeight: "normal",
                  }}
                >
                  {cardData.employee_details?.email}{" "}
                  {cardData.employee_details?.email && "|"}{" "}
                  {cardData.employee_details?.employee_id}
                </span>
              </div>
            </div>
          </div>
          <div className="tabs-sec">
            <ul>
              {secs.map(({ label, key }) => (
                <li
                  onClick={() => setTab(key.toLowerCase())}
                  className={`${tab.toLowerCase() === label.toLowerCase() ? "active" : ""
                    }`}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
          {(() => {
            switch (tab) {
              case "details":
                return (
                  <>
                    <section className="card-info p1 card-info-flex-start">
                      <div style={{ width: "100%" }}>
                        <div className="d-flex">
                          <div className="debit-card">
                            <h2>{cardData.company_name}</h2>
                            <div className="flex-between mt1">
                              <img
                                src={card_chip}
                                width={30}
                                alt={"card_chip"}
                              />
                              <img
                                src={card_wifi}
                                width={15}
                                alt={"card_wifi"}
                              />
                            </div>
                            <div className="mt1">
                              <span>
                                {cardData?.data?.maskCardNumber?.substr(0, 4)}{" "}
                                XXXX XXXX{" "}
                                {cardData?.data?.maskCardNumber?.substr(15)}
                              </span>
                              <br />
                              <span
                                style={{ marginTop: 5, fontSize: "0.85rem" }}
                              >
                                {cardData?.card_reference_no}
                              </span>
                            </div>
                            <div className="flex-between mt1">
                              <span style={{ fontSize: "0.85rem" }}>
                                {cardData?.employee_details?.name
                                  ? cardData?.employee_details?.name
                                  : cardData.data?.firstName +
                                  " " +
                                  cardData.data?.lastName}
                              </span>
                              <img src={card_name} alt={"card_name"} />
                            </div>
                          </div>
                          <div
                            className="m1 p1 other-info"
                            style={{ width: "100%" }}
                          >
                            <Row
                              className="flex-wrap"
                              style={{ marginBottom: "2rem" }}
                            >
                              <Col span={6}>
                                <p>
                                  <label>{t('card_holder_name')}</label>
                                </p>
                                <p>
                                  {cardData?.employee_details?.name
                                    ? cardData?.employee_details?.name
                                    : cardData.data?.firstName +
                                    " " +
                                    cardData.data?.lastName}
                                </p>
                              </Col>
                              <Col span={6}>
                                <p>
                                  <label>{t('mobile_number')}</label>
                                </p>
                                {/* <p>{beneficiary?.configurations?.currency}</p> */}
                                <p>
                                  {cardData.employee_details?.mobile || "."}
                                </p>
                              </Col>
                              <Col span={6}>
                                <p>
                                  <label>{t('kyc_status')}</label>
                                </p>
                                {/* <p>{beneficiary?.configurations?.currency}</p> */}
                                <p
                                  className={
                                    kycStatus === "Verified"
                                      ? "profileCardRightSideName colorCodeEF951C verifiedColor"
                                      : "profileCardRightSideName colorCodeEF951C"
                                  }
                                >
                                  {cardData.kyc_flag
                                    ? cardData.kyc_flag
                                    : "Limited KYC"}
                                </p>
                              </Col>
                            </Row>
                            <Row className="flex-wrap">
                              <Col span={6}>
                                <p>
                                  <label>{t('card_kit_number')}</label>
                                </p>
                                {/* <p>{beneficiary?.configurations?.currency}</p> */}
                                <p> {cardData.card_reference_no || "."}</p>
                              </Col>
                              <Col span={6}>
                                <p>
                                  <label>{t('card_type')}</label>
                                </p>
                                {/* <p>{beneficiary?.configurations?.currency}</p> */}
                                <p>{t('visa')}</p>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </section>
                    <hr />
                    <div className="m1 p1 other-info">
                      <h4>{t('wallets_balances')}</h4>
                      <Row
                        className="flex-wrap"
                        style={{ marginBottom: "2rem" }}
                      >
                        <Col span={4}>
                          <p>
                            <label>{t('expense')}</label>
                          </p>
                          <p>
                            ₹{" "}
                            {cardData?.data?.walletNameAndBalance?.[4]?.Balance}
                          </p>
                        </Col>
                        <Col span={4}>
                          <p>
                            <label>{t('reimbursement')}</label>
                          </p>
                          <p>
                            ₹{" "}
                            {cardData?.data?.walletNameAndBalance?.[3]?.Balance}
                          </p>
                        </Col>
                      </Row>
                      <Row className="flex-wrap">
                        <Col span={4}>
                          <p>
                            <label>{t('meal')}</label>
                          </p>
                          <p>
                            ₹{" "}
                            {cardData?.data?.walletNameAndBalance?.[2]?.Balance}
                          </p>
                        </Col>
                        <Col span={4}>
                          <p>
                            <label>{t('fuel')}</label>
                          </p>
                          <p>
                            ₹{" "}
                            {cardData?.data?.walletNameAndBalance?.[0]?.Balance}
                          </p>
                        </Col>
                        <Col span={4}>
                          <p>
                            <label>{t('travel')}</label>
                          </p>
                          <p>
                            ₹{" "}
                            {cardData?.data?.walletNameAndBalance?.[4]?.Balance}
                          </p>
                        </Col>
                        <Col span={4}>
                          <p>
                            <label>{t('books_periodicals')}</label>
                          </p>
                          <p>
                            ₹{" "}
                            {cardData?.data?.walletNameAndBalance?.[4]?.Balance}
                          </p>
                        </Col>
                        <Col span={4}>
                          <p>
                            <label>{t('gift')}</label>
                          </p>
                          <p>
                            ₹{" "}
                            {cardData?.data?.walletNameAndBalance?.[5]?.Balance}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </>
                );
              case "settings":
                return (
                  <>
                    <section>
                      <div className="setting_header">
                        <div className="m1 p1 other-info">
                          <h4 className="theader">{t('card_usage_controls')}</h4>
                          <Row
                            className="flex-wrap"
                            style={{
                              marginBottom: "2rem",
                              marginTop: "2.46rem",
                            }}
                          >
                            <Col span={6} className="v-center">
                              <p
                                className="d-flex flex-col card_switch_button"
                                style={{ marginRight: "1.75rem" }}
                              >
                                <label>{t('pos_trans')}</label>
                                <small>{t('enable_pos_trans')}</small>
                              </p>
                              <Switch
                                disabled={!hasWriteAccess}
                                className="switch"
                                checked={txnEnable["POS"]}
                                onChange={(val) => {
                                  setTxnEnable((prev) => ({
                                    ...prev,
                                    POS: val,
                                  }));
                                  dispatch(
                                    cardEnalbeLimit({
                                      card: {
                                        card_id,
                                        transaction_type: "POS",
                                        is_enabled: val,
                                      },
                                    })
                                  );
                                }}
                              />
                            </Col>
                            <Col span={6} className="v-center">
                              <p
                                className="d-flex flex-col card_switch_button"
                                style={{ marginRight: "1.75rem" }}
                              >
                                <label>{t('atm_withdraw')}</label>
                                <small>{t('enable_online_transactions')}</small>
                              </p>
                              <Switch
                                disabled={!hasWriteAccess}
                                className="switch"
                                checked={txnEnable["ATM"]}
                                onChange={(val) => {
                                  setTxnEnable((prev) => ({
                                    ...prev,
                                    ATM: val,
                                  }));
                                  dispatch(
                                    cardEnalbeLimit({
                                      card: {
                                        card_id,
                                        transaction_type: "ATM",
                                        is_enabled: val,
                                      },
                                    })
                                  );
                                }}
                              />
                            </Col>
                            <Col
                              span={6}
                              className="v-center card_switch_button_div"
                            >
                              <p
                                className="d-flex flex-col card_switch_button"
                                style={{ marginRight: "1.75rem" }}
                              >
                                <label>{t('online_transactions')}</label>
                                <small>{t('enable_online_transactions')}</small>
                              </p>
                              <Switch
                                disabled={!hasWriteAccess}
                                className="switch"
                                checked={txnEnable["ECOM"]}
                                onChange={(val) => {
                                  setTxnEnable((prev) => ({
                                    ...prev,
                                    ECOM: val,
                                  }));
                                  dispatch(
                                    cardEnalbeLimit({
                                      card: {
                                        card_id,
                                        transaction_type: "ECOM",
                                        is_enabled: val,
                                      },
                                    })
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <hr />
                      <div className="setting_body">
                        <div
                          className={`m1 p1 other-info ${true ? "collapse_button_none" : null
                            }`}
                        >
                          <h4 className="header">{t('card_limit')}</h4>
                          <CardSettingsAccordion
                            cardType="card_setting_page"
                            card_ref_number={card_data?.card_ref_number}
                            card_id={card_data?.id}
                            editRow={editRow}
                            setEditRow={setEditRow}
                            hasWriteAccess={hasWriteAccess}
                            // channels={cardData?.data?.card_limits?.map(
                            //   (row) => row.channelType
                            // )}
                            // card_limits={cardData?.data?.card_limits}
                            onEdit={() => {
                              let card_limit = cardData?.data?.card_limits?.map(
                                (row) => {
                                  let arr = [];
                                  if (row.currentDailyLimit) {
                                    arr.push({
                                      transaction_type: row.channelType,
                                      amount: row.currentDailyLimit,
                                      frequency: "DAILY",
                                    });
                                  }
                                  if (row.currentMonthlyLimit) {
                                    arr.push({
                                      transaction_type: row.channelType,
                                      amount: row.currentMonthlyLimit,
                                      frequency: "MONTHLY",
                                    });
                                  }
                                  if (row.currentAnnualLimit) {
                                    arr.push({
                                      transaction_type: row.channelType,
                                      amount: row.currentAnnualLimit,
                                      frequency: "ANNUALLY",
                                    });
                                  }
                                  return arr;
                                }
                              );
                              let arr = [];
                              card_limit.forEach((ele) => {
                                arr = [...arr, ...ele];
                              });

                              setSelectedRow({
                                id: card_data?.id,
                                channel_enabled: [
                                  {
                                    POS: cardData?.data?.channelType?.includes(
                                      "POS"
                                    ),
                                  },
                                  {
                                    ATM: cardData?.data?.channelType?.includes(
                                      "ATM"
                                    ),
                                  },
                                  {
                                    ECOM: cardData?.data?.channelType?.includes(
                                      "ECOM"
                                    ),
                                  },
                                ],
                                assigned_data: [],
                                card_limit: arr,
                              });
                              setIsModalVisible(true);
                            }}
                            onAdd={() => {
                              setIsModalVisible(true);
                            }}
                          />
                        </div>
                      </div>
                    </section>
                  </>
                );
              case "transactions":
                return (
                  <>
                    <section>
                      <div className="setting_header">
                        <div className="m1 p1 other-info">
                          {/* <h4 className="header">Transactions</h4> */}
                          <Row
                            className="flex-wrap"
                            style={{ marginBottom: "2rem" }}
                          >
                            <Col span={4}>
                              <p>
                                <label>{t('name')}</label>
                              </p>
                              <p>C{cardData?.employee_details?.name}</p>
                            </Col>
                            <Col span={4}>
                              <p>
                                <label>{t('available_balance')}</label>
                              </p>
                              <p>₹ {cardData?.balance}</p>
                            </Col>
                            <Col span={4}>
                              <p>
                                <label>{t('expense')}</label>
                              </p>
                              <p>
                                ₹{" "}
                                {
                                  cardData?.data?.walletNameAndBalance?.[4]
                                    ?.Balance
                                }
                              </p>
                            </Col>
                            <Col span={3}>
                              <p>
                                <label>{t('gift')}</label>
                              </p>
                              <p>
                                ₹{" "}
                                {
                                  cardData?.data?.walletNameAndBalance?.[5]
                                    ?.Balance
                                }
                              </p>
                            </Col>
                            <Col span={3}>
                              <p>
                                <label>{t('fuel')}</label>
                              </p>
                              <p>
                                ₹{" "}
                                {
                                  cardData?.data?.walletNameAndBalance?.[0]
                                    ?.Balance
                                }
                              </p>
                            </Col>
                            <Col span={4}>
                              <p>
                                <label>{t('reimbursement')}</label>
                              </p>
                              <p>
                                ₹{" "}
                                {
                                  cardData?.data?.walletNameAndBalance?.[3]
                                    ?.Balance
                                }
                              </p>
                            </Col>
                          </Row>
                          <Row className="flex-wrap">
                            <Col span={4}>
                              <p>
                                <label>{t('transit')}</label>
                              </p>
                              <p>
                                ₹{" "}
                                {
                                  cardData?.data?.walletNameAndBalance?.[1]
                                    ?.Balance
                                }
                              </p>
                            </Col>
                            <Col span={4}>
                              <p>
                                <label>{t('meal')}</label>
                              </p>
                              <p>
                                ₹{" "}
                                {
                                  cardData?.data?.walletNameAndBalance?.[2]
                                    ?.Balance
                                }
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="transaction_body">
                        <div className="m1 p1 other-info">
                          <Table
                            rowKey="id"
                            columns={modalColumns}
                            placeholder={t('search_by_transaction_id')}
                            btnsOnly={true}
                            btnsComponent={
                              <>
                                <button
                                  className="nm-btn"
                                  onClick={() => {
                                    // dispatch(
                                    //   analytics_cards_export({
                                    //     page,
                                    //     card_id: cardData.id,
                                    //   })
                                    // );
                                  }}
                                >
                                  {t('export_pdf')}
                                </button>
                                <button
                                  disabled={!hasWriteAccess}
                                  className="ml1 nm-btn"
                                  onClick={() => {
                                    if (cardData.id) {
                                      dispatch(
                                        transactions({
                                          card_id: cardData.id,
                                          refresh: true,
                                        })
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    src={sync}
                                    alt=""
                                    width="14"
                                    className="mr1"
                                  />
                                  {t('sync')}
                                </button>
                              </>
                            }
                          ></Table>
                        </div>
                      </div>
                    </section>
                  </>
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </>
  );
};

export default CardDetails;
