import React from 'react'

const CusPaymentsMade = ({ scale, fill, opacity }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="23.293" viewBox="0 0 23.5 23.293" style={{
      scale: scale ? scale : 1,
      zoom: scale ? scale : 1
    }}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_6075" data-name="Rectangle 6075" width="23.5" height="23.293" transform="translate(0 0.707)" fill={fill || "#fff"} />
        </clipPath>
      </defs>
      <g id="Group_18847" data-name="Group 18847" transform="translate(0 -0.707)" opacity={opacity || "0.5"}>
        <g id="Group_18846" data-name="Group 18846" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Path_20257" data-name="Path 20257" d="M14.048,13.079a3.305,3.305,0,0,0,0-4.675L13.9,8.263a3.31,3.31,0,0,0-4.673,0L4.2,13.3a4.132,4.132,0,0,1-.895-2.553V4.132A4.137,4.137,0,0,1,7.437,0H19.006a4.137,4.137,0,0,1,4.132,4.132v6.611a4.137,4.137,0,0,1-4.132,4.132H12.253Zm7.437-8.121H4.958V6.611H21.485ZM15.7,10.742h4.132V9.09H15.7ZM0,12.938v10.2H4.958a5.791,5.791,0,0,0,5.784-5.784V15.216l2.721-2.721a2.479,2.479,0,0,0,0-3.506l-.142-.141a2.479,2.479,0,0,0-3.505,0L4.374,14.289l-.182.18a4.915,4.915,0,0,1-1.713-3.728V8.747L1.211,10.016h0A4.105,4.105,0,0,0,0,12.938Zm8.151,10.2H11.87l.28-.242c1.9-1.895,1.9-3.114,1.9-5.542V15.7H11.569v1.653a6.612,6.612,0,0,1-3.418,5.784Z" transform="translate(0.155 0.706)" fill={fill || "#fff"} />
        </g>
      </g>
    </svg>
  )
}

export default CusPaymentsMade;
