import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import tick_out from "../../assets/tick_outline.png";

const Confirm = (props) => {
  const createOrder = useSelector((state) => state?.orders?.create_order);
  const history = useHistory();

  useEffect(() => {
    return () => {
      props.resetOrder();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="order-placed flex-col">
      <img src={tick_out} alt="tick" />
      <h3>Order Placed</h3>
      <p>
        {Array.isArray(createOrder?.data?.orders)
          ? "Thank you for placing your orders "
          : `Thank you for placing your order. Your sales order number is ${createOrder?.data?.order?.order_number
            ? createOrder?.data?.order?.order_number
            : ""
          }`}{" "}
        and you will receive a confirmation email shortly. If you have any questions please feel free to contact us.
      </p>
      <br />
      <div className="btns">
        <button
          className="hm-btn"
          onClick={() => {
            history.push("/products");
          }}
        >
          Back to Products
        </button>
        <button
          className="view-btn"
          onClick={() => {
            if (Array.isArray(createOrder?.data?.orders)) {
              history.push("/orders");
            } else {
              history.push("/order-detail/" + createOrder?.data?.order?.id, {
                order_id: createOrder?.data?.order?.id,
              });
            }
          }}
        >
          View order
        </button>
      </div>
    </div>
  );
};

export default Confirm;
