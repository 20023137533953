import React from "react";

const AnalyticsSVG = ({ scale, opacity, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.842"
      height="21"
      viewBox="0 0 29.842 21"
      style={{
        scale: scale ? scale : 1,
        zoom: scale ? scale : 1,
      }}
    >
      <g
        id="Group_16265"
        data-name="Group 16265"
        transform="translate(-5 -7)"
        opacity={opacity || "0.5"}
      >
        <path
          id="Path_18978"
          data-name="Path 18978"
          d="M44.053,11H31.316a1.739,1.739,0,0,0-1.737,1.737V23.158a1.739,1.739,0,0,0,1.737,1.737H44.053a1.739,1.739,0,0,0,1.737-1.737V12.737A1.739,1.739,0,0,0,44.053,11ZM34.789,22a.579.579,0,0,1-.579.579H31.895A.579.579,0,0,1,31.316,22V20.842a1.737,1.737,0,0,1,3.474,0Zm4.632,0a.579.579,0,0,1-.579.579H36.526A.579.579,0,0,1,35.947,22V17.368a1.737,1.737,0,0,1,3.474,0Zm4.632,0a.579.579,0,0,1-.579.579H41.158A.579.579,0,0,1,40.579,22V15.053a1.737,1.737,0,1,1,3.474,0Z"
          transform="translate(-10.947 -1.684)"
          fill={fill || "#fff"}
        />
        <path
          id="Path_18979"
          data-name="Path 18979"
          d="M5,41.472v.107a1.739,1.739,0,0,0,1.737,1.737H31.211a1.739,1.739,0,0,0,1.737-1.737v-.107Z"
          transform="translate(0.078 -15.316)"
          fill={fill || "#fff"}
        />
        <path
          id="Path_18980"
          data-name="Path 18980"
          d="M20.34,24.368a2.9,2.9,0,0,1-2.895-2.895V11.053A2.9,2.9,0,0,1,20.34,8.158h12.5A1.735,1.735,0,0,0,31.211,7H6.737A1.739,1.739,0,0,0,5,8.737V25.526H32.947V24.368ZM11.366,23.2a4.63,4.63,0,0,1-.579-9.223v4.591a.579.579,0,0,0,.579.579h4.591A4.636,4.636,0,0,1,11.366,23.2Zm-4.632-11a.579.579,0,0,1,.579-.579h4.053a.579.579,0,0,1,0,1.158H7.313A.579.579,0,0,1,6.735,12.2Zm5.211,5.789V13.979a4.635,4.635,0,0,1,4.012,4.012Zm3.632-7.526H7.313a.579.579,0,1,1,0-1.158h8.263a.579.579,0,0,1,0,1.158Z"
          fill={fill || "#fff"}
        />
      </g>
    </svg>
  );
};

export const DownSVG = ({ fill }) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="m0 0h24v24h-24z" fill="#fff" opacity="0" />
      <path
        d="m12 16a1 1 0 0 1 -.64-.23l-6-5a1 1 0 1 1 1.28-1.54l5.36 4.48 5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1 -.14 1.46l-6 4.83a1 1 0 0 1 -.63.17z"
        fill={fill ? fill : "#231f20"}
      />
    </svg>
  );
};

export default AnalyticsSVG;
