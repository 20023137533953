import { MoreOutlined, PlusCircleFilled, CloudUploadOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Modal, Row, Space, Radio, Badge } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { customers } from "../../actions/customers";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { convertString } from "../../utils";
import TableComponent from "../Common/TableComp/TableComp";
import TableHeader from "../Sales/TableHeader";
import AddCustomer from "./AddCustomer";

const Customers = ({ hasWriteAccess }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [dataBeneficiary, setDataBeneficiary] = useState(null);
  const [modelOpen, setModelOpen] = useState(false)
  const [boxValue, setBoxValue] = useState("2");
  const data = useSelector((state) => state.customers?.all?.data?.data || []);
  const loading = useSelector(
    (state) => state.customers?.all?.loading || false
  );
  const [page, setPage] = React.useState(1);
  const totalRecords = useSelector(
    (state) => state.customers?.all?.data?.total_count || 0
  );
  const [filters, setFilters] = React.useState({});

  const sortOptions = [
    {
      label: t("customer_name"),
      filter: "beneficiary_name",
    },
    {
      label: t("company_name"),
      filter: "company_name",
    },
    {
      label: t("email"),
      filter: "email",
    },
    {
      label: t("phone"),
      filter: "primary_contact",
    },
    {
      label: t("created_at"),
      filter: "created_at",
    },
    // {
    //   label: "Last Modified Date",
    //   filter: "updated_at"
    // },
  ];

  useEffect(() => {
    dispatch(
      customers({
        type: "customer",
      })
    );
    dispatch(
      headerTitles({
        title: "customers",
        description: "add_customer",
      })
    );
  }, []);

  const options = [
    {
      label: (
        <div className='table-menu-item' onClick={() => {
          setOpenAddNew(true)
        }}>
          <span className='menu-label'>
            Add New Customer
          </span>
        </div>
      ),
      key: '1',
      icon: (
        <div >
          <PlusCircleFilled className="pp-icon-18" />
        </div>
      ),
    },
    {
      label: (
        <div className='table-menu-item' 
        // onClick={(e) => {history.push("/customer/upload/section")}}
        >
          <span
            className='menu-label'
          >
            Import Customers
          </span>
        </div>
      ),
      key: '2',
      icon: (
        <div>
          <CloudUploadOutlined className="pp-icon-18" />
        </div>
      ),
    },
    // {
    //   label: (
    //     <div className='table-menu-item'>
    //       <span
    //         className='menu-label'
    //       >
    //         Upload Customers
    //       </span>
    //     </div>
    //   ),
    //   key: '3',
    //   icon: (
    //     <div>
    //       <CloudUploadOutlined className="pp-icon-18" />
    //     </div>
    //   ),
    // },
  ];

  const columns = [
    {
      title: t("customer_name"),
      dataIndex: "beneficiary_name",
      key: "beneficiary_name",
      render: (text, record) => (
        <>
          {record?.integration_type &&
            <div className="base_badge">
              <Badge.Ribbon text={convertString(record?.integration_type)} placement={"start"} color="#3f81f4" />
            </div>
          }
          {text}
        </>
      ),
    },
    {
      title: t("company_name"),
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("phone"),
      dataIndex: "primary_contact",
      key: "primary_contact",
    },
    {
      title: t("gst_treatment"),
      dataIndex: "gst_treatment",
      key: "gst_treatment",
    },
    {
      title: t("created_at"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space
          size='middle'
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setOpenAddNew(true);
                    setDataBeneficiary(record);
                  }}
                >
                  {t("edit")}
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              shape='default'
              icon={<MoreOutlined />}
              style={{ border: "none", color: "#5B87F9" }}
            />
          </Dropdown>
        </Space>
      ),
    },
  ];

  const menu = (
    <Menu onClick={(e) => {
      if(hasWriteAccess){
        if (e?.key == 1) {
          setOpenAddNew(true);
        } else {
          history.push("/customer/upload/section")
        }
      }
    }}>
      {options.map((ele) => (
        <Menu.Item key={ele.key} icon={ele.icon} className='flex-around'>
          {ele.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleSelectRow = (event, record) => {
    history.push(location.pathname + "/" + record.id, { isCustomer: true });
  };

  const handleFilter = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }

    if (value) {
      if (value === "all") {
        dispatch(
          customers({
            type: "customer",
          })
        );
        setFilters({});
      } else {
        dispatch(
          customers({
            ...filters,
            filters: {
              beneficiary_status: value,
            },
            type: "customer",
            isFilter: true,
          })
        );
        setFilters({
          ...filters,
          filters: {
            beneficiary_status: value,
          },
        });
      }
    }
    setPage(1);
  };

  const handleSort = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }

    if (value) {
      dispatch(
        customers({
          ...filters,
          sort: {
            sort_key: value,
            sort_order: "1",
          },
          type: "customer",
          isFilter: true,
        })
      );
      setFilters({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
      });
    }
  };

  const handleRefresh = () => {
    dispatch(
      customers({
        type: "customer",
      })
    );
    setFilters({});
  };
  const handleRadio = (e) => {
    setBoxValue(e.target.value);
  };
  const filterOptions = [
    { label: "All", filter: "all" },
    { label: "Active", filter: "active" },
    { label: "Inactive", filter: "in-active" },
  ];


  return (
    <>
      <Modal
        title={t("import_customers")}
        visible={modelOpen}
        onCancel={() => { setModelOpen(false) }}
        width={450}
        className="right-alinged-modal"
        footer={[]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <div>
            <p>{t("you_can_import")}</p>
            <div className="pp-form-item">
              <Radio.Group onChange={handleRadio} value={boxValue} className="radio-group">
                <Radio value="1" className="d-block">{t("customers")}</Radio>
                <Radio value="2" className="d-block">{t("cust_cont_per")}</Radio>
              </Radio.Group>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "2rem" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="pp-main-button"
            >
              <span>{t('continue')}</span>
            </Button>

            <Button
              className="pp-secondary-button"
              style={{ marginLeft: "2rem" }}
              onClick={() => { }}
            >
              <span>{t('cancel')}</span>
            </Button>
          </div>
        </div>
      </Modal>
      {openAddNew && (
        <AddCustomer
          open={openAddNew && hasWriteAccess}
          onClose={() => {
            setOpenAddNew(false);
            setDataBeneficiary(null);
          }}
          filters = {filters}
          dataBeneficiary={dataBeneficiary}
        />
      )}
      <Row wrap={false} className='table-wrapper'>
        <Col flex='auto' className='table-wrapper-col'>
          <div className='flex-grow'>
            <TableHeader
              menu={menu}
              setOpenAddNew={setOpenAddNew}
              sortOptions={sortOptions}
              // placeholder={t('search_by_name')}
              filterMenu={filterOptions}
              handleSort={handleSort}
              handleFilter={handleFilter}
              handleRefresh={handleRefresh}
              hasWriteAccess={hasWriteAccess}
              clickedFilter
              onSearch={(val) => {
                dispatch(
                  customers({
                    ...filters,
                    search_key: val,
                    type: "customer",
                  })
                );
                setFilters({
                  ...filters,
                  search_key: val,
                });
              }}
            />
            <div
              className={loading ? "shimmer" : ""}
              style={{ paddingTop: "15px" }}
            >
              <TableComponent
                columns={columns}
                hasCheckbox={false}
                loading={loading}
                data={Array.isArray(data) ? data : []}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                rowKey='id'
                handleRowClick={handleSelectRow}
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(
                      customers({
                        ...filters,
                        type: "customer",
                        page_number: pagee,
                      })
                    );
                    setFilters({page_number:pagee})
                    setPage(pagee);
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Customers;
