import { ReducerFunctions } from "../../utils";
import {
  Trips,
  TripsComments,
  TripsAddComments,
  TripsHistory,
  TripsApprove,
  TripsReject,
  TripsDelete,
  UserTrips,
  UserTripsSubmit,
  AddUserTrip,
  UpdateUserTrip,
  UserTripFields,
  TripsShow,
  TripsClose,
  TripDetailsPDF,
  UserReportLinkTrip,
  DownloadTripAttachments,
  AttachTripFile,
  RecallTrip
} from "./trips";

export const trips_reducer = ReducerFunctions(Trips, {});
export const download_trip_attachment_reducer = ReducerFunctions(DownloadTripAttachments, {});
export const attach_files_to_trips_reducer = ReducerFunctions(AttachTripFile, {});
export const trip_detail_pdf_reducer = ReducerFunctions(TripDetailsPDF, {});
export const tripsAddComments_reducer = ReducerFunctions(TripsAddComments, {});
export const tripsComments_reducer = ReducerFunctions(TripsComments, {});
export const tripsHistory_reducer = ReducerFunctions(TripsHistory, {});
export const tripsApprove_reducer = ReducerFunctions(TripsApprove, {});
export const tripsReject_reducer = ReducerFunctions(TripsReject, {});
export const tripsDelete_reducer = ReducerFunctions(TripsDelete, {});

export const userTrips_reducer = ReducerFunctions(UserTrips, {});
export const userTripsSubmit_reducer = ReducerFunctions(UserTripsSubmit, {});
export const addUserTrip_reducer = ReducerFunctions(AddUserTrip, {});
export const updateUserTrip_reducer = ReducerFunctions(UpdateUserTrip,{});
export const userTripFields_reducer = ReducerFunctions(UserTripFields, {});
export const userReportLinkTrips_reducer = ReducerFunctions(UserReportLinkTrip, {});
export const trips_show_reducer = ReducerFunctions(TripsShow, {});
export const trips_close_reducer = ReducerFunctions(TripsClose, {});
export const trip_recall_reducer = ReducerFunctions(RecallTrip, {});
