import {
  CaretDownFilled,
  CloseOutlined,
  DownOutlined,
  EllipsisOutlined,
  FilterOutlined,
  VerticalAlignBottomOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Select } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import filter from "../../assets/ippo_icons/feather-filter.png";
import plus from "../../assets/ippo_icons/plus.svg";
import search from "../../assets/ippo_icons/search.png";
import { LeftNavContext } from "../../LeftNavProvider";
const TableHeader = ({
  handleFilter,
  filterMenu,
  menu,
  sortOptions,
  handleSort,
  setOpenAddNew,
  noAddNewBtn,
  noDD,
  buttonLabel,
  handleAddButton,
  noFilter,
  noSort,
  onSearch,
  placeholder,
  handleRefresh,
  isBulkAction,
  bulkLoading,
  approveClick,
  rejectClick,
  handleselectPeriod,
  selectPeriodOptions,
  noSelectPeriod,
  handleStatement,
  statementOptions,
  noStatement,
  handleAdvanceFilter,
  showAdvanceFilter,
  hasExport,
  handleExport,
  hasWriteAccess,
  buttonComponent,
  showGrid,
  onShowGrid,
  defaultFilterValue,
  handleselect = () => {},
  SelectValue,
  selectOptions = [],
  noSelect = true,
}) => {
  const timeoutRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const [activeButton, setActiveButton] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [activeSort, setActiveSort] = useState("");
  const [statement, setStatement] = useState("");
  const [activeselectPeriod, setActiveselectPeriod] = useState("");
  const [advanceFilterObject, setAdvanceFilterObject] = useState([]);
  const { navChildren, activeMenu, activeSubMenu } = useContext(LeftNavContext);
  const { t } = useTranslation();

  useEffect(() => {
    setActiveButton(
      Array.isArray(filterMenu)
        ? filterMenu?.find((ele) => ele.filter === defaultFilterValue)?.label
        : ""
    );
  }, [location.pathname, defaultFilterValue]);
  useEffect(() => {
    if (activeButton !== "") {
      setAdvanceFilterObject((preValue) => [
        {
          label: activeButton,
          filter: activeFilter,
        },
        ...preValue,
      ]);
    }
  }, [activeFilter, activeButton]);

  const handleRemove = (index) => {
    const newFilterObject = advanceFilterObject.filter((items, valueIndex) => {
      return valueIndex !== index;
    });
    setAdvanceFilterObject(newFilterObject);
  };
  return (
    <div style={{ flexGrow: 1 }}>
      <div style={{ display: "flex", direction: "row", alignItems: "center" }}>
        {!noFilter &&
          (showAdvanceFilter ? (
            <>
              <Dropdown
                overlay={
                  <Menu>
                    {filterMenu?.map((items) => {
                      return (
                        <>
                          <label className="filter-text ml1">
                            {items?.title}
                          </label>
                          {items?.children?.map((ele, index) => {
                            return (
                              <>
                                <Menu.Item
                                  key={ele.filter}
                                  onClick={() => {
                                    handleFilter && handleFilter(ele.filter);
                                    setActiveButton(ele.label);
                                    setActiveFilter(ele.filter);
                                    if (ele.filter === "all") {
                                      setSearchKey("");
                                    }
                                  }}
                                >
                                  <button className="tableFilter">
                                    <span>{ele.label}</span>
                                  </button>
                                </Menu.Item>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                    <Menu.Item
                      className="advance-filter-btn"
                      onClick={() => handleAdvanceFilter()}
                    >
                      {" "}
                      <img src={filter} alt="advance-filter" />
                      Advance Filter
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  type="primary"
                  className="pp-main-button"
                  icon={
                    <div style={{ paddingRight: "5px" }}>
                      <img
                        src={filter}
                        alt=""
                        style={{
                          height: "14px",
                          position: "relative",
                          bottom: 2,
                        }}
                      />
                    </div>
                  }
                >
                  {t("addFilters")}
                </Button>
              </Dropdown>
              <>
                <div
                  style={{
                    borderRight: "2px solid #7676764F",
                    paddingLeft: "18px",
                    height: "25px",
                  }}
                ></div>

                <div style={{ paddingLeft: "17px" }}>
                  {advanceFilterObject?.length >= 1 ? (
                    advanceFilterObject?.map((items, index) => {
                      return (
                        <>
                          {items?.label !== "" ? (
                            <span
                              className="advance-filter-values"
                              onClick={() => handleRemove(index)}
                            >
                              {items?.label} <CloseOutlined />
                            </span>
                          ) : null}
                        </>
                      );
                    })
                  ) : (
                    <span className="no-filters">No Filters Applied</span>
                  )}
                </div>
              </>
            </>
          ) : (
            <>
              <Dropdown
                className="mr1"
                overlay={
                  <Menu>
                    {filterMenu?.map((ele, i) => (
                      <Menu.Item
                        key={i}
                        onClick={() => {
                          handleFilter && handleFilter(ele.filter);
                          setActiveButton(ele.label);
                          setActiveFilter(ele.filter);
                          if (ele.filter === "all") {
                            setSearchKey("");
                          }
                        }}
                      >
                        <button className="tableFilter">
                          <span>{ele.label}</span>
                        </button>
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button
                  type="primary"
                  className="pp-main-button"
                  icon={<FilterOutlined />}
                >
                  {activeButton || t("addFilters")}
                </Button>
              </Dropdown>
            </>
          ))}

        {!noSelect ? (
          <div className="w20">
            <section className="w100">
              <Select
                className="w100 pp-input tableHeader-select"
                size="large"
                onChange={(e) => {
                  handleselect(e);
                }}
                value={SelectValue}
                options={selectOptions}
                placeholder={"select"}
              />
            </section>
          </div>
        ) : null}
        {navChildren?.length ? (
          <div className="table tabs ">
            {navChildren?.map((ele) => {
              return (
                <button
                  className={`table tab ${
                    location.pathname?.includes(ele.key) ? "active" : ""
                  }`}
                  onClick={() => {
                    history.push(activeMenu + activeSubMenu + ele.key);
                  }}
                >
                  {ele.label}
                </button>
              );
            })}
          </div>
        ) : null}
        <div style={{ flexGrow: 1 }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {buttonComponent && buttonComponent}
          {isBulkAction && (
            <div className="action-btns">
              <button
                className="approve"
                disabled={bulkLoading}
                onClick={approveClick}
              >
                {t("approve")}
              </button>
              <button
                className="reject"
                disabled={bulkLoading}
                onClick={rejectClick}
              >
                {t("reject")}
              </button>
            </div>
          )}

          <div>
            <Input
              className="pp-input"
              size="large"
              id="search-input"
              placeholder={placeholder ? placeholder : t("searchh")}
              value={searchKey}
              prefix={<img src={search} alt="" style={{ height: "14px" }} />}
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #EAEAEA",
                borderRadius: "5px",
                minWidth: "22rem",
              }}
              onChange={(e) => {
                if (timeoutRef.current) {
                  clearTimeout(timeoutRef.current);
                }
                timeoutRef.current = setTimeout(() => {
                  onSearch && onSearch(e.target.value);
                }, 600);
                setSearchKey(e.target.value);
              }}
            />
          </div>
          {showGrid && (
            <div className="mlHalf">
              <Button className="pp-main-button" onClick={onShowGrid}>
                Show Grid
              </Button>
            </div>
          )}
          {hasExport && (
            <div className="mlHalf">
              <Button className="pp-main-button" onClick={handleExport}>
                Export as CSV
              </Button>
            </div>
          )}
          {!noAddNewBtn && (
            <div className="mlHalf">
              <Dropdown.Button
                icon={noDD ? null : <CaretDownFilled className="down-caret" />}
                type="primary"
                className={`pp-dropdownbutton ${noDD ? "no-dd" : ""}`}
                style={{
                  backgroundColor: "#3F81F4",
                  height: "42px",
                  display: "flex",
                  opacity: hasWriteAccess ? 1 : 0.5,
                }}
                onClick={() => {
                  if (handleAddButton) {
                    handleAddButton();
                  } else {
                    setOpenAddNew(true);
                  }
                }}
                overlay={menu}
                // menu={{ items: options, onClick: () => {} }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "6px" }}>
                    <PlusCircleFilled />
                  </div>
                  <span
                    style={{
                      margin: 0,
                      fontWeight: "medium",
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#ffffff",
                    }}
                  >
                    {buttonLabel || t("addNew")}
                  </span>
                </div>
              </Dropdown.Button>
            </div>
          )}

          {!noStatement && Array.isArray(statementOptions) && (
            <div className="mlHalf">
              {Array.isArray(statementOptions) ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <label className="dim-text ml1">SELECT PERIOD</label>
                      {statementOptions.map((option, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() => {
                            setStatement(option.filter);
                            handleStatement && handleStatement(option.filter);
                          }}
                        >
                          {option.label}
                        </Menu.Item>
                      ))}
                      {handleRefresh && (
                        <Menu.Item onClick={() => handleRefresh()}>
                          Refresh
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button className="select-period-btn">
                    Statement
                    <VerticalAlignBottomOutlined />
                  </Button>
                </Dropdown>
              ) : null}
            </div>
          )}
          {!noSelectPeriod && Array.isArray(selectPeriodOptions) && (
            <div className="mlHalf">
              {Array.isArray(selectPeriodOptions) ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <label className="dim-text ml1">SELECT PERIOD</label>
                      {selectPeriodOptions.map((option, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() => {
                            setActiveselectPeriod(option.filter);
                            handleselectPeriod &&
                              handleselectPeriod(option.filter);
                          }}
                        >
                          {option.label}
                        </Menu.Item>
                      ))}
                      {handleRefresh && (
                        <Menu.Item onClick={() => handleRefresh()}>
                          Refresh
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button className="select-period-btn">
                    Select Period
                    <DownOutlined className="" />
                  </Button>
                </Dropdown>
              ) : null}
            </div>
          )}

          {!noSort && (
            <div className="mlHalf">
              {/* <Dropdown.Button
              className="pp-dropdownbutton-menu"
              style={{
                borderRadius: '10px',
                height: 42
              }}
              overlay={sortOptions}
              size="large"
              icon={<EllipsisOutlined className="pp-icon-25" />}
            /> */}
              {Array.isArray(sortOptions) ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <label className="dim-text ml1">{t("by_sort")}</label>
                      {sortOptions.map((option, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() => {
                            setActiveSort(option.filter);
                            handleSort &&
                              handleSort(option.filter, option?.sort_order);
                          }}
                        >
                          {option.label}
                        </Menu.Item>
                      ))}
                      {handleRefresh && (
                        <Menu.Item
                          onClick={() => {
                            handleRefresh();
                            setActiveButton("");
                            setSearchKey("");
                          }}
                        >
                          {t("refresh")}
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button
                    style={{ color: "#000" }}
                    type="primary"
                    className={`pp-dropdownbutton sort-btn ${
                      noDD ? "no-dd" : ""
                    }`}
                  >
                    {/* {activeSort || "Sort By"} */}
                    <EllipsisOutlined className="pp-icon-25" />
                    {/* <CaretDownFilled className="down-caret" /> */}
                  </Button>
                </Dropdown>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
