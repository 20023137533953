import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { invoices } from "../../../actions/customers/customers";
import { SideMenu, headerTitles } from "../../../actions/masterdata/masterdata";
import { apis } from "../../../config/APIs";
import usePayAxios from "../../../config/useAxios";
import { amountFormat } from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import TableComponent from "../../Common/TableComp/TableComp";
import { formatDate } from "../Common";
import TableHeader from "../TableHeader";

const InvoiceApprovals = ({ isSales, isEstimates, hasWriteAccess }) => {
  const { onCall: BulkInvoice } = usePayAxios({
    api: apis.bulkInvoiceAPI,
    method: "post",
  });
  const { onCall: bulkRejectInvoice } = usePayAxios({
    api: apis.bulkRejectInvoiceAPI,
    method: "post",
  });
  var alphanumeric = /^[ \t\r\n,\.]*$/;
  const { Title, Text } = Typography;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [filters, setFilters] = useState({
    type: isEstimates ? t("ESTIMATE") : isSales ? t("SALES_ORDER") : "INVOICE",
  });
  const [page, setPage] = React.useState(1);
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [reason, setReason] = useState("");
  const is_admin = useSelector(
    (state) => state?.auth?.localVariables?.is_admin
  );

  useEffect(() => {
    if (location?.state?.filter) {
      dispatch(
        invoices({
          view: "admin",
          type: isEstimates ? "ESTIMATE" : isSales ? "SALES_ORDER" : "INVOICE",
          filters: { invoice_status: [location?.state?.filter] },
        })
      );
      setFilters({
        type: isEstimates
          ? t("ESTIMATE")
          : isSales
            ? t("SALES_ORDER")
            : "INVOICE",
        filters: { invoice_status: [location?.state?.filter] },
      });
    } else {
      dispatch(
        invoices({
          view: "admin",
          type: isEstimates ? "ESTIMATE" : isSales ? "SALES_ORDER" : "INVOICE",
        })
      );
      setFilters({
        type: isEstimates
          ? t("ESTIMATE")
          : isSales
            ? t("SALES_ORDER")
            : "INVOICE",
      });
    }
    dispatch(
      headerTitles({
        title: "apprvolas",
        description: `${isEstimates
            ? "approve_estimates"
            : isSales
              ? "approve_sales_orders"
              : "approve_invoice"
          }`,
      })
    );
    let locationSplit = location?.pathname?.split("/");
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: isEstimates || isSales ? "/" + locationSplit?.[2] : "",
        showChildren: true,
        childrenMenu: "/approvals",
      },
    });
  }, [location.pathname]);

  const InvoiceApprovals = useSelector(
    (state) => state?.customers?.invoices || {}
  );
  const totalRecords = useSelector(
    (state) => state.customers?.invoices?.data?.total_count
  );

  useMemo(() => {
    if (InvoiceApprovals?.success && !InvoiceApprovals?.data?.error) {
      setData(InvoiceApprovals.data.data);
    }
  }, [InvoiceApprovals]);

  const handleSearch = (searchKey) => {
    dispatch(
      invoices({
        ...filters,
        view: "admin",
        search_key: searchKey,
        type: isEstimates
          ? t("ESTIMATE")
          : isSales
            ? t("SALES_ORDER")
            : "INVOICE",
        page_number: 1,
      })
    );
    setPage(1);
    setFilters({
      ...filters,
      search_key: searchKey,
    });
  };

  const handleFilter = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }
    if (value === "all") {
      dispatch(
        invoices({
          view: "admin",
          type: isEstimates
            ? t("ESTIMATE")
            : isSales
              ? t("SALES_ORDER")
              : "INVOICE",
          page_number: 1,
        })
      );
      setFilters({
        type: isEstimates
          ? t("ESTIMATE")
          : isSales
            ? t("SALES_ORDER")
            : "INVOICE",
      });
    } else {
      dispatch(
        invoices({
          ...filters,
          view: "admin",
          filters:
            value === "closed"
              ? { status: [value] }
              : { invoice_status: [value] },
          isFilter: true,
        })
      );
      setFilters({
        ...filters,
        filters:
          value === "closed"
            ? { status: [value] }
            : { invoice_status: [value] },
      });
    }
    setPage(1);
  };

  const handleSort = (value) => {
    dispatch(
      invoices({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
        view: "admin",
        isFilter: true,
      })
    );
    setPage(1);
    setFilters({
      ...filters,
      sort: {
        sort_key: value,
        sort_order: "1",
      },
    });
  };

  const sortOptions = [
    {
      label: t("customer_name"),
      filter: "customer_name",
    },
    // {
    //   label: isSales ? t('sales_no') : isEstimates ? t('estimate_no') : t('invoice_no'),
    //   filter: "invoice_no"
    // },
    {
      label: t("date"),
      filter: "invoice_date",
    },
    {
      label: isSales
        ? t("shipment_date")
        : isEstimates
          ? t("expiry_date")
          : t("due_date"),
      filter: "po_due_date",
    },
    {
      label: t("Amount"),
      filter: "total_amount",
    },
  ];

  // const filterOptions = [
  //   { label: t('all_reports'), filter: "all" },
  //   { label: t('pending_approval'), filter: "pending_approval" },
  //   { label: t('approved'), filter: "approved" },
  //   { label: t('rejected'), filter: "rejected" },
  //   { label: t('overDue'), filter: "overdue" },
  // ];

  const filterOptions = isEstimates
    ? [
      { label: t("all"), filter: "all" },
      { label: t("pending_approval"), filter: "pending_approval" },
      { label: t("approved"), filter: "approved" },
      { label: t("overDue"), filter: "overdue" },
      { label: t("rejected"), filter: "rejected" },
      { label: t("decline"), filter: "declined" },
      { label: t("accept"), filter: "accepted" },
    ]
    : [
      { label: t("all"), filter: "all" },
      { label: t("pending_approval"), filter: "pending_approval" },
      { label: t("approved"), filter: "approved" },
      { label: t("overDue"), filter: "overdue" },
      { label: t("rejected"), filter: "rejected" },
    ];

  const columns = [
    {
      title: isSales
        ? t("sales_no")
        : isEstimates
          ? t("estimate_no")
          : t("invoice_no"),
      dataIndex: "invoice_no",
      key: "invoice_no",
    },
    {
      title: t("customer_name"),
      dataIndex: "customer_name",
      key: "customer_name",
    },
    isSales
      ? {
        title: t("estimate_no"),
        dataIndex: "ref_po_no",
        key: "ref_po_no",
      }
      : isEstimates
        ? {}
        : {
          title: t("so_number"),
          dataIndex: "ref_po_no",
          key: "ref_po_no",
        },
    {
      title: t("date"),
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text) => <div>{formatDate(text)}</div>,
    },
    {
      title: isSales
        ? t("shipment_date")
        : isEstimates
          ? t("expiry_date")
          : t("due_date"),
      dataIndex: "po_due_date",
      key: "po_due_date",
      render: (text) =>
        text && (
          <div className={moment().diff(text) <= 0 ? "" : "table-due-date"}>
            {formatDate(text)}
          </div>
        ),
    },
    {
      title: t("Amount"),
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text, record) => (
        <div>
          <BaseCurrency symbol_code={record?.currency_info?.iso_code} />
          {amountFormat(text, record?.currency_info?.decimal_scale)}
        </div>
      ),
    },
    // {
    //   title: 'Balance Due',
    //   dataIndex: 'balance_due',
    //   key: 'balance_due',
    //   render: (text) => <div><BaseCurrency />{text}</div>,
    // },
    {
      title: t("status"),
      dataIndex: "role_status",
      key: "role_status",
      render: (text, record) => (
        <div className={`status status_${text}`}>
          {record?.status
            ? record?.status?.replace(/_/g, " ")
            : text?.replace(/_/g, " ")}
        </div>
      ),
    },
    isEstimates || isSales
      ? {}
      : {
        title: t("payment_status"),
        dataIndex: "payment_status",
        key: "payment_status",
        render: (text, record) => (
          <div className={`status status_${text}`}>
            {text?.replace(/_/g, " ")}
          </div>
        ),
      },
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button
    //         shape="default"
    //         icon={<WarningOutlined />}
    //         style={{ border: "none", color: "#E24C4B" }}
    //       />
    //       <Button
    //         shape="default"
    //         icon={<MessageOutlined />}
    //         style={{ border: "none", color: "#5B87F9" }}
    //       />
    //       <Button
    //         shape="default"
    //         icon={<MoreOutlined />}
    //         style={{ border: "none", color: "#5B87F9" }}
    //       />
    //     </Space>
    //   ),
    // },
  ];
  const renderBulkOption = (type) => {
    return (
      <>
        <Text>
          {t("want_bulk")} {type} ?
        </Text>
        <br />
        {type === "reject" ? (
          <Form.Item>
            <Input.TextArea
              placeholder={t("enter_reason")}
              className="mt1"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
              required
            />
          </Form.Item>
        ) : null}
        <Button
          disabled={
            type === "reject" && reason?.match(alphanumeric) ? true : false
          }
          key="1"
          className="formButton mt1"
          onClick={() => {
            if (type === "reject") {
              let invoiceDetailsArray = [];
              let invoiceDetails = selectedRows
                ?.map(
                  (ele) => ele.invoice_status === "pending_approval" && ele.id
                )
                .filter((ele) => invoiceDetailsArray.push({ id: ele }));
              if (isEstimates) {
                bulkRejectInvoice({
                  data: {
                    invoiceDetailsArray: invoiceDetailsArray,
                    reject_reason: reason,
                  },
                  params: {
                    module_type: "ESTIMATE",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isEstimates
                          ? "Estimates Rejected Successfully "
                          : isSales
                            ? "Sales Orders Rejected Successfully"
                            : "Invoices Rejected Successfully"}
                      </span>
                    );
                    dispatch(
                      invoices({
                        view: "admin",
                        type: isEstimates
                          ? "ESTIMATE"
                          : isSales
                            ? "SALES_ORDER"
                            : "INVOICE",
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                    setReason("");
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              } else if (isSales) {
                bulkRejectInvoice({
                  data: {
                    invoiceDetailsArray: invoiceDetailsArray,
                    reject_reason: reason,
                  },
                  params: {
                    module_type: "SALES_ORDER",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isEstimates
                          ? "Estimates Rejected Successfully "
                          : isSales
                            ? "Sales Orders Rejected Successfully"
                            : "Invoices Rejected Successfully"}
                      </span>
                    );
                    dispatch(
                      invoices({
                        view: "admin",
                        type: isEstimates
                          ? "ESTIMATE"
                          : isSales
                            ? "SALES_ORDER"
                            : "INVOICE",
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                    setReason("");
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              } else {
                bulkRejectInvoice({
                  data: {
                    invoiceDetailsArray: invoiceDetailsArray,
                    reject_reason: reason,
                  },
                  params: {
                    module_type: "INVOICE",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isEstimates
                          ? "Estimates Rejected Successfully "
                          : isSales
                            ? "Sales Orders Rejected Successfully"
                            : "Invoices Rejected Successfully"}
                      </span>
                    );
                    dispatch(
                      invoices({
                        view: "admin",
                        type: isEstimates
                          ? "ESTIMATE"
                          : isSales
                            ? "SALES_ORDER"
                            : "INVOICE",
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                    setReason("");
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              }
            } else {
              let invoiceDetailsArray = [];
              let invoiceDetails = selectedRows
                ?.map(
                  (ele) => ele.invoice_status === "pending_approval" && ele.id
                )
                .filter((ele) => invoiceDetailsArray.push({ id: ele }));
              if (isEstimates) {
                BulkInvoice({
                  data: { invoiceDetailsArray: invoiceDetailsArray },
                  params: {
                    module_type: "ESTIMATE",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isEstimates
                          ? "Estimates Approved Successfully "
                          : isSales
                            ? "Sales Orders Approved Successfully"
                            : "Invoices Approved Successfully"}
                      </span>
                    );
                    dispatch(
                      invoices({
                        view: "admin",
                        type: isEstimates
                          ? "ESTIMATE"
                          : isSales
                            ? "SALES_ORDER"
                            : "INVOICE",
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              } else if (isSales) {
                BulkInvoice({
                  data: { invoiceDetailsArray: invoiceDetailsArray },
                  params: {
                    module_type: "SALES_ORDER",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isEstimates
                          ? "Estimates Approved Successfully "
                          : isSales
                            ? "Sales Orders Approved Successfully"
                            : "Invoices Approved Successfully"}
                      </span>
                    );
                    dispatch(
                      invoices({
                        view: "admin",
                        type: isEstimates
                          ? "ESTIMATE"
                          : isSales
                            ? "SALES_ORDER"
                            : "INVOICE",
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              } else {
                BulkInvoice({
                  data: { invoiceDetailsArray: invoiceDetailsArray },
                  params: {
                    module_type: "INVOICE",
                  },
                })
                  .then(() => {
                    message.success(
                      <span className="messageText">
                        {isEstimates
                          ? "Estimates Approved Successfully "
                          : isSales
                            ? "Sales Orders Approved Successfully"
                            : "Invoices Approved Successfully"}
                      </span>
                    );
                    dispatch(
                      invoices({
                        view: "admin",
                        type: isEstimates
                          ? "ESTIMATE"
                          : isSales
                            ? "SALES_ORDER"
                            : "INVOICE",
                      })
                    );
                    setIsBulkModalVisible(false);
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                  })
                  .catch((err) => {
                    message.error(
                      <span className="messageText">{err.message}</span>
                    );
                    setIsBulkModalVisible(false);
                  });
              }
            }
          }}
        >
          {type}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => {
            setIsBulkModalVisible(false);
          }}
        >
          {t("cancel")}
        </Button>
      </>
    );
  };

  const approveClick = () => {
    setType("approve");
    setIsBulkModalVisible(true);
  };
  const rejectClick = () => {
    setIsBulkModalVisible(true);
    setType("reject");
  };

  const onChangeCheckBox = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys([selectedRowKeys, ...selectedRowKeys]);
  };

  return (
    <>
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              noAddNewBtn={true}
              setOpenAddNew={setOpenAddNew}
              sortOptions={sortOptions}
              handleSort={handleSort}
              defaultFilterValue={filters?.filters?.invoice_status?.[0]}
              filterMenu={
                isSales
                  ? [...filterOptions, { label: t("closed"), filter: "closed" }]
                  : filterOptions
              }
              handleFilter={handleFilter}
              onSearch={handleSearch}
              approveClick={approveClick}
              rejectClick={rejectClick}
              isBulkAction={selectedRows?.length > 0}
            />

            <Modal
              title={<Title level={4}>{t("bulk_action")}</Title>}
              visible={isBulkModalVisible && hasWriteAccess}
              onCancel={() => {
                setReason("");
                setIsBulkModalVisible(false);
              }}
              footer={[]}
              className="center-alinged-modal"
            >
              {renderBulkOption(type)}
            </Modal>

            <div style={{ flexGrow: 1, paddingTop: "22px" }}>
              <TableComponent
                rowKey="id"
                columns={columns}
                scroll={{ x: 1200 }}
                data={Array.isArray(data) ? data : []}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                hasCheckbox={true}
                handleRowClick={(e, record) => {
                  history.push(
                    isEstimates
                      ? `/sales/approveestimate`
                      : isSales
                        ? `/sales/approveso`
                        : `/sales/approveinvoice`,
                    { id: record.id, from: location.pathname, isInvoice: true }
                  );
                }}
                rowSelection={{
                  type: "checkbox",
                  // ...rowSelection,
                  onChange: onChangeCheckBox,
                  getCheckboxProps: (record) => ({
                    disabled:
                      !is_admin || record.role_status !== "pending_approval",
                    style: {
                      display: is_admin ? "flex" : "none",
                    },
                  }),
                  selectedRowKeys: selectedRowKeys,
                  selectedRows: selectedRows,
                }}
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(
                      invoices({
                        ...filters,
                        view: "admin",
                        type: isEstimates
                          ? "ESTIMATE"
                          : isSales
                            ? "SALES_ORDER"
                            : "INVOICE",
                        page_number: pagee,
                      })
                    );
                    setPage(pagee);
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InvoiceApprovals;
