import React from "react";

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import Text from "antd/lib/typography/Text";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { categories } from "../../../../actions/categories";
import {
  AddTransactionField,
  companySetting,
  DeleteTransactionField,
  deleteTransactionField,
  getTransactionField,
  UpdateCompanyTransaction,
  updateCompanyTransaction,
  UpdateTransactionField,
  updateTransactionField,
} from "../../../../actions/companySettings";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { objectToFormData } from "../../../../utils";
import "./../../styles/setting.css";
import "./../Custom.css";
import AddExpenseField from "./AddExpenseField";
const { TabPane } = Tabs;
const { Title } = Typography;

message.config({
  maxCount: 3,
});
const Expenses = ({ hasWriteAccess, type }) => {
  // const [activeTab, setActiveTab] = React.useState('profile');
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState("center");
  const [selectedId, setSelectedId] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("field"),
      dataIndex: "column_name",
      key: "column_name",
    },
    {
      title: t("column_type"),
      dataIndex: "column_type",
      key: "column_type",
      render: (text, render) => {
        if (text == "integer") {
          return "Number";
        } else if (text === "string") {
          return "Text Box";
        } else if (text === "datetime") {
          return "Date & Time";
        } else if (text === "document") {
          return "Document";
        } else if (text === "dropdown") {
          return "Drop Down";
        } else {
          return text;
        }
      },
    },
  ];

  const formik = useFormik({
    initialValues: {
      module_type_prefix: "",
      module_type_reminder: "",
      module_type_reminder_days: "",
      module_type_starts_with: "",
      module_type_display_starts_with: "",
      edit_prepaid: "",
      enable_ereceipts: "",
      policy_violation: "",
    },
    onSubmit: (vals) => {
      dispatch(
        updateCompanyTransaction({ ...vals, params: { module_type: type } })
      );
    },
  });

  const companySettingResponse = useSelector(
    (state) => state.companySetting.companySetting || {}
  );
  const updateCompanySettingResponse = useSelector(
    (state) => state.companySetting.updateCompanySetting || {}
  );

  const transactionFieldResponse = useSelector(
    (state) => state.companySetting.getTransactionField || {}
  );
  const deleteTransactionFieldResponse = useSelector(
    (state) => state.companySetting.deleteTransactionField || {}
  );
  const addTransactionFieldResponse = useSelector(
    (state) => state.companySetting.addTransactionField || {}
  );
  const updateTransactionFieldResponse = useSelector(
    (state) => state.companySetting.updateTransactionField || {}
  );
  const updateTransactionReportResponse = useSelector(
    (state) => state.companySetting.updateTransactionReport || {}
  );
  const { data: { data: fields = [] } = {} } = transactionFieldResponse;
  const setTab = () => {};

  const totalRecords = transactionFieldResponse?.data?.total_count;

  React.useEffect(() => {
    dispatch(companySetting({ module_type: type }));
    dispatch(getTransactionField({}));
    dispatch(
      categories({
        status: true,
        pagination: false,
      })
    );
    dispatch(
      headerTitles({
        title: "Expenses",
        description: "",
      })
    );
    // setIsModalVisible(false,);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: { data: { result: company_setting } = {} } = {} } =
    companySettingResponse;
    console.log(formik.values, "data====>");
  React.useEffect(() => {
    formik.resetForm();
    const { data: { status = false } = {} } = companySettingResponse;
    // setCompanyValues({...company_setting});
    if (status) {
      formik.setValues({
        module_type_prefix: company_setting.transaction_prefix,
        module_type_reminder: company_setting.transaction_reminder,
        module_type_reminder_days: company_setting.transaction_reminder_days,
        module_type_starts_with: company_setting.transaction_starts_with,
        module_type_display_starts_with:
          company_setting?.transaction_module_display_starts_with,
        edit_prepaid: company_setting.edit_prepaid,
        enable_ereceipts: company_setting.enable_ereceipts,
        policy_violation: company_setting.policy_violation,
        remainders: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettingResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = updateCompanySettingResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UpdateCompanyTransaction.RESET,
      });
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: UpdateCompanyTransaction.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanySettingResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      updateTransactionReportResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(companySetting({ module_type: type }));
      handleSubmit();
      dispatch({
        type: UpdateCompanyTransaction.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTransactionReportResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = updateTransactionFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getTransactionField({}));
      dispatch({
        type: UpdateTransactionField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UpdateTransactionField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTransactionFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = deleteTransactionFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getTransactionField({}));
      dispatch({
        type: DeleteTransactionField.RESET,
      });
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: DeleteTransactionField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTransactionFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = addTransactionFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getTransactionField());
      dispatch({
        type: AddTransactionField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddTransactionField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTransactionFieldResponse]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSubmit = () => {
    setEditMode(false);
  };

  const tableAction = [
    {
      title: t("mandatory"),
      dataIndex: "is_required",
      key: "is_required",
      render: (status, record) => (
        <Checkbox
          disabled={!hasWriteAccess}
          key={record.id}
          onChange={(e) =>
            dispatch(
              updateTransactionField({
                // ...record,
                column_name: record.column_name,
                column_type: record.column_type,
                id: record.id,
                is_enable: record.is_enable,
                view_in_pdf: record.view_in_pdf,
                data: {
                  max_size: record.data.max_size || "",
                  doc_formats: record.data.doc_formats || "",
                },
                default_values: record.default_values || [],
                is_required: e.target.checked,
              })
            )
          }
          checked={status}
        ></Checkbox>
      ),
    },
    // {
    //   title: "Show in PDF",
    //   dataIndex: "view_in_pdf",
    //   key: "view_in_pdf",
    //   render: (status, record) => (
    //     <Checkbox
    //       key={record.id}
    //       onChange={(e) =>
    //         dispatch(
    //           updateTransactionField(
    //             objectToFormData({
    //               ...record,
    //               view_in_pdf: e.target.checked,
    //             })
    //           )
    //         )
    //       }
    //       checked={status}
    //     ></Checkbox>
    //   ),
    // },
    {
      title: t("enable"),
      dataIndex: "is_enable",
      key: "is_enable",
      render: (status, record) => (
        <Checkbox
          key={record.id}
          disabled={!hasWriteAccess}
          onChange={(e) =>
            dispatch(
              updateTransactionField({
                // ...record,
                column_name: record.column_name,
                column_type: record.column_type,
                id: record.id,
                is_required: record.is_required,
                view_in_pdf: record.view_in_pdf,
                data: {
                  max_size: record.data.max_size || "",
                  doc_formats: record.data.doc_formats || "",
                },
                default_values: record.default_values || [],
                is_enable: e.target.checked,
              })
            )
          }
          checked={status}
        ></Checkbox>
      ),
    },
    {
      title: t("actions"),
      key: "action",
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            disabled={!hasWriteAccess}
            onClick={() => showEditModal(record.id)}
          >
            <img src={EditIcon} alt={"logo"} className="action-logo" />
          </button>
          <button
            disabled={!hasWriteAccess}
            onClick={() => showDeleteModal(record.id)}
          >
            <img src={DeleteIcon} alt={"logo"} className="action-logo" />
          </button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("center");
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t("delete_sure_field")}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() => dispatch(deleteTransactionField({ id: selectedId }))}
        >
          {t("delete")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t("cancel")}
        </Button>
      </>
    );
  };

  return (
    <>
      {isModalVisible && (
        <Modal
          title={
            modalType === "center" ? (
              <>
                <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
                {t("delete_field")}
              </>
            ) : (
              <Title level={4}>
                {selectedId ? t("edit_fields") : t("add_fields")} &nbsp;
                <span className="text-small">{t("enter_details")}</span>
              </Title>
            )
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[]}
          className={
            modalType === "center"
              ? "center-aligned-modal"
              : "right-alinged-modal"
          }
        >
          {modalType === "center" ? (
            renderDeleteOption()
          ) : (
            <AddExpenseField id={selectedId} handleCancel={handleCancel} />
          )}
        </Modal>
      )}
      <Tabs onChange={setTab} type="card" size="large" className="custom-tabs">
        <TabPane tab={t("preferences")} key="preferences">
          <div className="tabContentWrapper">
            <Title level={5}>{t("auto_generate_expense")}</Title>
            <Divider />
            <Form
              layout="vertical"
              className="custom-form"
              onFinish={formik.handleSubmit}
              // validateMessages={validateMessages1}
            >
              <Row>
                <Col span={8}>
                  <Form.Item label={t("prefix")}>
                    <Input
                      name="module_type_prefix"
                      onChange={formik.handleChange}
                      placeholder=""
                      value={formik.values.module_type_prefix}
                      disabled={!editMode || company_setting.transaction_prefix}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="ml1">
                  <Form.Item label={t("start_with")}>
                    <Input
                      placeholder=""
                      name="module_type_starts_with"
                      value= {
                        company_setting?.transaction_module_display_starts_with
                          ? company_setting?.transaction_module_display_starts_with
                          : formik.values.module_type_starts_with
                      }
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) {
                          return;
                        }
                        formik.setFieldValue(
                          "module_type_starts_with",
                          e.target.value
                        );
                        // !company_setting?.transaction_module_display_starts_with ? formik.setFieldValue("transaction_module_display_starts_with",e.target.value)
                      }}
                      disabled={
                        !editMode ||
                        company_setting.transaction_module_display_starts_with
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Title level={5} className="customTitle">
                {t("notifications")}
              </Title>
              <Divider />
              <Row>
                <Col span={24}>
                  <Form.Item
                    valuePropName="checked"
                    style={{ marginBottom: "0px" }}
                  >
                    <Checkbox
                      checked={formik.values.module_type_reminder}
                      name="module_type_reminder"
                      disabled={!editMode}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "module_type_reminder",
                          e.target.checked
                        );
                      }}
                    >
                      <span className="flexDisplay">
                        {t("reminder_expense")}
                        &nbsp;
                        <Form.Item className="shortNumberInput">
                          <Input
                            type="text"
                            className="shortInput"
                            name="module_type_reminder_days"
                            disabled={!editMode}
                            value={formik.values.module_type_reminder_days}
                            onChange={(e) => {
                              if (Number.isNaN(Number(e.target.value))) {
                                return;
                              }
                              formik.setFieldValue(
                                "module_type_reminder_days",
                                e.target.value
                              );
                            }}
                          />
                        </Form.Item>{" "}
                        &nbsp;{t("days")}
                      </span>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              {/* <Title level={5} className="customTitle">
                Settings
              </Title>
              <Divider />
              <Row>
                <Col span={24}>
                  <Form.Item name="enable_ereceipts" valuePropName="checked">
                    <Checkbox>
                      Enable Forward E-Receipts from Primary/Secondary Email ids
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="policy_violation" valuePropName="checked">
                    <Checkbox>
                      Do not allow the employees to submit expenses if he
                      violate the policies
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Title level={5} className="customTitle">
                Cards
              </Title>
              <Divider />
              <Row>
                <Col span={24}>
                  <Form.Item name="edit_prepaid" valuePropName="checked">
                    <Checkbox>
                      Permit users to edit the amount in prepaid card expenses
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row> */}

              {!editMode && (
                <Button
                  className="formButton mt3"
                  onClick={() => handleEdit()}
                  disabled={!hasWriteAccess}
                >
                  {t("edit")}
                </Button>
              )}
              {editMode && (
                <Button
                  key="5"
                  className="formButton mt3"
                  htmlType="submit"
                  disabled={updateTransactionReportResponse?.loading}
                >
                  {t("save")}
                </Button>
              )}
              {/* <Button key="6" className="cancelButton ml1 mt3" onClick={() => history.push(RouteConstants.SETTING_USERS)}></Button> */}
            </Form>
          </div>
        </TabPane>
        <TabPane tab="Fields" key="fields">
          <div className="tabContentWrapper">
            {
              <Button
                onClick={showModal}
                className="mb1 addNew"
                disabled={!hasWriteAccess}
              >
                <img src={AddNewIcon} alt={"logo"} className="addNew-logo" />
                {t("new_field")}
              </Button>
            }
            <Table
              rowKey="id"
              columns={[...columns, ...tableAction]}
              dataSource={fields}
              className="settingsTable"
              pagination={{
                total: totalRecords || 0,
                onChange: (pagee) =>
                  dispatch(getTransactionField({ page_number: pagee })),
              }}
            />
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default Expenses;
