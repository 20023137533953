import {
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Modal,
  Select,
  Table
} from "antd";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MinusCircleOutlined } from "@ant-design/icons";
import { userCards } from "../../actions/cards/cards";
import BaseCurrency from "../Common/BaseCurrency";
import { useTranslation } from "react-i18next";
import { hasUpToOneDecimal } from '../../utils';
import * as yup from "yup";
import { notNumber } from "../../utils";
const TextArea = Input.TextArea;

const ReimburseModal = ({
  open,
  handleCancel,
  title,
  isPositive,
  id,
  onSubmit,
  total_advances_amount,
  total_amount,
  loading,
}) => {
  const [advance_refunds, setAdvanceFunds] = useState([
    {
      amount: "",
      refund_date: "",
      reference: "",
      isEditing: true
    },
  ]);
  const { t } = useTranslation();

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      id: id,
      report_id: id,
      mode: "",
      billing_to: "",
      reimbursed_on: new Date(),
      wallet_type: "",
      reference: "",
      notes: "",
      carry_forward: "",
      advance_refunds: [{
        amount: "",
        refund_date: "",
        reference: "",
        isEditing: true
      }],
    },
    onSubmit: (vals) => {
      vals.advance_refunds = vals.advance_refunds?.map(ele => {
        delete ele.isEditing
        return ele
      })?.filter(ele => ele.amount && ele.reference && ele.refund_date)
      onSubmit(vals);
    },
    validationSchema: isPositive ? yup.object().shape({
      mode: yup.string().required("Mode is required"),
      billing_to: yup.string().required("Billing To is required"),
    }) : yup.object().shape({
      reimbursed_on: yup.string().when('carry_forward', {
        is: false,
        then: yup.string().required("Received On is required")
      }),
    })
  });

  // useEffect(() => {
  //   dispatch(userCards({ status: "A", source: "admin" }));
  //   // eslint-disable-next-line
  // }, [])
  console.log(advance_refunds);
  const columns = [
    {
      title: t('date'),
      dataIndex: "refund_date",
      key: "refund_date",
      render: (text, _r, data) => (
        <div>
          {text && !advance_refunds?.[data]?.isEditing ? (
            text
          ) : (
            <DatePicker
              value={advance_refunds[data].refund_date ? moment(advance_refunds[data].refund_date, "DD/MM/YYYY") : ""}
              onChange={(e) => {
                let adv_funds = [...advance_refunds];
                if (e) {
                  adv_funds[data].refund_date = e.format("DD/MM/YYYY");
                  setAdvanceFunds(adv_funds);
                } else {
                  adv_funds[data].refund_date = "";
                }
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: t('reference'),
      dataIndex: "reference",
      key: "reference",
      render: (text, _r, data) => (
        <div>
          {text && !advance_refunds?.[data]?.isEditing ? (
            text
          ) : (
            <Input
              value={advance_refunds[data].reference}
              onChange={(e) => {
                let adv_funds = [...advance_refunds];
                adv_funds[data].reference = e.target.value.trimStart();
                setAdvanceFunds(adv_funds);
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: t('Amount'),
      dataIndex: "amount",
      key: "title",
      render: (text, _r, data) => {
        return (
          <div>
            {text && !advance_refunds?.[data]?.isEditing ? (
              <>
                <BaseCurrency /> {text}
                <MinusCircleOutlined className='minusButton inlineItem mtHalf cursor ml2' onClick={() => {
                  let adv_funds = [...advance_refunds];
                  adv_funds.splice(data, 1)
                  setAdvanceFunds(adv_funds.length > 0 ? adv_funds : [{
                    amount: "",
                    refund_date: "",
                    reference: "",
                    isEditing: true
                  }]);
                  formik.setFieldValue("advance_refunds", adv_funds.length > 0 ? adv_funds : [{
                    amount: "",
                    refund_date: "",
                    reference: "",
                    isEditing: true
                  }]);
                }} />
              </>
            ) : (
              <div className="d-flex">
                <Input
                  value={advance_refunds[data].amount}
                  onChange={(e) => {
                    if ((Number.isNaN(Number(e.target.value))|| hasUpToOneDecimal(e.target.value)) && e.target.value !== "") {
                      return;
                    }

                    if (
                      e.target.value?.split?.(".")?.length > 0 &&
                      e.target.value?.split?.(".")?.[1]?.length > 2
                    ) {
                      return;
                    }

                    let adv_funds = [...advance_refunds];
                    adv_funds[data].amount = e.target.value ? (e.target.value) : "";
                    setAdvanceFunds(adv_funds);
                  }}
                />
                <button
                  className="ml1 primary-btn"
                  type="button"
                  disabled={!advance_refunds[data].amount || !advance_refunds[data].reference || !advance_refunds[data].refund_date}
                  onClick={() => {
                    let advfnds = [...advance_refunds]
                    advfnds[data].isEditing = false
                    setAdvanceFunds(advfnds)
                    formik.setFieldValue("advance_refunds", advance_refunds);
                  }}
                >
                  {t('add')}
                </button>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <Modal title={title} visible={open} onCancel={() => {
      handleCancel();
      setAdvanceFunds([
        {
          amount: "",
          refund_date: "",
          reference: "",
        }
      ])
      formik.resetForm();
    }} width={1100}>

      <div className="d-flex">
        <div className="w65 mr2">
          <form className="reimburse-modal-form" onSubmit={formik.handleSubmit}>
            {isPositive ? (
              <>
                <div className="field-row">
                  <label className="required">{t('mode_reimbursement')}</label>
                  <Select
                    options={[
                      { label: "Bank Transfer", value: 0 },
                      { label: "Petty Cash", value: 1 },
                      { label: "Bluerack Wallet", value: 2 },
                      { label: "Undeposited Funds", value: 3 },
                    ]}
                    onChange={(e) => {
                      formik.setFieldValue("mode", e);
                    }}
                    value={formik.values.mode}
                  />
                  {formik.touched.mode && formik.errors.mode && <div className="errorMsg">{formik.errors.mode}</div>}
                </div>
                {formik.values.mode === 2 && (
                  <div className="field-row">
                    <label>{t('wallet_type')}</label>
                    <Select
                      options={[
                        { label: "Reimbursement", value: "reimbursement" },
                      ]}
                      onChange={(e) => {
                        formik.setFieldValue("wallet_type", e);
                      }}
                      value={formik.values.wallet_type}
                    />
                  </div>
                )}
                <div className="field-row">
                  <label className="required">{t('billing_to')}</label>
                  <Select
                    options={[
                      { label: "Company", value: "Company" },
                      { label: "Client", value: "Client" },
                    ]}
                    onChange={(e) => {
                      formik.setFieldValue("billing_to", e);
                    }}
                    value={formik.values.billing_to}
                  />
                  {formik.touched.billing_to && formik.errors.billing_to && <div className="errorMsg">{formik.errors.billing_to}</div>}
                </div>
                <div className="field-row">
                  <label className="required">{t('reimbursed_on')}</label>
                  <DatePicker
                    name="reimbursed_on"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "reimbursed_on",
                        e.format("DD/MM/YYYY")
                      );
                    }}
                    format={"DD/MM/YYYY"}
                    value={
                      formik.values.reimbursed_on
                        ? moment(formik.values.reimbursed_on, "DD/MM/YYYY")
                        : null
                    }
                  />
                  {formik.touched.reimbursed_on && formik.errors.reimbursed_on && <div className="errorMsg">{formik.errors.reimbursed_on}</div>}

                </div>
                <div className="field-row">
                  <label>{t('reference')}#</label>
                  <Input
                    name="reference"
                    onChange={formik.handleChange}
                    value={formik.values.reference}
                  />
                </div>
                <div className="field-row">
                  <label>{t('notes')}</label>
                  <TextArea
                    name="notes"
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                  />
                </div>
              </>
            ) : (
              <div className="negative mb2">
                <label>{t('advance_refunds')}</label>
                <Table
                  rowKey="id"
                  columns={columns}
                  dataSource={formik.values.advance_refunds}
                  pagination={false}
                />
                <p
                  className="cursor primary-text"
                  onClick={() => {
                    setAdvanceFunds([
                      ...advance_refunds,
                      {
                        amount: "",
                        refund_date: "",
                        reference: "",
                        isEditing: true
                      },
                    ]);
                    formik.setFieldValue("advance_refunds", [
                      ...advance_refunds,
                      {
                        amount: "",
                        refund_date: "",
                        reference: "",
                        isEditing: true
                      },
                    ]);
                  }}
                >
                  {t('add_another_field')}
                </p>
                <label>{t('manual_reimbursement')}</label>
                <p>{t('receive_advance')}</p>
                <div className="check-text">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        formik.setFieldValue("carry_forward", false);
                      } else {
                        formik.setFieldValue("carry_forward", "");
                      }
                    }}
                    checked={formik.values.carry_forward === false}
                  />{" "}
                  Yes, Returned Back
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        formik.setFieldValue("carry_forward", true);
                      } else {
                        formik.setFieldValue("carry_forward", "");
                      }
                    }}
                    checked={formik.values.carry_forward === true}
                  />{" "}
                  {t("carry_balance")}
                </div>
                {formik.values.carry_forward === false && (
                  <>
                    <div className="field-row mt2">
                      <label className="required">{t('received_on')}</label>
                      <DatePicker
                        onChange={(e) => {
                          formik.setFieldValue(
                            "reimbursed_on",
                            e.format("DD/MM/YYYY")
                          );
                        }}
                        format={"DD/MM/YYYY"}
                        value={
                          formik.values.reimbursed_on
                            ? moment(formik.values.reimbursed_on, "DD/MM/YYYY")
                            : null
                        }
                      />
                      {formik.touched.reimbursed_on && formik.errors.reimbursed_on && <div className="errorMsg">{formik.errors.reimbursed_on}</div>}
                    </div>
                    <div className="field-row">
                      <label>{('reference')}#</label>
                      <Input
                        name="reference"
                        onChange={formik.handleChange}
                        value={formik.values.reference}
                      />
                    </div>
                    <div className="field-row">
                      <label>{t('notes')}</label>
                      <TextArea
                        name="notes"
                        onChange={formik.handleChange}
                        value={formik.values.notes}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            <div>
              <button
                className="mr1 primary-btn"
                disabled={
                  loading || (formik.values.carry_forward === "" && !isPositive)
                }
              >
                {t('record_reimburse')}
              </button>
              <button
                className="secondary-btn"
                type="button"
                onClick={() => {
                  handleCancel();
                  setAdvanceFunds([
                    {
                      amount: "",
                      refund_date: "",
                      reference: "",
                    }
                  ])
                  formik.resetForm();
                }}
              >
                {t('cancel')}
              </button>
            </div>
          </form>
        </div>
        <div className="w35 m2">
          <div className="summary">
            <label>{t('record_reimburse')}</label>
            <Divider />
            <div className="flex-between" l>
              <span>{t('total_expense_amount')}</span>
              <span><BaseCurrency />  {total_amount}</span>
            </div>
            <div className="flex-between" l>
              <span>{t('non_reimburse_amount')}</span>
              <span><BaseCurrency />  0.00</span>
            </div>
            <div className="flex-between" l>
              <span>{t('advance_amount')}</span>
              <span><BaseCurrency />  {total_advances_amount}</span>
            </div>
            {advance_refunds.reduce(
              (ele, record) => ele + parseFloat(record.amount),
              0
            ) > 0 && (
                <div className="flex-between">
                  <span>{t('total_advance_refunds')}</span>
                  <span>
                    <BaseCurrency /> {" "}
                    {advance_refunds.reduce(
                      (ele, record) => ele + parseFloat(record.amount),
                      0
                    )}
                  </span>
                </div>
              )}
          </div>
          <div className="summary total">
            <label>{t('amount_to_reimburse')}</label>
            <label>
              <BaseCurrency /> {" "}
              {total_amount -
                total_advances_amount +
                (advance_refunds.reduce(
                  (ele, record) => ele + parseFloat(record.amount),
                  0
                )
                  ? advance_refunds.reduce(
                    (ele, record) => ele + parseFloat(record.amount),
                    0
                  )
                  : 0)}
            </label>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReimburseModal;
