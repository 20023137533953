import { ActionTypesFactory } from "../../utils";

export const GetPersonAccounts = ActionTypesFactory(
  "Accounts",
  "GetPersonAccounts"
);
export const GetPayments = ActionTypesFactory("Accounts", "GetAllPayment");
export const GetPurposes = ActionTypesFactory("Accounts", "GetPurposes");
export const GetMasterAccountTxns = ActionTypesFactory(
  "Accounts",
  "GetMasterAccountTxns"
);

export const InitializePayment = ActionTypesFactory(
  "Payments",
  "InitializePayment"
);

export const get_person_accounts = (payload) => {
  return {
    type: GetPersonAccounts.REQUEST,
    payload,
  };
};

export const get_master_account_txns = (payload) => {
  return {
    type: GetMasterAccountTxns.REQUEST,
    payload,
  };
};

export const initialize_payment = (payload) => {
  return {
    type: InitializePayment.REQUEST,
    payload,
  };
};

export const get_purposes = (payload) => {
  return {
    type: GetPurposes.REQUEST,
    payload,
  };
};

export const get_all_payments = (payload) => {
  return {
    type: GetPayments.REQUEST,
    payload,
  };
};
