import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import {AllTemplates, UpdateTemplates, DefaultTemplate,OldTemplate} from "./templates";

function getAllTemplates(req){
    return sagaFunctions(
        AllTemplates,
        "get",
        apis.getAllTemplates,
        req.payload,
        {},
        true
    )();
}

function getUpdateTemplates(req){
    const id = req.payload.id
    const moduleType = req.payload.module_type
    delete req.payload.id
    delete req.payload.module_type
    return sagaFunctions(
        UpdateTemplates,
        "put",
        `${apis.getUpdateTemplates}/${id}?module_type=${moduleType}`,
         req.payload,
        {},
        true
    )();
}

function getDefaultTemplate(req){
    return sagaFunctions(
        DefaultTemplate,
        "get",
        apis.getDefaultTemplate,
        req.payload,
        {},
        true
    )();
}


function getOldTemplate(req){
    const id = req.payload.TemplateID
    const moduleType = req.payload.module_type
    delete req.payload.id
    delete req.payload.module_type
    return sagaFunctions(
        OldTemplate,
        "get",
        `${apis.getOldTemplate}/${id}?module_type=${moduleType}`,
        {},
        {},
        true
    )();
}



export function* templatesWatcher(){
    yield takeLatest(AllTemplates.REQUEST, getAllTemplates);
    yield takeLatest(UpdateTemplates.REQUEST, getUpdateTemplates);
    yield takeLatest(DefaultTemplate.REQUEST, getDefaultTemplate);
    yield takeLatest(OldTemplate.REQUEST, getOldTemplate);

}