import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SideMenu, headerTitles } from '../../actions/masterdata/masterdata';
import Dashboard from '../Dashboard_New/Dashboard';
import CompanyDashboard from './CompanyDashboard';
import "./dashboard.css";
import { useTranslation } from "react-i18next";
import { hasAccess } from '../../utils';
// import MyDashboard from './MyDashboard';




const NewDashboard = () => {
  const [tab, setTab] = useState(1);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const items = [
     {
      key: 1,
      label: t('Company'),
    },
    {
      key: 2,
      label: t('Dashboard'),
    }
  ];

  useEffect(() => {
    dispatch(headerTitles({
      title: 'Dashboard',
      description: 'overviewOfAccounts',
    }))
    // if (!hasAccess("dashboard", "read")) {
    //   setTab(2)
    // }
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/dashboard",
        secondNav: ""
      },
    });
  }, []);

  return (
    <div>
      <ul className='dashboard-tabs table-tabs ant-tabs'>
        {items?.map(ele => <li className={`ant-tabs-tab-btn ${ele.key === tab ? "tab-active" : ""}`} onClick={() => setTab(ele.key)}>{ele.label}</li>)}
      </ul>
      {tab === 1 ? <CompanyDashboard /> : null}
      {tab === 2 ? <Dashboard /> : null}
    </div>
  );
};

export default NewDashboard;
