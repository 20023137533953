import { Button, Input, message, Space } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { apis } from '../../../config/APIs';
import usePayAxios from '../../../config/useAxios';
import { amountFormat } from '../../../utils';
import BaseCurrency from '../../Common/BaseCurrency';
import TableComponent from '../../Common/TableComp/TableComp';
import { formatDate } from '../../Sales/Common';
import { useTranslation } from 'react-i18next';

const ApplyCredit = ({ invoice_bal, data, onClose, onSuccess }) => {
  const { onCall, loading } = usePayAxios({ api: apis.applyCreditDebitNote, method: "post" });
  const {
    register,
    setValue,
    watch,
    handleSubmit
  } = useForm({
    defaultValues: {
      credits: []
    }
  })
  const credits = watch("credits")
  const {t} = useTranslation();
  console.log(credits);
  const columns = [{
    title: t('credit_note'),
    dataIndex: "credit_debit_note_number",
    key: "credit_debit_note_number",
  }, {
    title: t('date'),
    dataIndex: "date",
    render: (text) => <div>{formatDate(text)}</div>,
  },
  {
    title: t('Amount'),
    dataIndex: "total_amount",
    key: "total_amount",
    render: (text) => (
      <div>
        <BaseCurrency />
        {amountFormat(text)}
      </div>
    ),
  },
  {
    title: t('balance_due'),
    dataIndex: 'balance_amount',
    key: 'balance_amount',
    render: (text) => <div><BaseCurrency />
      {amountFormat(text)}
    </div>,
  }, {
    title: t('amount_to_credit'),
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => <div>
      <Input value={credits[index]?.credits_to_use} {...register(`credits.${index}.credits_to_use`, {
        onChange: (e) => {
          let arr = credits;
          if (!/[0-9]/.test(e.target.value) && e.target.value) {
            return;
          }
          if (arr.find(ele => ele.credit_note_id === text)) {
            arr[index].credits_to_use = e.target.value ? parseFloat(e.target.value) : 0
            arr[index].credit_note_id = text
            arr[index].invoice_id = record.invoice_id
          } else {
            arr[index] = {
              credits_to_use: e.target.value ? parseFloat(e.target.value) : 0,
              credit_note_id: text,
              invoice_id: record.invoice_id
            }
          }
          setValue("credits", arr)
        }
      })} />
    </div>,
  }]
  const onSubmit = (data, event) => {
    event.stopPropagation()
    onCall({
      data: data.credits?.filter(ele => ele.invoice_id), params: {
        module_type: "CREDIT"
      }
    }).then(res => {
      console.log({ res });
      message.success(
        <span className="messageText">{"Success"}</span>
      );
      onSuccess && onSuccess()
    }).catch(err => {
      message.error(
        <span className="messageText">{"Failed to apply credit"}</span>
      );
    })
  }
  return (
    <div className='apply-credit'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb2'>
          {t('invoice_balance')} <BaseCurrency /> {amountFormat(invoice_bal)}
        </div>
        <TableComponent columns={columns} data={data} hasCheckbox={false} />
        <div className='flex-end' style={{ width: "97%" }}>
          <div className='sub-total'>
            <div className='item'>
              <span>{t('amount_to_credit')}</span>
              <span><BaseCurrency /> {amountFormat(_.sumBy(credits, "credits_to_use"))}</span>
            </div>
            <div className='item'>
              <span>{t('invoice_balance_due')}</span>
              <span>(-) <BaseCurrency /> {amountFormat(invoice_bal - Number(_.sumBy(credits, "credits_to_use")))}</span>
            </div>
          </div>
        </div>
        <Space className='mt2'>

          <Button
            type="primary"
            htmlType="submit"
            className="pp-main-button"
            disabled={loading}
          >
            <span>{t('apply_credits')}</span>
          </Button>
          <Button
            className="pp-secondary-button"
            style={{ marginLeft: "2rem" }}
            onClick={onClose}
            htmlType="button"
          >
            <span>{t('cancel')}</span>
          </Button>
        </Space>
      </form>
    </div >
  );
};

export default ApplyCredit;