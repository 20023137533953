import { ReducerFunctions } from "../../utils";
import {
  CompanyProfile,
  CompanyProfileImages,
  UpdateCompanyProfile,
  UpdateCompanyProfileImages,
  ReportsApproval,
  UpdateReportsApproval,
  AdvanceApproval,
  UpdateAdvanceApproval,
  TripApproval,
  UpdateTripApproval,
  CompanySetting,
  AddCompanySetting,
  DeleteCompanySetting,
  CompanySettingStatus,
  UpdateCompanyTransaction,
  AddTransactionField,
  GetTransactionField,
  GetSingleTransactionField,
  DeleteTransactionField,
  UpdateTransactionField,
  UpdateCompanyReport,
  AddReportField,
  GetReportField,
  DeleteReportField,
  UpdateReportField,
  UpdateCompanyAdvances,
  AddAdvancesField,
  GetAdvancesField,
  DeleteAdvancesField,
  UpdateAdvancesField,
  AddCostCenterField,
  GetCostCenterField,
  DeleteCostCenterField,
  UpdateCostCenterField,
  EnableDisableCostCenterField,
  AddMerchantsField,
  GetMerchantsField,
  DeleteMerchantsField,
  UpdateMerchantsField,
  EnableDisableMarchentField,
  AddProjectsField,
  GetProjectsField,
  DeleteProjectsField,
  UpdateProjectsField,
  EnableDisableProjectField,
  AddTripsField,
  GetTripsField,
  DeleteTripsField,
  UpdateTripsField,
  UpdateCompanyTrips,
  AddRoles,
  GetRolesPermissions,
  GetRoles,
  GetSingleRoles,
  UpdateRoles,
  AddDesignations,
  DeleteDesignations,
  GetDesignations,
  UpdateDesignations,
  GetSingleDesignations,
  GetUserFields,
  GetUserFieldsColumnTypes,
  GetSingleUserFields,
  AddUserFields,
  UpdateUserFields,
  DeleteUserFields,
  GetCustomFields,
  GetCompanyCountries,
  GetCompanyStates,
  GetCompanyCities,
  DeleteRole,
  BillApproval,
  UpdateBillApproval,
  CompanyCurrency,
  GetReminderAPI,
  CreateReminderAPI,
  CreateCompanyMultipleCurrency,
  GetCompanyMultipleCurrency,
  GetAllCurrency,
  GetAllExchangeRates,
  CreateExchangeRate,
  ToggleExchangeRate,
  GetAllCompanyDetails,
  CreateBranch,
  GetAllBranch,
  GetAllBranchList,
  GetAllBranchHeader,
  GetBranchID,
  SwitchBrach,
  GetCardVenders,
  GetCompanyBaseCurrency,
  SwitchBrachRuby,
  GetAllCountries,
  GetPortalPermissions,
  GetCompanyTimeZones,
} from "./companySettings";

export const companyCountries_reducer = ReducerFunctions(
  GetCompanyCountries,
  {}
);
export const companyTzs_reducer = ReducerFunctions(GetCompanyTimeZones, {});
export const companyCurrency_reducer = ReducerFunctions(CompanyCurrency, {});

export const companyStates_reducer = ReducerFunctions(GetCompanyStates, {});
export const companyCities_reducer = ReducerFunctions(GetCompanyCities, {});

export const companyProfile_reducer = ReducerFunctions(CompanyProfile, {});
export const companyProfile_Images_reducer = ReducerFunctions(
  CompanyProfileImages,
  {}
);
export const updateCompanyProfile_reducer = ReducerFunctions(
  UpdateCompanyProfile,
  {}
);
export const update_companyProfile_imgae_reducer = ReducerFunctions(
  UpdateCompanyProfileImages,
  {}
);

export const reportsApproval_reducer = ReducerFunctions(ReportsApproval, {});
export const updateReportsApproval_reducer = ReducerFunctions(
  UpdateReportsApproval,
  {}
);

export const advanceApproval_reducer = ReducerFunctions(AdvanceApproval, {});
export const updateAdvanceApproval_reducer = ReducerFunctions(
  UpdateAdvanceApproval,
  {}
);

export const tripApproval_reducer = ReducerFunctions(TripApproval, {});
export const updateTripApproval_reducer = ReducerFunctions(
  UpdateTripApproval,
  {}
);

export const companySetting_reducer = ReducerFunctions(CompanySetting, {});
export const companySetting_status_reducer = ReducerFunctions(
  CompanySettingStatus,
  {}
);
export const add_companySetting_reducer = ReducerFunctions(
  AddCompanySetting,
  {}
);
export const delete_companySetting_reducer = ReducerFunctions(
  DeleteCompanySetting,
  {}
);

export const update_companytransaction_reducer = ReducerFunctions(
  UpdateCompanyTransaction,
  {}
);
export const get_transactionField_reducer = ReducerFunctions(
  GetTransactionField,
  {}
);
export const get_single_transactionField_reducer = ReducerFunctions(
  GetSingleTransactionField,
  {}
);
export const add_transactionField_reducer = ReducerFunctions(
  AddTransactionField,
  {}
);
export const delete_transactionField_reducer = ReducerFunctions(
  DeleteTransactionField,
  {}
);
export const update_transactionField_reducer = ReducerFunctions(
  UpdateTransactionField,
  {}
);

export const update_companyreport_reducer = ReducerFunctions(
  UpdateCompanyReport,
  {}
);
export const get_reportField_reducer = ReducerFunctions(GetReportField, {});
export const add_reportField_reducer = ReducerFunctions(AddReportField, {});
export const delete_reportField_reducer = ReducerFunctions(
  DeleteReportField,
  {}
);
export const update_reportField_reducer = ReducerFunctions(
  UpdateReportField,
  {}
);

export const update_advancesreport_reducer = ReducerFunctions(
  UpdateCompanyAdvances,
  {}
);
export const get_advancesField_reducer = ReducerFunctions(GetAdvancesField, {});
export const add_advancesField_reducer = ReducerFunctions(AddAdvancesField, {});
export const delete_advancesField_reducer = ReducerFunctions(
  DeleteAdvancesField,
  {}
);
export const update_advancesField_reducer = ReducerFunctions(
  UpdateAdvancesField,
  {}
);

export const get_tripsField_reducer = ReducerFunctions(GetTripsField, {});
export const add_tripsField_reducer = ReducerFunctions(AddTripsField, {});
export const delete_tripsField_reducer = ReducerFunctions(DeleteTripsField, {});
export const update_tripsField_reducer = ReducerFunctions(UpdateTripsField, {});
export const updateCompanyTrips_reducer = ReducerFunctions(
  UpdateCompanyTrips,
  {}
);

export const get_costCenterField_reducer = ReducerFunctions(
  GetCostCenterField,
  {}
);
export const add_costCenterField_reducer = ReducerFunctions(
  AddCostCenterField,
  {}
);
export const delete_costCenterField_reducer = ReducerFunctions(
  DeleteCostCenterField,
  {}
);
export const update_costCenterField_reducer = ReducerFunctions(
  UpdateCostCenterField,
  {}
);
export const enable_disable_costCenter_reducer = ReducerFunctions(
  EnableDisableCostCenterField,
  {}
);

export const get_merchantsField_reducer = ReducerFunctions(
  GetMerchantsField,
  {}
);
export const add_merchantsField_reducer = ReducerFunctions(
  AddMerchantsField,
  {}
);
export const delete_merchantsField_reducer = ReducerFunctions(
  DeleteMerchantsField,
  {}
);
export const update_merchantsField_reducer = ReducerFunctions(
  UpdateMerchantsField,
  {}
);
export const enable_disable_marchentField_reducer = ReducerFunctions(
  EnableDisableMarchentField,
  {}
);

export const get_projectsField_reducer = ReducerFunctions(GetProjectsField, {});
export const add_projectsField_reducer = ReducerFunctions(AddProjectsField, {});
export const delete_projectsField_reducer = ReducerFunctions(
  DeleteProjectsField,
  {}
);
export const update_projectsField_reducer = ReducerFunctions(
  UpdateProjectsField,
  {}
);
export const enable_disable_projectField_reducer = ReducerFunctions(
  EnableDisableProjectField,
  {}
);
export const get_rolesPermissions_reducer = ReducerFunctions(
  GetRolesPermissions,
  {}
);
export const get_roles_reducer = ReducerFunctions(GetRoles, {});
export const get_singleRoles_reducer = ReducerFunctions(GetSingleRoles, {});
export const add_roles_reducer = ReducerFunctions(AddRoles, {});
export const update_roles_reducer = ReducerFunctions(UpdateRoles, {});

export const get_userFields_reducer = ReducerFunctions(GetUserFields, {});
export const get_userFieldsColumnTypes_reducer = ReducerFunctions(
  GetUserFieldsColumnTypes,
  {}
);
export const get_singleUserFields_reducer = ReducerFunctions(
  GetSingleUserFields,
  {}
);
export const add_userFields_reducer = ReducerFunctions(AddUserFields, {});
export const delete_userFields_reducer = ReducerFunctions(DeleteUserFields, {});
export const update_userFields_reducer = ReducerFunctions(UpdateUserFields, {});

export const get_designations_reducer = ReducerFunctions(GetDesignations, {});
export const add_designations_reducer = ReducerFunctions(AddDesignations, {});
export const delete_role_reducer = ReducerFunctions(DeleteRole, {});
export const bill_approval_reducer = ReducerFunctions(BillApproval, {});
export const update_bill_approval_reducer = ReducerFunctions(
  UpdateBillApproval,
  {}
);
export const delete_designations_reducer = ReducerFunctions(
  DeleteDesignations,
  {}
);
export const update_designations_reducer = ReducerFunctions(
  UpdateDesignations,
  {}
);
export const get_single_designations_reducer = ReducerFunctions(
  GetSingleDesignations,
  {}
);

export const get_custom_fields_reducer = ReducerFunctions(GetCustomFields, {});
export const orgs_reducer = (state = [], action) => {
  switch (action?.type) {
    case "ORG_VALS":
      return [...state, action.payload];

    default:
      return state;
  }
};

export const get_reminder_api_reducer = ReducerFunctions(GetReminderAPI, {});
export const create_reminder_api_reducer = ReducerFunctions(
  CreateReminderAPI,
  {}
);

// multi-currency start

export const get_company_multiple_currency_reducer = ReducerFunctions(
  GetCompanyMultipleCurrency,
  {}
);
export const create_company_multiple_currency_reducer = ReducerFunctions(
  CreateCompanyMultipleCurrency,
  {}
);
export const get_all_currency_reducer = ReducerFunctions(GetAllCurrency, {});
export const get_all_countries_reducer = ReducerFunctions(GetAllCountries, {});
export const get_all_exchange_rates_reducer = ReducerFunctions(
  GetAllExchangeRates,
  {}
);
export const create_exchange_rates_reducer = ReducerFunctions(
  CreateExchangeRate,
  {}
);

export const toggle_exchange_rate_reducer = ReducerFunctions(
  ToggleExchangeRate,
  {}
);

export const get_all_company_details_reducer = ReducerFunctions(
  GetAllCompanyDetails,
  {}
);

export const create_multi_branch_reducer = ReducerFunctions(CreateBranch, {});
export const get_all_branch_reducer = ReducerFunctions(GetAllBranch, {});
export const get_all_branch_list_reducer = ReducerFunctions(
  GetAllBranchList,
  {}
);
export const get_all_branch_header_reducer = ReducerFunctions(
  GetAllBranchHeader,
  {}
);
export const get_branch_id_reducer = ReducerFunctions(GetBranchID, {});
export const switch_brach_reducer = ReducerFunctions(SwitchBrach, {});
export const switch_brach_ruby_reducer = ReducerFunctions(SwitchBrachRuby, {});
export const get_card_venders_reducer = ReducerFunctions(GetCardVenders, {});
export const get_portal_permission_reducer = ReducerFunctions(
  GetPortalPermissions,
  {}
);
export const get_company_base_currency_reducer = ReducerFunctions(
  GetCompanyBaseCurrency,
  {}
);
