import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
function paramsToObject(entries) {
  const result = {}
  for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
const QuickBookHandler = () => {
  const location = useLocation();
  useEffect(() => {
    const redirect = new URLSearchParams(location?.search);
    const entries = redirect.entries();
    const params = paramsToObject(entries);
    if (window && window.opener) {
      window?.opener?.postMessage(params, "*");
      window.close();
    }

  }, []);
  return <div></div>;
};

export default QuickBookHandler;