import React from "react";

import {
  Button,
  Checkbox,
  message,
  Modal,
  Space,
  Table,
  Typography,
} from "antd";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { reportsApproval } from "../../../actions/companySettings";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../assets/icons/delete.png";
import EditIcon from "../../../assets/icons/edit.png";
import { hasAccess, objectToFormData } from "../../../utils";
import PageHeader from "../../PageHeader";
import {
  AddCategories,
  categories,
  categoriesStatus,
  CategoriesStatus,
  categoriesStatusReset,
  deleteCategories,
  DeleteCategories,
} from "./../../../actions/categories";
import AddCategory from "./AddCategory";
const { Title } = Typography;

message.config({
  maxCount: 3,
});

const Categories = ({ hasWriteAccess }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState("center");
  const [tableData, setTableData] = React.useState([]);
  const [tableRowData, setTableRowData] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState('');
  const [has_parent_id, setHas_parent_id] = React.useState('');
  const categoriesResponse = useSelector(state => state.categories.categories?.data?.data || []);
  const total_records = useSelector(state => state.categories.categories?.data?.total_count || 0);
  const categoriesStatusResponse = useSelector(state => state.categories.categoriesStatus || {});
  const deleteCategoriesResponse = useSelector(state => state.categories.deleteCategories || {});
  const addCategoriesResponse = useSelector(state => state.categories.addCategories || {});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = [
    {
      title: t("names"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("account_code"),
      dataIndex: "account_code",
      key: "account_code",
    },
    // {
    //   title: 'Bill Mandatory',
    //   dataIndex: 'is_bill_mandatory',
    //   key: 'is_bill_mandatory',
    //   render: val => val ? 'Yes' : 'No',
    // },
  ];
  const tableAction = [
    {
      title: t("enable"),
      dataIndex: "is_enable",
      key: "is_enable",
      render: (is_enable, record) => (
        <Checkbox
          key={record.id}
          onChange={() =>
            dispatch(
              categoriesStatus(
                ({
                  id: record.id,
                  is_enable: !record.is_enable,
                })
              )
            )
          }
          checked={is_enable}
          disabled={!hasWriteAccess}
        ></Checkbox>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space key={record.id} size='middle'>
          <button
            disabled={!hasWriteAccess}
            onClick={() => showEditModal(record?.id, record?.parent_id)}
          >
            <img src={EditIcon} alt={"logo"} className='action-logo' />
          </button>
          <button
            disabled={!hasWriteAccess}
            onClick={() => showDeleteModal(record.id)}
          >
            <img src={DeleteIcon} alt={"logo"} className='action-logo' />
          </button>
        </Space>
      ),
    },
  ];

  // const onStatusChange = () => {

  // }

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("center");
  };

  const showEditModal = (id, parentId) => {
    setSelectedId(id);
    setHas_parent_id(parentId);
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };

  React.useEffect(() => {
    dispatch(categories());
    setIsModalVisible(false);
    // dispatch(reportsApproval({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(
      headerTitles({
        title: "Categories",
        description: "",
      })
    );
  }, []);
  React.useEffect(() => {
    // if (categoriesResponse.success) {
    if (categoriesResponse.length) {
      const _categories = categoriesResponse.map(function (obj) {
        obj["children"] = obj["category"];
        return obj;
      });
      setIsModalVisible(false);
      setTableData(_categories);
      setTableRowData(_categories.map((value) => value.category.length && value.id).filter(Boolean));
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = categoriesStatusResponse;
    if (status) {
      message.success(<span className='messageText'>{res_message}</span>);
      dispatch(categoriesStatusReset());
      dispatch(categories());
      dispatch({
        type: CategoriesStatus.RESET,
      });
    } else if (status === false && errors) {
      message.error(<span className='messageText'>{errors}</span>);
      dispatch({
        type: CategoriesStatus.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesStatusResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = deleteCategoriesResponse;
    if (status) {
      message.success(<span className='messageText'>{res_message}</span>);
      setIsModalVisible(false);
      dispatch(categories());
      dispatch({
        type: DeleteCategories.RESET,
      });
    } else if (status === false && errors) {
      message.error(<span className='messageText'>{errors}</span>);
      dispatch({
        type: DeleteCategories.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCategoriesResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "",error = "" } = {},
    } = addCategoriesResponse;
    if (error === false) {
      message.success(<span className='messageText'>{res_message}</span>);
      setIsModalVisible(false);
      dispatch(categories());
      dispatch({
        type: AddCategories.RESET,
      });
    } else if (error) {
      message.error(<span className='messageText'>{res_message}</span>);
      dispatch({
        type: AddCategories.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCategoriesResponse]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function handleButtonClick(e) {
    showModal();
  }

  const renderDeleteOption = () => {
    return (
      <div className='d-flex flex-col  gap1'>
        <Text>{t("delete_payment")}</Text>
        <div className='d-flex justify-sb align-items'>
          <Button
            key='1'
            className='formButton mt1'
            onClick={() =>
              dispatch(deleteCategories(objectToFormData({ id: selectedId })))
            }
          >
            {t("delete")}
          </Button>
          <Button
            key='2'
            className='cancelButton ml1 mt1'
            onClick={() => handleCancel()}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <div className="heading-div">
        <PageHeader
          title={t('categories')}
          navigation={{
            Home: "/dashboard/",

            'Categories': null,
          }}
        />
      </div> */}
      {isModalVisible && (
        <Modal
          title={
            modalType === "center" ? (
              <>
                <img src={DeleteIcon} alt={"logo"} className='action-logo' />{" "}
                {t("delete_field")}
              </>
            ) : (
              <Title level={4}>
                {selectedId ? t("edit_category") : t("new_category")} &nbsp;
                <span className='text-small'>{t("enter_details")}</span>
              </Title>
            )
          }
          visible={isModalVisible && hasWriteAccess}
          onCancel={handleCancel}
          footer={[]}
          className={
            modalType === "center"
              ? "center-aligned-modal"
              : "right-alinged-modal"
          }
        >
          {modalType === "center" ? (
            renderDeleteOption()
          ) : (
            <AddCategory
              id={selectedId}
              parentId={has_parent_id}
              handleCancel={handleCancel}
            />
          )}
        </Modal>
      )}
      {
        <Button
          disabled={!hasWriteAccess}
          onClick={handleButtonClick}
          className='mb1 ml1 addNew primaryButton'
        >
          <img src={AddNewIcon} alt={"logo"} className='addNew-logo' />
          {t("addNew")}
        </Button>
      }
      {categoriesResponse?.length ? (
        <Table
          rowKey='id'
          columns={[...columns, ...tableAction]}
          dataSource={tableData}
          className='settingsTable'
          pagination={{
            total: total_records,
            onChange: (pagee) => {
              dispatch(
                categories({
                  page_number: pagee,
                  limit: 10,
                })
              );
            },
          }}
        // defaultExpandedRowKeys={[...tableRowData]}
        />
      ) : (
        <div className='table-placeholder'>
          <div className='empty-normal'>
            <div className='empty-description'>{t("no_data")}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Categories;
