import { OnBoardEnglish } from "../Language/English";
import verifyIcon from "../assets/icons/verify-icon.svg"
import emailIcon from "../assets/icons/email-icon.svg"
import activeIcon from "../assets/icons/active-icon.svg"
import arrowIcon from "../assets/icons/arrow-right-icon.svg"
import "./common.css"
import { useHistory } from "react-router-dom";
import { Steps } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { onboardStep } from "../action/OnBoardForm";
import { useDispatch, useSelector } from "react-redux";

const Verification = () => {
	const Step = Steps.Step;
	const { t } = useTranslation()
	const history = useHistory();
	const dispatch = useDispatch();
	const rejected_reason = useSelector((state) => state?.on_board?.onboardStep?.data?.data?.reject_reason)
	const status = useSelector((state) => state?.on_board?.onboardStep?.data?.data?.kyc_status)

	useEffect(() => {
		dispatch(onboardStep({ country: localStorage.getItem("country") }))
	}, [])

	const getStep = () => {
		if (status === "kyc_approved") {
			return 3
		} else if (rejected_reason && status === "kyc_rejected") {
			return 3
		} else if (status === "kyc_submitted") {
			return 2
		}
		return 0;
	}
	return (
		<>
			<div className="verify-section d-flex">
				<div className="img-section w15 v-center">
					<img src={verifyIcon} alt="verification-icon" />
				</div>
				<div className="card-section d-flex flex-col w85">
					<div className="header-section">
						<h4 className="header-text">{OnBoardEnglish?.dashboardHeader}</h4>
						<span className="sub-text">{OnBoardEnglish?.dashboardSubHeader}</span>
					</div>
					<div className="email-active-wraper v-center">
						<div className="email-section w60 v-center pb0 pt0">
							<Steps

								type="navigation"
								current={getStep()}
								items={[
									{
										title: 'KYC Pending',
										// description: 'This is a description.',
									},
									{
										title: 'KYC Submited',
										// description: 'This is a description.',
									},
									{
										title: 'KYC Approval InProgress',
										// description: 'This is a description.',
									},
									{
										title: rejected_reason && status === "kyc_rejected" ? 'KYC Rejected' : 'KYC Completed',
										status: status === "kyc_approved" ? "finish" : rejected_reason && status === "kyc_rejected" ? "error" : "wait"
										// description: 'This is a description.',
									},
								]}
							/>
						</div>
						<div className="active-section w40 v-center">
							<div className="icon w10">
								<img src={activeIcon} alt="active-icon" />
							</div>
							<div className="content w70">
								<h4 className="content-header">{OnBoardEnglish?.activeHeader}</h4>
								<span className="content-sub-header">{OnBoardEnglish?.activeSubHeader}</span>
							</div>
							<div className="arrow-section flex-end w20">
								<img src={arrowIcon} alt="arrow-icon" onClick={() => {
									history.push("/onboard/kyc", {
										to_preview: true
									})
								}} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Verification;