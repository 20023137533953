import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { objectToFormData } from "../../utils";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import { amountFormat } from "../../utils";
import Table from "../Common/Table/Table";
import { transactions } from "./../../actions/cards/cards";
import PageHeader from "./../PageHeader";
import { walletText } from "./CardDetails";
// import data from "./cardTxn.json"
import BaseCurrency from "../Common/BaseCurrency";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { useTranslation } from "react-i18next";
// import Base from "antd/lib/typography/Base";

const CardTransactions = ({ user }) => {
  // const history = useHistory();
  const [transData, setTransData] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(1);
  const { t } = useTranslation()


  const transactionsResponse = useSelector(
    (state) => state.cards.transactions || {}
  );
  const dispatch = useDispatch();

  const columns = [
    {
      title: t('trans_id'),
      dataIndex: "vendor_transaction_id",
      key: "vendor_transaction_id",
    },

    {
      title: t('card_ref_no'),
      dataIndex: "card_reference_number",
      key: "card_reference_number",
    },
    !user ? {
      title: t('employee_name'),
      dataIndex: "employee_name",
      key: "employee_name",
      width: 250,
    } : {},
    {
      title: t('transaction_date'),
      dataIndex: "transaction_date",
      key: "transaction_date",
    },
    {
      title: t('merchant'),
      dataIndex: "merchant",
      key: "merchant",
    },
    {
      title: t('wallet_name'),
      dataIndex: "wallet_name",
      key: "wallet_name",
      render: (text) => <div>{walletText[text]}</div>,
    },
    {
      title: t('Amount'),
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <div>
          <img
            src={record?.transaction_type === "credit" ? credit : debit}
            width={10}
            alt="credit"
          />{" "}
          <BaseCurrency /> {amountFormat(text)}
        </div>
      ),
    },
    // {
    //   title: "Wallet",
    //   dataIndex: "title",
    //   key: "title",
    //   render: (text) => <div>Expense</div>,
    // },
    // {
    //   title: "ChannelType",
    //   dataIndex: "channelType",
    //   key: "channelType",
    //   // render: (text) => <div>Verified</div>,
    // },
    // {
    //   title: "Transaction Nature",
    //   dataIndex: "channelType",
    //   key: "channelType",
    //   render: (text, record) => <div>{record?.data?.txnType}</div>,

    // },
    // {
    //   title: "Type",
    //   dataIndex: "transaction_type",
    //   key: "transaction_type",
    // },

    // {
    //   title: "Terminal ID",
    //   dataIndex: "terminalId",
    //   key: "terminalId",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text) => <div className={`status ${text}`}>{text}</div>,
    // },
  ];

  React.useEffect(() => {
    dispatch(transactions({ card_only: true, source: "admin" }));
    dispatch(headerTitles({
      title: 'transactions',
      description: ""
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (value) => {
    dispatch(transactions({ card_only: true, search_key: value }));
    setFilters({
      ...filters,
      search_key: value,
      source: "admin"
    });
    setPage(1)

  };

  React.useEffect(() => {
    const {
      data: { response: { transactions = [] } = {}, status = false } = {},
    } = transactionsResponse;
    if (status) {
      setTransData(transactions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsResponse]);

  // const handleRowClick = (evt, record) => {
  //   evt.preventDefault();
  //   if (record) {
  //     history.push({
  //       pathname: `/card-details/${record?.card_id}`,
  //       state: { cardData: { id: record?.card_id } },
  //     });
  //   }
  // };

  return (
    <>
      <Table
        rowKey="id"
        className="tableStyled"
        columns={columns}
        data={transData}
        placeholder={t('search_by_transaction_id')}
        pagination={{

          total: transactionsResponse?.data?.total_records || 0,
          current: page,

          onChange: (pagee) => {
            dispatch(
              transactions({ card_only: true, page: pagee, ...filters, source: "admin" })
            );
            setPage(pagee);
          }
        }}
        dotMenu={[
          "employee_name",
          "transaction_date",
          "merchant_name",
          "amount",
        ]}
        handleSort={(key) => {
          dispatch(transactions({ card_only: true, sort_key: key, source: "admin" }));
          setFilters({
            ...filters,
            sort_key: key,
          });
          setPage(1)

        }}
        isTopFilter={true}
        filter={true}
        // handleRowClick={handleRowClick}
        handleSearch={handleSearch}
      // scroll={{ x: 1800 }}
      />
    </>
  );
};

export default CardTransactions;
