import { ReducerFunctions } from '../../utils';
import { CompanyAccounts, PrimaryAccounts, BeneficiaryAccount, MasterPoolAccount, AllBudgets, CreateBudgets, BudgetsBack, MasterAccountHistory, InitiatePayout, PayoutTxns} from './accounts';

export const primary_account_reducer = ReducerFunctions(PrimaryAccounts, {});
export const company_accounts_reducer = ReducerFunctions(CompanyAccounts, {});
export const beneficiary_accounts_reducer = ReducerFunctions(BeneficiaryAccount, {});
export const master_pool_account_reducer = ReducerFunctions(MasterPoolAccount, {});
export const all_budgets_reducer = ReducerFunctions(AllBudgets, {});
export const create_budgets_reducer = ReducerFunctions(CreateBudgets, {});
export const budgets_back_reducer = ReducerFunctions(BudgetsBack, {});
export const master_account_history_reducer = ReducerFunctions(MasterAccountHistory, {});
export const initiate_payout_reducer = ReducerFunctions(InitiatePayout, {});
export const payout_txns_reducer = ReducerFunctions(PayoutTxns, {});