import { ActionTypesFactory } from '../../utils';

export const Customers = ActionTypesFactory('Customers', 'Customers');
export const CreateCustomer = ActionTypesFactory('Customers', 'CreateCustomer');
export const ViewCustomer = ActionTypesFactory('Customers', 'ViewCustomer');
export const CreateInvoice = ActionTypesFactory('Customers', 'CreateInvoice');
export const Invoices = ActionTypesFactory('Customers', 'Invoices');
export const SubmitInvoice = ActionTypesFactory("SubmitInvoice, SubmitInvoice")
export const UploadProfile = ActionTypesFactory("UploadProfile", "UploadProfile")
export const ShowProfile = ActionTypesFactory("ShowProfile", "ShowProfile")
export const PortalTableData = ActionTypesFactory("PortalTableData", "PortalTableData")
export const PortalRecordDetails = ActionTypesFactory("PortalRecordDetails", "PortalRecordDetails")
export const PortalDashboard = ActionTypesFactory("PortalDashboard", "PortalDashboard")

export const VendorPortalTableData = ActionTypesFactory("VendorPortalTableData", "VendorPortalTableData")
export const VendorPortalRecordDetails = ActionTypesFactory("VendorPortalRecordDetails", "VendorPortalRecordDetails")
export const PortalDashboardCumpanyList = ActionTypesFactory("PortalDashboardCumpanyList", "PortalDashboardCumpanyList")

export const customers = (payload) => {
  return {
    type: Customers.REQUEST,
    payload,
  };
};

export const view_customer = (payload) => {
  return {
    type: ViewCustomer.REQUEST,
    payload,
  };
};

export const invoices = (payload) => {
  return {
    type: Invoices.REQUEST,
    payload,
  };
};

export const create_customers = (payload) => {
  return {
    type: CreateCustomer.REQUEST,
    payload,
  };
};

export const create_invoice = (payload) => {
  return {
    type: CreateInvoice.REQUEST,
    payload,
  };
};

export const submit_invoice = (payload) => {
  return {
    type: SubmitInvoice.REQUEST,
    payload,
  }
}

export const upload_profile = (payload) => {
  return {
    type: UploadProfile.REQUEST,
    payload,
  }
}
export const show_profile = (payload) => {
  return {
    type: ShowProfile.REQUEST,
    payload,
  }
}

// customer Portal 

export const portalTableData = (payload) => {
  return {
    type: PortalTableData.REQUEST,
    payload,
  }
}
export const portalRecordDetails = (payload) => {
  return {
    type: PortalRecordDetails.REQUEST,
    payload,
  }
}

export const portalDashboard = (payload) => {
  return {
    type: PortalDashboard.REQUEST,
    payload,
  }
}

export const portalDashboardCumpanyList = (payload) => {
  return {
    type: PortalDashboardCumpanyList.REQUEST,
    payload,
  }
}