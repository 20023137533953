import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions, sagaFunctionsSettings } from "../../utils";
import { Categories, AddCategories, DeleteCategories, CategoriesStatus, AccountTxns } from "./categories";

function getCategories(req) {
  return sagaFunctionsSettings(Categories, "get", apis.categories, req.payload, {
    "Authorization": localStorage.getItem("token"),
  })();
}

function accountTxns(req) {
  return sagaFunctions(AccountTxns, "get", apis.accountTxns, req.payload, {
    "Authorization": localStorage.getItem("token"),
  })();
}

function updateCategoriesStatus(req) {
  return sagaFunctionsSettings(CategoriesStatus, "post", apis.categoriesStatus, req.payload, {
    "Authorization": localStorage.getItem("token"),
  })();
}

function addCategories(req) {
  return sagaFunctionsSettings(AddCategories, "post", req?.payload?.id ? apis.updateCategory : apis.createCategory, req.payload)();
}

function deleteCategories(req) {
  return sagaFunctionsSettings(DeleteCategories, "post", apis.deleteCategories, req.payload, {
    "Content-Type": "multipart/formdata",
    "Authorization": localStorage.getItem("token"),
  })();
}

export function* categoriesWatcher() {
  yield takeLatest(Categories.REQUEST, getCategories);
  yield takeLatest(CategoriesStatus.REQUEST, updateCategoriesStatus);
  yield takeLatest(AddCategories.REQUEST, addCategories);
  yield takeLatest(DeleteCategories.REQUEST, deleteCategories);
  yield takeLatest(AccountTxns.REQUEST, accountTxns);
}
