import { ActionTypesFactory } from "../../utils";

export const Cards = ActionTypesFactory("Cards", "Cards");
export const CardTxnType = ActionTypesFactory("Cards", "CardTxnType");
export const CardLimitEnable = ActionTypesFactory("Cards", "CardLimitEnable");
export const UserCards = ActionTypesFactory("UserCards", "Cards");
export const ShowCardPolicy = ActionTypesFactory("ShowCardPolicy", "Cards");
export const SetCardPolicy = ActionTypesFactory("SetCardPolicy", "Cards");
export const CardPolicies = ActionTypesFactory("CardPolicies", "Cards");
export const CardPoliciesDelete = ActionTypesFactory("CardPolicies", "Delete");
export const CardLimitSet = ActionTypesFactory("CardLimitSet", "Cards");
export const UpdateCardLimitSet = ActionTypesFactory("UpdateCardLimitSet", "Cards");
export const GetCardLimit = ActionTypesFactory("GetCardLimit", "Cards");
export const GetCardPolicies = ActionTypesFactory("GetCardPolicies", "Cards");
export const CardsDetails = ActionTypesFactory("CardsDetails", "Cards");
export const Transactions = ActionTypesFactory("Transactions", "Cards");
export const Topup = ActionTypesFactory("Topup", "Cards");
export const Block = ActionTypesFactory("Block", "Cards");
export const DownloadBulk = ActionTypesFactory("DownloadBulk", "Cards");
export const UploadBulk = ActionTypesFactory("UploadBulk", "Cards");
export const Replacement = ActionTypesFactory("Replacement", "Cards");
export const CorporateBudget = ActionTypesFactory("CorporateBudget", "Cards");
export const TransferLogs = ActionTypesFactory("TransferLogs", "Transfer");
export const QucikLogs = ActionTypesFactory("QucikLogs", "Transfer");
export const AnalyticsCards = ActionTypesFactory("Analytics", "Cards");
export const AnalyticsCardsAll = ActionTypesFactory("AnalyticsAll", "CardsAll");
export const SetPin = ActionTypesFactory("SetPin", "Cards");
export const SendOTP = ActionTypesFactory("SendOTP", "Cards");
export const VerifyOTP = ActionTypesFactory("VerifyOTP", "Cards");
export const ActivateCard = ActionTypesFactory("ActivateCard", "Cards");
export const UpdateMobileNumber = ActionTypesFactory("UpdateMobileNumber", "Cards");
export const RemindCardActivation = ActionTypesFactory("RemindCardActivation", "Cards");
export const BlockReasons = ActionTypesFactory("BlockReasons", "Cards");
export const AnalyticsCardsExport = ActionTypesFactory(
  "Analytics",
  "CardsExport"
);

export const AnalyticsEstimates = ActionTypesFactory("AnalyticsEstimates", "AnalyticsEstimates");

////// RootiFi Integration ////////
export const CreateInviteLink = ActionTypesFactory("CreateInviteLink", "CreateInviteLink");
export const GetOneCompany = ActionTypesFactory("GetOneCompany", "GetOneCompany");
export const DisconnectCompany = ActionTypesFactory("DisconnectCompany", "DisconnectCompany");
export const GetAllOrganisations = ActionTypesFactory("GetAllOrganisations", "GetAllOrganisations");
export const SyncData = ActionTypesFactory("SyncData", "SyncData");
export const GetSyncHistory = ActionTypesFactory("GetSyncHistory", "GetSyncHistory");




export const cards = (payload) => {
  return {
    type: Cards.REQUEST,
    payload,
  };
};

export const card_block_reasons = (payload) => {
  return {
    type: BlockReasons.REQUEST,
    payload
  };
};

export const remind_card_activation = (payload) => {
  return {
    type: RemindCardActivation.REQUEST,
    payload,
  };
};


export const send_otp = (payload) => {
  return {
    type: SendOTP.REQUEST,
    payload,
  };
};

export const activate_card = (payload) => {
  return {
    type: ActivateCard.REQUEST,
    payload,
  };
};

export const update_mobile_number = (payload) => {
  return {
    type: UpdateMobileNumber.REQUEST,
    payload,
  };
};

export const verify_otp = (payload) => {
  return {
    type: VerifyOTP.REQUEST,
    payload,
  };
};


export const set_pin = (payload) => {
  return {
    type: SetPin.REQUEST,
    payload,
  };
};

export const show_card_policy = (payload) => {
  return {
    type: ShowCardPolicy.REQUEST,
    payload,
  };
};

export const set_card_policy = (payload) => {
  return {
    type: SetCardPolicy.REQUEST,
    payload,
  };
};

export const card_policy_delete = (payload) => {
  return {
    type: CardPoliciesDelete.REQUEST,
    payload,
  };
};

export const quick_logs = (payload) => {
  return {
    type: QucikLogs.REQUEST,
    payload,
  };
};

export const get_card_limit = (payload) => {
  return {
    type: GetCardLimit.REQUEST,
    payload,
  };
};

export const get_card_policies = (payload) => {
  return {
    type: GetCardPolicies.REQUEST,
    payload,
  };
};

export const cardPolicies = (payload) => {
  return {
    type: CardPolicies.REQUEST,
    payload
  };
};
export const cardSetPolicies = (payload) => {
  return {
    type: CardLimitSet.REQUEST,
    payload,
  };
};
export const cardupdateSetPolicies = (payload) => {
  return {
    type: UpdateCardLimitSet.REQUEST,
    payload,
  };
};

export const analytics_cards = (payload) => {
  return {
    type: AnalyticsCards.REQUEST,
    payload,
  };
};
export const analytics_cards_all = (payload) => {
  return {
    type: AnalyticsCardsAll.REQUEST,
    payload,
  };
};

export const analyticsEstimates = (payload) => {
  return {
    type: AnalyticsEstimates.REQUEST,
    payload,
  }
}

export const analytics_cards_export = (payload) => {
  return {
    type: AnalyticsCardsExport.REQUEST,
    payload,
  };
};

export const getTdransferLogs = () => {
  return {
    type: TransferLogs.REQUEST,
  };
};

export const userCards = (payload) => {
  return {
    type: UserCards.REQUEST,
    payload,
  };
};

export const cardsDetails = (payload) => {
  return {
    type: CardsDetails.REQUEST,
    payload,
  };
};

export const transactions = (payload) => {
  return {
    type: Transactions.REQUEST,
    payload,
  };
};

export const topup = (payload) => {
  return {
    type: Topup.REQUEST,
    payload,
  };
};

export const block_card = (payload) => {
  return {
    type: Block.REQUEST,
    payload,
  };
};

export const downloadBulk = (payload) => {
  return {
    type: DownloadBulk.REQUEST,
    payload,
  };
};

export const uploadBulk = (payload) => {
  return {
    type: UploadBulk.REQUEST,
    payload,
  };
};

export const replacement = (payload) => {
  return {
    type: Replacement.REQUEST,
    payload,
  };
};

export const corporateBudget = (payload) => {
  return {
    type: CorporateBudget.REQUEST,
    payload,
  };
};

export const cardEnalbeLimit = (payload) => {
  return {
    type: CardLimitEnable.REQUEST,
    payload,
  };
};

export const cardTxnLimit = (payload) => {
  return {
    type: CorporateBudget.REQUEST,
    payload,
  };
};


///// Rootifi //////
export const createInviteLink = (payload) => {
  return {
    type: CreateInviteLink.REQUEST,
    payload,
  }
}

export const getOneCompany = (payload) => {
  return {
    type: GetOneCompany.REQUEST,
    payload,
  }
}

export const getAllOrganisations = (payload) => {
  return {
    type: GetAllOrganisations.REQUEST,
    payload,
  }
}

export const syncData = (payload) => {
  return {
    type: SyncData.REQUEST,
    payload,
  }
}

export const disconnectCompany = (payload) => {
  return {
    type: DisconnectCompany.REQUEST,
    payload,
  }
}

export const getSyncHistory = (payload) => {
  return {
    type: GetSyncHistory.REQUEST,
    payload,
  }
}


