import TableWrap from "../Common/Table/Table";
import React from "react";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { SideMenu } from "../../actions/masterdata/masterdata";
import { useDispatch } from "react-redux";
const AdvReport = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const columns = [
    {
      title: t("report_id"),
      dataIndex: "report_number",
      key: "report_number",
    },
    {
      title: t("from_date"),
      dataIndex: "from_date",
      key: "from_date",
      render: (text) => (
        <div>{moment(text).format("DD MMM YYYY HH:mm:ss")}</div>
      ),
    },
    {
      title: t("to_date"),
      dataIndex: "to_date",
      key: "to_date",
      render: (text) => (
        <div>{moment(text).format("DD MMM YYYY HH:mm:ss")}</div>
      ),
    },
    {
      title: "Recorded By",
      dataIndex: "created_by",
      key: "created_by",
      render: (text) => (<div>{text.name}</div>),
    },

    {
      title: t("Amount"),
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text) => (
        <div>
          <BaseCurrency /> {amountFormat(text)}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <div className={`status status_${text}`}>{text.replace(/_/g, " ")}</div>
      ),
    },
  ];

  return (
    <div className="expenses-table">
      <TableWrap
        pagination={false}
        columns={columns}
        data={[props.repData]}
        handleRowClick={(evt, record) => {
          if (record) {
            history.push({
              pathname: `/expenses/report-details/${record.id}`,
              state: { reportData: record, user: props?.user },

            });
          }
        }}
      />
    </div>
  );
};

export default AdvReport;
