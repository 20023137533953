import TableWrap from "../Common/Table/Table";
import { message, Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { reports, userReportLinkAdv, userReports } from "../../actions/reports/reports";
import { objectToFormData } from "../../utils";
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

message.config({
  maxCount: 3,
});

const LinkAdvsToReport = (props) => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = React.useState([]);
  const [reportsData, setReportsData] = React.useState([]);
  const reportsResponse = useSelector(state => state.reports.reports || {});
const {t} = useTranslation()
  React.useEffect(() => {
    dispatch(reports({module_type:"REPORT",filters:{status: ['unsubmitted']} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const { data: { data:  reports = [] , status = false } = {} } = reportsResponse;
    if (status) {
      setReportsData(reports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsResponse]);

  console.log()

  const columns = [
    {
      title: t('report_id'),
      dataIndex: "report_number",
      key: "report_number",
    },
    {
      title: t('title'),
      dataIndex: "title",
      key: "title",
      // render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    },
    {
      title: t('dateAndTime'),
      dataIndex: "created_at",
      key: "created_at",
      render : (text) => <div>{moment(text).format("DD MMM YYYY HH:mm:ss")}</div>
    }
  ];

  const rowSelection = {
    selectedRowKeys: selectedData,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedData(selectedRowKeys);
    }
  };

  const handleClearList = () => {
    setSelectedData([]);
  }

  const handleAddExp = () => {
    const payload = {};
    payload.advance_ids = [props.adv_id];
    payload.report_id = selectedData[0];
    dispatch(userReportLinkAdv(({ ...payload })));
  }

  return (
    <div className="expenses-table">
      {selectedData.length ? (
        <>
          <Button
            type="default"
            ghost
            className="approve-add-btn mb1"
            onClick={() => handleClearList()}
          >
            Clear Selection
          </Button>
          <Button
            onClick={() => handleAddExp()}
            className="floatRight ml1 primaryButton"
          >
            Add to {selectedData.length > 1 ? selectedData.length : ""} Report
          </Button>
        </>
      ) : null}
      <TableWrap
        rowKey="id"
        columns={columns}
        data={reportsData.slice(0, 9)}
        className="tableStyled"
        handleSearch={true}
        pagination={{
          total: reportsResponse?.data?.total_count || 0,
          onChange: (pagee) => {
            dispatch(
              reports({
                module_type: "REPORT",
                filters: { status: ["unsubmitted"] },
                params: { page_number: pagee },
              })
            );
          },
        }}
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
      />
    </div>
  );
};

export default LinkAdvsToReport;
