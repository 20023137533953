import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Space, Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createBillingDetail, CreateBillingDetail } from '../../../actions/bills';
import { vendorsByName } from '../../../actions/purchases';
import { dummyPDF } from '../../Common/DummyPDF';
import { FormInput, FormSelect } from '../../inputs';
import DateInput from '../../inputs/DateInput';
import UploadField from '../../inputs/UploadField';
import { useTranslation } from 'react-i18next';

const items = [
  {
    key: '1',
    label: `Create Bill`
  }
];

const NewFileBill = () => {

  const history = useHistory();
  const dispatch = useDispatch();
const {t} = useTranslation();
  const [listFiles, setListFiles] = useState([]);
  const [listVendors, setListVendors] = useState([]);
  const [vendorsOptions, setVendorsOptions] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({
    name: '',
    email: '',
    address: ''
  });

  const { control, register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm({
    defaultValues: {
      vendor_info: '',
      vendor_name: '',
      vendor_mail: '',
      vendor_address: '',
      billing_date: '',
      billing_no: '',
      purchase_order: '',
      dueDateBill: '',
      ref_purchase_order_no: '',
      payment_terms: '',
      tax: '',
      total_amount: '',
      amountCoin: '',
      gl_category: '',
      gl_code: '',
      transaction_ref_details: '',
    },
    shouldUseNativeValidation: false,
  });

  const saveResponse = useSelector((state) => state.bills.createBillingDetail || {});
  useEffect(() => {
    if (saveResponse?.data?.error) {
      message.error(<span className='messageText'>{saveResponse?.data?.message}</span>)
      dispatch({
        type: CreateBillingDetail.RESET
      })
    }

    if (saveResponse?.data?.error === false) {
      message.success(<span className='messageText'>{"Bill Created"}</span>)
      dispatch({
        type: CreateBillingDetail.RESET
      })
      history.push("/purchases/bills");
    }
  }, [saveResponse]);

  const vendorsByNameData = useSelector((state) => state.purchases.vendorsByName || {});
  useMemo(() => {
    if (vendorsByNameData.success && !vendorsByNameData.data?.error) {
      setListVendors(vendorsByNameData.data.data);
      setVendorsOptions(
        vendorsByNameData.data?.data?.map((item) => ({
          value: item.beneficiary_name,
          label: item.beneficiary_name,
        }))
      );
    }
  }, [vendorsByNameData]);

  const vendorInfoWatch = watch('vendor_info');
  useEffect(() => {
    if (vendorInfoWatch) {
      let indexVendor = listVendors.findIndex((vendorInfo) => vendorInfo.beneficiary_name === vendorInfoWatch);
      if (indexVendor !== -1) {
        let vendorData = listVendors[indexVendor];
        setValue('beneficiary_id', vendorData.id);
        setValue('vendor_name', vendorData.beneficiary_name);
        setSelectedVendor({
          name: vendorData.beneficiary_name,
          email: vendorData.email,
          address: vendorData.primary_contact,
        })
      }
    }
  }, [vendorInfoWatch]);

  const onCloseDrawer = useCallback(() => {
    reset?.();
  }, [reset]);

  const onVendorSearch = useCallback(
    (e) => {
      if (e && e.trim() !== '') {
        dispatch(vendorsByName({ name: e.trim() }));
      }
    },
    dispatch,
    vendorsByName
  );

  const onSubmit = async (data) => {
    let payload = {
      beneficiary_id: "880b45d1-94e6-4e38-a27a-1447f96b5a04",
      vendor_name: "Sunil",
      billing_date: "20-01-2023",
      billing_no: "BI37",
      ref_purchase_order_no: "TY56",
      payment_terms: 1,
      place_of_supply: "Jammu",
      gl_category: "service",
      gl_code: "1",
      payment_status: "pending",
      transaction_ref_details: "",
      total_amount: 340,
      total_tax_amount: 45,
      total_discount: 5,
      total_payable_amount: 368,
      tds_type: 1,
      tds_amount: 50,
      vendor_billing_items: [
        {
          item_name: "Notepad",
          item_type: 1,
          hsn_code: "NP-2",
          unit_price: 50,
          quantity: 6,
          tax_type: "",
          discount_percentage: 5,
          tax_amount: 45,
          total_amount: 300
        }
      ]
    }

    dispatch(createBillingDetail(payload));
  };

  return (
    <>
      <Row wrap={false} style={{ flexGrow: 1, display: 'flex' }}>
        <Col flex="auto" style={{ width: '100%', display: 'flex', flexGrow: '1' }}>
          <div style={{ flexGrow: 1 }}>
            <div style={{
              backgroundColor: '#FFFFFF',
              border: '1px solid #DBDBDB'
            }}>
              <Row>
                <Col span={24} style={{ marginBottom: '80px' }}>
                  <div className='header-bill-panel'>
                    <Tabs defaultActiveKey="1" items={items} id='create-file-tab' />

                    <button
                      onClick={() => history.push("/purchases/bills")}
                      style={{ fontSize: '28px' }}
                    >
                      <CloseCircleOutlined />
                    </button>
                  </div>
                </Col>

                <Col span={12}>
                  <object
                    data={`${dummyPDF}`}
                    style={{
                      margin: '0px 10px',
                      height: '100vh',
                      width: '96%',
                    }}
                  />
                </Col>

                <Col span={12} style={{ paddingRight: '40px' }} >
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        <div className="p1">
                          <div className="pp-form-item">
                            <FormSelect
                              inline
                              control={control}
                              {...register('vendor_info', { required: true })}
                              required
                              errors={errors}
                              label="Vendor Info"
                              placeholder={t('enter_vendor_name')}
                              onSearch={onVendorSearch}
                              options={vendorsOptions}
                            />
                          </div>

                          {selectedVendor.name &&
                            <div style={{ backgroundColor: '#F5F7FA', padding: '30px 27px', marginTop: '20px', fontSize: '17px' }}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ fontWeight: '600', fontSize: '17px', lineHeight: '26px', color: '#000000' }}>
                                  {t('vendor_details')}
                                </span>

                                {/* <a style={{ color: '#ED3B41', fontSize: '17px' }}>Edit</a> */}
                              </div>

                              <Col style={{ paddingTop: '10px' }}>
                                <div>
                                  {t('name')} - {selectedVendor.name}
                                  <br />
                                  {t('email')} - {selectedVendor.email}
                                  <br />
                                  {t('address')} - {selectedVendor.address}
                                </div>
                              </Col>
                            </div>
                          }

                          <div style={{ marginTop: '40px' }}>
                            <span className="info">{t('inv_inf')}</span>

                            <div className="pp-form-item">
                              <DateInput
                                control={control}
                                {...register('billing_date', { required: true })}
                                errors={errors}
                                required
                                label={t('invoice_date')}
                              />
                            </div>

                            <div className="pp-form-item">
                              <FormInput
                                control={control}
                                {...register('billing_no')}
                                errors={errors}
                                label={t('invoice_hashh')}
                              />
                            </div>

                            <div className="pp-form-item">
                              <FormInput
                                control={control}
                                {...register('purchase_order')}
                                errors={errors}
                                label={t('order_purchase')}
                              />
                            </div>

                            <div className="pp-form-item">
                              <DateInput
                                control={control}
                                {...register('dueDateBill')}
                                errors={errors}
                                label="Purchase Due Date"
                              />
                            </div>

                            <div className="pp-form-item">
                              <FormSelect
                                inline
                                control={control}
                                {...register('payment_terms')}
                                errors={errors}
                                label={t('payment_term')}
                                placeholder={t('select')}
                              />
                            </div>

                            <div className="pp-form-item">
                              <FormSelect
                                inline
                                control={control}
                                {...register('tax')}
                                errors={errors}
                                label={t('tax')}
                                placeholder={t('select')}
                              />
                            </div>

                            <div className="pp-form-item">
                              <Row align={'bottom'} justify={'space-between'} gutter={16}>
                                <Col span={6}>
                                  <FormSelect
                                    inline
                                    control={control}
                                    {...register('amountCoin')}
                                    errors={errors}
                                    required
                                    label={t('Amount')}
                                  />
                                </Col>

                                <Col span={18}>
                                  <FormInput
                                    control={control}
                                    {...register('total_amount')}
                                    errors={errors}
                                    label=""
                                    placeholder={t('enter_amount')}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>

                          <div style={{ marginTop: '40px' }}>
                            <span className="info">{t('general_ledger')}</span>

                            <div className="pp-form-item">
                              <FormInput
                                control={control}
                                {...register('gl_category')}
                                errors={errors}
                                label={t('category')}
                              />
                            </div>

                            <div className="pp-form-item">
                              <FormInput
                                control={control}
                                {...register('gl_code')}
                                errors={errors}
                                label={t('gl_code')}
                              />
                            </div>

                            <div className="pp-form-item">
                              <FormInput
                                control={control}
                                {...register('transaction_ref_details')}
                                errors={errors}
                                label={t('customer_notes')}
                              />
                            </div>
                          </div>

                          <div style={{ marginTop: '40px' }}>
                            <UploadField
                              title="Attach File(s) to invoices"
                              desc="(Material/Delivery Receipt/Purchase Order)"
                              subDescripcion={'You can upload a maximum of 5 files, 5MB each'}
                              acceptType={'.jpg,.pdf,.png'}
                              onSelected={(fileInfo) => {
                                let listAux = [...listFiles];
                                listAux.push(fileInfo);
                                setListFiles(listAux);
                              }}
                            />

                            <Space
                              direction='vertical'
                              size={'middle'}
                              style={{ marginTop: '10px', width: '100%' }}
                            >
                              {listFiles.map((fileInfo, index) => (
                                <div className='file-container'>
                                  <span className="file-desc">
                                    {fileInfo.name}
                                  </span>

                                  <button
                                    className='file-delete-btn'
                                    type='button'
                                    onClick={() => {
                                      let listAux = [...listFiles];
                                      listAux.splice(index, 1);
                                      setListFiles(listAux);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </button>
                                </div>
                              ))}
                            </Space>
                          </div>
                        </div>
                      </div>

                      <Space
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '6rem 47px 6rem 0px',
                          justifyContent: 'end',
                        }}
                      >
                        <Button className="pp-secondary-button" style={{ marginLeft: '2rem' }} onClick={() => history.push("/purchases/bills")}>
                          <span>{t('cancel')}</span>
                        </Button>

                        <Button type="primary" htmlType="submit" className="pp-main-button ">
                          <span>{t('save')}</span>
                        </Button>

                        <Button type="primary" htmlType="submit" className="pp-main-button ">
                          <span>{t('save_and_submit')}</span>
                        </Button>
                      </Space>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewFileBill;
