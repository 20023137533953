import React, { useState } from "react";
import Header from "../../Header/Header";
import "./organisation.css";
import icon from "../../../assets/icons/org_icon.svg";
import default_icon from "../../../assets/icons/default_icon.svg";
import {
  SwitchBrach,
  SwitchBrachRuby,
  getAllBranchHeader,
  switchBrach,
  switchBrachRuby,
} from "../../../actions/companySettings";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import useDocumentAxios from "../../../config/useDocument";
import { apis } from "../../../config/APIs";
import { Button, Modal, message, Typography } from "antd";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/icons/delete.png";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import jwt_decode from "jwt-decode";
const Organisation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Text } = Typography;
  const { t } = useTranslation();
  const [deletModal, setDeleteModal] = useState(false);
  const [companyID, setCompanyID] = useState();

  const {
    onCall: DeleteMultiBranch,
    data: branchDeleteData,
    reset: barnchDeleteReset,
    loading,
  } = useDocumentAxios({
    api: apis.deleteMultiBranch,
    method: "post",
    baseURL: "settings",
  });
  const getAllBranchdata = useSelector(
    (state) => state?.companySetting?.getAllBranchHeader
  );
  const switchBrachToken = useSelector(
    (state) => state?.companySetting?.switchBrach?.data
  );
  const switchBrachRubyToken = useSelector(
    (state) => state?.companySetting?.switchBrachRuby
  );
  React.useEffect(() => {
    dispatch(getAllBranchHeader());
    dispatch(
      headerTitles({
        title: `Hi ${localStorage.getItem("name")}`,
        description:
          "You are a part of the following organizations. Go to the organization which you wish to access now.",
      })
    );
  }, []);
  React.useEffect(() => {
    if (branchDeleteData?.error === false) {
      barnchDeleteReset();
      dispatch(getAllBranchHeader());
      setDeleteModal(false);
      message.success(<span className="messageText">{t("org_del_suc")}</span>);
    } else if (branchDeleteData?.error === true) {
      message.error(
        <span className="messageText">{branchDeleteData?.message}</span>
      );
    }
  }, [branchDeleteData]);

  React.useEffect(() => {
    if (switchBrachToken?.data) {
      const payApiTokenDecoded = jwt_decode(switchBrachToken?.data);
      localStorage.setItem("tokenPayApi", switchBrachToken?.data);
      localStorage.setItem("user_id_payApi", payApiTokenDecoded.user_id);
      localStorage.setItem("company_name", payApiTokenDecoded?.company_name);
      localStorage.setItem("base_currency", JSON.stringify(payApiTokenDecoded?.base_currency));
      localStorage.setItem("menu", JSON.stringify(switchBrachToken?.menu ? switchBrachToken?.menu : {}));
    }
    if (switchBrachRubyToken?.data?.auth_token) {
      localStorage.setItem("token", switchBrachRubyToken?.data?.auth_token);
      localStorage.setItem("name", switchBrachRubyToken?.data?.name);
      localStorage.setItem("role", switchBrachRubyToken?.data?.role);
      localStorage.setItem("is_admin", switchBrachRubyToken?.data?.is_admin);
      localStorage.setItem("user", switchBrachRubyToken?.data?.user);
      localStorage.setItem(
        "employee_id",
        switchBrachRubyToken?.data?.employee_id
      );
      localStorage.setItem(
        "is_super_admin",
        switchBrachRubyToken?.data?.is_super_admin
      );
      localStorage.setItem(
        "is_kyb_completed",
        switchBrachRubyToken?.data?.is_kyb_completed
      );
    }
    if (switchBrachToken?.data && switchBrachRubyToken?.data?.auth_token) {
      history.push("/dashboard");
      window.location.reload();
      dispatch({ type: SwitchBrach.RESET });
      dispatch({ type: SwitchBrachRuby.RESET });
    }
  }, [switchBrachToken?.data, switchBrachRubyToken?.data]);

  React.useEffect(() => {
    if (getAllBranchdata?.data?.data?.length <= 1) {
      history.push("/dashboard");
      localStorage.setItem("company_id", getAllBranchdata?.data?.data?.[0]?.company_id)
    }
  }, [getAllBranchdata]);

  return (
    <>
      <Modal
        title={
          <>
            <img src={DeleteIcon} alt={"logo"} className="action-logo" />
            {t("delete_organization")}
          </>
        }
        visible={deletModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        // destroyOnClose={true}
        // footer={[]}
        className={"center-aligned-modal"}
      >
        <>
          <Text>{t("delete_role_are_u_sure_organization")}</Text>
          <br />
          <Button
            key="1"
            className="formButton mt1"
            onClick={() => DeleteMultiBranch(companyID)}
            disabled={loading}
          >
            {t("delete")}
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
            onClick={() => setDeleteModal(false)}
          >
            {t("cancel")}
          </Button>
        </>
      </Modal>
      <div className="organisation">
        <Header isOrganisation={true} />
        <div className="organisation_section">
          <div className="organisation_container">
            {/* <div className='header_section'>
                            <h4 className='admin_name'>Hi {localStorage.getItem("name")}</h4>
                            <span className='description'>You are a part of the following organizations. Go to the organization which you wish to access now.</span>
                        </div> */}
            <div className="organisation_cards_section">
              {getAllBranchdata?.data?.data?.map((item, index) => {
                return (
                  <div key={index} className="organisation_card">
                    {/* {
                                                item?.company_id === localStorage.getItem("switchBranch") ? (
                                                    <div className='badge'>
                                                        <img src={default_icon} alt='organistion logo' />
                                                    </div>
                                                ) : null
                                            } */}
                    <div className="organisation_logo">
                      <img
                        src={item?.logo?.url ? item?.logo?.url : icon}
                        alt="organistion logo"
                      />
                    </div>
                    <div className="organisation_details">
                      <h4 className="organisation_name">{item?.name}</h4>
                      <span className="organisation_create">
                        Organization created on{" "}
                        {moment(item?.created_at).format("D MMMM YYYY")}
                      </span>
                      <span className="organisation_id">
                        Organization ID: {item?.series}
                      </span>
                      <span className="organisation_admin">
                        You are an{" "}
                        <span className="role">{item?.roles?.join(",")}</span>{" "}
                        in this organization
                      </span>
                    </div>
                    <div className="button_setion">
                      <button
                        className="go_to_organisation"
                        onClick={() => {
                          dispatch(
                            switchBrach({
                              branch_id: item?.company_id,
                              user_id: item?.user_id,
                            })
                          );
                          dispatch(
                            switchBrachRuby({
                              company_id: item?.company_id,
                              user_id: item?.user_id,
                            })
                          );
                          localStorage.setItem(
                            "switchBranch",
                            item?.company_id
                          );
                          localStorage.setItem("company_id", item?.company_id);
                          localStorage.setItem("user_id", item?.user_id);
                          localStorage.setItem("parentID", item?.parent_id);
                        }}
                      >
                        Go to Organization
                      </button>
                      {/* <button className={`delete_organisation ${item?.company_id === localStorage.getItem("switchBranch") ? "no_drop" : null}`} disabled={item?.company_id === localStorage.getItem("switchBranch")} onClick={() => {
                                                    setDeleteModal(true)
                                                    setCompanyID({ data: { company_id: item?.company_id } })
                                                }}>Delete</button> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organisation;
