import React from 'react'

const CusCreditNotes = ({ scale, fill, opacity }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.2" height="25.5" viewBox="0 0 20.2 25.5" style={{
      scale: scale ? scale : 1,
      zoom: scale ? scale : 1
    }}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_6074" data-name="Rectangle 6074" width="20.2" height="25.5" transform="translate(0 0.009)" fill={fill || "#fff"} />
        </clipPath>
      </defs>
      <g id="Group_18845" data-name="Group 18845" transform="translate(0.336 0.247)" opacity={opacity || "0.5"}>
        <path id="Path_20254" data-name="Path 20254" d="M279.98,5.781V9.873h4.092Z" transform="translate(-264.692 -5.675)" fill={fill || "#fff"} />
        <g id="Group_18843" data-name="Group 18843" transform="translate(-0.336 -0.255)">
          <g id="Group_18842" data-name="Group 18842" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Path_20255" data-name="Path 20255" d="M.551,25.318H19.3a.553.553,0,0,0,.551-.551V5.513H14.884a.551.551,0,0,1-.551-.551V0H.551A.553.553,0,0,0,0,.551V24.766A.553.553,0,0,0,.551,25.318ZM16.538,22.01a.551.551,0,0,1-.551.551H3.858a.551.551,0,0,1,0-1.1H15.986a.551.551,0,0,1,.551.551M3.858,19.8V18.193a.551.551,0,0,1,.551-.551H6.614a.551.551,0,0,1,.551.551V19.8a.551.551,0,0,1-.551.551H4.409a.551.551,0,0,1-.551-.551m4.41,0V16.549A.551.551,0,0,1,8.82,16h2.205a.551.551,0,0,1,.551.551V19.8a.551.551,0,0,1-.551.551H8.82a.551.551,0,0,1-.551-.551m7.167-5.44a.551.551,0,0,1,.551.551V19.8a.551.551,0,0,1-.551.551H13.23a.551.551,0,0,1-.551-.551V14.916a.551.551,0,0,1,.551-.551ZM7.717,2.205a5.476,5.476,0,0,1,1.855.328c.01,0,.02,0,.03,0s.017.012.026.016a5.534,5.534,0,0,1,3.063,2.8c.006.011.016.018.022.029s0,.021.008.031A5.5,5.5,0,0,1,5.866,12.9c-.011,0-.023,0-.034-.006s-.017-.012-.026-.016a5.534,5.534,0,0,1-3.058-2.795c-.008-.014-.019-.025-.026-.039s0-.019-.007-.029a5.5,5.5,0,0,1,5-7.814Z" transform="translate(0.188 0.043)" fill={fill || "#fff"} />
            <path id="Path_20256" data-name="Path 20256" d="M71.939,67.99a3.97,3.97,0,1,0,3.97,3.97A3.968,3.968,0,0,0,71.939,67.99Zm1.14,5.537a1.346,1.346,0,0,1-.662.427.184.184,0,0,0-.157.209c.009.113,0,.235,0,.348a.149.149,0,0,1-.157.165H71.73c-.113,0-.165-.061-.165-.174v-.261c0-.192-.009-.2-.192-.226a2.2,2.2,0,0,1-.67-.192c-.165-.078-.183-.122-.139-.3.035-.131.07-.261.113-.392.026-.1.061-.139.1-.139a.267.267,0,0,1,.113.044,2.259,2.259,0,0,0,.749.226.659.659,0,0,0,.131.009,1,1,0,0,0,.357-.07.382.382,0,0,0,.087-.67,1.308,1.308,0,0,0-.279-.165c-.252-.113-.522-.2-.766-.34a1.072,1.072,0,0,1-.609-1.036A1.134,1.134,0,0,1,71.4,69.94c.2-.078.209-.07.209-.287v-.218c0-.157.035-.192.192-.192h.183c.3,0,.3.009.3.34,0,.244,0,.244.244.279a2.32,2.32,0,0,1,.531.157.159.159,0,0,1,.1.209c-.044.148-.078.3-.131.444-.026.087-.061.131-.113.131a.262.262,0,0,1-.113-.035,1.672,1.672,0,0,0-.74-.165h-.1a.655.655,0,0,0-.226.043.318.318,0,0,0-.078.566,1.894,1.894,0,0,0,.366.209,5.716,5.716,0,0,1,.662.3,1.181,1.181,0,0,1,.383,1.8Z" transform="translate(-64.033 -64.199)" fill={fill || "#fff"} />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CusCreditNotes;
