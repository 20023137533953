import React from 'react';

const TransfersSVG = ({ scale, opacity, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{
      scale: scale ? scale : 1,
      zoom: scale ? scale : 1
    }}>
      <g id="Group_16936" data-name="Group 16936" transform="translate(-6043 -11369.928)">
        <g id="Group_16262" data-name="Group 16262" transform="translate(6042 11368.928)" opacity={opacity || "0.5"}>
          <path id="Path_18974" data-name="Path 18974" d="M22,1H2A1,1,0,0,0,1,2V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V2A1,1,0,0,0,22,1ZM14,16H6.41l1.3,1.29A1,1,0,0,1,7.716,18.7l-.006.006a1,1,0,0,1-1.414.006L6.29,18.71l-3-3a1.034,1.034,0,0,1,0-1.42l3-3a1,1,0,0,1,1.42,1.42L6.41,14H14a1,1,0,0,1,0,2Zm6.92-6.62a1,1,0,0,1-.21.33l-3,3a1,1,0,0,1-1.414.006l-.006-.006a1,1,0,0,1-.006-1.414l.006-.006L17.59,10H10a1,1,0,0,1,0-2h7.59l-1.3-1.29a1,1,0,0,1,1.42-1.42l3,3a1.034,1.034,0,0,1,.21,1.09Z" fill={fill || "#fff"} />
        </g>
      </g>
    </svg>
  );
};

export default TransfersSVG;