import { DatePicker, Steps } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Close from "../../assets/icons/close.png";
import { amountFormat } from "../../utils";
import Input from "../FormComponents/InputComponent";
// import Address from "./Address";
import { CustomerAddToCart, CustomerCreateOrder, CustomerOrdersSubmit, CustomerRemoveCartItem, customer_cart, customer_create_order } from "../../actions/orders/orders";
import Confirm from "./Confirm";
import Products from "./Products";
import Summary from "./Summary";
import "./cart.css";
import useHandleResponse from "./useHandleResponse";
import BaseCurrency from "../Common/BaseCurrency";



const Step = Steps.Step;
const regEx = new RegExp(/^[a-zA-Z0-9]*$/)
const Cart = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const cart = useSelector((state) => state?.orders?.cart?.data || {});
  const createOrder = useSelector((state) => state?.orders?.create_order);
  const [poNumber, setPoNumber] = useState("");
  const [shipmentDate, setShipmentDate] = useState("");
  const [isAggree, setIsAggree] = useState(false);
  const removeItemFromCart = useSelector((state) => state?.orders?.remove_cart_item);

  const history = useHistory();
  const [shippingAddresses, setShippingAddresses] = useState({});

  const successCall = () => {
    dispatch(customer_cart({ company_id: localStorage?.getItem("company_id") }));
  }



  // useHandleResponse(
  //   CustomerRemoveCartItem,
  //   'Item removed successfully.',
  //   'Failed to remove Item.',
  //   removeItemFromCart,
  //   successCall
  // );

  useHandleResponse(
    CustomerCreateOrder,
    'Order placed successfully.',
    `${createOrder?.data?.message}`,
    createOrder,
    () => {
      setStep(2);
      successCall()
    },
    true
  );
  // useEffect(() => {
  //   if (createOrder.success) {
  //     toast.success("Order created.");
  //     localStorage.removeItem("cartId");
  //     setStep(2);
  //     dispatch({
  //       type: GetCart.RESET,
  //     });
  //   }
  //   if (createOrder.success === false) {
  //     toast.error(createOrder?.data?.error_details?.join("\n, ") || "Failed to create order.");
  //     dispatch({
  //       type: CreateOrder.RESET,
  //     });
  //     history.push("/shop-for-tyres")
  //   }
  //   // eslint-disable-next-line
  // }, [createOrder]);
  return (
    <div className="cart">
      <span className="close cursor">
        <img
          src={Close}
          alt="close"
          onClick={() => {
            history.goBack();
          }}
        />
      </span>
      <Steps current={step}>
        <Step
          title="Products"
          onClick={() => {
            setStep(0);
          }}
        />

        <Step
          title="Summary"
          onClick={() => {
            if (cart?.line_items?.length)
              setStep(1);
          }}
        />
        <Step
          title="Confirmation"
        // onClick={() => {
        //   setStep(2);
        // }}
        />
      </Steps>
      <div className="m2">
        {step === 0 && (
          <div className="header">
            <h3>My Order</h3>
            <p className="note">Kindly review your order below.</p>
          </div>
        )}

        {step === 1 && (
          <div className="header">
            <h3>Summary</h3>
          </div>
        )}
        {step <= 1 && (
          <div className="d-flex">
            <div className="w70">
              {step === 0 && <Products step={step} isEdit={true} noMargin={true} />}
              {step === 1 && (
                <Summary
                  step={step}
                  isAggree={isAggree}
                  setIsAggree={setIsAggree}
                  setShippingAddresses={setShippingAddresses}
                />
              )}
            </div>

            <div className="w30 right">
              {/* <ContainerStatus showFooter={true} /> */}

              <div className="price-details" id="poNum">
                <h3>PRICE DETAILS</h3>
                <div className="d-flex flex-col">
                  <span>
                    Total Quantity:{" "}
                    <strong> {cart?.total_quantity} pcs</strong>
                  </span>
                  <span>
                    Total Amount:{" "}
                    <strong><BaseCurrency symbol_code={cart?.currency_info?.iso_code} /> {cart?.total_price ? amountFormat(cart?.total_price) : 0}</strong>
                  </span>
                </div>
              </div>
              {step < 1 && (
                <button
                  disabled={!cart.line_items?.length}
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Continue
                </button>
              )}
              {step === 1 && (
                <>
                  <Input
                    label="Enter PO Number"
                    name="email"
                    type="email"
                    maxLength={35}
                    onChange={(e) => {
                      setPoNumber(e.target.value)
                    }}
                    error={!regEx.test(poNumber) && <div className="errorMsg">
                      PO Number must be alphanumeric without spaces (Ex: PO01233)
                    </div>}
                  />
                  <div className="input-wrap">
                    <label>Shipment Date</label>
                    <DatePicker
                      name="shipment_date"
                      className="w100"
                      style={{
                        height: "45px"
                      }}
                      onChange={(e) => setShipmentDate(moment(e).format("YYYYMMDD"))}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            <Confirm
              resetOrder={() => {
                dispatch({
                  type: CustomerCreateOrder.RESET,
                });
              }}
            />
          </div>
        )}
        {step === 1 && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className="mt2">
            <button
              className="confirm"
              disabled={!isAggree || createOrder?.loading || cart?.line_items?.length === 0}
              onClick={() => {
                dispatch(
                  customer_create_order({
                    cart_id: cart?.id,
                    po_number: poNumber,
                    requested_shipping_date: shipmentDate,
                    shipping_address_id: Object?.keys(shippingAddresses)?.[0],
                    billing_address_id: Object?.keys(shippingAddresses)?.[0],
                    company_id: localStorage?.getItem("company_id"),
                    params: {
                      company_id: localStorage?.getItem("company_id")
                    }
                  })
                );
              }}
            >
              {createOrder?.loading ? "Processing..." : "CONFIRM ORDER"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
