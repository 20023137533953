import { Button, Modal, Typography, message } from 'antd';
import { default as React } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { changeReports, reports, userReportLinkExp, userReports } from "../../actions/reports/reports";
import block from '../../assets/icons/block.png';
import warn from '../../assets/icons/warning.png';
import { objectToFormData } from "../../utils";
import TableWrap from "../Common/Table/Table";
// import moment from "moment";


import { useHistory } from "react-router-dom";
import { apis } from "../../config/APIs";
import endpoint from "../../config/Axios";
import "./report.css";
import moment from 'moment';
import endpointPayExpenseBeta from '../../config/AxiosPayExpense';

message.config({
  maxCount: 3,
});

const Title = Typography.Title;

const LinkExpensesToReport = (props) => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = React.useState([]);
  const [reportsData, setReportsData] = React.useState([]);
  const [violations, setViolations] = React.useState([]);
  const [showViolations, setShowViolations] = React.useState(false);
  const reportsResponse = useSelector(state => state.reports.reports || {});
  const expenseData = useSelector(
    (state) => state?.expenses?.expenseDetails?.data?.data || {}
  );
  const { t } = useTranslation();
  const history = useHistory()
  React.useEffect(() => {
    dispatch(reports({ filters:{status: ["unsubmitted"]}, module_type: "REPORT",params:{report_id:expenseData.report_id} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const { data: { data:  reports = [] , status = false } = {} } = reportsResponse;
    if (status) {
      setReportsData(reports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsResponse]);

  const columns = [
    {
      title: t('report_id'),
      dataIndex: "report_number",
      key: "report_number",
    },
    {
      title: t('title'),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t('dateAndTime'),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD MMM YYYY HH:mm:ss")}</div>,
    }
  ];

  const rowSelection = {
    selectedRowKeys: selectedData,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedData(selectedRowKeys);
    }
  };
  
  const handleClearList = () => {
    setSelectedData([]);
  }

  const handleAddExp = () => {
    const payload = {};
    payload.transaction_ids = [props.expense_id];
    payload.report_id = selectedData[0];
    expenseData.report_id && expenseData?.report_id
      ? dispatch(
          changeReports({
            params: { module_type: "EXPENSE" },
            id: expenseData.id,
            report_id: payload.report_id,
          })
        )
      : dispatch(userReportLinkExp({ ...payload }));
  }

  return (
    <div className="expenses-table">
      {selectedData.length ? (
        <>
          <Button
            type="default"
            ghost
            className="approve-add-btn mb1"
            onClick={() => handleClearList()}
          >
            {t("clear_selection")}
          </Button>
          <Button
            onClick={() => {
              endpointPayExpenseBeta
                .post(apis.validate_expense, {
                  transaction_ids: [props.expense_id],
                })
                .then((res) => {
                  let txns = res.data?.data?.transactions || [];
                  let policies = [];
                  txns?.forEach((ele) => {
                    policies = [
                      ...policies,
                      ...ele?.policies?.map(
                        (e) => (e.policy_action === "BLOCKING" || e.policy_action === "WARNING") && e.errors?.length
                      ),
                    ];
                  });

                  if (policies?.filter((ele) => ele)?.length) {
                    setShowViolations(true);
                    setViolations(txns);
                  } else {
                    handleAddExp();
                  }
                });
            }}
            className="floatRight ml1 primaryButton"
          >
            {/* {t("add_to")} {selectedData.length > 1 ? selectedData.length : ""}{" "}
            {t("report")} */}
            {expenseData.report_id ? "Change Report" : "Add to Report"}
          </Button>
        </>
      ) : null}
      {showViolations && <Modal
        title={<Title level={4}>{t("policy_violation")}</Title>}
        visible={showViolations}
        onCancel={() => {
          setShowViolations(false);
        }}
        wrapClassName='exp-table'
        // footer={violations?.map((ele) =>ele?.policies?.find((itm) =>itm.policy_action === "BLOCKING")).filter(ele=>ele).length ? []: [<Button onClick={handleAddExp} type="primary">Continue</Button>,<Button className="cancelButton ml1 mt1" onClick={()=>setShowViolations(false)} >Cancel</Button>]}
        footer = {violations
            ?.map(
              (ele) =>
                ele.policies.some((policy) => policy.errors.length > 0) &&
                ele?.policies?.find((itm) => itm.policy_action === "WARNING")
            )
            .filter((ele) => ele).some((ele) => ele?.errors?.length > 0) ? [<Button onClick={handleAddExp} type="primary">Continue</Button>,<Button className="cancelButton ml1 mt1" onClick={()=>setShowViolations(false)} >Cancel</Button>]:[]}
      >
        <div className="violations">
          {violations?.map((ele, idx) => (
            <div className="item">
              {idx + 1}. {ele.transaction_number} -
              {ele.policies?.map((itm) =>
                itm.errors.map((e) => (
                  <span className={itm.policy_action === "WARNING" ? "warn" : ""}>
                    <img
                      src={itm.policy_action === "WARNING" ? warn : block}
                      alt=""
                      width={12}
                    />{" "}
                    {e}
                  </span>
                ))
              )}
            </div>
          ))}
        </div>
      </Modal>}
      <TableWrap
        rowKey="id"
        columns={columns}
        data={reportsData?.slice(0, 9)}
        className="tableStyled"
        // pagination={false}
        pagination={{
          total: reportsData?.data?.total_count,
          showSizeChanger: false,
          onChange: (pagee) => {
            // dispatch(({ params: { page_number: pagee } }))
          },
        }}
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
      />
      {reportsData?.length === 0 && (
        <div className="filter-btns mt2 floatRight">
          <Button
            className="primaryButton"
            onClick={() => {
              history.push("/expenses/reports/my-reports", {
                createNew: true,
                expense_id: props.expense_id,
              });
            }}
          >
            {expenseData.report_id && reportsData?.length ? "Change Report" : "Add Report"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LinkExpensesToReport;
