import { Upload, message } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { apis } from "../../../config/APIs";
import useDocumentAxios from "../../../config/useDocument";
import pdf_icon from "../../../assets/icons/pdf_icon.png";
import excel_icon from "../../../assets/icons/excel1.png";
import tiff_icon from "../../../assets/icons/tifficon.png";
import ppt_icon from "../../../assets/icons/ppticon.png";
import { FormInput, FormSelect } from "../../inputs";
import DateInput from "../../inputs/DateInput";
import UseFormUpload from "../../inputs/UseFormUpload";
import moment from "moment";
import { getFileExtension } from "../../../utils";
const CustomField = ({
  control,
  register,
  fields,
  setValue,
  name,
  type,
  errors,
  watch,
  loadingRef,
}) => {
  const [uploadData, setUploadData] = React.useState({});
  const [uploadDetails, setUploadDetails] = React.useState({});
  const { onCall, loading } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });
  const { onCall: DocumentDelete } = useDocumentAxios({
    api: apis.deleteDocument,
    method: "delete",
    baseURL: "document",
  });

  const customfields = watch(name);

  const objectValue = (field_id, id) => {
    return customfields?.find(
      (ele, index) =>
        ele?.master_custom_field_id == field_id ||
        ele?.master_custom_field_id == id
    );
  };
  useEffect(() => {
    if (Array.isArray(fields) && fields?.length > 0) {
      let arr = [];
      fields.forEach((ele) => {
        // console.log({ type: ele?.column_type, imagetype: uploadDetails[ele?.field_id ?? ele.id], value: objectValue(ele?.field_id, ele.id, customfields)?.value },"logValues");
        let obj = {
          master_custom_field_id: ele?.field_id ?? ele.id,
          module_name: type,
          // value: ele?.column_type === "document" ? uploadDetails[ele?.field_id ?? ele.id]?.attachmentId?.[0] ? "document" : "" : ele?.value ? ele?.value : "",
          value:
            ele?.column_type === "document"
              ? uploadDetails[ele?.field_id ?? ele.id]?.attachmentId?.[0]
              : objectValue(ele?.field_id, ele.id)?.value ?? "",
          field_type: ele?.column_type,
        }
        if (ele?.column_type === "document") {
          obj.attachment_id = uploadDetails[ele?.field_id ?? ele.id]
            ? uploadDetails[ele?.field_id ?? ele.id]?.attachmentId
            : [];
        }
        arr.push(obj);
      });
      setValue(name, arr);
    }
  }, [uploadData, uploadDetails]);
  useEffect(() => {
    loadingRef(loading);
  }, [loading]);

  useEffect(() => {
    fields.forEach((ele) => {
      if (ele.column_type === "document") {
        ele?.attachments?.length &&
          setUploadDetails((prevUploadDetails) => {
            return {
              ...prevUploadDetails,
              [ele.field_id]: {
                attachmentId: ele.attachments.map((ele) => ele.blob_id),
                recordId: ele.field_id,
              },
            };
          });
      }
    });

    fields.forEach((ele) => {
      if (ele.column_type === "document") {
        ele?.attachments?.length &&
          setUploadData((b) => {
            return {
              ...b,
              [ele.field_id]: {
                attachmentId: ele.attachments.map((ele) => ele.blob_id),
                recordId: ele.field_id,
              },
            };
          });
      }
    });
    if (Array.isArray(fields) && fields?.length > 0) {
      let arr = [];
      fields.forEach((ele) => {
        arr.push({
          master_custom_field_id: ele?.field_id ?? ele.id,
          module_name: type,
          value:
            ele?.column_type === "document"
              ? uploadDetails[ele?.field_id ?? ele.id]?.attachmentId?.[0]
                ? "document"
                : ""
              : ele?.value
                ? ele?.value
                : "",
          // value:ele?.value,
          field_type: ele?.column_type,
          attachment_id: uploadDetails[ele?.field_id ?? ele?.id]
            ? uploadDetails[ele?.field_id ?? ele?.id]?.attachmentId
            : [],
        });
      });
      setValue(name, arr);
    }
  }, [fields]);
  const handleFileUpload = async (field_id, info) => {
    if (info.file.status === "removed") {
      if (info?.file?.blob_id) {
        const deleteData = await DocumentDelete({
          data: {
            ids: [info?.file?.id],
          },
          params: { record_id: info?.file?.record_id },
          headers: {
            "Content-Type": "multipart/formdata",
          },
        })

        if (deleteData?.error === false) {
          let updateUploadData = { ...uploadData };
          let updateUploadDetails = { ...uploadDetails };
          delete updateUploadData[info?.file?.uid];
          let indexOfAttachmentId = updateUploadDetails?.[
            field_id
          ]?.attachmentId?.indexOf(info?.file?.uid);
          updateUploadDetails?.[field_id]?.attachmentId?.splice(
            indexOfAttachmentId,
            1
          );
          updateUploadData?.[field_id]?.attachmentId?.splice(
            indexOfAttachmentId,
            1
          );
          setUploadData(updateUploadData);
          setUploadDetails(updateUploadDetails);
        }
      } else {
        const deleteData = await DocumentDelete({
          data: {
            ids: [uploadData[info?.file?.uid].attachment_id],
          },
          params: { record_id: uploadData[info?.file?.uid].recordId },
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
        if (deleteData?.error === false) {
          let updateUploadData = { ...uploadData };
          let updateUploadDetails = { ...uploadDetails };
          let indexOfAttachmentId = updateUploadDetails?.[
            field_id
          ]?.attachmentId?.indexOf(uploadData[info?.file?.uid].attachment_id);
          updateUploadDetails?.[field_id]?.attachmentId.splice(
            indexOfAttachmentId,
            1
          );
          setUploadData(updateUploadData);
          setUploadDetails(updateUploadDetails);
        }
      }
    } else {
      let uniqueNo = "";
      if (Object.keys(uploadDetails).includes(field_id)) {
        uniqueNo = uploadDetails[field_id].recordId;
      } else {
        uniqueNo = uuidv4();
      }
      const formData = new FormData();
      formData.append("files", info?.file);
      formData.append("name", info?.file?.name);
      formData.append("record_type", "Document");
      formData.append("record_id", uniqueNo);

      const attachId = await onCall({
        data: formData,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });
      setUploadDetails((b) => {
        if (Object.keys(b).includes(field_id)) {
          return {
            ...b,
            [field_id]: {
              ...b[field_id],
              attachmentId: [...b[field_id].attachmentId, attachId?.data?.[0]],
              recordId: uniqueNo,
            },
          };
        } else {
          return {
            ...b,
            [field_id]: {
              attachmentId: [attachId?.data?.[0]],
              recordId: uniqueNo,
            },
          };
        }
      });
      setUploadData((b) => {
        return {
          ...b,
          [info?.file?.uid]: {
            attachment_id: attachId?.data?.[0],
            recordId: uniqueNo,
          },
        };
      });
    }
  };
  const handleBeforeUpload = (e, uploadFile) => {
    const fileName = getFileExtension(e?.name);
    const maxSize = uploadFile?.data?.max_size;
    const allowedImages = uploadFile?.data?.doc_formats.split(",");
    const uppercaseImages = allowedImages.map((image) =>
      image.trim().toUpperCase()
    );
    const imagetype = uppercaseImages.join(",  ");

    if (!allowedImages?.includes(fileName)) {
      message.error(
        <span className="messageText">You can only upload {imagetype}.</span>
      );
      return Upload.LIST_IGNORE;
    } else if (e.size > maxSize * 1024) {
      message.error(
        <span className="messageText">
          File size must be smaller than {uploadFile?.data?.max_size} KB
        </span>
      );
      return Upload.LIST_IGNORE;
    }
    // if (["jpg", "png", "pdf", "jpeg", "tif", "tiff", "xlsx", "xls", "csv"].includes(fileType)) {
    //   let megabytes = uploadFile?.size / (1024 * 1024);
    //   if (megabytes < 5) {

    //   } else {
    //     message.error(<span className="messageText">Uploaded file should be less then 5MB</span>)
    //     return Upload.LIST_IGNORE
    //   }
    // }
    // else {
    //   message.error(<span className="messageText">Unsupported File upload</span>)
    //   return Upload.LIST_IGNORE
    // }
    return false;
  };

  console.log(fields, "infoDetails===>");

  return (
    <>
      {fields?.map((ele, idx) => {
        switch (ele.column_type) {
          case "dropdown":
            return (
              <div className="pp-form-item">
                <FormSelect
                  inline
                  control={control}
                  {...register(`${name}.${idx}.value`, {
                    required: ele.is_required
                      ? `${ele.column_name} is required`
                      : false,
                    onChange: (e) => {
                      let arr = customfields;
                      arr[idx].value = e.target.value;
                      setValue(`${name}`, arr);
                    },
                  })}
                  errors={errors}
                  required={ele.is_required}
                  label={ele.column_name}
                  hideCreateOption={true}
                  errorMessage={errors[name]?.[idx]?.value?.message}
                  placeholder="Select"
                  options={
                    ele.default_values ? ele?.default_values?.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                      : []
                  }
                />
              </div>
            );
          case "datetime":
            return (
              <div className="pp-form-item">
                <DateInput
                  control={control}
                  {...register(`${name}.${idx}.value`, {
                    required: ele.is_required
                      ? `${ele.column_name} is required`
                      : false,
                    onChange: (e) => {
                      console.log(e.target.value);
                      let arr = customfields;
                      arr[idx].value = e.target.value;
                      setValue(`${name}`, arr);
                      // handleFileUpload(e.target.value)
                    },
                  })}
                  value={
                    customfields?.[idx]?.value
                      ? moment(customfields?.[idx]?.value)
                      : null
                  }
                  required={ele?.is_required}
                  errorMessage={errors[name]?.[idx]?.value?.message}
                  errors={errors}
                  label={ele.column_name}
                  isTime={true}
                />
              </div>
            );
          case "document":
            return (
              <div className="pp-form-item">
                <UseFormUpload
                  control={control}
                  {...register(`${name}.${idx}.value`, {
                    required: ele.is_required
                      ? `${ele.column_name} is required`
                      : false,
                    onChange: (e) => {
                      // const jsonString = JSON.stringify(e.target.value.file);
                      // const base64String = Buffer.from(jsonString).toString('base64');
                      console.log(ele, "column_typeDetails");
                      let arr = customfields;
                      // arr[idx].value = base64String
                      setValue(`${name}`, arr);
                      handleFileUpload(
                        ele?.field_id ?? ele?.id,
                        e.target.value
                      );
                    },
                  })}
                  listType="picture"
                  defaultFileList={ele.attachments?.map((elem) => ({
                    ...elem,
                    url: elem.attachment_url,
                    key: elem.attachment_url,
                    name: elem.filename,
                    thumbUrl: elem.content_type === "image/jpeg" || elem.content_type === "image/jpg" ||
                      elem.content_type === "image/png"
                      ? null
                      : elem.content_type === "application/pdf"
                        ? pdf_icon : elem.content_type === "image/tiff" ? tiff_icon
                          : elem?.content_type ===
                            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            ? ppt_icon
                            : excel_icon,
                  }))}
                  required={ele.is_required}
                  errorMessage={watch(`${name}.${idx}.value`) ? null : errors[name]?.[idx]?.value?.message}
                  errors={watch(`${name}.${idx}.value`) ? null : errors}
                  label={ele.column_name}
                  multiple={false}
                  fileName="Click Here to Upload Files"
                  beforeUpload={(e) => handleBeforeUpload(e, ele)}
                />
              </div>
            );
          default:
            return (
              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register(`${name}.${idx}.value`, {
                    required: ele.is_required
                      ? `${ele.column_name} is required`
                      : false,
                    onChange: (e) => {
                      let arr = customfields;
                      arr[idx].value = e.target.value;
                      setValue(`${name}`, arr);
                    },
                  })}
                  errorMessage={errors[name]?.[idx]?.value?.message}
                  required={ele.is_required}
                  number={ele.column_type === "integer"}
                  errors={errors}
                  label={ele.column_name}
                />
              </div>
            );
        }
      })}
    </>
  );
};
export default CustomField;
