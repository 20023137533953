import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route } from "react-router-dom";
import { Divider } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { headerTitles } from "../../actions/masterdata/masterdata";
import AnalyticsNav from "./AnalyticsNav";
import Cards from "./Cards";
import Expenses from "./Expenses";
import LineGraph from "./LineArea";
import Reports from "./Reports";
import ReportSec from "./ReportsSec";
import Advances from "./AdvancesSec";
import Snapshots from "./Snapshots";
import Trips from "./Trips";
import Estimates from "./Estimates";
import SalesOrders from "./SalesOrders";
import Invoices from "./Invoices";
import PaymentsReceived from "./PaymentsReceived";
import Bills from "./Bills";
import PurchasesOrders from "./PurchasesOrders";
import PaymentMade from "./PaymentMade";
import InvoiceAging from "./InvoiceAging";
import BillsAging from "./BillsAging";

const Summary = ({ showGraph }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="reports">
        {showGraph && (
          <>
            <Divider />
            <div className="mt1" style={{ display: "flex", justifyContent: "center" }}>
              <LineGraph width={1000} height={350} id={"lineAreaGraph"} data={[
                { day: "Jan", txnValue: 39 },
                { day: "Feb", txnValue: 49 },
                { day: "Mar", txnValue: 69 },
                { day: "Apr", txnValue: 59 },
              ]} />
            </div>
          </>
        )}
        <Divider />
        <div className="heading">
          <span>{t('snap')}</span>
        </div>
        <Divider />
      </div>
      <Snapshots />
    </>
  );
};

const Analytics = () => {
  const [showGraph, setShowGraph] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [filters, setFilters] = useState({});
  const [activeNav, setActiveNav] = useState("expenses");
  const [agingValue, setAgingValue] = useState("");
  const pdfURL = useRef();
  const csvData = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(headerTitles({ title: 'Analytics', description: "" }));
  }, [dispatch]);

  return (
    <div className="heading-div">
      <AnalyticsNav activeNav={activeNav} setActiveNav={setActiveNav} />
      <div className="analytics-body">
        <Reports
          setShowGraph={setShowGraph}
          setFilterObj={setFilterObj}
          filters={filterObj}
          apiFilters={filters}
          setFilters={setFilters}
          activeNav={activeNav}
          setAgingValue={setAgingValue}
          pdfURL={pdfURL}
          csvData={csvData}
        />

        <Route exact path="/analytics" render={() => <Redirect to="/analytics/expenses" />} />

        {["expenses", "trips", "reports", "advances", "cards"].map((nav) => (
          <Route
            key={nav}
            exact
            path={`/analytics/${nav}`}
            render={() => {
              const Component = {
                expenses: Expenses,
                trips: Trips,
                reports: ReportSec,
                advances: Advances,
                cards: Cards,
              }[nav];

              return (
                <Component
                  showGraph={showGraph}
                  filterObj={filterObj}
                  apiFilters={filters}
                  setFilterObj={setFilterObj}
                  pdfURL={(func) => { pdfURL.current = func }}
                  csvData={(func) => { csvData.current = func }}
                />
              );
            }}
          />
        ))}

        <Route
          exact
          path="/analytics"
          render={() => <Summary showGraph={showGraph} filterObj={filterObj} apiFilters={filters} />}
        />

        {["estimates", "salesorders", "invoices", "paymentsreceived", "purchasesorders", "bills", "paymentmade", "invoice_aging", "bills_aging"].map((nav) => (
          <Route
            key={nav}
            exact
            path={`/analytics/${nav}`}
            render={() => {
              const Component = {
                estimates: Estimates,
                salesorders: SalesOrders,
                invoices: Invoices,
                paymentsreceived: PaymentsReceived,
                purchasesorders: PurchasesOrders,
                bills: Bills,
                paymentmade: PaymentMade,
                invoice_aging: InvoiceAging,
                bills_aging: BillsAging,
              }[nav];

              return (
                <Component
                  showGraph={showGraph}
                  filterObj={filterObj}
                  apiFilters={filters}
                  setFilterObj={setFilterObj}
                  agingValue={agingValue}
                  pdfURL={(func) => { pdfURL.current = func }}
                  csvData={(func) => { csvData.current = func }}
                />
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Analytics;
