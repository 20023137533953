import { UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Input,
  message,
  Modal,
  Row,
  Steps,
  Typography,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { companyProfile } from "../../actions/companySettings";
import excel_icon from "../../assets/icons/excel1.png";
import file_download from "../../assets/icons/file_download.png";
import pdf_icon from "../../assets/icons/pdf_icon.png";
import ppt_icon from "../../assets/icons/ppticon.png";
import tiff_icon from "../../assets/icons/tifficon.png";
import { amountFormat } from "../../utils";
import {
  ExpenseCustomDetails,
  expenseCustomDetails,
  ExpenseDetails,
  expenseDetails,
} from "./../../actions/expenses/expenses";
import {
  ChangeReports,
  reports,
  ReportsExpAddComments,
  reportsExpAddComments,
  UserReportLinkExp,
} from "./../../actions/reports/reports";
import LinkExpensesToReport from "./LinkExpensesToReport";

import { MessageOutlined } from "@ant-design/icons";
import axios from "axios";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { headerTitles, SideMenu } from "../../actions/masterdata/masterdata";
import Close from "../../assets/icons/close.png";
import { apis } from "../../config/APIs";
import { APP_BASE_URL } from "../../config/AxiosPdf";
import AdvReport from "../Advances/AdvReport";
import BaseCurrency from "../Common/BaseCurrency";
import NoCommentsComponent from "../Common/NoComentsComponent";
import NodataComponent from "../Common/NodataComponent";

const { Title } = Typography;
const { Panel } = Collapse;
const { Step } = Steps;

message.config({
  maxCount: 3,
});

const ExpensesDetails = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const inputComment = React.useRef(null);
  const params = useParams();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [transactionComments, setTransactionComments] = React.useState([]);
  const [reportsExpHistoryData, setReportsExpHistoryData] = React.useState([]);
  const [modalType, setModalType] = React.useState("");
  const [input, setInput] = React.useState("");
  const [tab, setTab] = React.useState("details");
  const { t } = useTranslation();
  // const expenseData = history?.location?.state?.expenseData ?? {}
  const expenseData = useSelector(
    (state) => state?.expenses?.expenseDetails?.data?.data || {}
  );
  const expenseCustomData = useSelector(
    (state) => state?.expenses?.expenseCustomDetails?.data?.data || []
  );
  const companySettingResponse = useSelector(
    (state) => state.companySetting.companySetting || {}
  );
  const is_admin = useSelector(
    (state) => state?.auth?.localVariables?.is_admin
  );
  const expCommentsResponse = useSelector(
    (state) => state.expenses.comments || {}
  );
  const reportsExpAddCommentsResponse = useSelector(
    (state) => state.reports.reportsExpAddComments || {}
  );

  const reportsExpHistoryResponse = useSelector(
    (state) => state.reports.reportsExpHistory || {}
  );
  const userReportLinkExpResponse = useSelector(
    (state) => state.reports.userReportLinkExp || {}
  );

  const changereportResponse = useSelector(
    (state) => state.reports.changeReports || {}
  );

  const updateResponse = useSelector((state) => state.expenses.updateExpense);

  React.useEffect(() => {
    if (params.id) {
      if (expenseData?.isFromExpense)
        dispatch(
          expenseDetails({ expense_id: params.id, module_type: "EXPENSE" })
        );
      // dispatch(reportsExpDetails({ report_id: params.id }))
      else
        dispatch(
          expenseDetails({ expense_id: params.id, module_type: "EXPENSE" })
        );
      // dispatch(
      //   expenseComments(
      //     { transaction_id: params.id }
      //   )
      // );
      // dispatch(
      //   reportsExpHistory(
      //     { transaction_id: params.id },
      //     { is_admin: is_admin }
      //   )
      // );
    }

    dispatch(
      headerTitles({
        title: "Expenses",
        description: "",
      })
    );
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/expenses",
        secondNav: "",
      },
    });
    return () => {
      dispatch({ type: ExpenseCustomDetails.RESET });
      dispatch({ type: ExpenseDetails.RESET });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (expenseData?.category_id) {
      dispatch(
        expenseCustomDetails({
          module_type: "EXPENSE",
          module_id: params.id,
          custom_flag: true,
          category_id: expenseData?.category_id,
        })
      );
    }
  }, [expenseData]);
  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      reportsExpAddCommentsResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      handleCancel();
      dispatch({
        type: ReportsExpAddComments.RESET,
      });
      // dispatch(
      //   expenseComments(
      //     { transaction_id: expenseData.id },
      //   )
      // );
      dispatch(
        expenseDetails({ expense_id: params.id, module_type: "EXPENSE" })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsExpAddCommentsResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      userReportLinkExpResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      handleCancel();
      dispatch({
        type: UserReportLinkExp.RESET,
      });
      history.push("/expenses");
    }
    if (status === false) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UserReportLinkExp.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkExpResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = changereportResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      handleCancel();
      dispatch({
        type: ChangeReports.RESET,
      });
      history.push("/expenses");
    }
    if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: ChangeReports.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changereportResponse]);

  // React.useEffect(()=>{

  // },[updateResponse])

  const handleDownloadClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  React.useEffect(() => {
    const { data: { response: { comments = [] } = {}, status = false } = {} } =
      expCommentsResponse;
    if (status) {
      setTransactionComments(comments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expCommentsResponse]);

  React.useEffect(() => {
    const { data: { response: { history = [] } = {}, status = false } = {} } =
      reportsExpHistoryResponse;
    if (status) {
      setReportsExpHistoryData(history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsExpHistoryResponse]);

  React.useEffect(() => {
    const {
      data: {
        response: { company_setting: { company_id = "" } = {} } = {},
        status = false,
      } = {},
    } = companySettingResponse;
    if (status && company_id) {
      dispatch(companyProfile({ id: company_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettingResponse]);

  // function handleMenuClick(e) {
  //   switch (e.key) {
  //     case "3":
  //       // dispatch(reportsExportExcel({ id: reportData.id }));
  //       break;
  //     default:
  //   }
  // }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddComment = (record) => {
    if (input && record.id) {
      dispatch(
        reportsExpAddComments(
          {
            body: input,
            commentable_id: record.id,
            commentable_type: "transaction",
          },
          { is_admin: is_admin }
        )
      );
      setInput("");
    }
  };

  const renderUnrepOption = () => {
    return (
      <>
        <LinkExpensesToReport expense_id={expenseData.id} />
      </>
    );
  };

  const renderModalData = (type) => {
    switch (type) {
      case "addUnrepExp":
        return renderUnrepOption();
      default:
        return "";
    }
  };

  const renderModalTitle = (type) => {
    switch (type) {
      case "addUnrepExp":
        return "Add Expense to Report";
      default:
        return "";
    }
  };

  const renderModalClass = (type) => {
    switch (type) {
      case "addUnrepExp":
        return "right-alinged-modal";
      default:
        return "";
    }
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageHovered, setIsImageHovered] = useState(false);

  const openImagePreview = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeImagePreview = () => {
    setSelectedImage(null);
  };

  const handleImageHover = () => {
    setIsImageHovered(true);
  };

  const handleImageUnhover = () => {
    setIsImageHovered(false);
  };

  const secs = ["Details", "Reports", "Comments", "History"];

  return (
    <>
      <Modal
        title={renderModalTitle(modalType)}
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={renderModalClass(modalType)}
      >
        {renderModalData(modalType)}
      </Modal>
      {expenseData?.reject_reason && (
        <div className="strip red">
          {t("reason_reject")}: {expenseData?.reject_reason}
        </div>
      )}
      <div className="report-btns">
        {expenseData?.status === "saved" &&
          expenseData?.transaction_type === "debit" && (
            <Button
              disabled={!hasWriteAccess}
              className="cancelButton"
              onClick={() => {
                // history.push("/expense-add",
                dispatch(
                  expenseDetails({
                    expense_id: expenseData?.id,
                    module_type: "EXPENSE",
                  })
                );
                history.push("/expense-add", {
                  isEdit: true,
                  id: expenseData?.id,
                  card_ref: expenseData?.card_reference_number,
                  files: expenseData.files,
                });
              }}
            >
              Edit
            </Button>
          )}
        {expenseData.purpose === "company" ? (
          <>
            {expenseData?.transaction_type === "debit" &&
              expenseData?.status !== "rejected" &&
              expenseData?.report_status !== "pending_approval" &&
              expenseData?.status !== "reimbursed" &&
              expenseData?.status !== "pending_reimbursement" ? (
              <Button
                disabled={!hasWriteAccess}
                className="primaryButton"
                onClick={() => {
                  setIsModalVisible(true);
                  setModalType("addUnrepExp");
                }}
              >
                {expenseData?.status === "saved" ? "Add to " : "Change "}
                Report
              </Button>
            ) : null}
          </>
        ) : null}
        {/* <Dropdown overlay={menu} placement="bottomRight"> */}
        {expenseData?.transaction_type === "debit" &&
          expenseData.attachments?.length > 0 && (
            <button
              disabled={!hasWriteAccess}
              className="more"
              onClick={() => {

              }}
            >
              <img src={file_download} width={14} alt="upload" />
              &nbsp; Receipts
            </button>
          )}

        {/* </Dropdown> */}

        <span
          className="close cursor"
          onClick={() => {
            expenseData?.isFromExpense
              ? history.push("/expenses/")
              : history.goBack();
          }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div>
      <div className="new-report-detail">
        <div className="flex-between">
          <div className="report-head">
            <div className="title-sec">
              <h3>{expenseData?.transaction_number}</h3>
              <span className={`status ${expenseData?.report_status === "pending_approval" && expenseData?.status !== "rejected" ? `status_${expenseData?.report_status}` : `status_${expenseData?.status}`} `}>
                {expenseData?.report_status === "pending_approval" && expenseData?.status !== "rejected" ? expenseData?.report_status?.replace(/_/g, ' ') : expenseData?.status ? expenseData?.status?.replace(/_/g, ' ') : ""}
              </span>
            </div>
            <p className="duration"></p>
            <p>
              <strong>
                Submitted by : {expenseData?.employee_name} <br />
                <span className="sm">
                  on{" "}
                  {moment(expenseData?.created_at).format(
                    "DD MMM YYYY, HH:mm a"
                  )}
                </span>
                <br />
                {/* <span className='sm'>to {expenseData?.submitted_to_email || expenseData?.submitted_to}</span> */}
              </strong>
            </p>
          </div>
          <div className="report-total">
            <p>
              <label>{t("Amount")}</label>
              <span className="lg">
                <BaseCurrency /> {amountFormat(expenseData?.amount_cents)}
              </span>
            </p>
          </div>
        </div>
        <div className="tabs-sec">
          <ul>
            {secs.map((ele) => (
              <li
                onClick={() => setTab(ele.toLowerCase())}
                className={`${tab.toLowerCase() === ele.toLowerCase() ? "active" : ""
                  }`}
              >
                {ele}
              </li>
            ))}
          </ul>
        </div>
        <div className="selected-tab">
          {tab === "details" ? (
            <>
              <Row>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">
                      {t("submitted_by")}
                    </span>
                    <span className="details_span name">
                      {expenseData?.employee_name}
                    </span>
                    <span className="details_span secondary sm">
                      {expenseData?.employee_email}
                    </span>
                  </p>
                </Col>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">
                      {t("submitted_to")}
                    </span>
                    <span className="details_span name">
                      {expenseData?.submitted_to?.name}
                    </span>
                    <span className="details_span secondary sm">
                      {expenseData?.submitted_to?.email}
                    </span>
                    <span className="details_span secondary sm">
                      {expenseData?.submitted_on}
                    </span>
                  </p>
                </Col>
                {expenseData?.report_status === "pending_approval" && (
                  <Col span={6}>
                    <p className="details_p">
                      <span className="details_span secondary">
                        {t("pendingApprovals")} :
                      </span>
                      <span className="details_span name">
                        {expenseData?.pending_with?.name}
                      </span>
                      <span className="details_span secondary sm">
                        {expenseData?.pending_with?.email}
                      </span>
                      {/* <button style={{ textAlign: "left", color: "#e99b39" }}>
                        View Approval Flow
                      </button> */}
                    </p>
                  </Col>
                )}
              </Row>
              <hr />
              <Row className="mt1">
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">Category</span>

                    <span className="details_span name">
                      {expenseData?.category}
                    </span>
                  </p>
                </Col>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">Merchant</span>

                    <span className="details_span name">
                      {expenseData?.merchant}
                    </span>
                  </p>
                </Col>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">
                      Payment Method
                    </span>

                    <span className="details_span name">
                      {expenseData?.payment_method}
                    </span>
                  </p>
                </Col>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">Report Name</span>

                    <span className="details_span name">
                      {expenseData?.report_title &&
                        /\S/.test(expenseData?.report_title)
                        ? expenseData?.report_title?.length > 30
                          ? expenseData?.report_title?.substring(0, 30) +
                          ". . ."
                          : expenseData?.report_title
                        : "-"}
                    </span>
                  </p>
                </Col>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">Purpose</span>
                    <span className="details_span name">
                      {expenseData?.purpose}
                    </span>
                  </p>
                </Col>

                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">Description</span>
                    <span className="details_span name">
                      {expenseData?.description}
                    </span>
                  </p>
                </Col>

                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">Cost Center</span>
                    <span className="details_span name">
                      {expenseData?.cost_center}
                    </span>
                  </p>
                </Col>

                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">Project</span>
                    <span className="details_span name">
                      {expenseData?.project}
                    </span>
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <p
                  style={{
                    fontSize: "1.4rem",
                    color: "#1c2b36",
                    opacity: "0.51",
                    fontWeight: "bold",
                  }}
                >
                  Custom Fields
                </p>
                {expenseCustomData?.map(
                  ({ key, column_name, column_type, value, attachments }) => {
                    return (
                      <>
                        <Col span={24}>
                          <p className="details_p">
                            <span className="details_span secondary">
                              {column_name.split("_").join(" ").toUpperCase()}
                            </span>
                            {column_type === "document" ? (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "auto auto auto auto",
                                  maxWidth: "40rem",
                                }}
                              >
                                {attachments?.map((eachImg) =>
                                  eachImg.content_type === "image/jpeg" ||
                                    eachImg.content_type === "image/jpg" ||
                                    eachImg.content_type === "image/png" ? (
                                    <div
                                      key={eachImg.id}
                                      onClick={() =>
                                        handleDownloadClick(
                                          eachImg.attachment_url
                                        )
                                      }
                                    >
                                      <img
                                        style={{
                                          width: "5rem",
                                          height: "5rem",
                                          margin: "1rem",
                                        }}
                                        src={eachImg.attachment_url}
                                        alt=""
                                      />
                                      <p
                                        title={eachImg.filename}
                                        className="customfield-text"
                                      >
                                        {eachImg.filename}
                                      </p>
                                      <Button
                                        style={{
                                          width: "7rem",
                                          color: "black",
                                        }}
                                        className="formButton mt1"
                                      >
                                        Download
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                      onClick={() =>
                                        handleDownloadClick(
                                          eachImg.attachment_url
                                        )
                                      }
                                    >
                                      {eachImg.content_type ===
                                        "application/pdf" ? (
                                        <img
                                          src={pdf_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : eachImg?.content_type ===
                                        "image/tiff" ? (
                                        <img
                                          src={tiff_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : eachImg?.content_type ===
                                        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                        <img
                                          src={ppt_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={excel_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      )}
                                      <p
                                        title={eachImg.filename}
                                        className="customfield-text"
                                      >
                                        {eachImg.filename}
                                      </p>
                                      <Button
                                        style={{
                                          width: "7rem",
                                          color: "black",
                                        }}
                                        className="formButton mt1"
                                      >
                                        Download
                                      </Button>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <span className="details_span name">{value}</span>
                            )}
                          </p>
                        </Col>
                      </>
                    );
                  }
                )}
              </Row>
              <section className="expense-info">
                <div className="title-card text-center">
                  <Swiper
                    spaceBetween={50}
                    modules={[Navigation]}
                    slidesPerView={1}
                    navigation
                  >
                    {expenseData.attachments?.length > 0
                      ? expenseData.attachments?.map((ele, idx) => (
                        <SwiperSlide
                          key={"img" + idx}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className={`image-preview ${isImageHovered ? "image-preview-blur" : ""
                              }`}
                            style={{
                              width: "32rem",
                              height: "23rem",
                              cursor: "pointer",
                            }}
                            onMouseEnter={handleImageHover}
                            onMouseLeave={handleImageUnhover}
                            onClick={() => openImagePreview(ele?.file)}
                          >
                            <img
                              src={ele?.url}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </SwiperSlide>
                      ))
                      : "No Document"}
                  </Swiper>

                  {/* Image Preview Modal */}
                  <Modal
                    visible={!!selectedImage}
                    onCancel={closeImagePreview}
                    footer={null}
                    width="80%"
                    centered
                  >
                    <img
                      src={selectedImage}
                      alt=""
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </Modal>
                </div>
              </section>
            </>
          ) : null}
          {tab === "reports" ? (
            expenseData && expenseData.reports ? (
              <AdvReport
                repData={expenseData.reports}
                from="report"
                user={true}
              />
            ) : (
              <NodataComponent
                primaryText={t("add_to_report")}
                // noBtns={!location?.state?.user}
                noSecBtn={true}
                noPrimaryBtn={
                  expenseData?.status !== "approved" &&
                  expenseData?.status !== "closed"
                }
                hasWriteAccess={hasWriteAccess}
                onPrimaryClick={() => {
                  if (hasWriteAccess) {
                    setIsModalVisible(true);
                    setModalType("addUnrepExp");
                    dispatch(
                      reports({
                        module_type: "REPORT",
                        params: { report_link_status: true },
                        filters: {
                          status: ["unsubmitted"],
                        },
                      })
                    );
                  }
                }}
              />
            )
          ) : null}
          {tab === "comments" ? (
            <>
              <div className="addComment mt2 mb1">
                <Input
                  placeholder="Enter Comment"
                  ref={inputComment}
                  value={input}
                  onInput={(e) => setInput(e.target.value.trimStart())}
                />
                <Button
                  disabled={!hasWriteAccess}
                  key="1"
                  className="formButton ml1"
                  onClick={() => handleAddComment(expenseData)}
                >
                  {t("send")}
                </Button>
              </div>
              {expenseData?.comments?.length ? (
                <>
                  <div className="commentsSection mt2">
                    <Steps
                      direction="vertical"
                      size="small"
                      current={expenseData?.comments?.length + 1 || 100}
                    >
                      {expenseData?.comments &&
                        expenseData?.comments?.map(
                          ({ body, created_at, name, id }) => (
                            <Step
                              key={id}
                              title={body}
                              description={`on ${moment(created_at).format(
                                "DD MM YYYY, HH:mm a"
                              )} | Commented by: ${name}`}
                              icon={<MessageOutlined />}
                            />
                          )
                        )}
                    </Steps>
                  </div>
                </>
              ) : (
                <NoCommentsComponent />
              )}
            </>
          ) : null}

          {tab === "history" && expenseData?.history?.length ? (
            <div className="commentsSection mt2">
              <Steps
                direction="vertical"
                size="small"
                current={expenseData?.history?.length + 1 || 100}
              >
                {expenseData?.history &&
                  expenseData?.history?.map(
                    ({ body, created_at, name, id, curr_status }) => (
                      <Step
                        key={id}
                        title={body}
                        description={
                          moment(created_at).format("DD MM YYYY, HH:mm a") +
                          " | " +
                          name +
                          " | Action: " +
                          curr_status?.replace(/_/g, ' ')
                        }
                        icon={<UserOutlined />}
                      />
                    )
                  )}
              </Steps>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ExpensesDetails;
