import React, { useEffect } from "react";
// import * as yup from 'yup';
import { Button, Col, Row, Space, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  hasAccess
} from "../../../../utils";
// import { addEmployees, updateEmployees } from '../../../../actions/employees';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { userCards } from "../../../../actions/cards/cards";
import card_chip from "../../../../assets/icons/card_chip.png";
import pdf_icon from "../../../../assets/icons/pdf_icon.png"
import excel_icon from "../../../../assets/icons/excel1.png";
import tiff_icon from "../../../../assets/icons/tifficon.png";
import ppt_icon from "../../../../assets/icons/ppticon.png";
import card_name from "../../../../assets/icons/card_name.png";
import card_wifi from "../../../../assets/icons/card_wifi.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import "./../userControl.css";
import moment from "moment";
import { GetCustomFields } from "../../../../actions/companySettings";


const { TabPane } = Tabs;

const UserDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory()
  const [userData, setUserData] = React.useState({});
  const employeesResponse = useSelector(
    (state) => state.employees.employees || {}
  );
  const userFieldsResponse = useSelector(
    (state) => state.companySetting.getCustomFields || {}
  );

  console.log(userFieldsResponse, "userFieldsData==>");
  const setTab = () => { };
  const userCardsResponse = useSelector((state) => state.cards.userCards || {});
  const employeeByIdResponse = useSelector((state) => state.employees.employeebyId)
  const { data: { response: { cards: userCardsList = [] } = {} } = {} } =
    userCardsResponse;
  // useEffect(() => {
  //   if (userData?.id)
  //     dispatch(userCards({ source: "admin", user_id: userData?.user_id }))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userData])
  const { data: { data: { custom_fields = [], approvers = [] } = {} } = {} } = userFieldsResponse || {}

  useEffect(() => {
    return () => {
      dispatch({ type: GetCustomFields.RESET })
    }
  }, [])


  const handleDownloadClick = async (fileUrl, fileName) => {
    window.open(fileUrl, "_blank")
    // try {
    //   const response = await fetch(fileUrl);
    //   const blob = await response.blob();

    //   // Create a link element
    //   const link = document.createElement('a');

    //   // Create a Blob URL for the file blob
    //   const blobUrl = window.URL.createObjectURL(blob);

    //   // Set the link's href and download attributes
    //   link.href = blobUrl;
    //   link.download = fileName;

    //   // Append the link to the document body
    //   document.body.appendChild(link);

    //   // Programmatically trigger a click on the link to initiate the download
    //   link.click();

    //   // Remove the link from the document
    //   document.body.removeChild(link);

    //   // Release the Blob URL
    //   window.URL.revokeObjectURL(blobUrl);
    // } catch (error) {
    //   console.error('Error downloading file:', error);
    // }
  };

  React.useEffect(() => {
    if (employeesResponse.success && props.selectedRecord) {
      // const currentValues =
      //   findLodash(employeesResponse?.data?.data, {
      //     id: props.selectedRecord,
      //   }) || {};
      const { data: { data: currentValues = {} } = {} } = employeeByIdResponse || {}
      setUserData(currentValues);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeByIdResponse]);

  return (
    <>

      <div className="tabContentWrapperSmall userDetailHead">
        <Space size="middle">
          <div className="detailsCircle">
            {userData.name ? userData.name.charAt(0).toUpperCase() : "Z"}
          </div>
          <div>
            <h3 style={{ color: "#333" }}>{userData.name}</h3>
            <span>{userData?.email}</span>
          </div>
        </Space>
        <div className="floatRight">
          <Space size="middle">
            <div className={`tableStatus_Active`}>Active</div>
            {
              userData?.roles?.includes("Super Admin") ? userData?.email === localStorage.getItem("user") ? (
                <button disabled={!props?.hasWriteAccess} onClick={() => props.showEditModal(props.selectedRecord)}>
                  <img src={EditIcon} alt={"logo"} className="action-logo" />
                </button>
              ) : null : (
                <button disabled={!props?.hasWriteAccess} onClick={() => props.showEditModal(props.selectedRecord)}>
                  <img src={EditIcon} alt={"logo"} className="action-logo" />
                </button>
              )
            }
            {
              userData?.roles?.includes("Super Admin") || userData?.email === localStorage.getItem("user") ? null : (
                <button disabled={!props?.hasWriteAccess} onClick={() => props.showDeleteModal(props.selectedRecord)}>
                  <img src={DeleteIcon} alt={"logo"} className="action-logo" />
                </button>
              )
            }
          </Space>
        </div>
      </div>
      <div className="userContentWrapper mt1">
        <Tabs
          onChange={setTab}
          type="card"
          size="large"
          className="custom-tabs"
        >
          <TabPane tab="Overview" key="preferences">
            <Row>
              <Col span={10}>
                <p>
                  <b>{t('employee_name')}</b>
                </p>
                <p>{userData.name}</p>

                <p className="mt2">
                  <b>{t('mobile')}</b>
                </p>
                <p>{userData.primary_phone}</p>
                <p className="mt2">
                  <b>{t('employee_id')}</b>
                </p>
                <p>{userData.emp_id || ""}</p>
                {custom_fields &&
                  custom_fields?.length > 0 &&
                  custom_fields.map((ele) => {
                    return (
                      <>
                        <p className="mt2">
                          <b>{ele.column_name}</b>
                        </p>
                        {ele.column_type !== "document" ?
                          ele.column_type !== "datetime" ?
                            <p>
                              {ele.selected.value && ele.selected.value
                                ? ele.selected.value
                                : "-"}
                            </p> :
                            <p>
                              {ele.selected.value && ele.selected.value
                                ? ele?.selected?.value
                                : "-"}
                            </p>
                          :
                          <div style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}>
                            {ele.selected.attachments && ele.selected.attachments?.map((eachImg) => (
                              eachImg.content_type === "image/jpeg" || eachImg.content_type === "image/jpg" ||
                                eachImg.content_type === "image/png" ? (
                                <div key={eachImg.id} onClick={() => handleDownloadClick(eachImg.key)}>
                                  <img
                                    style={{ width: "5rem", height: "5rem", margin: "1rem" }}
                                    src={eachImg.key}
                                    alt=""
                                  />
                                  <p title={eachImg.filename} className="customfield-text">{eachImg.filename}</p>
                                  <Button style={{ width: "7rem", color: "black" }} className="formButton mt1">Download</Button>
                                </div>
                              ) : (
                                <div style={{ display: "flex", flexDirection: "column" }} onClick={() => handleDownloadClick(eachImg.key, eachImg.filename)}>
                                  {eachImg.content_type === "application/pdf" ?
                                    <img src={pdf_icon} style={{ width: "5rem", height: "5rem", margin: "1rem" }} /> :
                                    eachImg?.content_type === "image/tiff" ? <img src={tiff_icon} style={{ width: "5rem", height: "5rem", margin: "1rem" }} /> :
                                      eachImg?.content_type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ?
                                        <img src={ppt_icon} style={{ width: "5rem", height: "5rem", margin: "1rem" }} /> :
                                        <img src={excel_icon} style={{ width: "5rem", height: "5rem", margin: "1rem" }} />
                                  }
                                  <p title={eachImg.filename} className="customfield-text">{eachImg.filename}</p>
                                  <Button style={{ width: "7rem", color: "black" }} className="formButton mt1">Download</Button>
                                </div>
                              )))}
                          </div>
                        }
                      </>
                    );
                  })}
                <p className="mt2">
                  <b>{t('approvers')}</b>
                </p>
                {userData.approvers &&
                  userData.approvers?.length > 0 &&
                  userData.approvers.map((ele) => (
                    <p>{`${ele.name} (${ele.role})`}</p>
                  ))}
              </Col>
              <Col span={8}>
                <p>
                  <b>{t('role')}</b>
                </p>
                <p>{userData.roles?.map((ele) => ele)}</p>
                <p>
                  <b>{t('cost_center')}</b>
                </p>
                <p>{userData.cost_center?.name}</p>
                <p>
                  <b>{t('project')}</b>
                </p>
                <p>{userData.project?.name}</p>
              </Col>
            </Row>
            {
              userData?.roles?.includes("Super Admin") ? userData?.email?.toLowerCase() === localStorage.getItem("user")?.toLowerCase() ? (
                <Button
                  key="5"
                  disabled={!props?.hasWriteAccess}
                  onClick={() => {
                    props.showEditModal(props.selectedRecord)
                    props.setEditTab(1)
                  }}
                  className="formButton mt3"
                >
                  {t('edit')}
                </Button>
              ) : null : (
                <Button
                  key="5"
                  disabled={!props?.hasWriteAccess}
                  onClick={() => {
                    props.showEditModal(props.selectedRecord)
                    props.setEditTab(1)
                  }}
                  className="formButton mt3"
                >
                  {t('edit')}
                </Button>
              )
            }
            <Button
              key="6"
              className="cancelButton ml1 mt3"
              onClick={props.handleRowClick}
            >
              {t('back')}
            </Button>
          </TabPane>
          <TabPane tab="Grades and Designations" key="fields3">
            <Row>
              <Col span={6}>
                <p>
                  <b>{t('Department')}</b>
                </p>
                <p className="mt2">
                  <b>{t('grade_level')}</b>
                </p>
              </Col>
              <Col span={8}>
                <p>{userData.designation_title}</p>
                <p className="mt2">{userData.grade_level}</p>
              </Col>
            </Row>
            {
              userData?.roles?.includes("Super Admin") ? userData?.email?.toLowerCase() === localStorage.getItem("user")?.toLowerCase() ? (
                <Button
                  key="5"
                  disabled={!props?.hasWriteAccess}
                  onClick={() => {
                    props.showEditModal(props.selectedRecord)
                    props.setEditTab(2)
                  }}
                  className="formButton mt3"
                >
                  {t('edit')}
                </Button>
              ) : null : (
                <Button
                  key="5"
                  disabled={!props?.hasWriteAccess}
                  onClick={() => {
                    props.showEditModal(props.selectedRecord)
                    props.setEditTab(2)
                  }}
                  className="formButton mt3"
                >
                  {t('edit')}
                </Button>
              )
            }
            <Button
              key="6"
              className="cancelButton ml1 mt3"
              onClick={props.handleRowClick}
            >
              {t('back')}
            </Button>
          </TabPane>
          <TabPane tab="Card" key="fields1">
            <div style={{ flexWrap: "wrap", gap: "2rem", display: "flex" }}>
              {userCardsList?.map((ele) => {
                return (
                  <div className="debit-card cursor" onClick={() => {
                    if (ele) {
                      history.push({
                        pathname: `/card-details/${ele.id}`,
                        state: { cardData: ele },
                      });
                    }
                  }} style={{ maxWidth: 250 }}>
                    <h2>{ele.company_name}</h2>
                    <div className="flex-between mt1">
                      <img src={card_chip} width={30} alt={"card_chip"} />
                      <img src={card_wifi} width={15} alt={"card_wifi"} />
                    </div>
                    <div className="mt1">
                      <span>
                        {ele?.data?.maskCardNumber?.substr(0, 4)} XXXX XXXX{" "}
                        {ele?.data?.maskCardNumber?.substr(15)}
                      </span>
                      <br />
                      <span style={{ marginTop: 5, fontSize: "0.85rem" }}>
                        {ele?.card_reference_no}
                      </span>
                    </div>
                    <div className="flex-between mt1">
                      <span style={{ fontSize: "0.85rem" }}>
                        {ele?.employee_details?.name ? ele?.employee_details?.name : ele.data?.firstName +
                          " " +
                          ele.data?.lastName}
                      </span>
                      <img src={card_name} alt={"card_name"} />
                    </div>
                  </div>
                )
              })}
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default UserDetails;
