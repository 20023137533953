import { ActionTypesFactory } from "../../utils";

export const Employees = ActionTypesFactory("Employees", "Employees");
export const EmployeeById = ActionTypesFactory("EmployeeById","Employees");
export const EmployeesStatus = ActionTypesFactory(
  "EmployeesStatus",
  "Employees"
);
export const CreateEmployee = ActionTypesFactory("CreateEmployee","Employee");
export const AddEmployees = ActionTypesFactory("AddEmployees", "Employees");
export const UpdateEmployees = ActionTypesFactory(
  "UpdateEmployees",
  "Employees"
);
export const DeleteEmployees = ActionTypesFactory(
  "DeleteEmployees",
  "Employees"
);
export const UploadEmployeeDocument = ActionTypesFactory(
  "UploadEmployeeDocument",
  "Employees"
)
export const DeleteEmployeeDocument = ActionTypesFactory(
  "DeleteEmployeeDocument",
  "Employees"
)
export const GetEmployeeDocument = ActionTypesFactory(
  "GetEmployeeDocument","Employees"
)
export const DownloadBulkLink = ActionTypesFactory(
  "DownloadBulkLink",
  "Employees"
);
export const UploadBulk = ActionTypesFactory("UploadBulk", "Employees");
export const GetUserFields = ActionTypesFactory("GetUserFields", "Employees");

// export const authorize = (payload) => {
//   console.log("step one");
//   return {
//     type: Authorize.REQUEST,
//     payload,
//   };
// };

export const uploadBulkEmployees = (payload) => {
  return {
    type: UploadBulk.REQUEST,
    payload,
  };
};

export const downloadBulkEmployees = () => {
  return {
    type: DownloadBulkLink.REQUEST,
  };
};

export const getUserFields = () => {
  return {
    type: GetUserFields.REQUEST,
  };
};

export const employees = (payload) => {
  return {
    type: Employees.REQUEST,
    payload,
  };
};

export const employeebyId = (payload) => {
  return {
    type: EmployeeById.REQUEST,
    payload,
  };
};

export const employeesStatus = (payload) => {
  return {
    type: EmployeesStatus.REQUEST,
    payload,
  };
};

export const addEmployees = (payload) => {
  return {
    type: AddEmployees.REQUEST,
    payload,
  };
};

export const updateEmployees = (payload) => {
  return {
    type: UpdateEmployees.REQUEST,
    payload,
  };
};

export const deleteEmployees = (payload) => {
  return {
    type: DeleteEmployees.REQUEST,
    payload,
  };
};

export const uploadEmployeeDocument = (payload) =>{
  return{
    type:UploadEmployeeDocument.REQUEST,
    payload,
  }
}

export const getEmployeeDocument = (payload) =>{
  return{
    type:GetEmployeeDocument.REQUEST,
    payload,
  }
}

export const deleteEmployeeDocument = (payload) =>{
  return{
    type:DeleteEmployeeDocument.REQUEST,
    payload,
  }
}

// export const commits = (payload) => {
//   let str = "";
//   Object.keys(payload).forEach((item) => {
//     str = str + `${item}:${payload[item]} `;
//   });
//   payload = encodeURIComponent(str);
//   return {
//     type: Commits.REQUEST,
//     payload,
//   };
// };
