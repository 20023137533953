import * as React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Typography } from 'antd';
import Close from '../../assets/icons/close.png';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;
const renderNavItem = (navigation) => {

  const items = [];
  for (const key of Object.keys(navigation)) {
    items.push(
      navigation[key] ? (
        <Breadcrumb.Item key={key}>
          <Link key={key} to={navigation[key]}>
            {key}
          </Link>
        </Breadcrumb.Item>
      ) : (
        <span key={key}>{key}</span>
      )
    );
  }
  return items;
};

const PageHeader = (props) => {
  const { title, navigation, description } = props;
  const {t} = useTranslation()
  return (
    <div className="pageHeader">
      <div>
        <div>
          <div>
            {' '}
            <span style={{ margin: 0, fontWeight: 'bold', fontSize: '36px', lineHeight: '44px', color: '#262626' }}>
              {t(title)}
            </span>
          </div>
          <div>
            {' '}
            <Text style={{ margin: 0, fontWeight: 'medium', fontSize: '18px', lineHeight: '22px', color: '#767676' }}>
              {t(description)}
            </Text>
          </div>
        </div>

        {navigation && <Breadcrumb separator=".">{renderNavItem(navigation)}</Breadcrumb>}
      </div>
      {props?.isClose && (
        <span className="close cursor" onClick={props.onCloseClick}>
          <img src={Close} width={20} alt={'close'} />
        </span>
      )}
    </div>
  );
};

export default PageHeader;
