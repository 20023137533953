import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { useEffect } from "react";


const Summary = ()=>{
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(headerTitles({
            title : "Statements",
            description : "Find records of all transactions both incoming and outgoing",
        }))
    })
    return(
        <>

        </>
    )
}
export default Summary;