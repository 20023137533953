import { Button } from 'antd';
import React, { useEffect } from 'react'
import success_icon from "../../assets/icons/success_icon.svg";
import success_gif from "../../assets/icons/success_gif.gif";
import { useHistory } from "react-router-dom";
const SuccessPage = () => {
    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem("reject_reason")) {
            localStorage.removeItem("reject_reason")
        }
    }, [])
    return (
        <div className='success_section'>
            <div className='success_container d-flex-center flex-col '>
                <div className='image_section mb2'>
                    <img src={success_gif} alt="success page icon" />
                </div>
                <div className='application'>
                    <h4>Application Submitted and Under Review</h4>
                </div>
                <div className='application_note'>
                    <span>Sit back and Relax, we are reviewing your application and will get to you shortly.</span>
                </div>
                <div className='button_section mt3'>
                    <Button
                        type="primary"
                        className="pp-main-button "
                        onClick={() => {
                            history.push("/dashboard")
                        }}
                    >
                        <span>Go to Dashboard</span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SuccessPage;