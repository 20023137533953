import { Button, Modal, message } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { get_tax_items } from "../../../actions/purchases/purchases";
import { apis } from "../../../config/APIs";
import usePayAxios from "../../../config/useAxios";
import { FormInput } from "../../inputs";


const AddTaxModal = ({ isVisible, onCancel }) => {
  const {t} = useTranslation()
  const { onCall } = usePayAxios({ api: apis.createTaxItem, method: "post" });
  const dispatch = useDispatch()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      tax_name: "",
      rate: ""
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload = {
      tax_name: data.tax_name,
      rate: Number(data.rate)
    };

    onCall({
      data: payload,
    }).then(data => {
      reset()
      onCancel()
      dispatch(get_tax_items())
      message.success(<span className="messageText">{data?.message}</span>);
    }).catch(err => {
      message.error(<span className="messageText">{err?.response?.data?.message}</span>);
  });
  };

  return (
    <>
      <Modal title="Add Item" visible={isVisible} onCancel={onCancel}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pp-form-item p0">
              <FormInput
                control={control}
                {...register("tax_name", { required: "Tax Name is required" })}
                errors={errors}
                label={t('tax_name')}
                placeholder={t('enter_tax_name')}
                required={true}
              />
            </div>
            <div className="pp-form-item p0">
              <FormInput
                number={true}
                control={control}
                {...register("rate", { required: "Rate is required" })}
                errors={errors}
                label={t('val')}
                placeholder={t('enter_val')}
                required={true}
              />
            </div>

            <div className="d-flex gap1 mt2">
              <button type="submit" className="pp-main-button" style={{
                color: "#fff"
              }}>
                {t('save')}
              </button>
              <Button className="pp-secondary-button" onClick={onCancel}>
                {t('cancel')}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddTaxModal;
