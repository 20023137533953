
export const expense_keys = [
    {
        label: "Transaction ID",
        value: "transaction_number",
        type: "string",
    },
    {
        label: "Category",
        value: "category",
        type: "string",
    },
    {
        label: "Report ID",
        type: "string",
        value: "report_number",
    },
    {
        label: "Report Name",
        type: "string",
        value: "report_name",
    },
    {
        label: "Merchant Name",
        type: "string",
        value: "merchant",
    },
    {
        label: "Employee Name",
        type: "string",
        value: "employee_name",
    },
    {
        label: "Submitted On",
        value: "report_submitted_on",
        type: "date",
    },
    {
        label: "Amount",
        value: "transaction_amount",
        type: "number",
    },
    {
        label: "Project",
        value: "project",
        type: "string",
    },
    {
        label: "Cost Center",
        value: "cost_center",
        type: "string",
    },
    {
        label: "Transaction Date",
        value: "transaction_date",
        type: "date",
    },
];

export const card_keys = [
    {
        label: "Employee ID",
        value: "employee_ids",
        type: "string",
    },
    {
        label: "Card Ref No",
        value: "card_reference_numbers",
        type: "string",
    },
    {
        label: "Employee Name",
        value: "employee_names",
        type: "string",
    },
    {
        label: "Transaction ID",
        value: "transaction_id",
        type: "string",
    },
    {
        label: "Transaction Date",
        value: "transaction_date",
        type: "date",
    },
    {
        label: "Merchant Name",
        value: "merchant",
        type: "string",
    },

    {
        label: "Transaction Amount",
        value: "transaction_amount",
        type: "number",
    },
    {
        label: "Wallet name",
        value: "wallet_name",
        type: "string",
    },
    {
        label: "Merchant MCC",
        value: "merchant_mcc",
        type: "string",
    },
    {
        label: "Transaction Type",
        value: "transaction_type",
        type: "select",
        values: [
            { label: "Credit", value: "Cr" },
            { label: "Debit", value: "Dr" },
        ],
    },
    {
        label: "Approval Status",
        value: "status",
        type: "string",
        // values: [{ label: "Pending", value: "pending_approval" }],
    },
    {
        label: "Project",
        value: "project",
        type: "string",
    },
    {
        label: "Cost Center",
        value: "cost_center",
        type: "string",
    },
];

export const report_keys = [
    {
        label: "Report ID",
        value: "report_id",
        type: "string",
    },
    {
        label: "Report Name",
        value: "report_name",
        type: "string",
    },
    {
        label: "Employee Name",
        value: "employee_name",
        type: "string",
    },
    {
        label: "From Date",
        value: "start_date",
        type: "date",
    },
    {
        label: "To Date",
        value: "end_date",
        type: "date",
    },
    {
        label: "Submitted On",
        value: "submitted_on",
        type: "date",
    },

    {
        label: "Amount",
        value: "total_amount",
        type: "number",
    },

    {
        label: "Status",
        value: "status",
        type: "string",
    },
    {
        label: "Project",
        value: "project",
        type: "string",
    },
    {
        label: "Cost Center",
        value: "cost_center",
        type: "string",
    },
];

export const advance_keys = [
    {
        label: "Advance ID",
        value: "advance_id",
        type: "string",
    },
    {
        label: "Purpose or Title",
        value: "title",
        type: "string",
    },
    {
        label: "Date & Time",
        value: "created_at",
        type: "date",
    },
    {
        label: "Reference",
        value: "reference_number",
        type: "string",
    },
    {
        label: "Amount",
        value: "amount",
        type: "number",
    },

    {
        label: "Status",
        value: "status",
        type: "string",
        // values: [
        //   { label: "Pending", value: "pending_approval" },
        //   { label: "Recovered", value: "recovered" },
        // ],
    },
    {
        label: "Project",
        value: "project",
        type: "string",
    },
    {
        label: "Cost Center",
        value: "cost_center",
        type: "string",
    },
];

export const trip_keys = [
    {
        label: "Trip ID",
        value: "trip_number",
        type: "string",
    },
    {
        label: "Trip Name",
        value: "trip_name",
        type: "string",
    },
    {
        label: "Employee Name",
        value: "employee_name",
        type: "string",
    },
    {
        label: "Purpose",
        value: "purpose",
        type: "string",
    },
    {
        label: "Submitted On",
        value: "submitted_on",
        type: "date",
    },
    {
        label: "Status",
        value: "status",
        type: "string",
        // values: [{ label: "Pending", value: "pending_approval" }],
    },
    {
        label: "Project",
        value: "project",
        type: "string",
    },
    {
        label: "Cost Center",
        value: "cost_center",
        type: "string",
    },
];

export const estimates_keys = [
    {
        label: "Customer Name",
        value: "customer_name",
        type: "string",
    },
    {
        label: "Estimate No",
        value: "invoice_no",
        type: "string",
    },
    {
        label: "Estimate Date",
        value: "invoice_date",
        type: "date",
    },
    {
        label: "Reference No",
        value: "ref_po_no",
        type: "string",
    },
    {
        label: "Expiry Date",
        value: "po_due_date",
        type: "date",
    },
    {
        label: "Tax Amount",
        value: "total_tax_amount",
        type: "number",
    },
    {
        label: "Discount",
        value: "total_discount",
        type: "number",
    },
    {
        label: "Total Amount",
        value: "total_amount",
        type: "number",
    },
    {
        label: "Status",
        value: "status",
        type: "string",
        // values: [{ label: "Pending", value: "pending_approval" }],
    },
];
export const sales_order_keys = [
    {
        label: "Customer Name",
        value: "customer_name",
        type: "string",
    },
    // {
    //   label: "Sales Order No",
    //   value: "invoice_no",
    //   type: "string",
    // },
    {
        label: "Sales Order Date",
        value: "invoice_date",
        type: "date",
    },
    {
        label: "Reference No",
        value: "ref_po_no",
        type: "string",
    },
    {
        label: "Payment Terms",
        value: "payment_terms",
        type: "string",
    },
    {
        label: "Expected Shipment Date",
        value: "po_due_date",
        type: "date",
    },
    {
        label: "Tax Amount",
        value: "total_tax_amount",
        type: "number",
    },
    {
        label: "Discount",
        value: "total_discount",
        type: "number",
    },
    {
        label: "Total Amount",
        value: "total_amount",
        type: "number",
    },
    {
        label: "Status",
        value: "status",
        type: "string",
        // values: [{ label: "Pending", value: "pending_approval" }],
    },
];
export const purchases_orders_keys = [
    {
        label: "Vendor Name",
        value: "vendor_name",
        type: "string",
    },
    {
        label: "Purchases Order No",
        value: "billing_no",
        type: "string",
    },
    {
        label: "Purchases Order Date",
        value: "billing_date",
        type: "date",
    },
    {
        label: "Payment Terms",
        value: "payment_terms",
        type: "string",
    },
    {
        label: "Expected Delivery Date",
        value: "due_date",
        type: "date",
    },
    {
        label: "Tax Amount",
        value: "total_tax_amount",
        type: "number",
    },
    {
        label: "Discount",
        value: "total_discount",
        type: "number",
    },
    {
        label: "Total Amount",
        value: "total_amount",
        type: "number",
    },
    {
        label: "Status",
        value: "status",
        type: "string",
        // values: [{ label: "Pending", value: "pending_approval" }],
    },
];
export const bills_keys = [
    {
        label: "Vendor Name",
        value: "vendor_name",
        type: "string",
    },
    {
        label: "Bill No",
        value: "billing_no",
        type: "string",
    },
    {
        label: "Bill Date",
        value: "billing_date",
        type: "date",
    },
    {
        label: "Reference No",
        value: "ref_purchase_order_no",
        type: "string",
    },
    // {
    //   label: "Payment Terms",
    //   value: "payment_terms",
    //   type: "string",
    // },
    {
        label: "Payment Due Date",
        value: "due_date",
        type: "date",
    },
    {
        label: "Tax Amount",
        value: "total_tax_amount",
        type: "number",
    },
    {
        label: "Discount",
        value: "total_discount",
        type: "number",
    },
    {
        label: "TDS Amount",
        value: "tds_amount",
        type: "number",
    },
    {
        label: "Total Amount",
        value: "total_amount",
        type: "number",
    },
    {
        label: "Status",
        value: "status",
        type: "string",
        // values: [{ label: "Pending", value: "pending_approval" }],
    },
];

export const payment_made_keys = [
    {
        label: "Bill Date",
        value: "billing_date",
        type: "date",
    },
    {
        label: "Vendor Name",
        value: "vendor_name",
        type: "string",
    },
    {
        label: "Bill No",
        value: "billing_no",
        type: "string",
    },
    {
        label: "Total Amount",
        value: "total_amount",
        type: "number",
    },
    {
        label: "Due Balance Amount",
        value: "due_balance_amount",
        type: "number",
    },
    {
        label: "Payment Status",
        value: "payment_status",
        type: "string",
    },
    {
        label: "Payment Mode",
        value: "payment_mode",
        type: "string",
    },
];

export const payment_received_keys = [
    {
        label: "Invoice Date",
        value: "invoice_date",
        type: "date",
    },
    {
        label: "Customer Name",
        value: "customer_name",
        type: "string",
    },
    // {
    //   label: "Bill No",
    //   value: "billing_no",
    //   type: "string",
    // },
    {
        label: "Total Amount",
        value: "total_amount",
        type: "number",
    },
    {
        label: "Due Balance Amount",
        value: "due_balance_amount",
        type: "number",
    },
    {
        label: "Payment Status",
        value: "payment_status",
        type: "string",
    },
    {
        label: "Payment Mode",
        value: "payment_mode",
        type: "string",
    },
];

export const invoices_keys = [
    {
        label: "Customer Name",
        value: "customer_name",
        type: "string",
    },
    {
        label: "Invoice Date",
        value: "invoice_date",
        type: "date",
    },
    {
        label: "Invoice No",
        value: "invoice_no",
        type: "string",
    },
    {
        label: "Reference No",
        value: "ref_po_no",
        type: "string",
    },
    {
        label: "Payment Terms",
        value: "payment_terms",
        type: "string",
    },
    {
        label: "Due Date",
        value: "po_due_date",
        type: "date",
    },
    {
        label: "GL Code",
        value: "gl_code",
        type: "string",
    },
    {
        label: "Tax Amount",
        value: "total_tax_amount",
        type: "number",
    },
    {
        label: "Discount",
        value: "total_discount",
        type: "number",
    },
    {
        label: "Total Amount",
        value: "total_amount",
        type: "number",
    },
    {
        label: "Status",
        value: "status",
        type: "string",
        // values: [{ label: "Pending", value: "pending_approval" }],
    },
];