import { DeleteOutlined, EyeFilled } from "@ant-design/icons";
import { Button, Modal, Upload, message } from "antd";
import React, { useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import png_icon from "../../../../assets/icons/pdf_file_icon.png";
import { apis } from "../../../../config/APIs";
import useDocumentAxios from "../../../../config/useDocument";
import { fetchThirdPartyAPIs, getFileExtension } from "../../../../utils";
import { FormInput, FormSelect } from "../../../inputs";
import ButtonUpload from "../../../inputs/ButtonUpload";
import DateInput from "../../../inputs/DateInput";
import FormCheckbox from "../../../inputs/FormCheckbox";
import FormRadio from "../../../inputs/FormRadio";
import SquareUpload from "../../../inputs/SquareUpload";
import KYCHeader from "../../CommonScreens/KYCHeader";
import delete_cross from "../../assets/icons/delete_cross_icon.svg";
import preview_icon from "../../assets/icons/preview_icon.svg";

const BusinessKYCFields = ({
  fieldObject,
  setFieldRenderData,
  fieldRenderData,
  control,
  register,
  errors,
  setValue,
  getValues,
  watch,
  setFileData,
  fileData,
  clearErrors,
  allCountries,
}) => {
  const [loader, setLoader] = useState(false);
  const setTimeRef = useRef();
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
  });
  const {
    onCall: DocumentCreate,
    reset: DocumentCreateReset,
    loading: DocumentCreateLoading,
  } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });
  const {
    onCall: DocumentDelete,
    reset: DocumentDeleteReset,
    loading: DocumentDeleteLoading,
  } = useDocumentAxios({
    api: apis.deleteDocument,
    method: "delete",
    baseURL: "document",
  });

  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleBeforeUpload = async (file, fieldType) => {
    const fileName = getFileExtension(file?.name);
    if ([...fieldType]?.includes(fileName)) {
    } else {
      message.error(
        <span className="messageText">{`Please Upload ${fieldType?.join(
          " or "
        )} files`}</span>
      );
      return Upload.LIST_IGNORE;
    }
    return false;
  };
  const handleFileDelete = async (
    fieldName,
    index,
    targetKey,
    targetValue,
    isRemove = false
  ) => {
    const deleteData = await DocumentDelete({
      data: {
        ids: [
          getObjectByFieldNameAndValue(fileData, targetKey, targetValue)
            ?.attachment_id,
        ],
      },
      params: {
        record_id: getObjectByFieldNameAndValue(
          fileData,
          targetKey,
          targetValue
        )?.uuid,
      },
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });

    if (deleteData?.error == false) {
      if (!isRemove) {
        setValue(
          `${fieldObject?.section_entity_column}.${index}.${fieldName}`,
          ""
        );
      }
      setFileData((prevData) => {
        return {
          ...prevData,
          [fieldObject?.section_entity_column]: (
            prevData[fieldObject?.section_entity_column] || []
          ).map((item, i) => {
            if (item[targetKey] == targetValue) {
              return {
                ...item,
                attachment_id: "",
                url: "",
                uploadFileName: "",
                fieldName: "",
                uuid: "",
              };
            }
            return item;
          }),
        };
      });
    }
  };
  const handleFileUpload = async (
    fieldObject,
    fieldName,
    uploadFile,
    documentKey,
    index,
    fieldElement
  ) => {
    let uniqueNo = uuidv4();
    if (
      ["incorporation", "gst_certificate", "logo"]?.includes(fieldName) &&
      getObjectByFieldNameAndValue(fileData, "fieldName", fieldName)
        ?.attachment_id
    ) {
      let attachment_id = getObjectByFieldNameAndValue(
        fileData,
        "fieldName",
        fieldName
      )?.attachment_id;
      handleFileDelete(fieldName, index, "attachment_id", attachment_id);
    }

    // if (
    //     Object.keys(fileData).includes(fieldObject?.section_entity_column) &&
    //     getObjectByFieldNameAndValue(fileData, "fieldName", fieldName)?.uuid
    // ) {
    //     let prevUuid = fileData?.[fieldObject?.section_entity_column]?.[index]?.uuid
    //     uniqueNo = prevUuid ? prevUuid : uuidv4();
    // } else {
    //     uniqueNo = uuidv4();
    // }
    const formData = new FormData();
    formData.append("files", uploadFile?.file);
    formData.append("name", documentKey);
    formData.append("record_type", "Document");
    formData.append("record_id", uniqueNo);

    const attachId = await DocumentCreate({
      data: formData,
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });
    if (attachId?.error == false) {
      await getBase64(uploadFile.file, (url) => {
        setValue(
          `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
          attachId?.data[0]
        );
        setFileData((prevData) => {
          const newData = { ...prevData };
          const columnName = fieldObject?.section_entity_column;
          const currentArray = newData[columnName] || [];
          // const existingItemIndex = currentArray.findIndex(
          //     (item) => item.fieldName === fieldName && item?.uuid == uniqueNo
          // );
          // const existingItemIndex = currentArray.findIndex((item) => item?.uuid == uniqueNo);
          // if (existingItemIndex !== -1) {
          //     // Update existing item
          //     currentArray[existingItemIndex] = {
          //         ...currentArray[existingItemIndex],
          //         attachment_id: attachId?.data[0],
          //         url: url,
          //         uploadFileName: uploadFile?.file?.name,
          //     };
          // } else {
          //     // Add new item
          //     currentArray.push({
          //         fieldName: fieldName,
          //         attachment_id: attachId?.data[0],
          //         uuid: uniqueNo,
          //         url: url,
          //         uploadFileName: uploadFile?.file?.name,
          //     });
          // }
          // Add new item
          currentArray.push({
            fieldName: fieldName,
            attachment_id: attachId?.data[0],
            uuid: uniqueNo,
            url: url,
            uploadFileName: uploadFile?.file?.name,
          });

          newData[columnName] = currentArray?.filter(
            (items) => items?.attachment_id
          );
          return newData;
        });
      });
      DocumentCreateReset();
    }
  };
  console.log("business kyc fields fileData", fileData);
  const handleRemoveList = (indexValue, targetKey, targetValue) => {
    if (indexValue >= 1) {
      const getFieldsValues = getValues();
      getFieldsValues[fieldRenderData?.section_entity_column]?.splice(
        indexValue,
        1
      );
      getFieldsValues[`${fieldRenderData?.section_entity_column}`]?.forEach(
        (element, index) => {
          setValue(`${fieldRenderData?.section_entity_column}.${index}`, {
            ...getFieldsValues[fieldRenderData?.section_entity_column]?.[index],
          });
        }
      );
      setFieldRenderData((prevData) => {
        const newData = JSON.parse(JSON.stringify(prevData));
        const fields = newData?.section_page_fields?.fields;
        fields?.splice(indexValue, 1);
        return newData;
      });

      setFileData((prevData) => {
        return {
          ...prevData,
          [fieldObject?.section_entity_column]: (
            prevData[fieldObject?.section_entity_column] || []
          ).map((item, i) => {
            if (item?.[targetKey] == targetValue) {
              // setValue(
              //     `${fieldObject?.section_entity_column}.${indexValue}.${item?.fieldName}`,
              //     ""
              // );
              if (targetValue) {
                handleFileDelete(
                  item?.fieldName,
                  indexValue,
                  targetKey,
                  targetValue,
                  true
                );
              }
              return {
                ...item,
                attachment_id: "",
                url: "",
                uploadFileName: "",
              };
            }
            return item;
          }),
        };
      });
    }
  };
  function getObjectByFieldNameAndValue(obj, targetKey, targetValue) {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        const targetObject = obj[key].find(
          (item) => item[targetKey] === targetValue
        );

        if (targetObject) {
          return targetObject;
        }
      }
    }

    return null;
  }
  // const disableKeys = ["state", "city", "bank_branch", "bank_name", "bank_address", "business_country"]
  return (
    <>
      {(DocumentCreateLoading || DocumentDeleteLoading || loader) && (
        <div className="text-center loadingDiv">
          <PulseLoader color="#fff" />
        </div>
      )}
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <img
            src={isPreviewOpen?.url}
            alt="preview image"
            style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
          />
        )}
      </Modal>
      <div>
        <div className="business-registration-section">
          <div className="top-section">
            <KYCHeader
              Header={fieldObject?.section_page_fields?.page_header}
              SubHeader={fieldObject?.section_page_fields?.page_description}
            />
          </div>
          <div className="business-registration-container">
            {fieldObject?.section_page_fields?.fields?.map((element, index) => (
              <>
                {fieldObject?.section_page_fields?.is_multiple ? (
                  <div className={`header_section ${index >= 1 ? "mt5" : ""}`}>
                    <div className="header_container w75 flex-between">
                      <h4>{`${fieldObject?.section_page_fields?.page_sub_header
                        } (${index + 1})`}</h4>
                      {index >= 1 ? (
                        <button
                          className="remove_button"
                          type="button"
                          onClick={() => {
                            handleRemoveList(
                              index,
                              "attachment_id",
                              watch(
                                `${fieldObject?.section_entity_column
                                }.${index}.${"url"}`
                              )
                            );
                          }}
                        >
                          <DeleteOutlined />
                        </button>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {element?.fields_list?.map((fieldElement, fieldIndex) => {
                  const RegexValue = fieldElement?.validations?.regex
                    ? fieldElement?.validations?.regex?.replaceAll("/", "")
                    : fieldElement?.validations?.regex;
                  const Regex = new RegExp(RegexValue);
                  // fieldElement?.value && setValue(`${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`, fieldElement?.value);
                  switch (fieldElement?.data_type) {
                    case "string":
                      return (
                        <>
                          <div className="pp-form-item businessFields">
                            <FormInput
                              control={control}
                              {...register(
                                `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                {
                                  pattern: fieldElement?.is_mandatory
                                    ? {
                                      value: Regex,
                                      message: `${fieldElement?.entity_column_label} is invalid`,
                                    }
                                    : null,
                                  required: fieldElement?.is_mandatory
                                    ? `${fieldElement?.entity_column_label} is required`
                                    : false,
                                  onChange: (e) => {
                                    if (
                                      fieldElement?.entity_column ===
                                      "pan_number" ||
                                      fieldElement?.entity_column ===
                                      "bank_ifsc_code" ||
                                      (fieldElement?.entity_column ===
                                        "card_number" &&
                                        fieldElement?.entity_column_label ===
                                        "PAN Number")
                                    ) {
                                      setValue(
                                        `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                        e?.target?.value?.toUpperCase()
                                      );
                                    } else {
                                      setValue(
                                        `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                        e?.target?.value
                                      );
                                    }
                                    if (
                                      fieldElement?.api_fetch &&
                                      ((fieldElement?.api_type ==
                                        "bank_details" &&
                                        e?.target?.value?.length > 8) ||
                                        (fieldElement?.api_type == "zipcode" &&
                                          e?.target?.value?.length > 4))
                                    ) {
                                      if (setTimeRef.current) {
                                        clearTimeout(setTimeRef.current);
                                      }
                                      setTimeRef.current = setTimeout(() => {
                                        fetchThirdPartyAPIs(
                                          fieldElement?.api_type,
                                          e?.target?.value,
                                          setLoader,
                                          setValue,
                                          `${fieldObject?.section_entity_column}.${index}`,
                                          clearErrors
                                        );
                                      }, 700);
                                    }
                                    if (
                                      fieldElement?.api_fetch &&
                                      fieldElement?.api_type ==
                                      "bank_details" &&
                                      e?.target?.value?.length == 0
                                    ) {
                                      setValue(
                                        `${fieldObject?.section_entity_column
                                        }.${index}.${"bank_name"}`,
                                        ""
                                      );
                                      setValue(
                                        `${fieldObject?.section_entity_column
                                        }.${index}.${"bank_branch"}`,
                                        ""
                                      );
                                    } else if (
                                      fieldElement?.api_fetch &&
                                      fieldElement?.api_type == "zipcode" &&
                                      e?.target?.value?.length == 0
                                    ) {
                                      setValue(
                                        `${fieldObject?.section_entity_column
                                        }.${index}.${"state"}`,
                                        ""
                                      );
                                      setValue(
                                        `${fieldObject?.section_entity_column
                                        }.${index}.${"city"}`,
                                        ""
                                      );
                                    }
                                  },
                                }
                              )}
                              errorMessage={
                                errors?.[fieldObject?.section_entity_column]?.[
                                  index
                                ]?.[fieldElement?.entity_column]?.message
                              }
                              required={fieldElement?.is_mandatory}
                              errors={errors}
                              label={fieldElement?.entity_column_label}
                            />
                          </div>
                        </>
                      );
                    case "number":
                      return (
                        <>
                          <div className="pp-form-item businessFields">
                            <FormInput
                              control={control}
                              {...register(
                                `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                {
                                  pattern: fieldElement?.is_mandatory
                                    ? {
                                      value: Regex,
                                      message: `${fieldElement?.entity_column_label} is invalid`,
                                    }
                                    : null,
                                  required: fieldElement?.is_mandatory
                                    ? `${fieldElement?.entity_column_label} is required`
                                    : false,
                                  onChange: (e) => {
                                    let inputValue = !isNaN(e?.target?.value)
                                      ? Number(e?.target?.value)
                                      : e?.target?.value;
                                    setValue(
                                      `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                      inputValue
                                    );
                                    if (fieldElement?.api_fetch) {
                                      if (setTimeRef.current) {
                                        clearTimeout(setTimeRef.current);
                                      }
                                      setTimeRef.current = setTimeout(() => {
                                        fetchThirdPartyAPIs(
                                          fieldElement?.api_type,
                                          e?.target?.value,
                                          setLoader,
                                          setValue,
                                          `${fieldObject?.section_entity_column}.${index}`,
                                          clearErrors
                                        );
                                      }, 700);
                                    }
                                  },
                                }
                              )}
                              errorMessage={
                                errors?.[fieldObject?.section_entity_column]?.[
                                  index
                                ]?.[fieldElement?.entity_column]?.message
                              }
                              required={fieldElement?.is_mandatory}
                              errors={errors}
                              label={fieldElement?.entity_column_label}
                              number={true}
                            />
                          </div>
                        </>
                      );
                    case "dropdown":
                      return (
                        <>
                          <div className="pp-form-item businessFields">
                            <FormSelect
                              inline
                              control={control}
                              {...register(
                                `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                {
                                  required: fieldElement?.is_mandatory
                                    ? `${fieldElement?.entity_column_label} is required`
                                    : false,
                                  onChange: (e) => {
                                    setValue(
                                      `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                      e?.target?.value
                                    );
                                  },
                                }
                              )}
                              errors={errors}
                              required={fieldElement?.is_mandatory}
                              label={fieldElement?.entity_column_label}
                              hideCreateOption={true}
                              errorMessage={
                                errors?.[fieldObject?.section_entity_column]?.[
                                  index
                                ]?.[fieldElement?.entity_column]?.message
                              }
                              placeholder="Select"
                              options={
                                fieldElement?.entity_column ===
                                  "business_country"
                                  ? allCountries
                                  : fieldElement?.dropdown_fields
                              }
                            />
                          </div>
                        </>
                      );
                    case "date":
                      return (
                        <>
                          <div className="pp-form-item businessFields">
                            <DateInput
                              control={control}
                              {...register(
                                `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                {
                                  required: fieldElement?.is_mandatory
                                    ? `${fieldElement?.entity_column_label} is required`
                                    : false,
                                  onChange: (e) => {
                                    // setValue(`${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`, e?.target?.value)
                                    setValue(
                                      `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                      e?.target?.value
                                    );
                                  },
                                }
                              )}
                              required={fieldElement?.is_mandatory}
                              errorMessage={
                                errors?.[fieldObject?.section_entity_column]?.[
                                  index
                                ]?.[fieldElement?.entity_column]?.message
                              }
                              errors={errors}
                              label={fieldElement?.entity_column_label}
                              dateFormat={
                                fieldElement?.picker == "year"
                                  ? ["YYYY"]
                                  : ["DD-MM-YYYY"]
                              }
                              maxDate={fieldElement?.max_date}
                              picker={fieldElement?.picker}
                              isTime={false}
                            />
                          </div>
                        </>
                      );
                    case "document":
                      if (
                        ["upload documents"]?.includes(
                          fieldElement?.group?.toLowerCase()
                        )
                      ) {
                        return (
                          <>
                            <div className="pp-form-item businessFields squareUpload p0 mb2">
                              <SquareUpload
                                control={control}
                                {...register(
                                  `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                  {
                                    required: fieldElement.is_mandatory
                                      ? `${fieldElement?.entity_column_label} is required`
                                      : false,
                                    onChange: (e) => {
                                      handleFileUpload(
                                        fieldObject,
                                        fieldElement?.entity_column,
                                        e.target.value,
                                        fieldElement?.document_key,
                                        index,
                                        fieldElement
                                      );
                                    },
                                  }
                                )}
                                required={fieldElement.is_mandatory}
                                errorMessage={
                                  errors?.[
                                    fieldObject?.section_entity_column
                                  ]?.[index]?.[fieldElement?.entity_column]
                                    ?.message
                                }
                                errors={errors}
                                label={fieldElement?.entity_column_label}
                                multiple={false}
                                beforeUpload={(e) =>
                                  handleBeforeUpload(
                                    e,
                                    fieldElement?.file_type,
                                    fieldElement?.entity_column
                                  )
                                }
                                isBusinessKYC={true}
                                accept={fieldElement?.file_type}
                                // fileURL={(fileData?.[fieldObject?.section_entity_column]?.[fieldIndex]?.attachment_id && fileData?.[fieldObject?.section_entity_column]?.[fieldIndex]?.fieldName === fieldElement?.entity_column) ? fileData?.[fieldObject?.section_entity_column]?.[fieldIndex]?.url : null}
                                fileURL={getObjectByFieldNameAndValue(
                                  fileData,
                                  "fieldName",
                                  fieldElement?.entity_column
                                )}
                              />
                              {
                                <div className="action_buttons justify-center">
                                  <Button
                                    icon={<EyeFilled />}
                                    onClick={() => {
                                      let fileobject =
                                        getObjectByFieldNameAndValue(
                                          fileData,
                                          "attachment_id",
                                          watch(
                                            `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`
                                          )
                                        );
                                      setIsPreviewOpen({
                                        open: true,
                                        fileType: getFileExtension(
                                          fileobject?.uploadFileName
                                        ),
                                        url: getObjectByFieldNameAndValue(
                                          fileData,
                                          "attachment_id",
                                          watch(
                                            `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`
                                          )
                                        )?.url,
                                      });
                                    }}
                                    disabled={
                                      !getObjectByFieldNameAndValue(
                                        fileData,
                                        "fieldName",
                                        fieldElement?.entity_column
                                      )?.attachment_id
                                    }
                                    className="mr1"
                                  />
                                  <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                      handleFileDelete(
                                        fieldElement?.entity_column,
                                        index,
                                        "fieldName",
                                        fieldElement?.entity_column
                                      );
                                    }}
                                    disabled={
                                      !getObjectByFieldNameAndValue(
                                        fileData,
                                        "fieldName",
                                        fieldElement?.entity_column
                                      )?.attachment_id
                                    }
                                  />
                                </div>
                                // Object.keys(fileData).includes(fieldObject?.section_entity_column) && getObjectByFieldNameAndValue(fileData, "fieldName", fieldElement?.entity_column)?.attachment_id && (
                                //     <div className='hover_section'>
                                //         <div className='active_section d-flex-center h100 w100'>
                                //             <div className='preview_section mr1' onClick={() => {
                                //                 setIsPreviewOpen({
                                //                     open: true,
                                //                     url: getObjectByFieldNameAndValue(fileData, "fieldName", fieldElement?.entity_column)?.url
                                //                 })
                                //             }}>
                                //                 <img src={preview_icon} alt="preview-icon" className='preview_img w100 h100' />
                                //             </div>
                                //             <div className='delete_section' onClick={() => {
                                //                 handleFileDelete(fieldElement?.entity_column, fieldIndex, "fieldName", fieldElement?.entity_column)
                                //             }}>
                                //                 <img src={delete_cross} alt="delete-icon" className='delete_img w100 h100' />
                                //             </div>
                                //         </div>
                                //     </div>
                                // )
                              }
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div className="pp-form-item buttonUpload pt0">
                              <ButtonUpload
                                control={control}
                                {...register(
                                  `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                  {
                                    required: fieldElement.is_mandatory
                                      ? `${fieldElement?.entity_column_label} is required`
                                      : false,
                                    onChange: (e) => {
                                      handleFileUpload(
                                        fieldObject,
                                        fieldElement?.entity_column,
                                        e.target.value,
                                        fieldElement?.document_key,
                                        index,
                                        fieldElement
                                      );
                                    },
                                  }
                                )}
                                required={fieldElement.is_mandatory}
                                errorMessage={
                                  errors?.[
                                    fieldObject?.section_entity_column
                                  ]?.[index]?.[fieldElement?.entity_column]
                                    ?.message
                                }
                                errors={errors}
                                label={fieldElement?.entity_column_label}
                                multiple={false}
                                beforeUpload={(e) =>
                                  handleBeforeUpload(
                                    e,
                                    fieldElement?.file_type,
                                    fieldElement?.entity_column
                                  )
                                }
                                isBusinessKYC={true}
                                accept={fieldElement?.file_type}
                                width={"75%"}
                              />
                            </div>
                            {Object.keys(fileData).includes(
                              fieldObject?.section_entity_column
                            ) &&
                              getObjectByFieldNameAndValue(
                                fileData,
                                "attachment_id",
                                watch(
                                  `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`
                                )
                              )?.attachment_id && (
                                <div className="business_image_section mt3">
                                  <div className="business_image_container mb1">
                                    <div className="left_section d-flex">
                                      <div className="image_section mr1">
                                        {(() => {
                                          let fileobject =
                                            getObjectByFieldNameAndValue(
                                              fileData,
                                              "attachment_id",
                                              watch(
                                                `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`
                                              )
                                            );
                                          if (
                                            ["pdf"]?.includes(
                                              getFileExtension(
                                                fileobject?.uploadFileName
                                              )?.toLowerCase()
                                            )
                                          ) {
                                            return (
                                              <img
                                                src={png_icon}
                                                className="w100 h100"
                                              />
                                            );
                                          } else {
                                            return (
                                              <img
                                                src={fileobject?.url}
                                                className="w100 h100"
                                              />
                                            );
                                          }
                                        })()}
                                      </div>
                                      <div className="image_name justify-center flex-col">
                                        <h4 className="file_name">
                                          {
                                            getObjectByFieldNameAndValue(
                                              fileData,
                                              "attachment_id",
                                              watch(
                                                `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`
                                              )
                                            )?.uploadFileName
                                          }
                                        </h4>
                                        {/* <span className='file_verify'>Verify</span> */}
                                      </div>
                                    </div>
                                    <div className="right_section">
                                      <div className="active_section d-flex">
                                        <div
                                          className="preview_section mr1"
                                          onClick={() => {
                                            let fileobject =
                                              getObjectByFieldNameAndValue(
                                                fileData,
                                                "attachment_id",
                                                watch(
                                                  `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`
                                                )
                                              );
                                            setIsPreviewOpen({
                                              open: true,
                                              fileType: getFileExtension(
                                                fileobject?.uploadFileName
                                              ),
                                              url: getObjectByFieldNameAndValue(
                                                fileData,
                                                "attachment_id",
                                                watch(
                                                  `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`
                                                )
                                              )?.url,
                                            });
                                          }}
                                        >
                                          <img
                                            src={preview_icon}
                                            alt="preview-icon"
                                            className="preview_img w100 h100"
                                          />
                                        </div>
                                        <div
                                          className="delete_section"
                                          onClick={() => {
                                            handleFileDelete(
                                              fieldElement?.entity_column,
                                              index,
                                              "attachment_id",
                                              watch(
                                                `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`
                                              )
                                            );
                                          }}
                                        >
                                          <img
                                            src={delete_cross}
                                            alt="delete-icon"
                                            className="delete_img w100 h100"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </>
                        );
                      }
                    case "checkbox":
                      return (
                        <>
                          <div
                            className="pp-form-item businessFields"
                            style={{ display: "block" }}
                          >
                            <FormCheckbox
                              control={control}
                              {...register(
                                `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`
                              )}
                              errors={errors}
                              label={fieldElement?.entity_column_label}
                              options={fieldElement?.checkbox_fields}
                            />
                          </div>
                        </>
                      );
                    case "radio":
                      return (
                        <>
                          <div
                            className="pp-form-item businessFields"
                            style={{ display: "block" }}
                          >
                            <FormRadio
                              control={control}
                              {...register(
                                `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                {
                                  onChange: (e) => {
                                    setValue(
                                      `${fieldObject?.section_entity_column}.${index}.${fieldElement?.entity_column}`,
                                      e?.target?.value
                                    );
                                  },
                                }
                              )}
                              errors={errors}
                              label={fieldElement?.entity_column_label}
                              options={fieldElement?.radio_fields}
                            />
                          </div>
                        </>
                      );
                  }
                })}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessKYCFields;
