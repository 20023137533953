import axios from 'axios';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getCompanyCountries } from '../../actions/companySettings';
import { FormInput, FormSelect } from '../inputs';
import { countries } from "../Settings/Organisation/country"
import { useTranslation } from 'react-i18next';

const BillingAddress = ({ control, errors, register, billing_address, setValue }) => {
  const dispatch = useDispatch();
  const pinRef = useRef()

  const { fields } = useFieldArray({
    control,
    name: 'billing_address',
  });
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getCompanyCountries());
  }, [])



  const handleSearchCountry = (value, country, index) => {
    if (pinRef.current) {
      clearTimeout(pinRef.current)
    }
    pinRef.current = setTimeout(() => {
      axios.get("https://api.worldpostallocations.com/pincode?postalcode=" + value + "&countrycode=" + countries?.find(ele => ele.label === country)?.code + "").then(
        response => {
          setValue(`billing_address.${index}.state`, response?.data?.result?.[0]?.state)
        })
    }, 700)

  }

  return (
    <>
      {fields.map((personData, index) => (
        <div key={`billing-${index}`}>
          <div className={index >= 1 ? `pp-form-item` : null}>
            <FormSelect
              control={control}
              {...register(`billing_address.${index}.country`)}
              label={t('country_region')}
              placeholder={t('select')}
              options={countries}
              hideCreateOption={true}
              errorMessage={errors.billing_address?.[index]?.['country']?.['message']}
            />
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.zipcode`, {
                onChange: (e) => {
                  handleSearchCountry(e.target.value, billing_address?.[index]?.country, index)
                }
              })}
              label="Zip"
              errorMessage={errors.billing_address?.[index]?.['zipcode']?.['message']}
            />
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.address`)}
              label={t('address')}
              placeholder="Street"
              errorMessage={errors.billing_address?.[index]?.['address']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.city`)}
              label={t('city')}
              errorMessage={errors.billing_address?.[index]?.['city']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.state`)}
              label={t('state')}
              errorMessage={errors.billing_address?.[index]?.['state']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              number={true}
              control={control}
              {...register(`billing_address.${index}.phone`)}
              label={t('phone')}
              errorMessage={errors.billing_address?.[index]?.['phone']?.['message']}
            />
          </div>

          {/* <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.fax`)}
              label="Fax"
              errorMessage={errors.billing_address?.[index]?.['fax']?.['message']}
            />
          </div> */}
        </div>
      ))}
    </>
  );
};

export default BillingAddress;
