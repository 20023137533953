import {
  CloudUploadOutlined, PlusCircleFilled
} from "@ant-design/icons";
import { Badge, Col, Menu, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FetchAllBillingDetails,
  fetchAllBillingDetails
} from "../../../actions/bills";
import { headerTitles, SideMenu } from "../../../actions/masterdata/masterdata";
import { amountFormat, convertString, hasAccess } from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import TableComponent from "../../Common/TableComp/TableComp";
import OcrUpload from "../../OCR/OcrUpload";
import TableHeader from "../../Sales/TableHeader";


const MyBills = ({ user, isPO, hasWriteAccess }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [moduleName, setModuleName] = React.useState({
    type: isPO ? "Purchase Order" : "Bill"
  })
  const totalRecords = useSelector(
    (state) => state.bills.fetchAllBillingDetails?.data?.total_count
  );
  useEffect(() => {
    let timeout = setTimeout(() => {
      dispatch(fetchAllBillingDetails({
        module_type: isPO ? t('purchase') : t('BIL'),
        page_number: page
      }));
      setFilters({})
    }, [600])

    dispatch(
      headerTitles({
        title: isPO ? 'purchase_order' : 'bills',
        description:
          isPO ? 'buyer_sell' : 'bill_document',
      })
    );

    let locationSplit = location?.pathname?.split("/")
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: "/" + locationSplit?.[2],
        showChildren: true,
        childrenMenu: "/my-bills"
      }
    })
    return () => {
      clearTimeout(timeout)
      dispatch({
        type: FetchAllBillingDetails.RESET
      })
    }
  }, [location.pathname]);

  const billsResponse = useSelector(
    (state) => state.bills.fetchAllBillingDetails || {}
  );
  // const sharedOnCell = (_, index) => {
  //   if (index === 4) {
  //     return {
  //       colSpan: 0,
  //     };
  //   }
  //   return {};
  // };


  const columns = [
    {
      title: isPO ? t('purchase_hash') : t('bill_hash'),
      dataIndex: "billing_no",
      key: "billing_no",
      render: (text, record) => (
        <>
          {record?.integration_type &&
            <div className="base_badge">
              <Badge.Ribbon text={convertString(record?.integration_type)} placement={"start"} color="#3f81f4" />
            </div>
          }
          {text}
        </>
      ),
    },
    {
      title: t('vendor_name'),
      dataIndex: "vendor_name",
      key: "vendor_name",
    },
    isPO ? {} : {
      title: t('purchase_order'),
      dataIndex: "ref_purchase_order_no",
      key: "ref_purchase_order_no",
    },
    {
      title: isPO ? t('purchase_date') : t('date'),
      dataIndex: "billing_date",
      key: "billing_date",
      render: (text) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
    },
    {
      title: isPO ? t('delivery_date') : t('due_date'),
      dataIndex: "due_date",
      key: "due_date",
      render: (text) => (
        <div className={moment().diff(text) <= 0 ? "" : "table-due-date"}>
          {moment(text).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: t('Amount'),
      dataIndex: "total_payable_amount",
      key: "total_payable_amount",
      render: (text, record) => <div><BaseCurrency symbol_code={record?.currency_info?.iso_code} />{amountFormat(text, record?.currency_info?.decimal_scale)}</div>,

    },
    isPO ? {} : {
      title: t('balance_due'),
      dataIndex: "due_balance_amount",
      key: "due_balance_amount",
      render: (text, record) => <div><BaseCurrency symbol_code={record?.currency_info?.iso_code} />{amountFormat(text, record?.currency_info?.decimal_scale)}</div>,
    },
    {
      title: t('status'),
      dataIndex: "invoice_status",
      key: "invoice_status",
      render: (text) => (
        <div className={`status status_${text}`}>{text.replace(/_/g, " ")}</div>
      ),
    },
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button
    //         shape="default"
    //         icon={<WarningOutlined />}
    //         style={{ border: "none", color: "#E24C4B" }}
    //       />
    //       <Button
    //         shape="default"
    //         icon={<MessageOutlined />}
    //         style={{ border: "none", color: "#5B87F9" }}
    //       />
    //       <Button
    //         shape="default"
    //         icon={<MoreOutlined />}
    //         style={{ border: "none", color: "#5B87F9" }}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  const options = [
    {
      label: (
        <div className='table-menu-item'>
          <span className='menu-label'>
            {` Add New ${moduleName?.type}`}
          </span>
        </div>
      ),
      key: '1',
      icon: (
        <div >
          <PlusCircleFilled className="pp-icon-18" />
        </div>
      ),
    },
    {
      label: (
        <div className='table-menu-item'>
          <span
            className='menu-label'
          >
            {`Import ${moduleName?.type}`}
          </span>
        </div>
      ),
      key: '2',
      icon: (
        <div>
          <CloudUploadOutlined className="pp-icon-18" />
        </div>
      ),
    },
    // {
    //   label: (
    //     <div className='table-menu-item'>
    //       <span
    //         className='menu-label'
    //       >
    //         {`Upload ${moduleName?.type}`}
    //       </span>
    //     </div>
    //   ),
    //   key: '3',
    //   icon: (
    //     <div>
    //       <CloudUploadOutlined className="pp-icon-18" />
    //     </div>
    //   ),
    // },
  ];

  const menu = (
    <Menu onClick={(e) => {
      if (e?.key == 1) {
        history.push(isPO ? "/purchases/orders/create-new-bill" : "/purchases/bills/create-new-bill", { from: location.pathname })
      } else if (e?.key == 2) {
        history.push(isPO ? "/purchase/order/upload/section" : "/bill/notes/upload/section")
      }
    }}>
      {options.map((ele) => (
        <Menu.Item
          key={ele.key}
          icon={ele.icon}
          onClick={() => (ele.onAction ? ele.onAction() : null)}
        >
          {ele.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const sortOptions = [
    {
      label: isPO ? t('purchase_date') : t('date'),
      filter: "billing_date"
    },
    // {
    //   label: isPO ? t('po_number') : t('no_bill'),
    //   filter: "billing_no"
    // },
    {
      label: t('vendor_name'),
      filter: "vendor_name"
    },
    {
      label: t('Amount'),
      filter: "total_payable_amount"
    },
    isPO ? {
      label: t('delivery_date'),
      filter: "due_date"
    } : {
      label: t('due_date'),
      filter: "due_date"
    },
    // {
    //   label: "Import Purchase Orders",
    //   filter: "import_purchase_orders"
    // },
    // {
    //   label: "Export Purchase Orders",
    //   filter: "export_purchase_orders"
    // },
  ];


  const handleSelectRow = (event, record) => {
    history.push(isPO ? `/purchases/orders/editbill` : `/purchases/editbill`, {
      id: record.id,
      record,
      from: location.pathname,
    });
  };

  const handleSearch = (searchKey) => {
    dispatch(
      fetchAllBillingDetails({
        ...filters,
        search_key: searchKey,
        module_type: isPO ? 'PURCHASE_ORDER' : 'BILL'
      })
    );
    setPage(1)
    setFilters({
      ...filters,
      search_key: searchKey
    })
  };

  const handleFilter = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }
    if (value) {
      if (value === "all") {
        dispatch(fetchAllBillingDetails({
          module_type: isPO ? 'PURCHASE_ORDER' : 'BILL'
        }))
        setFilters({});
      } else {
        dispatch({
          type: FetchAllBillingDetails.RESET,
        });
        dispatch(
          fetchAllBillingDetails({
            ...filters,
            filters: { invoice_status: [value] },
            isFilter: true,
            module_type: isPO ? 'PURCHASE_ORDER' : 'BILL'
          })
        );
        setFilters({
          ...filters,
          filters: { invoice_status: [value] },
        });
      }
    }
    setPage(1);
  };

  const handleSort = (value) => {

    if (location?.state?.status) {
      history.replace(location?.pathname);
    }
    if (value) {
      dispatch({
        type: FetchAllBillingDetails.RESET,
      });
      dispatch(fetchAllBillingDetails({

        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
        isFilter: true,
        module_type: isPO ? 'PURCHASE_ORDER' : "BILL"
      }));
      setFilters({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
      });
    }
    setPage(1);
  };

  const filterOptions = isPO ? [
    { label: t('all'), filter: "all" },
    { label: t('pending_approval'), filter: "pending_approval" },
    { label: t('approved'), filter: "approved" },
    { label: t('rejected'), filter: "rejected" },
    { label: t('un_submitted'), filter: "unsubmitted" },
    { label: t('overDue'), filter: "overdue" },
    { label: t('decline'), filter: "declined" },
    { label: t('accept'), filter: "accepted" },
    // { label: t('partillay_paid'), filter: "partially_paid" },
  ] : [
    { label: t('all'), filter: "all" },
    { label: t('pending_approval'), filter: "pending_approval" },
    { label: t('approved'), filter: "approved" },
    { label: t('rejected'), filter: "rejected" },
    { label: t('un_submitted'), filter: "unsubmitted" },
    { label: t('overDue'), filter: "overdue" },
    // { label: t('partillay_paid'), filter: "partially_paid" },
  ];

  const setOpenAddNew = () => {

    hasWriteAccess && history.push(isPO ? "/purchases/orders/create-new-bill" : "/purchases/bills/create-new-bill", { from: location.pathname })
  }

  return (
    <>
      {/* <NewBill
        open={openAddNew}
        onClose={() => {
          setOpenAddNew(false);
          dispatch(fetchAllBillingDetails());
        }}
      /> */}

      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              noDD={false}
              filterMenu={filterOptions}
              handleFilter={handleFilter}
              setOpenAddNew={setOpenAddNew}
              noAddNewBtn={!user && !hasWriteAccess}
              sortOptions={sortOptions}
              handleSort={handleSort}
              onSearch={handleSearch}
              menu={menu}
              hasWriteAccess={hasWriteAccess}

            />
            <div
              className={billsResponse?.loading ? "shimmer" : ""}
              style={{ paddingTop: "22px" }}
            >
              {/* <div className="flex-between scan-failed">
                <p>
                  <img src={scan_failed} width={14} /> Scanning failed for 2 receipts.
                </p>
                <span className="link">View</span>
              </div>
              <div className="flex-between scan-progress">
                <p>
                  <img src={scan} width={12} /> Scanning is in progress for 1 receipts.
                </p>
                <span className="link">View</span>
              </div> */}
              <TableComponent
                columns={columns}
                hasCheckbox={false}
                loading={billsResponse?.loading}
                data={
                  Array.isArray(billsResponse?.data?.data)
                    ? billsResponse?.data?.data
                    : []
                }
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleRowClick={handleSelectRow}
                rowKey="id"
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(
                      fetchAllBillingDetails({
                        ...filters,
                        page_number: pagee,
                        module_type: isPO ? t('purchase') : t('BIL'),
                      })
                    );
                    setPage(pagee)
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <OcrUpload />
    </>
  );
};

export default MyBills;
