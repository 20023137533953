import { Modal } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { apis } from '../../config/APIs';
import { useTranslation } from 'react-i18next';
import endpointPayApi from '../../config/AxiosPayApi';

const AddNewPrimaryAcc = ({ open, onClose }) => {
  const [iframeLink, setIframeLink] = useState("")
  const {t} = useTranslation();
  useEffect(() => {
    if (open)
      endpointPayApi.post(apis.connectToBank, {
        user: {
          "customerUserId": localStorage.getItem("user_id"),
          "firstName": "testfirst",
          "lastName": "testlast",
          "email": "test@tg.com",
          "userIpAddress": "77.69.179.162",
          "lastLoginTime": "2022-03-31T01:59:30.208+00:00"
        },
        redirectUrl: "https://dashboard.forgocards.com/consent"
      }).then((res) => {
        setIframeLink(res.data?.data?.connectUrl)
      })
  }, [open])
  return (
    <div>
      <Modal visible={open} className="right-alinged-modal" onCancel={onClose} title={t('connect_to_bank')}>
        {iframeLink ? <iframe src={iframeLink} style={{
          width: "100%",
          height: "700px",
          border: "none",
          overflow: "hidden"
        }} /> :
          <div>
           {t('connect_bank')}
          </div>}
      </Modal>
    </div>
  );
};

export default AddNewPrimaryAcc;