import { Button, Upload } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
// import plusIcon from "../../assets/icons/plus-icon.svg";
import png_icon from "../../assets/icons/pdf_file_icon.png";
import plusIcon from "../../components/OnBoarding/assets/icons/plus-icon.svg";
import uploadIcon from "../../components/OnBoarding/assets/icons/upload-icon.svg";
import { getFileExtension } from "../../utils";
import "./form.css";

const SquareUpload = ({
  value,
  label,
  required,
  errorMessage,
  errors,
  horizontal = false,
  beforeUpload,
  handleChange,
  multiple = false,
  accept,
  fileURL,
  ...restOfProps
}) => {
  const { Dragger } = Upload;
  return (
    <div className="business_upload">
      <Controller
        {...restOfProps}
        shouldUnregister={true}
        render={({ field: { onChange, value, name } }) => (
          <div
            style={{
              alignItems: horizontal && "center",
              display: horizontal && "flex",
            }}
          >
            <div
              style={{
                paddingTop: !horizontal && "0px",
                paddingLeft: horizontal && "10px",
              }}
            >
              <div className="upload-documents-section">
                <span className="upload-documents-section-label">{label}</span>
                <Dragger
                  showUploadList={false}
                  maxCount={1}
                  beforeUpload={beforeUpload}
                  className="upload_file"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  name={name}
                  value={value ? value : ""}
                  // maxCount={1}
                  multiple={multiple}
                  accept={accept}
                >
                  {fileURL?.url ? (
                    <div
                      className="image_section w100"
                      style={{ height: "17rem" }}
                    >
                      {(() => {
                        if (
                          ["pdf"]?.includes(
                            getFileExtension(
                              fileURL?.uploadFileName
                            )?.toLowerCase()
                          )
                        ) {
                          return <img src={png_icon} className="w50 h100" />;
                        } else {
                          return (
                            <img src={fileURL?.url} className="w100 h100" />
                          );
                        }
                      })()}
                    </div>
                  ) : (
                    <>
                      <p className="ant-upload-drag-icon upload-icon">
                        <img src={uploadIcon} alt="upload-icon" />
                      </p>
                      <p className="ant-upload-text upload-text justify-content-center">
                        <img src={plusIcon} alt="plus-icon" />
                        <span style={{ fontSize: "14px", color: "#3B3B3B" }}>
                          {label}
                          {required && (
                            <sup
                              style={{
                                color: "red",
                                fontSize: "14px",
                                position: "relative",
                                top: "-1px",
                              }}
                            >
                              *
                            </sup>
                          )}
                        </span>
                      </p>
                      <p className="ant-upload-hint upload-hint">
                        Drop your files here or
                      </p>
                      <p className="upload-button mb2">
                        <Button className="mAuto">
                          <span>Choose file</span>
                        </Button>
                      </p>
                    </>
                  )}
                </Dragger>
                {errors?.[restOfProps?.name]?.message || errorMessage ? (
                  <div className="errorMsg">
                    {errors?.[restOfProps?.name]?.message || errorMessage}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default SquareUpload;
