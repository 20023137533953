import { Button, message } from "antd";
import moment from "moment";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateBillingDetail,
  FetchBillingDetailsById,
  SubmitBillingDetails,
  createBillingDetail,
  submitBillingDetails
} from "../../../actions/bills/bills";
import {
  SideMenu,
  get_currencies,
  get_seed_data,
  headerTitles,
} from "../../../actions/masterdata/masterdata";
import { vendorsByName } from "../../../actions/purchases";

import {
  convertCurrency,
  showFile,
  uploadFile
} from "../../../utils";
import AddCategory from "../../Common/AddCategory";
import AddPayTermsModal from "../../Common/AddPayTerms";
import AddtTaxModal from "../../Common/AddTax";

import { useHistory, useLocation } from "react-router-dom";
import { categories } from "../../../actions/categories";
import "../../../assets/css/bill.css";
import { apis } from "../../../config/APIs";
import endpointSettingsApi from "../../../config/AxiosSettingsApi";
import usePayAxios from "../../../config/useAxios";
import useForgoCardsAxios from "../../../config/useForgoCardsAxios";
import AddVendor from "../AddVendor";
import NewBillForm from "./NewBillForm";
import useScrollToError from "../../../config/useScrollToError";
import { ExpenseCustomDetails, ExpenseFields, expenseCustomDetails, expenseFields } from "../../../actions/expenses/expenses";

const NewBill = ({ isPO }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [listFiles, setListFiles] = useState([]);
  const [removedItemsData, setRemovedItemsData] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [loadingRef, setLoadingRef] = useState(false);
  const { data, onCall } = usePayAxios({
    api: apis.hierarchyOptions,
    method: "get",
  });

  const [isSubmit, setIsSubmit] = useState(false);
  const [openAddNewVendor, setOpenAddNewVendor] = useState(false);
  const [exchange, setExchange] = useState({});
  const [hasExchange, setHasExchange] = useState(false);
  const [noExError, setNoExError] = useState("");
  const [cmp_cur_id, setCmpCurId] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isAddTax, setIsAddTax] = useState(false);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [dataBeneficiary, setDataBeneficiary] = useState({})
  // edit start
  const saveSubmit = useSelector(
    (state) => state.bills.submitBillingDetails || {}
  );
  const { data: prefix, onCall: getPrefix } = usePayAxios({
    api: apis.createPrefixVendor,
    method: "post",
  });
  const { onCall: customFields, data: custom_fields_values } =
    useForgoCardsAxios({
      api: apis.customFieldValues,
      method: "get",
      payApi: "settings",
    });
  const { onCall: bill_series_check } = usePayAxios({
    api: apis.billSeriesCheck,
    method: "post",
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, submitCount },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      vendor_info: "",
      beneficiary_id: "",
      vendor_name: "",
      billing_date: moment(),
      billing_no: "",
      ref_purchase_order_no: "",
      payment_terms: "",
      place_of_supply: "",
      gl_category: "",
      gl_code: "",
      transaction_ref_details: "",
      total_amount: 0,
      total_tax_amount: 0,
      total_discount: 0,
      total_payable_amount: 0,
      tds_type: "",
      tds_amount: 0,
      currency: "INR",
      due_date: "",
      vendor_billing_items: [],
      items: {},
    },
    shouldUseNativeValidation: false,
  });

  // Use the custom hook
  useScrollToError(Object.keys(errors)[0], errors, submitCount);
  const saveResponse = useSelector(
    (state) => state.bills.createBillingDetail || {}
  );
  useEffect(() => {
    setDataBeneficiary(location?.state?.dataBeneficiary)
  }, [location?.state?.dataBeneficiary])
  useEffect(() => {
    if (prefix?.error === true) {
      message.success(<div className="messageText">{prefix?.message}</div>)
    }
  }, [prefix])
  useEffect(() => {
    if (prefix?.data) {
      setValue("billing_no", prefix?.data);
    }
  }, [prefix]);
  useEffect(() => {
    watch("billing_no");
    watch("billing_date");
    watch("payment_terms");
    watch("due_date");
    let moduleName = "BILL_MODULE";
    let type = "bills";
    if (isPO) {
      moduleName = "PURCHASE_ORDER_MODULE";
      type = "purchase_orders";
    }
    customFields({
      params: {
        module_id: location.state?.id,
        module_type: moduleName?.replace("_MODULE", ""),
        pagination: false,
        custom_flag: true,
      },
    });
    if (!location?.state?.isEdit || location?.state?.convert) {
      getPrefix({
        params: {
          module_type: isPO ? "PURCHASE_ORDER" : "BILL",
        },
      });
    }

    dispatch(vendorsByName({ type: "vendor", status: "active" }));
    dispatch(
      headerTitles({
        title: isPO ? "Purchases" : "Bills",
        description: "bill_document",
      })
    );
    if (location?.state?.id) {

      try {
        showFile({
          record_type: "Vendor Billing Detail",
          record_id: location.state.id,
        }).then((dt) => {
          setListFiles(
            Array.isArray(dt.data)
              ? dt?.data?.map((ele, i) => ({
                uid: ele.id,
                name: ele.filename,
                url: ele.link,
                status: "done",
                thumbUrl: ele.link,
                isEdit: true,
              }))
              : []
          );
        });
      } catch (err) {
        console.error(err.message);
      }
    }

    if (location?.state?.setSideMenu) {
      let locationSplit = location?.pathname?.split("/");
      setValue("beneficiary_id", location?.state?.beneficiary_id);
      setValue("vendor_name", location?.state?.beneficiary_name);

      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
          childrenMenu: "/my-bills",
        },
      });
    }
    let seedType = "";
    if (isPO) {
      seedType = "purchase_order_hierarchy";
    } else {
      seedType = "bills_hierarchy";
    }

    onCall({
      params: {
        seed_type: seedType,
      },
    });
    dispatch(categories({ pagination: false }));
    // dispatch(
    //   fetchBillingDetailsById({
    //     billing_id: location?.state?.dataBeneficiary,
    //     module_type: location?.state?.convert
    //       ? location?.state?.from_module
    //       : isPO
    //         ? "PURCHASE_ORDER"
    //         : "BILL",
    //   })
    // );
    return () => {
      dispatch({
        type: SubmitBillingDetails.RESET,
      });
      dispatch({ type: CreateBillingDetail.RESET });
      dispatch({
        type: FetchBillingDetailsById.RESET,
      });
      setNoExError("");
    };
  }, []);

  useEffect(() => {
    if (saveResponse?.data?.error) {
      message.error(
        <span className="messageText">{saveResponse?.data?.message}</span>
      );
      dispatch({ type: CreateBillingDetail.RESET });
    }

    if (saveResponse?.data?.error === false) {
      if (dataBeneficiary?.beneficiary_id) {
        message.success(
          <span className="messageText">
            {isPO ? t("purchase_ord") : t("bill")} {t("created_success")}
          </span>
        );
      } else {
        !isSubmit &&
          message.success(
            <span className="messageText">
              {isPO ? t("purchase_ord") : t("bill")} {t("created_success")}
            </span>
          );
      }
      dispatch({ type: CreateBillingDetail.RESET });
      setRemovedItemsData([]);
      if (listFiles.length) {
        const formData = new FormData();
        Array.from(listFiles).forEach((ele) => {
          formData.append("files", ele);
        });

        formData.append("name", "documents")
        formData.append("record_type", "Vendor Billing Detail");
        formData.append("record_id", saveResponse.data.data);
        uploadFile(formData);
      }

      if (isSubmit) {
        let type = "BILL";
        if (isPO) {
          type = "PURCHASE_ORDER";
        }
        dispatch(
          submitBillingDetails({
            id: saveResponse?.data?.data,
            module_type: type,
          })
        );
        // dispatch({
        //   type: SubmitBillingDetails.RESET,
        // });
      } else {
        handleBack(location?.state?.to);
      }
    }
    // onClose();
  }, [saveResponse]);

  useEffect(() => {
    if (saveSubmit?.data?.error) {
      message.error(
        <span className="messageText">{saveSubmit?.data?.message}</span>
      );
      dispatch({
        type: SubmitBillingDetails.RESET,
      });
    }

    if (saveSubmit?.data?.error === false) {
      message.success(
        <span className="messageText">
          {isPO
            ? `Purchase Order ${t("save_submit")}`
            : `Bill ${t("save_submit")}`}
        </span>
      );
      dispatch({
        type: SubmitBillingDetails.RESET,
      });
      handleBack(location?.state?.to);
    }
  }, [saveSubmit]);

  const vendorsByNameData = useSelector(
    (state) => state.purchases.vendorsByName || {}
  );

  const onVendorSearch = (e) => {
    if (e && e.trim() !== "") {
      dispatch(
        vendorsByName({
          beneficiary_name: e.trim(),
          type: "vendor",
          status: "active",
        })
      );
    }
  };
  const onSubmit = async (data, event) => {
    if (!event.nativeEvent?.submitter?.id) {
      return;
    }
    if (event.nativeEvent?.submitter?.id === "save") {
      setIsSubmit(false);
    } else {
      setIsSubmit(true);
    }
    let payload = {
      beneficiary_id: data.beneficiary_id,
      vendor_name: data.vendor_name,
      billing_date: data.billing_date,
      billing_no: data.billing_no,
      ref_purchase_order_no: data.ref_purchase_order_no,
      payment_terms:
        data.payment_terms === "custom" ? 0 : Number(data.payment_terms),
      place_of_supply: data.place_of_supply,
      gl_category: data.gl_category,
      gl_code: data.gl_code,
      total_amount: parseFloat(data?.total_amount),
      total_tax_amount: parseFloat(data.total_tax_amount),
      total_discount: data.total_discount,
      total_payable_amount: parseFloat(data?.total_amount),
      tds_type: parseInt(data?.tds_type),
      tds_amount: parseFloat(data.tds_amount),
      currency: exchange?.convert_currency
        ? exchange?.convert_currency
        : data.currency,
      due_date: data.due_date,
      notes: data.notes,
      vendor_billing_items: data.vendor_billing_items.map((ele) => ({
        ...ele,
        bc_total_amount: convertCurrency(ele.total_amount, exchange),
        bc_tax_amount: convertCurrency(ele.tax_amount, exchange),
        bc_unit_price: convertCurrency(ele.unit_price, exchange),
        bc_discount_amount: convertCurrency(ele.discount_amount, exchange),
      })),
      customFields: data.customFields,
    };
    payload.vendor_billing_items =
      payload.vendor_billing_items?.concat(removedItemsData);
    payload.exchange_rate = exchange?.live_exchange_rates?.rate
      ? exchange?.live_exchange_rates?.rate
      : exchange?.manual_exchange_rates?.rate;
    payload.currency_datetime = exchange?.live_exchange_rates?.date
      ? exchange?.live_exchange_rates?.date
      : exchange?.manual_exchange_rates?.date;
    payload.bc_total_amount = convertCurrency(payload.total_amount, exchange);
    payload.bc_total_tax_amount = convertCurrency(
      payload.total_tax_amount,
      exchange
    );
    payload.bc_total_discount = convertCurrency(
      payload.total_discount,
      exchange
    );
    payload.company_currency_id = cmp_cur_id ? cmp_cur_id : vendorsByNameData.data?.data?.find(ele => ele.id === getValues().beneficiary_id)?.currency_info?.id;
    if (
      !location.state?.convert &&
      !location.state?.isClone &&
      dataBeneficiary &&
      Object.keys(dataBeneficiary).length
    ) {
      payload.id = dataBeneficiary.id;
      payload.company_id = dataBeneficiary.company_id;
    }

    if (location.state?.convert && dataBeneficiary?.id) {
      data.convert_id = dataBeneficiary?.id;
    }
    if (isPO) {
      payload.module_type = "PURCHASE_ORDER";
      delete payload.ref_purchase_order_no;
    } else {
      payload.module_type = "BILL";
    }
    let series_check_data = {
      billing_no: payload.billing_no,
    };
    if (
      !location.state?.convert &&
      !location.state?.isClone &&
      dataBeneficiary?.id
    ) {
      series_check_data.billing_id = dataBeneficiary.id;
    }

    bill_series_check({
      data: series_check_data,
      params: {
        module_type: payload.module_type,
      },
    }).then((res) => {
      if (res.data) {
        message.error(
          <span className="messageText">
            {isPO ? t("order_purchase") : t("bill_hash")} {t("already_present")}
          </span>
        );
      } else {
        dispatch(createBillingDetail(payload));
      }
    });
  };
  useEffect(() => {
    // dispatch(expenseFields({ module_type: isPO ? "PURCHASE_ORDER" : "BILL" }));
    // edit start
    // if (dataBeneficiary && dataBeneficiary?.beneficiary_id) {
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "place_of_supply",
      })
    );
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "payment_terms",
      })
    );
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "tds_types",
      })
    );
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "gl_category",
      })
    );
    dispatch(get_currencies());
    // }
    // edit end
    // vendorInfoWatch
  }, []);

  const setExchangeCurrency = (id) => {
    const vendor = vendorsByNameData.data?.data?.find((ele) => ele.id === id);
    setNoExError("");
    if (vendor?.currency_info?.id) {
      setHasExchange(
        vendor?.company_info?.currency_id !== vendor?.currency_info?.currency_id
      );
      setCmpCurId(vendor?.currency_info?.id);
      endpointSettingsApi
        .get(apis.getCompanyBaseCurrency, {
          params: { company_currency_id: vendor?.currency_info?.id },
        })
        .then((res) => {
          if (
            !res?.data?.data?.live_exchange_rates?.rate &&
            !res?.data?.data?.manual_exchange_rates?.rate
          ) {
            message.error(
              <span className="messageText">{res?.data?.message}</span>
            );
            setHasExchange(false);
            setNoExError(res?.data?.message);
          }
          setHasExchange(true);
          setExchange(res?.data?.data);
        })
        .catch((e) => {
          message.error(
            <span className="messageText">{e?.response?.data?.message}</span>
          );
        });
    } else {
      setHasExchange(false);
      setExchange({});
    }
  };
  useEffect(() => {
    if (dataBeneficiary && dataBeneficiary?.beneficiary_id) {
      setExchangeCurrency(dataBeneficiary?.beneficiary_id);
      setValue("beneficiary_id", dataBeneficiary?.beneficiary_id);
      setValue("vendor_info", dataBeneficiary?.vendor_name);
      setValue("vendor_name", dataBeneficiary?.vendor_name);
      setValue(
        "payment_terms",
        dataBeneficiary?.vendor_payment_terms?.term_name
      );
      setValue(
        "vendor_payment_terms.term_name",
        dataBeneficiary?.vendor_payment_terms?.term_name
      );
      if (!location?.state?.convert && !location.state?.isClone)
        setValue("billing_no", dataBeneficiary?.billing_no);
      setValue("billing_date", moment(dataBeneficiary?.billing_date));
      setValue(
        "ref_purchase_order_no",
        location?.state?.convert
          ? dataBeneficiary?.billing_no
          : dataBeneficiary?.ref_purchase_order_no
      );
      setValue("gl_category", dataBeneficiary?.gl_category);
      setValue("gl_code", dataBeneficiary?.gl_code);
      setValue("payment_terms", dataBeneficiary?.payment_terms == 0 ? "custom" : dataBeneficiary?.payment_terms ? dataBeneficiary?.payment_terms : null);
      setValue("place_of_supply", dataBeneficiary?.place_of_supply);
      setValue(
        "transaction_ref_details",
        dataBeneficiary?.transaction_ref_details
      );
      setValue("total_amount", dataBeneficiary?.total_amount);
      setValue("total_tax_amount", dataBeneficiary?.total_tax_amount);
      setValue("total_discount", dataBeneficiary?.total_discount);
      setValue("total_payable_amount", dataBeneficiary?.total_payable_amount);
      setValue("tax", dataBeneficiary?.vendor_tds_types?.tax_name);
      setValue("remarks", dataBeneficiary?.remarks);
      setValue("tds_amount", dataBeneficiary?.tds_amount);
      setValue("tds_type", dataBeneficiary?.tds_type);
      setValue("due_date", moment(dataBeneficiary?.due_date));
      setValue("vendor_billing_items", dataBeneficiary?.billing_items);
      setValue("notes", dataBeneficiary?.notes);
    }
  }, [dataBeneficiary]);
  const handlerNewVendor = () => {
    setOpenAddNewVendor(true);
  };
  const handleBack = (to) => {
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  };

  const c_fields = custom_fields_values?.data;

  return (
    <>
      {openAddNewVendor && (
        <AddVendor
          dataBeneficiary={dataBeneficiary}
          open={openAddNewVendor}
          onClose={() => {
            setOpenAddNewVendor(false);
          }}
          setBenId={(benId) => {
            setValue(benId);
          }}
          callOnSuccess={() => {
            dispatch(
              vendorsByName({
                beneficiary_name: "",
                type: "vendor",
                status: "active",
              })
            );
          }}
        />
      )}
      <AddPayTermsModal
        companyId={localStorage.getItem("company_id")}
        isVisible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
      />
      <AddtTaxModal
        companyId={localStorage.getItem("company_id")}
        isVisible={isAddTax}
        onCancel={() => {
          setIsAddTax(false);
        }}
      />
      <AddCategory
        companyId={localStorage.getItem("company_id")}
        isVisible={isAddCategory}
        onCancel={() => {
          setIsAddCategory(false);
        }}
      />

      <form className="new-bill-form" onSubmit={handleSubmit(onSubmit)}>
        <NewBillForm
          control={control}
          register={register}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          errors={errors}
          isPO={isPO}
          handleBack={handleBack}
          setExchangeCurrency={setExchangeCurrency}
          vendorsByNameData={vendorsByNameData}
          onVendorSearch={onVendorSearch}
          handlerNewVendor={handlerNewVendor}
          exchange={exchange}
          setIsVisible={setIsVisible}
          listFiles={listFiles}
          setListFiles={setListFiles}
          hasExchange={hasExchange}
          c_fields={c_fields}
          dataBeneficiary={dataBeneficiary}
          setRemovedItemsData={setRemovedItemsData}
          setLoadingRef={setLoadingRef}
        />
        <div className="bottom">
          {noExError && <div className="errorMsg">{noExError}</div>}
          <Button
            className="pp-secondary-button"
            style={{ marginLeft: "2rem" }}
            htmlType="button"
            onClick={() => handleBack()}
          >
            <span>{t("cancel")}</span>
          </Button>

          {data?.data?.[0]?.seed_value !== "without hierarchy" && (
            <Button
              type="primary"
              htmlType="submit"
              className="pp-main-button"
              disabled={saveResponse?.loading || saveSubmit?.loading || noExError || loadingRef}
              id="save"
            >
              <span>{location?.state?.isEdit ? t("update") : t("save")}</span>
            </Button>
          )}
          {!location?.state?.isEdit && (
            <Button
              type="primary"
              htmlType="submit"
              className="pp-main-button"
              disabled={
                saveResponse?.loading ||
                saveSubmit?.loading ||
                noExError ||
                loadingRef
              }
              id="save-and-submit"
            >
              <span>{"Save and Submit"}</span>
            </Button>
          )}
          {location?.state?.isEdit &&
            data?.data?.[0]?.seed_value === "without hierarchy" && (
              <Button
                type="primary"
                htmlType="submit"
                className="pp-main-button"
                id="save-and-submit"
                disabled={
                  saveResponse?.loading ||
                  saveSubmit?.loading ||
                  noExError ||
                  loadingRef
                }
              >
                <span>{t("update")}</span>
              </Button>
            )}
        </div>
      </form>
    </>
  );
};

export default NewBill;
