import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, message, Radio, Tabs } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  billApproval,
  getRoles,
  updateBillApproval,
  UpdateBillApproval,
} from "../../../actions/companySettings";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import { apis } from "../../../config/APIs";
import usePayAxios from "../../../config/useAxios";
import { objectToFormData } from "../../../utils";
import "./../styles/setting.css";
import AdvancedApproval from "./AdvancedApproval";
import HirarchicalApproval from "./HirarchicalApproval";

const { TabPane } = Tabs;

message.config({
  maxCount: 3,
});

const ApprovalBills = ({ invoice, inv_type, type, isMaterial, isDelivery, hasWriteAccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [billApprovalData, setBillApprovalData] = React.useState([]);
  const [rolesData, setRolesData] = React.useState([]);
  const [showApproval, setShowApproval] = React.useState(false);
  const [showAdvancedApproval, setShowAdvancedApproval] = React.useState(false);
  const [radioVal, setRadioVal] = React.useState(false);
  const billApprovalResponse = useSelector(
    (state) => state.approvals.bills || {}
  );
  const updateBillApprovalResponse = useSelector(
    (state) => state.approvals.update_bills || {}
  );
  const rolesResponse = useSelector(
    (state) => state.companySetting.getRoles || {}
  );
  const [editMode, setEditMode] = React.useState(false);
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const { data, onCall } = usePayAxios({
    api: apis.hierarchyOptions,
    method: "get",
  });
  const { onCall: updateOptions } = usePayAxios({
    api: apis.hierarchyUpdateOptions,
    method: "post",
  });
  const [
    hirarchicalApprovalCheckBoxTitle,
    setHirarchicalApprovalCheckBoxTitle,
  ] = React.useState("");
  const { data: { data: approvals = [], status = false } = {} } =
    billApprovalResponse;
  const setTab = () => { };

  React.useEffect(() => {
    let header_title = "";
    let seedType = "";
    if (type === "SALES_ORDER_APPROVAL") {
      header_title = "sale_order_approvals";
      seedType = "sales_order_hierarchy";
      setHirarchicalApprovalCheckBoxTitle("sale order");
    } else if (type === "ESTIMATE_APPROVAL") {
      header_title = "estimate_approvals";
      seedType = "estimates_hierarchy";
      setHirarchicalApprovalCheckBoxTitle("estimates");
    } else if (type === "PURCHASE_ORDER_APPROVAL") {
      header_title = "purchase_order_approvals";
      seedType = "purchase_order_hierarchy";
      setHirarchicalApprovalCheckBoxTitle("purchase order");
    } else if (type === "PAYMENT_APPROVAL") {
      header_title = "company_payments_approvals";
      seedType = "company_payments_hierarchy";
      setHirarchicalApprovalCheckBoxTitle("company payments");
    } else if (type === "BULK_PAYMENT_APPROVAL") {
      header_title = "company_bulk_payments_approvals";
      seedType = "company_bulk_payments_hierarchy";
      setHirarchicalApprovalCheckBoxTitle("company bulk payments");
    } else if (isDelivery) {
      header_title = "Delivery Challan";
      seedType = "delivery_challan_hierarchy";
      setHirarchicalApprovalCheckBoxTitle("delivery challan");
    } else if (isMaterial) {
      header_title = "Material Receipts";
      seedType = "material_receipt_hierarchy";
      setHirarchicalApprovalCheckBoxTitle("material receipt");
    } else {
      header_title = invoice ? "invoice_approvals" : "bill_approvals";
      seedType = invoice ? "invoice_hierarchy" : "bills_hierarchy";
      invoice
        ? setHirarchicalApprovalCheckBoxTitle("invoice")
        : setHirarchicalApprovalCheckBoxTitle("bills");
    }
    dispatch(
      billApproval({
        invoice,
        type,
        module_type: type
      })
    );
    onCall({
      params: {
        seed_type: seedType,
      },
    });
    dispatch(getRoles({}));
    dispatch(
      headerTitles({
        title: header_title,
        description: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      data?.data?.[0]?.seed_value === "with hierarchy" &&
      data?.data?.[0]?.seed_code?.includes("_1")
    ) {
      setRadioVal("hierarchical");
    } else if (
      data?.data?.[0]?.seed_value === "Custom Approval Flow" &&
      data?.data?.[0]?.seed_code?.includes("_2")
    ) {
      setRadioVal("advanced");
    } else if (data?.data?.[0]?.seed_value) {
      setRadioVal("no-approval");
    }
  }, [data]);

  React.useEffect(() => {
    if (status && approvals && !invoice) {
      setBillApprovalData(
        approvals?.map((ele, idx) => ({ ...ele, level: idx }))
      );
      setSelectedRoles(approvals?.map((k) => k.role_id));

      // message.success(<span className="messageText">{res_message}</span>);
    }
    if (status && approvals && invoice) {
      setBillApprovalData(
        approvals?.map((ele, idx) => ({ ...ele, level: idx }))
      );
      setSelectedRoles(approvals.map((k) => k.role_id));

      // message.success(<span className="messageText">{res_message}</span>);
    }
    // if (status && sale_order_approvals && type === "sales") {
    //   setBillApprovalData(sale_order_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
    //   setSelectedRoles(sale_order_approvals.map(k => k.role_id))

    //   // message.success(<span className="messageText">{res_message}</span>);
    // }
    // if (status && estimate_approvals && type === "estimation") {
    //   setBillApprovalData(estimate_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
    //   setSelectedRoles(estimate_approvals.map(k => k.role_id))

    //   // message.success(<span className="messageText">{res_message}</span>);
    // }

    // if (status && payment_approvals && type === "company_payments") {
    //   setBillApprovalData(payment_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
    //   setSelectedRoles(payment_approvals.map(k => k.role_id))

    //   // message.success(<span className="messageText">{res_message}</span>);
    // }
    // if (status && bulk_payment_approvals && type === "company_bulk_payments") {
    //   setBillApprovalData(bulk_payment_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
    //   setSelectedRoles(bulk_payment_approvals.map(k => k.role_id))

    //   // message.success(<span className="messageText">{res_message}</span>);
    // }
    // if (status && purchase_order_approvals
    //   && type === "purchase") {
    //   setBillApprovalData(purchase_order_approvals
    //     ?.map((ele, idx) => ({ ...ele, level: idx })));
    //   setSelectedRoles(purchase_order_approvals
    //     .map(k => k.role_id))

    //   // message.success(<span className="messageText">{res_message}</span>);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billApprovalResponse]);

  console.log(billApprovalResponse, "billsData===>");

  // React.useEffect(() => {
  //   const { data: { data: { bulk_payment_approvals = [], payment_approvals = [], billing_approvals = [], invoice_approvals = [], sale_order_approvals = [], estimate_approvals = [], purchase_order_approvals = [], } = {}, status = '', message: res_message = '', errors = '' } = {} } = updateBillApprovalResponse;
  //   if (status && billing_approvals && !invoice && type !== "sales" && type !== "estimation" && type !== "purchase") {
  //     setBillApprovalData(billing_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
  //     setSelectedRoles(billing_approvals.map(k => k.role_id))
  //     setEditMode(false)
  //   }
  //   if (status && invoice_approvals && invoice) {
  //     setBillApprovalData(invoice_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
  //     setSelectedRoles(invoice_approvals.map(k => k.role_id))
  //     setEditMode(false)
  //   }
  //   if (status && sale_order_approvals && type === "sales") {
  //     setBillApprovalData(sale_order_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
  //     setSelectedRoles(sale_order_approvals.map(k => k.role_id))
  //     setEditMode(false)
  //   }
  //   if (status && estimate_approvals && type === "estimation") {
  //     setBillApprovalData(estimate_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
  //     setSelectedRoles(estimate_approvals.map(k => k.role_id))
  //     setEditMode(false)
  //   }
  //   if (status && payment_approvals && type === "company_payments") {
  //     setBillApprovalData(payment_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
  //     setSelectedRoles(payment_approvals.map(k => k.role_id))
  //     setEditMode(false)
  //     // message.success(<span className="messageText">{res_message}</span>);
  //   }
  //   if (status && bulk_payment_approvals && type === "company_bulk_payments") {
  //     setBillApprovalData(bulk_payment_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
  //     setSelectedRoles(bulk_payment_approvals.map(k => k.role_id))
  //     setEditMode(false)
  //     // message.success(<span className="messageText">{res_message}</span>);
  //   }
  //   if (status && purchase_order_approvals
  //     && type === "purchase") {
  //     setBillApprovalData(purchase_order_approvals
  //       ?.map((ele, idx) => ({ ...ele, level: idx })));
  //     setSelectedRoles(purchase_order_approvals
  //       .map(k => k.role_id))
  //     setEditMode(false)
  //   }
  //   if (status === false) {
  //     message.error(<span className="messageText">{errors}</span>);
  //     dispatch({
  //       type: UpdateBillApproval.RESET,
  //     });
  //   }
  //   if (status) {
  //     message.success(<span className="messageText">{res_message}</span>);
  //     dispatch({
  //       type: UpdateBillApproval.RESET,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updateBillApprovalResponse]);

  React.useEffect(() => {
    const {
      data: {
        data: approvals = [],
        status = "",
        message: res_message = "",
        error = "",
      } = {},
    } = updateBillApprovalResponse;
    if (error === false) {
      dispatch(billApproval({ type, invoice, module_type: type }));
      message.success(<span className="messageText">{res_message}</span>);
      setEditMode(false);
      dispatch({
        type: UpdateBillApproval.RESET,
      });
    }
  }, [updateBillApprovalResponse]);

  React.useEffect(() => {
    const { data: { data: roles = [], status = "" } = {} } = rolesResponse;
    if (status) {
      setRolesData(roles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesResponse]);

  const handleSubmit = () => {
    const dataArray = billApprovalData.map(({ ...rest }) => rest);
    let data = { data: dataArray };
    // if (type === "sales") {
    //   data = { sale_order_approvals: [...dataArray] }
    // } else if (type === "estimation") {
    //   data = { estimate_approvals: [...dataArray] }
    // } else if (type === "purchase") {
    //   data = { purchase_order_approvals: [...dataArray] }
    // } else if (type === "company_payments") {
    //   data = { payment_approvals: [...dataArray] }
    // } else if (type === "company_bulk_payments") {
    //   data = { bulk_payment_approvals: [...dataArray]}
    // } else {
    //   data = invoice ? { invoice_approvals: [...dataArray] } : { billing_approvals: [...dataArray] }
    // }
    // data.params = {module_type : type}
    dispatch(
      updateBillApproval({
        ...data, params: {
          module_type: type
        }
      }, {
        invoice,
        type,
      })
    );
  };

  const handleCancel = () => {
    setBillApprovalData(approvals);
    setEditMode(false);
  };

  const handleApproveList = (type, key) => {
    if (type === "add") {
      setBillApprovalData([
        ...billApprovalData,
        {
          can_approve_or_reject: false,
          can_reimburse: false,
          id: billApprovalData.length,
          level: billApprovalData.length,
          role_name: "",
        },
      ]);
    } else {
      const filterArray = billApprovalData.map((obj) => {
        if (obj.level === key) {
          obj.is_active = false;
        }
        return obj;
      });
      const updatedArray = filterArray.map(({ level, ...rest }, index) => {
        return { level: index, ...rest };
      });
      setBillApprovalData([...updatedArray]);
    }
  };

  return (
    <>
      {showApproval ? (
        <Tabs
          onChange={setTab}
          type="card"
          size="large"
          className="custom-tabs"
        >
          <TabPane tab={t("hierarchy_approval")} key="preferences">
            <div className="tabContentWrapper">
              {showAdvancedApproval ? (
                <AdvancedApproval
                  type={inv_type}
                  inv_type={inv_type}
                  approvalData={billApprovalData}
                  hasWriteAccess={hasWriteAccess}
                />
              ) : (
                <HirarchicalApproval
                  approvalData={billApprovalData}
                  setApprovalData={setBillApprovalData}
                  editMode={editMode}
                  handleList={handleApproveList}
                  selectedRoles={selectedRoles}
                  setSelectedRoles={setSelectedRoles}
                  rolesData={rolesData}
                  hirarchicalApprovalCheckBoxTitle={
                    hirarchicalApprovalCheckBoxTitle
                  }
                  hasWriteAccess={hasWriteAccess}
                />
              )}
              {radioVal === "advanced" ? (
                <Button
                  className="cancelButton ml1 mt1"
                  onClick={() => {
                    setShowApproval(false);
                    setShowAdvancedApproval(false);
                  }}
                >
                  Back
                </Button>
              ) : (
                <>
                  {editMode &&
                    rolesData?.filter(
                      (ele) =>
                        ele.role_name !== "Admin" &&
                        !billApprovalData
                          ?.filter((ele) => ele.is_active !== false)
                          ?.map((ele) => ele.role_id)
                          ?.includes(ele.id)
                    )?.length > 0 && (
                      <Button
                        disabled={!hasWriteAccess}
                        type="default"
                        ghost
                        className="approve-add-btn mt1"
                        onClick={() => handleApproveList("add")}
                      >
                        + Add one more
                      </Button>
                    )}
                  <div className="floatBottom">
                    {!editMode && (
                      <>
                        <Button
                          disabled={!hasWriteAccess}
                          className="formButton mt1 primaryButton"
                          onClick={() => setEditMode(true)}
                        >
                          Edit
                        </Button>
                        <Button
                          className="cancelButton ml1 mt1"
                          onClick={() => {
                            setShowApproval(false);
                            setShowAdvancedApproval(false);
                          }}
                        >
                          Back
                        </Button>
                      </>
                    )}
                    {editMode && (
                      <>
                        <Button
                          disabled={!hasWriteAccess}
                          key="1"
                          className="formButton mt1 primaryButton"
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </Button>
                        <Button
                          key="2"
                          className="cancelButton ml1 mt1"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </TabPane>
        </Tabs>
      ) : (
        <div className="tabContentWrapper">
          <h3>{t("approval_types")}</h3>
          <Radio.Group
            className="w100"
            onChange={(e) => {
              setRadioVal(e.target.value);
            }}
            value={radioVal}
            disabled={!hasWriteAccess}
          >
            <div
              className={`hierarchical-select ${radioVal === "no-approval" ? "active" : ""
                }`}
              onClick={() => {
                if (hasWriteAccess) {
                  setRadioVal("no-approval");
                  let seedType = "",
                    seed_code = "";
                  if (type === "SALES_ORDER_APPROVAL") {
                    seed_code = "SHO_0";
                    seedType = "sales_order_hierarchy";
                  } else if (type === "ESTIMATE_APPROVAL") {
                    seed_code = "EHO_0";
                    seedType = "estimates_hierarchy";
                  } else if (type === "PURCHASE_ORDER_APPROVAL") {
                    seed_code = "PHO_0";
                    seedType = "purchase_order_hierarchy";
                  } else if (type === "PAYMENT_APPROVAL") {
                    seed_code = "CPS_0";
                    seedType = "company_payments_hierarchy";
                  } else if (type === "BULK_PAYMENT_APPROVAL") {
                    seed_code = "CBP_0";
                    seedType = "company_bulk_payments_hierarchy";
                  } else if (isDelivery) {
                    seed_code = "DHO_0";
                    seedType = "delivery_challan_hierarchy";
                  } else if (isMaterial) {
                    seed_code = "MHO_0";
                    seedType = "material_receipt_hierarchy";
                  }
                  else {
                    seed_code = invoice ? "IHO_0" : "BHO_0";
                    seedType = invoice
                      ? "invoice_hierarchy"
                      : "bills_hierarchy";
                  }
                  updateOptions({
                    data: {
                      seed_type: seedType,
                      seed_code: seed_code,
                      is_active: true,
                    },
                  });
                }
              }}
            >
              <Radio
                disabled={!hasWriteAccess}
                name="hierarchical"
                checked={radioVal === "no-approval"}
                value={"no-approval"}
              />
              <div>
                <span>{t("no_approval")}</span>
                <span>
                  {invoice
                    ? t("invoice_create")
                    : type === "sales"
                      ? t("create_sale_order")
                      : type === "company_payments"
                        ? t("no_approval_payment")
                        : type === "company_bulk_payments"
                          ? t("no_approval_bulk_payment")
                          : type === "estimation"
                            ? t("create_estimate_order")
                            : type === "purchase"
                              ? t("create_purchase_order")
                              : t("create_payments")}
                </span>
              </div>
            </div>
            <div
              className={`hierarchical-select ${radioVal === "hierarchical" ? "active" : ""
                }`}
              onClick={() => {
                if (hasWriteAccess) {
                  setRadioVal("hierarchical");
                  let seedType = "",
                    seed_code = "";
                  if (type === "SALES_ORDER_APPROVAL") {
                    seed_code = "SHO_1";
                    seedType = "sales_order_hierarchy";
                  } else if (type === "ESTIMATE_APPROVAL") {
                    seed_code = "EHO_1";
                    seedType = "estimates_hierarchy";
                  } else if (type === "PURCHASE_ORDER_APPROVAL") {
                    seed_code = "PHO_1";
                    seedType = "purchase_order_hierarchy";
                  } else if (type === "PAYMENT_APPROVAL") {
                    seed_code = "CPS_1";
                    seedType = "company_payments_hierarchy";
                  } else if (type === "BULK_PAYMENT_APPROVAL") {
                    seed_code = "CBP_1";
                    seedType = "company_bulk_payments_hierarchy";
                  } else if (isDelivery) {
                    seed_code = "DHO_1";
                    seedType = "delivery_challan_hierarchy";
                  } else if (isMaterial) {
                    seed_code = "MHO_1";
                    seedType = "material_receipt_hierarchy";
                  }
                  else {
                    seed_code = invoice ? "IHO_1" : "BHO_1";
                    seedType = invoice
                      ? "invoice_hierarchy"
                      : "bills_hierarchy";
                  }
                  updateOptions({
                    data: {
                      seed_type: seedType,
                      seed_code: seed_code,
                      is_active: true,
                    },
                  });
                }
              }}
            >
              <Radio
                disabled={!hasWriteAccess}
                name="hierarchical"
                checked={radioVal === "hierarchical"}
                value={"hierarchical"}
              />
              <div>
                <span>{t("hierarchy_approval")}</span>
                <span>{t("approval_flow")}</span>
                {radioVal === "hierarchical" ? (
                  <ArrowRightOutlined
                    onClick={() => {
                      setShowApproval(true);
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div
              className={`hierarchical-select ${radioVal === "advanced" ? "active" : ""
                }`}
              onClick={() => {
                if (hasWriteAccess) {
                  setRadioVal("advanced");
                  let seedType = "",
                    seed_code = "";
                  if (type === "SALES_ORDER_APPROVAL") {
                    seed_code = "SHO_2";
                    seedType = "sales_order_hierarchy";
                  } else if (type === "ESTIMATE_APPROVAL") {
                    seed_code = "EHO_2";
                    seedType = "estimates_hierarchy";
                  } else if (type === "PURCHASE_ORDER_APPROVAL") {
                    seed_code = "PHO_2";
                    seedType = "purchase_order_hierarchy";
                  } else if (type === "PAYMENT_APPROVAL") {
                    seed_code = "CPS_2";
                    seedType = "company_payments_hierarchy";
                  } else if (type === "BULK_PAYMENT_APPROVAL") {
                    seed_code = "CBP_2";
                    seedType = "company_bulk_payments_hierarchy";
                  } else if (isDelivery) {
                    seed_code = "DHO_2";
                    seedType = "delivery_challan_hierarchy";
                  } else if (isMaterial) {
                    seed_code = "MHO_2";
                    seedType = "material_receipt_hierarchy";
                  }
                  else {
                    seed_code = invoice ? "IHO_2" : "BHO_2";
                    seedType = invoice
                      ? "invoice_hierarchy"
                      : "bills_hierarchy";
                  }
                  updateOptions({
                    data: {
                      seed_type: seedType,
                      seed_code: seed_code,
                      is_active: true,
                    },
                  });
                }
              }}
            >
              <Radio
                disabled={!hasWriteAccess}
                name="advanced"
                checked={radioVal === "advanced"}
                value={"advanced"}
              />
              <div>
                <span>Advanced Approval</span>
                <span>
                  You can set up an approval flow based on one or more criteria.
                  These criteria can be chosen by you.
                </span>
                {radioVal === "advanced" ? (
                  <ArrowRightOutlined
                    onClick={() => {
                      setShowAdvancedApproval(true);
                      setShowApproval(true);
                    }}
                  />
                ) : null}
              </div>
            </div>
          </Radio.Group>
        </div>
      )}
    </>
  );
};

export default ApprovalBills;
