import React from "react";
import PageHeader from "./../PageHeader";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { Modal, Row, Col, Button, Typography, message, Menu, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import "./account.css";
import { useDispatch, useSelector } from "react-redux";
import { corporateBudget } from "../../actions/cards/cards";
import { amountFormat } from "../../utils";
import { companyProfile, companySetting, getDesignations } from "../../actions/companySettings";
import BaseCurrency from "../Common/BaseCurrency";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { useTranslation } from "react-i18next";
import { BudgetsBack, CreateBudgets, all_budgets, budgets_back, create_budgets, master_pool_account } from "../../actions/accounts/accounts";
import { FormInput, FormSelect } from "../inputs";
import usePayAxios from "../../config/useAxios";
import { apis } from "../../config/APIs";
import TextArea from "antd/lib/input/TextArea";
const { Title, Text } = Typography;

const Account = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companyProfileResponse = useSelector(state => state.companySetting.companyProfile || {});
  const companySettingResponse = useSelector(state => state.companySetting.companySetting || {});
  const masterPoolAccountResponse = useSelector(state => state.banks.master_pool_account || {});
  const allBudgetsResponse = useSelector(state => state.banks.all_budgets || {});
  const designationsResponse = useSelector(state => state.companySetting.getDesignations || {});
  const createBudgetResponse = useSelector((state) => state.banks.create_budgets || {});
  const transferBudgetBackResponse = useSelector(state => state.banks.budgets_back || {});
  const { data: { response: { company = {} } = {} } = {} } = companyProfileResponse;

  const { data: { response: { company_setting: { company_id = "" } = {} } = {} } = {} } = companySettingResponse;
  const { data: { data: masterPoolData = {} } = {} } = masterPoolAccountResponse;
  const { data: { data: allBudgetsData = [] } = {} } = allBudgetsResponse;
  const { data: { response: { designations: designationOption = [] } = {} } = {} } = designationsResponse
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [allBudgetModal, setAllBudgetModal] = React.useState(false);
  const [destOption, setDestOption] = React.useState([]);
  const [masterId, setMasterId] = React.useState({});
  const [isTransferBudgetBack, setIsTransferBudgetBack] = React.useState(false);
  const [reasonValue, setReasonValue] = React.useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);
  const [isDeleteData, setIsDeleteData] = React.useState({});
  const { onCall: deleteBudget } = usePayAxios({
    api: apis.deleteBudget,
    method: "post"
  })
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      allocate_funds: "",
      amount: "",
      description: "",
    },
    shouldUseNativeValidation: false,
  });
  const { t } = useTranslation();
  React.useEffect(() => {
    dispatch(corporateBudget({
      merchID: "PVRPITSERVICES"
    }));
    dispatch(companySetting({}));
    dispatch(headerTitles({
      title: 'account',
      description: 'account_Details'
    }))
    dispatch(master_pool_account())
    dispatch(all_budgets());
    dispatch(getDesignations({pagination:false}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (createBudgetResponse?.data?.message === "Success") {
      message.success(<span className="messageText">{"Fund Transfer Successful"}</span>);
      dispatch(master_pool_account())
      dispatch(all_budgets());
      reset();
      setAllBudgetModal(false);
      dispatch({
        type: CreateBudgets.RESET
      });
    } else if (createBudgetResponse?.data?.error == true) {
      if(Array.isArray(createBudgetResponse?.data?.data)){
        message.error(<span className="messageText">{createBudgetResponse?.data?.data?.[0]?.message}</span>)
      }
      else{
        message.error(<span className="messageText">{createBudgetResponse?.data?.message}</span>)
      }
      dispatch({
        type: CreateBudgets.RESET
      });
    }
  }, [createBudgetResponse?.data])

  React.useEffect(() => {
    if (transferBudgetBackResponse?.data?.message === "Success") {
      message.success(<span className="messageText">{"Fund Transfer Successful"}</span>);
      dispatch(master_pool_account())
      dispatch(all_budgets());
      reset();
      setAllBudgetModal(false);
      dispatch({
        type: BudgetsBack.RESET
      });
    } else if (transferBudgetBackResponse?.data?.error == true) {
      message.error(<span className="messageText">{transferBudgetBackResponse?.data?.message}</span>)
      dispatch({
        type: BudgetsBack.RESET
      });
    }
  }, [transferBudgetBackResponse?.data])

  React.useEffect(() => {
    if (company_id) {
      dispatch(companyProfile({ id: company_id }));
    }
    // eslint-disable-next-line 
  }, [company_id])
  React.useEffect(() => {
    if (designationsResponse?.data?.status) {
      setDestOption(
        designationsResponse?.data?.data?.map((item) => ({
          value: item.id,
          label: item.title,
        }))
      );
    }
  }, [designationsResponse]);
  const onSubmit = (data) => {
    if (isTransferBudgetBack) {
      const amount = parseFloat(data?.amount).toFixed(2)
      const formData = {
        id: masterId?.id,
        amount: parseFloat(amount),
        description: data.description,
      }

      dispatch(budgets_back(formData));
    } else {
      const amount = parseFloat(data?.amount).toFixed(2)
      const formData = {
        amount_allocated: parseFloat(amount),
        department_id: data.allocate_funds,
        description: data.description,
        master_account_detail_id: masterId?.id,
        currency: "INR"
      }
      dispatch(create_budgets(formData));
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setAllBudgetModal(false);
    setReasonValue("")
    setIsDeleteModalVisible(false)
    reset();
  };

  const renderViewAccount = () => {
    return (
      <>
        <Text>Step 1</Text>
        <div className="viewAccount">
          <Text className="notBold">
            {t('add_forgo')}
          </Text>
        </div>
        <br />
        <Text>Step 2</Text>
        <div className="viewAccount">
          <Row>
            <Col span={8}>
              <p>{t('account_name')}</p>
              <p>{t('account_num')}</p>
              <p>{t('ifsc_code')}</p>
              <p>{t('account_type')}</p>
              <p>{t('address')}</p>
              <br />
              <br />
              <p>{t('bank_name')}</p>
              <p>{t('branch')}</p>
            </Col>
            <Col span={12}>
              <span>{company?.account_name}</span>
              <br />
              <span>{company?.account_number}</span>
              <br />
              <span>{company?.account_ifsc}</span>
              <br />
              <span>{company?.account_type}</span>
              <br />
              <span>
                {company?.bank_address}
              </span>
              <br />
              <span>{company?.bank_name}</span>
            </Col>
          </Row>
        </div>
        <br />
        <Text>Step 3</Text>
        <div className="viewAccount">
          <Text className="notBold">
            {t('transfer_funds')}
          </Text>
        </div>
      </>
    );
  };

  const handleaBudgets = (masterData) => {
    setAllBudgetModal(true);
    setMasterId(masterData);
    setIsTransferBudgetBack(false);
  }
  const handleTransferBudgetBack = (masterData, key) => {
    if (key.key === "2") {

      deleteBudget({
        data: { id: masterData?.id, notes: reasonValue }
      }).then(res => {
        if (res.error === false) {
          dispatch(master_pool_account())
          dispatch(all_budgets());
          setReasonValue("")
          setIsDeleteModalVisible(false)
          message.success(<span className="messageText">{"Deleted Successfully"}</span>);
        } else {
          message.success(<span className="messageText">{"Failed to Delete"}</span>);
        }
      }).catch(err => {
        message.success(<span className="messageText">{"Failed to Delete"}</span>);
      })
    } else {
      setAllBudgetModal(true);
      setMasterId(masterData);
      setIsTransferBudgetBack(true);
    }
  }
  const handleDeleteTransfer = (masterData, key,) => {
    setIsDeleteData({ masterData: masterData, key: key });
    setIsDeleteModalVisible(true);
    setReasonValue("")
  }

  return (
    <>
      <Modal
        title={"Delete"}
        visible={isDeleteModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={"center-aligned-modal"}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleTransferBudgetBack(isDeleteData?.masterData, isDeleteData?.key)
          }}
        >
          <Text>{t('delete_reason')}</Text>
          <br />
          <TextArea
            rows={4}
            placeholder="Enter Reason"
            className="reasonTextArea"
            onChange={(e) => setReasonValue(e.target.value)}
            required={true}
            value={reasonValue}
          />
          <br />
          <Button
            key="1"
            className="formButton mt1"
            disabled={!reasonValue || reasonValue.trim() === "" ? true : false}
            htmlType="submit"
          >
            {t('confirm')}
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
            htmlType="button"
            onClick={() => handleCancel()}
          >
            {t('cancel')}
          </Button>
        </form>
      </Modal>
      <Modal
        title={
          <Title level={4}>
            {t('view_account_details')} &nbsp;
            <span className="text-small">{t('load_money')}</span>
          </Title>
        }
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className="right-alinged-modal"
      >
        {renderViewAccount()}
      </Modal>

      <Modal
        title={
          <Title level={4}>
            {t('all_budget_modal')} &nbsp;
            {/* <span className="text-small">{t('load_money')}</span> */}
          </Title>
        }
        visible={allBudgetModal && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className="right-alinged-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="main_section">
              <div className="header_section">
                <span>{t('account_create_form_sub_text')}</span>
              </div>
              <div className="amount_main_section">
                <span className="amount_section">
                  <Text className="balanceText">
                    <BaseCurrency />{" "}
                    {masterId?.available_amount ?
                      amountFormat(masterId?.available_amount) : "0.00"}{" "}
                  </Text>
                </span>
                <span className="text_section">{t('account_create_form_text')}</span>
              </div>
            </div>

            <div className={`pp-form-item field_length ${isTransferBudgetBack ? "d-none" : ""}`}>
              <FormSelect
                inline
                control={control}
                {...register("allocate_funds", {
                  required: isTransferBudgetBack ? false : `${t('allocate_fund') + " " + t('is_Requrired')}`
                })}
                errors={errors}
                label={t('allocate_fund')}
                placeholder={t('select')}
                //   onSearch={onVendorSearch}
                options={destOption}
                //   onAddItem={handlerNewVendor}
                hideCreateOption={true}
                required={true}
              />
            </div>

            <div className="pp-form-item field_length">
              <FormInput
                control={control}
                {...register("amount", {
                  pattern: {
                    value: /^[0-9.]+$/i,
                    message: `${t('amount_validation')}`,
                  },
                  required: `${t('amount_field') + " " + t('is_Requrired')}`
                })}
                errors={errors}
                label={t('enter_amount')}
                placeholder={t('please_enter_amount')}
                required={true}
                number={true}
              />
            </div>
            <div className={`pp-form-item field_length`}>
              <FormInput
                control={control}
                {...register("description",{required:`${t('allocate_desc') + " " + t('is_Requrired')}`})}
                errors={errors}
                label={t('allocate_desc')}
                placeholder={t('place_allocate_desc')}
                required={true}
              />
            </div>

          </div>
          <div style={{ display: "flex", marginTop: "2rem" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="pp-main-button"
              id="save"
            >
              <span>{isTransferBudgetBack ? t('return_funds') : t('allocate_button')}</span>
            </Button>

            <Button
              className="pp-secondary-button"
              style={{ marginLeft: "2rem" }}
              onClick={handleCancel}
            >
              <span>{t('cancel')}</span>
            </Button>
          </div>
        </form>
      </Modal>

      <div className="contentWrapper">

        <Row className="accountHead">
          <Col span={9}>
            <Title level={5}>{t('master_pool')}</Title>
          </Col>
          <Col span={15} style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="account-budget">
              <Title className="balanceTitle" level={5}>
                {t('balance')}
              </Title>
              <Text className="balanceText">
                <BaseCurrency />{" "}
                {masterPoolData?.available_amount ?
                  amountFormat(masterPoolData?.available_amount) : "0.00"}{" "}
              </Text>
            </div>
            <Button
              disabled={!hasWriteAccess}
              key="2"
              className="cancelButton"
              onClick={() => history.push("/transactions/")}
            >
              {t('transaction_history')}
            </Button>
            <Button
              disabled={!hasWriteAccess}
              key="2"
              className="cancelButton ml1"
              onClick={() => {
                setAllBudgetModal(true);
                setMasterId(masterPoolData);
                setIsTransferBudgetBack(false);
              }}
            >
              Allocate Funds
            </Button>
            {/* <Button
              key="1"
              htmlType="submit"
              className="formButton ml1 primaryButton"
              onClick={() => setIsModalVisible(true)}
            >
              {t('add_fund')}
            </Button> */}
          </Col>
          {/* <Col span={1}>
           <MoreOutlined className="moreDetails" onClick={() => handleaBudgets(masterPoolData)} /> 
            <div className="user-drop">
              <Dropdown
                overlay={
                  <Menu onClick={() => handleaBudgets(masterPoolData)}>
                    <Menu.Item key="1">Transfer</Menu.Item>
                  </Menu>
                }
                placement="bottomLeft"
              >
                <MoreOutlined className="moreDetails" />

              </Dropdown>
            </div>
          </Col> */}
        </Row>
        {
          allBudgetsData?.length > 0 &&
          allBudgetsData?.map((item, index) => {
            return (
              <Row className="accountHead" key={item.id}>
                <Col span={11}>
                  <Title level={5}>{item?.department?.title ? item?.department?.title : t('master_pool')}</Title>
                </Col>
                <Col span={4}>
                  {/* <div className="account-budget ">
                    <Title className="balanceTitle" level={5}>
                      {t('balance')}
                    </Title>
                    <Text className="balanceText">
                      <BaseCurrency />{" "}
                      {item?.available_amount ?
                        amountFormat(item?.available_amount) : "0.00"}{" "}
                    </Text>
                  </div> */}
                </Col>
                <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* <Button
                    key="2"
                    className="cancelButton"
                  // onClick={() => props.handleCancel()}
                  >
                    {t('transaction_history')}
                  </Button> */}
                  <div className="account-budget">
                    <Title className="balanceTitle" level={5}>
                      {t('balance')}
                    </Title>
                    <Text className="balanceText">
                      <BaseCurrency />{" "}
                      {item?.available_amount ?
                        amountFormat(item?.available_amount) : "0.00"}{" "}
                    </Text>
                  </div>
                  <Button
                    disabled={!hasWriteAccess}
                    key="2"
                    className="cancelButton ml1"
                    onClick={() => {
                      handleTransferBudgetBack(item, { key: "1" })
                    }}
                  >
                    Return Funds
                  </Button>
                  {/* <Button
                    key="1"
                    htmlType="submit"
                    className="formButton ml1 primaryButton"
                    onClick={() => setIsModalVisible(true)}
                  >
                    {t('add_fund')}
                  </Button> */}
                </Col>
                <Col span={1}>
                  {/* <MoreOutlined className="moreDetails" onClick={() => handleaBudgets(item)} /> */}

                  <div className="user-drop">
                    <Dropdown
                      disabled={!hasWriteAccess}
                      overlay={
                        <Menu onClick={(key) => handleDeleteTransfer(item, key)}>
                          {/* <Menu.Item key="1">Transfer</Menu.Item> */}
                          <Menu.Item key="2">Delete</Menu.Item>
                        </Menu>
                      }
                      placement="bottomLeft"
                    >
                      <MoreOutlined className="moreDetails" />

                    </Dropdown>
                  </div>
                </Col>
              </Row>
            )
          })
        }
        <br />
        <Row className="width-80 ml2 mb3">
          <Title level={5} className="accountNote">
            {t('notes')}
          </Title>
          <Text>
            {t('working_hours')}
            <br />
            <br />
          </Text>
          <Text>
            {t('import_365')}
            <br /> {t('neft_sunday')}
            <br /> {t('rtgs_sunday')}
            <br />
            <br />
          </Text>
          <Text>
            {t('any_queries')}
          </Text>
        </Row>
      </div>
    </>
  );
};

export default Account;
