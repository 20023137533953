import { DownOutlined, MessageOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Steps,
  Typography,
  message,
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import {
  AdvancesCommentsAdd,
  AdvancesExpDetails,
  UserAdvanceDelete,
  advanceApproveReset,
  advanceRejectReset,
  advancesApprove,
  advancesCommentsAdd,
  advancesExpDetails,
  advancesExportExcel,
  advancesReject,
  userAdvanceDelete,
  userAdvancesSubmit,
  userAdvancesSubmitReset,
} from "../../actions/advances/advances";
import {
  ExpenseCustomDetails,
  expenseCustomDetails,
} from "../../actions/expenses/expenses";
import { SideMenu, headerTitles } from "../../actions/masterdata/masterdata";
import {
  UserReportLinkAdv,
  userReportLinkAdvReset,
} from "../../actions/reports/reports";
import Close from "../../assets/icons/close.png";
import excel_icon from "../../assets/icons/excel1.png";
import pdf_icon from "../../assets/icons/pdf_icon.png";
import ppt_icon from "../../assets/icons/ppticon.png";
import tiff_icon from "../../assets/icons/tifficon.png";
import { apis } from "../../config/APIs";
import endpointPdfApi from "../../config/AxiosPdf";
import { amountFormat, hasAccess, isSuperAdmin } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import NoCommentsComponent from "../Common/NoComentsComponent";
import NodataComponent from "../Common/NodataComponent";
import AddAdvance from "./AddAdvance";
import AdvReport from "./AdvReport";
import LinkAdvsToReport from "./LinkAdvsToReport";
import "./advances.css";
import usePdfApi from "../../config/usePdfApi";

const { Panel } = Collapse;
const { TextArea } = Input;
const { Step } = Steps;
const { Title } = Typography;

message.config({
  maxCount: 3,
});
const AdvanceDetail = ({ }) => {
  const location = useLocation();
  const hasWriteAccess = location?.state?.user
    ? true
    : hasAccess("advances_approvals", "write");
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const [modalType, setIsModalType] = React.useState("center");
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  // const [commentsData, setCommentsData] = React.useState([]);
  const [reasonValue, setReasonValue] = React.useState("");
  const inputComment = React.useRef(null);
  const [input, setInput] = React.useState("");
  const [tab, setTab] = React.useState("details");
  const {
    onCall: advancePdfUrl,
    loading: pdfLoading,
    pdfUrl: advance_url,
  } = usePdfApi({
    api: apis.adv_pdf,
    key: "advance_url",
  });
  const advanceData = useSelector(
    (state) => state.advances.advancesExpDetails?.data?.data || []
  );
  const advancesCommentsAddResponse = useSelector(
    (state) => state.advances.advancesCommentsAdd || {}
  );
  const advancesRejectResponse = useSelector(
    (state) => state.advances.advancesReject || {}
  );
  const userAdvanceDeleteResponse = useSelector(
    (state) => state.advances.userAdvanceDelete || {}
  );
  const advancesApproveResponse = useSelector(
    (state) => state.advances.advancesApprove || {}
  );
  const advancesExportExcelResponse = useSelector(
    (state) => state.advances.advancesExportExcel || {}
  );

  const userAdvancesSubmitResponse = useSelector(
    (state) => state.advances.userAdvancesSubmit || {}
  );

  const advanceCustomData = useSelector(
    (state) => state?.expenses?.expenseCustomDetails?.data?.data || []
  );

  const userReportLinkAdvResponse = useSelector(
    (state) => state.reports.userReportLinkAdv || {}
  );
  // const { data: { response: { history: advanceHistory = [] } = {}, status = "" } = {} } = advancesHistoryResponse;
  const { history: advanceHistory = [] } = advanceData;

  // const { data: { response: { comments = [] } = {}, status = "" } = {} } = advancesCommentsResponse;
  const { comments: commentsData = [] } = advanceData;

  const callOnAction = () => {
    dispatch(
      advancesExpDetails({ module_type: "ADVANCE", advance_id: params.id })
    );
  };

  React.useEffect(() => {
    if (params.id) {
      dispatch(
        advancesExpDetails({ module_type: "ADVANCE", advance_id: params.id })
      );
      dispatch(
        expenseCustomDetails({
          module_type: "ADVANCE",
          module_id: params.id,
          custom_flag: true,
        })
      );
      advancePdfUrl({
        params: {
          id: params.id,
        },
      });
      // dispatch(advance_pdf({ id: params.id }));
    }

    dispatch(
      headerTitles({
        title: "advance_details",
        description: "adavnce_see",
      })
    );

    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/expenses",
        childrenMenu: location?.state?.user ? "/my-advances" : "",
        showChildren: true,
        secondNav: "/advances",
      },
    });

    return () => {
      dispatch(userAdvancesSubmitReset());
      dispatch(advanceApproveReset());
      dispatch(advanceRejectReset());
      dispatch(userReportLinkAdvReset());
      dispatch({
        type: AdvancesExpDetails.RESET,
      });

      dispatch({
        type: ExpenseCustomDetails.RESET,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      userReportLinkAdvResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      handleCancel();
      dispatch({
        type: UserReportLinkAdv.RESET,
      });
      dispatch(
        advancesExpDetails({ advance_id: params.id, module_type: "ADVANCE" })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkAdvResponse]);

  // React.useEffect(() => {
  //   const { data: { response: { comments = [] } = {}, status = "" } = {} } = advancesCommentsResponse;
  //   if (status) {
  //     setCommentsData(comments);
  //     setInput("")
  //     dispatch({
  //       type: AdvancesCommentsAdd.RESET
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [advancesCommentsResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      advancesCommentsAddResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(
        advancesExpDetails({ module_type: "ADVANCE", advance_id: params.id })
      );
      setInput("");
      dispatch({ type: AdvancesCommentsAdd.RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancesCommentsAddResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      advancesExportExcelResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      // dispatch(advancesComments({ advance_id: params.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancesExportExcelResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      advancesRejectResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      setReasonValue("");
      // dispatch(advances(objectToFormData({ status: true })));
      dispatch(advanceRejectReset());
      callOnAction();
    } else {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancesRejectResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      userAdvanceDeleteResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch({
        type: UserAdvanceDelete.RESET,
      });
      goBack();
    } else {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAdvanceDeleteResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      advancesApproveResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      callOnAction();
      dispatch(advanceApproveReset());
    } else {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancesApproveResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      userAdvancesSubmitResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(userAdvancesSubmitReset());
      callOnAction();
      // dispatch(trips({ trip_id: tripsData.id }));
    } else {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAdvancesSubmitResponse]);

  const handleAddComment = () => {
    if (input?.trim() && params.id) {
      dispatch(
        advancesCommentsAdd(
          {
            body: input,
            commentable_id: params.id,
            commentable_type: "advance",
          },
          { is_admin: !location?.state?.user }
        )
      );
    } else {
      message.error(<span className="messageText">Comments are required</span>);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setReasonValue("");
  };

  const renderRejectDeleteOption = (type) => {
    return (
      <>
        <Text>
          {"Please Specify an appropriate reason for"}{" "}
          {type === "delete" ? "deletion" : "rejection"}.
        </Text>
        <br />
        <TextArea
          rows={4}
          placeholder={t("enter_reason")}
          className="reasonTextArea"
          onChange={(e) => setReasonValue(e.target.value)}
          required={true}
        />
        <br />
        <Button
          key="1"
          className="formButton mt1"
          disabled={
            !reasonValue ||
            advancesRejectResponse.loading ||
            userAdvanceDeleteResponse.loading ||
            !hasWriteAccess
          }
          onClick={() =>
            type === "delete"
              ? dispatch(
                userAdvanceDelete({
                  id: params.id,
                  reject_reason: reasonValue,
                })
              )
              : dispatch(
                advancesReject({ id: params.id, reject_reason: reasonValue })
              )
          }
        >
          {t("confirm")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t("cancel")}
        </Button>
      </>
    );
  };

  function handleMenuClick(e) {
    switch (e.key) {
      case "1":
        if (advance_url) {
          window.open(advance_url, "_blank");
        } else if (pdfLoading) {
          message.warn(<span className="messageText">PDF is loading.</span>);
        } else {
          message.error(<span className="messageText">PDF not available</span>);
        }
        break;
      case "3":
        dispatch(advancesExportExcel({ id: params.id }));
        break;
      default:
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" disabled={!hasWriteAccess || !advance_url}>
        {t("download_pdf")}
      </Menu.Item>
      {/* <Menu.Item key="2">Download Receipts</Menu.Item> */}
      {/* <Menu.Item key="3">Export to Excel</Menu.Item> */}
      {/* <Menu.Item key="4">Print</Menu.Item>  */}
    </Menu>
  );

  const showLinkModal = (id) => {
    setIsModalVisible(true);
    setIsModalType("addUnrepExp");
  };

  const showEditModal = (id) => {
    setIsModalVisible(true);
    setIsModalType("addAdvanceModal");
  };

  const showRejectModal = (id) => {
    setIsModalVisible(true);
    setIsModalType("rejectModal");
  };

  const showDeleteModal = (id) => {
    setIsModalVisible(true);
    setIsModalType("DeleteModal");
  };

  const handleDownloadClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const renderUnrepOption = () => {
    return <>{hasWriteAccess && <LinkAdvsToReport adv_id={params.id} />}</>;
  };

  const renderModalData = (type) => {
    switch (type) {
      case "addUnrepExp":
        return renderUnrepOption();
      case "rejectModal":
        return renderRejectDeleteOption("reject");
      case "DeleteModal":
        return renderRejectDeleteOption("delete");
      case "addAdvanceModal":
        return (
          <AddAdvance
            handleCancel={() => {
              setIsModalVisible(false);
              history.goBack();
            }}
            advanceData={advanceData}
          />
        );
      default:
        return "";
    }
  };

  const renderModalTitle = (type) => {
    switch (type) {
      case "addUnrepExp":
        return "Add Advance to Report";
      case "rejectModal":
        return "Reject Advance";
      case "DeleteModal":
        return "Delete Advance";
      case "addAdvanceModal":
        return (
          <Title level={4}>
            {t("edit_fields")} &nbsp;
            <span className="text-small">{t("enter_details")}</span>
          </Title>
        );
      default:
        return "";
    }
  };

  const renderModalClass = (type) => {
    switch (type) {
      case "addUnrepExp":
        return "right-alinged-modal";
      case "rejectModal":
        return "center-aligned-modal";
      case "DeleteModal":
        return "center-aligned-modal";
      case "addAdvanceModal":
        return "right-alinged-modal";
      default:
        return "";
    }
  };

  const secs = ["Details", "Reports", "Comments", "History"];
  const goBack = () => {
    if (location?.state?.from) history.push(location?.state?.from);
    else history.goBack();
  };
  return (
    <>
      <Modal
        title={renderModalTitle(modalType)}
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={renderModalClass(modalType)}
      >
        {renderModalData(modalType)}
      </Modal>
      {advanceData?.reject_reason && (
        <div className="strip red">
          {t("reason_reject")}: {advanceData?.reject_reason}
        </div>
      )}
      <div className="report-btns">
        {!location?.state?.user ? (
          <>
            {(isSuperAdmin() || !advanceData?.action_restrict) &&
              advanceData.role_status === "pending_approval" &&
              advanceData.is_editable === true ? (
              <>
                <button
                  disabled={!hasWriteAccess || advancesApproveResponse?.loading}
                  className="approve"
                  onClick={() => dispatch(advancesApprove({ id: params.id }))}
                >
                  {t("approve")}
                </button>

                <button
                  disabled={!hasWriteAccess || advancesRejectResponse?.loading}
                  className="reject"
                  onClick={() => showRejectModal(params.id)}
                >
                  {t("reject")}
                </button>
              </>
            ) : null}{" "}
          </>
        ) : (
          <>
            {advanceData.status === "unsubmitted" ? (
              <>
                <button
                  disabled={!hasWriteAccess}
                  className="approve"
                  onClick={() =>
                    dispatch(
                      userAdvancesSubmit({ id: params.id })
                      // userAdvancesSubmit(
                      //   objectToFormData({ advance_id: params.id }),
                      //   { is_admin: !location?.state?.user }
                      // )
                    )
                  }
                >
                  {t("submit")}
                </button>
                <button
                  disabled={!hasWriteAccess}
                  className="reject"
                  onClick={() => showEditModal(params.id)}
                >
                  {t("edit")}
                </button>
                <button
                  disabled={!hasWriteAccess}
                  className="delete"
                  onClick={() => showDeleteModal(params.id)}
                >
                  {t("delete")}
                </button>
              </>
            ) : null}
          </>
        )}
        {advanceData?.is_recallable &&
          location?.state?.user &&
          advanceData.status === "pending_approval" && (
            <button
              disabled={!hasWriteAccess}
              onClick={() => {
                dispatch(userAdvancesSubmit({ id: params.id, isRecall: true }));
              }}
              className="reject"
            >
              Recall
            </button>
          )}

        {location?.state?.user && advanceData.status === "approved" && (
          <Button
            disabled={!hasWriteAccess}
            onClick={() => showLinkModal(params.id)}
            className="floatRight ml1 primaryButton"
          >
            {t("add_to_report")}
          </Button>
        )}

        <Dropdown overlay={menu} placement="bottomRight">
          <button className="more">
            {t("more")}&nbsp;
            <DownOutlined />
          </button>
        </Dropdown>

        <span
          className="close cursor"
          onClick={() => {
            goBack();
          }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div>
      <div className="new-report-detail">
        <div className="flex-between">
          <div className="report-head">
            <div className="title-sec">
              <h3>{`${advanceData?.advance_number
                ? "[" + advanceData?.advance_number + "] "
                : ""
                } ${advanceData?.title}`}</h3>
              <span
                className={`status status_${location?.state?.user
                  ? advanceData?.status
                  : advanceData?.role_status
                  }`}
              >
                {location?.state?.user
                  ? advanceData?.status?.replace(/_/g, " ")
                  : advanceData?.role_status?.replace(/_/g, " ")}
              </span>
            </div>
            <p className="duration"></p>
            <p>
              <strong>
                Submitted by : {advanceData?.submitted_by?.name} <br />
                {advanceData?.submitted_date && (
                  <span className="sm">
                    on{" "}
                    {moment(advanceData?.submitted_date).format(
                      "DD MMM YYYY, HH:mm a"
                    )}
                  </span>
                )}
                <br />
                {advanceData?.submitted_to?.email && (
                  <span className="sm">
                    to {advanceData?.submitted_to?.email}
                  </span>
                )}
              </strong>
            </p>
          </div>
          <div className="report-total">
            <p>
              <label>{t("advance_total")}</label>
              <span className="lg">
                <BaseCurrency /> {amountFormat(advanceData?.amount_cents) || 0}
              </span>
            </p>
          </div>
        </div>
        <div className="tabs-sec">
          <ul>
            {secs?.map((ele) => (
              <li
                onClick={() => setTab(ele.toLowerCase())}
                className={`${tab.toLowerCase() === ele.toLowerCase() ? "active" : ""
                  }`}
              >
                {ele}
              </li>
            ))}
          </ul>
        </div>
        <div className="selected-tab">
          {tab === "reports" ? (
            advanceData.reports && advanceData.reports ? (
              <AdvReport
                repData={advanceData.reports}
                user={location?.state?.user}
              />
            ) : (
              <NodataComponent
                hasWriteAccess={hasWriteAccess}
                noBtns={
                  !location?.state?.user || advanceData?.status !== "approved"
                }
                noSecBtn={true}
                noPrimaryBtn={
                  hasAccess("advances", "write") &&
                  advanceData.status !== "approved"
                }
                primaryText={"Add to Report"}
                onPrimaryClick={() => {
                  if (hasWriteAccess) {
                    setIsModalVisible(true);
                    setIsModalType("addUnrepExp");
                  }
                }}
              />
            )
          ) : null}
          {tab === "details" ? (
            <>
              <Row>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">
                      {t("submitted_by")}
                    </span>
                    <span className="details_span name">
                      {advanceData?.submitted_by?.name}
                    </span>
                    <span className="details_span secondary sm">
                      {advanceData?.submitted_by?.email}
                    </span>
                  </p>
                </Col>
                {advanceData?.submitted_to?.name && (
                  <Col span={6}>
                    <p className="details_p">
                      <span className="details_span secondary">
                        {t("submitted_to")}
                      </span>
                      <span className="details_span name">
                        {advanceData?.submitted_to?.name
                          ? advanceData?.submitted_to?.name
                          : ""}
                      </span>
                      <span className="details_span secondary sm">
                        {advanceData?.submitted_to?.email
                          ? advanceData?.submitted_to?.email
                          : ""}
                      </span>
                    </p>
                  </Col>
                )}
                {advanceData?.status === "pending_approval" && (
                  <Col span={6}>
                    <p className="details_p">
                      <span className="details_span secondary">
                        {t("pendingApprovals")} :
                      </span>
                      <span className="details_span name">
                        {advanceData?.pending_with?.name}
                      </span>
                      <span className="details_span secondary sm">
                        {advanceData?.pending_with?.email}
                      </span>
                      {/* <button style={{ textAlign: "left", color: "#e99b39" }}>
                        View Approval Flow
                      </button> */}
                    </p>
                  </Col>
                )}
              </Row>
              <hr />
              <Row>
                <Col span={6}>
                  <p className="details_p mt1">
                    <span className="details_span secondary">Description</span>
                    <span className="details_span name">
                      {advanceData?.description
                        ? advanceData?.description
                        : "-"}
                    </span>
                  </p>
                </Col>
                <Col span={6}>
                  <p className="details_p mt1">
                    <span className="details_span secondary">Reference No</span>
                    <span className="details_span name">
                      {advanceData?.reference_no}
                    </span>
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                {advanceCustomData.map(
                  ({ key, column_type, column_name, value, attachments }) => {
                    return (
                      <>
                        <Col span={24}>
                          <p className="details_p">
                            <span className="details_span secondary">
                              {column_name?.split("_").join(" ").toUpperCase()}
                            </span>
                            {column_type === "document" ? (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "auto auto auto auto",
                                  maxWidth: "40rem",
                                }}
                              >
                                {attachments?.map((eachImg) =>
                                  eachImg.content_type === "image/jpeg" ||
                                    eachImg.content_type === "image/jpg" ||
                                    eachImg.content_type === "image/png" ? (
                                    <div
                                      key={eachImg.id}
                                      onClick={() =>
                                        handleDownloadClick(
                                          eachImg.attachment_url
                                        )
                                      }
                                    >
                                      <img
                                        style={{
                                          width: "5rem",
                                          height: "5rem",
                                          margin: "1rem",
                                        }}
                                        src={eachImg.attachment_url}
                                        alt=""
                                      />
                                      <p
                                        title={eachImg.filename}
                                        className="customfield-text"
                                      >
                                        {eachImg.filename}
                                      </p>
                                      <Button
                                        style={{
                                          width: "7rem",
                                          color: "black",
                                        }}
                                        className="formButton mt1"
                                      >
                                        Download
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                      onClick={() =>
                                        handleDownloadClick(
                                          eachImg.attachment_url
                                        )
                                      }
                                    >
                                      {eachImg.content_type ===
                                        "application/pdf" ? (
                                        <img
                                          src={pdf_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : eachImg?.content_type ===
                                        "image/tiff" ? (
                                        <img
                                          src={tiff_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : eachImg?.content_type ===
                                        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                        <img
                                          src={ppt_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={excel_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      )}
                                      <p
                                        title={eachImg.filename}
                                        className="customfield-text"
                                      >
                                        {eachImg.filename}
                                      </p>
                                      <Button
                                        style={{
                                          width: "7rem",
                                          color: "black",
                                        }}
                                        className="formButton mt1"
                                      >
                                        Download
                                      </Button>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <span className="details_span name">{value}</span>
                            )}
                          </p>
                        </Col>
                      </>
                    );
                  }
                )}
              </Row>
            </>
          ) : null}
          {tab === "comments" ? (
            <>
              <div className="addComment mt2 mb1">
                <Input
                  placeholder="Enter Comment"
                  ref={inputComment}
                  value={input}
                  onChange={(e) => setInput(e.target.value.trimStart())}
                />
                <Button
                  disabled={!hasWriteAccess}
                  key="1"
                  className="formButton ml1"
                  onClick={() => hasWriteAccess && handleAddComment()}
                >
                  {t("send")}
                </Button>
              </div>
              {commentsData?.length ? (
                <>
                  <div className="commentsSection mt2">
                    <Steps
                      direction="vertical"
                      size="small"
                      current={commentsData?.length + 1 || 100}
                    >
                      {commentsData &&
                        commentsData?.map(({ body, created_at, name, id }) => (
                          <Step
                            key={id}
                            title={body}
                            description={`on ${moment(created_at).format(
                              "DD MMM YYYY HH:mm"
                            )} | Commented by: ${name}`}
                            icon={<MessageOutlined />}
                          />
                        ))}
                    </Steps>
                  </div>
                </>
              ) : (
                <NoCommentsComponent />
              )}
            </>
          ) : null}

          {tab === "history" ? (
            advanceHistory?.length ? (
              <div className="commentsSection mt2">
                <Steps
                  direction="vertical"
                  size="small"
                  current={advanceHistory?.length + 1 || 100}
                >
                  {advanceHistory &&
                    advanceHistory?.map(
                      ({ body, created_at, name, id, curr_status }) => (
                        <Step
                          key={id}
                          title={body}
                          description={
                            moment(created_at).format("DD MMM YYYY HH:mm:ss") +
                            " | " +
                            name +
                            " | Action: " +
                            curr_status
                          }
                          icon={<UserOutlined />}
                        />
                      )
                    )}
                </Steps>
              </div>
            ) : (
              <NodataComponent noBtns={true} />
            )
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AdvanceDetail;
