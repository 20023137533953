import {
    Menu
} from "antd";
import Sider from "antd/lib/layout/Sider";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    useDispatch, useSelector
} from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Collapsed } from "../../actions/auth";
import CusCreditNotes from "../../assets/ippo_icons/side-nav/CusCreditNotes";
import CusEstimates from "../../assets/ippo_icons/side-nav/CusEstimatesSVG";
import CusInvoices from "../../assets/ippo_icons/side-nav/CusInvoices";
import CusOverview from "../../assets/ippo_icons/side-nav/CusOverview";
import CusPaymentsMade from "../../assets/ippo_icons/side-nav/CusPaymentsMade";
import CusSalesOrders from "../../assets/ippo_icons/side-nav/CusSalesOrders";
import { LOGO, SHORT_LOGO } from '../../config/Images';
import useWindowDimensions from "./../useWindowDimensions";
import "./leftNavigation.css";
import DashboardSVG from "../../assets/ippo_icons/side-nav/DashboardSVG";

const CustomerLeftNavigation = (props) => {
    // const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [collapsed, setCollapsed] = React.useState(false);
    const [activeMenu, setActiveMenu] = React.useState("/customer/dashboard");
    const [activeSubMenu, setActiveSubMenu] = React.useState("");
    const [childrenMenu, setChildrenMenu] = React.useState("");
    const [showChildren, setShowChildren] = React.useState(false);
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const is_admin = useSelector((state) => state?.auth?.localVariables?.is_admin);
    const side_menu = useSelector((state) => state?.auth?.localVariables?.side_menu);
    const url = window.location.href;
    const history = useHistory();

    React.useEffect(() => {
        const storedMenu = localStorage.getItem("activeMenu");
        const storeActiveSubMenu = localStorage.getItem("activeSubMenu")
        const storeShowChildren = localStorage.getItem("showChildren")
        const storeChildrenMenu = localStorage.getItem("childrenMenu")
        setActiveMenu(storedMenu ? storedMenu : activeMenu);
        setActiveSubMenu(storeActiveSubMenu);
        setShowChildren(storeShowChildren);
        setChildrenMenu(storeChildrenMenu)
    }, []);

    React.useEffect(() => {
        localStorage.setItem("activeMenu", activeMenu);
        localStorage.setItem("activeSubMenu", activeSubMenu);
        localStorage.setItem("showChildren", showChildren);
        localStorage.setItem("childrenMenu", childrenMenu);
    }, [activeMenu, activeSubMenu, showChildren, childrenMenu]);
    React.useEffect(() => {
        if (width <= 1280) {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
    }, [width]);

    React.useEffect(() => {
        const url = window.location.href;
        if (url.includes("expenses/")) {
            setActiveMenu("/expenses");
        }
    }, [url]);

    React.useEffect(() => {
        if (performance.getEntriesByType) {
            const performanceEntries = performance.getEntriesByType("navigation");
            if (performanceEntries.length > 0) {
                const navigationTiming = performanceEntries[0];
                if (navigationTiming.type) {
                    // if (["/customer/invoice"].includes(activeMenu)) {
                    //     setCollapsed(true);
                    //     setShowChildren(true);
                    // }
                }
            }
        }
    }, [activeMenu, activeSubMenu, showChildren, childrenMenu, childrenMenu]);
    React.useEffect(() => {
        if (side_menu) {
            setActiveMenu(side_menu?.firstNav);
            setActiveSubMenu(side_menu?.secondNav);
            setShowChildren(side_menu?.showChildren);
            setChildrenMenu(side_menu?.childrenMenu)
        }
    }, [side_menu]);

    // const isSettings = () => {
    //   return hasAccess("organisation_settings", "read") ||
    //     hasAccess("users_and_controls", "read") ||
    //     hasAccess("categories", "read") ||
    //     hasAccess("policies_and_limits", "read") ||
    //     hasAccess("approvals", "read") ||
    //     hasAccess("summary", "read") ||
    //     hasAccess("statements", "read")

    // }
    const { t } = useTranslation();
    const submenus = {
        // '/customer/invoice': [{
        //     label: t('Invoice'),
        //     key: "",
        //     // altAdminKey: "/my-reports",
        //     hasAccess: true,
        //     children: [{
        //         label: t('Invoices'),
        //         key: "",
        //         hasAccess: true,
        //     }, {
        //         hasAccess: true,
        //         label: t('Retainer Invoices'),
        //         key: "/retainer"
        //     }]
        // }]
    }
    const items = [
        {
            key: '/customer/dashboard',
            icon: <CusOverview scale={0.7} opacity={activeMenu === '/customer/dashboard' ? 1 : null} />,
            icon2: <CusOverview scale={0.7} opacity={1} />,
            label: t('Dashboard'),
            description: 'Overview of the Spends',
            hasAccess: true
        },
        {
            key: '/products',
            icon: <DashboardSVG scale={0.7} opacity={activeMenu === '/products' ? 1 : null} />,
            icon2: <DashboardSVG scale={0.7} opacity={1} />,
            label: t('Products'),
            description: 'You can buy products here',
            hasAccess: true
        },
        {
            key: '/orders',
            icon: <DashboardSVG scale={0.7} opacity={activeMenu === '/orders' ? 1 : null} />,
            icon2: <DashboardSVG scale={0.7} opacity={1} />,
            label: t('Orders'),
            description: 'You can check your orders here',
            hasAccess: true
        },
        {
            key: '/customer/estimates',
            icon: <CusEstimates scale={0.7} opacity={activeMenu === '/customer/estimates' ? 1 : null} />,
            label: "Estimates",
            icon2: <CusEstimates scale={0.7} opacity={1} />,
            description: 'Review & Analyse and Download reports',
            hasAccess: true,
            titleLabel: "Estimates"
        },
        {
            key: '/customer/sales',
            icon: <CusSalesOrders scale={0.7} opacity={activeMenu === '/customer/sales' ? 1 : null} />,
            icon2: <CusSalesOrders scale={0.7} opacity={1} />,
            label: t('Sales Order'),
            description: 'Create Estimates,Invoices & Collect Payments',
            hasAccess: JSON.parse(localStorage.getItem("isSalesOrderView")),
            titleLabel: t('Sales Order'),
        },
        {
            key: '/customer/invoice',
            icon: <CusInvoices scale={0.7} opacity={activeMenu === '/customer/invoice' ? 1 : null} />,
            icon2: <CusInvoices scale={0.7} opacity={1} />,
            label: t('Invoice'),
            description: 'Create Estimates,Invoices & Collect Payments',
            hasAccess: true,
            titleLabel: t('Invoice'),
        },
        {
            key: '/cutomer/credit',
            icon: <CusCreditNotes scale={0.7} opacity={activeMenu === '/cutomer/credit' ? 1 : null} />,
            icon2: <CusCreditNotes scale={0.7} opacity={1} />,
            label: t('Credit Notes'),
            description: 'Create Estimates,Invoices & Collect Payments',
            hasAccess: JSON.parse(localStorage.getItem("isCreditNotesView")),
            titleLabel: t('Credit Notes'),
        },
        {
            key: '/customer/payments',
            icon: <CusPaymentsMade scale={0.7} opacity={activeMenu === '/customer/payments' ? 1 : null} />,
            icon2: <CusPaymentsMade scale={0.7} opacity={1} />,
            label: t('Payments Made'),
            description: 'Create Estimates,Invoices & Collect Payments',
            hasAccess: true,
            titleLabel: t('Payments Made'),
        },
    ]

    console.log("customer left navigation items", localStorage.getItem("isSalesOrderView"), items)
    useEffect(() => {
        dispatch({
            type: Collapsed,
            payload: collapsed
        })
    }, [collapsed])

    return (
        <div className='d-flex'>
            <Sider trigger={null} collapsible collapsed={collapsed} className={`left-menu ${collapsed ? "menu-collapsed" : ""}`}>
                <div className="logo mt2" onClick={() => {
                    setCollapsed(b => !b)
                }}>
                    {collapsed ?
                        <img src={SHORT_LOGO} alt="" onClick={() => history.push('/dashboard')} /> :
                        <img src={LOGO} alt="" />}
                </div>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    collapsed={collapsed}
                >
                    {items.map(itm => itm.hasAccess && <div data-testid={itm.key} key={itm.key} className={activeMenu === itm.key ? `menu-item menu-selected ${itm.divider ? "extra-margin" : ""}` : `menu-item ${itm.divider ? "extra-margin" : ""}`} onClick={() => {
                        setActiveMenu(itm.key)
                        if ([""].includes(itm.key)) {
                            // setCollapsed(true)
                            // const indexValue = submenus[itm.key].findIndex(item => item.hasAccess)
                            // const childHistorySet = () => {
                            //     const indexChild = submenus[itm.key][indexValue]?.children.findIndex(item => item.hasAccess)
                            //     setChildrenMenu(submenus[itm.key][indexValue]?.children?.[indexChild].key)
                            //     console.log("nav", itm.key + submenus[itm.key][indexValue]?.key + submenus[itm.key][indexValue]?.children?.[indexChild].key)
                            //     history.push(itm.key + submenus[itm.key][indexValue]?.key + submenus[itm.key][indexValue]?.children?.[indexChild].key)
                            // }
                            // if ("/customer/invoice") {
                            //     setActiveSubMenu(submenus[itm.key][indexValue]?.key)
                            //     setShowChildren(true)
                            //     if (submenus[itm.key][indexValue]?.children) {
                            //         childHistorySet()
                            //     } else {
                            //         console.log("nav", itm.key + submenus[itm.key][indexValue]?.key);
                            //         history.push(itm.key + submenus[itm.key][indexValue]?.key)
                            //     }
                            //     return;
                            // }

                        }
                        else {
                            setCollapsed(false)
                        }
                        history.push(itm.key)
                    }}>
                        <span className='icon'>
                            {itm.icon}
                        </span>
                        {!collapsed &&
                            <span className='nav-label'>
                                {itm.label}
                            </span>
                        }
                        {collapsed &&
                            <div className='side-nav-hover'>
                                <span className='icon'>
                                    {itm.icon2}
                                </span>
                                {itm.label}
                                <span className="dim-text">{itm.description}</span>
                            </div>}
                        {itm.divider && <div className="line" />}
                    </div>)}
                </Menu>
            </Sider>
            {collapsed && submenus[activeMenu] && <div className='sub-menu'>
                {items.find(item => item?.key === activeMenu) && <h4 className="titleLabel">{items.find(item => item?.key === activeMenu)?.titleLabel}</h4>}
                {submenus[activeMenu]?.map((itm) => itm.hasAccess && <>
                    {/* {activeMenu ===  && <h4 className="titleLabel">{itm.titleLabel}</h4>} */}
                    <div key={itm.key} data-testid={itm.key} className={activeSubMenu === itm.key ? `sub-menu-item sub-menu-selected ${itm.children ? "has-children" : ""}` : `sub-menu-item ${itm.children ? "has-children" : ""}`} onClick={(e) => {
                        e.stopPropagation()
                        setActiveSubMenu(itm.key)
                        if (itm.children) {
                            const indexValue = itm.children.findIndex(item => item.hasAccess)
                            setChildrenMenu(itm.children?.[indexValue].key)
                            history.push(activeMenu + itm.key + itm.children?.[indexValue].key)
                            setShowChildren(true)
                            return;
                        } else {
                            setShowChildren(false)
                        }
                        history.push(activeMenu + itm.key)
                    }}>
                        <span className='nav-label'>
                            {itm.label}
                        </span>
                    </div>
                    {showChildren && itm.children && activeSubMenu === itm.key && <div className='sub-menu-children'>
                        {itm.children?.map(ele => ele.hasAccess && <div className={childrenMenu === ele.key ? `sub-menu-children-item sub-menu-selected` : `sub-menu-children-item`} onClick={() => {
                            setChildrenMenu(ele.key)
                            history.push(activeMenu + activeSubMenu + ele.key)
                        }}><span className='nav-label'>
                                {ele.label}
                            </span></div>)}
                    </div>}
                </>)}

            </div>}
        </div>
    );
};

export default withRouter(CustomerLeftNavigation);
