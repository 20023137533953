import { AndroidFilled, AppleFilled, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Row, Space, Button } from 'antd';
import React from 'react';
import { useTranslation } from "react-i18next";
import PhoneImg from "../../assets/images/user_dash_mob.png";
import CardDashboard from './CardDashboard';

const DashBoardFoorter = () => {
  const { t } = useTranslation();
  return (
    <Row style={{ marginTop: '20px' }} >
      <Col>
        <CardDashboard
          title={t('contactUs')}
          className="download-app footer-contact"
        >
          <Space direction='vertical' size={'large'} style={{ padding: '10px' }} >
            <div className='d-flex'>
              <div className='circle-contact'>
                <MailOutlined />
              </div>

              <span className='contact-text'>
              {t('contactUsContant')}
              </span>
            </div>

            <div className='d-flex'>
              <div className='circle-contact' >
                <PhoneOutlined />
              </div>

              <span className='contact-text'>
              {t('contactUsContant')}
              </span>
            </div>
          </Space>
        </CardDashboard>
      </Col>

      <Col>
        <CardDashboard
          title={t('expenseOnTheGo')}
          className="download-app"
        >
          <div className='d-flex' style={{ padding: '10px' }}>
            <img src={PhoneImg} alt={"phone-logo"} style={{ width: '160px' }} />

            <div className="content">
              <span className='contact-text'>
              {t('expenseOnTheGoContent')}
              </span>

              <Space style={{ fontSize: '30px' }}>
                <AppleFilled />
                <AndroidFilled />
              </Space>
            </div>
          </div>
        </CardDashboard>
      </Col>

      <Col>
        <CardDashboard
          title={t('reviewUs')}
          style={{ height: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            <span className='contact-title'>
            {t('reviewUsTitle')}
            </span>

            <div className='contact-text' style={{ marginTop: '12px', marginBottom: '30px' }}>
            {t('reviewUsDecription')}
            </div>

            <div>
              <Button type="primary" className="pp-main-button ">
              {t('reviewUsButton')}
              </Button>
            </div>
          </div>
        </CardDashboard>
      </Col>
    </Row>
  );
};

export default DashBoardFoorter;