import React from 'react';
import success from "../../assets/images/success.png"
const Success = () => {
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <img src={success} alt="" style={{
        height: "100vh",
        padding:"5rem 0"
      }} />
    </div>
  );
};

export default Success;