import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import success from "../../assets/images/succesasset.png";
import failed from "../../assets/images/failed.png";
import CryptoJS from 'crypto-js';
const SetIn = () => {
  const location = useLocation();
  const history = useHistory()
  const [data, setData] = useState(null)

  useEffect(() => {
    const search = location.state?.search ?? location.search
    if (search) {
      let searchParams = new URLSearchParams(search)
      var rawData = CryptoJS.enc.Hex.parse(searchParams.get("enc_resp"));
      var key = CryptoJS.enc.Utf8.parse("P1a3y2C5r4a6f7tS")
      let bytes = CryptoJS.AES.decrypt({ ciphertext: rawData }, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
      setData(JSON.parse(atob(bytes.toString(CryptoJS.enc.Base64)).trim()))
      if (!location?.state?.search)
        history.replace("/pinset", {
          search: location.search
        })
    }
    // eslint-disable-next-line
  }, [location.search, location.state?.search])

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    }}>
      <img src={data?.responseCode === "00" ? success : failed} alt="" style={{
        padding: "2rem 0"
      }} width={300} />
      {data?.responseCode === "00" ? "" : data?.errorMsg}
      <h2 style={{ color: "#333" }}>PIN Set {data?.responseCode === "00" ? "Successful" : "Unsuccessful"}.</h2>
      <p></p>
    </div>
  );
};

export default SetIn;