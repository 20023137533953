import React from "react";

import {
  Button,
  Checkbox,
  message,
  Modal,
  Space,
  Table,
  Typography,
} from "antd";
import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteProjectsField,
  deleteProjectsField,
  getProjectsField,
  UpdateProjectsField,
  updateProjectsField,
  enableDisableProjectField,
  AddProjectsField,
} from "../../../../actions/companySettings";
import { employees } from "../../../../actions/employees";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { hasAccess, objectToFormData } from "../../../../utils";
import PageHeader from "../../../PageHeader";
import "./../../styles/setting.css";
import "./../Custom.css";
import AddProjectField from "./AddProjectField";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

message.config({
  maxCount: 3,
});


const Projects = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const [modalType, setIsModalType] = React.useState("center");
  const [selectedId, setSelectedId] = React.useState("");
  const [projectsFields, setProjectsFields] = React.useState([]);
  const [employeesData, setemployeesData] = React.useState([]);
  const { t } = useTranslation();
  const updateCompanySettingResponse = useSelector(
    (state) => state.companySetting.updateCompanySetting || {}
  );
  const projectsFieldResponse = useSelector(
    (state) => state.companySetting.getProjectsField || {}
  );
  const deleteProjectsFieldResponse = useSelector(
    (state) => state.companySetting.deleteProjectsField || {}
  );
  const addProjectsFieldResponse = useSelector(
    (state) => state.companySetting.addProjectsField || {}
  );
  const updateProjectsFieldResponse = useSelector(
    (state) => state.companySetting.updateProjectsField || {}
  );
  const employeesResponse = useSelector(
    (state) => state.employees.employees || {}
  );
  const {
    data: { data: employeesRecord = [] } = {},
    success = false,
  } = employeesResponse;
  const totalRecords = useSelector(
    (state) => state?.companySetting?.getProjectsField?.data?.total_count
  );

  console.log("projectsFieldResponse", projectsFieldResponse)

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("code"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("desc"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("project_head"),
      dataIndex: "user_name",
      key: "user_name",
    },
  ];

  React.useEffect(() => {
    // dispatch(companySetting({ show: 'id' }));
    dispatch(getProjectsField({}));
    dispatch(employees({ status: true, params: { pagination: false } }));
    dispatch(
      headerTitles({
        title: "projects",
        description: "",
      })
    );
    // setIsModalVisible(false,);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (success) {
      setemployeesData(employeesRecord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = false, errors = "" } = {},
    } = updateCompanySettingResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
    } else {
      errors && message.error(<span className="messageText">{errors}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanySettingResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = updateProjectsFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getProjectsField({}));
      dispatch({
        type: UpdateProjectsField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UpdateProjectsField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProjectsFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = deleteProjectsFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getProjectsField({}));
      dispatch({
        type: DeleteProjectsField.RESET,
      });
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: DeleteProjectsField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProjectsFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = addProjectsFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getProjectsField({}));
      dispatch({
        type: AddProjectsField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddProjectsField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProjectsFieldResponse]);

  React.useEffect(() => {
    const { data: { data: data = [], status = false } = {} } =
      projectsFieldResponse;
    if (status) {
      setProjectsFields(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsFieldResponse]);

  const tableAction = [
    {
      title: t("enable"),
      dataIndex: "is_enable",
      key: "is_enable",
      render: (status, record) => (
        <Checkbox
          key={record.id}
          onChange={(e) =>
            dispatch(
              enableDisableProjectField(
                objectToFormData({ id: record.id, is_enable: e.target.checked })
              )
            )
          }
          defaultChecked={status}
          disabled={!hasWriteAccess}
        ></Checkbox>)
    },
    {
      title: t('actions'),
      key: 'action',
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button disabled={!hasWriteAccess} onClick={() => showEditModal(record.id)}><img src={EditIcon} alt={"logo"} className="action-logo" /></button>
          <button disabled={!hasWriteAccess} onClick={() => showDeleteModal(record.id)}><img src={DeleteIcon} alt={"logo"} className="action-logo" /></button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("center");
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("right");
  };

  function handleButtonClick(e) {
    showModal();
  }

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t("no_longer_users")}</Text>
        <br />
        <Button
          disabled={!hasWriteAccess}
          key="1"
          className="formButton mt1"
          onClick={() => dispatch(deleteProjectsField(objectToFormData({ id: selectedId })))}
        >{t('delete')}</Button>
        <Button key="2" className="cancelButton ml1 mt1" onClick={() => handleCancel()} >{t('cancel')}</Button>
      </>
    );
  };

  return (
    <>
      <Modal
        title={
          modalType === "center" ? (
            <>
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
              {t("delete_project")}
            </>
          ) : (
            <Title level={4}>
              {selectedId ? t("edit_project") : t("new_project")} &nbsp;
                <span className="text-small">{t("enter_and_save")}</span>
            </Title>
          )
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={
          modalType === "center"
            ? "center-aligned-modal"
            : "right-alinged-modal-small"
        }
      >
        {modalType === "center" ? (
          renderDeleteOption()
        ) : (
          <AddProjectField
            id={selectedId}
            handleCancel={handleCancel}
            employeesData={employeesData}
          />
        )}
      </Modal>
      {<Button onClick={handleButtonClick} className="mb1 ml1 addNew" disabled={!hasWriteAccess}>
        <img src={AddNewIcon} alt={"logo"} className="addNew-logo" />{t('addNew')}
      </Button>}
      <Table rowKey="id" columns={[...columns, ...tableAction]} dataSource={projectsFields} className="settingsTable" 
      pagination={{
        total:totalRecords || 0,
        onChange:(pagee) =>dispatch(getProjectsField({
          page_number:pagee
        }))
      }}/>
    </>
  );
};

export default Projects;

