import { Button, message, Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ForwardApproval,
  forward_approval,
} from "../../actions/reports/reports";
import { FormSelect } from "../inputs";
import { useTranslation } from "react-i18next";
import useSettings from "../../config/useSettings";

const ForwardPopup = ({ isVisiable, onCancel, setForwardPop, reportId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { onCall, data } = useSettings({
    api: "/v1/settings/0/users/getFilteredRoles",
    method: "post",
  });
  const history = useHistory();
  const forwardApprvalResponse = useSelector(
    (state) => state?.reports?.forward_approval?.data ?? {}
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      approver_id: "",
    },
    shouldUseNativeValidation: false,
  });
  const role = localStorage.getItem("role");
  const onSubmit = (data) => {
    data.report_id = reportId;
    data.module_type = "REPORT";
    dispatch(forward_approval(data));
  };
  useEffect(() => {
    if (role)
      onCall({
        data: {
          role_name: localStorage.getItem("role")?.split(","),
        },
      });
  }, [role]);

  useEffect(() => {
    if (forwardApprvalResponse?.error === false) {
      message.success(
        <span className="messageText">{forwardApprvalResponse?.message}</span>
      );
      setForwardPop(false);
      reset();
      dispatch({
        type: ForwardApproval.RESET,
      });
      history.push("/expenses/reports");
    }
    if (forwardApprvalResponse?.error === true) {
      message.success(
        <span className="messageText">{forwardApprvalResponse?.message}</span>
      );
      dispatch({
        type: ForwardApproval.RESET,
      });
    }
  }, [forwardApprvalResponse]);

  return (
    <>
      <Modal
        title={t("select_employee")}
        visible={isVisiable}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={onCancel}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <FormSelect
                inline
                control={control}
                {...register("approver_id")}
                required
                errors={errors}
                label={t("employee_role")}
                placeholder="Select"
                hideCreateOption
                options={data?.data
                  ?.filter(
                    (item) => item.user_id !== localStorage.getItem("user_id")
                  )
                  ?.map((ele) => ({ label: ele.name, value: ele.id }))}
              />
            </div>
            <div style={{ display: "flex", marginTop: "2rem" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="pp-main-button"
                id="save"
              >
                <span>{t("submit")}</span>
              </Button>

              <Button
                className="pp-secondary-button"
                style={{ marginLeft: "2rem" }}
                onClick={() => setForwardPop(false)}
              >
                <span>{t("cancel")}</span>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default ForwardPopup;
