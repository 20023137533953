import { Button } from "antd";
import { OnBoardEnglish } from "../Language/English";
import NotifyBellIcon from "../assets/icons/notify-bell-icon.svg"
import NotifyIcon from "../assets/icons/notfication-icon.svg"
const KYCNotify = ({ allCountries, countary }) => {
    console.log("kyc notify all countries", allCountries)
    return (
        <>
            <div className="kyc-notify">
                <div className="kyc-notify-container d-flex">
                    <div className="icon-section">
                        <img src={NotifyIcon} alt="notify icon" />
                    </div>
                    <div>
                        <h4 className="header">{`Get notified about Bluerack in ${allCountries?.find(ele => ele?.value == localStorage.getItem("country"))?.country_name}`}</h4>
                        <span className="text">{OnBoardEnglish?.kycNotifyText}</span>
                        <div className="button mt2">
                            <Button className="v-center" type="default" icon={<img src={NotifyBellIcon} alt="notify-button-icon" />}>{OnBoardEnglish?.kycNotifyButtonText}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default KYCNotify;