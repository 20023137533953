import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { bus_columns } from './TripTables';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
const Option = Select.Option;

const othersValidation = yup.object().shape({
  other_bookings: yup.array().of(yup.object().shape({
    departure_date: yup.string().required("Departure date is required"),
    description: yup.string().required("Description is required"),
    from_city: yup.string().required("From city is required"),
    to_city: yup.string().required("To city is required"),
    travel_mode: yup.string().required("Mode is required"),
  }))
})



const OtherBooking = ({ tripForm, isEdit, isModalVisible, setIsModalVisible }) => {
  const { other_bookings = [] } = tripForm?.values;
  const otherFormik = useFormik({
    initialValues: {
      other_bookings: [{
        from_city: "",
        to_city: "",
        departure_date: "",
        travel_mode: "",
        description: ""
      }],
    },
    validationSchema: othersValidation,
    onSubmit: (vals) => {
      tripForm.setFieldValue("other_bookings", [...vals.other_bookings])
      handleModalCancelButton()
    }
  })
  const {t} = useTranslation();

  useEffect(() => {
    if (isModalVisible)
      otherFormik.setFieldValue("other_bookings", other_bookings?.length === 0 ? [{
        from_city: "",
        to_city: "",
        departure_date: "",
        travel_mode: "",
        description: ""
      }] : other_bookings)
    // eslint-disable-next-line
  }, [isModalVisible])


  const handleModalCancelButton = () => {
    setIsModalVisible(false)
  }

  const onNewRowChange = (type, idx) => {
    if (type === "remove") {
      if (idx > -1) {
        let trips = otherFormik.values.other_bookings
        trips.splice(idx, 1)
        otherFormik.setFieldValue("other_bookings", trips)
      }
    } else {
      let hr = otherFormik.values.other_bookings;
      hr = [...hr, {
        departure_date: "",
        description: "",
        from_city: "",
        to_city: "",
        travel_mode: "",
      }]
      otherFormik.setFieldValue("other_bookings", hr)
    }
  }

  const renderCarForm = () => <form
    onSubmit={otherFormik.handleSubmit}
    className="modal-form"
  >
    <div className="trips-detail detail-card p1">
      {otherFormik.values?.other_bookings.map((item, index) => (
        <Row className='mt1' key={`hotelrows_${index}`}>
          <Col span={4}>
            <Form.Item label={t('from_city')} required
            >
              <Input placeholder={t('enter')}
                name={`from_city_key${index}`}
                value={otherFormik.values?.other_bookings[index]?.from_city}
                onChange={(e) => {
                  let fr = otherFormik.values?.other_bookings;
                  fr[index].from_city = e.target.value
                  otherFormik.setFieldValue("other_bookings", fr)
                }}
              />
              {otherFormik.touched.other_bookings?.[index]?.from_city && otherFormik.errors.other_bookings?.[index]?.from_city && <div className='errorMsg'>
                {otherFormik.errors.other_bookings?.[index]?.from_city}
              </div>}
            </Form.Item>
          </Col>
          <Col span={4} className="ml1">
            <Form.Item label={t('to_city')} required
            >
              <Input placeholder={t('enter')}
                name={`to_city_key${index}`}
                value={otherFormik.values?.other_bookings[index]?.to_city}
                onChange={(e) => {
                  let fr = otherFormik.values?.other_bookings;
                  fr[index].to_city = e.target.value
                  otherFormik.setFieldValue("other_bookings", fr)
                }}
              />
              {otherFormik.touched.other_bookings?.[index]?.to_city && otherFormik.errors.other_bookings?.[index]?.to_city && <div className='errorMsg'>
                {otherFormik.errors.other_bookings?.[index]?.to_city}
              </div>}
            </Form.Item>
          </Col>
          <Col span={4} className="ml1">
            <Form.Item label={t('departure_date')} required
            >
              <DatePicker format={'DD/MM/YYYY HH:mm:ss a'} showTime
                name={`hot_departure_date_key${index}`}
                value={
                  otherFormik.values?.other_bookings[index]?.departure_date ?
                    moment(otherFormik.values?.other_bookings[index]?.departure_date, 'DD/MM/YYYY HH:mm:ss a') :
                    undefined}
                onChange={(date) => {
                  let fr = otherFormik.values?.other_bookings;
                  fr[index].departure_date = date ? moment(date).format('DD/MM/YYYY HH:mm:ss a') : ""
                  otherFormik.setFieldValue("other_bookings", fr)
                }}
              />
              {otherFormik.touched.other_bookings?.[index]?.departure_date && otherFormik.errors.other_bookings?.[index]?.departure_date && <div className='errorMsg'>
                {otherFormik.errors.other_bookings?.[index]?.departure_date}
              </div>}
            </Form.Item>
          </Col>
          <Col span={4} className="ml1">
            <Form.Item label={t('travel_mode')} required>
              <Select
                style={{ width: 175 }}
                placeholder={t('travel_mode')}
                value={otherFormik.values?.other_bookings[index]?.travel_mode}
                onChange={(val) => {
                  let fr = otherFormik.values?.other_bookings;
                  fr[index].travel_mode = val
                  otherFormik.setFieldValue("other_bookings", fr)
                }}
              >
                <Option key='bus' value='Bus'>{t('Bus')}</Option>
                <Option key='train' value='Train'>{t('train')}</Option>
                <Option key='other' value='Others'>{t('others')}</Option>
              </Select>
              {otherFormik.touched.other_bookings?.[index]?.travel_mode && otherFormik.errors.other_bookings?.[index]?.travel_mode && <div className='errorMsg'>
                {otherFormik.errors.other_bookings?.[index]?.travel_mode}
              </div>}
            </Form.Item>
          </Col>
          <Col span={4} className="ml1">
            <Form.Item label={t('desc')} required>
              <Input.TextArea
                placeholder={t('enter_desc')}
                autoSize={{ minRows: 1, maxRows: 5 }}
                maxLength={250}
                name={`hot_description_key${index}`}
                value={otherFormik.values?.other_bookings?.[index]?.description}
                onChange={(e) => {
                  let fr = otherFormik.values?.other_bookings;
                  fr[index].description = e.target.value
                  otherFormik.setFieldValue("other_bookings", fr)
                }}
              />
              {otherFormik.touched.other_bookings?.[index]?.description && otherFormik.errors.other_bookings?.[index]?.description && <div className='errorMsg'>
                {otherFormik.errors.other_bookings?.[index]?.description}
              </div>}
            </Form.Item>
          </Col>
          <Col span={1}>
            {index >= 1 ?
              <MinusCircleOutlined className='mt3' onClick={() => onNewRowChange('remove', index)} /> : null}
          </Col>
        </Row>
      ))}
      {other_bookings.length <= 5 ? <>
        <hr />
        <div className='p1 cursor type-link-button' onClick={() => onNewRowChange('add')}> + {t('another_line')}</div>
      </> : null
      }
    </div>

    <div className='mb3 mt1'>
      <Button key="2" className="cancelButton ml1 mt3 floatRight cancel-button" onClick={() => handleModalCancelButton()}>{t('cancel')}</Button>
      <Button key="1" htmlType="submit" className="formButton mt3 primaryButton floatRight save-button"
      >{t('save')}</Button>
    </div>
  </form>
  return (
    <>
      <Modal
        title={t('add_other_details')}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        footer={[]}
        className="center-aligned-modal-big"
      >
        {renderCarForm()}
      </Modal>
      <div className="trips-detail detail-card mb2">
        <div className='mt1'>
          <Button
            className="primary-btn floatRight mr1"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            {other_bookings?.length ? t('edit_other_booking') : t('add_other_booking')}
          </Button>
        </div>
        <Table
          rowKey="id"
          columns={bus_columns}
          dataSource={other_bookings?.length ? other_bookings : []}
          className="settingsTable tripsTable"
          pagination={false}
        />
      </div>
    </>
  )
};

export default OtherBooking;