import { CaretDownFilled, DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Row, Space, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { updateCompanyProfilefImages } from "../../actions/companySettings";
import { ShowProfile, UploadProfile, portalDashboard, show_profile, upload_profile } from "../../actions/customers";
import { SideMenu } from "../../actions/masterdata/masterdata";
import { UpdateBeneficiary, updateBeneficiary } from "../../actions/purchases";
import Close from "../../assets/icons/close.png";
import email from "../../assets/ippo_icons/email.png";
import phone from "../../assets/ippo_icons/phone.png";
import user_icon from "../../assets/ippo_icons/user_icon.png";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import AddVendor from "../Purchases/AddVendor";
import CreatePayment from "../Purchases/Payments/CreatePayment";
import AddCustomer from "./AddCustomer";
import useDocumentAxios from '../../config/useDocument';
const ViewDetails = ({ beneficiary, profilePic, isVendor, updateOnSuccess, hasWriteAccess, callOnMount }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [tab, setTab] = React.useState("overview");
  const [openAddNewVendor, setOpenAddNewVendor] = useState(false);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [openAddNewPaymentMade, setOpenAddNewPaymentMade] = useState(false);
  const [title, setTitle] = useState("")
  const [isActiveMessage, setIsActiveMessage] = useState(false)
  const history = useHistory();
  const { t } = useTranslation()
  const updateBenData = useSelector((state) => state?.purchases?.updateBeneficiary?.data || {});
  const showResponse = useSelector((state) => state?.customers?.upload_profile)
  const { onCall: portalActivate, data: portalActivateData, reset: portalActivateReset, loading: customerPortalLoading } = usePayAxios({ api: apis.portalActivateAPI, method: "post" });
  const secs = [t('Dashboard')];
  // const secs = [t('Dashboard'),
  //   "Comments", "History"
  // ];

  const { onCall: DeleteProfile, data: DeleteProfileData, reset: DeleteProfileReset, } = useDocumentAxios({
    api: beneficiary?.profile?.id ? apis?.profileUpdate : apis?.profileUpload,
    method: "post",
  });
  useEffect(() => {
    // dispatch(show_profile({
    //   id: beneficiary?.id,
    //   record_type: "beneficiary"
    // }))
    let locationSplit = location?.pathname?.split("/")
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: isVendor ? "/vendors" : "/customers",
      }
    })
  }, [])

  useEffect(() => {
    if (location?.state?.setSideMenu) {
      let locationSplit = location?.pathname?.split("/")
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
        }
      })
    }
  }, [location])

  useEffect(() => {
    if (showResponse?.data?.response?.[0]?.file) {
      dispatch(show_profile({
        id: beneficiary?.id,
        record_type: "beneficiary"
      }))
    }
    dispatch({ type: ShowProfile.RESET })
  }, [showResponse])



  useEffect(() => {
    if (isVendor) {
      setTitle("Vendor")
    } else {
      setTitle("Customer")
    }
  }, [])

  useEffect(() => {
    if (portalActivateData?.error == false) {
      message.success(<span className="messageText">{portalActivateData?.message}</span>)
      updateOnSuccess()
    } else if (portalActivateData?.error == true) {
      message.error(<span className="messageText">{portalActivateData?.message}</span>)
    }
    portalActivateReset({});
  }, [portalActivateData])
  const handleActivate = () => {
    setIsActiveMessage(!isActiveMessage)
    dispatch(updateBeneficiary({
      beneficiary_id: beneficiary?.id,
      beneficiary_status: "active"
    }));
  };

  const handleDeactivate = () => {
    setIsActiveMessage(!isActiveMessage)
    dispatch(updateBeneficiary({
      beneficiary_id: beneficiary?.id,
      beneficiary_status: "in-active"
    }));
  };

  const handlerNewBill = () => {
    history.push("/purchases/bills/create-new-bill", {
      setSideMenu: true,
      from: location.pathname,
      beneficiary_id: beneficiary?.id,
      beneficiary_name: beneficiary?.beneficiary_name,
    })
  };

  const handlerNewInvoice = () => {
    history.push("/sales/invoices/create-new-invoice", {
      setSideMenu: true,
      from: location.pathname,
      beneficiary_id: beneficiary?.id,
      beneficiary_name: beneficiary?.beneficiary_name,
      convert: true,
      to: "/sales/invoices"
    })
  };

  const handlerNewPO = () => {
    history.push("/purchases/orders/create-new-bill", {
      setSideMenu: true,
      from: location.pathname,
      beneficiary_id: beneficiary?.id,
      beneficiary_name: beneficiary?.beneficiary_name,
      convert: true,
      to: "/purchases/orders/my-bills"
    })
  };

  const handlerNewSO = () => {
    history.push("/sales/orders/create-new-sale", {
      setSideMenu: true,
      from: location.pathname,
      beneficiary_id: beneficiary?.id,
      beneficiary_name: beneficiary?.beneficiary_name,
      to: "/sales/orders/my-sales"
    })
  };
  const handleNewEstimate = () => {
    history.push("/sales/estimates/create-new-estimate", {
      setSideMenu: true,
      from: location.pathname,
      beneficiary_id: beneficiary?.id,
      beneficiary_name: beneficiary?.beneficiary_name,
      to: "/sales/estimates/my-estimates"
    })
  }


  useEffect(() => {
    if (DeleteProfileData?.error == false || showResponse?.data?.error == false) {
      message.success(<span className="messageText">{DeleteProfileData?.message ? DeleteProfileData?.message : showResponse?.data?.message}</span>)
      updateOnSuccess()
      dispatch({ type: UploadProfile.RESET })
      DeleteProfileReset()
    }
  }, [DeleteProfileData, showResponse])
  const uploadButton = (
    <div className="upload_div">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('upload')}
      </div>
    </div>
  );
  const handleChange = (info) => {
    if (info.file.status === 'done' || info.file.status === 'error') {
      if (info.file?.originFileObj?.type?.includes("png") || info.file?.originFileObj?.type?.includes("jpg") || info.file?.originFileObj?.type?.includes("jpeg")) {

        const formData = new FormData();

        if (beneficiary?.profile?.id) {
          formData.append("attachmentData", JSON.stringify([
            {
              id: beneficiary?.profile?.id,
              filename: info?.file?.name,
            }
          ]));
          formData.append("files", info.file.originFileObj)
          DeleteProfile({
            data: formData,
            params: {
              record_id: beneficiary?.id
            },
            headers: {
              "Content-Type": "multipart/formdata",
            }
          })
        } else {
          formData.append("files", info.file.originFileObj);
          formData.append("name", "profile")
          formData.append("record_type", "beneficiary");
          formData.append("record_id", beneficiary?.id);
          DeleteProfile({ data: formData })
        }
      } else {
        message.error(<span className="messageText">{t('image')}</span>)
      }
    }
  };


  useEffect(() => {
    if (updateBenData?.error === false) {
      updateOnSuccess()
      if (isActiveMessage) {
        setIsActiveMessage(!isActiveMessage)
        message.success(
          <span className="messageText">{`${title} ${beneficiary?.beneficiary_status === "active" ? "deactivated" : "activated"} succesfully`}</span>
        );
      }
      dispatch({
        type: UpdateBeneficiary.RESET
      })
    } else if (updateBenData?.error) {
      // updateOnSuccess()
      message.error(
        <span className="messageText">{updateBenData?.message}</span>
      )
      dispatch({
        type: UpdateBeneficiary.RESET
      })
    }

  }, [updateBenData])

  return (
    <>
      {openAddNewVendor && <AddVendor
        open={openAddNewVendor && hasWriteAccess}
        dataBeneficiary={beneficiary}
        onClose={() => {
          setOpenAddNewVendor(false);
        }}
      />}
      {openAddNew && <AddCustomer open={openAddNew && hasWriteAccess} onClose={() => {
        setOpenAddNew(false)
      }} dataBeneficiary={beneficiary} />}
      <CreatePayment
        isVisible={openAddNewPaymentMade && hasWriteAccess}
        onClose={() => {
          setOpenAddNewPaymentMade(false);
        }}
      />
      <div className="mr1 d-flex gap1 align-items-center justify-content-end">
        {beneficiary?.beneficiary_status === "active" && <Dropdown
          icon={<CaretDownFilled className="down-caret" />}
          type="primary"
          className={`pp-dropdownbutton`}
          style={{
            backgroundColor: "#3F81F4",
            height: "42px",
            display: "flex",
          }}
          trigger={['click']}
          onClick={() => {
            // if (handleAddButton) {
            //   handleAddButton()
            // } else {
            //   setOpenAddNew(true);
            // }
          }}
          overlay={
            <Menu>
              {isVendor ?
                <>
                  <label>{t('Purchases')}</label>

                  <Menu.Item onClick={() => {
                    hasWriteAccess && handlerNewBill()
                  }}>{t('bill')}</Menu.Item>
                  <Menu.Item onClick={() => {
                    hasWriteAccess && handlerNewPO()
                  }}>{t('purchase_order')}</Menu.Item>
                  {/* <Menu.Item onClick={handlerNewPaymentMade}>
                    Payment Made
                  </Menu.Item> */}
                </> :
                <>
                  <label>{t('Sales')}</label>
                  <Menu.Item onClick={() => {
                    hasWriteAccess && handleNewEstimate()
                  }}>
                    {t('est')}
                  </Menu.Item>
                  <Menu.Item onClick={() => {
                    hasWriteAccess && handlerNewSO()
                  }}>{t('order_sale')}</Menu.Item>
                  <Menu.Item onClick={() => {
                    hasWriteAccess && handlerNewInvoice()
                  }}>{t('invoice')}</Menu.Item>
                  {/* <Menu.Item onClick={() => {
                    hasWriteAccess && handlerNewPaymentReceived()
                  }}>
                    {t('payment_receive')}
                  </Menu.Item> */}
                </>}
              {/*<Menu.Item>Vendor Credits</Menu.Item>
                <Menu.Item>Journals</Menu.Item> */}
            </Menu>
          }
        // menu={{ items: options, onClick: () => {} }}
        >
          <div style={{ display: "flex", opacity: hasWriteAccess ? 1 : 0.5, }}>
            {/* <div style={{ paddingRight: "6px" }}>
                    <img
                      src={plus}
                      alt=""
                      style={{
                        height: "14px",
                        position: "relative",
                        bottom: 2,
                      }}
                    />
                  </div> */}
            <span
              style={{
                margin: 0,
                fontWeight: "medium",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#ffffff",
                backgroundColor: "#3f81f4",
                borderRadius: "10px !important",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer"
              }}
            >
              {t('new_transaction')} <CaretDownFilled className="down-caret" style={{ margin: "3px 0px 0px 3px" }} />
            </span>
          </div>

        </Dropdown>}
        <button disabled={!hasWriteAccess} className="pp-secondary-button" onClick={() => {
          if (isVendor)
            setOpenAddNewVendor(true)
          else
            setOpenAddNew(true)
        }}>
          <span>
            {t('edit')}
          </span>
        </button>
        <Dropdown
          disabled={!hasWriteAccess}
          icon={<CaretDownFilled className="down-caret" />}
          type="primary"
          className={`pp-dropdownbutton`}
          style={{
            backgroundColor: "#3F81F4",
            height: "42px",
            display: "flex",
          }}
          onClick={() => {
            // if (handleAddButton) {
            //   handleAddButton()
            // } else {
            //   setOpenAddNew(true);
            // }
          }}
          overlay={
            <Menu>
              <>
                <label>{t('more')}</label>
                {/* <Menu.Item onClick={handlerNewVendor}>Vendor</Menu.Item> */}
                {beneficiary?.beneficiary_status === "in-active" && <Menu.Item onClick={() => { hasWriteAccess && handleActivate() }}>{t('activate')}</Menu.Item>}
                {beneficiary?.beneficiary_status === "active" && <Menu.Item onClick={() => { hasWriteAccess && handleDeactivate() }}>{t('deactivate')}</Menu.Item>}
              </>
            </Menu>
          }
        // menu={{ items: options, onClick: () => {} }}
        >
          <Button
            style={{ color: "#000" }}
            type="primary"
            className={`pp-dropdownbutton sort-btn view-details no-dd`}
          >
            {/* {activeSort || "Sort By"} */}
            <EllipsisOutlined className="pp-icon-25" />
            {/* <CaretDownFilled className="down-caret" /> */}
          </Button>
        </Dropdown>

        <span
          className="close cursor"
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div>
      <div className="view-details">
        <div className="new-report-detail">
          <div className="flex-between ">
            <div className="flex-between">
              <div className="report-head">
                <div className="title-sec">
                  <h3>{beneficiary?.company_name}</h3>
                  {beneficiary?.beneficiary_status && (
                    <span
                      className={`status status_${beneficiary?.beneficiary_status}`}
                    >
                      {beneficiary?.beneficiary_status}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {
              beneficiary?.invited ? (
                <>
                  <div className="mr2">
                    <Button
                      style={{ color: "#000" }}
                      type="primary"
                      className="activate-portal"
                      disabled={!beneficiary?.invited}
                      onClick={() => {
                        portalActivate({
                          data: {
                            beneficiary_id: beneficiary?.id,
                            portal_access: !beneficiary?.configurations?.portalAccess
                          }
                        }).then((res) => {
                          if (callOnMount && res.error === false) {
                            callOnMount()
                          }
                        })
                      }}
                      loading={customerPortalLoading}
                    >
                      {beneficiary?.configurations?.portalAccess ? "Deactivate Portal" : "Activate Portal"}
                    </Button>
                  </div>
                </>
              ) : (null)
            }
          </div>

          <div className="tabs-sec">
            <ul>
              {secs.map((ele) => (
                <li
                  onClick={() => setTab(ele.toLowerCase())}
                  className={`${tab.toLowerCase() === ele.toLowerCase() ? "active" : ""
                    }`}
                >
                  {ele}
                </li>
              ))}
            </ul>
          </div>

          <div className="m1 p1">
            <Row>
              <Col span={12} className="name-details">
                <div className="d-flex-center flex-col">
                  <Upload
                    disabled={!hasWriteAccess}
                    name="avatar"
                    listType="picture-circle"
                    className="avatar-uploader mb1"
                    showUploadList={false}
                    onChange={handleChange}
                    id={"upload"}
                  >
                    {beneficiary?.profile?.id ? (
                      <img
                        src={beneficiary?.profile?.url}
                        alt="avatar"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%"
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                  {beneficiary?.profile?.id && <Space>
                    <Button icon={<EditOutlined />} onClick={() => {
                      document.getElementById("upload").click()
                    }} disabled={!hasWriteAccess} />
                    <Button icon={<DeleteOutlined />} onClick={() => {
                      const data = {
                        recordId: beneficiary?.id,
                        ids: [beneficiary?.profile?.id]
                      }
                      dispatch(upload_profile(data))
                    }} disabled={!hasWriteAccess} />
                  </Space>}
                </div>
                <div>
                  <p>
                    <b>{beneficiary?.beneficiary_name}</b>
                  </p>
                  <p>{beneficiary?.email}</p>
                  <p>
                    {`${beneficiary?.country_code}`} {beneficiary?.primary_contact}
                  </p>
                  {
                    !beneficiary?.invited ? (
                      <>
                        <button className="customer_portal_invite_button">
                          <span className="customer_portal_invite" onClick={() => {
                            let invite = beneficiary?.configurations?.portalAccess ? { reinvite: true } : { invite: true }
                            portalActivate({
                              data: {
                                beneficiary_id: beneficiary?.id,
                                portal_access: true,
                              },
                              params: invite
                            })
                          }}>{beneficiary?.allow_portal_reinvite ? "Re-invite to Portal" : "Invite to Portal"}</span>
                        </button>
                      </>
                    ) : (null)
                  }
                </div>
              </Col>
              {/* <Col span={12} className="d-flex align-items-center name-right">
                <Col span={10}>
                  <p>
                    <span>Outstanding Payables</span>
                  </p>
                  <p className="amt">Rs. 7,000.00</p>
                </Col>
                <Col span={10}>
                  <p>
                    <span>Unused Credits</span>
                  </p>
                  <p className="amt">Rs. 10,000.00</p>
                </Col>
              </Col> */}
            </Row>
          </div>
          <hr />
          <div className="m1 p1 other-info">
            <h4>{t('other_information')}</h4>
            <Row className="flex-wrap">
              <Col span={6}>
                <p>
                  <label>{t('name')}</label>
                </p>
                <p>{beneficiary?.beneficiary_name}</p>
              </Col>
              <Col span={6}>
                <p>
                  <label>{t('currency_code')}</label>
                </p>
                <p>{beneficiary?.configurations?.currency}</p>
              </Col>
              <Col span={6}>
                <p>
                  <label>{t('payment_term')}</label>
                </p>
                <p>{beneficiary?.configurations?.payment_terms}</p>
              </Col>
              <Col span={6}>
                <p>
                  <label>{t('gst_treatment')}</label>
                </p>
                <p>{beneficiary?.gst_treatment}</p>
              </Col>
              <Col span={6}>
                <p>
                  <label>{t('gstin')}</label>
                </p>
                <p>{beneficiary?.gstin}</p>
              </Col>
              <Col span={6}>
                <p>
                  <label>{t('source_of_supply')}</label>
                </p>
                <p>{beneficiary?.tax_settings?.place_of_supply}</p>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="m1 p1 other-info">
            <h4 className="mb1">{t('bank_Account_info')}</h4>
            <Row className="flex-wrap">
              {beneficiary?.bank_accounts?.map((ele, i) => (
                <Col span={8}>
                  {i === 0 ? <p>
                    <label>{t('primary')}</label>
                  </p> : <p>
                    <label>{t('others')}</label>
                  </p>}
                  <p>
                    {t('account_num')} - {ele.account_number} <br />
                    {t('ifsc_code')} - {ele.ifsc_code}
                    <br />
                    {t('bank_name')} - {ele.bank_name}
                  </p>
                </Col>
              ))}
            </Row>
          </div>
          <hr />
          <div className="m1 p1 other-info">
            <h4 className="mb1">{t('address')}</h4>
            <Row className="flex-wrap">
              <Col span={8}>
                <p>
                  <label>{t('billing_address')}</label>
                </p>
                {beneficiary?.billing_addresses?.map((ele) => (
                  <p className="mb1">
                    {ele.address}
                    <br />
                    {ele.city} - {ele.zipcode}
                    <br />
                    {ele.country}
                    <br />
                    Phone: {ele.country_code} {ele.phone}
                  </p>
                ))}
              </Col>
              <Col span={8}>
                <p>
                  <label>{t('shipping_address')}</label>
                </p>
                {beneficiary?.shipping_addresses?.map((ele) => (
                  <p className="mb1">
                    {ele.address}
                    <br />
                    {ele.city} - {ele.zipcode}
                    <br />
                    {ele.country}
                    <br />
                    Phone: {ele.country_code} {ele.phone}
                  </p>
                ))}
              </Col>
            </Row>
          </div>
          <hr />
          <div className="m1 p1 other-info">
            <h4 className="mb1">{t('contact_persons')}</h4>
            <Row className="flex-wrap">
              {beneficiary?.contact_persons?.map((ele) => (
                <Col span={8}>
                  <div className="contact">
                    <img src={user_icon} alt="" className="user-img" />
                    <div>
                      <p>
                        {ele.first_name} {ele.last_name}
                      </p>
                      <p className="dim">
                        <img src={email} width={16} /> {ele.email}
                      </p>
                      <div className="flex-between gap2">
                        {/* <p className="dim">
                          <img src={tel} width={14} /> {ele.country_code}{" "}
                          {ele.contact_number}
                        </p> */}
                        {ele.contact_number && <p className="dim">
                          <img src={phone} width={10} /> {ele.country_code}{" "}
                          {ele.contact_number}
                        </p>}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetails;
