import { ActionTypesFactory } from "../../utils";

export const Vendors = ActionTypesFactory("Vendors", "Vendors");
export const VendorsByName = ActionTypesFactory(
  "VendorsByName",
  "VendorsByName"
);
export const VendorsById = ActionTypesFactory("VendorsById", "VendorsById");
export const PlaceOfSupplies = ActionTypesFactory(
  "PlaceOfSupplies",
  "PlaceOfSupplies"
);
export const VendorTDS = ActionTypesFactory("VendorTDS", "VendorTDS");
export const PaymentTerms = ActionTypesFactory("PaymentTerms", "PaymentTerms");
export const SaveBeneficiary = ActionTypesFactory(
  "SaveBeneficiary",
  "SaveBeneficiary"
);
export const UpdateBeneficiary = ActionTypesFactory(
  "UpdateBeneficiary",
  "UpdateBeneficiary"
);
export const SavePlaceSupplies = ActionTypesFactory(
  "SavePlaceSupplies",
  "SavePlaceSupplies"
);
export const GetReceipts = ActionTypesFactory("Receipts", "GetReceipts");
export const PaymentMade = ActionTypesFactory("PaymentMade", "PaymentMade");
export const GetItems = ActionTypesFactory("Invoice", "GetItems");
export const GetTaxItems = ActionTypesFactory("Invoice", "GetTaxItems");
export const ReceiptsGet = ActionTypesFactory("ReceiptsGet", "ReceiptsGet");
export const ReceiptsBYID = ActionTypesFactory("ReceiptsBYID", "ReceiptsBYID");
export const ReceiptsCreate = ActionTypesFactory(
  "ReceiptsCreate",
  "ReceiptsCreate"
);
export const ReceiptsSubmit = ActionTypesFactory(
  "ReceiptsSubmit",
  "ReceiptsSubmit"
);

export const vendors = (payload) => {
  return {
    type: Vendors.REQUEST,
    payload,
  };
};

export const get_items = (payload) => {
  return {
    type: GetItems.REQUEST,
    payload,
  };
};

export const get_tax_items = (payload) => {
  return {
    type: GetTaxItems.REQUEST,
    payload,
  };
};

export const get_receipts = (payload) => {
  return {
    type: GetReceipts.REQUEST,
    payload,
  };
};

export const placeOfSupplies = (payload) => {
  return {
    type: PlaceOfSupplies.REQUEST,
    payload,
  };
};

export const vendorsByName = (payload) => {
  return {
    type: VendorsByName.REQUEST,
    payload,
  };
};

export const vendorsById = (payload) => {
  return {
    type: VendorsById.REQUEST,
    payload,
  };
};

export const vendorTDS = (payload) => {
  return {
    type: VendorTDS.REQUEST,
    payload,
  };
};

export const paymentTerms = (payload) => {
  return {
    type: PaymentTerms.REQUEST,
    payload,
  };
};

export const saveBeneficiary = (payload) => {
  return {
    type: SaveBeneficiary.REQUEST,
    payload,
  };
};

export const updateBeneficiary = (payload) => {
  return {
    type: UpdateBeneficiary.REQUEST,
    payload,
  };
};

export const savePlaceSupplies = (payload) => {
  return {
    type: SavePlaceSupplies.REQUEST,
    payload,
  };
};

export const paymentMade = (payload) => {
  return {
    type: PaymentMade.REQUEST,
    payload,
  };
};

// Receipts action start
export const receiptsGet = (payload) => {
  return {
    type: ReceiptsGet.REQUEST,
    payload,
  };
};
export const receiptsBYID = (payload) => {
  return {
    type: ReceiptsBYID.REQUEST,
    payload,
  };
};
export const receiptsCreate = (payload) => {
  return {
    type: ReceiptsCreate.REQUEST,
    payload,
  };
};
export const receiptsSubmit = (payload) => {
  return {
    type: ReceiptsSubmit.REQUEST,
    payload,
  };
};
