import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormInput, FormSelect } from '../inputs';
import { countries } from "../Settings/Organisation/country";
import { useTranslation } from "react-i18next";


const ShippingAddress = ({ control, errors, register, setValue, shipping_address }) => {
  const pinRef = useRef()
  const { t } = useTranslation();
  const { fields } = useFieldArray({
    control,
    name: 'shipping_address',
  });

  const handleSearchCountry = (value, country, index) => {
    if (pinRef.current) {
      clearTimeout(pinRef.current)
    }
    pinRef.current = setTimeout(() => {
      axios.get("https://api.worldpostallocations.com/pincode?postalcode=" + value + "&countrycode=" + countries?.find(ele => ele.label === country).code + "").then(
        response => {
          setValue(`shipping_address.${index}.state`, response?.data?.result?.[0]?.state)
        })
    }, 700)

  }
  return (
    <>
      {fields.map((personData, index) => (
        <div key={`shipping-${index}`}>
          <div className={index >= 1 ? `pp-form-item` : null}>
            <FormSelect
              control={control}
              {...register(`shipping_address.${index}.country`)}
              label={t('country_region')}
              placeholder={t('select')}
              options={countries}
              errorMessage={errors.shipping_address?.[index]?.['country']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.zipcode`, {
                onChange: (e) => {
                  handleSearchCountry(e.target.value, shipping_address[index].country, index)
                }
              })}
              label="Zip"
              errorMessage={errors.shipping_address?.[index]?.['zipcode']?.['message']}
            />
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.address`)}
              label={t('address')}
              placeholder={t('street')}
              errorMessage={errors.shipping_address?.[index]?.['address']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.city`)}
              label={t('city')}
              errorMessage={errors.shipping_address?.[index]?.['city']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.state`)}
              label={t('state')}
              errorMessage={errors.shipping_address?.[index]?.['state']?.['message']}
            />
          </div>


          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.phone`)}
              label={t('phone')}
              errorMessage={errors.shipping_address?.[index]?.['phone']?.['message']}
            />
          </div>

          {/* <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.fax`)}
              label="Fax"
              errorMessage={errors.shipping_address?.[index]?.['fax']?.['message']}
            />
          </div> */}
        </div>
      ))}
    </>
  );
};

export default ShippingAddress;
