import { Button, Col, message, Modal, Row, Space } from "antd";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { get_currencies } from "../../../actions/masterdata/masterdata";
import { get_receipts, vendorsByName } from "../../../actions/purchases";
import { FormInput, FormSelect } from "../../inputs";
import DateInput from "../../inputs/DateInput";
import usePayAxios from "../../../config/useAxios";
import { apis } from "../../../config/APIs";
import { invoices } from "../../../actions/customers/customers";
import { fetchAllBillingDetails } from "../../../actions/bills";
import PaymentModeFields from "../../Payments/PaymentModeFields";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CreatePayment = ({
  isVisible,
  onClose,
  onSuccess,
  isSales,
  dataBeneficiary,
}) => {
  const apiCalles = isSales ? apis.CreateInvoiceReceipt : apis.CreatePayment;
  const { onCall } = usePayAxios({ api: apiCalles, method: "post" });
  const dispatch = useDispatch();
  const [vendorsOptions, setVendorsOptions] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState([]);
  const [listVendors, setListVendors] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [isBillAdd, setIsBillAdd] = useState(false);
  const {t} = useTranslation();
  const billsResponse = useSelector(
    (state) => state.bills.fetchAllBillingDetails || {}
  );
  useEffect(() => {
    if (isVisible) {
      if (isSales) {
        dispatch(vendorsByName({ type: "customer", status: "active" }));
      }
      else {
        dispatch(vendorsByName({ type: "vendor", status: "active" }));
        dispatch(fetchAllBillingDetails({
          view: "admin",
          filters: { invoice_status: "approved" },
          isFilter: true,
          module_type: "BILL"
        }));
      }

      dispatch(get_currencies());
    }
  }, [isVisible]);

  // const customers = useSelector((state) => Array.isArray(state.customers?.all?.data?.data) ? state.customers?.all?.data?.data?.map(ele => ({ ...ele, label: ele.beneficiary_name, value: ele.id })) : []);
  const vendorsByNameData = useSelector(
    (state) => state.purchases.vendorsByName || {}
  );
  const InvoiceNumberData = useSelector(
    (state) => state?.customers?.invoices || {}
  );

  useEffect(() => {
    if (vendorsByNameData.success && !vendorsByNameData.data?.error) {
      setListVendors(vendorsByNameData.data.data);
      setVendorsOptions(
        vendorsByNameData.data?.data?.map((item) => ({
          value: item.id,
          label: item.beneficiary_name,
        }))
      );
    }
  }, [vendorsByNameData]);

  useEffect(() => {
    if (InvoiceNumberData?.success && !InvoiceNumberData.data?.error && isSales) {
      setInvoiceNumber(
        InvoiceNumberData?.data?.data?.map((item) => ({
          value: item.id,
          label: item.invoice_no,
        }))
      );
    }
  }, [InvoiceNumberData]);
  useEffect(() => {
    if (billsResponse?.success && !billsResponse.data?.error && !isSales) {
      setInvoiceNumber(
        billsResponse?.data?.data?.map((item) => ({
          value: item.id,
          label: item.billing_no,
        }))
      );
    }
  }, [billsResponse]);

  const currencies = useSelector((state) =>
    state?.currencies?.data?.response?.base_currencies?.length
      ? state?.currencies?.data?.response?.base_currencies?.map((ele) => ({
        label: ele.iso_code,
        value: ele.iso_code,
      }))
      : []
  );

  const onVendorSearch = (e) => {
    if (e && e.trim() !== "") {
      dispatch(vendorsByName({ beneficiary_name: e.trim(), type: isSales ? "customer" : "vendor", status: 'active' }));
    }
  };
  const onChangeNum = (e) => {
    setValue(isSales ? "ref_invoice_number" : "ref_billing_number", e)
  }
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      invoice_id: "",
      amount_paid: "",
      currency: "",
      payment_date: moment(),
      payment_mode: "",
      paid_through: "-",
      reference_number: "",
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload = {
      ...data,
      amount_paid: parseFloat(data.amount_paid),
    };
    if (isSales) {
      payload.invoice_id = data.invoice_id
    } else {
      payload.billing_id = data.billing_id
    }
    if (isBillAdd && isSales) {
      payload.ref_invoice_number = data.ref_invoice_number
    }
    if (isBillAdd && !isSales) {
      payload.ref_billing_number = data.ref_billing_number
    }
    onCall({ data: payload }).then(() => {
      reset();
      onClose();
      onSuccess && onSuccess();
      if (isSales)
        dispatch(get_receipts({ isPaymentsReceived: true }))
      isSales
        ? message.success(
          <span className="messageText">{"Record created"}</span>
        )
        : message.success(
          <span className="messageText">{"Bills created"}</span>
        );
    });
  };
  const inv_ref = watch("ref_invoice_number")
  const bill_ref = watch("ref_billing_number")
  const beneficiary_id = watch("beneficiary_id")

  useEffect(() => {
    if (beneficiary_id) {
      dispatch(
        invoices({
          view: "admin",
          filters: { invoice_status: ["approved"], payment_status:["payment_pending","partially_paid"], beneficiary_id: beneficiary_id },
          isFilter: true,
          type: "INVOICE"
        })
      );
      setValue("invoice_id", "");
      setValue("currency", "");
      setValue("amount_paid", "");
    }
  }, [beneficiary_id])
  useEffect(() => {
    if (dataBeneficiary && dataBeneficiary?.beneficiary_id) {
      setIsDisable(true);

      dispatch(
        invoices({
          view: "admin",
          filters: { invoice_status: ["approved"], payment_status:["payment_pending","partially_paid"], beneficiary_id: dataBeneficiary.beneficiary_id },
          isFilter: true,
          type: "INVOICE"
        })
      );
      setValue("beneficiary_id", dataBeneficiary.beneficiary_id);
      setValue("invoice_id", dataBeneficiary.id);
      setValue("currency", dataBeneficiary.currency);
      setValue("amount_paid", dataBeneficiary.due_balance_amount);
    }
  }, [dataBeneficiary]);

  const onCloseDrawer = useCallback(() => {
    reset?.();
    onClose?.();
  }, [reset, onClose]);

  const payment_mode = [
    {
      label: "Cheque",
      value: "Cheque",
    },
    {
      label: "DD",
      value: "DD",
    },
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "UPI",
      value: "UPI",
    },
    {
      label: "Card",
      value: "Card",
    },
    {
      label: "Wallet",
      value: "Wallet",
    },
  ];
  const paymentMode = watch("payment_mode")
  const amount_paid = watch("amount_paid")

  const payment_through = [
    {
      label: "Credit Card",
      value: "Credit Card",
    },
    {
      label: "Petty Cash",
      value: "Petty Cash",
    },
    {
      label: "Coupons",
      value: "Coupons",
    },
  ];

  useEffect(() => {
    if (isBillAdd && !bill_ref) {
      setIsBillAdd(false)
    }
  }, [bill_ref])

  useEffect(() => {
    if (isBillAdd && !inv_ref) {
      setIsBillAdd(false)
    }
  }, [inv_ref])

  return (
    <>
      <Modal
        onCancel={onClose}
        className="right-alinged-modal add-modal"
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">
                {isSales ? t('create_payment') : t('payment_create')}
              </span>
            </div>
          </div>
        }
        visible={isVisible}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pp-form-item">
              {isSales ? (
                <FormSelect
                  inline
                  control={control}
                  {...register("beneficiary_id")}
                  errors={errors}
                  label={t('cust')}
                  placeholder={t('enter_customer_name')}
                  onSearch={onVendorSearch}
                  options={vendorsOptions}
                  disabled={isDisable}
                  hideCreateOption
                // onAddItem={handlerNewPayTerms}
                />
              ) : (
                <FormSelect
                  inline
                  control={control}
                  {...register("beneficiary_id")}
                  errors={errors}
                  label={t('vendor')}
                  placeholder={t('enter_vendor_name')}
                  onSearch={onVendorSearch}
                  options={vendorsOptions}
                  hideCreateOption
                // onAddItem={handlerNewPayTerms}
                />
              )}
            </div>
            <div className="pp-form-item">
              {isSales ? (
                isBillAdd ? <FormInput
                  control={control}
                  {...register("ref_invoice_number", {
                    required: "Please enter Invoice Number"
                  })}
                  errors={errors}
                  label={t('number_invoice')}
                  placeholder={t('enter_invoice_no')}

                /> :
                  <FormSelect
                    inline
                    control={control}
                    {...register("invoice_id", {
                      onChange: (e) => {
                        console.log(e.target.value);
                        let inv = InvoiceNumberData?.data?.data?.find(ele => ele.id === e.target.value)
                        if (inv?.due_balance_amount) {
                          setValue("amount_paid", inv?.due_balance_amount + "")
                        }
                      }
                    })}
                    errors={errors}
                    label={t('number_invoice')}
                    placeholder={t('enter_invoice_no')}
                    onSearch={onChangeNum}
                    options={invoiceNumber}
                    disabled={isDisable}
                    onAddItem={() => {
                      setIsBillAdd(true)
                    }}
                  // onAddItem={handlerNewPayTerms}
                  />
              ) : (
                isBillAdd ? <FormInput
                  control={control}
                  {...register("ref_billing_number", {
                    required: "Please enter Bill Number"
                  })}
                  errors={errors}
                  label={t('no_bill')}
                  placeholder="Enter Bill Number"

                /> :
                  <FormSelect
                    inline
                    control={control}
                    {...register("billing_id")}
                    errors={errors}
                    label={t('no_bill')}
                    placeholder="Enter Bill Number"
                    onSearch={onChangeNum}
                    options={invoiceNumber}
                    onAddItem={() => {
                      setIsBillAdd(true)
                    }}
                  />
              )}
            </div>
            <div className="pp-form-item">

              <FormInput
                control={control}
                {...register("amount_paid", {
                  required: t('please_enter_amount')
                })}
                number={true}
                amount={true}
                errors={errors}
                label={t('Amount')}
                placeholder={t('enter_amount')}

              />
            </div>
            <div className="pp-form-item">
              <DateInput
                control={control}
                {...register("payment_date", { required: true })}
                errors={errors}
                required
                label={t('payment_date')}
              />
            </div>
            <div className="pp-form-item">
              <FormSelect
                inline
                control={control}
                {...register("payment_mode")}
                errors={errors}
                label={t('payment_mode')}
                placeholder={t('cash')}
                options={payment_mode}
                hideCreateOption
              // onAddItem={handlerNewPayTerms}
              />
            </div>
            <PaymentModeFields
              payment_mode={paymentMode}
              control={control}
              errors={errors}
              register={register}
              amount={amount_paid}
              setValue={setValue}
            />
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("reference_number")}
                errors={errors}
                label={isSales ? t('order_sale') : t('order_purchase')}
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("notes")}
                errors={errors}
                label={t('notes')}
              />
            </div>
            {/* <div style={{ marginTop: "40px" }}>
              <UploadField
                title="Attach File(s) to Payments"
                desc="(Material/Delivery Receipt/Purchase Order)"
                subDescripcion={"You can upload a maximum of 5 files, 5MB each"}
                acceptType={".jpg,.pdf,.png"}
                onSelected={(fileInfo) => {
                  let listAux = [...listFiles];
                  listAux.push(fileInfo);
                  setListFiles(listAux);
                }}
              />

              <Space
                direction="vertical"
                size={"middle"}
                style={{ marginTop: "10px", width: "100%" }}
              >
                {listFiles.map((fileInfo, index) => (
                  <div className="file-container">
                    <span className="file-desc">{fileInfo.name}</span>

                    <button
                      className="file-delete-btn"
                      type="button"
                      onClick={() => {
                        let listAux = [...listFiles];
                        listAux.splice(index, 1);
                        setListFiles(listAux);
                      }}
                    >
                      <DeleteOutlined />
                    </button>
                  </div>
                ))}
              </Space>
            </div> */}
            <Space
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "6rem 47px 6rem 0px",
                justifyContent: "end",
              }}
            >
              <Button
                className="pp-secondary-button"
                style={{ marginLeft: "2rem" }}
                onClick={onCloseDrawer}
              >
                <span>{t('cancel')}</span>
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                className="pp-main-button"
              >
                <span>{t('save')}</span>
              </Button>

              {/* <Button
                type="primary"
                htmlType="submit"
                className="pp-main-button"
              >
                <span>Save and Submit</span>
              </Button> */}
            </Space>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default CreatePayment;
