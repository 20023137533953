import {
  CloudUploadOutlined,
  MoreOutlined,
  PlusCircleFilled
} from "@ant-design/icons";
import { Badge, Button, Col, Dropdown, Menu, Row, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { vendors } from "../../actions/purchases";
import TableComponent from "../Common/TableComp/TableComp";
import TableHeader from "../Sales/TableHeader";
import AddVendor from "./AddVendor";
import { useTranslation } from "react-i18next";
import { convertString } from "../../utils";


const Vendors = ({ hasWriteAccess }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [dataBeneficiary, setDataBeneficiary] = useState(null);
  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(1);
  const totalRecords = useSelector((state) => state.purchases.vendors?.data?.total_count || {})

  const sortOptions = [
    {
      label: t('vendor_name'),
      filter: "beneficiary_name",
    },
    {
      label: t('company_name'),
      filter: "company_name",
    },
    {
      label: t('created_at'),
      filter: "created_at"
    },
    // {
    //   label: "Last Modified Date",
    //   filter: "updated_at"
    // },
  ];
  const options = [
    {
      label: (
        <div className="table-menu-item">
          <span className="menu-label">{t('add_new_vendor')}</span>
        </div>
      ),
      key: "1",
      icon: (
        <div>
          <PlusCircleFilled className="pp-icon-18" />
        </div>
      ),
      onAction: () => setOpenAddNew(true),
    },
    {
      label: (
        <div className='table-menu-item'>
          <span
            className='menu-label'
          >
            Import Vendors
          </span>
        </div>
      ),
      key: '2',
      icon: (
        <div>
          <CloudUploadOutlined className="pp-icon-18" />
        </div>
      ),
      onAction: () => {
        if(hasWriteAccess){
          history.push("/vendor/upload/section")
        }
      },
    },
    // {
    //   label: (
    //     <div className="table-menu-item">
    //       <span className="menu-label">Upload Vendors</span>
    //     </div>
    //   ),
    //   key: "2",
    //   icon: (
    //     <div>
    //       <CloudUploadOutlined className="pp-icon-18" />
    //     </div>
    //   ),
    // },
  ];

  const vendorsResponse = useSelector((state) => state.purchases.vendors || {});
  useEffect(() => {
    if (vendorsResponse?.success && !vendorsResponse?.data?.error) {
      setData(vendorsResponse.data.data);
    }
  }, [vendorsResponse]);

  useEffect(() => {
    dispatch(vendors({ type: "vendor" }));
    dispatch(
      headerTitles({
        title: 'vendors',
        description: 'add_vendor',
      })
    );
  }, []);

  const handleSelectRow = (event, record) => {
    history.push(location.pathname + "/" + record.id);
  };

  const handleSearch = (searchKey) => {
    dispatch(
      vendors({
        search_key: searchKey,
        type: "vendor",
      })
    );
  };

  const menu = (
    <Menu onClick={(e) => { }}>

      {options.map((ele) => (
        <Menu.Item
          key={ele.key}
          icon={ele.icon}
          onClick={ele.onAction}
        >
          {ele.label}
        </Menu.Item>
      ))}
    </Menu>
  );


  const columns = [
    {
      title: t('vendor_name'),
      dataIndex: "beneficiary_name",
      key: "beneficiary_name",
      render: (text, record) => (
        <>
          {record?.integration_type &&
            <div className="base_badge">
              <Badge.Ribbon text={convertString(record?.integration_type)} placement={"start"} color="#3f81f4"/>
            </div>
          }
          {text}
        </>
      ),
    },
    {
      title: t('company_name'),
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: t('email'),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t('phone'),
      dataIndex: "primary_contact",
      key: "primary_contact",
    },
    {
      title: t('gst_treatment'),
      dataIndex: "gst_treatment",
      key: "gst_treatment",
    },
    {
      title: t('created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space
          size="middle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setOpenAddNew(true);
                    setDataBeneficiary(record);

                  }}
                >
                  {t('edit')}
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              shape="default"
              icon={<MoreOutlined />}
              style={{ border: "none", color: "#5B87F9" }}
            />
          </Dropdown>
        </Space>
      ),
    },
  ];
  const handleFilter = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }

    if (value) {
      if (value === "all") {
        dispatch(vendors({ type: "vendor" }));
        setFilters({});
      } else {
        dispatch(
          vendors({
            ...filters,
            filters: {
              // beneficiary_name: [],
              beneficiary_status: value,
            },
            type: "vendor",
            isFilter: true,
          })
        );
        setFilters({
          ...filters,
          filters: {
            // beneficiary_name: [],
            beneficiary_status: value,
          },
        });
      }
    }
    setPage(1);
  };

  const handleSort = (value) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }

    if (value) {
      dispatch(
        vendors({
          ...filters,
          filters: {},
          sort: {
            sort_key: value,
            sort_order: "1",
          },
          type: "vendor",
          isFilter: true,
        })
      );
      setFilters({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
      });
    }
  };

  const handleRefresh = () => {
    dispatch(vendors({ type: "vendor" }));
  }
  const filterOptions = [
    { label: t('all'), filter: "all" },
    { label: t('active'), filter: "active" },
    { label: t('in_active'), filter: "in-active" },
    // { label: "Duplicate", filter: "duplicate" },
  ];
  return (
    <>
      {openAddNew && <AddVendor
        dataBeneficiary={dataBeneficiary}
        open={openAddNew && hasWriteAccess}
        onClose={() => {
          setOpenAddNew(false);
          setDataBeneficiary(null);
        }}
        filters = {filters}
      />}
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              menu={menu}
              setOpenAddNew={setOpenAddNew}
              sortOptions={sortOptions}
              handleSort={handleSort}
              onSearch={handleSearch}
              filterMenu={filterOptions}
              handleFilter={handleFilter}
              handleRefresh={handleRefresh}
              hasWriteAccess={hasWriteAccess}
            />

            <div
              className={vendorsResponse?.loading ? "shimmer" : ""}
              style={{ flexGrow: 1, paddingTop: "22px" }}
            >
              <TableComponent
                columns={columns}
                hasCheckbox={false}
                loading={vendorsResponse?.loading}
                data={Array.isArray(data) ? data : []}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                rowKey="id"
                handleRowClick={handleSelectRow}
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(vendors({ ...filters, type: "vendor", page_number: pagee }));
                    setPage(pagee)
                    setFilters({page_number:pagee})
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Vendors;
