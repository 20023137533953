import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import TableComponent from "../Common/TableComp/TableComp";
import TableHeader from "../Sales/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { customer_my_orders } from "../../actions/orders/orders";
import BaseCurrency from "../Common/BaseCurrency";
import { amountFormat, commonHandleSearch } from "../../utils";
import { headerTitles } from "../../actions/masterdata/masterdata";

const OrdersPage = ({ fromOrder }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation()
  const [page, setPage] = React.useState(1);
  const [filters, setFilters] = React.useState({});
  const orderDetails = useSelector(
    (state) => state?.orders?.my_orders?.data?.response?.orders
  );
  const orderDetailsTotal = useSelector(
    (state) => state?.orders?.my_orders?.data
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(customer_my_orders({
      fromOrder
    }));
    // dispatch(customer_my_orders({
    //   fromOrder, company_id: localStorage?.getItem("company_id")
    // }));
    dispatch(
      headerTitles({
        title: "Orders",
        description: "Check the orders.",
      })
    );
  }, []);

  const sortOptions = [
    {
      label: t("Total Amount"),
      filter: "total_amount",
    },
    {
      label: t("quantity"),
      filter: "total_quantity",
    },
    {
      label: t("date"),
      filter: "created_at",
    },
  ];

  const filterOptions = [
    { label: t("pending_approval"), filter: "pending_approval" },
    { label: t("approved"), filter: "approved" },
    { label: t("rejected"), filter: "rejected" },
    { label: t("Cancelled"), filter: "cancelled" },
  ];

  const columns = [
    {
      title: t("Order#"),
      dataIndex: "order_number",
      key: "order_number",
    },
    localStorage.getItem("isCustomer") === "true"
      ? {}
      : {
        title: t("Ordered By"),
        dataIndex: "customer_details",
        key: "customer_details",
        render: (text) => text.beneficiary_name,
      },
    localStorage.getItem("isCustomer") === "true"
      ? {
        title: t("Company Name"),
        dataIndex: "company_name",
        key: "company_name",
        // render: (text) => (<div>{text?.company_name}</div>),
      }
      : {},
    {
      title: t("Data And Time"),
      dataIndex: "created_at",
      key: "created_at",
      // render: (text) => moment(text).format("DD MMM YYYY HH:mm:ss"),
    },
    // {
    // 	title: t('SO Number'),
    // 	dataIndex: 'so_number',
    // 	key: 'so_number'
    // },
    // {
    // 	title: t('PO Number'),
    // 	dataIndex: 'po_number',
    // 	key: 'po_number'
    // },
    {
      title: t("Quantity Ordered"),
      dataIndex: "total_quantity",
      key: "total_quantity",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <div className={`status status_${text}`}>{text.replace(/_/g, " ")}</div>
      ),
    },
    {
      title: t("Total Amount"),
      dataIndex: "total_price",
      render: (total_price, record) => {
        return (
          <>
            <BaseCurrency symbol_code={record?.currency_info?.iso_code} /> {amountFormat(total_price)}
          </>
        );
      },
    },
  ];
  const handleSearch = (searchKey) => {
    dispatch(
      customer_my_orders({
        ...filters,
        search: searchKey,
        fromOrder
      })
    );
    setPage(1)
    setFilters({
      ...filters,
      search: searchKey
    })
  };
  // const handleSearch = (searchKey) => {
  //   commonHandleSearch(dispatch, setPage, setFilters, filters, searchKey, customer_my_orders, { fromOrder, company_id: localStorage?.getItem("company_id") }); // pass the relevant API endpoint
  // };

  const handleFilter = (value) => {
    dispatch(customer_my_orders({
      ...filters,
      status: value,
      fromOrder,
    }))
    setFilters({
      ...filters,
      status: value,
    });
    setPage(1);
  };

  const handleSort = (value) => {
    dispatch(
      customer_my_orders({
        ...filters,
        sort_key: value,
        sort_order: "1",
        fromOrder,
      })
    );

    setFilters({
      ...filters,
      sort_key: value,
      sort_order: "1",
    });
    setPage(1);
  };
  return (
    <>
      <div className="flex-grow">
        <TableHeader
          //   noDD={false}
          noAddNewBtn={true}
          handleFilter={handleFilter}
          handleSort={handleSort}
          onSearch={handleSearch}
          sortOptions={
            localStorage.getItem("isCustomer") !== "true"
              ? [
                ...sortOptions,
                {
                  label: t("Ordered By"),
                  filter: "ordered_by",
                },
              ]
              : sortOptions
          }
          filterMenu={filterOptions}
        />
        <div
          //   className={invLoading ? "shimmer" : ""}
          style={{ paddingTop: "22px" }}
        >
          <TableComponent
            columns={columns}
            data={Array.isArray(orderDetails) ? orderDetails : []}
            hasCheckbox={false}
            handleRowClick={(e, record) => {
              if (fromOrder) {
                history.push(`/order-detail/${record.id}`, { ...record, from: location.pathname });
              }
              else {
                history.push(`/sales/orderspage/${record.id}`, { ...record, from: location.pathname });
              }
            }}
            pagination={{
              total: orderDetailsTotal?.total_records || 0,
              current: page,
              onChange: (pagee) => {
                dispatch(customer_my_orders({ fromOrder, page: pagee }));
                setPage(pagee);
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OrdersPage;
