import React from 'react'
import Purpleinv from '../../PDFTemplates/Purpleinv';
import InvoicePDF from '../../PDFTemplates/InvoicePDF';
import CreditPdf from '../../PDFTemplates/CreditPdf';
import InvPDF from "../../PDFTemplates/invPDF";
import InvPDF2 from "../../PDFTemplates/invPDF2";
import { amountFormat } from '../../../utils';
import { getSymbol } from '../../../config/helper';


const getInvoiceData = (type, invoiceInfo) => {
    switch (true) {

        case (type === 'INVOICE' || type === 'INVOICE_RECEIPT'):
            return {
                CreditNo: invoiceInfo?.invoice_no,
                balanceAmount: invoiceInfo?.due_balance_amount,
                creditNoteDate: invoiceInfo?.date,
                invoiceNo: invoiceInfo?.invoice_no,
                invoiceDate: invoiceInfo?.invoice_date,
                SubTotal: invoiceInfo?.sub_total_amount,
                total_tax: invoiceInfo?.total_tax_amount,
                total_amount: invoiceInfo?.total_amount,
                totalDiscount: amountFormat(invoiceInfo?.total_discount, invoiceInfo?.currency_info?.decimal_scale),
                totalTax: amountFormat(invoiceInfo?.total_tax_amount, invoiceInfo?.currency_info?.decimal_scale),
                totalAmount: amountFormat(invoiceInfo?.total_amount, invoiceInfo?.currency_info?.decimal_scale),
                taxRates: getTaxRates(invoiceInfo?.invoice_items),

                lineItems: invoiceInfo?.invoice_items,
                tdsAmount: invoiceInfo?.tds_amount,

                title: 'INVOICE',
                noteTitle: "Invoice#",
                balanceTitle: 'Balance Due',
                numTitle: 'Invoice No',
                dateTitle: "Invoice Date",
                gst: invoiceInfo?.company?.gst_no,
                address: invoiceInfo?.company?.address,
                terms: invoiceInfo?.invoice_payment_terms?.term_name,
                poDue: invoiceInfo?.po_due_date,
                companyName: invoiceInfo?.company?.name




            }

        case (type === 'BILL' || type === 'BILL_RECEIPT'):
            return {
                CreditNo: invoiceInfo?.billing_no,
                balanceAmount: invoiceInfo?.due_balance_amount,
                creditNoteDate: invoiceInfo?.date,
                invoiceNo: invoiceInfo?.billing_no,
                invoiceDate: invoiceInfo?.billing_date,
                SubTotal: invoiceInfo?.sub_total_amount,
                totalDiscount: amountFormat(invoiceInfo?.total_discount, invoiceInfo?.currency_info?.decimal_scale),
                totalTax: amountFormat(invoiceInfo?.total_tax_amount, invoiceInfo?.currency_info?.decimal_scale),
                totalAmount: amountFormat(invoiceInfo?.total_amount, invoiceInfo?.currency_info?.decimal_scale),
                totAmt: invoiceInfo?.total_amount,
                lineItems: invoiceInfo?.billing_items,
                tdsAmount: invoiceInfo?.tds_amount,
                total_tax: invoiceInfo?.total_tax_amount,
                total_amount: invoiceInfo?.total_amount,

                taxRates: getTaxRates(invoiceInfo?.billing_items),
                title: 'BILL',
                noteTitle: "Bill#",
                balanceTitle: 'Balance Due',
                numTitle: 'Bill No',
                dateTitle: "Bill Date",
                gst: invoiceInfo?.company?.gst_no,
                address: invoiceInfo?.company?.address,
                terms: invoiceInfo?.vendor_payment_terms?.term_name,
                poDue: invoiceInfo?.due_date,
                companyName: invoiceInfo?.company?.name,
                amountPaid: invoiceInfo?.amount_paid




            };

        default:
            return {
                CreditNo: invoiceInfo?.credit_debit_note_number,
                balanceAmount: invoiceInfo?.balance_amount,
                creditNoteDate: invoiceInfo?.date,
                invoiceNo: invoiceInfo?.invoice_ref_number,
                invoiceDate: invoiceInfo?.date,
                SubTotal: invoiceInfo?.sub_total_amount,
                totalDiscount: invoiceInfo?.total_discount,
                totalTax: invoiceInfo?.total_tax_amount,
                totalAmount: amountFormat(invoiceInfo?.total_amount, invoiceInfo?.currency_info?.decimal_scale),
                lineItems: invoiceInfo?.line_items,
                taxRates: getTaxRates(invoiceInfo?.line_items),
                total_tax: invoiceInfo?.total_tax_amount,
                total_amount: invoiceInfo?.total_amount,

                title: 'Credit Note',
                noteTitle: "Credit Note#",
                balanceTitle: 'Balance',
                numTitle: 'Invoice No',
                dateTitle: 'Invoice Date',
                gst: invoiceInfo?.company?.gst_no,
                address: invoiceInfo?.company?.address,
                terms: invoiceInfo?.invoice_payment_terms?.term_name,
                poDue: invoiceInfo?.po_due_date,
                companyName: invoiceInfo?.company?.name


            }
    }
};

function calculatePercentage(total, taxPrice) {
    let p = taxPrice / total;
    return p * 100;
}


function getTaxRates(items) {
    const taxRates = {}
    if (!items?.length) {
        items = []
    }

    for (let i = 0; i < items?.length; i++) {
        let per = calculatePercentage(parseFloat(items[i].total_amount), parseFloat(items[i].tax_amount))
        if (per > 0)
            if (taxRates[per]) {
                taxRates[per] = amountFormat(taxRates[per] + parseFloat(items[i].tax_amount))

            } else {
                taxRates[per] = amountFormat(parseFloat(items[i].tax_amount))
            }
    }

    return taxRates;
}

const PaymentTemplates = ({ template, invoiceInfo, type, company }) => {

    // Then use the function in your component:
    const invoiceData = getInvoiceData(type, invoiceInfo, company);
    const subTotalAmount = amountFormat(parseFloat(invoiceData?.total_amount) - parseFloat(invoiceData?.total_tax), invoiceInfo?.currency_info?.decimal_scale);
    const TDSAmt = amountFormat(parseFloat(invoiceData?.tdsAmount), invoiceInfo?.currency_info?.decimal_scale);
    const subTds = Number(parseFloat(invoiceData?.total_amount) - parseFloat(invoiceData?.total_tax)) + Number(invoiceData?.tdsAmount);
    const shippingAddressCity = invoiceInfo?.beneficiary_shipping_addresses?.[0]?.city;
    const shippingAddressState = invoiceInfo?.beneficiary_shipping_addresses?.[0]?.state;
    const shippingAddressZip = invoiceInfo?.beneficiary_shipping_addresses?.[0]?.zipcode;
    const shippingAddressCountry = invoiceInfo?.beneficiary_shipping_addresses?.[0]?.country;
    const shippingAddressPhone = invoiceInfo?.beneficiary_shipping_addresses?.[0]?.phone;
    const shipAddress = invoiceInfo?.beneficiary_shipping_addresses?.[0]?.address;
    const companyAddr = company?.address;
    const companyState = company?.state;
    const companyCity = company?.city;
    const companyCountry = company?.country;
    const companyPin = company?.pincode;
    const orgName = company?.name;

    const billingAddressCity = invoiceInfo?.beneficiary_billing_addresses?.[0]?.city;
    const billingAddressState = invoiceInfo?.beneficiary_billing_addresses?.[0]?.state;
    const billingAddresszip = invoiceInfo?.beneficiary_billing_addresses?.[0]?.zipcode;
    const billingCountry = invoiceInfo?.beneficiary_billing_addresses?.[0]?.country;
    const billPhone = invoiceInfo?.beneficiary_billing_addresses?.[0]?.phone;
    const billAddress = invoiceInfo?.beneficiary_billing_addresses?.[0]?.address;
    const benficiaryGST = invoiceInfo?.beneficiaries?.gstin
    const bankName = invoiceInfo?.beneficiary_bank_accounts?.[0]?.bank_name;
    const ifsc = invoiceInfo?.beneficiary_bank_accounts?.[0]?.ifsc_code;
    const branch = invoiceInfo?.beneficiary_bank_accounts?.[0]?.branch;
    const bankAccount = invoiceInfo?.beneficiary_bank_accounts?.[0]?.account_number;
    const benefiName = invoiceInfo?.beneficiaries?.beneficiary_name
    const benifiEmail = invoiceInfo?.beneficiaries?.email
    const benifiCompany = invoiceInfo?.beneficiaries?.company_name
    const benifiContact = invoiceInfo?.beneficiaries?.primary_contact
    return (
        <div>{(() => {
            switch (template) {
                case 'template_01':
                    return <CreditPdf
                        balanceAmount={invoiceData.balanceAmount}
                        currency={invoiceInfo?.currency_iso ? invoiceInfo?.currency_iso : getSymbol()}
                        creditNoteNo={invoiceData.CreditNo}
                        creditDate={invoiceData.creditNoteDate}
                        invoiceNo={invoiceData.invoiceNo}
                        invoiceDate={invoiceData.invoiceDate}
                        lineItems={invoiceData.lineItems}
                        taxRates={invoiceData.taxRates}
                        subTotal={invoiceInfo?.tds_amount ? subTds : amountFormat(subTotalAmount, invoiceInfo?.currency_info?.decimal_scale)}
                        TDSAmt={TDSAmt}
                        billingAddressCity={billingAddressCity}
                        billingAddressState={billingAddressState}
                        billingAddresszip={billingAddresszip}
                        billPhone={billPhone}
                        billAddress={billAddress}
                        billingCountry={billingCountry}
                        type={type}
                        taxAmount={invoiceData.totalTax}
                        totalAmount={invoiceData.totalAmount}
                        title={invoiceData.title}
                        noteTitle={invoiceData.noteTitle}
                        balanceTitle={invoiceData.balanceTitle}
                        numTitle={invoiceData.numTitle}
                        address={company?.address}
                        gst={company?.gst_no}
                        dateTitle={invoiceData.dateTitle}
                        discount={invoiceData.totalDiscount}
                        benficiaryGST={benficiaryGST}
                        benefiName={benefiName}
                        benefiEmail={benifiEmail}
                    />;
                case 'template_02':
                    return <InvPDF
                        invoiceNo={invoiceData.invoiceNo}
                        currency={invoiceInfo?.currency_iso ? invoiceInfo?.currency_iso : getSymbol()}
                        lineItems={invoiceData.lineItems}
                        subTotal={invoiceInfo?.tds_amount ? subTds : amountFormat(subTotalAmount, invoiceInfo?.currency_info?.decimal_scale)}
                        invoiceDate={invoiceData.invoiceDate}
                        creditDate={invoiceData?.creditNoteDate}
                        TDSAmt={TDSAmt}
                        type={type}
                        taxAmount={invoiceData.totalTax}
                        totalAmount={invoiceData.totalAmount}
                        benficiaryGST={benficiaryGST}
                        title={invoiceData.title}
                        billingAddressCity={billingAddressCity}
                        billingAddressState={billingAddressState}
                        billingAddresszip={billingAddresszip}
                        billPhone={billPhone}
                        billAddress={billAddress}
                        primaryEmail={company?.primary_email}
                        billingCountry={billingCountry}
                        noteTitle={invoiceData.noteTitle}
                        balanceTitle={invoiceData.balanceTitle}
                        numTitle={invoiceData.numTitle}
                        dateTitle={invoiceData.dateTitle}
                        accountName={company?.account_name}
                        accountNumber={bankAccount}
                        discount={invoiceData.totalDiscount}
                        companyName={company?.name}
                        address={company?.address}
                        bankName={bankName}
                        benefiName={benefiName}
                        benefiEmail={benifiEmail}
                        companyAddr={companyAddr}
                        companyCity={companyCity}
                        companyState={companyState}
                        companyCountry={companyCountry}
                        companyPin={companyPin}
                    />;
                case 'template_03':
                    return <InvPDF2
                        invoiceNo={invoiceData.invoiceNo}
                        currency={invoiceInfo?.currency_iso ? invoiceInfo?.currency_iso : getSymbol()}
                        lineItems={invoiceData.lineItems}
                        TDSAmt={TDSAmt}
                        type={type}
                        subTotal={invoiceInfo?.tds_amount ? subTds : amountFormat(subTotalAmount, invoiceInfo?.currency_info?.decimal_scale)}
                        invoiceDate={invoiceData.invoiceDate}
                        creditDate={invoiceData.creditNoteDate}
                        taxAmount={invoiceData.totalTax}
                        totalAmount={invoiceData.totalAmount}
                        due_balance={invoiceData.balanceAmount}
                        title={invoiceData.title}
                        noteTitle={invoiceData.noteTitle}
                        company
                        benefiName={benefiName}
                        benefiEmail={benifiEmail}
                        benficiaryGST={benficiaryGST}
                        shippingAddressCity={shippingAddressCity}
                        shippingAddressCountry={shippingAddressCountry}
                        shippingAddressPhone={shippingAddressPhone}
                        shippingAddressState={shippingAddressState}
                        shippingAddressZip={shippingAddressZip}
                        billingAddressCity={billingAddressCity}
                        billingAddressState={billingAddressState}
                        shipAddress={shipAddress}
                        billingAddresszip={billingAddresszip}
                        billPhone={billPhone}
                        billAddress={billAddress}
                        billingCountry={billingCountry}
                        balanceTitle={invoiceData.balanceTitle}
                        numTitle={invoiceData.numTitle}
                        dateTitle={invoiceData.dateTitle}
                        terms={invoiceData?.terms}
                        taxRates={invoiceData.taxRates}
                        poDue={invoiceData?.poDue}
                        companyName={invoiceData?.companyName}
                        address={invoiceData?.address}
                        orgName={orgName}
                        companyAddr={companyAddr}
                        discount={invoiceData?.totalDiscount}
                        companyCity={companyCity}
                        companyState={companyState}
                        companyCountry={companyCountry}
                        companyPin={companyPin}
                    />;
                case 'template_04':
                    return <InvoicePDF
                        invoiceNo={invoiceData.invoiceNo}
                        currency={invoiceInfo?.currency_iso ? invoiceInfo?.currency_iso : getSymbol()}
                        lineItems={invoiceData.lineItems}
                        subTotal={invoiceInfo?.tds_amount ? subTds : amountFormat(subTotalAmount, invoiceInfo?.currency_info?.decimal_scale)}
                        invoiceDate={invoiceData.invoiceDate}
                        TDSAmt={TDSAmt}
                        type={type}
                        creditDate={invoiceData.creditNoteDate}
                        taxAmount={invoiceData.totalTax}
                        totalAmount={invoiceData.totalAmount}
                        title={invoiceData.title}
                        billingAddressCity={billingAddressCity}
                        billingAddressState={billingAddressState}
                        billingAddresszip={billingAddresszip}
                        billPhone={billPhone}
                        billAddress={billAddress}
                        benefiName={benefiName}
                        benefiEmail={benifiEmail}
                        billingCountry={billingCountry}
                        noteTitle={invoiceData.noteTitle}
                        balanceTitle={invoiceData.balanceTitle}
                        numTitle={invoiceData.numTitle}
                        dateTitle={invoiceData.dateTitle}
                        logo={company?.logo}
                        benficiaryGST={benficiaryGST}
                        bankName={bankName}
                        bankBranch={branch}
                        ifsc={ifsc}
                        discount={invoiceData.totalDiscount}
                        bankAccount={bankAccount}
                    />;
                case 'template_05':
                    return <Purpleinv
                        invoiceNo={invoiceData.invoiceNo}
                        currency={invoiceInfo?.currency_iso ? invoiceInfo?.currency_iso : getSymbol()}
                        lineItems={invoiceData.lineItems}
                        invoiceDate={invoiceData.invoiceDate}
                        TDSAmt={TDSAmt}
                        type={type}
                        totalAmount={invoiceData.totalAmount}
                        subTotal={invoiceInfo?.tds_amount ? subTds : amountFormat(subTotalAmount, invoiceInfo?.currency_info?.decimal_scale)}
                        discount={invoiceData.totalDiscount}
                        taxAmount={invoiceData.totalTax}
                        bankName={bankName}
                        bankBranch={branch}
                        ifsc={ifsc}
                        bankAccount={bankAccount}
                        primaryEmail={company?.primary_email}
                        primaryContact={company?.primary_phone}
                        cinNo={benficiaryGST}
                        gstNo={company?.gst_no}
                        numTitle={invoiceData.numTitle}
                        dateTitle={invoiceData.dateTitle}
                        benifiContact={benifiContact}
                        benifiEmail={benifiEmail}
                        benefiCompany={benifiCompany}
                        billingAddressCity={billingAddressCity}
                        billingAddressState={billingAddressState}
                        billingAddresszip={billingAddresszip}
                        billPhone={billPhone}
                        billAddress={billAddress}
                        benefiName={benefiName}
                        benficiaryGST={benficiaryGST}
                        billingCountry={billingCountry}
                        companyName={company?.name}
                        logo={company?.logo}
                        address={invoiceData.address}
                        accountName={company?.account_name ?? bankName}
                        accountNumber={company?.account_number ?? bankAccount}
                        bankAccountBranch={company?.bank_branch ?? branch}
                        accountIFSC={company?.account_ifsc ?? ifsc}
                    />;
                default:
                    return <div>No template selected</div>;
            }
        })()}</div>
    )
}

export default PaymentTemplates