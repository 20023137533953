import { message, Typography } from "antd";
import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import LeftArrow from "../../assets/icons/arrow-left.svg";
import { apis } from "../../config/APIs";
import endpoint from '../../config/Axios';
import { RouteConstants } from '../../utils';
import { useTranslation } from "react-i18next";

const { Text, Title } = Typography;


message.config({
  maxCount: 3,
});

const ConfirmEmail = () => {
  const location = useLocation();
  const history = useHistory();
  const [emailSent, setEmailSent] = React.useState({});
  const { t } = useTranslation();
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("token"))
      endpoint.get(apis.confirm_email, {
        params: {
          token: params.get("token")
        }
      }).then((res) => {
        setEmailSent(res.data)
      }).catch((err) => {
        setEmailSent(err.response)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="reset-form">
      <div className="head-sec">
        <Title level={3} className="mt2">
          {t('confirm_email')}
        </Title>
        <Text style={{ color: "white" }}>{emailSent?.message}</Text>
      </div>
      <div className="mt2">
        <Link to={RouteConstants.ROOT}>
          <span className="text-dim cursor">
            <img src={LeftArrow} alt={"LeftArrow"} className="leftArrow mr1" />
            {t('back_to_login ')}
          </span>
        </Link>
      </div>
      <div className="mt4 flex-center">
        <span className="text-dim footer-note">
          {t('dont_have')} <b> {t('register')}</b>
        </span>
      </div>
    </div>
  );
};

export default ConfirmEmail;
