import React from "react";

import {
  Button,
  Checkbox,
  message,
  Modal,
  Space,
  Table,
  Typography,
} from "antd";
import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteCostCenterField,
  deleteCostCenterField,
  getCostCenterField,
  UpdateCostCenterField,
  updateCostCenterField,
  enableDisableCostCenter,
  AddCostCenterField,
} from "../../../../actions/companySettings";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { hasAccess, objectToFormData } from "../../../../utils";
import PageHeader from "../../../PageHeader";
import { employees } from "./../../../../actions/employees";
import "./../../styles/setting.css";
import "./../Custom.css";
import AddCostCentersField from "./AddCostCenterField";
import { useTranslation } from "react-i18next";
import { headerTitles } from "../../../../actions/masterdata/masterdata";

const { Title } = Typography;

message.config({
  maxCount: 3,
});


const CostCenter = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filters, setFilters] = React.useState({});
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState("center");
  const [selectedId, setSelectedId] = React.useState("");
  const [costCenterFields, setCostCenterFields] = React.useState([]);
  const [employeesData, setemployeesData] = React.useState([]);

  const totalRecords = useSelector((state) => state?.companySetting?.getCostCenterField?.data?.total_count)


  const updateCompanySettingResponse = useSelector(
    (state) => state.companySetting.updateCompanySetting || {}
  );
  const costCenterFieldResponse = useSelector(
    (state) => state.companySetting.getCostCenterField || {}
  );
  const deleteCostCenterFieldResponse = useSelector(
    (state) => state.companySetting.deleteCostCenterField || {}
  );
  const addCostCenterFieldResponse = useSelector(
    (state) => state.companySetting.addCostCenterField || {}
  );
  const updateCostCenterFieldResponse = useSelector(
    (state) => state.companySetting.updateCostCenterField || {}
  );
  const employeesResponse = useSelector(
    (state) => state.employees.employees || {}
  );
  const enableCostCenterResponse = useSelector(
    (state) => state.companySetting.enableDisableCostCenter || {}
  )

  console.log(enableCostCenterResponse, "enableCostCenterResponse")
  const {
    data: { data: employeesRecord = [] } = {},
    success = false,
  } = employeesResponse;

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("code"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("desc"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("cost_head"),
      dataIndex: "user_name",
      key: "user_name",
    },
  ];


  React.useEffect(() => {
    // dispatch(companySetting({ show: 'id' }));
    dispatch(getCostCenterField({
      page_number: 1
    }));
    dispatch(employees(({ params: { pagination: false } })));
    dispatch(
      headerTitles({
        title: "cost_center",
        description: "",
      })
    );
    // setIsModalVisible(false,);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (success) {
      setemployeesData(employeesRecord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = false, errors = "" } = {},
    } = updateCompanySettingResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
    } else {
      errors && message.error(<span className="messageText">{errors}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanySettingResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = false, error = "" } = {},
    } = enableCostCenterResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
    } else if (error) {
      error && message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCostCenterResponse]);


  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = updateCostCenterFieldResponse;

    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getCostCenterField({}));
      dispatch({
        type: UpdateCostCenterField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UpdateCostCenterField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCostCenterFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = deleteCostCenterFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getCostCenterField({}));
      dispatch({
        type: DeleteCostCenterField.RESET,
      });
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: DeleteCostCenterField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCostCenterFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = addCostCenterFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getCostCenterField({}));
      dispatch({
        type: AddCostCenterField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddCostCenterField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCostCenterFieldResponse]);

  React.useEffect(() => {
    const {
      data: { data = [],
        error = '' } = {},
    } = costCenterFieldResponse;
    if (error === false) {
      setCostCenterFields(data);
    }
    console.log(costCenterFieldResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costCenterFieldResponse]);

  const tableAction = [
    {
      title: t("enable"),
      dataIndex: "is_enable",
      key: "is_enable",
      render: (is_enable, record) => (
        <Checkbox
          key={record.id}
          onChange={(e) =>
            dispatch(
              enableDisableCostCenter(
                ({ id: record.id, is_enable: e.target.checked })
              )
            )
          }
          defaultChecked={is_enable}
          disabled={!hasWriteAccess}
        ></Checkbox>
      )
    },
    {
      title: t('actions'),
      key: 'action',
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button disabled={!hasWriteAccess} onClick={() => showEditModal(record.id)}><img src={EditIcon} alt={"logo"} className="action-logo" /></button>
          <button disabled={!hasWriteAccess} onClick={() => showDeleteModal(record.id)}><img src={DeleteIcon} alt={"logo"} className="action-logo" /></button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("center");
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t("delete_cost_center")}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() =>
            dispatch(
              deleteCostCenterField(({ id: selectedId }))
            )
          }
        >
          {t("delete")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t("cancel")}
        </Button>
      </>
    );
  };

  function handleButtonClick(e) {
    showModal();
  }

  return (
    <>
      <Modal
        title={
          modalType === "center" ? (
            <>
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
              {t("delete_cost")}
            </>
          ) : (
            <Title level={4}>
              {selectedId ? t("edit_cost_center") : t("new_cost_center")} &nbsp;
              <span className="text-small">{t("enter_details")}</span>
            </Title>
          )
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={
          modalType === "center"
            ? "center-aligned-modal"
            : "right-alinged-modal-small"
        }
      >
        {modalType === "center" ? (
          renderDeleteOption()
        ) : (
          <AddCostCentersField
            id={selectedId}
            handleCancel={handleCancel}
            employeesData={employeesData}
          />
        )}
      </Modal>
      {<Button onClick={handleButtonClick} className="mb1 ml1 addNew" disabled={!hasWriteAccess}>
        <img src={AddNewIcon} alt={"logo"} className="addNew-logo" />{t('addNew')}
      </Button>}
      <Table rowKey="id" columns={[...columns, ...tableAction]} dataSource={costCenterFields} className="settingsTable" pagination={{
        total: totalRecords || 0,
        onChange:(pagee) =>dispatch(getCostCenterField({
          page_number: pagee
        }))
      }}/>
    </>
  );
};

export default CostCenter;
