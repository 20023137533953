import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get_currencies, get_seed_data } from '../../actions/masterdata/masterdata';
import { paymentTerms, placeOfSupplies, savePlaceSupplies, vendorTDS } from '../../actions/purchases';
import AddPayTermsModal from '../Common/AddPayTerms';
import AddTaxModal from '../Common/AddTax';
import { ToastUtils } from '../Common/ToastUtils';
import { FormInput, FormSelect } from '../inputs';

export const inv_type = [
  {
    value: 'Registered Business Regular',
    label: 'Registered Business Regular',
    description: 'Business that is registered under GST',
  },
  {
    value: 'Registered Business - Composition',
    label: 'Registered Business - Composition',
    description: 'Business that is registered under the Composition Scheme in GST',
  },
  {
    value: 'Overseas',
    label: 'Overseas',
    description: 'Person with whom you do import or export of Supplies outside India',
  },
  {
    value: 'UnRegistered Business',
    label: 'UnRegistered Business',
    // description: 'Business that is registered under the Composition Scheme in GST',
  },
  {
    value: 'Special Economic Zone',
    label: 'Special Economic Zone',
    // description: 'Business that is registered under the Composition Scheme in GST',
  }, {
    value: 'Deemed Export',
    label: 'Deemed Export',
    // description: 'Business that is registered under the Composition Scheme in GST',
  }, {
    value: 'Tax Deductor',
    label: 'Tax Deductor',
    // description: 'Business that is registered under the Composition Scheme in GST',
  },
  {
    value: 'SEZ Developer',
    label: 'SEZ Developer',
    // description: 'Business that is registered under the Composition Scheme in GST',
  },
]

const AddVendorOtherInformation = ({ control, dataBeneficiary, register, errors, watch, setError, setValue, isVendorPortal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [createdValue, setCreatedValue] = useState(null);
  const [listVendorTDS, setListVendorTSD] = useState([]);
  const [listPaymentTerms, setListPaymentTerms] = useState([]);
  const [placesOfSuppliesOptions, setPlacesOfSuppliesOptions] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isAddTax, setIsAddTax] = useState(false);

  const vendorsTDS = useSelector((state) => state.purchases.vendorTDS || {});
  const paymentResponse = useSelector((state) => state.purchases.paymentTerms || {});
  const placesSupplies = useSelector(state => state?.seed_data?.place_of_supply?.data?.length ? state?.seed_data?.place_of_supply?.data?.map(ele => ({ label: ele.seed_value, value: ele.seed_value })) : [])
  const createPlacesSupplies = useSelector((state) => state.purchases.savePlaceSupplies || {});
  const currenciesRes = useSelector(state => state?.currencies?.data?.data?.length ? state?.currencies?.data?.data?.map(ele => ({ label: ele.iso_code, value: ele.iso_code })) : [])
  const currencies = Array.from(new Set(currenciesRes.map(obj => obj.value))).map(value => currenciesRes.find(obj => obj.value === value));

  // console.log({ currencies });
  useEffect(() => {
    if (dataBeneficiary) {
      if (dataBeneficiary.configurations) {
        setValue('currency', dataBeneficiary.configurations.currency);
        setValue('payment_terms', dataBeneficiary.configurations.payment_terms);
      }

      if (dataBeneficiary.tax_settings) {
        setValue('place_of_supply', dataBeneficiary.tax_settings.place_of_supply);
        setValue('tds', dataBeneficiary.tax_settings.tax_type);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBeneficiary])
  useEffect(() => {
    if (placesSupplies.success && !placesSupplies.data?.error) {
      setPlacesOfSuppliesOptions(
        placesSupplies.data?.data?.map((item) => ({
          value: item.seed_value,
          label: item.seed_code,
        }))
      );
    }
  }, [placesSupplies]);

  useEffect(() => {
    if (vendorsTDS?.success && !vendorsTDS?.data?.error) {
      setListVendorTSD(
        vendorsTDS.data?.data?.map((item) => ({
          data: item,
          value: item.tax_name,
          label: item.tax_name,
        }))
      );
    }
  }, [vendorsTDS]);

  useEffect(() => {
    if (paymentResponse?.success && !paymentResponse?.data?.error) {
      setListPaymentTerms(
        paymentResponse.data?.data?.map((item) => ({
          value: item.term_name,
          label: item.term_name,
        }))
      );
    }
  }, [paymentResponse]);

  useEffect(() => {
    if (createPlacesSupplies.success) {
      ToastUtils.successMessage('Success', 'Source of supply successfully created.')
      if (createdValue) {
        setValue('place_of_supply', createdValue);
        dispatch(placeOfSupplies({ name: createdValue.trim() }));
      }
      setCreatedValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPlacesSupplies]);

  const tdsWatch = watch('tds');
  useEffect(() => {
    if (tdsWatch) {
      let indexTDS = listVendorTDS.findIndex(tdsInfo => tdsInfo.data.tax_name === tdsWatch);
      if (indexTDS !== -1) {
        let data = listVendorTDS[indexTDS].data;
        setValue('tax_type', data.tax_name);
        setValue('tax_id', data.id);
        setValue('tax_percentage', data.rate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tdsWatch]);

  useEffect(() => {
    dispatch(vendorTDS({}));
    dispatch(paymentTerms({}));
    dispatch(get_seed_data({
      company_id: localStorage.getItem("company_id"),
      seed_type: "place_of_supply"
    }));
    dispatch(get_currencies())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPlaceOfSupplySearch = useCallback(
    (e) => {
      if (e && e.trim() !== '') {
        dispatch(placeOfSupplies({ name: e.trim() }));
      }
    },
    dispatch,
    placeOfSupplies
  );

  const onCreateSupplies = useCallback((e) => {
    if (e && e.trim() !== '') {
      let payload = {
        company_id: localStorage.getItem('company_id'),
        seed_code: e,
        seed_value: e,
        seed_type: 'place_of_supply'
      }
      setCreatedValue(e);
      dispatch(savePlaceSupplies(JSON.stringify(payload)));
    }
  }, dispatch, savePlaceSupplies
  );

  const gst_treatment = watch('gst_treatment')
  useEffect(() => {
    if (gst_treatment === "UnRegistered Business" || gst_treatment === "Overseas" || !gst_treatment) {
      setError('gstin', '')
    }
  }, [gst_treatment])

  return (
    <>
      <AddPayTermsModal
        companyId={localStorage.getItem("company_id")}
        isVisible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
        callBack={() => {
          dispatch(paymentTerms({}))
        }}
      />
      <AddTaxModal
        companyId={localStorage.getItem("company_id")}
        isVisible={isAddTax}
        onCancel={() => {
          setIsAddTax(false);
        }}
        callBack={() => {
          dispatch(vendorTDS({}))
        }}
      />
      <div>
        <FormSelect
          control={control}
          {...register('gst_treatment', {
            required: !gst_treatment ? false : "GST Treatment is required."
          })}
          errors={errors}
          label={t('gst_treatment')}
          required={!gst_treatment ? false : true}
          hideCreateOption
          options={inv_type}
          disabled={isVendorPortal}
        />
      </div>
      <div className="pp-form-item">
        <FormInput
          control={control}
          {...register('gstin', {
            required: gst_treatment === "UnRegistered Business" || gst_treatment === "Overseas" || !gst_treatment ? false : "GSTIN / UIN is required"
          })}
          errors={errors}
          label={t('gst_uin')}
          placeholder={t('enter_gst_uin')}
          required={gst_treatment === "UnRegistered Business" || gst_treatment === "Overseas" || !gst_treatment ? false : true}
          disabled={isVendorPortal}
        />
      </div>
      <div className="pp-form-item">
        <FormInput
          control={control}
          {...register('pan_number', {
            required: !gst_treatment ? false : "Pan Number is required",
            pattern: {
              value: /[A-Z]{5}[0-9]{4}[A-Z]{1}$/i,
              message: "Enter valid PAN number"
            }
          })}
          required={!gst_treatment ? false : true}
          errors={errors}
          label={t('pan_no')}
          placeholder={t('enter_pan')}
          disabled={isVendorPortal}
        />
      </div>

      <div className="pp-form-item">
        <FormSelect
          control={control}
          {...register('place_of_supply', {
            required: !gst_treatment ? false : "Source of Supply is required",
          })}
          required={!gst_treatment ? false : true}
          errors={errors}
          hideCreateOption={true}
          label={t('source_of_supply')}
          onSearch={onPlaceOfSupplySearch}
          options={placesSupplies}
          onCreate={onCreateSupplies}
          disabled={isVendorPortal}
        />
      </div>

      <div className="pp-form-item">
        <FormSelect
          control={control}
          {...register('configurations.currency', {
            required: "Currency is required",
            onChange: (e) => {
              setValue('configurations.currency', e?.target?.value)
            }
          })}
          placeholder='Select Currency'
          errors={errors}
          label={t('currency')}
          required={true}
          hideCreateOption
          errorMessage={errors?.configurations?.currency?.message}
          disabled={dataBeneficiary?.configurations?.currency || isVendorPortal ? true : false}
          options={currencies}
        />
      </div>

      <div className="pp-form-item">
        <FormSelect
          control={control} {...register('configurations.payment_terms')}
          errors={errors}
          label={t('payment_term')}
          options={listPaymentTerms}
          onAddItem={() => {
            setIsVisible(true)
          }}
          disabled={isVendorPortal}
        />
      </div>

      <div className="pp-form-item">
        <FormSelect
          control={control}
          {...register('tds')}
          errors={errors}
          label="TDS"
          options={listVendorTDS}
          onAddItem={() => {
            setIsAddTax(true)
          }}
          disabled={isVendorPortal}
        />
      </div>
    </>
  );
};

export default AddVendorOtherInformation;
