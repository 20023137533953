import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions, sagaFunctionsSettings, sagaFunctionsExpense } from "../../utils";

import {
  AddUserAdvance, UpdateUserAdvance, Advances, AdvancesApprove, AdvancesComments,
  AdvancesCommentsAdd, AdvancesDetailPDF, AdvancesExpAddComments, AdvancesExpComments, AdvancesExpDetails, AdvancesExpHistory, AdvancesExportExcel,
  AdvancesHistory, AdvancesReject, TransactionsPartialApprove, TransactionsReject, UserAdvanceFields, UserAdvances,
  UserAdvancesSubmit, UserAdvanceDelete, AdvanceShow
} from "./advances";

function getAdvances(req) {
  let obj = {
    module_type: req.payload.module_type,
  }
  if (req.payload.status) {
    obj.status = req.payload.status
    delete req.payload.status
  }
  if (req.payload.view) {
    obj.view = req.payload.view
    delete req.payload.view
  }
  if (req.payload.page_number) {
    obj.page_number = req.payload.page_number
    delete req.payload.page_number
  }
  delete req.payload.view
  delete req.payload.module_type
  let urlParams = new URLSearchParams(obj)
  return sagaFunctionsExpense(Advances, "post", apis.advances + `?${urlParams}`, req.payload)();
}

function getAdvanceShow(req) {
  return sagaFunctions(AdvanceShow, "get", apis.advanceShow, req.payload)();
}

function getAdvancesReject(req) {
  return sagaFunctionsExpense(
    AdvancesReject,
    "post",
    apis.advancesReject,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getTransactionsReject(req) {
  return sagaFunctions(
    TransactionsReject,
    "post",
    apis.transactionsReject,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getTransactionsPartialApprove(req) {
  return sagaFunctions(
    TransactionsPartialApprove,
    "post",
    apis.transactionsPartialApprove,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getAdvancesApprove(req) {
  return sagaFunctionsExpense(
    AdvancesApprove,
    "post",
    apis.advancesApprove,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getAdvancesExpDetails(req) {
  return sagaFunctionsExpense(
    AdvancesExpDetails,
    "get",
    apis.advanceShow,
    req.payload,
  )();
}

function getAdvancesExpComments(req) {
  return sagaFunctions(
    AdvancesExpComments,
    "get",
    apis.getAdvanceTransactionComments,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getAdvancesExpAddComments(req) {
  return sagaFunctions(
    AdvancesExpAddComments,
    "post",
    apis.getAdvanceTransactionComments,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getAdvancesComments(req) {
  return sagaFunctions(
    AdvancesComments,
    "get",
    req.otherParams?.is_admin
      ? apis.advancesComments
      : apis.userAdvancesComments,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getAdvancesCommentsAdd(req) {
  return sagaFunctions(
    AdvancesCommentsAdd,
    "post",
    req.otherParams?.is_admin
      ? apis.advancesComments
      : apis.userAdvancesComments,
    req.payload,
    {},
    true,
    {
      // "Content-Type": "multipart/formdata",
      // Authorization: localStorage.getItem("token"),
    }
  )();
}

function getAdvancesExportExcel(req) {
  return sagaFunctions(
    AdvancesExportExcel,
    "post",
    apis.advancesExportExcel,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getAdvancesHistory(req) {
  return sagaFunctions(
    AdvancesHistory,
    "get",
    req.otherParams?.is_admin ? apis.advancesHistory : apis.userAdvancesHistory,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getAdvancesExpHistory(req) {
  return sagaFunctions(
    AdvancesExpHistory,
    "get",
    apis.transactionsHistory,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getUserAdvances(req) {
  return sagaFunctionsExpense(UserAdvances, "post", apis.userAdvances, req.payload)();
}

function advance_pdf(req) {
  return sagaFunctions(AdvancesDetailPDF, "get", apis.export_advance_details, req.payload)();
}

function getUserAdvancesSubmit(req) {
  const isRecall = req.payload.isRecall;
  delete req.payload.isRecall;
  return sagaFunctionsExpense(
    UserAdvancesSubmit,
    "post",
    isRecall ? apis.userAdvancesRecall : apis.userAdvancesSubmit,
    req.payload,
  )();
}

function getAddUserAdvance(req) {
  return sagaFunctionsExpense(AddUserAdvance, "post", apis.userAdvancesCreate, req.payload)();
}

function getUpdateUserAdvance(req) {
  // let formData = new FormData()
  // req.payload?.field_values?.forEach((ele, i) => {
  //   formData.append(`field_values[${i}][id]`, ele.id)
  //   formData.append(`field_values[${i}][value]`, ele.value)
  // })
  return sagaFunctionsExpense(UpdateUserAdvance, "post", apis.updateUserAdvances, req.payload)();
}

function getUserAdvanceFields(req) {
  const obj = {
    module_type: req.payload.module_type,
  }

  delete req.payload.module_type;
  let urlParams = new URLSearchParams(obj)
  return sagaFunctionsSettings(
    UserAdvanceFields,
    "get",
    apis.userAdvanceFields + `?${urlParams}`,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getUserAdvanceDelete(req) {
  return sagaFunctionsExpense(
    UserAdvanceDelete,
    "post",
    apis.userAdvanceDelete,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

export function* advancesWatcher() {
  yield takeLatest(Advances.REQUEST, getAdvances);
  yield takeLatest(AdvancesDetailPDF.REQUEST, advance_pdf);
  yield takeLatest(AdvancesReject.REQUEST, getAdvancesReject);
  yield takeLatest(TransactionsReject.REQUEST, getTransactionsReject);
  yield takeLatest(
    TransactionsPartialApprove.REQUEST,
    getTransactionsPartialApprove
  );
  yield takeLatest(AdvancesApprove.REQUEST, getAdvancesApprove);
  yield takeLatest(AdvanceShow.REQUEST, getAdvanceShow);
  yield takeLatest(AdvancesExpDetails.REQUEST, getAdvancesExpDetails);
  yield takeLatest(AdvancesExpComments.REQUEST, getAdvancesExpComments);
  yield takeLatest(AdvancesExpAddComments.REQUEST, getAdvancesExpAddComments);
  yield takeLatest(AdvancesComments.REQUEST, getAdvancesComments);
  yield takeLatest(AdvancesCommentsAdd.REQUEST, getAdvancesCommentsAdd);
  yield takeLatest(AdvancesExportExcel.REQUEST, getAdvancesExportExcel);
  yield takeLatest(AdvancesHistory.REQUEST, getAdvancesHistory);
  yield takeLatest(AdvancesExpHistory.REQUEST, getAdvancesExpHistory);
  yield takeLatest(UserAdvances.REQUEST, getUserAdvances);
  yield takeLatest(UserAdvancesSubmit.REQUEST, getUserAdvancesSubmit);
  yield takeLatest(AddUserAdvance.REQUEST, getAddUserAdvance);
  yield takeLatest(UpdateUserAdvance.REQUEST, getUpdateUserAdvance);
  yield takeLatest(UserAdvanceFields.REQUEST, getUserAdvanceFields);
  yield takeLatest(UserAdvanceDelete.REQUEST, getUserAdvanceDelete);
}
