import { ActionTypesFactory } from "../../utils";

export const CompanyProfile = ActionTypesFactory(
  "CompanyProfile",
  "CompanySetting"
);
export const CompanyProfileImages = ActionTypesFactory(
  "CompanyProfileImages",
  "CompanySetting"
);
export const UpdateCompanyProfile = ActionTypesFactory(
  "UpdateCompanyProfile",
  "CompanySetting"
);
export const UpdateCompanyProfileImages = ActionTypesFactory(
  "UpdateCompanyProfileImages",
  "CompanySetting"
);

export const ReportsApproval = ActionTypesFactory(
  "ReportsApproval",
  "CompanySetting"
);
export const UpdateReportsApproval = ActionTypesFactory(
  "UpdateReportsApproval",
  "CompanySetting"
);

export const AdvanceApproval = ActionTypesFactory(
  "AdvanceApproval",
  "CompanySetting"
);
export const UpdateAdvanceApproval = ActionTypesFactory(
  "UpdateAdvanceApproval",
  "CompanySetting"
);

export const TripApproval = ActionTypesFactory(
  "TripApproval",
  "CompanySetting"
);
export const BillApproval = ActionTypesFactory(
  "BillApproval",
  "CompanySetting"
);
export const UpdateTripApproval = ActionTypesFactory(
  "UpdateTripApproval",
  "CompanySetting"
);
export const UpdateBillApproval = ActionTypesFactory(
  "UpdateBillApproval",
  "CompanySetting"
);

export const CompanySetting = ActionTypesFactory(
  "CompanySetting",
  "CompanySetting"
);
export const CompanySettingStatus = ActionTypesFactory(
  "CompanySettingStatus",
  "CompanySetting"
);
export const AddCompanySetting = ActionTypesFactory(
  "AddCompanySetting",
  "CompanySetting"
);
export const DeleteCompanySetting = ActionTypesFactory(
  "DeleteCompanySetting",
  "CompanySetting"
);

export const GetCompanyCountries = ActionTypesFactory(
  "CompanyCountries",
  "CompanySetting"
);
export const GetCompanyTimeZones = ActionTypesFactory(
  "CompanyTimeZones",
  "CompanySetting"
);
export const GetCompanyStates = ActionTypesFactory(
  "CompanyStates",
  "CompanySetting"
);
export const GetCompanyCities = ActionTypesFactory(
  "CompanyCities",
  "CompanySetting"
);

export const UpdateCompanyTransaction = ActionTypesFactory(
  "UpdateCompanyTransaction",
  "CompanySetting"
);
export const GetTransactionField = ActionTypesFactory(
  "GetTransactionField",
  "CompanySetting"
);
export const GetSingleTransactionField = ActionTypesFactory(
  "GetSingleTransactionField",
  "CompanySetting"
);
export const AddTransactionField = ActionTypesFactory(
  "AddTransactionField",
  "CompanySetting"
);
export const DeleteTransactionField = ActionTypesFactory(
  "DeleteTransactionField",
  "CompanySetting"
);
export const UpdateTransactionField = ActionTypesFactory(
  "UpdateTransactionField",
  "CompanySetting"
);

export const UpdateCompanyReport = ActionTypesFactory(
  "UpdateCompanyReport",
  "CompanySetting"
);
export const GetReportField = ActionTypesFactory(
  "GetReportField",
  "CompanySetting"
);
export const AddReportField = ActionTypesFactory(
  "AddReportField",
  "CompanySetting"
);
export const DeleteReportField = ActionTypesFactory(
  "DeleteReportField",
  "CompanySetting"
);
export const UpdateReportField = ActionTypesFactory(
  "UpdateReportField",
  "CompanySetting"
);

export const UpdateCompanyAdvances = ActionTypesFactory(
  "UpdateCompanyAdvances",
  "CompanySetting"
);
export const GetAdvancesField = ActionTypesFactory(
  "GetAdvancesField",
  "CompanySetting"
);
export const AddAdvancesField = ActionTypesFactory(
  "AddAdvancesField",
  "CompanySetting"
);
export const DeleteAdvancesField = ActionTypesFactory(
  "DeleteAdvancesField",
  "CompanySetting"
);
export const UpdateAdvancesField = ActionTypesFactory(
  "UpdateAdvancesField",
  "CompanySetting"
);

export const GetTripsField = ActionTypesFactory(
  "GetTripsField",
  "CompanySetting"
);
export const AddTripsField = ActionTypesFactory(
  "AddTripsField",
  "CompanySetting"
);
export const UpdateCompanyTrips = ActionTypesFactory(
  "UpdateCompanyTrips",
  "CompanySetting"
);
export const DeleteTripsField = ActionTypesFactory(
  "DeleteTripsField",
  "CompanySetting"
);
export const UpdateTripsField = ActionTypesFactory(
  "UpdateTripsField",
  "CompanySetting"
);

export const GetCostCenterField = ActionTypesFactory(
  "GetCostCenterField",
  "CompanySetting"
);
export const AddCostCenterField = ActionTypesFactory(
  "AddCostCenterField",
  "CompanySetting"
);
export const DeleteCostCenterField = ActionTypesFactory(
  "DeleteCostCenterField",
  "CompanySetting"
);
export const UpdateCostCenterField = ActionTypesFactory(
  "UpdateCostCenterField",
  "CompanySetting"
);
export const EnableDisableCostCenterField = ActionTypesFactory(
  "EnableDisableCostCenterField",
  "CompanySetting"
);

export const GetMerchantsField = ActionTypesFactory(
  "GetMerchantsField",
  "CompanySetting"
);
export const AddMerchantsField = ActionTypesFactory(
  "AddMerchantsField",
  "CompanySetting"
);
export const DeleteMerchantsField = ActionTypesFactory(
  "DeleteMerchantsField",
  "CompanySetting"
);
export const UpdateMerchantsField = ActionTypesFactory(
  "UpdateMerchantsField",
  "CompanySetting"
);
export const EnableDisableMarchentField = ActionTypesFactory(
  "EnableDisableMarchentField",
  "CompanySetting"
);

export const GetProjectsField = ActionTypesFactory(
  "GetProjectsField",
  "CompanySetting"
);
export const AddProjectsField = ActionTypesFactory(
  "AddProjectsField",
  "CompanySetting"
);
export const DeleteProjectsField = ActionTypesFactory(
  "DeleteProjectsField",
  "CompanySetting"
);
export const UpdateProjectsField = ActionTypesFactory(
  "UpdateProjectsField",
  "CompanySetting"
);
export const EnableDisableProjectField = ActionTypesFactory(
  "EnableDisableProjectField",
  "CompanySetting"
);

export const GetRolesPermissions = ActionTypesFactory(
  "GetRolesPermissions",
  "CompanySetting"
);
export const GetRoles = ActionTypesFactory("GetRoles", "CompanySetting");
export const GetSingleRoles = ActionTypesFactory(
  "GetSingleRoles",
  "CompanySetting"
);
export const AddRoles = ActionTypesFactory("AddRoles", "CompanySetting");
export const UpdateRoles = ActionTypesFactory("UpdateRoles", "CompanySetting");

export const GetUserFields = ActionTypesFactory("UserFields", "CompanySetting");
export const GetUserFieldsColumnTypes = ActionTypesFactory(
  "UserFieldsColumnTypes",
  "CompanySetting"
);
export const GetSingleUserFields = ActionTypesFactory(
  "GetSingleUserFields",
  "CompanySetting"
);
export const AddUserFields = ActionTypesFactory(
  "AddUserFields",
  "CompanySetting"
);
export const UpdateUserFields = ActionTypesFactory(
  "UpdateUserFields",
  "CompanySetting"
);
export const DeleteUserFields = ActionTypesFactory(
  "DeleteUserFields",
  "CompanySetting"
);

export const GetDesignations = ActionTypesFactory(
  "GetDesignations",
  "CompanySetting"
);
export const AddDesignations = ActionTypesFactory(
  "AddDesignations",
  "CompanySetting"
);
export const DeleteDesignations = ActionTypesFactory(
  "DeleteDesignations",
  "CompanySetting"
);
export const UpdateDesignations = ActionTypesFactory(
  "UpdateDesignations",
  "CompanySetting"
);
export const GetSingleDesignations = ActionTypesFactory(
  "GetSingleDesignations",
  "CompanySetting"
);
export const DeleteRole = ActionTypesFactory("DeleteRole", "CompanySetting");

export const GetCustomFields = ActionTypesFactory(
  "GetCustomFields",
  "CompanySetting"
);
export const CompanyCurrency = ActionTypesFactory(
  "CompanyCurrency",
  "CompanySetting"
);
export const GetReminderAPI = ActionTypesFactory(
  "GetReminderAPI",
  "GetReminderAPI"
);
export const CreateReminderAPI = ActionTypesFactory(
  "CreateReminderAPI",
  "CreateReminderAPI"
);

// multi-currency start
export const GetCompanyMultipleCurrency = ActionTypesFactory(
  "GetCompanyMultipleCurrency",
  "GetCompanyMultipleCurrency"
);
export const CreateCompanyMultipleCurrency = ActionTypesFactory(
  "CreateCompanyMultipleCurrency",
  "CreateCompanyMultipleCurrency"
);
export const GetAllCurrency = ActionTypesFactory(
  "GetAllCurrency",
  "GetAllCurrency"
);
export const GetAllCountries = ActionTypesFactory(
  "GetAllCountries",
  "GetAllCountries"
);
export const GetAllExchangeRates = ActionTypesFactory(
  "GetAllExchangeRates",
  "GetAllExchangeRates"
);
export const CreateExchangeRate = ActionTypesFactory(
  "CreateExchangeRate",
  "CreateExchangeRate"
);
export const ToggleExchangeRate = ActionTypesFactory(
  "ToggleExchangeRate",
  "ToggleExchangeRate"
);
export const GetAllCompanyDetails = ActionTypesFactory(
  "GetAllCompanyDetails",
  "GetAllCompanyDetails"
);
export const GetCompanyBaseCurrency = ActionTypesFactory(
  "GetCompanyBaseCurrency",
  "GetCompanyBaseCurrency"
);

// Multi Branch
export const CreateBranch = ActionTypesFactory("CreateBranch", "CreateBranch");
export const GetAllBranch = ActionTypesFactory("GetAllBranch", "GetAllBranch");
export const GetAllBranchList = ActionTypesFactory(
  "GetAllBranchList",
  "GetAllBranchList"
);
export const GetAllBranchHeader = ActionTypesFactory(
  "GetAllBranchHeader",
  "GetAllBranchHeader"
);
export const GetBranchID = ActionTypesFactory("GetBranchID", "GetBranchID");
export const SwitchBrach = ActionTypesFactory("SwitchBrach", "SwitchBrach");
export const SwitchBrachRuby = ActionTypesFactory(
  "SwitchBrachRuby",
  "SwitchBrachRuby"
);
export const GetCardVenders = ActionTypesFactory(
  "GetCardVenders",
  "GetCardVenders"
);
export const GetPortalPermissions = ActionTypesFactory(
  "GetPortalPermissions",
  "GetPortalPermissions"
);
// export const authorize = (payload) => {
//   console.log("step one");
//   return {
//     type: Authorize.REQUEST,
//     payload,
//   };
// };

export const deleteRole = (payload) => {
  return {
    type: DeleteRole.REQUEST,
    payload,
  };
};

export const companycurrency = (payload) => {
  return {
    type: CompanyCurrency.REQUEST,
    payload,
  };
};

export const companyProfile = (payload) => {
  return {
    type: CompanyProfile.REQUEST,
    payload,
  };
};

export const companyProfileImages = (payload) => {
  return {
    type: CompanyProfileImages.REQUEST,
    payload,
  };
};

export const updateCompanyProfile = (payload) => {
  return {
    type: UpdateCompanyProfile.REQUEST,
    payload,
  };
};

export const updateCompanyProfilefImages = (payload) => {
  return {
    type: UpdateCompanyProfileImages.REQUEST,
    payload,
  };
};

// ReportsApproval ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const reportsApproval = (payload) => {
  return {
    type: ReportsApproval.REQUEST,
    payload,
  };
};

export const updateReportsApproval = (payload) => {
  return {
    type: UpdateReportsApproval.REQUEST,
    payload,
  };
};

// AdvanceApproval ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const advanceApproval = (payload) => {
  return {
    type: AdvanceApproval.REQUEST,
    payload,
  };
};

export const updateAdvanceApproval = (payload) => {
  return {
    type: UpdateAdvanceApproval.REQUEST,
    payload,
  };
};

// TripApproval ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const tripApproval = (payload) => {
  return {
    type: TripApproval.REQUEST,
    payload,
  };
};

export const billApproval = (payload) => {
  return {
    type: BillApproval.REQUEST,
    payload,
  };
};

export const updateBillApproval = (payload, others) => {
  return {
    type: UpdateBillApproval.REQUEST,
    payload,
    others,
  };
};

export const updateTripApproval = (payload) => {
  return {
    type: UpdateTripApproval.REQUEST,
    payload,
  };
};

// companySetting ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const companySetting = (payload) => {
  return {
    type: CompanySetting.REQUEST,
    payload,
  };
};

export const companySettingStatus = (payload) => {
  return {
    type: CompanySettingStatus.REQUEST,
    payload,
  };
};

export const addCompanySetting = (payload) => {
  return {
    type: AddCompanySetting.REQUEST,
    payload,
  };
};

export const deleteCompanySetting = (payload) => {
  return {
    type: DeleteCompanySetting.REQUEST,
    payload,
  };
};

// Transaction ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const updateCompanyTransaction = (payload) => {
  return {
    type: UpdateCompanyTransaction.REQUEST,
    payload,
  };
};

export const getTransactionField = (payload) => {
  return {
    type: GetTransactionField.REQUEST,
    payload,
  };
};

export const getSingleTransactionField = (payload) => {
  return {
    type: GetSingleTransactionField.REQUEST,
    payload,
  };
};

export const addTransactionField = (payload) => {
  return {
    type: AddTransactionField.REQUEST,
    payload,
  };
};

export const deleteTransactionField = (payload) => {
  return {
    type: DeleteTransactionField.REQUEST,
    payload,
  };
};

export const updateTransactionField = (payload) => {
  return {
    type: UpdateTransactionField.REQUEST,
    payload,
  };
};

// Report ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const updateCompanyReport = (payload) => {
  return {
    type: UpdateCompanyReport.REQUEST,
    payload,
  };
};

export const getReportField = (payload) => {
  return {
    type: GetReportField.REQUEST,
    payload,
  };
};

export const addReportField = (payload) => {
  return {
    type: AddReportField.REQUEST,
    payload,
  };
};

export const deleteReportField = (payload) => {
  return {
    type: DeleteReportField.REQUEST,
    payload,
  };
};

export const updateReportField = (payload) => {
  return {
    type: UpdateReportField.REQUEST,
    payload,
  };
};

// advances ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const updateCompanyAdvances = (payload) => {
  return {
    type: UpdateCompanyAdvances.REQUEST,
    payload,
  };
};

export const getAdvancesField = (payload) => {
  return {
    type: GetAdvancesField.REQUEST,
    payload,
  };
};

export const addAdvancesField = (payload) => {
  return {
    type: AddAdvancesField.REQUEST,
    payload,
  };
};

export const deleteAdvancesField = (payload) => {
  return {
    type: DeleteAdvancesField.REQUEST,
    payload,
  };
};

export const updateAdvancesField = (payload) => {
  return {
    type: UpdateAdvancesField.REQUEST,
    payload,
  };
};

// Trips ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const getTripsField = (payload) => {
  return {
    type: GetTripsField.REQUEST,
    payload,
  };
};

export const addTripsField = (payload) => {
  return {
    type: AddTripsField.REQUEST,
    payload,
  };
};

export const updateCompanyTrips = (payload) => {
  return {
    type: UpdateCompanyTrips.REQUEST,
    payload,
  };
};

export const deleteTripsField = (payload) => {
  return {
    type: DeleteTripsField.REQUEST,
    payload,
  };
};

export const updateTripsField = (payload) => {
  return {
    type: UpdateTripsField.REQUEST,
    payload,
  };
};

// CostCenter ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const getCostCenterField = (payload) => {
  return {
    type: GetCostCenterField.REQUEST,
    payload,
  };
};

export const addCostCenterField = (payload) => {
  return {
    type: AddCostCenterField.REQUEST,
    payload,
  };
};

export const deleteCostCenterField = (payload) => {
  return {
    type: DeleteCostCenterField.REQUEST,
    payload,
  };
};

export const updateCostCenterField = (payload) => {
  return {
    type: UpdateCostCenterField.REQUEST,
    payload,
  };
};

export const enableDisableCostCenter = (payload) => {
  return {
    type: EnableDisableCostCenterField.REQUEST,
    payload,
  };
};

// Merchants ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const getMerchantsField = (payload) => {
  return {
    type: GetMerchantsField.REQUEST,
    payload,
  };
};

export const addMerchantsField = (payload) => {
  return {
    type: AddMerchantsField.REQUEST,
    payload,
  };
};

export const deleteMerchantsField = (payload) => {
  return {
    type: DeleteMerchantsField.REQUEST,
    payload,
  };
};

export const updateMerchantsField = (payload) => {
  return {
    type: UpdateMerchantsField.REQUEST,
    payload,
  };
};

export const enabledisableMarchent = (payload) => {
  return {
    type: EnableDisableMarchentField.REQUEST,
    payload,
  };
};

// Projects ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const getProjectsField = (payload) => {
  return {
    type: GetProjectsField.REQUEST,
    payload,
  };
};

export const addProjectsField = (payload) => {
  return {
    type: AddProjectsField.REQUEST,
    payload,
  };
};

export const deleteProjectsField = (payload) => {
  return {
    type: DeleteProjectsField.REQUEST,
    payload,
  };
};

export const updateProjectsField = (payload) => {
  return {
    type: UpdateProjectsField.REQUEST,
    payload,
  };
};

export const enableDisableProjectField = (payload) => {
  return {
    type: EnableDisableProjectField.REQUEST,
    payload,
  };
};

// Roles ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const getRolesPermissions = (payload) => {
  return {
    type: GetRolesPermissions.REQUEST,
    payload,
  };
};

export const getRoles = (payload) => {
  return {
    type: GetRoles.REQUEST,
    payload,
  };
};

export const getSingleRoles = (payload) => {
  return {
    type: GetSingleRoles.REQUEST,
    payload,
  };
};

export const addRoles = (payload) => {
  return {
    type: AddRoles.REQUEST,
    payload,
  };
};

export const updateRoles = (payload) => {
  return {
    type: UpdateRoles.REQUEST,
    payload,
  };
};

// User fields ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const getUserFields = (payload) => {
  return {
    type: GetUserFields.REQUEST,
    payload,
  };
};
export const getUserFieldsColumnTypes = (payload) => {
  return {
    type: GetUserFieldsColumnTypes.REQUEST,
    payload,
  };
};
export const getSingleUserFields = (payload) => {
  return {
    type: GetSingleUserFields.REQUEST,
    payload,
  };
};
export const addUserFields = (payload) => {
  return {
    type: AddUserFields.REQUEST,
    payload,
  };
};
export const updateUserFields = (payload) => {
  return {
    type: UpdateUserFields.REQUEST,
    payload,
  };
};
export const deleteUserFields = (payload) => {
  return {
    type: DeleteUserFields.REQUEST,
    payload,
  };
};

// Designations ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const getDesignations = (payload) => {
  return {
    type: GetDesignations.REQUEST,
    payload,
  };
};

export const addDesignations = (payload) => {
  return {
    type: AddDesignations.REQUEST,
    payload,
  };
};

export const deleteDesignations = (payload) => {
  return {
    type: DeleteDesignations.REQUEST,
    payload,
  };
};

export const updateDesignations = (payload) => {
  return {
    type: UpdateDesignations.REQUEST,
    payload,
  };
};

export const getSingleDesignations = (payload) => {
  return {
    type: GetSingleDesignations.REQUEST,
    payload,
  };
};

// get custom field ===============================================================================================
// ===============================================================================================
// ===============================================================================================

export const getCustomFields = (payload) => {
  return {
    type: GetCustomFields.REQUEST,
    payload,
  };
};

export const getCompanyCities = (payload) => {
  return {
    type: GetCompanyCities.REQUEST,
    payload,
  };
};

export const getCompanyStates = (payload) => {
  return {
    type: GetCompanyStates.REQUEST,
    payload,
  };
};

export const getCompanyCountries = (payload) => {
  return {
    type: GetCompanyCountries.REQUEST,
    payload,
  };
};

export const getCompanyTimezones = (payload) => {
  return {
    type: GetCompanyTimeZones.REQUEST,
    payload,
  };
};

// export const commits = (payload) => {
//   let str = "";
//   Object.keys(payload).forEach((item) => {
//     str = str + `${item}:${payload[item]} `;
//   });
//   payload = encodeURIComponent(str);
//   return {
//     type: Commits.REQUEST,
//     payload,
//   };
// };

// Bill and invoice reminder APi
export const getReminderAPI = (payload) => {
  return {
    type: GetReminderAPI.REQUEST,
    payload,
  };
};
export const createReminderAPI = (payload) => {
  return {
    type: CreateReminderAPI.REQUEST,
    payload,
  };
};

// multi-currency start ==========================================================================================

export const createCompanyMultipleCurrency = (payload, isUpdate) => {
  return {
    type: CreateCompanyMultipleCurrency.REQUEST,
    payload,
    isUpdate,
  };
};

export const getCompanyMultipleCurrency = (payload) => {
  return {
    type: GetCompanyMultipleCurrency.REQUEST,
    payload,
  };
};

export const getAllCompanyDetails = (payload) => {
  return {
    type: GetAllCompanyDetails.REQUEST,
    payload,
  };
};

export const getAllCurrency = (payload) => {
  return {
    type: GetAllCurrency.REQUEST,
    payload,
  };
};
export const getAllCountries = (payload) => {
  return {
    type: GetAllCountries.REQUEST,
    payload,
  };
};

export const getAllExchangeRates = (payload) => {
  return {
    type: GetAllExchangeRates.REQUEST,
    payload,
  };
};

export const createExchangeRate = (payload, isUpdate) => {
  return {
    type: CreateExchangeRate.REQUEST,
    payload,
    isUpdate,
  };
};

export const toggleExchangeRate = (payload) => {
  return {
    type: ToggleExchangeRate.REQUEST,
    payload,
  };
};

// Multi Brach -----------------------------------
export const createBranch = (payload) => {
  return {
    type: CreateBranch.REQUEST,
    payload,
  };
};

export const getAllBranch = (payload) => {
  return {
    type: GetAllBranch.REQUEST,
    payload,
  };
};
export const getAllBranchList = (payload) => {
  return {
    type: GetAllBranchList.REQUEST,
    payload,
  };
};
export const getAllBranchHeader = (payload) => {
  return {
    type: GetAllBranchHeader.REQUEST,
    payload,
  };
};
export const getCompanyBaseCurrency = (payload) => {
  return {
    type: GetCompanyBaseCurrency.REQUEST,
    payload,
  };
};

export const getBranchID = (payload) => {
  return {
    type: GetBranchID.REQUEST,
    payload,
  };
};

export const switchBrach = (payload) => {
  return {
    type: SwitchBrach.REQUEST,
    payload,
  };
};
export const switchBrachRuby = (payload) => {
  return {
    type: SwitchBrachRuby.REQUEST,
    payload,
  };
};

export const getCardVenders = (payload) => {
  return {
    type: GetCardVenders.REQUEST,
    payload,
  };
};

// permission portal
export const getportalPermissions = (payload) => {
  return {
    type: GetPortalPermissions.REQUEST,
    payload,
  };
};
