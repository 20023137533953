import { Button, Col, message, Modal, Row, Space } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  default as React
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { get_cmp_accounts } from "../../actions/accounts/accounts";
import { fetchBillingDetailsById } from "../../actions/bills/bills";
import { get_currencies } from "../../actions/masterdata/masterdata";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import { convertCurrency, uploadFile } from "../../utils";
import { FormInput, FormSelect } from "../inputs";
import FormRadio from "../inputs/FormRadio";
import UploadField from "../inputs/UploadField";
import { useTranslation } from "react-i18next";
import { getCompanyBaseCurrency } from "../../actions/companySettings";

const PayViaAcc = ({ open, onClose, id, amount, options, value, benDetails, invoiceInfo, type }) => {
  const dispatch = useDispatch();
  const companyBaseCurrency = useSelector((state) => state?.companySetting?.getCompanyBaseCurrency?.data)
  const [listFiles, setListFiles] = useState([]);
  const currencies = useSelector((state) =>
    state?.currencies?.data?.response?.base_currencies?.length
      ? state?.currencies?.data?.response?.base_currencies?.map((ele) => ({
        label: ele.iso_code,
        value: ele.iso_code,
      }))
      : []
  );
  const { onCall: onPaymentReq, loading } = usePayAxios({
    api: apis.initiatePaymentRequest,
    method: "post",
  })
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues
  } = useForm({
    defaultValues: {
      // beneficiary_id: "",
      debit_account: "",
      currency: "",
      amount_paid: amount,
      payment_date: "",
      payment_mode: "Cash",
      paid_through: "Petty Cash",
      reference_number: "",
      comments: [],
    },
    shouldUseNativeValidation: false,
  });
  const { t } = useTranslation();

  const onSubmit = (data) => {
    data.billing_id = id
    data.amount_paid = parseFloat(data.amount_paid)
    const { live_exchange_rates, manual_exchange_rates } = companyBaseCurrency?.data;

    data.comments = [{
      body: data.notes
    }]
    data.currency = data.currency
    delete data.notes

    onPaymentReq({
      data: {
        endToEndId: "",
        paymentAmount: { currency: "BHD", value: data.amount_paid },
        reference: { label: "Bill Number", value: id },
        bankAccountDetails: {
          fromAccount: data.debit_account,
          toAccount: benDetails.id
        },
        bc_amount_paid: convertCurrency(amount, companyBaseCurrency?.data) ?? '',
        exchange_rate: live_exchange_rates?.rate || manual_exchange_rates?.rate || "",
        currency_datetime: invoiceInfo?.currency_datetime,
        company_currency_id: invoiceInfo?.company_currency_id,
      },
      params: {
        customerUserId: localStorage.getItem("user_id")
      }
    }).then((res) => {
      if (!res.error) {
        message.success(<span className="messageText">{t('receipt_creation')}</span>)
        onClose()
        reset()
        if (listFiles.length) {
          const formData = new FormData();
          Array.from(listFiles).forEach((ele) => {
            formData.append("attachments[]", ele);
          });
          formData.append("record_type", "invoice_receipt");
          formData.append("id", res?.data);
          uploadFile(formData);
        }
        dispatch(fetchBillingDetailsById({ billing_id: id, module_type: type }));
      }
    }).catch(err => {
      message.error(<span className="messageText">{t('receipt_failed')} {err?.response?.data?.message}</span>)
    })
  }

  useEffect(() => {
    dispatch(get_currencies());
    dispatch(get_cmp_accounts())
    watch("amount_paid");
    watch("debit_account");
  }, [])

  useLayoutEffect(() => {
    setValue("amount_paid", amount)
    setValue("currency", invoiceInfo?.currency_iso)
    if (invoiceInfo?.company_currency_id)
      dispatch(getCompanyBaseCurrency({ company_currency_id: invoiceInfo?.company_currency_id }))
  }, [amount])

  useEffect(() => {
    setValue("debit_account", value)
  }, [value])

  return (
    <>
      <Modal
        onCancel={onClose}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">{t('pay_via_corporate')}</span>
            </div>
          </div>
        }
        visible={open}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="p1">
                {invoiceInfo?.company_currency_id ?
                  <div className="justify-center" style={{ alignItems: 'baseline', width: '95%' }}>
                    <Col span={14}>
                      <h5>Currency Exchange Rate:</h5>
                    </Col>
                    <Col>
                      <p key={''} style={{ fontSize: '14px', color: '#333', fontWeight: '600' }}>
                        1 {invoiceInfo?.currency_iso} = {convertCurrency(1, companyBaseCurrency?.data)} {companyBaseCurrency?.data?.base_currency}
                        {/* 1 {exchange?.convert_currency} = {convertCurrency(1, exchange)} {exchange?.base_currency} */}
                      </p>
                    </Col>
                  </div> : null}
                <div className="pp-form-item">
                  <FormRadio options={[{ label: "IMPS" }, { label: "NEFT" }, { label: "RTGS" }, { label: "International (Swift)" }]} control={control} {...register('type')} errors={errors} label="Fund Transfer Type" />
                  <div><span className="subdesc ">{t('incorrect_due')}</span></div>
                </div>
                <div className="pp-form-item">
                  <FormSelect
                    inline
                    control={control}
                    {...register('debit_account', { required: true })}
                    required
                    errors={errors}
                    label={t('debit_account')}
                    placeholder={t('select_debit_account')}
                    hideCreateOption={true}
                    options={options}
                  />
                </div>

                <div style={{ backgroundColor: '#F5F7FA', padding: '30px 27px', marginTop: '20px', fontSize: '17px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: '600', fontSize: '17px', lineHeight: '26px', color: '#000000' }}>
                      {t('benificiery_details')}
                    </span>
                  </div>

                  <Col style={{ paddingTop: '10px' }}>
                    <div>
                      {t('name')} - {benDetails?.name}
                      <br />
                      {t('email')} - {benDetails?.email}
                      <br />
                      {t('ac_num')} - {benDetails?.bank?.account_number}
                      <br />
                      {t('ifsc')} - {benDetails?.bank?.ifsc_code}
                      <br />
                    </div>
                  </Col>
                </div>
                <div><span className="subdesc">{t('incorrect_due')}</span></div>
                <div className="pp-form-item">
                  <Row align={"bottom"} justify={"space-between"} gutter={16}>
                    <Col span={8}>
                      <FormSelect
                        inline
                        control={control}
                        {...register("currency")}
                        errors={errors}
                        required
                        label={t('Amount')}
                        options={currencies}
                        disabled={invoiceInfo?.currency_iso}
                      />
                    </Col>

                    <Col span={16}>
                      <FormInput
                        control={control}
                        {...register("amount_paid")}
                        errors={errors}
                        label=""
                        placeholder={t('enter_amount')}
                        errorMessage={getValues("amount_paid") > amount ? t('less_amount') : ""}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("notes")}
                    errors={errors}
                    label={t('desc')}
                  />
                </div>

                <div style={{ marginTop: "40px" }}>
                  <UploadField
                    title={t('attach_payments')}
                    desc={t('material_desc')}
                    subDescripcion={
                      t('desc_sub')
                    }
                    acceptType={".jpg,.pdf,.png"}
                    onSelected={(fileInfo) => {
                      let listAux = [...listFiles];
                      listAux.push(fileInfo);
                      setListFiles(listAux);
                    }}
                    filesList={listFiles}
                    onRemove={(index) => {
                      let listAux = [...listFiles];
                      listAux.splice(index, 1);
                      setListFiles(listAux);
                    }}
                  />
                </div>
              </div>
            </div>

            <Space
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "6rem 47px 6rem 0px",
                justifyContent: "end",
              }}
            >
              <Button
                className="pp-secondary-button"
                style={{ marginLeft: "2rem" }}
                onClick={onClose}
              >
                <span>{t('cancel')}</span>
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
                className="pp-main-button"
              >
                <span>{t('save')}</span>
              </Button>
            </Space>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default PayViaAcc;
