import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { getSymbol } from '../../../../config/helper';
import { amountFormat } from '../../../../utils';
import moment from 'moment';

const MISExpenses = ({ data, columnsKeys }) => {
	const styles = StyleSheet.create({
		page: {
			paddingTop: 60,
			paddingLeft: 10,
			paddingRight: 10,
			lineHeight: 1.5,
			flexDirection: 'column',
		},
		mainContainer: {
			width: '100%',
			flexDirection: "column",
		},
		Headercontainer: {
			flexDirection: 'row',
			backgroundColor: '#E4E4E4',
			alignItems: 'center',
			fontStyle: 'bold',
			paddingBottom: '5px',
			paddingTop: '10px',
			paddingLeft: '15px'
		},
		text: {
			width: "11%",
			marginRight: "5px",
			fontSize: "7px",
			color: "#333333"
		},
		Datacontainer: {
			flexDirection: 'row',
			alignItems: 'center',
			fontStyle: 'bold',
			paddingBottom: '5px',
			paddingTop: '10px',
			paddingLeft: '15px',
			border: "1px solid #E4E4E4",
			borderRadius: '5px',
			width: '100%',
			marginTop: '5px',
		},
		data: {
			width: "11%",
			marginRight: "5px",
			fontSize: "7px",
			color: "#333333",
			textTransform: "capitalize"
		},
	});
	const custom_fields = data?.[0]?.custom_fields?.map(ele => ele.key) ?? []
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.mainContainer}>
					<View style={styles.Headercontainer}>
						{
							columnsKeys?.map((item, index) => {
								return <Text key={index} style={styles.text}>{item?.label}</Text>
							})
						}
						{custom_fields?.map(ele =>
							<Text style={styles.data}>{ele?.replace("_", " ")}</Text>
						)}
					</View>
					{
						data?.map((items, index) => {
							return (
								<View style={styles.Datacontainer}>
									{columnsKeys?.map(colKey => {
										if(colKey?.subKey){
											if(colKey?.type === "date"){
												return <Text style={styles.data}>{items?.[colKey?.subKey]?.[0]?.[colKey?.key] ? moment(items?.[colKey?.subKey]?.[0]?.[colKey?.key]).format("DD MMM, YYYY ") : "-"}</Text>
											}if(colKey?.type === "amount"){
												return <Text style={styles.data}>{items?.[colKey?.subKey]?.[0]?.[colKey?.key] ? `${getSymbol()} ${amountFormat(items?.[colKey?.subKey]?.[0]?.[colKey?.key])}`  : "-"}</Text>
											}else{
												return <Text style={styles.data}>{items?.[colKey?.subKey]?.[0]?.[colKey?.key] ? items?.[colKey?.subKey]?.[0]?.[colKey?.key] : "-"}</Text>
											}
										}else{
											if(colKey?.type === "date"){
												return <Text style={styles.data}>{items?.[colKey?.key] ? moment(items?.[colKey?.key]).format("DD MMM, YYYY ") : "-"}</Text>
											}if(colKey?.type === "amount"){
												return <Text style={styles.data}>{items?.[colKey?.key] ? `${getSymbol()} ${amountFormat(items?.[colKey?.key])}`  : "-"}</Text>
											}else{
												return <Text style={styles.data}>{items?.[colKey?.key] ? items?.[colKey?.key] : "-"}</Text>
											}
										}
									})}
									{custom_fields?.map(ele =>
										<Text style={styles.data}>{items?.custom_fields?.find(itm => itm.key === ele)?.value ?? "-"}</Text>
									)}
								</View>

							)
						})
					}
				</View>
			</Page>
		</Document>
	)
}

export default MISExpenses;