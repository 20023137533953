import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions, sagaFunctionsOms } from "../../utils";
import {
  CustomerAddToCart,
  CustomerBillingAddresses,
  CustomerCart,
  CustomerCreateOrder,
  CustomerDeleteCart,
  CustomerGetOrderComments,
  CustomerLogin,
  CustomerLogout,
  CustomerMyOrders,
  CustomerOrderComments,
  CustomerOrders,
  CustomerOrdersApprove,
  CustomerOrdersCancel,
  CustomerOrdersDestroy,
  CustomerOrdersReject,
  CustomerOrdersShow,
  CustomerOrdersSubmit,
  CustomerOrdersUpdate,
  CustomerOrdersUpdateQuantity,
  CustomerProduct,
  CustomerProducts,
  CustomerProfile,
  CustomerRemoveCartItem,
  CustomerShippingAddresses,
  CustomerUpdateItemQuantity,
  CustomerAddAddresses,
} from "./orders";

function customerLogin(req) {
  return sagaFunctionsOms(CustomerLogin, "post", apis.customer_login, req.payload)()
}

function customerLogout(req) {
  return sagaFunctionsOms(CustomerLogout, "post", apis.customer_logout, req.payload)();
}

function customerProducts(req) {
  return sagaFunctionsOms(CustomerProducts, "get", apis.customer_products, req.payload)();
}

function customerProduct(req) {
  return sagaFunctionsOms(CustomerProduct, "get", apis.customer_product, req.payload)();
}

function customerProfile(req) {
  return sagaFunctionsOms(CustomerProfile, "get", apis.customer_profile, req.payload)();
}

function customerAddToCart(req) {
  return sagaFunctionsOms(CustomerAddToCart, "post", apis.customer_add_to_cart, req.payload)();
}

function customerCart(req) {
  return sagaFunctionsOms(CustomerCart, "get", apis.customer_cart, req.payload)();
}

function customerDeleteCart(req) {
  return sagaFunctionsOms(CustomerDeleteCart, "delete", apis.customer_delete_cart, req.payload)();
}

function customerRemoveCartItem(req) {
  return sagaFunctionsOms(CustomerRemoveCartItem, "post", apis.customer_remove_cart_item, req.payload)();
}

function customerUpdateItemQuantity(req) {
  return sagaFunctionsOms(CustomerUpdateItemQuantity, "post", apis.customer_update_item_quantity, req.payload)();
}

function customerOrders(req) {
  return sagaFunctionsOms(CustomerOrders, "get", apis.customer_orders, req.payload)();
}

function customerCreateOrder(req) {
  return sagaFunctionsOms(CustomerCreateOrder, "post", apis.customer_orders, req.payload)();
}

function customerOrdersShow(req) {
  return sagaFunctionsOms(CustomerOrdersShow, "get", apis.customer_orders_show, req.payload)();
}

function customerMyOrders(req) {
  let order = req.payload.fromOrder
  delete req.payload.fromOrder
  if (order)
    return sagaFunctionsOms(CustomerMyOrders, "get", apis.customer_my_orders, req.payload)();
  else
    return sagaFunctionsOms(CustomerMyOrders, "get", apis.all_orders, req.payload)();
}

function customerOrdersUpdate(req) {
  return sagaFunctionsOms(CustomerOrdersUpdate, "put", apis.customer_orders_update, req.payload)();
}

function customerOrderComments(req) {
  return sagaFunctionsOms(CustomerOrderComments, "post", apis.order_comments, req.payload)();
}

function customerGetOrderComments(req) {
  return sagaFunctionsOms(CustomerGetOrderComments, "get", apis.order_comments, req.payload)();
}

function customerOrdersApprove(req) {
  return sagaFunctionsOms(CustomerOrdersApprove, "post", apis.approve_order, req.payload)();
}

function customerOrdersReject(req) {
  return sagaFunctionsOms(CustomerOrdersReject, "post", apis.reject_order, req.payload)();
}

function customerOrdersSubmit(req) {
  return sagaFunctionsOms(CustomerOrdersSubmit, "post", apis.customer_orders_submit, req.payload)();
}

function customerOrdersCancel(req) {
  return sagaFunctionsOms(CustomerOrdersCancel, "post", apis.customer_orders_cancel, req.payload)();
}

function customerOrdersUpdateQuantity(req) {
  return sagaFunctionsOms(CustomerOrdersUpdateQuantity, "put", apis.customer_orders_update_quantity, req.payload)();
}

function customerOrdersDestroy(req) {
  return sagaFunctionsOms(CustomerOrdersDestroy, "delete", apis.customer_orders_destroy, req.payload)();
}

function customerShippingAddresses(req) {
  return sagaFunctionsOms(CustomerShippingAddresses, "get", apis.customer_shipping_addresses, req.payload)();
}

function customerBillingAddresses(req) {
  return sagaFunctionsOms(CustomerBillingAddresses, "get", apis.customer_billing_addresses, req.payload)();
}

function customerAddAddresses(req) {
  return sagaFunctionsOms(CustomerAddAddresses, "post", apis.customerAddAddressAPI, req.payload)();
}


export function* ordersWatcher() {
  yield takeLatest(CustomerLogin.REQUEST, customerLogin);
  yield takeLatest(CustomerLogout.REQUEST, customerLogout);
  yield takeLatest(CustomerProducts.REQUEST, customerProducts);
  yield takeLatest(CustomerProduct.REQUEST, customerProduct);
  yield takeLatest(CustomerProfile.REQUEST, customerProfile);
  yield takeLatest(CustomerAddToCart.REQUEST, customerAddToCart);
  yield takeLatest(CustomerCart.REQUEST, customerCart);
  yield takeLatest(CustomerDeleteCart.REQUEST, customerDeleteCart);
  yield takeLatest(CustomerRemoveCartItem.REQUEST, customerRemoveCartItem);
  yield takeLatest(CustomerUpdateItemQuantity.REQUEST, customerUpdateItemQuantity);
  yield takeLatest(CustomerOrders.REQUEST, customerOrders);
  yield takeLatest(CustomerOrdersShow.REQUEST, customerOrdersShow);
  yield takeLatest(CustomerMyOrders.REQUEST, customerMyOrders);
  yield takeLatest(CustomerOrdersUpdate.REQUEST, customerOrdersUpdate);
  yield takeLatest(CustomerOrdersSubmit.REQUEST, customerOrdersSubmit);
  yield takeLatest(CustomerOrdersCancel.REQUEST, customerOrdersCancel);
  yield takeLatest(CustomerOrdersUpdateQuantity.REQUEST, customerOrdersUpdateQuantity);
  yield takeLatest(CustomerOrdersDestroy.REQUEST, customerOrdersDestroy);
  yield takeLatest(CustomerShippingAddresses.REQUEST, customerShippingAddresses);
  yield takeLatest(CustomerBillingAddresses.REQUEST, customerBillingAddresses);
  yield takeLatest(CustomerCreateOrder.REQUEST, customerCreateOrder);
  yield takeLatest(CustomerOrdersApprove.REQUEST, customerOrdersApprove);
  yield takeLatest(CustomerOrdersReject.REQUEST, customerOrdersReject);
  yield takeLatest(CustomerOrderComments.REQUEST, customerOrderComments);
  yield takeLatest(CustomerGetOrderComments.REQUEST, customerGetOrderComments);
  yield takeLatest(CustomerAddAddresses.REQUEST, customerAddAddresses);
}
