import { DownOutlined, MessageOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Steps,
  Timeline,
  message,
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import {
  ExpenseCustomDetails,
  expenseCustomDetails,
} from "../../actions/expenses/expenses";
import { SideMenu, headerTitles } from "../../actions/masterdata/masterdata";
import { ChangeReports, reports } from "../../actions/reports";
import Car from "../../assets/icons/car.png";
import Close from "../../assets/icons/close.png";
import excel_icon from "../../assets/icons/excel1.png";
import Flight from "../../assets/icons/flight.png";
import Hotel from "../../assets/icons/hotel.png";
import pdf_icon from "../../assets/icons/pdf_icon.png";
import ppt_icon from "../../assets/icons/ppticon.png";
import tiff_icon from "../../assets/icons/tifficon.png";
import { apis } from "../../config/APIs";
import endpointPdfApi from "../../config/AxiosPdf";
import { getBoolVal, hasAccess, isSuperAdmin } from "../../utils";
import NoCommentsComponent from "../Common/NoComentsComponent";
import NodataComponent from "../Common/NodataComponent";
import "../Reports/new-report.css";
import {
  RecallTrip,
  TripsAddComments,
  TripsApprove,
  TripsClose,
  TripsComments,
  TripsDelete,
  TripsReject,
  TripsShow,
  UserReportLinkTrip,
  UserTripsSubmit,
  recallTrip,
  tripsAddComments,
  tripsApprove,
  tripsDelete,
  tripsReject,
  trips_close,
  trips_show,
  userReportLinkTripReset,
  userTripsSubmit,
} from "./../../actions/trips/trips";
import AdvReport from "./../Advances/AdvReport";
import LinkTripsToReport from "./LinkTripsToReport";
import TripTypeTable from "./TripTypeTable";
import usePdfApi from "../../config/usePdfApi";
const { Step } = Steps;
const { TextArea } = Input;
export const labels = {
  covered: "closed",
};

const TripDetail = ({ }) => {
  const location = useLocation();
  const hasWriteAccess = location?.state?.user
    ? true
    : hasAccess("trips_approvals", "write");
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;
  const inputComment = React.useRef(null);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  // const [tripsCommentsData, setTripsCommentsData] = React.useState([]);
  const [input, setInput] = React.useState("");
  const [tab, setTab] = React.useState("details");
  const [reasonValue, setReasonValue] = React.useState("");
  const tripData = useSelector(
    (state) => state.trips.tripsShow?.data?.data || {}
  );

  const { t } = useTranslation();
  const tripsCommentsAddResponse = useSelector(
    (state) => state.trips.tripsCommentsAdd || {}
  );

  const changereportResponse = useSelector(
    (state) => state.reports.changeReports || {}
  );

  const recallTripResponse = useSelector(
    (state) => state.trips.tripsRecall || {}
  );

  const tripsApproveResponse = useSelector(
    (state) => state.trips.tripsApprove || {}
  );
  const tripsRejectResponse = useSelector(
    (state) => state.trips.tripsReject || {}
  );

  const tripsDeleteResponse = useSelector(
    (state) => state.trips.tripsDelete || {}
  );
  const userTripsSubmitResponse = useSelector(
    (state) => state.trips.userTripsSubmit || {}
  );
  const userReportLinkTripResponse = useSelector(
    (state) => state.trips.userReportLinkTrips || {}
  );
  const tripRecallResponse = useSelector(
    (state) => state?.trips?.tripsRecall || {}
  );
  const tripsClose = useSelector((state) => state.trips.tripsClose || {});
  // const tripPDF = useSelector((state) => state.trips.tripDetailPDF?.data?.response || {});
  const tripsData = useSelector(
    (state) => state.trips.tripsShow?.data?.data || {}
  );
  const tripCustomData = useSelector(
    (state) => state?.expenses?.expenseCustomDetails?.data?.data || []
  );
  const {
    onCall: tripPdfUrl,
    loading: pdfLoading,
    pdfUrl: trip_detail_pdf,
  } = usePdfApi({
    api: apis.trip_pdf,
    key: "trip_url",
  });
  const callOnAction = () => {
    dispatch(trips_show({ trip_id: id, module_type: "TRIP" }));
    dispatch(
      expenseCustomDetails({
        module_type: "TRIP",
        module_id: id,
        custom_flag: true,
      })
    );
    // dispatch(
    //   tripsHistory({ trip_id: id }, { is_admin: !location?.state?.user })
    // );
  };

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      tripRecallResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      handleCancel();
      dispatch({
        type: RecallTrip.RESET,
      });
      dispatch(trips_show({ trip_id: id, module_type: "TRIP" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripRecallResponse]);

  React.useEffect(() => {
    if (id) {
      callOnAction();
      tripPdfUrl({
        params: {
          id: params.id,
        },
      });
    }
    dispatch(
      headerTitles({
        title: "Trip Details",
        description: "See your trip details here.",
      })
    );
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/expenses",
        childrenMenu: location?.state?.user ? "/my-trips" : "",
        showChildren: true,
        secondNav: "/trips",
      },
    });
    return () => {
      dispatch(userReportLinkTripReset());
      dispatch({
        type: TripsShow.RESET,
      });
      dispatch({
        type: ExpenseCustomDetails.RESET,
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = changereportResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      handleCancel();
      dispatch({
        type: ChangeReports.RESET,
      });
      history.push("/expenses/trips/my-trips");
    }
    if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: ChangeReports.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changereportResponse]);

  React.useEffect(() => {
    const { data: { message = "", error = "" } = {} } = recallTripResponse;
    if (error === false) {
      dispatch(trips_show({ trip_id: id, module_type: "TRIP" }));
    }
  }, [recallTripResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      userReportLinkTripResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      handleCancel();
      dispatch({
        type: UserReportLinkTrip.RESET,
      });
      dispatch(trips_show({ trip_id: id, module_type: "TRIP" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkTripResponse]);

  const { history: tripHistory = [] } = tripsData;
  const { comments: tripsCommentsData = [] } = tripsData;
  // const { data: { response: { history: tripHistory = [] } = {} } = {} } =
  //   tripsHistoryResponse;

  // React.useEffect(() => {
  //   const { data: { response: { comments = [] } = {}, status = false } = {} } =
  //     tripsCommentsResponse;
  //   if (status) {
  //     setTripsCommentsData(comments);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tripsCommentsResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      tripsCommentsAddResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(
        trips_show(
          { trip_id: tripsData.id, module_type: "TRIP", view: "admin" }
          // { is_admin: !location?.state?.user }
        )
      );
      setInput("");
      dispatch({ type: TripsComments.RESET });
      dispatch({ type: TripsAddComments.RESET });
    } else if (res_message) {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
      dispatch({ type: TripsComments.RESET });
      dispatch({ type: TripsAddComments.RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripsCommentsAddResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      tripsApproveResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      callOnAction();
      dispatch({
        type: TripsApprove.RESET,
      });
    } else {
      if (res_message) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: TripsApprove.RESET,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripsApproveResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      tripsRejectResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      callOnAction();
      if (modalType === "delete") {
        history.goBack();
      }
      setIsModalVisible(false);
      setReasonValue("");
      dispatch({
        type: TripsReject.RESET,
      });
    } else {
      if (res_message) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: TripsApprove.RESET,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripsRejectResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      tripsDeleteResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      // callOnAction();
      if (modalType === "delete") {
        history.goBack();
      }
      setIsModalVisible(false);
      setReasonValue("");
      dispatch({
        type: TripsDelete.RESET,
      });
    } else {
      if (res_message) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: TripsApprove.RESET,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripsDeleteResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      tripsClose;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch({
        type: TripsClose.RESET,
      });
      history.push("/expenses/trips/my-trips");
    } else {
      if (res_message && status === false) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: TripsClose.RESET,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripsClose]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      userTripsSubmitResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      callOnAction();
      dispatch({
        type: UserTripsSubmit.RESET,
      });
      // dispatch(trips({ trip_id: tripsData.id }));
    } else {
      if (res_message) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: UserTripsSubmit.RESET,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTripsSubmitResponse]);

  const handleAddComment = () => {
    if (input?.trim() && tripsData.id) {
      dispatch(
        tripsAddComments(
          {
            body: input,
            commentable_id: tripsData.id,
            commentable_type: "trip",
          },
          { is_admin: !location?.state?.user }
        )
      );
      setInput("");
    } else {
      message.error(
        <span className="messageText">{t("comments_required")}</span>
      );
    }
  };

  const renderUnrepOption = () => {
    return (
      <>
        {hasWriteAccess && (
          <LinkTripsToReport trip_id={tripData.id} visible={isModalVisible} />
        )}
      </>
    );
  };

  const renderModalData = (type) => {
    switch (type) {
      case "delete":
        return renderDeleteOption();
      case "reject":
        return renderRejectOption();
      case "addUnrepExp":
        return renderUnrepOption();
      default:
        return "";
    }
  };

  const renderModalTitle = (type) => {
    switch (type) {
      case "delete":
        return t("delete_trip");
      case "reject":
        return t("reject_trip");
      case "addUnrepExp":
        return t("add_trip_report");
      default:
        return "";
    }
  };

  const renderModalClass = (type) => {
    switch (type) {
      case "delete":
        return "center-aligned-modal";
      case "reject":
        return "center-aligned-modal";
      case "addUnrepExp":
        return "right-alinged-modal";
      default:
        return "";
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setReasonValue("");
  };

  const handleDownloadClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const renderDeleteOption = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            tripsDelete({ id: tripsData.id, delete_reason: reasonValue })
          );
        }}
      >
        <Text>{t("delete_reason")}</Text>
        <br />
        <TextArea
          rows={4}
          placeholder="Enter Reason"
          className="reasonTextArea"
          onChange={(e) => setReasonValue(e.target.value)}
          required={true}
        />
        <br />
        <Button
          key="1"
          className="formButton mt1"
          disabled={!reasonValue || tripsRejectResponse?.loading}
          htmlType="submit"
        >
          {t("confirm")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          htmlType="button"
          onClick={() => handleCancel()}
        >
          {t("cancel")}
        </Button>
      </form>
    );
  };

  const renderRejectOption = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            tripsReject({ id: tripsData.id, reject_reason: reasonValue })
          );
        }}
      >
        <Text>{t("rejection_reason")}</Text>
        <br />
        <TextArea
          rows={4}
          placeholder={t("enter_reason")}
          className="reasonTextArea"
          onChange={(e) => setReasonValue(e.target.value)}
          required={true}
        />
        <br />
        <Button
          key="1"
          className="formButton mt1"
          disabled={!reasonValue || tripsRejectResponse?.loading}
          htmlType="submit"
        >
          {t("confirm")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          htmlType="button"
          onClick={() => handleCancel()}
        >
          {t("cancel")}
        </Button>
      </form>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        disabled={!hasWriteAccess}
        title={!hasWriteAccess ? "You don't have permission for this trip" : ""}
        onClick={() => {
          if (hasWriteAccess && trip_detail_pdf) {
            window.open(trip_detail_pdf, "_blank");
          } else if (pdfLoading) {
            message.warn(<span className="messageText">PDF is loading.</span>);
          } else {
            message.error(
              <span className="messageText">PDF not available</span>
            );
          }
        }}
      >
        {t("download_pdf")}
      </Menu.Item>
      {/* <Menu.Item key="2">Download Receipts</Menu.Item> */}

      {/* <Menu.Item key="4">Print</Menu.Item>  */}
    </Menu>
  );

  const secs = [
    "Details",
    "Reports",
    "Trip Info",
    // "Flight Reservation",
    // "Hotel Reservation",
    // "Car Rental",
    "Others",
    "Comments",
    "History",
  ];

  return (
    <>
      <Modal
        title={renderModalTitle(modalType)}
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={renderModalClass(modalType)}
      >
        {renderModalData(modalType)}
      </Modal>
      {tripsData?.reject_reason && (
        <div className="strip red">
          {t("reason_reject")}: {tripsData?.reject_reason}
        </div>
      )}
      <div className="report-btns">
        {!location?.state?.user ? (
          <>
            {(!tripsData?.action_restrict || isSuperAdmin()) &&
              tripsData.role_status === "pending_approval" &&
              tripData.is_editable === true ? (
              <>
                <button
                  className="approve"
                  disabled={tripsApproveResponse?.loading || !hasWriteAccess}
                  onClick={() => {
                    dispatch(tripsApprove({ id: tripsData.id }));
                  }}
                >
                  {t("approve")}
                </button>
                <button
                  className="reject"
                  disabled={!hasWriteAccess}
                  onClick={() => {
                    setIsModalVisible(true);
                    setModalType("reject");
                  }}
                >
                  {t("reject")}
                </button>
              </>
            ) : null}{" "}
          </>
        ) : (
          <>
            {tripsData.status === "unsubmitted" ? (
              <>
                <button
                  className="approve"
                  disabled={userTripsSubmitResponse?.loading || !hasWriteAccess}
                  onClick={() =>
                    dispatch(userTripsSubmit({ id: tripsData.id }))
                  }
                >
                  {t("submit")}
                </button>
                <button
                  disabled={!hasWriteAccess}
                  className="reject"
                  onClick={() =>
                    history.push({
                      pathname: `/expenses/add-trip/`,
                      state: { tripData: tripsData },
                    })
                  }
                >
                  {t("edit")}
                </button>
                {location?.state?.user && (
                  <>
                    <button
                      disabled={!hasWriteAccess}
                      onClick={() => {
                        setIsModalVisible(true);
                        setModalType("delete");
                      }}
                      // onClick={() => handleAddExp()}
                      className="delete"
                    >
                      {t("delete")}
                    </button>
                    <button
                      className="reject"
                      disabled={!hasWriteAccess}
                      onClick={() =>
                        dispatch(
                          recallTrip({
                            id: params.id,
                            isCancel: true,
                            params: { module_type: "TRIP" },
                          })
                        )
                      }
                    >
                      Cancel
                    </button>
                  </>
                )}
              </>
            ) : null}
            {tripsData?.is_recallable &&
              tripsData?.status === "pending_approval" ? (
              <>
                <button
                  className="reject"
                  disabled={tripRecallResponse?.loading || !hasWriteAccess}
                  onClick={() =>
                    dispatch(
                      recallTrip({
                        id: params.id,
                        params: { module_type: "TRIP" },
                      })
                    )
                  }
                >
                  {t("recall")}
                </button>
              </>
            ) : null}
          </>
        )}
        {console.log(
          "new trip details location?.state?.user",
          location?.state?.user,
          tripsData?.reports?.status,
          tripsData?.status
        )}
        {location?.state?.user &&
          (tripsData?.status === "approved" ||
            tripsData?.status === "closed") && (
            <>
              {/* {tripsData?.reports?.status !== "unsubmitted" ? null : (
                <button
                  disabled={!hasWriteAccess}
                  onClick={() => {
                    setIsModalVisible(true);
                    setModalType("addUnrepExp");
                    // dispatch(userReports({ status: "unsubmitted" }));
                    dispatch(
                      reports({
                        module_type: "REPORT",
                        filters: { status: ["unsubmitted"] },
                      })
                    );
                  }}
                  // onClick={() => handleAddExp()}
                  className="pp-main-button"
                >
                  <span>
                    {tripsData?.reports ? t("change") : t("add_to")} Report
                  </span>
                </button>
              )} */}
              <button
                disabled={!hasWriteAccess}
                onClick={() => {
                  setIsModalVisible(true);
                  setModalType("addUnrepExp");
                  // dispatch(userReports({ status: "unsubmitted" }));
                  dispatch(
                    reports({
                      module_type: "REPORT",
                      filters: { status: ["unsubmitted"] },
                    })
                  );
                }}
                // onClick={() => handleAddExp()}
                className="pp-main-button"
              >
                <span>
                  {tripsData?.reports ? t("change") : t("add_to")} Report
                </span>
              </button>
              {tripsData.status !== "closed" && (
                <button
                  className="approve"
                  disabled={tripsClose?.loading || !hasWriteAccess}
                  onClick={() => {
                    dispatch(
                      trips_close({
                        id: tripsData.id,
                        params: { module_type: "TRIP" },
                      })
                    );
                  }}
                >
                  {t("mark_as_close")}
                </button>
              )}
            </>
          )}
        <Dropdown overlay={menu} placement="bottomRight">
          <button className="more">
            {t("more")}&nbsp;
            <DownOutlined />
          </button>
        </Dropdown>
        <span
          className="close cursor"
          onClick={() =>
            history.push({
              pathname: location.state?.from,
              state: { filter: location?.state?.filter },
            })
          }
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div>
      <div className="new-report-detail">
        <div className="flex-between">
          <div className="report-head">
            <div className="title-sec">
              <h3>{`${tripsData?.trip_number
                ? "[" + tripsData?.trip_number + "] "
                : ""
                } ${tripsData?.title}`}</h3>
              <span
                className={`status status_${location?.state?.user
                  ? tripsData?.status
                  : tripsData?.role_status
                  }`}
              // className={`status status_${is_admin ? tripsData?.status : (labels[tripsData?.status] ? labels[tripsData?.status] : tripsData?.status)}`}
              >
                {/* {is_admin ? tripData?.status?.replace(/_/g, " ") : (labels[tripsData?.status] ? labels[tripsData?.status]?.replace(/_/g, " ") : tripsData?.status?.replace(/_/g, " "))} */}
                {location?.state?.user
                  ? tripsData?.status?.replace?.(/_/g, " ")
                  : tripsData?.role_status?.replace?.(/_/g, " ")}
              </span>
            </div>
            <p className="duration"></p>
            <p>
              <strong>
                {t("by_submitted")}
                {tripsData?.submitted_by?.name} <br />
                <span className="sm">
                  on{" "}
                  {tripsData?.submitted_date &&
                    moment(tripsData?.submitted_date).format(
                      "DD MMM YYYY, HH:mm a"
                    )}
                </span>
                <br />
                <span className="sm">
                  {t("to")}{" "}
                  {tripsData?.submitted_to?.email ||
                    tripsData?.submitted_to?.name}
                </span>
              </strong>
            </p>
          </div>
        </div>
        <div className="tabs-sec">
          <ul>
            {secs.map((ele) => (
              <li
                onClick={() => setTab(ele.toLowerCase())}
                className={`${tab.toLowerCase() === ele.toLowerCase() ? "active" : ""
                  }`}
              >
                {ele}
              </li>
            ))}
          </ul>
        </div>
        <div className="selected-tab">
          {tab === "reports" ? (
            tripsData && tripsData.reports ? (
              <AdvReport
                repData={tripsData.reports}
                from="report"
                user={location?.state?.user}
              />
            ) : (
              <NodataComponent
                primaryText={t("add_to_report")}
                noBtns={!location?.state?.user}
                noSecBtn={true}
                noPrimaryBtn={
                  tripData?.status !== "approved" &&
                  tripData?.status !== "closed"
                }
                hasWriteAccess={hasWriteAccess}
                onPrimaryClick={() => {
                  if (hasWriteAccess) {
                    setIsModalVisible(true);
                    setModalType("addUnrepExp");
                    dispatch(
                      reports({
                        module_type: "REPORT",
                        params: { report_link_status: true },
                        filters: {
                          status: ["unsubmitted"],
                        },
                      })
                    );
                  }
                }}
              />
            )
          ) : null}

          {tab === "details" ? (
            <>
              <Row>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">
                      {t("by_submitted")}
                    </span>
                    <span className="details_span name">
                      {tripData?.submitted_by?.name}
                    </span>
                    <span className="details_span secondary sm">
                      {tripData?.submitted_by?.email}
                    </span>
                  </p>
                </Col>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">
                      {t("submitted_to")}
                    </span>
                    <span className="details_span name">
                      {tripData?.submitted_to?.name &&
                        tripData?.submitted_to?.name}
                    </span>
                    <span className="details_span secondary sm">
                      {tripData?.submitted_to?.email}
                    </span>
                    {/* <span className="details_span secondary sm">
                      {tripData?.submitted_date && moment(tripData?.submitted_date).format("DD MMM YYYY HH:mm:ss")}
                    </span> */}
                  </p>
                </Col>
                {tripsData?.status === "pending_approval" && (
                  <Col span={6}>
                    <p className="details_p">
                      <span className="details_span secondary">
                        {t("pendingApprovals")} :
                      </span>
                      <span className="details_span name">
                        {tripData?.pending_with?.name}
                      </span>
                      <span className="details_span secondary sm">
                        {tripData?.pending_with?.email}
                      </span>
                      {/* <button style={{ textAlign: "left", color: "#e99b39" }}>
                        View Approval Flow
                      </button> */}
                    </p>
                  </Col>
                )}
              </Row>
              <hr />
              <Row>
                {tripCustomData.map(
                  ({ key, column_name, column_type, value, attachments }) => {
                    return (
                      <>
                        <Col span={24}>
                          <p className="details_p">
                            <span className="details_span secondary">
                              {column_name.split("_").join(" ").toUpperCase()}
                            </span>
                            {column_type === "document" ? (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "auto auto auto auto",
                                  maxWidth: "40rem",
                                }}
                              >
                                {attachments?.map((eachImg) =>
                                  eachImg.content_type === "image/jpeg" ||
                                    eachImg.content_type === "image/jpg" ||
                                    eachImg.content_type === "image/png" ? (
                                    <div
                                      key={eachImg.id}
                                      onClick={() =>
                                        handleDownloadClick(
                                          eachImg.attachment_url
                                        )
                                      }
                                    >
                                      <img
                                        style={{
                                          width: "5rem",
                                          height: "5rem",
                                          margin: "1rem",
                                        }}
                                        src={eachImg.attachment_url}
                                        alt=""
                                      />
                                      <p
                                        title={eachImg.filename}
                                        className="customfield-text"
                                      >
                                        {eachImg.filename}
                                      </p>
                                      <Button
                                        style={{
                                          width: "7rem",
                                          color: "black",
                                        }}
                                        className="formButton mt1"
                                      >
                                        Download
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                      onClick={() =>
                                        handleDownloadClick(
                                          eachImg.attachment_url
                                        )
                                      }
                                    >
                                      {eachImg.content_type ===
                                        "application/pdf" ? (
                                        <img
                                          src={pdf_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : eachImg?.content_type ===
                                        "image/tiff" ? (
                                        <img
                                          src={tiff_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : eachImg?.content_type ===
                                        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                        <img
                                          src={ppt_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={excel_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      )}
                                      <p
                                        title={eachImg.filename}
                                        className="customfield-text"
                                      >
                                        {eachImg.filename}
                                      </p>
                                      <Button
                                        style={{
                                          width: "7rem",
                                          color: "black",
                                        }}
                                        className="formButton mt1"
                                      >
                                        Download
                                      </Button>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <span className="details_span name">
                                {value && value}
                              </span>
                            )}
                          </p>
                        </Col>
                      </>
                    );
                  }
                )}
              </Row>
            </>
          ) : null}

          {tab === "trip info" ? (
            tripsData ? (
              <>
                <Row justify="space-between">
                  <Col span={11}>
                    <div
                      class="details_div"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <p>
                        <span className="details_span">{t("departure")}</span>
                        {tripsData?.flight_reservations?.map(
                          ({ from_city }) => (
                            <span
                              style={{ display: "block", textAlign: "center" }}
                              className="details_span secondary"
                            >
                              {from_city}
                            </span>
                          )
                        )}
                      </p>
                      <p>
                        <span>{t("destination")}</span>
                        {tripsData?.flight_reservations?.map(({ to_city }) => (
                          <span
                            style={{ display: "block", textAlign: "center" }}
                            className="details_span secondary"
                          >
                            {to_city}
                          </span>
                        ))}
                      </p>
                    </div>
                  </Col>
                  <Col span={11}>
                    <div class="details_div">
                      <p>
                        <span>{t("business_purpose")}</span>
                        <span
                          style={{ margin: "2rem" }}
                          className="details_span secondary"
                        >
                          {tripsData?.purpose}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className="timeline_div">
                  <Timeline>
                    <Timeline.Item
                      className="timeline_item"
                      dot={
                        <img
                          alt=""
                          src={Flight}
                          style={{
                            width: "2.3rem",
                            color: "black",
                            border: "2px solid #E6E6E6",
                            padding: ".5rem",
                            background: "#E6E6E6",
                            borderRadius: "50%",
                          }}
                        />
                      }
                    >
                      <h4>{t("flight_reservation")}</h4>
                      <TripTypeTable type="flight" tripsData={tripsData} />
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <img
                          alt=""
                          src={Hotel}
                          style={{
                            width: "2.3rem",
                            color: "black",
                            border: "2px solid #E6E6E6",
                            padding: ".5rem",
                            background: "#E6E6E6",
                            borderRadius: "50%",
                          }}
                        />
                      }
                    >
                      <h4>{t("hotel_reservations")}</h4>
                      <TripTypeTable type="hotel" tripsData={tripsData} />
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <img
                          alt=""
                          src={Car}
                          style={{
                            width: "2.3rem",
                            color: "black",
                            border: "2px solid #E6E6E6",
                            padding: ".5rem",
                            background: "#E6E6E6",
                            borderRadius: "50%",
                          }}
                        />
                      }
                    >
                      <h4>{t("car_rental")}</h4>
                      <TripTypeTable type="car" tripsData={tripsData} />
                    </Timeline.Item>
                  </Timeline>
                </div>
              </>
            ) : (
              <NodataComponent
                module={t("Expenses")}
                msg={t("msg_expense")}
                noBtns={!location?.state?.user}
                hasWriteAccess={hasWriteAccess}
                onPrimaryClick={() => {
                  if (hasWriteAccess) {
                    setIsModalVisible(true);
                    setModalType("addNewExp");
                  }
                }}
                onSecondaryClick={() => {
                  if (hasWriteAccess) {
                    setIsModalVisible(true);
                    setModalType("addUnrepExp");
                  }
                }}
              />
            )
          ) : null}

          {tab === "flight reservation" ? (
            <TripTypeTable type="flight" tripsData={tripsData} />
          ) : null}
          {tab === "hotel reservation" ? (
            <TripTypeTable type="hotel" tripsData={tripsData} />
          ) : null}
          {tab === "car rental" ? (
            <TripTypeTable type="car" tripsData={tripsData} />
          ) : null}
          {tab === "others" ? (
            <TripTypeTable type="others" tripsData={tripsData} />
          ) : null}
          {tab === "comments" ? (
            <>
              <div className="addComment mt2 mb1">
                <Input
                  placeholder={t("enter_comment")}
                  ref={inputComment}
                  value={input}
                  onInput={(e) => setInput(e.target.value.trimStart())}
                />
                <Button
                  disabled={!hasWriteAccess}
                  key="1"
                  className="formButton ml1"
                  onClick={() => hasWriteAccess && handleAddComment()}
                >
                  {t("send")}
                </Button>
              </div>
              {tripsCommentsData.length > 0 ? (
                <>
                  <div className="commentsSection mt2">
                    <Steps
                      direction="vertical"
                      size="small"
                      current={tripsCommentsData.length + 1 || 100}
                    >
                      {tripsCommentsData &&
                        tripsCommentsData.map(
                          ({ body, created_at, name, id }) => (
                            <Step
                              key={id}
                              title={body}
                              description={`on ${moment(created_at).format(
                                "DD MMM YYYY HH:mm:ss"
                              )} | Commented by: ${name}`}
                              icon={<MessageOutlined />}
                            />
                          )
                        )}
                    </Steps>
                  </div>
                </>
              ) : (
                <NoCommentsComponent />
              )}
            </>
          ) : null}
          {tab === "history" ? (
            <div className="commentsSection mt2">
              {tripHistory && tripHistory.length > 0 ? (
                <Steps
                  direction="vertical"
                  size="small"
                  current={tripHistory.length + 1 || 100}
                >
                  {tripHistory.map(
                    ({ body, created_at, name, id, curr_status }) => (
                      <Step
                        key={id}
                        title={body}
                        description={
                          created_at +
                          " | " +
                          name +
                          " | Action: " +
                          curr_status
                        }
                        icon={<UserOutlined />}
                      />
                    )
                  )}
                </Steps>
              ) : (
                <NodataComponent noBtns={true} msg="No trips History" />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TripDetail;
