import React, { useState } from "react";
import { Modal, Input, Button, Form } from "antd";
import { useForm } from "react-hook-form";
import { FormInput } from "../inputs";
import usePayAxios from "../../config/useAxios";
import { apis } from "../../config/APIs";
import AddSource from "./AddSource";
import { useTranslation } from "react-i18next";
const AddCategory = ({ isVisible, onCancel, companyId }) => {
  const { onCall } = usePayAxios({ api: apis.AddCategory, method: "post" });
  const [addVisible, setAddVisible] = useState(false);
  const {t} = useTranslation()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      company_id: "",
      seed_code: "",
      seed_value: "",
      seed_type: "",
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload = {
      company_id: companyId,
      seed_code: "VBC_1",
      seed_value: data.seed_value,
      seed_type: "gl_category",
    };

    onCall({
      data: payload,
    })
      .then((data) => {
        reset();
        onCancel();
      })
      .catch((err) => err.message);
  };

  return (
    <>
      <AddSource
        isVisible={addVisible}
        onCancel={() => {
          setAddVisible(false);
        }}
      />
      <Modal title={t('payment_add_terms')} visible={isVisible} onCancel={onCancel}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("seed_value")}
                errors={errors}
                label={t('category_hash')}
                placeholder="Enter Category"
              />
            </div>
            {/* <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("number_of_days")}
                errors={errors}
                label="Number of days#"
                placeholder="Enter Number of days"
              />
            </div> */}
            <Button className="pp-secondary-button" onClick={onCancel}>
              {t('cancel')}
            </Button>
            <Button type="primary" htmlType="submit" className="pp-main-button">
              {t('submit')}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddCategory;
