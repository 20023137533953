import React, { useEffect, useState } from "react";
import { LOGO as Logo } from "../../config/Images";
import RightImg from "../../assets/images/login_img.png";
import "./auth.css";
import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { Select, Typography } from "antd";
import RegisterForDemo from "./RegisterForDemo";
import ConfirmEmail from "./ConfirmEmail";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ChangeLanguage } from "../../actions/auth";
import SignUp from "../OnBoarding/Auth/SignUp";
import TwoStepVerify from "./TwoStepVerification";
const { Text, Title } = Typography;

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const language = useSelector((state) => state.auth.localVariables?.language);
  const dispatch = useDispatch();
  const [displayLoginRight, setDisplayLoginRight] = useState(true);
  const signPage = history?.location?.pathname.includes("sign-up");
  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      !location.pathname.includes("reset-password") &&
      !location.pathname.includes("confirm_email")
    ) {
      history.push("/dashboard");
      localStorage.setItem("activeMenu", "/dashboard");
      localStorage.setItem("activeSubMenu", "");
      localStorage.setItem("showChildren", false);
      localStorage.setItem("childrenMenu", false);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="login-page">
      <div className="login-container">
        {displayLoginRight ? (
          <div className="w50 login-right">
            <div className="login-right-content"></div>
          </div>
        ) : null}
        <div
          className={`${displayLoginRight ? "w50" : "w100 h100vh"} ${
            signPage ? "background-color" : null
          } login-left p-relative`}
        >
          {/* <Select
            style={{
              position: "absolute",
              top: 30,
              right: 30
            }}
            onChange={(val) => {
              dispatch({
                type: ChangeLanguage,
                payload: val
              })
            }}
            value={language}
            options={[{
              label: "English",
              value: "en"
            }, {
              label: "عربي",
              value: "ar"
            }]}
          /> */}

          <div className="login-right-section">
            <div className="login-right-container">
              <Switch>
                <Route exact path="/">
                  <LoginForm />
                </Route>
                <Route path="/reset-password">
                  <ForgotPassword />
                </Route>
                <Route exact path="/two-step-verify">
                  <TwoStepVerify />
                </Route>
                <Route path="/set-password">
                  <ForgotPassword />
                </Route>
                <Route path="/confirm_email">
                  <ConfirmEmail />
                </Route>
                <Route exact path="/forgot-password/">
                  <ResetPassword />
                </Route>
                <Route exact path="/request-demo">
                  <RegisterForDemo />
                </Route>
                <Route exact path="/sign-up">
                  <SignUp setDisplayLoginRight={setDisplayLoginRight} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
