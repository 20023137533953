import { pdf } from "@react-pdf/renderer";
import { Badge, Divider } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from 'json-2-csv';
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  analyticsEstimates
} from "../../actions/cards/cards";
import { apis } from "../../config/APIs";
import endpointAnalyticsApi from "../../config/AxiosAnalyticsApi";
import { amountFormat, convertString } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import MISExpenses from "../Common/PDF/MISPDF/MISExpenses";
import BarGraph from "./BarGraph";
import ReportTable from "./ReportTable";
const _ = require("lodash");

const Bills = ({ showGraph, apiFilters, filterObj, setFilterObj, pdfURL, csvData, ...props }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const cards = useSelector((state) => state.cards?.analytics_estimates);
  const pdfData = useSelector((state) => state.analytics.analytics_cards_all?.data?.data);


  useEffect(() => {
    dispatch(analyticsEstimates({ params: { module_type: "BILL" } }))
    // dispatch(analytics_cards_all({ params:{ module_type:"BILL", isCsv:true}, type:"purchases" }));
    setFilterObj({})
  }, []);

  const columns = useMemo(() => [
    {
      title: t('vendor_name'),
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (text, record) => (
        <>
          {record?.integration_type &&
            <div className="base_badge_sales">
              <Badge.Ribbon text={convertString(record?.integration_type)} placement={"start"} color="#3f81f4" />
            </div>
          }
          {text}
        </>
      ),
    },
    {
      title: t('bill_no'),
      dataIndex: "billing_no",
      key: "billing_no",
    },
    {
      title: t('bill_date'),
      dataIndex: "billing_date",
      key: "billing_date",
      render: (text) => <div>{moment(text).format("DD MMM, YYYY")}</div>,
    },
    {
      title: t('reference_no'),
      dataIndex: "ref_purchase_order_no",
      key: "ref_purchase_order_no",
      render: (text) => text ?? '-'
    },
    {
      title: t('payment_term'),
      dataIndex: "payment_terms",
      key: "payment_terms",
      render: (text) => text ?? '-'
    },
    {
      title: t('payment_due_date'),
      dataIndex: "due_date",
      key: "due_date",
      render: (text) => <div>{moment(text).format("DD-MM-YYYY hh:mm A")}</div>,
    },
    {
      title: t('tax_amount'),
      dataIndex: "bc_total_tax_amount",
      key: "bc_total_tax_amount",
      render: (text) => text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')
    },
    {
      title: t('discount'),
      dataIndex: "bc_total_discount",
      key: "bc_total_discount",
      render: (text) => text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')
    },
    {
      title: t('tds_amount'),
      dataIndex: "bc_tds_amount",
      key: "bc_tds_amount",
      render: (text) => text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')
    },
    {
      title: t('total_amount'),
      dataIndex: "bc_total_amount",
      key: "bc_total_amount",
      render: (text) => text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')
    },
    {
      title: t('status'),
      dataIndex: "invoice_status",
      key: "invoice_status",
      render: (text) => <div className={`status status_${text}`}>{text}</div>,
    },
  ], [cards]);
  cards?.data?.response?.invoice_data?.forEach(({ custom_fields }) => {

    custom_fields.forEach(ele => {

      columns.push({
        title: ele?.display_name,
        dataIndex: "custom_fields",
        key: ele?.field_id,
        render: (text) => text.map((service) => {
          if (service?.field_id === ele?.field_id) {
            return service?.selected?.value
          }
        }),

      })
    })
  })
  const columnsKeys = [
    { label: "Vendor Name", key: "vendor_name", type: "string" },
    { label: "Bill No", key: "billing_no", type: "string" },
    { label: "Bill Date", key: "billing_date", type: "date" },
    { label: "Reference No", key: "ref_purchase_order_no", type: "string" },
    { label: "Payment Terms", key: "payment_terms", type: "string" },
    { label: "Payment Due Date", key: "due_date", type: "date" },
    { label: "Tax Amount", key: "bc_total_tax_amount", type: "amount" },
    { label: "Discount", key: "bc_total_discount", type: "amount" },
    { label: "TDS Amount", key: "bc_tds_amount", type: "amount" },
    { label: "Total Amount", key: "bc_total_amount", type: "amount" },
    { label: "Status", key: "invoice_status", type: "string" }
  ];
  let tableColumns = _.uniqBy(columns, "title")

  const downloadPdf = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.getAnalyticsEstimates + '?module_type=BILL&isCsv=true', { filters: filters });
    if (response) {
      const blob = await pdf((
        <MISExpenses data={response?.data?.data} columnsKeys={columnsKeys} />
      )).toBlob();
      saveAs(blob, "MISBills.pdf");
    }
  }

  const downloadCsv = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.getAnalyticsEstimates + '?module_type=BILL&isCsv=true', { filters: filters });
    if (response) {
      const prod = response?.data?.data?.map(ele => ({
        "Customer Name": ele.vendor_name,
        "Bill No": ele.billing_no,
        "Bill Date": ele.billing_date,
        "Reference No": ele.ref_purchase_order_no,
        "Payment Terms": ele.payment_terms,
        "Payment Due Date": moment(ele.due_date).format("DD MMM, YYYY "),
        "Tax Amount": ele.bc_total_tax_amount,
        "Discount": ele.bc_total_discount,
        "TDS Amount": ele.bc_tds_amount,
        "Total Amount": ele.bc_total_amount,
        "Status": ele.invoice_status,
      }))

      json2csv(prod).then((csv) => {
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "Bills.csv");
      });
    }
  }
  useEffect(() => {
    pdfURL(downloadPdf)
  }, [pdfData])

  useEffect(() => {
    csvData(downloadCsv)
  }, [pdfData])
  return (
    <>
      <div className="reports">
        {showGraph && (
          <>
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BarGraph
                width={1000}
                height={350}
                id={"lineAreaGraph"}
                data={[
                  { day: "Jan", txnValue: 39 },
                  { day: "Feb", txnValue: 49 },
                  { day: "Mar", txnValue: 69 },
                  { day: "Apr", txnValue: 59 },
                ]}
                barColor={"#151424"}
              />
            </div>
          </>
        )}
        <Divider />
      </div>
      <ReportTable
        columns={tableColumns}
        data={Array.isArray(cards?.data?.data) ? cards?.data?.data : []}
        total_records={cards?.data?.total_count}
        scroll={{ x: 2000 }}
        paginationCall={(vals) => {
          // vals.type = "isModuleType"
          vals.params.module_type = "BILL";
          // vals.params.module_type = "PURCHASE_ORDER" ;
          // vals.module_name="BILL_MODULE";
          dispatch(analyticsEstimates(vals))
          // dispatch(analytics_cards_export({ pdf: true, csv: true, ...vals }));
        }}
        filterObj={filterObj}
        payload={{ ...filterObj, ...apiFilters }}
      />
    </>
  );
};

export default Bills;
