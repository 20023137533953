import { Button, message } from "antd";
import React from "react";
import { apis } from "../../../../config/APIs";
import useNotificationAxios from "../../../../config/useNotification";
import OTPComponent from "../../../Common/OTPComponent";

const OTPForm = ({
  register,
  control,
  errors,
  setPageCount,
  pageCount,
  setOtp,
  otp,
  userData,
}) => {
  const { onCall: verifyOtp, loading: verifyLoading } = useNotificationAxios({
    api: apis.payment_verify_otp,
    method: "post",
  });
  return (
    <React.Fragment>
      <div
        className="pp-form-item field_length d-flex flex-col"
        style={{ alignItems: "center" }}
      >
        {pageCount == 2 && <OTPComponent
          value={otp.otp}
          loading={false}
          onChange={(otp) => {
            setOtp((o) => ({
              ...o,
              otp,
            }));
          }}
          source={"ONBOARD"}
        />}
      </div>
      <div className="otp_info">
        <span>
          Check your email!{" "}
          <span style={{ fontWeight: "800" }}>
            {userData?.business_email}
          </span>{" "}
          We've sent an OTP to your linked email.
        </span>
        <div style={{ marginTop: "0.5rem" }}>
          <span className="footer-note">
            <b
              className="cursor"
              style={{ color: "#3F81F4", textDecoration: "underline" }}
              onClick={() => {
                setPageCount(1)
              }}
            >

              Change Email ID
            </b>
          </span>
        </div>
      </div>
      <div className={`pp-form-item field_length mt2`}>
        <Button
          type="primary"
          // htmlType="submit"
          className="pp-main-button"
          id="save"
          block={true}
          onClick={() => {
            verifyOtp({
              data: {
                // user_id: localStorage.getItem("user_id"),
                email_id: userData?.business_email,
                source: "ONBOARD",
                otp: otp.otp,
                user_details: userData,
              },
            })
              .then((res) => {
                if (res.error === false) {
                  setPageCount((preVlaue) => preVlaue + 1);
                }
              })
              .catch((err) => {
                message.error(
                  <span className="messageText">
                    {err?.response?.data?.message}
                  </span>
                );
              });
          }}
          loading={verifyLoading}
        >
          Verify OTP
        </Button>
      </div>
    </React.Fragment>
  );
};

export default OTPForm;
