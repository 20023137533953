import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import failed from "../../assets/images/failed.png";
import success from "../../assets/images/succesasset.png";
import { apis } from '../../config/APIs';
import endpoint from '../../config/Axios';
const ReportAction = ({ type }) => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = useParams()
  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      const approverToken = searchParams.get("approverToken")
      const emailAction = searchParams.get("emailAction")
      setLoading(true)
      endpoint.post(type === "approve" ? apis.reportsApprove : apis.reportsReject, {
        id: params.id
      }, {
        headers: {
          approverToken,
          emailAction
        }
      }).then(res => {
        setData(res.data)
        setLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [location.search])
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    }}>
      {loading ? <div>Loading...</div> : <>
        <img src={data?.status ? success : failed} alt="" style={{
          padding: "2rem 0"
        }} width={300} />
        <h2 style={{ color: "#333" }}>
          {data?.status ? `Report ${type} successful` : data?.errors ?? data?.message}
        </h2>
        <p></p>
      </>}
    </div>
  );
};

export default ReportAction;