
import React from "react";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error or perform other actions here
    console.error('Error caught by boundary:', error, errorInfo);
    this.setState({ hasError: true, error, errorInfo });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname && this.state.hasError) {
      this.setState({ hasError: false });
    }
    return true;
  }

  render() {
    if (this.state.hasError) {
      // You can display a fallback UI here
      return <div>Something went wrong. Please Contact App Developer.</div>;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);