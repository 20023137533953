import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Row, Space, Typography } from "antd";
import React, { useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { get_person_accounts } from "../../actions/z_accounts";
import copy from "../../assets/icons/copy.png";
import down from "../../assets/icons/down.png";
import { currencyMapper } from "../../config/helper";
import { amountFormat } from "../../utils";
import CardDashboard from "./CardDashboard";

const { Title, Text, Link } = Typography;

const BankAccount = ({ acc, fromDetail }) => {
  const [visible, setVisible] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const toggleVisibility = () => {
    setVisible(!visible);
  };
  const bAcc = useSelector((state) => state?.z_accs?.accounts?.data?.data);
  const bankAcc = useSelector(
    (state) =>
      state?.z_accs?.accounts?.data?.data?.responseData?.data?.values?.filter(
        (ele) => ele.accountTypeCode !== "VIRTUAL"
      ) || []
  );

  const menu = (
    <Menu>
      <Menu.Item key="1">Transfer To</Menu.Item>
      <Menu.Item key="2">Transfer From</Menu.Item>
    </Menu>
  );

  function handleMenuClick(itm) {
    if (bankAcc?.length) {
      setSelectedAccount(itm.key);
    }
  }

  const accountMenu = useMemo(() => {
    return bankAcc?.length ? (
      <Menu onClick={handleMenuClick}>
        {bankAcc?.map((itm) => (
          <Menu.Item key={itm?.accountId}>
            {itm?.accountName} - {itm.accountNumbers?.[0]?.accountNumber?.value}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <Menu>
        <Menu.Item key="1">No Accounts</Menu.Item>
      </Menu>
    );
  }, [bankAcc]);

  useLayoutEffect(() => {
    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
  }, []);

  useLayoutEffect(() => {
    if (bankAcc?.length) {
      setSelectedAccount(bankAcc?.[0]?.accountId);
    }
  }, [bAcc]);

  const account = acc
    ? acc
    : bankAcc?.find((ele) => ele.accountId === selectedAccount);

  return (
    <Row className="account-card">
      <Col className="first-col" style={{ width: "63%" }}>
        <CardDashboard>
          <div>
            {/* Account Selection */}
            <div className="mh-12">
              <div className="flex-between">
                <Dropdown
                  overlay={accountMenu}
                  trigger={["click"]}
                  disabled={fromDetail}
                >
                  <Button>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <img
                        src="https://flagcdn.com/us.svg"
                        alt="US Flag"
                        width="24"
                      />
                      <span className="acc-num" title={account?.accountName}>
                        {account?.accountName?.length > 25
                          ? `${account?.accountName?.substr(0, 25)}...`
                          : account?.accountName}{" "}
                        - {account?.accountNumbers?.[0]?.accountNumber?.value}{" "}
                      </span>
                      {fromDetail ? null : <img src={down} width={11} />}
                    </div>
                  </Button>
                </Dropdown>
                <Space>
                  <Button className="gn-btn">+ Deposit</Button>
                  <Dropdown overlay={menu} className="gn-btn">
                    <Button>
                      <span className="acc-num mr1">Transfer</span>
                      <img src={down} width={11} />
                    </Button>
                  </Dropdown>
                </Space>
              </div>

              {/* Balance Info */}
              <div className="bal-sec">
                <Text className="avl-bal">Available Balance</Text>
                <h3>
                  {visible
                    ? `${
                        currencyMapper[account?.balances?.[0]?.currencyCode] ??
                        "$"
                      } ${amountFormat(
                        account?.balances?.[0]?.availableBalanceAmount
                      )}`
                    : "******"}
                  <Button
                    type="link"
                    icon={visible ? <EyeInvisibleFilled /> : <EyeFilled />}
                    onClick={toggleVisibility}
                  />
                </h3>
                <Text className="avl-bal">USD • Main</Text>
              </div>
            </div>
            {/* Balance Details */}
            <div className="hr" />
            <Row
              style={{
                gap: "4rem",
              }}
              className="bal-details"
            >
              <Col>
                <Text className="avl-bal">Type</Text>
                <div className="value">{account?.accountTypeCode}</div>
              </Col>
              <Col>
                <Text className="avl-bal">Balance</Text>
                <div className="value">
                  {`${
                    currencyMapper[account?.balances?.[0]?.currencyCode] ?? "$"
                  }
                  ${amountFormat(account?.balances?.[0]?.balanceAmount)}`}
                </div>
              </Col>
              <Col>
                <Text className="avl-bal">Reserved Amount</Text>
                <div className="value">{`${
                  currencyMapper[account?.balances?.[0]?.currencyCode] ?? "$"
                }
                  ${amountFormat(
                    account?.balances?.[0]?.reservedAmount
                  )}`}</div>
              </Col>
            </Row>
          </div>
        </CardDashboard>
      </Col>
      {/* Right Column */}

      <Col style={{ width: "37%" }} className="right-col">
        <CardDashboard className={"h100"}>
          <div className="w95 mh-12 mt1">
            <div className="flex-between">
              <Text className="avl-bal">Routing Number</Text>
              <div className="value">
                {account?.accountNumbers?.[0]?.financialInstitutionId?.value}{" "}
                <img src={copy} width={12} />
              </div>
            </div>
            <div className="flex-between">
              <Text className="avl-bal">Account number</Text>
              <div className="value">
                {account?.accountNumbers?.[0]?.accountNumber?.value}{" "}
                <img src={copy} width={12} />
              </div>
            </div>
            <div className="flex-between">
              <Text className="avl-bal">Status</Text>
              <div
                className={`status status_${account?.statusCode?.toLowerCase()}`}
              >
                {account?.statusCode}
              </div>
            </div>
          </div>
          <div className="hr" />
          <div>
            <span
              className="view-statement"
              onClick={() => {
                history.push("/statements");
              }}
            >
              View Statements
            </span>
          </div>
        </CardDashboard>
      </Col>
    </Row>
  );
};

export default BankAccount;
