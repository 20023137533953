import { getSymbol } from "../../config/helper";
import { amountFormat } from "../../utils";
import CreditPdf from "../PDFTemplates/CreditPdf";

export const renderTemplate = (type, templatesData) => {
    let title;
    let totalAmountSum;
    if (templatesData?.billing_items) {
        totalAmountSum = templatesData?.billing_items?.reduce((sum, item) => sum + parseFloat(item.total_amount), 0);
    } else if (templatesData?.invoice_items) {
        totalAmountSum = templatesData?.invoice_items?.reduce((sum, item) => sum + parseFloat(item.total_amount), 0);
    }
    if (type == "SALES_ORDER") {
        type = "ESTIMATE"
        title = "Sales Order"
    } else if (type == "INVOICE") {
        type = "ESTIMATE"
        title = "Invoice"
    } else if (type == "INVOICE_RECEIPT") {
        type = "ESTIMATE"
        title = "Invoice"
    } else if (type == "PURCHASE_ORDER") {
        type = "ESTIMATE"
        title = "Purchase Order"
    } else if (type == "BILL") {
        type = "ESTIMATE"
        title = "Invoice"
    } else if (type == "BILL_RECEIPT") {
        type = "ESTIMATE"
        title = "Invoice"
    } else {
        title = "Estimate"
    }
    switch (type) {
        case 'ESTIMATE':
            return <CreditPdf
                balanceAmount={amountFormat(parseFloat(templatesData?.due_balance_amount ? templatesData?.due_balance_amount : templatesData?.bills?.[0]?.due_balance_amount), templatesData?.currency_info?.decimal_scale)}
                creditNoteNo={templatesData?.invoice_no ? templatesData?.invoice_no : templatesData?.billing_no}
                creditDate={templatesData?.created_at}
                invoiceNo={templatesData?.invoice_no ? templatesData?.invoice_no : templatesData?.billing_no}
                currency={templatesData?.currency}
                invoiceDate={templatesData?.invoice_date ? templatesData?.invoice_date : templatesData?.billing_date ? templatesData?.billing_date : templatesData?.created_at}
                lineItems={templatesData?.invoice_items ? templatesData?.invoice_items : templatesData?.billing_items}
                taxRates={templatesData?.exchange_rate}
                subTotal={amountFormat(parseFloat(totalAmountSum), templatesData?.currency_info?.decimal_scale)}
                TDSAmt={"0.00"}
                billingAddressCity={templatesData?.beneficiary_billing_addresses?.[0]?.city}
                billingAddressState={templatesData?.beneficiary_billing_addresses?.[0]?.state}
                billingAddresszip={templatesData?.beneficiary_billing_addresses?.[0]?.zipcode}
                billPhone={templatesData?.beneficiary_billing_addresses?.[0]?.phone}
                billAddress={templatesData?.beneficiary_billing_addresses?.[0]?.address}
                billingCountry={templatesData?.beneficiary_billing_addresses?.[0]?.country}
                type={title == "Invoice" ? "INVOICE" : ""}
                taxAmount={amountFormat(parseFloat(templatesData?.total_tax_amount ? templatesData?.total_tax_amount : templatesData?.invoices?.[0]?.total_tax_amount ? templatesData?.invoices?.[0]?.total_tax_amount : templatesData?.bills?.[0]?.total_tax_amount), templatesData?.currency_info?.decimal_scale)}
                totalAmount={amountFormat(parseFloat(templatesData?.total_amount ? templatesData?.total_amount : templatesData?.invoices?.[0]?.total_amount ? templatesData?.invoices?.[0]?.total_amount : templatesData?.bills?.[0]?.total_amount), templatesData?.currency_info?.decimal_scale)}
                title={title}
                noteTitle={`${title} No`}
                balanceTitle={title == "Invoice" ? "Balance Due" : ""}
                numTitle={`${title} No`}
                address={"Testing"}
                gst={templatesData?.company?.gst_no ? templatesData?.company?.gst_no : templatesData?.company_info?.gst_no}
                dateTitle={"Date"}
                discount={amountFormat(parseFloat(templatesData?.total_discount ? templatesData?.total_discount : templatesData?.bills?.[0]?.total_discount ? templatesData?.bills?.[0]?.total_discount : templatesData?.invoices?.[0]?.total_discount), templatesData?.currency_info?.decimal_scale)}
                benficiaryGST={templatesData?.beneficiaries?.gstin}
                benefiName={templatesData?.company?.name}
                benefiEmail={templatesData?.company?.primary_email}
                iscutomer={true}
            />
    }
};