import {
  Tally_Logo,
  Zoho_Logo,
  QuickBooks_Logo,
  Tally_Main_Logo,
  Zoho_Main_Logo,
  QuickBooks_Main_Logo,
  Dynamic365,
  SapOne,
  OracleNetsuite,
  Sage,
  Xero
} from "../../../assets/images/RootiFi-Integration";
import QuickBook from "../../../assets/images/RootiFi-Integration/quickbooks-2.svg";
import QuickBook_Small from "../../../assets/images/RootiFi-Integration/quickbook_small.png";

export const roofiFiData = [
  {
    id: 1,
    active: false,
    cardImageSrc: Zoho_Logo,
    company_name: "ZOHO_BOOKS",
    fullImage: Zoho_Main_Logo,
    cardTitle: "Zoho Corporation",
    cardDescription: "Accounting & ERP",
    owner: "Krishna123@gmail.com",
    organisation: "Jeevan Technologies",
    title: "Zoho Corporation",
    description:
      "Zoho Corporation, is an Indian multinational technology company that makes computer software and web-based business tools. It is best known for the online office suite offering Zoho Office Suite",
  },
  {
    id: 2,
    active: false,
    cardImageSrc: QuickBook_Small,
    company_name: "QUICK BOOKS",
    fullImage: QuickBook,
    cardTitle: "Quick Books",
    cardDescription: "Accounting & ERP",
    owner: "Krishna123@gmail.com",
    organisation: "Jeevan Technologies",
    title: "Quick Book Corporation",
    description:
      "Organize & manage your business with the #1 rated solution. Fast & easy setup. Sign up for a free trial to join 7 million businesses already using QuickBooks.",
    formItems: [
      {
        label: "Client Id",
        key: "client_id",
      },
      {
        label: "Client Secret",
        key: "client_secret",
      },
      {
        label: "Company Id",
        key: "company_id",
      },
    ],
  },
  {
    id: 2,
    active: true,
    cardImageSrc: Dynamic365,
    fullImage: Dynamic365,
    cardTitle: "Dynamic 365",
    cardDescription: "Accounting & ERP",
    owner: "Siva@gmail.com",
    organisation: "Siva Purpleplum",
    title: "Dynamic 365",
    description:
      "TallyPrime has been trusted as best Business Software by SMBs for over 30 years. Download the all-new TallyPrime – it's Easier, Flexible & Simply Better.",
  },
  {
    id: 3,
    active: true,
    cardImageSrc: SapOne,
    fullImage: SapOne,
    cardTitle: "SAP Business One",
    cardDescription: "Accounting & ERP",
    owner: "Siva@gmail.com",
    organisation: "Siva Purpleplum",
    title: "SAP Business One",
    description:
      "TallyPrime has been trusted as best Business Software by SMBs for over 30 years. Download the all-new TallyPrime – it's Easier, Flexible & Simply Better.",
  },
  {
    id: 4,
    active: false,
    cardImageSrc: OracleNetsuite,
    fullImage: OracleNetsuite,
    cardTitle: "Oracle Netsuite",
    cardDescription: "Accounting & ERP",
    owner: "Satya@gmail.com",
    organisation: "Satya Oracle",
    title: "Oracle Netsuite",
    description:
      "QuickBooks Online is a cloud based financial management software. Its designed to slash the time you spend managing your business finances, by helping you with tasks such as: Creating estimates and invoices. Tracking sales and cash flow.",
  },
  {
    id: 5,
    active: false,
    cardImageSrc: Sage,
    fullImage: Sage,
    cardTitle: "Sage",
    cardDescription: "Accounting & ERP",
    owner: "Satya@gmail.com",
    organisation: "Satya Sage",
    title: "Sage",
    description:
      "From startup to enterprise solutions, Sage has innovative software powered by the latest AI and machine learning to manage your accounting, people, payroll, and payments.",
  },
  {
    id: 6,
    active: false,
    cardImageSrc: Xero,
    fullImage: Xero,
    cardTitle: "Xero",
    cardDescription: "Accounting & ERP",
    owner: "Satya@gmail.com",
    organisation: "Xero",
    title: "Xero",
    description:
      "Xero online accounting software for your business connects you to your bank, accountant, bookkeeper, and other business apps. Start a free trial today..",
  },
];

export const syncHistoryData = [
  {
    id: 1,
    historytTitle: "Expenses, bills & accounts are synced",
    historyDate: "9 Sep 2023 7:48 PM",
    userName: "John Patric (Reporting Manager)",
    action: "Attachment deleted for Bill - 5464462452456",
  },
  {
    id: 2,
    historytTitle: "Contact updated",
    historyDate: "20 Oct 2023 7:48 PM",
    userName: "Chandra Shekar",
    action: "Marked as Inactive",
  },
  {
    id: 3,
    historytTitle: "Payments Made added",
    historyDate: "9 Sep 2023 7:48 PM",
    userName: "John Patric (Reporting Manager)",
    action: "Attachment deleted for Bill - 5464462452456",
  },
  {
    id: 4,
    historytTitle: "Bill Deleted",
    historyDate: "20 Oct 2023 7:48 PM",
    userName: "Chandra Shekar",
    action: "Marked as Inactive",
  },
  {
    id: 5,
    historytTitle: "Expenses, bills & accounts are synced",
    historyDate: "9 Sep 2023 7:48 PM",
    userName: "John Patric (Reporting Manager)",
    action: "Attachment deleted for Bill - 5464462452456",
  },
  {
    id: 6,
    historytTitle: "Contact updated",
    historyDate: "20 Oct 2023 7:48 PM",
    userName: "Chandra Shekar",
    action: "Marked as Inactive",
  },
  {
    id: 7,
    historytTitle: "Payments Made added",
    historyDate: "9 Sep 2023 7:48 PM",
    userName: "John Patric (Reporting Manager)",
    action: "Attachment deleted for Bill - 5464462452456",
  },
  {
    id: 8,
    historytTitle: "Bill Deleted",
    historyDate: "20 Oct 2023 7:48 PM",
    userName: "Chandra Shekar",
    action: "Marked as Inactive",
  },
];
