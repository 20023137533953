import { Button, Form, Input, message, Typography } from "antd";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import * as yup from "yup";
import { RequestDemo, requestDemo } from "../../actions/auth/auth";

const { Text, Title } = Typography;

message.config({
  maxCount: 3,
});

const RegisterForDemo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const req_demo = useSelector((state) => state.auth.requestDemo);

  const ValidationSchema = yup.object().shape({
    name: yup.string().required(t("name_required")),
    phone_no: yup.string().required(t("mobile_required")),
    work_email: yup.string().email().required(t("work_email_required")),
    message: yup.string().required(t("message_required")),
    company_name: yup.string().required(t("company_required")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone_no: "",
      work_email: "",
      message: "",
      company_name: "",
    },
    onSubmit: (vals) => {
      dispatch(requestDemo(vals));
    },
    validationSchema: ValidationSchema,
  });

  useEffect(() => {
    if (req_demo?.data?.status) {
      message.success(
        <span className="messageText">{req_demo?.data?.message}</span>
      );
      formik.resetForm();
      dispatch({
        type: RequestDemo.RESET,
      });
    }

    if (req_demo?.data?.status === false) {
      message.error(
        <span className="messageText">{req_demo?.data?.message?.inquiry}</span>
      );
      dispatch({
        type: RequestDemo.RESET,
      });
    }
    // eslint-disable-next-line
  }, [req_demo]);

  return (
    <div className="reset-form">
      <div className="head-sec">
        <Title level={3}>{t("request_demo")}</Title>
      </div>
      <Form onFinish={formik.handleSubmit} layout="vertical">
        <Form.Item
          label={t("name")}
          help={formik.touched.name && formik.errors.name}
          validateStatus={
            formik.errors.name && formik.touched.name ? "error" : "success"
          }
          required
        >
          <Input
            placeholder={t("name_placeholder")}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </Form.Item>
        <Form.Item
          label={t("mobile")}
          help={formik.touched.phone_no && formik.errors.phone_no}
          validateStatus={
            formik.errors.phone_no && formik.touched.phone_no
              ? "error"
              : "success"
          }
          required
        >
          <Input
            placeholder={t("mobile_placeholder")}
            name="phone_no"
            value={formik.values.phone_no}
            onChange={formik.handleChange}
          />
        </Form.Item>
        <Form.Item
          label={t("work_email")}
          help={formik.touched.work_email && formik.errors.work_email}
          validateStatus={
            formik.errors.work_email && formik.touched.work_email
              ? "error"
              : "success"
          }
          required
        >
          <Input
            placeholder={t("work_email_placeholder")}
            name="work_email"
            value={formik.values.work_email}
            onChange={formik.handleChange}
          />
        </Form.Item>
        <Form.Item
          label={t("Company")}
          help={formik.touched.company_name && formik.errors.company_name}
          validateStatus={
            formik.errors.company_name && formik.touched.company_name
              ? "error"
              : "success"
          }
          required
        >
          <Input
            placeholder={t("Company_placeholder")}
            name="company_name"
            value={formik.values.company_name}
            onChange={formik.handleChange}
          />
        </Form.Item>
        <Form.Item
          label={t("message")}
          help={formik.touched.message && formik.errors.message}
          validateStatus={
            formik.errors.message && formik.touched.message
              ? "error"
              : "success"
          }
          required
        >
          <Input
            placeholder={t("message_placeholder")}
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
          />
        </Form.Item>

        <div className="mt1">
          <Text>{t("by_click")}</Text>
        </div>
        <Button
          htmlType="submit"
          className="primaryButton mt2"
          disabled={req_demo?.loading}
        >
          {req_demo?.loading ? (
            <ClipLoader loading={req_demo?.loading} size={20} color="#ffffff" />
          ) : (
            t("book_your_demo")
          )}
        </Button>
      </Form>

      <div className="mt2">
        <span className="footer-note">
          {t("have_an_account")}{" "}
          <b
            className="cursor emp-text"
            onClick={() => {
              history.push("/");
            }}
          >
            {t("login")}
          </b>
        </span>
      </div>
      {/* <div className="mt1 flex-center">
        <span className="text-dim footer-note" style={{ textAlign: "center" }}>
          Help and Support{" "}, Phone: <a href="tel:+91 7400088941">+91 7400088941</a>{" "},
          Email: <a href="mailto:customercare@paycraftsol.com">customercare@paycraftsol.com</a>
        </span>
      </div> */}
    </div>
  );
};

export default RegisterForDemo;
