import { message } from "antd";
import axios from "axios";
import _, { find, findIndex, isEqual, remove } from "lodash";
import moment from "moment";
import { call, put } from "redux-saga/effects";
import RoleIcon from "./assets/icons/business.svg";
import DashboardRight from "./assets/images/dashboardRight.svg";
import LoaderIcon from "./assets/images/dots_loading_2.gif";
import UserDashMobile from "./assets/images/user_dash_mob.png";
import { apis } from "./config/APIs";
import endpoint from "./config/Axios";
import endpointAdminApi from "./config/AxiosAdmin";
import endpointAnalyticsApi from "./config/AxiosAnalyticsApi";
import endpointDocumentApi from "./config/AxiosDocuments";
import endpointImportApi from "./config/AxiosImportApi";
import endpointNotificationApi from "./config/AxiosNotificationsAPI";
import endpointOms from "./config/AxiosOMS";
import endpointOcrApi from "./config/AxiosOcrApi";
import endpointPayApi from "./config/AxiosPayApi";
import endpointPayApiBeta from "./config/AxiosPayApiBeta";
import endpointPayExpenseBeta from "./config/AxiosPayExpense";
import endpointRootiIntegrationApi from "./config/AxiosRootiFiIntegrationApi";
import endpointSettingsApi from "./config/AxiosSettingsApi";
import { LOGO as Logo } from "./config/Images";
import endpointGridApi from "./config/AxiosGridApi";
export const RouteConstants = {
  ROOT: "/",
  DASHBOARD: "/dashboard/",
  REPORTS: "/reports/",
  EXPENSES: "/expenses/",
  TRIPS: "/trips/",
  ADVANCES: "/advances/",
  CARDS: "/cards/",
  CARD_DETAILS: "/card-details/",
  CARD_TRANSACTIONS: "/transactions",
  CARD_INVENTORY: "/inventory",
  TRANSFERS: "/transfers/",
  QUICK_TRANSFERS: "/transfers/quick-transfers/",
  BULK_TRANSFERS: "/transfers/bulk-transfers/",
  TRANSFERS_LOG: "/transfers/transfers-log/",
  TRANSFERS_REPORTS: "/transfers/transfers-reports/",
  ACCOUNT: "/account/",
  ACCOUNT_TRANSACTIONS: "/transaction/",
  ANALYTICS: "/analytics",
  SETTINGS: "/setting/",
  RESETPASSWORD: "/reset-password",
  FORGOTPASSWORD: "/forgot-password/",
  SETTING_ORGANISATION: "/setting/organisation/",
  SETTING_ORGANISATION_MANAGE: "/setting/organisation/manage",
  SETTING_USERS: "/setting/users/",
  SETTING_USERS_ADD_USER: "/setting/users/add_user/",
  SETTING_ROLES: "/setting/roles/",
  SETTING_GRADES: "/setting/grades/",
  SETTING_CUSTOM_FIELD: "/setting/custom_field/",
  SETTING_CATEGORIES: "/setting/categories/",
  SETTING_POLICIES: "/setting/policies/",
  SETTING_POLICIES_EXPENSE: "/setting/policies/expense/",
  SETTING_POLICIES_CARDS: "/setting/policies/cards/",
  SETTING_APPROVALS: "/setting/approvals/",
  SETTING_APPROVALS_ADVANCE: "/setting/approvals_advance/",
  SETTING_APPROVALS_TRIP: "/setting/approvals_trip/",
  SETTING_CUSTOMISATION: "/setting/customisation/",
  SETTING_CUSTOMISATION_EXPENSES: "/setting/customisation/expenses/",
  SETTING_CUSTOMISATION_REPORTS: "/setting/customisation/reports/",
  SETTING_CUSTOMISATION_ADVANCES: "/setting/customisation/advances/",
  SETTING_CUSTOMISATION_TRIPS: "/setting/customisation/trips/",
  SETTING_CUSTOMISATION_COSTCENTER: "/setting/customisation/cost_center/",
  SETTING_CUSTOMISATION_MERCHANT: "/setting/customisation/merchant/",
  SETTING_CUSTOMISATION_PROJECTS: "/setting/customisation/projects/",
  SETTING_CUSTOMISATION_REIMBURSEMENTS:
    "/setting/customisation/reimbursements/",
};

export const roleRouteMapping = {
  company: "",
  dashboard: "/dashboard",
  reports: "/expenses/reports",
  expenses: "/expenses",
  trips: "/trips",
  advances: "/advances",
  cards: "/cards",
  transfers: "/transfers/quick-transfers/",
  account: "/account",
  analytics: "/analytics",
  employees: "",
  transactions: "",
  organisation_settings: "/setting/organisation",
  users_and_controls: "/setting/users",
  categories: "/setting/categories",
  policies_and_limits: "/setting/policies/expense",
  approvals: "/setting/approvals/",
  expense_settings: "/setting/customisation/expenses/",
  reports_settings: "/setting/customisation/reports/",
  advances_settings: "/setting/customisation/advances/",
  trips_settings: "/setting/customisation/trips/",
  projects: "/setting/customisation/projects/",
  reimbursements: "",
  cost_center: "/setting/customisation/cost_center/",
  merchants: "/setting/customisation/merchant/",
  currencies: "",
};

export const GLOBAL_STATIC_FILES = {
  COMPANY_LOGO: Logo,
  DASHBOARD_RIGHT: DashboardRight,
  ROLE_ICON: RoleIcon,
  LOADER: LoaderIcon,
  USER_DASH_MOBILE: UserDashMobile,
};

export const ActionTypesFactory = (prefix, type) => {
  let p = _.upperCase(prefix) + "/" + _.upperCase(type);
  return {
    REQUEST: p + "_REQUEST",
    INPROGRESS: p + "_INPROGRESS",
    SUCCESS: p + "_SUCCESS",
    FAILED: p + "_FAILED",
    CHANGED: p + "_CHANGED",
    RESET: p + "_RESET",
    custom: (name) => {
      return `${p}_${name}`;
    },
  };
};

export const isSettings = () => {
  return (
    hasAccess("estimates_settings", "read") ||
    hasAccess("sales_orders_settings", "read") ||
    hasAccess("invoices_settings", "read") ||
    hasAccess("currencies", "read") ||
    hasAccess("users", "read") ||
    hasAccess("purchase_orders_settings", "read") ||
    hasAccess("bills_settings", "read") ||
    hasAccess("organisation_settings", "read") ||
    hasAccess("users_and_controls", "read") ||
    hasAccess("policies_and_limits", "read") ||
    hasAccess("approvals", "read") ||
    hasAccess("expense_settings", "read") ||
    hasAccess("reports_settings", "read") ||
    hasAccess("advances_settings", "read") ||
    hasAccess("trips_settings", "read") ||
    hasAccess("projects", "read") ||
    hasAccess("cost_center", "read") ||
    hasAccess("merchants", "read") ||
    hasAccess("company", "read") ||
    hasAccess("items", "read") ||
    hasAccess("taxes", "read") ||
    hasAccess("tds", "read") ||
    hasAccess("categories", "read") ||
    // ---------
    hasAccess("roles_and_permissions", "read") ||
    hasAccess("department_and_grades", "read") ||
    hasAccess("custom_fields", "read") ||
    hasAccess("expense_policy", "read") ||
    hasAccess("cards_policy", "read") ||
    hasAccess("payments_policy", "read") ||
    hasAccess("reports_approvals_settings", "read") ||
    hasAccess("trips_approvals_settings", "read") ||
    hasAccess("advances_approvals_settings", "read") ||
    hasAccess("bills_approvals_settings", "read") ||
    hasAccess("invoices_approvals_settings", "read") ||
    hasAccess("sales_order_approvals_settings", "read") ||
    hasAccess("estimates_approvals_settings", "read") ||
    hasAccess("purchase_orders_approvals_settings", "read") ||
    hasAccess("payment_approvals_settings", "read") ||
    hasAccess("bulk_payment_approvals_settings", "read") ||
    hasAccess("module_customisations", "read") ||
    hasAccess("reimbursements", "read") ||
    hasAccess("credit_note_settings", "read") ||
    hasAccess("payment_received_settings", "read") ||
    hasAccess("payment_made_settings", "read") ||
    hasAccess("customer_portal_settings", "read") ||
    hasAccess("vendor_portal_settings", "read") ||
    hasAccess("material_receipt_approvals_settings", "read") ||
    hasAccess("delivery_challan_approvals_settings", "read") ||
    hasAccess("delivery_challan_settings", "read") ||
    hasAccess("material_receipt_settings", "read") ||
    hasAccess("quality_check_settings", "read") ||
    hasAccess("returns_settings", "read") ||
    // hasCustomize()
    isCustomisation()
  );
};

export const hasCustomize = () => {
  return (
    hasAccess("expense_settings", "read") ||
    hasAccess("expense_reports_settings", "read") ||
    hasAccess("advances_settings", "read") ||
    hasAccess("trips_settings", "read") ||
    hasAccess("projects", "read") ||
    hasAccess("cost_center", "read") ||
    hasAccess("merchants", "read")
  );
};
export const getSize = (size) => {
  if (size / (1024 * 1024) >= 1) {
    return (size / (1024 * 1024)).toFixed(2) + " MB";
  } else if (size / 1024 >= 1) {
    return (size / 1024).toFixed(2) + " KB";
  } else {
    return size + " B";
  }
};

export const sagaFunctions = (
  action_type,
  method,
  api,
  data,
  headers,
  payApi
) => {
  let params = undefined;
  if (data && data?.params) {
    params = data?.params;
    delete data?.params;
  }
  return function* () {
    let res = null;
    try {
      if (method === "post" || method === "put") {
        res = yield call(
          payApi === "settings"
            ? endpointSettingsApi[method]
            : payApi === "import"
            ? endpointImportApi[method]
            : payApi === "admin"
            ? endpointAdminApi[method]
            : payApi
            ? endpointPayApi[method]
            : endpoint[method],
          api,
          data,
          {
            headers,
            params,
          }
        );
      } else if (method === "delete") {
        res = yield axios.delete(api, {
          data,
        });
      } else {
        res = yield call(
          payApi === "settings"
            ? endpointSettingsApi[method]
            : payApi === "import"
            ? endpointImportApi[method]
            : payApi
            ? endpointPayApi[method]
            : endpoint[method],
          api,
          {
            headers,
            params: data,
          }
        );
      }
      if (res?.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaFunctionsOms = (action_type, method, api, data, headers) => {
  let params = undefined;
  if (data && data?.params) {
    params = data?.params;
    delete data?.params;
  }
  return function* () {
    let res = null;
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointOms[method], api, data, {
          headers,
          params,
        });
      } else if (method === "delete") {
        res = axios.delete(api, {
          data,
        });
      } else {
        res = yield call(endpointOms[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaFunctionsGrid = (
  action_type,
  method,
  api,
  data,
  headers,
  params
) => {
  return function* () {
    let res = null;
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointGridApi[method], api, data, {
          headers,
          params,
        });
      } else {
        res = yield call(endpointGridApi[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaFunctionsExpense = (
  action_type,
  method,
  api,
  data,
  headers
) => {
  let params = undefined;
  if (data && data?.params) {
    params = data?.params;
    delete data?.params;
  }
  return function* () {
    let res = null;
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointPayExpenseBeta[method], api, data, {
          headers,
          params,
        });
      } else if (method === "delete") {
        res = axios.delete(api, {
          data,
        });
      } else {
        res = yield call(endpointPayExpenseBeta[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaFunctionsOcr = (action_type, method, api, data, headers) => {
  return function* () {
    let res = null;
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointOcrApi[method], api, data, {
          headers,
        });
      } else {
        res = yield call(endpointOcrApi[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaFunctionsAnalytics = (
  action_type,
  method,
  api,
  data,
  headers
) => {
  return function* () {
    let res = null;
    let params = undefined;
    if (data && data?.params) {
      params = data?.params;
      delete data?.params;
    }
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointAnalyticsApi[method], api, data, {
          headers,
          params,
        });
      } else {
        res = yield call(endpointAnalyticsApi[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaFunctionsSettings = (
  action_type,
  method,
  api,
  data,
  headers
) => {
  return function* () {
    let res = null;
    let params = undefined;
    if (data?.params) {
      params = data.params;
      delete data.params;
    }
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointSettingsApi[method], api, data, {
          headers,
          params,
        });
      } else {
        res = yield call(endpointSettingsApi[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaPaySettings = (action_type, method, api, data, headers) => {
  return function* () {
    let res = null;
    let params = undefined;
    if (data?.params) {
      params = data.params;
      delete data.params;
    }
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointPayApiBeta[method], api, data, {
          headers,
          params,
        });
      } else {
        res = yield call(endpointPayApiBeta[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaAdminFunctions = (action_type, method, api, data, headers) => {
  return function* () {
    let res = null;
    let params = undefined;
    if (data?.params) {
      params = data.params;
      delete data.params;
    }
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointAdminApi[method], api, data, {
          headers,
          params,
        });
      } else {
        res = yield call(endpointAdminApi[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaNotificationFunctions = (
  action_type,
  method,
  api,
  data,
  headers
) => {
  return function* () {
    let res = null;
    let params = undefined;
    if (data?.params) {
      params = data.params;
      delete data.params;
    }
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointNotificationApi[method], api, data, {
          headers,
          params,
        });
      } else {
        res = yield call(endpointNotificationApi[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const sagaDocuments = (action_type, method, api, data, headers) => {
  return function* () {
    let res = null;
    let params = undefined;
    if (data?.params) {
      params = data.params;
      delete data.params;
    }
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointDocumentApi[method], api, data, {
          headers,
          params,
        });
      } else if (method === "delete") {
        res = yield call(endpointDocumentApi[method], api, {
          headers,
          params,
          data,
        });
      } else {
        res = yield call(endpointDocumentApi[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};
export const sagaFunctionsRootiFiIntegration = (
  action_type,
  method,
  api,
  data,
  headers
) => {
  let params = undefined;
  if (data && data?.params) {
    params = data?.params;
    delete data?.params;
  }
  return function* () {
    let res = null;
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpointRootiIntegrationApi[method], api, data, {
          headers,
          params,
        });
      } else {
        res = yield call(endpointRootiIntegrationApi[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const ReducerFunctions = (ActionType, initialState) => {
  return function (state = initialState, action) {
    let data = Array.isArray(action?.payload)
      ? [...action?.payload]
      : { ...action?.payload };
    switch (action?.type) {
      case ActionType.REQUEST:
        return {
          ...state,
          loading: true,
        };
      case ActionType.SUCCESS: {
        return {
          ...state,
          data,
          loading: false,
          success: true,
        };
      }
      case ActionType.FAILED:
        return {
          ...state,
          data,
          loading: false,
          success: false,
        };
      case ActionType.RESET:
        return {
          loading: false,
        };

      default:
        return state;
    }
  };
};

export const objectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export const capitalize = ([first, ...rest], lowerRest = false) =>
  first.toUpperCase() + rest.join("").toLowerCase();

export const getBoolVal = (val) => val === true || val === "true";

export const getKycStatus = (type) => {
  switch (type) {
    case "GNI":
      return;
    case "INA":
      return;
    case "A":
      return;
    case "Y":
      return;
    case "THL":
      return;
    case "PHL":
      return;
    default:
      return "";
  }
};

export const isEqualLodash = (value, other) => isEqual(value, other);
export const findLodash = (array, object) => find(array, object);
export const removeLodash = (array, object) => remove(array, object);
export const findIndexLodash = (array, object) => findIndex(array, object);

export const hasAccess = (module, key) => {
  let menuAccess = localStorage.getItem("menu")
    ? JSON.parse(localStorage.getItem("menu"))
    : {};

  if (
    (menuAccess && menuAccess?.[module]?.includes(key)) ||
    isSuperAdmin()
    // localStorage.getItem("is_admin") == "true" ||
  ) {
    return true;
  }

  return false;
};

export const isEqualObject = (fromPayload, toPayload) =>
  JSON.stringify(fromPayload) === JSON.stringify(toPayload);

const amountEnFormat = new Intl.NumberFormat("en-US");

export const amountFormat = (amount, decimal_scale = 2) => {
  const c = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: decimal_scale,
    minimumFractionDigits: decimal_scale,
  });

  return amount === null || Number.isNaN(Number(amount))
    ? "0.00"
    : c.format(parseFloat(amount));
};

export const amountEnterFormat = (amount) => {
  return amountEnFormat.format(parseFloat(amount));
};
export const notNumber = (value) => {
  if (Number.isNaN(Number(value))) {
    return true;
  }
  return false;
};
export const getWidth = (arr, val) => {
  return (val / Math.max(...arr)) * 100;
};

// file attachment custom hook
export const uploadFile = async (formData) => {
  const result = await endpointDocumentApi.post(
    "/v1/document/0/upload",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result.data;
};

// file attachment show api
export const showFile = async (showFileData) => {
  const result = await endpointDocumentApi.get("/v1/document/0/attachments", {
    params: showFileData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return result.data;
};

export const deleteFile = async (showFileData) => {
  const result = await endpoint.post("/api/attachment/delete", showFileData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return result.data;
};
export const isSuperAdmin = () => {
  let bool = localStorage.getItem("is_super_admin");
  // eslint-disable-next-line
  return bool == "true" ? true : false;
};

export const arrrayOfObjectToArray = (arr, key) => {
  let newArr = arr?.map((item) => item[key]);
  return newArr;
};

// ----------------------------
// export const isSettings = () => {
//   return hasAccess("estimates_settings", "read") ||
//     hasAccess("sales_orders_settings", "read") ||
//     hasAccess("invoices_settings", "read") ||
//     hasAccess("company_base_currencies", "read") ||
//     hasAccess("employees", "read") ||
//     hasAccess("purchase_orders_settings", "read") ||
//     hasAccess("bills_settings", "read") ||
//     hasAccess("organisation_settings", "read") ||
//     hasAccess("users_and_controls", "read") ||
//     hasAccess("categories", "read") ||
//     hasAccess("policies_and_limits", "read") ||
//     hasAccess("approvals", "read") ||
//     hasAccess("expense_settings", "read") ||
//     hasAccess("reports_settings", "read") ||
//     hasAccess("advances_settings", "read") ||
//     hasAccess("trips_settings", "read") ||
//     hasAccess("projects", "read") ||
//     hasAccess("cost_center", "read") ||
//     hasAccess("merchants", "read") ||
//     hasAccess("company", "read") ||
//     hasAccess("items", "read") ||
//     hasAccess("taxes", "read") ||
//     hasAccess("tds", "read")
// }

export const isAnalytics = () => {
  return hasAccess("analytics", "read");
};

export const isAccount = () => {
  return (
    hasAccess("company_master_account", "read") ||
    hasAccess("account_transactions", "read") ||
    // -----
    hasAccess("primary_accounts", "read")
  );
};

export const isPayroll = () => {
  return hasAccess("payroll", "read");
};

export const isPurchases = () => {
  return (
    hasAccess("purchase_orders", "read") ||
    hasAccess("bills", "read") ||
    hasAccess("vendors", "read") ||
    hasAccess("payments_made", "read") ||
    // ------
    hasAccess("purchase_order_approvals", "read") ||
    hasAccess("my_purchases", "read") ||
    hasAccess("bill_approvals", "read") ||
    hasAccess("my_bills", "read") ||
    hasAccess("payments_made_pending", "read") ||
    hasAccess("payments_made_paid", "read") ||
    hasAccess("utility_bills", "read") ||
    hasAccess("my_material_receipts", "read") ||
    hasAccess("material_receipt_approvals", "read") ||
    hasAccess("quality_check", "read") ||
    hasAccess("returns", "read")
  );
};

export const isExpenses = () => {
  return (
    hasAccess("trips", "read") ||
    hasAccess("expenses", "read") ||
    hasAccess("advances", "read") ||
    hasAccess("expense_reports", "read") ||
    // ----------
    hasAccess("reports_approvals", "read") ||
    hasAccess("trips_approvals", "read") ||
    hasAccess("advances_approvals", "read")
  );
};

export const isSales = () => {
  return (
    hasAccess("estimates", "read") ||
    hasAccess("sales_orders", "read") ||
    hasAccess("invoices", "read") ||
    hasAccess("customers", "read") ||
    hasAccess("payments_received", "read") ||
    // -----
    hasAccess("estimates_approvals", "read") ||
    hasAccess("sales_orders_approvals", "read") ||
    hasAccess("invoices_approvals", "read") ||
    hasAccess("credit_notes", "read") ||
    hasAccess("my_delivery_challans", "read") ||
    hasAccess("delivery_challan_approvals", "read")
  );
};

export const isStatements = () => {
  return hasAccess("banks", "read");
};

export const isCards = () => {
  return (
    hasAccess("transfers", "read") ||
    hasAccess("inventory", "read") ||
    // ----
    hasAccess("all_cards", "read") ||
    hasAccess("my_cards", "read") ||
    hasAccess("quick_transfers", "read") ||
    hasAccess("bulk_transfers", "read") ||
    hasAccess("transfer_logs", "read") ||
    hasAccess("all_card_transactions", "read") ||
    hasAccess("my_card_transactions", "read")
  );
};

export const isTransfers = () => {
  return (
    hasAccess("company_accounts", "read") ||
    hasAccess("beneficiary_accounts", "read") ||
    hasAccess("history", "read") ||
    hasAccess("manage_beneficiaries", "read") ||
    hasAccess("bulk_payment_links", "read") ||
    hasAccess("payment_links", "read") ||
    // -----
    hasAccess("bulk_transfer_upload_payments", "read") ||
    hasAccess("bulk_transfers_history", "read") ||
    hasAccess("bulk_upload_payment_links", "read") ||
    hasAccess("bulk_payment_history", "read")
  );
};

export const isCustomisation = () => {
  return (
    hasAccess("expense_settings", "read") ||
    hasAccess("reports_settings", "read") ||
    hasAccess("advances_settings", "read") ||
    hasAccess("trips_settings", "read") ||
    hasAccess("bills_settings", "read") ||
    hasAccess("purchase_orders_settings", "read") ||
    hasAccess("invoices_settings", "read") ||
    hasAccess("sales_orders_settings", "read") ||
    hasAccess("estimates_settings", "read") ||
    hasAccess("projects", "read") ||
    hasAccess("cost_center", "read") ||
    hasAccess("merchants", "read") ||
    hasAccess("items", "read") ||
    hasAccess("taxes", "read") ||
    hasAccess("tds", "read") ||
    hasAccess("module_customisations", "read") ||
    hasAccess("credit_note_settings", "read") ||
    hasAccess("payment_received_settings", "read") ||
    hasAccess("payment_made_settings", "read") ||
    hasAccess("material_receipt_approvals_settings", "read") ||
    hasAccess("delivery_challan_approvals_settings", "read") ||
    hasAccess("delivery_challan_settings", "read") ||
    hasAccess("material_receipt_settings", "read") ||
    hasAccess("quality_check_settings", "read") ||
    hasAccess("returns_settings", "read")
  );
};

export function hasUpToOneDecimal(value) {
  const stringValue = value.toString();

  return !/^\d+(\.\d{0,2})?$/.test(stringValue);
}

export function stringCapitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const isNumber = (value) => {
  return !isNaN(value);
};
export const convertCurrency = (amount, options = {}) => {
  const { auto_currency, live_exchange_rates, manual_exchange_rates } = options;

  // Check if auto_currency is enabled and live_exchange_rates are available
  if (auto_currency && live_exchange_rates && live_exchange_rates.rate) {
    const conversionRate = live_exchange_rates.rate;
    const convertedAmount = amount * conversionRate;
    return convertedAmount.toFixed(2);
  } else if (manual_exchange_rates && manual_exchange_rates.rate) {
    const conversionRate = manual_exchange_rates.rate;
    const convertedAmount = amount * conversionRate;
    return convertedAmount.toFixed(2);
  }
};

export const reverseCurrencyConverter = (amount, options) => {
  const { auto_currency, live_exchange_rates, manual_exchange_rates } = options;
  if (Number(amount) > 0) {
    let conversionRate;

    if (auto_currency && live_exchange_rates && live_exchange_rates.rate) {
      conversionRate = 1 / live_exchange_rates.rate; // Reverse conversion rate
    } else if (manual_exchange_rates && manual_exchange_rates.rate) {
      conversionRate = 1 / manual_exchange_rates.rate; // Reverse conversion rate
    }

    const convertedAmount = amount * conversionRate;
    return convertedAmount.toFixed(2);
  }

  return 0;
};

export function getFileExtension(filename) {
  if (filename) {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }
}

export function convertString(inputString) {
  if (inputString.includes("_")) {
    let words = inputString.split("_");
    let modifiedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return modifiedString;
  } else {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  }
}

export function getCsvColumnMessage(errorsArray, csvColumnName) {
  const matchingError = errorsArray.find(
    (error) => error.csv_column_name === csvColumnName
  );

  if (matchingError) {
    return {
      isPresent: true,
      message: matchingError.message,
    };
  } else {
    return {
      isPresent: false,
      message: "",
    };
  }
}

export function hasEmptyMandatoryFields(array) {
  return array.some((page) =>
    page.entity_columns.some((column) =>
      column.entity_list.some(
        (entity) =>
          entity.is_mandatory &&
          ["", " ", null, undefined]?.includes(entity.csv_column)
      )
    )
  );
}

export const valueMapping = {
  bills_billing_no: "Bill No",
  bills_ref_purchase_order_no: "Reference No",
  bills_due_date: "Payment Due date",
  bills_aging_billing_no: "Bill No",
  bills_aging_ref_purchase_order_no: "Reference No",
  bills_aging_due_date: "Payment Due date",
  payment_made_billing_no: "Bill No",
  transaction_number: "Transaction ID",
  transaction_amount: "Amount",
  report_number: "Report ID",
  pending_reimbursement: "Pending Reimbursement",
  pending_approval: "Pending Approval",
  payment_pending: "Payment Pending",
  partially_paid: "Partially Paid",
  min: "Greater Than",
  max: "Less Than",
  start_date: "Start Date",
  end_date: "End Date",
  po_due_date: "Expiry Date",
  sales_order_po_due_date: "Expected Shipment Date",
  invoice_status: "Status",
  invoice_no: "Estimate No",
  trip_number: "Trip ID",
  ref_po_no: "Reference Number",
  invoice_date: "Date",
  due_date: "Due Date",
  sales_order_invoice_date: "Sales Order Date",
  invoices_invoice_date: "Invoice Date",
  invoices_po_due_date: "Due Date",
  total_tax_amount: "Tax Amount",
  total_amount: "Amount",
  total_discount: "Discount",
  billing_date: "Billing Date",
  purchase_order_due_date: "Expected Delivery Date",
  purchase_order_billing_no: "Purchase Order No",
  purchase_order_total_amount: "Total Amount",
  purchase_order_billing_date: "Purchase Order Date",
  invoice_aging_invoice_no: "Invoice No",
  invoice_aging_invoice_date: "Invoice Date",
  payments_received_invoice_date: "Invoice Date",
  invoices_invoice_no: "Invoice No",
};

export function transformFilters(filters, type) {
  if (typeof filters === "object" && filters !== null) {
    const transformedFilters = {};

    Object.keys(filters).forEach((key) => {
      let value = filters[key];

      if (value instanceof moment) {
        value = moment(value).format("DD-MM-YYYY");
      }

      const transformedKey =
        valueMapping[type + "_" + key] || valueMapping[key] || key;

      transformedFilters[transformedKey] = value;
    });

    return transformedFilters;
  }

  return filters;
}
// on boarding fields rendering object function
export function getSectionPageFields(sectionDetails, section_order) {
  const section = sectionDetails?.page_details
    ?.flatMap((page) => page?.sections)
    ?.find((section) => section?.section_order == section_order);
  return section ? section : null;
}

export const ExcelDateToJSDate = (date) => {
  if (moment(date, "DD-MM-YYYY").isValid()) {
    return date;
  } else {
    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    let day = converted_date.getDate();
    let month = converted_date.getMonth() + 1; // Month is zero-based, so add 1
    let year = converted_date.getFullYear();

    // Format the date as DD-MM-YYYY
    return `${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}-${year}`;
  }
};

export function incrementIfNumber(strNumber) {
  const number = parseFloat(strNumber);
  if (!isNaN(number) && number != -1) {
    return number + 1;
  } else {
    return 0;
  }
}

export function getTotalSections(obj) {
  let totalSections = 0;
  if (Array.isArray(obj?.page_details)) {
    obj?.page_details?.forEach((pageDetail) => {
      if (Array.isArray(pageDetail?.sections)) {
        totalSections += pageDetail?.sections?.length;
      }
    });
  }

  return totalSections;
}

export function fetchThirdPartyAPIs(
  type,
  value,
  setLoader,
  setValue,
  parentKey,
  clearErrors
) {
  switch (type) {
    case "bank_details":
      setLoader(true);
      axios
        .get(apis.getIfscDetails, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            ifsc_code: value?.toUpperCase(),
          },
        })
        .then((res) => {
          if (!res?.data?.error) {
            setValue(`${parentKey}.${"bank_name"}`, res?.data?.data?.bank_name);
            setValue(`${parentKey}.${"bank_branch"}`, res?.data?.data?.branch);
            setValue(
              `${parentKey}.${"bank_address"}`,
              res?.data?.data?.address
            );
            clearErrors(`${parentKey}.${"bank_name"}`);
            clearErrors(`${parentKey}.${"bank_branch"}`);
            clearErrors(`${parentKey}.${"bank_address"}`);
          } else {
            setValue(`${parentKey}.${"bank_name"}`, "");
            setValue(`${parentKey}.${"bank_branch"}`, "");
            setValue(`${parentKey}.${"bank_address"}`, "");
            if (value) {
              message.error(
                <span className="messageText">Bank Details not found.</span>
              );
            }
          }
          setLoader(false);
        })
        .catch(() => {
          if (value) {
            message.error(
              <span className="messageText">Bank Details not found.</span>
            );
          }
          setValue(`${parentKey}.${"bank_name"}`, "");
          setValue(`${parentKey}.${"bank_branch"}`, "");
          setValue(`${parentKey}.${"bank_address"}`, "");
          setLoader(false);
        });
      break;
    case "zipcode":
      setLoader(true);
      axios
        .get(
          "https://api.worldpostallocations.com/pincode?postalcode=" +
            value +
            "&countrycode=" +
            "IN"
        )
        .then((res) => {
          if (!res?.data?.error) {
            console.log("ifsc bank details bank address", {
              address: res?.data?.result,
              res: res,
            });
            setValue(`${parentKey}.${"state"}`, res?.data?.result?.[0]?.state);
            setValue(
              `${parentKey}.${"city"}`,
              res?.data?.result?.[0]?.district
            );
            setValue(
              `${parentKey}.${"business_country"}`,
              res?.data?.result?.[0]?.country
            );

            clearErrors(`${parentKey}.${"state"}`);
            clearErrors(`${parentKey}.${"city"}`);
            clearErrors(`${parentKey}.${"business_country"}`);
          } else {
            setValue(`${parentKey}.${"state"}`, "");
            setValue(`${parentKey}.${"city"}`, "");
            setValue(`${parentKey}.${"business_country"}`, "");
            if (value) {
              message.error(
                <span className="messageText">Address not found.</span>
              );
            }
          }
          setLoader(false);
        })
        .catch(() => {
          if (value) {
            message.error(
              <span className="messageText">Address not found.</span>
            );
          }
          setValue(`${parentKey}.${"state"}`, "");
          setValue(`${parentKey}.${"city"}`, "");
          setValue(`${parentKey}.${"business_country"}`, "");
          setLoader(false);
        });
      break;
    default:
      return "";
  }
}

export const commonHandleSearch = (
  dispatch,
  setPage,
  setFilters,
  filters,
  searchKey,
  apiEndpoint,
  params
) => {
  dispatch(
    apiEndpoint({
      ...filters,
      search: searchKey,
      ...params,
    })
  );
  setPage(1);
  setFilters({
    ...filters,
    search: searchKey,
  });
};

export const timeFormat = (time) => {
  const tf = localStorage.getItem("time_format");
  if (tf === "24") {
    return moment(time).format("DD MMM YYYY, HH:mm:ss");
  }
  return moment(time).format("DD MMM YYYY, hh:mm:ss a");
};

export function calculatePercentageAmt(amount, percent) {
  let p = amount * (percent / 100);
  return p;
}
