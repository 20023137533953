import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import {
  CreateCustomer,
  CreateInvoice,
  Customers,
  Invoices,
  PortalDashboard,
  PortalRecordDetails,
  PortalTableData,
  ShowProfile,
  SubmitInvoice,
  UploadProfile,
  ViewCustomer,
  PortalDashboardCumpanyList,
} from "./customers";

export function getCustomers(req) {
  let type = req.payload?.type;
  let page = req.payload?.page_number;
  let isName = req.payload?.isName;
  delete req.payload.type;
  delete req.payload.page_number;
  delete req.payload.isName;

  return sagaFunctions(
    Customers,
    isName ? "get" : "post",
    isName
      ? `${apis.vendorsByName}?type=${type}`
      : `${apis.getAllBeneficiaries}?type=${type}&page_number=${page}`,
    req.payload,
    {},
    true
  )();
}

export function createCustomers(req) {
  const isCustomer = req?.payload?.is_customer;
  delete req.payload.is_customer;
  return sagaFunctions(
    CreateCustomer,
    "post",
    `${
      isCustomer
        ? apis?.CustomerPortalProfileUpdateAPI
        : req.payload.beneficiary_id
        ? apis?.updateBeneficiary
        : apis?.createBeneficiary
    }`,
    req.payload,
    {},
    true
  )();
}

export function viewCustomers(req) {
  return sagaFunctions(
    ViewCustomer,
    "get",
    apis.getBeneficiary,
    req.payload,
    {},
    true
  )();
}

function createInvoice(req) {
  const isEdit = req.payload.isEdit;
  const type = req.payload.module_type;
  const from_module = req.payload.from_module;
  let params = `?module_type=${type}`;
  if (from_module) {
    params = params + `&from_module=${from_module}`;
  }
  const isCredit = type === "CREDIT";
  const url = isEdit
    ? isCredit
      ? apis.updateCreditDebitNote
      : apis.updateInvoice
    : isCredit
    ? apis.createCreditDebitNotes
    : apis.createInvoice;
  delete req.payload.isEdit;
  delete req.payload.module_type;
  return sagaFunctions(
    CreateInvoice,
    "post",
    `${url}${params}`,
    req.payload,
    {},
    true
  )();
}

export function getInvoices(req) {
  let type = req.payload?.type;
  let page = req?.payload?.page_number
    ? `&page_number=${req?.payload?.page_number}`
    : "";
  let view = req?.payload?.view ? `&view=${req?.payload?.view}` : "";
  delete req.payload?.page_number;
  delete req.payload?.type;
  const request = `${
    type === "CREDIT" ? apis.creditDebitNotes : apis.filterInvoice
  }?module_type=${type}${view}${page}`;
  return sagaFunctions(Invoices, "post", request, req.payload, {}, true)();
}

export function submitInvoice(req) {
  let type = req.payload.module_type;
  const isCredit = type === "CREDIT";
  delete req.payload.module_type;
  return sagaFunctions(
    SubmitInvoice,
    "post",
    `${
      isCredit ? apis.submitCreditDebitNote : apis.submitInvoice
    }?module_type=${type}`,
    req.payload,
    { "Content-Type": "application/json" },
    true
  )();
}

export function uploadProfile(req) {
  console.log("sage customer api", req?.payload);
  let recordId = req.payload.recordId;
  delete req.payload.recordId;

  return sagaFunctions(
    UploadProfile,
    recordId ? "delete" : "post",
    recordId
      ? apis.deleteProfile + `?record_id=${recordId}`
      : apis.uploadProfile,
    req.payload,
    { "Content-Type": "application/json" }
  )();
}
function showProfile(req) {
  return sagaFunctions(ShowProfile, "get", apis.showProfile, req.payload, {})();
}

// customer portal
function portalTableData(req) {
  let API;
  if (["BILL", "PURCHASE_ORDER"]?.includes(req?.payload?.params?.module_type)) {
    API = apis.vendorPortalTableAPI;
  } else if (["BILL_RECEIPT"]?.includes(req?.payload?.params?.module_type)) {
    API = apis.vendorPortalTablePaymentAPI;
  } else if (["INVOICE_RECEIPT"]?.includes(req?.payload?.params?.module_type)) {
    API = apis.portalTableDataPaymentAPI;
  } else {
    API = apis.portalTableDataAPI;
  }
  return sagaFunctions(PortalTableData, "post", API, req.payload, {}, true)();
}

function portalRecordDetails(req) {
  let API;
  if (["BILL", "PURCHASE_ORDER"]?.includes(req?.payload?.module_type)) {
    API = apis.vendorPortalTableRecordAPI;
  } else if (["BILL_RECEIPT"]?.includes(req?.payload?.module_type)) {
    API = apis.vendorPortalRecordPaymentAPI;
  } else if (["INVOICE_RECEIPT"]?.includes(req?.payload?.module_type)) {
    API = apis.portalRecordDetailsPaymentAPI;
  } else {
    API = apis.portalRecordDetailsAPI;
  }
  return sagaFunctions(
    PortalRecordDetails,
    "get",
    API,
    req.payload,
    {},
    true
  )();
}

function portalDashboard(req) {
  return sagaFunctions(
    PortalDashboard,
    "get",
    localStorage?.getItem("isCustomer")
      ? apis.porttalDashboardAPI
      : apis.VendorportalDashboardAPI,
    req.payload,
    {},
    true
  )();
}
function portalDashboardCumpanyList(req) {
  return sagaFunctions(
    PortalDashboardCumpanyList,
    "get",
    apis.porttalDashboardCumpanyListAPI,
    req.payload,
    {},
    true
  )();
}

export function* customersWatcher() {
  yield takeLatest(Customers.REQUEST, getCustomers);
  yield takeLatest(ViewCustomer.REQUEST, viewCustomers);
  yield takeLatest(CreateCustomer.REQUEST, createCustomers);
  yield takeLatest(Invoices.REQUEST, getInvoices);
  yield takeLatest(CreateInvoice.REQUEST, createInvoice);
  yield takeLatest(SubmitInvoice.REQUEST, submitInvoice);
  yield takeLatest(UploadProfile.REQUEST, uploadProfile);
  yield takeLatest(ShowProfile.REQUEST, showProfile);
  yield takeLatest(PortalTableData.REQUEST, portalTableData);
  yield takeLatest(PortalRecordDetails.REQUEST, portalRecordDetails);
  yield takeLatest(PortalDashboard.REQUEST, portalDashboard);
  yield takeLatest(
    PortalDashboardCumpanyList.REQUEST,
    portalDashboardCumpanyList
  );
}
