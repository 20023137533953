import { ReducerFunctions } from '../../utils';
import { GetItems, GetReceipts, GetTaxItems, PaymentMade, PaymentTerms, PlaceOfSupplies, ReceiptsBYID, ReceiptsCreate, ReceiptsGet, ReceiptsSubmit, SaveBeneficiary, SavePlaceSupplies, UpdateBeneficiary, Vendors, VendorsById, VendorsByName, VendorTDS } from './purchases';

export const vendors_reducer = ReducerFunctions(Vendors, {});
export const place_of_supplies_reducer = ReducerFunctions(PlaceOfSupplies, {});
export const vendors_by_name_reducer = ReducerFunctions(VendorsByName, {});
export const vendors_by_id_reducer = ReducerFunctions(VendorsById, {});
export const verdors_tds_reducer = ReducerFunctions(VendorTDS, {});
export const verdors_payment_terms = ReducerFunctions(PaymentTerms, {});
export const save_beneficiary = ReducerFunctions(SaveBeneficiary, {});
export const update_beneficiary = ReducerFunctions(UpdateBeneficiary, {});
export const save_place_supplies = ReducerFunctions(SavePlaceSupplies, {});
export const get_receipts_reducer = ReducerFunctions(GetReceipts, {});
export const payment_made_reducer = ReducerFunctions(PaymentMade, {});
export const get_items_reducer = ReducerFunctions(GetItems, {});
export const get_tax_items_reducer = ReducerFunctions(GetTaxItems, {});

// receipts reducer start
export const receipts_get_reducer = ReducerFunctions(ReceiptsGet, {});
export const receipts_by_id_reducer = ReducerFunctions(ReceiptsBYID, {});
export const receipts_create_reducer = ReducerFunctions(ReceiptsCreate, {});
export const receipts_submit_reducer = ReducerFunctions(ReceiptsSubmit, {});
