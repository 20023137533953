import { Menu } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SideMenu, headerTitles } from "../../../actions/masterdata/masterdata";
import { receiptsGet } from "../../../actions/purchases";
import { amountFormat } from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import TableComponent from "../../Common/TableComp/TableComp";
import TableHeader from "../../Sales/TableHeader";

const Receipt = ({
  hasWriteAccess,
  isDelivery,
  isMaterial,
  isMaterialApp,
  isDeliveryApp,
  isQCcheck,
  isReturns,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [type, setType] = useState({});
  const [filters, setFilters] = useState({});
  const receiptsData = useSelector(
    (state) => state?.purchases?.receiptsGet?.data
  );
  useEffect(() => {
    let title = "";
    let description = "";
    let params = {};
    if (isMaterial) {
      title = t("material_receipt");
      description = t("material_rec_desc");
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "MATERIAL_RECEIPT",
        };
      });
      params = {
        module_type: "MATERIAL_RECEIPT",
      };
    } else if (isMaterialApp) {
      title = t("apprvolas");
      description = t("material_rec_app_desc");
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "MATERIAL_RECEIPT",
          view: "admin",
        };
      });
      params = {
        module_type: "MATERIAL_RECEIPT",
        view: "admin",
      };
    } else if (isDelivery) {
      title = t("delivery_challan");
      description =
        "A Delivery Challan is a document sent to your customers confirming the items and prices being delivered.";
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "DELIVERY_CHALLAN",
        };
      });
      params = {
        module_type: "DELIVERY_CHALLAN",
      };
    } else if (isDeliveryApp) {
      title = t("apprvolas");
      description = "An action of approving/rejecting the Delivery Challan";
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "DELIVERY_CHALLAN",
          view: "admin",
        };
      });
      params = {
        module_type: "DELIVERY_CHALLAN",
        view: "admin",
      };
    } else if (isQCcheck) {
      title = t("QC Check");
      description = "Manage the qc check against the orders";
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "QUALITY_CHECK",
        };
      });
      params = {
        module_type: "QUALITY_CHECK",
      };
    } else if (isReturns) {
      title = t("Returns");
      description = "Manage the returns against the orders";
      setType((prevData) => {
        return {
          ...prevData,
          module_type: "RETURN_CHECK",
        };
      });
      params = {
        module_type: "RETURN_CHECK",
      };
    }
    dispatch(
      headerTitles({
        title: title,
        description: description,
      })
    );
    dispatch(
      receiptsGet({
        params: { ...params },
      })
    );
    setFilters({});
  }, [
    isDelivery,
    isMaterial,
    isMaterialApp,
    isDeliveryApp,
    isQCcheck,
    isReturns,
  ]);

  const columns = useMemo(() => {
    let baseColumns = [
      {
        title: t("date"),
        dataIndex: "created_at",
        key: "created_at",
        render: (text) => <div>{moment(text)?.format("DD MMMM YY")}</div>,
      },
      {
        title: `${t("vendor_name")}`,
        dataIndex: isQCcheck || isReturns ? "beneficiaries" : "vendor_name",
        key: isQCcheck || isReturns ? "beneficiaries" : "vendor_name",
        render: (text) => (
          <div>{isQCcheck || isReturns ? text?.beneficiary_name : text}</div>
        ),
      },
      {
        title: t("material_rec_no"),
        dataIndex: "mr_no",
        key: "mr_no",
      },
      {
        title: t("PO Number"),
        dataIndex: "ref_purchase_order_no",
        key: "ref_purchase_order_no",
      },
      {
        title: t("status"),
        dataIndex:
          isMaterialApp || isDeliveryApp ? "role_status" : "invoice_status",
        key: isMaterialApp || isDeliveryApp ? "role_status" : "invoice_status",
        render: (text, record) => (
          <div className={`status status_${text}`}>
            {text ? text?.replace(/_/g, " ") : ""}
          </div>
        ),
      },
    ];
    if (isDelivery || isDeliveryApp) {
      return [
        ...baseColumns.slice(0, 1),
        {
          title: t("delivery_challan"),
          dataIndex: "dc_no",
          key: "dc_no",
        },
        {
          title: t("reference_number"),
          dataIndex: "ref_po_no",
          key: "ref_po_no",
        },
        {
          title: t("customer_name"),
          dataIndex: "customer_name",
          key: "customer_name",
        },
        {
          title: t("amount"),
          dataIndex: "total_amount",
          key: "total_amount",
          render: (text) => (
            <div>
              {" "}
              <BaseCurrency />
              {amountFormat(text)}
            </div>
          ),
        },
        ...baseColumns.slice(4),
      ];
    } else if (isQCcheck) {
      return [
        {
          title: `${t("QC#")}`,
          dataIndex: "qc_no",
          key: "qc_no",
        },
        ...baseColumns.slice(1, 2),
        ...baseColumns.slice(0, 1),
        {
          title: `${t("Material Receipt#")}`,
          dataIndex: "mr_no",
          key: "mr_no",
        },
        ...baseColumns.slice(4),
      ];
    } else if (isReturns) {
      return [
        ...baseColumns.slice(0, 2),
        {
          title: `${t("Returns#")}`,
          dataIndex: "ret_no",
          key: "ret_no",
        },
        {
          title: `${t("Material Receipt#")}`,
          dataIndex: "mr_no",
          key: "mr_no",
        },
        ...baseColumns.slice(4),
      ];
    }
    return baseColumns;
  }, [
    isDelivery,
    isMaterial,
    isMaterialApp,
    isDeliveryApp,
    isQCcheck,
    isReturns,
    t,
  ]);
  useEffect(() => {
    if (isQCcheck) {
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/purchases",
          secondNav: "/material/qc-check",
          childrenMenu: "",
          showChildren: true,
        },
      });
    } else if (isReturns) {
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/purchases",
          secondNav: "/material/returns",
          childrenMenu: "",
          showChildren: true,
        },
      });
    }
  }, [isQCcheck, isReturns]);

  const filtersOptions =
    isMaterialApp || isDeliveryApp
      ? [
          { label: t("all"), filter: "all" },
          { label: t("Approved"), filter: "approved" },
          { label: t("Pending Approval"), filter: "pending_approval" },
          { label: t("Rejected"), filter: "rejected" },
        ]
      : isMaterial || isDelivery
      ? [
          { label: t("all"), filter: "all" },
          { label: t("Unsubmitted"), filter: "unsubmitted" },
          { label: t("Approved"), filter: "approved" },
          { label: t("Pending Approval"), filter: "pending_approval" },
          { label: t("Rejected"), filter: "rejected" },
        ]
      : [
          { label: t("all"), filter: "all" },
          {
            label: isQCcheck ? t("QC Check Pending") : t("Return Pending"),
            filter:  isQCcheck ? "qc_pending" : "return_pending",
          },
          { label: t("Completed"), filter: "completed" },
        ];
  const sortOptions = [
    {
      label: t("Date"),
      filter: "created_at",
    },
  ];
  const handleFilter = (value) => {
    if (value === "all") {
      if (isMaterialApp || isDeliveryApp) {
        dispatch(
          receiptsGet({
            params: {
              module_type: type?.module_type,
              view: "admin",
              page_number: 1,
            },
          })
        );
      } else {
        dispatch(
          receiptsGet({
            params: {
              module_type: type?.module_type,
              page_number: 1,
            },
          })
        );
      }
      setFilters({
        ...filters,
        filters: {},
      });
    } else {
      if (isMaterialApp || isDeliveryApp) {
        dispatch(
          receiptsGet({
            ...filters,
            filters: { invoice_status: [value] },
            params: {
              module_type: type?.module_type,
              view: "admin",
              page_number: 1,
            },
          })
        );
      } else {
        dispatch(
          receiptsGet({
            ...filters,
            filters: { invoice_status: [value] },
            params: {
              module_type: type?.module_type,
              page_number: 1,
            },
          })
        );
      }
      setFilters({
        ...filters,
        filters: { invoice_status: [value] },
      });
    }
    setPage(1);
  };
  const handleSearch = (searchKey) => {
    if (isMaterialApp || isDeliveryApp) {
      dispatch(
        receiptsGet({
          ...filters,
          search_key: searchKey,
          params: {
            module_type: type?.module_type,
            view: "admin",
            page_number: 1,
          },
        })
      );
    } else {
      dispatch(
        receiptsGet({
          ...filters,
          search_key: searchKey,
          params: {
            module_type: type?.module_type,
            page_number: 1,
          },
        })
      );
    }

    setPage(1);
    setFilters({
      ...filters,
      search_key: searchKey,
    });
  };
  const handleSort = (value) => {
    if (isMaterialApp || isDeliveryApp) {
      dispatch(
        receiptsGet({
          ...filters,
          sort: {
            sort_key: value,
            sort_order: "1",
          },
          params: {
            module_type: type?.module_type,
            view: "admin",
            page_number: 1,
          },
        })
      );
    } else {
      dispatch(
        receiptsGet({
          ...filters,
          sort: {
            sort_key: value,
            sort_order: "1",
          },
          params: {
            module_type: type?.module_type,
            page_number: 1,
          },
        })
      );
    }
    setFilters({
      ...filters,
      sort: {
        sort_key: value,
        sort_order: "1",
      },
    });
    setPage(1);
  };
  const handleButtonClick = (event, record) => {
    if (isMaterial && hasWriteAccess) {
      history.push("/purchases/material/form");
    } else if (isDelivery && hasWriteAccess) {
      history.push("/sales/delivery/form");
    }
  };
  const handleRowClick = (event, record) => {
    let routeParams = {
      record: record,
    };
    if (isMaterialApp) {
      routeParams.isMaterialApp = true;
    } else if (isMaterial) {
      routeParams.isMaterial = true;
    } else if (isDelivery) {
      routeParams.isDelivery = true;
    } else if (isDeliveryApp) {
      routeParams.isDeliveryApp = true;
    } else if (isReturns) {
      routeParams.isReturns = true;
    } else if (isQCcheck) {
      routeParams.isQCcheck = true;
    }
    routeParams.hasWriteAccess = hasWriteAccess;
    history.push("/purchases/receipts/details", routeParams);
  };
  const handlePaginationClick = (page) => {
    if (isDeliveryApp || isMaterialApp) {
      dispatch(
        receiptsGet({
          ...filters,
          page_number: page,
          params: {
            module_type: type?.module_type,
            page_number: page,
            view: "admin",
          },
        })
      );
    } else {
      dispatch(
        receiptsGet({
          ...filters,
          page_number: page,
          params: {
            module_type: type?.module_type,
            page_number: page,
          },
        })
      );
    }
  };

  const menu = (
    <Menu onClick={() => {}}>
      {/* <Menu.Item
                    key={ele.key}
                    icon={ele.icon}
                    className="flex-around"
                    disabled={!hasWriteAccess}
                >
                    {ele.label}
                </Menu.Item> */}
    </Menu>
  );
  return (
    <div>
      <TableHeader
        menu={menu}
        filterMenu={filtersOptions}
        handleFilter={handleFilter}
        handleAddButton={handleButtonClick}
        noAddNewBtn={
          isMaterialApp || isDeliveryApp || isQCcheck || isReturns
            ? true
            : false
        }
        sortOptions={sortOptions}
        onSearch={handleSearch}
        placeholder={"Search"}
        hasWriteAccess={hasWriteAccess}
        handleSort={handleSort}
        SelectValue={filters?.search_key}
      />

      <div style={{ paddingTop: "15px" }}>
        <TableComponent
          rowKey="id"
          columns={columns}
          data={receiptsData?.data || []}
          hasCheckbox={false}
          handleRowClick={handleRowClick}
          pagination={{
            total: receiptsData?.total_count,
            current: page,
            onChange: (pagee) => {
              handlePaginationClick(pagee);
              setPage(pagee);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Receipt;
