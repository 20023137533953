import { message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { SideMenu, headerTitles } from "../../actions/masterdata/masterdata";
import { getBoolVal, objectToFormData } from "../../utils";
import { sort_key } from "../Common/Table/Table";
import moment from "moment";
import { trips, userTrips } from "../../actions/trips/trips";
import TableHeader from "../Sales/TableHeader";
import TableComponent from "../Common/TableComp/TableComp";
export const labels = {
  covered: "closed",
};

const Trips = ({ user, hasWriteAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [tripsData, setTripsData] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const tripsResponse = useSelector((state) => state.trips || {});
  // console.log("trips trips response ", {
  //   tripsResponse: userTripsResponse,
  //   tripsData: tripsData,
  //   user: user,
  //   hasWriteAccess: hasWriteAccess,
  // });

  const userTripsResponse = useSelector((state) => state.trips.userTrips || {});
  const [page, setPage] = React.useState(1);
  const is_admin = getBoolVal(localStorage.getItem("is_admin"));
  const addUserTripResponse = useSelector(
    (state) => state.trips.addUserTrip || {}
  );
  const [filters, setFilters] = React.useState({});
  const { t } = useTranslation();
  const columns = [
    {
      title: t('trip_id'),
      dataIndex: "trip_number",
      key: "number",
      render: (text) => {
        return <div className={`status-line cursor`}>{text}</div>;
      },
    },
    {
      title: t("employee_name"),
      dataIndex: "user_info",
      key: "user_info",
      render: (text) => {
        return <div>{text.name}</div>
      }
    },
    {
      title: t("trip_name"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("trip_type"),
      dataIndex: "trip_type",
      key: "trip_type",
    },
    {
      title: t("purpose"),
      dataIndex: "purpose",
      key: "purpose",
      width: "200px",
      render: (text) => {
        return (
          <div>
            {text?.length > 199 ? text?.substring(0, 200) + ". . ." : text}
          </div>
        );
      },
    },
    // {
    //   title: "Created On",
    //   dataIndex: "created_at",
    //   key: "created_at",
    // },
    {
      title: t('submittedOn'),
      dataIndex: "submitted_date",
      key: "submitted_on",
      render: (text) => {
        return text && <div> {moment(text).format("DD MMM, YYYY hh:mm A")}</div>
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          // className={`status status_${is_admin ? record?.status : (labels[text] ? labels[text] : text)}`}
          className={`status status_${
            // location?.state?.user? 
            user ?
              record?.status
              : record?.role_status
            }`}
        >
          {/* {is_admin ? record?.status?.replace(/_/g, " ") : (labels[text] ? labels[text]?.replace(/_/g, " ") : text?.replace(/_/g, " "))} */}
          {user ? record?.status?.replace?.(/_/g, " ")
            : record?.role_status?.replace?.(/_/g, " ")}
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    if (location?.state?.filter) {
      handleFilter(location?.state?.filter);
    } else {
      let obj = {
        view: "admin",
        module_type: "TRIP",
        page: 1,
        per_page: 10
      }
      if (user) {
        delete obj.view;
      }
      dispatch(trips(obj))
    }
    dispatch(
      headerTitles({
        title: "trips",
        description: "",
      })
    );
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/expenses",
        childrenMenu: user ? "/my-trips" : "",
        showChildren: true,
        secondNav: "/trips",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);
  React.useEffect(() => {
    const { trips: { data: { data: tripsList = [], status = false, total_count = 0 } = {} } = {}, } = tripsResponse;
    if (status) {
      setTripsData(tripsList);
      setTotalRecords(total_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripsResponse]);
  console.log({ l: userTripsResponse?.data, user: user }, "triplistData===>");

  React.useEffect(() => {
    const {
      data: { response: { trips: tripsList = [] } = {}, status = false } = {},
    } = userTripsResponse;
    if (status) {
      setTripsData(tripsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTripsResponse]);

  React.useEffect(() => {
    const { data: { status = false, message: res_message = "" } = {} } =
      addUserTripResponse;
    if (status) {
      message.success(<span className='messageText'>{res_message}</span>);
      dispatch(userTrips(objectToFormData({ status: true })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserTripResponse]);

  const handleRowClick = (evt, record) => {
    evt.preventDefault();
    if (record) {
      history.push({
        pathname: `/expenses/trip-details/${record.id}`,
        state: {
          tripData: record,
          filter: filters.status,
          from: location.pathname,
          user,
        },
      });
    }
  };

  const handleAddButton = () => {
    if (hasWriteAccess) {
      history.push({ pathname: `/expenses/add-trip/` });
    }
  };

  const handleFilter = (value) => {
    if (value === "true") {
      let obj = {
        view: "admin",
        module_type: "TRIP",
        page: 1,
        per_page: 10
      }
      if (user) {
        delete obj.view;
      }
      dispatch(trips(obj))
      setFilters({
        ...filters,
        filters: {}
      });
    } else {
      let obj = {
        view: "admin",
        module_type: "TRIP",
        page: 1,
        per_page: 10,
        ...filters,
        filters: {
          status: [value],
        },
      }
      if (user) {
        delete obj.view;
      }
      dispatch(trips(obj, {
        ...filters,
        filters: {
          status: [value],
        }
      }));
      setFilters({
        ...filters,
        filters: {
          status: [value],
        },
      });
    }
    setPage(1);
  };

  const handleSearch = (value) => {
    let obj = {
      view: "admin",
      module_type: "TRIP",
      page: 1,
      per_page: 10,
      search_key: value,
    }
    if (user) {
      delete obj.view;
    }
    dispatch(trips(obj))

    setFilters({
      ...filters,
      search_key: value,
    });
    setPage(1);
  };

  const common = [
    { label: t("all_trips"), filter: "true" },
    { label: t("approval_pending"), filter: "pending_approval" },
    // { label: t("cancelled"), filter: "cancelled" },
    { label: t("approved"), filter: "approved" },
    { label: t("rejected"), filter: "rejected" },
    { label: t("closed"), filter: "closed" },
  ];

  const sortOptions = ["title", "submitted_date", "purpose"]
  const options = !user
    ? common
    : [...common, { label: t("un_submitted"), filter: "unsubmitted" }, { label: t("cancelled"), filter: "cancelled" }];

  // const sortOptions = ["trip_name", "submitted_on", "purpose"];

  const sort_opts = sortOptions.map((ele) => ({
    label: (
      <span
        style={{
          textTransform: "capitalize",
          color: "#333",
        }}
      >
        {sort_key[ele] ? sort_key[ele] : ele?.replace("_", " ")}
      </span>
    ),
    filter: ele,
  }));

  return (
    <>
      {/* <Table
        rowKey="id"
        columns={columns}
        data={tripsData}
        handleRowClick={handleRowClick}
        handleFilter={handleFilter}
        handleSearch={handleSearch}
        placeholder={"Search By Trip ID, Name"}
        isTopFilter={true}
        handleAddButton={handleAddButton}
        !user={localStorage.getItem("!user")}
        module="Trip"
        dotMenu={["trip_name", "submitted_on", "purpose"]}
        pagination={{
          total: (getBoolVal(localStorage.getItem("!user")) ?
            tripsResponse?.data?.total_records : userTripsResponse?.data?.total_records) || 0,
          showSizeChanger: false,
          current: page,
          onChange: (pagee) => {
           !user
              ? dispatch(trips({ page: pagee, ...filters }))
              : dispatch(userTrips({ page: pagee, ...filters }));
            setPage(pagee);
          },
        }}
        handleSort={(key) => {
          if (key === "purpose") {
            key = "destination"
          }
         !user
            ? dispatch(trips({ sort_key: key, ...filters }))
            : dispatch(userTrips({ sort_key: key, ...filters }));
          setFilters({
            ...filters,
            sort_key: key,
          });
          setPage(1)

        }}
        activeButton={location?.state?.filter}
        topFilters={!user ? common : [
          ...common,
          { label: "Unsubmitted", filter: "unsubmitted" },
        ]}
      /> */}

      <TableHeader
        noDD={true}
        defaultFilterValue={filters?.filters?.status?.[0]}
        filterMenu={options}
        handleFilter={handleFilter}
        noAddNewBtn={!user}
        handleAddButton={handleAddButton}
        sortOptions={sort_opts}
        onSearch={handleSearch}
        placeholder={t("search_by_trip")}
        hasWriteAccess={hasWriteAccess}
        handleSort={(key) => {
          console.log(key, "keyDetails===>");
          let obj = {
            view: "admin",
            module_type: "TRIP",
            page: 1,
            per_page: 10,
            ...filters,
            sort: {
              sort_key: key,
              sort_order: "1",
            },
          };
          if (user) {
            delete obj.view;
          }
          dispatch(trips(obj));
          setFilters({
            ...filters,
            sort: {
              sort_key: key,
              sort_order: "1",
            },
          });
          setPage(1);
        }}
      />
      <div style={{ paddingTop: "15px" }}>
        <TableComponent
          rowKey="id"
          columns={columns}
          data={tripsData ? tripsData : []}
          hasCheckbox={false}
          handleRowClick={handleRowClick}
          hasWriteAccess={hasWriteAccess}
          // selectedRows={selectedRows}
          // setSelectedRows={setSelectedRows}
          scroll={{
            x: 1300,
          }}
          pagination={{
            total:
              // !user? 
              tripsResponse.trips?.data?.total_count,
            // : userTripsResponse?.data?.total_records,
            current: page,
            onChange: (pagee) => {
              let obj = {
                view: "admin",
                module_type: "TRIP",
                page_number: pagee,
                per_page: 10,
                ...filters,
              };
              if (user) {
                delete obj.view;
              }
              dispatch(trips(obj));

              setPage(pagee);
            },
          }}
        />
      </div>
    </>
  );
};

export default Trips;
