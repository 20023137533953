import { ActionTypesFactory } from "../../utils";


export const Expenses = ActionTypesFactory("Expenses", "Expenses");
export const AddExpense = ActionTypesFactory("AddExpense", "Expenses");
export const UpdateExpense = ActionTypesFactory("UpdateExpense", "Expenses");
export const ValidateExpense = ActionTypesFactory("ValidateExpense", "Expenses");
export const ExpenseViolations = "EXPENSE_VIOLATIONS";
export const ExpenseViolationsReset = "EXPENSE_VIOLATIONS_RESET";
export const ExpenseDetails = ActionTypesFactory("ExpenseDetails", "Expenses");
export const ExpenseCustomDetails = ActionTypesFactory("ExpenseCustomDetails","Expenses");
export const ExpenseFields = ActionTypesFactory("ExpenseFields", "Expenses");
export const PrefixExpenseFields = ActionTypesFactory("PrefixExpenseFields", "Expenses");
export const CreateExpensePolicies = ActionTypesFactory("CreateExpensePolicies", "Expenses");
export const ListExpensePolicies = ActionTypesFactory("ListExpensePolicies", "Expenses");
export const DeleteExpensePolicies = ActionTypesFactory("DeleteExpensePolicies", "Expenses");
export const DeleteExpense = ActionTypesFactory("DeleteExpense", "Expenses");
export const EditExpensePolicies = ActionTypesFactory("EditExpensePolicies", "Expenses");
export const ShowExpensePolicies = ActionTypesFactory("ShowExpensePolicies", "Expenses");
export const ExpenseComments = ActionTypesFactory("ExpenseComments", "Expenses");

export const expenses = (payload) => {
  return {
    type: Expenses.REQUEST,
    payload,
  };
};

export const expenseComments = (payload) => {
  return {
    type: ExpenseComments.REQUEST,
    payload,
  };
};


export const show_expense_policy = (payload) => {
  return {
    type: ShowExpensePolicies.REQUEST,
    payload,
  }
}

export const expensesReset = (payload) => {
  return {
    type: Expenses.RESET,
    payload,
  };
};

export const addExpense = (payload, otherParams) => {
  return {
    type: AddExpense.REQUEST,
    payload,
    otherParams
  };
};

export const updateExpense = (payload, otherParams) => {
  return {
    type: UpdateExpense.REQUEST,
    payload,
    otherParams,
  };
};

export const validateExpense = (payload, otherParams) => {
  return {
    type: ValidateExpense.REQUEST,
    payload,
    otherParams,
  };
};

export const expenseDetails = (payload, otherParams) => {
  return {
    type: ExpenseDetails.REQUEST,
    payload,
    otherParams
  };
};

export const expenseCustomDetails = (payload, otherParams) => {
  return {
    type: ExpenseCustomDetails.REQUEST,
    payload,
    otherParams,
  };
};

export const expenseFields = (payload, otherParams) => {
  return {
    type: ExpenseFields.REQUEST,
    payload,
    otherParams
  };
};

export const prefixexpenseFields = (payload, otherParams) => {
  return {
    type: PrefixExpenseFields.REQUEST,
    payload,
    otherParams
  };
};

export const create_expense_policies = (payload) => {
  return {
    type: CreateExpensePolicies.REQUEST,
    payload,
  };
};

export const expense_policies_list = (payload) => {
  return {
    type: ListExpensePolicies.REQUEST,
    payload,
  };
};

export const expense_policies_delete = (payload) => {
  return {
    type: DeleteExpensePolicies.REQUEST,
    payload,
  };
};

export const expenses_delete = (payload) => {
  return {
    type: DeleteExpense.REQUEST,
    payload,
  };
};


export const edit_expense_policies = (payload) => {
  return {
    type: EditExpensePolicies.REQUEST,
    payload,
  };
};


