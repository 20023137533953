import { Button, Col, Row } from 'antd';
import pdf_icon from "../../../assets/icons/pdf_icon.png"
import excel_icon from "../../../assets/icons/excel1.png";
import tiff_icon from "../../../assets/icons/tifficon.png";
import ppt_icon from "../../../assets/icons/ppticon.png";
import React from 'react';
import moment from 'moment';

const BillData = ({ label, value, documenttype, isDate = false }) => {
  const handleDownloadClick = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };
  console.log(documenttype, "typeDetails==>");
  return (
    <Row style={{ display: 'flex', marginTop: '17px' }}>
      <Col span={12}>
        <span style={{ color: '#262626', fontSize: '1.1rem', lineHeight: '24px', fontWeight: "medium" }}>
          {label}
        </span>
      </Col>

      {documenttype === "document" ?
        <Col>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "2rem" }}>
            {value && value?.map((eachImg) => (
              eachImg?.content_type === "image/jpg" || eachImg?.content_type === "image/jpeg" || eachImg?.content_type === "image/png" ? (
                <div key={eachImg.id} onClick={() => handleDownloadClick(eachImg.attachment_url)}>
                  <img
                    style={{ width: "5rem", height: "5rem", margin: "1rem" }}
                    src={eachImg.attachment_url}
                    alt=""
                  />
                  <p title={eachImg.filename} className="customfield-text">{eachImg.filename}</p>
                  <Button style={{ width: "auto", color: "black" }} className="formButton mt1">Download</Button>
                </div>
              ) :
                <div style={{ display: "flex", flexDirection: "column" }} onClick={() => handleDownloadClick(eachImg.attachment_url)}>
                  {eachImg.content_type === "application/pdf" ?
                    <img src={pdf_icon} style={{ width: "5rem", height: "5rem", margin: "1rem" }} /> :
                    eachImg?.content_type === "image/tiff" ? <img src={tiff_icon} style={{ width: "5rem", height: "5rem", margin: "1rem" }} /> :
                      eachImg?.content_type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ?
                        <img src={ppt_icon} style={{ width: "5rem", height: "5rem", margin: "1rem" }} /> :
                        <img src={excel_icon} style={{ width: "5rem", height: "5rem", margin: "1rem" }} />
                  }
                  <p title={eachImg.filename} className="customfield-text">{eachImg.filename}</p>
                  <Button style={{ width: "7rem", color: "black" }} className="formButton mt1">Download</Button>
                </div>
            ))}
          </div>
        </Col> :
        documenttype === "datetime" ?
          <Col>
            <span style={{ color: '#333333', fontSize: '1.1rem', lineHeight: '24px', fontWeight: "normal" }}>
              {value ? moment(value).format("DD MMM YYYY") : "-"}
            </span>
          </Col> :
          <Col span={12}>
            <span style={{ color: '#333333', fontSize: '1.1rem', lineHeight: '24px', fontWeight: "normal" }}>
              {value ? value : "-"}
            </span>
          </Col>
      }
    </Row>
  )
}

export default BillData;