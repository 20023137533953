import { SyncOutlined } from '@ant-design/icons';
import React from "react";
import { toast } from 'react-toastify';

export class ToastUtils {

  static successMessage(title, message) {
    toast.success(
      <div className='toastify-body'>
        <SyncOutlined />
        <div className='toastify-content'>
          <div className='toastify-title'>
            {title}
          </div>
          <div className='toastify-message'>
            {message}
          </div>
        </div>
      </div>,
      {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      }
    )
  }

  static warnMessage(title, message) {
    toast.warn(
      <div className='toastify-body'>
        <SyncOutlined />
        <div className='toastify-content'>
          <div className='toastify-title'>
            {title}
          </div>
          <div className='toastify-message'>
            {message}
          </div>
        </div>
      </div>,
      {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    )
  }
}
