import { ReducerFunctions } from "../../utils";
import { HeaderTitles, SideMenu } from "../masterdata/masterdata";
import { ExpenseViolations, ExpenseViolationsReset } from "../expenses/expenses";
import {
  Login,
  Logout,
  ResetPassword,
  ForgotPassword,
  ChangePassword,
  Local_Variables,
  Notifications,
  RequestDemo,
  ChangeLanguage,
  Collapsed,
  Is_Login
} from "./auth";

export const login_reducer = ReducerFunctions(Login, {});
export const notifications_reducer = ReducerFunctions(Notifications, {});
export const logout_reducer = ReducerFunctions(Logout, {});
export const resetPassword_reducer = ReducerFunctions(ResetPassword, {});
export const forgotPassword_reducer = ReducerFunctions(ForgotPassword, {});
export const changePassword_reducer = ReducerFunctions(ChangePassword, {});
export const request_demo_reducer = ReducerFunctions(RequestDemo, {});

export const local_varaibles = (state = { is_admin: "", policyViolations: [], policyModal: false, language: "en", is_login: false }, action) => {
  switch (action.type) {
    case Local_Variables:
      return {
        ...state,
        ...action.payload,
      };
    case HeaderTitles:
      return {
        ...state,
        titles: action.payload
      }
    case SideMenu:
      return {
        ...state,
        side_menu: action.payload
      }
    case ChangeLanguage:
      localStorage.setItem("language", action.payload);
      return {
        ...state,
        language: action.payload
      }
    case Collapsed:
      return {
        ...state,
        collapsed: action.payload
      }
    case ExpenseViolations: {
      return { ...state, ...action.payload }
    }
    case ExpenseViolationsReset: {
      return {
        ...state,
        policyViolations: [],
        policyModal: false
      }
    }
    case Is_Login: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state;
  }
};
