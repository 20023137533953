import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Steps,
  Upload,
  message,
} from "antd";
import { Formik, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import pdf_icon from "../../assets/icons/pdf_icon.png";
import excel_icon from "../../assets/icons/excel1.png";
import tiff_icon from "../../assets/icons/tifficon.png";
import ppt_icon from "../../assets/icons/ppticon.png";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { deleteEmployeeDocument } from "../../actions/employees";
import {
  AddUserTrip,
  TripsShow,
  UpdateUserTrip,
  addUserTrip,
  addUserTripReset,
  trips_show,
  updateUserTrip,
  userTripFields,
} from "../../actions/trips";
import Car from "../../assets/icons/car.png";
import Close from "../../assets/icons/close.png";
import Flight from "../../assets/icons/flight.png";
import Hotel from "../../assets/icons/hotel.png";
import { apis } from "../../config/APIs";
import useDocumentAxios from "../../config/useDocument";
import CarRentals from "./CarRentals";
import FlightData from "./FlightData";
import HotelForm from "./HotelForm";
import OtherBooking from "./OtherBookings";
import "./trips.css";
import {
  ExpenseCustomDetails,
  PrefixExpenseFields,
  expenseCustomDetails,
  prefixexpenseFields,
} from "../../actions/expenses/expenses";
import { getFileExtension } from "../../utils";
import useScrollToError from "../../config/useScrollToError";
const { Dragger } = Upload;
// const trip_validation = yup.object().shape({
//   field_values: yup.array().of(
//     yup.object().shape({
//       id: yup.string(),
//       value: yup.string(),
//     })
//   ),
//   car_rentals: yup.array(),
//   other_bookings: yup.array(),
// });
const AddTripComponent = () => {
  const [isFlightModalVisible, setIsFlightModalVisible] = React.useState(false);
  const [isHotelsModalVisible, setIsHotelsModalVisible] = React.useState(false);
  const [isCarModalVisible, setIsCarModalVisible] = React.useState(false);
  const [isOtherModalVisible, setIsOtherModalVisible] = React.useState(false);
  const [validationSchema, setValidationSchema] = React.useState(null);
  const [tab, setTab] = React.useState("New Trip Request");
  const { t } = useTranslation();
  const [image, setImage] = useState("");
  const [uploadDetails, setUploadDetails] = React.useState({});
  const [uploadData, setUploadData] = React.useState({});
  const [removedItemsData, setRemovedItemsData] = useState([]);

  const { onCall, loading } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
  });

  const validationSchema1 = yup.object().shape({
    title: yup.string().required("Title is required"),
    is_visa_required: yup.string().required("Visa is required"),
    purpose: yup.string().required("Purpose is required"),
    trip_type: yup.string().required("Trip Type is Required"),
    destination_country: yup.string().required("Destination is Required"),
    ...validationSchema,
  });

  const tripForm = useFormik({
    initialValues: {
      title: "",
      is_visa_required: "",
      purpose: "",
      trip_type: "",
      destination_country: "",
      trip_number: "",
      // field_values: {},
      hotel_reservations: [],
      car_rentals: [],
      other_bookings: [],
      flight_reservations: {},
    },
    // enableReinitialize: true,
    // validateOnBlur: false,
    // validateOnChange: true,
    validationSchema: validationSchema1,
    onSubmit: (values) => {
      let vals = { ...values };
      if (tripData.id) {
        const custom_fields = expenseCustomData.map(
          ({ field_id, column_name, column_type }) => {
            let master_custom_field_id = field_id;
            let module_name = "TRIP";
            let field_type = column_type;
            let value1 = tripForm.values[field_id];
            let value =
              column_type !== "datetime" ? value1 : value1 ? value1 : null;
            let field_name = column_name;
            delete vals?.[field_id];
            // return { id, value, field_name };

            if (column_type === "document") {
              let attachment_id =
                uploadDetails[field_id]?.attachmentId &&
                uploadDetails[field_id]?.attachmentId
                  ? uploadDetails[field_id]?.attachmentId
                  : [];
              return {
                master_custom_field_id,
                field_name,
                field_type,
                attachment_id,
                module_name,
              };
            } else {
              return {
                master_custom_field_id,
                value,
                field_name,
                field_type,
                module_name,
              };
            }
          }
        );
        vals["custom_fields"] = custom_fields.filter(Boolean) || [];
      } else {
        const custom_fields = fields.map(({ id, column_name, column_type }) => {
          let master_custom_field_id = id;
          let module_name = "TRIP";
          let field_type = column_type;
          let value1 = tripForm.values[id];
          let value =
            column_type !== "datetime"
              ? value1
              : value1
              ? moment(value1).format("DD-MM-YYYY HH:mm:ss")
              : null;
          let field_name = column_name;
          let attachment_id = uploadDetails[id]?.attachmentId
            ? uploadDetails[id]?.attachmentId
            : [];
          delete vals?.[id];
          // return { id, value, field_name };
          if (column_type !== "document") {
            return {
              master_custom_field_id,
              value,
              field_name,
              field_type,
              module_name,
            };
          } else {
            return {
              master_custom_field_id,
              value,
              field_name,
              field_type,
              attachment_id,
              module_name,
            };
          }
        });
        vals["custom_fields"] = custom_fields.filter(Boolean) || [];
      }
      // vals["custom_fields"] = custom_fields.filter(Boolean) || [];
      if (isFlightModalVisible) {
        setIsFlightModalVisible(false);
        return;
      }
      if (isCarModalVisible) {
        setIsCarModalVisible(false);
        return;
      }
      if (isHotelsModalVisible) {
        setIsHotelsModalVisible(false);
        return;
      }

      if (isOtherModalVisible) {
        setIsOtherModalVisible(false);
        return;
      }
      vals.hotel_reservations =
        vals.hotel_reservations.concat(removedItemsData);

      if (tripData?.id) {
        vals.id = tripData?.id;
        dispatch(updateUserTrip({ ...vals, params: { module_type: "TRIP" } }));
      } else {
        dispatch(addUserTrip(vals));
      }
    },
    enableReinitialize: true,
  });

  // Use the custom hook
  useScrollToError(
    Object.keys(tripForm?.errors)[0],
    tripForm?.errors,
    tripForm?.submitCount
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    location: { state: { tripData = {} } = {} },
  } = history;

  const userTripsAddResponse = useSelector(
    (state) => state.trips.addUserTrip || {}
  );
  const userTripFieldsResponse = useSelector(
    (state) => state.trips.userTripFields || {}
  );

  const { data: { data: fields = [] } = {} } = userTripFieldsResponse;

  const expenseCustomData = useSelector(
    (state) => state?.expenses?.expenseCustomDetails?.data?.data || []
  );

  const deleteEmployeeResponse = useSelector(
    (state) => state?.employees?.deleteEmployeeDocument
  );

  const updateUserTripResponse = useSelector(
    (state) => state?.trips?.updateUserTrip || {}
  );

  const tripDetailsByIdResponse = useSelector(
    (state) => state?.trips?.tripsShow || {}
  );

  const prefixResponse = useSelector(
    (state) => state.expenses?.prefixexpenseFields?.data?.["TRIP"]
  );
  React.useEffect(() => {
    dispatch(prefixexpenseFields({ params: { module_type: "TRIP" } }));
    if (tripData?.id) {
      dispatch(
        expenseCustomDetails({
          module_type: "TRIP",
          module_id: tripData?.id,
          custom_flag: true,
        })
      );
      dispatch(trips_show({ trip_id: tripData?.id, module_type: "TRIP" }));
    } else {
      dispatch(userTripFields({ module_type: "TRIP", custom_flag: true }));
    }
    return () => {
      dispatch(addUserTripReset());
      dispatch({ type: UpdateUserTrip.RESET });
      dispatch({ type: PrefixExpenseFields.RESET });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = userTripsAddResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setImage([]);
      setRemovedItemsData([]);
      history.push("/expenses/trips/my-trips");
      dispatch({
        type: AddUserTrip.RESET,
      });
      dispatch({
        type: ExpenseCustomDetails.RESET,
      });
      // dispatch(trips({ trip_id: tripsData.id }));
    } else if (error) {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddUserTrip.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTripsAddResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = updateUserTripResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setImage([]);
      setRemovedItemsData([]);
      history.push("/expenses/trips/my-trips");
      dispatch({
        type: TripsShow.RESET,
      });
      dispatch({
        type: ExpenseCustomDetails.RESET,
      });
      // dispatch(trips({ trip_id: tripsData.id }));
    } else if (error) {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddUserTrip.RESET,
      });
      dispatch({
        type: ExpenseCustomDetails.RESET,
      });
    }
  }, [updateUserTripResponse]);

  React.useEffect(() => {
    if (prefixResponse) {
      tripForm.setFieldValue("trip_number", prefixResponse);
    } else if (prefixResponse === false) {
      tripForm.setFieldError("trip_number", "error");
    }
  }, [prefixResponse]);

  React.useEffect(() => {
    expenseCustomData?.forEach((each_custom_field) => {
      // obj[each_custom_field.field_id] = each_custom_field?.value;
      let blobids = each_custom_field?.attachments?.map((item) => item.blob_id);
      if (each_custom_field.column_type === "document") {
        blobids?.length
          ? tripForm.setFieldValue(each_custom_field?.field_id, blobids)
          : tripForm.setFieldValue(each_custom_field?.field_id, "");
        // tripForm.setFieldValue(each_custom_field?.field_id, blobids);
      } else {
        tripForm.setFieldValue(
          each_custom_field?.field_id,
          each_custom_field?.value
        );
      }
    });
    const { data: { data: trips = {} } = {} } = tripDetailsByIdResponse;
    [
      "title",
      "is_visa_required",
      "purpose",
      "trip_type",
      "destination_country",
      "trip_number",
    ].forEach((itm) => {
      tripForm.setFieldValue(itm, trips[`${itm}`]);
    });
  }, [tripDetailsByIdResponse]);

  // const memoizedValidationSchema = useMemo(() => {
  //   const field_vals = {}
  //   const obj = {}

  //   fields.forEach((item) => {
  //     field_vals[item.field_id] = item.field_id === "trip_type" || item.field_id === "is_visa_required" ? (item.selected?.id ?? "") : (item?.selected?.value ?? "")
  //     if (item.is_required) {
  //       obj[item.id] = yup.string().required(`${item.display_name} is required`).nullable(`${item.display_name} is required`)
  //     }
  //   })
  //   return yup.object().shape({ field_values: yup.object().shape(obj) });
  // }, [fields]);
  // useEffect(() => {
  //   if (memoizedValidationSchema) {
  //     setValidationSchema(memoizedValidationSchema);
  //   }
  // }, [memoizedValidationSchema])

  React.useEffect(() => {
    if (tripData?.id) {
      let flight_reservations = {
        trips: [],
        mode: tripData?.trip_mode ?? "one_way",
        time_preference: tripData?.time_preference,
        seat_preference: tripData?.seat_preference,
        meal_preference: tripData?.meal_preference,
        description: tripData?.flight_reservation_description,
      };
      let trips = tripData?.flight_reservations?.map((ele) => {
        return {
          ...ele,
          departure_date: moment(ele.departure_date)
            .utc()
            .format("DD/MM/YYYY HH:mm:ss"),
          return_date: ele.return_date
            ? moment(ele.return_date).utc().format("DD/MM/YYYY HH:mm:ss")
            : "",
          mode: tripData.trip_mode,
        };
      });
      let hotels = tripData?.hotel_reservations?.map((ele) => {
        return {
          ...ele,
          duration_from: moment(ele.duration_from)
            .utc()
            .format("DD/MM/YYYY HH:mm:ss"),
          duration_to: ele.duration_to
            ? moment(ele.duration_to).utc().format("DD/MM/YYYY HH:mm:ss")
            : "",
        };
      });
      let car_rentals = tripData?.car_rentals?.map((ele) => {
        return {
          ...ele,
          duration_from: moment(ele.duration_from)
            .utc()
            .format("DD/MM/YYYY HH:mm:ss"),
          duration_to: ele.duration_to
            ? moment(ele.duration_to).utc().format("DD/MM/YYYY HH:mm:ss")
            : "",
        };
      });
      let other_bookings = tripData?.other_bookings?.map((ele) => {
        return {
          ...ele,
          departure_date: moment(ele.departure_date)
            .utc()
            .format("DD/MM/YYYY HH:mm:ss"),
        };
      });
      flight_reservations.trips = trips;
      const field_vals = {};

      fields.forEach((item) => {
        field_vals[item.field_id] =
          item.field_id === "trip_type" || item.field_id === "is_visa_required"
            ? item.selected?.id ?? ""
            : item?.selected?.value ?? "";
      });
      tripForm.setValues({
        // field_values: field_vals,
        hotel_reservations: hotels ? hotels : [],
        car_rentals: car_rentals ? car_rentals : [],
        other_bookings: other_bookings ? other_bookings : [],
        flight_reservations: flight_reservations ? flight_reservations : [],
      });
    }
    if (expenseCustomData) {
      expenseCustomData?.forEach((each_custom_field) => {
        // obj[each_custom_field.field_id] = each_custom_field?.value;
        let blobids = each_custom_field?.attachments?.map(
          (item) => item.blob_id
        );
        if (each_custom_field?.column_type === "document") {
          tripForm.setFieldValue(each_custom_field?.field_id, blobids);
        } else {
          tripForm.setFieldValue(
            each_custom_field?.field_id,
            each_custom_field?.value
          );
        }
      });

      expenseCustomData?.forEach((ele) => {
        if (ele.column_type === "document") {
          setUploadDetails((prevUploadDetails) => {
            return {
              ...prevUploadDetails,
              [ele.field_id]: {
                attachmentId: ele.attachments.map((ele) => ele.blob_id),
                recordId: ele.field_id,
              },
            };
          });
        }
      });

      expenseCustomData.forEach((ele) => {
        if (ele.column_type === "document") {
          setUploadData((b) => {
            return {
              ...b,
              [ele.field_id]: {
                attachmentId: ele.attachments.map((ele) => ele.blob_id),
                recordId: ele.field_id,
              },
            };
          });
        }
      });
    }
    const { data: { data: trips = {} } = {} } = tripDetailsByIdResponse;
    [
      "title",
      "is_visa_required",
      "purpose",
      "trip_type",
      "destination_country",
      "trip_number",
    ].forEach((itm) => {
      tripForm.setFieldValue(itm, trips[`${itm}`]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripDetailsByIdResponse]);
  
  React.useEffect(() => {
    if (fields && fields?.length) {
      let obj = {};
      let obj2 = {};
      fields.forEach((item) => {
        if (item.is_required) {
          obj2[item.id] = "";
          if (item.column_type === "document") {
            obj[item.id] = yup
              .array()
              .of(yup.string())
              .required(`${item.display_name} is required`);
          } else {
            obj[item.id] = yup
              .string()
              .required(`${item.display_name} is required`);
          }
        }
      });
      if (!tripData?.id)
        tripForm.setValues({
          ...tripForm.values,
          ...obj2,
        });
      setValidationSchema(obj);
    }
  }, [fields]);

  React.useEffect(() => {
    if (expenseCustomData && expenseCustomData?.length) {
      let obj = {};
      let obj2 = {};
      expenseCustomData.forEach((item) => {
        if (item.is_required) {
          obj2[item.field_id] = "";
          if (item.column_type === "document") {
            obj[item.field_id] = yup
              .array()
              .of(yup.string())
              .required(`${item.display_name} is required`);
          } else {
            obj[item.field_id] = yup
              .string()
              .required(`${item.display_name} is required`);
          }
        }
      });
      if (tripData?.id)
        tripForm.setValues({
          ...tripForm.values,
          ...obj2,
        });
      setValidationSchema(obj);
    }
  }, [expenseCustomData]);

  const handleCancelButton = () => {
    dispatch({ type: ExpenseCustomDetails.RESET });
    dispatch({ type: TripsShow.RESET });
    history.push("/expenses/trips/my-trips");
  };

  React.useEffect(() => {
    if (tripData?.id) {
      fields?.map((item) => {
        if (item?.field_type === "document" && item?.selected?.value) {
          const existImage = image?.findIndex(
            (eachImg) => eachImg.id === item.field_id
          );
          if (existImage !== -1) {
            setImage((prev) => {
              const updateImg = [...prev];
              updateImg[existImage].url = item?.selected?.value;
              return updateImg;
            });
          } else {
            setImage((b) => [
              ...b,
              { url: item?.selected?.value, id: item.field_id },
            ]);
          }
        }
      });
    }
  }, [fields]);

  const handleChange = (val, id) => {
    tripForm.setFieldValue(id, val);
  };

  const onDocumentFileChange = async (info, name) => {
    if (info.file.status === "removed") {
      tripData.id && info.file.blob_id
        ? dispatch(
            deleteEmployeeDocument({
              record_id: info?.file?.record_id,
              ids: [info?.file?.id],
            })
          )
        : dispatch(
            deleteEmployeeDocument({
              record_id: uploadData[info?.file?.uid].recordId,
              ids: [uploadData[info?.file?.uid].attachment_id],
            })
          );
      let recordfile = "";

      tripData.id && info.file.blob_id
        ? (recordfile = info.file.blob_id)
        : (recordfile = uploadData[info?.file?.uid].attachment_id);

      const customFieldDocumentValue = {
        ...uploadDetails[name],
        attachmentId: uploadDetails[name].attachmentId.filter(
          (val) => val !== recordfile
        ),
      };

      setUploadDetails((b) => ({
        ...b,
        [name]: {
          attachmentId: [
            ...b[name].attachmentId.filter((val) => val.includes(recordfile)),
          ],
        },
      }));
      tripForm.setFieldValue(
        name,
        customFieldDocumentValue?.attachmentId?.length
          ? customFieldDocumentValue?.attachmentId
          : ""
      );
    } else {
      const nextState = {};
      nextState.selectedFile = info.file;
      nextState.selectedFileList = [info.file];
      let uniqueNo = fields.id ? uploadDetails[name]?.recordId : uuidv4();

      const formData = new FormData();
      formData.append("files", info?.file);
      formData.append("name", info.file.name);
      formData.append("record_type", "Document");
      formData.append("record_id", uniqueNo);

      const attachId = await onCall({
        data: formData,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });

      setUploadDetails((b) => {
        if (Object.keys(b).includes(name)) {
          return {
            ...b,
            [name]: {
              ...b[name],
              attachmentId: [...b[name].attachmentId, attachId?.data?.[0]],
            },
          };
        } else {
          return {
            ...b,
            [name]: {
              attachmentId: [attachId?.data?.[0]],
            },
          };
        }
      });

      setUploadData((b) => {
        return {
          ...b,
          [info?.file?.uid]: {
            attachment_id: attachId?.data?.[0],
            recordId: uniqueNo,
          },
        };
      });
      if (uploadDetails[name] && uploadDetails[name].attachmentId) {
        tripForm.setFieldValue(name, [
          ...uploadDetails[name].attachmentId,
          attachId?.data?.[0],
        ]);
      } else {
        tripForm.setFieldValue(name, [attachId?.data?.[0]]);
      }
    }
  };

  const renderDataTypes = (
    value,
    default_values,
    id,
    name,
    attachments,
    attachmentType,
    attachmentDetails
  ) => {
    switch (value) {
      case "integer":
        return (
          <>
            <Input
              placeholder={"Enter Value"}
              style={{ minWidth: "216px", maxWidth: "300px" }}
              // key={item?.[id]}
              value={tripForm.values?.[id]}
              id={name}
              onChange={(e) => {
                if (Number.isNaN(Number(e.target.value)) && e.target.value) {
                  return;
                }
                handleChange(e.target.value.trimStart(), id);
                // let field_values = { ...tripForm.values?.field_values };
                // field_values[item.field_id] = e.target.value;
                // tripForm.setFieldValue("field_values", field_values);
              }}
            />
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.[id]}
            </div>
          </>
        );
      case "string":
        return (
          <>
            <Input
              placeholder={id === "title" ? "Title" : "Enter Value"}
              style={{ minWidth: "216px", maxWidth: "300px" }}
              // key={item.id}
              id={name}
              value={tripForm?.values?.[id]}
              onChange={(e) => {
                // let field_values = { ...tripForm.values?.field_values };
                // field_values[item.field_id] = e.target.value;
                // tripForm.setFieldValue("field_values", field_values);
                handleChange(e.target.value, id);
              }}
              disabled={id === "trip_number"}
            />
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.[id]}
            </div>
          </>
        );
      case "dropdown":
        return (
          <>
            <Select
              style={{ width: 200 }}
              placeholder="Select Type"
              showSearch
              id={name}
              value={tripForm.values?.[id]}
              onChange={(val) => {
                // let field_values = { ...tripForm.values?.field_values };
                // field_values[item.field_id] = val;
                // tripForm.setFieldValue("field_values", field_values);
                handleChange(val, id);
              }}
            >
              {default_values &&
                default_values.map((item) => (
                  <option key={id} value={item}>
                    {item}
                  </option>
                ))}
            </Select>
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.[id]}
            </div>
          </>
        );
      case "datetime":
        return (
          <>
            <DatePicker
              format={"DD/MM/YYYY HH:mm:ss"}
              value={
                tripForm.values?.[id]
                  ? moment(tripForm.values?.[id], "DD/MM/YYYY HH:mm:ss")
                  : null
              }
              id={id}
              onChange={(e, dateString) => {
                // let field_values = { ...tripForm.values?.field_values };
                // field_values[item.field_id] = dateString;
                // tripForm.setFieldValue("field_values", field_values);
                handleChange(dateString, id);
              }}
              showTime
            />
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.[id]}
            </div>
          </>
        );
      case "document":
        return (
          <>
            <Dragger
              name="document"
              beforeUpload={(file) => {
                const fileName = getFileExtension(file?.name);
                const allowedImages = attachmentType.split(",");
                const uppercaseImages = allowedImages.map((image) =>
                  image.trim().toUpperCase()
                );
                const imagetype = uppercaseImages.join(",  ");
                if (!allowedImages?.includes(fileName)) {
                  message.error(
                    <span className="messageText">
                      You can only upload {imagetype}.
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                } else if (file.size > attachmentDetails * 1024) {
                  message.error(
                    <span className="messageText">
                      File size must be smaller than {attachmentDetails} KB
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
              listType="picture"
              defaultFileList={attachments}
              onChange={(evt) => onDocumentFileChange(evt, id)}
              disabled={loading || deleteEmployeeResponse.loading}
            >
              {image ? (
                <img
                  src={image}
                  alt="testing"
                  style={{ width: "150px", height: "100px" }}
                />
              ) : (
                <p className="ant-upload-text">
                  <InboxOutlined />
                  {t("drag_attachment")}
                  <br />
                  {t("click_here_to")}
                </p>
              )}
              {/* {image.find((val) => val?.id === item.field_id)?.id ? <img src={image.find((val) => val?.id === item.field_id)?.url} alt='test' style={{ width: "150px", height: "100px" }} /> : <p className="ant-upload-text"><InboxOutlined />{t('drag_png')}<br />{t('click_here_to')}</p>} */}
            </Dragger>
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.[id]}
            </div>
          </>
        );
      default:
      // code block
    }
  };
  const secs = [
    {
      label: `${tripData?.id ? "Edit Trip" : "New Trip Request"}`,
      key: `${tripData?.id ? "Edit Trip" : "New Trip Request"}`,
    },
  ];
  return (
    <div className="trips-detail detail-card">
      <div className="tabs-sec flex-between">
        <ul>
          {secs.map(({ label, key }) => (
            <li
              onClick={() => setTab(key.toLowerCase())}
              className={`${
                tab.toLowerCase() === label.toLowerCase() ? "active" : ""
              }`}
            >
              {label}
            </li>
          ))}
        </ul>
        <div className="report-btns" style={{ right: 0 }}>
          <span
            className="close cursor"
            onClick={() => {
              history.goBack();
            }}
          >
            <img src={Close} width={20} alt={"close"} />
          </span>
        </div>
      </div>
      <section className="head trips-form-div">
        <form
          className="modal-form"
          onSubmit={tripForm.handleSubmit}
          layout="vertical"
        >
          <div className="nameItem">
            <label htmlFor="trip_number" className="inline-block required">
              Trip Id
            </label>
            <br />
            <Input
              placeholder={"enter_val"}
              value={tripForm.values?.trip_number}
              style={{ maxWidth: "300px" }}
              name="title"
              disabled={true}
            />
            <div className="error">
              {tripForm.errors?.trip_number && (
                <span style={{ color: "red" }}>
                  Please specify the prefix value in Trips customization
                </span>
              )}
            </div>
          </div>
          <div className="nameItem">
            <label htmlFor="title" className="inline-block required">
              Title
            </label>
            <br />
            <Input
              placeholder={t("enter_val")}
              value={tripForm.values?.["title"]}
              style={{ maxWidth: "300px" }}
              name="title"
              onChange={(e) => tripForm.setFieldValue("title", e.target.value)}
            />
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.title}
            </div>
          </div>
          <div className="nameItem">
            <label htmlFor="is_visa_required" className="inline-block required">
              Visa Required
            </label>
            <br />
            <Select
              placeholder={t("enter_val")}
              value={tripForm.values?.is_visa_required}
              style={{ minWidth: "300px" }}
              name="is_visa_required"
              id="is_visa_required"
              // onChange={(e) => tripForm.setFieldValue("is_visa_required", e)}
              onChange={(e) => tripForm.setFieldValue("is_visa_required", e)}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Select>
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.is_visa_required}
            </div>
          </div>
          <div className="nameItem">
            <label htmlFor="purpose" className="inline-block required">
              Purpose
            </label>
            <br />
            <Input.TextArea
              placeholder={t("enter_val")}
              value={tripForm.values?.purpose}
              style={{ maxWidth: "300px" }}
              onChange={(e) =>
                tripForm.setFieldValue("purpose", e.target.value)
              }
            />
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.purpose}
            </div>
          </div>

          <div className="nameItem">
            <label htmlFor="trip_type" className="inline-block required">
              Trip Type
            </label>
            <br />
            <Select
              placeholder={t("enter_val")}
              value={tripForm.values?.trip_type}
              style={{ minWidth: "300px" }}
              id="trip_type"
              onChange={(e) => tripForm.setFieldValue("trip_type", e)}
            >
              <option value="domestic">Domestic</option>
              <option value="international">International</option>
            </Select>
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.trip_type}
            </div>
          </div>

          <div className="nameItem">
            <label
              htmlFor="destination_country"
              className="inline-block required"
            >
              Destination
            </label>
            <br />
            <Input
              placeholder={t("enter_val")}
              value={tripForm.values?.destination_country}
              style={{ maxWidth: "300px" }}
              name="destination_country"
              onChange={(e) =>
                tripForm.setFieldValue("destination_country", e.target.value)
              }
            />
            <div className="error">
              {tripForm.submitCount > 0 && tripForm.errors?.destination_country}
            </div>
          </div>

          {tripData?.id
            ? expenseCustomData &&
              expenseCustomData.map((item) => (
                <Form.Item
                  // label={
                  //   item.display_name === "Destination Country"
                  //     ? "Destination"
                  //     : item.display_name
                  // }
                  label={t(item.column_name)}
                  className="nameItem"
                  rules={[
                    {
                      required: item?.is_required,
                      message: `${item.column_name} is required`,
                    },
                  ]}
                  required={item?.is_required}
                >
                  {renderDataTypes(
                    item.column_type,
                    item.default_values,
                    item.field_id,
                    item.column_name,
                    item.attachments?.map((ele) => ({
                      ...ele,
                      url: ele.attachment_url,
                      key: ele.blob_id,
                      name: ele.filename,
                      thumbUrl: ele?.content_type?.includes("image/")
                        ? null
                        : ele.content_type === "application/pdf"
                        ? pdf_icon
                        : ele?.content_type ===
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                        ? ppt_icon
                        : excel_icon,
                    })),
                    item.data.doc_formats,
                    item.data.max_size
                  )}
                </Form.Item>
              ))
            : fields.length > 0 &&
              fields.map((item) => (
                <Form.Item
                  // label={
                  //   item.display_name === "Destination Country"
                  //     ? "Destination"
                  //     : item.display_name
                  // }
                  label={t(item.column_name)}
                  className="nameItem"
                  rules={[
                    {
                      required: item?.is_required,
                      message: `${item.column_name} is required`,
                    },
                  ]}
                  required={item?.is_required}
                >
                  {
                    // {/* {item.field_id === "purpose" ? (
                    //   <>
                    //     <Input.TextArea
                    //       rows={4}
                    //       placeholder={t("enter_val")}
                    //       maxLength={250}
                    //       key={item.field_id}
                    //       value={tripForm.values?.field_values[item.field_id]}
                    //       name={item.field_id}
                    //       onChange={(e) => {
                    //         let field_values = { ...tripForm.values?.field_values };
                    //         field_values[item.field_id] = e.target.value;
                    //         tripForm.setFieldValue("field_values", field_values);
                    //       }}
                    //     />
                    //     <div className="error">
                    //       {tripForm.submitCount > 0 &&
                    //         tripForm.errors.field_values?.[item?.field_id]}
                    //     </div>
                    //   </>
                    // ) :  */}
                    renderDataTypes(
                      item.column_type,
                      item.default_values,
                      item.id,
                      item.column_name,
                      item.attachment,
                      item.data.doc_formats,
                      item.data.max_size
                    )
                  }
                </Form.Item>
              ))}
          <p className="headLabels trip-header mt2">{t("trip_iteniry")}</p>
          <hr />
          <br />
          <div className="trips-steps-div">
            <Steps direction="vertical" current={100}>
              <Steps.Step
                subTitle={
                  <FlightData
                    tripForm={tripForm}
                    isModalVisible={isFlightModalVisible}
                    setIsModalVisible={setIsFlightModalVisible}
                  />
                }
                title={t("flight_reservation")}
                description=""
                icon={
                  <img
                    alt=""
                    src={Flight}
                    style={{
                      width: "2.3rem",
                      color: "black",
                      border: "2px solid #E6E6E6",
                      padding: ".5rem",
                      background: "#E6E6E6",
                      borderRadius: "50%",
                    }}
                  />
                }
              />
              <Steps.Step
                subTitle={
                  <HotelForm
                    isEdit={tripForm.hotel_reservations?.length}
                    tripForm={tripForm}
                    isModalVisible={isHotelsModalVisible}
                    setIsModalVisible={setIsHotelsModalVisible}
                    setRemovedItemsData={setRemovedItemsData}
                    removedItemsData={removedItemsData}
                  />
                }
                title={t("hotel_reservation")}
                description=""
                icon={
                  <img
                    alt=""
                    src={Hotel}
                    style={{
                      width: "2.3rem",
                      color: "black",
                      border: "2px solid #E6E6E6",
                      padding: ".5rem",
                      background: "#E6E6E6",
                      borderRadius: "50%",
                    }}
                  />
                }
              />
              <Steps.Step
                subTitle={
                  <CarRentals
                    isEdit={tripForm.car_rentals?.length}
                    tripForm={tripForm}
                    isModalVisible={isCarModalVisible}
                    setIsModalVisible={setIsCarModalVisible}
                  />
                }
                title={t("car_rental")}
                description=""
                icon={
                  <img
                    alt=""
                    src={Car}
                    style={{
                      width: "2.3rem",
                      color: "black",
                      border: "2px solid #E6E6E6",
                      padding: ".5rem",
                      background: "#E6E6E6",
                      borderRadius: "50%",
                    }}
                  />
                }
              />
              <Steps.Step
                subTitle={
                  <OtherBooking
                    isEdit={tripForm.other_bookings?.length}
                    tripForm={tripForm}
                    isModalVisible={isOtherModalVisible}
                    setIsModalVisible={setIsOtherModalVisible}
                  />
                }
                title={t("other_bookings")}
                description=""
                icon={
                  <img
                    alt=""
                    src={Car}
                    style={{
                      width: "2.3rem",
                      color: "black",
                      border: "2px solid #E6E6E6",
                      padding: ".5rem",
                      background: "#E6E6E6",
                      borderRadius: "50%",
                    }}
                  />
                }
              />
            </Steps>
          </div>
          <div className="trips-button-div">
            <Button
              key="1"
              htmlType="submit"
              id="main_sub"
              className="formButton mt3 primaryButton"
              loading={
                userTripsAddResponse?.loading ||
                loading ||
                deleteEmployeeResponse.loading
              }
            >
              {t("Save")}
            </Button>
            <Button
              key="2"
              className="cancelButton ml1 mt3 "
              onClick={() => handleCancelButton()}
            >
              {t("cancel")}
            </Button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AddTripComponent;
