import {
  Document,
  Page,
  View,
  StyleSheet,
  Text,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import moment from "moment";
import TemplateTable from "./TemplateTable";
import invoice1 from "../../assets/images/invoice01.jpg";
import { columns } from "./invPDF2";

const Purpleinv = (props) => {
  const {
    billingAddressCity,
    billingAddressState,
    billingAddresszip,
    billingCountry,
    billAddress,
    deliveryCharges,
  } = props;

  // Function to form the address
  const formatAddress = () => {
    const addressComponents = [
      billAddress,
      billingAddressCity,
      billingAddressState,
      billingAddresszip,
      billingCountry,
    ];

    // Filter out undefined or empty values
    const filteredAddressComponents = addressComponents.filter(Boolean);
    const address = filteredAddressComponents.join(", ");

    return address;
  };

  const formAddress = formatAddress();

  const styles = StyleSheet.create({
    page: {
      paddingTop: 30,
      paddingLeft: 50,
      paddingRight: 50,
      lineHeight: 1.5,
      paddingBottom: "20px",
      //   //  fontWeight:"bold",
      //     fontFamily: "Roboto",
      backgroundColor: "#E6E7EC",
    },

    subPage: {
      //paddingTop: 0,
      paddingLeft: 50,
      paddingRight: 50,
      // lineHeight: 1.5,
    },

    subMenu: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 20,
      width: "45%",
    },
    footerMenu: {
      flexDirection: "row",
      justifyContent: "space-between",
      /// marginTop: 50,
      width: "40%",
    },
    test: {
      flexDirection: "column",
      // alignItems: 'flex-end',
      fontSize: "12px",
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
      //color: "grey"
    },
    display: {
      flexDirection: "row",
      justifyContent: "space-between",
      //  paddingHorizontal: 20,
      marginTop: 15,
      width: "40%",
    },
    container: {
      display: "flex",
      alignItems: "flex-end",
      marginTop: 10,
      minWidth: 2,
    },

    displayOne: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 20,
      marginTop: 15,
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
      //color: "grey"
    },
  });
  const currency =
    props?.useDummyData === true ? props?.obj?.currency : props?.currency;

  return (
    <div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          <Page size="A4" wrap={false}>
            <View style={styles.page}>
              <View
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <View style={styles.test}>
                  <Text style={{ color: "red", width: "5px", height: "5px" }}>
                    <img
                      src={invoice1}
                      alt="Logo"
                      style={{ width: "5px", height: "5px" }}
                    />
                  </Text>

                  <Text
                    style={{
                      marginTop: "3px",
                      maxWidth: "250px",
                      fontSize: "10px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.beniName
                      : props?.benefiName}
                  </Text>
                  <Text
                    style={{
                      marginTop: "3px",
                      maxWidth: "225px",
                      fontSize: "10px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.address
                      : formAddress}
                  </Text>
                  <Text
                    style={{
                      marginTop: "3px",
                      maxWidth: "200px",
                      fontSize: "10px",
                    }}
                  >
                    CALL:{" "}
                    {props?.useDummyData === true
                      ? props?.obj?.mobile
                      : props?.benifiContact}
                  </Text>
                  <Text
                    style={{
                      marginTop: "5px",
                      maxWidth: "200px",
                      fontSize: "10px",
                    }}
                  >
                    Email us:{" "}
                    {props?.useDummyData === true
                      ? props?.obj?.beniEmail
                      : props?.benifiEmail}
                  </Text>
                  <Text
                    style={{
                      marginTop: "3px",
                      maxWidth: "200px",
                      fontSize: "10px",
                    }}
                  >
                    GST-{" "}
                    {props?.useDummyData === true
                      ? "GST24AAACC4563F"
                      : props?.benficiaryGST}
                  </Text>
                </View>
                <View style={styles.test}>
                  <View style={{ flexDirection: "column" }}>
                    <Text style={{ fontSize: "12px" }}>
                      {props?.useDummyData === true
                        ? props?.obj?.dateTitle
                        : props?.dateTitle}
                      : {moment(props?.invoiceDate).format("MMMM DD, YY")}
                    </Text>
                    <Text style={{ fontSize: "12px" }}>
                      {props?.useDummyData === true
                        ? props?.obj?.numTitle
                        : props?.numTitle}
                      :{" "}
                      {props?.useDummyData === true
                        ? props?.obj?.invoiceNo
                        : props?.invoiceNo}
                    </Text>
                    {/* <Text style={{ fontSize: "12px", }}>{props?.useDummyData === true ? props?.obj?.creditTitle : props?.creditTitle}: {props?.useDummyData === true ? props?.obj?.CreditNo : props?.creditNo}</Text> */}

                    <Text
                      style={{
                        marginTop: "25px",
                        maxWidth: "250px",
                        fontSize: "10px",
                      }}
                    >
                      Organization Name :
                      {props?.useDummyData === true
                        ? props?.obj?.companyName
                        : props?.companyName}
                    </Text>
                    <Text
                      style={{
                        marginTop: "3px",
                        maxWidth: "200px",
                        fontSize: "10px",
                      }}
                    >
                      GST-{" "}
                      {props?.useDummyData === true
                        ? props?.obj?.gst
                        : props?.gstNo}
                    </Text>
                    <Text
                      style={{
                        marginTop: "3px",
                        maxWidth: "200px",
                        fontSize: "10px",
                      }}
                    >
                      CALL-{" "}
                      {props?.useDummyData === true
                        ? props?.obj?.mobile
                        : props?.primaryContact}
                    </Text>
                    <Text
                      style={{
                        marginTop: "3px",
                        maxWidth: "200px",
                        fontSize: "10px",
                      }}
                    >
                      Email-{" "}
                      {props?.useDummyData === true
                        ? props?.obj?.email
                        : props?.primaryEmail}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ ...styles.subPage }}>
              <TemplateTable
                columns={columns}
                currency={currency}
                lineItems={
                  props?.useDummyData === true
                    ? props?.obj?.lineItems
                    : props?.lineItems
                }
                tableHeadRow={{
                  borderBottomStyle: "1 px solid grey",
                  borderBottom: 0,

                  //borderBottomWidth: 0,
                  borderBottomColor: "#000",
                  // borderTopStyle: 'solid',
                  //  borderTopWidth: 1,
                  //  borderTopColor: "#000",
                  // borderTop: "1px solid #000",
                  //  paddingVertical: "10px",
                }}
                tableRow={{
                  borderBottom: 0,
                  fontSize: "12px",
                  paddingVertical: "10px",
                  paddingLeft: "3px",
                }}
              />

              <View>
                <Text
                  style={{
                    borderBottom: "1px solid black",
                    paddingTop: "30px",
                  }}
                ></Text>
              </View>

              <View style={{ display: "flex", flexDirection: "row-reverse" }}>
                <View style={styles.displayOne}>
                  <View
                    style={{
                      ...styles.container,
                      marginRight: "10px",
                      alignItems: "flex-end",
                    }}
                  >
                    <Text style={{ ...styles.text, marginTop: "5px" }}>
                      Sub Total
                    </Text>
                    <Text style={{ ...styles.text, marginTop: "5px" }}>
                      Total Discount
                    </Text>
                    <Text style={{ ...styles.text, marginTop: "5px" }}>
                      Total Tax
                    </Text>
                    {deliveryCharges && (
                      <Text style={styles.text}>Delivery Charges</Text>
                    )}
                    {props?.type === "PURCHASE_ORDER" ||
                    props?.type === "BILL" ||
                    props?.obj?.tdsFlag === true ? (
                      <Text style={styles.text}>TDS Amount</Text>
                    ) : null}

                    <Text style={{ ...styles.text, marginTop: "5px" }}>
                      Total Amount
                    </Text>
                  </View>
                  <View style={styles.container}>
                    <Text style={{ ...styles.text, marginTop: "5px" }}>
                      {" "}
                      {props?.useDummyData === true
                        ? currency + " " + props?.obj?.subTotal
                        : props?.subTotal
                        ? currency + " " + props?.subTotal
                        : currency + " " + 0}
                    </Text>
                    <Text style={{ ...styles.text, marginTop: "5px" }}>
                      {" "}
                      {props?.discount
                        ? currency + " " + props?.discount
                        : currency + " " + 0}
                    </Text>
                    <Text style={{ ...styles.text, marginTop: "5px" }}>
                      {" "}
                      {props?.useDummyData === true
                        ? currency + " " + props?.obj?.totalTax
                        : props?.taxAmount
                        ? currency + " " + props?.taxAmount
                        : currency + " " + 0}
                    </Text>
                    {deliveryCharges && (
                      <Text style={styles.text}>
                        {currency + " " + deliveryCharges}
                      </Text>
                    )}
                    {props?.type === "PURCHASE_ORDER" ||
                    props?.type === "BILL" ||
                    props?.obj?.tdsFlag === true ? (
                      <Text style={styles.text}>
                        {props?.useDummyData === true
                          ? currency + " " + props?.obj?.tdsAmut
                          : props?.TDSAmt
                          ? currency + " " + props?.TDSAmt
                          : currency + " " + 0}
                      </Text>
                    ) : null}
                    <Text style={{ ...styles.text, marginTop: "5px" }}>
                      {props?.totalAmount
                        ? currency + " " + props?.totalAmount
                        : currency + " " + 2000}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: "25%",
                flex: 1,
                justifyContent: "flex-end",
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingBottom: "30",
              }}
            >
              <View>
                <Text style={{ fontSize: 11 }}>
                  Payment can be transferred to the following bank account:
                </Text>
                <View
                  style={{
                    fontSize: 10,
                    marginTop: "15px",
                    width: 200,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={{ minWidth: "100px" }}>Account Number:</Text>
                  <Text>
                    {props?.useDummyData === true
                      ? props?.obj?.bankAccount
                      : props?.accountNumber}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: 10,
                    marginTop: "15px",
                    width: 200,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={{ minWidth: "100px" }}>Account Name:</Text>
                  <Text>
                    {props?.useDummyData === true
                      ? props?.obj?.bankName
                      : props?.accountName}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: 10,
                    marginTop: "15px",
                    width: 200,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={{ minWidth: "100px" }}>IFSC code:</Text>
                  <Text style={{ minWidth: "100px" }}>
                    {props?.useDummyData === true
                      ? props?.obj?.ifscCode
                      : props?.accountIFSC}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: 10,
                    marginTop: "15px",
                    width: 200,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={{ minWidth: "100px" }}>Branch:</Text>
                  <Text>
                    {props?.useDummyData === true
                      ? props?.obj?.bankBranch
                      : props?.bankAccountBranch}
                  </Text>
                </View>
                {/* <Text style={{ fontSize: 10,marginTop: "5px" }}>SWIFT CODE: </Text> */}
              </View>

              <View>
                <Text
                  style={{ borderBottom: "1px solid grey", paddingTop: "30px" }}
                ></Text>
              </View>

              <View
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <View style={styles.test}>
                  <Text
                    style={{
                      marginTop: "3px",
                      maxWidth: "250px",
                      fontSize: "10px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.benefiCompany
                      : props?.companyName}
                  </Text>
                  <Text
                    style={{
                      marginTop: "3px",
                      maxWidth: "250px",
                      fontSize: "10px",
                    }}
                  >
                    CIN No :{" "}
                    {props?.useDummyData === true
                      ? props?.obj?.gst
                      : props?.gstNo}
                  </Text>
                </View>
                <View style={styles.test}>
                  <View style={{ flexDirection: "column" }}>
                    <Text
                      style={{
                        marginTop: "5px",
                        maxWidth: "200px",
                        fontSize: "10px",
                      }}
                    >
                      Email :{" "}
                      {props?.useDummyData
                        ? props?.obj?.email
                        : props?.primaryEmail}
                    </Text>
                    <Text
                      style={{
                        marginTop: "3px",
                        maxWidth: "200px",
                        fontSize: "10px",
                      }}
                    >
                      Mobile :{" "}
                      {props?.useDummyData
                        ? props?.obj?.mobile
                        : props?.primaryContact}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default Purpleinv;
