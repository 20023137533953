import { Button, Divider, Modal, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { get_addresses, store_addr_details } from "../../actions/orders";
import check from "../../assets/icons/check.svg";
import close from "../../assets/icons/close.png";
import { customer_shipping_addresses } from "../../actions/orders/orders";
import AddCardAddress from "./AddCardAddress";
import { portalDashboard } from "../../actions/customers";
// import termsNConditions from "../../assets/Terms_Conditions_of_Sale.pdf";
// import PopupComponent from "../../common/Popup";

const Address = ({ step, isAggree, setIsAggree, setShippingAddresses }) => {
  const [isChecked, setIsChecked] = useState({});
  const [showAddr, setShowAddr] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showChangeAddr, setShowChangeAddr] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false)
  const addresses = useSelector(
    (state) =>
      state?.orders?.shipping_addresses?.data?.response?.addresses || []
  );
  const stored_details = useSelector((state) => state?.orders?.storedOrder);
  const dashboardData = useSelector((state) => state?.customers?.portalDashboard?.data?.data)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customer_shipping_addresses({}));
    dispatch(portalDashboard({ company_id: localStorage?.getItem("company_id") }))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShippingAddresses(isChecked);
    // eslint-disable-next-line
  }, [isChecked]);

  const [primaryAddr, setPrimaryAddr] = useState({});
  useEffect(() => {
    if (primaryAddr?.id) {
      setIsChecked({
        [primaryAddr?.id]: true,
      });
    }
    // eslint-disable-next-line
  }, [addresses, primaryAddr]);

  // useEffect(() => {
  //   let addr =
  //     addresses?.find?.(
  //       (ele) => ele?.id === stored_details?.shipping_address_id
  //     ) || addresses?.[0];
  //   setPrimaryAddr(addr || {});
  // }, [stored_details, addresses]);
  useEffect(() => {
    setPrimaryAddr(prevPrimaryAddr => {
      let addr =
        addresses?.find?.(
          (ele) => ele?.id === stored_details?.shipping_address_id
        ) || addresses?.[0];
      return addr || prevPrimaryAddr; // return previous state if addr is falsy
    });
  }, [stored_details, addresses]);


  return (
    <div className="addresses">
      <div>
        <label>Delivery Address</label>

        <div className="address jc-space-between">
          <span className="addr">
            {primaryAddr?.name}
            <br />
            {primaryAddr?.address && (
              <>
                {primaryAddr?.address},
                <br />
              </>
            )}
            {primaryAddr?.city && <>{primaryAddr?.city},</>}
            <br /> {primaryAddr?.state} {primaryAddr?.zipcode}
            <br />
            PH : {primaryAddr?.phone}
          </span>

          <div className="d-flex">
            <span
              className="change_addr"
              onClick={() => {
                setShowAddAddress(true);
              }}
            >
              Add Address
            </span>
            <span
              className="change_addr"
              onClick={() => {
                setShowChangeAddr(true);
              }}
            >
              Change Address
            </span>
          </div>
        </div>

        {showAddr && (
          <>
            <label>Other Address</label>
            {addresses &&
              addresses?.length > 0 &&
              addresses
                ?.filter((ele) => ele.id !== primaryAddr?.id)
                ?.map((item) => (
                  <div className="address">
                    {isChecked[item.id] ? (
                      <span
                        className="check"
                        onClick={() =>
                          setIsChecked({
                            ...isChecked,
                            [item.id]: false,
                          })
                        }
                      >
                        <img alt="check" src={check} />
                      </span>
                    ) : (
                      <span
                        className="uncheck"
                        onClick={() =>
                          setIsChecked({
                            ...isChecked,
                            [item.id]: true,
                          })
                        }
                      ></span>
                    )}
                    <span className="addr">
                      {item.name}
                      <br />
                      {item?.address1 && (
                        <>
                          {item?.address1},
                          <br />
                        </>
                      )}
                      {item?.address2 && (
                        <>
                          {item?.address2},
                          <br />
                        </>
                      )}
                      {item?.city && (
                        <>
                          {item?.city},
                          <br />
                        </>
                      )}
                      <br /> {item?.state} {item?.zip}
                      <br />
                      {/* PH : +61 89880940 */}
                    </span>
                  </div>
                ))}
          </>
        )}
      </div>

      {step === 1 && (
        <div className="summary-terms">
          <div>
            <label>PAYMENT TERMS</label>
            <div className="address">
              <span
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {dashboardData?.beneficiary_details?.configurations?.payment_terms ? dashboardData?.beneficiary_details?.configurations?.payment_terms : "-"}
              </span>
            </div>
          </div>
          {/* <div>
            <label>DELIVERY TERMS</label>
            <div className="address">
              <span
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {localStorage.getItem("delivery_term") ||
                  "Express Shipping Available"}
                <br />
                <br />
              </span>
            </div>
          </div> */}
          <div className="diff-location">
            {isAggree ? (
              <span className="check" onClick={() => setIsAggree(false)}>
                <img alt="check" src={check} />
              </span>
            ) : (
              <span
                className="uncheck"
                onClick={() => {
                  setIsAggree(true);
                }}
              ></span>
            )}
            <p
              className="agree-link"
            >
              I agree to the <span className="terms-conditions cursor" onClick={() => {
                setShowTerms(true);
              }}>Terms and Conditions</span>
            </p>
          </div>
        </div>
      )}
      {
        showChangeAddr && <Modal visible={showChangeAddr} title={"Change address"} onCancel={() => {
          setShowChangeAddr(false)
        }}>
          <ChangeAddress
            primaryAddr={primaryAddr}
            onBack={() => {
              setShowChangeAddr(false);
            }}
            onChange={(e) => {
              let addr = addresses?.find?.((ele) => ele?.id === e);
              if (addr) {
                setPrimaryAddr(addr);
                setIsChecked({
                  [addr?.id]: true,
                });
              }
            }}
            setShowAddAddress={setShowAddAddress}
          />
        </Modal>
      }
      {
        showAddAddress && <AddCardAddress showAddAddress={showAddAddress} setShowAddAddress={setShowAddAddress} />
      }
    </div>
  );
};

const ChangeAddress = ({ onChange, primaryAddr, onBack, setShowAddAddress }) => {
  const addresses = useSelector((state) =>
    state?.orders?.shipping_addresses?.data?.response?.addresses?.map(
      (ele) => ({
        label: `${ele?.city}, Pincode: ${ele?.zipcode}`,
        value: ele?.id,
      })
    )
  );
  const [addr, setAddr] = useState(primaryAddr?.id);
  return (
    <div className="popup-wrap">
      {/* <span className="close" onClick={() => {
        onBack()
      }}>
        <img
          src={close}
          alt="close"
          className="cursor"
          style={{ width: "1rem" }}
        />
      </span> */}
      {/* <h2>Change address</h2> */}
      <div className="d-flex flex-col">
        <section className="w60 mt1">
          <Select
            className="w100"
            onChange={(e) => {
              setAddr(e);
            }}
            value={addr}
            dropdownRender={(menu) => (
              <>
                <div className="pp-dropdown-menu">{menu}</div>
                <Divider className="pp-dropdown-divider" />
                {(
                  <Button
                    type="text"
                    htmlType="button"
                    className="pp-dropdown-add-new"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setShowAddAddress(true)
                    }}
                  >
                    <span>Add New Address</span>
                  </Button>
                )}
              </>
            )}
            options={addresses?.length ? addresses : []}
            placeholder={"select"}
          />
        </section>
      </div>
      <div className="d-flex mt1">
        <Button
          key="2"
          htmlType="button"
          className="cancelButton  mt1"
          onClick={() => {
            onBack();
            setAddr("");
          }}
        >
          Cancel
        </Button>
        <Button
          key="1"
          className="formButton ml1 mt1"
          // htmlType="submit"
          onClick={() => {
            onChange(addr);
            setAddr("");
            onBack();
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};


export default Address;
