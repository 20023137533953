import React, { useEffect } from "react";
import { FormInput } from "../../../inputs";
import { Button, message } from "antd";
import { apis } from "../../../../config/APIs";
import PasswordCheck from "../../../Common/PasswordCheck";
import useAdminAxios from "../../../../config/useAdmin";
import useNotificationAxios from "../../../../config/useNotification";
import { useTranslation } from "react-i18next";


const UserDetailsForm = ({
  register,
  control,
  errors,
  watch,
  onSuccess,
  handleSubmit,
  reset,
  setUserData,
  pageCount = "",
  setValue,
  userData
}) => {
  useEffect(() => {
    reset();
  }, [])

  const { t } = useTranslation();
  const { onCall, loading: otpLoading } = useNotificationAxios({
    api: apis.gen_otp,
    method: "post",
  });
  const { onCall: createAccount, loading } = useAdminAxios({
    api: apis.onboardPostAPI,
    method: "post",
  });

  const formFields = [
    {
      name: "first_name",
      label: t('first_name'),
      placeholder: t('enter_first_name'),
      validation: { required: t('first_name_required') },
    },
    {
      name: "last_name",
      label: t('last_name'),
      placeholder: t('enter_last_name'),
      validation: { required: t('last_name_required') },
    },
    {
      name: "email",
      label: t('email'),
      placeholder: t('enter_email'),
      validation: {
        required: t('email_required'),
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: t('enter_valid_Email')
        },
      },
    },
    {
      name: "password",
      label: t('password'),
      type: "password",
      placeholder: t('enter_pd'),
      validation: { required: t('password_required') },
    },
    {
      name: "user_phone_number",
      label: "Mobile Number",
      type: "number",
      placeholder: "Enter Mobile Number",
      validation: { required: "Mobile Number is required" },
    },
  ];

  const onSubmit = (data) => {
    data.business_email = data.email;
    data.step = 1;
    // createAccount({
    //   data,
    // })
    //   .then((customer) => {
    //     if (customer.error === false) {
    //       localStorage.setItem("user_id", customer?.data?.id);
    //       localStorage.setItem("user", data.email);
    //       onSuccess();
    //       onCall({
    //         data: {
    //           email_id: data.email,
    //           user_id: customer?.data?.id,
    //           source: "ONBOARD",
    //         },
    //       })
    //         .then((res) => {
    //           if (res.error === false) {
    //             message.success(
    //               <span className="messageText">{res?.message}</span>
    //             );
    //           } else if (res.error) {
    //             message.error(
    //               <span className="messageText">{res?.message}</span>
    //             );
    //           }
    //         })
    //         .catch((err) => {
    //           if (err?.response?.data?.message)
    //             message.error(
    //               <span className="messageText">
    //                 {err?.response?.data?.message}
    //               </span>
    //             );
    //         });
    //     }
    //     if (customer.error) {
    //       message.error(
    //         <span className="messageText">{customer?.message}</span>
    //       );
    //     }
    //   })
    //   .catch((err) => {
    //     if (err?.response?.data?.message) {
    //       message.error(
    //         <span className="messageText">{err?.response?.data?.message}</span>
    //       );
    //     }
    //   });
    onCall({
      data: {
        email_id: data.email,
        // user_id: customer?.data?.id,
        source: "ONBOARD",
      },
    })
      .then((res) => {
        if (res.error === false) {
          message.success(
            <span className="messageText">{res?.message}</span>
          );
          setUserData(data)
          onSuccess();
        } else if (res.error) {
          message.error(
            <span className="messageText">{res?.message}</span>
          );
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message)
          message.error(
            <span className="messageText">
              {err?.response?.data?.message}
            </span>
          );
      });
  };
  const password = watch("password");
  useEffect(() => {
    formFields?.forEach(formFieldsElements => {
      setValue(`${formFieldsElements?.name}`, userData?.[formFieldsElements?.name])
    })
  }, [pageCount])
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formFields.map((field, index) => (
          <div key={index} className={`pp-form-item field_length ${index == 0 ? "pt0" : ""}`} style={{ paddingTop: "15px" }}>
            <FormInput
              inline
              control={control}
              {...register(field.name, field.validation)}
              errors={errors}
              label={field.label}
              placeholder={field.placeholder}
              type={field.type}
              required={true}
              number={field.type === "number" ? true : false}
            />
            {field.type === "password" && <PasswordCheck password={password} />}
          </div>
        ))}
        <div className={`pp-form-item field_length`}>
          <Button
            type="primary"
            loading={loading || otpLoading}
            className="pp-main-button"
            id="first"
            htmlType="submit"
          >
            {t('get_started')}
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default UserDetailsForm;
