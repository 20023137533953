import { Table, Row, Col, Select, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { headerTitles } from "../../actions/masterdata/masterdata";
import Tyre from "../../assets/coming-soon.jpg";
import cart from "../../assets/icons/cart.svg";
import close from "../../assets/icons/close.png";
import TableHeader from "../Sales/TableHeader";
import {
  CustomerAddToCart,
  CustomerRemoveCartItem,
  customer_add_to_cart,
  customer_cart,
  customer_products,
  customer_remove_cart_item,
} from "../../actions/orders/orders";
import TableComponent from "../Common/TableComp/TableComp";
import useHandleResponse from "../Cart/useHandleResponse";
import BaseCurrency from "../Common/BaseCurrency";
import { amountFormat } from "../../utils";
import { portalDashboardCumpanyList } from "../../actions/customers";
import { useTranslation } from "react-i18next";

const Shop = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const prodsData = useSelector(
    (state) => state?.orders?.customer_products?.data?.response?.items || []
  );
  const line_items = useSelector(
    (state) => state?.orders?.cart?.data?.line_items
  );
  const [showTable, setShowTable] = useState(true);
  const [quantityObj, setQuantityObj] = useState({});
  const [page, setPage] = React.useState(1);
  const [filters, setFilters] = React.useState({});
  const [companyShift, setCompanyShift] = React.useState({
    open: false,
    value: "",
  });
  const addToCart = useSelector((state) => state?.orders?.add_to_cart);
  const removeItemFromCart = useSelector(
    (state) => state?.orders?.remove_cart_item
  );
  const selectedItems = Object.keys(quantityObj)?.filter(
    (ele) =>
      !ele.includes("prod_id") &&
      !line_items?.find(
        (itm) => itm["Item Id"] === ele && quantityObj[ele] === itm.Quantity
      ) &&
      quantityObj[ele]
  );
  const companyListData = useSelector(
    (state) => state?.customers?.portalCompanyList?.data?.data
  );
  useHandleResponse(
    CustomerAddToCart,
    "Product added to cart.",
    "Please Enter Quantity",
    addToCart,
    () => {
      dispatch(
        customer_cart({ company_id: localStorage?.getItem("company_id") })
      );
    }
  );
  useHandleResponse(
    CustomerRemoveCartItem,
    "Item removed successfully.",
    "Failed to remove Item.",
    removeItemFromCart,
    () => {
      dispatch(
        customer_cart({ company_id: localStorage?.getItem("company_id") })
      );
    }
  );

  useEffect(() => {
    dispatch(
      customer_products({ company_id: localStorage?.getItem("company_id") })
    );
    dispatch(
      headerTitles({
        title: "Products",
        description: "Buy the products from your customer",
      })
    );
    dispatch(
      portalDashboardCumpanyList({
        type: localStorage.getItem("isCustomer") ? "customer" : "vendor",
      })
    );
    return () => {
      setQuantityObj({});
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (line_items?.length) {
      let obj = {};
      line_items.forEach((element) => {
        obj[element["Item Id"]] = element.Quantity;
      });
      setQuantityObj(obj);
    } else if (!line_items?.length) {
      setQuantityObj({});
    }
  }, [line_items]);

  const columns = [
    {
      title: "Company Name",
      dataIndex: "company_name",
    },
    {
      title: "SKU",
      dataIndex: "sku",
    },
    {
      title: "Name",
      dataIndex: "item_name",
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      render: (_, row) => (
        <>
          <BaseCurrency symbol_code={row?.currency_info?.iso_code} />
          {`${amountFormat(row?.unit_price)}`}
        </>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "",
      render: (_sku, item) => (
        <div
          className="cart-input"
          style={{ width: props.actionWidth + "%" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            value={quantityObj[item.id] ? quantityObj[item.id] : ""}
            // onChange={(e) => {
            //   if (!Number.isNaN(Number(e.target.value))) {
            //     setQuantityObj({
            //       ...quantityObj,
            //       [item.id]: parseInt(e.target.value),
            //     });
            //   }
            //   if ([null, undefined, "", " "]?.includes(e.target.value?.trim())) {
            //     setQuantityObj((prevQuantity) => {
            //       delete prevQuantity[item.id]
            //       return prevQuantity ? prevQuantity : {}
            //     });
            //   }
            // }}
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === "" || !Number.isNaN(Number(value))) {
                setQuantityObj((prevQuantity) => {
                  const newQuantityObj = { ...prevQuantity };
                  if (value === "") {
                    delete newQuantityObj[item.id];
                  } else {
                    newQuantityObj[item.id] = parseInt(value);
                  }
                  return newQuantityObj;
                });
              }
            }}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "sku",
      render: (_sku, item) => (
        <div
          className="cart-input"
          style={{ width: props.actionWidth + "%" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* <input
            value={quantityObj[item.id] ? quantityObj[item.id] : ""}
            onChange={(e) => {
              if (!Number.isNaN(Number(e.target.value))) {
                setQuantityObj({
                  ...quantityObj,
                  [item.id]: parseInt(e.target.value),
                });
              }
            }}
          /> */}

          <button
            className={
              quantityObj[item.id] > 0 &&
              line_items?.find((ele) => ele?.["Item Id"] === item?.id)?.Quantity
                ? "bg-green"
                : ""
            }
            disabled={
              selectedItems.length > 1 ||
              [null, undefined]?.includes(quantityObj[item.id])
            }
            onClick={() => {
              dispatch(
                customer_add_to_cart({
                  line_items: [
                    {
                      quantity: quantityObj[item.id],
                      item_id: item.id,
                    },
                  ],
                  params: {
                    company_id: localStorage?.getItem("company_id"),
                  },
                })
              );
            }}
          >
            {/* <img src={cart} alt="cart" width={15} /> */}
            Add To Cart
          </button>
          {quantityObj[item.id] > 0 &&
            line_items?.find((ele) => ele?.["Item Id"] === item?.id)
              ?.Quantity && (
              <img
                className="cancel mlHalf"
                src={close}
                alt={"close"}
                width={14}
                onClick={() =>
                  dispatch(
                    customer_remove_cart_item({
                      id: line_items?.find((ele) => ele.Sku === item.sku)?.Id,
                    })
                  )
                }
              />
            )}
        </div>
      ),
    },
  ];

  const handleSearch = (searchKey) => {
    dispatch(
      customer_products({
        ...filters,
        search: searchKey,
        company_id: localStorage?.getItem("company_id"),
      })
    );
    setPage(1);
    setFilters({
      ...filters,
      search: searchKey,
    });
  };

  // const handleFilter = (value) => {
  //   if (value === "all") {
  //     dispatch(portalTableData({
  //       params: {
  //         module_type: moduleType,
  //         page_number: 1
  //       }
  //     }))
  //     setFilters({ type: moduleType })
  //   } else {
  //     if (value == "closed" || value == "partially_invoiced") {
  //       dispatch(
  //         portalTableData({
  //           ...filters,
  //           filters: { status: [value], invoice_status: ["approved"] },
  //           isFilter: true,
  //           params: {
  //             module_type: moduleType,
  //             page_number: 1
  //           }
  //         })
  //       );
  //       setFilters({
  //         ...filters,
  //         filters: { status: [value], invoice_status: ["approved"] }
  //       });
  //     } else if (isPayment) {
  //       dispatch(
  //         portalTableData({
  //           ...filters,
  //           filters: { payment_status: [value] },
  //           isFilter: true,
  //           params: {
  //             module_type: moduleType,
  //             page_number: 1
  //           }
  //         })
  //       );
  //       setFilters({
  //         ...filters,
  //         filters: { payment_status: [value] }
  //       });
  //     } else {
  //       dispatch(
  //         portalTableData({
  //           ...filters,
  //           filters: { invoice_status: [value] },
  //           isFilter: true,
  //           params: {
  //             module_type: moduleType,
  //             page_number: 1
  //           }
  //         })
  //       );
  //       setFilters({
  //         ...filters,
  //         filters: { invoice_status: [value] }
  //       });
  //     }

  //   }
  //   setPage(1);
  // };

  // const handleSort = (value) => {
  //   dispatch(
  //     portalTableData({
  //       ...filters,
  //       sort: {
  //         sort_key: value,
  //         sort_order: "1",
  //       },
  //       isFilter: true,
  //       params: {
  //         module_type: moduleType,
  //         page_number: 1
  //       }
  //     })
  //   );

  //   setFilters({
  //     ...filters,
  //     sort: {
  //       sort_key: value,
  //       sort_order: "1",
  //     },
  //   });
  //   setPage(1);
  // };

  const handleselect = (value) => {
    localStorage.setItem("company_id", value);
    dispatch(customer_products({ company_id: value }));
    dispatch(customer_cart({ company_id: value }));
    setQuantityObj({});
  };
  return (
    <>
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              noAddNewBtn={true}
              noFilter={true}
              showGrid={true}
              onShowGrid={() => setShowTable((b) => !b)}
              noDD={false}
              noSort={false}
              onSearch={handleSearch}
              handleselect={handleselect}
              SelectValue={localStorage?.getItem("company_id")}
              selectOptions={companyListData?.map((listElement) => ({
                label: listElement?.name,
                value: listElement?.id,
              }))}
              noSelect={false}
            />
            <div
              className={prodsData?.loading ? "shimmer" : ""}
              style={{ paddingTop: "22px" }}
            >
              {showTable ? (
                <TableComponent
                  hasCheckbox={false}
                  columns={columns}
                  data={prodsData}
                  pagination={{
                    total: prodsData?.totalRecords || 0,
                    current: page,
                    onChange: (pagee) => {
                      dispatch(
                        customer_products({
                          ...filters,
                          company_id: localStorage?.getItem("company_id"),
                          page: pagee,
                        })
                      );
                      setPage(pagee);
                    },
                  }}
                />
              ) : prodsData?.loading ? (
                <div
                  className="mt2 mb2"
                  style={{
                    height: "15rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <ScaleLoader color={"#2f2d51"} />
                  </div>
                  {prodsData?.loading && (
                    <p style={{ color: "#2f2d51", fontWeight: 600 }}>
                      Loading...
                    </p>
                  )}
                </div>
              ) : (
                <div className="pb2 orders-grid">
                  {prodsData?.map((ele) => {
                    return (
                      <div className="item" onClick={() => {}}>
                        <div className="mb1 p1">
                          <img
                            src={
                              ele.attachments?.length
                                ? ele.attachments?.[0]?.key
                                : Tyre
                            }
                            alt="type"
                            className="item-img"
                          />
                        </div>
                        <div className="ml1 mr1 mb1">
                          <div className="flex-between light">
                            <span>{"SKU"}</span>
                            <span>{ele.sku}</span>
                          </div>
                          <div className="flex-between bold">
                            <span>{ele.item_name}</span>
                            <span>
                              <BaseCurrency
                                symbol_code={ele?.currency_info?.iso_code}
                              />
                              {ele.unit_price}
                            </span>
                          </div>
                          {/* <div className="flex-between light o5">
                            <span>{ele.brand}</span>
                            <span>{ele.li} / {ele.sr} / {ele.lr}</span>
                          </div> */}
                          <div
                            className="cart-input"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <input
                              value={
                                quantityObj[ele.id] ? quantityObj[ele.id] : ""
                              }
                              onChange={(e) => {
                                if (!Number.isNaN(Number(e.target.value))) {
                                  setQuantityObj({
                                    ...quantityObj,
                                    [ele.id]: parseInt(e.target.value),
                                  });
                                }
                              }}
                            />
                            <button
                              style={{ marginLeft: "0.5rem" }}
                              className={
                                quantityObj[ele.id] > 0 &&
                                line_items?.find((ele1) => ele1.Sku === ele.sku)
                                  ? "bg-green"
                                  : ""
                              }
                              onClick={() => {
                                dispatch(
                                  customer_add_to_cart({
                                    line_items: Object.keys(quantityObj).map(
                                      (ele) => ({
                                        quantity: quantityObj[ele],
                                        item_id: ele,
                                      })
                                    ),
                                    params: {
                                      company_id:
                                        localStorage?.getItem("company_id"),
                                    },
                                  })
                                );
                              }}
                            >
                              {"Add to cart"}
                            </button>
                            {quantityObj[ele.id] > 0 && (
                              <img
                                className="cancel mlHalf"
                                src={close}
                                alt={"close"}
                                width={14}
                                onClick={() =>
                                  dispatch(
                                    customer_remove_cart_item({
                                      id: line_items?.find(
                                        (ele1) => ele1.Sku === ele.sku
                                      )?.Id,
                                    })
                                  )
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {console.log(
            "shop quantity obje",
            Object.keys(quantityObj),
            quantityObj
          )}
          {selectedItems.length > 1 && (
            <div className="show_multi">
              <span>({Object.keys(quantityObj)?.length}) items selected</span>
              <button
                className="primary-btn"
                onClick={() => {
                  dispatch(
                    customer_add_to_cart({
                      line_items: Object.keys(quantityObj).map((ele) => ({
                        quantity: quantityObj[ele],
                        item_id: ele,
                      })),
                      params: {
                        company_id: localStorage?.getItem("company_id"),
                      },
                    })
                  );
                }}
              >
                Add items to cart
              </button>
              <button
                className="pp-secondary-button"
                onClick={() => {
                  setQuantityObj({});
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Shop;
