
import {
  Button, Form, Input, Radio, Select
} from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup';
import { findLodash, objectToFormData } from '../../../utils';
import { addCategories } from './../../../actions/categories';
import "./../styles/setting.css";
import { useTranslation } from 'react-i18next';
const { Option } = Select;

const { TextArea } = Input;

const ValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Please provide category name'),
  // description: yup.string(),
  // account_code: yup.string(),
});

const AddCategory = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { id: currentId, parentId: parent_id } = props;
  const [categoryType, setRadioValue] = React.useState('main');
  const dispatch = useDispatch();
  const categoriesResponse = useSelector(state => state.categories.categories?.data?.data || []);

  const onChange = e => {
    setRadioValue(e.target.value);
  };
  React.useEffect(() => {
    form.resetFields();
    if (categoriesResponse.length && currentId) {
      const editValues = parent_id ?
        (findLodash([...(findLodash(categoriesResponse, { id: parent_id }))?.category], { id: currentId })) :
        (findLodash(categoriesResponse, { id: currentId }));
      console.log('44=', editValues)
      form.setFieldsValue({
        name: editValues.name,
        account_code: editValues.account_code ?? "",
        description: editValues.description ?? "",
        parent_id: editValues.parent_id,
      });
      setRadioValue(parent_id ? 'subCat' : 'main');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, categoriesResponse]);
  const handleCancelButton = () => {
    form.resetFields();
    props.handleCancel();
  }


  const renderMainCategoryUi = () => {
    return (
      <Form layout="vertical"
        form={form}
        onFinish={handleSubmit}
        className="modal-form"
      >
        <Form.Item label={t('category_name')} name="name" required={true} rules={[{ required: true, message: t('please_enter_category_name') }]}>
          <Input placeholder={t('category_name')} />
        </Form.Item>
        <Form.Item label={t('account_code')} name="account_code">
          <Input placeholder={t('enter_category_code')} />
        </Form.Item>
        <Form.Item label={t('desc')} name="description">
          <TextArea
            placeholder={t('enter_desc')}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        {/* <div className="dividerText">
             Define rules for this category
          </div>
           <Form.Item name="checkboxGroup" valuePropName="checked" style={{ marginBottom: '5px' }}>
            <Collapse accordion className='customCollapse'>
                  <Panel header="Expense" key="1">
                    <Checkbox.Group onChange={onCheckChange} defaultValue={checkboxesData}>
                        <p>Bill Mandatory <Checkbox value="is_bill_mandatory" /></p>
                        <p>Documents required<Checkbox value="is_document_mandatory" /></p>
                        <p>Allow users to submit future bill date<Checkbox value="is_submited_feature_bill" /></p>
                    </Checkbox.Group>
                  </Panel>
              </Collapse>
            </Form.Item>
           <Collapse accordion className='customCollapse'>
                <Panel header="Category based Approval Flow" key="2">
                  <Steps direction="vertical" size="small" current={reportsApprovalData.length + 1 || 100}>
                    {reportsApprovalData && reportsApprovalData.map((item, index) =>
                      <Step key={item.id} title={item.role_name} icon={<div className="stepsNumber">{index+1}</div>} />
                    )}
                  </Steps>
                </Panel>
            </Collapse> */}
        {/* <Collapse accordion>
                <Panel header="Required expense fields" key="3">
                </Panel>
            </Collapse> */}
        {/* <Form.Item label="View Load Money Accounts">
               <Select
                 style={{ width: '400px' }}
                 mode="multiple"
                 getPopupContainer={(trigger) => trigger.parentNode}
                 placeholder="Select account"
               >
                   <Option>item</Option>
               </Select>
           </Form.Item> */}
        <Button key="1" htmlType="submit" className="formButton mt3 primaryButton">{currentId ? t('update') : t('save')}</Button>
        <Button key="2" className="cancelButton ml1 mt3" onClick={() => handleCancelButton()}>{t('cancel')}</Button>
      </Form>
    );
  }

  const renderSubCategoryUi = () => {
    return (
      <Form layout="vertical"
        onFinish={handleSubmit}
        className="modal-form"
      >
        <Form.Item label={t('category')} name="name" rules={[{ required: true, message: t('please_enter_name') }]}>
          <Input placeholder={t('category_name')} />
        </Form.Item>
        <Form.Item label={t('category_parent')} name="parent_id" rules={[{ required: true, message: t('please_select_value') }]}>
          <Select
            placeholder={t('select_category')}
          >
            {categoriesResponse && categoriesResponse.map(({ id, name }) => <Option key={id} value={id}>{name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item label={t('account_code')} name="account_code">
          <Input placeholder={t('category_code')} />
        </Form.Item>
        <Form.Item label={t('desc')} name="description">
          <TextArea
            // value={value}
            // onChange={this.onChange}
            placeholder={t('enter_desc')}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item>
          <Text className="mb1">{t('parent_category')}</Text>
        </Form.Item>
        <Button key="1" htmlType="submit" className="formButton mt1">{t('save')}</Button>
        <Button key="2" className="cancelButton ml1 mt1" onClick={() => props.handleCancel()}>{t('cancel')}</Button>
      </Form>
    );
  }

  const handleSubmit = (values) => {
    const payload = {};
    if (categoryType === 'subCat') {
      [
        'name',
        'description',
        'account_code',
        'parent_id',
      ].forEach(item => {
        payload[item] = values[item];
      });
      ['is_bill_mandatory',
        'is_document_mandatory',
        'is_submited_feature_bill', 'status'].forEach((item) => { payload[item] = false });
    } else {
      [
        'name',
        'description',
        'account_code',
      ].forEach(item => {
        payload[item] = values[item];
      });
      //  'is_submited_feature_bill'].forEach((item) => payload[item] = JSON.parse(payload[item]));   
    }
    // console.log(payload,"payloadDetails==>");

    ValidationSchema.validate(payload)
      .then(function ({ ...value }) {
        Object.keys(payload).forEach(key => {
          if (payload[key] === undefined) {
            delete payload[key];
          }
        });
        currentId ?
          dispatch(addCategories({ ...payload, id: currentId })) :
          dispatch(addCategories({ ...payload }));
        form.resetFields();
      })
      .catch(function (err) {
      });
  }

  return (
    <>
      <Radio.Group onChange={onChange} value={categoryType} className="category_type">
        <Radio value={'main'} disabled={currentId}>{t('main_category')}</Radio>
        <Radio value={'subCat'} disabled={currentId}>{t('sub_category')}</Radio>
      </Radio.Group>
      {categoryType === 'main' ? renderMainCategoryUi() : renderSubCategoryUi()}
    </>
  );
}

export default AddCategory;

