import { pdf } from "@react-pdf/renderer";
import { Divider } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from 'json-2-csv';
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  analytics_cards
} from "../../actions/cards/cards";
import { apis } from "../../config/APIs";
import endpointAnalyticsApi from "../../config/AxiosAnalyticsApi";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import MISExpenses from "../Common/PDF/MISPDF/MISExpenses";
import ReportTable from "./ReportTable";


const ReportsSec = (props) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.analytics.analytics_cards);
  const language = useSelector((state) => state.auth.localVariables?.language)
  const { t } = useTranslation()
  const pdfData = useSelector((state) => state?.analytics?.analytics_cards_all?.data?.data);

  useEffect(() => {
    dispatch(analytics_cards({ type: "reports" }));
    // dispatch(analytics_cards_all({ type: "reports", params: { isCsv:true },}));
    props.setFilterObj({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.pdfURL(downloadPdf)
  }, [pdfData])

  const columnsKeys = [
    { label: "Report ID", key: "report_id", type: "string" },
    { label: "Employee Name", key: "employee_name", type: "string" },
    { label: "Report Name", key: "report_name", type: "string" },
    { label: "From Date", key: "start_date", type: "date" },
    { label: "To Date", key: "end_date", type: "date" },
    { label: "Submitted On", key: "submitted_on", type: "date" },
    { label: "Amount", key: "total_amount", type: "amount" },
    { label: "Cost Center", key: "cost_center", type: "string" },
    { label: "Project", key: "project", type: "string" },
    { label: "Status", key: "status", type: "string" },
  ];


  const downloadPdf = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.analytics_reports + '?isCsv=true', { filters: filters });
    if (response) {
      const blob = await pdf((
        <MISExpenses data={response?.data?.data} columnsKeys={columnsKeys} />
      )).toBlob();
      saveAs(blob, "MISExpenseReports.pdf");
    }
  }

  ///// Dowloading CSV file method ////////
  const downloadCsv = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.analytics_reports + '?isCsv=true', { filters: filters });
    if (response) {
      const prod = response?.data?.data?.map(ele => ({
        "Report ID": ele.report_id,
        "Employee Name": ele.employee_name,
        "Report Name": ele.report_name,
        "From Date": moment(ele.start_date).format("DD MMM, YYYY"),
        "To Date": moment(ele.end_date).format("DD MMM, YYYY"),
        "Submitted On": moment(ele.submitted_on).format("DD MMM, YYYY"),
        "Amount": ele.total_amount,
        "Cost Cente": ele.cost_center,
        "Project": ele.project,
        "Status ": ele.status,
      }))

      json2csv(prod).then((csv) => {
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, 'Expense Reports.csv');
      });
    }
  }

  useEffect(() => {
    props.csvData(downloadCsv)
  }, [pdfData])

  const getObjs = () => {
    if (cards?.data?.response?.report?.length > 0) {
      return cards?.data?.response?.report?.[0]?.custom_fields?.map(ele => ({
        title: ele.key?.replace("_", " "),
        dataIndex: "custom_fields",
        key: "custom_fields",
        render: (text, data) => <div>{data?.custom_fields?.find(itm => itm.key === ele.key)?.value}</div>,
      }))
    }

    return []
  }

  const getObjKeys = () => {
    if (cards?.data?.response?.report?.length > 0) {
      return cards?.data?.response?.report?.[0]?.custom_fields?.map(ele => (ele.key))
    }

    return []
  }

  const columns = useMemo(() => [
    {
      title: t('report_id'),
      dataIndex: "report_id",
      key: "report_id",
    },
    {
      title: t('employee_name'),
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: t('reportName'),
      dataIndex: "report_name",
      key: "report_name",
    },
    {
      title: t('from_date'),
      dataIndex: "start_date",
      key: "start_date",
      render: (record) => moment(record).format("DD MMM, YYYY")

    },
    {
      title: t('to_date'),
      dataIndex: "end_date",
      key: "end_date",
      render: (record) => record ? moment(record).format("DD MMM, YYYY") : "-"

    },
    {
      title: t('submittedOn'),
      dataIndex: "submitted_on",
      key: "submitted_on",
      render: (record) => record ? moment(record).format("DD MMM, YYYY") : "-"
    },
    {
      title: t('Amount'),
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text) => <div> {text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')}</div>,
    },
    ...getObjs(),
    {
      title: t('cost_center'),
      dataIndex: "cost_center",
      key: "cost_center",
      render: (text) => text ?? '-',
    },
    {
      title: t('project'),
      dataIndex: "project",
      key: "project",
      render: (text) => text ?? '-'
    },
    {
      title: t('status'),
      dataIndex: "status",
      key: "status",
      render: (text) => <div className={`status status_${text}`}>{text ? text?.replace(/_/g, ' ') : ""}</div>,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [cards, language, t]);

  return (
    <>
      {/* <PDFDownloadLink document={} custom_fields={getObjKeys()} fileName="Reports.pdf">
        {({ blob, url, loading, error }) =>
          loading ? '' : props.setpdfURL(url)
        }
      </PDFDownloadLink> */}
      <div className="reports">
        <Divider />
      </div>
      <ReportTable
        columns={columns}
        scroll={{ x: 1800 }}
        data={Array.isArray(cards?.data?.data) ? cards?.data?.data : []}
        total_records={cards?.data?.total_count}
        paginationCall={(vals) => {
          vals.type = "reports";
          dispatch(analytics_cards(vals));
          // dispatch(analytics_cards_export({ pdf: true, csv: true, ...vals }));
        }}
        filterObj={props.filterObj}
        payload={{ ...props.filterObj, ...props.apiFilters }}
      />
    </>
  );
};

export default ReportsSec;
