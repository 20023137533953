import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Popover,
  Row,
  Skeleton,
  Steps,
  Typography,
  message,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import {
  ExpenseCustomDetails,
  expenseCustomDetails,
} from "../../actions/expenses/expenses";
import { SideMenu, headerTitles } from "../../actions/masterdata/masterdata";
import {
  ReportsCommentsAdd,
  UserReport,
  UserReportLinkExp,
  UserReportRecall,
  UserReportSubmit,
  reportsApprove,
  reportsApproveReset,
  reportsCommentsAdd,
  reportsExportExcel,
  reportsReimburse,
  reportsReimburseReset,
  reportsReject,
  reportsRejectReset,
  userReport,
  userReportLinkAdvReset,
  userReportLinkExp,
  userReportRecall,
  userReportSubmit,
} from "../../actions/reports";
import { userReportLinkTripReset } from "../../actions/trips";
import block from "../../assets/icons/block.png";
import Close from "../../assets/icons/close.png";
import Comment from "../../assets/icons/comment.png";
import excel_icon from "../../assets/icons/excel1.png";
import pdf_icon from "../../assets/icons/pdf_icon.png";
import ppt_icon from "../../assets/icons/ppticon.png";
import tiff_icon from "../../assets/icons/tifficon.png";
import History from "../../assets/icons/time_icon.png";
import warn from "../../assets/icons/warning.png";
import { apis } from "../../config/APIs";
import endpointPayExpenseBeta from "../../config/AxiosPayExpense";
import endpointPdfApi from "../../config/AxiosPdf";
import {
  amountFormat,
  arrrayOfObjectToArray,
  hasAccess,
  isSuperAdmin,
} from "../../utils";
import AddAdvance from "../Advances/AddAdvance";
import BaseCurrency from "../Common/BaseCurrency";
import { downloadAndZip } from "../Common/DownloadAll";
import NoCommentsComponent from "../Common/NoComentsComponent";
import NodataComponent from "../Common/NodataComponent";
import AddExpenses from "../Expenses/AddExpenses";
import AddReport from "./AddReport";
import Expenses from "./Expenses";
import ForwardPopup from "./ForwardPopup";
import LinkAdvances from "./LinkAdvances";
import LinkExpenses from "./LinkExpenses";
import LinkTrips from "./LinkTrips";
import ReimburseModal from "./ReimburseModal";
import ReportAdvances from "./ReportAdvances";
import ReportTrips from "./ReportTrips";
import ShareModal from "./ShareModal";
import "./new-report.css";
import usePdfApi from "../../config/usePdfApi";
const { TextArea } = Input;
const Title = Typography.Title;
const Text = Typography.Text;

const NewReportDetail = ({}) => {
  const location = useLocation();
  const hasWriteAccess = location?.state?.user
    ? true
    : hasAccess("reports_approvals", "write");
  const history = useHistory();
  const { t } = useTranslation();
  const [input, setInput] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [shareReport, setShareReport] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [violations, setViolations] = React.useState([]);
  const [showViolations, setShowViolations] = React.useState(false);
  const [reimburseModalVisible, setReimburseModalVisible] =
    React.useState(false);
  const [isReportModalVisible, setIsReportModalVisible] = React.useState(false);
  const [reasonValue, setReasonValue] = React.useState("");
  const [reportErrors, setReportErrors] = React.useState([]);
  const [forwardPop, setForwardPop] = React.useState(false);

  const reportsReimburseResponse = useSelector(
    (state) => state.reports.reportsReimburse || {}
  );
  const reportData = useSelector(
    (state) => state?.reports?.userReportShow?.data?.data || {}
  );
  const reportLoading = useSelector(
    (state) => state?.reports?.userReportShow?.loading
  );

  const {
    onCall: reportPdfUrl,
    loading: pdfLoading,
    pdfUrl: reportPDFURL,
  } = usePdfApi({
    api: apis.report_pdf,
    key: "report_url",
  });
  const reportsCommentsAddResponse = useSelector(
    (state) => state.reports.reportsCommentsAdd || {}
  );
  const reportsRejectResponse = useSelector(
    (state) => state.reports.reportsReject || {}
  );
  const reportsApproveResponse = useSelector(
    (state) => state.reports.reportsApprove || {}
  );

  const userReportSubmitResponse = useSelector(
    (state) => state.reports.userReportSubmit || {}
  );
  const userReportRecallResponse = useSelector(
    (state) => state.reports.userReportRecall || {}
  );
  // const { data: { response: { advances = [] } = {} } = {} } =
  //   reportsAdvanceResponse;
  const [tab, setTab] = useState("details");
  const expensesResponse = useSelector(
    (state) => state.reports.reportsExpDetails || {}
  );

  const { data: { response: { transactions = [] } = {} } = {} } =
    expensesResponse;

  const {
    expenses: expenses = [],
    advances: advances = [],
    trips: trips = [],
  } = reportData;

  const userReportLinkExpResponse = useSelector(
    (state) => state.reports.userReportLinkExp || {}
  );
  const addExpenseResponse = useSelector(
    (state) => state.expenses.addExpense || {}
  );
  const userReportLinkAdvResponse = useSelector(
    (state) => state.reports.userReportLinkAdv || {}
  );
  const userReportLinkTripResponse = useSelector(
    (state) => state.trips.userReportLinkTrips || {}
  );

  const expenseFieldsResponse = useSelector(
    (state) => state.expenses.expenseFields || {}
  );
  const tripCustomData = useSelector(
    (state) => state?.expenses?.expenseCustomDetails?.data?.data || []
  );

  const { data: { response: { fields = [] } = {}, status = false } = {} } =
    expenseFieldsResponse;
  // const { data: { response: { trips = [] } = {} } = {} } = reportsTripsResponse;
  const inputComment = React.useRef(null);
  const resetFormRef = React.useRef();

  const handleAddComment = () => {
    if (input?.trim() && params.id) {
      dispatch(
        reportsCommentsAdd({
          body: input,
          commentable_id: params.id,
          // is_admin: !location?.state?.user,
          commentable_type: "report",
        })
      );
      setInput("");
    } else {
      message.error(
        <span className="messageText">{t("comments_required")}</span>
      );
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setReasonValue("");
    setIsReportModalVisible(false);
    if (resetFormRef.current) {
      resetFormRef.current();
    }
  };

  const dispatch = useDispatch();

  const params = useParams();
  const callOnMount = () => {
    if (params.id) {
      dispatch(
        userReport({
          module_type: "REPORT",
          report_id: params.id,
          // is_admin: !location?.state?.user,
        })
      );
      reportPdfUrl({
        params: {
          report_id: params.id,
        },
      });

      dispatch(
        expenseCustomDetails({
          module_type: "REPORT",
          module_id: params.id,
          custom_flag: true,
        })
      );
    }
  };

  React.useEffect(() => {
    callOnMount();
    dispatch(
      headerTitles({
        title: "report_details",
        description: "report_desc",
      })
    );
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/expenses",
        childrenMenu: location?.state?.user ? "/my-reports" : "",
        showChildren: true,
        secondNav: "/reports",
      },
    });

    return () => {
      setReportErrors([]);
      dispatch({
        type: UserReport.RESET,
      });
      dispatch({ type: ExpenseCustomDetails.RESET });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const callOnAction = () => {
    dispatch(
      userReport({
        module_type: "REPORT",
        report_id: params.id,
        is_admin: !location?.state?.user,
      })
    );
    // dispatch(
    //   reportsHistory(
    //     { report_id: params.id },
    //     { is_admin: !location?.state?.user }
    //   )
    // );
  };
  React.useEffect(() => {
    const { data: { status = "", data: { id = "" } = {} } = {} } =
      addExpenseResponse;
    if (status) {
      setIsModalVisible(false);
      if (resetFormRef.current) {
        resetFormRef.current();
      }
      // message.success(<span className="messageText">{res_message}</span>);
      const payload = {};
      payload.transaction_ids = [id];
      payload.report_id = params.id;
      endpointPayExpenseBeta
        .post(apis.validate_expense, {
          transaction_ids: payload.transaction_ids,
        })
        .then((res) => {
          let txns = res.data?.data?.transactions || [];
          let policies = [];
          txns?.forEach((ele) => {
            policies = [
              ...policies,
              ...ele?.policies?.map(
                (e) =>
                  (e.policy_action === "BLOCKING" ||
                    e.policy_action === "WARNING") &&
                  e.errors?.length
              ),
            ];
          });

          if (policies?.filter((ele) => ele)?.length) {
            setShowViolations(true);
            setViolations(txns);
          } else {
            dispatch(userReportLinkExp(payload));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExpenseResponse]);

  React.useEffect(() => {
    const { data: { status = false, message: res_message = "" } = {} } =
      userReportLinkAdvResponse;
    if (status) {
      setIsModalVisible(false);
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(
        userReport({
          report_id: params.id,
          module_type: "REPORT",
          is_admin: !location?.state?.user,
        })
      );
      dispatch(userReportLinkAdvReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkAdvResponse]);

  React.useEffect(() => {
    const { data: { status = false, message: res_message = "" } = {} } =
      userReportLinkTripResponse;
    if (status) {
      setIsModalVisible(false);
      message.success(<span className="messageText">{res_message}</span>);
      // dispatch(
      //   reportsTrips(
      //     { report_id: params.id },
      //     { is_admin: !location?.state?.user }
      //   )
      // );
      dispatch(userReportLinkTripReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkTripResponse]);

  React.useEffect(() => {
    const { data: { status = false, message: res_message = "" } = {} } =
      userReportSubmitResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch({
        type: UserReportSubmit.RESET,
      });
      callOnAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportSubmitResponse]);

  React.useEffect(() => {
    const { data: { status = false, message: res_message = "" } = {} } =
      userReportRecallResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      callOnAction();
      dispatch({
        type: UserReportRecall.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportRecallResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      reportsCommentsAddResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(
        userReport(
          { report_id: params.id, module_type: "REPORT" }
          // { is_admin: !location?.state?.user }
        )
      );
      dispatch({
        type: ReportsCommentsAdd.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsCommentsAddResponse]);

  function handleMenuClick(e) {
    switch (e.key) {
      case "3":
        dispatch(reportsExportExcel({ id: params.id }));
        break;
      case "5":
        setShareReport(true);
        break;
      case "6":
        setForwardPop(true);
      default:
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="1"
        onClick={() => {
          if (hasWriteAccess && reportPDFURL) {
            window.open(reportPDFURL, "_blank");
          } else if (pdfLoading) {
            message.warn(<span className="messageText">PDF is loading.</span>);
          } else {
            message.error(
              <span className="messageText">PDF not available</span>
            );
          }
        }}
      >
        {t("download_pdf")}
      </Menu.Item>
      <Menu.Item key="5">{t("share_report")}</Menu.Item>
      {!location.state?.user && (
        <Menu.Item key="6">{t("forward_approval")}</Menu.Item>
      )}
      {transactions?.filter((ele) => ele?.files?.length > 0)?.length > 0 && (
        <Menu.Item
          key="2"
          onClick={() => {
            endpointPdfApi
              .get(apis.zip, {
                params: {
                  id: params.id,
                },
              })
              .then((res) => {
                if (res.data) {
                  console.log(res);
                }
              });
          }}
        >
          {t("download_receipts")}
        </Menu.Item>
      )}
      {/* <Menu.Item key="3">Export to Excel</Menu.Item>
      <Menu.Item key="4">Print</Menu.Item> */}
    </Menu>
  );

  const content =
    reportData?.total_amount - reportData?.total_advances_amount > 0 &&
    reportData?.reimbursement ? (
      <div className="pop-card">
        <p>
          <span>
            {t("reference")} &nbsp;&nbsp;&nbsp;&nbsp; <br />
          </span>
          <span className="dim">{reportData?.reimbursement.reference}</span>
        </p>
        <p>
          <span>
            {t("reimbursed_on")} &nbsp;&nbsp;&nbsp;&nbsp; <br />
          </span>
          <span className="dim">
            {moment(reportData?.reimbursement.reimbursed_on).format(
              "DD-MM-YYYY HH:mm"
            )}
          </span>
        </p>
        <p>
          <span>
            {t("billing_to")}
            <br />
          </span>
          <span className="dim">{reportData?.reimbursement.billing_to}</span>
        </p>
        <p>
          <span>
            Mode &nbsp;&nbsp;&nbsp;&nbsp; <br />
          </span>
          <span className="dim">{reportData?.reimbursement.mode}</span>
        </p>
        <p>
          <span>
            {t("notes")} &nbsp;&nbsp;&nbsp;&nbsp; <br />
          </span>
          <span className="dim">{reportData?.reimbursement.notes}</span>
        </p>
      </div>
    ) : (
      <div className="pop-card">
        <p>
          <span>
            {t("total_expense_amount")}&nbsp;&nbsp;&nbsp;&nbsp; <br />
          </span>
          <span className="dim">
            <BaseCurrency /> {amountFormat(reportData?.total_amount) || 0}
          </span>
        </p>
        <p>
          <span>
            {t("non_reimburse_amount")} &nbsp;&nbsp;&nbsp;&nbsp; <br />
          </span>
          <span className="dim">
            <BaseCurrency />
            {(reportData?.total_non_reimbursable_amount &&
              amountFormat(reportData?.total_non_reimbursable_amount)) ||
              0}
          </span>
        </p>
        <p>
          <span>
            {t("total_advance_amount")} &nbsp;&nbsp;&nbsp;&nbsp; <br />
          </span>
          <span className="dim">
            <BaseCurrency />
            {amountFormat(reportData?.total_advances_amount) || 0}
          </span>
        </p>
        <p>
          <span>
            {t("reimburse_amount")} &nbsp;&nbsp;&nbsp;&nbsp; <br />
          </span>
          <span className="dim">
            <BaseCurrency />
            {amountFormat(
              reportData?.advance_refunds?.reduce((accu, current) => {
                return accu + current.amount_cents;
              }, 0)
            ) || 0}
          </span>
        </p>
        <p>
          <span>
            {reportData?.status === "reimbursed"
              ? t("reimbursed_amount")
              : t("amount_to_reimburse")}
            <br />
          </span>
          <span className="dim">
            <BaseCurrency />
            {amountFormat(
              reportData?.total_amount -
                reportData?.total_non_reimbursable_amount -
                reportData?.total_advances_amount
            ) || 0}
          </span>
        </p>
      </div>
    );

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      reportsRejectResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      // dispatch(reports(objectToFormData({ status: true })));
      dispatch(reportsRejectReset());
      setReasonValue("");
      callOnAction();
    } else {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsRejectResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      reportsApproveResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(reportsApproveReset());
      callOnAction();
    } else {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsApproveResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      reportsReimburseResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      // dispatch(reports(objectToFormData({ status: true })));
      dispatch(reportsReimburseReset());
      setReimburseModalVisible(false);
      callOnAction();
    } else {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsReimburseResponse]);

  React.useEffect(() => {
    const { data: { status = "", message: res_message = "" } = {} } =
      userReportLinkExpResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      // dispatch(
      //   reportsExpDetails(
      //     { report_id: params.id },
      //     { is_admin: !location?.state?.user }
      //   )
      // );
      dispatch(
        userReport({
          module_type: "REPORT",
          report_id: params.id,
          is_admin: !location?.state?.user,
        })
      );
      dispatch({
        type: UserReportLinkExp.RESET,
      });
    } else if (status === false) {
      setIsModalVisible(false);
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UserReportLinkExp.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkExpResponse]);

  React.useEffect(() => {
    if (reportData?.expense_errors?.report) {
      let arr = [];
      reportData?.expense_errors?.report?.forEach((ele) =>
        ele?.policies?.forEach((itm) =>
          itm.errors?.forEach((item) => arr.push(item))
        )
      );
      setReportErrors(arr);
    }
  }, [reportData]);

  const handleSubmitReport = () => {
    dispatch(
      userReportSubmit(
        { id: params.id },
        {
          is_admin: !location?.state?.user,
        }
      )
    );
    setShowViolations(false);
  };

  const renderModalTitle = (type) => {
    switch (type) {
      case "delete":
        return "Reject Report";
      case "addNewExp":
        return "";
      case "addNewAdvance":
        return "";
      case "addUnrepExp":
        return "Add Expenses to Report";
      case "addUnrepAdvance":
        return "Add Advance to Report";
      case "addUnrepTrip":
        return "Add Trip to Report";
      default:
        return "";
    }
  };

  const renderModalClass = (type) => {
    switch (type) {
      case "delete":
        return "center-aligned-modal";
      case "addNewExp":
        return "center-aligned-modal-big expense";
      case "addUnrepExp":
        return "right-alinged-modal";
      case "addUnrepAdvance":
        return "right-alinged-modal";
      case "addUnrepTrip":
        return "right-alinged-modal";
      default:
        return "";
    }
  };

  const renderModalData = (type) => {
    switch (type) {
      case "delete":
        return renderDeleteOption();
      case "addNewExp":
        return (
          <AddExpenses
            expensesFieldsData={fields}
            setAddOption={setIsModalVisible}
            handleCancel={handleCancel}
            reportId={reportData?.id}
            isModalVisible={isModalVisible}
            resetForm={(resetForm) => {
              resetFormRef.current = resetForm;
            }}
          />
        );
      case "addNewAdvance":
        return (
          <AddAdvance handleCancel={handleCancel} reportId={reportData?.id} />
        );
      case "addNewTrip":
        history.push("/expenses/add-trip/");
        return;
      case "addUnrepExp":
        return renderUnrepOption();
      case "addUnrepAdvance":
        return hasWriteAccess ? (
          <LinkAdvances report_id={params.id} from="report" />
        ) : null;
      case "addUnrepTrip":
        return renderUnrepTripOption();
      default:
        return "";
    }
  };

  const renderDeleteOption = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            reportsReject({
              id: params.id,
              reject_reason: reasonValue,
              params: { module_type: "REPORT" },
            })
          );
        }}
      >
        <Text>{t("rejection_reason")}</Text>
        <br />
        <TextArea
          rows={4}
          placeholder="Enter Reason"
          className="reasonTextArea"
          onChange={(e) => setReasonValue(e.target.value)}
          required={true}
        />
        <br />
        <Button
          key="1"
          className="formButton mt1"
          htmlType="submit"
          disabled={reportsRejectResponse?.loading}
        >
          {t("confirm")}
        </Button>
        <Button
          key="2"
          htmlType="button"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t("cancel")}
        </Button>
      </form>
    );
  };

  const renderUnrepOption = () => {
    return (
      <>
        {hasWriteAccess && (
          <LinkExpenses
            report_id={params?.id}
            transactions_id={arrrayOfObjectToArray(transactions, "id")}
          />
        )}
      </>
    );
  };

  const handleDownloadClick = (fileUrl) => {
    console.log(fileUrl, "fileDetails==>");
    window.open(fileUrl, "_blank");
  };

  const renderUnrepTripOption = () => {
    return (
      <>{hasWriteAccess && <LinkTrips report_id={params.id} update={true} />}</>
    );
  };

  const secs = [
    "Details",
    "Expense",
    "Advances",
    "Trips",
    "Comments",
    "History",
  ];
  return (
    <>
      <ForwardPopup
        isVisiable={forwardPop && hasWriteAccess}
        onCancel={() => {
          setForwardPop(false);
        }}
        reportId={reportData?.id}
        setForwardPop={setForwardPop}
      />
      <ShareModal
        open={shareReport && hasWriteAccess}
        id={reportData?.id}
        onClose={() => {
          setShareReport(false);
        }}
      />
      <Modal
        title={renderModalTitle(modalType)}
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={renderModalClass(modalType)}
      >
        {renderModalData(modalType)}
      </Modal>
      <ReimburseModal
        title={t("record_reimburse")}
        open={reimburseModalVisible && hasWriteAccess}
        handleCancel={() => {
          setReimburseModalVisible(false);
        }}
        id={params.id}
        onSubmit={(vals) => {
          dispatch(reportsReimburse(vals));
        }}
        isPositive={
          reportData?.total_amount - reportData?.total_advances_amount >= 0
        }
        total_amount={reportData?.total_amount}
        total_advances_amount={reportData?.total_advances_amount}
        loading={reportsReimburseResponse?.loading}
      />
      <Modal
        title={<Title level={4}>{t("policy_violation")}</Title>}
        wrapClassName="exp-table"
        visible={showViolations && hasWriteAccess}
        onCancel={() => {
          setShowViolations(false);
        }}
        // footer={[]}
        footer={
          violations?.some((ele) =>
            ele?.policies?.some(
              (itm) =>
                itm.policy_action === "BLOCKING" && itm?.errors?.length > 0
            )
          )
            ? []
            : [
                <Button type="primary" onClick={handleSubmitReport}>
                  Continue
                </Button>,
                <Button
                  className="cancelButton ml1 mt1"
                  onClick={() => setShowViolations(false)}
                >
                  Cancel
                </Button>,
              ]
        }
      >
        <div className="violations">
          {violations?.map((ele, idx) =>
            ele.policies.some((policy) => policy.errors.length > 0) ? (
              <div className="item">
                {idx + 1}. {ele.report_number} -
                {ele.policies?.map((itm) =>
                  itm.errors.map((e) => (
                    <span
                      className={itm?.policy_action === "WARNING" ? "warn" : ""}
                    >
                      <img
                        src={itm?.policy_action === "WARNING" ? warn : block}
                        alt=""
                        width={12}
                      />{" "}
                      {e}
                    </span>
                  ))
                )}
              </div>
            ) : null
          )}
        </div>
      </Modal>
      {isReportModalVisible && (
        <Modal
          title={
            <Title level={4}>
              {t("edit_report")} &nbsp;
              <span className="text-small">{t("enter_details")}</span>
            </Title>
          }
          visible={isReportModalVisible && hasWriteAccess}
          onCancel={handleCancel}
          footer={[]}
          className="right-alinged-modal"
        >
          <AddReport
            handleCancel={handleCancel}
            report={reportData}
            callOnMount={callOnMount}
          />
        </Modal>
      )}
      {reportData?.reject_reason && (
        <div className="strip red">
          {t("reason_reject")}: {reportData?.reject_reason}
        </div>
      )}
      <div className="report-btns">
        {!location?.state?.user ? (
          <>
            {(!reportData?.action_restrict || isSuperAdmin()) &&
            reportData?.role_status === "pending_approval" &&
            reportData.is_editable === true ? (
              <>
                <button
                  className="approve"
                  disabled={reportsApproveResponse?.loading || !hasWriteAccess}
                  onClick={() => {
                    dispatch(
                      reportsApprove({
                        id: params.id,
                        params: { module_type: "REPORT" },
                      })
                    );
                  }}
                >
                  {t("approve")}
                </button>
                <button
                  disabled={!hasWriteAccess}
                  className="reject"
                  onClick={() => {
                    setIsModalVisible(true);
                    setModalType("delete");
                  }}
                >
                  {t("reject")}
                </button>
              </>
            ) : null}
            {reportData?.status === "pending_reimbursement" &&
              reportData.is_editable === true && (
                <>
                  <button
                    disabled={!hasWriteAccess}
                    className="approve"
                    onClick={() => {
                      setReimburseModalVisible(true);
                    }}
                  >
                    {t("reimburse")}
                  </button>
                  <button
                    disabled={!hasWriteAccess}
                    className="reject"
                    onClick={() => {
                      setIsModalVisible(true);
                      setModalType("delete");
                    }}
                  >
                    {t("reject")}
                  </button>
                </>
              )}
          </>
        ) : (
          <>
            {reportData?.status === "unsubmitted" && (
              <>
                <button
                  className="approve"
                  disabled={
                    userReportSubmitResponse?.loading ||
                    reportData?.expenses.length < 1 ||
                    !hasWriteAccess
                  }
                  onClick={() => {
                    endpointPayExpenseBeta
                      .post(apis.validate_expense, {
                        report_id: params.id,
                      })
                      .then((res) => {
                        let txns = res.data?.data?.report || [];
                        let policies = [];
                        txns?.forEach((ele) => {
                          policies = [
                            ...policies,
                            ...ele?.policies?.map(
                              (e) =>
                                (e.policy_action === "BLOCKING" ||
                                  e.policy_action === "WARNING") &&
                                e.errors?.length
                            ),
                          ];
                        });
                        console.log(policies, "policyDetails====>");

                        if (policies?.filter((ele) => ele)?.length) {
                          setShowViolations(true);
                          setViolations(txns);
                        } else {
                          dispatch(
                            userReportSubmit(
                              { id: params.id },
                              {
                                is_admin: !location?.state?.user,
                              }
                            )
                          );
                        }
                      });
                  }}
                >
                  {t("submit")}
                </button>
                <button
                  disabled={!hasWriteAccess}
                  className="reject"
                  onClick={() => {
                    setIsReportModalVisible(true);
                  }}
                >
                  {t("edit")}
                </button>
              </>
            )}
            {reportData?.is_recallable &&
            reportData?.status === "pending_approval" &&
            location?.state?.user ? (
              <>
                <button
                  className="reject"
                  disabled={
                    userReportRecallResponse?.loading || !hasWriteAccess
                  }
                  onClick={() =>
                    dispatch(
                      userReportRecall({
                        params: { module_type: "REPORT" },
                        id: params.id,
                        // is_admin: !location?.state?.user,
                      })
                    )
                  }
                >
                  {t("recall")}
                </button>
              </>
            ) : null}
          </>
        )}

        <Dropdown overlay={menu} placement="bottomRight">
          <button className="more">
            {t("more")}&nbsp;
            <DownOutlined />
          </button>
        </Dropdown>
        <span
          className="close cursor"
          // onClick={() => {
          //   history.push(location?.state?.from, {
          //     status: location?.state?.status,
          //   });
          // }}
          onClick={() => {
            history.push({
              pathname: location?.state?.user
                ? "/expenses/reports/my-reports"
                : `/expenses/reports`,
              state: {
                status: location?.state?.status,
              },
            });
          }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div>
      <div className="new-report-detail">
        <div className="flex-between">
          <div className="report-head">
            <div className="title-sec">
              <h3>
                {reportLoading ? (
                  <Skeleton.Button style={{ width: "20rem" }} />
                ) : (
                  `${
                    reportData?.report_number
                      ? "[" + reportData?.report_number + "] "
                      : ""
                  } ${reportData?.title}`
                )}
              </h3>
              {reportLoading ? null : (
                <span
                  className={`status status_${
                    location?.state?.user
                      ? reportData?.status
                      : reportData?.role_status
                  }`}
                >
                  {location?.state?.user
                    ? reportData?.status?.replace(/_/g, " ")
                    : reportData?.role_status?.replace(/_/g, " ")}
                </span>
              )}
            </div>

            <p className="duration">
              {reportLoading ? (
                <Skeleton.Button style={{ width: "20rem", height: 10 }} />
              ) : (
                t("duration")
              )}
              : {moment(reportData?.from_date).format("DD MMM YYYY, HH:mm a")} -{" "}
              {moment(reportData?.to_date).format("DD MMM YYYY, HH:mm a")}
            </p>
            <p>
              <strong>
                {t("submitted_by")}{" "}
                {reportLoading ? (
                  <Skeleton.Button
                    style={{
                      width: "5rem",
                      height: 15,
                      marginLeft: "10px",
                      marginTop: 5,
                    }}
                  />
                ) : (
                  reportData?.submitted_by?.name
                )}{" "}
                <br />
                <span className="sm">
                  {t("on")}{" "}
                  {reportLoading ? (
                    <Skeleton.Button
                      style={{
                        width: "5rem",
                        height: 15,
                        marginLeft: "10px",
                        marginTop: 5,
                      }}
                    />
                  ) : (
                    reportData.submitted_date &&
                    moment(reportData.submitted_date).format(
                      "DD MMM YYYY, HH:mm a"
                    )
                    // moment(reportData.submitted_date && reportData?.submitted_date).format("DD MMM YYYY HH:mm:ss")
                  )}
                </span>
                <br />
                <span className="sm">
                  {t("to")}{" "}
                  {reportLoading ? (
                    <Skeleton.Button
                      style={{
                        width: "7rem",
                        height: 15,
                        marginLeft: "10px",
                        marginTop: 5,
                      }}
                    />
                  ) : (
                    reportData?.submitted_to?.email
                  )}
                </span>
                {reportData?.status === "pending_approval" ? (
                  <>
                    <span>
                      {reportData?.violation_data?.report?.map(
                        (reportItem, reportIndex) => (
                          <div key={reportIndex}>
                            {reportItem?.policies?.map(
                              (policy, policyIndex) => (
                                <div key={policyIndex}>
                                  {policy.is_warning ? (
                                    <>
                                      {policy.errors.map(
                                        (error, errorIndex) => (
                                          <>
                                            <div
                                              key={errorIndex}
                                              className="v-center mbHalf"
                                            >
                                              <img
                                                src={warn}
                                                alt="warning-icon"
                                                style={{
                                                  width: "1rem",
                                                  height: "1rem",
                                                  marginRight: "0.5rem",
                                                }}
                                              />
                                              <h4
                                                className="mb0"
                                                style={{ color: "orange" }}
                                              >
                                                {error}
                                              </h4>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {policy.errors.map(
                                        (error, errorIndex) => (
                                          <>
                                            <div
                                              key={errorIndex}
                                              className="v-center mbHalf"
                                            >
                                              <img
                                                src={block}
                                                alt="block-icon"
                                                style={{
                                                  width: "1rem",
                                                  height: "1rem",
                                                  marginRight: "0.5rem",
                                                }}
                                              />
                                              <h4
                                                className="mb0"
                                                style={{ color: "orange" }}
                                              >
                                                {error}
                                              </h4>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )
                      )}
                    </span>
                  </>
                ) : null}
              </strong>
            </p>
          </div>
          <div className="report-total">
            <p>
              <label>{t("report_total")}</label>
              <span className="lg">
                <BaseCurrency /> {amountFormat(reportData?.total_amount) || 0}
              </span>
            </p>
            <p>
              <label>{t("amount_to_reimburse")}</label>
              <span className="lg">
                <BaseCurrency />{" "}
                {amountFormat(
                  reportData?.total_amount -
                    reportData?.total_non_reimbursable_amount -
                    reportData?.total_advances_amount
                ) || 0}
              </span>
              <Popover
                placement="bottomRight"
                title={t("summary")}
                content={content}
                trigger="click"
                className="summaryCard"
              >
                <span className="link cursor">{t("view_summary")}</span>
              </Popover>
            </p>
          </div>
        </div>
        {reportErrors?.length > 0 && (
          <section className="report-error forReport mt1">
            <p>{reportData.report_number}</p>
            {reportErrors?.map((itm) => (
              <p>
                <img src={block} alt="" className="mr1" />
                {itm}
              </p>
            ))}
          </section>
        )}

        <div className="tabs-sec">
          <ul>
            {secs.map((ele) => (
              <li
                onClick={() => {
                  setTab(ele.toLowerCase());
                  if (ele === "History") {
                    // dispatch(
                    //   reportsHistory(
                    //     { report_id: params.id },
                    //     { is_admin: !location?.state?.user }
                    //   )
                    // );
                  }
                }}
                className={`${
                  tab.toLowerCase() === ele.toLowerCase() ? "active" : ""
                }`}
              >
                {ele}
              </li>
            ))}
          </ul>
        </div>

        <div className="selected-tab">
          {tab === "expense" ? (
            expenses?.length ? (
              <>
                {location?.state?.user &&
                  reportData?.status === "unsubmitted" && (
                    <div className="report-btns mb2">
                      <button
                        disabled={!hasWriteAccess}
                        className="secondary-btn mr1"
                        onClick={() => {
                          setIsModalVisible(true);
                          setModalType("addNewExp");
                        }}
                      >
                        {t("add_new_expense")}
                      </button>
                      <button
                        disabled={!hasWriteAccess}
                        className="primary-btn"
                        onClick={() => {
                          setIsModalVisible(true);
                          setModalType("addUnrepExp");
                        }}
                      >
                        {t("add_unreported_expense")}
                      </button>
                    </div>
                  )}
                <Expenses
                  expensesData={expenses}
                  expensesDataLength={expenses?.length > 1}
                  showReject={
                    reportData?.status === "pending_approval" && hasWriteAccess
                  }
                  showDelete={
                    reportData?.status === "unsubmitted" && hasWriteAccess
                  }
                  callExpenseDetails={() => {
                    if (hasWriteAccess) {
                      dispatch(
                        userReport({
                          report_id: params.id,
                          module_type: "REPORT",
                          is_admin: !location?.state?.user,
                        })
                      );
                      // dispatch(
                      //   reportsExpDetails(
                      //     { report_id: params.id },
                      //     { is_admin: !location?.state?.user }
                      //   )
                      // );
                    }
                  }}
                />
                <Row className="mt1">
                  <Col span={6} offset={18}>
                    <div className="totalsSection">
                      <p>
                        {t("total_expense_amount")}
                        <span>
                          <BaseCurrency />{" "}
                          {amountFormat(reportData?.total_amount) || 0}
                        </span>
                      </p>
                      <p>
                        {t("non_reimburse_amount")}
                        <span>
                          {" "}
                          <BaseCurrency />{" "}
                          {amountFormat(
                            reportData?.total_non_reimbursable_amount
                          ) || 0}
                        </span>
                      </p>
                      <p>
                        {t("advance_amount")}
                        <span>
                          {" "}
                          <BaseCurrency />{" "}
                          {amountFormat(reportData?.total_advances_amount) || 0}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt1 ">
                  <Col span={24}>
                    <div className="totalBar">
                      <p>
                        {t("amount_to_reimburse")}
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <BaseCurrency />{" "}
                          {amountFormat(
                            reportData?.total_amount -
                              reportData?.total_non_reimbursable_amount -
                              reportData?.total_advances_amount
                          ) || 0}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <NodataComponent
                module={"Expenses"}
                msg={t("msg_expense")}
                noBtns={!location?.state?.user}
                noSecBtn={reportData?.status !== "unsubmitted"}
                hasWriteAccess={hasWriteAccess}
                onPrimaryClick={() => {
                  if (hasWriteAccess) {
                    setIsModalVisible(true);
                    setModalType("addNewExp");
                  }
                }}
                secondaryText={t("link_unreported_expense")}
                onSecondaryClick={() => {
                  if (hasWriteAccess) {
                    setIsModalVisible(true);
                    setModalType("addUnrepExp");
                  }
                }}
              />
            )
          ) : null}
          {tab === "advances" ? (
            advances.length ? (
              <>
                {location?.state?.user &&
                  reportData?.status === "unsubmitted" && (
                    <div className="report-btns mb2">
                      <button
                        disabled={!hasWriteAccess}
                        className="secondary-btn mr1"
                        onClick={() => {
                          // setIsModalVisible(true);
                          // setModalType("addNewExp");
                          setIsModalVisible(true);
                          setModalType("addUnrepAdvance");
                        }}
                      >
                        {t("link_advance")}
                        {/* {t('add_new_advance')} */}
                      </button>
                    </div>
                  )}
                <ReportAdvances
                  reportsAdvanceData={advances}
                  user={location?.state?.user}
                  isUnlink={
                    (reportData?.status === "unsubmitted" ||
                      reportData?.status === "rejected") &&
                    location?.state?.user
                  }
                  reportStatus={reportData?.status}
                  callExpenseDetails={() => {
                    if (hasWriteAccess) {
                      dispatch(
                        userReport({
                          report_id: params.id,
                          is_admin: !location?.state?.user,
                          module_type: "REPORT",
                        })
                      );
                    }
                  }}
                />
              </>
            ) : (
              <NodataComponent
                msg={t("msg_advance")}
                module={"Advances"}
                noBtns={!location?.state?.user}
                noPrimaryBtn={true}
                noSecBtn={reportData?.status !== "unsubmitted"}
                secondaryText={t("link_advance")}
                hasWriteAccess={hasWriteAccess}
                onSecondaryClick={() => {
                  if (hasWriteAccess) {
                    setIsModalVisible(true);
                    setModalType("addUnrepAdvance");
                  }
                }}
              />
            )
          ) : null}
          {tab === "trips" ? (
            trips.length ? (
              <>
                {location?.state?.user &&
                  reportData?.status === "unsubmitted" && (
                    <div className="report-btns mb2">
                      <button
                        disabled={!hasWriteAccess}
                        className="secondary-btn mr1"
                        onClick={() => {
                          setIsModalVisible(true);
                          setModalType("addUnrepTrip");
                        }}
                      >
                        {/* {t('add_new_trip')} */}
                        {t("link_trips")}
                      </button>
                    </div>
                  )}
                <ReportTrips
                  reportsTripData={trips}
                  user={location?.state?.user}
                  callExpenseDetails={() => {
                    if (hasWriteAccess) {
                      dispatch(
                        userReport({
                          report_id: params.id,
                          module_type: "REPORT",
                        })
                      );
                    }
                  }}
                  reportStatus={reportData?.status}
                  isUnlink={
                    (reportData?.status === "unsubmitted" ||
                      reportData?.status === "rejected") &&
                    location?.state?.user
                  }
                />
              </>
            ) : (
              <NodataComponent
                msg={t("msg_trip")}
                module={"Trips"}
                noPrimaryBtn={true}
                noBtns={!location?.state?.user}
                noSecBtn={reportData?.status !== "unsubmitted"}
                secondaryText={t("link_trips")}
                hasWriteAccess={hasWriteAccess}
                onSecondaryClick={() => {
                  if (hasWriteAccess) {
                    setIsModalVisible(true);
                    setModalType("addUnrepTrip");
                  }
                }}
              />
            )
          ) : null}

          {tab === "details" ? (
            <>
              <Row>
                <Col span={6}>
                  <p className="details_p">
                    <span className="details_span secondary">
                      {t("submitted_by")}
                    </span>
                    <span className="details_span name">
                      {reportData?.submitted_by?.name}
                    </span>
                    <span className="details_span secondary sm">
                      {reportData?.submitted_by?.email}
                    </span>
                  </p>
                </Col>
                <Col span={6}>
                  <p className="details_p">
                    {reportData?.submitted_to?.name ? (
                      <span className="details_span secondary">
                        {t("submitted_to")}
                      </span>
                    ) : null}
                    <span className="details_span name">
                      {reportData?.submitted_to?.name}
                    </span>
                    <span className="details_span secondary sm">
                      {reportData?.submitted_to?.email}
                    </span>
                    <span className="details_span secondary sm">
                      {reportData?.submitted_on}
                    </span>
                  </p>
                </Col>
                {(reportData?.status === "pending_approval" ||
                  reportData?.status === "pending_reimbursement") &&
                  reportData?.pending_with.email &&
                  reportData?.pending_with.name && (
                    <Col span={6}>
                      <p className="details_p">
                        <span className="details_span secondary">
                          {t("pendingApprovals")} :
                        </span>
                        <span className="details_span name">
                          {reportData?.pending_with.name}
                        </span>
                        <span className="details_span secondary sm">
                          {reportData?.pending_with.email}
                        </span>
                        {/* <button style={{ textAlign: "left", color: "#e99b39" }}>
                        View Approval Flow
                      </button> */}
                      </p>
                    </Col>
                  )}
              </Row>
              <hr />
              <Row>
                <Col>
                  <p className="details_p mt1">
                    <span className="details_span secondary">Description</span>
                    <span className="details_span name">
                      {reportData?.description}
                    </span>
                  </p>
                </Col>
              </Row>
              <hr />
              <Row className="mt2">
                {tripCustomData?.map(
                  ({ column_name, column_type, value, attachments }) => {
                    return (
                      <>
                        <Col span={24}>
                          <p className="details_p">
                            <span className="details_span secondary">
                              {column_name.split("_").join(" ").toUpperCase()}
                            </span>
                            {column_type === "document" ? (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "auto auto auto auto",
                                  maxWidth: "40rem",
                                }}
                              >
                                {attachments?.map((eachImg) =>
                                  eachImg.content_type === "image/jpeg" ||
                                  eachImg.content_type === "image/jpg" ||
                                  eachImg.content_type === "image/png" ? (
                                    <div
                                      key={eachImg.id}
                                      onClick={() =>
                                        handleDownloadClick(
                                          eachImg.attachment_url
                                        )
                                      }
                                    >
                                      <img
                                        style={{
                                          width: "5rem",
                                          height: "5rem",
                                          margin: "1rem",
                                        }}
                                        src={eachImg.attachment_url}
                                        alt=""
                                      />
                                      <p
                                        title={eachImg.filename}
                                        className="customfield-text"
                                      >
                                        {eachImg.filename}
                                      </p>
                                      <Button
                                        style={{
                                          width: "7rem",
                                          color: "black",
                                        }}
                                        className="formButton mt1"
                                      >
                                        Download
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                      onClick={() =>
                                        handleDownloadClick(
                                          eachImg.attachment_url
                                        )
                                      }
                                    >
                                      {eachImg.content_type ===
                                      "application/pdf" ? (
                                        <img
                                          src={pdf_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : eachImg?.content_type ===
                                        "image/tiff" ? (
                                        <img
                                          src={tiff_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : eachImg?.content_type ===
                                        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                        <img
                                          src={ppt_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={excel_icon}
                                          style={{
                                            width: "5rem",
                                            height: "5rem",
                                            margin: "1rem",
                                          }}
                                        />
                                      )}
                                      <p
                                        title={eachImg.filename}
                                        className="customfield-text"
                                      >
                                        {eachImg.filename}
                                      </p>
                                      <Button
                                        style={{
                                          width: "7rem",
                                          color: "black",
                                        }}
                                        className="formButton mt1"
                                      >
                                        Download
                                      </Button>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <span className="details_span name">{value}</span>
                            )}
                          </p>
                        </Col>
                      </>
                    );
                  }
                )}
              </Row>
            </>
          ) : null}

          {tab === "comments" ? (
            <>
              <div className="addComment mb1">
                <Input
                  placeholder={t("enter_comment")}
                  ref={inputComment}
                  value={input}
                  onInput={(e) => setInput(e.target.value.trimStart())}
                />
                <Button
                  disabled={!hasWriteAccess}
                  key="1"
                  className="formButton ml1"
                  onClick={() => hasWriteAccess && handleAddComment()}
                >
                  {t("send")}
                </Button>
              </div>

              {reportData?.comments.length ? (
                <>
                  <div className="commentsSection">
                    <Steps
                      direction="vertical"
                      size="small"
                      current={reportData?.comments.length + 1 || 100}
                    >
                      {reportData?.comments &&
                        reportData?.comments.map(
                          ({ body, created_at, name, id }) => (
                            <Steps.Step
                              key={id}
                              title={body}
                              // description={created_at + "/" + user_name}
                              description={`on ${moment(created_at).format(
                                "DD MMM YYYY HH:mm:ss"
                              )} | Commented by: ${name}`}
                              icon={
                                <img src={Comment} alt="history" width={18} />
                              }
                            />
                          )
                        )}
                    </Steps>
                  </div>
                </>
              ) : (
                <NoCommentsComponent />
              )}
            </>
          ) : null}

          {tab === "history" ? (
            reportData?.history ? (
              <div className="commentsSection">
                <Steps
                  direction="vertical"
                  size="small"
                  current={reportData?.data?.history.length + 1 || 100}
                >
                  {reportData?.history &&
                    reportData?.history.map(
                      ({ body, created_at, name, id, curr_status }) => (
                        <Steps.Step
                          key={id}
                          title={body}
                          description={
                            created_at +
                            " | " +
                            name +
                            " | Action: " +
                            curr_status
                          }
                          icon={<img src={History} alt="history" width={18} />}
                        />
                      )
                    )}
                </Steps>
              </div>
            ) : (
              <NodataComponent noBtns={true} />
            )
          ) : null}
        </div>
      </div>
    </>
  );
};

export default NewReportDetail;
