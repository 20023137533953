import React from "react";

import { Button, Modal, Space, Table, Typography, message } from "antd";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AddRoles as AddRolesAction,
  DeleteRole,
  UpdateRoles,
  deleteRole,
  getRoles,
  getRolesPermissions
} from "../../../../actions/companySettings";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { hasAccess } from "../../../../utils";
import AddRoles from "./AddRole";

const { Title } = Typography;


message.config({
  maxCount: 3,
});

const Roles = ({ hasWriteAccess }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState("center");
  const [selectedId, setSelectedId] = React.useState("");
  const [permissionsData, setPermissionsData] = React.useState({});
  const [rolesData, setRolesData] = React.useState([]);
  const rolesPermissionResponse = useSelector(
    (state) => state.companySetting.getRolesPermissions || {}
  );
  const rolesResponse = useSelector(
    (state) => state.companySetting.getRoles || {}
  );
  const addRolesResponse = useSelector(
    (state) => state.companySetting.addRoles || {}
  );

  const deleteRoleResponse = useSelector(
    (state) => state.companySetting.delete_role || {}
  );

  const updateRoleResponse = useSelector(
    (state) => state.companySetting.updateRoles || {}
  )

  const totalRecords = rolesResponse?.data?.total_count

  const columns = [
    {
      title: t('role_name'),
      dataIndex: "role_name",
      key: "role_name",
      //   render: text => <a href="/">{text}</a>,
    },
    {
      title: t('role_desc'),
      dataIndex: "description",
      key: "description",
    },
  ];

  const tableAction = [
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        record.role_name === "Admin" ? null :
          record.role_name !== "Employee" && <Space key={record.id} size="middle">
            <button
              disabled={!hasWriteAccess}
              onClick={(e) => {
                showEditModal(record.id);
              }}
            >
              <img src={EditIcon} alt={"logo"} className="action-logo" />
            </button>
            {record.role_name !== "Employee" && record.role_name !== "Reporting Manager" && <button
              disabled={!hasWriteAccess}
              onClick={(e) => {
                showDeleteModal(record.id);
              }}
            >
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />
            </button>}
          </Space>
      ),
    },
  ];

  React.useEffect(() => {
    dispatch(getRolesPermissions({}));
    dispatch(getRoles({}));
    dispatch(headerTitles({
      title: 'roles_permissions',
      description: ""
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const {
      data: { data: permissions = {}, status = false } = {},
    } = rolesPermissionResponse;
    if (status) {
      setPermissionsData(permissions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesPermissionResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      deleteRoleResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getRoles({}));
      dispatch({ type: DeleteRole.RESET })
    } else if (status === false) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({ type: DeleteRole.RESET })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRoleResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", error = "" } = {} } =
      addRolesResponse;
    if (error === false) {
      message.success(<span className="messageText">{selectedId ? t('role_updation') : res_message}</span>);
      setIsModalVisible(false);
      dispatch(getRoles({}));
      dispatch({ type: AddRolesAction.RESET })
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({ type: AddRolesAction.RESET })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRolesResponse]);
  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      updateRoleResponse;
    if (status) {
      message.success(<span className="messageText">{selectedId ? t('role_updation') : res_message}</span>);
      setIsModalVisible(false);
      dispatch(getRoles({}));
      dispatch({ type: UpdateRoles.RESET })
    } else if (status === false) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({ type: AddRolesAction.RESET })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRoleResponse]);

  console.log("rolesResponse", rolesResponse)

  React.useEffect(() => {
    const { data: { data: roles = [], status = false } = {} } =
      rolesResponse;
    if (status) {
      setRolesData(roles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesResponse]);

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("center");
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t('delete_role_are_u_sure')}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() => dispatch(deleteRole(({ id: selectedId })))}
          disabled={deleteRoleResponse?.loading}
        >
          {t('delete')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t('cancel')}
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        title={
          modalType === "center" ? (
            <>
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
              {t('delete_role')}
            </>
          ) : (
            <Title level={4}>
              {selectedId ? t('edit_role') : t('new_role')} &nbsp;
              <span className="text-small">{t('enter_details')}</span>
            </Title>
          )
        }
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[]}
        className={
          modalType === "center"
            ? "center-aligned-modal"
            : "right-alinged-modal-small"
        }
      >
        {modalType === "center" ? (
          renderDeleteOption()
        ) : (
          isModalVisible && <AddRoles
            id={selectedId}
            handleCancel={handleCancel}
            permissionsData={JSON.parse(JSON.stringify(permissionsData))}
            setIsModalVisible={setIsModalVisible}
          />
        )}
      </Modal>

      {
        <Button disabled={!hasWriteAccess} onClick={showModal} className="mb1 addNew primaryButton">
          <img src={AddNewIcon} alt={"logo"} className="addNew-logo " />
          {t('add_new_role')}
        </Button>}
      <Table
        rowKey="id"
        columns={[...columns, ...tableAction]}
        dataSource={rolesData}
        pagination={{
          total: totalRecords || 0,
          onChange: (pagee) => dispatch(getRoles({ page_number: pagee }))
        }}
        className="settingsTable"
      />
    </>
  );
};

export default Roles;
