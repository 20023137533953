import { Col, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import { paymentMade } from "../../../actions/purchases";
import { amountFormat } from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import TableComponent from "../../Common/TableComp/TableComp";
import TableHeader from "../../Sales/TableHeader";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const CompletePayments = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const history = useHistory();
  const location = useLocation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [_openAddNew, setOpenAddNew] = useState(false);
  const [filters, setFilters] = useState({
    filters: { payment_status: "paid", invoice_status: ["approved"] }
  });
  const [page, setPage] = useState(1);


  const sortOptions = [
    {
      label: t('creation_date'),
      filter: "created_at"
    },
    {
      label: t('bill_no'),
      filter: "billing_no"
    },
    {
      label: t('vendor_name'),
      filter: "vendor_name"
    },
    {
      label: t('Amount'),
      filter: "amount_paid",
      sort_order: "-1"
    },
  ];



  const totalRecords = useSelector(
    (state) => state.payments.payment_made?.data?.total_count || {}
  );
  useEffect(() => {
    dispatch(paymentMade({ ...filters }));
    dispatch(
      headerTitles({
        title: 'payment_made',
        description: "",
        // 'bill_document',
      })
    );
  }, []);

  const columns = [
    {
      title: t('date'),
      dataIndex: "billing_date",
      key: "billing_date",
      render: (text) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
    },
    {
      title: t('payment_mode'),
      dataIndex: "payment_mode",
      key: "payment_mode",
    },
    {
      title: t('reference'),
      dataIndex: "reference_number",
      key: "reference_number",
    },
    {
      title: t('vendor_name'),
      dataIndex: "vendor_name",
      key: "vendor_name",
    },
    {
      title: t('bill_no'),
      dataIndex: "billing_no",
      key: "billing_no",
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: t('amount_paid'),
      dataIndex: "amount_paid",
      key: "amount_paid",
      render: (text, record) => <div><BaseCurrency symbol_code={record?.currency_info?.iso_code} />{amountFormat(text, record?.currency_info?.decimal_scale)}</div>,

    },
    {
      title: t('status'),
      dataIndex: "payment_status",
      key: "payment_status",
      render: (text) => (
        <div className={`status status_${text}`}>{text?.replace(/_/g, " ")}</div>
      ),
    },
  ];
  const paidBillsResponse = useSelector((state) => state.payments.payment_made || {});

  console.log({ paidBillsResponse });

  const handleSelectRow = (event, record) => {
    history.push("/purchases/viewpaidbills", {
      id: record.receipt_id,
      bill_id: record?.billing_id,
      from: location.pathname,
      amountPaid: record?.amount_paid,
      placeOfSupply: record?.place_of_supply,
      refNo: record?.reference_number,
      paymentMode: record?.payment_mode
    });
  };


  const handleSearch = (searchKey) => {
    dispatch(
      paymentMade({
        ...filters,
        search_key: searchKey,
      })
    );
    setFilters({
      ...filters,
      search_key: searchKey,
    })
  };

  const handleSort = (value, sort_order) => {
    if (location?.state?.status) {
      history.replace(location?.pathname);
    }
    if (value) {
      dispatch(paymentMade({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: sort_order ?? "1",
        },

        isFilter: true,
      }));
      setFilters({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: sort_order ?? "1",
        },
      });
    }
  };

  const callOnPageChange = useMemo(() => {
    return (pagee) => dispatch(
      paymentMade({
        ...filters,
        page_number: pagee
      })
    );
  }, [filters])

  return (
    <>
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              noDD={true}
              noFilter={true}
              setOpenAddNew={setOpenAddNew}
              noAddNewBtn={true}
              sortOptions={sortOptions}
              handleSort={handleSort}
              onSearch={handleSearch}
            />

            <div
              className={paidBillsResponse?.loading ? "shimmer" : ""}
              style={{ paddingTop: "22px" }}
            >
              <TableComponent
                columns={columns}
                loading={paidBillsResponse?.loading}
                data={Array.isArray(paidBillsResponse?.data?.data) ? paidBillsResponse?.data?.data : []}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleRowClick={handleSelectRow}
                hasCheckbox={false}
                rowKey="id"
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    callOnPageChange(pagee)
                    setPage(pagee)
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default CompletePayments;
