import {
  CaretDownFilled,
  EyeOutlined
} from "@ant-design/icons";
import React from "react";

import { Button, Col, Dropdown, Image, Input, Menu, Row, Steps, message } from "antd";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { defaultTemplate, oldTemplate } from "../../../actions/Templates";
import {
  CreateBillingComment,
  FetchBillingDetailsById,
  createBillingComment,
  fetchBillingDetailsById,
} from "../../../actions/bills";
import { companyProfile } from "../../../actions/companySettings";
import Close from "../../../assets/icons/close.png";
import Comment from "../../../assets/icons/comment.png";
import History from "../../../assets/icons/time_icon.png";
import { apis } from "../../../config/APIs";
import useGenPdf from "../../../config/useGenPdf";
import { amountFormat, showFile, stringCapitalize } from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import NoCommentsComponent from "../../Common/NoComentsComponent";
import NodataComponent from "../../Common/NodataComponent";
import PayViaAcc from "../../Payments/PayViaAcc";
import PaymentModeDisplayFields from "../../Payments/PaymentModeFieldsDisplay";
import RecordPayment from "../../Payments/RecordPayment";
import BillData from "../Bills/BillData";
import PaymentTemplates from "./PaymentTemplates";

const ViewPendingBills = ({ module, type, hasWriteAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [rdPayment, setRdPayment] = useState(false);
  const [accPayment, setAccPayment] = useState(false);
  const [tab, setTab] = useState("details");
  const [selectedAcc, setSelectedAcc] = useState(false);
  const inputComment = useRef(null);
  const [input, setInput] = useState("");
  const [file, setFile] = useState([]);
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { onCall: downloadPDFCall, pdfUrl } = useGenPdf({
    api: apis.generatePDFBill,
  });
  const [benDetails, setBenDetails] = useState({});
  const companyProfileResponse = useSelector(state => state.companySetting.companyProfile || {});
  const { data: { response: { company = {} } = {} } = {} } = companyProfileResponse
  const defaultRes = useSelector(state => state.templates.defaultTemplate.data?.data);
  const oldRes = useSelector(state => state.templates.oldTemplate?.data?.data);

  const createBillingCommentRes = useSelector(
    (state) => state.bills.createBillingComment || {}
  );

  const company_id = localStorage.getItem('company_id')

  React.useEffect(() => {
    if (company_id) {
      dispatch(companyProfile({ id: company_id }));

    }
    // eslint-disable-next-line 
  }, []);

  const TemplateID = invoiceInfo?.template_id;
  React.useEffect(() => {
    if (TemplateID) {
      dispatch(oldTemplate({ module_type: module, TemplateID }));
    } else {
      dispatch(defaultTemplate({ module_type: module }));
    }
  }, [TemplateID])

  const items = [
    {
      key: "1",
      label: t('details'),
    },
    {
      key: "2",
      label: t('history'),
    },
    {
      key: "3",
      label: t('comments'),
    },
  ];


  useEffect(() => {
    if (createBillingCommentRes?.data?.error) {
      message.error(
        <span className="messageText">{createBillingCommentRes?.data?.message}</span>
      );
      dispatch({
        type: CreateBillingComment.RESET,
      });
    }

    if (createBillingCommentRes?.data?.error === false) {
      dispatch({
        type: CreateBillingComment.RESET,
      });
      if (location.state?.id) {
        dispatch(fetchBillingDetailsById({ billing_id: location?.state?.id, module_type: module }));
      } else {
        dispatch(fetchBillingDetailsById({ billing_id: location?.state?.bill_id, module_type: "BILL" }));
      }
    }
  }, [createBillingCommentRes]);

  async function getShowData(id) {
    const showData = {
      record_type: "bill_receipt",
      record_id: id ?? location?.state?.id,
    };

    try {
      const data = await showFile(showData);
      setFile(data.response);
    } catch (err) {
      console.error(err.message);
    }
  }
  React.useEffect(() => {
    dispatch(defaultTemplate({ module_type: module }))
  }, [])


  useEffect(() => {
    if (location.state?.id) {
      dispatch(fetchBillingDetailsById({ billing_id: location?.state?.id, module_type: "BILL" }));
      getShowData();
    } else {
      dispatch(fetchBillingDetailsById({ billing_id: location?.state?.bill_id, module_type: "BILL" }));
    }

  }, [location.state]);

  const paidViewBillResponse = useSelector(
    (state) => state.bills.fetchBillingDetailsById || {}
  );
  useMemo(() => {
    if (paidViewBillResponse?.success && !paidViewBillResponse?.data?.error) {
      setInvoiceInfo(paidViewBillResponse.data.data);
    }
  }, [paidViewBillResponse]);

  const cmp_accs = useSelector((state) =>
    state?.banks?.cmp_accs?.data?.data?.length
      ? state?.banks?.cmp_accs?.data?.data?.map((ele) => ({
        label: `${ele.bank_name} - ${ele.account_number}`,
        value: ele.id,
      }))
      : []
  );

  const handleAddComment = (event) => {
    if (input && invoiceInfo?.id) {
      dispatch(
        createBillingComment({
          commentable_id: invoiceInfo?.id,
          body: input,
          module_type: module,
          commentable_type: "receipt",
        })
      );
      setInput("");
    } else {
      message.error(<span className="messageText">Comments are required</span>);
    }
  };

  const makePaymentProps = (
    <Menu
      onClick={(action) => {
        if (hasWriteAccess) {
          switch (action.key) {
            case "1":
              setRdPayment(true);
              break;
            default:
              setSelectedAcc(action.key);
              setAccPayment(true);
              setBenDetails({
                bank: invoiceInfo?.beneficiary_bank_accounts?.[0],
                name: invoiceInfo?.vendor_name,
                email: invoiceInfo?.user_email,
                id: invoiceInfo?.beneficiary_bank_accounts?.[0]?.id
              })
              break;
          }
        }
      }}
    >
      <label>{t('Transfers')}</label>
      <Menu.Item key="1">{t('record_payment')}</Menu.Item>
      {cmp_accs?.map((ele) => (
        <Menu.Item key={ele.value}>{ele.label}</Menu.Item>
      ))}
    </Menu>
  );
  useEffect(() => {
    return () => {
      dispatch({
        type: FetchBillingDetailsById.RESET
      })
    }
  }, [])

  return (
    <>
      <RecordPayment
        open={rdPayment && hasWriteAccess}
        id={location?.state?.bill_id}
        amount={invoiceInfo?.due_balance_amount}
        invoiceInfo={invoiceInfo}
        onClose={() => {
          setRdPayment(false);
        }}
        file_type={"Bill Receipts"}
        onSuccess={() => {
          history.push(location.state?.from);
        }}
      />
      <PayViaAcc
        open={accPayment && hasWriteAccess}
        id={location?.state?.bill_id}
        amount={invoiceInfo?.due_balance_amount}
        onClose={() => {
          setAccPayment(false);
        }}
        benDetails={benDetails}
        options={cmp_accs}
        value={selectedAcc}
      />
      <div className="report-btns">
        <Dropdown.Button
          disabled={!hasWriteAccess}
          className={`pp-dropdownbutton approve`}
          overlay={makePaymentProps}
          icon={<CaretDownFilled className="down-caret" />}
        >
          <span>{t('make_payment')}</span>
        </Dropdown.Button>
        {/* <Dropdown overlay={menuProps}>
          <Button className="pp-secondary-button">
            <Space>
              {t('more')}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown> */}
        <span
          className="close cursor"
          onClick={() => {
            history.push(location.state?.from);
          }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div>
      <Row
        wrap={false}
        className="new-report-detail"
        style={{ flexGrow: 1, display: "flex" }}
      >
        <Col
          flex="auto"
          style={{ width: "100%", display: "flex", flexGrow: "1" }}
        >
          <div style={{ flexGrow: 1 }}>
            <Row>
              <Col span={24}>
                <div className="flex-between">
                  <div className="report-head">
                    <div className="title-sec">
                      <h3>Bill# {invoiceInfo.billing_no}</h3>
                      <span
                        className={`status status_${invoiceInfo?.payment_status?.toLowerCase()}`}
                      >
                        {invoiceInfo?.payment_status?.replace(/_/g, " ")}
                      </span>
                    </div>
                    <p className="duration">
                      Created Date :{" "}
                      {moment(invoiceInfo.created_at).format("DD/MM/YYYY")}
                    </p>
                    <p>
                      <strong>
                        Created by : {invoiceInfo.created_by?.name}{" "}
                        <span
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "normal",
                            color: "#1C2B36",
                            opacity: "0.51",
                          }}
                        >
                          ({invoiceInfo.created_by?.email})
                        </span>
                      </strong>
                    </p>
                  </div>
                </div>
              </Col>

              <Col span={12}>
                {/* {renderedTemplate} */}
                {/* {pdfUrl && <object
                  data={`${pdfUrl}`}
                  style={{
                    margin: "0px 10px",
                    height: "100vh",
                    width: "96%",
                  }}
                >
                  <param name="view" value={"150%"} />
                </object>} */}
                <PaymentTemplates template={TemplateID ? oldRes?.template_name : defaultRes?.[0]?.template_name} type={type} invoiceInfo={invoiceInfo} />
              </Col>
              <Col
                span={12}
                style={{
                  paddingRight: "10px",
                }}
              >
                <div>
                  <div className="tabs-sec mb1">
                    <ul>
                      {items.map((ele) => (
                        <li
                          onClick={() => setTab(ele.label.toLowerCase())}
                          className={`${tab.toLowerCase() === ele.label.toLowerCase()
                            ? "active"
                            : ""
                            }`}
                        >
                          {ele.label}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {tab === "details" ? (
                    <div>
                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          padding: "25px 36px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1rem",
                            lineHeight: "20px",
                            color: "#58666E",
                            fontWeight: "normal",
                          }}
                        >
                          {t('Amount')}
                        </span>

                        <span
                          style={{
                            fontSize: "1.7rem",
                            lineHeight: "34px",
                            color: "#1C2B36",
                            fontWeight: "600",
                          }}
                        >
                          <BaseCurrency symbol_code={invoiceInfo?.currency_iso} /> {amountFormat(invoiceInfo.total_payable_amount, invoiceInfo?.currency_info?.decimal_scale)}
                        </span>

                        <span
                          style={{
                            fontSize: "1rem",
                            lineHeight: "22px",
                            color: "#333333",
                            fontWeight: "normal",
                          }}
                        >
                          {t('due_amount')} - <BaseCurrency symbol_code={invoiceInfo?.currency_iso} /> {amountFormat(invoiceInfo.due_balance_amount, invoiceInfo?.currency_info?.decimal_scale)}
                        </span>
                      </div>

                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                          padding: "25px 36px",
                        }}
                      >

                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={"Bill#"}
                          value={invoiceInfo.billing_no}
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('order_purchase')}
                          value={invoiceInfo.ref_purchase_order_no}
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('bill_date')}
                          value={moment(invoiceInfo.billing_date).format(
                            "DD/MM/YYYY"
                          )}
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('payment_due_date')}
                          value={moment(invoiceInfo.due_date).format(
                            "DD/MM/YYYY"
                          )}
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('payment_term')}
                          value={
                            invoiceInfo.payment_terms
                              ? invoiceInfo.payment_terms
                              : invoiceInfo.payment_terms === 0 ? "Custom" : ""
                          }
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('Amount')}
                          value={`${invoiceInfo?.currency_iso} ${amountFormat(invoiceInfo.total_amount, invoiceInfo?.currency_info?.decimal_scale)}`}
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label="TDS %"
                          value={`${invoiceInfo?.currency_iso} ${amountFormat(invoiceInfo.tds_amount, invoiceInfo?.currency_info?.decimal_scale)}`}
                        />
                        <PaymentModeDisplayFields data={invoiceInfo} />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                          padding: "25px 36px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.1rem",
                            lineHeight: "22px",
                            color: "#3C4853",
                            marginBottom: "30px",
                            fontWeight: "600",
                          }}
                        >
                          {t('attachments')}
                        </div>

                        <div>
                          {file?.map((element, index) => {
                            return (
                              <>
                                <div>
                                  {index + 1}. {element.filename} (
                                  <a
                                    href={element?.file}
                                    target="_blank"
                                    style={{ color: "black" }}
                                  >
                                    {t('download')}
                                  </a>
                                  )
                                  {element.filename
                                    && element.filename
                                      ?.includes && (element.filename
                                        ?.includes(".jpg") || element.filename
                                          ?.includes(".png") || element.filename
                                            ?.includes(".jpeg")) && <EyeOutlined color='#333' onClick={() => {
                                              setUrl(element?.file)
                                              setVisible(true)
                                            }} className='ml1' />}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {url && <Image
                    width={200}
                    style={{
                      display: 'none',
                    }}
                    src={url}
                    preview={{
                      visible,
                      scaleStep: 0.5,
                      src: url,
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                  />}
                  {tab === "comments" ? (
                    <>
                      <div
                        className="addComment mb1"
                        style={{ display: "flex" }}
                      >
                        <Input
                          placeholder={t('enter_comment')}
                          ref={inputComment}
                          value={input}
                          onChange={(e) => {
                            setInput(e.target.value.trimStart())
                          }}
                        />
                        <Button
                          disabled={!hasWriteAccess}
                          key="1"
                          className="formButton ml1"
                          onClick={() => hasWriteAccess && handleAddComment()}
                        >
                          {t('send')}
                        </Button>
                      </div>

                      {invoiceInfo?.comments?.length ? (
                        <>
                          <div className="commentsSection">
                            <Steps
                              direction="vertical"
                              size="small"
                              current={invoiceInfo.comments.length + 1 || 100}
                            >
                              {invoiceInfo.comments &&
                                invoiceInfo.comments.map(
                                  ({ body, created_at, id, name }) => (
                                    <Steps.Step
                                      key={id}
                                      title={body}
                                      // description={moment(created_at).format('DD-MM-YYYY HH:mm:ss')}
                                      description={`on ${moment(created_at).format(
                                        "DD-MMM-YYYY HH:mm:ss"
                                      )} | ${name}`}
                                      icon={
                                        <img
                                          src={Comment}
                                          alt="comment"
                                          width={18}
                                        />
                                      }
                                    />
                                  )
                                )}
                            </Steps>
                          </div>
                        </>
                      ) : (
                        <NoCommentsComponent />
                      )}
                    </>
                  ) : null}

                  {tab === "history" ? (
                    invoiceInfo?.history?.length ? (
                      <div className="commentsSection">
                        <Steps
                          direction="vertical"
                          size="small"
                          current={invoiceInfo.history.length + 1 || 100}
                        >
                          {invoiceInfo.history &&
                            invoiceInfo.history.map(
                              ({ body, created_at, id, curr_status, name }) => (
                                <Steps.Step
                                  key={id}
                                  title={body}
                                  description={
                                    `on ${moment(created_at).format(
                                      "DD MMM YYYY, HH:mm:ss a"
                                    )} | Action: ${stringCapitalize(curr_status).split("_").join(" ")} by ${name}`
                                  }
                                  icon={
                                    <img
                                      src={History}
                                      alt="history"
                                      width={18}
                                    />
                                  }
                                />
                              )
                            )}
                        </Steps>
                      </div>
                    ) : (
                      <NodataComponent noBtns={true} />
                    )
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ViewPendingBills;