import React from 'react';
import { useTranslation } from 'react-i18next';

const HasPermissions = (props) => {
  const {t} = useTranslation()
  const menu = Object.keys(JSON.parse(localStorage.getItem("menu")))
  if (menu.includes(props.role))
    return props.children
  else
    return <div>
      {t('user_dont_have')} {props.name}
    </div>
};

export default HasPermissions;