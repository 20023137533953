import { Button, Dropdown, Menu, message, Typography, Modal } from 'antd';
import TableWrap from "../Common/Table/Table";
import DropdownIcon from "../../assets/icons/dropdown_icon.png";

import React from "react";
// import moment from "moment";

import "./report.css";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteExpense, expenses_delete } from '../../actions/expenses/expenses';
import { useTranslation } from "react-i18next";
import moment from 'moment';

message.config({
  maxCount: 3,
});
const Text = Typography.Text


const ReportTrips = (props) => {
  const history = useHistory()
  const [expenseSelectedData, setExpensesSelectedData] = React.useState("");
  const is_admin = useSelector((state) => state?.auth?.localVariables?.is_admin);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const deleteExpResponse = useSelector(
    (state) => state.reports.deleteExpense || {}
  );
  const dispatch = useDispatch()
  const { t } = useTranslation();

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      deleteExpResponse;
    if (status) {
      setIsModalVisible(false);
      dispatch({
        type: DeleteExpense.RESET
      })
      props.callExpenseDetails()
    } else {
      if (res_message) {
        dispatch({
          type: DeleteExpense.RESET
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteExpResponse]);
  const columns = [
    {
      title: t('trip_id'),
      dataIndex: "trip_number",
      key: "trip_number",
    },
    {
      title:t('trip_title'),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t('date_time'),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    },
    {
      title: t('employee_name'),
      dataIndex: "user_info",
      key: "user_info",
      render : (text) =><div>{text.name}</div>
    },
    {
      title: t('trip_type'),
      dataIndex: "trip_type",
      key: "trip_type",
    },
    {
      title: t('status'),
      dataIndex: "status",
      key: "status",
     render: (text) => <div className={`status status_${text}`}>{(text)?.replace(/_/g, ' ')}</div>,

    },
    props.isUnlink ? {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (props.reportStatus === "unsubmitted" ||
          props.reportStatus === "rejected") ? (
          <Dropdown overlay={menu(record)} placement="bottomRight">
            <img src={DropdownIcon} alt={"logo"} className="action-logo" />
          </Dropdown>
        ) : null;
      },
    } : {},
  ];

  console.log(props.isUnlink);

  const menu = (record) => (
    <Menu onClick={(e) => {
      e.domEvent.stopPropagation()
      handleMenuClick(e, record)
    }}>
      <Menu.Item key="1">
        {t('unlink_trips')}
      </Menu.Item>
    </Menu>
  );

  const renderDelete = () => {
    return (
      <>
        <Text>{t('unlink_trip')}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          disabled={deleteExpResponse?.loading}
          onClick={() => {
            dispatch(
              expenses_delete({
                trip_ids: [expenseSelectedData],
                params: { module_type: "REPORT" },
                unlink: true,
                trip: true,
              })
            );
          }
          }
        >
          {t('unlink')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => {
            setIsModalVisible(false)
            setExpensesSelectedData("")
          }}
        >
          {t('cancel')}
        </Button>
      </>
    );
  };

  function handleMenuClick(e, record) {
    switch (e.key) {
      case '1':
        setIsModalVisible(true)
        setExpensesSelectedData(record.id)
        break;
      case '2':
        // setReasonValue('')
        break;
      default:
    }
  }
  return (
    <div className="expenses-table">
      <Modal
        title={
          "Unlink Trip"
        }
        visible={isModalVisible}
        onCancel={() => {
          setExpensesSelectedData("")
          setIsModalVisible(false)
        }}
        footer={[]}
        className="center-aligned-modal"
      >
        {renderDelete()}
      </Modal>
      <TableWrap columns={columns} data={props.reportsTripData} pagination={false} handleRowClick={(evt, record) => {
        evt.preventDefault();
        if (record) {
          history.push({
            pathname: `/expenses/trip-details/${record.id}`,
            state: { tripData: record, user: props.user },
          });
        }
      }} />
    </div>
  );
};

export default ReportTrips;
