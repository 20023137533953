import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Checkbox, Collapse, message, Modal } from "antd";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  SaveBeneficiary,
  saveBeneficiary,
  updateBeneficiary,
  UpdateBeneficiary,
  vendors,
  vendorsById,
} from "../../actions/purchases";
import { FormInput } from "../inputs";
import AddVendorAddress from "./AddVendorAddress";
import AddVendorContactPerson from "./AddVendorContactPerson";
import AddVendorOtherInformation from "./AddVendorOtherInformation";
import AddVendorPaymentDetails from "../Sales/AddPaymentDetails";
import useScrollToError from "../../config/useScrollToError";

const { Panel } = Collapse;

const AddVendor = ({
  dataBeneficiary,
  open,
  onClose,
  setBenId,
  callOnSuccess,
  isVendorPortal,
  filters = {}
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currencies = useSelector((state) => state?.currencies?.data?.data);

  const {
    reset,
    watch,
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, submitCount },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      beneficiary_name: "",
      beneficiary_type: "vendor",
      company_name: "",
      email: "",
      country_code: "+91",
      primary_contact: "",
      shippingAddressSame: true,
      gst_treatment: "",
      gstin: "",
      tax_settings: {
        tax_applicable: true,
        tax_id: "",
        tax_percentage: 0,
        tax_type: "",
        place_of_supply: "",
      },
      configurations: {
        portalAccess: false,
        currency: "",
        payment_terms: "",
      },
      contact_persons: [],
      billing_address: [],
      shipping_address: [],
      bank_account_details: [
        {
          bank_name: "",
          account_number: "",
          ifsc_code: "",
          branch: "",
        },
      ],
    },
    shouldUseNativeValidation: false,
  });

  // Use the custom hook
  useScrollToError(Object.keys(errors)[0], errors, submitCount);

  useEffect(() => {
    if (dataBeneficiary && dataBeneficiary?.id) {
      dispatch(vendorsById(dataBeneficiary?.id));
    } else {
      reset();
    }
  }, [dataBeneficiary]);

  const fetchVendorsById = useSelector(
    (state) => state.purchases.vendorsById || {}
  );
  useEffect(() => {
    watch("gstTreatment");
  }, []);
  useMemo(() => {
    if (
      fetchVendorsById?.success &&
      !fetchVendorsById?.data?.error &&
      dataBeneficiary?.id
    ) {
      setValue("beneficiary_id", dataBeneficiary?.id);
      setValue("beneficiary_name", dataBeneficiary?.beneficiary_name);
      setValue("beneficiary_type", dataBeneficiary?.beneficiary_type);
      setValue("company_name", dataBeneficiary?.company_name);
      setValue("email", dataBeneficiary?.email);
      setValue("primary_contact", dataBeneficiary?.primary_contact);
      setValue(
        "bank_account_details",
        dataBeneficiary?.bank_accounts?.length === 0
          ? [
            {
              bank_name: "",
              account_number: "",
              ifsc_code: "",
              branch: "",
            },
          ]
          : dataBeneficiary?.bank_accounts
      );
      setValue("bank_account_details", dataBeneficiary?.bank_accounts);
      setValue("contact_persons", dataBeneficiary?.contact_persons);
      setValue("shipping_address", dataBeneficiary?.shipping_addresses);
      setValue("billing_address", dataBeneficiary?.billing_addresses);
      setValue("tax_settings", dataBeneficiary?.tax_settings);
      setValue("pan_number", dataBeneficiary?.pan_number);
      setValue("configurations", dataBeneficiary?.configurations);
      setValue("gstin", dataBeneficiary?.gstin);
      setValue("gst_treatment", dataBeneficiary?.gst_treatment);
      setValue("shippingAddressSame", dataBeneficiary?.same_as_billing_address);
    }
  }, [fetchVendorsById]);

  const saveResponse = useSelector(
    (state) => state.purchases.saveBeneficiary || {}
  );

  useEffect(() => {
    if (saveResponse?.data?.error) {
      message.error(
        <span className="messageText">{saveResponse?.data?.message}</span>
      );
      dispatch({
        type: SaveBeneficiary.RESET,
      });
    }

    if (saveResponse?.data?.error === false) {
      message.success(
        <span className="messageText">{"Vendor created succesfully"}</span>
      );
      dispatch({
        type: SaveBeneficiary.RESET,
      });
      setBenId && setBenId(saveResponse?.data?.data);
      callOnSuccess && callOnSuccess();
      dispatch(vendors({ type: "vendor" }));
      reset();
      onClose();
    }
  }, [saveResponse]);

  const updateResponse = useSelector(
    (state) => state.purchases.updateBeneficiary || {}
  );

  useEffect(() => {
    if (updateResponse?.data?.error) {
      message.error(
        <span className="messageText">{updateResponse?.data?.message}</span>
      );
      dispatch({
        type: UpdateBeneficiary.RESET,
      });
    }

    if (updateResponse?.data?.error === false) {
      message.success(
        <span className="messageText">{"Vendor Updated succesfully"}</span>
      );

      dispatch({
        type: UpdateBeneficiary.RESET,
      });

      dispatch(vendors({ type: "vendor", ...filters }));
      onClose();
    }
  }, [updateResponse]);

  const onSubmit = async (data, event) => {
    if (!event.nativeEvent?.submitter?.id) {
      return;
    }
    if (data.shippingAddressSame) {
      data.shipping_address = JSON.parse(JSON.stringify(data.billing_address));
      if (data && data.shipping_address[0]) {
        data.shipping_address[0].address_type = "shipping";
      }
    }

    data.bank_account_details = data.bank_account_details?.map((ele) => {
      return ele.id
        ? {
          bank_name: ele?.bank_name,
          account_number: ele?.account_number,
          ifsc_code: ele?.ifsc_code,
          branch: ele?.branch,
          id: ele?.id,
        }
        : {
          bank_name: ele.bank_name,
          account_number: ele?.account_number,
          ifsc_code: ele?.ifsc_code,
          branch: ele?.branch,
        };
    });

    if (!data.beneficiary_id) {
      data.tax_settings = {
        tax_applicable: true,
        tax_type: data.tax_type,
        tax_id: data.tax_id,
        tax_percentage: data.tax_percentage,
        place_of_supply: data.place_of_supply,
      };
    }

    data.company_id = localStorage.getItem("company_id");
    data.same_as_billing_address = data.shippingAddressSame;
    delete data.shippingAddressSame;
    delete data.tds;
    delete data.payment_terms;
    delete data.tax_type;
    delete data.tax_id;
    delete data.tax_percentage;
    delete data.place_of_supply;
    delete data.currency;
    if (!isVendorPortal) {
      data.company_currency_id = currencies?.find(
        (ele) => ele.iso_code === data.configurations.currency
      )?.id;
    }

    if (data.beneficiary_id) {
      delete data.beneficiary_type;
    }
    data.contact_persons = data.contact_persons?.filter(
      (ele) => ele.salutation && ele.first_name && ele.email
    );
    if (data.contact_persons?.length === 0) {
      delete data.contact_persons;
    }
    if (isVendorPortal) {
      delete data?.email;
      delete data?.gst_treatment;
      delete data?.gstin;
      delete data?.pan_number;
      delete data?.tax_settings?.place_of_supply;
      delete data?.configurations?.currency;
      delete data?.configurations.payment_terms;
      data.is_vendor = true;
    }
    if (data.beneficiary_id) {
      dispatch(updateBeneficiary(data));
    } else {
      dispatch(saveBeneficiary(data));
    }
  };

  const bank_account_details = watch("bank_account_details");
  return (
    <>
      <Modal
        onCancel={onClose}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">
                {" "}
                {dataBeneficiary?.id ? t("edit") : t("new")} {t("vendor")}
              </span>
              <span
                className="fs16 lh20 color-818181 fw-medium"
                style={{
                  marginLeft: "18px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#818181",
                  fontWeight: "medium",
                }}
              >
                {t("enter_details")}
              </span>
            </div>
          </div>
        }
        visible={open}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("beneficiary_name", {
                    required: "Vendor name is required",
                    validate: (v) => {
                      if (/^[A-Za-z. ]+$/i.test(v)) {
                        return null;
                      } else {
                        return "Vendor name can only contain letters, dots or spaces.";
                      }
                    },
                  })}
                  required
                  errors={errors}
                  label={t("vendor_name")}
                  placeholder={t("enter_vendor_name")}
                />
              </div>

              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("company_name", {
                    required: "Company name is required",
                    validate: (v) => {
                      if (/^[A-Za-z0-9._ ]+$/i.test(v)) {
                        return null;
                      } else {
                        return "Please enter valid company name";
                      }
                    },
                  })}
                  required
                  errors={errors}
                  label={t("company_name")}
                  placeholder={t("enter_company_name")}
                />
              </div>

              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("email", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Enter valid email.",
                    },
                    required: "Email is required",
                  })}
                  errors={errors}
                  required={true}
                  label={t("emailAddr")}
                  placeholder={t("email_add")}
                  disabled={isVendorPortal}
                />
              </div>

              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("primary_contact", {
                    required: "Phone Number is required.",
                  })}
                  errors={errors}
                  number={true}
                  required={true}
                  label={t("phone")}
                  placeholder={t("enter_phone")}
                />
              </div>
            </div>
            {!dataBeneficiary?.id && (
              <div className="pp-form-item justify-between">
                <div className="enable-portal">
                  <span>Enable Portal?</span>
                </div>
                <div className="enable-portal-label">
                  <Checkbox
                    style={{ float: "left" }}
                    checked={watch("configurations.portalAccess")}
                    name={"portalAccess"}
                    onChange={(e) => {
                      setValue(
                        "configurations.portalAccess",
                        e?.target?.checked
                      );
                    }}
                  ></Checkbox>
                  <label>Allow portal access for this customer</label>
                </div>
              </div>
            )}

            <div className="additional-div">
              <div className="bg">
                <span>
                  {t("additional_info")} ({t("optional")})
                </span>
              </div>

              <div className="collapse-div">
                <Collapse
                  defaultActiveKey={["otherInformation"]}
                  className="pp-collapse"
                  expandIconPosition="end"
                  expandIcon={() => <CaretDownOutlined />}
                >
                  <Panel
                    header={
                      <>
                        <div className="pp-collapse-header">
                          {t("other_information")}
                        </div>
                      </>
                    }
                    key="otherInformation"
                  >
                    <AddVendorOtherInformation
                      control={control}
                      dataBeneficiary={dataBeneficiary}
                      setError={setError}
                      register={register}
                      errors={errors}
                      watch={watch}
                      setValue={setValue}
                      isVendorPortal={isVendorPortal}
                    />
                  </Panel>

                  <Panel
                    header={
                      <>
                        <div className="pp-collapse-header">{t("address")}</div>
                      </>
                    }
                    key="address"
                  >
                    <AddVendorAddress
                      control={control}
                      register={register}
                      watch={watch}
                      errors={errors}
                      billing_address={getValues().billing_address}
                      shipping_address={getValues().shipping_address}
                    />
                  </Panel>

                  <Panel
                    header={
                      <>
                        <div className="pp-collapse-header">
                          {t("contact_persons")}
                        </div>
                      </>
                    }
                    key="contact"
                  >
                    <AddVendorContactPerson
                      control={control}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      watch={watch}
                      contact_persons={getValues().contact_persons}
                      clearErrors={clearErrors}
                      setError={setError}
                      submitCount={submitCount}
                    />
                  </Panel>

                  <Panel
                    header={
                      <>
                        <div className="pp-collapse-header">
                          {t("payment_details")}
                        </div>
                      </>
                    }
                    key="paymentDetails"
                  >
                    <AddVendorPaymentDetails
                      control={control}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      watch={watch}
                      bank_account_details={bank_account_details}
                    />
                  </Panel>
                </Collapse>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "6rem 0px",
                  paddingLeft: "54px",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="pp-main-button "
                  id="save"
                  disabled={saveResponse?.loading}
                >
                  <span>{dataBeneficiary?.id ? t("update") : t("save")}</span>
                </Button>
                <Button
                  className="pp-secondary-button"
                  style={{ marginLeft: "2rem" }}
                  onClick={onClose}
                >
                  <span>{t("cancel")}</span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddVendor;
