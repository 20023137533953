import moment from "moment";

export const one_way_columns = [
  {
    title: "Depart From*",
    dataIndex: "from_city",
    dataType: 'text',
  },
  {
    title: "Arrive At*",
    dataIndex: "to_city",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Departure Date*",
    dataIndex: "departure_date",
    dataType: 'date',
    editable: true,
    render: (text) => (
      <>
        <div>{moment(text, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, hh:mm a")}</div>
      </>
    ),
  },
];

export const round_trip_columns = [
  {
    title: "Depart From*",
    dataIndex: "from_city",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Arrive At*",
    dataIndex: "to_city",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Departure Date*",
    dataIndex: "departure_date",
    dataType: 'date',
    editable: true,
    render: (text) => (
      <div>{moment(text, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, hh:mm a")}</div>
    ),
  },
  {
    title: "Return Date*",
    dataIndex: "return_date",
    dataType: 'date',
    editable: true,
    render: (text) => (
      <div>{moment(text, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, hh:mm a")}</div>
    ),
  },
];

export const hotel_columns = [
  {
    title: "Hotel Name*",
    dataIndex: "hotel_name",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Visiting City*",
    dataIndex: "location",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Check In*",
    dataIndex: "duration_from",
    dataType: 'datetime',
    editable: true,
    render: (text) => (
      <div>{moment(text, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, hh:mm a")}</div>
    ),
  },
  {
    title: "Check Out*",
    dataIndex: "duration_to",
    dataType: 'datetime',
    editable: true,
    render: (text) => (
      <div>{moment(text, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, hh:mm a")}</div>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    dataType: 'text',
    editable: true,
  },
];

export const car_columns = [
  {
    title: "Cab type*",
    dataIndex: "cab_type",
    dataType: 'text',
    editable: true,
  },
  {
    title: "City*",
    dataIndex: "city",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Pickup*",
    dataIndex: "duration_from",
    dataType: 'datetime',
    editable: true,
    render: (text) => (
      <div>{moment(text, "DD/MM/YYYY HH:mm:ss a").format("DD MMM YYYY, hh:mm a")}</div>
    ),
  },
  {
    title: "Drop off*",
    dataIndex: "duration_to",
    dataType: 'datetime',
    editable: true,
    render: (text) => (
      <div>{moment(text, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, hh:mm a")}</div>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    dataType: 'text',
    editable: true,
  }
];

export const bus_columns = [
  {
    title: "Depart From*",
    dataIndex: "from_city",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Arrive At*",
    dataIndex: "to_city",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Departure Date*",
    dataIndex: "departure_date",
    dataType: 'datetime',
    editable: true,
    render: (text) => (
      <div>{moment(text, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, hh:mm a")}</div>
    ),
  },
  {
    title: "Travel Mode*",
    dataIndex: "travel_mode",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    dataType: 'text',
    editable: true,
  }
];

export const train_columns = [
  {
    title: "Depart From*",
    dataIndex: "from_city",
    dataType: 'text',
    editable: true,
  },
  {
    title: "Arrive At*",
    dataIndex: "to_city",
    dataType: 'datetime',
    editable: true,
    render: (text) => (
      <div>{moment(text, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, hh:mm a")}</div>
    ),
  },
  {
    title: "Departure Date*",
    dataIndex: "departure_date",
    dataType: 'datetime',
    editable: true,
    render: (text) => (
      <div>{moment(text, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, hh:mm a")}</div>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    dataType: 'text',
    editable: true,
  }
];