import { Button, Col, Input, Row, Table, message, Steps, Image, Space } from 'antd';
import {
	EyeOutlined
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom"
import NodataComponent from '../Common/NodataComponent';
import ItemsTableShow from '../Common/itemsTableShow';
import { useTranslation } from 'react-i18next';
// import Steps from 'rc-steps';
import NoCommentsComponent from '../Common/NoComentsComponent';
import PaymentReceived from '../PDFTemplates/PaymentReceived';
import bill_icon from "../../assets/images/bill_icon.svg";
import ship_icon from "../../assets/images/ship_icon.svg";
import Comment from "../../assets/icons/comment.png";
import Close from "../../assets/icons/close.png";
import { useDispatch, useSelector } from 'react-redux';
import { CreateBillingComment, createBillingComment } from '../../actions/bills';
import { portalRecordDetails } from '../../actions/customers';
import moment from 'moment';
import usePayAxios from '../../config/useAxios';
import { apis } from '../../config/APIs';
import { formatDate } from '../Sales/Common';
import BaseCurrency from '../Common/BaseCurrency';
import { amountFormat } from '../../utils';
import { renderTemplate } from './customerPortalTemplates';
import BillData from '../Purchases/Bills/BillData';
import History from "../../assets/icons/time_icon.png";
import DeleteConfirm from '../Common/DeleteConfirm';
import TableComponent from '../Common/TableComp/TableComp';
const CustomerPortalDetailView = ({ type }) => {
	const { t } = useTranslation()
	const history = useHistory()
	const location = useLocation()
	const { onCall: portalApprove, data: portalApproveData, reset: portalApproveReset } = usePayAxios({ api: apis.portalApprove, method: "post" });
	const { onCall: portalDecline, data: portalDeclineData, reset: portalDeclineReset, loading } = usePayAxios({ api: apis.portalDecline, method: "post" });
	const { onCall: paymentFecth, data: paymentData, reset: paymentReset } = usePayAxios({ api: apis.portalInvoicePaymentAPI, method: "post" });
	const createBillingCommentRes = useSelector((state) => state.bills.createBillingComment?.data);
	const dispatch = useDispatch()
	const [tab, setTab] = useState("details");
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [input, setInput] = useState("");
	const [title, setTitle] = useState("")
	const inputComment = useRef(null);
	const recordData = useSelector((state) => state?.customers?.portalRecordDetails?.data?.data)
	useEffect(() => {
		dispatch(portalRecordDetails({
			id: location?.state?.id,
			module_type: location?.state?.module_type,
		}))
	}, [location?.state?.id])

	useEffect(() => {
		if (portalApproveData?.error == false || portalDeclineData?.error == false) {
			message.success(<span className="messageText">{portalApproveData?.message ? portalApproveData?.message : portalDeclineData?.message}</span>)
			if (location?.state?.id) {
				dispatch(portalRecordDetails({
					id: location?.state?.id,
					module_type: location?.state?.module_type,
				}))
				setDeleteConfirm(false)
			}
			portalApproveReset({});
			portalDeclineReset({});
		} else if (portalApproveData?.error == true || portalDeclineData?.error == true) {
			message.error(<span className="messageText">{portalApproveData?.message ? portalApproveData?.message : portalDeclineData?.message}</span>)
		}
	}, [portalApproveData, portalDeclineData])
	useEffect(() => {
		if (createBillingCommentRes?.error == false) {
			message.success(<span className='messageText'>{createBillingCommentRes?.message}</span>);
			dispatch({ type: CreateBillingComment.RESET })
			if (location?.state?.id) {
				dispatch(portalRecordDetails({
					id: location?.state?.id,
					module_type: location?.state?.module_type,
				}))
			}
		} else if (createBillingCommentRes?.error == true) {
			message.error(<span className='messageText'>{createBillingCommentRes?.message}</span>);
		}
	}, [createBillingCommentRes])
	useEffect(() => {
		if (paymentData?.error == false) {
			message.success(<span className='messageText'>{paymentData?.message}</span>);
			// window.location.href = `${paymentData?.data}`;
			window.open(paymentData?.data, '_blank');
			paymentReset()
		} else if (paymentData?.error == false) {
			message.error(<span className='messageText'>{paymentData?.message}</span>);
			paymentReset()
		}
	}, [paymentData])
	useEffect(() => {
		if (location?.state?.module_type == "ESTIMATE") {
			setTitle("Estimates#")
		} else if (location?.state?.module_type == "SALES_ORDER") {
			setTitle("Sales Order#")
		} else if (location?.state?.module_type == "INVOICE") {
			setTitle("Invoice#")
		} else if (location?.state?.module_type == "INVOICE_RECEIPT") {
			setTitle("Payment Made#")
		} else if (location?.state?.module_type == "PURCHASE_ORDER") {
			setTitle("Purchase Order#")
		} else if (location?.state?.module_type == "BILL") {
			setTitle("Invoice#")
		} else if (location?.state?.module_type == "BILL_RECEIPT") {
			setTitle("Payment Made#")
		}

	}, [location?.state?.module_type])
	const items = [
		{
			key: "1",
			label: t("details"),
		},
		["INVOICE_RECEIPT", "BILL_RECEIPT"]?.includes(location?.state?.module_type) ? (null) :
			{
				key: "2",
				label: t("items"),
			},
		["INVOICE"]?.includes(location?.state?.module_type) ? {
			key: "3",
			label: t("payment_made"),
		} : (null),
		["INVOICE_RECEIPT", "BILL_RECEIPT"]?.includes(location?.state?.module_type) ? (null) :
			{
				key: "3",
				label: t("comments"),
			},
		["INVOICE", "BILL"]?.includes(location?.state?.module_type) ?
			{
				key: "3",
				label: t("history"),
			} : (null)
	]
	const paymentMadeColumns = [
		{
			title: t("date"),
			dataIndex: "payment_date",
			render: (text) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
		},
		{
			title: t("payment_mode"),
			dataIndex: "payment_mode",
		},
		{
			title: t("amount_paid"),
			dataIndex: "amount_paid",
			render: (text) => <div>{`${recordData?.currency} ${text}`}</div>
		},
	];

	const handleAddComment = (event) => {
		if (input.trim() && location?.state?.id) {
			dispatch(
				createBillingComment({
					commentable_id: location?.state?.id,
					body: input,
					commentable_type: location?.state?.module_type === "COMPANY_PAYMENTS" ? "receipt" : "invoice",
					beneficiary_id: recordData?.beneficiary_id,
					isCustomerPortal: localStorage.getItem("isCustomer") ? "isCustomer" : "isVendor"
				})
			);
			setInput("");
		} else {
			message.error(
				<span className='messageText'>{t("comments_required")}</span>
			);
		}
	};
	return (
		<>
			{recordData?.reject_reason && (
				<div className='strip red'>
					{t("reason_reject")}: {recordData?.reject_reason}
				</div>
			)}
			<div className="mr1 d-flex gap1 align-items-center justify-content-end report-btns">
				{
					["ESTIMATE", "PURCHASE_ORDER"]?.includes(location?.state?.module_type) && recordData?.invoice_status === "approved" && recordData?.permissions?.allow_po_acceptance ? (
						<>
							<button
								className='approve'
								onClick={() => {
									portalApprove({
										data: {
											id: location?.state?.id,
										},
										params: {
											module_type: location?.state?.module_type
										}
									})
								}}
							>
								<span>{t("Accept")}</span>
							</button>
							<button
								className='reject'
								onClick={() => {
									setDeleteConfirm(true)
								}}
							>
								<span>{t("Decline")}</span>
							</button>
						</>
					) : (null)
				}
				{
					["INVOICE"]?.includes(location?.state?.module_type) && recordData?.payment_status !== "paid" ? (<>
						<div className='customer-invoice-action'>
							<button
								className='paynow-button'
								disabled={recordData?.payment_status === "paid"}
								onClick={() => {
									if (recordData?.invoice_status === "approved" && recordData?.payment_status !== "paid") {
										paymentFecth({
											data: {
												id: recordData?.id,
												beneficiary_id: recordData?.beneficiary_id
											},
											params: {
												module_type: location?.state?.module_type
											}
										})
									}
								}}
							>
								Pay Now
							</button>
						</div>
					</>) : (null)
				}
				<span
					className="close cursor"
					onClick={() => {
						history.push(`${location.state?.from}`)
					}}
				>
					<img src={Close} width={20} alt={"close"} />
				</span>
			</div >
			<DeleteConfirm
				open={deleteConfirm}
				onCancel={() => {
					setDeleteConfirm(false)
				}}
				onConfirm={(val) => {
					portalDecline({
						data: {
							id: location?.state?.id,
							reject_reason: val
						},
						params: {
							module_type: location?.state?.module_type
						}
					})
				}}
				loading={loading}
				name={`${title}`}
				text={"decline"}
				isReject={true}
				headerTitle={"Please specify an appropriate reason for decline."}
			/>
			<Row
				wrap={false}
				className='new-report-detail'
				style={{ flexGrow: 1, display: "flex" }}
			>
				<Col
					flex='auto'
					style={{ width: "100%", display: "flex", flexGrow: "1" }}
				>
					<div style={{ flexGrow: 1 }}>
						{
							location?.state?.module_type === "INVOICE_RECEIPT" || location?.state?.module_type === "BILL_RECEIPT" ? (null) : (
								<>
									<Row>
										<Col span={24}>
											<div className='flex-between'>
												<div className='report-head'>
													<div className='title-sec'>
														<h3>
															{title}&nbsp;
															{recordData?.invoice_no}
														</h3>
														<span
															className={`status status_${recordData?.invoice_status}`}>
															{recordData?.invoice_status}
														</span>
													</div>
													<p className='duration'>
														{t("creation_date")} :{" "}
														{moment(recordData?.created_at).format("DD/MM/YYYY")}
													</p>
													<p>
														<strong>
															{t("created_byy")}{" "}
															{recordData?.submitted_by?.name}{" "}
															<span
																style={{
																	fontSize: "0.9rem",
																	fontWeight: "normal",
																	color: "#1C2B36",
																	opacity: "0.51",
																}}
															>
																({recordData?.submitted_by?.email})
															</span>
														</strong>
													</p>
												</div>
											</div>
										</Col>
									</Row>
									<hr className='mb1' />
								</>
							)
						}
						<Row className={`${location?.state?.module_type === "INVOICE_RECEIPT" || location?.state?.module_type === "BILL_RECEIPT" ? "p1" : null}`}>
							{/* <Col span={12}>
                                {location?.state?.module_type === "INVOICE_RECEIPT" || location?.state?.module_type === "BILL_RECEIPT" ? (<PaymentReceived />) : (<>
                                    <div className='billing-section'>
                                        <div className='billing-container'>
                                            <div className="m1 p1 other-info">
                                                <Row className="flex-wrap">
                                                    <Col span={11}>
                                                        <p>
                                                            <img src={bill_icon} alt='bill icon' style={{ marginRight: "0.5rem" }} />
                                                            <label>{t('billing_address')}</label>
                                                        </p>
                                                        {recordData?.beneficiary_billing_addresses?.map((ele) => (
                                                            <p className="mb1">
                                                                {ele?.address}
                                                                <br />
                                                                {ele?.city} - {ele?.zipcode}
                                                                <br />
                                                                {ele?.country}
                                                                <br />
                                                                Phone: {""} {ele?.phone}
                                                            </p>
                                                        ))}
                                                    </Col>
                                                    <Col span={11}>
                                                        <p>
                                                            <img src={ship_icon} alt='bill icon' style={{ marginRight: "0.5rem" }} />
                                                            <label>{t('shipping_address')}</label>
                                                        </p>
                                                        {recordData?.beneficiary_shipping_addresses?.map((ele) => (
                                                            <p className="mb1">
                                                                {ele?.address}
                                                                <br />
                                                                {ele?.city} - {ele?.zipcode}
                                                                <br />
                                                                {ele?.country}
                                                                <br />
                                                                Phone: {""} {ele?.phone}
                                                            </p>
                                                        ))}
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='table m1 p1'>
                                                <Table columns={columnsBill} dataSource={recordData?.invoice_items || recordData?.billing_items} size="small" pagination={false} />
                                            </div>
                                            <div className='total-amount'>
                                                <div className='m1 p1 all-amount'>
                                                    <div className='amount-div amount-title'>
                                                        <span className='title mr2'>Discount</span>
                                                        <span className='title mr2'>Tax Amount</span>
                                                        <span className='title mr2'>Total</span>
                                                    </div>
                                                    <div className='amount-div amount-value'>
                                                        <span className='amount'><BaseCurrency symbol_code={recordData?.currency_info?.iso_code} />{amountFormat(recordData?.bc_total_discount)}</span>
                                                        <span className='amount'><BaseCurrency symbol_code={recordData?.currency_info?.iso_code} />{amountFormat(recordData?.bc_total_tax_amount)}</span>
                                                        <span className='amount'><BaseCurrency symbol_code={recordData?.currency_info?.iso_code} />{amountFormat(recordData?.total_amount)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                            </Col> */}
							{/* <Col span={12}>
                                {pdfLoading ? (
                                    <div className='pdf shimmer'>
                                        <div className='loader'></div>
                                    </div>
                                ) : (
                                    renderedTemplate
                                )}
                            </Col> */}
							<Col span={12}>
								<div>
									{renderTemplate(location?.state?.module_type, recordData)}
								</div>
							</Col>
							<Col
								span={12}
								style={{
									paddingRight: "10px",
								}}
							>
								<div>
									<div className='tabs-sec mb1'>
										<ul>
											{items.map(
												(ele) =>
													ele?.label && (
														<li
															onClick={() => setTab(ele.label?.toLowerCase())}
															className={`${tab.toLowerCase() === ele.label?.toLowerCase()
																? "active"
																: ""
																}`}
														>
															{ele.label}
														</li>
													)
											)}
										</ul>
									</div>

									{tab === "details" ? (
										<div className='pl1 pr1'>
											<div
												style={{
													backgroundColor: "#F7F7F7",
													border: "1px solid #EDF0F5",
													borderRadius: "9px",
													display: "flex",
													flexDirection: "column",
													padding: "25px 36px",
												}}
											>
												<span
													style={{
														fontSize: "1rem",
														lineHeight: "20px",
														color: "#58666E",
														fontWeight: "normal",
													}}
												>
													{t("Amount")}
												</span>

												<span
													style={{
														fontSize: "1.7rem",
														lineHeight: "34px",
														color: "#1C2B36",
														fontWeight: "600",
													}}
												>
													<BaseCurrency symbol_code={recordData?.currency} />{" "}
													{amountFormat(recordData?.total_amount ? recordData?.total_amount : recordData?.bills?.[0]?.total_amount ? recordData?.bills?.[0]?.total_amount : recordData?.invoices?.[0]?.total_amount, recordData?.currency_info?.decimal_scale)}
												</span>
											</div>

											<div
												style={{
													backgroundColor: "#F7F7F7",
													border: "1px solid #EDF0F5",
													borderRadius: "9px",
													display: "flex",
													flexDirection: "column",
													marginTop: "20px",
													padding: "25px 36px",
												}}
											>
												<BillData
													style={{
														fontSize: "1.1rem",
													}}
													label={location?.state?.module_type == "SALES_ORDER" ? t("order_salee") : location?.state?.module_type == "INVOICE" || location?.state?.module_type == "INVOICE_RECEIPT" || location?.state?.module_type == "BILL_RECEIPT" ? t("invoice_hashh") : location?.state?.module_type == "PURCHASE_ORDER" ? t("order_purchase") : location?.state?.module_type == "BILL" ? t("bill_hash") : t("estimate_hash")}
													value={location?.state?.module_type == "PURCHASE_ORDER" || location?.state?.module_type == "BILL" || location?.state?.module_type == "BILL_RECEIPT" ? recordData?.billing_no : recordData?.invoice_no}
												/>
												{["SALES_ORDER", "INVOICE"]?.includes(location?.state?.module_type) ? <>
													<BillData
														style={{
															fontSize: "1.1rem",
														}}
														label={location?.state?.module_type == "INVOICE" ? t("order_salee") : t("reference_hash")}
														value={recordData?.ref_po_no}
													/>
												</> : (null)}
												<BillData
													style={{
														fontSize: "1.1rem",
													}}
													label={location?.state?.module_type == "SALES_ORDER" ? t("order_salee") : location?.state?.module_type == "INVOICE" || location?.state?.module_type == "INVOICE_RECEIPT" || location?.state?.module_type == "BILL_RECEIPT" ? t("invoice_date") : location?.state?.module_type == "PURCHASE_ORDER" ? t("purchase_date") : location?.state?.module_type == "BILL" ? t("bill_date") : t("estimate_date")}
													value={recordData?.invoice_date ? moment(recordData?.invoice_date).format("DD/MM/YYYY") : recordData?.billing_date ? moment(recordData?.billing_date).format("DD/MM/YYYY") : "-"}
												/>
												<BillData
													style={{
														fontSize: "1.1rem",
													}}
													label={location?.state?.module_type == "SALES_ORDER" ? t("expected_shipment_date") : location?.state?.module_type == "INVOICE" || location?.state?.module_type == "INVOICE_RECEIPT" || location?.state?.module_type == "BILL_RECEIPT" ? t("payment_due_date") : location?.state?.module_type == "PURCHASE_ORDER" ? t("expected_delivery_date") : location?.state?.module_type == "BILL" ? t("payment_due_date") : t("expiry_date")}
													value={recordData?.po_due_date ? moment(recordData?.po_due_date).format("DD/MM/YYYY") : recordData?.due_date ? moment(recordData?.due_date).format("DD/MM/YYYY") : "-"}
												/>

												{["SALES_ORDER", "INVOICE", "INVOICE_RECEIPT", "PURCHASE_ORDER", "BILL"]?.includes(location?.state?.module_type) ? <BillData
													style={{
														fontSize: "1.1rem",
													}}
													label={t("payment_term")}
													value={location?.state?.module_type == "SALES_ORDER" || location?.state?.module_type == "INVOICE_RECEIPT" || location?.state?.module_type == "PURCHASE_ORDER" || location?.state?.module_type == "BILL" || location?.state?.module_type == "INVOICE" ? recordData?.payment_terms == 0 ? "Custom" : recordData?.vendor_payment_terms?.term_name ? recordData?.vendor_payment_terms?.term_name : recordData?.invoice_payment_terms?.term_name ? recordData?.invoice_payment_terms?.term_name : "" : ""}
												/> : (null)}
												<BillData
													style={{
														fontSize: "1.1rem",
													}}
													label={t("Amount")}
													value={
														<>
															<BaseCurrency symbol_code={recordData?.currency} />{" "}
															{amountFormat(recordData?.total_amount ? recordData?.total_amount : recordData?.bills?.[0]?.total_amount ? recordData?.bills?.[0]?.total_amount : recordData?.invoices?.[0]?.total_amount, recordData?.currency_info?.decimal_scale)}
														</>
													}
												/>
												{
													["BILL_RECEIPT", "INVOICE_RECEIPT"]?.includes(location?.state?.module_type) ? (null) : (

														<BillData
															style={{
																fontSize: "1.1rem",
															}}
															label={t("Discount Amount")}
															value={
																<>
																	<BaseCurrency symbol_code={recordData?.currency} />{" "}
																	{amountFormat(recordData?.total_discount ? recordData?.total_discount : recordData?.bills?.[0]?.total_discount, recordData?.currency_info?.decimal_scale)}
																</>
															}
														/>

													)
												}
												{["INVOICE", "PURCHASE_ORDER", "BILL", "BILL_RECEIPT"]?.includes(location?.state?.module_type) ?
													<BillData
														label={t("due_amount")}
														value={
															<>
																<BaseCurrency symbol_code={recordData?.currency} />{" "}
																{amountFormat(parseFloat(recordData?.due_balance_amount ? recordData?.due_balance_amount : recordData?.bills?.[0]?.due_balance_amount), recordData?.currency_info?.decimal_scale)}
															</>
														}
													/> : (null)}

												{["PURCHASE_ORDER", "BILL"]?.includes(location?.state?.module_type) ?
													<BillData
														label={t("tds_amount")}
														value={
															<>
																<BaseCurrency symbol_code={recordData?.currency} />{" "}
																{amountFormat(recordData?.tds_amount, recordData?.currency_info?.decimal_scale)}
															</>
														}
													/> : (null)}
												<BillData
													style={{
														fontSize: "1.1rem",
													}}
													label={t("tax_amount")}
													value={
														<>
															<BaseCurrency symbol_code={recordData?.currency} />{" "}
															{amountFormat(recordData?.total_tax_amount ? recordData?.total_tax_amount : recordData?.bills?.[0]?.total_tax_amount ? recordData?.bills?.[0]?.total_tax_amount : recordData?.invoices?.[0]?.total_tax_amount, recordData?.currency_info?.decimal_scale)}
														</>
													}
												/>
												<BillData
													label={t("notes")}
													value={recordData?.notes ? recordData?.notes : "-"}
												/>
											</div>
										</div>
									) : null}

									{tab === "items" ? (recordData?.invoice_items?.length || recordData?.billing_items?.length ? (
										<div className='commentsSection pl1 pr1'>
											<ItemsTableShow
												currency={recordData?.currency}
												currencyInfo={recordData?.currency_info}
												itemsTableShowData={location?.state?.module_type == "PURCHASE_ORDER" || location?.state?.module_type == "BILL" ? recordData?.billing_items : recordData?.invoice_items}
											/>
										</div>
									) : (
										<NodataComponent noBtns={true} />
									)
									) : null}

									{tab === "payment made" ? (
										recordData?.receipts?.length ? (
											<div className='commentsSection'>
												<TableComponent
													columns={paymentMadeColumns}
													// loading={invoiceInfo?.loading}
													data={
														Array.isArray(recordData?.receipts)
															? recordData?.receipts
															: []
													}
													hasCheckbox={false}
												/>
											</div>
										) : (
											<NodataComponent noBtns={true} />
										)) : null}

									{tab === "comments" ? (
										<>
											<div
												className='addComment mb1 ml1'
												style={{ display: "flex" }}
											>
												<Input
													placeholder={t("enter_comment")}
													ref={inputComment}
													value={input}
													onInput={(e) => setInput(e.target.value.trimStart())}
												/>
												<Button
													// disabled={!hasWriteAccess}
													key='1'
													className='formButton ml1'
													onClick={() => handleAddComment()}
												>
													{t("send")}
												</Button>
											</div>

											{recordData?.comments?.length ? (
												<>
													<div className='commentsSection ml1 '>
														<Steps
															direction='vertical'
															size='small'
															current={recordData?.comments?.length + 1 || 100}
														>
															{recordData?.comments &&
																recordData?.comments?.map(
																	({ body, created_at, id, name }) => (
																		<>
																			<Steps.Step
																				key={id}
																				title={body}
																				description={`on ${moment(
																					created_at
																				).format(
																					"DD MMM YYYY, HH:mm:ss a"
																				)} | Commented by: ${name}`}
																				icon={
																					<img
																						src={Comment}
																						alt='history'
																						width={18}
																					/>
																				}
																			/>
																		</>
																	)
																)}
														</Steps>
													</div>
												</>
											) : (
												<NoCommentsComponent />
											)}
										</>
									) : null}

									{tab === "history" ? (
										recordData?.receipts?.length ? (
											<div className='commentsSection pl1 pr1'>
												<Steps
													direction='vertical'
													size='small'
													current={recordData?.receipts?.length + 1 || 100}
												>
													{recordData?.receipts &&
														recordData?.receipts?.map(
															({ body, payment_date, id, amount_paid, userInfo }) => (
																<Steps.Step
																	key={id}
																	title={body ? body : "Payment Transaction"}
																	description={
																		<>
																			{`On ${moment(payment_date).format("DD MMM YYYY, HH:mm:ss")} | Paid: `}
																			<BaseCurrency symbol_code={recordData?.currency} />
																			{` ${amountFormat(amount_paid, recordData?.currency_info?.decimal_scale)} by ${userInfo?.name}`}
																		</>
																	}
																	icon={
																		<img
																			src={History}
																			alt='history'
																			width={18}
																		/>
																	}
																/>
															)
														)}
												</Steps>
											</div>
										) : (
											<NodataComponent noBtns={true} />
										)
									) : null}

									{/* {tab === "invoice" ? (
                                        <Table columns={columns} dataSource={location?.state?.module_type == "ESTIMATE" ? recordData?.est_invoices : location?.state?.module_type == "INVOICE_RECEIPT" ? recordData?.invoices : recordData?.so_invoices} size="small" pagination={false} />
                                    ) : (
                                        <NodataComponent noBtns={true} />
                                    )} */}
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default CustomerPortalDetailView;