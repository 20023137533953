import { ReducerFunctions } from '../../../../utils';
import { GetOnboardFields, OnBoardPost, OnboardCreate, OnboardFinalSubmit, OnboardStep, OnboardStepPreview, STATUS } from "./onboard";

export const post_onboard_api = ReducerFunctions(OnBoardPost, {})
export const get_onboard_fields_reducer = ReducerFunctions(GetOnboardFields, {})
export const onboard_create_reducer = ReducerFunctions(OnboardCreate, {})
export const onboard_step_reducer = ReducerFunctions(OnboardStep, {})
export const onboard_step_preview_reducer = ReducerFunctions(OnboardStepPreview, {})
export const onboard_final_submit_reducer = ReducerFunctions(OnboardFinalSubmit, {})
const initialState = {
}

export const create_on_board_form_reducer = (state = initialState, action) => {
    switch (action.type) {
        case STATUS:
            return { step: action?.step };
            break;
        default:
            return state
    }
}