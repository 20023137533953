import { useState } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const MultiFieldsUI = ()=> {
  const {t} = useTranslation()
  const [emails, setEmails] = useState([]);

  const handleEmailsChange = (newEmails) => {
    setEmails(newEmails);
  };

  return (
    <Select
      mode="tags"
      style={{ width: "100%" }}
      placeholder={t('enter_email_address')}
      onChange={handleEmailsChange}
      value={emails}
    >
      {emails.map((email) => (
        <Option key={email}>{email}</Option>
      ))}
    </Select>
  );
}
export default MultiFieldsUI;
