import { MoreOutlined, PlusCircleFilled, EyeOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Modal, Row, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import {
  DeletePaymentLink,
  deletePaymentLink,
  fetchPaymentLinks,
} from "../../actions/transfers";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import CreatePaymentLink from "../Common/CreatePaymentLink";
import TableComponent from "../Common/TableComp/TableComp";
import { formatDate } from "../Sales/Common";
import TableHeader from "../Sales/TableHeader";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../assets/icons/delete.png";
import EditIcon from "../../assets/icons/edit.png";
import ViewImage from "../../assets/icons/eye.svg";
import { CloseCircleOutlined } from "@ant-design/icons";

const PaymentLinks = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [record, setRecord] = useState({});
  const [editres, setEditRes] = useState({});
  const [filters, setFilters] = React.useState({});
  const [deletePaymentLinkModal, setDeletePaymentLinkModal] = useState(false);
  const paymentData = useSelector(
    (state) => state?.transfers?.fetch_payment_links?.data?.data || []
  );
  const deletePaymentLinkResponse = useSelector(
    (state) => state?.transfers?.delete_payment_link
  );

  useEffect(() => {
    if (deletePaymentLinkResponse?.data?.error === true) {
      message.error(
        <span className="messageText">
          {deletePaymentLinkResponse?.data?.message}
        </span>
      );
      dispatch({
        type: DeletePaymentLink.RESET,
      });
    } else if (deletePaymentLinkResponse?.data?.error === false) {
      message.success(
        <span className="messageText">
          {"Payment Link Deleted Successfully" ||
            deletePaymentLinkResponse?.data?.message}
        </span>
      );
      setDeletePaymentLinkModal(false);
      dispatch({
        type: DeletePaymentLink.RESET,
      });
      dispatch(fetchPaymentLinks());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePaymentLinkResponse]);
  const { t } = useTranslation();
  const options = [
    {
      label: (
        <div className="table-menu-item">
          <span className="menu-label">{t("add_new_vendor")}</span>
        </div>
      ),
      key: "1",
      icon: (
        <div>
          <PlusCircleFilled className="pp-icon-18" />
        </div>
      ),
      onAction: () => setOpenAddNew(true),
    },
  ];
  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Request Money",
        description: "Receive funds to your accounts.",
      })
    );
    dispatch(fetchPaymentLinks());
    // eslint-disable-next-line
  }, []);

  const handleConfirm = () => {
    dispatch(
      deletePaymentLink({ id: record?.id, module_type: "COMPANY_PAYMENTS" })
    );
  };

  const handleCancel = () => {
    setDeletePaymentLinkModal(false);
  };

  const onClickEdit = (record) => {
    setOpenAddNew(true);
    setEditRes(record);
  };
  console.log("Record=", paymentData);
  // const handleFilter = (value) => {
  //   if (location?.state?.status) {
  //     history.replace(location?.pathname);
  //   }

  //   if (value) {
  //     if (value === "all") {
  //       dispatch(
  //         customers({
  //           type: "customer",
  //         })
  //       );
  //       setFilters({});
  //     } else {
  //       dispatch(
  //         customers({
  //           ...filters,
  //           filters: {
  //             beneficiary_status: value,
  //           },
  //           type: "customer",
  //           isFilter: true,
  //         })
  //       );
  //       setFilters({
  //         ...filters,
  //         filters: {
  //           beneficiary_status: value,
  //         },
  //       });
  //     }
  //   }
  //   setPage(1);
  // };

  const handleSort = (value) => {
    // if (location?.state?.status) {
    //   history.replace(location?.pathname);
    // }
    if (value) {
      dispatch(
        fetchPaymentLinks({
          ...filters,
          sort: {
            sort_key: value,
            sort_order: "1",
          },
          isFilter: true,
        })
      );
      setFilters({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
      });
    }
  };
  const columns = [
    {
      title: t("create_date"),
      dataIndex: "created_at",
      render: (text) => <div>{formatDate(text)}</div>,
    },
    {
      title: t("link_id"),
      dataIndex: "link_id",
    },
    {
      title: t("customer_name"),
      dataIndex: "beneficiaries",
      render: (text) => {
        return text.beneficiary_name;
      },
    },
    {
      title: t("mobile_number"),
      dataIndex: "beneficiaries",
      render: (text) => {
        return text.primary_contact;
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      render: (text) => (
        <div>
          <BaseCurrency /> {amountFormat(text)}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text) => (
        <div className={`status status_${text}`}>{text.replace(/_/g, " ")}</div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space
          size="middle"
          className="myspace"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => onClickEdit(record)}>
                  <img src={ViewImage} alt={""} className="mr1" />
                  {t("View")}
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setDeletePaymentLinkModal(true);
                    setRecord(record);
                  }}
                >
                  <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              shape="default"
              icon={<MoreOutlined />}
              style={{ border: "none", color: "#5B87F9" }}
            />
          </Dropdown>
        </Space>
      ),
    },
  ];
  const filterOptions = [{ label: "Paid" }];
  const sortOptions = [
    { label: t("create_date_time"), filter: "created_at" },
    { label: t("customer_name"), filter: "beneficiary_name" },
    { label: t("mobile_no"), filter: "primary_contact" },
    { label: t("Amount"), filter: "amount" },
    { label: t("status"), filter: "status" },
  ];

  return (
    <>
      {openAddNew && (
        <CreatePaymentLink
          isVisible={openAddNew && hasWriteAccess}
          onClose={() => {
            setOpenAddNew(false);
            setEditRes({});
          }}
          editres={editres ? editres : ""}
        />
      )}
      {deletePaymentLinkModal && (
        <Modal
          title="Delete"
          className="disconnect-modal"
          width={470}
          open={deletePaymentLinkModal}
          onCancel={handleCancel}
          maskClosable={false}
          closeIcon={<CloseCircleOutlined style={{ fontSize: "1.5rem" }} />}
        >
          <>
            <p className="modal-text">{t("deletePaymentLink")}</p>
            <div
              className="form-btm mt3"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                className="pp-main-button cursor"
                onClick={handleConfirm}
                disabled={deletePaymentLinkResponse?.loading}
              >
                <span>{t("confirm")}</span>
              </Button>
              <Button
                className="pp-secondary-button cursor"
                style={{ marginLeft: "2rem" }}
                onClick={handleCancel}
              >
                <span>{t("cancel")}</span>
              </Button>
            </div>
          </>
        </Modal>
      )}
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              noDD={true}
              filterMenu={[]}
              noFilter={true}
              //   handleFilter={handleFilter}
              setOpenAddNew={setOpenAddNew}
              noAddNewBtn={false}
              sortOptions={sortOptions}
              handleSort={handleSort}
              hasWriteAccess={hasWriteAccess}
              //   onSearch={handleSearch}
              menu={options}
              onSearch={(val) => {
                dispatch(
                  fetchPaymentLinks({
                    ...filters,
                    search_key: val,
                  })
                );
                setFilters({
                  ...filters,
                  search_key: val,
                });
              }}
            />
            <div className="" style={{ paddingTop: "22px" }}>
              <TableComponent
                columns={columns}
                loading=""
                data={paymentData}
                hasCheckbox={false}
                // selectedRows={selectedRows}
                // setSelectedRows={setSelectedRows}
                // handleRowClick={handleSelectRow}
                rowKey="id"
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PaymentLinks;
