import { takeLatest } from "redux-saga/effects";
import { InventoryItemsCreate, InventoryItemsGet } from "./inventory";
import { sagaFunctions } from "../../utils";
import { apis } from "../../config/APIs";
function inventoryItemsCreate(req) {
    const requestAPI = req?.payload?.id ? apis?.inventoryItemsUpdateAPIS : apis?.inventoryItemsAPIS
    return sagaFunctions(InventoryItemsCreate, "post", requestAPI, req.payload, {}, true)();
}
function inventoryItemsGet(req) {
    let requestAPI;
    if (req?.payload?.type === "items") {
        requestAPI = apis?.inventoryItemsGetAPIS
    } else if (req?.payload?.type === "report") {
        requestAPI = apis?.inventoryReportsGetAPIS
    } else if (req?.payload?.type === "log") {
        requestAPI = apis?.inventoryLogGetAPIS
    }
    delete req?.payload?.type
    return sagaFunctions(InventoryItemsGet, "post", requestAPI, req.payload, {}, true)();
}
export function* InventoryWatcher() {
    yield takeLatest(InventoryItemsCreate.REQUEST, inventoryItemsCreate)
    yield takeLatest(InventoryItemsGet.REQUEST, inventoryItemsGet)
}