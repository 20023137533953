import { Document, PDFViewer, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { amountFormat } from "../../utils";
import TemplateTable from "./TemplateTable";


const PaymentReceivedTwo = (props) => {

    const {
        billingAddressCity,
        billingAddressState,
        billingAddresszip,
        billingCountry,
        billAddress,
        companyAddr,
        companyCity,
        companyState,
        companyCountry,
        companyPin
    } = props;

    const companyAddress = () => {
        const addressComponents = [

            companyAddr,
            companyCity,
            companyState,
            companyCountry,
            companyPin
        ];

        // Filter out undefined or empty values
        const filteredCompanyComponents = addressComponents.filter(Boolean);
        const address = filteredCompanyComponents.join(', ');

        return address;
    };

    const compAddr = companyAddress()
    const formatAddress = () => {
        const addressComponents = [

            billAddress,
            billingAddressCity,
            billingAddressState,
            billingAddresszip,
            billingCountry,
        ];

        // Filter out undefined or empty values
        const filteredAddressComponents = addressComponents.filter(Boolean);
        const address = filteredAddressComponents.join(', ');

        return address;
    };

    const formAddress = formatAddress()

    const columns = [{
        lable: props?.type === "INVOICE_RECEIPT" || props?.useDummyData === true && props?.obj?.type === "INVOICE_RECEIPT" ? "Invoice Number" : "Bill#",
        key: "bill_number",
        width: 40
    }, {
        lable: props?.type === "INVOICE_RECEIPT" || props?.useDummyData === true && props?.obj?.type === "INVOICE_RECEIPT" ? "Invocie Date" : "Bill Date",
        key: "invoice_date",
        width: 35
    }, {
        lable: props?.type === "INVOICE_RECEIPT" || props?.useDummyData === true && props?.obj?.type === "INVOICE_RECEIPT" ? "Invoice Amount" : "Bill Amount",
        key: "invoice_amount",
        width: 40,

    },
        // {
        //     lable: "Payment Amount",
        //     key: "payment_amount",
        //     width: 30,

        // }

    ]




    const styles = StyleSheet.create({

        page: {
            paddingTop: 60,
            paddingLeft: 50,
            paddingRight: 50,
            lineHeight: 1.5,

        },
        subMenu: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 20,
            width: "120%"
        },
        footerMenu: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            /// marginTop: 50,
            width: "45%",
        },
        test: {
            flexDirection: "column",
            alignItems: 'flex-end',
            fontSize: "15px",
        },
        text: {
            fontSize: 12,
            marginBottom: 5,
            //color: "grey"
        },
        display: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            //  paddingHorizontal: 20,
            marginTop: 5,
            width: "20%"
        },
        container: {
            display: "flex",
            //alignItems: 'flex-end',
            marginTop: 5,


        },

        displayOne: {
            flexDirection: 'row',
            justifyContent: "space-between",
            // paddingHorizontal: 20,
            marginTop: 10,
            minWidth: "35%"

        },
    })

    const lineItems = props?.lineItems?.map((item) => {
        return ({
            bill_number: props?.useDummyData === true ? props?.obj?.invoiceNo : props?.invoiceNo,
            invoice_date: props?.useDummyData === true ? props?.obj?.invoiceDate : moment(props?.invoiceDate).format("DD-MM-YYYY"),
            invoice_amount: props?.useDummyData === true ? props?.obj?.totalAmount : item?.total_amount,
            // payment_amount: props?.useDummyData === true ? props?.obj?.totalAmount : props?.amountPaid
        })
    })
    // const lineItems = [
    //     {
    //         bill_number: props?.useDummyData === true ? props?.obj?.invoiceNo : props?.invoiceNo,
    //         invoice_date: props?.useDummyData === true ? props?.obj?.invoiceDate : moment(props?.invoiceDate).format("DD-MM-YYYY"),
    //         invoice_amount: props?.useDummyData === true ? props?.obj?.totalAmount : props?.totalAmount,
    //         payment_amount: props?.useDummyData === true ? props?.obj?.totalAmount : props?.amountPaid
    //     }

    // ]
    const currency = props?.useDummyData === true ? props?.obj?.currency : props?.currency

    return (
        <div>
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <Document>
                    <Page style={styles.page} wrap={false}>
                        <View style={{ justifyContent: "space-between", flexDirection: "row", }}>
                            <View style={styles.test}>
                                <Text style={{ marginTop: "120px", maxWidth: "250px", fontSize: "15px" }}>PAYMENT RECEIPT</Text>
                            </View>
                            <View style={styles.test}>
                                <View style={{ flexDirection: "column", }}>
                                    <View style={styles.test}>
                                        {/* <Text style={{ fontSize: "14px", color: "#00ADF2", fontSize: "20px" }}>mymoneykarma</Text> */}
                                        <Text style={{ marginTop: "10px", color: "black", maxWidth: "220px", fontSize: "12px", textAlign: "right" }}>{props?.useDummyData === true ? props?.obj?.companyName : props?.companyName}</Text>
                                        {/* <Text style={{ marginTop: "5px", color: "grey", maxWidth: "200px", fontSize: "12px", textAlign: "right" }}> Emerald Garden</Text> */}
                                        <Text style={{ marginTop: "5px", color: "grey", maxWidth: "200px", fontSize: "12px", textAlign: "right" }}> {props?.useDummyData === true ? props?.obj?.address : compAddr}</Text>
                                        <Text style={{ marginTop: "5px", color: "grey", maxWidth: "180px", fontSize: "12px", textAlign: "right" }}>TAX ID :{props?.useDummyData === true ? "GST24AAACC4563F" : props?.gstNo}</Text>



                                    </View>
                                </View>
                            </View>
                        </View>
                        <View>
                            <Text style={{ borderBottom: "1px solid grey", paddingTop: "10px" }}></Text>
                        </View>

                        <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>


                            <View style={{ ...styles.subMenu }}>

                                <View style={{ ...styles.container }}>
                                    <Text style={{ color: "black", fontSize: 12 }}></Text>
                                    <Text style={{ color: "black", fontSize: 12, marginTop: "5px" }}>Amount Received</Text>
                                    <Text style={{ color: "black", fontSize: 15, marginTop: "5px" }}> {props?.useDummyData === true ? currency + ' ' + props?.obj?.totalAmount : currency + ' ' + amountFormat(props?.amountPaid)}</Text>

                                    <Text style={{ color: "black", fontSize: 12, marginTop: "15px" }}>Received From</Text>
                                    <Text style={{ color: "grey", fontSize: 12, marginTop: "10px" }}>{props?.useDummyData === true ? props?.obj?.beniName : props?.benefiName}</Text>
                                    <Text style={{ color: "grey", fontSize: 12, marginTop: "5px" }}>{props?.useDummyData === true ? props?.obj?.beniEmail : props?.benifiEmail}</Text>
                                    <Text style={{ color: "grey", fontSize: 12, marginTop: "3px", maxWidth: '180px' }}>{props?.useDummyData === true ? props?.obj?.address : formAddress}</Text>

                                    <Text style={{ color: "grey", fontSize: 12, marginTop: "3px" }}>GSTIN:{props?.useDummyData === true ? props?.obj?.gst : props?.benficiaryGST}</Text>





                                </View>
                                <View style={{ ...styles.container, borderLeft: "0.5px border grey", paddingLeft: "15px" }}>
                                    <View style={{ fontSize: 10, marginTop: "5px", width: 200, display: "flex", flexDirection: "row" }}>
                                        <Text style={{
                                            minWidth: "100px",
                                            fontSize: 12,
                                            marginBottom: 5,
                                            color: "grey",
                                            fontWeight: "bold",
                                        }}>
                                            Payment Date

                                        </Text>
                                        <Text>
                                            <Text style={styles.text}>{props?.useDummyData === true ? props?.obj?.invoiceDate : moment(props?.invoiceDate).format('DD-MM-YYYY')}</Text>

                                        </Text>
                                    </View>
                                    <View style={{ fontSize: 10, marginTop: "5px", width: 250, display: "flex", flexDirection: "row" }}>
                                        <Text style={{
                                            minWidth: "110px",
                                            fontSize: 12,
                                            marginBottom: 5,
                                            color: "grey",
                                            fontWeight: "bold",

                                        }}>Reference Number</Text>
                                        <Text>
                                            <Text style={styles.text}>{props?.useDummyData === true ? props?.obj?.refNo : props?.refNo}  </Text>

                                        </Text>
                                    </View>
                                    <View style={{ fontSize: 10, marginTop: "5px", width: 200, display: "flex", flexDirection: "row" }}>
                                        <Text style={{
                                            minWidth: "100px",
                                            fontSize: 12,
                                            marginBottom: 5,
                                            color: "grey",
                                            fontWeight: "bold",

                                        }}>Payment Mode   </Text>
                                        <Text>
                                            <Text style={styles.text}> {props?.useDummyData === true ? props?.obj?.paymentMode : props?.paymentMode}  </Text>

                                        </Text>
                                    </View>
                                    <View style={{ fontSize: 10, marginTop: "5px", width: 200, display: "flex", flexDirection: "row" }}>
                                        <Text style={{
                                            minWidth: "100px",
                                            fontSize: 12,
                                            marginBottom: 5,
                                            color: "grey",
                                            fontWeight: "bold",

                                        }}>{props?.type === "BILL_RECEIPT" || props?.obj?.type === "BILL_RECEIPT" ? "Place Of Supply" : ""} </Text>
                                        <Text>
                                            {props?.type === "BILL_RECEIPT" || props?.obj?.type === "BILL_RECEIPT" ? <Text style={styles.text}>{props?.useDummyData === true ? props?.obj?.bankBranch : props?.placeOfSupply}</Text> : null}

                                        </Text>
                                    </View>


                                </View>
                            </View>

                        </View>
                        <View>
                            <Text style={{ borderBottom: "1px solid grey", paddingTop: "10px" }}></Text>
                        </View>



                        <Text style={{ color: "black", fontSize: 20, paddingTop: "30px" }}>Payment for</Text>

                        <TemplateTable lineItems={lineItems} columns={columns}
                            currency={currency}
                            tableRow={{
                                color: "grey", fontSize: "12px", padding: 5

                            }} />



                        {/* <View style={{marginTop:"100px",fontSize:15,color:"grey"}}>
                        <Text>Description Of Supply</Text>
                       </View> */}
                        <View>
                            <Text style={{ borderBottom: "1px solid grey", paddingTop: "90px" }}></Text>
                        </View>
                    </Page>

                </Document>
            </PDFViewer>
        </div>
    )
}

export default PaymentReceivedTwo;

