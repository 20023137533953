import { Button, Form, Modal, message } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../inputs';
import "./currencies.css";

import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateExchangeRate, createExchangeRate, getAllExchangeRates, getCompanyMultipleCurrency } from '../../../actions/companySettings';
import DateInput from '../../inputs/DateInput';

const AddExhangeRate = ({ open, onCancel, record, selectedRecord, baseCurrency }) => {
  //    console.log('14=',baseCurrency)
  const createExchangeRateResponse = useSelector((state) => state?.companySetting?.createExchangeRate?.data)
  const dispatch = useDispatch();

  if (createExchangeRateResponse?.status === 200) {
    dispatch(getAllExchangeRates({ company_currency_id: record?.id }));
    message.success(<span className="messageText">{createExchangeRateResponse?.message}</span>)
    dispatch({
      type: CreateExchangeRate.RESET
    })
    dispatch(getCompanyMultipleCurrency())
  } if (createExchangeRateResponse?.error) {
    message.error(<span className="messageText">{createExchangeRateResponse?.message}</span>)
    dispatch({
      type: CreateExchangeRate.RESET
    })
  }

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      exchange_date: '',
      exchange_rate: '',
    },
    shouldUseNativeValidation: false,
  });
  const onSubmit = (data) => {
    if (record) {
      // const isUpdate = true;
      const exchange_Rate = {
        company_currency_id: record?.id,
        exchange_rate: data?.exchange_rate,
        exchange_date: moment(data.exchange_date, 'ddd MMM DD YYYY HH:mm:ss [GMT]Z').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      }

      dispatch(createExchangeRate({
        exchange_Rate: exchange_Rate
      }));
      reset();
      onCancel();
    }
    else {
      const isUpdate = false;
      const exchange_Rate = [{
        exhchange_Rate_id: data.id,
        exchange_rate: data.exchange_rate,
        exchange_date: moment(data.exchange_date, 'ddd MMM DD YYYY HH:mm:ss [GMT]Z').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      }]
      // console.log('69=', exchange_Rate)
      dispatch(createExchangeRate({
        exchange_Rate: exchange_Rate
      }, isUpdate));
      reset();
      onCancel();
    }
  }

  useEffect(() => {
    watch("exchange_rate")
    watch("exchange_date")

        if (selectedRecord?.id) {
            setValue("exchange_date", moment(selectedRecord?.exchange_date || record?.exchange_date));
            setValue("exchange_rate", selectedRecord?.exchange_rate || record?.exchange_rate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRecord, onCancel,record])
    const handleClose = () => {
        reset();
        onCancel();
    }



  const maxDate = moment(); 

  return (
    <>
      <div className='add-currency-container'>
        <Modal
          title={selectedRecord?.exchange_rate ? 'Edit Exchange Rate' : 'Add Exchange Rate'}
          open={open}
          onCancel={handleClose}
          footer={[]}
          className="right-alinged-modal add-currency-modal"
          width={650}
        >
          <Form onFinish={handleSubmit(onSubmit)} className='h100'>
            <div className='form-wrapper'>
              <div className='form-fields'>
                <div className='pp-form-item w60'>
                  <DateInput
                    control={control}
                    {...register("exchange_date")}
                    errors={errors}
                    label="Date"
                    maxDate={new Date()} 
                    disabledDate={(current) => {
                      const maxDate = new Date(); 
                      return current && current > maxDate;
                    }}
                    disabled={selectedRecord?.exchange_rate}
                  // required 
                  />
                </div>
                <div className='pp-form-item addonn-css w60'>
                  <FormInput
                    control={control}
                    {...register("exchange_rate")}
                    errors={errors}
                    label={`Exchange Rate (${baseCurrency?.iso_code})`}
                    // required 
                    addonBefore={`1 ${record?.iso_code} =`}
                    addonAfter={`${baseCurrency?.iso_code}`}
                    // number={true}
                  />
                </div>
              </div>
              <div className='form-btm'
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="pp-main-button "
                  id="save"
                // disabled={create_ben?.loading}
                >
                  <span>{selectedRecord?.exchange_rate ? 'Update' : 'Save'}</span>
                </Button>
                <Button
                  className="pp-secondary-button"
                  style={{ marginLeft: "2rem" }}
                  onClick={handleClose}
                >
                  <span>Clear</span>
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default AddExhangeRate;