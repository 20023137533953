import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Input, message, Modal, Row, Select, Tabs } from "antd";
import Title from "antd/lib/typography/Title";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useDispatch, useSelector
} from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { AddMerchantsField as AddMerchantField } from '../../actions/companySettings';
import { AddExpense, addExpense, ExpenseCustomDetails, ExpenseFields, expenseFields, expensesReset } from "../../actions/expenses/expenses";
import AddReport from '../Reports/AddReport';
import AddMerchantsField from "../Settings/Customisation/Merchants/AddMerchantsField";

import './expenses.css';
const { TabPane } = Tabs;
// const { TextArea } = Input;
const AddBulkExpense = (props) => {

  const addExpenseResponse = useSelector(state => state.expenses.addExpense || {});
  const expenseFieldsResponse = useSelector(state => state.expenses.expenseFields || {});
  const location = useLocation();
  const history = useHistory();
  const { data: { response: { fields: expensesFieldsData = [] } = {} } = {} } = expenseFieldsResponse;


  const dispatch = useDispatch();
  const [defaultObj, setDefaultObj] = React.useState(null);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isModalVisibleReport, setIsModalVisibleReport] = React.useState(false);
  const [searchVal, setSearchVal] = React.useState("");
  const [validationSchema, setValidationSchema] = React.useState(null);
  const { t } = useTranslation();

  const addMerchantsFieldResponse = useSelector(
    (state) => state.companySetting.addMerchantsField || {}
  );
  const formik = useFormik({
    initialValues: {
      transactions: []
    },
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (vals) => {
      dispatch(addExpense({ vals, isBulk: true }))
    }
  });

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = '', errors = "" } = {},
    } = addMerchantsFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(expenseFields({
        id: location?.state?.id
      }));
      dispatch({
        type: AddMerchantField.RESET
      })
    } else if (status === false) {
      (errors || res_message) && message.error(<span className="messageText">{errors || res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMerchantsFieldResponse]);



  React.useEffect(() => {

    const { data: { status = '', message: res_message = '' } = {} } = addExpenseResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      // dispatch(expenses());
      if (!props.reportId)
        history.push("/expenses/");
      dispatch({
        type: AddExpense.RESET
      })
    } else if (status === false) {
      if (res_message) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: AddExpense.RESET
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExpenseResponse]);

  React.useEffect(() => {

    if (location.state?.isEdit) {
      dispatch(expenseFields({
        id: location?.state?.id
      }));
    } else {
      dispatch(expenseFields());
    }
    if (props.resetForm)
      props.resetForm(formik.resetForm)
    message.config({
      maxCount: 1
    })
    return () => {
      dispatch(expensesReset());
      dispatch({ type: ExpenseFields?.RESET })
      dispatch({ type: ExpenseCustomDetails?.RESET })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (expensesFieldsData && expensesFieldsData?.length && !validationSchema) {
      let obj = {}
      let obj2 = {}
      expensesFieldsData.forEach((item, index) => {
        if (item.is_required) {
          obj2[index] = { id: item.field_id, value: "" }
          obj[index] = { id: Yup.string().required(`${item.display_name} is required`), value: Yup.string().required(`${item.display_name} is required`) }
        }
      })
      formik.setValues({
        transactions: [{ field_values: obj2 }]
      })
      setDefaultObj(obj2)
      setValidationSchema(Yup.object().shape({
        transactions: Yup.array().of(Yup.object().shape({
          field_values: Yup.object().shape(obj)
        })).min(1, "Atleast one expense is required")
      }))

    }
    // eslint-disable-next-line
  }, [expensesFieldsData])

  const handleChange = (val, id, index, innerIdx) => {
    let txns = formik.values.transactions
    txns[index].field_values[innerIdx].value = val
    formik.setFieldValue("transactions", txns)
  };

  const renderDataTypes = (value, default_values, id, name, is_disabled, index, innerIdx) => {
    switch (value) {
      case 'string':
        return (
          <>
            <Input placeholder={t('enter_Value')} value={formik.values?.transactions?.[index]?.field_values?.[innerIdx]?.value} style={{ minWidth: '216px' }} onChange={(e) => {
              handleChange(e.target.value, id, index, innerIdx)
            }} />
            <div className="error">
              {formik.touched?.transactions?.[index]?.field_values?.[innerIdx]?.value && formik.errors?.transactions?.[index]?.field_values?.[innerIdx]?.value}
            </div>
          </>
        );
      case 'integer':
        return (
          <>
            <Input disabled={is_disabled} placeholder={t('enter_val')} type="text" value={formik.values?.transactions?.[index]?.field_values?.[innerIdx]?.value} style={{ minWidth: '216px' }} onChange={(e) => {
              if (Number.isNaN(Number(e.target.value))) {
                return;
              }
              handleChange(e.target.value, id, index, innerIdx)
            }} />
            <div className="error">
              {formik.touched?.transactions?.[index]?.field_values?.[innerIdx]?.value && formik.errors?.transactions?.[index]?.field_values?.[innerIdx]?.value}
            </div>
          </>
        );
      case 'dropdown':
        return (
          <>
            <Select
              style={{ width: 200 }}
              placeholder="Select"
              showSearch={name?.includes("merchant_id")}
              value={formik.values?.transactions?.[index]?.field_values?.[innerIdx]?.value ? default_values?.find(ele => ele.id === formik.values?.transactions?.[index]?.field_values?.[innerIdx]?.value)?.value : ""}
              onSearch={(val) => {
                setSearchVal(val)
              }}
              searchValue={searchVal}
              notFoundContent={name?.includes("merchant_id") ?
                <Button type="text" className='merchant-btn' icon={<PlusOutlined />} onClick={() => {
                  setIsModalVisible(true)
                }}>
                  {t('add_merchant')}
                </Button> : <div>{t('no_data')}</div>}
              onChange={(val, data) => {
                if (name === "category_id") {
                  let obj = {
                    category_id: data.id
                  }
                  if (location?.state?.id) {
                    obj.id = location?.state?.id
                  }
                  dispatch(expenseFields(obj))
                }
                handleChange(data.id, id, index, innerIdx)
              }}
              disabled={is_disabled}
              options={default_values && default_values.map(({ id, value }) => ({ label: value, value: value, id }))}
            />
            <div className="error">
              {formik.touched?.transactions?.[index]?.field_values?.[innerIdx]?.value && formik.errors?.transactions?.[index]?.field_values?.[innerIdx]?.value}
            </div>
          </>
        );
      case 'checkbox':
        return (
          <>
            <Checkbox style={{ float: 'left' }}
              checked={formik.values?.transactions?.[index]?.field_values?.[innerIdx]?.value}
              onChange={(e) => {
                handleChange(e.target.checked, id, index, innerIdx)
              }} >{_.startCase(name)}</Checkbox>
            <div className="error">
              {formik.touched?.transactions?.[index]?.field_values?.[innerIdx]?.value && formik.errors?.transactions?.[index]?.field_values?.[innerIdx]?.value}
            </div>
          </>
        );

      case 'datetime':
        return (
          <>
            <DatePicker
              disabled={is_disabled}
              value={formik.values?.transactions?.[index]?.field_values?.[innerIdx]?.value ? moment(formik.values?.transactions?.[index]?.field_values?.[innerIdx]?.value, ["DD MMM'YY, HH:mm:ss a", "YYYY-MM-DD HH:mm:ss"]) : null}
              onChange={(date, dateString) => {
                handleChange(dateString, id, index, innerIdx);
              }} showTime />
            <div className="error">
              {formik.touched?.transactions?.[index]?.field_values?.[innerIdx]?.value && formik.errors?.transactions?.[index]?.field_values?.[innerIdx]?.value}
            </div>
          </>
        );
      default:
      // code block
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <Title level={4}>
            {t('new_report')} &nbsp;
            <span className="text-small">{t('enter_details')}</span>
          </Title>
        }
        visible={isModalVisibleReport}
        onCancel={() => { setIsModalVisibleReport(false) }}
        footer={[]}
        className="right-alinged-modal"
      >
        {isModalVisibleReport && <AddReport handleCancel={() => { setIsModalVisibleReport(false) }} />}
      </Modal>
      <Tabs type="card" size='large' className="custom-tabs">
        <TabPane tab={t('add_bulk_expense')} key="preferences">
          <div className="tabContentWrapper userContentWrap" >
            <form className="modal-form bulk-expense" onSubmit={formik.handleSubmit}>
              {Array.isArray(formik.values.transactions) && formik.values.transactions?.map((ele, index) => {
                return <Row className='flex-col'>
                  <Col className="pt3 pb1" style={{
                    display: "flex",
                    gap: "2rem",
                    flexWrap: "wrap",
                    alignItems: "center"
                  }}>
                    {expensesFieldsData.length && expensesFieldsData.map((item) => {
                      // eslint-disable-next-line
                      const innerIdx = Object.entries(ele.field_values)?.map(ele => ele[1])?.findIndex(ele => ele.id == item.field_id)
                      return item.field_name === "cost center" ? null :
                        <div
                          className="nameItem"
                        >
                          {item.field_type !== "checkbox" && <label className={`inline-block ${item.is_required ? "required" : ""}`}>{item.display_name}</label>}
                          <br />
                          {renderDataTypes(item.field_type, item.default_values, item.field_id, item.field_name, [
                            "amount_cents",
                            "transaction_date",
                            "transaction_merchant_id"].includes(item.field_name) && location?.state?.card_ref, index, innerIdx)}
                        </div>
                    })}
                  </Col>
                </Row>
              })}
              <button type='button' className='p1 cursor new-line primary-btn' onClick={() => {
                const txns = formik.values.transactions
                formik.setValues({ transactions: [...txns, { field_values: defaultObj }] })
              }}> + {t('another_line')}</button>

              <Row className="submitRow">
                <Button key="2" className="cancelButton mt1" type='button' onClick={() => {
                  if (props.reportId) {
                    formik.resetForm()
                    props.handleCancel()
                  }
                  else
                    history.push("/expenses");
                }}>
                  {t('close')}
                </Button>
                <Button
                  key="15" htmlType="submit"
                  className="formButton ml1 mt1 primaryButton"
                  disabled={addExpenseResponse.loading}
                >{t('save_close')}</Button>
              </Row>
            </form>
          </div>
        </TabPane>
      </Tabs>
      <Modal
        title={
          <Title level={4}>
            {t('new_merchant')} &nbsp;
            <span className="text-small">{t('enter_details')}</span>
          </Title>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={
          "right-alinged-modal-small"
        }
      >
        {isModalVisible && <AddMerchantsField
          handleCancel={handleCancel}
        />}
      </Modal>
    </>
  );
};

export default AddBulkExpense;
