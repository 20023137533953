import React from 'react';
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons"
import { Image, message } from 'antd';
import { useTranslation } from 'react-i18next';

const UploadField = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [fileObj, setFileObj] = React.useState(null);
  const { t } = useTranslation()
  const handleFileLoad = (event) => {
    let fileList = event.target.files;
    if (fileList[0].size > 1048576) {
      message.error(<span className='messageText'>{t('file_size')}</span>)
      return;
    }
    if ((props.filesList?.length + fileList?.length) > 5) {
      message.error(<span className='messageText'>{t('five_files')}</span>)
      return;
    }
    if (props.onSelected) {
      props.onSelected(fileList[0])
    }
    document.getElementById(`file-${props.key}`).value = null
  };
  return (
    <div className="upload-field">
      <div>
        <span className="title">{props.title}</span>

        {props.desc && <span className="desc">{props.desc}</span>}
      </div>

      <div style={{ paddingTop: '0.5rem', width: '100%', flexGrow: 1 }}>
        <div className="field-upload" style={{ width: '100%', flexGrow: 1 }}>
          <input
            key={props.key}
            type="file"
            name={props.key}
            id={`file-${props.key}`}
            accept={props.acceptType}
            className="inputfile"
            multiple={false}
            onChange={(event) => handleFileLoad(event)}
          />

          <label
            key={props.key}
            htmlFor={`file-${props.key}`}
            className="field-box"
            style={{ width: '100%', display: 'flex' }}
          >
            <div className="upload-filename">{Array.isArray(props.filesList) && props.filesList?.length > 0 ? props.filesList?.map(ele => ele?.name)?.join(", ") : t('files_upload')}</div>
            <div className="btn-upload">{t('browse')}</div>
          </label>
        </div>
      </div>
      <div>{props.subDescripcion && <span className="subdesc">{props.subDescripcion}</span>}</div>
      {Array.isArray(props.filesList) && props.filesList?.length > 0 && props.filesList?.map((ele, idx) => <div className='mt1'>
        <span className='mr1' style={{
          color: "#333"
        }}>
          {ele.name}
        </span>
        <DeleteOutlined color='#333' onClick={() => props.onRemove(idx)} />
        {ele.name && ele.name?.includes && (ele.name?.includes(".jpg") || ele.name?.includes(".png") || ele.name?.includes(".jpeg")) && <EyeOutlined color='#333' onClick={() => {
          setFileObj(props.filesList[idx])
          setVisible(true)
        }} className='ml1' />}
      </div>
      )}
      <Image
        width={200}
        style={{
          display: 'none',
        }}
        src={fileObj?.url && fileObj?.isEdit ? fileObj?.url : fileObj && URL?.createObjectURL(fileObj)}
        preview={{
          visible,
          scaleStep: 0.5,
          src: fileObj?.url && fileObj?.isEdit ? fileObj?.url : fileObj && URL?.createObjectURL(fileObj),
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </div>
  );
};

export default UploadField;
