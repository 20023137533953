import React, { useCallback } from "react";
import * as d3 from "d3";

const BarGraph = (props) => {
  const drawControlRef = useCallback((node) => {
    if (node !== null) {
      let deviceWidth = props.width;

      let margin = {
          top: 10,
          right: 30,
          bottom: 50,
          left: 60,
          transformTop: 5,
        },
        width = deviceWidth - margin.left - margin.right,
        height = props.height;

      const data2 = [
        {
          day: "Sun",
          txnValue: 1394831,
        },
        {
          day: "Mon",
          txnValue: 1493233,
        },
        {
          day: "Tue",
          txnValue: 123938,
        },
        {
          day: "Wed",
          txnValue: 344221,
        },
        {
          day: "Thu",
          txnValue: 3452132,
        },
        {
          day: "Fri",
          txnValue: 3123005,
        },
        {
          day: "Sat",
          txnValue: 1233456,
        },
      ];

      var scale = d3
        .scaleBand()
        .domain(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"])
        .range([0, (width - 50) * 0.95])
        .padding(0.5);
      var scaleL = d3.scaleLinear().range([(height - 40) * 0.95, 0]);

      let LMax = d3.max(data2, function (d) {
        return d.txnValue;
      });

      scaleL.domain([0, LMax]);

      let x_axis = d3.axisBottom(scale).ticks(7).tickSize(0);
      let y_axisL = d3
        .axisLeft(scaleL)
        .tickFormat((d) => {
          return parseInt(d / 1e5).toString() + (d ? "m" : "");
        })
        .ticks(7)
        .tickSize(0);

      var svg = d3
        .select("#" + props.id)
        .append("svg")
        .attr("width", width)
        .attr("height", height);
      if (!props.noAxis) {
        svg
          .append("g")
          .attr("transform", `translate(40,${height - 25})`)
          .attr("style", "color:#dbe1e9")
          .call(x_axis);
        svg
          .append("g")
          .attr("transform", `translate(${40},33)`)
          .attr("style", "color:#dbe1e9")
          .call(y_axisL);
      }

      svg
        .selectAll(".bar")
        .data(data2)
        .enter()
        .append("rect")
        .attr("fill", props.barColor)
        .attr("class", "bar")
        .attr("x", (d) => {
          return scale(d.day) + 40;
        })
        .attr("y", (d) => {
          return scaleL(d.txnValue);
        })
        .attr("width", scale.bandwidth())
        .attr("height", function (d) {
          return height - scaleL(d.txnValue) - 25;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="chart-wrapper graph-container">
      <div
        id={props.id}
        ref={drawControlRef}
        className="draw_graph_container"
      ></div>
    </div>
  );
};

export default BarGraph;
