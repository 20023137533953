import { ActionTypesFactory } from "../../utils";

export const Dashboard = ActionTypesFactory("Dashboard", "Dashboard");
export const PayDashboard = ActionTypesFactory("PayDashboard", "Dashboard");
export const InvoiceDashboard = ActionTypesFactory("InvoiceDashboard", "Dashboard");
export const ErrorModal = ActionTypesFactory("Global", "ErrorModal");

export const dashboard = (payload) => {
  return {
    type: Dashboard.REQUEST,
    payload,
  };
};

export const pay_dashboard = (payload) => {
  return {
    type: PayDashboard.REQUEST,
    payload
  };
};

export const invoice_dashboard = (payload) => {
  return {
    type: InvoiceDashboard.REQUEST,
    payload
  };
};


export const errorModalReset = () => {
  return {
    type: ErrorModal.RESET,
  };
};



