import { Button, Form, Modal } from 'antd';
import React from 'react'
import { FormInput, FormSelect } from '../../inputs';
import { useForm } from 'react-hook-form';
import country from "./country.json";
import "./currencies.css";

import { createCompanyMultipleCurrency, getAllCurrency } from '../../../actions/companySettings';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

const AddCurrencies = ({ open, onCancel, record }) => {
	const dispatch = useDispatch();
	const [isCurrencyID, setIsCurrencyID] = useState();

	const allCurrencyList = useSelector((state) => state?.companySetting?.getAllCurrency?.data?.data?.map((item, index) => ({
		...item,
		value: item?.id,
		label: <span style={{
			color: "#212121",
			fontSize: 15
		}}>
			<img src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_code?.toLowerCase()}.svg`} className='mr1' width={25} style={{
				boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)"
			}} />
			{`${item?.country_name} (${item?.iso_code})`}
		</span>
	})))

	const allCurrencySymbols = useSelector((state) => state?.companySetting?.getAllCurrency?.data?.data)

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		watch,
	} = useForm({
		defaultValues: {
			currency_name: "",
			currency_code: "",
			currency_symbol: "",
			decimal_places: "2",
			format: "",
		},
		shouldUseNativeValidation: false,
	});

	const onSubmit = (data) => {
		if (record) {
			const isUpdate = true;
			const company_currency = [{
				id: record?.id,
				currency_id: record?.currency_id,
				is_enabled: true,
				exchange_rate: record.exchange_rate,
				decimal_scale: data.decimal_places,
			}]
			dispatch(createCompanyMultipleCurrency({
				company_currency: company_currency
			}, isUpdate));
			reset();
		} else {
			const isUpdate = false;
			const company_currency = [{
				currency_id: data.currency_code,
				symbol: data.currency_symbol,
				decimal_scale: data.decimal_places,
				is_enabled: true,
				exchange_rate: "0"
			}]
			dispatch(createCompanyMultipleCurrency({
				company_currency: company_currency
			}, isUpdate));
		}
	}
	useEffect(() => {
		dispatch(getAllCurrency());
	}, [])

	useEffect(() => {
		watch("currency_code")
		if (record?.id) {
			setValue("currency_code", `${record?.name} (${record?.iso_code})`);
			setValue("currency_symbol", record?.symbol);
			setValue("decimal_places", record?.decimal_scale);
			setValue("format", record?.format);
		}
	}, [record])


	useEffect(() => {
		watch("currency_symbol");
		const symbol = allCurrencySymbols?.filter((items, index) => (items?.id === isCurrencyID))
		if (symbol?.[0]?.symbol) {
			setValue("currency_symbol", symbol?.[0]?.symbol);
		}
		return () => {
			setValue("currency_symbol", '')
		}

	}, [isCurrencyID])

	const handleClose = () => {
		reset();
		onCancel();
	}

	const currency_symbol = watch("currency_symbol")

	return (
		<>
			<div className='add-currency-container'>
				<Modal
					title={!!record ? 'Edit Currency' : 'Add Currency'}
					open={open}
					onCancel={() => handleClose()}
					footer={[]}
					className="right-alinged-modal add-currency-modal"
					width={650}
				>
					<Form onFinish={handleSubmit(onSubmit)} className='h100'>
						<div className='form-wrapper'>
							<div className='form-fields'>
								<div className='pp-form-item form-select'>
									<FormSelect
										inline
										control={control}
										{...register("currency_code", {
											required: {
												value: true,
												message: "Currency Code cant be empty"
											},
											onChange: (e) => setIsCurrencyID(e.target.value)
										}
										)}
										errors={errors}
										label="Currency Code"
										hideCreateOption
										placeholder={"Select Currency"}
										filterKey={"country_name"}
										// required 
										disabled={!!record}
										onAddItem={false}
										options={allCurrencyList}
										allowClear={true} />
								</div>
								{currency_symbol ?
									<div className='pp-form-item '>
										<FormInput
											control={control}
											{...register("currency_symbol")}
											errors={errors}
											label="Currency Symbol"
											// required
											readOnly
										/>
									</div> : null}
								<div className='pp-form-item form-select'>
									<FormSelect
										inline
										control={control}
										{...register("decimal_places", {
											required: {
												value: true,
												message: "Decimal Places cant be empty"
											}
										})}
										errors={errors}
										label="Decimal Places"
										placeholder={"Select Decimal"}
										options={[
											{
												value: '0',
												label: '0',
											},
											{
												value: '2',
												label: '2',
											},
											{
												value: '3',
												label: '3',
											},
										]}
										hideCreateOption
									/>
								</div>
								{/* <div className='pp-form-item form-select'>
                                    <FormSelect
                                        inline
                                        control={control}
                                        {...register("format" , {
                                            required:{
                                                value: true,
                                                message : "Format cant be empty"
                                              }})}
                                        errors={errors}
                                        label="Format"
                                        options={[
                                            {
                                              value: '0',
                                              label: '0',
                                            },
                                            {
                                              value: '1',
                                              label: '1',
                                            },
                                            {
                                              value: '2',
                                              label: '2',
                                            },
                                        ]}
                                        hideCreateOption
                                        />
                                </div> */}
							</div>
							<div className='form-btm'
								style={{
									display: "flex",
									flexDirection: "row",
								}}>
								<Button
									type="primary"
									htmlType="submit"
									className="pp-main-button "
									id="save"
								// disabled={create_ben?.loading}
								>
									<span>{!!record ? 'Update' : 'Save'}</span>
								</Button>
								<Button
									className="pp-secondary-button"
									style={{ marginLeft: "2rem" }}
									onClick={() => handleClose()}
								>
									<span>Cancel</span>
								</Button>
							</div>
						</div>
					</Form>

				</Modal>
			</div>
		</>
	)
}

export default AddCurrencies;