import { Button, message } from 'antd';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { objectToFormData } from "../../utils";
import TableWrap from "../Common/Table/Table";
// import moment from "moment";
import { useTranslation } from 'react-i18next';


import { trips, userReportLinkTrip, userTrips } from "../../actions/trips";
import "./report.css";
import { reports, userReport } from '../../actions/reports';
import moment from 'moment';

message.config({
  maxCount: 3,
});

const LinkTrips = (props) => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = React.useState([]);
  const userTripsResponse = useSelector(state => state.trips.trips?.data || {});
  React.useEffect(() => {
    dispatch(trips({ module_type: "TRIP", page_number: 1, filters: { status: ["approved"] }, params: { report_link_status: true } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { data: trips1 = [], total_count: total_records = 0 } = userTripsResponse;
  const { t } = useTranslation();


  const columns = [
    {
      title: t('trip_id'),
      dataIndex: "trip_number",
      key: "trip_number",
    },
    {
      title: t('trip_name'),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t('date'),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD MMM, YYYY hh:mm A")}</div>
    },
    // {
    //   title: "Purpose",
    //   dataIndex: "business_purpose",
    //   key: "business_purpose",
    //   // render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    // },

  ];

  const rowSelection = {
    selectedRowKeys: selectedData,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows, selectedData);
      setSelectedData(selectedRowKeys);
    }
  };

  const userReportLinkTripResponse = useSelector(
    (state) => state.trips.userReportLinkTrips || {}
  );

  React.useEffect(() => {
    const { data: { status = false } = {} } =
      userReportLinkTripResponse;
    if (status) {
      dispatch(userReport({ module_type: "REPORT", report_id: props.report_id }));
      handleClearList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportLinkTripResponse]);

  const handleClearList = () => {
    setSelectedData([]);
  }

  const handleAddExp = () => {
    const payload = {};
    payload.trip_ids = selectedData;
    payload.report_id = props.report_id;
    dispatch(userReportLinkTrip({ ...payload }));
    setSelectedData([]);
  }

  return (
    <div className="advances-table">
      {selectedData.length ?
        <>
          <Button type="default" ghost className="approve-add-btn mb1" onClick={() => handleClearList()}>
            {t('clear_selection')}
          </Button>
          <Button
            onClick={() => handleAddExp()}
            className="floatRight ml1 primaryButton"
          >
            {t('add')} {selectedData.length > 1 ? selectedData.length : ""} {t('trips')}
          </Button></> : null}
      <TableWrap
        rowKey="id"
        columns={columns}
        data={trips1}
        className="tableStyled"
        pagination={{
          total: total_records,
          onChange: (pagee) => {
            dispatch(trips(({ added_to_report: false, params: { page_number: pagee } })));
          },
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </div>
  );
};

export default LinkTrips;
