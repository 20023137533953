import React from 'react';
import BillData from '../Purchases/Bills/BillData';
import { useTranslation } from 'react-i18next';
const PaymentModeDisplayFields = ({ data }) => {
const {t} = useTranslation()
  return (
    <>
      {(data?.payment_mode === "Cheque" || data?.payment_mode === "DD") && <>
        <BillData
          label={t('payee_name')}
          value={data?.payee_name}
        />
        <BillData
          value={data?.ifsc_code}
          label={t('ifsc_code')}
        />
        <BillData
          value={data?.bank_name}
          label={t('bank_name')}
        />

        <BillData
          value={data?.bank_account_no}
          label={t('bank_num')}
        />

        <BillData
          value={data?.receiver_name}
          label={t('receiver_name')}
        />


        {
          data?.payment_mode == "DD" ? <>
            {data?.total_amount >= 50000 &&
              <BillData
                value={data?.pan_number}
                label={t('pan_no')}
                              />}
            <BillData
              value={data?.dd_number}
              label={t('dd_number')}
            />
            <BillData
              value={data?.dd_type}
              label={t('dd_type')}
            />
          </> : <>
            <BillData
              value={data?.cheque_date}
              label={t('cheque_date')}
            />
            <BillData
              value={data?.cheque_number}
              label={t('cheque_no')}
            />
          </>
        }
      </>}
      {data?.payment_mode === "Card" && <>
        <BillData
          value={data?.card_no}
          label={t('card_number')}
        />
        <BillData
          value={data?.bank_name}
          label={t('bank_name')}
        />
        <BillData
          value={data?.card_type}
          label={t('card_type')}
        />
      </>}
      {data?.payment_mode === "Wallet" && <>
        <BillData
          value={data?.wallet_service_provider}
          label={t('wallet_service')}
        />
        <BillData
          value={data?.mobile_no}
          label="Mobile No"
        />
      </>}
      {
        data?.payment_mode === "UPI" && <>
          <BillData
            value={data?.upi_id}
            label={t('upi_id')}
          />
          <BillData
            value={data?.transaction_id}
            label={t('trans_id')}
          />
          <BillData
            value={data?.receiver_name}
            label={t('receiver_name')}
          />
          <BillData
            value={data?.transaction_date}
            label={t('transaction_date')}
          />
        </>
      }
    </>
  );
};

export default PaymentModeDisplayFields;