import { Button, Modal, message } from 'antd'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { FormInput, FormSelect } from "../../components/inputs"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerAddAddresses, customer_add_addresses, customer_shipping_addresses } from '../../actions/orders/orders';
import { getCompanyCountries } from '../../actions/companySettings';
import { countries } from "../Settings/Organisation/country"
const AddCardAddress = ({ setShowAddAddress, showAddAddress }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const AddAddress = useSelector((state) => state?.orders?.customerAddAddress)
  useEffect(() => {
    dispatch(getCompanyCountries());
  }, [])
  useEffect(() => {
    if (AddAddress?.data?.status == true) {
      message.success(<span className="messageText">{AddAddress?.data?.message}</span>);
      dispatch({ type: CustomerAddAddresses.RESET })
      dispatch(customer_shipping_addresses({}));
      setShowAddAddress(false)
    } else if (AddAddress?.data?.status == false) {
      message.error(<span className="messageText">{AddAddress?.data?.message}</span>);
    }
  }, [AddAddress])

  const {
    reset,
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      city: "",
      state: "",
      phone: "",
      zipcode: "",
      // fax: "",
      country: "",
      address_type: "shipping",
      address: ""
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    dispatch(customer_add_addresses({ addresses: [{ ...data }] }))
  }
  const handleClose = () => {
    setShowAddAddress(false)
  }
  return (
    <Modal
      onCancel={handleClose}
      className="right-alinged-modal add-modal"
      width={720}
      title={
        <div className="d-flex">
          <div className="flex-grow">
            <span className="title">{"Add New Address"}</span>
            <span
              style={{
                marginLeft: "18px",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#818181",
                fontWeight: "medium",
              }}
            >
              {t('enter_details')}
            </span>
          </div>
        </div>
      }
      visible={showAddAddress}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{
            padding: "0rem 3rem"
          }}>
            <div className="pp-form-item">
              <FormSelect
                control={control}
                {...register("country")}
                label={t('country')}
                placeholder={t('select')}
                options={countries || []}
                hideCreateOption={true}
                errorMessage={errors.country?.['message']}
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`zipcode`, {
                  // onChange: (e) => {
                  //     handleSearchCountry(e.target.value, billing_address[index].country, index)
                  // }
                })}
                label="zipcode"
                errorMessage={errors.zipcode?.['message']}
                placeholder={"zipcode"}
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`address`)}
                label={t('address')}
                placeholder="address"
                errorMessage={errors.address?.['message']}
              />
            </div>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`city`)}
                label={t('city')}
                errorMessage={errors.city?.['message']}
                placeholder={t('city')}
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`state`)}
                label={t('state')}
                errorMessage={errors.state?.['message']}
                placeholder={t('state')}
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                number={true}
                control={control}
                {...register(`phone`)}
                label={t('phone')}
                errorMessage={errors.phone?.['message']}
                placeholder={t('phone')}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "2rem 2rem",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="pp-main-button "
              id="save"
              disabled={AddAddress?.loading}
            >
              <span>{t('save')}</span>
            </Button>
            <Button
              className="pp-secondary-button"
              style={{ marginLeft: "2rem" }}
              onClick={handleClose}
            >
              <span>{t('cancel')}</span>
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AddCardAddress