import { Button, Modal, Typography, Checkbox, Divider } from 'antd';
import React, { useState } from 'react';
import "../Currencies/currencies.css";
import './RootFi.css';

import { useDispatch, useSelector } from 'react-redux';
import { syncData } from '../../../actions/cards/cards';

const SyncDataModels = ({ open, onCancel, integrationType, company_id }) => {
    const dispatch = useDispatch();
    const { Title } = Typography;
    const syncDataResponse = useSelector(state => state?.cards?.sync_data)
    // console.log('syncData=', syncDataResponse)
    const labels = [
        { label: 'Invoices', value: 'INVOICES' },
        { label: 'Purchase Orders', value: 'PURCHASE_ORDERS' },
        { label: 'Sales Orders', value: 'SALES_ORDERS' },
        { label: 'Bills', value: 'BILLS' },
        { label: 'Items', value: 'ITEMS' },
        { label: 'Beneficaries', value: 'CONTACTS' },
    ];

    const [checkedList, setCheckedList] = useState([]);
    const checkAll = labels.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < labels.length;

    const onChange = (list) => {
        setCheckedList(list);
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? labels.map(label => label.value) : []);
    };
    const handleClose = () => {
        onCancel();
    }

    const handleSyncData = () => {
        const full_sync = checkedList?.length === labels.length ? true : false;
        let payloadBody = {
            company_id,
            integration_type: integrationType,
            full_sync,
            data_models_to_sync: checkedList
        }

        dispatch(syncData(payloadBody));
    }

    return (
        <>
            <div className='add-currency-container'>
                <Modal
                    title={<Title level={4}>
                        Select Sync Data
                    </Title>}
                    open={open}
                    onCancel={() => handleClose()}
                    footer={[]}
                    className="right-alinged-modal add-currency-modal"
                >
                    <div className='sync-data'>
                        <div>
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} style={{ marginRight: '0.7rem' }} />
                            <label style={{ color: 'black' }}>Select All</label>
                            <Divider />
                            <Checkbox.Group value={checkedList} onChange={onChange} className='d-flex flex-col' style={{ gap: '1rem' }}>
                                {labels.map((label) => (
                                    <div>
                                        <Checkbox key={label.value} value={label.value} style={{ marginRight: '0.7rem' }} />
                                        <label style={{ color: 'black' }}>{label?.label}</label>
                                    </div>
                                ))}
                            </Checkbox.Group>

                        </div>
                        <div className='form-btm'
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}>
                            <Button
                                type="primary"
                                className="pp-main-button"
                                onClick={handleSyncData}
                                disabled={syncDataResponse?.loading}
                            >
                                <span>Sync</span>
                            </Button>
                            <Button
                                className="pp-secondary-button"
                                style={{ marginLeft: "2rem" }}
                                onClick={() => handleClose()}
                            >
                                <span>Cancel</span>
                            </Button>
                        </div>
                    </div>

                </Modal>
            </div>
        </>
    )
}

export default SyncDataModels;