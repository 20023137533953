import { Col, Row, Switch } from "antd";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import closeIcon from "../../../src/assets/icons/close.png";
import { FormInput, FormSelect } from "../inputs";
import FormRadio from "../inputs/FormRadio";
import InventoryItems from "./InventoryItems";
const InventoryFormFields = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    errors,
    vendorsByNameData,
    accountResponse,
    fieldValues,
    warehouseData,
    unitTypeData,
    taxTypeData,
  } = props;
  const item_track_inventory = watch("item_track_inventory");
  const ref = useRef();
  const base_currency = localStorage.getItem("base_currency")
    ? JSON.parse(localStorage.getItem("base_currency"))?.iso_code
    : "";

  useEffect(() => {
    if (base_currency) {
      setValue((`information.${[0]}.currency`, base_currency));
      setValue((`information.${[1]}.currency`, base_currency));
    }
  }, [base_currency]);
  return (
    <>
      <div className="head">
        <label>{t("Inventory Items")}</label>
        <span className="close cursor mr2">
          <img
            src={closeIcon}
            width={20}
            alt="close"
            onClick={() => history.goBack()}
          />
        </span>
      </div>
      <div className="pl2">
        <div>
          <div className="pp-form-item w40">
            <FormRadio
              control={control}
              {...register(`item_type`, {
                onChange: (e) => {
                  setValue("item_type", e?.target?.value);
                },
                required: "Type is required",
              })}
              required={true}
              errors={errors}
              label={"Type"}
              options={[
                { label: "Goods", value: "goods" },
                { label: "Service", value: "service" },
              ]}
            />
          </div>
          <div className="pp-form-item w40">
            <FormInput
              control={control}
              {...register("item_name", {
                required: "Name is required",
              })}
              errors={errors}
              label={t("Name")}
              required={true}
            />
          </div>
          {/* <div className="pp-form-item w40">
                        <FormInput
                            control={control}
                            {...register("quantity")}
                            errors={errors}
                            label={t("Quantity")}
                            number={true}
                        />
                    </div> */}
          <div className="pp-form-item w40">
            <FormInput
              control={control}
              {...register("sku")}
              errors={errors}
              label={t("SKU")}
            />
          </div>
          <div className="pp-form-item w40">
            <FormSelect
              inline
              control={control}
              {...register("unit_type", {
                onChange: (e) => {},
              })}
              errors={errors}
              label={t("Unit")}
              placeholder="Select"
              options={
                Array.isArray(unitTypeData?.data)
                  ? unitTypeData?.data?.map((item) => ({
                      value: item?.id,
                      label: item?.unit_name,
                    }))
                  : []
              }
              hideCreateOption={true}
              noPaddingTop={true}
            />
          </div>
          <div className="pp-form-item w40">
            <FormInput
              control={control}
              {...register("hsn_code")}
              errors={errors}
              label={t("HSN Code")}
            />
          </div>
        </div>
        <div className="d-flex mt2" style={{ gap: "7rem" }}>
          <div className="sales_info w40">
            <div className="form-sub-title">
              <span>Sales Information</span>
            </div>
            <div className="pp-form-item d-flex">
              <Row align={"top"} justify={"space-between"} gutter={16}>
                <Col style={{ width: "12rem" }}>
                  <FormSelect
                    inline
                    control={control}
                    {...register(`information.${[0]}.currency`)}
                    errors={errors?.information?.[0]?.currency}
                    label={t("Currency ")}
                    value={base_currency}
                    disabled={true}
                    placeholder="Select"
                    options={[{ label: base_currency, value: base_currency }]}
                    hideCreateOption={true}
                    noPaddingTop={true}
                    errorMessage={errors?.information?.[0]?.currency?.message}
                  />
                </Col>
                <Col style={{ width: "21rem" }}>
                  <FormInput
                    control={control}
                    {...register(`information.${[0]}.selling_price`)}
                    errors={errors}
                    label={t("Selling Price")}
                    number={true}
                    errorMessage={
                      errors?.information?.[0]?.selling_price?.message
                    }
                  />
                </Col>
                {/* {balCheck && <div className='errorMsg'>
                                    Insufficient Balance
                                </div>} */}
              </Row>
            </div>
            <div className="pp-form-item">
              <FormSelect
                inline
                control={control}
                {...register(`information.${[0]}.account_id`)}
                errors={errors}
                label={t("Sales Account")}
                placeholder="Select"
                options={
                  Array.isArray(props?.saleAccs)
                    ? props?.saleAccs?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))
                    : []
                }
                hideCreateOption={true}
                noPaddingTop={true}
                errorMessage={errors?.information?.[0]?.account_id?.message}
                notFoundContent={"Add Beneficiary Account in Payments"}
              />
            </div>
            <div className="pp-form-item mb2">
              <FormInput
                control={control}
                {...register(`information.${[0]}.description`)}
                errors={errors}
                label={t("Description")}
                type="textarea"
                rows={3}
              />
            </div>
          </div>
          <div className="purchase_info w40">
            <div className="form-sub-title">
              <span>Purchase information</span>
            </div>
            <div className="pp-form-item d-flex">
              <Row align={"bottom"} justify={"space-between"} gutter={16}>
                <Col style={{ width: "12rem" }}>
                  <FormSelect
                    inline
                    control={control}
                    {...register(`information.${[1]}.currency`)}
                    errors={errors}
                    label={t("Currency")}
                    value={base_currency}
                    disabled={true}
                    placeholder="Select"
                    options={[{ label: base_currency, value: base_currency }]}
                    hideCreateOption={true}
                    noPaddingTop={true}
                    errorMessage={errors?.information?.[1]?.currency?.message}
                  />
                </Col>
                <Col style={{ width: "21rem" }}>
                  <FormInput
                    control={control}
                    {...register(`information.${[1]}.selling_price`)}
                    errors={errors}
                    label={t("Cost Price")}
                    number={true}
                    errorMessage={
                      errors?.information?.[1]?.selling_price?.message
                    }
                  />
                </Col>
                {/* {balCheck && <div className='errorMsg'>
                                    Insufficient Balance
                                </div>} */}
              </Row>
            </div>
            <div className="pp-form-item">
              <FormSelect
                inline
                control={control}
                {...register(`information.${[1]}.account_id`)}
                errors={errors}
                label={t("Purchase Account")}
                placeholder="Select"
                options={
                  Array.isArray(props?.prAccs)
                    ? props?.prAccs?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))
                    : []
                }
                // options={[
                //   { label: "Expense Account", value: "expense_account" },
                //   { label: "Accounts Payable", value: "accounts_payable" },
                //   {
                //     label: "Purchase Discount Account",
                //     value: "purchase_discount_account",
                //   },
                //   { label: "Inventory Account", value: "inventory_account" },
                //   {
                //     label: "Purchase Tax Account",
                //     value: "purchase_tax_account",
                //   },
                // ]}
                hideCreateOption={true}
                noPaddingTop={true}
                errorMessage={errors?.information?.[1]?.account_id?.message}
                notFoundContent={"Add Beneficiary Account in Payments"}
              />
            </div>
            <div className="pp-form-item mb2">
              <FormInput
                control={control}
                {...register(`information.${[1]}.description`)}
                errors={errors}
                label={t("Description")}
                type="textarea"
                rows={3}
              />
            </div>
          </div>
        </div>
        <hr className="mr2 mb1" />
        <div className="tax">
          <div className="tax-sub-title">
            <span>Default Tax Rates</span>
          </div>
          <div>
            <div className="tax-fields-section">
              <div className="d-flex" style={{ gap: "2rem" }}>
                <div className="pp-form-item w10">
                  <h4
                    style={{
                      color: "#a09a9a",
                      fontSize: "1.1rem",
                      fontWeight: "400",
                    }}
                  >
                    Intra State Tax Rates
                  </h4>
                </div>
                <div className="pp-form-item w15">
                  <FormSelect
                    inline
                    control={control}
                    {...register(`tax_rates.${[0]}.company_tax_type_id`, {
                      onChange: (e) => {
                        setValue(
                          `tax_rates.${[0]}.company_tax_type_id`,
                          e?.target?.value
                        );
                      },
                    })}
                    errors={errors}
                    label={t("")}
                    placeholder="percentage"
                    options={
                      Array.isArray(taxTypeData?.data)
                        ? taxTypeData?.data?.map((item) => ({
                            value: item?.id,
                            label: item?.tax_name,
                          }))
                        : []
                    }
                    hideCreateOption={true}
                    noPaddingTop={true}
                  />
                </div>
              </div>
              <div
                className="d-flex"
                style={{ gap: "2rem", marginBottom: "2rem" }}
              >
                <div className="pp-form-item w10">
                  <h4
                    style={{
                      color: "#a09a9a",
                      fontSize: "1.1rem",
                      fontWeight: "400",
                    }}
                  >
                    Inter State Tax Rates
                  </h4>
                </div>
                <div className="pp-form-item w15">
                  <FormSelect
                    inline
                    control={control}
                    {...register(`tax_rates.${[1]}.company_tax_type_id`, {
                      onChange: (e) => {
                        setValue(
                          `tax_rates.${[1]}.company_tax_type_id`,
                          e?.target?.value
                        );
                      },
                    })}
                    errors={errors}
                    label={t("")}
                    placeholder="percentage"
                    options={
                      Array.isArray(taxTypeData?.data)
                        ? taxTypeData?.data?.map((item) => ({
                            value: item?.id,
                            label: item?.tax_name,
                          }))
                        : []
                    }
                    hideCreateOption={true}
                    noPaddingTop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mr2 mb1" />
        <div className="track-inventory mb1">
          <div className="d-flex align-center gap1">
            <Switch
              checked={item_track_inventory}
              onChange={(val) => {
                setValue("item_track_inventory", val);
                setTimeout(() => {
                  if (ref?.current) ref?.current?.scrollIntoView();
                }, 100);
              }}
            />
            <div>
              <h4 className="track-header">Track Inventory for this item</h4>
              <h6 className="track-sub-header">
                You cannot enable/disable inventory tracking once you've created
                transactions for this item
              </h6>
            </div>
          </div>
          {item_track_inventory && (
            <div className="mt2" ref={ref}>
              <InventoryItems
                control={control}
                register={register}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                errors={errors}
                vendorsByNameData={vendorsByNameData}
                itemsData={[{ ...fieldValues?.taxInventory }]}
                warehouseData={warehouseData}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InventoryFormFields;
