import { takeLatest } from 'redux-saga/effects';
import { apis } from '../../config/APIs';
import { sagaFunctions } from '../../utils';
import { CompanyAccounts, PrimaryAccounts, BeneficiaryAccount, MasterPoolAccount, AllBudgets, CreateBudgets, BudgetsBack, MasterAccountHistory, InitiatePayout, PayoutTxns } from './accounts';

function getAccounts(req) {
  return sagaFunctions(
    PrimaryAccounts,
    'post',
    `${apis.getAccounts}`,
    req.payload,
    {},
    true
  )();
}

function getCompanyAccounts(req) {
  return sagaFunctions(
    CompanyAccounts,
    'get',
    `${apis.getAllCompanyBankDetailsById}`,
    req.payload,
    {},
    true
  )();
}

function getBeneficiaryAccounts(req) {
  return sagaFunctions(
    BeneficiaryAccount,
    'get',
    `${apis.getAllBeneficiariesBankDetails}`,
    req.payload,
    {},
    true
  )();
}

function getMasterPoolAccount(req) {
  return sagaFunctions(
    MasterPoolAccount,
    'get',
    `${apis.masterPoolAccount}`,
    req.payload,
    {},
    true
  )();
}

function getAllBudgets(req) {
  return sagaFunctions(
    AllBudgets,
    'post',
    `${apis.getAllBudgets}`,
    req.payload,
    {},
    true
  )();
}

function CreateAllBudgets(req) {
  return sagaFunctions(
    CreateBudgets,
    'post',
    `${apis.createBudgets}`,
    req.payload,
    {},
    true
  )();
}
function BudgetsBackTo(req) {
  return sagaFunctions(
    BudgetsBack,
    'post',
    `${apis.transferBudgetBack}`,
    req.payload,
    {},
    true
  )();
}
function MasterAccount(req) {
  return sagaFunctions(
    MasterAccountHistory,
    'post',
    `${apis.getMasterAccountHistory}`,
    req.payload,
    {},
    true
  )();
}

function initialPayout(req) {
  return sagaFunctions(
    InitiatePayout,
    'post',
    `${apis.initiatePayout}`,
    req.payload,
    {},
    true
  )();
}

function payoutTxns(req) {
  const params = {}

  if (req?.payload?.page_number) {
    params.page_number = req.payload.page_number
  }

  const str = new URLSearchParams(params)

  return sagaFunctions(
    PayoutTxns,
    'post',
    `${apis.payoutTxns}?${str}`,
    req.payload,
    {},
    true
  )();
}


export function* accountsWatcher() {
  yield takeLatest(PrimaryAccounts.REQUEST, getAccounts);
  yield takeLatest(CompanyAccounts.REQUEST, getCompanyAccounts);
  yield takeLatest(BeneficiaryAccount.REQUEST, getBeneficiaryAccounts);
  yield takeLatest(MasterPoolAccount.REQUEST, getMasterPoolAccount);
  yield takeLatest(AllBudgets.REQUEST, getAllBudgets);
  yield takeLatest(CreateBudgets.REQUEST, CreateAllBudgets);
  yield takeLatest(BudgetsBack.REQUEST, BudgetsBackTo);
  yield takeLatest(MasterAccountHistory.REQUEST, MasterAccount);
  yield takeLatest(InitiatePayout.REQUEST, initialPayout);
  yield takeLatest(PayoutTxns.REQUEST, payoutTxns);

}
