export const ar = {
  spendTitleText: "منصة إدارة الإنفاق المصممة لمساعدة عملك على الإنفاق بشكل أفضل",
  mordernMind: "تم تصميمه مع وضع الأعمال الحديثة في الاعتبار",
  welcomeBack: "مرحبًا بعودتك",
  signIntoAcc: "يرجى تسجيل الدخول إلى الحساب الخاص بك.",
  emailAddr: "عنوان البريد الإلكتروني",
  empId: "معرف العميل",
  new_trip_request: "طلب رحلة جديدة",
  password: "كلمة المرور",
  password_required: "كلمة المرور مطلوبة",
  desc_required: "نموذج الوصف مطلوب",
  enter: "يدخل",
  visiting_city: "زيارة مدينة",
  closed: "مغلق",
  check_out: "يفحص خارج",
  check_in: "يفحص في",
  add_hotel_details: "أضف تفاصيل الفندق",
  advance_search: "البحث المتقدم",
  trip_type: "نوع الرحلة",
  all_trips: "جميع الرحلات",
  other_bookings: "حجوزات أخرى",
  company_accounts: "حسابات الشركة",
  created_estimates: "التقديرات التي تم إنشاؤها",
  created_sale_order: "طلب المبيعات مخلوق",
  create_invoice: "تم إنشاء الفاتورة",
  forgotPassword: "هل نسيت كلمة السر",
  or: "أو",
  loginWithEmpId: "تسجيل الدخول بمعرف الموظف",
  loginWithEmail: "تسجيل الدخول بالبريد الإلكتروني",
  haveAcc: "لا تملك حسابا حتى الآن",
  clickHere: "انقر هنا ل",
  freeDemo: "سجل في حساب تجريبي مجاني",
  request_demo: 'طلب التجريبي',
  book_your_demo: 'احجز العرض التوضيحي الخاص بك',
  Amount: "كمية",
  "Date and Time": "التاريخ و الوقت",
  Company: "شركة",
  Company_placeholder: "أدخل اسم الشركة",
  ifsc: "IFSC",
  pay_via_corporate: "الدفع عن طريق ميزانية الشركة",
  manual_payment: "الدفع اليدوي",
  fund_transfer_type: "نوع التحويل المالي",
  receipt_created: "الرجاء إدخال مبلغ أقل من أو يساوي المبلغ المستحق.",
  search_by_trip: "البحث عن طريق معرف الرحلة ، الاسم",
  failed_receipt: "فشل إنشاء الإيصال",


  same_bank: "نفس البنك",
  export_pdf: "تصدير ملف PDF",
  other_bank: "بنوك أخرى",
  inter_swift: "الدولية (سويفت)",
  transaction_nature: "Transaction Nature",
  sync: "مزامنة",

  accounts_desc: "المدفوعات المحولة بين حسابات الشركة.",

  accountBalance: "رصيد الحساب",
  accountBalanceHint: "الرصيد الإجمالي لمحفظة الشركة المتاحة",
  totalPayables: "مجموع الذمم الدائنة",
  totalPayablesHint: "المبلغ الحالي والمتأخر الذي يجب أن تحصل عليه من العملاء",
  totalReceivables: "إجمالي الذمم المدينة",
  totalReceivablesHint: "المبلغ الحالي والمتأخر الذي لم تدفعه بعد إلى البائعين",
  balance: "توازن",
  new_advance: "يتقدم جديد",
  total: "مبلغ كلي",
  recovered: "تعافى",
  primary_account_balance: "",
  corporate_balance: "رصيد الشركات",
  pending_reimbursement: "Pending Reimbursement",
  pending_recovery: "الاسترداد المعلق",
  unreported_expense: "المصاريف غير المبلغ عنها",
  adv: "مقدما",
  top_expenses: "قمة  نفقات",
  totalUnpaidInvoices: "إجمالي الفواتير غير المسددة",
  totalUnpaidBills: "إجمالي الفواتير غير المسددة",
  current: "حاضِر",
  overDue: "متأخر",
  pendingApprovals: "الموافقات المعلقة",
  overallSummary: "ملخص شامل",
  reports: "التقارير",
  bills: "الفواتير",
  invoices: "الفواتير",
  trips: "رحلات",
  advances: "سالسلفةس",
  id: "بطاقة تعريف",
  my_reports: "تقاريري",
  reportName: "تقرير اسم",
  submittedOn: "تم إرساله",
  enter_pd: "أدخل كلمة المرور",
  input_pwd: "الرجاء إدخال كلمة المرور الخاصة بك!",
  totalExpenses: "المصروفات الكلية",
  awaiting: "في انتظار",
  totalReimbursed: "إجمالي المبالغ المسددة",
  totalTrips: "إجمالي الرحلات",
  cashFlow: "تدفق مالي",
  topExpense: "أعلى المصروفات",
  mealsEntertainment: "وجبات وترفيه",
  travel: "يسافر",
  enter_invoice_no: "أدخل رقم الفاتورة",
  enter_amount: "أدخل المبلغ",
  trip: "رحلة",
  contactUs: "اتصل بنا",
  contactUsContant: "تحتاج مساعدة؟ أرسل إلينا بريدًا إلكترونيًا وسنعاود الاتصال بك.",
  expenseOnTheGo: "المصاريف أثناء التنقل",
  expenseOnTheGoContent: "قم بتنزيل تطبيق الهاتف لنظام Android أو iOS",
  reviewUs: "راجعنا",
  reviewUsTitle: "مثل Bluerack المنتج؟",
  reviewUsDecription: "إذا كانت الإجابة بنعم ، فلماذا لا تخبر العالم عنها؟ راجعنا واجعل يومنا هذا!",
  reviewUsButton: "أكتب مراجعة",
  spendHistory: "تاريخ الإنفاق",
  searchEngine: "محرك البحث",
  direct: "مباشر",
  email: "بريد إلكتروني",
  unionAds: "إعلانات الاتحاد",
  videoAds: "إعلانات الفيديو",
  overviewOfAccounts: "نظرة عامة على الحسابات.",
  administrator: "مدير",
  addFilters: "إضافة عوامل التصفية",
  addNew: "اضف جديد",
  expensesId: "معرف المصروفات",
  dateAndTime: "التاريخ و الوقت",
  merchant: "تاجر",
  category: "فئة",
  category_parent: "الأبوين فئة",
  company_register_no: "تسجيل الشركة لا",
  please_select_value: "الرجاء تحديد القيمة",
  please_enter_category_name: "مناشدات أدخل اسم الفئة",
  email_required: "البريد الالكتروني مطلوب",
  email_valid: 'أدخل بريدًا إلكترونيًا صالحًا',
  category_name: "فئة اسم",
  please_enter_name: "الرجاء إدخال الاسم",
  general_ledger: "دفتر الأستاذ العام",
  isReimbursable: "قابل للسداد",
  select_payment_term: "الرجاء تحديد شروط الدفع",
  cardRefNumber: "رقم مرجع البطاقة",
  reference_hash: "مرجع #",
  note_credit: "  ملحوظة ائتمان ",

  status: "حالة",






  // Side Nav
  Dashboard: "لوحة القيادة",
  Sales: "مبيعات",
  Expenses: "نفقات",
  transfer: "التحويلات",
  un_submitted: "غير مقدم",
  decline: "تقدم نحو النهاية",
  delivery_date: "تاريخ التسليم او الوصول",
  from_computer: "من الكمبيوتر",
  transfer_log: " سجل التحويلات ",
  bill_hash: "ضرب من السلاح#",
  purchase_hash: "شىء مشترى#",
  upload_bills: "رفع الفواتير",
  rejected: "مرفوض",
  purchase_date: "تاريخ الشراء",
  all_reports: "كافة التقارير",
  bill_no_hash: "مشروع قانون لا#",
  place_of_supply: "مكان التوريد",
  apply_credits: "تطبيق الاعتمادات",
  amount_to_credit: "مبلغ الائتمان",
  collect_payment: "جمع الدفعات المالية",

  bill_desc: "يمكنك تحميل 5 ملفات كحد أقصى ، إجمالي 1 ميغابايت",
  attach_bill_file: "إرفاق ملف (ملفات) للمشروع قانون",
  approved: "موافق عليه",
  select_vendor: "حدد بائعًا",
  convert_to: "حول الى",
  mark_as_confirmed: "وضع علامة على أنه تم التأكيد عليه",
  reason_reject: "رفض السبب",
  convert: "هدى إلى",
  mark_as_declined: "وضع علامة على أنها اكبيتيد",
  mark_as_accept: "وضع علامة على أنها اكبيتيد",
  convert_to_Sale_order: "تحويل إلى أمر بيع",
  collect_payment_link: "جمع عبر رابط الدفع",
  balance_amount: "مقدار وسطي",
  payment_through: "الدفع من خلال",
  po_32: "فاتورة PO-312455",
  creation_date: " الإنشاء تاريخ ",
  clone: "استنساخ",
  all: "الجميع",
  send_email: "ارسل بريد الكتروني",
  convert_to_invoice: "تحويل للبيع",
  partillay_paid: "المدفوعة جزئيا",

  Purchases: "المشتريات",
  Cards: "البطاقات",
  my_cards: "بطاقاتي",
  my_purchases: "مشترياتي",
  vendor: "بائع",
  all_cards: "جميع البطاقات",
  Transfers: "التحويلات",
  Accounts: "حسابات",
  Analytics: "تحليلات",
  Settings: "إعدادات",

  //Expenses
  duplicate_account: "يبدو وكأنه حساب مكرر",
  resolve: "حل الآن",
  search: "البحث عن طريق معرف المصاريف والاسم",
  add_merchant: "أضف التاجر",
  no_data: "لا بيانات",
  attachment: "يسمح فقط بـ 5 مرفقات.",
  delete: "يمسح",
  cancel: "يلغ",
  are_u_sure: "هل أنت متأكد أنك تريد حذف?",
  alert: "يُحذًِر",
  ocr_documents: "إظهار مستندات OCR",
  upload: "رفع الملف من الحاسوب",
  new_report: "تقرير جديد",
  enter_details: "أدخل التفاصيل وأرسل",
  new_merchant: "تاجر جديد",
  save_close: "احفظ وأغلق",
  add_report: "إضافة تقرير",
  close: "يغلق",
  another_line: "أضف سطرًا آخر",
  send: "يرسل",
  submit: "يُقدِّم",
  confirm: "يتأكد",
  specify_amount: "يرجى تحديد مبلغ الموافقة الجزئية.",
  unlink: "فك الارتباط",
  unlink_expense: "هل أنت متأكد من أنك تريد إلغاء ربط المصروفات بهذا التقرير.",
  rejection_reason: "يرجى تحديد سبب مناسب للرفض.",
  expense_unlink: "نفقة فك الارتباط",
  reject_expense: "رفض المصاريف",
  view_expense: "مشاهدة ملف",
  no_comments: "بدون تعليقات",
  ex_id: "معرف السابق",
  date_time: "التاريخ - الوقت",
  date_range: "نطاق الموعد",
  payment_mode: "طريقة الدفع",
  merchant_mcc: "التاجر MCC",
  is_after: "هو بعد",
  due_balance_amount: "مبلغ الرصيد المستحق",
  is_before: "من قبل",
  search_text: "نص البحث",
  no_records_found: "لا توجد سجلات",
  range: "يتراوح",
  max_amount: "المبلغ الأقصى",
  is_lessthan: "أقل من يكون",
  is_greaterthan: "أكبر من",
  min_amount: "المبلغ الأدنى",
  expense_errors: "خطأ في المصاريف",
  enter_reason: "أدخل السبب",
  reject_advance: "رفض مقدما",
  delete_advance: "حذف مقدما",


  //Reports
  select_employee: "حدد الموظف بنفس الدور",
  employee_role: "موظف بنفس الدور",
  advance_id: "المعرف المسبق",
  title: "عنوان",
  date: "تاريخ",
  invoice_type: "نوع الفاتورة",
  clear_selection: "التحديد الواضح",
  credit_note_date: "تاريخ اشعار دائن",
  edit_project: "تحرير المشروع",
  new_project: "مشروع جديد",
  projects_name: "اسم المشاريع",
  projects_code: "كود المشاريع",
  add: "يضيف",
  policy_violation: "معلومات انتهاك السياسة",
  report_id: "معرّف التقرير",
  add_to: "اضف إليه",
  report: "تقرير",
  select_type: "اختر صنف",
  edit_trip: "تحرير الرحلة",
  veg: "نباتي",
  non_veg: "غير نباتي",
  add_other_details: "أضف تفاصيل أخرى",
  flight_mode: "وضع الطيران:",
  trip_id: "معرف الرحلة",
  from_city: "من المدينة",
  to_city: "إلى المدينة",
  add_train_details: "أضف تفاصيل القطار",
  add_bus_details: "أضف تفاصيل الحافلة",
  trip_iteniry: "خط سير الرحلة ",
  unsubmitted_estimates: "  التقديرات غير مقدم ",
  add_car_details: "إضافة تفاصيل السيارة",
  trip_name: "اسم رحلة",
  comments_required: "التعليقات مطلوبة",
  download_pdf: "تحميل PDF",
  report_details: "تفاصيل التقرير",
  report_desc: "انظر تفاصيل التقرير الخاص بك هنا.",
  share_report: "تقرير المشاركة",
  forward_approval: "إلى الأمامموافقة",
  download_receipts: "تحميل الإيصالات",
  reference: "مرجع",
  reimbursed_on: "على السداد",
  billing_to: "الفواتير ل",
  none: "None",
  aisle: "Aisle",
  notes: "ملحوظات",
  total_expense_amount: "كميةمصروفالمجموع",
  non_reimburse_amount: "المبلغ غير القابل للاسترداد",
  advance_amount: "المبلغ المقدميتق",
  amount_to_reimburse: "المبلغ الواجب سداده",
  reimbursed_amount: "المبلغ المسدد",
  record_reimburse: "سجل السداد",
  records_reimburse: " سجل رقم قياسيالسداد ",
  edit_report: "تحرير التقرير",
  approve: "يعتمد",
  msg: "ليس لديك أي تفاصيل",
  enter_comment: "أدخل التعليق",
  view_approval: "عرض تدفق الموافقة",
  submitted_to: "ارسلت الى:",
  convert_to_inv: "تحويل إلى فاتورة",
  submitted_by: "مقدم من:",
  est: "التقديرات",
  link_trips: "+  الارتباطرحلات",
  msg_trip: "ليس لديك أي رحلات مرتبطة بهذا التقرير",
  link_advance: "+ تقدم الارتباط",
  msg_advance: "ليس لديك أي تطورات مرتبطة بهذا التقرير.",
  link_unreported_expense: "+ ربط المصاريف غير المبلغ عنها",
  msg_expense: "ليس لديك أي نفقات مرتبطة بهذا التقرير.",
  add_unreported_expense: "إضافة المصاريف غير المبلغ عنها",
  add_new_expense: "إضافة مصاريف جديدة",
  add_new_policy: "إضافة مصاريف جديدة",
  view_summary: "ملخص منظر",
  summary: "ملخص",
  report_total: "التقرير الإجمالي",
  on: "على",
  to: "ل",
  duration: "مدة",
  more: "أكثر",
  recall: "يتذكر",
  edit: "يتذكر",
  reject: "رفض",
  reimburse: "أعاد النفقات",
  mode_reimbursement: "طريقة السداد",
  wallet_type: "نوع المحفظة",
  debit_account: "حساب مدين",
  credit_account: "حساب الائتمان",
  advance_refunds: "المبالغ المستردة مقدما",
  add_another_field: "أضف حقل آخر",
  manual_reimbursement: "السداد اليدوي",
  receive_advance: "هل تلقيت الرصيد المتبقي مقدمًا",
  carry_balance: "لا ، يمكن للمستخدم ترحيل الرصيد المتوفر",
  received_on: "وردت في",
  total_advance_refunds: "إجمالي المبالغ المستردة مقدما",
  bulk_msg: "هل أنت متأكد أنك تريد أن تجمع",
  export_csv: "تصدير CSV",
  excel_export: "اكسل تصدير",
  unlink_advance: "هل أنت متأكد أنك تريد إلغاء الربط المسبق من هذا التقرير.",
  unlink_advances: "فك الارتباط المسبق",
  comments: "تعليقات",
  company_master_account: "حساب سيد الشركة ",
  trips_link: "رحلات وصلة",
  bank_Accounts: "حسابات بنكية",
  add_expenses: "إضافة المصاريف غير المبلغ عنها",
  pending_approval: "قيد الانتظارموافقة :",
  approval_pending: "قيد الانتظارموافقة  ",
  new_expense: "جديدمصروف",
  delete_report: "هل أنت متأكد أنك تريد حذف التقرير؟",
  unlink_trip: "هل أنت متأكد أنك تريد إلغاء ربط الرحلة بهذا التقرير.",
  send_report_email: "إرسال تقرير إلى البريد الإلكتروني",
  flight_type: "نوع الرحلة",
  meal_preference: "تفضيل الوجبة:",
  wallets_balances: "محافظ وأرصدة ",
  visa: "تأشيرة",
  pos_trans: "معاملات نقاط البيع",
  books_periodicals: "الكتب والدوريات",
  enable_pos_trans: "تمكين معاملات نقاط البيع",
  enable_online_transactions: "تمكين المعاملات عبر الإنترنت",
  meal: "وجبة",
  card_limit: "حدود البطاقة",
  fuel: "وقود",
  gift: "هدية",

  seat_preference: "تفضيل المقعد:",
  preference_seat: "تفضيل المقعد",
  return_date: "تاريخ العودة",
  time_departure: "وقترحي",


  time_preference: "تفضيلات الوقت:",
  departure_time: "وقترحيل  : ",
  return_time: "وقت العودة:",
  one_way: "طريقة واحدة",
  round_trip: "الرحلة الإ نكفائية",
  multi_city: "متعدد-مدينة",
  window: "نافذة او شباك",
  middle: "وسط",
  save: "احفظ",
  yes: "نعم",
  no: "لا",
  medium: "واسطة",
  small: "صغير",
  enter_desc: "أدخل الوصف",
  attach_payments: " إرفاق ملف (ملفات) إلى المدفوعات ",
  select_credit_account: "حدد حساب الائتمان",
  select_debit_account: "حساب مدين اختار",
  five_files: "يسمح بخمسة ملفات فقط.",
  file_size: "يجب أن يكون حجم الملف أقل من 1 ميغا بايت.",
  desc_sub: "يمكنك تحميل 5 ملفات بحد أقصى ، 5 ميجا بايت لكل ملف",
  morning: "صباح",
  before_11: "قبل 11 صباحا",
  afternoon: "بعد الظهر",
  evening: "مساء",
  night: "ليل",
  ola: "علا",
  uber: "اوبر",
  rapido: "رابيدو",
  public_transport: "النقل العام",
  private_transport: "النقل الخاص",
  others: "آحرون",
  drop_off: "غلبه النعاس",
  all_expenses: "كل التكاليف",
  reimbursed: "سددها",
  recorded_by: "مسجلة بواسطة",
  purpose_title: "Purpose/Title",
  all_advances: "كل السلف",

  reported: "Reported",
  pending_reimburse: "Pending Reimbursements",

  saved: "أنقذ",
  expense_add: "أضف المصاريف",
  apply_advance: "تطبيق مقدما",
  max_7: "يمكنك تحميل 5 ملفات كحد أقصى ، 7 ميجا بايت لكل ملف.",
  mode: "Mode",
  reject_report: "رفض التقرير",
  add_adavnce_report: "إضافة مقدما إلى التقرير",
  add_trip_report: "إضافة رحلة إلى التقرير",

  add_expense_report: "إضافة المصاريف للتقرير",

  add_flight_details: "أضف تفاصيل الرحلة",
  flight_reservation: "حجوزات الطيران",
  car_rental_details: "أضف تفاصيل تأجير السيارات",
  arrived_at: "وصل في",
  depart_from: "تقلع من",
  hotel_reservation: "حجوزات فندقية",
  car_rental: "تأجير السيارات",
  business_purpose: "هدف العمل",
  destination: "وجهة",
  mark_as_close: "وضع علامة قريبة",
  edit_other_booking: "تحرير أخرىالحجوزات",
  add_other_booking: "إضافة أخرى الحجوزات",
  bus: "حافلة",
  train: "يدرب",
  employee_name: "اسم الموظف",
  purpose: "غاية",
  departure_date: "تاريخ المغادرة",
  To: "ل",
  From: 'من',
  travel_mode: "وضع السفر",
  pick_up: "يلتقط",
  arrival_date: "تاريخ وصول",
  hotel_name: "اسم فندق",
  location: "موقع",
  cab_type: "نوع الكابينة",
  search_by_report: "البحث عن طريق معرف التقرير ، الاسم",
  search_by_advance: "البحث عن طريق المعرف المسبق والاسم",
  add_to_report: "أضف إلى التقرير",
  paid_through: "تدفع عن طريق",
  associated_reports: "التقارير المرتبطة",
  from_date: "من التاريخ",
  to_date: "To Date",
  advance_total: "مجموع مقدما",
  edit_fields: "تحرير الحقول",
  new_sales: "مبيعات جديدة",
  invoice_hash: "رقم الفاتورة#",
  column_type: "نوع العمود",
  po: "PO",
  account_code: "رمز الحساب",

  march_24: "24 مارس 2022",
  new_estimates: "تقديرات جديدة",
  create_submitted_estimates: "التقديرات التي تم إنشاؤها وتقديمها",
  create_submit_sale_order: "تم إنشاؤها وتقديمها طلب المبيعات",
  create_submit_invoice: "الفاتورة المنشأة والمقدمة",
  new_credit_note: "مذكرة ائتمان جديدة",
  already_present: "موجودة بالفعل",
  new_invoice: " جديدة فاتورة",
  select_reason: "الرجاء تحديد السبب",
  customer_info_required: "معلومات العميل مطلوبة",
  receipt_creation: "تم إنشاء إيصال بنجاح",
  receipt_failed: "فشل إنشاء الإيصال.",
  incorrect_due: "ملاحظة: Bluerack ليست مسؤولة عن أي مدفوعات تتم بسبب تفاصيل البنك غير الصحيحة.",
  benificiery_details: "تفاصيل المستفيد",
  benificiery_account: "حسابات المستفيد",
  payment_upload: "تحميل المدفوعات رفع",
  manage_beneficial: "إدارة المستفيد",
  upload_payment_links: " رفعتحميل روابط المدفوعات",
  payment_links: "روابط الدفع",
  history: "سجل",
  name: "اسم",
  name_required: 'مطلوب اسم',
  name_placeholder: 'أدخل الاسم',
  success_bulk_payments: "بدأ بنجاح عمليات الدفع المجمعة",
  bulk_transfer: "عمليات النقل بالجملة",
  my_pdf: "ملف PDF الخاص بي",
  export_purchase_orders: "تصدير أوامر الشراء",
  import_purchase_orders: "أوامر شراء الاستيراد",
  bulk_payment_links: "روابط الدفع بالجملة",
  all_trans: "كل الحركات المالية",
  get_paid: "احصل على الدفع",
  my_trans: "معاملاتي",
  inventory: "جردة",
  ac_num: "رقم A / c",
  run_payroll: "قم بتشغيل كشوف المرتبات الخاصة بك في دقائق.",
  run_payroll_many: "قم بتشغيل كشوف المرتبات عدة مرات حسب حاجتك كل شهر للموظفين والمستشارين والمقاولين والعاملين لحسابهم الخاص.يتقاضى فريقك رواتبهم من خلال بضع نقرات فقط.",
  access_payroll: "الوصول إلى كشوف المرتبات",
  payroll_preference: "تفضيلات وإعدادات كشوف المرتبات",
  view_shared_bills: "عرض الفواتير المشتركة كموظفين آخرين",
  add_forgo: "أضف Bluerack كمستفيد جديد في حسابك الجاري باستخداممعلومات الحساب الواردة أدناه.",
  account_name: "إسم الحساب",
  account_num: "رقم حساب",
  account_type: "نوع الحساب",
  account_typo: "A / c النوع",
  rejection: "الرفض",
  deletion: "حذف",
  please_specify_reason: "يرجى تحديد سبب مناسب ل",
  corporate_id: "معرف الشركة",
  swift_bic: "سويفت رمز /BIC",
  failed_add_accounts: "فشل في إضافة حسابات بنكية",
  bank_account_added: "تمت إضافة الحسابات المصرفية",
  corporate: "شركة كبرى",
  last_updated_at: "آخر تحديث في",
  additional_info: "معلومات إضافية",
  enter_phone: "أدخل رقم الهاتف",
  enter_company_name: "أدخل اسم الشركة",
  customer_name_required: "اسم العميل مطلوب.",
  enter_customer_name: "أدخل اسم العميل",
  customer_space: "يمكن أن يحتوي اسم العميل على أحرف أو نقاط أو مسافات فقط.",
  customer_create: "تم إنشاء زبون  بنجاح ",

  bank_name: "اسم البنك",
  add_more_contact: "إضافة المزيد من جهات الاتصال",
  last_name: "آخر اسم",
  enter_last_name: 'إدخال اسم آخر',
  payment_details: "بيانات الدفع",
  ship_address: "عنوان الشحن - نسخ نفس العنوان",
  middle_name: "الاسم الأوسط",
  first_name: "الاسم الأول",
  first_name_required: 'الإسم الأول مطلوب',
  branch: "فرع",
  bank_country: "  بلد بنك ",
  transfer_funds: "تحويل الأموال من حسابك الجاري إلى حساب Bluerack عبرمعلومات NEFT / RTGS / IMPS الواردة أدناه.",
  view_account_details: "عرض تفاصيل الحساب",
  load_money: "تحميل الأموال في حساب التمويل",
  master_pool: "حساب المجمع الرئيسي",
  transaction_history: "تاريخ المعاملة",
  add_fund: "إضافة الأموال",
  trans_id: "رقم المعاملة",
  connect_bank: "الاتصال بالبنك الذي تتعامل معه",
  export_reports: "تقارير التصدير",
  ac_balance: "ميزان A / c",
  add_new_customer: "إضافة عميل جديد",
  sort_by: "ترتيب حسب",
  no_filters_applied: "لم يتم تطبيق أي مرشحات",
  action: "فعل",
  search_status: "البحث عن طريق المعرف والاسم والحالة",
  cost_center: "مركز يكلف",
  project: "مشروع",
  snap: "لقطات",
  expense_reports: "تقارير النفقات",
  card_transactions: "معاملات البطاقة",
  estimate: "التقديرات",
  sale_order: "طلبات المبيعات",
  received_payment: "تم استلام الدفعة",
  aging_invoice: "تقادم الفاتورة",
  purchases_orders: "أوامر الشراء",
  made_payments: "المدفوعات التي تمت",
  create_payment_links: "أنشئ رابط الدفع",
  link_create: "تم إنشاء رابط الدفع",
  copy_link: "نسخ الوصلة",
  link_Sent_phone: "سيتم إرسال رابط الدفع إلى رقم الهاتف ومعرف البريد الإلكتروني",
  customer_Details: "تفاصيل العميل",
  customer_info: "معلومات العميل",
  bills_aging: "شيخوخة الفواتير",
  customer_name: " زبون اسم",
  new_transaction: "معاملة جديدة",
  activate: "تفعيل",
  deactivate: "ديتفعيل",
  gstin: "رقم GSTIN",
  other_information: "معلومات أخرى",
  gst_treatment: "علاج GST",
  refresh: "نعش الذاكرة",
  by_sort: "ترتيب حسب",
  search_by_name: "البحث عن طريق الإسم",

  created_at: "أنشئت في",

  add_vendor: "أضف البائعين الذين تتعامل معهم في عملك.",
  add_customer: "أضف العملاء الذين تتعامل معهم في عملك.",
  country_region: "البلد / المنطقة",
  vendors: "الباعة",
  customers: "الزبائن",
  enter_vendor_name: "أدخل اسم البائع",
  number_invoice: "رقم الفاتورة",
  customer_update: "تم زبو العميل بنجاح",
  cust: "زبون",
  city: "مدينة",
  state: "ولاية",
  bank_account_Details: "تفاصيل البنك غير موجود",
  select: "انتقى",
  street: "شارع",
  currency_code: "شفرة العملة",
  bill_no: "مشروع قانون لا",
  save_and_submit: "حفظ و يُقدِّم",
  material_desc: "المادة / إيصال التسليم / أمر الشراء",
  inv_desc: "يمكنك تحميل 5 ملفات كحد أقصى ، 1 ميجا بايت لكل ملف",
  payment_receive: "الدفعات المستلمة",
  no_account: "رقم حساب",
  currency: "عملة",
  bill_date: "تاريخ الفاتورة",
  reference_no: "رقم المرجع",
  payment_term: "شروط الدفع",
  payment_due_date: "دفع مستحق تاريخ",
  tax_amount: " كمية ضريبة ",
  discount: "تخفيض",
  gst_uin: "رقم GSTIN / UIN #",
  enter_gst_uin: "أدخل رقم GSTIN / UIN",
  enter_pan: "أدخل PAN",
  email_add: "أدخل عنوان البريد الالكتروني",
  order_purchase: "أمر شراء #",
  order_salee: "طلب المبيعات  #",
  download_text: "تحميل",
  attachments: "المرفقات",
  credit_note: "  ملحوظة ائتمان#",
  balance_due: "الرصيد المستحق",
  invoice_balance: "رصيد الفاتورة",
  invoice_balance_due: "رصيد الفاتورة المستحق",
  create_payment: "إنشاء الدفع",
  payment_create: "إنشاء الدفع",
  hsn_code: "رمز HSN",
  unit_price: "سعر الوحدة",
  tax_name: "الاسم الضريبي",
  val: "قيمة",
  enter_val: "أدخل القيمة",
  item_name: "اسم العنصر",
  item_type: "نوع العنصر",
  make_payment: "قم بالدفع",
  record_payment: "الدفع المحضر",
  number_phone: "رقم التليفون",
  pan_no: "رقم المقلاة",
  balance_due_amount: "الرصيد المستحق كمية",
  tds_amount: "مبلغ TDS",
  total_amount: "المبلغ الإجمالي",
  aging: "شيخوخة",

  //Analytics
  employee_id: "هوية شخصية موظف",
  card_ref_no: "بطاقة المرجع  لا",
  transaction_date: "تاريخ الصفقة",
  merchant_name: "اسم التاجر",
  transaction_amount: "قيمة التحويل",
  merchant_code: "كود التاجر",
  wallet_name: "اسم المحفظة",
  export_to_Excel: "تصدير إلى تتفوق",
  card_block: " بطاقة كتلة ",
  transaction_type: "نوع المعاملة",
  print: "طباعة",
  approval_status: "حالة القبول",
  estimate_no: "تقدير لا",
  estimate_date: " تاريخ تقدير",
  expiry_date: "تاريخ الانتهاء",
  invoice_date: "تاريخ الفاتورة",
  due_date: "تاريخ الاستحقاق",
  gl_code: "كود GL",
  invoice_no: "رقم الفاتورة",
  shipment_date: "شحنة تاريخ",
  po_number: "PO عدد",
  sales_no: "رقم المبيعات",
  sales_hash: "مبيعات#",
  vendor_name: "اسم البائع",
  expected_delivery_date: "تاريخ التسليم المتوقع",
  purchase_order_no: "الطلب شراء لا",
  purchase_order_date: "تاريخ أمر الشراء",
  sales_order_no: "رقم طلب المبيعات",
  sales_order_date: "تاريخ أمر المبيعات",
  expected_shipment_date: "تاريخ الشحن المتوقع",
  top_spending_users: "المستخدمين الأكثر إنفاقًا",
  view_details: "عرض التفاصيل",
  top_vendors: "كبار البائعين",
  top_violations: "أهم الانتهاكات",
  top_categories: "أعلى الفئات",
  add_bill: "أضف بيل",
  due_amount: "مستحق كمية",
  amount_paid: " مدفوع المبلغ ا",
  no_bill: "  رقم  مشروع قانون",
  how_it: "كيف تعمل",
  shared_bill: "عرض الفواتير المشتركة كموظفين آخرين",
  set_approvals: "موافقات الإعداد",
  bill_preference: "تفضيلات وإعدادات دفع الفواتير",
  confirm_email: ".تأكيد عنوان البريد الإلكتروني",
  pay_bill: "إدارة ودفع جميع الفواتير عبر مواقع عملك باستخدام لوحة تحكم واحدة.سيتم جلب تفاصيل الفاتورة مثل تواريخ الاستحقاق والمبالغ تلقائيًا من أجل التتبع البسيط وإجراء الدفعات.",
  bill_payments: "مدفوعات الفواتير. مبسط.",
  utility_bill: "  الفواتير منفعة ",
  dont_have: "لا تملك حسابا حتى الآن؟ انقر هنا ل",
  register: "يسجل",
  my_bills: "فواتيري",
  set_pwd: "تعيين كلمة المرور الخاصة بك",
  create_new: "الرجاء إنشاء كلمة مرور جديدة أدناه. يجب أن تحتوي كلمة مرورك الجديدة على ملف 8 أحرف على الأقل.",
  unable_to_change: "غير قادر على تغيير كلمة المرور؟ اتصال",
  support: "يدعم",
  enter_email: "أدخل البريد الإلكتروني",
  enter_pwd: "أدخل كلمة المرور",
  set_modify_pin: "تعيين / تعديل PIN",
  assign_cards: "تعيين البطاقات",
  by_click: "بالنقر فوق احجز العرض التوضيحي  فإنك توافق على أن يتم الاتصال بك بواسطة التخلي لتلقي معلومات حول العرض التوضيحي.",
  have_an_account: "هل لديك حساب؟ انقر هنا ل",
  login: "تسجيل الدخول",
  reset_ur_password: "اعد ضبط كلمه السر",
  check_inbox: "تحقق من صندوق الوارد الخاص بك ، لقد أرسلنا لك بريدًا إلكترونيًا على العنوان",
  check_spam: "يرجى التحقق من مجلد البريد العشوائي أو البريد العشوائي. الرابط صالح لمدة 24 ساعة يمكنك استخدامه لإعادة تعيين كلمة المرور الخاصة بك.",
  email_link: "سنرسل لك بريدًا إلكترونيًا يحتوي على رابط لإعادة تعيين كلمة المرور الخاصة بك",
  back_to_login: "العودة إلى تسجيل الدخول",
  search_by_ref: "البحث عن طريق رقم البطاقة ، الاسم",
  card_number: "رقم البطاقة",
  total_balance: "إجمالي الرصيد",
  kyc_status: "حالة KYC",
  reason: "سبب",
  details: "تفاصيل",
  new: "جديد",
  lock_status: "حالة القفل",
  activated_on: "تفعيل في",
  card_policy: "سياسة البطاقة",
  block_card: "هل أنت متأكد أنك تريد حظر البطاقة؟",
  card_balalnce: "أرصدة البطاقة",
  card_type: "نوع البطاقة",
  mobile_number: "رقم الهاتف المحمول",
  mobile: 'متحرك',
  mobile_required: 'رقم الجوال مطلوب',
  mobile_placeholder: "أدخل رقم الجوال",
  work_email: 'البريد الإلكتروني العمل',
  work_email_required: 'مطلوب البريد الإلكتروني للعمل',
  work_email_placeholder: "أدخل البريد الإلكتروني للعمل",
  card_holder_name: "إسم صاحب البطاقة",
  update_mobile: "تحديث رقم الهاتف المتحرك",
  activate_card: "تفعيل البطاقة",
  replace_card: " يستبدل بطاقة ",
  request_replacement: "تم تقديم طلب بطاقة بديلة بنجاح.",
  update: "تحديث",
  browse: "تصفح",
  files_upload: "رفع الملفات",
  browser_popup: "ملاحظة: - يجب تمكين إذن المتصفح المنبثق لإعادة التوجيه.",
  dont_continue: "إذا كنت لا تريد المتابعة ، فاضغط على إلغاء.",
  otp_verification: "أنت تغادر تطبيق Paycraft ويتم إعادة توجيهك إلى صفحة ويب Activate Card بعد التحقق من OTP.",
  otp_verify: "تحقق من OTP",
  update_mobile_number: "هل أنت متأكد أنك تريد تحديث رقم الهاتف المحمول؟",
  replace: "يستبدل",
  request_new_card: "هل أنت متأكد أنك تريد طلب بطاقة جديدة؟ ",
  continue: "واصل",
  card_set_pin: "أنت تغادر تطبيق Bluerack وتتم إعادة توجيهك إلى صفحة ويب Card Set PIN ، يرجى النقر فوق متابعة للتأكيد.",
  set_pin: " دبوس تعيين",
  block: "إعترض سبيل",
  employee_mobile: "موبايل الموظف",
  company_name: "اسم الشركة",
  bank_transfers: "التحويلات المصرفية",
  select_user_assign: "حدد المستخدم وقم بتعيينه",
  debit_error_msg: "رقم الحساب المدين مطلوب",
  schedule_payment: "دفع مالجدول ",
  transactions: "المعاملات",
  available_balance: "الرصيد المتوفر",
  full_kyc: "مليء اعرف عميلك",
  have_document_kyc: "هل المستندات في متناول يديك الآن؟ انقر على KYC الكامل.",
  dont_have_kyc: "ألا تمتلك مستندات اعرف عميلك معك؟ أكمل الحد الأدنى من KYC  فورا!",
  address_proof: "إثبات العنوان: بطاقة Aadhaar ، رخصة القيادة ، هوية الناخب ، جواز سفر",
  identity_proof: "إثبات الهوية: بطاقة Aadhaar ، بطاقة PAN ، رخصة القيادة ، VoterID ، جواز السفر ",
  photo_assist: "الصورة: صورة مصدقة",
  arrange_kyc: "فيما يلي المستندات المطلوبة للترتيب لـ KYC",
  no_atm: "لا يمكن إجراء معاملات أجهزة الصراف الآلي بدون نظام اعرف عميلك الكامل وحسابكلا يمكن أن تتجاوز المعاملات 10000 روبية هندية.",
  documents_verified: "تم التحقق من المستندات",
  kyc_verify: "التحقق من KYC",
  number_of_cards: "عدد البطاقات",
  available_cards: "البطاقات المتاحة في المخزون - 10430",
  phone: "هاتف",
  card_holder_email: "البريد الإلكتروني لحامل البطاقة",
  initiate_transfer_request: "يرجى التأكد من توفر رصيد كافٍ لديك قبل بدء طلب التحويل.",
  use_funds: "ملاحظة: سيستخدم هذا التحويل الأموال من ميزانية الشركة.",
  csv_edit: "يمكنك تحرير المعلومات في الملف (.csv) وتحميلها.",
  sample_file: "سيقوم ملف العينة بتنزيل معلومات البطاقات النشطة جنبًا إلى جنب مع حقول TopUp للمحفظة",
  beneficiary_name: "أسم المستفيد",
  batch_name: "اسم الدفعة",
  enter_batch: " دخل اسم الدفعة",
  phone_no: "رقم الهاتف",
  create_date: "إنشاء التاريخ والوقت",
  link_id: "معرف الارتباط",
  message: "رسالة",
  message_required: "الرسالة مطلوبة",
  message_placeholder: 'أدخل رسالة',
  apprvolas: "الموافقات",
  my_estimates: "تقديراتي",
  status_file: "يبدأ. ستتم مشاركة ملف الحالة على البريد الإلكتروني بمجرد اكتمال العملية.",
  add_new_vendor: "إضافة بائع جديد",
  enter_ifsc: "أدخل رمز IFSC",
  enter_benificiary: "أدخل اسم المستفيد",
  payment_for: "الدفع مقابل",
  enter_valid_number: "أدخل رقمًا صالحًا",
  enter_valid_Email: "أدخل بريدًا إلكترونيًا صالحًا.",
  enter_number: "أدخل رقم الحساب",
  create: "خلق",
  phone_Required: "رقم الهاتف مطلوب",

  enter_bank_name: "أدخل اسم البنك",
  number_account: "رقم الحساب",
  ifsc_code: "كود IFSC",
  create_manage_benificiary: "إنشاء إدارة المستفيد",
  from_account: "من حساب",
  file_name: "اسم الملف",
  count: "Count",
  schedule_date: "جدولة التاريخ والوقت",
  bank_account_num: "رقم الحساب البنكي",
  payment_amount: "مبلغ الدفع",
  upload_excel: " انقر ل رفع ملف اكسل",
  transfer_transaction: "ملاحظة: في تاريخ التحويل المحدد ، ستبدأ المعاملة.",
  download_samples: "تنزيل العينات",
  plain_template: "ملاحظة: نموذج الملف هو قالب الألم",
  without_employee: "بدون بيانات الموظف",
  search_by_transaction_id: "البحث عن طريق معرف المعاملة والاسم",
  download_sample_form: "تنزيل نموذج نموذج",
  quick_load: "تحميل سريع",
  create_date_time: "إنشاء التاريخ والوقت",
  loaded_amount: " مبلغ محملة ",
  mobile_no: "رقم المحمول",
  term_id: "معرف المحطة",
  no_of_cards: "لا ... من البطاقات",
  added_date: "التاريخ المضاف",
  remarks: "ملاحظات",
  beneficiary_id: " المستفيدالاسم والمعرف ",
  to_account: "لحساب",
  create_company_payments: "إنشاء مدفوعات حسابات المستفيدين",
  update_company_payments: "تحديث دفعات حسابات المستفيدين",
  add_one_more: "+إضافة واحد أكثر",
  back: "ظهر",
  no_approval: "لا موافقة",
  create_sale_order: "إنشاء أمر مبيعات وتحويله إلى فاتورة مباشرة.",
  create_estimate_order: "إنشاء تقدير وتحويله إلى أمر مبيعات مباشرة.",
  create_purchase_order: "إنشاء أمر شراء وتحويله إلى فواتير مباشرة.",
  create_payments: "إنشاء الفواتير وإجراء المدفوعات مباشرة",
  hierarchy_approval: "الموافقة الهرمية",
  approval_flow: "سيعتمد تدفق الموافقة على المعتمدين الذين تم تكوينهم في حقول عمليات الإرسال إلى والموافقات وإعادة التوجيه إلى المستخدمين",
  approval_types: " أنواع موافقة",
  main_category: "الفئة الرئيسية",
  sub_category: " الفرعية فئة-",
  parent_category: "ملاحظة: ستنطبق سياسات الفئة الرئيسية على الفئة الفرعية ، إذا كنت ترغب في التغيير يمكنك دائمًا التعديل في الفئات الفرعية ضمن قسم الفئات",
  enter_category_code: "أدخل رمز الفئة",
  category_code: "أدخل رمز الفئة",
  select_category: "حدد فئة الأصل",
  delete_payment: "هل أنت متأكد أنك تريد حذف هذه الفئة؟",
  delete_field: "حذف الحقل",
  number: "رقم",
  show_in_pdf: "إظهار في كل ملفات PDF",
  is_mandatory: "إلزامي",
  options: "خيارات",
  expense_should_Empty: "يجب ألا يكون حد المصاريف فارغًا",
  card_limit_msg: "يجب ألا يكون حد البطاقة فارغًا",

  block_msg: "يجب اختيار حظر أو تحذير أي شخص",

  reminder_alert: "تنبيه تذكير للموظفين إذا كان المبلغ المقدم معلقًا لأكثر من",
  days_submission: "أيام للتقديم",
  notifications: "إشعارات",
  auto_generate: "إنشاء معرف متقدم تلقائيًا",
  enter_cost_center: "أدخل اسم مركز التكلفة",
  enter_cost_center_code: "أدخل رمز مركز التكلفة",
  select_cost_head: "حدد رئيس مركز التكلفة",
  delete_cost_center: "إذا قمت بحذف مركز التكلفة هذا ، فلن يكون المستخدمون مرتبطين بعد الآن بمركز التكلفة هذا. هل أنت متأكد أنك تريد حذف ذلك؟",
  delete_cost: "احذف مركز التكلفة",
  apply_expense: " التقدم بطلب محددفئات المصاريف ",
  days: "أيام",
  reminder_expense: "إرسال تذكير بالنفقات غير المبلغ عنها لأكثر من",
  auto_generate_expense: "إنشاء معرف المصروفات تلقائيًا",
  delete_sure_field: "هل أنت متأكد أنك تريد حذف هذا الحقل",
  mandatory: "إلزامي",
  reminder_deleted: "تم حذف التذكير بنجاح",
  pdf_show: "إظهار في PDF",
  enable: "مكن",
  add_fields: "أضف الحقول",
  enter_tax_name: "أدخل اسم الضريبة",
  enter_unit_price: "أدخل اسم الضريبة",
  enter_hsn_code: "أدخل رمز HSN",
  enter_sku: "أدخل SKU",
  enter_item_name: "أدخل اسم العنصر",
  enter_project_code: "أدخل كود المشروع",
  select_project_head: "حدد رئيس المشاريع",
  no_longer_users: "إذا قمت بحذف هذه المشاريع ، فلن يكون المستخدمون مرتبطين بهذه المشاريع. هل أنت متأكد أنك تريد حذف ذلك؟",
  delete_project: "حذف المشروع",
  edit_marchant: "تحرير التاجر",
  select_column_type: "حدد نوع العمود",
  auto_generate_report: "إنشاء معرّف التقرير تلقائيًا",
  expense_report_submission: "عند تقديم تقرير المصاريف",
  notify_approver: "قم بإخطار الموافق عبر البريد الإلكتروني عند تقديم التقارير",
  approval_notifications: "إخطارات الموافقة",
  reminder_expense_report: "تنبيه تذكير للموافقة إذا كانت تقارير المصاريف معلقة لأكثر من",
  auto_generate_msg: "توليد تلقائي",
  id_msg: "ID",
  reminder_alert_reports: "تنبيه تذكير ل",
  reminder: "تذكير",
  contact_name: "اسم جهة الاتصال",
  country: "بلد",
  primary_email: "البريد الإلكتروني الرئيسي",
  branch_name: "اسم الفرع",
  organisation_name: "اسم المنظمة",
  select_city: "اختر مدينة",
  profile_updated_success: "تم تحديث الملف الشخصي بنجاح",
  select_state: "اختر ولايه",
  branch_address: "عنوان فرع",
  Organisation_address: "عنوان المنظمة",
  branch_register_no: "مسجل الفرع لا",
  Organisation_register_no: "رقم تسجيل المنظمة",
  add_more_details: "أضف المزيد من التفاصيل",
  remove: "يزيل",
  enter_address: "أدخل العنوان",
  drag_png: "اسحب هنا لتحميل JPG / PNG",
  click_here_to: "أو انقر هنا للإرفاق",
  preferred_image: "حجم الصورة المفضل: 240 بكسل × 240 بكسل @ 72 نقطةفي البوصة الحد الأقصى للحجم1 ميغا بايت",
  select_country: "حدد الدولة",
  pincode: "الرمز السري",
  office_number: "رقم المكتب",
  gstin_no: "رقم GSTIN لا ",
  tan_no: "رقم TAN",
  upload_company_documents: "قم بتحميل مستندات شركتك",
  upload_organisation_documents: "تحميل مستندات مؤسستك",
  certificate_incorporation: "شهادة تأسيس",
  primary_contact: "اتصال رئيسي",
  primary_contact_number: "رقم الاتصال الأساسي",
  primary_contact_email: "عنوان البريد الإلكتروني لجهة الاتصال الأساسية",
  primary_contact_name: "الاسم الأساسي لجهة الاتصال",
  other_documents: "وثائق أخرى",
  gst_tan: "شهادة ضريبة السلع والخدمات ، TAN",
  upload_company_log: "قم بتحميل شعار شركتك",
  default_policy: "النهج الافتراضي",
  atm: "ماكينة الصراف الآلي",
  pos: "نقاط البيع",
  online: "متصلين",
  contact_less: "Contact Less",
  daily_limit: "الحد اليومي",
  select_all_read: " حدد الكل مقروء",
  select_all_write: "حدد الكل اكتب",
  assign_prepaid_card: "تعيين بطاقة مسبقة الدفع",
  monthly_limit: "الحد الشهري",
  yearly_limit: "الحد السنوي",
  when: "متى",
  online_transactions: "متصلين المعاملات",
  atm_withdraw: "أجهزة الصراف الآلي الانسحاب",
  card_usage_controls: "ضوابط استخدام البطاقة",
  if_any: "لو اي",
  specific_grade: "الدرجة / التعيين المحدد",
  specific_user: "مستخدم محدد",
  rule_will: "ستطبق القاعدة على",
  choose_who_will: "اختر من سيتأثر بهذه القاعدة.",
  define_users: "تحديد المستخدمين",
  define_conditions: "تحديد شروط لهذه السياسة",
  desc: "الوص",
  policy_name: "اسم السياسة",
  define_the_limit: "حدد الحد",
  for: "For",
  create_limits: "خلق حدود لهذه القاعدة",
  warning: "تحذير",
  select_actions: "حدد الإجراءات التي تريد حدوثها عند تطبيق القاعدة.",
  uploads: "تم الرفع",
  complete: "مكتمل",
  review: "مراجعة",
  not_uploaded: "لم يتم الرفع",
  less_than: "أقل من",
  greater_than: "Greater Than",
  less_than_or: "اصغر من او يساوي",
  greater_than_or: "أكبر من أو يساوي",
  not_equal: "غير متساوي",
  expense_amount: "مبلغ المصاريف",
  expense_policy: "سياسة المصاريف",
  options_are_required: "الخيارات مطلوبة",
  advances_field_name: "اسم الحقل adavnces",
  edit_category: "تحرير الفئة",
  unlink_trips: "رحلات فك الارتباط",
  no_pdf_found: "لم يتم العثور على ملف pdf",
  rip_title: "عنوان الرحلة",
  searchh: "حاول إيجاد",
  new_category: "فئة جديدة",
  custom_name: "اسم مخصص",
  edit_custom_field: "تحرير حقل مخصص",
  new_custom_field: "حقل مخصص جديد",
  download_file: "تحميل الملف",
  connect_to_bank: "الاتصال بالبنك الذي تتعامل معه",
  in_active: "غير نشط",
  company_address: "عنوان الشركة",
  drag_upload: "اسحب هنا لتحميل JPG / PNG / PDF",
  organization_profile: "الملف التعريفي للمنظمة",
  see_organization: "انظر تفاصيل مؤسستك هنا",
  see_company: "اطلع على تفاصيل شركتك هنا",
  users: "المستخدمون",
  roles: "الأدوار",
  permissions: "أذونات",
  grades: "درجات",
  destinations: "التعيينات",
  custom_fields: "الحقول المخصصة",
  download_sample_file: "تنزيل نموذج ملف",
  download_csv: "يمكنك تنزيل نموذج ملف النموذج (.csv) وتعبئة المعلومات.",
  upload_ur_file: "قم بتحميل ملفك",
  edit_info: "يمكنك تحرير المعلومات في الملف (.csv) وتحميلها.",
  proceed: "يتابع",
  instructions: "تعليمات",
  user_grade_match: "يجب أن تتطابق حقول درجة المستخدم والتعيين والأدوار والعنوان التي تم إدخالها تمامًا مع البيانات الرئيسية المتوفرة في التطبيق.",
  unique_id: "يجب أن يكون معرف الموظف فريدًا ، ولن يسمح النظام بتكرار معرف المستخدم.",
  email_format: "يجب أن يكون تنسيق البريد الإلكتروني صالحًا ، ولن يسمح النظام بمعرف البريد الإلكتروني المكرر لمؤسسة ما.",
  mobile_number_should_have: "يجب أن يكون رقم الهاتف الأساسي صالحًا لرقم هاتف محمول مكون من 10 أرقام.",
  email_id_approvers: "يجب توفير معرف البريد الإلكتروني للموافقين لتعيينهم للمستخدم. (مثال: مدير التقارير - rm@yopmail.com)",
  success_file_upload: "عند تحميل الملف بنجاح ، سيتم تشغيل إنشاء وظائف المستخدمين في وظيفة غير متزامنة. بعد الانتهاء بنجاح من الوظيفة ، سيتم إرسال بريد إلكتروني إلى المسؤول حول حالة إنشاء المستخدمين.",
  upload_file_format: "يجب أن يكون ملف التحميل بتنسيق CSV.",
  want_give: "تريد أن تعطي بطاقة لهذا المستخدم",
  next: "تال",
  select_advance_approver: "حدد الموافقة المسبقة",
  select_approver: 'حدد الموافق',
  limits_settings: "الحدود والإعدادات",
  define_user_limits: "تحديد حدود المستخدم",
  profile: "حساب تعريفي",
  personal_information: "معلومات شخصية",
  user: "مستخدم",
  s: "س",
  Card: "بطاقة",
  role: "دور",
  add_new_user: "إضافة مستخدم جديد",
  transfer_pending: "نقل الموافقات المعلقة مع هذا المستخدم إلى المستخدم الجديد",
  employee_assign: "تعيين الموظفين المعينين لهذا المستخدم لموافق آخر",
  employee_expense: "ستظل تقارير نفقات ونفقات الموظفين في قاعدة البيانات لعرضها ولكن سيتم حذف الموظف ولن يتمكن من الوصول إلى النظام بعد الآن.",
  enter_card: "أدخل رقم البطاقة",
  ur_selected_file: "ملفك المحدد:",
  previous: "مسبق",
  fields: "مجالات",
  imported_file_headers: "رؤوس الملفات المستوردة",
  best_match_selected: "تم تحديد أفضل تطابق لكل حقل في الملف المحدد تلقائيًا.",
  approvers: "الموافقون",
  role_name: "اسم الدور",
  role_desc: "وصف الدور",
  define_permissions: "تحديد أذونات لهذا الدور",
  add_new_role: "إضافة دور جديد",
  delete_role: "حذف الدور",
  delete_organization: "حذف المنظمة",
  edit_role: "تحرير الدور",
  new_role: "دور جديد",
  delete_role_are_u_sure: "هل أنت متأكد من حذف هذا الدور؟",
  delete_role_are_u_sure_organization: "هل أنت متأكد من حذف هذه المنظمة؟",
  role_updation: "تم تحديث الدور بنجاح",
  delete_grade: "حذف التقدير",
  user_grade_degree: "درجة / مستوى المستخدم",
  user_title: "عنوان المستخدم",
  grade_designations: "الدرجات والتسميات",
  address: "عنوان",
  enter_valid_company: "الرجاء إدخال اسم شركة صالح",
  company_required: "اسم الشركة مطلوب",
  upload_ur_company_logo: "قم بتحميل شعار شركتك",
  upload_ur_organisation_logo: "تحميل شعار مؤسستك",
  primary_contact_email_Address: "عنوان البريد الإلكتروني لجهة الاتصال الأساسية",
  user_dont_have: "المستخدم ليس لديه أذونات ل",
  see_ur_approved: "انظر المعاملات المعتمدة الخاصة بك هنا",
  select_grade_level: "حدد الدرجة / المستوى",
  select_designation: "تحديد التعيين",
  new_user: "مستخدم جديد",
  desi: "تعيين",
  grade_level: "مستوى الصف",
  please_input: "الرجاء إدخال رقم هاتف صالح!",
  enter_employee_id: "أدخل معرف الموظف",
  select_project: "اختر المشروع",
  card_no_kit: "رقم البطاقة أو رقم المجموعة",
  select_file: "حدد ملف",
  single_line: "سطر واحد",
  default_value: "القيمة الافتراضية",

  write: "وضع الألحان",
  new_grade: "درجة جديدة",
  edit_grade: "تحرير الدرجة",
  read: "يقرأ",
  map_files: "ملفات الخرائط",
  select_cost_center: "حدد مركز التكلفة",
  enter_first_name: "أدخل الاسم الأول",
  input_email: "الرجاء إدخال البريد الإلكتروني الخاص بك!",
  valid_email: "الإدخال ليس بريدًا إلكترونيًا صالحًا!",

  delete_user: "مسح المستخدم",
  enter_num: "أدخل رقم",
  mr: "السيد",
  mrs: "السّيدة",
  title_required: "العنوان مطلوب",
  uplaod_users: "رفعتحميل المستخدمين",
  contact_persons: " الاتصال الأشخاص ",
  payments_transferred: "المدفوعات المحولة بين حسابات المستفيدين للشركة.",
  manage_branches: "إدارة الفروع",
  manage_organisation: "إدارة المنظمة",
  view_user_edit: "عرض تفاصيل المستخدم وتحرير",
  convert_sure: "هل أنت متأكد من أنك تريد التحويل إلى ملف",
  add_new_branch: "إضافة فرع جديد",
  add_new_organisation: "إضافة منظمة جديدة",
  polocies_limits: "السياسات والحدود",
  actions: "Actions",
  optional: "غير إجبارى",

  roles_permissions: "الأدوار والأذونات",

  see_branches: "انظر الفروع  بك",
  see_organisation: "راجع مؤسستك",

  shipping_address: "عنوان الشحن",
  purchase_order: "أمر شراء",
  billing_address: " عنوان الفواتير",
  primary: "أساسي",
  bill: "فاتورة",
  order_sale: "طلب المبيعات",
  credit_notes: "ملاحظات الائتمان",
  my_sales: "مبيعاتي",
  my_invoices: "فواتيرى",
  payment_made: "تم الدفع",
  invoice: "فاتورة",
  payments_made: "المدفوعات المسددة",

  advance_approver: "الموافقات المسبقة",
  report_approvals: " الموافقات تقرير ",

  bank_Account_info: "معلومات الحساب المصرفي",
  source_of_supply: " مصدر  إمداد",
  image: "الرجاء تحميل الصورة. التنسيقات المقبولة jpeg و png و jpg",
  invoice_po: "فاتورة PO-312455",
  created_by: "تم إنشاؤه بواسطة Lokesh.ankam@abslogistic.com",
  created_date: "تاريخ الإنشاء - 12 فبراير ، 2022 12.34 مساءً",
  estimate_hash: "تقديرات#",
  invoice_hashh: "فاتورة#",
  so_no: "رقم SO",
  sale_order_document: "أمر المبيعات هو مستند يتم إرساله إلى عملائك لتأكيد عناصر وأسعار البيع",
  an_estimate: "التقدير هو اقتراح ترسله إلى عملائك",
  a_invoice: "الفاتورة هي مستند يشير إلى المبلغ الذي تدين به لعملائك.",
  tax: "ضريبة",
  created_history: "انشأ من قبل",
  size_file: "حجم الملف",
  last_name_required: "إسم العائلة مطلوب",
  contact_number_required: "رقم الاتصال مطلوب ",
  middle_name_required: "الإسم الأوسط مطلوب",
  first_name_required: "الإسم الأول مطلوب.",
  salution: "تحية",
  approve_invoice: "إجراء الموافقة / رفض الفواتير",
  account: "حساب",
  SignUp: "اشتراك",
  account_Details: "انظر تفاصيل حسابك هنا.",
  working_hours: " رفع طلبات التحميل من حساباتك الجارية بين الساعة 9 صباحًا و 5:30 صباحًا ستتم معالجة PM في أيام عمل البنك في نفس اليوم. الجميع  الطلبات التي أثيرت بعد الوقت المحدد ستنعكس فقط على   يوم عمل البنك التالي حسب ساعات عمل البنك",
  any_queries: "لأية استفسارات أخرى حول حسابك ، تواصل مع care@ippopay.in",

  import_365: "IMPS 24 * 7/365 يومًا",
  neft_sunday: "NEFT من 08:00 صباحًا إلى 06:00 مساءً (يوميًا ما عدا الثاني والرابع  أيام السبت والأحد وأعياد RBI)",
  payment_status: "تسديد الديون حالة",
  bulk_action: "الأكتين السائب",
  want_bulk: "هل أنت متأكد أنك تريد أن تجمع",
  receipt_due: "مستحق عند الاستلام",
  rtgs_sunday: "RTGS 08:00 صباحًا إلى 04:00 مساءً (يوميًا ما عدا الثاني والرابع أيام السبت والأحد وأعياد RBI)",
  account_transactions: "معاملات الحساب",

  manage_pay: "قم بإدارة ودفع جميع فواتير الخدمات العامة الخاصة بك على لوحة تحكم واحدة.",

  primary_accounts: "الحسابات   أساسي أولي",
  virtual_Accounts: "حسابات افتراضية",
  Est_mate: "التقديرات",
  advance_details: "تفاصيل مسبقة",
  purchase_ord: "أمر شراء",
  payment_date: "تاريخ الدفع",
  customer_notes: "ملاحظات الزبون",
  setup_payroll: "إعداد كشوف المرتبات",
  too: "ل",
  change: "يتغير",
  departure: "رحيل",
  card_kit_number: "رقم طقم أدوات البطاقات",
  reject_trip: "ارفض الرحلة",
  delete_trip: "حذف الرحلة",
  enter_current_pwd: "إدخل كلمة السر الحالية",
  new_pwd: "كلمة المرور الجديدة",
  change_pwd: "تغيير كلمة المرور",
  connect_ur_bank: "جارٍ ربط المصرف الذي تتعامل معه ...",
  confirm_new_pwd: "تأكيد كلمة المرور الجديدة",
  enter_new_pwd: "أدخل كلمة مرور جديدة",
  current_pwd: "كلمة السر الحالية",
  set_ur_pwd: "تعيين كلمة المرور الخاصة بك",
  please_create_new_pwd: " الرجاء إنشاء كلمة مرور جديدة أدناه. يجب ألا تقل كلمة المرور الجديدة عن 8 أحرف",
  bank_num: "بنك A / C لا",
  cheque_no: " رقمشيكات ",
  cheque_date_required: "تاريخ الشيك مطلوب.",
  less_amount: "الرجاء إدخال مبلغ أقل من أو يساوي المبلغ المستحق.",
  dd_type_required: "نوع DD مطلوب.",
  card_number_required: "رقم البطاقة مطلوب",
  bank_name_required: "اسم البنك مطلوب",
  wallet_service: "مزود خدمة المحفظة",
  wallet_service_required: "مزود خدمة المحفظة مطلوب",
  upi_id: "معرف UPI",
  upi_id_required: "معرف UPI مطلوب.",
  cheque_date: "تحقق من التاريخ",
  card_type_required: "نوع البطاقة مطلوب.",
  transaction_required: "معرف المعاملة مطلوب",
  dd_number: "رقم DD",
  dd_type: "نوع DD",
  dd_number_required: "رقم DD مطلوب.",
  payee_name: " اسم مدفوع له ",
  receiver_required: "اسم المتلقي مطلوب.",
  pan_number_required: "رقم PAN مطلوب",
  ifsc_code_required: "مطلوب رمز IFSC",
  bank_required: "اسم البنك مطلوب",
  payee_name_required: "اسم المدفوع لأمره مطلوب",
  transaction_date_required: "تاريخ المعاملة مطلوب",
  created_success: "تم إنشاؤها بنجاح",
  receiver_name: "اسم المتلقي",
  sight_demand_draft: "مشروع تحت الطلب البصر",
  time_demand_draft: "مشروع وقت الطلب",
  credit_Card: "بطاقة إئتمان",
  debit_Card: "دَينبطاقة",

  payment_pending: "انتظار الدفع",
  please_enter_amount: "مناشدات إدخال المبلغ",
  updated_success: "تم التحديث بنجاح",
  purchase: "أمر شراء",
  cash: "مال يدفع نقدا",
  sales_ord: "طلب المبيعات",
  BIL: "فاتورة",
  ESTIMATE: "التقديرا",
  SALES_ORDER: "طلب المبيعات",
  inv_inf: "معلومات الفاتورة",
  vendor_details: "تفاصيل البائع",
  reject_msg: "هل تريد رفض هذا",
  approve_bills: "إجراء الموافقة / رفض مشروعات القوانين",
  buyer_sell: "يصدر المشتري مستندًا إلى البائع معلومات حول العناصر التي يرغبون في شرائها",
  bill_document: "الفاتورة هي مستند يشير إلى المبلغ الذي تدين به للبائعين.",
  new_purchase: "شراء جديدة",
  new_bill: "مشروع قانون جديد",
  approve_msg: "هل تريد الموافقة على هذا",

  adavnce_see: "انظر التفاصيل الخاصة بك مسبقا هنا",
  payroll: "كشف رواتب",
  manage_payroll: "إدارة ومعالجة كشوف المرتبات.",
  trip_approval: "موافقات الرحلة",
  modules: "الوحدات",
  input_value: "الرجاء إدخال قيمة!",
  start_with: " بدء ا مع ",
  reminder_trip: "تنبيه تذكير للموظفين إذا كان",
  more_than: "قيد الانتظار لأكثر من",
  me: "أنا",
  BEFORE: "قبل",
  destination_country: "وجهة",
  is_visa_required: "هل تأشيرة الدخول مطلوبة",
  AFTER: "في ما بعد",
  CUSTOMER: "عميل",
  customer_and_me: "العملاء وأنا",
  taxes: "الضرائب",
  prefix: "بادئة",
  preferences: "التفضيلات",
  custom: "التخصيص",
  customize_expense_fields: "قم بتخصيص الحقول المطلوبة في إنشاء التقديرات وإعداد التفضيلات",
  configure_expense: "تكوين الحقول المطلوبة في نماذج إنشاء المصاريف وإعداد التفضيلات الأخرى.",
  configure_reports: "تكوين تفضيلات الإرسال والإعلام للتقارير وإعداد أنواع التقارير.",
  configure_advance: "تخصيص الحقول في طلبات مسبقة وإنشاء حالة مخصصة.",
  configure_trips: "تخصيص طلبات الرحلات وإنشاء حالات مخصصة للرحلات وإعداد التفضيلات.",
  customize_sale_fields: "قم بتخصيص الحقول التي تطلبها في إنشاء طلب المبيعات وإعداد التفضيلات.",
  customize_invoice_fields: "قم بتخصيص الحقول التي تطلبها في إنشاء الفاتورة وإعداد التفضيلات.",
  purchase_order_preference: "قم بتخصيص الحقول التي تطلبها في إنشاء أمر الشراء وإعداد التفضيلات.",
  bills_preference: "تخصيص الحقول تحتاج  في خلق مشروع قانونس ان    وإعداد التفضيلات",
  project_related: "أضف مشاريع الأعمال التي تتكبد نفقات لها ، حتى يتمكن المستخدمون من إقران المصروفات المتعلقة بالمشروع بها.",
  org_cost: "أضف المؤسسة الفرعية التي قد يتم تحميل التكاليف عليها لأغراض المحاسبة.",
  module_name: "اسم وحدة",
  org: "التنظيم",
  company_profile: "ملف الشركة",
  categories: "فئات",
  purchase_orders: "طلبات الشراء",
  expense: "نفقة",
  users_and_controls: "المستخدمين والضوابط",
  general: "عام",
  general_taxes: "الضرائب العامة",
  tds_slab: "أضف المختلف ألواح  المواد الصلبة الذائبة  لأي منهم    المبيعات والمشتريات  متورطون  في مؤسستك",
  purchase_tax: "أضف الضرائب المختلفة التي تدخل فيها المبيعات والشراء في مؤسستك.",
  sale_items: "أضف العناصر المقيدة للمبيعات والشراء في مؤسستك.",
  merchant_business: "أضف التجار الذين تتكبد لهم نفقات العمل حتى يتمكن المستخدمون من ربطهم بالنفقات الخاصة بهم.",
  items: "أغراض",
  invoice_create: "إنشاء الفاتورة وتحصيل المدفوعات مباشرة",
  sale_order_approvals: "موافقات أوامر المبيعات",
  estimate_approvals: "تقدير الموافقات",
  purchase_order_approvals: "الموافقات على أوامر الشراء",

  invoice_approvals: "الموافقات على الفاتورة",
  bill_approvals: "الموافقات مشروع قانون",
  select_role: "حدد الدور",
  my_advances: "  خاصتيسالسلفةس ",
  my_trips: " رحلات  خاصتيس",
  merchants: "التجار",
  projects: "المشاريع",
  execute_payments: "تنفيذ مدفوعات متعددة من خلال عملية واحدة.",
  access_transfer: "حالة الوصول ومعلومات عن جميع عمليات النقل المجمعة.",
  accept: "مسلم بصحته",
  active: "نشيط",
  not_activated: "غير مفعل",
  hot_list: "المدرجة الساخنة",
  temporary_hot_list: "القائمة الساخنة المؤقتة",
  not_assigned: "غيرمعتمد",
  grade_options: "يجب ألا تكون خيارات الدرجة/المستوى فارغة",
  provide_grade: "يرجى تقديم اسم الصف ",
  field: "غيط حقل",
  add_option: "إضافة خيار",
  field_name: "اسم الحقل",
  data_type: "نوع البيانات",
  text_box: "مربع الكتابة",
  unsubmitted_bills: "الفواتير غير المقدمة",
  unsubmitted_trip: "الرحلات التي لم يتم تقديمها",
  invoice_module: "وحدة الفاتورة",
  unsubmitted_purchase_order: "أوامر الشراء التي لم يتم تقديمها",
  unsubmitted_invoices: "الفواتير غير المقدمة",
  unsubmitted_sales_order: "أمر مبيعات لم يتم تقديمه",
  estimates_field_name: "تقديرات اسم الحقل",
  drop_down: "اسقاط",
  code: "شفرة",
  value: "قيمة",
  stock_sku: "SKU (وحدة حفظ المخزون)",
  services: "خدمات",
  cost_center_name: "اسم مركز التكلفة",

  label_name: "اسم الطابع",
  new_cost_center: "مركز تكلفة جديد",
  cost_center_code: "كود مركز التكلفة",
  edit_cost_center: "تحرير مركز التكلفة",
  cost_head: "رئيس مركز التكلفة",
  tax_name_required: "الاسم الضريبي مطلوب",
  unit_price_required: "سعر الوحدة مطلوب",
  hsn_code_required: "مطلوب رمز HSN",
  hsn_codes: "رمز HSN (نظام التسمية المنسق)",
  goods: "بضائع",
  sku_required: "SKU مطلوب",
  item_name_required: "اسم العنصر مطلوب",
  created: "مكون",
  edited: "تم تحريره",
  deleted_success: "حذف بنجاح",
  rate: "معدل",
  project_head: "رئيس المشاريع",
  reimburse_title: "السداد",
  can_perform: "يمكن أن يؤدي العمل على الجميع",
  created_byy: "انشأ من قبل:",
  by_submitted: "مقدم من",
  quick_transfers: "تحويلات سريعة",
  upload_bulk_payments: "تحميل المدفوعات بالجملة",
  grade_name: "اسم الدرجة",
  ok: "نعم",
  category_hash: "فئة#",
  enter_email_address: "أدخل عناوين البريد الإلكتروني",
  no_attachments_found: "لم يتم العثور على مرفقات",
  quantity: "مقدار",
  enter_rate: "أدخل السعر",
  reimbursement: "السداد",
  product_desc: "وصف المنتج:",
  product_price: "سعر المنتج:",
  product_rating: "تصنيف المنتج:",
  account_number_required: "رقم الحساب مطلوب",
  product_title: "عنوان المنتج:",
  transit: "عبور",
  manage_beneficiary: "تم إنشاء إدارة المستفيد",
  beneficiary_name_required: "اسم المستفيد مطلوب",

  rate_hash: "معدل#",
  tax_hash: "الاسم الضريبي #",
  term_name: "اسم المصطلح *",
  enter_source_apply: "أدخل مصدر التوريد",
  enter_number_days: "أدخل عدد الأيام",
  number_of_days: "عدد الأيام*",
  enter_term_name: "أدخل اسم المصطلح",
  term_list: "اسم المصطلح مطلوب",
  report_title: "عنوان التقرير",
  select_report: 'حدد تقرير',
  payment_add_terms: "أضف شروط الدفع",
  add_bulk_expense: "إضافة المصاريف بالجملة",
  create_borderless_account: "أنشئ حسابك المجاني بلا حدود",
  get_started: 'البدء',
  already_have_account: 'هل لديك حساب؟',
  logIn: 'تسجيل الدخول',
  legal_privacy: "القانونية والخصوصية",
  help_center: 'مركز المساعدة',
  doc_type: 'نوع الوثيقة',
  select_doc_type: "حدد نوع المستند",
  max_size: 'أكبر مقاس',
  select_max_size: "حدد الحد الأقصى للحجم",
  kb500: '',
  mb1: '',
  org_del_suc: "تم حذف المنظمة بنجاح",

  /// Integration ///
  integration: "التكامل",
  all_integrations: "جميع عمليات التكامل",
  integration_description: "قم بتوصيل التخلي مع نظام تخطيط موارد المؤسسات تخطيط موارد المؤسسات الخاص بك",
  edit_organisation: 'تحرير المنظمة',
  add_organisation: 'أضف منظمة',
  disconnect: 'قطع الاتصال',
  disconnected: 'انقطع الاتصال',
  disconnect_failed: "فشل قطع الاتصال.",
  owner_name: 'اسم المالك',
  owner_name_placeholder: 'أدخل اسم المالك',
  owner_name_required: 'لا يمكن أن يكون اسم المالك فارغًا',
  owner_email: 'البريد الإلكتروني للمالك',
  owner_email_placeholder: 'أدخل البريد الإلكتروني للمالك',
  owner_email_required: 'البريد الإلكتروني للمالك لا يمكن أن يكون فارغا',
  organisation_name_placeholder: 'أدخل اسم المنظمة',
  organisation_name_required: 'لا يمكن أن يكون اسم المنظمة فارغًا',
  okay: "تمام",
  pause: "يوقف",
  configure: 'تهيئة',
  deletePayment: 'هل أنت متأكد أنك تريد حذف الدفعة',
  deletePaymentLink: 'هل أنت متأكد أنك تريد حذف رابط الدفع؟',
  unit_slab: "Add the different Unit type for which Material Receipts, Delivery Challan and Items in your organization.",
  warehouse_des: "Add the different Warehouse for which Material Receipts, Delivery Challan and Items in your organization."
};
