import { useEffect, useState } from "react";
import {
  Button,
  Collapse, Form, Input, Select,
  Switch, Typography
} from "antd";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { cardPolicies, cardSetPolicies, cardupdateSetPolicies } from "../../../../actions/cards/cards";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import * as yup from 'yup'
import useScrollToError from "../../../../config/useScrollToError";
const { Panel } = Collapse;
//  import { validationRules } from '../../../utils';
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;


const ValidationSchema = yup.object().shape({
  name: yup.string().required("Policy name is required."),
  description: yup.string().required("Description is required"),
  defineConditions: yup.array().of(yup.object().shape({
    approval_type: yup.string().required(),
    approval_type_value: yup.string().required(),
    approval_type_condition: yup.string().required(),
  }))
})

const AddCards = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { from } = props;
  const cardPoliciesRes = useSelector((state) => state?.cards?.cardPolicies);
  const [chan_key, set_chan_key] = useState("employees");
  const initialValues = {
    name: "",
    description: "",
    pos: false,
    atm: false,
    ecom: false,
    ctls: false,
    channel_enabled: [
      {
        POS: false,
      },
      {
        ATM: false,
      },
      {
        ECOM: false,
      },
      {
        CTLS: false,
      }
    ],
    defineConditions: [
      {
        approval_type: "",
        approval_type_value: "",
        approval_type_condition: "",
      },
    ],
  }
  const formik = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (vals) => {
      let obj = JSON.parse(JSON.stringify({ ...vals }))
      if (vals[chan_key])

        obj.defineCriteria = [{
          field_data_type: "string",
          field_name: chan_key,
          field_name_display: chan_key === "cost_center" ? "Cost Center" : chan_key === "projects" ? "Projects" : chan_key === "employees" ? "Employees" : "Departments",
          field_value: vals[chan_key],
          field_operator: "for",
          field_priority: 1,
          field_condition: "when"
        }]
      obj.defineConditions = vals.defineConditions?.map((ele, ind) => ({
        ...ele,
        priority: ind + 1
      }))

      delete obj.channel_enabled
      if (obj[chan_key])
        delete obj[chan_key]

      if (props.selectedRow?.id) {
        obj.edit = true
        obj.card_policy_id = props.selectedRow?.id
        delete obj.card_policy_limit
        delete obj.file_status_url
        delete obj.id
        delete obj.status
        delete obj.assigned_data
        dispatch(cardupdateSetPolicies({ ...obj }))
      }
      else {
        dispatch(cardSetPolicies({ ...obj }));
      }
    },
  });
  // Use the custom hook
  useScrollToError(Object.keys(formik?.errors)[0], formik?.errors, formik?.submitCount);
  useEffect(() => {
    dispatch(cardPolicies({
      module_type: "EXPENSE"
    }));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (props.selectedRow && props.selectedRow?.id) {
      // let obj = {
      //   ...props.selectedRow,
      //   defineConditions: from === "card_detail" ? props.selectedRow?.defineConditions : props.selectedRow?.card_policy_limit ? props.selectedRow?.card_policy_limit : [],
      //   channel_enabled: from === "card_detail" ? props?.selectedRow?.channel_enabled : props?.selectedRow?.channel_data
      // }
      // if (props.selectedRow.assigned_data) {
      //   let chan_key1 = Object.keys(props.selectedRow.assigned_data)?.[0];
      //   if (chan_key1) {
      //     set_chan_key(chan_key1)
      //     obj[chan_key1] = props.selectedRow?.assigned_data?.[chan_key1]
      //   }
      // }
      let obj = {
        id: props?.selectedRow?.id,
        name: props?.selectedRow?.name,
        description: props?.selectedRow?.description,
        defineConditions: props?.selectedRow?.defineConditions?.map(ele => ({
          approval_type: ele?.approval_type,
          approval_type_value: ele?.approval_type_value,
          approval_type_condition: ele?.approval_type_condition
        })),
        pos: props?.selectedRow?.cardLimits?.pos,
        atm: props?.selectedRow?.cardLimits?.atm,
        ecom: props?.selectedRow?.cardLimits?.ecom,
        contact_less: props?.selectedRow?.cardLimits?.contact_less,
        [props?.selectedRow?.defineCriteria?.[0]?.field_name]: props?.selectedRow?.defineCriteria?.[0]?.field_value
      }
      formik.setValues(obj)
      set_chan_key(props?.selectedRow?.defineCriteria?.[0]?.field_name)
    } else {
      formik.resetForm()
    }
    // eslint-disable-next-line
  }, [props.selectedRow])

  const handleCancelButton = () => {
    // form.resetFields();
    formik.setValues({

    })
    props.handleCancel();
  };

  console.log({ res: props, chan_key: formik.values[chan_key], values: formik.values }, "cardResp==>");
  const otps = cardPoliciesRes?.data?.data?.[chan_key]?.map((ele) => ({ label: ele.title || ele.name || ele.email, value: ele.id }))
  return (
    <>
      <Form
        layout="vertical"
        // form={form}
        onFinish={formik.handleSubmit}
        className="modal-form"
      >
        <Form.Item label={t('policy_name')}
          help={formik.touched.name && formik.errors.name}
          required={true}
          validateStatus={formik.errors.name && formik.touched.name ? "error" : "success"}
        >
          <Input
            placeholder={t('default_policy')}
            onChange={formik.handleChange}
            name="name"
            value={formik.values.name}
          />
        </Form.Item>
        <Form.Item label={t('desc')} required={true}>
          <TextArea
            placeholder={t('enter_desc')}
            autoSize={{ minRows: 3, maxRows: 5 }}
            onChange={formik.handleChange}
            name="description"
            value={formik.values.description}
          // help={formik.touched.description && formik.errors.description}
          // validateStatus={formik.errors.description && formik.touched.description ? "error" : "success"}
          />
          {formik.touched.description && <div className="errorMsg">{formik.errors.description}</div>}
        </Form.Item>
        <div className="dividerText">{t('define_conditions')}</div>
        <Form.Item name="checkboxGroup" style={{ marginBottom: "5px" }}>
          <Collapse
            accordion
            className="customCollapse"
            defaultActiveKey={["1"]}
          >
            <Panel header={<b>{t('define_users')} ( {t('if_any')} )</b>} key="1">
              <Text>{t('choose_who_will')}</Text>
              <div className="viewPolicyCards">
                1<div className="darkbox">{t('rule_will')}</div>
                <div className="lightbox">
                  <Select
                    placeholder="Select "
                    onChange={(val) => {
                      set_chan_key(val)
                    }}
                    value={chan_key}
                  >
                    <Option value="employees">{t('specific_user')}</Option>
                    <Option value="departments">
                      {t('specific_grade')}
                    </Option>
                    <Option value="cost_center">{t('cost_center')}</Option>
                    <Option value="projects">{t('project')}</Option>
                  </Select>
                </div>
                <div className="lightbox">
                  <Select
                    placeholder="Select "
                    onChange={(val) => {
                      formik.setFieldValue(chan_key, val);
                      Array.from(["employees", "departments", "cost_center", "projects"]).forEach(ele => {
                        if (chan_key !== ele)
                          formik.setFieldValue(ele, undefined)
                      })
                    }}
                    value={otps?.find(ele => ele.value === formik.values[chan_key])}
                    showSearch
                    filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                    options={otps}
                  />

                </div>
              </div>
            </Panel>
          </Collapse>
        </Form.Item>
        <Form.Item name="checkboxGroup" style={{ marginBottom: "5px" }}>
          <Collapse
            accordion
            className="customCollapse"
            defaultActiveKey={["2"]}
          >
            <Panel header={<b>{t('card_usage_controls')} ( {t('if_any')} )</b>} key="2">
              <div className="card-usage">
                <div className="item">
                  <div>
                    <span>{t('pos')}</span>
                    <span></span>
                  </div>
                  <Switch
                    className="switch"
                    onChange={(e) => {
                      // let channels = formik.values.channel_enabled;
                      // channels[0].POS = e;
                      // formik.setFieldValue("channel_enabled", channels);
                      formik.setFieldValue("pos", e);
                    }}
                    // checked={formik.values?.channel_enabled?.[0]?.POS}
                    checked={formik.values?.pos}
                  />
                </div>
                <div className="item">
                  <div>
                    <span>{t('atm_withdraw')}</span>
                    <span></span>
                  </div>
                  <Switch
                    className="switch"
                    onChange={(e) => {
                      // let channels = formik.values.channel_enabled;
                      // channels[1].ATM = e;
                      // formik.setFieldValue("channel_enabled", channels);
                      formik.setFieldValue("atm", e);
                    }}
                    // checked={formik.values?.channel_enabled?.[1]?.ATM}
                    checked={formik.values?.atm}
                  />
                </div>
                <div className="item">
                  <div>
                    <span>{t('online_transactions')}</span>
                    <span></span>
                  </div>
                  <Switch
                    className="switch"
                    onChange={(e) => {
                      // let channels = formik.values.channel_enabled;
                      // channels[2].ECOM = e;
                      // formik.setFieldValue("channel_enabled", channels);
                      formik.setFieldValue("ecom", e);

                    }}
                    // checked={formik.values?.channel_enabled?.[2]?.ECOM}
                    checked={formik.values?.ecom}
                  />
                </div>
                <div className="item">
                  <div>
                    <span>{t('contact_less')}</span>
                    <span></span>
                  </div>
                  <Switch
                    className="switch"
                    onChange={(e) => {
                      // let channels = formik.values.channel_enabled;
                      // channels[3].CTLS = e;
                      // formik.setFieldValue("channel_enabled", channels);
                      formik.setFieldValue("ctls", e);
                    }}
                    // checked={formik.values?.channel_enabled?.[3]?.CTLS}
                    checked={formik.values?.ctls}
                  />
                </div>
              </div>
            </Panel>
          </Collapse>
        </Form.Item>
        <Collapse accordion className="customCollapse" defaultActiveKey={["3"]}>
          <Panel header={<b>{t('define_the_limit')}( {t('if_any')} )</b>} key="3">
            <Text>{t('create_limits')}</Text>
            {formik.values.defineConditions.map((ele, i) => (
              <>
                <div className="viewPolicyCards">
                  {i + 1}
                  <div className="darkbox">{t('when')}</div>
                  <Select
                    placeholder={t('select')}
                    onChange={(val) =>
                      formik.setFieldValue(`defineConditions[${i}].approval_type_condition`, val)
                    }
                    value={formik.values.defineConditions[i].approval_type_condition}
                  >
                    <Option value="DAILY">{t('daily_limit')}</Option>
                    <Option value="MONTHLY">{t('monthly_limit')}</Option>
                    <Option value="ANNUALLY">{t('yearly_limit')}</Option>
                  </Select>
                  <div className="darkbox">{t('for')}</div>
                  <Select
                    placeholder={t('select')}
                    onChange={(val) =>
                      formik.setFieldValue(
                        `defineConditions[${i}].approval_type`,
                        val
                      )
                    }
                    value={formik.values.defineConditions[i].approval_type}
                  >
                    <Option value="ATM">{t('atm')}</Option>
                    <Option value="POS">{t('pos')}</Option>
                    <Option value="ECOM">{t('online')}</Option>
                    <Option value="CTLS">{t('contact_less')}</Option>
                  </Select>
                </div>
                <div className="viewPolicyCards">
                  {t('Amount')}
                  <Input
                    name="approval_type_value"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `defineConditions[${i}].approval_type_value`,
                        e.target.value
                      );
                    }}
                    value={formik.values.defineConditions[i].approval_type_value}
                  />
                  {i > 0 && <MinusCircleOutlined className="ml3 cursor" onClick={() => {
                    let conditions = formik.values.defineConditions
                    conditions.splice(i, 1)
                    formik.setFieldValue("defineConditions", conditions)
                  }} />}
                </div>
              </>
            ))}
            <Button
              type="default"
              ghost
              className="approve-add-btn mt1"
              onClick={() => {
                formik.setFieldValue(`defineConditions`, [
                  ...formik.values.defineConditions,
                  {
                    approval_type: "",
                    approval_type_value: "",
                    approval_type_condition: "",
                  },
                ]);
              }}
            >
              {t('add_one_more')}
            </Button>
            <div className="errorMsg">{formik.touched.defineConditions && formik.errors.defineConditions?.length > 0 && t('card_limit_msg')}</div>
          </Panel>
        </Collapse>
        {/* <Collapse accordion className="customCollapse" defaultActiveKey={["4"]}>
          <Panel header={<b>Actions</b>} key="4">
            <Text>
              Select the actions you want to occur when the rule applies.
            </Text>
            <Checkbox>Blocking</Checkbox>
          </Panel>
        </Collapse> */}

        <Button
          key="1"
          htmlType="submit"
          className="formButton mt3 primaryButton"
        >
          {t('save')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt3"
          onClick={() => {
            handleCancelButton()
            formik.resetForm()
          }}
        >
          {t('cancel')}
        </Button>
      </Form>
    </>
  );
};

export { AddCards };

