import { InboxOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, message, Select, Upload } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { deleteEmployeeDocument } from "../../actions/employees";
import {
  ExpenseCustomDetails,
  expenseCustomDetails,
  prefixexpenseFields,
} from "../../actions/expenses/expenses";
import {
  AddUserReports,
  addUserReports,
  reports,
  userReport,
  userReportFields,
  userReportLinkExp,
} from "../../actions/reports";
import excel_icon from "../../assets/icons/excel1.png";
import pdf_icon from "../../assets/icons/pdf_icon.png";
import ppt_icon from "../../assets/icons/ppticon.png";
import { apis } from "../../config/APIs";
import useDocumentAxios from "../../config/useDocument";
import { getFileExtension, objectToFormData } from "../../utils";
import useScrollToError from "../../config/useScrollToError";
const { Dragger } = Upload;
const { Option } = Select;

const AddReport = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [validationSchema, setValidationSchema] = React.useState(null);
  const [image, setImage] = useState("");
  const [uploadDetails, setUploadDetails] = React.useState({});
  const [uploadData, setUploadData] = React.useState({});
  const { t } = useTranslation();

  const { onCall, loading } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
  });

  const validationSchema1 = yup.object().shape({
    title: yup
      .string()
      .matches(/^[a-zA-Z]+[a-zA-Z0-9\s]*$/, "Please Enter Proper Name")
      .required("Title is required"),
    from_date: yup.string().required("From Date is required"),
    to_date: yup.string().required("To Date is required"),
    description: yup.string().required("Description is Required"),
    ...validationSchema,
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      from_date: "",
      to_date: "",
      description: "",
    },
    initialValues: {},
    enableReinitialize: true,
    validationSchema: validationSchema1,
    onSubmit: (vals) => {
      let payload = { ...vals };
      if (props.report?.id) {
        const custom_fields = expenseCustomData.map(
          ({ field_id, column_name, column_type }) => {
            let master_custom_field_id = field_id;
            let module_name = "EXPENSE";
            let field_type = column_type;
            let value1 = formik.values[field_id];
            let value =
              column_type !== "datetime"
                ? value1
                : value1
                ? moment(value1).format("YYYY-MM-DD HH:mm:ss")
                : null;
            let field_name = column_name;
            delete payload?.[field_id];
            // return { id, value, field_name };
            if (column_type === "document") {
              let attachment_id = uploadDetails[field_id]?.attachmentId;
              return {
                master_custom_field_id,
                field_name,
                field_type,
                attachment_id,
                module_name,
              };
            } else if (value) {
              return {
                master_custom_field_id,
                value,
                field_name,
                field_type,
                module_name,
              };
            } else {
              return {
                master_custom_field_id,
                value,
                field_name,
                field_type,
                module_name,
              }
            }
          }
        );
        // payload["custom_fields"] = custom_fields.filter(Boolean) || [];
        payload["custom_fields"] = custom_fields || [];
      } else {
        const custom_fields = fields?.map(
          ({ id, column_name, column_type }) => {
            let master_custom_field_id = id;
            let module_name = "REPORT";
            let field_type = column_type;
            let value1 = formik.values[id];
            let value =
              column_type !== "datetime"
                ? value1
                : value1
                ? moment(value1).format("YYYY-MM-DD HH:mm:ss")
                : null;
            let field_name = column_name;
            delete payload?.[id];
            // return { id, value, field_name };
            if (column_type === "document" && value) {
              let attachment_id = uploadDetails[id]?.attachmentId;
              return {
                master_custom_field_id,
                field_name,
                field_type,
                attachment_id,
                module_name,
              };
            } else if (value) {
              return {
                master_custom_field_id,
                value,
                field_name,
                field_type,
                module_name,
              };
            }
          }
        );
        payload["custom_fields"] = custom_fields?.filter(Boolean) || [];
      }
      if (props.report?.id) {
        dispatch(
          addUserReports({
            ...payload,
            id: props?.report?.id,
            params: { module_type: "REPORT" },
          })
        );
      } else {
        dispatch(addUserReports({ ...payload }));
      }
    },
  });

  // Use the custom hook
  useScrollToError(
    Object.keys(formik?.errors)[0],
    formik?.errors,
    formik?.submitCount
  );
  const userReportFieldsResponse = useSelector(
    (state) => state.reports.userReportFields?.data || {}
  );

  const addUserReportsResponse = useSelector(
    (state) => state.reports.addUserReports || {}
  );

  const reportDetailsByIdResponse = useSelector(
    (state) => state.reports.userReportShow
  );

  const expenseCustomData = useSelector(
    (state) => state?.expenses?.expenseCustomDetails?.data?.data || []
  );

  const prefixResponse = useSelector(
    (state) => state.expenses?.prefixexpenseFields?.data?.["REPORT"]
  );

  const deleteEmployeeResponse = useSelector(
    (state) => state?.employees?.deleteEmployeeDocument
  );

  const { data: fields = [] } = userReportFieldsResponse;

  React.useEffect(() => {
    if (addUserReportsResponse?.data?.error == false) {
      message.success(
        <span className="messageText">
          {addUserReportsResponse.data?.errors ??
            addUserReportsResponse.data?.message}
        </span>
      );
      setImage([]);
      setUploadDetails({});
      setUploadData({});
      if (props.from === "EXPENSE")
        dispatch(
          reports({
            module_type: "REPORT",
            filters: { status: ["unsubmitted"] },
          })
        );
      else dispatch(reports({ module_type: "REPORT" }));
      dispatch({ type: ExpenseCustomDetails.RESET });
      dispatch({ type: AddUserReports.RESET });
      formik.resetForm({});
      props.handleCancel();
      if (location?.state?.expense_id) {
        const payload = {};
        payload.id = addUserReportsResponse?.data?.response?.report?.id;
        payload.transactions = [location?.state?.expense_id];
        dispatch(userReportLinkExp(objectToFormData({ ...payload })));
      }

      if (props.report?.id) {
        props.callOnMount && props.callOnMount();
      }
    }

    if (addUserReportsResponse?.data?.error) {
      message.error(
        <span className="messageText">
          {addUserReportsResponse.data?.errors ??
            addUserReportsResponse.data?.message}
        </span>
      );
      
      dispatch({
        type: AddUserReports.RESET,
      });
      dispatch({
        type: ExpenseCustomDetails.RESET,
      });
    }
    // eslint-disable-next-line
  }, [addUserReportsResponse]);

  React.useEffect(() => {
    if (prefixResponse) {
      formik.setFieldValue("report_number", prefixResponse);
    } else if (prefixResponse === false) {
      formik.setFieldError("report_number", "error");
    }
  }, [prefixResponse]);

  React.useEffect(() => {
    if (props.report?.id) {
      dispatch(
        userReport({
          module_type: "REPORT",
          report_id: props.report?.id,
          // is_admin: !location?.state?.user,
        })
      );
      dispatch(
        expenseCustomDetails({
          module_type: "REPORT",
          module_id: props.report?.id,
          custom_flag: true,
        })
      );
    } else {
      dispatch(
        userReportFields({
          module_type: "REPORT",
          custom_flag: true,
        })
      );
      dispatch(prefixexpenseFields({ params: { module_type: "REPORT" } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (fields && fields?.length) {
      let obj = {};
      let obj2 = {};
      fields.forEach((item) => {
        if (item.is_required) {
          obj2[item.id] = "";

          if (item.column_type === "document") {
            obj[item.id] = yup
              .array()
              .of(yup.string())
              .required(`${item.display_name} is required`);
          } else {
            obj[item.id] = yup
              .string()
              .required(`${item.display_name} is required`);
          }
        }
      });
      if (!props.report?.id)
        formik.setValues({
          ...formik.values,
          ...obj2,
        });
      setValidationSchema(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportFieldsResponse]);

  React.useEffect(() => {
    if (expenseCustomData && expenseCustomData?.length) {
      let obj = {};
      let obj2 = {};
      expenseCustomData?.forEach((item) => {
        if (item.is_required) {
          obj2[item.field_id] =
            location?.state?.expense?.[item?.field_id] ||
            formik.values[item.field_id] ||
            "";
          if (item.column_type === "document") {
            obj[item.field_id] = yup
              .array()
              .of(yup.string())
              .required(`${item.display_name} is required`);
          } else {
            obj[item.field_id] = yup
              .string()
              .required(`${item.display_name} is required`);
          }
        }
      });

      if (!location?.state?.isEdit) {
        Object.keys(obj2)?.map((ele) => {
          formik.setFieldValue(ele, obj2[ele]);
        });
        // formik.setValues({
        //   ...obj2,
        //   ...arr,
        // });
      }

      setValidationSchema(obj);
    }
  }, [expenseCustomData]);

  const handleCancelButton = () => {
    formik.resetForm();
    props.handleCancel();
    setImage([]);
  };

  React.useEffect(() => {
    const { data: { data: reportData = {}, error = "" } = {} } =
      reportDetailsByIdResponse;
    let obj = {};
    expenseCustomData.forEach((ele) => {
      if (ele.column_type === "document") {
        setUploadDetails((prevUploadDetails) => {
          return {
            ...prevUploadDetails,
            [ele.field_id]: {
              attachmentId: ele.attachments.map((ele) => ele.blob_id),
              recordId: ele.field_id,
            },
          };
        });
      }
    });

    expenseCustomData.forEach((ele) => {
      if (ele.column_type === "document") {
        setUploadData((b) => {
          return {
            ...b,
            [ele.field_id]: {
              attachmentId: ele.attachments.map((ele) => ele.blob_id),
              recordId: ele.field_id,
            },
          };
        });
      }
    });

    expenseCustomData?.forEach((each_custom_field) => {
      // obj[each_custom_field.field_id] = each_custom_field?.value;
      let blobids = each_custom_field?.attachments?.map((item) => item.blob_id);
      if (each_custom_field.column_type === "document") {
        blobids?.length
          ? formik.setFieldValue(each_custom_field?.field_id, blobids)
          : formik.setFieldValue(each_custom_field?.field_id, "");
        // formik.setFieldValue(each_custom_field?.field_id, blobids);
      } else {
        formik.setFieldValue(
          each_custom_field?.field_id,
          each_custom_field?.value
        );
      }
    });
    if (reportDetailsByIdResponse?.data?.error === false) {
      [
        "title",
        "from_date",
        "to_date",
        "description",
        "report_number",
      ]?.forEach((ele) => {
        if (ele === "from_date" || ele === "to_date") {
          formik.setFieldValue(ele, moment(reportData[`${ele}`]));
        } else {
          formik.setFieldValue(ele, reportData[`${ele}`]);
        }
      });
    }
  }, [reportDetailsByIdResponse]);

  React.useEffect(() => {
    if (props?.report?.id) {
      fields?.map((item) => {
        if (item?.field_type === "document" && item?.selected?.value) {
          const existImage = image?.findIndex(
            (eachImg) => eachImg.id === item.field_id
          );
          if (existImage !== -1) {
            setImage((prev) => {
              const updateImg = [...prev];
              updateImg[existImage].url = item?.selected?.value;
              return updateImg;
            });
          } else {
            setImage((b) => [
              ...b,
              { url: item?.selected?.value, id: item.field_id },
            ]);
          }
        }
      });
    }
  }, [fields]);

  const onDocumentFileChange = async (info, name) => {
    if (info.file.status === "removed") {
      reportDetailsByIdResponse?.data?.data?.id && info.file.blob_id
        ? dispatch(
            deleteEmployeeDocument({
              record_id: info?.file?.record_id,
              ids: [info?.file?.id],
            })
          )
        : dispatch(
            deleteEmployeeDocument({
              record_id: uploadData[info?.file?.uid].recordId,
              ids: [uploadData[info?.file?.uid].attachment_id],
            })
          );
      let recordfile = "";

      reportDetailsByIdResponse?.data?.data?.id && info.file.blob_id
        ? (recordfile = info.file.blob_id)
        : (recordfile = uploadData[info?.file?.uid].attachment_id);

      const data = {
        ...uploadDetails[name],
        attachmentId: uploadDetails[name].attachmentId.filter(
          (val) => val !== recordfile
        ),
      };

      setUploadDetails((b) => ({
        ...b,
        [name]: {
          attachmentId: [
            ...b[name].attachmentId.filter((val) => val !== recordfile),
          ],
        },
      }));
      formik.setFieldValue(
        name,
        data?.attachmentId?.length ? data?.attachmentId : ""
      );
    } else {
      const nextState = {};
      nextState.selectedFile = info.file;
      nextState.selectedFileList = [info.file];
      let uniqueNo = fields.id ? uploadDetails[name]?.recordId : uuidv4();
      const formData = new FormData();
      formData.append("files", info?.file);
      formData.append("name", info.file.name);
      formData.append("record_type", "Document");
      formData.append("record_id", uniqueNo);

      const attachId = await onCall({
        data: formData,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });

      setUploadDetails((b) => {
        if (Object.keys(b).includes(name)) {
          return {
            ...b,
            [name]: {
              ...b[name],
              attachmentId: [...b[name].attachmentId, attachId?.data?.[0]],
            },
          };
        } else {
          return {
            ...b,
            [name]: {
              attachmentId: [attachId?.data?.[0]],
            },
          };
        }
      });

      setUploadData((b) => {
        return {
          ...b,
          [info?.file?.uid]: {
            attachment_id: attachId?.data?.[0],
            recordId: uniqueNo,
          },
        };
      });
      if (uploadDetails[name] && uploadDetails[name].attachmentId) {
        formik.setFieldValue(name, [
          ...uploadDetails[name].attachmentId,
          attachId?.data?.[0],
        ]);
      } else {
        formik.setFieldValue(name, [attachId?.data?.[0]]);
      }
    }
  };

  const handleChange = (val, id) => {
    formik.setFieldValue(id, val);
  };

  const renderDataTypes = (
    value,
    default_values,
    id,
    column_name,
    attachments,
    attachmentType,
    attachment_size
  ) => {
    switch (value) {
      case "string":
        return (
          <>
            <Input
              placeholder={t("enter_val")}
              value={formik.values?.[id] ? formik.values?.[id] : ""}
              style={{ minWidth: "216px" }}
              onChange={(e) => {
                handleChange(e.target.value.trimStart(), id);
              }}
              disabled={id === "report_number"}
            />
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "integer":
        return (
          <>
            <Input
              placeholder={t("enter_val")}
              type="text"
              value={formik.values?.[id] ? formik.values?.[id] : ""}
              style={{ minWidth: "216px" }}
              onChange={(e) => {
                if (Number.isNaN(Number(e.target.value))) {
                  return;
                }
                handleChange(e.target.value.trimStart(), id);
              }}
            />
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "dropdown":
        return (
          <>
            <Select
              style={{ width: 200 }}
              placeholder="Select"
              value={formik.values?.[id]}
              onChange={(val) => {
                handleChange(val, id);
              }}
            >
              {default_values &&
                default_values.map((item) => (
                  <Option key={id} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "datetime":
        return (
          <>
            <DatePicker
              value={
                formik.values?.[id]
                  ? moment(formik.values?.[id], ["YYYY-MM-DD HH:mm:ss"])
                  : null
              }
              onChange={(date, dateString) => {
                handleChange(dateString, id);
              }}
              showTime
              id={id}
            />
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "document":
        return (
          <>
            <Dragger
              name="document"
              beforeUpload={(file) => {
                const fileName = getFileExtension(file?.name);
                const maxSize = attachment_size;
                const allowedImages = attachmentType.split(",");
                const uppercaseImages = allowedImages.map((image) =>
                  image.trim().toUpperCase()
                );
                const imagetype = uppercaseImages.join(",  ");
                if (!allowedImages?.includes(fileName)) {
                  message.error(
                    <span className="messageText">
                      You can only upload {imagetype}.
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                } else if (file.size > maxSize * 1024) {
                  message.error(
                    <span className="messageText">
                      File size must be smaller than {attachment_size} KB
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
              defaultFileList={attachments}
              listType="picture"
              onChange={(evt) => onDocumentFileChange(evt, id)}
              disabled={loading || deleteEmployeeResponse.loading}
              // value = {formik.values?.[id] ? formik.values?.files?.id:""}
            >
              <p className="ant-upload-text">
                <InboxOutlined />
                {t("drag_attachment")}
                <br />
                {t("click_here_to")}
              </p>

              {/* <p className="ant-upload-text"><InboxOutlined />{t('drag_png')}<br />{t('click_here_to')}</p> */}
            </Dragger>
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      default:
      // code block
    }
  };

  return (
    <>
      <form
        layout="vertical"
        className="modal-form"
        onSubmit={formik.handleSubmit}
      >
        <div className="nameItem">
          <label htmlFor="report_number" className="inline-block required">
            Report Id
          </label>
          <Input
            placeholder={t("enter_val")}
            value={formik.values?.report_number}
            style={{ minWidth: "216px" }}
            onChange={(e) => handleChange(e.target.value, "title")}
            disabled={true}
          />
          <div className="error">
            {formik.errors?.report_number && (
              <span style={{ color: "red" }}>
                Please specify the prefix value in reports customization
              </span>
            )}
          </div>
        </div>
        <div className="nameItem">
          <label htmlFor="title" className="inline-block required">
            Report Title
          </label>
          <Input
            placeholder={t("enter_val")}
            value={formik.values?.title}
            style={{ minWidth: "216px" }}
            onChange={(e) => handleChange(e.target.value, "title")}
          />
          <div className="error">
            {formik.submitCount > 0 && formik.errors?.title}
          </div>
        </div>
        <div className="nameItem">
          <label htmlFor="transaction_date" className="inline-block required">
            From Date
          </label>
          <DatePicker
            placeholder={t("enter_val")}
            value={formik.values?.from_date}
            style={{ minWidth: "30rem" }}
            onChange={(evt) => {
              handleChange(evt, "from_date");
            }}
            showTime
            id="from_date"
          />
          <div className="error">
            {formik.submitCount > 0 && formik.errors?.from_date}
          </div>
        </div>
        <div className="nameItem">
          <label htmlFor="to_date" className="inline-block required">
            To Date
          </label>
          <DatePicker
            placeholder={t("enter_val")}
            value={formik.values?.to_date}
            style={{ minWidth: "30rem" }}
            onChange={(evt) => {
              handleChange(evt, "to_date");
            }}
            showTime
            disabledDate={(current) => {
              if (formik.values?.from_date)
                return (
                  current &&
                  current < moment(formik.values?.from_date, "DD/MM/YYYY")
                );
            }}
            id="to_date"
          />
          <div className="error">
            {formik.submitCount > 0 && formik.errors?.to_date}
          </div>
        </div>
        <div className="nameItem">
          <label htmlFor="description" className="inline-block required">
            Description
          </label>
          <Input
            placeholder={t("enter_val")}
            value={formik.values?.description}
            style={{ minWidth: "216px" }}
            onChange={(e) => handleChange(e.target.value, "description")}
          />
          <div className="error">
            {formik.submitCount > 0 && formik.errors?.description}
          </div>
        </div>
        {props?.report?.id
          ? expenseCustomData &&
            expenseCustomData.map((item) => (
              <div className="nameItem">
                <label
                  className={`inline-block ${
                    item.is_required ? "required" : ""
                  }`}
                >
                  {item.display_name}
                </label>
                <br />
                {renderDataTypes(
                  item.column_type,
                  item.default_values,
                  item.field_id,
                  item.column_name,
                  item.attachments?.map((ele) => ({
                    ...ele,
                    url: ele.attachment_url,
                    key: ele.blob_id,
                    name: ele.filename,
                    thumbUrl: ele?.content_type?.includes("image/")
                      ? null
                      : ele.content_type === "application/pdf"
                      ? pdf_icon
                      : ele?.content_type ===
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                      ? ppt_icon
                      : excel_icon,
                  })),
                  item.data.doc_formats,
                  item.data.max_size
                )}
              </div>
            ))
          : fields &&
            fields.map((item) => (
              <div className="nameItem">
                <label
                  className={`inline-block ${
                    item.is_required ? "required" : ""
                  }`}
                >
                  {item.display_name}
                </label>
                <br />

                {renderDataTypes(
                  item.column_type,
                  item.default_values,
                  item.id,
                  item.column_name,
                  item.attachment,
                  item.data.doc_formats,
                  item.data.max_size
                )}
              </div>
            ))}
        <div>
          <Button
            key="1"
            htmlType="submit"
            className="formButton mt3 primaryButton"
            disabled={loading || addUserReportsResponse?.loading}
          >
            {t("Save")}
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt3 "
            onClick={() => handleCancelButton()}
          >
            {t("cancel")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddReport;
