import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Modal, Select, Switch, Typography } from "antd";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  AnalyticsCardsExport,
  analyticsEstimates,
  analytics_cards,
} from "../../actions/cards/cards";
import cross from "../../assets/icons/cross_filled.svg";
import file from "../../assets/images/file.png";
import { amountFormat, transformFilters, valueMapping } from "../../utils";
import FilterInputs from "./FilterInputs";

import {
  advance_keys,
  bills_keys,
  card_keys,
  estimates_keys,
  expense_keys,
  invoices_keys,
  payment_made_keys,
  payment_received_keys,
  purchases_orders_keys,
  report_keys,
  sales_order_keys,
  trip_keys,
} from "./AnalyticsKeys";

const { Title } = Typography;

const Reports = ({
  setShowGraph,
  setFilterObj,
  filters,
  apiFilters,
  setFilters,
  activeNav,
  setAgingValue,
  pdfURL,
  csvData,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [keys, setKeys] = useState([]);
  const [from, setFrom] = useState("summary");
  const [isAll, setIsAll] = useState({});
  const [selectedVals, setSelectedVals] = useState([0]);
  const [selectedType, setSelectedType] = useState({});
  const [selectedFil, setSelectedFil] = useState({});
  const [selectExport, setSelectExport] = useState(null);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isSalesPurchases, setIsSalesPurchases] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const [aging, setAging] = React.useState("");
  const [type1, setType1] = React.useState("");
  const [inputError, setInputError] = useState("");
  const [selectFilterObj, setSelectFilterObj] = useState({});
  const [numberSelectFilterObj, setNumberSelectFilterObj] = useState({});
  const [before_filters, setBeforeFilters] = useState({});
  const [before_api_filters, setBeforeAPIFilters] = useState({});

  const cards_export = useSelector(
    (state) => state.analytics.analytics_cards_export
  );
  const cards = useSelector((state) => state.analytics.analytics_cards);
  const estimate_data = useSelector((state) => state.cards.analytics_estimates);
  // const [exportOptions] = useExport(cards_export);
  // useEffect(() => {
  //   if (cards?.success || estimate_data?.success) {
  //     setIsModalVisible(false);
  //   }
  // }, [cards, estimate_data]);
  useEffect(() => {
    if (Object.keys(apiFilters).length === 0) {
      setSelectedVals([0]);
      setSelectedFil({});
      setSelectedType({});
    }
  }, [apiFilters]);

  useEffect(() => {
    if (
      location?.pathname?.includes("estimates") ||
      location?.pathname?.includes("salesorders") ||
      location?.pathname?.includes("purchasesorders") ||
      location?.pathname?.includes("bills") ||
      location?.pathname?.includes("invoices") ||
      location?.pathname?.includes("paymentmade") ||
      location?.pathname?.includes("paymentsreceived") ||
      location?.pathname?.includes("invoice_aging") ||
      location?.pathname?.includes("bills_aging")
    ) {
      setIsSalesPurchases(true);
    } else {
      setIsSalesPurchases(false);
    }
    setAging("Select Aging");
    let type = "";

    const pathname = location?.pathname;

    if (pathname.includes("estimates")) {
      type = "estimates";
    } else if (pathname.includes("salesorders")) {
      type = "sales_order";
    } else if (pathname.includes("purchasesorders")) {
      type = "purchase_order";
    } else if (pathname.includes("bills") && !pathname.includes("bills_aging")) {
      type = "bills";
    } else if (pathname.includes("invoices")) {
      type = "invoices";
    } else if (pathname.includes("paymentmade")) {
      type = "payment_made";
    } else if (pathname.includes("paymentsreceived")) {
      type = "payments_received";
    } else if (pathname.includes("invoice_aging")) {
      type = "invoice_aging";
    } else if (pathname.includes("bills_aging")) {
      type = "bills_aging";
    }
    setType1(type);
    if (Object.keys(apiFilters).length > 0) {
      setSelectedVals([0]);
      setSelectedFil({});
      setSelectedType({});
      setFilters({});
    }
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      dispatch({
        type: AnalyticsCardsExport.RESET,
      });
    };
    // eslint-disable-next-line
  }, []);

  const labels = {
    summary: {
      a: "Total Spend",
      b: "Cards",
      c: "Reimbursements",
      d: "Pending Reimbursements",
    },
    cards: {
      a: "Total Transaction Amount",
      b: "Available Balance on Cards",
      c: "Reported",
      d: "Unreported",
    },
  };

  const runReport = (fromRemove, reset) => {
    // Handle run filters
    const otherObj = {};
    if (location.pathname?.includes("cards")) {
      otherObj.card_only = true;
    }
    // Initialize options object
    const options = {
      filters,
      ...otherObj,
    };
    if (reset) {
      setFilterObj({});
      setSelectedType({});
      setSelectedVals([0]);
      setSelectedFil({});
      setIsAll({});
      setSelectFilterObj({});
      setNumberSelectFilterObj({});
      setInputError("");
      setFilters({});
    } else {
      if (!fromRemove) {
        setFilters(filters);
        setBeforeAPIFilters(JSON.parse(JSON.stringify(filters)));
      }
    }
    // Determine module name and type based on pathname
    if (location.pathname) {
      if (location.pathname.includes("estimates")) {
        options.module_name = "ESTIMATE";
        options.type = "analytics_invoices";
      } else if (location.pathname.includes("salesorders")) {
        options.module_name = "SALES_ORDER";
        options.type = "analytics_invoices";
      } else if (location.pathname.includes("bills")) {
        options.params = { module_type: "BILL" };
        if (location.pathname.includes("bills_aging")) {
          options.aging = aging;
        }
      } else if (location.pathname.includes("purchasesorders")) {
        options.params = { module_type: "PURCHASE_ORDER" };
      } else if (
        location.pathname.includes("invoices") ||
        location.pathname.includes("invoice_aging")
      ) {
        options.module_name = "INVOICE";
        options.type = "analytics_invoices";
        if (location.pathname.includes("invoice_aging")) {
          options.aging = aging;
        }
      } else if (location.pathname.includes("paymentmade")) {
        options.type = "analytics_payment_made";
      } else if (location.pathname.includes("paymentsreceived")) {
        options.type = "analytics_payment_received";
      }
    }
    if (!fromRemove) {
      if (
        location.pathname?.includes("cards") ||
        location.pathname.includes("expenses") ||
        location.pathname.includes("trips") ||
        location.pathname.includes("reports") ||
        location.pathname.includes("advances")
      ) {
        // Dispatch action based on the determined options
        let obj1 = {
          type: location.pathname.includes("expenses")
            ? "expenses"
            : location.pathname.includes("trips")
              ? "trips"
              : location.pathname.includes("reports")
                ? "reports"
                : location.pathname.includes("advances")
                  ? "advances"
                  : "cards",
        };
        if (!reset) {
          obj1 = { ...options, ...obj1 };
        }
        dispatch(analytics_cards(obj1));
      } else {
        dispatch(analyticsEstimates(reset ? {} : options));
      }
    }
    handleRunFilters(fromRemove);
  };

  const handleCancel = () => {
    setFilters(before_filters?.filters);
    setFilterObj(before_filters?.filtersObj);
    setSelectedVals(before_filters?.selectedVals);
    setSelectedType(before_filters?.selectedType);
    setSelectedFil(before_filters?.selectedFil);
    setBeforeFilters({});
    setIsModalVisible(false);
  };
  const handleRunFilters = (bool) => {
    setIsFilterApplied(true);
    if (!bool) setIsModalVisible(false);
    setInputError("");
  };

  useEffect(() => {
    if (location?.pathname?.includes("cards")) {
      setFrom("cards");
      setKeys(card_keys);
    }

    if (location?.pathname?.includes("reports")) setKeys(report_keys);
    if (location?.pathname?.includes("advances")) setKeys(advance_keys);
    if (location?.pathname?.includes("trips")) setKeys(trip_keys);
    if (location?.pathname?.includes("expenses")) setKeys(expense_keys);
    if (location?.pathname?.includes("estimates")) setKeys(estimates_keys);
    if (location?.pathname?.includes("salesorders")) setKeys(sales_order_keys);
    if (location?.pathname?.includes("purchasesorders"))
      setKeys(purchases_orders_keys);
    if (location?.pathname?.includes("bills") || location?.pathname?.includes("bills_aging")) setKeys(bills_keys);
    if (location?.pathname?.includes("paymentmade")) setKeys(payment_made_keys);
    if (location?.pathname?.includes("paymentsreceived"))
      setKeys(payment_received_keys);
    if (location?.pathname?.includes("invoices") || location?.pathname?.includes("invoice_aging")) setKeys(invoices_keys);
    // eslint-disable-next-line
  }, [location.pathname]);

  const getKeys = (index) => {
    let cKeys = [];
    if (index - 1 !== selectedFil?.[index]?.[1]) {
      cKeys = keys.filter(
        (ele) =>
          !Object.values(selectedFil)
            .map((ele) => ele[0])
            .includes(ele.value)
      );
      let cKey = keys.find((ele) => selectedFil?.[index]?.[0] === ele.value);
      if (cKey) cKeys.push(cKey);
    } else {
      cKeys = keys.filter(
        (ele) =>
          !Object.values(selectedFil)
            .map((ele) => ele[0])
            .includes(ele.value)
      );
      let cKey = keys.find((ele) => selectedFil?.[index]?.[0] === ele.value);
      if (cKey) cKeys.push(cKey);
    }

    return cKeys;
  };

  const mapValues = (ele) => {
    if (valueMapping?.hasOwnProperty(ele) || valueMapping?.hasOwnProperty(`${type1}_${ele}`)) {
      return (valueMapping[type1 + "_" + ele] || valueMapping[ele]) + " - ";
    } else {
      return ele?.replace(/_/g, " ") + " - ";
    }
  };

  function handleMenuClick(e) {
    const agingMap = {
      1: { range: "All", value: "" },
      2: { range: "1-10", value: "1-10" },
      3: { range: "11-25", value: "11-25" },
      4: { range: "26-45", value: "26-45" },
      5: { range: ">45", value: ">45" },
    };

    const selectedAging = agingMap[e.key];

    if (selectedAging) {
      setAging(selectedAging.range);
      setAgingValue(selectedAging.value);

      const analyticsParams = {
        type: "analytics_invoices",
        module_name: "INVOICE",
        aging: selectedAging.value,
      };

      const billParams = {
        params: { module_type: "BILL" },
        aging: selectedAging.value,
      };

      if (location?.pathname?.includes("invoice_aging")) {
        dispatch(analyticsEstimates({ ...analyticsParams }));
      } else {
        dispatch(analyticsEstimates({ ...billParams }));
      }
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">All</Menu.Item>
      <Menu.Item key="2">1 - 10</Menu.Item>
      <Menu.Item key="3">11 - 25</Menu.Item>
      <Menu.Item key="4">26 - 45</Menu.Item>
      <Menu.Item key="5">{">45"}</Menu.Item>
    </Menu>
  );

  return (
    <div className="reports">
      <div className="w100">
        <div className="flex-between m1">
          <div>
            <span
              className="customize ml1"
              onClick={() => {
                setIsModalVisible(true);
                setBeforeAPIFilters(JSON.parse(JSON.stringify(apiFilters)));
                setBeforeFilters(
                  JSON.parse(
                    JSON.stringify({
                      filters: JSON.parse(JSON.stringify(apiFilters)),
                      selectedVals,
                      filtersObj: filters,
                      selectedFil,
                      selectedType,
                    })
                  )
                );
              }}
            >
              Customize
            </span>
          </div>
          <div
            className="d-flex mr1"
            style={{
              alignItems: "center",
            }}
          >
            {!location?.pathname?.includes("reports") &&
              !location?.pathname?.includes("cards") &&
              !location?.pathname?.includes("trips") &&
              !location?.pathname?.includes("expenses") &&
              !location?.pathname?.includes("advances") &&
              !location?.pathname?.includes("estimates") &&
              !location?.pathname?.includes("salesorders") &&
              !location?.pathname?.includes("invoices") &&
              !location?.pathname?.includes("paymentsreceived") &&
              !location?.pathname?.includes("purchasesorders") &&
              !location?.pathname?.includes("bills") &&
              location?.pathname?.includes("invoice_aging") &&
              location?.pathname?.includes("bills_aging") && (
                <div className="mr2">
                  <label className="mr1">Show Graph</label>
                  <Switch
                    className="switch"
                    onChange={(e) => {
                      setShowGraph(e);
                    }}
                  />
                </div>
              )}
            <div
              className="d-flex mr1"
              style={{
                alignItems: "center",
              }}
            >
              {location?.pathname?.includes("invoice_aging") ||
                location?.pathname?.includes("bills_aging") ? (
                <div>
                  <label>Aging Filter : </label>
                  <Dropdown overlay={menu} placement="bottomRight">
                    <button className="more">
                      {aging} &nbsp;
                      <DownOutlined />
                    </button>
                  </Dropdown>
                </div>
              ) : null}
            </div>
            <div className="mr1">
              <Select
                placeholder="Export as"
                options={[
                  {
                    label: "PDF",
                    value: "PDF",
                  },
                  {
                    label: "CSV",
                    value: "CSV",
                  },
                ]}
                className="exportAs"
                value={selectExport}
                notFoundContent={cards_export?.loading ? "Loading..." : ""}
                onChange={(e) => {
                  setSelectExport(e);
                  if (e === "CSV") {
                    csvData?.current?.(filters, aging);
                    setSelectExport([]);
                  } else {
                    pdfURL?.current?.(filters, aging);
                    setSelectExport([]);
                  }
                }}
              />
            </div>
          </div>
        </div>

        {isFilterApplied
          ? Object.keys(apiFilters).length > 0 && (
            <div className="ml2">
              <div
                className="filterAppliedContainer d-flex spacing mt1 mb1"
                style={{
                  alignItems: "center",
                }}
              >
                <div className="filterBlock">
                  <span className="filterTxt">Filters applied :</span>
                  {Object.keys(before_api_filters).map((ele, index) => (
                    <span className="filterBtn" key={ele}>
                      <span className="filterItem capitalize">
                        {mapValues(ele)}
                        {isAll[ele]
                          ? "All"
                          : Array.isArray(before_api_filters[ele])
                            ? before_api_filters[ele]
                              ?.map(
                                (value) =>
                                  valueMapping[type1 + "_" + value] ||
                                  valueMapping[value] ||
                                  value
                              )
                              .join(", ")
                            : JSON.stringify(
                              transformFilters(
                                before_api_filters[ele],
                                type1
                              ),
                              null,
                              0
                            )?.replace(/^{|}$/g, "")}
                      </span>
                      <img
                        src={cross}
                        alt="cross"
                        onClick={(e) => {
                          e.stopPropagation()
                          let obj = { ...filters };
                          let numberSelect = { ...numberSelectFilterObj };
                          let dateSelect = { ...selectFilterObj };
                          if (isAll[ele]) {
                            let newIsAll = { ...isAll };
                            delete newIsAll[ele];
                            setIsAll(newIsAll);
                          }
                          if (
                            typeof filters[ele] === "object" &&
                            filters[ele] !== null
                          ) {
                            delete obj[ele];
                            delete numberSelect[ele];
                            delete dateSelect[ele];
                            setSelectFilterObj(dateSelect);
                            setNumberSelectFilterObj(numberSelect);
                            setFilterObj(obj);
                          } else {
                            delete obj[ele];
                            delete numberSelect[ele];
                            delete dateSelect[ele];
                            setSelectFilterObj(dateSelect);
                            setNumberSelectFilterObj(numberSelect);
                            setFilterObj(obj);
                          }
                          let arr = [...selectedVals];
                          arr.splice(index, 1);
                          arr = arr.map((_e, i) => i);
                          delete selectedFil[index];
                          let sortedArray = Object.entries(selectedFil).sort(
                            (a, b) => a[1][1] - b[1][1]
                          );
                          let sortedObj = {};
                          sortedArray.forEach(([key, value], index) => {
                            sortedObj[index] = [value[0], index];
                          });
                          setSelectedFil(sortedObj);
                          setSelectedVals(arr);
                          let otherObj = {};
                          setFilters(obj);
                          setBeforeAPIFilters(obj);
                          if (location.pathname?.includes("cards")) {
                            otherObj.card_only = true;
                          }
                          const getPageType = () => {
                            const pathname = location.pathname;
                            if (pathname.includes("estimates"))
                              return {
                                module_name: "ESTIMATE",
                                type: "analytics_invoices",
                              };
                            if (pathname.includes("salesorders"))
                              return {
                                module_name: "SALES_ORDER",
                                type: "analytics_invoices",
                              };
                            if (pathname.includes("bills"))
                              return { params: { module_type: "BILL" } };
                            if (pathname.includes("purchasesorders"))
                              return {
                                params: { module_type: "PURCHASE_ORDER" },
                              };
                            if (pathname.includes("invoices"))
                              return {
                                module_name: "INVOICE",
                                type: "analytics_invoices",
                              };
                            if (pathname.includes("paymentmade"))
                              return { type: "analytics_payment_made" };
                            if (pathname.includes("paymentsreceived"))
                              return { type: "analytics_payment_received" };
                            if (pathname.includes("invoice_aging"))
                              return {
                                module_name: "INVOICE",
                                type: "analytics_invoices",
                                aging,
                              };
                            return {
                              type: pathname.includes("expenses")
                                ? "expenses"
                                : pathname.includes("trips")
                                  ? "trips"
                                  : pathname.includes("reports")
                                    ? "reports"
                                    : pathname.includes("advances")
                                      ? "advances"
                                      : "cards",
                            };
                          };
                          dispatch(
                            analyticsEstimates({
                              filters: { ...obj, ...otherObj },
                              ...getPageType(),
                            })
                          );
                        }}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>
          )
          : null}
      </div>

      {!location?.pathname?.includes("reports") &&
        !location?.pathname?.includes("cards") &&
        !location?.pathname?.includes("trips") &&
        !location?.pathname?.includes("expenses") &&
        !location?.pathname?.includes("advances") &&
        !location?.pathname?.includes("estimates") &&
        !location?.pathname?.includes("salesorders") &&
        !location?.pathname?.includes("invoices") &&
        !location?.pathname?.includes("paymentsreceived") &&
        !location?.pathname?.includes("purchasesorders") &&
        !location?.pathname?.includes("bills") &&
        !location?.pathname?.includes("paymentmade") &&
        !location?.pathname?.includes("invoice_aging") &&
        !location?.pathname?.includes("bills_aging") && (
          <div className="p1 pl4 d-flex">
            <div className="v-center w25">
              <img src={file} alt="file" width={20} height={24} />
              <div className="spend-card">
                <span className="title">{labels[from]?.a}</span>
                <span className="amt">Rs. {amountFormat(66900)}</span>
                <span className="sub">{`(1400 Expenses)`}</span>
              </div>
            </div>
            <div className="v-center w25">
              <img src={file} alt="file" width={20} height={24} />
              <div className="spend-card">
                <span className="title">{labels[from]?.b}</span>
                <span className="amt">Rs. {amountFormat(95000)}</span>
                <span className="sub">{`(1400 Expenses)`}</span>
              </div>
            </div>
            <div className="v-center w25">
              <img src={file} alt="file" width={20} height={24} />
              <div className="spend-card">
                <span className="title">{labels[from]?.c}</span>
                <span className="amt">Rs. {amountFormat(150268)}</span>
                <span className="sub">{`(1400 Expenses)`}</span>
              </div>
            </div>
            <div className="v-center w25">
              <img src={file} alt="file" width={20} height={24} />
              <div className="spend-card">
                <span className="title">{labels[from]?.d}</span>
                <span className="amt">Rs. {amountFormat(52132)}</span>
                <span className="sub">{`(1400 Expenses)`}</span>
              </div>
            </div>
          </div>
        )}
      {isModalVisible && (
        <Modal
          title={
            <Title level={4}>
              Customize&nbsp;
              <span className="text-small">{location?.state?.subNav}</span>
            </Title>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[]}
          className="right-alinged-modal customized-wrap"
        >
          <div className="mb2 flex-between">
            <div>
              <button className="filter-btn active mr1">Filters</button>
              {/* <button className="filter-btn">Show/Hide Columns</button> */}
            </div>
            <div>
              <span
                className="customize"
                onClick={() => {
                  runReport(false, true);
                }}
              >
                Reset Filters
              </span>
            </div>
          </div>
          <div className="mb2">
            {selectedVals.map((ev, i) => (
              <div
                className="mb2 d-flex fil-inputs"
                style={{ alignItems: "center" }}
              >
                <Select
                  className="w25"
                  placeholder="Select"
                  options={getKeys(i)}
                  showSearch
                  onChange={(e) => {
                    setSelectedType({
                      ...selectedType,
                      [e]: keys.find((ele) => ele.value === e).type,
                    });
                    setSelectedFil({
                      ...selectedFil,
                      [i]: [e, i],
                    });
                  }}
                  value={selectedFil?.[i]?.[0]}
                />
                <FilterInputs
                  inputError={inputError}
                  setInputError={setInputError}
                  filters={selectedType}
                  setFilterObj={setFilterObj}
                  isAll={isAll}
                  setIsAll={setIsAll}
                  selectFilterObj={selectFilterObj}
                  setSelectFilterObj={setSelectFilterObj}
                  numberSelectFilterObj={numberSelectFilterObj}
                  setNumberSelectFilterObj={setNumberSelectFilterObj}
                  filtersObj={filters}
                  takeKey={activeNav === "sales" || activeNav === "purchases"}
                  ele={selectedFil?.[i]?.[0]}
                  isSalesPurchases={isSalesPurchases}
                  index={i}
                  remove={(idx) => {
                    let arr = [...selectedVals];
                    arr.splice(idx, 1);
                    arr = arr.map((_e, i) => i);
                    delete selectedFil[i];
                    let sortedArray = Object.entries(selectedFil).sort(
                      (a, b) => a[1][1] - b[1][1]
                    );
                    let sortedObj = {};
                    sortedArray.forEach(([key, value], index) => {
                      sortedObj[index] = [value[0], index];
                    });

                    setSelectedFil(sortedObj);
                    setSelectedVals(arr);
                    runReport(true);
                  }}
                  customized_data={
                    isSalesPurchases
                      ? estimate_data?.data?.customized_data
                      : cards?.data?.customized_data
                  }
                />
              </div>
            ))}
            <button
              className="add-btn bgWhite"
              onClick={() => {
                let arr = [...selectedVals];
                arr.push(arr.length);
                setSelectedVals(arr);
              }}
            >
              + Add More
            </button>
            {inputError && (
              <div style={{ color: "red", marginTop: 10 }}>{inputError}</div>
            )}
          </div>
          <div>
            <button
              className="mr1 primary-btn"
              onClick={() => runReport()}
              disabled={
                inputError ||
                cards?.loading ||
                estimate_data?.loading ||
                Object.keys(filters).length === 0
              }
            >
              {cards?.loading || estimate_data?.loading
                ? "Loading..."
                : "Run Report"}
            </button>
            <button
              className="secondary-btn"
              type="button"
              onClick={handleCancel}
            >
              Close
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Reports;
