import { Table } from 'antd';
import React from "react";
import { useTranslation } from "react-i18next";
import { amountFormat } from "../../utils";
import BaseCurrency from "./BaseCurrency";

const ItemsTableShow = ({ itemsTableShowData, currency, currencyInfo, isOrder = false }) => {
  const { t } = useTranslation()
  const columns = [
    {
      title: t('item_name'),
      dataIndex: isOrder ? "Item Name" : 'item_name',
    },
    {
      title: t('quantity'),
      dataIndex: isOrder ? "Quantity" : 'quantity',
    },
    {
      title: t('unit_price'),
      dataIndex: isOrder ? "Unit Price" : 'unit_price',
      render: (text) => <div> <BaseCurrency symbol_code={currency} />{amountFormat(text, currencyInfo?.decimal_scale)}</div>,
    },
    {
      title: t('discount'),
      dataIndex: isOrder ? "" : 'discount_percentage',
      render: (text) => <div>{parseFloat(text) ? text : "0"}%</div>,
    },
    {
      title: t('total_amount'),
      dataIndex: isOrder ? "Amount" : 'total_amount',
      render: (text) => <div> <BaseCurrency symbol_code={currency} />{amountFormat(text, currencyInfo?.decimal_scale)}</div>,
    },
  ];

  const columns_order = [
    {
      title: t('item_name'),
      dataIndex: 'Item Name',
    },
    {
      title: t('quantity'),
      dataIndex: 'Quantity',
    },
    {
      title: t('unit_price'),
      dataIndex: 'Unit Price',
      render: (text) => <div> <BaseCurrency symbol_code={currency} />{amountFormat(text, currencyInfo?.decimal_scale)}</div>,
    },
    {
      title: t('discount'),
      dataIndex: 'discount_percentage',
      render: (text) => <div>{parseFloat(text) ? text : "0"}%</div>,
    },
    {
      title: t('total_amount'),
      dataIndex: 'Amount',
      render: (text) => <div> <BaseCurrency symbol_code={currency} />{amountFormat(text, currencyInfo?.decimal_scale)}</div>,
    },
  ];

  return (
    <>
      <Table columns={isOrder ? columns_order : columns} dataSource={itemsTableShowData} size="small" pagination={false} />
    </>
  )
};
export default ItemsTableShow;