import { ActionTypesFactory } from '../../utils';

export const CreateBillingDetail = ActionTypesFactory('CreateBillingDetail', 'CreateBillingDetail');
export const UpdateBillingDetail = ActionTypesFactory('UpdateBillingDetail', 'UpdateBillingDetail');
export const UpdateBillAttachment = ActionTypesFactory('UpdateBillAttachment', 'UpdateBillAttachment');
export const FetchAllBillingDetails = ActionTypesFactory('FetchAllBillingDetails', 'FetchAllBillingDetails');
export const FetchBillingDetailsById = ActionTypesFactory('FetchBillingDetailsById', 'FetchBillingDetailsById');
export const SubmitBillingDetails = ActionTypesFactory('SubmitBillingDetails', 'SubmitBillingDetails');
export const ApproveBillingDetails = ActionTypesFactory('ApproveBillingDetails', 'ApproveBillingDetails');
export const RejectBillingDetails = ActionTypesFactory('RejectBillingDetails', 'RejectBillingDetails');
export const CreateBillingComment = ActionTypesFactory("CreateBillingComment", "CreateBillingComment");
export const BillingComment = ActionTypesFactory("BillingComment", "BillingComment");
export const BillingCommentHistory = ActionTypesFactory("BillingCommentHistory", "BillingCommentHistory");

export const createBillingDetail = (payload) => {
  return {
    type: CreateBillingDetail.REQUEST,
    payload,
  };
};

export const updateBillingDetail = (payload) => {
  return {
    type: UpdateBillingDetail.REQUEST,
    payload,
  };
};

export const updateBillAttachment = (payload) => {
  return {
    type: UpdateBillAttachment.REQUEST,
    payload,
  };
};

export const fetchAllBillingDetails = (payload) => {
  return {
    type: FetchAllBillingDetails.REQUEST,
    payload,
  };
};

export const fetchBillingDetailsById = (payload) => {
  return {
    type: FetchBillingDetailsById.REQUEST,
    payload,
  };
};

export const submitBillingDetails = (payload) => {
  return {
    type: SubmitBillingDetails.REQUEST,
    payload,
  };
};

export const approveBillingDetails = (payload) => {
  return {
    type: ApproveBillingDetails.REQUEST,
    payload,
  };
};

export const rejectBillingDetails = (payload) => {
  return {
    type: RejectBillingDetails.REQUEST,
    payload,
  };
};

export const createBillingComment = (payload) => {
  return {
    type: CreateBillingComment.REQUEST,
    payload
  }
}

export const billingComment = (payload, otherParams)=>{
  return{
    type : BillingComment.REQUEST,
    payload,
    otherParams
  }
}

export const billingCommentHistory = (payload, otherParams) =>{
  return {
    type: BillingCommentHistory.REQUEST,
    payload,
    otherParams
  }
} 