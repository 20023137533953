
import { message, Upload } from 'antd';
// Import Swiper styles
import { InboxOutlined } from '@ant-design/icons';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
const { Dragger } = Upload;

const ImageSwiper = ({ imgs, onFileChange, onFileRemove, fileList }) => {
  const {t} = useTranslation()
  function beforeUpload(file) {
    const isPNG = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';
    let sum = file.size;
    if (sum > 7340032) {
      message.error(<span className="messageText"> <span style={{
        textOverflow: "ellipsis",
        maxWidth: 250,
        marginRight: 10,
        whiteSpace: "nowrap",
        color: "#333"
      }}>
        {/* "{file.name}" */}
      </span>file is more than 7 MB. Only 7 MB below files are accepted.</span>);
      return Upload.LIST_IGNORE;
    }
    if (!isPNG) {
      message.error(<span className="messageText">
        <span style={{
          textOverflow: "ellipsis",
          maxWidth: 250,
          marginRight: 10,
          whiteSpace: "nowrap",
          color: "#333"
        }}>
          "{file.name}"
        </span>
        is not jpg/png</span>)
      return Upload.LIST_IGNORE;
    }

    return false
  }
  return (
    <div className="userDragger">
      <Dragger
        customRequest={(options) => {
          const { onSuccess, file } = options
          onSuccess(null, file)
        }}
        name='logo'
        // maxCount={5}
        onChange={onFileChange}
        beforeUpload={beforeUpload}
        fileList={fileList}
        onRemove={onFileRemove}
        multiple
        accept={["image/png", "image/jpeg", "image/jpg"]}
      >
        <p className="flex-center">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('drag_png')}<br />{t('click_here_to')}</p>
        <p className="ant-upload-text">{t('max_7')}</p>
      </Dragger>
      <Swiper
        spaceBetween={50}
        modules={[Navigation]}
        slidesPerView={1}
        navigation
        className='mt2'
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      >
        {imgs?.map((ele, idx) => <SwiperSlide key={"img" + idx}>
          <div className='image-preview'>
            <img src={ele?.src} alt="" />
          </div>
        </SwiperSlide>)}
      </Swiper>
    </div>

  );
};

export default ImageSwiper;