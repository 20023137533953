import { Button, Form, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantsField } from "../../../../actions/companySettings";
import { apis } from "../../../../config/APIs";
import useDocumentAxios from "../../../../config/useDocument";
import useSettings from "../../../../config/useSettings";
import { findLodash } from "../../../../utils";
import { FormInput, FormSelect } from "../../../inputs";
import UploadField from "../../../inputs/UploadField";

const AddItemsFields = ({ isVisible, onClose, type, data, id }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const merchantsFieldResponse = useSelector(
    (state) => state.companySetting.getMerchantsField?.data?.data || {}
  );
  const [listFiles, setListFiles] = useState([]);
  console.log("add items fields id", id)
  let requested;
  if (type === "items") {
    if (id) {
      requested = apis.updateItemsField;
    } else {
      requested = apis.createPaymentItems;
    }
  } else if (type === "taxes") {
    if (id) {
      requested = apis.updateTaxesField;
    } else {
      requested = apis.createTaxTypes;
    }
  } else if (type === "tds") {
    if (id) {
      requested = apis.updateTdsField;
    } else {
      requested = apis.createTDSTypes;
    }
  } else if (type === "unit") {
    if (id) {
      requested = apis.unitTypeUpdateAPI;
    } else {
      requested = apis.unitTypeCreateAPI;
    }
  } else if (type === "warehouse") {
    if (id) {
      requested = apis.warehouseUpdateAPI;
    } else {
      requested = apis.warehouseCreateAPI;
    }
  } else {
    requested = "";
  }

  const { onCall: DocumentCreate } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });

  const { onCall: DocumentDelete } = useDocumentAxios({
    api: apis.deleteDocument,
    method: "delete",
    baseURL: "document",
  });
  const { onCall: callSettings } = useSettings({
    api: requested,
    method: "post",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      item_name: "",
      item_type: "",
      sku: "",
      hsn_code: "",
      unit_price: "",
      tax_name: "",
      rate: "",
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload;
    if (type === "items") {
      payload = [
        {
          item_name: data.item_name,
          item_type: data.item_type,
          sku: data.sku,
          hsn_code: data.hsn_code,
          unit_price: Number(data.unit_price),
        },
      ];
      if (id) {
        payload[0].id = id;
        payload[0].tax_amount = 0;
        payload[0].discount_percentage = 0;
        payload[0].quantity = 0;
      }
    } else if (type === "taxes") {
      payload = {
        tax_name: data.tax_name,
        rate: Number(data.rate),
      };
      if (id) {
        payload.id = id;
      }
    } else if (type === "unit") {
      payload = {
        unit_name: data?.unit_name,
        category_name: data?.category_name
      }
      if (id) {
        payload.id = id
      }
    } else if (type === "warehouse") {
      payload = {
        ware_house_name: data?.ware_house_name
      }
      if (id) {
        payload.id = id
      }
    } else {
      payload = {
        tax_name: data.tax_name,
        rate: Number(data.rate),
      };
      if (id) {
        payload.id = id;
      }
    }
    callSettings({
      data: payload,
    })
      .then((data) => {
        if (type === "items") {
          if (listFiles.length > 0) {
            const formData = new FormData();
            listFiles
              ?.filter((ele) => !ele.isEdit)
              .forEach((ele) => {
                formData.append("files", ele);
                formData.append("name", "images");
              });
            formData.append("record_type", "Item");
            formData.append("record_id", data?.data?.[0]?.id ?? id);

            DocumentCreate({
              data: formData,
              headers: {
                "Content-Type": "multipart/formdata",
              },
            }).then(() => {
              dispatch(
                getMerchantsField({
                  company_id: localStorage.getItem("company_id"),
                  type: type,
                })
              );
            });
          }
        }
        message.success(<span className="messageText">{data?.message}</span>);
        reset();
        setListFiles([]);
        onClose();
        dispatch(
          getMerchantsField({
            company_id: localStorage.getItem("company_id"),
            type: type,
          })
        );
      })
      .catch((err) => {
        console.log(err.response, "messages");
        message.error(
          <span className="messageText">{err.response.data.message}</span>
        );
      });
  };

  useEffect(() => {
    if (id) {
      if (type === "items" && id) {
        let editValues = findLodash(merchantsFieldResponse, { id: id }) || {};
        setValue("item_name", editValues?.item_name);
        setValue("item_type", editValues?.item_type);
        setValue("hsn_code", editValues?.hsn_code);
        setValue("sku", editValues?.sku);
        setValue("unit_price", editValues?.unit_price);
        setListFiles(
          Array.isArray(editValues.attachments)
            ? editValues.attachments?.map((ele) => ({
              uid: ele.id,
              name: ele.filename,
              url: ele.key,
              status: "done",
              thumbUrl: ele.key,
              isEdit: true,
            }))
            : []
        );
      } else if (type === "taxes" && id) {
        let editValues = findLodash(merchantsFieldResponse, { id: id }) || {};
        setValue("tax_name", editValues?.tax_name);
        setValue("rate", editValues?.rate);
      } else if (type === "unit") {
        let editValues = findLodash(merchantsFieldResponse, { id: id }) || {};
        console.log("add items fields", { merchantsFieldResponse, editValues, id })
        setValue("unit_name", editValues?.unit_name);
        setValue("category_name", editValues?.category_name);
      } else if (type === "warehouse") {
        let editValues = findLodash(merchantsFieldResponse, { id: id }) || {};
        setValue("ware_house_name", editValues?.ware_house_name);
      } else {
        let editValues = findLodash(merchantsFieldResponse, { id: id }) || {};
        setValue("tax_name", editValues?.tax_name);
        setValue("rate", editValues?.rate);
      }
    } else {
      reset();
    }
  }, [id]);
  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <>
      <Modal
        onCancel={onClose}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">
                {id ? t("Update") : t("add")} {type}
              </span>
              <span
                className="fs16 lh20 color-818181 fw-medium"
                style={{
                  marginLeft: "18px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#818181",
                  fontWeight: "medium",
                }}
              ></span>
            </div>
          </div>
        }
        visible={isVisible}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)} form={form}>
            {type === "items" ? (
              <>
                <div>
                  <div className="pp-form-item field_length">
                    <FormInput
                      inline
                      control={control}
                      {...register("item_name", {
                        required: t("item_name_required"),
                        pattern: {
                          value: /^[a-zA-Z][a-zA-Z0-9\s]*$/,
                          message: t("Please Enter Proper Name"),
                        },
                      })}
                      required={true}
                      errors={errors}
                      label={t("item_name")}
                      placeholder={t("enter_item_name")}
                    //   onSearch={onVendorSearch}
                    //   options={customer}
                    //   onAddItem={handlerNewVendor}
                    />
                  </div>
                  <div className="pp-form-item field_length">
                    <FormSelect
                      inline
                      control={control}
                      {...register("item_type", {
                        required: "Item type is required",
                      })}
                      required={true}
                      errors={errors}
                      label={t("item_type")}
                      placeholder={t("select")}
                      hideCreateOption={true}
                      //   onSearch={onVendorSearch}
                      options={[
                        { label: t("goods"), value: "Goods" },
                        { label: t("services"), value: "Services" },
                      ]}
                      //   onAddItem={handlerNewVendor}
                      labelColor={false}
                    />
                  </div>
                  <div className="pp-form-item field_length">
                    <FormInput
                      inline
                      control={control}
                      {...register("sku", { required: t("sku_required") })}
                      required={true}
                      errors={errors}
                      label={t("stock_sku")}
                      placeholder={t("enter_sku")}
                    //   onSearch={onVendorSearch}
                    //   options={customer}
                    //   onAddItem={handlerNewVendor}
                    />
                  </div>
                  <div className="pp-form-item field_length">
                    <FormInput
                      inline
                      control={control}
                      {...register("hsn_code", {
                        required: t("hsn_code_required"),
                      })}
                      required={true}
                      errors={errors}
                      label={t("hsn_codes")}
                      placeholder={t("enter_hsn_code")}
                    //   onSearch={onVendorSearch}
                    //   options={customer}
                    //   onAddItem={handlerNewVendor}
                    />
                  </div>
                  <div className="pp-form-item field_length">
                    <FormInput
                      inline
                      control={control}
                      {...register("unit_price", {
                        required: t("unit_price_required"),
                        validate: (val) => {
                          if (parseFloat(val) <= 0) {
                            return "Unit price must be greater than zero";
                          }
                        },
                      })}
                      required={true}
                      errors={errors}
                      number={"integer"}
                      label={t("unit_price")}
                      placeholder={t("enter_unit_price")}
                    //   onSearch={onVendorSearch}
                    //   options={customer}
                    //   onAddItem={handlerNewVendor}
                    />
                  </div>
                  {/* <div className="pp-form-item field_length">
                    <FormInput
                      inline
                      control={control}
                      {...register("quantity")}
                      required
                      errors={errors}
                      label="Quantity"
                      placeholder="Select"
                      //   onSearch={onVendorSearch}
                      //   options={customer}
                      //   onAddItem={handlerNewVendor}
                    />
                  </div> */}
                </div>
              </>
            ) : type === "unit" ? (
              <div>
                <div className="pp-form-item field_length">
                  <FormInput
                    inline
                    control={control}
                    {...register("unit_name", {
                      required: t("Unit name is required"),
                    })}
                    required={true}
                    errors={errors}
                    label={"Unit Name"}
                    placeholder={"Enter"}
                  />
                </div>
                <div className="pp-form-item field_length">
                  <FormInput
                    inline
                    control={control}
                    {...register("category_name", {
                      required: `Category name is required`,
                    })}
                    required={true}
                    errors={errors}
                    label={"Category Name"}
                    placeholder={`Enter`}
                  // number={true}
                  />
                </div>
              </div>
            ) : type === "warehouse" ? (
              <div>
                <div className="pp-form-item field_length">
                  <FormInput
                    inline
                    control={control}
                    {...register("ware_house_name", {
                      required: t("Warehouse name is required"),
                    })}
                    required={true}
                    errors={errors}
                    label={"Warehouse name"}
                    placeholder={"Enter"}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="pp-form-item field_length">
                  <FormInput
                    inline
                    control={control}
                    {...register("tax_name", {
                      required: t("tax_name_required"),
                    })}
                    required={true}
                    errors={errors}
                    label={type == "taxes" ? t("tax_name") : t("TDS Name")}
                    placeholder={
                      type == "taxes"
                        ? t("enter_tax_name")
                        : t("Enter TDS Name")
                    }
                  //   onSearch={onVendorSearch}
                  //   options={customer}
                  //   onAddItem={handlerNewVendor}
                  />
                </div>
                <div className="pp-form-item field_length">
                  <FormInput
                    inline
                    control={control}
                    {...register("rate", {
                      required: `${type === "tds" ? "Rate" : "Value"
                        } is required`,
                    })}
                    required={true}
                    errors={errors}
                    label={type === "tds" ? t("rate") : t("value")}
                    placeholder={`Enter ${type === "tds" ? t("rate") : t("value")
                      }`}
                    number={true}
                  //   onSearch={onVendorSearch}
                  //   options={customer}
                  //   onAddItem={handlerNewVendor}
                  />
                </div>
              </div>
            )}
            <br />
            {type === "items" && (
              <UploadField
                title={`Attach File(s) to Item`}
                // desc={t('material_desc')}
                subDescripcion={t("inv_desc")}
                acceptType={".jpg,.jpeg,.png"}
                onSelected={(fileInfo) => {
                  let listAux = [...listFiles];
                  listAux.push(fileInfo);
                  setListFiles(listAux);
                }}
                filesList={listFiles}
                onRemove={(index) => {
                  let listAux = [...listFiles];
                  if (listAux[index].isEdit) {
                    DocumentDelete({
                      data: {
                        ids: [listAux[index].uid],
                      },
                      params: {
                        record_id: listAux[index].uid,
                      },
                      headers: {
                        "Content-Type": "multipart/formdata",
                      },
                    });
                  }
                  listAux.splice(index, 1);
                  setListFiles(listAux);
                }}
              />
            )}
            <div style={{ display: "flex", marginTop: "2rem" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="pp-main-button"
                id="save"
              >
                <span>{id ? t("update") : t("submit")}</span>
              </Button>

              <Button
                className="pp-secondary-button"
                style={{ marginLeft: "2rem" }}
                onClick={handleClose}
              >
                <span>{t("cancel")}</span>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default AddItemsFields;
