import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { Children, useEffect } from "react";
import { Col, Modal, Radio, Row } from "antd";
import TableHeader from "../Sales/TableHeader";
import TableComponent from "../Common/TableComp/TableComp";
import ExportStatement from "../Common/ExportStatement";
import { useState } from "react";
import BankAdvanceFilter from "../Common/BankAdvanceFilter";
import { useForm } from "react-hook-form";
import BankBalance from "../Common/BankBalance";
import Opening from "../../assets/icons/open.svg";
import Closing from "../../assets/icons/close.svg";
import Transaction from "../../assets/icons/transactions.svg";
import BankShow from "../Common/BankShow";
import { payout_txns } from "../../actions/accounts/accounts";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { get_master_account_txns } from "../../actions/z_accounts";
import { amountFormat } from "../../utils";
import { currencyMapper } from "../../config/helper";
import CustomStatement from "./StatementSelect";

const Banks = ({ hasWriteAccess }) => {
  const today = moment();
  const dispatch = useDispatch();
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [openExportStatement, setOpenExportStatement] = useState(false);
  const [openAdvanceFilter, setOpenAdvanceFilter] = useState(false);
  const [account, setAccount] = useState("");
  const [accountObj, setAccountObj] = useState({});
  const [filterValue, setFilterValue] = useState("all");
  const [filterDetails, setFilterDetails] = useState({});
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const data = useSelector(
    (state) => state?.z_accs?.txns?.data?.data?.responseData?.data?.values ?? []
  );
  const loading = useSelector((state) => state?.z_accs?.txns?.loading);
  const totalRecords = useSelector(
    (state) => state?.banks?.payout_txns?.data?.total_count ?? []
  );
  useEffect(() => {
    dispatch(
      headerTitles({
        title: t("statements"),
        description: t("find_records"),
      })
    );
    dispatch(
      get_master_account_txns({
        account_id: "ID-1165",
        date_from: today
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        date_to: today.clone().endOf("month").format("YYYY-MM-DD"),
        date_type: "VALUE_DATE",
        page_number: 1,
        page_size: 50,
      })
    );
  }, []);
  const columns = [
    {
      title: t("txn_id"),
      dataIndex: "accountTransactionId",
      key: "accountTransactionId",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "counterparty",
      key: "counterparty",
      render: (text) => <div>{text?.name}</div>,
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty",
      key: "counterparty",
      render: (text) => <div>XXXX{text?.accountNumber?.value?.substr(4)}</div>,
    },
    {
      title: t("mode_of_payment"),
      dataIndex: "labels",
      key: "labels",
      render: (text) => <div>{text?.join(", ")}</div>,
    },

    {
      title: t("date"),
      dataIndex: "postingDate",
      key: "postingDate",
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, r) => (
        <div>
          {currencyMapper[r.currencyCode]} {amountFormat(text)}
        </div>
      ),
    },
    {
      title: t("transaction_type"),
      dataIndex: "directionCode",
      key: "directionCode",
      render: (text) => (
        <div className={`status_${text?.toLowerCase()}`}>
          {text === "IN"
            ? "Credit"
            : text === "OUT"
            ? "Debit"
            : text?.replace(/_/g, " ")}
        </div>
      ),
    },
  ];

  const handleFilter = (key) => {
    setFilterValue(key);
  };
  const handleStatement = (key) => {
    switch (key) {
      case "statement":
        setOpenExportStatement(true);
    }
  };

  const handleBankShowRefresh = () => {};
  return (
    <>
      {!isFilterSelected ? (
        <CustomStatement
          setSelectedAccount={setIsFilterSelected}
          account={account}
          setAccount={setAccount}
          setAccountObj={setAccountObj}
          setFilterDetails={setFilterDetails}
        />
      ) : (
        <>
          <Row wrap={false} className="table-wrapper">
            <Col flex="auto" className="table-wrapper-col">
              <div className="flex-grow">
                {filterValue === "all" ? (
                  <div className="mt1">
                    <BankShow
                      handleBankShowRefresh={handleBankShowRefresh}
                      handleBack={() => {
                        setIsFilterSelected(false);
                      }}
                      dates={[filterDetails?.date_from, filterDetails?.date_to]}
                      accountId={accountObj?.accountId}
                      setFilterDetails={setFilterDetails}
                      account={`${
                        accountObj?.accountName?.length > 8
                          ? `${accountObj?.accountName?.substr(0, 8)}...`
                          : accountObj?.accountName
                      }
                      - XXXXX${accountObj?.accountNumbers?.[0]?.accountNumber?.value?.slice(
                        -4
                      )}`}
                      date={`${moment(filterDetails?.date_from).format(
                        "MMM DD,YY"
                      )} - ${moment(filterDetails?.date_to).format(
                        "MMM DD,YY"
                      )}`}
                    />
                  </div>
                ) : null}

                <div className="shimmer" style={{ paddingTop: "15px" }}>
                  <TableComponent
                    columns={columns}
                    loading={loading}
                    data={data}
                    hasCheckbox={false}
                    rowKey="bill_no"
                    pagination={{
                      total: totalRecords || 0,
                      current: page,
                      onChange: (pagee) => {
                        dispatch(
                          payout_txns({
                            page_number: pagee,
                            type: "individual",
                          })
                        );
                        setPage(pagee);
                      },
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default Banks;
