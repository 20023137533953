import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sort_key } from "../Common/Table/Table";
import {
  reports,
  reportsApproveReset,
  reportsBulkApproveReject,
  ReportsBulkApproveReject,
  reportsRejectReset,
  reportsReset,
  userReports,
  userReportsReset
} from "./../../actions/reports/reports";
// import moment from "moment";
import { Button, message, Modal, Typography } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from "json-2-csv";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { prefixexpenseFields } from "../../actions/expenses/expenses";
import { headerTitles, SideMenu } from "../../actions/masterdata/masterdata";
import { userTripFields } from "../../actions/trips";
import { apis } from "../../config/APIs";
import endpointPayApiBeta from "../../config/AxiosPayApiBeta";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import TableComponent from "../Common/TableComp/TableComp";
import TableHeader from "../Sales/TableHeader";
import AddReport from "./AddReport";

const { Title, Text } = Typography;

const Reports = ({ user, hasWriteAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [reportsData, setReportsData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isBulkModalVisible, setIsBulkModalVisible] = React.useState(false);
  const [type, setType] = React.useState("");
  const [totalRecords, setTotalRecords] = React.useState(0);
  const reportsResponse = useSelector(
    (state) => state.reports.reports.data || {}
  );
  const bulkApprove = useSelector((state) => state.reports.bulkApprove || {});
  const { t } = useTranslation();

  const columns = [
    {
      title: t("report_id"),
      dataIndex: "report_number",
      key: "report_number",
    },
    {
      title: t("employee_name"),
      dataIndex: "user_info",
      key: "user_info",
      render: (text) => <div>{text?.name}</div>,
    },
    {
      title: t("reportName"),
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: "Start Date",
    //   dataIndex: "from_date",
    //   key: "from_date",
    //   // render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    // },
    // {
    //   title: "End Date",
    //   dataIndex: "to_date",
    //   key: "to_date",
    //   // render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    // },
    {
      title: t("submittedOn"),
      dataIndex: "submitted_date",
      key: "submitted_date",
      render: (text) =>
        text && <div>{moment(text).format("DD-MM-YYYY hh:mm:ss")}</div>,
    },
    {
      title: t("Amount"),
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text) => (
        <div>
          <BaseCurrency />
          {(text && amountFormat(text)) || 0}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: user ? "status" : "role_status",
      key: user ? "status" : "role_status",
      render: (text) => (
        <div className={`status status_${text}`}>
          {text?.replace(/_/g, " ")}
        </div>
      ),
      width: 240,
    },
  ];
  const [page, setPage] = React.useState(1);

  const addUserReportsResponse = useSelector(
    (state) => state.reports.addUserReports || {}
  );
  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.createNew) {
      setIsModalVisible(true);
    }

    dispatch(
      headerTitles({
        title: "reports",
        description: "",
      })
    );

    return () => {
      dispatch(reportsReset());
      dispatch(userReportsReset());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (location?.state?.filter) {
      dispatch(
        reports({
          module_type: "REPORT",
          filters: { status: [location?.state?.filter] },
          view: "admin",
        })
      );
      setFilters({
        filters: { status: [location?.state?.filter] },
      });
    } else {
      if (!user) {
        if (location.state?.status) {
          dispatch(reports({ status: location.state?.status }));
        } else {
          dispatch(
            reports({ module_type: "REPORT", params: { view: "admin" } })
          );
        }
        // setFilters({});
      } else {
        if (location.state?.status) {
          dispatch(reports({ module_type: "REPORT", filters: { status: [location.state?.status] } }));
          setFilters({
            filters: { status: [location.state?.status] },
          });
        } else {
          dispatch(reports({ module_type: "REPORT" }));
        }
        // setFilters({});
      }
    }
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/expenses",
        childrenMenu: user ? "/my-reports" : "",
        showChildren: true,
        secondNav: "/reports",
      },
    });
    // eslint-disable-next-line
  }, [user, location.pathname]);

  React.useEffect(() => {
    const { data: { status = "", error = "" } = {} } = addUserReportsResponse;
    if (error) {
      // message.success(<span className="messageText">{res_message}</span>);
      dispatch(userReports());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserReportsResponse]);

  React.useEffect(() => {
    const { data: { status = "", message: res_message = "" } = {} } =
      bulkApprove;
    if (status) {
      setIsBulkModalVisible(false);
      message.success(<span className="messageText">{res_message}</span>);
      setSelectedRows([]);
      setSelectedRowKeys([]);
      !user ? dispatch(reports()) : dispatch(userReports());

      dispatch({
        type: ReportsBulkApproveReject.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkApprove]);
  console.log(reportsResponse, "responseDetails====>");

  React.useEffect(() => {
    const {
      data: expense = [],
      status = false,
      total_count = 0,
    } = reportsResponse;
    if (status) {
      setReportsData(expense);
      setTotalRecords(total_count);
      dispatch(reportsReset());
      dispatch(reportsApproveReset());
      dispatch(reportsRejectReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsResponse]);

  const handleAddButton = (evt, record) => {
    // handleModal();
    dispatch(prefixexpenseFields({ params: { module_type: "REPORT" } }));
    dispatch(userTripFields({ module_type: "REPORT" }));
    if (hasWriteAccess) {
      setIsModalVisible(true);
    }
  };

  const handleSearch = (value) => {
    dispatch(
      reports({
        module_type: "REPORT",
        params: { view: user ? "" : "admin" },
        ...filters,
        search_key: value,
      })
    );
    setFilters({
      ...filters,
      search_key: value,
    });
    setPage(1);
  };

  const handleRowClick = (evt, record) => {
    console.log(record, "recordDetaild===>");
    if (
      evt.target.className?.includes("ant-checkbox-inner") ||
      evt.target.className?.includes("ant-checkbox") ||
      evt.target.className?.includes("ant-checkbox-input")
    ) {
    } else {
      evt.preventDefault();
      if (record) {
        history.push({
          pathname: `/expenses/report-details/${record.id}`,
          state: {
            reportData: record,
            user,
            status: filters.status,
            from: location.pathname,
            isMyReport: true,
          },
        });
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFilter = (value) => {
    if (location.state?.status) {
      if (user) {
        history.replace("/expenses/reports/my-reports");
      } else {
        history.replace("/expenses/reports");
      }
    }
    if (value === "") {
      dispatch(reports({ module_type: "REPORT", view: user ? "" : "admin" }));
    } else {
      dispatch(
        reports({
          module_type: "REPORT",
          params: { view: user ? "" : "admin" },
          ...filters,
          filters: { status: [value] },
        })
      );
      setFilters({
        ...filters,
        filters: { status: [value] },
      });
    }
    setPage(1);
  };

  const renderBulkOption = (type) => {
    return (
      <>
        <Text>
          {t("bulk_msg")} {type} ?
        </Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() => {
            if (type === "reject") {
              dispatch(
                reportsBulkApproveReject({
                  type: "reject",
                  reports: selectedRows?.map((ele) => ele.id),
                })
              );
            } else {
              dispatch(
                reportsBulkApproveReject({
                  type: "approve",
                  reports: selectedRows
                    ?.map((ele) => ele.status === "pending_approval" && ele.id)
                    .filter((ele) => ele),
                })
              );
            }
          }}
        >
          {type}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => {
            setIsBulkModalVisible(false);
          }}
        >
          {t("cancel")}
        </Button>
      </>
    );
  };
  const onChangeCheckBox = (selectedRowKeys, selectedRows, res) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const sortOptions = !user
    ? [
      { label: "amount", key: "total_amount" },
      { label: "Report Name", key: "title" },
      { label: "Submitted Date", key: "submitted_date" },
    ]
    : [
      { label: "amount", key: "total_amount" },
      { label: "Report Name", key: "title" },
      { label: "Submitted Date", key: "submitted_date" },
    ];
  const options = !user
    ? [
      { label: t("all_reports"), filter: "" },
      { label: t("approval_pending"), filter: "pending_approval" },
      // { label: t("reimburse"), filter: "reimbursed" },
      { label: t("pending_reimburse"), filter: "pending_reimbursement" },
      { label: t("approved"), filter: "approved" },
      { label: t("rejected"), filter: "rejected" },
      { label: t("reimbursed"), filter: "reimbursed" },
    ]
    : [
      { label: t("all_reports"), filter: "" },
      { label: t("approval_pending"), filter: "pending_approval" },
      { label: t("pending_reimburse"), filter: "pending_reimbursement" },
      { label: t("un_submitted"), filter: "unsubmitted" },
      { label: t("rejected"), filter: "rejected" },
      { label: t("reimbursed"), filter: "reimbursed" },
    ];

  const sort_opts = [
    ...sortOptions.map((ele) => ({
      label: (
        <span
          style={{
            textTransform: "capitalize",
            color: "#333",
          }}
        >
          {sort_key[ele?.label]
            ? sort_key[ele?.label]
            : ele?.label?.replace("_", " ")}
        </span>
      ),
      filter: ele?.key,
    })),
    // {
    //   label: (
    //     <span
    //       style={{
    //         textTransform: "capitalize",
    //         color: "#333",
    //       }}
    //     >
    //       {t("export_csv")}
    //     </span>
    //   ),
    //   filter: "csv",
    // },
    // {
    //   label: <span style={{
    //     textTransform: "capitalize",
    //     color: "#333"
    //   }}>{t('excel_export')}</span>,
    //   filter: "xlsx"
    // }
  ];

  const approveClick = () => {
    setType("approve");
    setIsBulkModalVisible(true);
  };
  const rejectClick = () => {
    setIsBulkModalVisible(true);
    setType("reject");
  };

  return (
    <div className="reports-div">
      <TableHeader
        noDD={true}
        defaultFilterValue={filters?.filters?.status?.[0]}
        filterMenu={options}
        handleFilter={handleFilter}
        handleAddButton={handleAddButton}
        setOpenAddNew={setIsModalVisible}
        noAddNewBtn={!user}
        sortOptions={sort_opts}
        onSearch={handleSearch}
        hasWriteAccess={hasWriteAccess}
        handleSort={(key) => {
          if (key !== "csv" && key !== "xlsx") {
            !user
              ? dispatch(
                reports({
                  ...filters,
                  sort: { sort_key: key },
                  params: { view: "admin" },
                  module_type: "REPORT",
                })
              )
              : dispatch(
                reports({
                  ...filters,
                  sort: { sort_key: key },
                  module_type: "REPORT",
                })
              );
            setFilters({
              ...filters,
              sort: { sort_key: key },
            });
            setPage(1);
          } else if (!user) {
            endpointPayApiBeta
              .post(
                apis.reports + `?module_type=REPORT&isCsv=true&view=admin`,
                { ...filters }
              )
              .then((res) => {
                let cli_data = res?.data?.data?.map((ele) => ({
                  ReportId: ele?.report_number,
                  EmployeeName: ele?.user_info?.name,
                  ReportName: ele?.title,
                  SubmittedOn: moment(ele?.submitted_date).format("DD-MM-YYYY"),
                  Amonut: ele?.total_amount,
                  Status: ele?.status,
                }));
                console.log(cli_data, "reposnseDetials===>");
                json2csv(cli_data).then((csv) => {
                  var blob = new Blob([csv], {
                    type: "text/csv;charset=utf-8",
                  });
                  saveAs(blob, "Report.csv");
                });
              });
          } else if (user) {
            endpointPayApiBeta
              .post(apis.reports + `?module_type=REPORT&isCsv=true`, {
                ...filters,
              })
              .then((res) => {
                let cli_data = res?.data?.data?.map((ele) => ({
                  ReportId: ele?.report_number,
                  EmployeeName: ele?.user_info?.name,
                  ReportName: ele?.title,
                  SubmittedOn: moment(ele?.submitted_date).format("DD-MM-YYYY"),
                  Amonut: ele?.total_amount,
                  Status: ele?.status,
                }));
                console.log(cli_data, "reposnseDetials===>");
                json2csv(cli_data).then((csv) => {
                  var blob = new Blob([csv], {
                    type: "text/csv;charset=utf-8",
                  });
                  saveAs(blob, "Report.csv");
                });
              });
          }
        }}
        placeholder={t("search_by_report")}
        isBulkAction={selectedRows?.length > 0}
        bulkLoading={bulkApprove?.loading}
        approveClick={() => hasWriteAccess && approveClick()}
        rejectClick={() => hasWriteAccess && rejectClick()}
      />

      {isModalVisible && (
        <Modal
          title={
            <Title level={4}>
              {t("new_report")} &nbsp;
              <span className="text-small">{t("enter_and_save")}</span>
            </Title>
          }
          open={isModalVisible && hasWriteAccess}
          onCancel={handleCancel}
          footer={[]}
          className="right-alinged-modal"
        >
          <AddReport handleCancel={handleCancel} />
        </Modal>
      )}
      <Modal
        title={<Title level={4}>{t("bulk_action")}</Title>}
        visible={isBulkModalVisible && hasWriteAccess}
        onCancel={() => {
          setIsBulkModalVisible(false);
        }}
        footer={[]}
        className="center-alinged-modal"
      >
        {renderBulkOption(type)}
      </Modal>
      <div style={{ paddingTop: "15px" }}>
        <TableComponent
          rowKey="id"
          columns={columns}
          data={reportsData ? reportsData : []}
          rowSelection={{
            type: "checkbox",
            preserveSelectedRowKeys: true,
            // ...rowSelection,
            // onChange: onChangeCheckBox,
            getCheckboxProps: (record) => ({
              disabled:
                !!user || record.current_report_status !== "pending_approval",
              style: {
                display: !user ? "flex" : "none",
              },
            }),
            selectedRowKeys: selectedRowKeys,
            onSelect: (record, selected) => {
              if (selected) {
                setSelectedRows((rows) => [...rows, record]);
                setSelectedRowKeys((rows) => [...rows, record.id]);
              } else {
                let rows = selectedRows;
                let idx = rows.findIndex((ele) => ele.id === record.id);
                if (idx > -1) {
                  rows.splice(idx, 1);
                }
                setSelectedRows(rows);
                setSelectedRowKeys(rows?.map((ele) => ele.id));
              }
            },
            onSelectAll: (checked, records) => {
              if (checked) {
                setSelectedRows((rows) => [...rows, ...records]);
                setSelectedRowKeys((rows) => [
                  ...rows,
                  ...records?.map((ele) => ele?.id),
                ]);
              } else {
                let rows = selectedRows;
                reportsData.forEach((itm) => {
                  let idx = rows.findIndex((ele) => ele.id === itm.id);
                  if (idx > -1) {
                    rows.splice(idx, 1);
                  }
                });
                setSelectedRows(rows);
                setSelectedRowKeys(rows?.map((ele) => ele.id));
              }
            },
          }}
          getCheckboxProps={(record) => ({
            disabled: !!user || record.status !== "pending_approval",
            style: {
              display: !user ? "flex" : "none",
            },
          })}
          setSelectedRowKeys={setSelectedRowKeys}
          handleRowClick={handleRowClick}
          hasCheckbox={!user}
          pagination={{
            total: totalRecords || 0,
            current: page,
            onChange: (pagee) => {
              if (!user)
                dispatch(
                  reports({
                    module_type: "REPORT",

                    params: { page_number: pagee, view: "admin" },
                    ...filters,
                  })
                );
              else
                dispatch(
                  reports({
                    module_type: "REPORT",
                    params: { page_number: pagee },
                    ...filters,
                  })
                );
              setPage(pagee);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Reports;
