import {
  Document,
  Page,
  View,
  StyleSheet,
  Text,
  PDFViewer,
  page,
  Image,
} from "@react-pdf/renderer";
import React from "react";
import moment from "moment";
import TemplateTable from "./TemplateTable";
import { PageBreak } from "@react-pdf/renderer";
import { columns } from "./invPDF2";

const invPDF = (props) => {
  const {
    billingAddressCity,
    billingAddressState,
    billingAddresszip,
    billingCountry,
    billPhone,
    billAddress,
    companyAddr,
    companyCity,
    companyState,
    companyCountry,
    companyPin,
    deliveryCharges,
  } = props;

  // Function to form the address
  const formatAddress = () => {
    const addressComponents = [
      billAddress,
      billingAddressCity,
      billingAddressState,
      billingAddresszip,
      billPhone,
      billingCountry,
    ];

    // Filter out undefined or empty values
    const filteredAddressComponents = addressComponents.filter(Boolean);
    const address = filteredAddressComponents.join(", ");

    return address;
  };

  const formAddress = formatAddress();

  const companyAddress = () => {
    const addressComponents = [
      companyAddr,
      companyCity,
      companyState,
      companyCountry,
      companyPin,
    ];

    // Filter out undefined or empty values
    const filteredCompanyComponents = addressComponents.filter(Boolean);
    const address = filteredCompanyComponents.join(", ");

    return address;
  };

  const compAddr = companyAddress();

  const styles = StyleSheet.create({
    page: {
      paddingTop: 60,
      paddingLeft: 50,
      paddingRight: 50,
      lineHeight: 1.5,
    },
    subMenu: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 20,
      width: "45%",
    },
    footerMenu: {
      flexDirection: "row",
      justifyContent: "space-between",
      /// marginTop: 50,
      width: "45%",
    },
    test: {
      flexDirection: "column",
      alignItems: "flex-end",
      fontSize: "15px",
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
      //color: "grey"
    },
    display: {
      flexDirection: "row",
      justifyContent: "space-between",
      //  paddingHorizontal: 20,
      marginTop: 5,
      width: "20%",
    },
    container: {
      display: "flex",
      //salignItems: 'flex-end',
      marginTop: 5,
    },

    displayOne: {
      flexDirection: "row",
      justifyContent: "space-between",
      // paddingHorizontal: 20,
      marginTop: 10,
      minWidth: "35%",
    },
    image: {
      width: "50px",
      height: "50px",
      marginTop: "2px",
      marginRight: "10px",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      marginTop: "5px",
    },
  });
  const currency =
    props?.useDummyData === true ? props?.obj?.currency : props?.currency;

  return (
    <div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          <Page style={styles.page} wrap={false}>
            <View>
              <View style={styles.test}>
                {props.image && (
                  <View>
                    <Image src={props.image} style={styles.image} />
                  </View>
                )}
                <Text style={{ fontSize: "14px", color: "black" }}>
                  {props?.useDummyData
                    ? props?.obj?.companyName
                    : props?.companyName}
                </Text>
                <Text
                  style={{
                    marginTop: "5px",
                    color: "grey",
                    maxWidth: "180px",
                    fontSize: "12px",
                  }}
                >
                  {props?.useDummyData === true
                    ? props?.obj?.address
                    : compAddr}
                </Text>
                <Text style={{ fontSize: "14px", color: "black" }}>
                  {props?.useDummyData
                    ? props?.obj?.companyName
                    : props?.companyName}
                </Text>
                <Text
                  style={{
                    marginTop: "5px",
                    color: "grey",
                    maxWidth: "180px",
                    fontSize: "12px",
                    textAlign: "right",
                  }}
                >
                  {props?.useDummyData === true
                    ? props?.obj?.address
                    : compAddr}
                </Text>
              </View>
            </View>
            <View>
              <Text
                style={{ fontSize: "25px", color: "black", marginTop: "20px" }}
              >
                {props?.useDummyData === true
                  ? props?.obj?.title
                  : props?.title}
              </Text>
            </View>

            <View style={{ display: "flex" }}>
              <View style={styles.subMenu}>
                <View style={styles.container}>
                  <Text
                    style={{ color: "black", fontSize: 12, minWidth: "120px" }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.numTitle
                      : props?.numTitle}
                    :
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: 12,
                      marginTop: "5px",
                      minWidth: "120px",
                    }}
                  >
                    Bill To:
                  </Text>
                </View>
                <View style={styles.container}>
                  {props?.invoiceNo || props?.useDummyData === true ? (
                    <Text style={{ color: "grey", fontSize: 12 }}>
                      {props?.useDummyData === true
                        ? props?.obj?.invoiceNo
                        : props?.invoiceNo}
                    </Text>
                  ) : null}
                  <Text
                    style={{
                      color: "grey",
                      fontSize: 12,
                      maxWidth: "150px",
                      marginTop: "5px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.beniName
                      : props?.benefiName}
                  </Text>
                  <Text
                    style={{
                      color: "grey",
                      fontSize: 12,
                      maxWidth: "150px",
                      marginTop: "5px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.beniEmail
                      : props?.benefiEmail}
                  </Text>

                  <Text
                    style={{
                      color: "grey",
                      fontSize: 12,
                      maxWidth: "150px",
                      marginTop: "5px",
                    }}
                  >
                    {props?.useDummyData === true
                      ? props?.obj?.address
                      : formAddress}
                  </Text>
                  <Text
                    style={{
                      marginTop: "5px",
                      maxWidth: "180px",
                      fontSize: "12px",
                    }}
                  >
                    GSTIN:{" "}
                    {props?.useDummyData === true
                      ? props?.obj?.gst
                      : props?.benficiaryGST}
                  </Text>
                </View>
              </View>
            </View>
            <TemplateTable
              columns={columns}
              currency={
                props?.useDummyData === true
                  ? props?.obj?.currency
                  : `${props?.currency}`
              }
              lineItems={
                props?.useDummyData === true
                  ? props?.obj?.lineItems
                  : props?.lineItems
              }
              tableHeadRow={{
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
                borderBottomColor: "#000",
                borderTopStyle: "solid",
                borderTopWidth: 1,
                borderTopColor: "#000",
                borderTop: "1px solid #000",
                paddingVertical: "10px",
              }}
              tableRow={{
                //  borderBottom: "1px solid #000",
                fontSize: "12px",
                paddingVertical: "10px",
                paddingLeft: "3px",
              }}
            />

            <View style={{ display: "flex", flexDirection: "row-reverse" }}>
              <View style={styles.displayOne}>
                <View
                  style={{
                    ...styles.container,
                    marginRight: "10px",
                    alignItems: "flex-end",
                    PageBreak: "auto",
                  }}
                >
                  <Text style={styles.text}>Sub Total</Text>
                  <Text style={styles.text}>Total Discount</Text>
                  <Text style={styles.text}>Total Tax</Text>
                  {deliveryCharges && (
                    <Text style={styles.text}>Delivery Charges</Text>
                  )}
                  {props?.type === "PURCHASE_ORDER" ||
                  props?.type === "BILL" ||
                  props?.obj?.tdsFlag === true ? (
                    <Text style={styles.text}>TDS Amount</Text>
                  ) : null}

                  <Text style={styles.text}>Total Amount</Text>
                </View>
                <View style={styles.container}>
                  <Text style={styles.text}>
                    {" "}
                    {props?.useDummyData === true
                      ? currency + " " + props?.obj?.subTotal
                      : props?.subTotal
                      ? currency + " " + props?.subTotal
                      : currency + " " + 0}
                  </Text>
                  <Text style={styles.text}>
                    {" "}
                    {props?.useDummyData === true
                      ? currency + " " + props?.obj?.discount
                      : props?.discount
                      ? currency + " " + props?.discount
                      : currency + " " + 0}
                  </Text>
                  <Text style={styles.text}>
                    {" "}
                    {props?.useDummyData === true
                      ? currency + " " + props?.obj?.totalTax
                      : props?.taxAmount
                      ? currency + " " + props?.taxAmount
                      : currency + " " + 0}
                  </Text>
                  {deliveryCharges && (
                    <Text style={styles.text}>
                      {currency + " " + deliveryCharges}
                    </Text>
                  )}
                  {props?.type === "PURCHASE_ORDER" ||
                  props?.type === "BILL" ||
                  props?.obj?.tdsFlag === true ? (
                    <Text style={styles.text}>
                      {props?.useDummyData === true
                        ? currency + " " + props?.obj?.tdsAmt
                        : props?.TDSAmt
                        ? currency + " " + props?.TDSAmt
                        : currency + " " + 0}
                    </Text>
                  ) : null}
                  <Text style={styles.text}>
                    {props?.useDummyData === true
                      ? currency + " " + props?.obj?.totalAmount
                      : props?.totalAmount
                      ? currency + " " + props?.totalAmount
                      : currency + " " + 0}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: "25%",
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <View
                style={{ justifyContent: "flex-end", flexDirection: "column" }}
              >
                <View style={styles.footerMenu}>
                  <View style={styles.container}>
                    <Text style={{ color: "black", fontSize: 12 }}>
                      Bank Name{" "}
                    </Text>
                    <Text
                      style={{ color: "black", fontSize: 12, marginTop: "5px" }}
                    >
                      Bank Account :{" "}
                    </Text>
                  </View>
                  <View style={styles.container}>
                    {props?.bankName || props?.useDummyData ? (
                      <Text style={{ color: "grey", fontSize: 12 }}>
                        {" "}
                        {props?.useDummyData === true
                          ? props?.obj?.bankName
                          : props?.bankName}
                      </Text>
                    ) : null}
                    {props?.accountNumber || props?.useDummyData ? (
                      <Text
                        style={{
                          color: "grey",
                          fontSize: 12,
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        {props?.useDummyData === true
                          ? props?.obj?.bankAccount
                          : props?.accountNumber}
                      </Text>
                    ) : null}
                  </View>
                </View>
              </View>

              <View>
                <Text
                  style={{ borderBottom: "1px solid black", paddingTop: "5px" }}
                ></Text>
              </View>
              <View>
                <Text
                  style={{
                    paddingTop: "10px",
                    fontSize: "12px",
                    color: "grey",
                    textAlign: "center",
                  }}
                >
                  if you have any queries please contact -{" "}
                  {props?.useDummyData === true
                    ? props?.obj?.email
                    : props?.primaryEmail}
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default invPDF;
