import React from "react";
import { useHistory } from "react-router-dom";
import card_chip from "../../assets/icons/card_chip.png";
import card_name from "../../assets/icons/card_name.png";
import card_wifi from "../../assets/icons/card_wifi.png";
import adv from "../../assets/icons/advance.png";
import report from "../../assets/icons/report.png";
import exp from "../../assets/icons/expense.png";
import { amountFormat, hasAccess } from "../../utils";
import { useSelector } from "react-redux";
import BaseCurrency from "../Common/BaseCurrency";
import { message, Modal, Typography } from "antd";
import AddAdvance from "./../Advances/AddAdvance";
import { millify } from "../../config/helper";
// import Base from "antd/lib/typography/Base";

const { Title } = Typography;

const TopSec = ({ card }) => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const dashboard = useSelector((state) => state.cards.reports_count?.data?.data || {});
  const addUserAdvanceResponse = useSelector(
    (state) => state.advances.addUserAdvance || {}
  );

  React.useEffect(() => {
    const { data: { status = false, message: res_message = "" } = {} } =
      addUserAdvanceResponse;
    if (status) {
      handleCancel();
      message.success(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserAdvanceResponse]);

  console.log(dashboard,"dashboardDetails==>");

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    card && card?.id ?
      (<section className="d-flex gap1">
        <Modal
          title={
            <Title level={4}>
              New Advance &nbsp;
              <span className="text-small">Enter details and submit</span>
            </Title>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[]}
          className="right-alinged-modal"
        >
          <AddAdvance handleCancel={handleCancel} />
        </Modal>
        <aside className="w50 p1 card card-sec">
          <div className="flex-between">
            <span className="summary-title">Card Summary</span>
            <span className="active-text cursor" onClick={() => {
              history.push("/cards")
            }}>View All</span>
          </div>
          <div className="flex-between">
            <div className="w50">
              <div className="high-small">
                <label>Available balance</label>
                <span><BaseCurrency /> {millify(card?.balance)}</span>
              </div>
              <div className="high-medium">
                {/* <label>Transacted this month (Till date)</label>
              <span>
                {" "}
                <strong>Rs. 56,473</strong> (24 Transactions)
              </span> */}
              </div>
              <div className="high-medium">
                {/* <label>Pending bill uploads</label>
              <span>
                <strong>12</strong> Transactions
              </span> */}
              </div>
            </div>
            <div className="w50">
              <div className="debit-card">
                <h2 style={{ color: "white" }}>{card?.company_name}</h2>
                <div className="flex-between mt1">
                  <img src={card_chip} width={30} alt={"card_chip"} />
                  <img src={card_wifi} width={15} alt={"card_wifi"} />
                </div>
                <div className="mt1">
                  <span>{card?.card_number?.substr(0, 4)} XXXX XXXX {card?.card_number?.substr(15)}</span>
                  <br />
                  <span style={{ marginTop: 5, fontSize: "0.85rem" }}>
                    {card?.card_reference_no}
                  </span>
                </div>
                <div className="flex-between mt1">
                  <span style={{ fontSize: "0.85rem" }}>{card?.employee_details?.name}</span>
                  <img src={card_name} alt={"card_name"} />
                </div>
              </div>
              <div className="flex-between">
                <button className="primary-btn" onClick={() => {
                  history.push("/card-details/" + card?.id, {
                    cardData: card,
                    viewTxns: true
                  })
                }}>View Transactions</button>
                <button className="nm-btn" onClick={() => {
                  history.push("/card-details/" + card?.id, {
                    cardData: card
                  })
                }}>Settings</button>
              </div>
            </div>
          </div>
        </aside>
        <aside className="w50">
          <div className="card card-sec">
            <div className="p1">
              <span className="summary-title">Pending Tasks</span>
            </div>
            <div className="tasks">
              <div className="mb1">
                <label>Reports Pending Approval</label>
                <span>
                  <strong><BaseCurrency /> {amountFormat(dashboard?.reports_pending_approval?.amount)}</strong>
                  ({dashboard?.reports_pending_approval?.count} Reports)
                </span>
              </div>
              <div>
                <label>Unreported Expenses</label>
                <span>
                  <strong><BaseCurrency /> {amountFormat(dashboard?.unreported_expenses?.amount)}</strong>
                  ({dashboard?.unreported_expenses?.count} Expenses)
                </span>
              </div>
              <div>
                <label>Unreported Reports</label>
                <span>
                  <strong><BaseCurrency />  {amountFormat(dashboard?.unreported_reports?.amount)}</strong>
                  ({dashboard?.unreported_reports?.count} Reports)
                </span>
              </div>
              <div>
                <label>Unreported Advances</label>
                <span>
                  <strong><BaseCurrency />  {amountFormat(dashboard?.unreported_advances?.amount)}</strong>
                  ({dashboard?.unreported_advances?.count} Advances)
                </span>
              </div>
            </div>
            <div className="d-flex quick-links">
              <div className="one" onClick={() => {
                history.push("/advances/", {
                  createNew: true
                })
              }}>Create Advances</div>
              <div className="two" onClick={() => {
                history.push("/expenses", {
                  createNew: true
                })
              }}>Create Expenses</div>
              <div className="three" onClick={() => {
                history.push("/expenses/reports", {
                  createNew: true
                })
              }}>Create Reports</div>
            </div>
          </div>
        </aside>
      </section>)
      :
      (<section className="d-flex gap1 no-card">
        <aside className="w50 card card-sec">
          <div className="flex-between p1">
            <span className="summary-title">Pending Tasks</span>
          </div>
          <div className="tasks">
            <div className="mb1">
              <label>Reports Pending Approval</label>
              <span>
                <strong><BaseCurrency />  {amountFormat(dashboard?.reports_pending_approval?.amount)}</strong>
                ({dashboard?.reports_pending_approval?.count} Reports)
              </span>
            </div>
            <div>
              <label>Unreported Expenses</label>
              <span>
                <strong><BaseCurrency />  {amountFormat(dashboard?.unreported_expenses?.amount)}</strong>
                ({dashboard?.unreported_expenses?.count} Expenses)
              </span>
            </div>
            <div>
              <label>Unsubmitted Reports</label>
              <span>
                <strong><BaseCurrency />  {amountFormat(dashboard?.unreported_reports?.amount)}</strong>
                ({dashboard?.unreported_reports?.count} Reports)
              </span>
            </div>
            <div>
              <label>Unsubmitted Advances</label>
              <span>
                <strong><BaseCurrency />  {amountFormat(dashboard?.unreported_advances?.amount)}</strong>
                ({dashboard?.unreported_advances?.count} Advances)
              </span>
            </div>
          </div>
        </aside>
        <aside className="w50">
          <div className="card card-sec">
            <div className="p1">
              <span className="summary-title">Quick Links</span>
            </div>
            <div className="d-flex quick-links">
              <div className="one" style={{ opacity: hasAccess("advances", "write") || true ? 1 : 0.5 }} onClick={() => {
                (hasAccess("advances", "write") || true) && history.push("/expenses/advances/my-advances", {
                  createNew: true
                })
              }}>
                <span className="flex-center">
                  <img src={adv} alt="" width={18} />
                </span>
                Create Advances
              </div>
              <div className="two" style={{ opacity: hasAccess("expenses", "write") || true ? 1 : 0.5 }} onClick={() => {
                (hasAccess("expenses", "write") || true) && history.push("/expenses", {
                  createNew: true
                })
              }}>
                <span className="flex-center">
                  <img src={exp} alt="" width={18} />
                </span>
                Create Expenses</div>
              <div className="three" style={{ opacity: hasAccess("expense_reports", "write") || true ? 1 : 0.5 }} onClick={() => {
                (hasAccess("expense_reports", "write") || true) && history.push("/expenses/reports/my-reports", {
                  createNew: true
                })
              }}>
                <span className="flex-center">
                  <img src={report} alt="" width={18} />
                </span>
                Create Reports
              </div>
            </div>
          </div>
        </aside>
      </section>)
  );
};

export default TopSec;
