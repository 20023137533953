import React from "react";
import { useHistory } from "react-router";

const ModulesSubMenu = ({ submenuheader, submenuColumn, hasAccess }) => {
  const history = useHistory();
  return (
    <>
      {hasAccess && <div className="modules_submenu">
        <div className="submenu_header">{submenuheader}</div>
        {submenuColumn?.map(({ title, description, to, hasAccess,moduleIcon }) => hasAccess && (
          <>
            <div className="submenu_content" onClick={() => history.push(to)}>
              <div className="submenu_content_wrapper">
                <div className="submenu_content_circle d-flex-center">
                  {moduleIcon}
                </div>
                <div className="submenu_content_header">{title}</div>
              </div>
              <div className="submenu_content_header">{description}</div>
            </div>
          </>
        ))}
      </div>}
    </>
  );
};

export default ModulesSubMenu;
