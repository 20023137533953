import { Col, Row } from "antd";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { hasAccess } from "../../utils";
import ApprovalAdvance from "./Approvals/ApprovalsAdvance";
import ApprovalsReports from "./Approvals/ApprovalsReports";
import Categories from "./Categories/Categories";
import Advances from "./Customisation/Advances/Advances";
import CostCenter from "./Customisation/CostCenter/CostCenter";
import Expenses from "./Customisation/Expenses/Expenses";
import Projects from "./Customisation/Projects/Projects";
import Reports from "./Customisation/Reports/Reports";
import Trips from "./Customisation/Trips/Trips";
import Organisation from "./Organisation";
import Cards from "./PoliciesLimits/Cards/Cards";
import Expense from "./PoliciesLimits/Expense/Expense";
import CustomField from "./UsersControls/CustomField/CustomField";
import Grades from "./UsersControls/GradesDesignation/Grades";
import Roles from "./UsersControls/Roles/Roles";
import Users from "./UsersControls/Users";
import AddUsers from "./UsersControls/Users/AddUser";
import "./styles/setting.css";
// import Reimbursements from "./Customisation/Reimbursements/Reimbursements";
import ApprovalBills from "./Approvals/AprrovalsBills";
import ApprovalTrip from "./Approvals/AprrovalsTrip";
import Currencies from "./Currencies/Currencies";
import CustomModules from "./Customisation/CustomModules";
import Merchants from "./Customisation/Merchants/Merchants";
import HasPermissions from "./HasPermissions";
import AddNewOrg from "./Organisation/AddNewOrg";
import ManageOrganization from "./Organisation/ManageOrganization";
import Transfer from "./PoliciesLimits/Transfers/Transfer";
import OrganisationHistory from "./RootFi-Integration/OrganisatioHistory";
import RootFiIntegration from "./RootFi-Integration/RootFiIntegration";
import Portal from "./Portal";

// const { SubMenu } = Menu;
// const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub5", "sub6"];

const Settings = () => {
  // const [openKeys, setOpenKeys] = React.useState(["sub2"]);
  // const { t } = useTranslation()
  // const onOpenChange = (keys) => {
  //   const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
  //   if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
  //     setOpenKeys(keys);
  //   } else {
  //     setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  //   }
  // };

  // const renderSettingMenu = () => {
  //   return (
  //     <Menu
  //       mode="inline"
  //       className="setting-nav"
  //       openKeys={openKeys}
  //       onOpenChange={onOpenChange}
  //       style={{ width: 256, padding: "20px 0" }}
  //     >
  //       {hasAccess("organisation_settings", "read") && (
  //         <Menu.Item
  //           key="1"
  //           icon={
  //             <img src={OrganisationIcon} alt={"logo"} className="menu-logo" />
  //           }
  //         >
  //           <Link to={RouteConstants.SETTING_ORGANISATION} />
  //           {t('organization_profile')}
  //         </Menu.Item>
  //       )}
  //       <Menu.Item
  //         key="19"
  //         icon={
  //           <img src={OrganisationIcon} alt={"logo"} className="menu-logo" />
  //         }
  //       >
  //         <Link to={RouteConstants.SETTING_ORGANISATION_MANAGE} />
  //         {t('manage_branches')}
  //       </Menu.Item>
  //       {hasAccess("users", "read") || hasAccess("roles_and_permissions", "read") || hasAccess("department_and_grades", "read") || hasAccess("custom_fields", "read") || hasAccess("users_and_controls", "read") && (
  //         <SubMenu
  //           key="sub2"
  //           icon={
  //             <img
  //               src={Users_controlsIcon}
  //               alt={"logo"}
  //               className="menu-logo"
  //             />
  //           }
  //           title={t('users_and_controls')}
  //         >
  //           {hasAccess("users", "read") && <Menu.Item key="2">
  //             <Link to={RouteConstants.SETTING_USERS} />
  //             {t('users')}
  //           </Menu.Item>}
  //           {hasAccess("roles_and_permissions", "read") && <Menu.Item key="3">
  //             <Link to={RouteConstants.SETTING_ROLES} />
  //             {t('roles')} &amp; {t('permissions')}
  //           </Menu.Item>}
  //           {hasAccess("department_and_grades", "read") && <Menu.Item key="4">
  //             <Link to={RouteConstants.SETTING_GRADES} />
  //             {t('grades')} &amp; {t('destinations')}
  //           </Menu.Item>}

  //           {hasAccess("custom_fields", "read") && <Menu.Item key="5">
  //             <Link to={RouteConstants.SETTING_CUSTOM_FIELD} />
  //             {t('custom_fields')}
  //           </Menu.Item>}
  //         </SubMenu>
  //       )}
  //       {hasAccess("categories", "read") && (
  //         <Menu.Item
  //           key="6"
  //           icon={
  //             <img src={CategoriesIcon} alt={"logo"} className="menu-logo" />
  //           }
  //         >
  //           <Link to={RouteConstants.SETTING_CATEGORIES} />
  //           {t('categories')}
  //         </Menu.Item>
  //       )}
  //       {hasAccess("expense_policy", "read") || hasAccess("cards_policy", "read") || hasAccess("payments_policy", "read") || hasAccess("policies_and_limits", "read") && (
  //         <SubMenu
  //           key="sub4"
  //           icon={
  //             <img
  //               src={Policies_LimitsIcon}
  //               alt={"logo"}
  //               className="menu-logo"
  //             />
  //           }
  //           title="Policies &amp; Limits"
  //         >
  //           {
  //             hasAccess("expense_policy", "read") && <Menu.Item key="7">
  //               <Link to={RouteConstants.SETTING_POLICIES_EXPENSE} />
  //               {t('expense')}
  //             </Menu.Item>}

  //           {hasAccess("cards_policy", "read") && <Menu.Item key="8">
  //             <Link to={RouteConstants.SETTING_POLICIES_CARDS} />
  //             {t('Cards')}
  //           </Menu.Item>}
  //         </SubMenu>
  //       )}
  //       {hasAccess("approvals", "read") && (
  //         <SubMenu
  //           key="sub5"
  //           icon={
  //             <img src={ApprovalsIcon} alt={"logo"} className="menu-logo" />
  //           }
  //           title="Approvals"
  //         >
  //           <Menu.Item key="72">
  //             <Link to={RouteConstants.SETTING_APPROVALS} />
  //             {t('reports')}
  //           </Menu.Item>
  //           <Menu.Item key="82">
  //             <Link to={RouteConstants.SETTING_APPROVALS_TRIP} />
  //             Trips
  //           </Menu.Item>
  //           <Menu.Item key="822">
  //             <Link to={RouteConstants.SETTING_APPROVALS_ADVANCE} />
  //             Advances
  //           </Menu.Item>
  //         </SubMenu>
  //       )}
  //       {hasCustomize() &&
  //         <SubMenu
  //           key="sub6"
  //           icon={<img src={PathIcon} alt={"logo"} className="menu-logo" />}
  //           title="Customisation"
  //         >
  //           {hasAccess("expense_settings", "read") && (
  //             <Menu.Item key="71">
  //               <Link to={RouteConstants.SETTING_CUSTOMISATION_EXPENSES} />
  //               Expenses
  //             </Menu.Item>
  //           )}
  //           {hasAccess("expense_reports_settings", "read") && (
  //             <Menu.Item key="72">
  //               <Link to={RouteConstants.SETTING_CUSTOMISATION_REPORTS} />
  //               Reports
  //             </Menu.Item>
  //           )}
  //           {hasAccess("advances_settings", "read") && (
  //             <Menu.Item key="93">
  //               <Link to={RouteConstants.SETTING_CUSTOMISATION_ADVANCES} />
  //               {t('advances')}
  //             </Menu.Item>
  //           )}
  //           {hasAccess("trips_settings", "read") && (
  //             <Menu.Item key="94">
  //               <Link to={RouteConstants.SETTING_CUSTOMISATION_TRIPS} />
  //               {t('trips')}
  //             </Menu.Item>
  //           )}
  //           {hasAccess("projects", "read") && (
  //             <Menu.Item key="95">
  //               <Link to={RouteConstants.SETTING_CUSTOMISATION_PROJECTS} />
  //               {t('projects')}
  //             </Menu.Item>
  //           )}
  //           {/* <Menu.Item key="97">
  //           <Link to={RouteConstants.SETTING_CUSTOMISATION_REIMBURSEMENTS} />
  //           Reimbursements
  //         </Menu.Item> */}
  //           {hasAccess("cost_center", "read") && (
  //             <Menu.Item key="931">
  //               <Link to={RouteConstants.SETTING_CUSTOMISATION_COSTCENTER} />
  //               Cost Center
  //             </Menu.Item>
  //           )}
  //           {hasAccess("merchants", "read") && (
  //             <Menu.Item key="99">
  //               <Link to={RouteConstants.SETTING_CUSTOMISATION_MERCHANT} />
  //               {t('merchants')}
  //             </Menu.Item>
  //           )}
  //         </SubMenu>}
  //     </Menu>
  //   );
  // };

  return (
    <Row>
      {/* <Col flex="256px">{renderSettingMenu()}</Col> */}
      <Col flex="1 1">
        <div className="content-div">
          <Switch>
            <Route path="/settings" exact component={() => <HasPermissions role={"organisation_settings"} name="Organisation Profile">
              <Organisation />
            </HasPermissions>} />
            <Route
              exact
              path="/settings/profile/"
              component={() => <HasPermissions role={"organisation_settings"} name="Organisation Profile">
                <Organisation hasWriteAccess={hasAccess("organisation_settings", "write")} />
              </HasPermissions>}
            />
            {/* Rootfi Routes */}
            <Route path="/settings/rootFi-integration" component={() => <RootFiIntegration hasWriteAccess={hasAccess("integrations", "write")} />} />
            <Route exact path="/settings/rootFi-integration-history" component={() => <OrganisationHistory />} />

            <Route
              path="/settings/currencies/"
              component={() => <Currencies hasWriteAccess={hasAccess("currencies", "write")} />}
            />
            <Route path="/settings/branches/add" component={() => <AddNewOrg />} />
            <Route
              path="/settings/branches"
              component={() => <ManageOrganization hasWriteAccess={[null, "null"].includes(localStorage?.getItem("parentID")) ? true : false} />}
            />
            <Route path="/settings/users/" exact component={() => <Users hasWriteAccess={hasAccess("users", "write")} />} />
            <Route
              path="/settings/users/add_user/"
              component={() => <AddUsers />}
            />
            <Route path="/settings/roles/" component={() => <Roles hasWriteAccess={hasAccess("roles_and_permissions", "write")} />} />
            <Route path="/settings/grades/" component={() => <Grades hasWriteAccess={hasAccess("department_and_grades", "write")} />} />
            <Route
              path="/settings/customfields/"
              component={() => <CustomField hasWriteAccess={hasAccess("custom_fields", "write")} />}
            />
            <Route
              path="/settings/categories/"
              component={() => <Categories hasWriteAccess={hasAccess("categories", "write")} />}
            />
            <Route
              path="/settings/policies/expense/"
              component={() => <Expense hasWriteAccess={hasAccess("expense_policy", "write")} />}
            />
            <Route
              path="/settings/policies/transfers/"
              component={() => <Transfer hasWriteAccess={hasAccess("payments_policy", "write")} />}
            />
            <Route
              path="/settings/policies/cards/"
              component={() => <Cards hasWriteAccess={hasAccess("cards_policy", "write")} />}
            />
            <Route
              path="/settings/approvals/reports"
              component={() => <ApprovalsReports hasWriteAccess={hasAccess("reports_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/bills"
              component={() => <ApprovalBills inv_type={"BILL"} type={"BILLING_APPROVAL"} hasWriteAccess={hasAccess("bills_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/invoice"
              component={() => <ApprovalBills invoice={true} inv_type={"INVOICE"} type={"INVOICE_APPROVAL"} hasWriteAccess={hasAccess("invoices_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/material"
              component={() => <ApprovalBills isMaterial={true} inv_type={"MATERIAL_RECEIPT"} type={"MATERIAL_RECEIPT"} hasWriteAccess={hasAccess("material_receipt_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/delivery"
              component={() => <ApprovalBills isDelivery={true} inv_type={"DELIVERY_CHALLAN"} type={"DELIVERY_CHALLAN"} hasWriteAccess={hasAccess("delivery_challan_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/advances/"
              component={() => <ApprovalAdvance hasWriteAccess={hasAccess("advances_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/trips/"
              component={() => <ApprovalTrip hasWriteAccess={hasAccess("trips_approvals_settings", "write")} />}
            />

            <Route
              path="/settings/customisation/"
              exact
              component={() => <Expenses />}
            />
            <Route
              path="/settings/customisation/expenses/"
              component={() => <Expenses hasWriteAccess={hasAccess("expense_settings", "write")} type="EXPENSE" />}
            />
            <Route
              path="/settings/customisation/reports/"
              component={() => <Reports hasWriteAccess={hasAccess("reports_settings", "write")} />}
            />
            <Route
              path="/settings/customisation/advances/"
              component={() => <Advances hasWriteAccess={hasAccess("advances_settings", "write")} type={"ADVANCE"} />}
            />
            <Route
              path="/settings/customisation/trips/"
              component={() => <Advances hasWriteAccess={hasAccess("trips_settings", "write")} type={"TRIP"} />}
            />
            <Route
              path="/settings/modules/bills/"
              component={() => <Trips type={"bills"} moduleName={"BILL_MODULE"} hasWriteAccess={hasAccess("bills_settings", "write")} module={"BILL"} />}
            />
            <Route
              path="/settings/modules/purchase_orders/"
              component={() => <Trips type="purchase_orders" moduleName={"PURCHASE_ORDER_MODULE"} hasWriteAccess={hasAccess("purchase_orders_settings", "write")} module={"PURCHASE_ORDER"} />}
            />
            <Route
              path="/settings/modules/invoices/"
              component={() => <Trips type="invoices" moduleName={"INVOICE_MODULE"} hasWriteAccess={hasAccess("invoices_settings", "write")} module={"INVOICE"} />}
            />
            <Route
              path="/settings/modules/delivery/"
              component={() => <Trips type="delivery_challan" moduleName={"DELIVERY_CHALLAN"} hasWriteAccess={hasAccess("delivery_challan_settings", "write")} module={"DELIVERY_CHALLAN"} />}
            />
            <Route
              path="/settings/modules/payment-received/"
              component={() => <Trips type="payment-receive" moduleName={"PAYMENT_MODULE"} hasWriteAccess={hasAccess("payment_received_settings", "write")} module={"INVOICE_RECEIPT"} />}
            />
            <Route
              path="/settings/modules/credit-note/"
              component={() => <Trips type="credit-note" moduleName={"CREDIT_MODULE"} hasWriteAccess={hasAccess("credit_note_settings", "write")} module={"CREDIT"} />}
            />
            <Route
              path="/settings/modules/payment-made/"
              component={() => <Trips type="payment-made" moduleName={"PAYMENT_MADE"} hasWriteAccess={hasAccess("payment_made_settings", "write")} module={"BILL_RECEIPT"} />}
            />
            <Route
              path="/settings/modules/material/"
              component={() => <Trips type="material_receipt" moduleName={"MATERIAL_RECEIPT"} hasWriteAccess={hasAccess("material_receipt_settings", "write")} module={"MATERIAL_RECEIPT"} />}
            />
            <Route
              path="/settings/modules/qc-check/"
              component={() => <Trips type="qc_check" moduleName={"QUALITY_CHECK"} hasWriteAccess={hasAccess("quality_check_settings", "write")} module={"QUALITY_CHECK"} />}
            />
            <Route
              path="/settings/modules/returns/"
              component={() => <Trips type="returns" moduleName={"RETURN_CHECK"} hasWriteAccess={hasAccess("returns_settings", "write")} module={"RETURN_CHECK"} />}
            />
            <Route
              path="/settings/modules/sales_order/"
              component={() => <Trips type="sales_order" moduleName={"SALES_ORDER_MODULE"} hasWriteAccess={hasAccess("sales_orders_settings", "write")} module={"SALES_ORDER"} />}
            />
            <Route
              path="/settings/modules/estimates/"
              component={() => <Trips type="estimates" moduleName={"ESTIMATE_MODULE"} hasWriteAccess={hasAccess("estimates_settings", "write")} module={"ESTIMATE"} />}
            />
            <Route
              path="/settings/customisation/projects/"
              component={() => <Projects hasWriteAccess={hasAccess("projects", "write")} />}
            />
            {/* <Route
              path="/settings/customisation/reimbursements/"
              component={() => <Reimbursements />}
            /> */}
            <Route
              path="/settings/customisation/cost_center/"
              component={() => <CostCenter hasWriteAccess={hasAccess("cost_center", "write")} />}
            />
            <Route
              path="/settings/customisation/merchant/"
              component={() => <Merchants hasWriteAccess={hasAccess("merchants", "write")} />}
            />
            <Route
              path="/settings/customisation/items/"
              component={() => <Merchants type={"items"} hasWriteAccess={hasAccess("items", "write")} />}
            />
            {/* <Route path = "/settings/customisation/templates"
             component={() => <Templates type={"templates"} />}
             /> */}
            <Route
              path="/settings/customisation/taxes/"
              component={() => <Merchants type={"taxes"} hasWriteAccess={hasAccess("taxes", "write")} />}
            />
            <Route
              path="/settings/customisation/tds/"
              component={() => <Merchants type={"tds"} hasWriteAccess={hasAccess("tds", "write")} />}
            />
            <Route
              path="/settings/customisation/unit/"
              component={() => <Merchants type={"unit"} hasWriteAccess={true} />}
            />
            <Route
              path="/settings/customisation/warehouse/"
              component={() => <Merchants type={"warehouse"} hasWriteAccess={true} />}
            />
            <Route
              path="/settings/approvals/sales_order"
              component={() => <ApprovalBills type={"SALES_ORDER_APPROVAL"} inv_type={"SALES_ORDER"} hasWriteAccess={hasAccess("sales_order_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/estimation"
              component={() => <ApprovalBills type={"ESTIMATE_APPROVAL"} inv_type={"ESTIMATE"} hasWriteAccess={hasAccess("estimates_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/purchase_orders"
              component={() => <ApprovalBills type={"PURCHASE_ORDER_APPROVAL"} inv_type={"PURCHASE_ORDER"} hasWriteAccess={hasAccess("purchase_orders_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/company_payments"
              component={() => <ApprovalBills type={"PAYMENT_APPROVAL"} inv_type={"COMPANY_PAYMENTS"} hasWriteAccess={hasAccess("payment_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/approvals/company_bulk_payments"
              component={() => <ApprovalBills type={"BULK_PAYMENT_APPROVAL"} inv_type={"COMPANY_BULK_PAYMENTS"} hasWriteAccess={hasAccess("bulk_payment_approvals_settings", "write")} />}
            />
            <Route
              path="/settings/customisation/custom_modules"
              component={() => <CustomModules type={"custommodules"} hasWriteAccess={hasAccess("module_customisations", "write")} />}
            />
            <Route
              path="/settings/customer/portal"
              component={() => <Portal isCustomer={true} hasWriteAccess={hasAccess("customer_portal_settings", "write")} />}
            />
            <Route
              path="/settings/vendor/portal"
              component={() => <Portal isVendor={true} hasWriteAccess={hasAccess("vendor_portal_settings", "write")} />}
            />
          </Switch>
        </div>
      </Col>
    </Row>
  );
};

export default Settings;
