import { InboxOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, message, Select, Upload } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import {
  AddUserAdvance,
  addUserAdvance,
  AdvancesExpDetails,
  advancesExpDetails,
  UpdateUserAdvance,
  updateUserAdvance,
  userAdvanceFields,
} from "../../actions/advances";
import { deleteEmployeeDocument } from "../../actions/employees";
import {
  ExpenseCustomDetails,
  expenseCustomDetails,
  prefixexpenseFields,
} from "../../actions/expenses/expenses";
import excel_icon from "../../assets/icons/excel1.png";
import pdf_icon from "../../assets/icons/pdf_icon.png";
import ppt_icon from "../../assets/icons/ppticon.png";
import { apis } from "../../config/APIs";
import useDocumentAxios from "../../config/useDocument";
import useScrollToError from "../../config/useScrollToError";
import { getFileExtension, notNumber } from "../../utils";
const { Dragger } = Upload;
const { Option } = Select;

const AddAdvance = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [image, setAdvanceImage] = useState("");
  const [uploadDetails, setUploadDetails] = React.useState({});
  const [uploadData, setUploadData] = React.useState({});

  const userAdvanceFieldsResponse = useSelector(
    (state) => state.advances.userAdvanceFields || {}
  );

  const addUserAdvanceResponse = useSelector(
    (state) => state.advances.addUserAdvance
  );
  const editRes = useSelector((state) => state.advances.updateUserAdvance);
  const { data: { data: fields = [] } = {} } = userAdvanceFieldsResponse;

  const prefixResponse = useSelector(
    (state) => state.expenses?.prefixexpenseFields?.data?.["ADVANCE"]
  );

  const getAdvanceDetailsByIdResponse = useSelector(
    (state) => state?.advances?.advancesExpDetails
  );

  const deleteEmployeeResponse = useSelector(
    (state) => state?.employees?.deleteEmployeeDocument
  );

  const advanceCustomData = useSelector(
    (state) => state?.expenses?.expenseCustomDetails?.data?.data || []
  );

  const { onCall, loading } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
  });

  const [validationSchema, setValidationSchema] = React.useState(null);
  const validationSchema1 = yup.object().shape({
    title: yup
      .string()
      .matches(/^[a-zA-Z]+[a-zA-Z0-9\s]*$/, "Please Enter Proper Name")
      .required("Title is required"),
    amount_cents: yup
      .string()
      .required("Amount is required")
      .test(
        "greaterThanZero",
        "Amount must be greater than 0",
        (value) => parseFloat(value) > 0
      ),
    description: yup.string().required("Description is required"),
    advance_date: yup.string().required("Date and Time is Required"),
    reference_no: yup.string().required("Reference is Required"),
    ...validationSchema,
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      amount_cents: "",
      advance_date: "",
      reference_no: "",
      description: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema1,
    onSubmit: (vals) => {
      const payload = { ...vals };

      if (props.advanceData?.id) {
        const custom_fields = advanceCustomData.map(
          ({ field_id, column_name, column_type }) => {
            let master_custom_field_id = field_id;
            let module_name = "ADVANCE";
            let field_type = column_type;
            let value1 = formik.values[field_id];
            let value =
              column_type !== "datetime"
                ? value1
                : moment(value1).format("DD-MM-YYYY HH:mm:ss");
            let field_name = column_name;
            delete vals?.[field_id];
            // return { id, value, field_name };
            delete payload?.[field_id];
            if (column_type === "document" && value) {
              let attachment_id = uploadDetails[field_id]?.attachmentId;
              return {
                master_custom_field_id,
                field_name,
                field_type,
                attachment_id,
                module_name,
              };
            } else if (value) {
              return {
                master_custom_field_id,
                value,
                field_name,
                field_type,
                module_name,
              };
            }
          }
        );
        payload["custom_fields"] = custom_fields.filter(Boolean) || [];
      } else {
        const custom_fields = fields.map(({ id, column_name, column_type }) => {
          let master_custom_field_id = id;
          let module_name = "ADVANCE";
          let field_type = column_type;
          let value1 = formik.values?.[id];
          let value =
            column_type === "document"
              ? uploadDetails[id]?.attachmentId
              : column_type === "datetime"
                ? moment(value1).format("DD-MM-YYYY HH:mm:ss")
                : value1;
          let field_name = column_name;
          delete payload?.[id];
          if (column_type === "document") {
            let attachment_id = uploadDetails[id]?.attachmentId;
            return {
              master_custom_field_id,
              field_name,
              field_type,
              attachment_id,
              module_name,
            };
          } else if (value) {
            return {
              master_custom_field_id,
              value,
              field_name,
              field_type,
              module_name,
            };
          }
        });
        payload["custom_fields"] = custom_fields.filter(Boolean) || [];
      }
      // props.advanceData?.id ? payload.id = props?.advanceData?.id: null
      if (props.advanceData?.id) {
        payload.id = props?.advanceData?.id;
        dispatch(
          updateUserAdvance({ ...payload, params: { module_type: "ADVANCE" } })
        );
      } else {
        dispatch(addUserAdvance({ ...payload }));
      }
    },
  });

  // Use the custom hook
  useScrollToError(Object.keys(formik?.errors)[0], formik?.errors, formik?.submitCount);

  const callOnMount = () => {
    if (props.advanceData?.id) {
      dispatch(
        advancesExpDetails({
          advance_id: props.advanceData?.id,
          module_type: "ADVANCE",
          custom_flag: true,
        })
      );
      dispatch(
        expenseCustomDetails({
          module_type: "ADVANCE",
          module_id: props.advanceData?.id,
        })
      );
    } else {
      dispatch(
        userAdvanceFields({ module_type: "ADVANCE", custom_flag: true })
      );
      dispatch(prefixexpenseFields({ params: { module_type: "ADVANCE" } }));
    }
  };

  React.useEffect(() => {
    callOnMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const { data: { status = false } = {} } = addUserAdvanceResponse;
    if (status) {
      formik.resetForm();
      dispatch({ type: AddUserAdvance.RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserAdvanceResponse]);

  React.useEffect(() => {
    let obj = {};
    advanceCustomData.forEach((ele) => {
      if (ele.column_type === "document") {
        setUploadDetails((prevUploadDetails) => {
          return {
            ...prevUploadDetails,
            [ele.field_id]: {
              attachmentId: ele.attachments.map((ele) => ele.blob_id),
              recordId: ele.field_id,
            },
          };
        });
      }
    });

    advanceCustomData.forEach((ele) => {
      if (ele.column_type === "document") {
        setUploadData((b) => {
          return {
            ...b,
            [ele.field_id]: {
              attachmentId: ele.attachments.map((ele) => ele.blob_id),
              recordId: ele.field_id,
            },
          };
        });
      }
    });
    advanceCustomData?.forEach((each_custom_field) => {
      // obj[each_custom_field.field_id] = each_custom_field?.value;
      let blobids = each_custom_field?.attachments?.map((item) => item.blob_id);
      if (each_custom_field.column_type === "document") {
        blobids?.length
          ? formik.setFieldValue(each_custom_field?.field_id, blobids)
          : formik.setFieldValue(each_custom_field?.field_id, "");
        // formik.setFieldValue(each_custom_field?.field_id, blobids);
      } else if (each_custom_field.column_type === "datetime") {
        formik.setFieldValue(
          each_custom_field?.field_id,
          moment(each_custom_field?.value, "DD-MM-YYYY HH:mm:ss")
        );
      } else {
        formik.setFieldValue(
          each_custom_field?.field_id,
          each_custom_field?.value
        );
      }
    });
    const { data: { data: advanceData = {} } = {} } =
      getAdvanceDetailsByIdResponse;
    // expenseCustomData?.forEach((each_custom_field) => {
    //   // obj[each_custom_field.field_id] = each_custom_field?.value;
    //   formik.setFieldValue(
    //     each_custom_field?.field_id,
    //     each_custom_field?.value
    //   );
    // });
    if (getAdvanceDetailsByIdResponse?.data?.error === false) {
      [
        "title",
        "amount_cents",
        "advance_date",
        "reference_no",
        "description",
      ]?.forEach((ele) => {
        if (ele === "advance_date") {
          formik.setFieldValue(
            ele,
            moment(advanceData[`${ele}`], "YYYY-MM-DD HH:mm:ss")
          );
        } else {
          formik.setFieldValue(ele, advanceData[`${ele}`]);
        }
      });
    }
  }, [getAdvanceDetailsByIdResponse]);

  React.useEffect(() => {
    // if (fields && fields?.length > 0 && props.advanceData?.id) {
    //   let arr = {}
    //   fields.forEach((item) => {
    //     arr[item.field_id] = item.field_type === "dropdown" && item.selected.id ? item?.selected?.id : item?.selected?.value
    //   })
    //   formik.setValues({
    //     ...arr,
    //   })
    // }

    if (fields && fields?.length) {
      let obj = {};
      let obj2 = {};
      fields.forEach((item) => {
        if (item.is_required) {
          obj2[item.id] = "";
          if (item.column_type === "document") {
            obj[item.id] = yup
              .array()
              .of(yup.string())
              .required(`${item.display_name} is required`);
          } else {
            obj[item.id] = yup
              .string()
              .required(`${item.display_name} is required`);
          }
        }
      });
      if (!props.advanceData?.id)
        formik.setValues({
          ...obj2,
          ...formik.values,
        });
      setValidationSchema(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  console.log({ values: formik.values }, "formsda===>");

  React.useEffect(() => {
    if (advanceCustomData && advanceCustomData?.length) {
      let obj = {};
      let obj2 = {};
      advanceCustomData?.forEach((item) => {
        if (item.is_required) {
          obj2[item.field_id] =
            props.advanceData?.id?.[item?.field_id] ||
            formik.values[item.field_id] ||
            "";
          if (item.column_type === "document") {
            obj[item.field_id] = yup
              .array()
              .of(yup.string())
              .required(`${item.display_name} is required`);
          } else {
            obj[item.field_id] = yup
              .string()
              .required(`${item.display_name} is required`);
          }
        }
      });

      if (!!props.advanceData?.id) {
        Object.keys(obj2)?.map((ele) => {
          formik.setFieldValue(ele, obj2[ele]);
        });
        // formik.setValues({
        //   ...obj2,
        //   ...arr,
        // });
      }

      setValidationSchema(obj);
    }
  }, [advanceCustomData]);

  React.useEffect(() => {
    if (prefixResponse) {
      formik.setFieldValue("advance_number", prefixResponse);
    } else if (prefixResponse === false) {
      formik.setFieldError("advance_number", "error");
    }
  }, [prefixResponse]);

  React.useEffect(() => {
    if (editRes?.data?.error === false) {
      handleCancelButton();
      // callOnMount()
      message.success(
        <span className="messageText">{editRes.data?.message}</span>
      );
      formik.resetForm({});
      dispatch({
        type: UpdateUserAdvance.RESET,
      });
      dispatch({
        type: AdvancesExpDetails.RESET,
      });

      dispatch({
        type: ExpenseCustomDetails.RESET,
      });
    }

    if (editRes?.data?.error) {
      dispatch({
        type: AdvancesExpDetails.RESET,
      });
      message.error(
        <span className="messageText">{editRes.data?.message}</span>
      );
      formik.resetForm({});
    }
    // eslint-disable-next-line
  }, [editRes]);
  const handleCancelButton = () => {
    formik.resetForm();
    props.handleCancel();
    dispatch({
      type: ExpenseCustomDetails.RESET,
    });
    dispatch({
      type: AdvancesExpDetails.RESET,
    });
  };

  const handleChange = (val, id) => {
    formik.setFieldValue(id, val);
  };

  React.useEffect(() => {
    if (props.advanceData?.id) {
      fields?.map((item) => {
        if (item?.field_type === "document" && item?.selected?.value) {
          const existImage = image?.findIndex(
            (eachImg) => eachImg.id === item.field_id
          );
          if (existImage !== -1) {
            setAdvanceImage((prev) => {
              const updateImg = [...prev];
              updateImg[existImage].url = item?.selected?.value;
              return updateImg;
            });
          } else {
            setAdvanceImage((b) => [
              ...b,
              { url: item?.selected?.value, id: item.field_id },
            ]);
          }
        }
      });
    }
  }, [fields]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleUploadFile = (uploadFile, id) => {
    if (uploadFile.file.status === "error") {
      getBase64(uploadFile.file.originFileObj, (url) => {
        formik.setFieldValue(id, url);
        const existImage = image.findIndex((eachImg) => eachImg.id === id);
        if (existImage !== -1) {
          setAdvanceImage((prev) => {
            const updateImg = [...prev];
            updateImg[existImage].url = url;
            return updateImg;
          });
        } else {
          setAdvanceImage((b) => [...b, { url: url, id: id }]);
        }
      });
    }
  };

  const onDocumentFileChange = async (info, name) => {
    if (info.file.status === "removed") {
      getAdvanceDetailsByIdResponse?.data?.data?.id && info.file.blob_id
        ? dispatch(
          deleteEmployeeDocument({
            record_id: info?.file?.record_id,
            ids: [info?.file?.id],
          })
        )
        : dispatch(
          deleteEmployeeDocument({
            record_id: uploadData[info?.file?.uid].recordId,
            ids: [uploadData[info?.file?.uid].attachment_id],
          })
        );
      let recordfile = "";
      getAdvanceDetailsByIdResponse?.data?.data?.id && info.file.blob_id
        ? (recordfile = info.file.blob_id)
        : (recordfile = uploadData[info?.file?.uid].attachment_id);

      const customFieldDocumentValue = {
        ...uploadDetails[name],
        attachmentId: uploadDetails[name].attachmentId.filter(
          (val) => val !== recordfile
        ),
      };

      setUploadDetails((b) => ({
        ...b,
        [name]: {
          attachmentId: [
            ...b[name].attachmentId.filter((val) => val !== recordfile),
          ],
        },
      }));
      formik.setFieldValue(
        name,
        customFieldDocumentValue?.attachmentId?.length
          ? customFieldDocumentValue?.attachmentId
          : ""
      );
    } else {
      const nextState = {};
      nextState.selectedFile = info.file;
      nextState.selectedFileList = [info.file];
      let uniqueNo = getAdvanceDetailsByIdResponse?.data?.data?.id
        ? getAdvanceDetailsByIdResponse?.data?.data?.id
        : uuidv4();
      const formData = new FormData();
      formData.append("files", info?.file);
      formData.append("name", info.file.name);
      formData.append("record_type", "Document");
      formData.append("record_id", uniqueNo);

      const attachId = await onCall({
        data: formData,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });

      formik.setFieldValue(name, [attachId?.data?.[0]]);
      setUploadDetails((b) => {
        if (Object.keys(b).includes(name)) {
          return {
            ...b,
            [name]: {
              ...b[name],
              attachmentId: [...b[name].attachmentId, attachId?.data?.[0]],
            },
          };
        } else {
          return {
            ...b,
            [name]: {
              attachmentId: [attachId?.data?.[0]],
            },
          };
        }
      });

      setUploadData((b) => {
        return {
          ...b,
          [info?.file?.uid]: {
            attachment_id: attachId?.data?.[0],
            recordId: uniqueNo,
          },
        };
      });
    }
  };

  const renderDataTypes = (
    value,
    default_values,
    id,
    val,
    attacment,
    attachmentType,
    attachment_size
  ) => {
    switch (value) {
      case "string":
        return (
          <>
            <Input
              placeholder={t("enter_val")}
              value={formik.values?.[id]}
              style={{ minWidth: "216px" }}
              onChange={(e) => {
                handleChange(e.target.value.trimStart(), id);
              }}
              disabled={id === "advance_number"}
            />
            <div className="error">
              {formik.submitCount > 0 && formik.errors?.[id]}
            </div>
          </>
        );
      case "integer":
        return (
          <>
            <Input
              placeholder={t("enter_val")}
              type="text"
              value={formik.values?.[id]}
              style={{ minWidth: "216px" }}
              onChange={(e) => {
                if (
                  e.target.value?.split?.(".")?.length > 0 &&
                  e.target.value?.split?.(".")?.[1]?.length > 2
                ) {
                  return;
                }

                if (notNumber(e.target.value?.trimStart())) {
                  return;
                }
                handleChange(e.target.value.trimStart(), id);
              }}
            />
            <div className="error">
              {formik.submitCount > 0 && formik.errors?.[id]}
            </div>
          </>
        );
      case "dropdown":
        return (
          <>
            <Select
              style={{ width: 200 }}
              placeholder={t("select")}
              value={formik.values?.[id]}
              onChange={(val) => {
                handleChange(val, id);
              }}
            >
              {default_values &&
                default_values.map((value) => (
                  <Option key={id} value={value}>
                    {value}
                  </Option>
                ))}
            </Select>
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "datetime":
        return (
          <>
            <DatePicker
              value={
                formik.values?.[id]
                  ? moment(formik.values?.[id], "YYYY-MM-DD HH:mm:ss")
                  : null
              }
              onChange={(date, dateString) => {
                handleChange(dateString, id);
              }}
              showTime
            />
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      case "document":
        return (
          // <>
          //   <Dragger
          //     value={formik.values?.[id] ? formik.values?.[id] : ""}
          //     name='document'
          //     maxCount={1}
          //     // onChange={(val) => {
          //     //   const jsonString = JSON.stringify(val.file);
          //     //   const base64String = Buffer.from(jsonString).toString('base64');
          //     //   handleChange(val.file ? base64String : null, id)
          //     // }}
          //     onChange={(val)=>{handleUploadFile(val,id)}}
          //   >
          //     <p className="ant-upload-text"><InboxOutlined />{t('drag_png')}<br />{t('click_here_to')}</p>
          //   </Dragger>
          //   <div className="error">
          //     {formik.touched?.[id] && formik.errors?.[id]}
          //   </div>
          // </>
          <>
            <Dragger
              name="document"
              listType="picture"
              showUploadList={{
                showDownloadIcon: true,
                downloadIcon: "Download",
                showRemoveIcon: true,
              }}
              beforeUpload={(file) => {
                const fileName = getFileExtension(file?.name);
                const maxSize = attachment_size;
                const allowedImages = attachmentType.split(",");
                const uppercaseImages = allowedImages.map((image) =>
                  image.trim().toUpperCase()
                );
                const imagetype = uppercaseImages.join(",  ");
                if (!allowedImages?.includes(fileName)) {
                  message.error(
                    <span className="messageText">
                      You can only upload {imagetype}.
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                } else if (file.size > maxSize * 1024) {
                  message.error(
                    <span className="messageText">
                      File size must be smaller than {attachment_size} KB
                    </span>
                  );
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
              disabled={loading || deleteEmployeeResponse.loading}
              defaultFileList={attacment}
              onChange={(evt) => onDocumentFileChange(evt, id)}
              value={formik.values?.[id] ? formik.values?.files?.id : ""}
            >
              {image ? (
                <img
                  src={image}
                  alt="testing"
                  style={{ width: "150px", height: "100px" }}
                />
              ) : (
                <p className="ant-upload-text">
                  <InboxOutlined />
                  {t("drag_attachment")}
                  <br />
                  {t("click_here_to")}
                </p>
              )}
              {/* {image?.find((val) => val?.id === id)?.id ? <img src={image?.find((val) => val?.id === id)?.url} alt='test' style={{ width: "150px", height: "100px" }} /> : <p className="ant-upload-text"><InboxOutlined />{t('drag_png')}<br />{t('click_here_to')}</p>} */}
            </Dragger>
            <div className="error">
              {formik.touched?.[id] && formik.errors?.[id]}
            </div>
          </>
        );
      default:
      // code block
    }
  };

  return (
    <>
      <form className="modal-form" onSubmit={formik.handleSubmit}>
        <div className="nameItem">
          <label htmlFor="advance_number" className="inline-block required">
            Advance Id
          </label>
          <Input
            placeholder={t("enter_val")}
            value={formik.values?.advance_number}
            style={{ minWidth: "216px" }}
            disabled={true}
          />
          <div className="error">
            {formik.errors?.advance_number && (
              <span style={{ color: "red" }}>
                Please specify the prefix value in Advance customization
              </span>
            )}
          </div>
        </div>
        <div className="nameItem">
          <label htmlFor="title" className="inline-block required">
            Purpose/Title
          </label>
          <Input
            placeholder={t("enter_val")}
            value={formik.values?.title}
            style={{ minWidth: "216px" }}
            onChange={(e) => handleChange(e.target.value, "title")}
          />
          <div className="error">
            {formik.touched?.title && formik.errors?.title}
          </div>
        </div>
        <div className="nameItem">
          <label htmlFor="amount_cents" className="inline-block required">
            Amount
          </label>
          <Input
            placeholder={t("enter_val")}
            value={formik.values?.amount_cents}
            style={{ minWidth: "216px" }}
            onChange={(e) => {
              if (
                e.target.value?.split?.(".")?.length > 0 &&
                e.target.value?.split?.(".")?.[1]?.length > 2
              ) {
                return;
              }

              if (notNumber(e.target.value?.trimStart())) {
                return;
              }
              handleChange(e.target.value, "amount_cents");
            }}
          />
          <div className="error">
            {formik.touched?.amount_cents && formik.errors?.amount_cents}
          </div>
        </div>
        <div className="nameItem">
          <label htmlFor="advance_date" className="inline-block required">
            Date And Time
          </label>
          <DatePicker
            placeholder={t("enter_val")}
            value={formik.values?.advance_date}
            style={{ minWidth: "30rem" }}
            onChange={(evt) => {
              handleChange(evt, "advance_date");
            }}
            showTime
            id="advance_date"
          />
          <div className="error">
            {formik.touched?.advance_date && formik.errors?.advance_date}
          </div>
        </div>
        <div className="nameItem">
          <label htmlFor="reference_no" className="inline-block required">
            Reference#
          </label>
          <Input
            placeholder={t("enter_val")}
            value={formik.values?.reference_no}
            style={{ minWidth: "216px" }}
            onChange={(e) => handleChange(e.target.value, "reference_no")}
          />
          <div className="error">
            {formik.touched?.reference_no && formik.errors?.reference_no}
          </div>
        </div>
        <div className="nameItem">
          <label htmlFor="description" className="inline-block required">
            Description
          </label>
          <Input
            placeholder={t("enter_val")}
            value={formik.values?.description}
            style={{ minWidth: "216px" }}
            onChange={(e) => handleChange(e.target.value, "description")}
          />
          <div className="error">
            {formik.touched?.description && formik.errors?.description}
          </div>
        </div>
        {props.advanceData?.id
          ? advanceCustomData &&
          advanceCustomData.map(
            (item) =>
              item.field_id !== "payment_method" && (
                <div className="nameItem">
                  <label
                    className={`inline-block ${item.is_required ? "required" : ""
                      }`}
                  >
                    {item.display_name}
                  </label>
                  <br />
                  {renderDataTypes(
                    item.column_type,
                    item.default_values,
                    item.field_id,
                    item.vlaue,
                    item.attachments?.map((ele) => ({
                      ...ele,
                      url: ele.attachment_url,
                      key: ele.blob_id,
                      name: ele.filename,
                      thumbUrl: ele?.content_type?.includes("image/")
                        ? null
                        : ele.content_type === "application/pdf"
                          ? pdf_icon
                          : ele?.content_type ===
                            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            ? ppt_icon
                            : excel_icon,
                    })),
                    item.data.doc_formats,
                    item.data.max_size
                  )}
                </div>
              )
          )
          : fields.length > 0 &&
          fields.map(
            (item) =>
              item.field_id !== "payment_method" && (
                <div className="nameItem">
                  <label
                    className={`inline-block ${item.is_required ? "required" : ""
                      }`}
                  >
                    {item.display_name}
                  </label>
                  <br />
                  {renderDataTypes(
                    item.column_type,
                    item.default_values,
                    item.id,
                    item.column_name,
                    item.attachment,
                    item.data.doc_formats,
                    item.data.max_size
                  )}
                </div>
              )
          )}
        <div>
          <Button
            key="1"
            htmlType="submit"
            className="formButton mt3 primaryButton"
            disabled={
              loading ||
              deleteEmployeeResponse.loading ||
              addUserAdvanceResponse?.loading
            }
          >
            {t("Save")}
          </Button>
          <Button
            key="2"
            htmlType="button"
            className="cancelButton ml1 mt3 "
            onClick={() => handleCancelButton()}
          >
            {t("cancel")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddAdvance;
