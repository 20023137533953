import { takeLatest } from 'redux-saga/effects';
import { apis } from '../../config/APIs';
import { sagaFunctions } from '../../utils';
import { GetPageDetails, GetSummary, ImportApi } from './imports';

function getPageDetails(req) {
    return sagaFunctions(GetPageDetails, "get", apis?.getPageDetails, req.payload, { 'Content-Type': 'application/json' }, "import")();
}
function getSummary(req) {
    return sagaFunctions(GetSummary, "post", apis?.getSummary, req.payload, { 'Content-Type': 'application/json' }, "import")();
}
function importApi(req) {
    return sagaFunctions(ImportApi, "post", apis?.importApi, req.payload, { 'Content-Type': 'application/json' }, "import")();
}

export function* importWatcher() {
    yield takeLatest(GetPageDetails.REQUEST, getPageDetails)
    yield takeLatest(GetSummary.REQUEST, getSummary)
    yield takeLatest(ImportApi.REQUEST, importApi)
}