import { Collapse, Switch } from 'antd';
import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormInput, FormSelect } from '../inputs';
import { countries } from "../Settings/Organisation/country";
import { useTranslation } from "react-i18next";

const ShippingAddress = ({ control, errors, register, shipping_address }) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (shipping_address?.length === 0)
      append({
        country: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        phone: '',
        address_type: "shipping"
      })
  }, [])

  const { append, fields } = useFieldArray({
    control,
    name: 'shipping_address',
  });

  return (
    <>
      {fields.map((personData, index) => (
        <div key={`shipping-${personData.key}`}>
          <div>
            <FormSelect
              control={control}
              {...register(`shipping_address.${index}.country`)}
              label={t('country_region')}
              placeholder={t('select')}
              options={countries}
              hideCreateOption={true}
              errorMessage={errors.shipping_address?.[index]?.['country']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.address`)}
              label={t('address')}
              placeholder={t('street')}
              errorMessage={errors.shipping_address?.[index]?.['address']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.city`)}
              label={t('city')}
              errorMessage={errors.shipping_address?.[index]?.['city']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.state`)}
              label={t('state')}
              errorMessage={errors.shipping_address?.[index]?.['state']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.zipcode`)}
              label="Zip"
              errorMessage={errors.shipping_address?.[index]?.['zipcode']?.['message']}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.phone`)}
              label={t('phone')}
              errorMessage={errors.shipping_address?.[index]?.['phone']?.['message']}
            />
          </div>

          {/* <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`shipping_address.${index}.fax`)}
              label="Fax"
              errorMessage={errors.shipping_address?.[index]?.['fax']?.['message']}
            />
          </div> */}
        </div>
      ))}
    </>
  );
};

export default ShippingAddress;
