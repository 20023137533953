import { Button, Input, message, Modal } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { apis } from "../../config/APIs";
import endpointPdfApi from "../../config/AxiosPdf";
const ShareModal = ({ open, onClose, id }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Enter valid email")
        .required("Email is required"),
    }),
    onSubmit: (vals) => {
      vals.id = id;
      endpointPdfApi
        .post(apis.shareReport, vals, {
          params: vals,
        })
        .then(() => {
          message.success(
            <span className="messageText">{t("send_report_email")}</span>
          );
          onClose();
          formik.resetForm();
        });
    },
  });
  const { t } = useTranslation();

  return (
    <Modal
      visible={open}
      title="Share"
      onCancel={() => {
        onClose();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label
            style={{
              color: "#333",
              marginBottom: "1rem",
              display: "inline-block",
            }}
          >
            {t("email")}
          </label>
          <Input
            name={"email"}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <div className="error">
            {formik.touched?.["email"] && formik.errors?.["email"]}
          </div>
          <div>
            <Button
              key="1"
              htmlType="submit"
              className="formButton mt3 primaryButton"
            >
              {t("submit")}
            </Button>
            <Button
              key="2"
              htmlType="button"
              className="cancelButton ml1 mt3 "
              onClick={() => {
                onClose();
                formik.resetForm();
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ShareModal;
