import { Button, Col, message, Modal, Row } from "antd";
import { color } from "highcharts";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { customers } from "../../actions/customers";
import { fetchPaymentLinks } from "../../actions/transfers";
import "../../components/Transfers/transfer.css";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import { FormInput, FormSelect } from "../inputs";
import AddCustomer from "../Sales/AddCustomer";
import { get_currencies } from "../../actions/masterdata/masterdata";

const CreatePaymentLink = ({ isVisible, onClose, editres }) => {
  const { onCall, loading } = usePayAxios({
    api: apis.paymentLink,
    method: "post",
  });
  const history = useHistory();
  const [payLinkResponse, setPayLinkResponse] = useState("");
  const [showData, setShowData] = useState("");
  const [openAddNew, setOpenAddNew] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      customers({
        type: "customer",
      })
    );
    dispatch(get_currencies());
  }, []);
  const customer = useSelector((state) =>
    Array.isArray(state.customers?.all?.data?.data)
      ? state.customers?.all?.data?.data?.map((ele) => ({
        ...ele,
        label: ele.beneficiary_name,
        value: ele.id,
      }))
      : []
  );

  const currenciesRes = useSelector((state) =>
    state?.currencies?.data?.data?.length
      ? state?.currencies?.data?.data?.map((ele) => ({
        label: <span style={{
          color: "#212121",
          fontSize: 15
        }}>
          <img src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele?.currency?.country_code?.toLowerCase()}.svg`} className='mr1' width={25} style={{
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)"
          }} />
          {`${ele?.iso_code}`}
        </span>,
        value: ele.id,
      }))
      : []
  );
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      beneficiary_id: "",
      description: "",
      currency: "",
      due_amount: "",
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    setShowData(data.beneficiary_id);
    const payload = {
      beneficiary_id: data.beneficiary_id,
      due_amount: data.due_amount,
      company_id: localStorage.getItem("company_id"),
    };

    onCall({
      data: payload,
    })
      .then((data) => {
        setPayLinkResponse(data?.data);
        message.success(<span className='messageText'>{data?.message}</span>);
        dispatch(fetchPaymentLinks());
      })
      .catch((err) => {
        message.error(
          <span className='errorMsg'>{err?.response?.data?.message}</span>
        );
      });
  };

  const beneficiaryID = watch("beneficiary_id")
  useEffect(() => {
    setPayLinkResponse(editres?.payment_link);
  }, [isVisible]);
  const handlerNewCustomer = () => {
    setOpenAddNew(true);
  };

  useEffect(() => {
    if (beneficiaryID) {
      let beneficiaryCurrency = customer?.find((ele) => ele?.value === getValues()?.beneficiary_id)?.configurations?.currency
      setValue("currency", `${beneficiaryCurrency}`)
    }
  }, [beneficiaryID])
  return (
    <>
      {openAddNew && <AddCustomer open={openAddNew} onClose={() => {
        setOpenAddNew(false)
      }} />}
      {isVisible && <Modal
        onCancel={onClose}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">
                {payLinkResponse
                  ? t('link_create')
                  : t('create_payment_links')}
              </span>
              <span
                className="fs16 lh20 color-818181 fw-medium"
                style={{
                  marginLeft: "18px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#818181",
                  fontWeight: "medium",
                }}
              ></span>
            </div>
          </div>
        }
        visible={isVisible}
      >
        {editres?.payment_link || payLinkResponse ? (
          <>
            <div>
              <div className='link_div'>
                <h4 className='link_header'>SHARE THE LINK CUSTOMER </h4>
                <h4 className='link_display'>{payLinkResponse}</h4>
                <button
                  style={{
                    marginLeft: "1rem",
                    paddingTop: "0.5rem",
                    color: "#3f81f4",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(payLinkResponse);
                    message.success(
                      <span className='messageText'>Linked copied</span>
                    );
                  }}
                >
                  {t("copy_link")}
                </button>
              </div>
              <div className='link_line'></div>
              <div className='share_link'>
                <h4 className='link_header'>{t("customer_info")}</h4>
              </div>
              <div className='customer_div'>
                <h4 className='company_name'>
                  {" "}
                  {
                    customer?.find((ele) => ele.value === showData)
                      ?.company_name
                  }
                </h4>
                <div className='customer_body'>
                  {
                    <div
                      style={{
                        backgroundColor: "#F5F7FA",
                        padding: "30px 27px",
                        marginTop: "20px",
                        fontSize: "17px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "17px",
                            lineHeight: "26px",
                            color: "#000000",
                          }}
                        >
                          {t("customer_Details")}
                        </span>

                        {/* <a style={{ color: "#ED3B41", fontSize: "17px" }}>
                          {t("edit")}
                        </a> */}
                      </div>

                      <Col style={{ paddingTop: "10px" }}>
                        <div>
                          {t("name")} -{" "}
                          {
                            customer?.find((ele) => ele.value === showData)
                              ?.beneficiary_name ?? editres?.beneficiaries?.beneficiary_name
                          }
                          <br />
                          {t("email")} -{" "}
                          {
                            customer?.find((ele) => ele.value === showData)
                              ?.email ?? editres?.beneficiaries?.beneficiary_email
                          }
                          <br />
                          {t("phone")} -
                          {
                            customer?.find((ele) => ele.value === showData)
                              ?.primary_contact ?? editres?.beneficiaries?.primary_contact
                          }
                          <br />
                        </div>
                      </Col>
                    </div>
                  }
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <h4 className='link_header'>{t("customer_Details")}</h4>
                <h5 style={{ fontSize: "1rem", color: "rgba(0,0,0,.85)" }}>
                  {t("link_Sent_phone")}
                </h5>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className='pp-form-item field_length'>
                    <FormSelect
                      inline
                      control={control}
                      {...register("beneficiary_id")}
                      required
                      errors={errors}
                      label={t("cust")}
                      placeholder={t("select")}
                      //   onSearch={onVendorSearch}
                      options={customer}
                      onAddItem={handlerNewCustomer}
                    />
                  </div>
                  {watch("beneficiary_id") && (
                    <div
                      style={{
                        backgroundColor: "#F5F7FA",
                        padding: "30px 27px",
                        marginTop: "20px",
                        fontSize: "17px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "17px",
                            lineHeight: "26px",
                            color: "#000000",
                          }}
                        >
                          {t("customer_Details")}
                        </span>

                        {/* <a style={{ color: "#ED3B41", fontSize: "17px" }}>
                          {t("edit")}
                        </a> */}
                      </div>

                      <Col style={{ paddingTop: "10px" }}>
                        <div>
                          {t("name")} -{" "}
                          {
                            customer?.find(
                              (ele) =>
                                ele.value === getValues().beneficiary_id ? getValues().beneficiary_id : editres?.beneficiary_id
                            )?.beneficiary_name
                          }
                          <br />
                          {t("email")} -{" "}
                          {
                            customer?.find(
                              (ele) =>
                                ele.value === getValues().beneficiary_id
                            )?.email
                          }
                          <br />
                          {/* Address - {customers?.find(ele => ele.value === getValues().beneficiary_id)?.email} */}
                        </div>
                      </Col>
                    </div>
                  )}
                  <div className='pp-form-item field_length'>
                    <FormInput
                      control={control}
                      {...register("description")}
                      errors={errors}
                      label={t("payment_for")}
                      placeholder={t("desc")}
                    />
                  </div>
                  <div className='pp-form-item field_length'>
                    <div>
                      <Row>
                        <Col span={8}>
                          <FormSelect
                            control={control}
                            {...register('currency', {
                              required: "currency is required"
                            })}
                            placeholder='Select Currency'
                            errors={errors}
                            label={t('Amount')}
                            required={true}
                            disabled={true}
                            hideCreateOption
                            options={currenciesRes} />
                          {/* <FormInput
                            inline
                            control={control}
                            {...register("currency")}
                            errors={errors}
                            placeholder={t("Amount")}
                            disabled={true}
                          /> */}
                        </Col>
                        <Col span={16} className='amount_div'>
                          <FormInput
                            control={control}
                            {...register("due_amount")}
                            errors={errors}
                            placeholder={t("Amount")}
                            number={true}
                            amount={true}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "2rem" }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='pp-main-button'
                    id='save'
                    loading={loading}
                  >
                    <span>{t("create")}</span>
                  </Button>

                  <Button
                    className='pp-secondary-button'
                    style={{ marginLeft: "2rem" }}
                    onClick={onClose}
                  >
                    <span>{t("cancel")}</span>
                  </Button>
                </div>
              </form>
            </div>
          </>
        )}
      </Modal>
      }
    </>
  );
};

export default CreatePaymentLink;
