import { pdf } from "@react-pdf/renderer";
import { Divider } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from 'json-2-csv';
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  analyticsEstimates
} from "../../actions/cards/cards";
import { apis } from "../../config/APIs";
import endpointAnalyticsApi from "../../config/AxiosAnalyticsApi";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import MISExpenses from "../Common/PDF/MISPDF/MISExpenses";
import BarGraph from "./BarGraph";
import ReportTable from "./ReportTable";
const PaymentMade = ({
  showGraph,
  apiFilters,
  filterObj,
  setFilterObj,
  csvData,
  pdfURL,
  ...props
}) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state?.cards?.analytics_estimates);
  const pdfData = useSelector((state) => state.analytics.analytics_cards_all?.data?.data);

  const { t } = useTranslation()
  useEffect(() => {
    dispatch(analyticsEstimates({ type: "analytics_payment_made" }));
    // dispatch(analytics_cards_all({ params:{ module_type:"PURCHASE_ORDER", isCsv:true}, type:"payment_made" }));
   
    setFilterObj({});
    // eslint-disable-next-line
  }, []);

  const columns = useMemo(
    () => [
      {
        title: t('bill_date'),
        dataIndex: "vendor_billing_details",
        key: "",
        render: (text) => {
          if (Array.isArray(text) && text.length > 0) {
            return text.map((item, index) => (
              <div key={index}>{moment(item?.billing_date).format("DD MMM, YYYY")}</div>
            ));
          }
          return null;
        }
      },
      {
        title: t('vendor_name'),
        dataIndex: "vendor_billing_details",
        key: "",
        render: (text) => {
          if (Array.isArray(text) && text.length > 0) {
            return text.map((item, index) => (
              <div key={index}>{item?.vendor_name}</div>
            ));
          }
          return null;
        }
      },
      {
        title: t('bill_no'),
        dataIndex: "vendor_billing_details",
        key: "",
        render: (text) => {
          if (Array.isArray(text) && text.length > 0) {
            return text.map((item, index) => (
              <div key={index}>{item?.billing_no}</div>
            ));
          }
          return null;
        }
      },
      {
        title: t('total_amount'),
        dataIndex: "vendor_billing_details",
        key: "",
        render: (text) => {
          if (Array.isArray(text) && text.length > 0) {
            return text.map((item, index) => (
              <div key={index}>{item?.total_amount !== null ? (<><BaseCurrency />{amountFormat(item?.total_amount)}</>) : ('-')}</div>

            ));
          }
          return null;
        }
      },
      {
        title: t('due_balance_amount'),
        dataIndex: "vendor_billing_details",
        key: "",
        render: (text) => {
          if (Array.isArray(text) && text.length > 0) {
            return text.map((item, index) => (
              <div key={index}>{item?.due_balance_amount !== null ? (<><BaseCurrency />{amountFormat(item?.due_balance_amount)}</>) : ('-')}</div>
            ));
          }
          return null;
        }
      },
      {
        title: t('payment_mode'),
        dataIndex: "payment_mode",
        key: "payment_mode",
      },
      {
        title: t('payment_status'),
        dataIndex: "vendor_billing_details",
        key: "",
        render: (text) => {
          if (Array.isArray(text) && text.length > 0) {
            return text.map((item, index) => (
              <div key={index} className={`status status_${item?.payment_status}`}>{item?.payment_status}</div>
            ));
          }
          return null;
        }
      },
    ],
    [cards]
  );
  const columnsKeys = [
    { label: "Bill Date", key: "billing_date",subKey : "vendor_billing_details", type: "date" },
    { label: "Vendor Name", key: "vendor_name",subKey : "vendor_billing_details", type: "string" },
    { label: "Bill No", key: "billing_no",subKey : "vendor_billing_details", type: "string" },
    { label: "Total Amount", key: "total_amount",subKey : "vendor_billing_details", type: "amount" },
    { label: "Due Balance Amount", key: "due_balance_amount",subKey : "vendor_billing_details", type: "amount" },
    { label: "Status", key: "invoice_status",subKey : "vendor_billing_details", type: "string" }
  ];
  const downloadPdf = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.getAnalyticsPaymentMade + '?module_type=SALES_ORDER&isCsv=true', { filters: filters });
    if(response){
      const blob = await pdf((
        <MISExpenses data={response?.data?.data} columnsKeys={columnsKeys}/>
        )).toBlob();
        saveAs(blob, "MISPayments_Made.pdf");
      }
  }
  const downloadCsv = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.getAnalyticsPaymentMade + '?module_type=SALES_ORDER&isCsv=true', { filters: filters });
    if(response){
      const prod = response?.data?.data?.map(ele => ({
        "Bill Date": moment(ele.vendor_billing_details[0]?.billing_date).format("DD MMM, YYYY"),
        "Vendor Name": ele.vendor_billing_details[0]?.vendor_name,
        "Bill No": ele.vendor_billing_details[0]?.billing_no,
        "Total Amount": ele.vendor_billing_details[0]?.total_amount,
        "Due Balance Amount": ele.vendor_billing_details[0]?.due_balance_amount,
        "Status": ele.vendor_billing_details[0]?.invoice_status,
      }))
      
      json2csv(prod).then((csv) => {
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "Payment Made.csv");
      });
    }
    }
    useEffect(() => {
      pdfURL(downloadPdf)
  }, [pdfData])

  useEffect(() => {
    csvData(downloadCsv)
  }, [pdfData])
  return (
    <>
      <div className="reports">
        {showGraph && (
          <>
            <Divider />
            <div
              className="mt1"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BarGraph
                width={1000}
                height={350}
                id={"lineAreaGraph"}
                data={[
                  { day: "Jan", txnValue: 39 },
                  { day: "Feb", txnValue: 49 },
                  { day: "Mar", txnValue: 69 },
                  { day: "Apr", txnValue: 59 },
                ]}
                barColor={"#151424"}
              />
            </div>
          </>
        )}
        <Divider />
      </div>
      <ReportTable
        columns={columns}
        data={Array.isArray(cards?.data?.data) ? cards?.data?.data : []}
        total_records={cards?.data?.total_count}
        scroll={{ x: 2000 }}
        paginationCall={(vals) => {
          vals.type = "analytics_payment_made";
          dispatch(analyticsEstimates(vals));
          // dispatch(
          //   analytics_cards_export({
          //     pdf: true,
          //     csv: true,
          //     type: "analytics_payment_made",
          //   })
          // );
        }}
        filterObj={filterObj}
        payload={{ ...filterObj, ...apiFilters }}
      />
    </>
  );
};

export default PaymentMade;
