import { Button, Divider, Form, Typography, message, Upload } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import {
  CreateBranch,
  GetBranchID,
  createBranch,
  getAllBranchHeader,
  getAllCurrency,
  getBranchID,
  getCardVenders,
  getCompanyCountries,
  getCompanyTimezones,
} from "../../../actions/companySettings/companySettings";
import Close from "../../../assets/icons/close.png";
import { apis } from "../../../config/APIs";
import useDocumentAxios from "../../../config/useDocument";
import AddNewOrgForm from "./AddNewOrgForm";
import { PulseLoader } from "react-spinners";
import { getFileExtension } from "../../../utils";
import { countries as country } from "./country";
import useScrollToError from "../../../config/useScrollToError";
const { Title } = Typography;

const AddNewOrg = () => {
  const history = useHistory();
  let location = useLocation();
  const { onCall: DocumentAttID, loading: isUploadLoading } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });
  const { onCall: DocumentDelete } = useDocumentAxios({
    api: apis.deleteDocument,
    method: "delete",
    baseURL: "document",
  });
  const allCurrencyList = useSelector((state) =>
    state?.companySetting?.getAllCurrency?.data?.data?.map((item, index) => ({
      ...item,
      value: item?.id,
      label: (
        <span
          style={{
            color: "#212121",
            fontSize: 15,
          }}
        >
          <img
            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_code?.toLowerCase()}.svg`}
            className="mr1"
            width={25}
            style={{
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
            }}
          />
          {`${item?.country_name} (${item?.iso_code})`}
        </span>
      ),
    }))
  );

  const branchDetails = useSelector(
    (state) => state?.companySetting?.getBranchID
  );
  const createBranchResponseLoading = useSelector(
    (state) => state?.companySetting?.createMultiBranch?.loading
  );
  const createBranchResponse = useSelector(
    (state) => state?.companySetting?.createMultiBranch
  );
  const getCardVendersResponse = useSelector((state) =>
    state?.companySetting?.getCardVenders?.data?.data?.map((item, index) => ({
      ...item,
      value: item?.id,
      label: item?.name,
    }))
  );
  const [uploadData, setUploadData] = React.useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fieldsKeys = [
    "name",
    "registration_no",
    "primary_email",
    "logo",
    "address",
    "country",
    "state",
    "city",
    "pincode",
    "office_no",
    "tan_no",
    "gst_no",
    "incorporation",
    "gst_certificate",
    "vendor_id",
    "mo_id",
    "virtual_id",
    "outlet_id",
    "fiscal_year",
    "primary_contact_name",
    "email",
    "primary_phone",
    "base_currency_id",
    "account_ifsc",
    "account_name",
    "account_number",
    "bank_name",
    "bank_branch",
    "bank_address",
    "enabled",
    "parent_id",
  ];
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(`${t("organisation_name")} is required`),
    registration_no: Yup.string().required(
      `${t("Organisation_register_no")} is required`
    ),
    primary_email: Yup.string()
      .matches(/^[A-Za-z0-9+_.-]+@(.+)$/, "Invalid email address")
      .required(`${t("org_primary_email")} is required`),
    address: Yup.string().required(`${t("address")} is required`),

    country: Yup.string().required(`${t("country")} is required`),
    pincode: Yup.string()
      .trim()
      .required("Pincode is required")
      .min(6, "Pincode must be at least 6 characters")
      .max(6, "Pincode must not exceed 6 characters"),
    office_no: Yup.string()
      .trim()
      .required(`${t("office_number")} is required`)
      .min(10, `${t("office_number")} must be at least 10 characters`)
      .max(12, `${t("office_number")} must not exceed 12 characters`),
    virtual_id: Yup.string()
      .trim()
      .test(
        "is-virtual-id",
        `${t("virtual_id")} is required and must be 16 characters`,
        function (value) {
          const { path, createError, originalValue, parent } = this;
          if (originalValue && originalValue.length < 16) {
            return createError({
              path,
              message: `${t(
                "virtual_id"
              )} is required and must be 16 characters`,
            });
          } else {
            return true;
          }
        }
      ),
    // fiscal_year: Yup.string().nullable().required(`${t('fiscal_year')} is required`),
    // outlet_id: Yup.string().required(`${t('outlet_id')} is required`),
    time_zone_id: Yup.string().required("Timezone is required"),
    time_format: Yup.string().required("Time format is required"),
    tan_no: Yup.string()
      .trim()
      .required(`${t("tan_no")} is required`)
      .min(10, `${t("tan_no")} must be at least 10 characters`)
      .max(10, `${t("tan_no")} must not exceed 10 characters`),
    gst_no: Yup.string()
      .trim()
      .required(`${t("gstin_no")} is required`)
      .min(15, `${t("gstin_no")} must be at least 15 characters`)
      .max(15, `${t("gstin_no")} must not exceed 15 characters`),
    primary_contact_name: Yup.string().required(
      `${t("primary_contact_name")} is required`
    ),
    email: Yup.string()
      .matches(/^[A-Za-z0-9+_.-]+@(.+)$/, "Invalid email address")
      .required(`${t("primary_email")} is required`),
    primary_phone: Yup.string()
      .trim()
      .required(`${t("primary_contact_number")} is required`)
      .min(10, `${t("primary_contact_number")} must be at least 10 characters`)
      .max(12, `${t("primary_contact_number")} must not exceed 12 characters`),
    base_currency_id: Yup.string().required(
      `${t("base_currency")} is required`
    ),
    account_ifsc: Yup.string()
      .trim()
      .required(`${t("account_ifsc")} is required`)
      .min(11, `${t("account_ifsc")} must be at least 11 characters`)
      .max(16, `${t("account_ifsc")} must not exceed 16 characters`),
    account_number: Yup.string()
      .trim()
      .required(`${t("account_number")} is required`)
      .min(9, `${t("account_number")} must be at least 9 characters`)
      .max(18, `${t("account_number")} must not exceed 18 characters`),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      registration_no: "",
      primary_email: "",
      logo: "",
      address: "",

      country: "",
      state: "",
      city: "",
      pincode: "",
      office_no: "",
      tan_no: "",
      gst_no: "",

      incorporation: "",
      gst_certificate: "",

      vendor_id: null,
      mo_id: "",
      virtual_id: "",
      outlet_id: "",
      fiscal_year: null,
      primary_contact_name: "",
      email: "",
      primary_phone: "",
      base_currency_id: "",
      account_ifsc: "",
      account_name: "",
      account_number: "",
      bank_name: "",
      bank_branch: "",
      bank_address: "",
      time_zone_id: null,
      time_format: null,
      enabled: true,
      parent_id: localStorage.getItem("company_id"),
    },
    validationSchema: ValidationSchema,
    onSubmit: (vals) => {
      if (uploadData?.logo) {
        vals["logo"] = [uploadData?.logo?.attachId];
      }
      if (uploadData?.incorporation) {
        vals["incorporation"] = [uploadData?.incorporation?.attachId];
      }
      if (uploadData?.other) {
        vals["other"] = [uploadData?.other?.attachId];
      }
      if (uploadData?.gst_certificate) {
        vals["gst_certificate"] = [uploadData?.gst_certificate?.attachId];
      }
      if (location?.state) {
        vals.id = location?.state;
      }
      dispatch(createBranch(vals));
    },
  });

  // Use the custom hook
  useScrollToError(
    Object.keys(formik?.errors)[0],
    formik?.errors,
    formik?.submitCount
  );
  React.useEffect(() => {
    dispatch(getCompanyCountries());
    dispatch(getCompanyTimezones());
    dispatch(getAllCurrency());
    dispatch(getCardVenders());

    return () => {
      dispatch({ type: GetBranchID.RESET });
    };
  }, []);

  React.useEffect(() => {
    if (location?.state) {
      dispatch(getBranchID({ id: location?.state }));
    }
  }, [location?.state]);
  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  React.useEffect(() => {
    let countryCode = country?.find(
      (ele) =>
        ele?.code == branchDetails?.data?.data?.country ||
        ele?.value == branchDetails?.data?.data?.country
    )?.code;
    if (branchDetails?.data?.data) {
      fieldsKeys?.forEach((ele) => {
        formik.setFieldValue(ele, branchDetails?.data?.data[ele]);
      });
    }
    formik.setFieldValue("state", branchDetails?.data?.data?.state);
    formik.setFieldValue("city", branchDetails?.data?.data?.city);
    formik.setFieldValue("country", countryCode);
    formik.setFieldValue(
      "vendor_id",
      branchDetails?.data?.data?.card_vendors?.card_vendor_id
    );
    formik.setFieldValue("logo", branchDetails?.data?.data?.logo?.id);
    formik.setFieldValue(
      "incorporation",
      branchDetails?.data?.data?.incorporation?.id
    );
    formik.setFieldValue(
      "gst_certificate",
      branchDetails?.data?.data?.gst_certificate?.id
    );
    if (branchDetails?.data?.data) {
      setUploadData((upData) => ({
        ...upData,
        gst_certificate: {
          attachId: branchDetails?.data?.data?.gst_certificate?.id,
          recordId: branchDetails?.data?.data?.id,
          url: branchDetails?.data?.data?.gst_certificate?.url,
          fileName: branchDetails?.data?.data?.gst_certificate?.file_name,
        },
        incorporation: {
          attachId: branchDetails?.data?.data?.incorporation?.id,
          recordId: branchDetails?.data?.data?.id,
          url: branchDetails?.data?.data?.incorporation?.url,
          fileName: branchDetails?.data?.data?.incorporation?.file_name,
        },
        logo: {
          attachId: branchDetails?.data?.data?.logo?.id,
          recordId: branchDetails?.data?.data?.id,
          url: branchDetails?.data?.data?.logo?.url,
          fileName: branchDetails?.data?.data?.logo?.file_name,
        },
      }));
    }
  }, [branchDetails?.data?.data?.id]);
  React.useEffect(() => {
    if (createBranchResponse?.data?.error === false) {
      message.success(
        <span className="messageText">
          {createBranchResponse?.data?.message}
        </span>
      );
      dispatch({ type: CreateBranch.RESET });
      dispatch(getAllBranchHeader());
      formik.resetForm();
      history.push("/settings/branches");
    } else if (createBranchResponse?.data?.error) {
      message.error(
        <span className="messageText">
          {createBranchResponse?.data?.message}
        </span>
      );
      dispatch({ type: CreateBranch.RESET });
    }
  }, [createBranchResponse]);

  const handleFileChange = async (info, name) => {
    if (info?.file?.status === "remove") {
      await DocumentDelete({
        data: {
          ids: uploadData?.[name]?.attachId,
        },
        params: { record_id: uploadData[info?.file?.uid]?.recordId },
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });
    } else {
      if (uploadData?.[name]?.attachId) {
        await DocumentDelete({
          data: {
            ids: [uploadData?.[name]?.attachId],
          },
          params: { record_id: uploadData[name]?.recordId },
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
      }
      const uniqueNo = uploadData?.[name]
        ? uploadData?.[name]?.recordId
        : uuidv4();
      const formData = new FormData();
      formData.append("files", info?.file);
      formData.append("name", name);
      formData.append("record_type", "Company");
      formData.append("record_id", uniqueNo);
      const attachId = await DocumentAttID({
        data: formData,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });
      if (attachId?.data?.[0]) {
        await getBase64(info?.file, (url) => {
          formik.setFieldValue(`${name}`, attachId?.data[0]);
          switch (name) {
            case "logo":
              setUploadData({
                ...uploadData,
                logo: {
                  attachId: attachId?.data?.[0],
                  recordId: uniqueNo,
                  url: url,
                  fileName: info?.file?.name,
                },
              });
              break;
            case "incorporation":
              setUploadData({
                ...uploadData,
                incorporation: {
                  attachId: attachId?.data?.[0],
                  recordId: uniqueNo,
                  url: url,
                  fileName: info?.file?.name,
                },
              });
              break;
            case "gst_certificate":
              setUploadData({
                ...uploadData,
                gst_certificate: {
                  attachId: attachId?.data?.[0],
                  recordId: uniqueNo,
                  url: url,
                  fileName: info?.file?.name,
                },
              });
              break;
            default:
              break;
          }
        });
      }
    }
  };
  const handleBack = () => {
    history.push("/settings/branches");
    formik.resetForm();
  };

  const beforeUploadLogo = (file, fileList) => {
    const fileName = getFileExtension(file?.name)?.toLowerCase();
    if (["jpg", "jpeg", "png"]?.includes(fileName)) {
      return false;
    } else {
      message.error(
        <span className="messageText">{"Please Upload JPG or PNG files"}</span>
      );
      return Upload.LIST_IGNORE;
    }
  };
  const beforeUpload = (file, fileList) => {
    const fileName = getFileExtension(file?.name)?.toLowerCase();
    if (["jpg", "jpeg", "png", "pdf"]?.includes(fileName)) {
      return false;
    } else {
      message.error(
        <span className="messageText">
          {"Please Upload JPG or PNG or PDF files"}
        </span>
      );
      return Upload.LIST_IGNORE;
    }
  };

  return (
    <>
      {isUploadLoading && (
        <div className="text-center loadingDiv">
          <PulseLoader color="#fff" />
        </div>
      )}
      <div className="contentWrapper">
        <div className="flex-between">
          <Title level={5}>{t("Organisation_address")}</Title>
          <span className="close cursor mr2">
            <img
              src={Close}
              width={20}
              alt={"close"}
              onClick={() => handleBack()}
            />
          </span>
        </div>
        <Divider />
        <Form
          layout="vertical"
          onFinish={formik.handleSubmit}
          className="settings_form"
        >
          <AddNewOrgForm
            formik={formik}
            beforeUpload={beforeUpload}
            beforeUploadLogo={beforeUploadLogo}
            handleFileChange={handleFileChange}
            uploadData={uploadData}
            getCardVendersResponse={getCardVendersResponse}
            branchDetails={branchDetails}
            allCurrencyList={allCurrencyList}
          />
          <Button
            key="1"
            htmlType="submit"
            className="formButton mt1 primaryButton"
            disabled={isUploadLoading || createBranchResponseLoading}
          >
            {t("save")}
          </Button>
          <Button key="2" className="cancelButton ml1 mt1" onClick={handleBack}>
            {t("cancel")}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default AddNewOrg;
