import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import {
  AppliedLoanAmount,
  APR,
  AutomatedReview,
  BusinessAge,
  Duration,
  LoanType,
  MonthlyEmiAmount,
  MonthlyPayment,
  RightArrow,
} from "../../assets/icons/loans";
import { MyLoanImg } from "../../assets/images/loans";
import "./Loan.css";
import { useTranslation } from "react-i18next";
const MyLoans = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(
      headerTitles({
        title: t("my_loans"),
        description: "",
      })
    );
  }, []);

  const AppliedLoansData = [
    {
      iconImg: (
        <img
          src={AutomatedReview}
          className='icon'
          alt='Automated Review Icon'
        />
      ),
      loanTitle: t("auto_rev"),
      loanDescription: t("pre_que"),
    },
    {
      iconImg: <img src={LoanType} className='icon' alt='Loan Type Icon' />,
      loanTitle: t("loan_type"),
      loanDescription: t("working_cap"),
    },
    {
      iconImg: (
        <img src={BusinessAge} className='icon' alt='Business Age Icon' />
      ),
      loanTitle: t("business_age"),
      loanDescription: t("years_5"),
    },
    {
      iconImg: (
        <img
          src={AppliedLoanAmount}
          className='icon'
          alt='Applied Loan amount Icon'
        />
      ),
      loanTitle: t("applied_loan_amount"),
      loanDescription: "$200,000",
    },
    {
      iconImg: <img src={APR} className='icon' alt='APR Icon' />,
      loanTitle: "APR",
      loanDescription: "4.5%",
    },
    {
      iconImg: (
        <img src={MonthlyPayment} className='icon' alt='Monthly payment Icon' />
      ),
      loanTitle: t("monthly_payment"),
      loanDescription: "6.8%",
    },
    {
      iconImg: <img src={Duration} className='icon' alt='Duration Icon' />,
      loanTitle: t("duration"),
      loanDescription: t("months_60"),
    },
    {
      iconImg: (
        <img
          src={MonthlyEmiAmount}
          className='icon'
          alt='Monthly EMI Amount Icon'
        />
      ),
      loanTitle: t("monthly_emi"),
      loanDescription: "$3,333.33",
    },
  ];

  return (
    <section className='d-flex flex-col'>
      <div className='v-center gap4 mt2 tabsSection'>
        <span className='active'>{t("applied")}</span>
        <span>{t("active")}</span>
        <span>{t("closed")}</span>
      </div>
      <div className='w-35 mt3 card-container flex-col'>
        <div className='flex-between' style={{ maxWidth: "32rem" }}>
          <h3 style={{ fontWeight: "bold" }}>{t("work_capital")} </h3>
          <div className='status'>
            <span>{t("processing")}</span>
          </div>
        </div>
        <div className='d-flex gap1 mt1'>
          <img src={MyLoanImg} alt='My Loan Image' className='cardImage' />

          <ul className='cards'>
            {AppliedLoansData.map((item) => {
              return (
                <li>
                  <div className='d-flex flex-col'>
                    <div className='d-flex align-flex-start'>
                      <span>{item.iconImg}</span>
                      <div className='flex-col card-features-section-elements'>
                        <p>{item.loanTitle}</p>
                        <label>{item.loanDescription}</label>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <img src={RightArrow} alt='Right Arrow' className='rightArrowIcon' style={{ width: "1.2rem" }} />
        </div>
      </div>
    </section>
  );
};

export default MyLoans;
