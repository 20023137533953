import { ActionTypesFactory } from '../../utils';

export const PrimaryAccounts = ActionTypesFactory('Accounts', 'PrimaryAccounts');
export const CompanyAccounts = ActionTypesFactory('Accounts', 'CompanyAccounts');
export const BeneficiaryAccount = ActionTypesFactory("Account", "BeneficiaryAccount");
export const MasterPoolAccount = ActionTypesFactory("Account", "MasterPoolAccount");
export const AllBudgets = ActionTypesFactory("Account", "AllBudgets");
export const CreateBudgets = ActionTypesFactory("Account", "CreateBudgets");
export const BudgetsBack = ActionTypesFactory("Account", "BudgetsBack");
export const InitiatePayout = ActionTypesFactory("Account", "InitiatePayout");
export const PayoutTxns = ActionTypesFactory("Account", "PayoutTxns");
export const MasterAccountHistory = ActionTypesFactory("Account", "MasterAccountHistory");
export const get_accounts = (payload) => {
  return {
    type: PrimaryAccounts.REQUEST,
    payload,
  };
};

export const get_cmp_accounts = (payload) => {
  return {
    type: CompanyAccounts.REQUEST,
    payload
  };
};

export const beneficiary_accounts = (payload) => {
  return {
    type: BeneficiaryAccount.REQUEST,
    payload
  };
};

export const master_pool_account = (payload) => {
  return {
    type: MasterPoolAccount.REQUEST,
    payload
  };
};

export const all_budgets = (payload) => {
  return {
    type: AllBudgets.REQUEST,
    payload
  };
};

export const create_budgets = (payload) => {
  return {
    type: CreateBudgets.REQUEST,
    payload
  };
};
export const budgets_back = (payload) => {
  return {
    type: BudgetsBack.REQUEST,
    payload
  };
};
export const master_account_history = (payload) => {
  return {
    type: MasterAccountHistory.REQUEST,
    payload
  };
};


export const initiate_payout = (payload) => {
  return {
    type: InitiatePayout.REQUEST,
    payload
  };
};

export const payout_txns = (payload) => {
  return {
    type: PayoutTxns.REQUEST,
    payload
  };
};