import React from 'react';
import { Document, View} from '@react-pdf/renderer';
import usePdfTable from './usePDFTable';

const ConvertPDF = ({headers, rows, dataMapKey}) => {
  return (
    <Document>
        <View>
          <View>
            {usePdfTable(headers, rows, dataMapKey)}
          </View>
        </View>
    </Document>
  );
};

export default ConvertPDF;
