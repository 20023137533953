import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Table } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { hotel_columns } from './TripTables';

const hotelValidation = yup.object().shape({
  hotel_reservations: yup.array().of(yup.object().shape({
    duration_from: yup.string().required("Check In is required"),
    duration_to: yup.string().required("Check Out is required"),
    location: yup.string().required("City is required"),
    description: yup.string().required("Description from is required"),
    hotel_name: yup.string().required("Hotel name from is required"),
  }))
})
const HotelForm = ({ tripForm, isEdit, isModalVisible, setIsModalVisible, setRemovedItemsData, removedItemsData }) => {
  const { hotel_reservations = [] } = tripForm?.values;
  const [hotal_reservation_data,set_hotel_reservation_data] = useState([])
  const { t } = useTranslation();
  const hotelFormik = useFormik({
    initialValues: {
      hotel_reservations: [{
        duration_from: "",
        duration_to: "",
        location: "",
        description: "",
        hotel_name: ""
      }],
    },
    validationSchema: hotelValidation,
    onSubmit: (vals) => {
      tripForm.setFieldValue("hotel_reservations", [...vals.hotel_reservations])
      handleModalCancelButton()
    }
  })

  const handleModalCancelButton = () => {
    setIsModalVisible(false)
  }
  
  

  const onNewRowChange = (type, idx) => {
    if (type === "remove") {
      if (idx > -1 && hotel_reservations[idx]?.id) {
        let trips = hotelFormik.values.hotel_reservations
        setRemovedItemsData(itm => ([...itm, { ...trips[idx], is_delete: false }]))
        trips.splice(idx, 1)
        hotelFormik.setFieldValue("hotel_reservations", trips)
      }
      else{
        let trips = [...hotel_reservations]
        trips.splice(idx, 1)
        hotelFormik.setFieldValue("hotel_reservations", trips)
      }
    } else {
      let hr = hotelFormik.values.hotel_reservations;
      hr = [...hr, {
        duration_from: "",
        duration_to: "",
        location: "",
        description: "",
        hotel_name: ""
      }]
      hotelFormik.setFieldValue("hotel_reservations", hr)
    }
  }

  console.log({ removedItemsData: removedItemsData, hotel_reservations: hotelFormik.values.hotel_reservations, hotel_reservations1:hotel_reservations },"removed_list");


  React.useEffect(() => {
    if (isModalVisible)
      hotelFormik.setFieldValue(
        'hotel_reservations', hotel_reservations?.length === 0 ? [{
          duration_from: "",
          duration_to: "",
          location: "",
          description: ""
        }] : hotel_reservations
      )
    // eslint-disable-next-line
  }, [isModalVisible])
  const renderHotelForm = () => <form
    onSubmit={hotelFormik.handleSubmit}
    className="modal-form"
  >
    <div className="trips-detail detail-card p1">
      {hotelFormik.values.hotel_reservations?.map((item, index) => (
        <Row className='mt1' key={`hotelrows_${index}`}>
          <Col span={4} >
            <Form.Item label={t('hotel_name')} required>
              <Input placeholder={t('enter')}
                value={hotelFormik.values?.hotel_reservations[index]?.hotel_name}
                onChange={(e) => {
                  let fr = [...hotelFormik.values?.hotel_reservations];
                  fr[index].hotel_name = e.target.value;
                  hotelFormik.setFieldValue("hotel_reservations", fr);
                }}
              />
              {hotelFormik.submitCount > 0 && hotelFormik.errors.hotel_reservations?.[index]?.hotel_name && <div className='errorMsg'>
                {hotelFormik.errors.hotel_reservations?.[index]?.hotel_name}
              </div>}
            </Form.Item>
          </Col>
          <Col span={4} className="ml1">
            <Form.Item label={t('visiting_city')} required>
              <Input placeholder={t('enter')}
                value={hotelFormik.values?.hotel_reservations[index]?.location}
                onChange={(e) => {
                  let fr = [...hotelFormik.values?.hotel_reservations];
                  fr[index].location = e.target.value;
                  hotelFormik.setFieldValue("hotel_reservations", fr);
                }}
              />
              {hotelFormik.submitCount > 0 && hotelFormik.errors.hotel_reservations?.[index]?.location && <div className='errorMsg'>
                {hotelFormik.errors.hotel_reservations?.[index]?.location}
              </div>}
            </Form.Item>
          </Col>
          <Col span={4} className="ml1">
            <Form.Item label={t('check_in')} required>
              <DatePicker format={'DD/MM/YYYY HH:mm:ss'} showTime
                value={
                  hotelFormik.values?.hotel_reservations[index]?.duration_from ?
                    moment(hotelFormik.values?.hotel_reservations[index]?.duration_from, 'DD/MM/YYYY HH:mm:ss a') :
                    undefined}
                id='check_in'
                onChange={(date) => {
                  let fr = [...hotelFormik.values?.hotel_reservations];
                  fr[index].duration_from = date ? moment(date).format('DD/MM/YYYY HH:mm:ss a') : ""
                  fr[index].duration_to = ""
                  hotelFormik.setFieldValue("hotel_reservations", fr)
                }}
              />
              {hotelFormik.submitCount > 0 && hotelFormik.errors.hotel_reservations?.[index]?.duration_from && <div className='errorMsg'>
                {hotelFormik.errors.hotel_reservations?.[index]?.duration_from}
              </div>}
            </Form.Item>
          </Col>
          <Col span={4} className="ml1">
            <Form.Item label={t('check_out')} required
            >
              <DatePicker format={'DD/MM/YYYY HH:mm:ss'} showTime
                value={
                  hotelFormik.values?.hotel_reservations[index]?.duration_to ?
                    moment(hotelFormik.values?.hotel_reservations[index]?.duration_to, 'DD/MM/YYYY HH:mm:ss a') :
                    undefined}
                id='check_out'
                onChange={(date) => {
                  let fr = [...hotelFormik.values?.hotel_reservations];
                  fr[index].duration_to = date ? moment(date).format('DD/MM/YYYY HH:mm:ss a') : ""
                  hotelFormik.setFieldValue("hotel_reservations", fr)
                }}
                disabledDate={(current) => {
                  let customDate = moment(hotelFormik.values?.hotel_reservations[index]?.duration_from, "DD/MM/YYYY HH:mm:ss a");
                  return current && current < moment(customDate);
                }}
              />
              {hotelFormik.submitCount > 0 && hotelFormik.errors.hotel_reservations?.[index]?.duration_to && <div className='errorMsg'>
                {hotelFormik.errors.hotel_reservations?.[index]?.duration_to}
              </div>}
            </Form.Item>
          </Col>
          <Col span={5} className="ml1">
            <Form.Item label={t('desc')}  required>
              <Input.TextArea
                placeholder={t('enter_desc')}
                autoSize={{ minRows: 1, maxRows: 5 }}
                maxLength={250}
                value={hotelFormik.values?.hotel_reservations?.[index]?.description}
                onChange={(e) => {
                  let fr = [...hotelFormik.values?.hotel_reservations];
                  fr[index].description = e.target.value
                  hotelFormik.setFieldValue("hotel_reservations", fr)
                }}
              />
              {hotelFormik.submitCount > 0 && hotelFormik.errors.hotel_reservations?.[index]?.description && <div className='errorMsg'>
                {hotelFormik.errors.hotel_reservations?.[index]?.description}
              </div>}
            </Form.Item>
          </Col>
          <Col span={1}>
            {index >= 1 ?
              <MinusCircleOutlined className='mt3' onClick={() => onNewRowChange('remove', index)} /> : null}
          </Col>
        </Row>
      ))}
      {hotel_reservations.length <= 5 ? <>
        <hr />
        <div className='p1 cursor type-link-button' onClick={() => onNewRowChange('add')}> + {t('another_line')}</div>
      </> : null
      }
    </div>

    <div className='mb3 mt1'>
      <Button key="2" htmlType="button" className="cancelButton ml1 mt3 floatRight" onClick={() => handleModalCancelButton()}>{t('cancel')}</Button>
      <Button key="1" htmlType="submit" className="formButton mt3 primaryButton floatRight"
      >{t('save')}</Button>
    </div>
  </form>
  return (
    <>
      <Modal
        title={t('add_hotel_details')}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        footer={[]}
        className="center-aligned-modal-big"
      >
        {renderHotelForm()}
      </Modal>
      <div className="trips-detail detail-card mb2">
        <div className='mt1'>
          <Button
            className="primary-btn floatRight mr1"
            htmlType="button"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            {hotel_reservations?.length ? t('edit') : t('add_hotel_details')}
          </Button>
        </div>
        <Table
          rowKey="id"
          columns={hotel_columns}
          dataSource={hotel_reservations?.length ? hotel_reservations : []}
          className="settingsTable tripsTable"
          pagination={false}
        />
      </div>
    </>
  )
};

export default HotelForm;