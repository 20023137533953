import React from "react";
import { useTranslation } from "react-i18next";
import Bentable from "../../../Transfers/BulkTransfers/BenTable";
import { Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export const benkeys = {
  "Beneficiary name": "beneficiary_name",
  "Beneficiary type": "beneficiary_type",
  "Phone number": "primary_contact",
  Email: "email",
  "Account Number": "account_number",
  "IFSC code": "ifsc_code",
  "Bank name": "branch",
  "Currency": "currency"
};

export const user_keys = {
  "Grade Id": "Grade",
};

const MapFields = ({
  setColumnRows,
  error,
  fileContent,
  columnRows,
  setError,
  setMapFields,
  columnHeader,
  mapToTable,
  setStep,
  uploadLoading,
  isBeneficiary,
  errors,
}) => {
  const data = isBeneficiary
    ? fileContent.rows.map((ele) => {
      const obj = {};
      ele.forEach((itm, idx) => {
        obj[benkeys[fileContent.columns[idx]]] = itm;
      });
      return obj;
    })
    : fileContent.rows.map((ele) => {
      const obj = {};
      ele.forEach((itm, idx) => {
        if (user_keys[fileContent.columns[idx]]) {
          obj[user_keys[fileContent.columns[idx]]] = itm;
        } else {
          obj[fileContent.columns[idx]] = itm;
        }
      });
      return obj;
    });
  const { t } = useTranslation();

  return (
    <div className="map-fields">
      <div className="w60">
        <div>
          <span className="flex-start">
            <div style={{ color: "#525353" }}>{t("ur_selected_file")}</div>
            <div
              style={{
                color: "#474186",
                marginLeft: "1rem",
              }}
            >
              {fileContent.name}
            </div>
          </span>
          <div style={{ color: "#525353", margin: "0.5rem 0" }}>
            {t("best_match_selected")}
          </div>
        </div>
        <div>
          <div className="fields">
            <div className="flex-between header">
              <div>{t("fields")}</div>
              <div>{t("imported_file_headers")}</div>
            </div>
            {columnHeader.map((ele) => (
              <div className=" mbHalf flex-between">
                <label for="grid-state" style={{ color: "#2A254F" }}>
                  {ele.Header}
                </label>

                <select
                  id="grid-state"
                  style={{ color: "#2A254F" }}
                  defaultValue={fileContent.columns.find(
                    (itm) => itm === ele.Header
                  )}
                  onChange={(e) => {
                    setColumnRows({
                      ...columnRows,
                      [ele.Header]: e.target.value,
                    });
                  }}
                  placeholder={ele.Header?.replace("_", " ")}
                >
                  {fileContent.columns.map((col, i) => {
                    return (
                      <option value={col} defaultValue={fileContent.columns[0]}>
                        {col}
                      </option>
                    );
                  })}
                </select>
              </div>
            ))}

            {error && <div style={{ color: "#ff0000" }}>{error}</div>}
          </div>
        </div>
      </div>
      {isBeneficiary ? (
        <Bentable data={data} errors={errors} />
      ) : (
        <Bentable
          data={data}
          headers={columnHeader.map((ele) => ({
            title: ele.Header,
            dataIndex: ele.Header === "Grade Id" ? "Grade" : ele.Header,
            render: (record, item, index) => (
              <p style={{ margin: 0 }}>
                {record}
                {errors[index]?.[user_keys[ele.Header] ?? ele.Header] && (
                  <Popover
                    content={
                      errors[index]?.[user_keys[ele.Header] ?? ele.Header]
                    }
                  >
                    <InfoCircleOutlined style={{ color: "red" }} />
                  </Popover>
                )}
              </p>
            ),
          }))}
        />
      )}
      {Object.keys(errors).length > 0 && (
        <div style={{ color: "red", marginBottom: 14, marginTop: 10 }}>
          Please ensure that you include the necessary and accurate details in
          the Excel file, and then proceed to upload it once again.
        </div>
      )}
      <div className="mb2">
        <button
          type="button"
          style={{
            background:
              "transparent linear-gradient(0deg, #F2F4F7 0%, #FEFFFF 100%) 0% 0% no-repeat padding-box",
            color: "#354052",
            borderColor: "#CED0DA",
            height: "2.5rem",
            padding: "0 1rem",
            marginRight: "1rem",
          }}
          onClick={() => {
            setError("");
            setMapFields(false);
            setStep(0);
          }}
        >
          {t("previous")}
        </button>
        <button
          type="button"
          className="primary-btn"
          onClick={() => {
            mapToTable(data);
          }}
          disabled={uploadLoading || Object.keys(errors).length > 0}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default MapFields;
