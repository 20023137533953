import { pdf } from "@react-pdf/renderer";
import { Divider } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from 'json-2-csv';
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  analytics_cards
} from "../../actions/cards/cards";
import { apis } from "../../config/APIs";
import endpointAnalyticsApi from "../../config/AxiosAnalyticsApi";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import MISExpenses from "../Common/PDF/MISPDF/MISExpenses";
import BarGraph from "./BarGraph";
import ReportTable from "./ReportTable";

const Expenses = ({ showGraph, apiFilters, filterObj, setFilterObj, pdfURL,csvData }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const cards = useSelector((state) => state.analytics.analytics_cards);
  const language = useSelector((state) => state.auth.localVariables?.language);
  const pdfData = useSelector((state) => state.analytics.analytics_cards_all);

  useEffect(() => {
    dispatch(analytics_cards({ type: "expenses" }));
    // dispatch(analytics_cards_all({ type: "expenses", params: { isCsv:true },}));
    setFilterObj({});
    // eslint-disable-next-line
    return () =>{
    setFilterObj({});
    }
  }, []);
  
  useEffect(() => {
    pdfURL(downloadPdf)
  }, [pdfURL])

  ///// Dowloading Pdf file method ////////
  const columnsKeys = [
    { label: "Transaction ID", key: "transaction_number", type: "string" },
    { label: "Merchant Name", key: "merchant", type: "string" },
    { label: "Employee Name", key: "employee_name", type: "string" },
    { label: "Category", key: "category", type: "string" },
    { label: "Report ID", key: "report_number", type: "string" },
    { label: "Report Name", key: "report_name", type: "string" },
    { label: "Transaction Date", key: "transaction_date", type: "date"},
    { label: "Submitted On", key: "report_submitted_on", type: "date"},
    { label: "Amount", key: "transaction_amount", type: "amount" },
  ];

  const downloadPdf = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.analytics_expenses+'?isCsv=true',{filters:filters});
    if(response){
      const blob = await pdf((
        <MISExpenses data={response?.data?.data} columnsKeys={columnsKeys}/>
      )).toBlob();
      saveAs(blob, "MISExpense.pdf");
    }
  }

  ///// Dowloading CSV file method ////////
  const downloadCsv = async (filters) => {
    const response = await endpointAnalyticsApi.post(apis.analytics_expenses+'?isCsv=true',{filters:filters});
    if(response){
      const prod = response?.data?.data?.map(ele => ({
          "Transaction ID": ele.transaction_number,
          "Merchant Name": ele.merchant,
          "Employee Name": ele.employee_name,
          "Category": ele.category,
          "Report ID": ele.report_number,
          "Report Name": ele.report_name,
          "Transaction Date":moment(ele.transaction_date).format("DD-MM-YYYY"),
          "Submitted On": moment(ele.report_submitted_on).format("DD-MM-YYYY"),
          "Amount": ele.transaction_amount,
        }))
  
        json2csv(prod).then((csv) => {
          var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
          saveAs(blob,'Expenses.csv');
        });
    }
  }

    useEffect(()=>{
      csvData(downloadCsv)
    },[csvData])

  const getObjs = () => {
    if (cards?.data?.response?.transaction?.length > 0) {
      return cards?.data?.response?.transaction?.[0]?.custom_fields?.map(ele => ({
        title: ele.key?.replace("_", " "),
        dataIndex: "custom_fields",
        key: "custom_fields",
        render: (text, data) => <div>{data?.custom_fields?.find(itm => itm.key === ele.key)?.value}</div>,
      }))
    }

    return []
  }

  const columns = useMemo(() => [
    {
      title: t('trans_id'),
      dataIndex: "transaction_number",
      key: "transaction_number",
    },
    {
      title: t('merchant_name'),
      dataIndex: "merchant",
      key: "merchant",
    },
    {
      title: t('employee_name'),
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: t('category'),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t('report_id'),
      dataIndex: "report_number",
      key: "report_number",
    },
    {
      title: t('reportName'),
      dataIndex: "report_name",
      key: "report_name",
    },
    {
      title: t('cost_center'),
      dataIndex: "cost_center",
      key: "cost_center",
      render: (text) => text ?? '-',
    },
    {
      title: t('project'),
      dataIndex: "project",
      key: "project",
      render: (text) => text ?? '-'
    },
    {
      title: t('transaction_date'),
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (record) => moment(record).format("DD MMM, YYYY hh:mm A")
    },
    {
      title: t('submittedOn'),
      dataIndex: "report_submitted_on",
      key: "report_submitted_on",
      render: (record) => record ? moment(record).format("DD MMM, YYYY"):"-"
    },
    {
      title: t('Amount'),
      dataIndex: "transaction_amount",
      key: "transaction_amount",
      render: (text) => <div> {text !== null ? (<><BaseCurrency />{amountFormat(text)}</>) : ('-')}</div>,
    },
    ...getObjs(),
    // eslint-disable-next-line
  ], [cards,language,t]);

  return (
    <>
      <div className="reports">
        {showGraph && (
          <>
            <Divider />
            <div
              className="mt1"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BarGraph
                width={1000}
                height={350}
                id={"lineAreaGraph"}
                data={[
                  { day: "Jan", txnValue: 39 },
                  { day: "Feb", txnValue: 49 },
                  { day: "Mar", txnValue: 69 },
                  { day: "Apr", txnValue: 59 },
                ]}
                barColor={"#151424"}
              />
            </div>
          </>
        )}
        <Divider />
      </div>
      <ReportTable
        columns={columns}
        data={Array.isArray(cards?.data?.data) ? cards?.data?.data : []}
        total_records={cards?.data?.total_count}
        scroll={{ x: 2000 }}
        paginationCall={(vals) => {
          vals.type = "expenses";
          dispatch(analytics_cards(vals));
          // dispatch(analytics_cards_export({ pdf: true, csv: true, ...vals }));
        }}
        filterObj={filterObj}
        payload={{ ...filterObj, ...apiFilters}}
      />
    </>
  );
};

export default Expenses;
