import { Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorModalReset } from '../actions/dashboard/dashboard';

const ErrorModal = () => {
  const errorModal = useSelector(state => state.errorModal)
  const dispatch = useDispatch()
  console.log(errorModal);
  return (
    <Modal title={"Error!"} visible={errorModal?.open} onCancel={() => {
      dispatch(errorModalReset())
    }}>
      <div>
        <div className='errorMsg mb1' style={{ fontSize: "1.5rem" }}>
          {errorModal?.errMsg}
        </div>
        <button className='nm-btn' onClick={() => {
          dispatch(errorModalReset())
        }}>Ok</button>
      </div>
    </Modal>
  );
};

export default ErrorModal;