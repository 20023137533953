import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Select, Switch } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { get_items, get_tax_items } from "../../../actions/purchases/purchases";
import Close from "../../../assets/icons/close.png";
import { FormInput, FormSelect } from "../../inputs";
import AddItemModal from "./AddItem";
import AddTaxItem from "./AddTaxItem";

import { get_seed_data } from "../../../actions/masterdata/masterdata";
import {
  amountFormat,
  isNumber,
  reverseCurrencyConverter,
} from "../../../utils";
import AddTaxModal from "../../Common/AddTax";
import BaseCurrency from "../../Common/BaseCurrency";
import { useLocation } from "react-router-dom";
const ItemsTable = ({
  setRemovedItemsData,
  exchange,
  hasExchange,
  isConvertOrClone,
  control,
  register,
  setValue,
  name,
  items: billing_items,
  vals,
  watch,
  isEdit,
  isInvoice,
  errors,
  isCN,
  fromReceipts,
}) => {
  const location = useLocation();
  const [addItem, setAddItem] = useState(false);
  const [addTax, setAddTax] = useState(false);
  const [addTds, setAddTds] = useState(false);
  const [tds, setTds] = useState(false);
  const [del, setDel] = useState(false);
  const [onlyOnce, setOnlyOnce] = useState(true);
  const [tdsVal, setTdsVal] = useState(0);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const { t } = useTranslation();
  const items = useSelector((state) => state?.bills?.items?.data?.data);
  const tax_items = useSelector(
    (state) => state?.bills?.tax_items?.data?.data || []
  );
  const tds_types = useSelector((state) =>
    state?.seed_data?.tds_types?.data?.length
      ? state?.seed_data?.tds_types?.data?.map((ele) => ({
          label: ele?.tax_name,
          value: parseInt(ele?.id),
          rate: ele?.rate,
        }))
      : []
  );

  const [values, setValues] = useState([
    {
      item_name: "",
      item_id: "",
      item_type: 0,
      hsn_code: "",
      unit_price: 0,
      quantity: 0,
      tax_type: "",
      discount_percentage: 0,
      discount_amount: 0,
      tax_amount: 0,
      total_amount: 0,
    },
  ]);
  console.log(errors);
  const del_charges = watch("delivery_charges");

  const dispatch = useDispatch();
  const { append } = useFieldArray({
    control,
    name: "items",
  });
  useEffect(() => {
    dispatch(
      get_items({
        params: { pagination: false },
      })
    );
    dispatch(
      get_tax_items({
        pagination: false,
      })
    );
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "tds_types",
      })
    );
    watch("tds_type");
  }, []);
  useEffect(() => {
    if (name) {
      setValue(name, values);
      setValue("items", values);
    }
  }, [values]);

  useEffect(() => {
    if (Array.isArray(billing_items) && Array.isArray(items) && onlyOnce) {
      let arr = [];
      billing_items?.forEach((c_ele, idx) => {
        const itm = items?.find((ele) => ele.id === c_ele?.item_id);
        const stokeOnHand = itm;
        const ele = JSON.parse(JSON.stringify(c_ele));
        const unitPrice =
          itm?.taxSalePurchaseInfo?.length > 0
            ? isInvoice
              ? itm?.taxSalePurchaseInfo?.find((it) => it.type === "SALES")
                  ?.price
              : itm?.taxSalePurchaseInfo?.find((it) => it.type === "PURCHASE")
                  ?.price
            : ele.unit_price;
        ele.unit_price =
          hasExchange && ele?.bc_unit_price
            ? parseFloat(reverseCurrencyConverter(ele?.bc_unit_price, exchange))
            : unitPrice;
        const price = ele.unit_price * ele?.quantity;
        const tax =
          tax_items.find((el) => parseInt(el.id) === parseInt(ele?.tax_type))
            ?.rate ?? 0;
        setValue(`items.${idx}.item_id`, ele.item_id);
        setValue(
          `items.${idx}.discount_percentage`,
          ele.discount_percentage ?? 0
        );
        setValue(`items.${idx}.quantity`, ele?.quantity);
        setValue(`items.${idx}.unit_price`, ele?.unit_price);
        setValue(`items.${idx}.tax_type`, ele.tax_type ?? 0);
        setValue(`items.${idx}.stock_on_hand`, stokeOnHand?.stock_on_hand ?? 0);
        ele.stock_on_hand = stokeOnHand?.stock_on_hand;
        ele.discount_amount =
          price *
          (parseInt(ele.discount_percentage ? ele.discount_percentage : 0) /
            100);
        ele.total_amount = price - ele?.discount_amount;
        ele.tax_amount = parseFloat(price - ele.discount_amount) * (tax / 100);
        delete ele.module_name;
        delete ele.discount_type;

        if (!isEdit) {
          delete ele.id;
          delete ele.billing_id;
        }
        arr.push(ele);
      });

      const timeout = setTimeout(() => {
        setValues(JSON.parse(JSON.stringify(arr)));
        setOnlyOnce(false);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [billing_items, exchange, items]);

  useEffect(() => {
    if (del_charges) {
      setDel(true);
    }
  }, [del_charges]);

  useEffect(() => {
    if (exchange && values?.length > 0 && values?.[0]?.item_id && isEdit) {
      updateValuesOnExchange();
    }
  }, [exchange, values?.[0]?.item_id]);
  useEffect(() => {
    if (vals?.tds_type && (isEdit || isConvertOrClone) && !tdsVal) {
      let TDSRates = tds_types?.find(
        (ele) => ele?.value == vals?.tds_type
      )?.rate;
      setTdsVal(TDSRates);
      setTds(true);
    }
  }, [vals]);

  const addItemVal = (val, index, isCheck) => {
    let vals = [...values];
    let item = isCheck
      ? val
      : items?.find(
          (ele) => ele.id === val?.target?.value || ele.id === val?.id
        );
    if (item) {
      item.unit_price =
        item?.taxSalePurchaseInfo?.length > 0
          ? isInvoice
            ? item?.taxSalePurchaseInfo?.find((it) => it.type === "SALES")
                ?.price
            : item?.taxSalePurchaseInfo?.find((it) => it.type === "PURCHASE")
                ?.price
          : item?.unit_price ?? 0;
    }
    vals[index].quantity = parseInt(item?.quantity ?? 0);
    setValue(`items.${index}.quantity`, parseInt(item?.quantity ?? 0));
    setValue(
      `items.${index}.unit_price`,
      (hasExchange
        ? parseFloat(reverseCurrencyConverter(item?.unit_price, exchange))
        : parseFloat(item?.unit_price)) ?? 0
    );
    vals[index].item_name = item?.item_name ?? "";
    vals[index].item_id = item?.id ?? "";
    vals[index].unit_price =
      (hasExchange
        ? parseFloat(reverseCurrencyConverter(item?.unit_price, exchange))
        : parseFloat(item?.unit_price)) ?? 0;

    vals[index].hsn_code = item?.hsn_code ?? "";
    vals[index].tax_type = item?.tax_type ?? 0;

    if (item) {
      const price = vals[index].unit_price * vals[index].quantity;
      let tax =
        tax_items.find((ele) => parseInt(ele.id) === vals[index].tax_type)
          ?.rate ?? 0;
      vals[index].tax_amount = price * (tax / 100);
      vals[index].total_amount = price;
      vals[index].stock_on_hand = item?.stock_on_hand || 0;
    } else {
      vals[index].tax_amount = 0;
      vals[index].total_amount = 0;
      vals[index].discount_amount = 0;
      vals[index].discount_percentage = 0;
      setValue(`items.${index}.discount_percentage`, 0);
      vals[index].tax_type = 0;
      vals[index].stock_on_hand = 0;
    }

    setValues(vals);
  };
  const updateValuesOnExchange = () => {
    let vals = [...values];
    if (items?.length > 0)
      vals.forEach((item, index) => {
        vals[index].quantity = parseInt(item?.quantity ?? 0);
        setValue(`items.${index}.quantity`, parseInt(item?.quantity ?? 0));
        setValue(
          `items.${index}.unit_price`,
          (hasExchange
            ? parseFloat(reverseCurrencyConverter(item?.unit_price, exchange))
            : parseFloat(item?.unit_price)) ?? 0
        );
        vals[index].unit_price =
          (hasExchange
            ? parseFloat(reverseCurrencyConverter(item?.unit_price, exchange))
            : parseFloat(item?.unit_price)) ?? 0;
        vals[index].hsn_code = item?.hsn_code ?? "";
        vals[index].tax_type = item?.tax_type ?? 0;
        if (item) {
          const price = vals[index].unit_price * vals[index].quantity;
          let tax =
            tax_items?.find((ele) => parseInt(ele.id) === vals[index]?.tax_type)
              ?.rate ?? 0;
          vals[index].tax_amount = price * (tax / 100);
          vals[index].total_amount = price;
          vals[index].discount_amount =
            price * (parseInt(vals[index].discount_percentage) / 100);
          if (vals[index].discount_amount) {
            vals[index].total_amount = price - vals[index].discount_amount;
            vals[index].tax_amount = vals[index].total_amount * (tax / 100);
          }
        } else {
          vals[index].tax_amount = 0;
          vals[index].total_amount = 0;
          vals[index].discount_amount = 0;
          vals[index].discount_percentage = 0;
          setValue(`items.${index}.discount_percentage`, 0);
          vals[index].tax_type = 0;
        }
      });
    setValues(vals);
  };
  const columns = [
    {
      title: "Item Details",
      dataIndex: "item_name",
      key: "item_name",
      render: (record, index) => (
        <>
          <FormSelect
            control={control}
            errorMessage={errors.items?.[index]?.item_id?.message}
            allowClear={true}
            placeholder={"Select Item"}
            onAddItem={() => {
              setAddItem(true);
              setSelectedItemIndex(index);
            }}
            {...register(`items.${index}.item_id`, {
              required: values[index]?.item_id ? false : "Item is required.",
              onChange: (val) => addItemVal(val, index),
            })}
            className="w100"
            value={values[index]?.item_id}
            options={
              Array.isArray(items)
                ? items?.map((ele) => ({
                    label: ele?.item_name,
                    value: ele?.id,
                  }))
                : []
            }
            // hideCreateOption={true}
          />
          <div className="errorMsg">{errors?.items?.[index]?.id?.message}</div>
        </>
      ),
      width: 350,
    },
    ...(fromReceipts
      ? [
          {
            title: "Stock on Hand",
            dataIndex: "stock_on_hand",
            key: "stock_on_hand",
            width: 100,
          },
        ]
      : []),
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 100,
      key: "quantity",
      render: (record, index) => (
        <FormInput
          placeholder={"0"}
          control={control}
          number={true}
          errorMessage={errors.items?.[index]?.quantity?.message}
          {...register(`items.${index}.quantity`, {
            value: values[index]?.quantity,
            validate: (value) => {
              // if (parseInt(value) > 0) {
              //   return null;
              // } else {
              //   return "Quantity must be greater than 0.";
              // }
              const itm = items?.find((ele) => ele.id === record?.item_id);

              if (
                itm?.track_inventory &&
                parseInt(values[index]?.quantity) <=
                  parseInt(record?.stock_on_hand) &&
                parseInt(values[index]?.quantity) > 0
              ) {
                return null;
              } else if (
                fromReceipts &&
                itm?.track_inventory &&
                parseInt(values[index]?.quantity) >
                  parseInt(record?.stock_on_hand)
              ) {
                // message.error(
                //   <span className="messageText">Quantity must be less than Stock on Hand.</span>
                // );
                return "Quantity must be less than or equal to Stock on Hand.";
              } else if (parseInt(values[index]?.quantity) > 0) {
                return null;
              } else {
                return "Quantity must be greater than 0.";
              }
            },
            onChange: (e) => {
              let vals = [...values];
              const inputValue = e.target.value;
              let tax =
                tax_items.find(
                  (ele) => parseInt(ele.id) === vals[index].tax_type
                )?.rate ?? 0;

              if (!isNumber(inputValue)) {
                return;
              }

              if (!isNaN(inputValue)) {
                const price = parseFloat(inputValue) * record.unit_price;
                vals[index].tax_amount = price * (tax / 100);
                vals[index].total_amount = price;
                vals[index].discount_amount =
                  price * (parseFloat(vals[index]?.discount_percentage) / 100);

                if (vals[index].discount_amount) {
                  vals[index].total_amount =
                    price - vals[index].discount_amount;
                  vals[index].tax_amount =
                    vals[index].total_amount * (tax / 100);
                }
              } else {
                vals[index].unit_price = 0;
                vals[index].tax_amount = 0;
                vals[index].total_amount = 0;
              }

              vals[index].quantity = inputValue
                ? parseInt(inputValue)
                : inputValue;
              setValues(vals);
              setValue(`items.${index}.quantity`, e?.target?.value);
            },
          })}
        />
      ),
    },
    {
      title: "Rate",
      dataIndex: "unit_price",
      key: "unit_price",
      width: 100,
      render: (record, index) => (
        <FormInput
          control={control}
          placeholder={"0.00"}
          value={values[index].unit_price}
          errorMessage={errors.items?.[index]?.unit_price?.message}
          number={true}
          {...register(`items.${index}.unit_price`, {
            validate: (value) => {
              if (parseFloat(value) > 0) {
                return null;
              } else {
                return "Price must be greater than 0.";
              }
            },
            onChange: (e) => {
              let vals = [...values];
              let tax =
                tax_items.find((ele) => parseInt(ele.id) === record.tax_type)
                  ?.rate || 0;

              const inputValue = e.target.value;
              if (!isNaN(inputValue)) {
                vals[index].unit_price = inputValue;
                vals[index].tax_amount =
                  inputValue * record.quantity * (tax / 100);
                vals[index].total_amount = inputValue * record.quantity;

                const price = parseFloat(vals[index].quantity) * inputValue;
                vals[index].discount_amount =
                  price * (parseFloat(vals[index].discount_percentage) / 100);

                if (vals[index].discount_amount) {
                  vals[index].total_amount =
                    price - vals[index].discount_amount;
                  vals[index].tax_amount =
                    vals[index].total_amount * (tax / 100);
                }
              } else {
                vals[index].unit_price = 0;
                vals[index].tax_amount = 0;
                vals[index].total_amount = 0;
              }

              setValue(`items.${index}.unit_price`, e.target.value);
              setValues(vals);
            },
          })}
        />
      ),
    },
    {
      title: "Discount(%)",
      dataIndex: "discount_percentage",
      key: "discount_percentage",
      render: (_record, index) => (
        <FormInput
          placeholder={"0"}
          control={control}
          number={true}
          lessThan={100}
          {...register(`items.${index}.discount_percentage`, {
            value: values[index].discount_percentage,
            onChange: (e) => {
              const inputValue = e.target.value;
              let vals = [...values];
              let tax =
                tax_items.find(
                  (ele) => parseInt(ele.id) === vals[index]?.tax_type
                )?.rate ?? 0;
              const price =
                parseFloat(vals[index]?.quantity) * vals[index]?.unit_price;

              vals[index].discount_amount = inputValue
                ? price * (parseFloat(inputValue) / 100)
                : 0;
              vals[index].total_amount = price - vals[index].discount_amount;
              vals[index].tax_amount = vals[index].total_amount * (tax / 100);
              vals[index].discount_percentage = inputValue ? inputValue : 0;

              setValue(
                `items.${index}.discount_percentage`,
                vals[index]?.discount_percentage
              );
              setValues(vals);
            },
          })}
        />
      ),
      width: 100,
    },
    {
      title: t("tax"),
      dataIndex: "tax",
      key: "tax",
      width: 100,
      render: (record, index) => (
        <FormSelect
          control={control}
          placeholder={"Select Tax"}
          allowClear={true}
          onAddItem={() => {
            setAddTax(true);
          }}
          {...register(`items.${index}.tax_type`, {
            onChange: (e) => {
              let vals = [...values];
              let tax = tax_items.find(
                (ele) => parseInt(ele.id) === parseInt(e.target.value)
              )?.rate;
              vals[index].tax_type = e.target.value;
              if (tax) {
                vals[index].tax_amount = vals[index].total_amount * (tax / 100);
                setValues(vals);
              } else {
                vals[index].tax_amount = 0;
                setValues(vals);
              }
            },
          })}
          value={values[index].tax_type}
          className="w100"
          options={
            Array.isArray(tax_items)
              ? tax_items?.map((ele) => ({
                  label: ele.tax_name,
                  value: parseInt(ele.id),
                }))
              : []
          }
        />
      ),
    },
    {
      title: t("Amount"),
      dataIndex: "total_amount",
      key: "total_amount",
      render: (_record, index) =>
        amountFormat(values[index]?.total_amount) ?? 0.0,
    },
  ];

  const getTotal = () => {
    let l1 = Number(_.sumBy(values, "total_amount"));
    let l5 = del_charges ? Number(del_charges) : 0;
    let l3 = Number(Number(_.sumBy(values, "tax_amount"))?.toFixed(2));
    let l2 = Number(Number(_.sumBy(values, "discount_amount"))?.toFixed(2));
    let l4 =
      -1 *
      (
        Number(_.sumBy(values, "total_amount")) *
        (parseFloat(tdsVal) / 100)
      )?.toFixed(2);
    setValue("total_amount", (l1 + l3 + l4 + l5)?.toFixed(2));
    setValue("sub_total_amount", l1?.toFixed(2));
    setValue("total_tax_amount", l3?.toFixed(2));
    setValue("total_discount", l2?.toFixed(2));
    if (!isInvoice)
      setValue(
        "tds_amount",
        (
          Number(_.sumBy(values, "total_amount")) *
          (parseFloat(tdsVal) / 100)
        )?.toFixed(2)
      );

    return (l1 + l3 + l4 + l5)?.toFixed(2);
  };
  return (
    <>
      <AddItemModal
        isVisible={addItem}
        onCancel={() => {
          setAddItem(false);
        }}
        onSuccess={(val) => {
          const time = setTimeout(() => {
            addItemVal(val, selectedItemIndex, true);
            setSelectedItemIndex("");
            clearTimeout(time);
          }, 2000);
        }}
      />
      <AddTaxItem
        isVisible={addTax}
        onCancel={() => {
          setAddTax(false);
        }}
      />
      <AddTaxModal
        isVisible={addTds}
        onCancel={() => {
          setAddTds(false);
        }}
        companyId={localStorage.getItem("company_id")}
      />
      <table className="items-table" style={{ width: "97%" }}>
        <thead>
          <tr>
            {columns?.map((ele) => (
              <th>{ele?.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {values?.map((itm, idx) => (
            <>
              <tr>
                {columns?.map((ele) => (
                  <td style={{ width: ele?.width || "auto" }}>
                    {ele?.render ? ele?.render(itm, idx) : itm[ele?.key]}
                  </td>
                ))}
                {values?.length > 1 && (
                  <img
                    src={Close}
                    alt="close"
                    className="close-icon-table"
                    width={20}
                    onClick={() => {
                      let vals = [...values];
                      // setRemovedItemsData((itm) => [
                      //   ...itm,
                      //   { ...vals[idx], is_active: false },
                      // ]);
                      setRemovedItemsData((itm) => {
                        const newItem = vals[idx]?.billing_id
                          ? { ...vals[idx], is_active: false }
                          : null;
                        return newItem !== null ? [...itm, newItem] : itm;
                      });

                      vals.splice(idx, 1);
                      setValues(vals);
                      setValue("items", vals);
                    }}
                  />
                )}
              </tr>
            </>
          ))}
        </tbody>
      </table>
      {!isCN ? (
        <span
          className="link"
          onClick={() => {
            append({
              item_name: "",
              item_type: 0,
              hsn_code: "",
              unit_price: 0,
              quantity: 0,
              tax_type: "",
              discount_percentage: 0,
              tax_amount: 0,
              total_amount: 0,
              stock_on_hand: "",
            });
            setValues((vals) => [
              ...vals,
              {
                item_name: "",
                item_type: 0,
                hsn_code: "",
                unit_price: 0,
                quantity: 0,
                tax_type: "",
                discount_percentage: 0,
                tax_amount: 0,
                total_amount: 0,
                stock_on_hand: "",
              },
            ]);
          }}
        >
          + Add one more
        </span>
      ) : (
        <span className="link"></span>
      )}
      <div className="flex-end" style={{ width: "97%" }}>
        <div className="sub-total">
          <div className="item">
            <span>Sub total</span>
            <span>
              <BaseCurrency symbol_code={exchange?.convert_currency} />{" "}
              {amountFormat(_.sumBy(values, "total_amount"))}
            </span>
          </div>
          <div className="item">
            <span>Total Discount Amount</span>
            <span>
              (-) <BaseCurrency symbol_code={exchange?.convert_currency} />{" "}
              {amountFormat(_.sumBy(values, "discount_amount"))}
            </span>
          </div>
          <div className="item">
            <span>Total Tax Amount</span>
            <span>
              (+) <BaseCurrency symbol_code={exchange?.convert_currency} />{" "}
              {amountFormat(_.sumBy(values, "tax_amount"))}
            </span>
          </div>
          {!isInvoice ? (
            <div className="item">
              <div className="d-flex align-items-center ">
                <span className="item-absolute">
                  <Switch
                    className="mr1"
                    checked={tds}
                    onChange={(val) => {
                      setTds(val);
                      if (!val) {
                        setTdsVal(0);
                        setValue("tds_type", null);
                      }
                    }}
                  />
                </span>

                <span
                  className="d-flex align-items-center"
                  style={{
                    height: 40,
                  }}
                >
                  TDS
                  {tds && (
                    <>
                      <div className="d-flex flex-col">
                        <Select
                          {...register("tds_type", {
                            validate: (value) => {
                              if (tds && value) {
                                return null;
                              } else if (tds) {
                                return "TDS is Required";
                              } else {
                                return null;
                              }
                            },
                          })}
                          value={vals?.tds_type ? vals?.tds_type : null}
                          className="tds"
                          placeholder="TDS %"
                          allowClear={true}
                          options={tds_types}
                          onChange={(val, data) => {
                            if (val) {
                              setTdsVal(data?.rate ? parseInt(data?.rate) : 0);
                              setValue("tds_type", val ? parseInt(val) : "");
                            } else {
                              setTdsVal(0);
                              setValue("tds_type", null);
                            }
                          }}
                          style={{
                            width: "12rem",
                            marginLeft: "1rem",
                          }}
                          dropdownRender={(menu) => (
                            <>
                              <div className="pp-dropdown-menu">{menu}</div>
                              <Divider className="pp-dropdown-divider" />
                              <Button
                                type="text"
                                htmlType="button"
                                className="pp-dropdown-add-new"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                  setAddTds(true);
                                }}
                              >
                                <span>Add TDS</span>
                              </Button>
                            </>
                          )}
                        />
                        {errors.tds_type && !vals?.tds_type && (
                          <span className="errorMsg ml1">
                            {errors.tds_type.message}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </span>
              </div>
              <span>
                (-)
                <BaseCurrency symbol_code={exchange?.convert_currency} />{" "}
                {amountFormat(
                  Number(_.sumBy(values, "total_amount")) *
                    (parseFloat(tdsVal) / 100)
                )}
              </span>
            </div>
          ) : (
            <div className="item">
              <div className="d-flex align-items-center ">
                <span className="item-absolute">
                  <Switch
                    className="mr1"
                    checked={del}
                    onChange={(val) => {
                      setDel(val);
                      if (!val) {
                        // setTdsVal(0);
                        setValue("delivery_charges", "");
                      }
                    }}
                  />
                </span>

                <span
                  className="d-flex align-items-center"
                  style={{
                    height: 40,
                  }}
                >
                  Delivery Charges
                  {del && (
                    <>
                      <div className="d-flex flex-col">
                        <FormInput
                          {...register("delivery_charges")}
                          control={control}
                          value={vals?.delivery_charges}
                          className="tds"
                          placeholder="Enter Delivery Charges"
                          style={{
                            width: "10rem",
                            marginLeft: "1rem",
                            background: "#fff",
                          }}
                        />
                      </div>
                    </>
                  )}
                </span>
              </div>
              <span>
                (+)
                <BaseCurrency symbol_code={exchange?.convert_currency} />{" "}
                {del_charges}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex-end total" style={{ width: "97%" }}>
        <div className="item">
          <span>Total</span>
          <span>
            <BaseCurrency symbol_code={exchange?.convert_currency} />{" "}
            {amountFormat(getTotal())}
          </span>
        </div>
      </div>
    </>
  );
};

export default ItemsTable;
