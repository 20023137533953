import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import { getAllOrganisations } from "../../../actions/cards/cards";
import { Button, Pagination } from "antd";
import { roofiFiData } from "./RootFiData";

function RootFiConnections() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const record = location?.state?.recordData;
  const companyStatus = location?.state?.companyStatus;
  const companyId = location?.state?.company_id;
  const connectionStatus = location?.state?.status;
  const organisationConnectionResponse = useSelector(
    (state) => state?.cards?.getAll_organisations
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(getAllOrganisations({ params: { page_number: page } }));
  };
  useEffect(() => {
    if (connectionStatus) {
      dispatch(
        getAllOrganisations({
          params: {
            page_number: currentPage,
            company_id: companyId,
            status: companyStatus,
          },
        })
      );
    } else {
      history.push({
        pathname: `/settings/rootFi-integration/${id}/connections/inactive`,
        state: { recordData: record },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectCompany = roofiFiData?.find((ele) => ele.id === parseInt(id))?.company_name;

  return (
    <div className="d-flex flex-col gap1">
      {connectionStatus && (
        <Button
          type="primary"
          className="pp-main-button"
          style={{
            width: "fit-content",
            alignSelf: "end",
            position: "absolute",
            top: "1.5rem",
          }}
          onClick={() =>
            history.push({
              pathname: `/settings/rootFi-integration/${id}/connections/Connect`,
              state: {
                recordData: record,
                newConnect: true,
              },
            })
          }
          // disabled={syncDataResponse?.loading}
        >
          <span>New Company</span>
        </Button>
      )}
      <div className="contentWrapper">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          {organisationConnectionResponse?.data?.data
            ?.filter((ele) => ele.integrations?.includes(selectCompany))
            ?.map((item) => (
              <div className="integration">
                <div
                  // className={`integration-card ${item?.status !== "HEALTHY" ? "disabled" : ""}`}
                  className="integration-card"
                  key={item?.id}
                  onClick={() => {
                    // if (item?.status === "HEALTHY") {
                    history.push({
                      pathname: `/settings/rootFi-integration/${id}/connections/${item?.status}`,
                      state: {
                        status: item?.status,
                        recordData: record,
                        company_name: item?.company_name,
                        selectedRecord: item,
                      },
                    });
                    // }
                  }}
                >
                  <span
                    className={`banner ${
                      item?.status === "HEALTHY" ? "active" : "inactive"
                    }`}
                  >
                    {item?.status === "HEALTHY" ? "Active" : "Inactive"}
                  </span>

                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    <img
                      src={record?.cardImageSrc}
                      alt={item?.cardTitle}
                      style={{
                        objectFit: "contain",
                        maxWidth: "10rem",
                        marginTop: "1rem",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <h2>{item?.company_name}</h2>
                    <p>{record?.cardDescription}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Pagination
        current={currentPage}
        total={organisationConnectionResponse?.data?.total_count}
        pageSize={organisationConnectionResponse?.data?.per_page || 0}
        onChange={handlePageChange}
        style={{ alignSelf: "self-end" }}
      />
    </div>
  );
}

export default RootFiConnections;
