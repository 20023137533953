import { ReducerFunctions } from "../../utils";

import {
  CustomerAddAddresses,
  CustomerAddToCart,
  CustomerBillingAddresses,
  CustomerCart,
  CustomerCreateOrder,
  CustomerDeleteCart,
  CustomerGetOrderComments,
  CustomerLogin,
  CustomerLogout,
  CustomerMyOrders,
  CustomerOrderComments,
  CustomerOrders,
  CustomerOrdersApprove,
  CustomerOrdersCancel,
  CustomerOrdersDestroy,
  CustomerOrdersReject,
  CustomerOrdersShow,
  CustomerOrdersSubmit,
  CustomerOrdersUpdate,
  CustomerOrdersUpdateQuantity,
  CustomerProduct,
  CustomerProducts,
  CustomerProfile,
  CustomerRemoveCartItem,
  CustomerShippingAddresses,
  CustomerUpdateItemQuantity,
} from "./orders";


export const customer_login_reducer = ReducerFunctions(CustomerLogin, {});
export const customer_logout_reducer = ReducerFunctions(CustomerLogout, {});
export const customer_products_reducer = ReducerFunctions(CustomerProducts, {});
export const customer_product_reducer = ReducerFunctions(CustomerProduct, {});
export const customer_profile_reducer = ReducerFunctions(CustomerProfile, {});
export const customer_add_to_cart_reducer = ReducerFunctions(CustomerAddToCart, {});
export const customer_cart_reducer = ReducerFunctions(CustomerCart, {});
export const customer_delete_cart_reducer = ReducerFunctions(CustomerDeleteCart, {});
export const customer_remove_cart_item_reducer = ReducerFunctions(CustomerRemoveCartItem, {});
export const customer_update_item_quantity_reducer = ReducerFunctions(CustomerUpdateItemQuantity, {});
export const customer_orders_reducer = ReducerFunctions(CustomerOrders, {});
export const customer_create_order_reducer = ReducerFunctions(CustomerCreateOrder, {});
export const customer_orders_show_reducer = ReducerFunctions(CustomerOrdersShow, {});
export const customer_my_orders_reducer = ReducerFunctions(CustomerMyOrders, {});
export const customer_orders_update_reducer = ReducerFunctions(CustomerOrdersUpdate, {});
export const customer_orders_submit_reducer = ReducerFunctions(CustomerOrdersSubmit, {});
export const customer_orders_cancel_reducer = ReducerFunctions(CustomerOrdersCancel, {});
export const customer_orders_update_quantity_reducer = ReducerFunctions(CustomerOrdersUpdateQuantity, {});
export const customer_orders_destroy_reducer = ReducerFunctions(CustomerOrdersDestroy, {});
export const customer_shipping_addresses_reducer = ReducerFunctions(CustomerShippingAddresses, {});
export const customer_billing_addresses_reducer = ReducerFunctions(CustomerBillingAddresses, {});
export const order_approve_reducer = ReducerFunctions(CustomerOrdersApprove, {});
export const order_reject_reducer = ReducerFunctions(CustomerOrdersReject, {});
export const order_comments_reducer = ReducerFunctions(CustomerOrderComments, {});
export const order_get_comments_reducer = ReducerFunctions(CustomerGetOrderComments, {});
export const customer_add_addresses_reducer = ReducerFunctions(CustomerAddAddresses, {})
