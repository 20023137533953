import {
  BankOutlined,
  CalendarOutlined,
  FilterOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Col, Dropdown, Input, Menu, Radio, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Download from "../../assets/icons/file_download.png";
import search from "../../assets/ippo_icons/search.png";
import {
  get_master_account_txns,
  get_person_accounts,
} from "../../actions/z_accounts";

const BankShow = ({
  handleBack,
  account,
  date,
  noBack,
  noDownload,
  addBtn,
  text,
  setOpenAddNew,
  dates,
  accountId,
  setFilterDetails,
  callAction,
}) => {
  const dispatch = useDispatch();
  const bankAcc = useSelector(
    (state) =>
      state?.z_accs?.accounts?.data?.data?.responseData?.data?.values?.filter(
        (ele) => ele.accountTypeCode !== "VIRTUAL"
      ) || []
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(moment().add(1, "months").format("YYYY-MM-DD"))
  );
  const [acc, setAcc] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(["1"]);

  useEffect(() => {
    if (dates?.[0] && dates?.[1]) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      setAcc(accountId);
    }
  }, [dates]);

  useEffect(() => {
    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
  }, []);

  function handleMenuClick(e) {
    setSelectedKeys([e.key]);
  }

  const dropMenu = () => {
    return (
      <div
        className="bank-menu"
        style={{ padding: "20px", backgroundColor: "#fff" }}
      >
        <Row gutter={[16]}>
          <Col span={6}>
            <Menu
              selectedKeys={selectedKeys}
              mode="inline"
              style={{ height: "100%", borderRight: 0 }}
              onClick={handleMenuClick}
            >
              <Menu.Item key="1" icon={<BankOutlined />}>
                Accounts
              </Menu.Item>
              <Menu.Item key="2" icon={<CalendarOutlined />}>
                Date
              </Menu.Item>
            </Menu>
          </Col>

          {/* Main Content */}
          <Col span={18}>
            {/* Date Range Picker */}
            {selectedKeys?.includes("1") ? (
              <Radio.Group
                className="accounts-sec"
                onChange={(e) => {
                  setAcc(e.target.value);
                }}
              >
                {bankAcc?.map((ele) => {
                  return (
                    <Radio
                      checked={acc === ele.accountId}
                      value={ele.accountId}
                      name="account"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <img
                          src={`https://flagcdn.com/${ele?.residencyCode?.toLowerCase()}.svg`}
                          alt="US Flag"
                          width="24"
                        />
                        <span className="acc-num" title={ele?.accountName}>
                          {ele?.accountName?.length > 8
                            ? `${ele?.accountName?.substr(0, 8)}...`
                            : ele?.accountName}{" "}
                          - {ele?.accountNumbers?.[0]?.accountNumber?.value}{" "}
                        </span>
                        {/* <img src={down} width={11} /> */}
                      </div>
                    </Radio>
                  );
                })}
              </Radio.Group>
            ) : (
              <>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  inline
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  inline
                />
              </>
            )}
            {/* Footer Buttons */}
            <div
              style={{
                display: "flex",
                justifyContent: selectedKeys?.includes("1")
                  ? "flex-end"
                  : "space-between",
                marginTop: "16px",
              }}
            >
              {selectedKeys?.includes("1") ? null : (
                <div>
                  <Input
                    value={moment(startDate).format("DD/MM/YYYY")}
                    style={{ width: 120, marginRight: "8px" }}
                  />
                  <span>→</span>
                  <Input
                    value={moment(endDate).format("DD/MM/YYYY")}
                    style={{ width: 120, marginLeft: "8px" }}
                  />
                </div>
              )}
              <div className="d-flex gap1">
                <button
                  className="back-btn"
                  onClick={() => {
                    if (callAction) {
                      callAction(
                        undefined,
                        moment().subtract(1, "week").startOf("week"),
                        moment().clone().endOf("week")
                      );
                    }
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="send-btn"
                  onClick={() => {
                    if (callAction) {
                      callAction(acc, moment(startDate), moment(endDate));
                    } else {
                      setFilterDetails?.({
                        account_id: acc,
                        // account_id: "ID-1165",
                        date_from: moment(startDate).format("YYYY-MM-DD"),
                        date_to: moment(endDate).format("YYYY-MM-DD"),
                        date_type: "VALUE_DATE",
                        page_number: 1,
                        page_size: 50,
                      });
                      dispatch(
                        get_master_account_txns({
                          account_id: acc,
                          // account_id: "ID-1165",
                          date_from: moment(startDate).format("YYYY-MM-DD"),
                          date_to: moment(endDate).format("YYYY-MM-DD"),
                          date_type: "VALUE_DATE",
                          page_number: 1,
                          page_size: 50,
                        })
                      );
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <div className="bank-show-section">
        <div className="bank-show-container flex-between">
          <div className="left-side v-center">
            <Dropdown overlay={dropMenu} open={open}>
              <button
                className="send-btn"
                onClick={() => {
                  setOpen((b) => !b);
                }}
              >
                <FilterOutlined /> Add Filter
              </button>
            </Dropdown>
            <div className="bank-name">
              {/* <img src={Open} alt="bank-icon" /> */}
              <span>{account}</span>
              {account && date && <span className="seperator">|</span>}
              <span>{date}</span>
              {account && (
                <span
                  className="change"
                  onClick={() => {
                    setOpen((b) => !b);
                  }}
                >
                  Change
                </span>
              )}
            </div>
          </div>
          <div className="v-center">
            <div>
              <Input
                className="pp-input"
                size="large"
                id="search-input"
                placeholder="Search..."
                prefix={<img src={search} alt="" style={{ height: "14px" }} />}
                style={{
                  backgroundColor: "#FAFAFA",
                  border: "1px solid #EAEAEA",
                  borderRadius: "5px",
                  minWidth: "22rem",
                }}
              />
            </div>
            <div className="right-side">
              {!noDownload && (
                <button className="mlHalf">
                  Download <img src={Download} alt="refresh-icon" />
                </button>
              )}
              {!noBack && (
                <button className="mlHalf" onClick={() => handleBack()}>
                  Back
                </button>
              )}
            </div>
            {addBtn && (
              <button
                className="send-btn mlHalf"
                onClick={() => setOpenAddNew(true)}
              >
                <PlusCircleFilled /> {text}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BankShow;
