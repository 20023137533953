import { getSymbolCode } from "../../config/helper";

const BaseCurrency = ({ symbol_code: sc }) => {
  let symbol_code;
  if (!localStorage?.getItem("isCustomer") && !localStorage?.getItem("isVendor")) {
    symbol_code = getSymbolCode();
  }
  return (

    <div className="base_currency" dangerouslySetInnerHTML={{
      __html: sc ? sc : symbol_code
    }}></div>

  );
};

export default BaseCurrency;
