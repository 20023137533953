import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  addUserFields,
  updateUserFields,
} from "../../../../actions/companySettings";
import { findLodash, objectToFormData } from "../../../../utils";
import "./../../styles/setting.css";

const { Option } = Select;

const ValidationSchema = yup.object().shape({
  column_name: yup.string().required("Please provide Field name"),
  column_type: yup.string().required("Please provide column type"),
  default_values: yup.mixed().when("column_type", {
    is: "dropdown",
    then: yup
      .array()
      .of(
        yup.object().shape({
          // id: yup.string().required(),
          level: yup.string().required(),
        })
      )
      .required()
      .min(1),
    otherwise: yup.array(),
  }),

  data: yup.mixed().when("column_type", {
    is: "document",
    then: yup.object().shape({
      max_size: yup.string().required("Max Size is required").min(2),
      doc_formats: yup
        .array()
        .required("Document Formats are required")
        .min(1)
        .of(yup.string()),
    }),
  }),
  // is_required: yup.boolean(),
  // view_in_pdf: yup.boolean(),
  // is_enable: yup.boolean()
});

const AddCustomField = (props) => {
  const { t } = useTranslation();
  const { id: currentId } = props;
  const formik = useFormik({
    initialValues: {
      column_name: "",
      column_type: "",
      default_values: [{ id: 1, level: "" }],
      is_required: false,
      view_in_pdf: false,
      is_enable: true,
      is_editable: true,
      data: {
        max_size: "",
        doc_formats: [],
      },
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      const payload = { ...values };
      if (payload.column_type !== "document") {
        // Object.keys(payload).forEach(key => {
        //   console.log(payload[key],"payloadDetail===>a");
        //   if (!payload[key] && typeof payload[key] !== "boolean") {
        //     delete payload[key];
        //   }
        // });
        delete payload.data;
      } else {
        payload.data = {
          max_size: payload.data.max_size || "",
          doc_formats: payload.data.doc_formats.join(",") || [],
        };
      }
      const default_values =
        values?.default_values?.map((item) => item.level) || [];

      if (values.column_type === "dropdown") {
        payload.default_values = [...default_values];
      } else {
        payload.default_values = [];
      }
      currentId
        ? dispatch(updateUserFields({ ...payload, id: currentId.toString() }))
        : dispatch(addUserFields({ ...payload }));
    },
  });
  const dispatch = useDispatch();
  const [fieldsData, setFieldsData] = React.useState([{ id: 1, level: "" }]);
  const [selectedDataType, setSelectedDataType] = React.useState("");
  const getUserFieldsResponse = useSelector(
    (state) => state.companySetting.getUserFields || {}
  );
  // const getSingleUserFieldResponse = useSelector(state => state.companySetting.getSingleUserFields || {})

  React.useEffect(() => {
    formik.resetForm();
    if (currentId) {
      const editValues = findLodash(getUserFieldsResponse?.data?.data, {
        id: currentId,
      });
      console.log(editValues, "editvalues===>");
      formik.setValues({
        column_name: editValues.column_name,
        column_type: editValues.column_type,
        is_required: editValues.is_required,
        view_in_pdf: editValues.view_in_pdf,
        is_enable: editValues.is_enable,
        default_values: Array.isArray(editValues.default_values)
          ? editValues.default_values.map((item, index) => ({
              id: index + 1,
              level: item,
            }))
          : [],
        data: {
          doc_formats:
            editValues.data?.doc_formats?.split(",")?.map((ele) => ele) || [],
          max_size: editValues?.data?.max_size,
        },
      });
      setSelectedDataType(editValues.column_type);
      editValues &&
        Array.isArray(editValues.default_values) &&
        setFieldsData(
          editValues.default_values.map((item, index) => ({
            id: index + 1,
            level: item,
          }))
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleCancelButton = () => {
    setSelectedDataType("");
    formik.resetForm();
    props.handleCancel();
  };

  const handleFields = (type, key) => {
    if (type === "add") {
      setFieldsData([...fieldsData, { id: fieldsData.length + 1, level: "" }]);
      formik.setFieldValue("default_values", [
        ...fieldsData,
        { id: fieldsData.length + 1, level: "" },
      ]);
    } else {
      const filterArray = [...fieldsData];
      filterArray.splice(key, 1);
      setFieldsData([...filterArray]);
      formik.setFieldValue("default_values", filterArray);
    }
  };

  const handleInputs = (evt, key) => {
    fieldsData[key].level = evt.target.value;
    setFieldsData([...fieldsData]);
    formik.setFieldValue("default_values", fieldsData);
  };

  return (
    <>
      <Form
        layout="vertical"
        onFinish={formik.handleSubmit}
        className="modal-form custom-form"
      >
        <div className="nameItem">
          <label className={`inline-block required`}>{t("custom_name")}</label>
          <br />

          <Input
            placeholder={t("custom_name")}
            onChange={formik.handleChange}
            name="column_name"
            value={formik.values.column_name}
          />
          {formik.touched.column_name && formik.errors.column_name && (
            <div className="errorMsg">{formik.errors.column_name}</div>
          )}
        </div>
        <div className="nameItem">
          <label className={`inline-block required`}>{t("column_type")}</label>
          <br />
          <Select
            placeholder="Select"
            className="w100"
            value={formik.values.column_type}
            onChange={(val) => {
              setSelectedDataType(val);
              formik.setFieldValue("column_type", val);
            }}
          >
            <Option value="string">
              {t("text_box")}({t("single_line")})
            </Option>
            <Option value="integer">{t("number")} (123..)</Option>
            <Option value="dropdown">{t("drop_down")}</Option>
            <Option value="document">{"Document"}</Option>
            <Option value="datetime">{"Date & Time"}</Option>

            {/* <Option value="checkbox">Check Box</Option>
                  <Option value="multicheckbox">Multi Select</Option> */}
          </Select>
          {formik.touched.column_type && formik.errors.column_type && (
            <div className="errorMsg">{formik.errors.column_type}</div>
          )}
        </div>
        {selectedDataType === "dropdown" ? (
          <Form.Item label={t("default_value")}>
            <div className="optionsContainer">
              <Row>
                {formik.values.default_values.map((item, index) => (
                  <div key={item.id} className="fieldsDiv mt1">
                    <span className="inlineItem levelTitle">
                      {t("options")} {index + 1}
                    </span>
                    <Input
                      className="inlineItem"
                      onChange={(e) => handleInputs(e, index)}
                      value={item.level}
                    />
                    <MinusCircleOutlined
                      className="minusButton inlineItem mtHalf cursor ml2"
                      onClick={() => handleFields("remove", index)}
                    />
                  </div>
                ))}
              </Row>
              <Button
                type="link"
                className="addFieldButton mt2"
                onClick={() => handleFields("add")}
              >
                <PlusCircleOutlined /> {t("add_option")}
              </Button>
              {formik.touched.default_values &&
                formik.errors.default_values?.length && (
                  <div className="errorMsg">{t("options_required")}</div>
                )}
            </div>
          </Form.Item>
        ) : null}
        {formik.values.column_type === "document" ? (
          <>
            {/* <Form.Item label={t('Document Type')} className={`inline-block required1`} > */}
            <div className="nameItem">
              <label className={`inline-block required`}>
                {t("Document Type")}
              </label>
              <br />
              <div className="fieldsDiv mt1">
                <Row>
                  <Select
                    name="doc_formats"
                    mode="multiple"
                    placeholder={t("Select doc_type")}
                    style={{ width: "30rem" }}
                    value={formik.values?.data?.doc_formats}
                    onChange={(val) =>
                      formik.setFieldValue(`data['doc_formats']`, val)
                    }
                  >
                    <Option value="jpg">{t("JPG")}</Option>
                    <Option value="jpeg">{t("JPEG")}</Option>
                    <Option value="png">{t("PNG")}</Option>
                    <Option value="pdf">{t("PDF")}</Option>
                    <Option value="csv">{t("CSV")}</Option>
                    <Option value="xls">{t("XLS")}</Option>
                    <Option value="xlsx">{t("XLSX")}</Option>
                    <Option value="tiff">{t("TIFF")}</Option>
                    <Option value="pptx">{t("PPT")}</Option>
                  </Select>
                  {formik.touched?.data?.doc_formats &&
                    formik.errors?.data?.doc_formats && (
                      <div className="errorMsg">{t("options_required")}</div>
                    )}
                </Row>
              </div>
            </div>
            {/* </Form.Item> */}
            {/* <Form.Item label={t('Max Size')} > */}
            <div className="nameItem">
              <label className={`inline-block required`}>{t("Max Size")}</label>
              <br />
              <Select
                name="max_size"
                placeholder={t("Select Max size")}
                value={formik.values.data.max_size}
                style={{ width: "30rem" }}
                onChange={(val) =>
                  formik.setFieldValue(`data['max_size']`, val)
                }
              >
                <Option value="500">{t("500 Kb")}</Option>
                <Option value="1000">{t("1 MB")}</Option>
                <Option value="2000">{t("2 MB")}</Option>
                <Option value="3000">{t("3 MB")}</Option>
              </Select>
              {formik.touched.data?.max_size &&
                formik.errors.data?.max_size && (
                  <div className="errorMsg">{t("options_required")}</div>
                )}
              {/* {formik.touched.max_size && formik.errors.max_size && (
                          <div className='errorMsg'>
                          {formik.errors.max_size}
                          </div>
                          )} */}
              {/* </Form.Item> */}
            </div>
          </>
        ) : null}
        <Row>
          <Col span={24}>
            <Form.Item>
              <Checkbox
                name="is_required"
                checked={formik?.values?.is_required}
                onChange={formik.handleChange}
              >
                {t("is_mandatory")}
              </Checkbox>
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item >
              <Checkbox name="view_in_pdf" checked={formik.values.view_in_pdf} onChange={formik.handleChange}>Show in all PDF</Checkbox>
            </Form.Item>
          </Col> */}
          {currentId && (
            <Col span={24}>
              <Form.Item>
                <Checkbox
                  name="is_enable"
                  checked={formik.values.is_enable}
                  onChange={formik.handleChange}
                >
                  {t("enable")}
                </Checkbox>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Button
          key="1"
          htmlType="submit"
          className="formButton mt1 primaryButton"
        >
          {currentId ? t("update") : t("save")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancelButton()}
        >
          {t("cancel")}
        </Button>
      </Form>
    </>
  );
};

export default AddCustomField;
