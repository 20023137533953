import { Document, Page, View, StyleSheet, Text, PDFViewer, page } from "@react-pdf/renderer";
import React from "react";
import { useState } from "react";
import moment from "moment";
import TemplateTable from "./TemplateTable";
import { amountFormat } from "../../utils";
import { line } from "d3";



export const columns = [{
  lable: "Description",
  key: "item_name",
  width: 80
}, {
  lable: "Amount Paid",
  key: "total_amount",
  width: 20
},
  //   {
  //     lable: "Total Amount",
  //     key: "total_amount",
  //     width: 30,

  //   }]
]



const Payments = (props) => {
  const {
    billingAddressCity,
    billingAddressState,
    billingAddresszip,
    billingCountry,
    billAddress,
    companyAddr,
    companyCity,
    companyState,
    companyCountry,
    companyPin
  } = props;
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      paddingTop: 25,
      paddingLeft: 20,
      paddingRight: 20
    },

    dataRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      borderColor: 'lightgray',
      paddingVertical: 10,
    },
    borderedContainer: {
      // border: '1px solid #000000',
      // flex: 1,
    },
    Menu: {
      display: "flex",
      flexDirection: 'row',
      justifyContent: "space-between",
      paddingLeft: "10px",
      width: "100%",

    },
    display: {
      flexDirection: 'row',
      //justifyContent: 'space-between',
      paddingHorizontal: 10,
      marginTop: 10,
      width: "60%"
    },
    displayTwo: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      width: "70%",
    },
    displayTab: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 20,
      width: "100%", // Change the width to 100%
      marginTop: 15, // Change marginTop to a numeric value (without "px")
    },
    container3: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      width: "80%"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    container2: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center", // Center the content vertically
      justifyContent: "space-between", // Add space around the vertical line
    },
    content: {
      flex: 1, // Fill available space
      paddingHorizontal: 10, // Add some horizontal spacing
    },
    containerOne: {
      display: "flex",
      //  	alignItems: 'flex-end',
      marginTop: 10,
    },
    text: {
      fontSize: 10,
      marginBottom: 5,
      color: "black",
      fontWeight: "bold",
    },
    textOne: {
      fontSize: 12,
      marginBottom: 5,
      color: "black",
      fontWeight: "bold",
      marginTop: 5
    },
    textTwo: {
      fontSize: 12,
      marginBottom: 5,
      color: "black",

    },
    subMenu: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20,
      width: "100%",
      border: "1px solid black",
      // paddingBottom: "50px",
      //margin: "10px"
      marginBottom: "10px",
      //paddingBottom:"9px"
    },
    Menu1: {
      display: "flex",
      flexDirection: 'row',
      justifyContent: "space-between",
      marginTop: 60,
    },
    table: {
      display: 'table',
      width: '92%',
      marginTop: 15,
      paddingLeft: "2%"
    },
    tableHeadRow: {
      //  backgroundColor: "#ccc",
      flexDirection: 'row',

    },
    tableRow: {
      //  flexDirection: 'row',
    },
    mainTableCellOne: {
      borderStyle: 'solid',
      borderWidth: 1,
      padding: 5,
      // backgroundColor: "grey",
      color: "black",
      fontSize: "12px"
    },
    dataTableCellOne: {
      borderBottomStyle: 'solid',
      padding: 5,
      // color: "grey",
      fontSize: "12px",
      borderBottom: "1px"
    },
    containerBox: {
      display: "flex",
      //alignItems: 'flex-end',
      // marginTop: 5,
      //   //border:"1px solid black"


    },
    minHeight: {

    },
    columnHeader: {
      width: '50%', // Adjust width as needed
      borderBottomWidth: 1, // Add a bottom border for the header row
      borderColor: 'black', // Border color
      padding: 10, // Padding for header cells
    },
    dataRow: {
      flexDirection: 'row',
      width: '100%', // Full width for data rows
      borderBottomWidth: 1, // Add a bottom border for data rows
      borderColor: 'black', // Border color
    },
    dataCell: {
      width: '50%', // Adjust width as needed
      // padding: 10, // Padding for data cells
    },
    footer: {
      borderBottomWidth: 1, // Add a bottom border for the footer
      borderColor: 'black', // Border color
      padding: 10, // Padding for the footer
    },
  })

  const lineItems = props?.useDummyData === true ? props?.obj?.lineItems : props?.lineItems

  const companyAddress = () => {
    const addressComponents = [

      companyAddr,
      companyCity,
      companyState,
      companyCountry,
      companyPin
    ];

    // Filter out undefined or empty values
    const filteredCompanyComponents = addressComponents.filter(Boolean);
    const address = filteredCompanyComponents.join(', ');

    return address;
  };

  const compAddr = companyAddress()
  const formatAddress = () => {
    const addressComponents = [

      billAddress,
      billingAddressCity,
      billingAddressState,
      billingAddresszip,
      billingCountry,
    ];

    // Filter out undefined or empty values
    const filteredAddressComponents = addressComponents.filter(Boolean);
    const address = filteredAddressComponents.join(', ');

    return address;
  };

  const formAddress = formatAddress()
  const currency = props?.useDummyData === true ? props?.obj?.currency : props?.currency

  return (
    <div>
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <Document>
          <Page size="A4" style={styles.page}  >
            <View style={styles.borderedContainer}>
              <View style={styles.Menu}>
                <View>
                  <Text style={{ marginTop: "5px", fontWeight: "bold", fontSize: "18px" }}>{props?.useDummyData === true ? props?.obj?.companyName : props?.companyName}</Text>
                  <Text style={{ marginTop: "5px", maxWidth: "150px", fontSize: "11px" }}>{props?.useDummyData === true ? props?.obj?.address : compAddr}</Text>
                  <Text style={{ marginTop: "5px", maxWidth: "150px", fontSize: "11px" }}>TAX ID : {props?.useDummyData === true ? "GST24AAACC4563F" : props?.gstNo}</Text>





                </View>
                <View >
                  <Text style={{ fontSize: "18px", paddingTop: "12px", paddingLeft: "40px", textAlign: "right" }}>Receipt</Text>
                  <Text style={{ fontSize: "10px", paddingTop: "12px", paddingLeft: "40px", textAlign: "right" }}>CALL:{props?.useDummyData === true ? props?.obj?.mobile : props?.primaryContact}</Text>
                  <Text style={{ fontSize: "10px", paddingTop: "5px", paddingLeft: "40px" }}>Email:{props?.useDummyData === true ? props?.obj?.email : props?.primaryEmail}</Text>


                </View>
              </View>
              <View style={{ marginTop: "15px" }}>


                <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>


                  <View style={styles.subMenu}>

                    <View style={{ ...styles.containerBox }}>
                      <Text style={{ color: "black", fontSize: 12 }}></Text>

                      <Text style={{ color: "black", fontSize: 12, marginTop: "5px", paddingLeft: "10px" }}>From:</Text>
                      <Text style={{ color: "black", fontSize: 10, marginTop: "5px", paddingLeft: "10px" }}>{props?.useDummyData === true ? props?.obj?.beniName : props?.benefiName}</Text>
                      <Text style={{ color: "black", fontSize: 10, marginTop: "5px", paddingLeft: "10px" }}>{props?.useDummyData === true ? props?.obj?.beniEmail : props?.benifiEmail}</Text>
                      <Text style={{ color: "black", fontSize: 10, marginTop: "5px", paddingLeft: "10px", maxWidth: "180px" }}>{props?.useDummyData === true ? props?.obj?.address : formAddress}</Text>

                      <Text style={{ color: "black", fontSize: 10, marginTop: "5px", paddingLeft: "10px" }}>TAX ID : {props?.useDummyData === true ? props?.obj?.gst : props?.benficiaryGST}</Text>

                    </View>
                    <View style={{ ...styles.containerBox, borderLeft: "1px border grey", paddingLeft: "15px" }}>
                      <View style={{ fontSize: 10, marginTop: "10px", width: 200, display: "flex", flexDirection: "row" }}>
                        <Text style={{
                          minWidth: "100px",
                          fontSize: 12,
                          //ś marginBottom: 5,
                          color: "black",
                          fontWeight: "bold",
                        }}>
                          Date

                        </Text>
                        <Text>
                          <Text style={styles.text}>{props?.useDummyData === true ? props?.obj?.invoiceDate : moment(props?.invoiceDate).format("DD-MM-YYYY")}</Text>

                        </Text>
                      </View>
                      <View style={{ fontSize: 10, marginTop: "3px", display: "flex", flexDirection: "row" }}>
                        <Text style={{
                          minWidth: "100px",
                          fontSize: 12,
                          // marginBottom: 5,
                          color: "black",
                          fontWeight: "bold",
                        }}>
                          {/* {props?.useDummyData === true ? props?.numTitle} */}

                        </Text>
                        {/* <Text>
                          <Text style={styles.text}>{props?.useDummyData === true ? props?.obj?.invoiceNo : props?.invoiceNo}</Text>

                        </Text> */}
                      </View>
                    </View>
                  </View>
                </View>

              </View>

              <View style={{ border: "1px solid black", marginTop: "15px" }}>
                <View style={{ width: "100%", borderBottom: "1px solid black", flexDirection: "row" }}>

                  <View style={{ width: "70%", paddingLeft: "10px" }}>
                    <Text style={{ fontSize: 15, padding: "5px" }}>Description </Text>
                  </View>
                  <View style={{ width: "30%", borderLeft: "1px solid black", paddingLeft: "10px" }}>
                    <Text style={{ fontSize: 15, padding: "5px" }}>Amount Paid</Text>
                  </View>
                </View>
                <View style={{ borderRight: "1px solid black" }}>
                  {lineItems?.map((ele, index) => (

                    <View style={{ width: "100%", flexDirection: "row" }}>
                      {lineItems?.length === index + 1 ? styles.minHeight.minHeight = "150px" : styles.minHeight.minHeight = "0px"}

                      {columns.map(itm => {
                        return <>
                          {(itm.key === 'total_amount' ? <View style={{ ...styles.minHeight, width: "30%", borderLeft: "1px solid black" }}><Text style={{ textAlign: "right", paddingRight: "5px", marginTop: "5px", fontSize: 15 }}>{currency} {''} {amountFormat(ele[itm.key])}</Text> </View> : <View style={{ ...styles.minHeight, width: "70%", paddingLeft: "10px", fontSize: "15px", marginTop: "5px" }}><Text >{ele[itm.key]}</Text></View>)}
                        </>

                      })}
                    </View>

                  ))}

                </View>
                <View style={{ flexDirection: "row", width: "100%", border: "1px solid black" }}>
                  <View style={{ width: "70%", paddingTop: "15px", paddingBottom: "15px", textAlign: "center" }} >
                    <Text>
                      Total Payment Received With Thanks

                    </Text>
                  </View>
                  <View style={{ width: "30%", borderLeft: "1px solid black ", paddingTop: "15px", paddingBottom: "15px" }}>
                    <Text style={{ textAlign: "right", padding: "5px" }}>
                      {props?.useDummyData === true ? currency + ' ' + amountFormat(props?.obj?.totalAmount) : currency + " " + amountFormat(props?.totalAmount)}
                    </Text>
                  </View>
                </View>
              </View>

            </View>



          </Page>
        </Document>
      </PDFViewer>
    </div>
  )
}

export default Payments;

