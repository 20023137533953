import { useFormik } from "formik";
import React from "react";

import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Space, Table, Tabs, Typography, message } from 'antd';
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAdvancesField,
  DeleteAdvancesField,
  UpdateAdvancesField,
  UpdateCompanyAdvances,
  UpdateCompanyTransaction,
  companySetting,
  deleteAdvancesField,
  getAdvancesField,
  updateAdvancesField,
  updateCompanyTransaction
} from '../../../../actions/companySettings';
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { objectToFormData } from '../../../../utils';
import "./../../styles/setting.css";
import "./../Custom.css";
import AddExpenseField from './AddAdvancesField';
const { TabPane } = Tabs;
const { Title } = Typography;

message.config({
  maxCount: 3,
});


const Advances = ({ hasWriteAccess, type }) => {
  // const [activeTab, setActiveTab] = React.useState('profile');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editMode, setEditMode] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState('center');
  const [selectedId, setSelectedId] = React.useState('');
  const [advancesFields, setAdvancesFields] = React.useState([]);
  // const [companyValues, setCompanyValues] = React.useState({});
  const companySettingResponse = useSelector(state => state.companySetting.companySetting || {});
  const updateCompanySettingResponse = useSelector(state => state.companySetting.updateTransactionReport || {});
  const advancesFieldResponse = useSelector(state => state.companySetting.getAdvancesField || {});
  const deleteAdvancesFieldResponse = useSelector(state => state.companySetting.deleteAdvancesField || {});
  const addAdvancesFieldResponse = useSelector(state => state.companySetting.addAdvancesField || {});
  const updateAdvancesFieldResponse = useSelector(state => state.companySetting.updateAdvancesField || {});
  const updateAdvancesReportResponse = useSelector(state => state.companySetting.updateAdvancesReport || {});
  const setTab = () => {

  }

  const totalRecords = advancesFieldResponse?.data?.total_count



  const columns = [
    {
      title: t('field'),
      dataIndex: "column_name",
      key: "column_name",
    },
    {
      title: t('column_type'),
      dataIndex: "column_type",
      key: "column_type",
      render: (text, render) => {
        if (text == "integer") {
          return "Number"
        } else if (text === "string") {
          return "Text Box"
        }
        else if (text === "datetime") {
          return "Date & Time"
        }
        else if (text === "document") {
          return "Document"
        }
        else if (text === "dropdown") {
          return "Drop Down"
        }
        else {
          return text
        }
      }
    },
  ];


  React.useEffect(() => {
    dispatch(companySetting({ module_type: type }));
    dispatch(getAdvancesField({ module_type: type }));
    dispatch(headerTitles({
      title: type ?? 'advances',
      description: ""
    }))
    // setIsModalVisible(false,);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { data: { data: {result:company_setting = {}} = {} } = {} } = companySettingResponse;
  console.log({ company_setting });
  React.useEffect(() => {
    formik.resetForm();
    if (type === "TRIP")
      formik.setValues({
        module_type_comments_pdf: company_setting.trip_amount_comments_pdf,
        module_type_history_pdf: company_setting.trip_amount_history_pdf,
        module_type_mandatory: company_setting.trip_amount_mandatory,
        module_type_receive_copy: company_setting.trip_amount_receive_copy,
        module_type_receive_email: company_setting.trip_amount_receive_email,
        module_type_amount_reminder: company_setting.trip_amount_reminder,
        module_type_amount_reminder_days: company_setting.trip_amount_reminder_days,
        module_type_emp_details_pdf: company_setting.trip_emp_details_pdf,
        module_type_include_logo_pdf: company_setting.trip_include_logo_pdf,
        module_type_prefix: company_setting.trip_prefix,
        module_type_starts_with: company_setting.trip_starts_with,
        module_type_display_starts_with: company_setting?.trip_module_display_starts_with,
        remainders:[]
      });
    else
      formik.setValues({
        module_type_comments_pdf: company_setting.adv_amount_comments_pdf,
        module_type_history_pdf: company_setting.adv_amount_history_pdf,
        module_type_mandatory: company_setting.adv_amount_mandatory,
        module_type_receive_copy: company_setting.adv_amount_receive_copy,
        module_type_receive_email: company_setting.adv_amount_receive_email,
        module_type_amount_reminder: company_setting.adv_amount_reminder,
        module_type_amount_reminder_days: company_setting.adv_amount_reminder_days,
        module_type_emp_details_pdf: company_setting.adv_emp_details_pdf,
        module_type_include_logo_pdf: company_setting.adv_include_logo_pdf,
        module_type_prefix: company_setting.adv_prefix,
        module_type_starts_with: company_setting.adv_starts_with,
        module_type_display_starts_with: company_setting?.adv_module_display_starts_with,
        remainders:[]
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettingResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = '', status = "", errors = '' } = {} } = updateCompanySettingResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(companySetting({ module_type: type }));
      setEditMode(false)
      dispatch({
        type: UpdateCompanyTransaction.RESET
      })
    }
    else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: UpdateCompanyTransaction.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanySettingResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = '', status = "", errors = '' } = {} } = updateAdvancesFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getAdvancesField({}));
      dispatch({
        type: UpdateAdvancesField.RESET
      })
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: UpdateAdvancesField.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAdvancesFieldResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = '', status = false } = {} } = updateAdvancesReportResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(companySetting({}));
      handleSubmit();


      dispatch({
        type: UpdateCompanyAdvances.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAdvancesReportResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = '', status = "", errors = '' } = {} } = deleteAdvancesFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getAdvancesField({module_type:type}));
      dispatch({
        type: DeleteAdvancesField.RESET
      })
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: DeleteAdvancesField.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAdvancesFieldResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = '', status = "", error = '' } = {} } = addAdvancesFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getAdvancesField({module_type:type}));
      // dispatch(addAdvancesField({module_type:"EXPENSE"}))
      dispatch({
        type: AddAdvancesField.RESET
      })
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddAdvancesField.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAdvancesFieldResponse]);

  React.useEffect(() => {
    const { data: { data: fields = [], status = false } = {} } = advancesFieldResponse;
    if (status) {
      setAdvancesFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancesFieldResponse]);


  const tableAction = [
    {
      title: t('mandatory'),
      dataIndex: "is_required",
      key: "is_required",
      render: (status, record) =>
        <Checkbox key={record.id}
          disabled={!hasWriteAccess}
          onChange={(e) => dispatch(updateAdvancesField(({ ...record, is_required: e.target.checked,params:{module_type:type} })))}
          checked={status}
        ></Checkbox>
    },
    // {
    //   title: "Show in PDF",
    //   dataIndex: "view_in_pdf",
    //   key: "view_in_pdf",
    //   render: (status, record) =>
    //     <Checkbox key={record.id}
    //       onChange={(e) => dispatch(updateAdvancesField(objectToFormData({ ...record, view_in_pdf: e.target.checked })))}
    //       checked={status}
    //     ></Checkbox>
    // },
    {
      title: t('enable'),
      dataIndex: 'is_enable',
      key: 'is_enable',
      render: (status, record) =>
        <Checkbox key={record.id}
          disabled={!hasWriteAccess}
          onChange={(e) => dispatch(updateAdvancesField(({ ...record, is_enable: e.target.checked,params:{module_type:type} })))}
          checked={status}
        ></Checkbox>
    }, {
      title: t('actions'),
      key: 'action',
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            disabled={!hasWriteAccess}
            onClick={() => showEditModal(record.id)}><img src={EditIcon} alt={"logo"} className="action-logo" /></button>
          <button
            disabled={!hasWriteAccess}
            onClick={() => showDeleteModal(record.id)}><img src={DeleteIcon} alt={"logo"} className="action-logo" /></button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setSelectedId('');
    setIsModalVisible(true);
    setIsModalType('right');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType('center');
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType('right');
  };

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t('are_u_sure')}</Text><br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() => dispatch(deleteAdvancesField(({ id: selectedId,params:{module_type:type} })))}
        >{t('delete')}</Button>
        <Button key="2" className="cancelButton ml1 mt1" onClick={() => handleCancel()}>{t('cancel')}</Button>
      </>
    );
  }

  const formik = useFormik({
    initialValues: {
      module_type_starts_with: "",
      module_type_prefix: "",
      module_type_amount_reminder: "",
      module_type_amount_reminder_days: ""
    },
    onSubmit: (values) => {
      dispatch(updateCompanyTransaction({ ...values, params: { module_type: type } }));
    }
  })

  const handleEdit = () => {
    setEditMode(true);
  }

  const handleSubmit = () => {
    setEditMode(false);
  }

  console.log({ companySettingResponse });

  return (
    <>
      <Modal
        title={modalType === 'center' ?
          <><img src={DeleteIcon} alt={"logo"} className="action-logo" /> {t('delete_field')}</> :
          <Title level={4}>{selectedId ? t('edit_fields') : t('add_fields')} &nbsp;<span className="text-small">{t('enter_details')}</span></Title>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={modalType === 'center' ? "center-aligned-modal" : "right-alinged-modal-small"}
      >
        {modalType === 'center' ? renderDeleteOption() : <AddExpenseField id={selectedId} handleCancel={handleCancel} type={type}/>}
      </Modal>
      <Tabs onChange={setTab} type="card" size='large' className="custom-tabs">
        <TabPane tab={t('preferences')} key="preferences">
          <div className="tabContentWrapper">
            <Title level={5}>
              {t('auto_generate')}
            </Title>
            <Divider />
            <Form layout="vertical" className="custom-form"
              onFinish={formik.handleSubmit}
            // validateMessages={validateMessages1}
            >
              <Row>
                <Col span={8}>
                  <Form.Item

                    label={t('Prefix')}
                  >
                    <Input
                      name="module_type_prefix"
                      placeholder=""
                      value={formik.values.module_type_prefix}
                      onChange={formik.handleChange}
                      disabled={!editMode || company_setting.trip_prefix || company_setting.adv_prefix}
                    //   disabled={company_setting.adv_prefix}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="ml1">
                  <Form.Item
                    label={t('start_with')}
                  >
                    <Input
                      name="module_type_starts_with"
                      placeholder=""
                      value={company_setting.adv_module_display_starts_with
                        ? company_setting.adv_module_display_starts_with
                        : formik.values.module_type_starts_with}
                      //onChange={formik.handleChange}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) {
                          return;
                        }
                        formik.setFieldValue("module_type_starts_with", e.target.value)
                      }}
                      disabled={!editMode || company_setting.trip_module_display_starts_with || company_setting.adv_module_display_starts_with}
                    />
                  </Form.Item>
                </Col>
              </Row>


              {/* <Title level={5} className="customTitle">
                        PDF Options
                    </Title>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Form.Item name="adv_emp_details_pdf" valuePropName="checked">
                                <Checkbox>Show Employee Details in PDF</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="adv_amount_history_pdf" valuePropName="checked">
                                <Checkbox>Show advance amount history in PDF</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="adv_amount_comments_pdf" valuePropName="checked">
                                <Checkbox>Show advance amount comments in PDF</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="adv_include_logo_pdf" valuePropName="checked">
                                <Checkbox>Include company logo in PDF</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row> */}

              <Title level={5} className="customTitle">
                {t('notifications')}
              </Title>
              <Divider />
              <Row>
                <Col span={24}>
                  <Form.Item
                    valuePropName="checked" style={{ marginBottom: '0px' }}>
                    <Checkbox checked={formik.values.module_type_amount_reminder}
                      disabled={!editMode}
                      name="module_type_amount_reminder" onChange={(e) => {
                        formik.setFieldValue("module_type_amount_reminder", e.target.checked)
                      }}>
                      <span className="flexDisplay">
                        {t(type === "TRIP" ? "reminder_trip_alert" : 'reminder_alert')}&nbsp;
                        <Form.Item className="shortNumberInput ml1" >
                          <Input className="shortInput"
                            name="module_type_amount_reminder_days"
                            disabled={!editMode}
                            onChange={(e) => {
                              if (Number.isNaN(Number(e.target.value))) {
                                return;
                              }
                              formik.setFieldValue("module_type_amount_reminder_days", e.target.value)
                            }}
                            value={formik.values.module_type_amount_reminder_days}

                          />
                        </Form.Item>  &nbsp;{t('days_submission')}
                      </span>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              {!editMode &&
                <Button type="button" disabled={!hasWriteAccess} className="formButton mt3" onClick={() => handleEdit()} >{t("edit")}</Button>}
              {
                editMode &&
                <Button key="5" className="formButton mt3" htmlType="submit">{t('save')}</Button>
              }
              {/* <Button key="6" className="cancelButton ml1 mt3" onClick={() => history.push(RouteConstants.SETTING_USERS)}></Button> */}
            </Form >
          </div >
        </TabPane >
        <TabPane tab="Fields" key="fields">
          <div className="tabContentWrapper">
            {<Button
              disabled={!hasWriteAccess}
              onClick={showModal}
              className="mb1 addNew"><img src={AddNewIcon} alt={"logo"} className="addNew-logo" />{t('new_field')}</Button>}
            <Table rowKey="id" columns={[...columns, ...tableAction]} dataSource={advancesFields} className="settingsTable"
              pagination={{
                total: totalRecords || 0,
                onChange: (pagee) => {
                  dispatch(getAdvancesField({ page_number: pagee,module_type:type }))
                }
              }}
            />
          </div>
        </TabPane>
      </Tabs >
    </>
  );
};

export default Advances;
