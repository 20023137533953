import { Button, Upload } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import attachment from "../../assets/icons/attachment.svg";
import cloud from "../../assets/icons/cloud.svg";
const ButtonUpload = ({
  value,
  label,
  required,
  errorMessage,
  errors,
  horizontal = false,
  beforeUpload,
  handleChange,
  multiple = false,
  accept,
  width,
  hasId,
  renderUploaded,
  disabled,
  fileSize,
  ...restOfProps
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="business_upload"
      style={{
        width: width ? width : "100%",
        pointerEvents: disabled ? "none" : "all",
        opacity: disabled ? 0.6 : 1,
      }}
    >
      <Controller
        {...restOfProps}
        shouldUnregister={true}
        render={({ field: { onChange, value, name } }) =>
          hasId ? (
            renderUploaded
          ) : (
            <div
              style={{
                alignItems: horizontal && "center",
                display: horizontal && "flex",
              }}
              className="upload-sec"
            >
              <div className="d-flex align-center">
                <img
                  src={cloud}
                  className="cloud"
                  width={45}
                  style={{
                    marginLeft: 8,
                  }}
                />
                <div className="d-flex flex-col ml1">
                  <span className="title">
                    {label}
                    {required && (
                      <sup
                        style={{
                          color: "red",
                          fontSize: "14px",
                          position: "relative",
                          top: "-1px",
                        }}
                      >
                        *
                      </sup>
                    )}
                  </span>
                  <span
                    className="supported"
                    style={{
                      marginTop: 5,
                    }}
                  >
                    Files Supported:{" "}
                    {Array.isArray(accept)
                      ? accept?.join(", ")
                      : "JPEG, PDF and PNG (max size 2mb)"}
                  </span>
                  {fileSize ? (
                    <span
                      className="supported"
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Max File Size: {(fileSize / (1024 * 1024)).toFixed(2)} MB
                    </span>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  paddingTop: !horizontal && "0px",
                  paddingLeft: horizontal && "10px",
                }}
              >
                <Upload
                  showUploadList={false}
                  maxCount={1}
                  beforeUpload={beforeUpload}
                  className="upload_file"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  name={name}
                  value={value ? value : ""}
                  // maxCount={1}
                  multiple={multiple}
                  accept={accept}
                  disabled={disabled}
                >
                  <Button
                    style={{ color: "#293241" }}
                    className="upload_buttom"
                  >
                    {t("Attach File")}
                    <img
                      src={attachment}
                      width={16}
                      style={{
                        marginLeft: 8,
                      }}
                    />
                  </Button>
                </Upload>
                {errors?.[restOfProps?.name]?.message || errorMessage ? (
                  <div className="errorMsg">
                    {errors?.[restOfProps?.name]?.message || errorMessage}
                  </div>
                ) : null}
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

export default ButtonUpload;
