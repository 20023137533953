import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { headerTitles } from '../../actions/masterdata/masterdata';
import ub_1 from "../../assets/images/payroll.png";
import { useTranslation } from 'react-i18next';
const Payroll = () => {
  const dispatch = useDispatch();
const {t} = useTranslation();
  useEffect(() => {
    dispatch(headerTitles({
      title: 'payroll',
      description: 'manage_payroll'
    }))
  }, [])

  return (
    <div className='utility-bills payroll'>
      <div className='d-flex'>
        <div className='w35'>
          <h4>{t('run_payroll')}</h4>
          <p>
            {t('run_payroll_many')}
          </p>
          <button className='pp-main-button'>
            <span>
              {t('access_payroll')}
            </span>
          </button>
          <div className='line'></div>
          <label>{t('payroll_preference')}</label>
          <span className='link'>{t('setup_payroll')}</span>
          <span className='link'>{t('view_shared_bills')}</span>
        </div>
        <img src={ub_1} alt="" className='w65' />
      </div>
    </div>
  );
};

export default Payroll;