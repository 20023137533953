import React from 'react'

const CusInvoices = ({ scale, fill, opacity }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.921" height="22.317" viewBox="0 0 20.921 22.317" style={{
      scale: scale ? scale : 1,
      zoom: scale ? scale : 1
    }}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_6073" data-name="Rectangle 6073" width="20.921" height="22.317" fill={fill || "#fff"} />
        </clipPath>
      </defs>
      <g id="Group_18841" data-name="Group 18841" opacity={opacity || "0.5"}>
        <g id="Group_18840" data-name="Group 18840" clip-path="url(#clip-path)">
          <path id="Path_20253" data-name="Path 20253" d="M17.434,0H2.789l.059.028A3.108,3.108,0,0,0,0,3.119v5.25H5.579v13.25a.7.7,0,0,0,.957.647l3.228-1.292,3.227,1.292a.679.679,0,0,0,.517,0l3.229-1.292,3.227,1.292a.7.7,0,0,0,.26.05.7.7,0,0,0,.7-.7V3.487A3.491,3.491,0,0,0,17.434,0ZM5.579,4.184V6.974H1.395V3.119A1.727,1.727,0,0,1,3.119,1.395h.736A1.727,1.727,0,0,1,5.579,3.119ZM17.434,16.737H11.855a.7.7,0,0,1,0-1.395h5.579a.7.7,0,1,1,0,1.395m0-2.789H11.855a.7.7,0,0,1,0-1.395h5.579a.7.7,0,1,1,0,1.395m0-2.789H9.066a.7.7,0,0,1,0-1.395h8.368a.7.7,0,1,1,0,1.395m0-5.579H9.066a.7.7,0,0,1,0-1.395h8.368a.7.7,0,1,1,0,1.395" fill={fill || "#fff"} />
        </g>
      </g>
    </svg>
  )
}

export default CusInvoices;
