import React, { useState } from "react";
import UploadField from "../../inputs/UploadField";
import { FormInput, FormSelect } from "../../inputs";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import endpointGridApi from "../../../config/AxiosGridApi";
import { gridApis } from "../../../config/GridApis";
import { message } from "antd";
import usePayAxios from "../../../config/useAxios";
import { countries } from "../../Settings/Organisation/country";
import { useSelector } from "react-redux";
import ButtonUpload from "../../inputs/ButtonUpload";

const AdditionalInfo = ({
  next,
  back,
  mainData,
  dbAcc,
  crAcc,
  setMainData,
}) => {
  const { t } = useTranslation();
  const [listFiles, setListFiles] = useState([]);
  const purposes = useSelector(
    (state) =>
      state?.z_accs?.purposes?.data?.data?.responseData?.map((ele) => ({
        label: ele.description,
        value: ele.id,
      })) ?? []
  );

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { onCall, loading } = usePayAxios({
    type: "grid",
    api: gridApis.initialisePayment,
    method: "post",
  });

  const onSubmit = (data) => {
    const addressObj = crAcc?.billing_addresses?.[0];

    const payload = {
      amount: mainData?.amount?.toFixed(2),
      currency_code: dbAcc?.defaultCurrencyCode,
      preferred_payment_scheme: mainData?.method, // SWIFT_CROSSBORDER / LOCAL_US_ACH
      overparty_accountNumberCountryCode:
        dbAcc?.accountNumbers?.[0]?.countryCode,
      overparty_account_number:
        dbAcc?.accountNumbers?.[0]?.accountNumber?.value,
      overparty_account_number_type: "BBAN", // BBAN / IBAN
      overparty_account_name: dbAcc?.accountName,
      overparty_account_personTypeCode: "P", // P / L
      counterparty_accountNumberCountryCode: countries?.find(
        (ele) => ele.value === addressObj.country
      ).code,
      counterparty_account_name: crAcc?.beneficiary_name,
      counterparty_address_street1: addressObj?.address,
      counterparty_address_street2: "",
      counterparty_address_city: addressObj?.city,
      counterparty_address_state: addressObj?.state,
      counterparty_address_zipcode: addressObj?.zipcode,
      counterparty_address_country_code: "CO",
      counterparty_account_number: crAcc?.bank_accounts?.[0]?.account_number,
      counterparty_account_number_type: "BBAN",
      counterparty_account_institution_id: mainData?.method?.includes("SWIFT")
        ? crAcc?.bank_accounts?.[0]?.swift_code
        : crAcc?.bank_accounts?.[0]?.routing_code,
      detail_name: data.notes,
      recipient_amount: mainData?.recipient_amount,
      sender_amount: mainData?.sender_amount,
      exchange_fee: mainData?.exchange_fee,
      exchange_rate: mainData?.exchange_rate,
      purpose_code: data?.purpose,
    };

    onCall({ data: payload }).then((res) => {
      if (res?.data?.responseData?.data?.paymentId) {
        setMainData((b) => ({
          ...b,
          paymentId: res?.data?.responseData?.data?.paymentId,
        }));
        next();
      } else if (res?.data?.responseData?.errors?.length) {
        message.error(
          <span className="messageText">
            {res?.data?.responseData?.errors?.[0]}
          </span>
        );
      }
    });
  };
  return (
    <div>
      <form className="w40 ml3" onSubmit={handleSubmit(onSubmit)}>
        <div className="pp-form-item">
          <FormSelect
            inline
            control={control}
            {...register("purpose")}
            errors={errors}
            label={t("Transfer purpose")}
            options={purposes}
            hideCreateOption={true}
          />
        </div>
        <div className="pp-form-item">
          <FormInput
            control={control}
            required={true}
            {...register("notes", {
              required: "Notes is required.",
            })}
            errors={errors}
            label={t("notes")}
          />
        </div>

        <div style={{ marginTop: "40px" }}>
          <ButtonUpload
            control={control}
            {...register(`files`, {
              onChange: (e) => {},
            })}
            errors={errors}
            label={t("attach_payments")}
            multiple={false}
          />
        </div>
        <div className="mt3">
          <button type="button" className="back-btn mr2" onClick={() => back()}>
            Back
          </button>
          <button className="send-btn" disabled={loading}>
            Send Wire
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdditionalInfo;
