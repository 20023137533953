import {
  CaretDownFilled,
  InfoCircleOutlined,
  EyeOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Row,
  Space,
  Steps,
  message,
} from "antd";

import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { defaultTemplate, oldTemplate } from "../../../actions/Templates";
import {
  ApproveBillingDetails,
  FetchBillingDetailsById,
  RejectBillingDetails,
  SubmitBillingDetails,
  approveBillingDetails,
  createBillingComment,
  fetchBillingDetailsById,
  rejectBillingDetails,
  submitBillingDetails,
} from "../../../actions/bills";
import { companyProfile } from "../../../actions/companySettings/companySettings";
import { invoices } from "../../../actions/customers";
import { SideMenu, headerTitles } from "../../../actions/masterdata/masterdata";
import Close from "../../../assets/icons/close.png";
import Comment from "../../../assets/icons/comment.png";
import User from "../../../assets/icons/new_dash/address-book.png";
import History from "../../../assets/icons/time_icon.png";
import { apis } from "../../../config/APIs";
import usePayAxios from "../../../config/useAxios";
import useDocumentAxios from "../../../config/useDocument";
import useGenPdf from "../../../config/useGenPdf";
import {
  amountFormat,
  isSuperAdmin,
  showFile,
  timeFormat,
} from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import DeleteConfirm from "../../Common/DeleteConfirm";
import NoCommentsComponent from "../../Common/NoComentsComponent";
import NodataComponent from "../../Common/NodataComponent";
import TableComponent from "../../Common/TableComp/TableComp";
import ItemsTableShow from "../../Common/itemsTableShow";
import PayViaAcc from "../../Payments/PayViaAcc";
import PaymentModeDisplayFields from "../../Payments/PaymentModeFieldsDisplay";
import RecordPayment from "../../Payments/RecordPayment";
import { formatDate } from "../../Sales/Common";
import CreatePayment from "../Payments/CreatePayment";
import ApplyCredit from "./ApplyCredit";
import BillData from "./BillData";
import BillsTemplates from "./BillsTemplates";

const ApproveBill = ({
  user,
  isInvoiceView,
  isPayment,
  isPO,
  isEstimates,
  isSales,
  type,
  isRecieved,
  title,
  isCN,
  module,
  hasWriteAccess,
}) => {
  const billResponse = useSelector(
    (state) => state.bills.fetchBillingDetailsById || {}
  );

  const cmp_accs = useSelector((state) =>
    state?.banks?.cmp_accs?.data?.data?.length
      ? state?.banks?.cmp_accs?.data?.data?.map((ele) => ({
          label: `${ele.bank_name} - ${ele.account_number}`,
          value: ele.id,
        }))
      : []
  );
  const saveApprove = useSelector(
    (state) => state.bills.approveBillingDetails || {}
  );

  const company = useSelector(
    (state) => state.companySetting.companyProfile?.data?.data || {}
  );

  const saveSubmit = useSelector(
    (state) => state.bills.submitBillingDetails || {}
  );
  const { t } = useTranslation();
  const saveReject = useSelector(
    (state) => state.bills.rejectBillingDetails || {}
  );
  const history = useHistory();
  const { search } = useLocation();
  const location = useLocation();

  const dispatch = useDispatch();
  const [tab, setTab] = useState("details");
  const [accPayment, setAccPayment] = useState(false);
  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedAcc, setSelectedAcc] = useState(false);
  const [benDetails, setBenDetails] = useState({});
  const { onCall } = usePayAxios({ api: apis.recallInvoice, method: "post" });
  const { onCall: recallBill, loading: recallLoading } = usePayAxios({
    api: apis.recallBillingDetail,
    method: "post",
  });
  const { onCall: sendEmail } = usePayAxios({
    api: apis.sendEmail,
    method: "post",
  });
  const { onCall: deleteInvoice, loading: delInvLoading } = usePayAxios({
    api: apis.deleteInvoiceAPI,
    method: "post",
  });
  const { onCall: deleteBill, loading: delBillLoading } = usePayAxios({
    api: apis.deleteBillsAPI,
    method: "post",
  });
  const {
    onCall: leadConvert,
    loading: leadConverLoading,
    data: leadConverData,
    reset: leadConvertReset,
  } = usePayAxios({
    api: apis.leadConvert,
    method: "post",
  });
  const { onCall: customFields, data: custom_fields } = useDocumentAxios({
    api: apis.customFieldValues,
    method: "get",
    baseURL: "settings",
  });
  const {
    onCall: callPDF,
    pdfUrl,
    loading: pdfLoading,
  } = useGenPdf({
    api: location?.state?.isInvoice
      ? isCN
        ? apis.generatePDFCN
        : apis.generatePDFInv
      : apis.generatePDFBill,
  });
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState({
    show: false,
    title: null,
    message: null,
    onAction: null,
  });
  const [input, setInput] = useState("");
  const [reason, setReason] = useState("");
  const [rdPayment, setRdPayment] = useState(false);
  const [applyCredit, setApplyCredit] = useState(false);
  const inputComment = useRef(null);
  const [file, setFile] = useState([]);
  const [declineConfirm, setDeclineConfirm] = useState(false);
  // const [rejectTitle, setRejectTitle] = useState("");
  const credit_data = useSelector(
    (state) => state.customers?.invoices?.data?.data || []
  );
  const defaultRes = useSelector(
    (state) => state.templates.defaultTemplate.data?.data
  );
  const oldRes = useSelector(
    (state) => state.templates.oldTemplate?.data?.data
  );

  const paymentMadeColumns = [
    {
      title: t("date"),
      dataIndex: "payment_date",
      render: (text) => <div>{moment(text).format("DD/MM/YYYY")}</div>,
    },
    {
      title: t("payment_mode"),
      dataIndex: "payment_mode",
    },
    // {
    //   title: "Payment Through",
    //   dataIndex: "paid_through",
    // },
    {
      title: t("amount_paid"),
      dataIndex: "amount_paid",
      render: (text) => <div>{`${invoiceInfo?.currency_iso} ${text}`}</div>,
    },
  ];

  const onMountCall = () => {
    if (location.state?.id || location?.state?.receipt_id) {
      let pdfParams = isCN
        ? {
            credit_debit_note_id: location.state?.id,
          }
        : location?.state?.isInvoice
        ? {
            invoice_id: location.state?.id,
          }
        : {
            bill_id: location.state?.id,
          };
      if (isRecieved) {
        pdfParams.receipt_id = location?.state?.receipt_id;
      }
      // callPDF({
      //   params: pdfParams,
      // });
      customFields({
        params: {
          module_id: location.state?.id,
          module_type: type === "INVOICE_RECEIPT" ? "INVOICE" : type,
          custom_flag: true,
        },
      });
      let invObj = {
        invoice_id: location?.state?.id,
        isInvoice: location?.state?.isInvoice,
        module_type: type,
      };
      if (location?.state?.receipt_id) {
        invObj = {
          receipt_id: true,
          id: location?.state?.receipt_id,
        };
      }
      dispatch(
        fetchBillingDetailsById(
          location?.state?.isInvoice
            ? invObj
            : {
                billing_id: location?.state?.id,
                module_type: type,
              }
        )
      );
    }
  };

  const resetModal = () => {
    setReason("");
    setModalData({
      show: false,
      title: null,
      message: null,
      onAction: null,
    });
  };
  useEffect(() => {
    onMountCall();
    if (location.state?.id) {
      async function getShowData() {
        const showData = {
          record_id: isRecieved
            ? location?.state?.receipt_id
            : location.state.id,
        };

        try {
          const data = await showFile(showData);
          setFile(data.data);
        } catch (err) {
          console.error(err.message);
        }
      }
      getShowData();
      if (type === "INVOICE") {
        dispatch(
          invoices({
            type: "CREDIT",
            filters: {
              beneficiary_id: [location?.state?.benId],
              invoice_id: [location?.state?.id],
              status: "open",
            },
          })
        );
      }
    }
    dispatch(
      headerTitles({
        title: title,

        description: !location?.state?.isInvoice
          ? t(isPO ? "buyer_sell" : "bill_document")
          : t(
              isSales
                ? "sale_order_document"
                : isEstimates
                ? "an_estimate"
                : isPayment
                ? ""
                : "a_invoice"
            ),
      })
    );
    let locationSplit = location?.pathname?.split("/");
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: isCN
          ? "/credit-notes"
          : isRecieved
          ? "/payments-received"
          : type === "BILL"
          ? "/bills"
          : isEstimates
          ? "/estimates"
          : isSales || isPO
          ? "/orders"
          : "",
        showChildren: isRecieved || isCN ? false : true,
        childrenMenu: !user
          ? "/approvals"
          : isRecieved || isCN
          ? ""
          : type === "BILL"
          ? "/my-bills"
          : isPO
          ? "/my-bills"
          : isEstimates
          ? "/my-estimates"
          : isSales
          ? "/my-sales"
          : "/invoices",
      },
    });
    return () => {
      dispatch({
        type: FetchBillingDetailsById.RESET,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  useEffect(() => {
    if (billResponse.success && !billResponse.data?.error) {
      setInvoiceInfo(billResponse?.data?.data);
    }
  }, [billResponse]);

  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search);
      const idBill = query.get("idBill");
      dispatch(
        fetchBillingDetailsById({
          billing_id: idBill,
          module_type: type,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (saveApprove?.data?.error) {
      message.error(
        <span className="messageText">{saveApprove?.data?.message}</span>
      );
      dispatch({
        type: ApproveBillingDetails.RESET,
      });
    }

    if (saveApprove?.data?.error === false) {
      message.success(
        <span className="messageText">{`${Capitalize(
          type
        )} Approved Successfully`}</span>
      );
      dispatch({
        type: ApproveBillingDetails.RESET,
      });
      onMountCall();
      resetModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveApprove]);

  useEffect(() => {
    if (saveSubmit?.data?.error) {
      message.error(
        <span className="messageText">{saveSubmit?.data?.message}</span>
      );
      dispatch({
        type: SubmitBillingDetails.RESET,
      });
    }

    if (saveSubmit?.data?.error === false) {
      message.success(
        <span className="messageText">{`${Capitalize(
          type
        )} Submitted Successfully`}</span>
      );
      dispatch({
        type: SubmitBillingDetails.RESET,
      });
      resetModal();
      onMountCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSubmit]);

  useEffect(() => {
    if (saveReject?.data?.error) {
      message.error(
        <span className="messageText">{saveReject?.data?.message}</span>
      );
      dispatch({
        type: RejectBillingDetails.RESET,
      });
    }

    if (saveReject?.data?.error === false) {
      message.success(
        <span className="messageText">{`${Capitalize(
          type
        )}  Rejected Successfully`}</span>
      );
      dispatch({
        type: RejectBillingDetails.RESET,
      });
      resetModal();
      onMountCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReject]);

  useEffect(() => {
    if (leadConverData?.error == false) {
      message.success(
        <span className="messageText">{leadConverData?.message}</span>
      );
      leadConvertReset();
      setDeclineConfirm(false);
      dispatch(companyProfile({ id: company_id }));
    } else if (leadConverData?.error == true) {
      message.error(
        <span className="messageText">{leadConverData?.message}</span>
      );
    }
  }, [leadConverData]);

  // useEffect(() => {
  //   if (type === "ESTIMATE" && isInvoiceView) {
  //     setRejectTitle(t('estimate'));
  //   } else if (type === "SALES_ORDER" && isInvoiceView) {
  //     setRejectTitle(t("order_sale"));
  //   } else if (type === "INVOICE" && isInvoiceView) {
  //     setRejectTitle(t('invoice'));
  //   } else if (type === "BILL") {
  //     setRejectTitle(t("bill"));
  //   } else if (type === "PURCHASE_ORDER") {
  //     setRejectTitle(t("purchase_order"));
  //   }
  // }, [type]);
  const related = [
    {
      step: t("created_history"),
      name: invoiceInfo?.created_by ? invoiceInfo?.created_by?.name : "",
      email: invoiceInfo?.created_by ? invoiceInfo?.created_by?.email : "",
      date: moment(invoiceInfo?.created_at).format("DD/MM/YYYY"),
    },
    isCN
      ? ""
      : {
          step: t("submitted_to"),
          name: invoiceInfo?.submitted_to?.name
            ? invoiceInfo?.submitted_to?.name
            : "NA",
          email: invoiceInfo?.submitted_to?.email
            ? invoiceInfo?.submitted_to?.email
            : "",
          date: invoiceInfo?.submitted_date
            ? moment(invoiceInfo?.submitted_date).format("DD/MM/YYYY")
            : "",
          role:
            invoiceInfo?.submitted_to?.role_name &&
            `(${invoiceInfo?.submitted_to?.role_name})`,
        },
    isCN ||
    invoiceInfo?.invoice_status === "approved" ||
    invoiceInfo?.invoice_status === "rejected"
      ? ""
      : {
          step: t("approval_pending"),
          name: invoiceInfo?.pending_with?.name
            ? invoiceInfo?.pending_with?.name
            : "NA",
          email: invoiceInfo?.pending_with?.email
            ? invoiceInfo?.pending_with?.email
            : "",
          // date: "18/03/2022",
          role:
            invoiceInfo?.pending_with?.role_name &&
            `(${invoiceInfo?.pending_with?.role_name})`,
        },
  ];
  const handleApprove = () => {
    dispatch(
      approveBillingDetails({
        id: location?.state?.id,
        isInvoice: location?.state?.isInvoice,
        module_type: type,
      })
    );
  };
  const company_id = localStorage.getItem("company_id");

  React.useEffect(() => {
    if (company_id) {
      dispatch(companyProfile({ id: company_id }));
    }
    // eslint-disable-next-line
  }, []);

  const deleteConfirmOpen = () => {
    setDeleteConfirm(true);
  };

  const deleteConfirmClose = () => {
    setDeleteConfirm(false);
  };
  const TemplateID = invoiceInfo?.template_id;
  React.useEffect(() => {
    if (TemplateID) {
      dispatch(oldTemplate({ module_type: module, TemplateID }));
    } else {
      dispatch(defaultTemplate({ module_type: module }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TemplateID]);
  const handleDeleteReport = () => {
    if (location.state.isInvoice) {
      deleteInvoice({
        params: {
          module_type: type,
        },
        data: {
          id: location?.state?.id,
        },
      })
        .then((data) => {
          message.success(
            <span className="messageText">{`${Capitalize(
              type
            )} Deleted Successfully`}</span>
          );
          // history.goBack();
          history.push(location?.state?.from);
        })
        .catch((err) => {
          message.error(<span className="messageText">{err.message}</span>);
        });
    } else {
      deleteBill({
        params: {
          module_type: type,
        },
        data: {
          id: location?.state?.id,
        },
      })
        .then((data) => {
          message.success(
            <span className="messageText">{`${Capitalize(
              type
            )} Deleted Successfully`}</span>
          );
          // history.goBack();
          history.push(location?.state?.from);
        })
        .catch((err) => {
          message.error(<span className="messageText">{err.message}</span>);
        });
    }
  };

  const Capitalize = (string) => {
    switch (string) {
      case "ESTIMATE":
        return t("estimate");
        break;
      case "SALES_ORDER":
        return t("order_sale");
        break;
      case "INVOICE":
        return t("invoice");
        break;
      case "BILL":
        return t("bill");
        break;
      case "PURCHASE_ORDER":
        return t("purchase_order");
        break;
    }
  };

  const handleConvertToBill = () => {
    if (isPO)
      history.push("/purchases/bills/create-new-bill", {
        setSideMenu: true,
        dataBeneficiary: invoiceInfo,
        convert: true,
        from: location.pathname,
        from_module: type,
        id: invoiceInfo?.id,
        to: "/purchases/bills/my-bills",
      });
    else if (isSales)
      history.push("/sales/invoices/create-new-invoice", {
        setSideMenu: true,
        dataBeneficiary: invoiceInfo,
        convert: true,
        from_module: type,
        from: location.pathname,
        id: invoiceInfo?.id,
        to: "/sales/invoices",
      });
  };
  const handleReject = (rea) => {
    // const query = new URLSearchParams(search);
    // const idBill = query.get('idBill');
    if (rea)
      dispatch(
        rejectBillingDetails({
          id: location?.state?.id,
          isInvoice: location?.state?.isInvoice,
          module_type: type,
          reject_reason: rea,
        })
      );
    else {
      message.error(
        <span className="messageText">Reject reason is required</span>
      );
    }
  };

  const handleAddComment = (event) => {
    if (input.trim() && location?.state?.id) {
      if (isPayment) {
        dispatch(
          createBillingComment({
            commentable_id: invoiceInfo?.receipt_id,
            body: input,
            isPayment: isPayment,
            commentable_type: "receipt",
          })
        );
      } else if (isRecieved) {
        dispatch(
          createBillingComment({
            commentable_id: invoiceInfo?.invoice_id,
            body: input,
            commentable_type: "invoice",
            module_type: "payment_received",
          })
        );
      } else if (isPO || module == "BILL") {
        dispatch(
          createBillingComment({
            commentable_id: location?.state?.id,
            body: input,
            module_type: type,
            commentable_type: "bill",
          })
        );
      } else {
        dispatch(
          createBillingComment({
            commentable_id: location?.state?.id,
            body: input,
            module_type: type,
            commentable_type: "invoice",
          })
        );
      }
      setInput("");
      onMountCall();
    } else {
      message.error(
        <span className="messageText">{t("comments_required")}</span>
      );
    }
  };
  const makePaymentProps = (
    <Menu
      onClick={(action) => {
        if (hasWriteAccess) {
          switch (action.key) {
            case "1":
              setRdPayment(true);
              break;
            default:
              setSelectedAcc(action.key);
              setBenDetails({
                bank: invoiceInfo?.beneficiary_bank_accounts?.[0],
                name: invoiceInfo?.vendor_name,
                email: invoiceInfo?.user_email,
              });
              setAccPayment(true);
              break;
          }
        }
      }}
      disabled={!hasWriteAccess}
    >
      <label>{t("Transfers")}</label>
      <Menu.Item key="1">{t("record_payment")}</Menu.Item>
      {cmp_accs?.map((ele) => (
        <Menu.Item key={ele.value}>{ele.label}</Menu.Item>
      ))}
    </Menu>
  );

  const convertProps = (
    <Menu
      onClick={(action) => {
        if (hasWriteAccess) {
          switch (action.key) {
            case "1":
              history.push("/sales/invoices/create-new-invoice", {
                setSideMenu: true,
                dataBeneficiary: invoiceInfo,
                convert: true,
                from_module: type,
                id: invoiceInfo?.id,
                to: "/sales/invoices",
              });
              break;
            case "2":
              history.push("/sales/orders/create-new-sale", {
                setSideMenu: true,
                dataBeneficiary: invoiceInfo,
                convert: true,
                fromEstimate: isEstimates,
                id: invoiceInfo?.id,
                to: "/sales/orders/my-sales",
                from_module: type,
              });
              break;
            default:
              break;
          }
        }
      }}
    >
      <Menu.Item key="1">{t("convert_to_inv")}</Menu.Item>
      <Menu.Item key="2">{t("convert_to_Sale_order")}</Menu.Item>
    </Menu>
  );

  const colllectPaymentProps = (
    <Menu
      onClick={(action) => {
        if (hasWriteAccess) {
          switch (action.key) {
            case "1":
              setRdPayment(true);
              break;
            case "2":
              sendEmail({
                data: {
                  order_id: location.state?.id,
                  to: invoiceInfo?.beneficiaries?.email,
                  cc: [],
                  subject: "Payment Link",
                  is_payment: true,
                },
                params: {
                  type: "payment",
                  module_type: type,
                },
              })
                .then((data) => {
                  message.success(
                    <span className="messageText">{`Email sent succesfully`}</span>
                  );
                })
                .catch((err) => {
                  message.error(
                    <span className="messageText">
                      {err?.response?.data?.message}
                    </span>
                  );
                });
              break;
            default:
              setSelectedAcc(action.key);
              setAccPayment(true);
              break;
          }
        }
      }}
    >
      <label>{t("Transfers")}</label>
      <Menu.Item key="1">{t("record_payment")}</Menu.Item>
      <Menu.Item key="2">{t("collect_payment_link")}</Menu.Item>
    </Menu>
  );

  const handleEdit = () => {
    const locState = location?.state ? location?.state : {};
    history.push(
      location?.state?.isInvoice
        ? `/sales/${
            isEstimates ? "estimates" : isSales ? "orders" : "invoices"
          }/create-new-${
            isEstimates ? "estimate" : isSales ? "sale" : "invoice"
          }`
        : isPO
        ? "/purchases/orders/create-new-bill"
        : "/purchases/bills/create-new-bill",
      {
        ...locState,
        setSideMenu: true,
        dataBeneficiary: invoiceInfo,
        isEdit: true,
        from: location.pathname,
        to: location?.state?.isInvoice
          ? `/sales/${isEstimates ? "estimates" : isSales ? "orders" : ""}${
              isEstimates ? "/my-estimates" : isSales ? "/my-sales" : "invoices"
            }`
          : isPO
          ? "/purchases/orders/my-bills"
          : "/purchases/bills/my-bills",
      }
    );
  };

  const handleRecall = () => {
    let payload = {
      id: location?.state?.id,
    };
    if (location?.state?.isInvoice) {
      onCall({
        data: payload,
        params: {
          module_type: type,
        },
      })
        .then(() => {
          onMountCall();
          message.success(
            <span className="messageText">{`${Capitalize(
              type
            )} Recalled Successfully`}</span>
          );
        })
        .catch((err) => err.message);
    } else {
      recallBill({
        data: payload,
        params: {
          module_type: type,
        },
      })
        .then(() => {
          onMountCall();
          message.success(
            <span className="messageText">{`${Capitalize(
              type
            )} Recalled Successfully`}</span>
          );
        })
        .catch((err) => err.message);
    }
  };

  const handleSubmit = () => {
    dispatch(
      submitBillingDetails({
        id: location.state?.id,
        isInvoice: location?.state?.isInvoice,
        module_type: type,
      })
    );
  };

  const handleSendEmail = () => {
    sendEmail({
      params: {
        module_type: type,
        type: "payment",
      },
      data: {
        order_id: location.state?.id,
        to: invoiceInfo?.beneficiaries?.email,
        cc: [],
        subject: "Payment Link",
        is_payment: false,
        // attachments: [
        //   {
        //     link: isCN || isRecieved ? "" : pdfUrl,
        //   },
        // ],
      },
    })
      .then((data) => {
        message.success(
          <span className="messageText">{`Email sent succesfully`}</span>
        );
      })
      .catch((err) => {
        message.error(
          <span className="messageText">{err?.response?.data?.message}</span>
        );
      });
  };

  const handleAcceptDecline = async (status, val) => {
    let res = await leadConvert({
      data: {
        record_id: invoiceInfo?.id,
        from_module: location?.state?.isInvoice
          ? isEstimates
            ? "ESTIMATE"
            : isSales
            ? "SALES_ORDER"
            : "INVOICE"
          : isPO
          ? "PURCHASE_ORDER"
          : "BILL",
        to_module: location?.state?.isInvoice
          ? isEstimates
            ? "ESTIMATE"
            : isSales
            ? "SALES_ORDER"
            : "INVOICE"
          : isPO
          ? "PURCHASE_ORDER"
          : "BILL",
        from_module_status: status,
        company_id: localStorage.getItem("company_id"),
      },
    });
    if (!res?.data?.error) {
      onMountCall();
    }
  };

  const menuProps = (
    <Menu>
      {/* <Menu.Item key="1" onClick={handleDownloadPDF}>
        Download PDF
      </Menu.Item> */}
      {type === "BILL" || type === "PURCHASE_ORDER" ? null : (
        <Menu.Item key="1" onClick={() => hasWriteAccess && handleSendEmail()}>
          {t("send_email")}
        </Menu.Item>
      )}
      {/* <Menu.Item key="2">Download Receipts</Menu.Item> */}
      {(isEstimates || isPO) &&
        invoiceInfo?.invoice_status === "approved" &&
        invoiceInfo?.flow_status !== "accepted" &&
        invoiceInfo?.flow_status !== "declined" && (
          <>
            <Menu.Item
              key="5"
              onClick={() => hasWriteAccess && handleAcceptDecline("accepted")}
            >
              {t("mark_as_accept")}
            </Menu.Item>
            <Menu.Item
              key="6"
              onClick={() => {
                if (hasWriteAccess) {
                  setDeclineConfirm(true);
                }
              }}
            >
              {t("mark_as_declined")}
            </Menu.Item>
          </>
        )}
      {/* <Menu.Item key="3">Export to Excel</Menu.Item> */}
      {/* <Menu.Item key="3">Share</Menu.Item> */}
      {!isRecieved && (
        <Menu.Item
          key="3"
          onClick={() => {
            if (hasWriteAccess) {
              history.push(
                location?.state?.isInvoice
                  ? `/sales/${
                      isEstimates
                        ? "estimates"
                        : isSales
                        ? "orders"
                        : "invoices"
                    }/create-new-${
                      isEstimates ? "estimate" : isSales ? "sale" : "invoice"
                    }`
                  : isPO
                  ? "/purchases/orders/create-new-bill"
                  : "/purchases/bills/create-new-bill",
                location?.state?.isInvoice || isRecieved
                  ? {
                      dataBeneficiary: invoiceInfo,
                      from: location.pathname,
                      setSideMenu: true,
                      isClone: true,
                      id: invoiceInfo?.id,
                      to: `/sales/${
                        isEstimates
                          ? "estimates"
                          : isSales
                          ? "orders"
                          : "invoices"
                      }/${
                        isEstimates ? "my-estimates" : isSales ? "my-sales" : ""
                      }`,
                    }
                  : {
                      setSideMenu: true,
                      dataBeneficiary: invoiceInfo,
                      from: location.pathname,
                      isClone: true,
                      id: invoiceInfo?.id,
                      to: isPO
                        ? "/purchases/orders/my-bills"
                        : "/purchases/bills/my-bills",
                    }
              );
            }
          }}
        >
          {t("clone")}
        </Menu.Item>
      )}
      {isRecieved || invoiceInfo?.status === "closed" ? (
        ""
      ) : (
        <Menu.Item
          key="4"
          onClick={() => hasWriteAccess && deleteConfirmOpen()}
        >
          {t("delete")}
        </Menu.Item>
      )}
      {/* <Menu.Item key="4" onClick={() => {
      }}>Print</Menu.Item> */}
    </Menu>
  );

  const items = location?.state?.isSales
    ? [
        {
          key: "1",
          label: t("details"),
        },
        {
          key: "2",
          label: t("comments"),
        },
        {
          key: "3",
          label: t("history"),
        },
      ]
    : [
        {
          key: "1",
          label: t("details"),
        },

        {
          key: "2",
          label: t("items"),
        },
        isCN || isSales || isEstimates || isPO
          ? {}
          : {
              key: "3",
              label: t("payment_made"),
            },
        {
          key: "4",
          label: t("comments"),
        },
        {
          key: "5",
          label: t("history"),
        },
      ];
  const sales_inv_columns = [
    {
      title: !isSales ? t("sales_hash") : t("invoice_hashh"),
      dataIndex: "invoice_no",
      key: "invoice_no",
      render: (text, record) => {
        return (
          <span
            className="link"
            onClick={() => {
              history.replace(!isSales ? `/sales/so` : `/sales/invoice`, {
                id: record.id,
                benId: record?.beneficiary_id,
                from: location.pathname,
                isInvoice: true,
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    !isSales
      ? {}
      : {
          title: t("so_no"),
          dataIndex: "ref_po_no",
          key: "ref_po_no",
        },
    {
      title: t("date"),
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text) => <div>{formatDate(text)}</div>,
    },
    {
      title: !isSales ? t("shipment_date") : t("due_date"),
      dataIndex: "po_due_date",
      key: "po_due_date",
      render: (text) => (
        <div className={moment().diff(text) <= 0 ? "" : "table-due-date"}>
          {formatDate(text)}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: isCN ? "status" : "invoice_status",
      key: isCN ? "status" : "invoice_status",
      render: (text, record) => (
        <div
          className={`status status_${
            text === "approved" && record?.flow_status
              ? record?.flow_status
              : text
          }`}
        >
          {record?.status
            ? record?.status
            : text === "approved" && record?.flow_status
            ? record?.flow_status?.replace(/_/g, " ")
            : text?.replace(/_/g, " ")}
        </div>
      ),
    },
  ];

  const isRecallable = () => {
    return !Object?.values?.(invoiceInfo?.role_based_status)?.includes?.(
      "approved"
    );
  };

  return (
    <>
      {declineConfirm && (
        <DeleteConfirm
          open={declineConfirm}
          onCancel={() => {
            setDeclineConfirm(false);
          }}
          onConfirm={(val) => {
            let res = leadConvert({
              data: {
                record_id: invoiceInfo?.id,
                from_module: location?.state?.isInvoice
                  ? isEstimates
                    ? "ESTIMATE"
                    : isSales
                    ? "SALES_ORDER"
                    : "INVOICE"
                  : isPO
                  ? "PURCHASE_ORDER"
                  : "BILL",
                to_module: location?.state?.isInvoice
                  ? isEstimates
                    ? "ESTIMATE"
                    : isSales
                    ? "SALES_ORDER"
                    : "INVOICE"
                  : isPO
                  ? "PURCHASE_ORDER"
                  : "BILL",
                from_module_status: "declined",
                company_id: localStorage.getItem("company_id"),
                reject_reason: val,
              },
            });
            if (!res?.data?.error) {
              onMountCall();
            }
          }}
          loading={leadConverLoading}
          name={`${title}`}
          text={"decline"}
          isReject={true}
          headerTitle={"Please specify an appropriate reason for decline."}
        />
      )}
      {openRecordPayment && (
        <CreatePayment
          isVisible={openRecordPayment && hasWriteAccess}
          onClose={() => {
            setOpenRecordPayment(false);
          }}
          dataBeneficiary={invoiceInfo}
          isSales={isSales}
        />
      )}

      <DeleteConfirm
        open={deleteConfirm}
        onCancel={deleteConfirmClose}
        onConfirm={handleDeleteReport}
        loading={delInvLoading || delBillLoading}
        name={type?.toLowerCase()}
      />

      <PayViaAcc
        open={accPayment && hasWriteAccess}
        id={invoiceInfo?.id}
        amount={invoiceInfo?.due_balance_amount}
        onClose={() => {
          setAccPayment(false);
        }}
        options={cmp_accs}
        value={selectedAcc}
        benDetails={benDetails}
        invoiceInfo={invoiceInfo}
        type={
          location?.state?.isInvoice
            ? isEstimates
              ? "ESTIMATE"
              : isSales
              ? "SALES_ORDER"
              : type
            : isPO
            ? "PURCHASE_ORDER"
            : "BILL"
        }
      />

      {rdPayment && (
        <RecordPayment
          open={rdPayment && hasWriteAccess}
          id={isRecieved ? invoiceInfo?.invoice_id : invoiceInfo?.id}
          beneficiary_id={invoiceInfo?.beneficiary_id}
          amount={invoiceInfo?.due_balance_amount}
          onClose={() => {
            setRdPayment(false);
          }}
          invoiceInfo={invoiceInfo}
          isInvoice={location?.state?.isInvoice || isRecieved}
          type={
            location?.state?.isInvoice
              ? isEstimates
                ? "ESTIMATE"
                : isSales
                ? "SALES_ORDER"
                : type
              : isPO
              ? "PURCHASE_ORDER"
              : "BILL"
          }
          file_type={
            location?.state?.isInvoice ? "invoice_receipt" : "bill_receipt"
          }
        />
      )}

      {modalData.show && hasWriteAccess && (
        <Modal
          title={modalData.title}
          open={true}
          closeIcon={
            <img
              src={Close}
              width={18}
              alt=""
              onClick={() => {
                setModalData({
                  show: false,
                  title: null,
                  message: null,
                  onAction: null,
                });
              }}
            />
          }
        >
          <div
            style={{
              color: "#4A4A4A",
              fontSize: "15px",
              lineHeight: "30px",
              marginBottom: "20px",
            }}
          >
            {modalData.message}
          </div>

          <Space>
            <Button
              className="pp-main-button"
              disabled={
                saveApprove?.loading || saveReject?.loading || !hasWriteAccess
              }
              onClick={() => modalData.onAction(reason)}
              type="primary"
            >
              <span>{t("confirm")}</span>
            </Button>

            <Button
              disabled={!hasWriteAccess}
              className="pp-secondary-button"
              onClick={() => {
                setReason("");
                setModalData({
                  show: false,
                  title: null,
                  message: null,
                  onAction: null,
                });
              }}
            >
              <span>{t("cancel")}</span>
            </Button>
          </Space>
        </Modal>
      )}

      {invoiceInfo?.reject_reason && (
        <div className="strip red">
          {t("reason_reject")}: {invoiceInfo?.reject_reason}
        </div>
      )}
      <div className="report-btns">
        {invoiceInfo?.invoice_status === "approved" &&
          invoiceInfo?.due_balance_amount > 0 &&
          type === "INVOICE" && (
            <Dropdown.Button
              disabled={!hasWriteAccess}
              className={`pp-dropdownbutton approve`}
              overlay={colllectPaymentProps}
              icon={<CaretDownFilled className="down-caret" />}
            >
              <span>{t("collect_payment")}</span>
            </Dropdown.Button>
          )}
        {invoiceInfo?.invoice_status === "approved" &&
          !location?.state?.isInvoice &&
          !isEstimates &&
          !isPO &&
          !isSales &&
          invoiceInfo?.due_balance_amount > 0 && (
            <Dropdown.Button
              className={`pp-dropdownbutton approve`}
              overlay={makePaymentProps}
              icon={<CaretDownFilled className="down-caret" />}
              style={{ opacity: hasWriteAccess ? 1 : 0.5 }}
            >
              <span>{t("make_payment")}</span>
            </Dropdown.Button>
          )}

        {(invoiceInfo?.invoice_status === "approved" ||
          invoiceInfo?.invoice_status === "accepted") &&
          isEstimates && (
            <Dropdown.Button
              disabled={!hasWriteAccess}
              className={`pp-dropdownbutton`}
              overlay={convertProps}
              icon={<CaretDownFilled className="down-caret" />}
            >
              <span>{t("convert")}</span>
            </Dropdown.Button>
          )}
        {/* {invoiceInfo?.invoice_status === "approved" &&
          invoiceInfo?.flow_status !== "confirmed" &&
          isSales && (
            <button
              disabled={!hasWriteAccess}
              className='pp-main-button'
              onClick={async () => {
                handleAcceptDecline("confirmed");
              }}
            >
              <span>{t("mark_as_confirmed")}</span>
            </button>
          )} */}
        {invoiceInfo?.status !== "closed" &&
          (invoiceInfo?.invoice_status === "approved" ||
            invoiceInfo?.invoice_status === "accepted") &&
          (isPO || isSales) && (
            <>
              <button
                disabled={!hasWriteAccess}
                className="approve"
                onClick={() => {
                  if (isPO) {
                    history.push("/purchases/material/form", {
                      OrderNO: invoiceInfo?.billing_no,
                      orderDetails: invoiceInfo,
                    });
                  } else if (isSales) {
                    console.log(
                      "purchases maters invoiceInfo",
                      invoiceInfo?.invoice_no
                    );
                    history.push("/sales/delivery/form", {
                      OrderNO: invoiceInfo?.invoice_no,
                      orderDetails: invoiceInfo,
                    });
                  }
                }}
              >
                <span>
                  {t("Create")}{" "}
                  {isSales ? t("Delivery Challan") : t("Material Receipt")}
                </span>
              </button>
              <button
                disabled={!hasWriteAccess}
                className="approve"
                onClick={() => {
                  setModalData({
                    show: true,
                    title: (
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <div>
                          <span
                            style={{
                              color: "#4B5660",
                            }}
                          >
                            {t("convert_to")}{" "}
                            {isSales ? t("invoice") : t("bill")}
                          </span>
                        </div>
                      </div>
                    ),
                    message: `${t("convert_sure")} ${
                      isSales ? t("invoice") : t("bill")
                    } ?`,
                    onAction: handleConvertToBill,
                  });
                }}
              >
                <span>
                  {t("convert_to")} {isSales ? t("invoice") : t("bill")}
                </span>
              </button>
            </>
          )}
        {invoiceInfo?.role_status === "pending_approval" &&
        (invoiceInfo?.is_editable || isSuperAdmin()) &&
        !user ? (
          <>
            <button
              disabled={!hasWriteAccess}
              className="approve"
              onClick={() => {
                setModalData({
                  show: true,
                  title: (
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div>
                        <span
                          style={{
                            color: "#4B5660",
                          }}
                        >
                          {t("approve")}
                        </span>
                      </div>
                    </div>
                  ),
                  message: `${t("approve_msg")} ${
                    isEstimates
                      ? t("Est_mate")
                      : isPO
                      ? t("purchase_ord")
                      : isSales
                      ? t("sales_ord")
                      : isInvoiceView
                      ? t("invoice")
                      : t("bill")
                  }?`,
                  onAction: () => handleApprove(),
                });
              }}
            >
              <span>{t("approve")}</span>
            </button>

            <button
              disabled={!hasWriteAccess}
              className="reject"
              onClick={() =>
                setModalData({
                  show: true,
                  title: (
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div>
                        <span
                          style={{
                            color: "#4B5660",
                          }}
                        >
                          {t("reject")}
                        </span>
                      </div>
                    </div>
                  ),
                  message: (
                    <>
                      {t("reject_msg")} {Capitalize(type)}? <br />
                      <Form.Item>
                        <Input.TextArea
                          maxLength={250}
                          placeholder={t("enter_reason")}
                          className="mt1"
                          onChange={(e) => setReason(e.target.value)}
                        />
                      </Form.Item>
                    </>
                  ),
                  onAction: handleReject,
                })
              }
            >
              <span>{t("reject")}</span>
            </button>
          </>
        ) : null}

        {invoiceInfo && user ? (
          invoiceInfo.invoice_status ===
          "approved" ? null : invoiceInfo.invoice_status ===
            "pending_approval" ? (
            isRecallable() && (
              <button
                disabled={!hasWriteAccess || recallLoading}
                className="pp-secondary-button"
                onClick={handleRecall}
              >
                <span>{t("recall")}</span>
              </button>
            )
          ) : invoiceInfo.invoice_status === "unsubmitted" ? (
            <>
              <button
                disabled={!hasWriteAccess}
                className="pp-secondary-button"
                onClick={handleEdit}
              >
                <span>{t("edit")}</span>
              </button>
              <button
                disabled={!hasWriteAccess || saveSubmit?.loading}
                type="primary"
                htmlType="submit"
                className="pp-main-button "
                onClick={handleSubmit}
              >
                <span>{t("submit")}</span>
              </button>
            </>
          ) : null
        ) : null}
        {isCN && invoiceInfo.status === "draft" && (
          <>
            <button
              disabled={!hasWriteAccess}
              className="pp-secondary-button"
              onClick={() => {
                history.push("/sales/credit-note/create-new-credit", {
                  setSideMenu: true,
                  dataBeneficiary: invoiceInfo,
                  isEdit: true,
                  from: location.pathname,
                  to: "/sales/credit-notes",
                });
              }}
            >
              <span>{t("edit")}</span>
            </button>
            <button
              disabled={!hasWriteAccess}
              type="primary"
              htmlType="submit"
              className="pp-main-button "
              onClick={handleSubmit}
            >
              <span>{t("submit")}</span>
            </button>
          </>
        )}
        {!isRecieved && (
          <Dropdown overlay={menuProps} disabled={!hasWriteAccess}>
            <Button className="pp-secondary-button">
              <Space>
                {t("more")}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
        <span
          className="close cursor"
          onClick={() => {
            // history.goBack();
            history.push(`${location?.state?.from}`);
          }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div>
      <Row
        wrap={false}
        className="new-report-detail"
        style={{ flexGrow: 1, display: "flex" }}
      >
        <Col
          flex="auto"
          style={{ width: "100%", display: "flex", flexGrow: "1" }}
        >
          <div style={{ flexGrow: 1 }}>
            <Row>
              <Col span={24}>
                <div className="report-head">
                  {invoiceInfo?.payment_status === "payment_pending" && (
                    <Collapse
                      accordion
                      className="w100 mb1"
                      style={{
                        background: "rgba(63, 129, 244, 0.13)",
                        position: "relative",
                      }}
                    >
                      <Collapse.Panel
                        header={
                          <div className="flex-between">
                            <p>
                              <InfoCircleOutlined
                                // size={20}
                                style={{
                                  marginRight: "1rem",
                                  fontSize: 17,
                                }}
                              />{" "}
                              Congratulations! Your invoice is eligible for
                              invoice discounting.
                            </p>
                            <Button
                              disabled={!hasWriteAccess}
                              className="pp-secondary-button small mr1"
                            >
                              <span>Apply Now</span>
                            </Button>
                          </div>
                        }
                        key="1"
                      >
                        <div
                          className="flex-end apply-credit inv-discount"
                          style={{ width: "57%" }}
                        >
                          <div className="sub-total">
                            <div className="item">
                              <span>* Payment Due in 45 days</span>
                            </div>
                            <div className="item smFont">
                              <span>Invoice Amount</span>
                              <span>
                                <BaseCurrency
                                  symbol_code={invoiceInfo?.currency?.symbol}
                                />
                                {amountFormat(invoiceInfo?.bc_total_amount)}
                              </span>
                            </div>
                            <div className="item smFont">
                              <span>Discount</span>
                              <span>
                                (-)
                                <BaseCurrency
                                  symbol_code={invoiceInfo?.currency?.symbol}
                                />
                                {amountFormat(1393)}
                              </span>
                            </div>
                            <div className="item smFont">
                              <span>Processing Fee</span>
                              <span>
                                (+){" "}
                                <BaseCurrency
                                  symbol_code={invoiceInfo?.currency?.symbol}
                                />
                                {amountFormat(2000)}
                              </span>
                            </div>
                            <div
                              className="item bigFont"
                              style={{
                                position: "absolute",
                                right: "2rem",
                                top: "8rem",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <span>Dispersement Amount</span>
                              <span>
                                <BaseCurrency
                                  symbol_code={invoiceInfo?.currency?.symbol}
                                />{" "}
                                {amountFormat(
                                  invoiceInfo?.bc_total_amount + 2000 - 1393
                                )}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                  {!isRecieved &&
                  (type === "INVOICE" || type === "SALES_ORDER") &&
                  (Array.isArray(invoiceInfo.invoices) ||
                    Array.isArray(invoiceInfo.invoices)) &&
                  (invoiceInfo.sales_orders?.length ||
                    invoiceInfo.invoices?.length) ? (
                    <Collapse accordion className="w100">
                      <Collapse.Panel
                        header={
                          <p>
                            Associated{" "}
                            {type === "INVOICE" ? "Sales Orders" : "Invoices"} (
                            {type === "INVOICE"
                              ? invoiceInfo.sales_orders?.length
                              : invoiceInfo.invoices?.length}
                            )
                          </p>
                        }
                        key="1"
                      >
                        <TableComponent
                          data={
                            type === "INVOICE"
                              ? invoiceInfo.sales_orders
                              : invoiceInfo.invoices
                          }
                          columns={sales_inv_columns}
                          hasCheckbox={false}
                        />
                      </Collapse.Panel>
                    </Collapse>
                  ) : null}
                  {credit_data?.length > 0 &&
                    !isRecieved &&
                    type === "INVOICE" && (
                      <Collapse accordion className="w100 mt1">
                        <Collapse.Panel
                          header={
                            <p>Apply Credits for {invoiceInfo?.invoice_no}</p>
                          }
                          key={"cn"}
                        >
                          <ApplyCredit
                            onSuccess={() => {
                              dispatch(
                                invoices({
                                  type: "CREDIT",
                                  filters: {
                                    beneficiary_id: [location?.state?.benId],
                                    invoice_id: [location?.state?.id],
                                    status: "open",
                                  },
                                })
                              );
                              setApplyCredit(false);
                            }}
                            data={credit_data ?? []}
                            invoice_bal={invoiceInfo?.due_balance_amount}
                            onClose={() => setApplyCredit("")}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    )}
                </div>
              </Col>
              <Col span={24}>
                <div className="flex-between">
                  <div className="report-head">
                    <div className="title-sec">
                      <h3>
                        {isCN
                          ? t("credit_note")
                          : isPO
                          ? t("order_purchase")
                          : isSales
                          ? t("order_salee")
                          : isEstimates
                          ? t("estimate_hash")
                          : location?.state?.isInvoice
                          ? t("invoice_hashh")
                          : t("bill_hash")}{" "}
                        {isCN
                          ? invoiceInfo?.credit_debit_note_number
                          : location?.state?.isInvoice
                          ? invoiceInfo?.invoice_no
                          : invoiceInfo?.billing_no}
                      </h3>
                      {user ? (
                        <span
                          className={`status status_${
                            invoiceInfo?.invoice_status === "approved" &&
                            invoiceInfo?.flow_status
                              ? invoiceInfo?.flow_status?.toLowerCase()
                              : invoiceInfo?.invoice_status?.toLowerCase()
                          }`}
                        >
                          {isCN || invoiceInfo?.status
                            ? invoiceInfo?.status
                            : invoiceInfo?.invoice_status === "approved" &&
                              invoiceInfo?.flow_status
                            ? invoiceInfo?.flow_status?.replace(/_/g, " ")
                            : invoiceInfo?.invoice_status?.replace(/_/g, " ")}
                        </span>
                      ) : (
                        <span
                          className={`status status_${
                            invoiceInfo?.role_status === "approved" &&
                            invoiceInfo?.flow_status
                              ? invoiceInfo?.flow_status?.toLowerCase()
                              : invoiceInfo?.role_status?.toLowerCase()
                          }`}
                        >
                          {isCN || invoiceInfo?.status
                            ? invoiceInfo?.status
                            : invoiceInfo?.role_status === "approved" &&
                              invoiceInfo?.flow_status
                            ? invoiceInfo?.flow_status?.replace(/_/g, " ")
                            : invoiceInfo?.role_status?.replace(/_/g, " ")}
                        </span>
                      )}
                      {isEstimates ||
                      isSales ||
                      isPO ||
                      isCN ||
                      invoiceInfo?.invoice_status === "rejected" ? null : (
                        <span
                          style={{
                            border: "none",
                            position: "absolute",
                            right: 10,
                          }}
                          className={`status status_${invoiceInfo?.payment_status?.toLowerCase()}`}
                        >
                          {invoiceInfo?.payment_status?.replace(/_/g, " ")}
                        </span>
                      )}
                    </div>
                    {type === "INVOICE" && credit_data?.length > 0 ? (
                      <p>
                        Credits Available:{" "}
                        <BaseCurrency symbol_code={invoiceInfo?.currency_iso} />{" "}
                        {amountFormat(
                          _.sumBy(credit_data, "balance_amount"),
                          invoiceInfo?.currency?.decimal_scale
                        )}
                      </p>
                    ) : null}
                    <p className="duration">
                      {t("creation_date")} :{" "}
                      {moment(invoiceInfo.created_at).format("DD/MM/YYYY")}
                    </p>
                    <p>
                      <strong>
                        {user ? t("created_byy") : t("by_submitted")}
                        {user
                          ? invoiceInfo?.created_by?.name ??
                            invoiceInfo?.user_info?.name
                          : invoiceInfo?.submitted_by?.name}
                        <span
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "normal",
                            color: "#1C2B36",
                            opacity: "0.51",
                          }}
                        >
                          {user
                            ? `(${
                                invoiceInfo?.created_by?.email ??
                                invoiceInfo?.user_info?.email
                              })`
                            : `(${invoiceInfo?.submitted_by?.email})`}
                        </span>
                      </strong>
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                {pdfLoading ? (
                  <div className="pdf shimmer">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <BillsTemplates
                    template={
                      TemplateID
                        ? oldRes?.template_name
                        : defaultRes?.[0]?.template_name
                    }
                    invoiceInfo={invoiceInfo}
                    isCN={isCN}
                    type={type}
                    company={company}
                  />
                )}
              </Col>

              <Col
                span={12}
                style={{
                  paddingRight: "10px",
                }}
              >
                <div>
                  <div className="tabs-sec mb1">
                    <ul>
                      {items.map(
                        (ele) =>
                          ele?.label && (
                            <li
                              onClick={() => setTab(ele.label?.toLowerCase())}
                              className={`${
                                tab.toLowerCase() === ele.label?.toLowerCase()
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {ele.label}
                            </li>
                          )
                      )}
                    </ul>
                  </div>

                  {tab === "details" ? (
                    <div>
                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          padding: "25px 36px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1rem",
                            lineHeight: "20px",
                            color: "#58666E",
                            fontWeight: "normal",
                          }}
                        >
                          {t("Amount")}
                        </span>

                        <span
                          style={{
                            fontSize: "1.7rem",
                            lineHeight: "34px",
                            color: "#1C2B36",
                            fontWeight: "600",
                          }}
                        >
                          <BaseCurrency
                            symbol_code={invoiceInfo?.currency_iso}
                          />{" "}
                          {amountFormat(
                            invoiceInfo.total_amount,
                            invoiceInfo?.currency?.decimal_scale
                          )}
                        </span>

                        <span
                          style={{
                            fontSize: "1rem",
                            lineHeight: "22px",
                            color: "#333333",
                            fontWeight: "normal",
                          }}
                        >
                          {isCN ? (
                            <>
                              {t("balance_amount")} -{" "}
                              <BaseCurrency
                                symbol_code={invoiceInfo?.currency_iso}
                              />
                              {amountFormat(
                                invoiceInfo.balance_amount,
                                invoiceInfo?.currency?.decimal_scale
                              )}
                            </>
                          ) : isEstimates || isSales ? null : (
                            <>
                              {t("due_amount")}-{" "}
                              <BaseCurrency
                                symbol_code={invoiceInfo?.currency_iso}
                              />
                              {amountFormat(
                                invoiceInfo.due_balance_amount,
                                invoiceInfo?.currency?.decimal_scale
                              )}
                            </>
                          )}
                        </span>
                      </div>

                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                          padding: "25px 36px",
                        }}
                      >
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={
                            isPO
                              ? t("order_purchase")
                              : isEstimates
                              ? t("estimate_hash")
                              : isSales
                              ? t("order_salee")
                              : location?.state?.isInvoice
                              ? t("invoice_hashh")
                              : t("bill_hash")
                          }
                          value={
                            location?.state?.isInvoice
                              ? isCN
                                ? invoiceInfo.invoice_ref_number
                                : invoiceInfo.invoice_no
                              : invoiceInfo.billing_no
                          }
                        />
                        {!isPO && !isEstimates && (
                          <BillData
                            style={{
                              fontSize: "1.1rem",
                            }}
                            label={
                              location?.state?.isInvoice
                                ? isSales
                                  ? t("estimate_hash")
                                  : isCN
                                  ? t("reference_hash")
                                  : t("order_salee")
                                : t("order_purchase")
                            }
                            value={
                              location?.state?.isInvoice
                                ? isCN
                                  ? invoiceInfo.reference_number
                                  : invoiceInfo.ref_po_no
                                : invoiceInfo.ref_purchase_order_no
                            }
                          />
                        )}
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={
                            isCN
                              ? t("date")
                              : isPO
                              ? t("purchase_date")
                              : isSales
                              ? t("sales_order_date")
                              : isEstimates
                              ? t("estimate_date")
                              : location?.state?.isInvoice
                              ? t("invoice_date")
                              : t("bill_date")
                          }
                          value={
                            invoiceInfo["date"]
                              ? moment(invoiceInfo["date"]).format("DD/MM/YYYY")
                              : invoiceInfo["billing_date"]
                              ? moment(invoiceInfo["billing_date"]).format(
                                  "DD/MM/YYYY"
                                )
                              : invoiceInfo["invoice_date"]
                              ? moment(invoiceInfo["invoice_date"]).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"
                          }
                        />
                        {isCN ? null : (
                          <BillData
                            style={{
                              fontSize: "1.1rem",
                            }}
                            label={
                              isPO
                                ? t("expected_delivery_date")
                                : isSales
                                ? t("expected_shipment_date")
                                : isEstimates
                                ? t("expiry_date")
                                : t("payment_due_date")
                            }
                            value={
                              invoiceInfo[
                                location.state?.isInvoice
                                  ? "po_due_date"
                                  : "due_date"
                              ]
                                ? moment(
                                    invoiceInfo[
                                      location.state?.isInvoice
                                        ? "po_due_date"
                                        : "due_date"
                                    ]
                                  ).format("DD/MM/YYYY")
                                : ""
                            }
                          />
                        )}
                        {isEstimates || isCN ? null : (
                          <BillData
                            style={{
                              fontSize: "1.1rem",
                            }}
                            label={t("payment_term")}
                            value={
                              invoiceInfo?.vendor_payment_terms?.term_name
                                ? invoiceInfo?.vendor_payment_terms?.term_name
                                : invoiceInfo?.invoice_payment_terms
                                    ?.term_name ??
                                  (invoiceInfo[
                                    location.state?.isInvoice
                                      ? "po_due_date"
                                      : "due_date"
                                  ]
                                    ? "Custom"
                                    : "")
                            }
                          />
                        )}

                        {isPO || module == "BILL" ? (
                          <BillData
                            label={t("Place of Supply")}
                            value={invoiceInfo.place_of_supply}
                          />
                        ) : null}

                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t("Amount")}
                          value={
                            <>
                              <BaseCurrency
                                symbol_code={invoiceInfo?.currency_iso}
                              />{" "}
                              {amountFormat(
                                invoiceInfo.total_amount,
                                invoiceInfo?.currency?.decimal_scale
                              )}
                            </>
                          }
                        />
                        {invoiceInfo.delivery_charges && (
                          <BillData
                            style={{
                              fontSize: "1.1rem",
                            }}
                            label={t("Delivery Charges")}
                            value={
                              <>
                                <BaseCurrency
                                  symbol_code={invoiceInfo?.currency_iso}
                                />{" "}
                                {amountFormat(
                                  invoiceInfo.delivery_charges,
                                  invoiceInfo?.currency?.decimal_scale
                                )}
                              </>
                            }
                          />
                        )}
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t("Total Discount")}
                          value={
                            <>
                              <BaseCurrency
                                symbol_code={invoiceInfo?.currency_iso}
                              />{" "}
                              {amountFormat(
                                invoiceInfo.total_discount,
                                invoiceInfo?.currency?.decimal_scale
                              )}
                            </>
                          }
                        />
                        {isCN ? (
                          <BillData
                            label={t("balance_amount")}
                            value={
                              <>
                                <BaseCurrency
                                  symbol_code={invoiceInfo?.currency_iso}
                                />{" "}
                                {amountFormat(
                                  invoiceInfo.balance_amount,
                                  invoiceInfo?.currency?.decimal_scale
                                )}
                              </>
                            }
                          />
                        ) : isSales || isEstimates ? null : (
                          <BillData
                            label={t("due_amount")}
                            value={
                              <>
                                <BaseCurrency
                                  symbol_code={invoiceInfo?.currency_iso}
                                />{" "}
                                {amountFormat(
                                  invoiceInfo.due_balance_amount,
                                  invoiceInfo?.currency?.decimal_scale
                                )}
                              </>
                            }
                          />
                        )}
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t("tax_amount")}
                          value={
                            <>
                              <BaseCurrency
                                symbol_code={invoiceInfo?.currency_iso}
                              />{" "}
                              {amountFormat(
                                invoiceInfo.total_tax_amount,
                                invoiceInfo?.currency?.decimal_scale
                              )}
                            </>
                          }
                        />
                        {!location?.state?.isInvoice && (
                          <BillData
                            style={{
                              fontSize: "1.1rem",
                            }}
                            label={t("tds_amount")}
                            value={
                              <>
                                <BaseCurrency
                                  symbol_code={invoiceInfo?.currency_iso}
                                />{" "}
                                {amountFormat(
                                  invoiceInfo.tds_amount,
                                  invoiceInfo?.currency?.decimal_scale
                                )}
                              </>
                            }
                          />
                        )}
                        {custom_fields?.data?.map((ele) =>
                          ele.column_type === "document" ? (
                            <BillData
                              label={ele.column_name}
                              value={ele?.attachments}
                              documenttype={ele.column_type}
                            />
                          ) : (
                            <BillData
                              label={ele.column_name}
                              value={ele.value}
                              documenttype={ele.column_type}
                            />
                          )
                        )}
                        <BillData
                          label={t("notes")}
                          value={invoiceInfo.notes}
                        />
                        {isRecieved && (
                          <PaymentModeDisplayFields data={invoiceInfo} />
                        )}
                      </div>

                      {location?.state?.isSales || isCN ? null : (
                        <div
                          style={{
                            backgroundColor: "#F7F7F7",
                            border: "1px solid #EDF0F5",
                            borderRadius: "9px",
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px",
                            padding: "25px 36px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "1.1rem",
                              lineHeight: "22px",
                              color: "#3C4853",
                              marginBottom: "30px",
                              fontWeight: "600",
                            }}
                          >
                            {t("approvers")}
                          </div>

                          <Row>
                            <Space direction="vertical">
                              {invoiceInfo?.approvers?.map((data) => (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#000000",
                                      fontSize: "1.1rem",
                                      lineHeight: "23px",
                                      fontWeight: "medium",
                                    }}
                                  >
                                    {data.level + 1}
                                  </span>

                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      border: "1px solid #D6D6D6",
                                      borderRadius: "50px",
                                      height: "40px",
                                      justifyContent: "center",
                                      margin: "0px 10px",
                                      width: "40px",
                                    }}
                                  >
                                    <img src={User} alt="history" width={18} />
                                    <span
                                      style={{
                                        color: "rgba(0, 0, 0, 0.32)",
                                        fontSize: "1rem",
                                        lineHeight: "21px",
                                        fontWeight: "medium",
                                      }}
                                    >
                                      {data.init}
                                    </span>
                                  </div>

                                  <span
                                    style={{
                                      color: "#4A4A4ACC",
                                      fontSize: "1.1rem",
                                      lineHeight: "18px",
                                      fontWeight: "medium",
                                    }}
                                  >
                                    {data.role_name === "Employee"
                                      ? invoiceInfo?.created_by?.name
                                      : data.name}
                                    <span
                                      style={{
                                        fontSize: "1rem",
                                        color: "#4A4A4ACC",
                                        fontWeight: "medium",
                                      }}
                                    >
                                      ({data.role_name})
                                    </span>
                                  </span>
                                </div>
                              ))}
                            </Space>
                          </Row>
                        </div>
                      )}

                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                          padding: "25px 36px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.1rem",
                            lineHeight: "22px",
                            color: "#3C4853",
                            marginBottom: "30px",
                            fontWeight: "600",
                          }}
                        >
                          {t("attachments")}
                        </div>

                        <div>
                          {file?.map((element, index) => {
                            return (
                              <>
                                <div style={{ marginBottom: "0.5rem" }}>
                                  {index + 1}. {element.filename} (
                                  <a
                                    href={element?.link}
                                    target="_blank"
                                    style={{ color: "black" }}
                                  >
                                    {t("download_text")}
                                  </a>
                                  )
                                  {element.filename &&
                                    element.filename?.includes &&
                                    (element.filename?.includes(".jpg") ||
                                      element.filename?.includes(".png") ||
                                      element.filename?.includes(".jpeg")) && (
                                      <EyeOutlined
                                        color="#333"
                                        onClick={() => {
                                          setUrl(element?.link);
                                          setVisible(true);
                                        }}
                                        className="ml1"
                                      />
                                    )}
                                </div>
                              </>
                            );
                          })}
                          {url && (
                            <Image
                              width={200}
                              style={{
                                display: "none",
                              }}
                              src={url}
                              preview={{
                                visible,
                                scaleStep: 0.5,
                                src: url,
                                onVisibleChange: (value) => {
                                  setVisible(value);
                                },
                              }}
                            />
                          )}
                        </div>
                      </div>

                      {location?.state?.isSales ? null : (
                        <div
                          style={{
                            backgroundColor: "#F7F7F7",
                            border: "1px solid #EDF0F5",
                            borderRadius: "9px",
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            padding: "25px 36px",
                          }}
                        >
                          <Space direction="vertical">
                            {related.map(
                              (data) =>
                                data && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#3C4853",
                                        fontSize: "1rem",
                                        lineHeight: "22px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {data.step}
                                    </span>

                                    <span
                                      style={{
                                        color: "#3C4853",
                                        fontSize: "1rem",
                                        lineHeight: "28px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {data.name}
                                    </span>

                                    <span
                                      style={{
                                        color: "#8B8B8B",
                                        fontSize: "0.95rem",
                                        lineHeight: "23px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {data.email}
                                    </span>

                                    <span
                                      style={{
                                        color: "#8B8B8B",
                                        fontSize: "0.95rem",
                                        lineHeight: "23px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {data.date}
                                    </span>

                                    {data.role && (
                                      <span
                                        style={{
                                          color: "#3C4853",
                                          fontSize: "0.8rem",
                                          lineHeight: "28px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {data.role}
                                      </span>
                                    )}
                                  </div>
                                )
                            )}
                          </Space>
                        </div>
                      )}
                    </div>
                  ) : null}

                  {tab === "history" ? (
                    invoiceInfo?.history?.length ? (
                      <div className="commentsSection">
                        <Steps
                          direction="vertical"
                          size="small"
                          current={invoiceInfo.history.length + 1 || 100}
                        >
                          {invoiceInfo.history &&
                            invoiceInfo.history.map(
                              ({ body, created_at, id, curr_status, name }) => (
                                <Steps.Step
                                  key={id}
                                  title={body}
                                  description={`on ${timeFormat(
                                    created_at
                                  )} | Action: ${curr_status} by ${name}`}
                                  icon={
                                    <img
                                      src={History}
                                      alt="history"
                                      width={18}
                                    />
                                  }
                                />
                              )
                            )}
                        </Steps>
                      </div>
                    ) : (
                      <NodataComponent noBtns={true} />
                    )
                  ) : null}

                  {tab === "payment made" &&
                  (type === "INVOICE" || type === "BILL") ? (
                    invoiceInfo?.receipts?.length ? (
                      <div className="commentsSection">
                        <TableComponent
                          columns={paymentMadeColumns}
                          loading={invoiceInfo?.loading}
                          data={
                            Array.isArray(invoiceInfo?.receipts)
                              ? invoiceInfo?.receipts
                              : []
                          }
                          hasCheckbox={false}
                        />
                      </div>
                    ) : (
                      <NodataComponent noBtns={true} />
                    )
                  ) : null}

                  {tab === "comments" ? (
                    <>
                      <div
                        className="addComment mb1"
                        style={{ display: "flex" }}
                      >
                        <Input
                          placeholder={t("enter_comment")}
                          ref={inputComment}
                          value={input}
                          onInput={(e) => setInput(e.target.value.trimStart())}
                        />
                        <Button
                          disabled={!hasWriteAccess}
                          key="1"
                          className="formButton ml1"
                          onClick={() => handleAddComment()}
                        >
                          {t("send")}
                        </Button>
                      </div>

                      {invoiceInfo?.comments?.length ? (
                        <>
                          <div className="commentsSection">
                            <Steps
                              direction="vertical"
                              size="small"
                              current={invoiceInfo.comments.length + 1 || 100}
                            >
                              {invoiceInfo.comments &&
                                invoiceInfo.comments.map(
                                  ({ body, created_at, id, name }) => (
                                    <Steps.Step
                                      key={id}
                                      title={body}
                                      description={`on ${timeFormat(
                                        created_at
                                      )} | Commented by: ${name}`}
                                      icon={
                                        <img
                                          src={Comment}
                                          alt="history"
                                          width={18}
                                        />
                                      }
                                    />
                                  )
                                )}
                            </Steps>
                          </div>
                        </>
                      ) : (
                        <NoCommentsComponent />
                      )}
                    </>
                  ) : null}

                  {tab === "items" ? (
                    invoiceInfo?.[
                      location?.state?.isInvoice
                        ? isCN
                          ? "line_items"
                          : "invoice_items"
                        : "billing_items"
                    ]?.length ? (
                      <div className="commentsSection">
                        <ItemsTableShow
                          currency={invoiceInfo?.currency_iso}
                          currencyInfo={invoiceInfo?.currency}
                          itemsTableShowData={
                            invoiceInfo?.[
                              location?.state?.isInvoice
                                ? isCN
                                  ? "line_items"
                                  : "invoice_items"
                                : "billing_items"
                            ]
                          }
                        />
                      </div>
                    ) : (
                      <NodataComponent noBtns={true} />
                    )
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ApproveBill;
