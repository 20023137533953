import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Space
} from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import BillActive from "../../assets/icons/bill_active.png";
import BillInactive from "../../assets/icons/bill_inactive.png";
import CommentInactive from "../../assets/icons/comment.png";
import CommentActive from "../../assets/icons/comment_active.png";
import DropdownIcon from "../../assets/icons/dropdown_icon.png";
import ErrorActive from "../../assets/icons/error_active.png";
import ErrorInactive from "../../assets/icons/error_inactive.png";
import warn from "../../assets/icons/warning.png";
import block from "../../assets/icons/block.png";
import { amountFormat, objectToFormData } from "../../utils";
import TableWrap from "../Common/Table/Table";
import { useTranslation } from "react-i18next";

import "./report.css";

import {
  ReportsCommentsAdd,
  reportsExpAddComments,
  ReportsExpComments,
  reportsExpComments,
  TransactionsPartialApprove,
  transactionsPartialApprove,
  TransactionsReject,
  transactionsReject,
  userReport
} from "../../actions/reports";
import BaseCurrency from "../Common/BaseCurrency";
import { DeleteExpense, expenses_delete } from "../../actions/expenses/expenses";
import moment from "moment";

const { TextArea } = Input;

// const content = (
//   <div>
//     <Row>
//       <Col span={10}>
//         <Button danger className="policyVoiButton">
//           Warning
//         </Button>
//       </Col>
//       <Col span={14}>
//         Cash amount should not exceed than Rs. 1000 per quarter
//       </Col>
//     </Row>
//     <hr className="mt1 mb1" />
//     <Row>
//       <Col span={10}>
//         <Button danger className="policyVoiButton">
//           Warning
//         </Button>
//       </Col>
//       <Col span={14}>
//         Cash amount should not exceed than Rs. 1000 per quarter
//       </Col>
//     </Row>
//   </div>
// );

message.config({
  maxCount: 3,
});

const Expenses = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const is_admin = useSelector((state) => state?.auth?.localVariables?.is_admin);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [reasonValue, setReasonValue] = React.useState("");
  const [amountValue, setAmountValue] = React.useState("");
  const [input, setInput] = React.useState("");
  const { expensesData } = props;
  const [expenseSelectedData, setExpensesSelectedData] = React.useState("");
  const [filesPopup, setFilesPopup] = React.useState(false);
  const [billFiles, setBillFiles] = React.useState([]);
  const { t } = useTranslation();


  const reportsExpCommentsResponse = useSelector(
    (state) => state.reports.reportsExpComments || {}
  );
  const reportsExpAddCommentsResponse = useSelector(
    (state) => state.reports.reportsExpAddComments || {}
  );
  const transactionsRejectResponse = useSelector(
    (state) => state.reports.transactionsReject || {}
  );
  const deleteExpResponse = useSelector(
    (state) => state.reports.deleteExpense || {}
  );
  const transactionsPartialApproveResponse = useSelector(
    (state) => state.reports.transactionsPartialApprove || {}
  );

  const reportsCommentsAddResponse = useSelector(
    (state) => state.reports.reportsCommentsAdd || {}
  );

  React.useEffect(() => {

    // expensesData?.forEach(ele => {
    //   dispatch(reportsExpComments({ transaction_id: ele.id }));
    // })
    return () => {
      dispatch({
        type: ReportsExpComments.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expensesData])


  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      reportsExpAddCommentsResponse;
    if (status && expenseSelectedData) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch(
        userReport(
          { report_id: params.id, module_type: "REPORT" },
        )
      );
      dispatch({
        type: ReportsCommentsAdd.RESET,
      });
      // dispatch(reportsExpComments({ transaction_id: expenseSelectedData }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsExpAddCommentsResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      transactionsRejectResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      setReasonValue("")
      dispatch({
        type: TransactionsReject.RESET
      })
      props.callExpenseDetails()
    } else {
      if (res_message) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: TransactionsReject.RESET
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsRejectResponse]);
  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      deleteExpResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch({
        type: DeleteExpense.RESET
      })
      props.callExpenseDetails()
    } else {
      if (res_message) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: DeleteExpense.RESET
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteExpResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      transactionsPartialApproveResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch({
        type: TransactionsPartialApprove.RESET
      })
    } else {
      if (res_message) {
        message.error(<span className="messageText">{res_message}</span>);
        dispatch({
          type: TransactionsPartialApprove.RESET
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsPartialApproveResponse]);

  const handleAddComment = (record) => {
    if (input && record?.id) {
      dispatch(
        reportsExpAddComments(
          objectToFormData({
            body: input,
            commentable_id: record?.id,
            commentable_type: "transaction"
          })
        )
      );
      setInput('');
    }
  };

  const expenseError = (record) => {
    let totalErrors = 0; // Declare totalErrors variable

    record?.violation_data?.transactions?.forEach(transaction => {
      transaction.policies.forEach(policy => {
        totalErrors += policy.errors.length;
      });
    });

    // Replace arr?.length with an appropriate value or variable
    return totalErrors; // Return totalErrors instead of arr?.length
  };


  const columns = [
    {
      title: t("ex_id"),
      dataIndex: "transaction_number",
      key: "transaction_number",
    },
    {
      title: t("date_time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD MMM YYYY HH:mm:ss")}</div>
    },
    {
      title: t("merchant"),
      dataIndex: "merchant_name",
      key: "merchant_name",
    },
    {
      title: t("category"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("payment_mode"),
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: t("Amount"),
      dataIndex: "amount_cents",
      key: "amount_cents",
      render: (text) => (
        <>
          <BaseCurrency /> {amountFormat(text)}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space
            key={record.id}
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="imgWrapper"
              style={{
                "--bg-color": record.attachments.length ? "#E1F8D9" : "#F7F7F7",
              }}
              onClick={() => {
                if (record.attachments?.length > 0) {
                  setFilesPopup(true);
                  setBillFiles(record.attachments);
                }
              }}
            >
              {record.attachments.length ? (
                <span
                  style={{
                    "--color": "#49B928",
                  }}
                >
                  {record.attachments.length}
                </span>
              ) : null}
              <img
                src={record.attachments?.length ? BillActive : BillInactive}
                alt={"logo"}
                className="action-logo"
              />
            </div>
            <Popover
              placement="bottom"
              title={t("expense_errors")}
              content={
                <section className="report-error p1">
                  {expenseError(record) > 0
                    ?
                    // record?.expense_errors?.expense?.map((ele) => (
                    //     <>
                    //       <p>{ele.transaction_number}</p>

                    //       {ele?.policies?.map((itm) => (
                    //         <p
                    //           className={`item ${itm.is_warning ? "warn" : ""}`}
                    //         >
                    //           {itm?.errors?.map((item) => (
                    //             <>
                    //               {" "}
                    //               <img
                    //                 src={itm.is_warning ? warn : ErrorActive}
                    //                 alt=""
                    //                 width={12}
                    //                 className="mr1"
                    //               />{" "}
                    //               {item}
                    //               <br />
                    //             </>
                    //           ))}
                    //         </p>
                    //       ))}
                    //     </>
                    //   ))

                    record?.violation_data?.transactions?.map((reportItem, reportIndex) => (
                      <div key={reportIndex}>
                        {reportItem?.policies?.map((policy, policyIndex) => (
                          <div key={policyIndex}>
                            {policy?.policy_action == "WARNING" ? (
                              <>
                                {policy?.errors?.map((error, errorIndex) => (
                                  <>
                                    <div key={errorIndex} className="v-center mbHalf">
                                      <img src={warn} alt="warning-icon" style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }} />
                                      <h4 className="mb0" style={{ color: "orange" }}>{error}</h4>
                                    </div>
                                  </>

                                ))}
                              </>
                            ) : (
                              <>
                                {policy?.errors?.map((error, errorIndex) => (
                                  <>
                                    <div key={errorIndex} className="v-center mbHalf">
                                      <img src={block} alt="block-icon" style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }} />
                                      <h4 className="mb0" style={{ color: "orange" }}>{error}</h4>
                                    </div>
                                  </>
                                ))}
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    ))
                    : "No Data"}
                </section>
              }
              trigger="click"
            >
              <div
                className="imgWrapper"
                style={{
                  "--bg-color": false ? "#FCE7E7" : "#F7F7F7",
                }}
              >
                {expenseError(record) > 0 && (
                  <span
                    style={{
                      "--color": "#E24C4B",
                    }}
                  >
                    {expenseError(record)}
                  </span>
                )}
                <img
                  src={expenseError(record) ? ErrorActive : ErrorInactive}
                  alt={"logo"}
                  className="action-logo"
                />
              </div>
            </Popover>
            <Popover
              placement="bottom"
              title="Comments"
              content={commentsContent(record)}
              trigger="click"
            >
              <div
                className="imgWrapper"
                style={{
                  "--bg-color": record?.comments.length
                    ? "#F0F4FC"
                    : "#F7F7F7",
                }}
                onClick={() => handleExpComments(text)}
              >
                {record?.comments?.length
                  ? (
                    <span
                      style={{
                        "--color": "#5A87F9",
                      }}
                    >
                      {
                        record?.comments?.length
                      }
                    </span>
                  ) : null}
                <img
                  src={
                    reportsExpCommentsResponse?.[record?.id]?.response?.comments
                      ?.length
                      ? CommentActive
                      : CommentInactive
                  }
                  alt={"logo"}
                  className="action-logo"
                />
              </div>
            </Popover>
            <Dropdown overlay={menu(record)} placement="bottomRight">
              <img src={DropdownIcon} alt={"logo"} className="action-logo" />
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const commentsContent = (record) => (
    <div>
      <Row>
        <Col span={24} className="commentsContent">
          {record?.comments?.length ? (
            record.comments?.map((item) => (
              <>
                <p key={item.id} className="commentBody">
                  {item.body}
                </p>
                <span className="commentData">
                  {item.created_at} | {item.user_name}
                </span>
                <hr className="mt1 mb1" />
              </>
            ))
          ) : (
            <p style={{ color: "#333" }}>{t('no_comments')}</p>
          )}
        </Col>
      </Row>
      <Row>
        <div className="addExpComment mt2">
          <Input placeholder="Enter Comment"
            value={input}
            onInput={(e) => {
              setInput(e.target.value.trimStart())
            }}
          />
          <Button
            key="1"
            className="formButton ml1"
            onClick={() => handleAddComment(record)}
          >
            {t('send')}
          </Button>
        </div>
      </Row>
    </div>
  );

  function handleMenuClick(e, record) {
    switch (e.key) {
      case "1":
        history.push({
          pathname: `/expense-details/${record.id}`,
          state: { expenseData: { ...record, isFromExpense: false } },
        });
        break;
      case "2":
        setReasonValue("");
        setIsModalVisible(true);
        setModalType("delete");
        setExpensesSelectedData(record.id);
        break;
      case "3":
        setAmountValue("");
        setIsModalVisible(true);
        setModalType("partial");
        setExpensesSelectedData(record.id);
        break;
      case "4":
        setReasonValue("");
        setIsModalVisible(true);
        setModalType("deleteExp");
        setExpensesSelectedData(record.id);
        break;
      default:
    }
  }

  const menu = (record) => (
    <Menu onClick={(e) => handleMenuClick(e, record)}>
      <Menu.Item key="1">{t('view_expense')}</Menu.Item>
      {props.showReject && props.expensesDataLength && <>
        {is_admin && !props.isLastExpense && <Menu.Item key="2">{t('reject_expense')}</Menu.Item>}
        {/* {is_admin && <Menu.Item key="3">Partial Approval</Menu.Item>} */}
      </>}
      {props.showDelete && <Menu.Item key="4">{t('expense_unlink')}</Menu.Item>}
    </Menu>
  );

  const handleCancel = () => {
    setIsModalVisible(false);
    setReasonValue("")
  };

  const handleExpComments = (id) => {
    setExpensesSelectedData(id);
    // dispatch(reportsExpComments({ transaction_id: id }));
  };

  const renderDeleteOption = () => {
    return (
      <form onSubmit={(e) => {
        e.preventDefault()
        dispatch(
          transactionsReject(
            ({
              id: expenseSelectedData,
              reject_reason: reasonValue,
              params: { module_type: "EXPENSE" }
            })
          )
        )
      }
      }>
        <Text>{t('rejection_reason')}</Text>
        <br />
        <TextArea
          rows={4}
          placeholder={t('enter_reason')}
          className="reasonTextArea"
          value={reasonValue}
          required={true}
          onChange={(e) => setReasonValue(e.target.value)}
        />
        <br />
        <Button
          key="1"
          htmlType="submit"
          className="formButton mt1"
          disabled={!reasonValue || transactionsRejectResponse?.loading}
        >
          {t('confirm')}
        </Button>
        <Button
          key="2"
          htmlType="button"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t('cancel')}
        </Button>
      </form>
    );
  };

  const renderDelete = () => {
    return (
      <>
        <Text>{t('unlink_expense')}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          disabled={deleteExpResponse?.loading}
          onClick={() => {
            dispatch(
              expenses_delete({
                transaction_ids: [expenseSelectedData],
                unlink: true,
                params: { module_type: "REPORT" },
              })
            );
          }
          }
        >
          {t('unlink')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t('cancel')}
        </Button>
      </>
    );
  };

  const renderPartialAcceptOption = () => {
    return (
      <>
        <Text>{t('specify_amount')}</Text>
        <br />
        <br />
        <Input
          placeholder="Enter Amount"
          value={amountValue}
          onChange={(e) => setAmountValue(e.target.value)}
        />
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() =>
            dispatch(
              transactionsPartialApprove(
                objectToFormData({
                  id: expenseSelectedData,
                  approved_amount: amountValue,
                })
              )
            )
          }
        >
          {t('confirm')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t('cancel')}
        </Button>
      </>
    );
  };

  return (
    <div className="expenses-table">
      <Modal
        title={
          modalType === "deleteExp" ? "Unlink Expense" : modalType === "delete" ? "Reject Expense" : "Partial Approve Expense"
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className="center-aligned-modal"
      >
        {modalType === "deleteExp" ? renderDelete() : modalType === "delete"
          ? renderDeleteOption()
          : renderPartialAcceptOption()}
      </Modal>
      <Modal
        title={"Receipts"}
        visible={filesPopup}
        onCancel={() => setFilesPopup(false)}
        footer={[]}
        className="right-alinged-modal popup-imgs"
      >
        {billFiles?.map(img => <img src={img.url} alt="" className={"w100 mb1"} />)}
      </Modal>
      <TableWrap columns={columns} data={expensesData} pagination={false} handleRowClick={(evt, record) => {
        history.push({ pathname: `/expense-details/${record.id}`, state: { expenseData: record } });
      }} />
    </div>
  );
};

export default Expenses;
