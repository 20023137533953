import { ActionTypesFactory } from "../../utils";

export const InventoryItemsCreate = ActionTypesFactory("InventoryItemsCreate", "InventoryItemsCreate");
export const InventoryItemsGet = ActionTypesFactory("InventoryItemsGet", "InventoryItemsGet");

export const inventoryItemsCreate = (payload) => {
    return {
        type: InventoryItemsCreate.REQUEST,
        payload
    }
}
export const inventoryItemsGet = (payload) => {
    return {
        type: InventoryItemsGet.REQUEST,
        payload
    }
}