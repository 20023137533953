import React, { useEffect } from 'react';
import { FormInput, FormSelect } from '../inputs';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AddVendorContactPerson = ({ control, errors, register, watch, setValue, clearErrors, setError, submitCount, contact_persons }) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (contact_persons?.length === 0) {
      append({
        salutation: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        contact_number: ''
      })

    }
  }, [])

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'contact_persons',
  });


  const handleDelete = (index) => {
    remove(index);
    if (index === 0) {
      // Do not remove the first element, update it with empty values
      fields.append({
        salutation: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        contact_number: ''
      });
    }
  };

  const handleError = (type, index) => {
    if (type == "add") {
      setError(`contact_persons[${index}].salutation`, { type: "custom", message: "Salutation is required." })
      setError(`contact_persons[${index}].first_name`, { type: "custom", message: "First Name is required." })
      setError(`contact_persons[${index}].last_name`, { type: "custom", message: "Last Name is required." })
      setError(`contact_persons[${index}].email`, { type: "custom", message: "Email is required." })
    } else if (type == "remove") {
      clearErrors(`contact_persons[${index}].salutation`)
      clearErrors(`contact_persons[${index}].first_name`)
      clearErrors(`contact_persons[${index}].last_name`)
      clearErrors(`contact_persons[${index}].email`)
    }
  }
  return (
    <>
      {fields.map((personData, index) => {
        const obj = contact_persons[index];
        const isFieldRequired = Object.keys(obj).some(key => key !== "country_code" && obj[key] !== "");
        return (
          <div key={personData.id}>

            <div className="pp-form-item">
              <FormSelect
                control={control}
                {...register(`contact_persons.${index}.salutation`, {
                  required: isFieldRequired ? "Salutation is required." : false,
                  onChange: (e) => {
                    let fieldValue = e?.target?.value?.trim()
                    if (fieldValue && fieldValue?.length >= 1) {
                      handleError("add", index)
                    } else if (!fieldValue && fieldValue?.length == 0) {
                      handleError("remove", index)
                    }
                  }
                })}
                label="Salutation"
                options={[{
                  label: "Mr.",
                  value: "Mr.",
                }, {
                  label: "Mrs.",
                  value: "Mrs.",
                }, {
                  label: "Miss.",
                  value: "Miss.",
                }, {
                  label: "Dr.",
                  value: "Dr.",
                }, {
                  label: "Ms.",
                  value: "Ms.",
                }]}
                hideCreateOption={true}
                required={isFieldRequired}
                errorMessage={!watch(`contact_persons[${index}].salutation`) && isFieldRequired && submitCount > 0 ? errors.contact_persons?.[index]?.['salutation']?.['message'] : null}
              />
            </div>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`contact_persons.${index}.first_name`, {
                  required: isFieldRequired ? t('first_name_required') : false,
                  onChange: (e) => {
                    let fieldValue = e?.target?.value?.trim()
                    if (fieldValue && fieldValue?.length == 1) {
                      handleError("add", index)
                    }
                  }
                })}
                label={t('first_name')}
                required={isFieldRequired}
                errorMessage={!watch(`contact_persons[${index}].first_name`) && isFieldRequired && submitCount > 0 ? errors.contact_persons?.[index]?.['first_name']?.['message'] : null}
              />
            </div>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`contact_persons.${index}.middle_name`,
                  {
                    onChange: (e) => {
                      let fieldValue = e?.target?.value?.trim()
                      if (fieldValue && fieldValue?.length == 1) {
                        handleError("add", index)
                      } else if (!fieldValue && fieldValue?.length == 0) {
                        handleError("remove", index)
                      }
                    }
                  })}
                label={t('middle_name')}
                errorMessage={errors.contact_persons?.[index]?.['middle_name']?.['message']}
              />
            </div>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`contact_persons.${index}.last_name`, {
                  required: isFieldRequired ? t('last_name_required') : false,
                  onChange: (e) => {
                    let fieldValue = e?.target?.value?.trim()
                    if (fieldValue && fieldValue?.length == 1) {
                      handleError("add", index)
                    } else if (!fieldValue && fieldValue?.length == 0) {
                      handleError("remove", index)
                    }
                  }
                })}
                label={t('last_name')}
                required={isFieldRequired}
                errorMessage={!watch(`contact_persons[${index}].last_name`) && isFieldRequired && submitCount > 0 ? errors.contact_persons?.[index]?.['last_name']?.['message'] : null}
              />
            </div>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`contact_persons.${index}.email`, {
                  required: isFieldRequired ? t('email_required') : false,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('enter_valid_Email')
                  },
                  onChange: (e) => {
                    let fieldValue = e?.target?.value?.trim()
                    if (fieldValue && fieldValue?.length == 1) {
                      handleError("add", index)
                    } else if (!fieldValue && fieldValue?.length == 0) {
                      handleError("remove", index)
                    }
                  }
                })}
                label={t('email')}
                required={isFieldRequired}
                errorMessage={!watch(`contact_persons[${index}].email`) && isFieldRequired && submitCount > 0 ? errors.contact_persons?.[index]?.['email']?.['message'] : null}
              />
            </div>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register(`contact_persons.${index}.contact_number`, {
                  onChange: (e) => {
                    setValue("contact_persons[${index}].contact_number", e.target.value)
                    let fieldValue = e?.target?.value?.trim()
                    if (fieldValue && fieldValue?.length == 1) {
                      handleError("add", index)
                    } else if (!fieldValue && fieldValue?.length == 0) {
                      handleError("remove", index)
                    }
                  }
                })}
                label={t('number_phone')}
                errorMessage={errors.contact_persons?.[index]?.['contact_number']?.['message']}
                number={true}
              />
            </div>

            <div style={{ paddingTop: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              {(fields.length > 0 && index === fields.length - 1) ?
                <Button
                  type="text"
                  className="pp-dropdown-add-new"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    append({
                      // key: index + 2,
                      salutation: '',
                      first_name: '',
                      middle_name: '',
                      last_name: '',
                      email: '',
                      contact_number: ''
                    });
                  }}>
                  <span>{t('add_more_contact')}</span>
                </Button>
                :
                <Button
                  type="text"
                  className="pp-dropdown-add-new"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(index)}>
                  <span>{t('remove')}</span>
                </Button>
              }
            </div>
          </div>

        )
      })}
    </>
  );
};

export default AddVendorContactPerson;
