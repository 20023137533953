import { Input } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';

const FormCheckbox = ({ options, errors, errorMessage, label, placeholder, required, readOnly = false, horizontal = false, ...restOfProps }) => {
    return (
        <div>
            <Controller
                {...restOfProps}
                shouldUnregister={true}
                render={({ field: { onChange } }) => (
                    <div style={{ alignItems: horizontal && 'center', display: horizontal && 'flex' }} >
                        <span style={{ marginBottom: '15px', display: "inline-block", fontSize: '14px', color: ((errors && errors[restOfProps.name]) || errorMessage) ? '#E0272B' : '#3B3B3B' }}>
                            {label}
                            {required && '*'}
                        </span>
                        <div style={{ paddingTop: !horizontal && '0px', paddingLeft: horizontal && '10px', display: 'flex', justifyContent: 'space-between' }}>
                            {options?.map((ele, i) => <label style={{
                                display: 'inline-flex',
                                color: '#333',
                                cursor: 'pointer',
                                alignItems: 'center',
                            }} htmlFor={"radio" + i} >
                                <Input
                                    className="pp-form-radio"
                                    placeholder={placeholder}
                                    onChange={onChange}
                                    name={"radio"}
                                    id={"radio" + i}
                                    readOnly={readOnly}
                                    type="checkbox"
                                />
                                &nbsp;&nbsp;
                                <span style={{
                                    color: '#333',
                                    fontSize: 14,
                                    fontWeight: 500
                                }} >
                                    {ele.label}
                                </span>
                            </label>)
                            }
                        </div>
                    </div>
                )}
            />
        </div>
    );
}

export default FormCheckbox