import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import {
  ApproveBillingDetails,
  BillingComment,
  BillingCommentHistory,
  CreateBillingComment,
  CreateBillingDetail,
  FetchAllBillingDetails,
  FetchBillingDetailsById,
  RejectBillingDetails,
  SubmitBillingDetails,
  UpdateBillAttachment,
  UpdateBillingDetail,
} from "./bills";

function createBillingDetails(req) {
  let id = req.payload.id;
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    CreateBillingDetail,
    "post",
    `${id ? apis.updateBillingDetail : apis.createBillingDetail
    }?module_type=${type}`,
    req.payload,
    { "Content-Type": "application/json" },
    true
  )();
}

function updateBillingDetails(req) {
  return sagaFunctions(
    UpdateBillingDetail,
    "post",
    apis.updateBillingDetail,
    req.payload,
    { "Content-Type": "application/json" },
    true
  )();
}

function updateBillAttachments(req) {
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    UpdateBillAttachment,
    "post",
    `${apis.updateBillAttachment}?module_type=${type}`,
    req.payload,
    { "Content-Type": "multipart/form-data" },
    true
  )();
}

function fetchAllBillingDetails(req) {
  let AdminView = req?.payload?.view;
  let type = req.payload.module_type;
  delete req.payload.module_type;
  const request =
    apis.filtersBillingDetails +
    (AdminView
      ? `?view=${AdminView}&module_type=${type}&page_number=${req.payload?.page_number}`
      : `?module_type=${type}&page_number=${req.payload?.page_number}`);
  delete req.payload?.isFilter;
  return sagaFunctions(
    FetchAllBillingDetails,
    "post",
    request,
    req.payload,
    {},
    true
  )();
}

function fetchBillingDetailById(req) {
  let isInvoice = req.payload.isInvoice;
  let receipt_id = req.payload.receipt_id;
  let isReceipt = req.payload.isReceipt;
  delete req.payload.isInvoice;
  delete req.payload.receipt_id;
  delete req.payload.isReceipt;
  let type = req.payload.module_type;
  const isCredit = type === "CREDIT"
  if (isCredit) {
    req.payload.id = req.payload.invoice_id
    delete req.payload.invoice_id
  }
  delete req.payload.module_type;
  return sagaFunctions(
    FetchBillingDetailsById,
    "get",
    isReceipt ? apis.getReceiptByID : receipt_id ? apis.getInvoiceReceiptByID : `${isCredit ? apis.getCreditDebitNoteByID : isInvoice ? apis.getCustomerInvoiceByID : apis.fetchBillingDetailById
      }?module_type=${type}`,
    req.payload,
    {},
    true
  )();
}

function submitBillingDetails(req) {
  let isInvoice = req.payload.isInvoice;
  delete req.payload.isInvoice;
  let type = req.payload.module_type;
  delete req.payload.module_type;
  const isCredit = type === "CREDIT"

  return sagaFunctions(
    SubmitBillingDetails,
    "post",
    isCredit ? `${apis.submitCreditDebitNote}?module_type=${type}` :
      isInvoice
        ? `${apis.submitInvoice}?module_type=${type}`
        : `${apis.submitBillingDetail}?module_type=${type}`,
    req.payload,
    { "Content-Type": "application/json" },
    true
  )();
}

function approveBillingDetails(req) {
  let isInvoice = req.payload.isInvoice;
  delete req.payload.isInvoice;
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    ApproveBillingDetails,
    "post",
    isInvoice
      ? `${apis.approveInvoice}?module_type=${type}`
      : `${apis.approveBillingDetails}?module_type=${type}`,
    req.payload,
    { "Content-Type": "application/json" },
    true
  )();
}

function rejectBillingDetails(req) {
  let isInvoice = req.payload.isInvoice;
  delete req.payload.isInvoice;
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    RejectBillingDetails,
    "post",
    isInvoice
      ? `${apis.rejectInvoice}?module_type=${type}`
      : `${apis.rejectBillingDetails}?module_type=${type}`,
    req.payload,
    { "Content-Type": "application/json" },
    true
  )();
}

function createBillingComments(req) {
  let type = req.payload.module_type;
  let isCustomerPortal = req?.payload?.isCustomerPortal
  delete req.payload.module_type;
  delete req.payload.isPayment
  return sagaFunctions(
    CreateBillingComment,
    "post",
    // type === "payment_received" ? apis.createPaymentReceivedComments : `${apis.createBillingComments}`,
    isCustomerPortal == "isCustomer" ? apis.customerPortalCommentes : isCustomerPortal == "isVendor" ? apis.vendorPortalCommentes : apis.createPaymentReceivedComments,
    req.payload,
    { "Content-Type": "application/json" },
    true
  )();
}

function billingComment(req) {
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    BillingComment,
    "get",
    `${apis.fetchBillingDetailById}?module_type=${type}`,
    req.payload,
    {},
    true
  );
}

function billingCommentHistory(req) {
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    BillingCommentHistory,
    "get",
    `${apis.fetchBillingDetailById}?module_type=${type}`,
    req.payload,
    {},
    true
  );
}

export function* billsWatcher() {
  yield takeLatest(CreateBillingDetail.REQUEST, createBillingDetails);
  yield takeLatest(UpdateBillingDetail.REQUEST, updateBillingDetails);
  yield takeLatest(UpdateBillAttachment.REQUEST, updateBillAttachments);
  yield takeLatest(FetchAllBillingDetails.REQUEST, fetchAllBillingDetails);
  yield takeLatest(FetchBillingDetailsById.REQUEST, fetchBillingDetailById);
  yield takeLatest(SubmitBillingDetails.REQUEST, submitBillingDetails);
  yield takeLatest(ApproveBillingDetails.REQUEST, approveBillingDetails);
  yield takeLatest(RejectBillingDetails.REQUEST, rejectBillingDetails);
  yield takeLatest(CreateBillingComment.REQUEST, createBillingComments);
  yield takeLatest(BillingComment.REQUEST, billingComment);
  yield takeLatest(BillingCommentHistory.REQUEST, billingCommentHistory);
}
