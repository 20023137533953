import React, { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form';
import { FormInput, FormSelect } from '../inputs';
import Close from "../../../src/assets/icons/close.png";
import { vendorsByName } from '../../actions/purchases';
import { useDispatch } from 'react-redux';
const InventoryItems = (props) => {
    const dispatch = useDispatch();
    const {
        control,
        register,
        setValue,
        getValues,
        watch,
        errors,
        vendorsByNameData,
        itemsData,
        warehouseData
    } = props;
    const { append } = useFieldArray({
        control,
        name: "track_inventory",
    });

    const onVendorSearch = (e) => {
        if (e && e.trim() !== "") {
            dispatch(
                vendorsByName({
                    beneficiary_name: e.trim(),
                    type: "vendor",
                    status: "active",
                })
            );
        }
    };

    const [values, setValues] = useState([
        {
            vendor_id: "",
            opening_stock_per_unit: "",
            opening_stock_value_per_unit: "",
        },
    ]);

    const columns = [
        {
            title: "Warehouse Name",
            dataIndex: "vendor_id",
            key: "vendor_id",
            render: (record, index) => (
                <>
                    <FormSelect
                        control={control}
                        errorMessage={errors.track_inventory?.[index]?.vendor_id?.message}
                        allowClear={true}
                        placeholder={"Select Item"}
                        {...register(`track_inventory.${index}.vendor_id`, {
                            onChange: (e) => {
                                let vals = [...values];
                                vals[index].vendor_id = e?.target?.value
                                setValue(`track_inventory.${index}.vendor_id`, e?.target?.value)
                                setValues(vals)
                            }
                        })}
                        className="w100"
                        value={values?.[index]?.vendor_id}
                        options={
                            Array.isArray(warehouseData?.data?.data)
                                ? warehouseData?.data?.data?.map((item) => ({
                                    value: item?.id,
                                    label: item?.ware_house_name,
                                }))
                                : []
                        }
                        hideCreateOption={true}
                    />
                    <div className="errorMsg">{errors?.track_inventory?.[index]?.vendor_id?.message}</div>
                </>
            ),
            width: "40%",
        },
        {
            title: "Opening Stock",
            dataIndex: "opening_stock_per_unit",
            width: "30%",
            key: "opening_stock_per_unit",
            render: (record, index) => (
                <FormInput
                    placeholder={"0"}
                    control={control}
                    number={true}
                    value={values?.[index]?.opening_stock_per_unit}
                    errorMessage={errors?.track_inventory?.[index]?.opening_stock_per_unit?.message}
                    {...register(`track_inventory.${index}.opening_stock_per_unit`, {
                        onChange: (e) => {
                            let vals = [...values];
                            vals[index].opening_stock_per_unit = e?.target?.value
                            setValue(`track_inventory.${index}.opening_stock_per_unit`, e?.target?.value)
                            setValues(vals)
                        }
                    })}
                />
            ),
        },
        {
            title: "Opening Stock Value",
            dataIndex: "opening_stock_value_per_unit",
            key: "opening_stock_value_per_unit",
            width: "30%",
            render: (record, index) => (
                <FormInput
                    placeholder={"0"}
                    control={control}
                    number={true}
                    value={values?.[index]?.opening_stock_value_per_unit}
                    errorMessage={errors?.track_inventory?.[index]?.opening_stock_value_per_unit?.message}
                    {...register(`track_inventory.${index}.opening_stock_value_per_unit`, {
                        onChange: (e) => {
                            let vals = [...values];
                            vals[index].opening_stock_value_per_unit = e?.target?.value
                            setValue(`track_inventory.${index}.opening_stock_value_per_unit`, e?.target?.value)
                            setValues(vals)
                        }
                    })}
                />
            ),
        }
    ];

    useEffect(() => {
        if (itemsData) {
            const updateTaxInventory = () => {
                const updatedTaxInventory = itemsData?.map((item, index) => ({
                    ...item,
                    vendor_id: values[index]?.vendor_id || item.vendor_id,
                    opening_stock_per_unit: values[index]?.opening_stock_per_unit || item.opening_stock_per_unit,
                    opening_stock_value_per_unit: values[index]?.opening_stock_value_per_unit || item.opening_stock_value_per_unit,
                }));
                setValues(updatedTaxInventory);
            };
            updateTaxInventory();
        }
    }, [itemsData])
    return (
        <div>
            <table className="items-table" style={{ width: "90%", marginBottom: "2rem" }}>
                <thead>
                    <tr>
                        {columns.map((ele) => (
                            <th>{ele.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {values?.map((itm, idx) => (
                        <>
                            <tr>
                                {columns.map((ele) => (
                                    <td style={{ width: ele.width || "auto" }}>
                                        {ele.render ? ele.render(itm, idx) : itm[ele.key]}
                                    </td>
                                ))}
                                {values?.length > 1 && (
                                    <img
                                        src={Close}
                                        alt="close"
                                        className="close-icon-table"
                                        width={20}
                                        onClick={() => {
                                            let vals = [...values];
                                            vals.splice(idx, 1);
                                            setValues(vals);
                                        }}
                                    />
                                )}
                            </tr>
                        </>
                    ))}
                </tbody>
            </table>

            {/* <span
                className="link"
                onClick={() => {
                    append({
                        vendor_id: "",
                        opening_stock_per_unit: "",
                        opening_stock_value_per_unit: "",
                    },);
                    setValues((vals) => [
                        ...vals,
                        {
                            vendor_id: "",
                            opening_stock_per_unit: "",
                            opening_stock_value_per_unit: "",
                        },
                    ]);
                }}
            >
                + Add one more
            </span> */}
        </div>
    )
}

export default InventoryItems;