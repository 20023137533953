import { ActionTypesFactory } from "../../utils";

export const CustomerLogin = ActionTypesFactory("Customer", "Login");
export const CustomerLogout = ActionTypesFactory("Customer", "Logout");
export const CustomerProducts = ActionTypesFactory("Customer", "Products");
export const CustomerProduct = ActionTypesFactory("Customer", "Product");
export const CustomerProfile = ActionTypesFactory("Customer", "Profile");
export const CustomerAddToCart = ActionTypesFactory("Customer", "AddToCart");
export const CustomerCart = ActionTypesFactory("Customer", "Cart");
export const CustomerDeleteCart = ActionTypesFactory("Customer", "DeleteCart");
export const CustomerRemoveCartItem = ActionTypesFactory("Customer", "RemoveCartItem");
export const CustomerUpdateItemQuantity = ActionTypesFactory("Customer", "UpdateItemQuantity");
export const CustomerCreateOrder = ActionTypesFactory("Customer", "CreateOrder");
export const CustomerOrders = ActionTypesFactory("Customer", "Orders");
export const CustomerOrderComments = ActionTypesFactory("Customer", "OrderComments");
export const CustomerGetOrderComments = ActionTypesFactory("Customer", "GetOrderComments");
export const CustomerOrdersShow = ActionTypesFactory("Customer", "OrdersShow");
export const CustomerMyOrders = ActionTypesFactory("Customer", "MyOrders");
export const CustomerOrdersUpdate = ActionTypesFactory("Customer", "OrdersUpdate");
export const CustomerOrdersSubmit = ActionTypesFactory("Customer", "OrdersSubmit");
export const CustomerOrdersCancel = ActionTypesFactory("Customer", "OrdersCancel");
export const CustomerOrdersApprove = ActionTypesFactory("Customer", "OrdersApprove");
export const CustomerOrdersReject = ActionTypesFactory("Customer", "OrdersReject");
export const CustomerOrdersUpdateQuantity = ActionTypesFactory("Customer", "OrdersUpdateQuantity");
export const CustomerOrdersDestroy = ActionTypesFactory("Customer", "OrdersDestroy");
export const CustomerShippingAddresses = ActionTypesFactory("Customer", "ShippingAddresses");
export const CustomerBillingAddresses = ActionTypesFactory("Customer", "BillingAddresses");
export const CustomerAddAddresses = ActionTypesFactory("Customer", "CustomerAddAddresses");


// Action creators
export const customer_login = (payload) => ({
  type: CustomerLogin.REQUEST,
  payload,
});

export const order_approve = (payload) => ({
  type: CustomerOrdersApprove.REQUEST,
  payload,
});

export const order_reject = (payload) => ({
  type: CustomerOrdersReject.REQUEST,
  payload,
});

export const customer_logout = (payload) => ({
  type: CustomerLogout.REQUEST,
  payload,
});

export const customer_products = (payload) => ({
  type: CustomerProducts.REQUEST,
  payload,
});

export const customer_product = (payload) => ({
  type: CustomerProduct.REQUEST,
  payload,
});

export const customer_profile = (payload) => ({
  type: CustomerProfile.REQUEST,
  payload,
});

export const customer_add_to_cart = (payload) => ({
  type: CustomerAddToCart.REQUEST,
  payload,
});

export const customer_cart = (payload) => ({
  type: CustomerCart.REQUEST,
  payload,
});

export const customer_delete_cart = (payload) => ({
  type: CustomerDeleteCart.REQUEST,
  payload,
});

export const customer_remove_cart_item = (payload) => ({
  type: CustomerRemoveCartItem.REQUEST,
  payload,
});

export const customer_update_item_quantity = (payload) => ({
  type: CustomerUpdateItemQuantity.REQUEST,
  payload,
});

export const customer_create_order = (payload) => ({
  type: CustomerCreateOrder.REQUEST,
  payload,
});

export const customer_orders = (payload) => ({
  type: CustomerOrders.REQUEST,
  payload,
});

export const customer_order_comments = (payload) => ({
  type: CustomerOrderComments.REQUEST,
  payload,
});

export const get_order_comments = (payload) => ({
  type: CustomerGetOrderComments.REQUEST,
  payload,
});

export const customer_orders_show = (payload) => ({
  type: CustomerOrdersShow.REQUEST,
  payload,
});

export const customer_my_orders = (payload) => ({
  type: CustomerMyOrders.REQUEST,
  payload,
});

export const customer_orders_update = (payload) => ({
  type: CustomerOrdersUpdate.REQUEST,
  payload,
});

export const customer_orders_submit = (payload) => ({
  type: CustomerOrdersSubmit.REQUEST,
  payload,
});

export const customer_orders_cancel = (payload) => ({
  type: CustomerOrdersCancel.REQUEST,
  payload,
});

export const customer_orders_update_quantity = (payload) => ({
  type: CustomerOrdersUpdateQuantity.REQUEST,
  payload,
});

export const customer_orders_destroy = (payload) => ({
  type: CustomerOrdersDestroy.REQUEST,
  payload,
});

export const customer_shipping_addresses = (payload) => ({
  type: CustomerShippingAddresses.REQUEST,
  payload,
});

export const customer_billing_addresses = (payload) => ({
  type: CustomerBillingAddresses.REQUEST,
  payload,
});

export const customer_add_addresses = (payload) => ({
  type: CustomerAddAddresses.REQUEST,
  payload
})
