import { CloseCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Button, Form, Modal, Typography, message } from "antd";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateInviteLink,
  DisconnectCompany,
  GetOneCompany,
  SyncData,
  createInviteLink,
  disconnectCompany,
  getAllOrganisations,
} from "../../../actions/cards/cards";
import { FormInput } from "../../inputs";
import AddOrganisation from "./AddOrganisation";
import ConfigureModal from "./ConfigureModal";
import "./RootFi.css";
import SyncDataModels from "./SyncDataModels";
import useIntegration from "../../../config/useIntegration";
import { apis } from "../../../config/APIs";
import endpointRootiIntegrationApi from "../../../config/AxiosRootiFiIntegrationApi";

function RootFiDetails() {
  const { Title } = Typography;
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recordData = location?.state?.recordData;
  const statusRes = location?.state?.status;
  const companyName = location?.state?.company_name;
  // const newConnect = location?.state?.newConnect;
  const selectedRecord = location?.state?.selectedRecord;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOpenVisible, setIsOpenVisible] = useState(false);
  const [url, setUrl] = useState("");
  const [isDisconnectModalVisible, setIsDisconnectModalVisible] =
    useState(false);
  const [configureModalVisible, setConfigureModalVisible] = useState(false);
  const [syncDataModalVisible, setSyncDataModalVisible] = useState(false);
  const { onCall: createQuickBooks, loading } = useIntegration({
    api: apis.createQuickBooks,
    method: "post",
  });
  const connectInviteLinkResponse = useSelector(
    (state) => state?.cards?.createInvite_Link
  );
  const syncDataResponse = useSelector((state) => state?.cards?.sync_data);
  const disconnectCompanyResponse = useSelector(
    (state) => state?.cards?.disconnect_company
  );

  // console.log('RecordData=', recordData)
  const integration_status = localStorage.getItem("integrationStatus");

  useEffect(() => {
    if (connectInviteLinkResponse?.data?.error === false) {
      reset();
      const inviteLink =
        connectInviteLinkResponse?.data?.data?.data?.invite_link_data
          ?.invite_link_id;

      const RootfiLink = window.RootfiLink;
      if (RootfiLink) {
        RootfiLink.initialize({
          linkToken: inviteLink,
          onSuccess: () => {
            RootfiLink.closeLink();
            message.success(
              <span className="messageText">Account linked Successfully</span>
            );
            // dispatch(getAllOrganisations());
            history.push({
              pathname: "/settings/rootFi-integration",
              state: {
                reloadRootiFiMain: true,
                company_id:
                  connectInviteLinkResponse?.data?.data?.data?.invite_link_data
                    ?.rootfi_company_id,
              },
            });
          },
          onReady: () => {
            RootfiLink.openLink();
          },
          onExit: () => {
            RootfiLink.closeLink();
            history.push({
              pathname: "/settings/rootFi-integration",
              state: {
                reloadRootiFiMain: true,
                company_id:
                  connectInviteLinkResponse?.data?.data?.data?.invite_link_data
                    ?.rootfi_company_id,
              },
            });
          },
        });
      }
      dispatch({ type: CreateInviteLink.RESET });
    } else if (connectInviteLinkResponse?.data?.error === true) {
      message.error(
        <span className="messageText">
          {connectInviteLinkResponse?.data?.message}
        </span>
      );
      dispatch({ type: CreateInviteLink.RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectInviteLinkResponse]);

  useEffect(() => {
    if (syncDataResponse?.data?.error === true) {
      message.error(
        <span className="messageText">{syncDataResponse?.data?.message}</span>
      );
      dispatch({
        type: SyncData.RESET,
      });
    } else if (syncDataResponse?.data?.error === false) {
      message.success(
        <span className="messageText">
          {syncDataResponse?.data?.message || "Synced Successfully"}
        </span>
      );
      setSyncDataModalVisible(false);
      dispatch({
        type: SyncData.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncDataResponse]);

  useEffect(() => {
    if (disconnectCompanyResponse?.data?.error === true) {
      message.error(
        <span className="messageText">
          {disconnectCompanyResponse?.data?.message}
        </span>
      );
      dispatch({
        type: DisconnectCompany.RESET,
      });
    } else if (disconnectCompanyResponse?.data?.error === false) {
      localStorage.removeItem("organisation_connection");
      message.success(
        <span className="messageText">
          {disconnectCompanyResponse?.data?.message}
        </span>
      );
      setIsDisconnectModalVisible(false);
      dispatch({
        type: GetOneCompany.RESET,
      });
      history.push({
        pathname: `/settings/rootFi-integration/${recordData?.id}/connections`,
        state: {
          recordData: recordData,
          status: statusRes,
        },
      });
      dispatch({
        type: DisconnectCompany.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disconnectCompanyResponse]);

  // console.log('status=', statusRes,'selectedRecord=', selectedRecord)

  useEffect(() => {
    setValue("company_name", companyName);
    return () => {
      setValue("company_name", "");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  useEffect(() => {
    // const requestPopupPermission = async () => {
    //   // Check if the Notification API is supported
    //   if ("Notification" in window) {
    //     // Request permission to show notifications
    //     const permission = await Notification.requestPermission();

    //     if (permission === "granted") {
    //       // User granted permission, do nothing
    //       console.log("Pop-up permission granted");
    //     } else if (permission === "denied") {
    //       // User denied permission, show information helper if available
    //       if ("showInformationHelper" in window) {
    //         // setShowPopupHelper(true);
    //       } else {
    //         // Fallback for browsers that don't support showInformationHelper
    //         console.log(
    //           "Please allow pop-ups for an optimal experience on our website."
    //         );
    //       }
    //     }
    //   } else {
    //     // Fallback for browsers that don't support the Notification API
    //     console.log("This browser does not support pop-up permissions.");
    //   }
    // };

    // requestPopupPermission();

    window.onmessage = function (e) {
      if (e && e.data && e.data?.success) {
        history.push("/settings/rootFi-integration");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = () => {
    if (recordData?.id) {
      endpointRootiIntegrationApi
        .get(apis.disconnectQuickbooks, {
          params: {
            status: "DISCONNECTED",
            client_secret: recordData?.client_secret,
            client_id: recordData?.client_id,
            company_id: recordData?.company_id,
          },
        })
        .then((res) => {
          if (res?.data?.status < 400) {
            message.success(
              <span className="messageText">Disconnected Successfully.</span>
            );
            history.push("/settings/rootFi-integration");
          }
        });
    } else {
      dispatch(
        disconnectCompany({
          params: { company_id: selectedRecord?.company_id },
          status: "DISCONNECTED",
          integration_type: recordData?.company_name,
        })
      );
    }
  };
  const handleCancel = () => {
    setIsDisconnectModalVisible(false);
    setIsOpenVisible(false);
  };

  const onSubmit = (data) => {
    if (recordData?.id === 1) {
      if (selectedRecord) {
        // console.log('Selected Record=',selectedRecord)
        const RootfiLink = window.RootfiLink;
        if (RootfiLink) {
          RootfiLink.initialize({
            linkToken: selectedRecord?.invite_link_id,
            onSuccess: () => {
              RootfiLink.closeLink();
              message.success(
                <span className="messageText">
                  Account Reconnected Successfully
                </span>
              );
              // dispatch(getAllOrganisations({params: { company_id: selectedRecord?.company_id,status:'RE_CONNECT' } }));
              history.push({
                pathname: `/settings/rootFi-integration/${recordData?.id}/connections`,
                state: {
                  recordData: recordData,
                  status: integration_status,
                  company_id: selectedRecord?.company_id,
                  companyStatus: "RE_CONNECT",
                },
              });
            },
            onReady: () => {
              RootfiLink.openLink();
            },
            onExit: () => {
              RootfiLink.closeLink();
              history.push({
                pathname: `/settings/rootFi-integration/${recordData?.id}/connections`,
                state: {
                  recordData: recordData,
                  status: integration_status,
                },
              });
            },
          });
        }
      } else {
        let obj = {
          company_name: data?.company_name,
          integrations: [recordData?.company_name],
        };
        dispatch(createInviteLink(obj));
      }
    } else if (recordData?.id === 2) {
      createQuickBooks({ params: data })
        .then((res) => {
          window.open(res?.data, "intergration", "height=600,width=850");
        })
        .catch((err) => {
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        });
    }
  };

  const user_email = localStorage.getItem("user");
  const organisation_name = localStorage.getItem("company_name");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      company_name: "",
    },
    shouldUseNativeValidation: false,
  });

  return (
    <section className="contentWrapper">
      {statusRes === "HEALTHY" ? (
        <div className="flex-col gap1 w80 details mt1">
          <div className="d-flex flex-col">
            <Title level={3} style={{ marginBottom: "0" }}>
              {recordData?.title}
            </Title>
            <div className="d-flex" style={{ alignItems: "flex-start" }}>
              <p style={{ color: "#818181" }}>Connected Organization : </p>
              <h4 style={{ marginLeft: "0.2rem" }}>
                {" "}
                {selectedRecord?.company_name}
              </h4>
            </div>
          </div>
          <p className="mt1">{recordData?.description}</p>
          <div className="user-info p1 gap1">
            <div>
              <div className="grid-container" style={{ gap: "0.5rem" }}>
                <p>Owner Email</p>
                <h5>: {user_email ? user_email : " "}</h5>
                {/* <button className="primaryButton pl1 pr1 w-5"
                  onClick={() => setIsModalVisible(true)}
                  style={{ maxWidth: '6rem' }}>
                  {recordData ? t('update') : t('add')}
                </button> */}
                <p>Organisation</p>
                <h5>: {organisation_name ? organisation_name : ""}</h5>
                <p></p>
              </div>
              <div className="v-center button-container mt1">
                {/* <button onClick={() => { }}>{t('pause')}</button>
                <div className="vertical-line"></div> */}
                <button onClick={() => setIsDisconnectModalVisible(true)}>
                  {t("disconnect")}
                </button>
                <div className="vertical-line"></div>
                {/* <button onClick={() => setConfigureModalVisible(true)}>{t('configure')}</button>
                <div className="vertical-line"></div>*/}
                <button
                  onClick={() =>
                    history.push("/settings/rootFi-integration-history")
                  }
                >
                  {t("history")}
                </button>
                {recordData?.id !== 2 && (
                  <>
                    <div className="vertical-line"></div>
                    <button onClick={() => setSyncDataModalVisible(true)}>
                      {t("sync")}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-col p4 connect">
          <img
            src={recordData?.fullImage}
            alt="Logo"
            style={{ maxWidth: "20rem" }}
          />
          <Title level={2} className="mt1">
            {!!selectedRecord
              ? `Reconnect to your ${recordData?.title}`
              : `Sign In to your ${recordData?.title}`}
          </Title>
          <Form onFinish={handleSubmit(onSubmit)} className="w35">
            {recordData?.formItems ? (
              recordData?.formItems?.map((ele) => (
                <FormInput
                  control={control}
                  {...register(ele.key, {
                    required: {
                      value: true,
                      message: ele.label + " is required",
                    },
                    minLength: {
                      value: 3,
                      message:
                        ele.label + " should be at least 3 characters long",
                    },
                  })}
                  autocomplete="off"
                  placeholder={"Enter " + ele.label}
                  errors={errors}
                  label={ele.label}
                />
              ))
            ) : (
              <FormInput
                control={control}
                {...register("company_name", {
                  required: {
                    value: true,
                    message: "Company Name is required",
                  },
                  minLength: {
                    value: 3,
                    message:
                      "Company Name should be at least 3 characters long",
                  },
                  pattern: {
                    value: /^[A-Za-z\s]+$/,
                    message: "Company Name should only contain letters",
                  },
                })}
                autocomplete="off"
                placeholder="Enter Company Name"
                errors={errors}
                label="Company Name"
              />
            )}
            <button
              type="submit"
              className="primaryButton pt1 pb1 pl2 pr2 mt1 w100"
              disabled={connectInviteLinkResponse?.loading || loading}
            >
              {companyName
                ? `Reconnect to your ${recordData?.title}`
                : `Connect to your ${recordData?.title}`}
            </button>
          </Form>
        </div>
      )}
      {isModalVisible && (
        <AddOrganisation
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          record={recordData ? recordData : ""}
        />
      )}

      {configureModalVisible && (
        <ConfigureModal
          open={configureModalVisible}
          onCancel={() => setConfigureModalVisible(false)}
        />
      )}

      {syncDataModalVisible && (
        <SyncDataModels
          open={syncDataModalVisible}
          onCancel={() => setSyncDataModalVisible(false)}
          integrationType={recordData?.company_name}
          company_id={selectedRecord?.company_id}
        />
      )}

      <Modal
        title="Disconnect"
        className="disconnect-modal"
        width={470}
        open={isDisconnectModalVisible}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={<CloseCircleOutlined style={{ fontSize: "1.5rem" }} />}
      >
        <>
          <p className="modal-text">
            Are you sure you want to disconnect {recordData?.title} and Bluerack?
          </p>
          <div
            className="form-btm mt3"
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              className="pp-main-button cursor"
              onClick={handleConfirm}
              disabled={disconnectCompanyResponse?.loading}
            >
              <span>{t("confirm")}</span>
            </Button>
            <Button
              className="pp-secondary-button cursor"
              style={{ marginLeft: "2rem" }}
              onClick={() => setIsDisconnectModalVisible(false)}
            >
              <span>{t("cancel")}</span>
            </Button>
          </div>
        </>
      </Modal>
      <Modal
        title="Connect"
        className="disconnect-modal right-alinged-modal"
        width={470}
        open={isOpenVisible}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={<CloseCircleOutlined style={{ fontSize: "1.5rem" }} />}
      >
        <iframe
          src={url}
          style={{
            border: "none",
            width: "100%",
            height: 700,
          }}
        />
      </Modal>
    </section>
  );
}

export default RootFiDetails;
