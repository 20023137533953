import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  findLodash,
  getFileExtension,
  hasAccess,
  objectToFormData,
} from "../../../utils";
import "../styles/setting.css";
import { useTranslation } from "react-i18next";
import { InboxOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import {
  companyProfile,
  companyProfileImages,
  companySetting,
  getCompanyCities,
  getCompanyCountries,
  getCompanyTimezones,
  getCompanyStates,
  UpdateCompanyProfile,
  updateCompanyProfile,
  updateCompanyProfilefImages,
} from "../../../actions/companySettings";
import Loader from "../../Loader";
import { countries as country } from "./country";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import { useFormik } from "formik";
import pdf_icon from "../../../assets/icons/pdf_file_icon.png";
import { apis } from "../../../config/APIs";
import useDocumentAxios from "../../../config/useDocument";
import { v4 as uuidv4 } from "uuid";
import { PulseLoader } from "react-spinners";
import useScrollToError from "../../../config/useScrollToError.js";
const { Dragger } = Upload;
// const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const ValidationSchema = yup.object().shape({
  name: yup.string().required("Please provide category name"),
  time_zone_id: yup.string().required("Timezone is required"),
  time_format: yup.string().required("Time format is required"),
  gst_no: yup.string().matches("^[A-Z0-9]{9,20}$", "Enter valid GST No"),
  tan_no: yup.string().matches("^[A-Z0-9]{9,20}$", "Enter valid TAN No"),
  address: yup
    .string()
    .matches("^[A-Za-z0-9$&,:;@#.-/ ]*$", "Enter valid address"),
  // state: yup.string().matches("^[A-Za-z ]*$", "Enter valid State"),
  state: yup.string().required("Enter valid State"),
  city: yup.string().required("Enter valid City"),
  // city: yup.string().matches("^[A-Za-z ]*$", "Enter valid City"),
});

message.config({
  maxCount: 3,
});

const Organisation = ({ hasWriteAccess }) => {
  const { t } = useTranslation();
  const profileUpdateData = useSelector(
    (state) => state?.companySetting?.updateCompanyProfilefImages?.data
  );

  const formik = useFormik({
    initialValues: {
      city: "",
      state: "",
      country: "",
      gst_no: "",
      incorporation: "",
      logo: "",
      name: "",
      address: "",
      office_no: "",
      gst_certificate: "",
      pincode: "",
      primary_contact_name: "",
      primary_email: "",
      primary_phone: "",
      registration_no: "",
      series: "",
      tan_no: "",
      account_ifsc: "",
      account_name: "",
      account_number: "",
      address_proof: "",
      primary_contact_email: "",
      bank_address: "",
      bank_branch: "",
      bank_name: "",
      time_zone_id: null,
      time_format: "12",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      if (uploadData?.logo) {
        values["logo"] = [uploadData?.logo?.attachId];
      }
      if (uploadData?.incorporation) {
        values["incorporation"] = [uploadData?.incorporation?.attachId];
      }
      if (uploadData?.gst_certificate) {
        values["gst_certificate"] = [uploadData?.gst_certificate?.attachId];
      }
      if (companyData.id) {
        values.id = companyData.id;
      }
      dispatch(updateCompanyProfile(values));
    },
  });

  // Use the custom hook
  useScrollToError(
    Object.keys(formik?.errors)[0],
    formik?.errors,
    formik?.submitCount
  );
  const dispatch = useDispatch();
  const [companyData, setCompanyData] = React.useState({});
  const [dataLoading, setDataLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [countryData, setCountryData] = React.useState("");
  const [uploadLogoData, setUploadLogoData] = React.useState({});
  const [onError, setOnError] = React.useState({});
  const [uploadIncorpData, setUploadIncorpData] = React.useState({});
  const [uploadOtherData, setUploadOtherData] = React.useState({});
  const [fileError, setFileError] = React.useState({
    message: "",
    error: false,
  });

  const [output, setOutput] = React.useState({});
  const [uploadData, setUploadData] = React.useState({});
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
    fileType: "",
  });
  const { onCall: DocumentAttID, loading: isUploadLoading } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });
  const { onCall: DocumentDelete } = useDocumentAxios({
    api: apis?.deleteDocument,
    method: "delete",
    baseURL: "document",
  });
  const companySettingResponse = useSelector(
    (state) => state.companySetting.companySetting?.data?.data || {}
  );
  const companyProfileResponse = useSelector(
    (state) => state.companySetting.companyProfile || {}
  );
  const updateCompanyProfileResponse = useSelector(
    (state) => state.companySetting.updateCompanyProfile || {}
  );
  const loaderCheck = useSelector((state) => state.companySetting);
  const countryDetails = useSelector(
    (state) => state?.companySetting?.companyCountries?.data?.data || []
  );
  const tzs = useSelector(
    (state) => state?.companySetting?.companyTzs?.data?.data || []
  );
  // const companyProfileImagesResponse = useSelector(state => state.companySetting.companyProfileImages || {});
  const [postalcode, setPostalCode] = useState("");

  // const { data: { response: { company_setting: { company_id = "", company = {} } = {} } = {} } = {} } = companySettingResponse;
  const { company_id = "" } = ({} = companySettingResponse);
  const { data: { data: company = {} } = {} } = companyProfileResponse;
  const searchRef = useRef();
  const handleSearch = (value) => {
    setPostalCode(value);
    formik.setFieldValue("pincode", value);
    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }
    searchRef.current = setTimeout(() => {
      axios
        .get(
          "https://api.worldpostallocations.com/pincode?postalcode=" +
            value +
            "&countrycode=" +
            formik.values.country +
            ""
        )
        .then((response) => {
          setOutput(
            response?.data?.result?.[0] ? response?.data?.result?.[0] : {}
          );
        });
    }, 500);
  };
  
  React.useEffect(() => {
    Object.values(loaderCheck).forEach((val) => setDataLoading(val.loading));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderCheck]);
  React.useEffect(() => {
    formik?.setFieldValue("state", output?.state);
    formik?.setFieldValue("city", output?.district);
  }, [output]);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleFileChange = async (info, name) => {
    if (info?.file?.status === "remove") {
      await DocumentDelete({
        data: {
          ids: uploadData?.[name]?.attachId,
        },
        params: { record_id: uploadData[info?.file?.uid]?.recordId },
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });
    } else {
      if (uploadData?.[name]?.attachId) {
        await DocumentDelete({
          data: {
            ids: [uploadData?.[name]?.attachId],
          },
          params: { record_id: uploadData[name]?.recordId },
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
      }
      const uniqueNo = uploadData?.[name]
        ? uploadData?.[name]?.recordId
        : uuidv4();
      const formData = new FormData();
      formData.append("files", info?.file);
      formData.append("name", name);
      formData.append("record_type", "Company");
      formData.append("record_id", uniqueNo);
      const attachId = await DocumentAttID({
        data: formData,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });
      if (attachId?.data?.[0]) {
        await getBase64(info?.file, (url) => {
          formik.setFieldValue(`${name}`, attachId?.data[0]);
          switch (name) {
            case "logo":
              setUploadData({
                ...uploadData,
                logo: {
                  attachId: attachId?.data?.[0],
                  recordId: uniqueNo,
                  url: url,
                  fileName: info?.file?.name,
                },
              });
              break;
            case "incorporation":
              setUploadData({
                ...uploadData,
                incorporation: {
                  attachId: attachId?.data?.[0],
                  recordId: uniqueNo,
                  url: url,
                  fileName: info?.file?.name,
                },
              });
              break;
            case "gst_certificate":
              setUploadData({
                ...uploadData,
                gst_certificate: {
                  attachId: attachId?.data?.[0],
                  recordId: uniqueNo,
                  url: url,
                  fileName: info?.file?.name,
                },
              });
              break;
            default:
              break;
          }
        });
      }
    }
  };

  const beforeUploadLogo = (file, fileList) => {
    const fileName = getFileExtension(file?.name)?.toLowerCase();
    if (["jpg", "jpeg", "png"]?.includes(fileName)) {
      return false;
    } else {
      message.error(
        <span className="messageText">{"Please Upload JPG or PNG files"}</span>
      );
      return Upload.LIST_IGNORE;
    }
  };
  const beforeUpload = (file, fileList) => {
    const fileName = getFileExtension(file?.name)?.toLowerCase();
    if (["jpg", "jpeg", "png", "pdf"]?.includes(fileName)) {
      return false;
    } else {
      message.error(
        <span className="messageText">
          {"Please Upload JPG or PNG or PDF files"}
        </span>
      );
      return Upload.LIST_IGNORE;
    }
  };
  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  React.useEffect(() => {
    dispatch(companySetting({}));
    dispatch(getCompanyCountries({ params: { isCsv: true } }));
    dispatch(getCompanyTimezones({}));
    dispatch(
      headerTitles({
        title: t("company_profile"),
        description: t("see_company"),
      })
    );
    // eslint-disable-next-line
    dispatch(companyProfile({}));
  }, []);

  React.useEffect(() => {
    if (company_id) {
      dispatch(companyProfile({ id: company_id }));
      dispatch(getCompanyStates({ params: { country_id: company.country } }));
      dispatch(getCompanyCities({ params: { state_id: company.state } }));
    }
    // eslint-disable-next-line
    // dispatch(companyProfile({}))
  }, []);

  React.useEffect(() => {
    const { data: { status = "", errors = "", data = "" } = {} } =
      updateCompanyProfileResponse;
    if (status) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      message.success(
        <span className="messageText">{t("profile_updated_success")}</span>
      );
      dispatch({
        type: UpdateCompanyProfile.RESET,
      });
      dispatch(
        companyProfile({ id: companySettingResponse?.result?.company_id })
      );
      setEditMode(false);
      setUploadData({});
    } else if (status === false) {
      errors && message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: UpdateCompanyProfile.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanyProfileResponse]);

  React.useEffect(() => {
    const { data: { data: company = {}, status = false } = {} } =
      companyProfileResponse;

    if (status && company) {
      setCompanyData(company);
      formik.setValues({
        city: company.city,
        state: company.state,
        country: company.country,
        gst_no: company.gst_no,
        incorporation: company.incorporation?.id,
        logo: company?.logo?.id,
        name: company.name,
        time_zone_id: company.time_zone_id,
        time_format: company.time_format,
        address: company.address,
        office_no: company.office_no,
        pincode: company.pincode,
        primary_contact_name: company.primary_contact_name,
        primary_email: company.primary_email,
        primary_phone: company.primary_phone,
        registration_no: company.registration_no,
        series: company.series,
        tan_no: company.tan_no,
        account_ifsc: company.account_ifsc,
        account_name: company.account_name,
        account_number: company.account_number,
        address_proof: company.address_proof,
        primary_contact_email: company.primary_contact_email,
        bank_address: company.bank_address,
        bank_branch: company.bank_branch,
        bank_name: company.bank_name,
        gst_certificate: company?.gst_certificate?.id,
      });
      if (company) {
        setUploadData((upData) => ({
          ...upData,
          gst_certificate: {
            attachId: company?.gst_certificate?.id,
            recordId: company?.id,
            url: company?.gst_certificate?.url,
            fileName: company?.gst_certificate?.file_name,
          },
          incorporation: {
            attachId: company?.incorporation?.id,
            recordId: company?.id,
            url: company?.incorporation?.url,
            fileName: company?.incorporation?.file_name,
          },
          logo: {
            attachId: company?.logo?.id,
            recordId: company?.id,
            url: company?.logo?.url,
            fileName: company?.logo?.file_name,
          },
        }));
      }
      setOutput((obj) => ({
        ...obj,
        state: company.state,
        district: company.city,
      }));
      setCountryData(
        country?.find(
          (ele) => ele.value === company.country || ele.code === company.country
        )?.code
      );
      setPostalCode(company?.pincode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfileResponse]);

  const handleCountryData = (value) => {
    setCountryData(value);
    formik.setFieldValue("country", value);
    dispatch(getCompanyStates({ params: { country_id: value } }));

    // if (postalcode)
    //   handleSearchCountry(postalcode, value)
    // dispatch(getCompanyStates({ country: value }));
  };
  return (
    <>
      {isUploadLoading && (
        <div className="text-center loadingDiv">
          <PulseLoader color="#fff" />
        </div>
      )}
      {dataLoading ? <Loader /> : null}

      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
            fileType: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex-center"
          >
            <img
              src={isPreviewOpen?.url}
              alt="preview image"
              style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
            />
          </div>
        )}
      </Modal>
      <div className="contentWrapper">
        <Title level={5}>{t("company_address")}</Title>
        <Divider />
        <Form
          layout="vertical"
          onFinish={formik.handleSubmit}
          className="settings_form"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t("company_name")}>
                <Input
                  placeholder={t("company_name")}
                  disabled={!editMode}
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </Form.Item>
              <Form.Item label={t("company_register_no")}>
                <Input
                  placeholder={t("company_register_no")}
                  disabled={!editMode}
                  name="registration_no"
                  onChange={formik.handleChange}
                  value={formik.values.registration_no}
                />
              </Form.Item>
              <Form.Item label={t("primary_email")}>
                <Input
                  placeholder={t("primary_email")}
                  disabled={!editMode || company.primary_email}
                  name="primary_email"
                  value={formik.values.primary_email}
                  onChange={formik.handleChange}
                />
              </Form.Item>
              <Form.Item label={t("address")}>
                <TextArea
                  value={formik.values.address}
                  disabled={!editMode}
                  name="address"
                  placeholder={t("enter_address")}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={(e) => {
                    // if (!/^[A-Za-z0-9$&,:;@#.-/]*$/.test((e.target.value))) {
                    //   return;
                    // }
                    formik.setFieldValue("address", e.target.value);
                  }}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="errorMsg">{formik.errors.address}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="uploadSection">
              <Text>{t("upload_ur_organisation_logo")}</Text>
              {uploadData?.logo?.attachId === formik.values?.logo &&
              formik.values?.logo ? (
                <div
                  style={{ width: "15rem", height: "10rem" }}
                  onClick={() => {
                    setIsPreviewOpen({
                      open: true,
                      url: `${uploadData?.logo?.url}`,
                      fileType: getFileExtension(uploadData?.logo?.fileName),
                    });
                  }}
                >
                  <img
                    alt="logo_url"
                    src={uploadData?.logo?.url}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              ) : null}
              <Dragger
                fileList={uploadLogoData.selectedFileList}
                beforeUpload={beforeUploadLogo}
                customRequest={dummyRequest}
                showUploadList={false}
                name="logo"
                disabled={!editMode}
                maxCount={1}
                onChange={(evt) => handleFileChange(evt, "logo")}
              >
                <p className="ant-upload-text">
                  <InboxOutlined />
                  {t("drag_png")}
                  <br />
                  {t("click_here_to")}
                </p>
              </Dragger>
              <p className="errorMsg">
                {fileError.error === "logo" && fileError.message}
              </p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("country")}>
                <Select
                  // value={country?.find(ele => ele.code === countryData)?.value}
                  value={formik?.values?.country}
                  placeholder={t("select_country")}
                  options={countryDetails?.map((value) => ({
                    label: value?.name,
                    value: value?.id,
                  }))}
                  disabled={!editMode}
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase?.() ?? "")?.includes(
                      input?.toLowerCase?.()
                    )
                  }
                  showSearch
                  onChange={(val, data) => {
                    handleCountryData(val);
                    formik?.setFieldValue("state", "");
                    formik?.setFieldValue("city", "");
                    formik.setFieldValue("pincode", "");
                  }}
                  className={`${editMode ? null : "county_disable"}`}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Timezone")}>
                <Select
                  // value={country?.find(ele => ele.code === countryData)?.value}
                  value={formik?.values?.time_zone_id}
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase?.() ?? "")?.includes(
                      input?.toLowerCase?.()
                    )
                  }
                  placeholder={t("Select timezone")}
                  options={tzs?.map((value) => ({
                    label: value?.time_zone + " " + value?.offset,
                    value: value?.id,
                  }))}
                  disabled={!editMode}
                  showSearch
                  onChange={(val, data) => {
                    formik?.setFieldValue("time_zone_id", val);
                  }}
                  className={`${editMode ? null : "county_disable"}`}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Time Format")}>
                <Select
                  // value={country?.find(ele => ele.code === countryData)?.value}
                  value={formik?.values?.time_format}
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase?.() ?? "")?.includes(
                      input?.toLowerCase?.()
                    )
                  }
                  placeholder={t("Select time format")}
                  options={[
                    {
                      label: "12-hr format",
                      value: "12",
                    },
                    {
                      label: "24-hr format",
                      value: "24",
                    },
                  ]}
                  disabled={!editMode}
                  showSearch
                  onChange={(val, data) => {
                    formik?.setFieldValue("time_format", val);
                  }}
                  className={`${editMode ? null : "county_disable"}`}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("pincode")}>
                <Input
                  name="pincode"
                  placeholder={t("pincode")}
                  className="ml1 searchBox"
                  disabled={!editMode}
                  value={formik.values.pincode}
                  onChange={(e) => {
                    if (Number.isNaN(Number(e.target.value))) {
                      return;
                    }
                    handleSearch(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={t("state")}>
                <Input
                  placeholder={t("state")}
                  disabled={!editMode}
                  name="state"
                  value={formik.values.state}
                  onChange={(e) => {
                    formik.setFieldValue("state", e.target.value);
                  }}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="errorMsg">{formik.errors.state}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("city")}>
                <Input
                  placeholder={t("city")}
                  disabled={!editMode}
                  name="city"
                  value={formik.values.city}
                  onChange={(e) => {
                    formik.setFieldValue("city", e.target.value);
                  }}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="errorMsg">{formik.errors.city}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("office_number")}>
                <Input
                  placeholder={t('office_number"')}
                  name="office_no"
                  disabled={!editMode}
                  value={formik.values.office_no}
                  onChange={(e) => {
                    if (Number.isNaN(Number(e.target.value))) {
                      return;
                    }
                    formik.setFieldValue("office_no", e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("gstin_no")}>
                <Input
                  placeholder={t("gstin_no")}
                  disabled={!editMode}
                  name="gst_no"
                  value={formik.values.gst_no}
                  onChange={(e) => {
                    if (!/^[A-Za-z0-9]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue(
                      "gst_no",
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.gst_no && formik.errors.gst_no && (
                  <div className="errorMsg">{formik.errors.gst_no}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("tan_no")}>
                <Input
                  placeholder={t("tan_no")}
                  disabled={!editMode}
                  value={formik.values.tan_no}
                  onChange={(e) => {
                    if (!/^[A-Za-z0-9]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue(
                      "tan_no",
                      e.target.value?.toUpperCase()
                    );
                  }}
                  name="tan_no"
                />
                {formik.touched.tan_no && formik.errors.tan_no && (
                  <div className="errorMsg">{formik.errors.tan_no}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Text>{t("upload_company_documents")}</Text>
          <Row className="mt1">
            <Col span={12} className="uploadSectionSmall">
              <Text>{t("certificate_incorporation")}</Text>
              {uploadData?.incorporation?.attachId ===
                formik.values?.incorporation &&
              ["pdf"]?.includes(
                getFileExtension(uploadData?.incorporation?.fileName)
              ) ? (
                <>
                  {/* <a href={uploadData?.incorporation?.url} target="_blank"> */}
                  <div
                    style={{ width: "6rem", margin: "auto" }}
                    onClick={() => {
                      setIsPreviewOpen({
                        open: true,
                        url: `${uploadData?.incorporation?.url}`,
                        fileType: "pdf",
                      });
                    }}
                  >
                    <img
                      alt="incorporation_url"
                      src={pdf_icon}
                      style={{ width: "100%" }}
                    />
                  </div>
                  {/* <h4>{uploadData?.incorporation?.fileName}</h4> */}
                  {/* </a> */}
                </>
              ) : uploadData?.incorporation?.attachId ===
                  formik.values?.incorporation &&
                formik.values?.incorporation ? (
                <div
                  style={{ width: "15rem", height: "10rem" }}
                  onClick={() => {
                    setIsPreviewOpen({
                      open: true,
                      url: `${uploadData?.incorporation?.url}`,
                      fileType: getFileExtension(
                        uploadData?.incorporation?.fileName
                      ),
                    });
                  }}
                >
                  <img
                    alt="incorporation_url"
                    src={uploadData?.incorporation?.url}
                    className="w100 h100"
                  />
                </div>
              ) : null}

              <Dragger
                fileList={uploadIncorpData.selectedFileList}
                beforeUpload={beforeUpload}
                customRequest={dummyRequest}
                showUploadList={false}
                onChange={(evt) => handleFileChange(evt, "incorporation")}
                name="incorporation"
                maxCount={1}
                disabled={!editMode}
              >
                <p className="ant-upload-text">
                  <InboxOutlined />
                  {t("drag_upload")}
                  <br />
                  {t("click_here_to")}
                </p>
              </Dragger>
              <p className="errorMsg">
                {fileError.error === "incorporation" && fileError.message}
              </p>
            </Col>
            <Col span={12} className="uploadSectionSmall">
              <Text>
                {t("other_documents")} ({t("gst_tan")})
              </Text>
              {uploadData?.gst_certificate?.attachId ===
                formik.values?.gst_certificate &&
              ["pdf"]?.includes(
                getFileExtension(uploadData?.gst_certificate?.fileName)
              ) ? (
                <>
                  {/* <a href={uploadData?.other?.url} target="_blank"> */}
                  <div
                    style={{ width: "6rem", margin: "auto" }}
                    onClick={() => {
                      setIsPreviewOpen({
                        open: true,
                        url: `${uploadData?.gst_certificate?.url}`,
                        fileType: "pdf",
                      });
                    }}
                  >
                    <img
                      alt="other_url"
                      src={pdf_icon}
                      style={{ width: "100%" }}
                    />
                  </div>
                  {/* <h4>{uploadData?.other?.fileName}</h4> */}
                  {/* </a> */}
                </>
              ) : uploadData?.gst_certificate?.attachId ===
                  formik.values?.gst_certificate &&
                formik.values?.gst_certificate ? (
                <div
                  style={{ width: "15rem", height: "10rem" }}
                  onClick={() => {
                    setIsPreviewOpen({
                      open: true,
                      url: `${uploadData?.gst_certificate?.url}`,
                      fileType: getFileExtension(
                        uploadData?.gst_certificate?.fileName
                      ),
                    });
                  }}
                >
                  <img
                    alt="other_url"
                    src={uploadData?.gst_certificate?.url}
                    className="w100 h100"
                  />
                </div>
              ) : null}
              <Dragger
                name="gst_certificate"
                fileList={uploadOtherData.selectedFileList}
                beforeUpload={beforeUpload}
                customRequest={dummyRequest}
                onChange={(evt) => handleFileChange(evt, "gst_certificate")}
                maxCount={1}
                showUploadList={false}
                disabled={!editMode}
              >
                <p className="ant-upload-text">
                  <InboxOutlined />
                  {t("drag_upload")}
                  <br />
                  {t("click_here_to")}
                </p>
              </Dragger>
              <p className="errorMsg">
                {fileError.error === "gst_certificate" && fileError.message}
              </p>
            </Col>
          </Row>
          <Title level={5} className="mt2">
            {t("primary_contact")}
          </Title>
          <Divider />
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item label={t("primary_contact_name")}>
                <Input
                  placeholder={t("primary_contact_name")}
                  disabled={!editMode || company.primary_contact_name}
                  name="primary_contact_name"
                  value={formik.values.primary_contact_name}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label={t("primary_contact_email_Address")}>
                <Input
                  placeholder={t("primary_contact_email_Address")}
                  disabled={!editMode || company.primary_contact_email}
                  name="primary_contact_email"
                  value={formik.values.primary_contact_email}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label={t("primary_contact_number")}>
                <Input
                  placeholder={t("primary_contact_number")}
                  disabled={!editMode || company.primary_phone}
                  name="primary_phone"
                  value={formik.values.primary_phone}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {!editMode && (
            <Button
              disabled={
                !hasWriteAccess ||
                !JSON.parse(localStorage.getItem("is_kyb_completed"))
              }
              className="formButton mt1 primaryButton"
              onClick={() => setEditMode(true)}
            >
              {t("edit")}
            </Button>
          )}
          {editMode && (
            <>
              <Button
                key="1"
                htmlType="submit"
                className="formButton mt1 primaryButton"
                disabled={updateCompanyProfileResponse?.loading}
              >
                {t("save")}
              </Button>
              <Button
                key="2"
                className="cancelButton ml1 mt1"
                onClick={() => {
                  setEditMode(false);
                  if (company)
                    formik.setValues({
                      city: company.city,
                      state: company.state,
                      country: company.country,
                      gst_no: company.gst_no,
                      incorporation: company.incorporation?.id,
                      logo: company.logo?.id,
                      name: company.name,
                      address: company.address,
                      office_no: company.office_no,
                      pincode: company.pincode,
                      primary_contact_name: company.primary_contact_name,
                      primary_email: company.primary_email,
                      primary_phone: company.primary_phone,
                      registration_no: company.registration_no,
                      series: company.series,
                      tan_no: company.tan_no,
                      account_ifsc: company.account_ifsc,
                      account_name: company.account_name,
                      account_number: company.account_number,
                      address_proof: company.address_proof,
                      bank_address: company.bank_address,
                      bank_branch: company.bank_branch,
                      bank_name: company.bank_name,
                      primary_contact_email: company.primary_contact_email,
                      gst_certificate: company?.gst_certificate?.id,
                    });
                }}
              >
                {t("cancel")}
              </Button>
            </>
          )}
        </Form>
      </div>
    </>
  );
};

export default Organisation;
