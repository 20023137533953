import * as React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Typography } from "antd";
import Close from "../assets/icons/close.png";

const { Title } = Typography;
const renderNavItem = (navigation) => {
  const items = [];
  for (const key of Object.keys(navigation)) {
    items.push(
      navigation[key] ? (
        <Breadcrumb.Item key={key}>
          <Link key={key} to={navigation[key]}>
            {key}
          </Link>
        </Breadcrumb.Item>
      ) : (
        <span style={{color:'#000' , opacity:0.5}} key={key}>{key}</span>
      )
    );
  }
  return items;
};

const PageHeader = (props) => {
  const { title, navigation } = props;
  return (
    <div className="pageHeader">
      <div>
        <Title level={3}>{title}</Title>
        {navigation && (
          <Breadcrumb separator=".">{renderNavItem(navigation)}</Breadcrumb>
        )}
      </div>
      {props?.isClose && (
        <span className="close cursor" onClick={props.onCloseClick}>
          <img src={Close} width={20} alt={"close"} />
        </span>
      )}
    </div>
  );
};

export default PageHeader;
