import React, { useEffect, useMemo, useState } from "react";
import TableHeader from "../Sales/TableHeader";
import TableComponent from "../Common/TableComp/TableComp";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { inventoryItemsGet } from "../../actions/inventory/inventory";
import { amountFormat } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";

const InventoryTable = ({
  isItems,
  isINReport,
  isINLogs,
  hasWriteAccess = true,
}) => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [moduleType, setModuleType] = useState({});
  const tableData = useSelector((state) => state?.inventory?.itemsGet?.data);
  useEffect(() => {
    let title = "";
    let description = "";
    let type = "";
    if (isItems) {
      title = t("Items");
      description = t(
        "Items are tangible objects or products involved in the sales and purchase transactions of a business."
      );
      type = "items";
      setModuleType((pre) => {
        return {
          ...pre,
          type: "items",
        };
      });
    } else if (isINReport) {
      title = t("Inventory Report");
      description = t(
        "Inventory report that provides detailed information about on-hand, incoming, and outgoing stock quantities"
      );
      type = "report";
      setModuleType((pre) => {
        return {
          ...pre,
          type: "report",
        };
      });
    } else if (isINLogs) {
      title = t("Inventory Log");
      description = t(
        "Inventory log records the movement of every individual item"
      );
      type = "log";
      setModuleType((pre) => {
        return {
          ...pre,
          type: "log",
        };
      });
    }
    dispatch(
      headerTitles({
        title: title,
        description: description,
      })
    );
    dispatch(inventoryItemsGet({ type }));
  }, [isItems, isINReport, isINLogs]);
  const columns = useMemo(() => {
    let baseColumns = [
      {
        title: t("Items Name"),
        dataIndex: "item_name",
        key: "item_name",
        width: 400,
      },
      // {
      //     title: t("Quantity"),
      //     dataIndex: "quantity",
      //     key: "quantity",
      // },
      {
        title: t("SKU"),
        dataIndex: "sku",
        key: "sku",
      },
      {
        title: t("Stock On Hand"),
        dataIndex: "stock_on_hand",
        key: "stock_on_hand",
      },
      {
        title: t("HSN/SAC"),
        dataIndex: "hsn_code",
        key: "hsn_code",
      },
      {
        title: t("Unit Price"),
        dataIndex: "taxInventory",
        key: "taxInventory",
        render: (text) => (
          <div>
            <BaseCurrency />
            {amountFormat(text?.opening_stock_value_per_unit)}
          </div>
        ),
      },
      {
        title: t("Sales Rate"),
        dataIndex: "taxSalePurchaseInfo",
        key: "taxSalePurchaseInfo",
        render: (text) => (
          <div>
            {text?.[0]?.currency_info?.symbol ?? <BaseCurrency />}{" "}
            {amountFormat(text?.[0]?.price)}
          </div>
        ),
      },
      {
        title: t("Purchase Rate"),
        dataIndex: "taxSalePurchaseInfo",
        key: "taxSalePurchaseInfo",
        render: (text) => (
          <div>
            {text?.[1]?.currency_info?.symbol ?? <BaseCurrency />}{" "}
            {amountFormat(text?.[1]?.price)}
          </div>
        ),
      },
    ];
    if (isINReport) {
      return [
        {
          title: t("Product"),
          dataIndex: "item_name",
          key: "item_name",
        },
        {
          title: t("On Hand"),
          dataIndex: "on_hand",
          key: "on_hand",
        },
        {
          title: t("Opening Stock"),
          dataIndex: "opening_stock",
          key: "opening_stock",
        },
        {
          title: t("Incoming"),
          dataIndex: "incoming",
          key: "incoming",
        },
        {
          title: t("Outgoing"),
          dataIndex: "outgoing",
          key: "outgoing",
        },
      ];
    } else if (isINLogs) {
      return [
        {
          title: t("Product"),
          dataIndex: "item_name",
          key: "item_name",
        },
        {
          title: t("From"),
          dataIndex: "from_source",
          key: "from_source",
        },
        {
          title: t("To"),
          dataIndex: "to_source",
          key: "to_source",
        },
        {
          title: t("Quantity"),
          dataIndex: "item_quantity",
          key: "item_quantity",
        },
      ];
    }
    return baseColumns;
  }, [isItems, isINReport, isINLogs]);
  const filtersOptions = [];
  const sortOptions = [
    {
      label: isINReport || isINLogs ? t("Product") : t("Item Name"),
      filter: "item_name",
    },
  ];
  const menu = (
    <Menu onClick={() => {}}>
      {/* <Menu.Item
                    key={ele.key}
                    icon={ele.icon}
                    className="flex-around"
                    disabled={!hasWriteAccess}
                >
                    {ele.label}
                </Menu.Item> */}
    </Menu>
  );

  const handleFilter = () => {};
  const handleSearch = (searchKey) => {
    dispatch(
      inventoryItemsGet({
        ...filters,
        type: moduleType?.type,
        search_key: searchKey,
        params: {
          page_number: 1,
        },
      })
    );

    setPage(1);
    setFilters({
      ...filters,
      search_key: searchKey,
    });
  };
  const handleSort = (value) => {
    dispatch(
      inventoryItemsGet({
        ...filters,
        type: moduleType?.type,
        sort: {
          sort_key: value,
          sort_order: value?.includes("quantity") ? "-1" : "1",
        },
        params: {
          page_number: 1,
        },
      })
    );
    setFilters({
      ...filters,
      sort: {
        sort_key: value,
        sort_order: value?.includes("quantity") ? "-1" : "1",
      },
    });
    setPage(1);
  };
  const handleButtonClick = (event, record) => {
    history.push("/inventory/items/create");
  };
  const handleRowClick = (event, record) => {
    if (isItems) {
      history.push("/inventory/items/create", {
        record,
      });
    }
  };
  return (
    <div>
      <TableHeader
        noDD={true}
        menu={menu}
        filterMenu={filtersOptions}
        handleFilter={handleFilter}
        handleAddButton={handleButtonClick}
        noAddNewBtn={isItems ? false : true}
        sortOptions={
          isINLogs
            ? [
                ...sortOptions,
                {
                  label: t("Quantity"),
                  filter: "item_quantity",
                },
              ]
            : sortOptions
        }
        onSearch={handleSearch}
        placeholder={"Search"}
        hasWriteAccess={hasWriteAccess}
        handleSort={handleSort}
        noFilter={true}
      />

      <div style={{ paddingTop: "15px" }}>
        <TableComponent
          rowKey="id"
          // scroll={{ x: 1600 }}
          // scroll={isItems ? { x: 1200 } : undefined}
          columns={columns}
          data={tableData?.data || []}
          hasCheckbox={false}
          handleRowClick={handleRowClick}
          pagination={{
            total: tableData?.total_count,
            current: page,
            onChange: (pagee) => {
              let type = "";
              if (isItems) {
                type = "items";
              } else if (isINReport) {
                type = "report";
              } else if (isINLogs) {
                type = "log";
              }
              dispatch(
                inventoryItemsGet({
                  type,
                  params: {
                    page_number: pagee,
                  },
                  ...filters,
                })
              );
              setPage(pagee);
            },
          }}
        />
      </div>
    </div>
  );
};

export default InventoryTable;
