import { takeLatest } from 'redux-saga/effects';
import { apis } from '../../config/APIs';
import { sagaFunctionsOcr } from '../../utils';
import {
  OcrDocumentDelete,
  OcrDocuments
} from './ocr';

function get_ocr_docs() {
  return sagaFunctionsOcr(OcrDocuments, 'get', apis.ocrDocuments + `/${localStorage.getItem("company_id")}`)();
}

function get_ocr_doc_delete(req) {
  return sagaFunctionsOcr(OcrDocumentDelete, 'delete', apis.ocrDocumentsDelete + `/${req.payload.id}`)();
}

export function* ocrWatcher() {
  yield takeLatest(OcrDocuments.REQUEST, get_ocr_docs);
  yield takeLatest(OcrDocumentDelete.REQUEST, get_ocr_doc_delete);
}
