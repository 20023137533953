import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { objectToFormData } from "../../utils";
import {
  Button, Form,
  Input, Modal,
  Select,
  Typography
} from "antd";
import { cards } from "../../actions/cards/cards";
import AddUser from "../../assets/icons/add_user.png";
import Table from "../Common/Table/Table";
import PageHeader from "../PageHeader";

import { useHistory } from "react-router-dom";
import { status } from "./Card";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CardInventory = ({ hasWriteAccess }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState("center");
  const [selectedId, setSelectedId] = React.useState("");
  const cardsResponse = useSelector((state) => state.cards.cards || {});
  const { t } = useTranslation()
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: { response: { cards: cardsList = [] } = {} } = {} } =
    cardsResponse;

  const columns = [
    {
      title: t('card_ref_no'),
      dataIndex: "card_reference_no",
      key: "card_reference_no",
      width: 150,
    },
    // {
    //   title: "Card Number",
    //   dataIndex: "card_number",
    //   key: "card_number",
    //   width: 150,
    //   render: (text, record) => (
    //     <div>
    //       {record?.data?.maskCardNumber?.slice(
    //         record.data.maskCardNumber.length - 8
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Employee ID",
    //   dataIndex: "employee_id",
    //   key: "employee_id",
    //   width: 150,
    //   render: (text, record) => <div>{record.employee_details?.employee_id}</div>,
    // },
    {
      title: t('employee_name'),
      dataIndex: "employee_details",
      key: "employee_details",
      width: 300,
      render: (text, record) => <div>{record.employee_details?.name}</div>,
    },
    {
      title: t('employee_mobile'),
      dataIndex: "emp_mobile",
      key: "emp_mobile",
      width: 250,
      render: (text, record) => <div>{record.employee_details?.mobile}</div>,
    },
    {
      title: t('company_name'),
      dataIndex: "company_name",
      key: "company_name",
      width: 200,
    },
    // {
    //   title: "Email",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 200,
    //   render: (text, record) => <div>{record.employee_details?.email}</div>,
    // },
    // {
    //   title: "Total Balance",
    //   dataIndex: "balance",
    //   key: "balance",
    //   width: 150,
    // },
    // {
    //   title: "Issued On",
    //   dataIndex: "card_activated_on",
    //   key: "card_activated_on",
    //   width: 150,
    //   // render: (text) => <div>9 Sep'18</div>,
    // },
    {
      title: t('lock_status'),
      dataIndex: "status",
      key: "status",
      width: 200,
    },
    {
      title: t('kyc_status'),
      dataIndex: "kyc_flag",
      key: "kyc_flag",
      width: 150,
      render: (text) => (
        <div className={`status_text_${status[text]}`}>{text}</div>
      ),
    },
    {
      title: t('action'),
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <button
          className="arrowBackground"
          onClick={() => {
            history.push("/setting/users", {
              openAddNew: true,
              user: record,
            });
          }}
          disabled={!hasWriteAccess}
          style={{ padding: 5 }}
        >
          <img src={AddUser} alt="text" width={16} height={14} />
        </button>
      ),
    },
  ];

  React.useEffect(() => {
    dispatch(cards({ non_linked: true, source: "admin" }));
    dispatch(headerTitles({
      title: 'inventory',
      description: ""
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleMenuClick(e) {
    showModal();
  }

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <Typography.Title level={4}>
            {selectedId ? t('edit_fields') : t('assign_cards')} &nbsp;
            <span className="text-small">{t('select_user_assign')}</span>
          </Typography.Title>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={
          modalType === "center"
            ? "center-aligned-modal"
            : "right-alinged-modal-small"
        }
      >
        <Form
          layout="vertical"
          // onFinish={formik.handleSubmit}
          className="settings_form"
        >
          <Form.Item label="From Branch">
            <Select
              style={{ width: 200 }}
              placeholder="Select Country"
            // onChange={(val) => handleCountryData(val)}
            // value={countryData}
            >
              <Option>Bluerack (Master Org)</Option>
            </Select>
          </Form.Item>
          <Form.Item label="To Branch">
            <Select
              style={{ width: 200 }}
              placeholder="Select Country"
            // onChange={(val) => handleCountryData(val)}
            // value={countryData}
            >
              <Option>Bluerack Solutions</Option>
              <Option>Bluerack Info Ltd</Option>
            </Select>
          </Form.Item>
          <Form.Item label={t('no_of_cards')}>
            <Input placeholder={t('no_of_cards')} />
          </Form.Item>
          <Form.Item label={t('available_cards')} />
          <Button
            key="1"
            htmlType="submit"
            className="formButton mt1 primaryButton"
          >

          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
          // onClick={() => props.handleCancel()}
          >
            {t('cancel')}
          </Button>
        </Form>
      </Modal>

      <Table
        rowKey="id"
        // rowSelection={{
        //   type: 'checkbox',
        //   // ...rowSelection,
        // }}
        placeholder={t('search_by_ref')}

        handleSearch={(value) => {
          dispatch(cards({ non_linked: true, search_key: value, source: "admin" }));
        }}
        className="tableStyled"
        columns={columns}
        data={cardsList}
        isTopFilter={true}
        filter={true}
        isMore={true}
        pagination={{
          total: cardsResponse?.data?.total_records || 0,
          onChange: (pagee) =>
            dispatch(cards({ non_linked: true, page: pagee, source: "admin" })),
        }}
        scroll={{ x: 970 }}
      />
    </>
  );
};

export default CardInventory;
