import { DatePicker, Input, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import remove from "../../assets/icons/remove.png";
import InputSelect from "./InputSelect";

const keys = {
  advance_id: "advance_ids",
  reference_number: "reference_numbers",
  employee_id: "employee_ids",
  employee_name: "employee_name",
  report_id: "report_ids",
  report_name: "report_name",
  approval_status: "status",
  vendor_name: "vendor_name",
  title: "titles",
};

const FilterInputs = ({
  filters,
  setFilterObj,
  filtersObj,
  ele,
  remove: rmFunc,
  customized_data,
  index,
  isSalesPurchases,
  isAll,
  setIsAll,
  setInputError,
  selectFilterObj,
  setSelectFilterObj,
  numberSelectFilterObj,
  setNumberSelectFilterObj,
}) => {
  const handleInputChange = (error) => {
    setInputError(error);
  };

  const { t } = useTranslation();

  const handleDateChange = (type, date) => {
    let obj = { [ele]: { [type]: date } };
    console.log("date", filtersObj, obj);
    if (filtersObj[ele]) {
      obj = { [ele]: { ...filtersObj[ele], ...obj[ele] } };
    }
    setFilterObj((fils) => ({ ...fils, ...obj }));
    handleInputChange("");
  };

  const handleStringChange = (vals) => {
    ele = isSalesPurchases && ele === "status" ? "invoice_status" : ele;
    if (Array.isArray(vals)) {
      setFilterObj((fils) => ({ ...fils, [ele]: vals }));
    } else {
      setFilterObj((fils) => ({ ...fils, [ele]: [vals] }));
    }
  };

  const handleNumberChange = (type, value, key) => {
    const inputValue = value ? Number(value) : "";
    const minValue = Number(type === "min" ? value : filtersObj[ele]?.min);
    const maxValue = Number(type === "max" ? value : filtersObj[ele]?.max);
    if (Number.isNaN(inputValue) && !inputValue) {
      return;
    }
    if (type !== "amount") {
      setFilterObj((fils) => ({
        ...fils,
        [ele]: { ...fils[ele], [type]: inputValue },
      }));
      if (minValue > maxValue) {
        setInputError(
          `${t("Min Amount should not be")} ${t("greater")} ${t("than")} ${t(
            "the maximum amount"
          )}`
        );
      } else if (maxValue < minValue) {
        setInputError(
          `${t("Max Amount should not be")} ${t("less")} ${t("than")} ${t(
            "the minimum amount"
          )}`
        );
      } else setInputError("");
      return;
    } else if (type === "amount") {
      setFilterObj((fils) => ({ ...fils, [ele]: { [key]: inputValue } }));
      setInputError("");
      return;
    }
  };

  const handleSelectChange = (value) => {
    ele = isSalesPurchases && ele === "status" ? "invoice_status" : ele;
    const selectedValue = value ? [value] : [];
    setFilterObj((fils) => ({ ...fils, [ele]: selectedValue }));
  };

  const handleRemove = (e) => {
    e.stopPropagation()
    let filtes = filters;
    delete filtes.before_date;
    delete filtes.after_date;
    delete filtes.end_date;
    delete filtes.start_date;
    delete filtes[ele];
    delete filtersObj[ele];
    setFilterObj({ ...filtersObj });
    setSelectFilterObj((fils) => {
      const updatedFilters = { ...fils };
      delete updatedFilters[ele];
      return updatedFilters;
    });
    rmFunc(index);
  };
  console.log(selectFilterObj);
  const getComponent = (type, ele) => {
    switch (type) {
      case "date":
        return (
          <>
            <Select
              value={selectFilterObj[ele] || ""}
              className="w25"
              options={[
                { label: t("date_range"), value: "dr" },
                { label: t("end_date"), value: "before" },
                { label: t("start_date"), value: "after" },
              ]}
              onChange={(e) => {
                setSelectFilterObj((fils) => ({ ...fils, [ele]: e }));
                setFilterObj((fils) => ({ ...fils, [ele]: {} }));
              }}
            />
            {selectFilterObj[ele] === "dr" ? (
              <>
                <DatePicker
                  value={
                    filtersObj[ele]?.start_date
                      ? moment(filtersObj[ele]?.start_date)
                      : null
                  }
                  className="w25"
                  type={"date"}
                  onChange={(e) => handleDateChange("start_date", e)}
                />
                <DatePicker
                  value={
                    filtersObj[ele]?.end_date
                      ? moment(filtersObj[ele]?.end_date)
                      : null
                  }
                  className="w25"
                  type={"date"}
                  disabledDate={(d) =>
                    d &&
                    new Date(d.format("YYYY-MM-DD")).getTime() <
                      new Date(
                        moment(
                          filtersObj[ele]?.start_date,
                          "DD/MM/YYYY"
                        ).format("YYYY-MM-DD")
                      ).getTime()
                  }
                  onChange={(e) => handleDateChange("end_date", e)}
                />
              </>
            ) : (
              <DatePicker
                value={
                  filtersObj[ele]?.end_date || filtersObj[ele]?.start_date
                    ? selectFilterObj[ele] === "before"
                      ? moment(filtersObj[ele]?.end_date)
                      : moment(filtersObj[ele]?.start_date)
                    : null
                }
                className="w50"
                onChange={(e) =>
                  handleDateChange(
                    selectFilterObj[ele] === "before"
                      ? "end_date"
                      : "start_date",
                    e
                  )
                }
              />
            )}
            <img
              className="ml1 cursor"
              src={remove}
              alt="remove"
              height={20}
              onClick={handleRemove}
            />
          </>
        );

      case "string":
        return (
          <>
            <InputSelect
              options={customized_data[keys[ele] ? keys[ele] : ele]?.map(
                (ele) => ({
                  label: ele
                    ?.replace("_", " ")
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
                  value: ele,
                })
              )}
              isAll={isAll}
              setIsAll={setIsAll}
              isSalesPurchases={isSalesPurchases}
              isFilter={true}
              fil_key={ele}
              values={
                filtersObj[
                  isSalesPurchases && ele === "status" ? "invoice_status" : ele
                ]
                  ? filtersObj[
                      isSalesPurchases && ele === "status"
                        ? "invoice_status"
                        : ele
                    ] ||
                    filtersObj[
                      isSalesPurchases && ele === "status"
                        ? "invoice_status"
                        : ele
                    ]?.[0]
                  : []
              }
              onChange={handleStringChange}
            />
            <img
              className="ml1 cursor"
              src={remove}
              alt="remove"
              height="20"
              onClick={handleRemove}
            />
          </>
        );

      case "number":
        return (
          <>
            <Select
              value={numberSelectFilterObj[ele] || ""}
              className="w25"
              options={[
                { label: t("range"), value: "range" },
                { label: t("is_lessthan"), value: "before" },
                { label: t("is_greaterthan"), value: "after" },
              ]}
              onChange={(e) =>
                setNumberSelectFilterObj((fils) => ({ ...fils, [ele]: e }))
              }
            />
            {numberSelectFilterObj[ele] === "range" ? (
              <>
                <Input
                  className="w25"
                  pattern="[0-9]*"
                  onChange={(e) => handleNumberChange("min", e.target.value)}
                  placeholder={t("min_amount")}
                  value={filtersObj[ele] ? filtersObj[ele]?.min : ""}
                />
                <Input
                  className="w25"
                  pattern="[0-9]*"
                  onChange={(e) => handleNumberChange("max", e.target.value)}
                  placeholder={t("max_amount")}
                  value={filtersObj[ele] ? filtersObj[ele]?.max : ""}
                />
              </>
            ) : (
              <Input
                className="w50"
                pattern="[0-9]*"
                onChange={(e) =>
                  handleNumberChange(
                    "amount",
                    e.target.value,
                    numberSelectFilterObj[ele] === "before" ? "max" : "min"
                  )
                }
                value={
                  filtersObj[ele]
                    ? filtersObj[ele][
                        numberSelectFilterObj[ele] === "before" ? "max" : "min"
                      ]
                    : ""
                }
              />
            )}
            <img
              className="ml1 cursor"
              src={remove}
              alt="remove"
              height="20"
              onClick={handleRemove}
            />
          </>
        );

      case "select":
        return (
          <>
            <Select
              className="w75"
              options={customized_data[keys[ele] ? keys[ele] : ele]?.map(
                (ele) => ({
                  label: ele
                    ?.replace("_", " ")
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
                  value: ele,
                })
              )}
              onChange={(e) => handleSelectChange(e)}
              value={
                isSalesPurchases && ele === "status"
                  ? filtersObj["invoice_status"]
                  : filtersObj[ele]
                  ? filtersObj[ele]
                  : []
              }
            />
            <img
              className="ml1 cursor"
              src={remove}
              alt="remove"
              height="20"
              onClick={handleRemove}
            />
          </>
        );

      default:
        return null;
    }
  };

  return <>{getComponent(filters[ele], ele)}</>;
};

export default FilterInputs;
