import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
// import { objectToFormData } from "../../utils";
import card_chip from "../../assets/icons/card_chip.png";
import card_name from "../../assets/icons/card_name.png";
import aadhar from "../../assets/icons/aadhar.svg";
import upload_file from "../../assets/icons/upload_file.svg";
import kyc from "../../assets/icons/kyc.svg";
import card_wifi from "../../assets/icons/card_wifi.png";
import Close from "../../assets/icons/close.png";
import InvoiceIcon from "../../assets/icons/invoice.svg";
import BaseCurrency from "../Common/BaseCurrency";
import { useTranslation } from "react-i18next";
import success from "../../assets/images/succesasset.png";
import card_activate from "../../assets/images/card_activate.png";

import {
  ActivateCard,
  activate_card,
  Block,
  block_card,
  CardLimitEnable,
  CardsDetails,
  cardsDetails,
  get_card_limit,
  Replacement,
  replacement,
  SendOTP,
  send_otp,
  verify_otp,
  SetCardPolicy,
  SetPin,
  set_pin,
  UpdateMobileNumber,
  update_mobile_number,
  VerifyOTP,
  card_block_reasons
} from "./../../actions/cards/cards";
import PageHeader from "./../PageHeader";
import "./card.css";
import CardSettingsAccordion from "./cardSettingsAccordion";
import { Dropdown, Input, Menu, message, Modal, Select, Typography } from "antd";
import { ScaleLoader } from "react-spinners";
import { hasAccess } from "../../utils";
import { AddCards } from "../Settings/PoliciesLimits/Cards/AddCard";
import cardDetail from "./card_detail.json";
import TransactionsAccordion from "./transactions";
import { SettingOutlined } from "@ant-design/icons";
import { ErrorModal } from "../../actions/dashboard/dashboard";

export const walletText = {
  REIMBURSEMENT: "REIMBURSE WALLET",
  GIFT: "GIFT WALLET",
  OFFLINE: "OFFILNE WALLET",
  TRANSIT: "TRANSIT WALLET",
  EXPENSE: "EXPENSE WALLET",
  MEAL: "MEAL WALLET",
  FUEL: "FUEL WALLET",
  REIMBWALLET: "REIMBURSE WALLET",
  GIFTWALLET: "GIFT WALLET",
  OFFLINEWALLET: "OFFLINE WALLET",
  TRANSITWALLET: "TRANSIT WALLET",
  EXPENSEWALLET: "EXPENSE WALLET",
  MEALWALLET: "MEAL WALLET",
  FUELWALLET: "FUEL WALLET",
};

const { Option } = Select;


const { Title } = Typography;

const CardDetails = () => {
  const [kycStatus] = useState();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState({})
  const [cardDetailsData, setCardDetailsData] = React.useState([]);
  const [editRow, setEditRow] = useState({});
  const is_admin = useSelector((state) => state?.auth?.localVariables?.is_admin);

  const [walletData, setWalletData] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [blockModel, setBlockModel] = React.useState(false);
  const [pinModel, setPinModel] = React.useState(false);
  const [sendOtp, setSendOtp] = React.useState(false);
  const [cardActivation, setCardActivation] = React.useState(false);
  const [updateMobile, setUpdateMobile] = React.useState(false);
  const [activateCardModel, setActivateCardModel] = React.useState(false)
  const [mobile, setMobile] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [blockReason, setBlockReason] = React.useState(null);
  const [replaceModel, setReplaceModel] = React.useState(false);
  const cardsDetailsResponse = useSelector((state) => state.cards.cardsDetails);
  const setCardLimit = useSelector((state) => state.cards.set_card_policy);
  const setPin = useSelector((state) => state.cards.setPin);
  const blockCardResponse = useSelector((state) => state.cards.block);
  const replacementResponse = useSelector((state) => state.cards.replacement);
  const sendOtpRes = useSelector((state) => state.cards.send_otp);
  const verifyOtpRes = useSelector((state) => state.cards.verify_otp);
  const activate_Card = useSelector((state) => state.cards.activate_card);
  const updateMobileNumber = useSelector((state) => state.cards.update_mobile);
  const block_reasons = useSelector((state) => state.cards.block_reasons?.data?.response ?? []);

  // const { data: { response: { card_details: cardData = cardDetail } = {} } = {} } =
  //   cardsDetailsResponse;
  const cardData = cardDetail;
  const location = useLocation();
  const card_enalbe = useSelector((state) => state.cards.card_enable);
  const { state: { cardData: card_data = {} } = {} } = location;

  const handleCancel = () => {
    setIsModalVisible(false);
    setBlockModel(false);
    setSendOtp(false);
    setReplaceModel(false);
    setSelectedRow({})
    setUpdateMobile(false)
    setBlockReason(null)
    setMobile("")
    setOtp("")
  };

  React.useEffect(() => {
    dispatch(cardsDetails({ card_id: card_data?.id }));
    dispatch(get_card_limit({ card_id: card_data?.id }));
    dispatch(card_block_reasons({ card_id: card_data?.id }));

    return () => {
      dispatch({
        type: CardsDetails.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (card_enalbe?.success) {
      if (!card_enalbe?.data?.status) {
        message.error(
          <span className="messageText">{card_enalbe?.data?.errors?.message}</span>
        );
      } else {
        message.success(
          <span className="messageText">{card_enalbe?.data?.response}</span>
        );
      }
      if (card_enalbe?.data?.status)
        dispatch(cardsDetails({ card_id: card_data?.id }));
      dispatch({
        type: CardLimitEnable.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card_enalbe]);

  React.useEffect(() => {
    if (setCardLimit?.success && !setCardLimit?.data?.errors) {
      message.success(
        <span className="messageText">{setCardLimit?.data?.message}</span>
      );
      setEditRow({})
      dispatch(cardsDetails({ card_id: card_data?.id }));
      dispatch({
        type: SetCardPolicy.RESET,
      });
    }

    if ((setCardLimit?.success || !setCardLimit?.success) && setCardLimit?.data?.errors) {
      message.error(
        <span className="messageText">{setCardLimit?.data?.message}</span>
      );
      dispatch({
        type: ErrorModal.SUCCESS,
        payload: {
          open: true,
          errMsg: setCardLimit?.data?.errors
        }
      })
      dispatch({
        type: SetCardPolicy.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCardLimit]);

  React.useEffect(() => {
    if (updateMobileNumber?.data?.status) {
      message.success(
        <span className="messageText">{updateMobileNumber?.data?.message}</span>
      );
      setMobile("")
      dispatch({
        type: UpdateMobileNumber.RESET,
      });
    }
    if (updateMobileNumber?.data?.status === false) {
      message.error(
        <span className="messageText">{updateMobileNumber?.data?.errors}</span>
      );
      dispatch({
        type: UpdateMobileNumber.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMobileNumber]);

  React.useEffect(() => {
    if (blockCardResponse?.data?.status === true) {
      dispatch(cardsDetails({ card_id: card_data?.id }));
      message.success(
        <span className="messageText">{blockCardResponse?.data?.message}</span>
      );
      setBlockReason(null)
      dispatch({
        type: Block.RESET,
      });
    }

    if (blockCardResponse?.data?.status === false) {
      dispatch({
        type: ErrorModal.SUCCESS,
        payload: {
          open: true,
          errMsg: blockCardResponse?.data?.errors?.message
        }
      })
      dispatch({
        type: Block.RESET,
      });
    }
    // eslint-disable-next-line
  }, [blockCardResponse]);

  React.useEffect(() => {
    if (replacementResponse?.data?.status) {
      dispatch(cardsDetails({ card_id: card_data?.id }));
    }

    if (replacementResponse?.data?.status === false) {
      dispatch({
        type: ErrorModal.SUCCESS,
        payload: {
          open: true,
          errMsg: replacementResponse?.data?.errors?.message
        }
      })
      dispatch({
        type: Replacement.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementResponse]);

  React.useEffect(() => {
    if (setPin?.success) {
      window.open(setPin?.data?.response?.set_pin_url, "_blank")
      dispatch({
        type: SetPin.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPin]);

  React.useEffect(() => {
    if (activate_Card?.data?.status) {
      if (activate_Card?.data?.response?.responseCode === "00") {
        setCardActivation(activate_Card?.data?.response?.url)
        dispatch({
          type: ActivateCard.RESET
        })
        setOtp("")
        setSendOtp(false)
      } else {
        setOtp("")
        setSendOtp(false)
        setActivateCardModel(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate_Card]);

  React.useEffect(() => {
    if (sendOtpRes?.success) {
      setSendOtp(true)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendOtpRes]);

  React.useEffect(() => {
    if (verifyOtpRes?.data?.status) {
      dispatch({
        type: VerifyOTP.RESET
      })
      dispatch(activate_card({
        card_id: cardData?.id,
        action_type: "CARDACTIVATION",
      }))
      dispatch({
        type: SendOTP.RESET
      })
    }
    if (verifyOtpRes?.data?.status === false) {
      message.error(
        <span className="messageText">{verifyOtpRes?.data?.message}</span>
      );
      dispatch({
        type: VerifyOTP.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyOtpRes]);


  React.useEffect(() => {
    const {
      data: {
        response: { card_balances = {}, card_details = {} } = {},
        status = false,
      } = {},
    } = cardsDetailsResponse;
    setWalletData(cardData.wallets);
    if (status) {
      setCardDetailsData({ ...card_balances, ...card_details });
      // setWalletData(card_details.wallets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsDetailsResponse]);

  // function updateKycStatus(status) {
  //   setKycStatus(status);
  // }
  const statues = {
    "Permanent Hotlist": "status_rejected",
  };

  const hideBC = {
    "Permanent Hotlist": true,
  };

  return (
    <>
      {isModalVisible && <Modal
        title={
          <Title level={4}>
            {selectedRow?.id ? "Edit" : "Add"} {t('card_policy')} &nbsp;
            <span className="text-small">{t('enter_details')}</span>
          </Title>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className="right-alinged-modal lg"
        width={650}
      >
        <AddCards handleCancel={handleCancel} selectedRow={{ ...selectedRow }} from={"card_detail"} />
      </Modal>}
      <Modal
        title={
          <Title level={4}>
            {t('confirm')} &nbsp;
          </Title>
        }
        visible={blockModel}
        onCancel={handleCancel}
        footer={[]}
        width={450}
        closable={false}
      >
        <span
          className="cursor"
          onClick={() => {
            setBlockModel(false)
          }}
          style={{ position: "absolute", right: 10, top: 10 }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
        <div>

          <Title level={5} className="mb1">
            {t('block_card')}
          </Title>
          <Select placeholder="Select Reason" className="w100" value={blockReason} onChange={(val) => setBlockReason(val)}>
            {Array.isArray(block_reasons) && block_reasons?.map(ele => {
              return <Option value={ele?.reasons}>{ele?.reasons}</Option>
            })}
          </Select>
          {block_reasons?.message && <div className="errorMsg">
            {block_reasons?.message}
          </div>}
          <div className="top-filter mt1">
            <div className="flt-btns">
              <button onClick={() => {
                setBlockModel(false)
                setBlockReason(null)
              }}>
                <span>{t('cancel')}</span>
              </button>
              <button className="active" onClick={() => {
                dispatch(
                  block_card({
                    card_id: card_data?.id,
                    reason: blockReason,
                    action_type: "BLOCKCARD"
                  })
                );
                setBlockModel(false)
              }}>
                <span>{t('block')}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <Title level={4}>
            {t('set_pin')} &nbsp;
          </Title>
        }
        visible={pinModel}
        onCancel={handleCancel}
        footer={[]}
        width={450}
        closable={false}
      >
        <span
          className="cursor"
          onClick={() => {
            setPinModel(false)
          }}
          style={{ position: "absolute", right: 10, top: 10 }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
        <div>

          <p className="mb1 message">
            {t('card_set_pin')}
            <br />
            <br />
            {t('dont_continue')}
          </p>
          <p className="note">
            {t('browser_popup')}
          </p>
          <div className="top-filter mt1">
            <div className="flt-btns">
              <button onClick={() => {
                setPinModel(false)
              }}>
                <span>{t('cancel')}</span>
              </button>
              <button className="active" onClick={() => {
                dispatch(
                  set_pin({
                    card_id: card_data?.id,
                  })
                );
                setPinModel(false)
              }}>
                <span>{t('continue')}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <Title level={4}>
            {t('replace_card')} &nbsp;
          </Title>
        }
        visible={replaceModel}
        onCancel={handleCancel}
        footer={[]}
        width={450}
      >
        <div>
          <Title level={5}>
            {t('request_new_card')}&nbsp;
          </Title>
          <div className="top-filter mt1">
            <div className="flt-btns">
              <button
                onClick={() => {
                  setReplaceModel(false)
                }}
              >
                <span>{t('cancel')}</span>
              </button>
              <button className="active" onClick={() => {
                dispatch(
                  replacement({
                    card_id: card_data?.id,
                    reason: card_data?.data?.cardStatusReason?.toUpperCase()
                  })
                );
                setReplaceModel(false)
              }}>
                <span>{t('replace')}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <Title level={4}>
            {t('update_mobile')}&nbsp;
          </Title>
        }
        visible={updateMobile}
        onCancel={handleCancel}
        footer={[]}
        width={500}
      >
        <div>
          <Title level={5}>
            {t('update_mobile_number')} &nbsp;
          </Title>
          <Input placeholder="Enter mobile number" value={mobile} onChange={(e) => {
            if (Number.isNaN(Number(e.target.value))) {
              return;
            }
            setMobile(e.target.value)
          }}

          />
          <div className="top-filter mt1">
            <div className="flt-btns">
              <button
                onClick={() => {
                  setUpdateMobile(false)
                  setMobile("")
                }}
              >
                <span>{t('cancel')}</span>
              </button>
              <button className="active" onClick={() => {
                dispatch(
                  update_mobile_number({
                    card_id: cardData?.id,
                    new_mobile_number: mobile
                  })
                );
                setUpdateMobile(false)
              }}>
                <span>{t('update')}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={
          <Title level={4}>
            {t('otp_verify')} &nbsp;
          </Title>
        }
        visible={sendOtp}
        onCancel={() => {
          handleCancel()
          dispatch({
            type: SendOTP.RESET
          })
        }}
        footer={[]}
        width={450}
      >
        <div>
          <Input placeholder="Verify OTP" value={otp} onChange={(e) => {
            if (Number.isNaN(Number(e.target.value))) {
              return;
            }
            setOtp(e.target.value)
          }} />
          <p className="mb1 mt1 message">
            {t('otp_verification')}
            <br />
            <br />
            {t('dont_continue')}
          </p>
          <p className="note">
            {t('browser_popup')}
          </p>
          <div className="top-filter mt1">
            <div className="fl t-btns">
              <button
                onClick={() => {
                  setSendOtp(false)
                  setOtp("")
                  dispatch({
                    type: SendOTP.RESET
                  })
                }}
              >
                <span>{t('cancel')}</span>
              </button>
              <button className="active" disabled={activate_Card?.loading} onClick={() => {
                dispatch(
                  verify_otp({
                    card_id: card_data?.id,
                    otp,
                    action_type: "CARDACTIVATION",
                    txnId: sendOtpRes?.data?.response?.txnId
                  })
                );
              }}>
                <span>{t('update')}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={
          <Title level={4}>
            Card Activation
          </Title>
        }
        visible={cardActivation}
        onCancel={() => {
          setCardActivation(false)
        }}
        footer={[]}
        width={550}
      >
        <div className="card-activation">

          <p className="mb1 mt1 message flex-between">
            <p className="w60">
              Complete Card KYC
              <br />
              <span className="rbi">
                As per RBI guidelines, You need to complete your KYC to active the card.
              </span>
            </p>
            <img src={card_activate} alt="" width={100} />
          </p>
          <ul>
            <li>
              <p className="flex-start">
                <img src={aadhar} alt="" width={30} />
                <p>
                  <p className="title">Download Aadhar XML</p>
                  <span>1. Download the Aadhar XML file from the UIDAI site.</span>
                  <span>2. Login and navigate to Offline KYC</span>
                  <span>3. Set the Passcode and download</span>
                </p>
              </p>
              <p className="flex-start">

                <img src={upload_file} alt="" width={30} />
                <p>
                  <p className="title">Upload downloaded file</p>
                  <span>Navigate to bank KYC page to upload the downloaded file and enter details required for KYC.</span>
                </p>
              </p>
              <p className="flex-start">

                <img src={kyc} alt="" width={30} />

                <p>
                  <p className="title">KYC Completed</p>
                  <span>Post completion of KYC process, Your Card will be activated with in 24hrs.</span>
                </p>
              </p>
            </li>
          </ul>
          <p className="note">
            Note:- You will be redirected to the web browser for completing the KYC process.
          </p>
          <div className="top-filter mt1">
            <div className="flt-btns">
              <button className="active" onClick={() => {
                window.open(cardActivation, "_blank")
              }}>
                <span>Open Link</span>
              </button>
              <button
                onClick={() => {
                  setCardActivation(false)
                }}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={
          <Title level={4}>
            Card Activation Status&nbsp;
          </Title>
        }
        visible={activateCardModel}
        onCancel={() => {
          setActivateCardModel(false)
          dispatch({
            type: ActivateCard.RESET
          })
        }}
        footer={[]}
        width={500}
      >
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: 'center'
        }}>
          {activate_Card?.data?.response?.code === "cms149" ? <img src={success} width={220} /> : ""}
          <br />
          <p style={{
            textAlign: "center"
          }}>
            {activate_Card?.data?.response?.message ?? activate_Card?.data?.response?.responseMessage}
          </p>
          {activate_Card?.data?.response?.code === "cms149" ? <p style={{
            background: "#AFF5DC",
            padding: 10,
            borderRadius: 10,
            marginTop: 20
          }}>
            Haven't completed the activation ? You can finish it using the link shared on the your Registered mobile number or retry after 48hrs.
          </p> : ""}

          <div className="top-filter mt1">
            <div className="flt-btns">
              <button
                onClick={() => {
                  setActivateCardModel(false)
                  dispatch({
                    type: ActivateCard.RESET
                  })
                }}
                style={{
                  width: 150,
                  height: 45,
                }}
              >
                <span style={{
                  fontSize: 16
                }}>{t('ok')}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="heading-div">
        <PageHeader
          title={`Cards`}
          navigation={{
            Home: hasAccess("dashboard", "read") || hasAccess("dashboard", "write") ? "/dashboard" : "",
            Cards: "/cards/",
          }}
        />
        {cardData?.replacement_request_placed && (
          <div
            className="success-toast mb2"
            onClick={() => {
              dispatch({
                type: Replacement.RESET,
              });
            }}
          >
            {t('request_replacement')}
          </div>
        )}
        {cardsDetailsResponse?.loading && !cardData?.id ? (
          <div className="loader">
            <ScaleLoader />
          </div>
        ) : (
          <div className="detail-card">
            <section className="head">
              <div className="flex-between">
                <div className="v-center">
                  <h3 className="fontWeightBold fontSize2rem">
                    {cardData.employee_details?.name}
                  </h3>
                  <span
                    className={`status ${statues[cardData.status] ? statues[cardData.status] : ""
                      }`}
                  >
                    {cardData.status}
                  </span>
                  {/* {cardData.kyc_flag ? (
                  ""
                ) : ( */}
                  <span className="withoutBgButton ml1 mr1">
                    {t('kyc_status')}: {cardData.kyc_flag ? cardData.kyc_flag : ""}
                  </span>
                  {/* )} */}
                </div>
                {is_admin ?
                  <div className="d-flex action-btns">
                    {/* <button className="approve">Active</button> */}
                    {hasAccess("cards", "write") && !hideBC[cardData.status] ? (
                      <button
                        className="more"
                        onClick={() => {
                          setBlockModel(bool => !bool)
                        }}
                        disabled={blockCardResponse?.loading}
                      >
                        {blockCardResponse?.loading
                          ? "Blocking..."
                          : "Block Card"}
                      </button>
                    ) : (hasAccess("cards", "write") && is_admin &&
                      !cardDetailsData.replacement_request_placed && (
                        <button
                          className="more"
                          onClick={() => {
                            setReplaceModel(bool => !bool)
                          }}
                          disabled={replacementResponse?.loading}
                        >
                          {t('replace_card')}
                        </button>
                      )
                    )}
                    <span
                      className="close cursor"
                      onClick={() => history.push({
                        pathname: `/cards`, state: {
                          status: location.state?.status,
                          non_linked: location.state?.non_linked,
                          prevStatus: true
                        }
                      })}
                    >
                      <img src={Close} width={20} alt="" />
                    </span>
                  </div> :
                  <div className="d-flex action-btns">
                    {!hideBC[cardData.status] && <Dropdown overlay={
                      <Menu>
                        {hasAccess("cards", "write") && cardData.status === "Issued not active" &&
                          <Menu.Item>
                            <button className="text-heading small" onClick={() => {
                              dispatch(send_otp({
                                card_id: cardData?.id,
                                action_type: "CARDACTIVATION"
                              }))
                            }}>{t('activate_card')}</button>
                          </Menu.Item>}
                        {hasAccess("cards", "write") && cardData.status !== "Issued not active" && (
                          <Menu.Item>
                            <button className="text-heading small"
                              onClick={() => {
                                setBlockModel(bool => !bool)
                              }}
                              disabled={blockCardResponse?.loading}
                            >
                              {blockCardResponse?.loading
                                ? "Blocking..."
                                : "Block Card"}
                            </button>
                          </Menu.Item>
                        )}
                        {hasAccess("cards", "write") && (
                          <Menu.Item>
                            <button className="text-heading small" onClick={() => {
                              setPinModel(true)
                            }}>{t('set_modify_pin')}</button>
                          </Menu.Item>)}
                        {hasAccess("cards", "write") && cardData.status !== "Issued not active" && (
                          <Menu.Item onClick={() => {
                            setUpdateMobile(true)
                          }}>
                            <button className="text-heading small">{t('update_mobile')}</button>
                          </Menu.Item>)}
                      </Menu>
                    }>
                      <button
                        className="more"
                        onClick={() => {
                          // setActiveIndex(100);
                        }}
                      >
                        <SettingOutlined />
                      </button>
                    </Dropdown>}
                    <span
                      className="close cursor"
                      onClick={() => history.push({ pathname: `/cards/` })}
                    >
                      <img src={Close} width={20} alt="" />
                    </span>
                  </div>
                }
              </div>
              <div>
                <span className="duration fontWeight-normal">
                  {cardData.employee_details?.email} {cardData.employee_details?.email && "|"}{" "}
                  {cardData.employee_details?.employee_id}
                </span>
              </div>
            </section>
            <hr />
            <section className="card-info p1 card-info-flex-start">
              <div className="d-flex flex-col flex07">
                <div className="d-flex">
                  <div className="debit-card">
                    <h2>{cardData.company_name}</h2>
                    <div className="flex-between mt1">
                      <img src={card_chip} width={30} alt={"card_chip"} />
                      <img src={card_wifi} width={15} alt={"card_wifi"} />
                    </div>
                    <div className="mt1">
                      <span>
                        {cardData?.data?.maskCardNumber?.substr(0, 4)} XXXX XXXX{" "}
                        {cardData?.data?.maskCardNumber?.substr(15)}
                      </span>
                      <br />
                      <span style={{ marginTop: 5, fontSize: "0.85rem" }}>
                        {cardData?.card_reference_no}
                      </span>
                    </div>
                    <div className="flex-between mt1">
                      <span style={{ fontSize: "0.85rem" }}>
                        {cardData?.employee_details?.name ? cardData?.employee_details?.name : cardData.data?.firstName +
                          " " +
                          cardData.data?.lastName}
                      </span>
                      <img src={card_name} alt={"card_name"} />
                    </div>
                  </div>
                  <div className="cardProfile d-flex w85">
                    <div className="d-flex flex-col">
                      <span className="profileCardLeftSideTitle">
                        {t('card_holder_name')}
                      </span>
                      <span className="profileCardLeftSideTitle">
                        {t('mobile_number')}
                      </span>
                      <span className="profileCardLeftSideTitle">
                        {t('card_kit_number')}
                      </span>
                      <span className="profileCardLeftSideTitle">
                        {t('card_type')}
                      </span>
                      <span className="profileCardLeftSideTitle">
                        {t('kyc_status')}
                      </span>
                    </div>
                    <div className="d-flex flex-col ml2">
                      <span className="profileCardRightSideName">
                        {cardData?.employee_details?.name ? cardData?.employee_details?.name : cardData.data?.firstName +
                          " " +
                          cardData.data?.lastName}
                      </span>
                      <span className="profileCardRightSideName">
                        {cardData.employee_details?.mobile || "."}
                      </span>
                      <span className="profileCardRightSideName">
                        {cardData.card_reference_no || "."}
                      </span>
                      <span className="profileCardRightSideName">RuPay</span>
                      <span
                        className={
                          kycStatus === "Verified"
                            ? "profileCardRightSideName colorCodeEF951C verifiedColor"
                            : "profileCardRightSideName colorCodeEF951C"
                        }
                      >
                        {cardData.kyc_flag ? cardData.kyc_flag : "Limited KYC"}
                      </span>
                    </div>
                  </div>
                </div>
                <CardSettingsAccordion
                  card_ref_number={card_data?.card_ref_number}
                  card_id={card_data?.id}
                  editRow={editRow}
                  setEditRow={setEditRow}
                  channels={cardData?.data?.card_limits?.map((row) => row.channelType)}
                  card_limits={cardData?.data?.card_limits}
                  onEdit={() => {
                    let card_limit = cardData?.data?.card_limits?.map((row) => {
                      let arr = []
                      if (row.currentDailyLimit) {
                        arr.push({
                          transaction_type: row.channelType,
                          amount: row.currentDailyLimit,
                          frequency: "DAILY",
                        })
                      }
                      if (row.currentMonthlyLimit) {
                        arr.push({
                          transaction_type: row.channelType,
                          amount: row.currentMonthlyLimit,
                          frequency: "MONTHLY",
                        })
                      }
                      if (row.currentAnnualLimit) {
                        arr.push({
                          transaction_type: row.channelType,
                          amount: row.currentAnnualLimit,
                          frequency: "ANNUALLY",
                        })
                      }
                      return arr;
                    })
                    let arr = []
                    card_limit.forEach(ele => {
                      arr = [...arr, ...ele]
                    })

                    setSelectedRow({
                      id: card_data?.id,
                      channel_enabled: [
                        {
                          POS: cardData?.data?.channelType?.includes("POS"),
                        },
                        {
                          ATM: cardData?.data?.channelType?.includes("ATM"),
                        },
                        {
                          ECOM: cardData?.data?.channelType?.includes("ECOM"),
                        }
                      ],
                      assigned_data: [],
                      card_limit: arr,
                    });
                    setIsModalVisible(true)
                  }}
                  onAdd={() => {
                    setIsModalVisible(true)
                  }}
                />
                {/* <KYCDocumentsAccordion
                kycStatus={kycStatus}
                updateKycStatus={(status) => updateKycStatus(status)}
              /> */}
                <TransactionsAccordion walletData={walletData} />
              </div>
              <div className="cardProfile ml1 w40 flex03">
                <div className="w100">
                  <span
                    className="expensesAmount"
                    style={{ opacity: 0.8, fontSize: "1.5rem" }}
                  >
                    {t('card_balance')}
                  </span>
                  <hr className="mt1 mb1" />
                  {walletData.map((item) => (
                    <div key={item.wallet_identifier}>
                      <div
                        key={item.wallet_identifier}
                        className="d-flex flex-between mb1 mt1"
                      >
                        <div>
                          <img src={InvoiceIcon} alt={"logo"} />
                          <span className="titleColorCode333333 ml1">
                            {walletText[item.wallet_name] ? walletText[item.wallet_name] : item.wallet_name?.replace("WALLET", "") + " WALLET"}
                          </span>
                        </div>
                        <span className="expensesAmount"><BaseCurrency /> {item.balance}</span>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export default CardDetails;
