import React from 'react'

const CusSalesOrders = ({ scale, fill, opacity }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.487" height="20.266" viewBox="0 0 20.487 20.266" style={{
            scale: scale ? scale : 1,
            zoom: scale ? scale : 1
        }}>
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_6072" data-name="Rectangle 6072" width="20.487" height="20.266" fill={fill || "#fff"} />
                </clipPath>
            </defs>
            <g id="Group_18839" data-name="Group 18839" opacity={opacity || "0.5"}>
                <g id="Group_18838" data-name="Group 18838" clip-path="url(#clip-path)">
                    <path id="Path_20250" data-name="Path 20250" d="M146.82,118.216h-8.781a1.28,1.28,0,0,0-1.223,1.185l-.556,10.929a1.031,1.031,0,0,0,.3.778,1.009,1.009,0,0,0,.778.334h10.225a1.066,1.066,0,0,0,.778-.334,1.2,1.2,0,0,0,.3-.778l-.556-10.929a1.313,1.313,0,0,0-1.259-1.185Zm-.963,3.89a3.409,3.409,0,0,1-6.817,0v-.926a.667.667,0,1,1,1.334,0v.926a2.038,2.038,0,0,0,4.075,0v-.926a.667.667,0,1,1,1.334,0v.926Z" transform="translate(-128.148 -111.177)" fill={fill || "#fff"} />
                    <path id="Path_20251" data-name="Path 20251" d="M13.226,58.915V57.767a1.154,1.154,0,0,0-1.149-1.149H1.149A1.154,1.154,0,0,0,0,57.767v14.6a1.154,1.154,0,0,0,1.149,1.149H7a2.133,2.133,0,0,1-.222-1.185L7.3,61.4a2.631,2.631,0,0,1,2.593-2.482Z" transform="translate(0 -53.247)" fill={fill || "#fff"} />
                    <path id="Path_20252" data-name="Path 20252" d="M70.959,5.483H64.7V3.112a3.112,3.112,0,0,1,6.224,0V5.483ZM65.81,4.371h4V3.112a2,2,0,0,0-4,0Z" transform="translate(-60.846)" fill={fill || "#fff"} />
                </g>
            </g>
        </svg>
    )
}

export default CusSalesOrders;
