import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import { useEffect } from "react";
import { OnBoardEnglish } from "../Language/English";
import { Button, Form, Select } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import KYCCommon from "../CommonScreens/KYCCommon";
import { useForm } from "react-hook-form";
import KYCNotify from "../CommonScreens/KYCNotify";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { onboardStep, onboardStepPreview } from "../action/OnBoardForm";
import { getAllCountries } from "../../../actions/companySettings";
const KYCScreens = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [countary, setCountary] = useState("IN")
	const location = useLocation()
	const {
		control,
		register,
		getValues,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		watch,
	} = useForm({
		defaultValues: {
			countary: ""
		},
		shouldUseNativeValidation: false,
	});
	const allCountries = useSelector((state) => state?.companySetting?.getAllCountries?.data?.data?.map((item, index) => ({
		...item,
		value: item?.country_iso_code,
		label: <span style={{
			color: "#212121",
			fontSize: 15
		}}>
			<img src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_iso_code?.toLowerCase()}.svg`} className='mr1' width={25} style={{
				boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)"
			}} />
			{`${item?.country_name} (${item?.country_iso_code})`}
		</span>
	})))
	useEffect(() => {
		dispatch(headerTitles({
			title: "KYC",
			describe: "",
		}))
		dispatch(getAllCountries());
		if (location?.state?.to_preview) {
			if (["india", "in"]?.includes(localStorage.getItem("country")?.toLowerCase())) {
				dispatch(onboardStep({ step: 0, country: countary }))
				history.push("/onboard/businesskyc", { country: countary })
			}
		}
	}, [])

	const handleChange = (value) => {
		setCountary(value)
	};
	return (
		<>
			<div className="kyc-section">

				<div className="kyc-container">
					<div className="back-button">
						<Button type="link" icon={<ArrowLeftOutlined />} onClick={() => {
							history.push("/dashboard")
						}}>Back</Button>
					</div>
					<div className="kyc-header mt1">
						<h4 className="kyc-header-text">{OnBoardEnglish?.kycHeader}</h4>
						<span className="kyc-sub-header-text">{OnBoardEnglish?.kycSubHeader}</span>
					</div>
					<div className="kyc-card">
						<div className="card-header">
							<h4 className="card-header-text">{OnBoardEnglish?.kycCardHeader}</h4>
							<span className="card-sub-header-text">{OnBoardEnglish?.kycCardSubHeader}</span>
						</div>
						<div className="select-section">
							<div className="select-container">
								<Form.Item>
									<Select
										defaultValue={localStorage.getItem("country")}
										onChange={handleChange}
										style={{ width: "100%" }}
										options={allCountries}
									>
									</Select>
								</Form.Item>
							</div>
						</div>
						<div className="kyc-card-footer">
							{
								["india", "in"]?.includes(localStorage.getItem("country")?.toLowerCase()) ? <KYCCommon /> : null
							}

						</div>
					</div>

					<div className="button-section w60 mAuto">
						{
							["india", "in"]?.includes(localStorage.getItem("country")?.toLowerCase()) ? (
								<>
									<Button type="primary" className="pp-main-button w100" onClick={() => {
										history.push("/onboard/businesskyc", { country: countary })
									}}>Get Started</Button>
									<Button type="link" className="w100" onClick={() => {
										history.push("/dashboard")
									}}>Skip KYC</Button>
								</>
							) : null
						}
					</div>
					<div className="kyc-notify-section">
						{
							["india", "in"]?.includes(localStorage.getItem("country")?.toLowerCase()) ? null : <KYCNotify allCountries={allCountries} countary={countary} />
						}

					</div>
				</div>
			</div>
		</>
	)
}
export default KYCScreens;