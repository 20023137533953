import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { userCards } from "../../actions/cards/cards";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { reports, reportsCount, userReports } from "../../actions/reports/reports";
import { amountFormat, hasAccess } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import { useTranslation } from "react-i18next";
import "./dashboard.css";
import TopSec from "./TopSec";
import moment from "moment";
const Dashboard = ({ hasWriteAccess }) => {
  const history = useHistory()
  const userCardResponse = useSelector((state) => state.cards.userCards?.data?.response?.cards?.[0] || {});
  const reports_count = useSelector((state) => state.cards.reports_count?.data?.data || {});
  const dispatch = useDispatch()
  const [reportStatus, setReportStatus] = useState("unsubmitted")
  const reportsResponse = useSelector((state) => state.reports.reports || {});
  const { t } = useTranslation();
  useEffect(() => {
    // dispatch(dashboard());
    dispatch(reportsCount());
    // dispatch(userCards({ status: "A", source: "admin" }));
    dispatch(headerTitles({
      title: "Dashboard",
      description: "Overview of Accounts."
    }))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(reports({
      // status: reportStatus,
      module_type: "REPORT",
      filters: { status: [reportStatus] }
    }))
    // eslint-disable-next-line
  }, [reportStatus])

  const columns = [
    {
      title: t('report_details'),
      dataIndex: "report_id",
      key: "report_id",
      render: (text, record) => {
        return (
          <div className="report-title">
            <span className="d-block">{record?.report_number}</span>
            <span>
              {moment(record?.from_date).format("DD MMM YYYY")} - {moment(record.to_date).format("DD MMM YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text) => <div><BaseCurrency /> {amountFormat(text)}</div>,
    },
    {
      title: "Advance Amount",
      dataIndex: "total_advances_amount",
      key: "total_advances_amount",
      render: (text) => <div><BaseCurrency /> {amountFormat(text)}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div>
          <div className={`status status_${text}`}>{text.replace(/_/g, " ")}</div>
          <div>{moment(record.created_at).format("DD MMM YYYY")}</div>
        </div>
      ),
    },
  ];

  return (
    <div className="dashboard">
      <TopSec card={userCardResponse} />
      {
        (hasAccess("expense_reports", "read") || true) && (
          <>
            <div className="card mt2 report-summary">
              <h2 className="p1">Reports Summary</h2>
              <div className="d-flex">
                <div className={`cursor ${reportStatus === "unsubmitted" ? "active" : ""}`} onClick={() => {
                  setReportStatus("unsubmitted")
                }}>
                  <span>Unsubmitted Reports</span>
                  <span>
                    <strong>{reports_count?.unreported_reports?.count}</strong>
                    Reports
                  </span>
                </div>
                <div className={`cursor ${reportStatus === "pending_approval" ? "active" : ""}`} onClick={() => {
                  setReportStatus("pending_approval")
                }}>
                  <span>Pending Approval</span>
                  <span>
                    <strong>{reports_count?.pending_approval_emp_count}</strong>
                    Reports
                  </span>
                </div>
                <div className={`cursor ${reportStatus === "pending_reimbursement" ? "active" : ""}`} onClick={() => {
                  setReportStatus("pending_reimbursement")
                }} >
                  <span>Pending Reimbursement</span>
                  <span>
                    <strong>{reports_count?.reports_pending_reimbursement?.count}</strong>
                    Reports
                  </span>
                </div>
              </div>
            </div>
            <div className="report-table paycraft-table mt2">
              <Table columns={columns} dataSource={reportsResponse?.data?.data?.slice(0, 5)} pagination={false} />
              <span className="active-text cursor ml1" onClick={() => {
                history.push("/expenses/reports/my-reports", {
                  status: reportStatus
                })
              }}>View All</span>
            </div>
          </>

        )
      }
    </div>
  );
};

export default Dashboard;
