import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const BankingRedirect = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const [params, setParams] = useState({})
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let params = new URLSearchParams(location.search)
      let obj = {
        status: params.get("status")
      }
      setParams(obj)
    }

  }, [location.search]);

  useEffect(() => {
    if (params?.status === "SUCCESSFUL") {
      history.push("/account", {
        success: true
      })
    }

    if (params?.status === "FAILED") {
      history.push("/account", {
        success: false
      })
    }
  }, [params]);

  return (
    <div className="">
      <h3 className="mt-2 mb-2">{t('connet_ur_bank')}</h3>

      <div className="">
        <div className="">
          <div className="text-center">

          </div>
          <div className="mt-2 isBankAuthTxt text-center"></div>
        </div>
      </div>
    </div>
  )
};

export default BankingRedirect;