import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import Text from "antd/lib/typography/Text";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AddTripsField,
  companySetting,
  DeleteTripsField,
  deleteTripsField,
  getReminderAPI,
  getTripsField,
  UpdateCompanyTrips,
  updateCompanyTrips,
  UpdateTripsField,
  updateTripsField,
} from "../../../../actions/companySettings";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { apis } from "../../../../config/APIs";
import useForgoCardsAxios from "../../../../config/useForgoCardsAxios";
import Templates from "../Templates/Templates";
import "./../../styles/setting.css";
import "./../Custom.css";
import AddExpenseField from "./AddTripsField";
import { allTemplates } from "../../../../actions/Templates";
import { headerTitles } from "../../../../actions/masterdata/masterdata";

const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;

message.config({
  maxCount: 3,
});

const Trips = ({ type, moduleName, hasWriteAccess, module }) => {
  const { t } = useTranslation();
  // const { onCall: editReminder } = useForgoCardsAxios({ api: apis.editReminderAPI, method: "post" })
  const { onCall: deleteReminder } = useForgoCardsAxios({
    api: apis.deleteReminderAPI,
    method: "post",
  });
  const AllTemplates = useSelector(
    (state) => state.templates.allTemplates?.data
  );
  // const [activeTab, setActiveTab] = React.useState('profile');
  const [typeOne, setType] = useState("template_01");
  // const template_name = allTemplates?.template_name;

  const dispatch = useDispatch();

  const columns = [
    {
      title: t("field"),
      dataIndex: "column_name",
      key: "column_name",
    },
    {
      title: t("column_type"),
      dataIndex: "column_type",
      key: "column_type",
      render: (text, render) => {
        if (text == "integer") {
          return "Number";
        } else if (text === "string") {
          return "Text Box";
        } else if (text === "datetime") {
          return "Date & Time";
        } else if (text === "document") {
          return "Document";
        } else if (text === "dropdown") {
          return "Drop Down";
        } else {
          return text;
        }
      },
    },
  ];

  const typeArray = [
    "estimates",
    "sales_order",
    "invoices",
    "purchase_orders",
    "bills",
    "delivery_challan",
    "material_receipt",
    "qc_check",
    "returns"
  ];
  const type_key = {
    estimates: "estimate_module",
    sales_order: "sales_order_module",
    invoices: "invoice_module",
    purchase_orders: "purchase_order_module",
    bills: "bill_module",
    delivery_challan: "delivery_challan_module",
    material_receipt: "material_receipt_module",
    qc_check: "quality_check_module",
    returns: "returns_module",
  };

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState("center");
  const [selectedId, setSelectedId] = React.useState("");
  const [tripsFields, setTripsFields] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [titleHeader, setTitleHeader] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [notificationModuleName, setNotificationModuleName] =
    React.useState("");
  const [reminders, setReminders] = React.useState([]);
  const [remindersSelectedId, setRemindersSelectedId] = React.useState();

  useEffect(() => {
    if (type === "estimates") {
      setTitleHeader("estimate");
      setNotificationModuleName(t("unsubmitted_estimates"));
      setType("estimates");
    } else if (type === "sales_order") {
      setTitleHeader("order_sale");
      setNotificationModuleName(t("unsubmitted_sales_order"));
      setType("sales");
    } else if (type === "invoices") {
      setTitleHeader("invoices");
      setType("invoice");
      setNotificationModuleName(t("unsubmitted_invoices"));
    } else if (type === "purchase_orders") {
      setTitleHeader("purchase_orders");
      setNotificationModuleName(t("unsubmitted_purchase_order"));
      setType("po");
    } else if (type === "bills") {
      setTitleHeader("bills");
      setNotificationModuleName(t("unsubmitted_bills"));
      setType("bills");
    } else if (type === "payment-receive") {
      setTitleHeader("Payment Received");
    } else if (type === "payment-made") {
      setTitleHeader("Payment Made");
    } else if (type === "credit-note") {
      setTitleHeader("Credit Note");
    } else if (type === "delivery_challan") {
      setTitleHeader("Delivery Challan");
    } else if (type === "material_receipt") {
      setTitleHeader("Material Receipts");
    } else if (type === "qc_check") {
      setTitleHeader("QC Check");
    } else if (type === "returns") {
      setTitleHeader("Returns");
    } else {
      setTitleHeader("trips");
      setNotificationModuleName(t("unsubmitted_trip"));
    }

    setIsDisabled(hasWriteAccess);
  }, []);

  useEffect(() => {
    dispatch(
      headerTitles({
        title: titleHeader,
        description: "",
      })
    );
  }, [titleHeader]);

  const formik = useFormik({
    initialValues: {
      module_type_prefix: "",
      module_type_employee_reminder: "",
      module_type_employee_reminder_days: "",
      module_type_starts_with: "",
      module_type_display_starts_with: "",
      edit_prepaid: false,
      enable_ereceipts: false,
      policy_violation: false,
    },
    onSubmit: (values) => {
      values.module_type_display_starts_with = values.module_type_starts_with;
      if (typeArray.includes(type)) {
        dispatch(
          updateCompanyTrips({
            ...values,
            module_type: module,
            params: { module_type: module },
            type: type,
          })
        );
      } else {
        dispatch(
          updateCompanyTrips({ ...values, params: { module_type: "TRIP" } })
        );
      }
    },
  });

  const companySettingResponse = useSelector(
    (state) => state.companySetting.companySetting || {}
  );
  const updateCompanySettingResponse = useSelector(
    (state) => state.companySetting.updateCompanySetting || {}
  );
  const tripsFieldResponse = useSelector(
    (state) => state.companySetting.getTripsField || {}
  );
  const deleteTripsFieldResponse = useSelector(
    (state) => state.companySetting.deleteTripsField || {}
  );
  const addTripsFieldResponse = useSelector(
    (state) => state.companySetting.addTripsField || {}
  );
  const updateTripsFieldResponse = useSelector(
    (state) => state.companySetting.updateTripsField || {}
  );
  const updateCompanyTripsResponse = useSelector(
    (state) => state.companySetting.updateCompanyTrips || {}
  );
  // const getReminderData = useSelector(
  //   (state) => state?.companySetting?.getReminderData?.data?.response?.reminders
  // );

  const totalRecords = tripsFieldResponse?.data?.total_count;

  const setTab = () => { };
  React.useEffect(() => {
    if (typeArray.includes(type)) {
      dispatch(companySetting({ module_type: module }));
      dispatch(getTripsField({ module_type: module }));
    } else {
      dispatch(companySetting({ module_type: "TRIP" }));
      dispatch(getTripsField({ module_type: module }));
    }
    dispatch(allTemplates({ module_type: module }));
  }, []);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false } = {} } =
      updateCompanyTripsResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false)
      if (typeArray.includes(type)) {
        dispatch(companySetting({ module_type: module }));
        // dispatch(getReminderAPI({ module_name: module }));
      } else {
        dispatch(companySetting({}));
        setIsModalVisible(false)
      }
      handleSubmit();
      dispatch({
        type: UpdateCompanyTrips.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanyTripsResponse]);

  const {
    data: {
      data: {
        result: company_setting = { company_info: {}, base_currency: "" },
        remainders: getReminderData = [],
      } = {},
    } = {},
  } = companySettingResponse;

  const setModuleValues = (moduleType) => {
    const moduleSettings = company_setting;
    return {
      module_type_prefix:
        moduleSettings?.[
        `${moduleType}${moduleType === "trip" ? "" : "_module"}_prefix`
        ],
      module_type_starts_with:
        moduleSettings?.[
        `${moduleType}${moduleType === "trip" ? "" : "_module"
        }_display_starts_with`
        ],
      module_type_employee_reminder:
        moduleSettings?.[
        `${moduleType}${moduleType === "trip" ? "" : "_module"
        }_employee_reminder`
        ],
      module_type_employee_reminder_days:
        moduleSettings?.[
        `${moduleType}${moduleType === "trip" ? "" : "_module"
        }_employee_reminder_days`
        ],
      module_type_approver_reminder: moduleSettings?.[
        `${moduleType}${moduleType === "trip" ? "" : "_module"
        }_approver_reminder`
      ],
      module_type_approver_reminder_days: moduleSettings?.[
        `${moduleType}${moduleType === "trip" ? "" : "_module"
        }_approver_reminder_days`
      ],
    };
  };


  React.useEffect(() => {
    // formik.resetForm();

    const moduleTypeMapping = {
      estimates: "estimate",
      sales_order: "sales_order",
      invoices: "invoice",
      purchase_orders: "purchase_order",
      bills: "bill",
      delivery_challan: "delivery_challan",
      material_receipt: "material_receipt",
      qc_check: "quality_check",
      returns: "returns",
      default: "trip",
    };

    const typeKey = moduleTypeMapping[type]
      ? moduleTypeMapping[type]
      : moduleTypeMapping?.default;
    const data = setModuleValues(typeKey);
    formik.setValues(data);

    if (["bills", "invoices", "estimates", "delivery_challan", "material_receipt", "qc_check", "returns"].includes(type)) {
      formik.setFieldValue("remainders", getReminderData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettingResponse]);

  // console.log(formik.values, "titledData===>");

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = updateCompanySettingResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UpdateCompanyTrips.RESET,
      });
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: UpdateCompanyTrips.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanySettingResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = updateTripsFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      if (typeArray.includes(type)) {
        dispatch(getTripsField({ module_name: module, type: type }));
      } else {
        dispatch(getTripsField());
      }
      dispatch({
        type: UpdateTripsField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UpdateTripsField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTripsFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = deleteTripsFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      if (typeArray.includes(type)) {
        dispatch(getTripsField({ module_type: module }));
      } else {
        dispatch(getTripsField({ module_type: module }));
      }
      dispatch({
        type: DeleteTripsField.RESET,
      });
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: DeleteTripsField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTripsFieldResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", error = "" } = {} } =
      addTripsFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      if (typeArray.includes(type)) {
        dispatch(getTripsField({ module_type: module }));
      } else {
        dispatch(getTripsField({ module_type: module }));
      }
      dispatch({
        type: AddTripsField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddTripsField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTripsFieldResponse]);

  React.useEffect(() => {
    const { data: { data: fields = [], status = false } = {} } =
      tripsFieldResponse;
    if (status) {
      setTripsFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripsFieldResponse]);

  React.useEffect(() => {
    setReminders(getReminderData ? getReminderData : []);
  }, [getReminderData]);

  const handleCheckMandatory = (e, record) => {
    if (typeArray.includes(type)) {
      dispatch(
        updateTripsField({
          // ...record,
          is_required: e.target.checked,
          column_name: record.column_name,
          column_type: record.column_type,
          id: record.id,
          is_enable: record.is_enable,
          view_in_pdf: record.view_in_pdf,
          default_values: record.default_values || [],
          data: {
            max_size: record.data.max_size || "",
            doc_formats: record.data.doc_formats || "",
          },
          params: { module_type: module },
          type: type,
        })
      );
    } else {
      dispatch(
        updateTripsField({
          // ...record,
          params: { module_type: "TRIP" },
          column_name: record.column_name,
          column_type: record.column_type,
          id: record.id,
          is_enable: record.is_enable,
          view_in_pdf: record.view_in_pdf,
          module_name: moduleName,
          default_values: record.default_values || [],
          data: {
            max_size: record.data.max_size || "",
            doc_formats: record.data.doc_formats || "",
          },
          is_required: e.target.checked,
        })
      );
    }
  };
  // const handleCheckShow = (e, record) => {
  //   if (typeArray.includes(type)) {
  //     dispatch(
  //       updateTripsField({
  //         ...record,
  //         view_in_pdf: e.target.checked,
  //         module_name: moduleName,
  //         type: type,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       updateTripsField(
  //         objectToFormData({
  //           ...record,
  //           view_in_pdf: e.target.checked,
  //         })
  //       )
  //     );
  //   }
  // };
  const handleCheckEnable = (e, record) => {
    if (typeArray.includes(type)) {
      dispatch(
        updateTripsField({
          // ...record,
          column_name: record.column_name,
          column_type: record.column_type,
          id: record.id,
          is_enable: record.is_enable,
          view_in_pdf: record.view_in_pdf,
          is_enable: e.target.checked,
          data: {
            max_size: record.data.max_size || "",
            doc_formats: record.data.doc_formats || "",
          },
          default_values: record.default_values || [],
          params: { module_type: module },
          type: type,
        })
      );
    } else {
      dispatch(
        updateTripsField({
          // ...record,
          column_name: record.column_name,
          column_type: record.column_type,
          id: record.id,
          is_enable: record.is_enable,
          view_in_pdf: record.view_in_pdf,
          module_name: moduleName,
          data: record.data,
          default_values: record.default_values || [],
          data: {
            max_size: record.data.max_size || "",
            doc_formats: record.data.doc_formats || "",
          },
          is_enable: e.target.checked,
        })
      );
    }
  };

  const tableAction = [
    {
      title: t("mandatory"),
      dataIndex: "is_required",
      key: "is_required",
      render: (status, record) => (
        <Checkbox
          key={record.id}
          disabled={!isDisabled}
          onChange={(e) => handleCheckMandatory(e, record)}
          checked={status}
        ></Checkbox>
      ),
    },
    // {
    //   title: "Show in PDF",
    //   dataIndex: "view_in_pdf",
    //   key: "view_in_pdf",
    //   render: (status, record) => (
    //     <Checkbox
    //       key={record.id}
    //       onChange={(e) => handleCheckShow(e, record)}
    //       checked={status}
    //     ></Checkbox>
    //   ),
    // },
    {
      title: t("enable"),
      name: "enable",
      dataIndex: "is_enable",
      key: "is_enable",
      render: (status, record) => (
        <Checkbox
          key={record.id}
          disabled={!isDisabled}
          onChange={(e) => handleCheckEnable(e, record)}
          checked={status}
        ></Checkbox>
      ),
    },
    {
      title: t("actions"),
      key: "action",
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            disabled={!hasWriteAccess}
            onClick={() => showEditModal(record.id)}
          >
            <img src={EditIcon} alt={"logo"} className="action-logo" />
          </button>
          <button
            disabled={!hasWriteAccess}
            onClick={() => showDeleteModal(record.id)}
          >
            <img src={DeleteIcon} alt={"logo"} className="action-logo" />
          </button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("center");
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSubmit = () => {
    setEditMode(false);
  };

  // console.log(AllTemplates,"templetatesData===>");

  const handleAddReminder = () => {
    // const newId = reminders[reminders.length - 1].level + 1;
    const newReminders = [
      ...reminders,
      {
        module_name: "",
        module_id: "",
        alert_to: "",
        is_enable: "",
        days: "",
        before_after: "",
      },
    ];
    setReminders(newReminders);
  };

  // const handleEditReminder = (id) => {
  //   editReminder({
  //     params: {
  //       id: id,
  //     }
  //   })
  // };
  const handleDeleteReminder = (id, indexValue) => {
    if (id) {
      setIsModalVisible(true);
      setRemindersSelectedId(id);
    } else {
      let removeRow = reminders.filter((items, index) => {
        return index !== indexValue;
      });
      setReminders(removeRow);
    }
  };
  const handleDelete = () => {
    if (typeArray.includes(type)) {
      if (remindersSelectedId) {
        // updateCompanyTrips({
        //   params: {
        //     id: remindersSelectedId,
        //   }
        // })
        const moduleTypeMapping = {
          estimates: "estimate",
          sales_order: "sales_order",
          invoices: "invoice",
          purchase_orders: "purchase_order",
          bills: "bill",
          delivery_challan: "delivery_challan",
          material_receipt: "material_receipt",
          default: "trip",
        };
        const typeKey = moduleTypeMapping[type];
        const deleteData = setModuleValues(typeKey);
        delete deleteData?.remainders

        dispatch(
          updateCompanyTrips({
            ...deleteData,
            remainders: getReminderData?.map((ele) => {
              if (remindersSelectedId === ele?.id) {
                return { ...ele, is_active: false };
              }
              return ele;
            }),
            params: { module_type: module },
          })
        );
        // .then(() => {
        //   message.success(<span className="messageText">{t('reminder_deleted')}</span>);
        //   setIsModalVisible(false)
        //   dispatch(getReminderAPI({ module_name: moduleName }));
        // }).catch((err) => {
        //   message.error(<span className="messageText">{err.message}</span>);
        //   setIsModalVisible(false)
        // })
      } else {
        dispatch(
          deleteTripsField({
            id: selectedId,
            module_name: moduleName,
            type: type,
            params: { module_type: module },
          })
        );
      }
    } else {
      dispatch(
        deleteTripsField({ id: selectedId, params: { module_type: "TRIP" } })
      );
    }
  };

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t("delete_sure_field")}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() => handleDelete()}
        >
          {t("delete")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t("cancel")}
        </Button>
      </>
    );
  };
  return (
    <>
      <Modal
        title={
          modalType === "center" ? (
            <>
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
              {t("delete_field")}
            </>
          ) : (
            <Title level={4}>
              {selectedId ? t("edit_fields") : t("add_fields")} &nbsp;
              <span className="text-small">{t("enter_details")}</span>
            </Title>
          )
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={
          modalType === "center"
            ? "center-aligned-modal"
            : "right-alinged-modal-small"
        }
      >
        {modalType === "center" ? (
          renderDeleteOption()
        ) : (
          <AddExpenseField
            id={selectedId}
            handleCancel={handleCancel}
            type={type}
          />
        )}
      </Modal>

      <Tabs onChange={setTab} type="card" size="large" className="custom-tabs">
        {module !== "BILL_RECEIPT" &&
          module !== "INVOICE_RECEIPT" &&
          module !== "CREDIT" ? (
          <TabPane tab={t("preferences")} key="preferences">
            <div className="tabContentWrapper">
              <Form
                layout="vertical"
                className="custom-form"
                onFinish={formik.handleSubmit}
              // validateMessages={validateMessages1}
              >
                <Title level={5}>
                  {t("auto_generate_msg")} {titleHeader} {t("id_msg")}
                </Title>
                <Divider />

                <Row>
                  <Col span={8}>
                    <Form.Item label={t("prefix")}>
                      <Input
                        placeholder=""
                        value={formik.values.module_type_prefix}
                        onChange={formik.handleChange}
                        name={"module_type_prefix"}
                        disabled={
                          !editMode ||
                          company_setting[type_key[type] + "_prefix"]
                        }

                      // disabled={/*form.getFieldValue("trip_prefix")||*/
                      //   company_setting.trip_prefix
                      // }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} className="ml1">
                    <Form.Item
                      label={t("start_with")}
                      rules={[{ required: true, message: t("input_value") }]}
                    >
                      <Input
                        placeholder=""
                        name={"module_type_starts_with"}
                        //  onChange={formik.handleChange}
                        onChange={(e) => {
                          if (Number.isNaN(Number(e.target.value))) {
                            return;
                          }
                          formik.setFieldValue(
                            "module_type_starts_with",
                            e.target.value
                          );
                        }}
                        value={formik.values.module_type_starts_with}
                        disabled={
                          !editMode ||
                          company_setting[
                          type_key[type] + "_display_starts_with"
                          ]
                        }

                      //  disabled={company_setting.trip_starts_with}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Title level={5} className="customTitle">
                PDF Options
              </Title>
              <Divider />
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="trip_emp_details_pdf"
                    valuePropName="checked"
                  >
                    <Checkbox>Show Employee Details in PDF</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="trip_amount_history_pdf"
                    valuePropName="checked"
                  >
                    <Checkbox>Show advance amount history in PDF</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="trip_amount_comments_pdf"
                    valuePropName="checked"
                  >
                    <Checkbox>Show trip amount comments in PDF</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="trip_include_logo_pdf"
                    valuePropName="checked"
                  >
                    <Checkbox>Include company logo in PDF</Checkbox>
                  </Form.Item>
                </Col>
              </Row> */}
                {(type === "qc_check" || type === "returns" || type === "delivery_challan" || type === "material_receipt") ? null : (
                  <>
                    <Title level={5} className="customTitle">
                      {t("notifications")}
                    </Title>
                    <Divider />
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          valuePropName="checked"
                          style={{ marginBottom: "0px" }}
                        >
                          <Checkbox
                            checked={formik.values.module_type_employee_reminder}
                            name="module_type_employee_reminder"
                            disabled={!editMode}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "module_type_employee_reminder",
                                e.target.checked
                              );
                            }}
                          >
                            <span className="flexDisplay">
                              {`${t("reminder_trip")} ${notificationModuleName} ${t(
                                "more_than"
                              )}`}
                              &nbsp;
                              <Form.Item className="shortNumberInput">
                                <Input
                                  className="shortInput"
                                  name={"module_type_employee_reminder_days"}
                                  disabled={!editMode}
                                  onChange={(e) => {
                                    if (Number.isNaN(Number(e.target.value))) {
                                      return;
                                    }
                                    formik.setFieldValue(
                                      "module_type_employee_reminder_days",
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    formik.values.module_type_employee_reminder_days
                                  }
                                />
                              </Form.Item>{" "}
                              &nbsp;{t("days_submission")}
                            </span>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )
                }
                {type === "bills" ||
                  type === "invoices" ||
                  type === "estimates" ? (
                  <>
                    <Title level={5} className="customTitle">
                      {t("reminder")}
                    </Title>
                    <Divider />
                    {reminders &&
                      reminders.map((reminder, idx) => (
                        <Row style={{ marginBottom: "0px" }}>
                          <Col span={24}>
                            <Form.Item valuePropName="checked">
                              <Checkbox
                                name="is_enable"
                                checked={
                                  formik?.values?.remainders?.[idx]?.is_enable
                                }
                                disabled={!editMode}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `remainders[${idx}].is_enable`,
                                    e.target.checked
                                  );
                                }}
                              >
                                {t("reminder_trip")} &nbsp;
                              </Checkbox>
                              <Select
                                className={`${!editMode ? "blur-color" : "normaol-color"
                                  }`}
                                style={{ width: 250 }}
                                disabled={!editMode}
                                onChange={(val) => {
                                  formik.setFieldValue(
                                    `remainders[${idx}].alert_to`,
                                    val
                                  );
                                }}
                                value={
                                  formik?.values?.remainders?.[idx]?.alert_to
                                }
                              >
                                <Option value="CREATED BY">{t("me")}</Option>
                                <Option value="CUSTOMER">
                                  {t("CUSTOMER")}
                                </Option>
                                <Option value="CUSTOMER&CREATEDBY">
                                  {t("customer_and_me")}
                                </Option>
                              </Select>
                              <Input
                                style={{
                                  marginLeft: "1rem",
                                  marginRight: "1rem",
                                }}
                                className="shortInput"
                                name="days"
                                disabled={!editMode}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `remainders[${idx}].days`,
                                    e.target.value
                                  );
                                }}
                                value={formik?.values?.remainders?.[idx]?.days}
                              />
                              &nbsp;{t("days")}&nbsp;
                              <Select
                                className={`${!editMode ? "blur-color" : "normaol-color"
                                  }`}
                                style={{
                                  width: 120,
                                  marginLeft: "1rem",
                                  marginRight: "1rem",
                                }}
                                disabled={!editMode}
                                onChange={(val) => {
                                  formik.setFieldValue(
                                    `remainders[${idx}].before_after`,
                                    val
                                  );
                                }}
                                value={
                                  formik?.values?.remainders?.[idx]
                                    ?.before_after
                                }
                              >
                                <Option value="BEFORE">{t("BEFORE")}</Option>
                                <Option value="AFTER">{t("AFTER")}</Option>
                              </Select>
                              &nbsp;
                              {type === "estimates"
                                ? t("expiry_date")
                                : t("due_date")}
                              {/* <Button
                              type="text"
                              icon={
                                <img
                                  src={EditIcon}
                                  alt={"edit"}
                                  className="action-logo"
                                />
                              }
                              disabled={!editMode}
                              onClick={() => handleEditReminder(reminder.id)}
                            /> */}
                              <Button
                                style={{ marginLeft: "2rem" }}
                                type="text"
                                icon={
                                  <img
                                    src={DeleteIcon}
                                    alt={"delete"}
                                    className="action-logo"
                                  />
                                }
                                disabled={!editMode}
                                onClick={() =>
                                  handleDeleteReminder(reminder.id, idx)
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    <Button
                      disabled={!editMode}
                      type="link"
                      className="approve-add-btn mt1"
                      onClick={handleAddReminder}
                    >
                      {t("add_one_more")}
                    </Button>
                    <br />
                  </>
                ) : null}

                {!editMode && (
                  <Button
                    className="formButton mt3"
                    disabled={!isDisabled}
                    onClick={() => handleEdit()}
                  >
                    {t("edit")}
                  </Button>
                )}
                {editMode && (
                  <>
                    <Button
                      // onClick={()=>{dispatch(updateCompanyTrips({module_type:"TRIP"}))}}
                      key="5"
                      className="formButton mt3"
                      htmlType="submit"
                      disabled={updateCompanyTripsResponse?.loading}
                    >
                      {t("save")}
                    </Button>
                    {/* <Button
                    style={{ marginLeft: "1rem" }}
                    key="5"
                    className="formButton mt3"
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    Close
                  </Button> */}
                  </>
                )}
                {/* <Button key="6" className="cancelButton ml1 mt3" onClick={() => history.push(RouteConstants.SETTING_USERS)}></Button> */}
              </Form>
            </div>
          </TabPane>
        ) : (
          ""
        )}
        {module !== "BILL_RECEIPT" &&
          module !== "INVOICE_RECEIPT" &&
          module !== "CREDIT" &&
          moduleName !== "QUALITY_CHECK" &&
          moduleName !== "RETURN_CHECK" ? (
          <TabPane tab={t("fields")} key="fields">
            <div className="tabContentWrapper">
              {
                <Button
                  onClick={showModal}
                  className="mb1 addNew"
                  disabled={!hasWriteAccess}
                >
                  <img src={AddNewIcon} alt={"logo"} className="addNew-logo" />
                  {t("new_field")}
                </Button>
              }
              <Table
                rowKey="id"
                columns={[...columns, ...tableAction]}
                dataSource={tripsFields}
                className="settingsTable"
                pagination={{
                  total: totalRecords || 0,
                  onChange: (pagee) => {
                    dispatch(getTripsField({ page_number: pagee, module_type: module }));
                  },
                }}
              />
            </div>
          </TabPane>
        ) : (
          ""
        )}
        {module !== "TRIP" && type !== "qc_check" && type !== "returns" ? (
          <TabPane tab="Templates" key="templates">
            <div className="tabContentWrapper">
              <Templates
                type={typeOne}
                moduleName={module}
                tempaltesData={AllTemplates?.data}
                hasWriteAccess={hasWriteAccess}
              />
            </div>
          </TabPane>
        ) : (
          ""
        )}
      </Tabs>
    </>
  );
};

export default Trips;
