import { ActionTypesFactory } from "../../utils";


export const Trips = ActionTypesFactory("Trips", "Trips");
export const TripsAddComments = ActionTypesFactory("TripsAddComments", "Trips");
export const TripsComments = ActionTypesFactory("TripsComments", "Trips");
export const TripsClose = ActionTypesFactory("TripsClose", "Trips");
export const TripsHistory = ActionTypesFactory("TripsHistory", "Trips");
export const TripsApprove = ActionTypesFactory("TripsApprove", "Trips");
export const TripsReject = ActionTypesFactory("TripsReject", "Trips");
export const TripsDelete = ActionTypesFactory("TripsDelete", "Trips");
export const TripsShow = ActionTypesFactory("TripsShow", "Trips");
export const TripDetailsPDF = ActionTypesFactory("TripDetailsPDF", "Trips");

export const UserTrips = ActionTypesFactory("UserTrips", "Trips");
export const UserTripsSubmit = ActionTypesFactory("UserTripsSubmit", "Trips");
export const AddUserTrip = ActionTypesFactory("AddUserTrip", "Trips");
export const UpdateUserTrip = ActionTypesFactory("UpdateUserTrip", "Trips");
export const UserTripFields = ActionTypesFactory("UserTripFields", "Trips");
export const UserReportLinkTrip = ActionTypesFactory("UserReportLinkTrip", "Trips");
export const DownloadTripAttachments = ActionTypesFactory("DownloadTripAttachments", "Trips");
export const AttachTripFile = ActionTypesFactory("AttachTripFile", "Trips");
export const RecallTrip = ActionTypesFactory("RecallTrip", "Trips");

export const attachTripFile = (payload) => {
  return {
    type: AttachTripFile.REQUEST,
    payload,
  };
};

export const download_trip_attachments = (payload) => {
  return {
    type: DownloadTripAttachments.REQUEST,
    payload,
  };
};


export const recallTrip = (payload) => {
  return {
    type: RecallTrip.REQUEST,
    payload,
  };
};

export const trips = (payload) => {
  return {
    type: Trips.REQUEST,
    payload,
  };
};

export const trips_show = (payload) => {
  return {
    type: TripsShow.REQUEST,
    payload,
  };
};

export const trip_details_pdf = (payload) => {
  return {
    type: TripDetailsPDF.REQUEST,
    payload,
  };
};


export const trips_close = (payload) => {
  return {
    type: TripsClose.REQUEST,
    payload,
  };
};

export const tripsAddComments = (payload, otherParams) => {
  return {
    type: TripsAddComments.REQUEST,
    payload,
    otherParams
  };
};

export const tripsComments = (payload, otherParams) => {
  return {
    type: TripsComments.REQUEST,
    payload,
    otherParams
  };
};

export const tripsHistory = (payload, otherParams) => {
  return {
    type: TripsHistory.REQUEST,
    payload,
    otherParams
  };
};

export const tripsApprove = (payload) => {
  return {
    type: TripsApprove.REQUEST,
    payload,
  };
};

export const tripsReject = (payload, others) => {
  return {
    type: TripsReject.REQUEST,
    payload,
    others
  };
};

export const tripsDelete = (payload, others) => {
  return {
    type: TripsDelete.REQUEST,
    payload,
    others,
  };
};

export const userTrips = (payload) => {
  return {
    type: UserTrips.REQUEST,
    payload,
  };
};

export const userTripsSubmit = (payload) => {
  return {
    type: UserTripsSubmit.REQUEST,
    payload,
  };
};

export const addUserTrip = (payload) => {
  return {
    type: AddUserTrip.REQUEST,
    payload,
  };
};

export const updateUserTrip = (payload) =>{
  return {
    type: UpdateUserTrip.REQUEST,
    payload,
  };
}

export const addUserTripReset = (payload) => {
  return {
    type: AddUserTrip.RESET,
    payload,
  };
};

export const userTripFields = (payload) => {
  return {
    type: UserTripFields.REQUEST,
    payload,
  };
};

export const userReportLinkTrip = (payload) => {
  return {
    type: UserReportLinkTrip.REQUEST,
    payload,
  };
};

export const userReportLinkTripReset = (payload) => {
  return {
    type: UserReportLinkTrip.RESET,
    payload,
  };
};
