import { Switch } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import BillingAddress from "./AddBillingAddress";
import ShippingAddress from "./AddShippingAddress";
import { useTranslation } from "react-i18next";

const AddVendorAddress = ({
  control,
  register,
  watch,
  errors,
  noShowSame,
  setValue,
  billing_address
}) => {
  const { t } = useTranslation();
  const shippingAddressSame = watch("shippingAddressSame");
  return (
    <>
      <BillingAddress
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        billing_address={billing_address}
      />

      {noShowSame ? null : (
        <div
          style={{
            paddingTop: "25px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <span style={{ color: "#3B3B3B" }}>{t("ship_address")}</span>
          <div style={{ paddingLeft: "1rem" }}>
            <Controller
              shouldUnregister={true}
              control={control}
              name="shippingAddressSame"
              render={({
                fieldState: { error },
                field: { onChange, onBlur, value },
              }) => (
                <Switch
                  color="#3F81F4"
                  size="small"
                  defaultChecked
                  checked={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
            />
          </div>
        </div>
      )}

      {!shippingAddressSame ? (
        <ShippingAddress
          control={control}
          register={register}
          errors={errors}
        />
      ) : null}
    </>
  );
};

export default AddVendorAddress;
