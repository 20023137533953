import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { get_all_payments } from "../../actions/z_accounts";
import BaseCurrency from "../Common/BaseCurrency";
import NoTableData from "../Common/TableComp/NoTableData";
import TableComponent from "../Common/TableComp/TableComp";
import { p_types } from "../Payments/SendMoney/Recipients";
import { payment_status } from "../../config/helper";

const AccTxnTable = ({ account_number, dash }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );

  useEffect(() => {
    dispatch(
      get_all_payments({
        params: {
          account_number,
        },
        filters: {
          created_at: {
            start_date: moment().subtract(1, "week").startOf("week"),
            end_date: moment().clone().endOf("week"),
          },
        },
      })
    );
  }, []);

  const columns = [
    {
      title: t("txn_id"),
      dataIndex: "reference_number",
      key: "reference_number",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "counterparty_account_name",
      key: "counterparty_account_name",
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
    },
    {
      title: t("Date and Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: "Payment Method",
      dataIndex: "preferred_payment_scheme",
      key: "preferred_payment_scheme",
      render: (text) => p_types?.find((ele) => ele.value === text)?.label,
    },

    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <div>
          <BaseCurrency />
          {text}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status_code",
      key: "status_code",
      render: (text) => (
        <span
          className={`status status_${payment_status[text]?.toLowerCase()}`}
        >
          {payment_status[text]}
        </span>
      ),
    },
  ];

  return (
    <>
      {data?.length === 0 ? (
        <NoTableData title={"No Transactions yet."} />
      ) : (
        <TableComponent
          rowKey="id"
          columns={columns}
          hasCheckbox={false}
          data={Array.isArray(data) ? (dash ? data?.slice(0, 5) : data) : []}
          pagination={null}
          scroll={{
            x: 1450,
          }}
        />
      )}
    </>
  );
};

export default AccTxnTable;
