import { Modal, Table, Typography } from 'antd'
import React from 'react'

function SyncHistoryModal({open,onCancel,selectedStep}) {
  const {Title} = Typography;
  console.log('6=',selectedStep)

  const handleClose = () =>{
    onCancel();
  }
  const columns = [
    {
      title: "No",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
  ];

  const tableData = [
    {number:1,details:'15 Expenses added'},
    {number:2,details:'5 Sales deleted'},
    {number:3,details:'2 Purchases updated'},
  ];
  return (
    <div className='add-currency-container'>
    <Modal
      title={<Title level={4}>Sync History</Title>}
      open={open}
      onCancel={handleClose}
      footer={[]}
      className='right-alinged-modal add-currency-modal'
      width={650}
    >
      <div className='w70 d-flex flex-col'>
        <h4>{selectedStep?.title}</h4>
        <div className='d-flex'>
          <p style={{color: '#818181',opacity: '0.63'}}>{selectedStep?.description}</p>
         
        </div>
      </div>
      <Table
        rowKey='id'
        columns={[...columns]}
        dataSource={tableData}
        className='settingsTable'
        pagination={false}
      />
    </Modal>
  </div>
  )
}

export default SyncHistoryModal