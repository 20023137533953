import React, { useEffect } from "react";
import { FormInput, FormSelect } from "../../inputs";
import { vendorsByName } from "../../../actions/purchases";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UploadField from "../../inputs/UploadField";
import ItemsTable from "../Bills/ItemsTable";
import DateInput from "../../inputs/DateInput";
import { deleteFile, objectToFormData } from "../../../utils";
import closeIcon from "../../../assets/icons/close.png";
import { Col } from "antd";
import CustomField from "../Bills/CustomFields";

const ReceiptsFormFields = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    errors,
    vendorsByNameData,
    setExchangeCurrency,
    exchange,
    hasExchange,
    listFiles,
    setListFiles,
    setRemovedItemsData,
    isDelivery,
    fieldsData,
    warehouseData,
    c_fields,
    setLoadingRef,
  } = props;
  const onVendorSearch = (e) => {
    if (e && e.trim() !== "") {
      dispatch(
        vendorsByName({
          beneficiary_name: e.trim(),
          type: isDelivery ? "customer" : "vendor",
          status: "active",
        })
      );
    }
  };

  return (
    <>
      <div className="head">
        <label>
          {isDelivery ? t("new_delivery_challan") : t("material_receipt")}
        </label>
        <span className="close cursor mr2">
          <img
            src={closeIcon}
            width={20}
            alt="close"
            onClick={() => history.goBack()}
          />
        </span>
      </div>
      <div>
        <div className="w40 pl2">
          <div className="pp-form-item">
            <FormSelect
              inline
              control={control}
              {...register("beneficiary_id", {
                required: isDelivery
                  ? "Customer is required"
                  : "Vendor is required",
                onChange: (e) => {
                  setExchangeCurrency(e.target.value);
                  setValue("beneficiary_id", e.target.value);
                  // setValue("vendor_name", vendorsByNameData.data?.data?.find(ele => ele.id === getValues().beneficiary_id)?.beneficiary_name)
                },
              })}
              required
              errors={errors}
              label={isDelivery ? t("customer_name") : t("vendor_name")}
              placeholder={t("select")}
              onSearch={onVendorSearch}
              options={
                vendorsByNameData?.data?.data &&
                Array.isArray(vendorsByNameData.data?.data)
                  ? vendorsByNameData.data?.data?.map((item) => ({
                      value: item.id,
                      label: item.beneficiary_name,
                    }))
                  : []
              }
              onFocus={() => {
                dispatch(
                  vendorsByName({
                    beneficiary_name: "",
                    type: isDelivery ? "customer" : "vendor",
                    status: "active",
                  })
                );
              }}
              hideCreateOption={true}
            />
          </div>
          {watch("beneficiary_id") && (
            <div
              style={{
                backgroundColor: "#F5F7FA",
                padding: "30px 27px",
                marginTop: "20px",
                fontSize: "17px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "17px",
                    lineHeight: "26px",
                    color: "#000000",
                  }}
                >
                  {isDelivery ? t("Customer Details") : t("Vendor Details")}
                </span>
              </div>
              <Col style={{ paddingTop: "10px" }}>
                <div>
                  {t("name")} -{" "}
                  {
                    vendorsByNameData.data?.data?.find(
                      (ele) => ele.id === getValues().beneficiary_id
                    )?.beneficiary_name
                  }
                  <br />
                  {t("email")} -{" "}
                  {
                    vendorsByNameData.data?.data?.find(
                      (ele) => ele.id === getValues().beneficiary_id
                    )?.email
                  }
                </div>
              </Col>
            </div>
          )}
          {
            isDelivery ? null : null
            // <div className="pp-form-item">
            //     <FormSelect
            //         inline
            //         control={control}
            //         {...register("receipt_type", {
            //             required: "Document Type is required",
            //             onChange: () => { },
            //         })}
            //         errors={errors}
            //         required
            //         label={t("document_type")}
            //         placeholder="Select"
            //         options={[{ label: "Testing", value: "Tesing" }]}
            //     />
            // </div>
          }
        </div>

        {isDelivery && (
          <div className="pl2">
            <div className="pp-form-item w40">
              <FormInput
                control={control}
                {...register("dc_no")}
                errors={errors}
                label={t("delivery_challan_hash")}
                required={true}
              />
            </div>
          </div>
        )}

        {isDelivery ? null : (
          <>
            <div className="d-flex gap2 pl2">
              <div className="pp-form-item w40">
                <DateInput
                  control={control}
                  {...register("movement_date", {
                    required: "Movement Date is Required",
                  })}
                  errors={errors}
                  label={t("movement_date")}
                  isTime={true}
                  required={true}
                />
              </div>
              <div className="pp-form-item w40">
                <DateInput
                  control={control}
                  {...register("account_date", {
                    required: "Account Date is Required",
                    onChange: (date) => {},
                  })}
                  errors={errors}
                  label={t("account_date_hash")}
                  isTime
                  required={true}
                />
              </div>
            </div>
            <div className="d-flex gap2 pl2">
              <div className="pp-form-item w40">
                <FormInput
                  control={control}
                  {...register("mr_no")}
                  errors={errors}
                  label={t("material_receipt_hash")}
                  required={true}
                />
              </div>
              <div className="pp-form-item w40">
                <DateInput
                  control={control}
                  {...register("receipt_date", {
                    required: "Material Receipt Date is Required",
                    onChange: (date) => {},
                  })}
                  errors={errors}
                  label={t("material_receipt_date")}
                  isTime
                  required={true}
                />
              </div>
            </div>
          </>
        )}

        <div className="pl2">
          <div className="pp-form-item w40">
            <FormInput
              control={control}
              {...register(isDelivery ? "ref_po_no" : "ref_purchase_order_no")}
              errors={errors}
              label={isDelivery ? t("SO Number") : t("PO Number")}
            />
          </div>
        </div>

        {isDelivery && (
          <div className="w40 pl2">
            <div className="pp-form-item">
              <DateInput
                control={control}
                {...register("challan_date", {
                  required: `${t("movement_date")} is required`,
                  onChange: (date) => {},
                })}
                errors={errors}
                label={t("delivery_challan_Date")}
                required
              />
            </div>
            <div className="pp-form-item">
              <FormSelect
                inline
                control={control}
                {...register("challan_type", {
                  required: "Challan Type is required",
                  onChange: (e) => {},
                })}
                errors={errors}
                required
                label={t("challan_type")}
                placeholder="Select"
                options={[
                  {
                    label: "Supply of Liquid Gas",
                    value: "supply_of_liquid_gas",
                  },
                  { label: "Job Work", value: "job_work" },
                  {
                    label: "Supply on Approvals",
                    value: "supply_on_approvals",
                  },
                  { label: "Others", value: "others" },
                ]}
                hideCreateOption={true}
              />
            </div>
          </div>
        )}

        <div className="d-flex gap2 pl2">
          <div className="pp-form-item w40">
            <FormSelect
              inline
              control={control}
              {...register("ware_house_detail_id", {
                required: "Warehouse is required",
                onChange: (e) => {},
              })}
              errors={errors}
              required
              label={t("warehouse")}
              placeholder="Select"
              options={
                Array.isArray(warehouseData?.data?.data)
                  ? warehouseData?.data?.data?.map((item) => ({
                      value: item?.id,
                      label: item?.ware_house_name,
                    }))
                  : []
              }
              hideCreateOption={true}
            />
          </div>
          {
            !isDelivery && null
            // <div className="pp-form-item w40">
            //     <FormSelect
            //         inline
            //         control={control}
            //         {...register("priority", {
            //             required: "Priority is required",
            //             onChange: (e) => { },
            //         })}
            //         errors={errors}
            //         required
            //         label={t("priority")}
            //         placeholder="Select"
            //         options={[{ label: "Testing", value: "Tesing" }]}
            //     />
            // </div>
          }
        </div>

        {
          <div className="pp-form-item w40 pl2">
            {c_fields?.length ? (
              <div style={{ marginTop: "40px" }}>
                <span className="info">{t("custom_fields")}</span>
                <CustomField
                  setValue={setValue}
                  name={"customFields"}
                  watch={watch}
                  fields={c_fields}
                  register={register}
                  control={control}
                  errors={errors}
                  type={isDelivery ? "DELIVERY_CHALLAN" : "MATERIAL_RECEIPT"}
                  loadingRef={setLoadingRef}
                />
              </div>
            ) : null}
          </div>
        }

        <div className="p2 pl2">
          <ItemsTable
            exchange={exchange}
            hasExchange={hasExchange}
            isEdit={location?.state?.isEdit}
            control={control}
            errors={errors}
            register={register}
            name={
              isDelivery ? "delivery_challan_items" : "material_receipt_items"
            }
            items={
              isDelivery
                ? fieldsData?.delivery_challan_items
                  ? fieldsData?.delivery_challan_items
                  : fieldsData?.invoice_items
                : fieldsData?.billing_items
            }
            watch={watch}
            setValue={setValue}
            vals={getValues()}
            setRemovedItemsData={setRemovedItemsData}
            isStockOnHand={true}
            isInvoice={true}
            fromReceipts={isDelivery}
          />
        </div>

        <div className="w40 pl2 mb2">
          <div className="pp-form-item mb2">
            <FormInput
              control={control}
              {...register("notes")}
              errors={errors}
              label={t("notes")}
              type="textarea"
              rows={3}
            />
          </div>
          <UploadField
            title={t("attach_bill_file")}
            desc={t("material_desc")}
            subDescripcion={t("bill_desc")}
            acceptType=".jpg,.pdf,.png"
            onSelected={(fileInfo) => {
              setListFiles([...listFiles, fileInfo]);
            }}
            filesList={listFiles}
            onRemove={(index) => {
              const listAux = [...listFiles];
              if (listAux[index].isEdit) {
                deleteFile(
                  objectToFormData({
                    id: location.state?.id,
                    attachment_id: listAux[index].uid,
                    record_type: "vendor_billing_detail",
                  })
                );
              }
              listAux.splice(index, 1);
              setListFiles(listAux);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ReceiptsFormFields;
