import React from "react";

import {
  Badge,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Space,
  Typography,
} from "antd";
import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteMerchantsField,
  deleteMerchantsField,
  getMerchantsField,
  UpdateMerchantsField,
  updateMerchantsField,
  enabledisableMarchent,
  AddMerchantsField,
  EnableDisableMarchentField,
} from "../../../../actions/companySettings";
import { employees } from "../../../../actions/employees";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import {
  amountFormat,
  convertString,
  hasAccess,
  objectToFormData,
} from "../../../../utils";
import PageHeader from "../../../PageHeader";
import "./../../styles/setting.css";
import "./../Custom.css";
import AddMerchantField from "./AddMerchantsField";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import { useEffect } from "react";
import AddItemsFields from "./AddItemsFields";
import { useState } from "react";
import usePayAxios from "../../../../config/useAxios";
import { apis } from "../../../../config/APIs";
import { useTranslation } from "react-i18next";
import BaseCurrency from "../../../Common/BaseCurrency";
import useSettings from "../../../../config/useSettings";
import TableComponent from "../../../Common/TableComp/TableComp";
const { Title } = Typography;

message.config({
  maxCount: 3,
});

const Merchants = ({ type, hasWriteAccess }) => {
  let typeArray = ["items", "taxes", "tds", "unit", "warehouse"];
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let titleHeader = "";
  const [columns, setColumns] = React.useState([]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState("center");
  const [selectedId, setSelectedId] = React.useState("");
  const [merchantsFields, setMerchantsFields] = React.useState([]);
  const [employeesData, setemployeesData] = React.useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [requiredAPI, setRequiredAPI] = useState();
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const updateCompanySettingResponse = useSelector(
    (state) => state.companySetting.updateCompanySetting || {}
  );
  const merchantsFieldResponse = useSelector(
    (state) => state.companySetting.getMerchantsField || {}
  );
  const deleteMerchantsFieldResponse = useSelector(
    (state) => state.companySetting.deleteMerchantsField || {}
  );
  const addMerchantsFieldResponse = useSelector(
    (state) => state.companySetting.addMerchantsField || {}
  );
  const updateMerchantsFieldResponse = useSelector(
    (state) => state.companySetting.updateMerchantsField || {}
  );
  const employeesResponse = useSelector(
    (state) => state.employees.employees || {}
  );

  const enabledisableResponse = useSelector(
    (state) => state.companySetting.enabledisableMarchent || {}
  );

  const {
    data: { response: { employees: employeesRecord = [] } = {} } = {},
    success = false,
  } = employeesResponse;

  const columnsMerchant = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("code"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("desc"),
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Merchant Head",
    //   dataIndex: "user_name",
    //   key: "user_name",
    // },
  ];
  const columnsItems = [
    {
      title: t("item_name"),
      dataIndex: "item_name",
      key: "item_name",
      render: (text, record) => (
        <>
          {record?.integration_type && (
            <div className="base_badge">
              <Badge.Ribbon
                text={convertString(record?.integration_type)}
                placement={"start"}
                color="#3f81f4"
              />
            </div>
          )}
          {text}
        </>
      ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: t("hsn_code"),
      dataIndex: "hsn_code",
      key: "hsn_code",
    },
    {
      title: t("item_type"),
      dataIndex: "item_type",
      key: "item_type",
    },
    {
      title: t("unit_price"),
      dataIndex: "unit_price",
      key: "unit_price",
      render: (text) => (
        <div>
          <BaseCurrency />
          {amountFormat(text)}
        </div>
      ),
    },
  ];
  const columnsTaxes = [
    {
      title: t("tax_name"),
      dataIndex: "tax_name",
      key: "tax_name",
    },
    {
      title: t("value"),
      dataIndex: "rate",
      key: "value",
    },
  ];
  const columnsTDS = [
    {
      title: t("TDS Name"),
      dataIndex: "tax_name",
      key: "tax_name",
    },
    {
      title: t("rate"),
      dataIndex: "rate",
      key: "rate",
    },
  ];

  const columnsUnits = [
    {
      title: t("Category Name"),
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: t("Unit Name"),
      dataIndex: "unit_name",
      key: "unit_name",
    },
  ];

  const columnsWarehouse = [
    {
      title: t("Serial No"),
      dataIndex: "",
      key: "",
      render: (text, render, index) => <div>{index + 1}</div>,
    },
    {
      title: t("warehouse"),
      dataIndex: "ware_house_name",
      key: "ware_house_name",
    },
  ];
  useEffect(() => {
    if (type === "items") {
      titleHeader = "Items";
      setColumns(columnsItems);
      setRequiredAPI(apis.deleteItemsField);
    } else if (type === "taxes") {
      titleHeader = "Taxes";
      setColumns(columnsTaxes);
      setRequiredAPI(apis.deleteTaxesField);
    } else if (type === "tds") {
      titleHeader = "TDS";
      setColumns(columnsTDS);
      setRequiredAPI(apis.deleteTdsField);
    } else if (type === "unit") {
      titleHeader = "Unit";
      setColumns(columnsUnits);
      setRequiredAPI(apis.unitTypeDeleteAPI);
    } else if (type === "warehouse") {
      titleHeader = "Warehouse";
      setColumns(columnsWarehouse);
      setRequiredAPI(apis.warehouseDeleteAPI);
    } else {
      setColumns(columnsMerchant);
    }
  }, []);

  const { onCall: callSettings } = useSettings({
    api: requiredAPI,
    method: "post",
  });
  const {
    onCall: unitDefault,
    data: unitDefaultData,
    reset: unitDefaultReset,
    loading: unitDefaultLoading,
  } = useSettings({ api: apis.unitTypeDefaultAPI, method: "get" });

  // const columns = type ? tableColumn : columnsMerchant

  React.useEffect(() => {
    // dispatch(companySetting({ show: 'id' }));
    dispatch(
      getMerchantsField({
        // company_id: localStorage.getItem("company_id"),
        type: type,
        status: true,
      })
    );

    // setIsModalVisible(false,);
    dispatch(
      headerTitles({
        title: type ? titleHeader : "merchants",
        description: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(merchantsFieldResponse?.data, "merchentsData");

  React.useEffect(() => {
    if (success) {
      setemployeesData(employeesRecord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = false, errors = "" } = {},
    } = updateCompanySettingResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
    } else {
      errors && message.error(<span className="messageText">{errors}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanySettingResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = false, error = "" } = {},
    } = enabledisableResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch({
        type: EnableDisableMarchentField.RESET,
      });
    } else {
      error &&
        message.error(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledisableResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = updateMerchantsFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);

      getMerchantsField({
        company_id: localStorage.getItem("company_id"),
        type: type,
      });
      dispatch({
        type: UpdateMerchantsField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UpdateMerchantsField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMerchantsFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", errors = "" } = {},
    } = deleteMerchantsFieldResponse;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getMerchantsField({}));
      getMerchantsField({
        company_id: localStorage.getItem("company_id"),
        type: type,
      });
      dispatch({
        type: DeleteMerchantsField.RESET,
      });
    } else if (status === false && errors) {
      message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: DeleteMerchantsField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMerchantsFieldResponse]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = addMerchantsFieldResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      getMerchantsField({
        company_id: localStorage.getItem("company_id"),
        type: type,
      });
      dispatch({
        type: AddMerchantsField.RESET,
      });
    } else if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddMerchantsField.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMerchantsFieldResponse]);

  React.useEffect(() => {
    if (type) {
      setMerchantsFields(merchantsFieldResponse?.data?.data);
    } else {
      const { data: { data = [], status = false } = {} } =
        merchantsFieldResponse;
      if (status) {
        setMerchantsFields(data);
      }
    }
    const {
      data: {
        response: { transaction_merchants = [] } = {},
        status = false,
      } = {},
    } = merchantsFieldResponse;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantsFieldResponse]);
  React.useEffect(() => {
    if (unitDefaultData?.error == false) {
      message.success(
        <div className="messageText">{unitDefaultData?.message}</div>
      );
      dispatch(
        getMerchantsField({
          company_id: localStorage.getItem("company_id"),
          type: type,
        })
      );
      unitDefaultReset();
    } else if (unitDefaultData?.error == true) {
      message.error(
        <div className="messageText">{unitDefaultData?.message}</div>
      );
    }
  }, [unitDefaultData]);
  const tableAction = [
    type === "items" ||
    type === "taxes" ||
    type === "tds" ||
    type === "warehouse"
      ? {}
      : {
          title: t("enable"),
          dataIndex: type === "unit" ? "is_enable" : "enable",
          key: type === "unit" ? "is_enable" : "enable",
          width: 150,
          render: (enable, record) => (
            <Checkbox
              key={record.id}
              disabled={!hasWriteAccess}
              onChange={(e) =>
                dispatch(
                  enabledisableMarchent({
                    id: record.id,
                    is_enable: e.target.checked,
                    type: "unit",
                  })
                )
              }
              defaultChecked={enable}
            ></Checkbox>
          ),
        },
    {
      title: t("actions"),
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            disabled={!hasWriteAccess}
            onClick={() => showEditModal(record?.id)}
          >
            <img src={EditIcon} alt={"logo"} className="action-logo" />
          </button>
          <button
            disabled={!hasWriteAccess}
            onClick={() => showDeleteModal(record?.id)}
          >
            <img src={DeleteIcon} alt={"logo"} className="action-logo" />
          </button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };
  const showModalType = () => {
    setSelectedId("");
    setOpenAddNew(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);

    setIsModalType("center");
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    type ? setOpenAddNew(true) : setIsModalVisible(true);
    setIsModalType("right");
  };
  const handleDelete = () => {
    if (typeArray.includes(type)) {
      callSettings({
        data: {
          id: selectedId,
        },
      })
        .then((data) => {
          message.success(<span className="messageText">{data.message}</span>);
          setIsModalVisible(false);
          dispatch(
            getMerchantsField({
              // company_id: localStorage.getItem("company_id"),
              type: type,
            })
          );
        })
        .catch((err) => {
          message.error(<span className="messageText">{err.message}</span>);
          setIsModalVisible(false);
          dispatch(
            getMerchantsField({
              // company_id: localStorage.getItem("company_id"),
              type: type,
            })
          );
        });
    } else {
      dispatch(deleteMerchantsField(objectToFormData({ id: selectedId })));
      setIsModalVisible(false);
    }
  };
  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t("are_u_sure")}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() => handleDelete()}
        >
          {t("delete")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t("cancel")}
        </Button>
      </>
    );
  };

  function handleButtonClick(e) {
    if (typeArray.includes(type)) {
      showModalType();
    } else {
      showModal();
    }
  }

  return (
    <>
      <AddItemsFields
        isVisible={openAddNew}
        onClose={() => {
          setOpenAddNew(false);
          setSelectedId("");
        }}
        type={type}
        id={selectedId}
        data={merchantsFields}
      />
      <Modal
        title={
          modalType === "center" ? (
            <>
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
              {t("delete")} {type}
            </>
          ) : (
            <Title level={4}>
              {selectedId ? t("edit_marchant") : t("new_merchant")} &nbsp;
              <span className="text-small">{t("enter_details")}</span>
            </Title>
          )
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={
          modalType === "center"
            ? "center-aligned-modal"
            : "right-alinged-modal-small"
        }
      >
        {modalType === "center" ? (
          renderDeleteOption()
        ) : (
          <AddMerchantField
            id={selectedId}
            handleCancel={handleCancel}
            employeesData={employeesData}
            type={type}
          />
        )}
      </Modal>
      {
        <Button
          onClick={handleButtonClick}
          className="mb1 ml1 addNew"
          disabled={!hasWriteAccess}
        >
          <img src={AddNewIcon} alt={"logo"} className="addNew-logo" />
          {t("addNew")}
        </Button>
      }
      {type === "unit" && !merchantsFieldResponse?.data?.defaults && (
        <Button
          onClick={() => {
            unitDefault({});
          }}
          className="mb1 ml1 addNew"
        >
          {t("Create Default Values")}
        </Button>
      )}
      {type === "unit" && (
        <Input
          placeholder={t("Search By Unit Name..")}
          value={searchValue}
          style={{ width: "15rem", float: "right" }}
          onChange={(e) => {
            setSearchValue(e?.target?.value);
            dispatch(
              getMerchantsField({
                company_id: localStorage.getItem("company_id"),
                type: "unit",
                search_key: e?.target?.value?.trim(),
              })
            );
          }}
        />
      )}
      <TableComponent
        rowKey="id"
        columns={[...columns, ...tableAction]}
        data={merchantsFields}
        pagination={{
          total: merchantsFieldResponse?.data?.total_count || 0,
          onChange: (pagee) =>
            dispatch(
              getMerchantsField({
                // company_id: localStorage.getItem("company_id"),
                type: type,
                page_number: pagee,
              })
            ),
        }}
        className="settingsTable"
        hasCheckbox={false}
      />
    </>
  );
};

export default Merchants;

// merchants_prefix = 'rr',
// merchants_reminder = false,
// merchants_reminder_days = '10',
// merchants_starts_with = null,
