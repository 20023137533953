import { Divider } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  analytics_cards,
  analytics_cards_all,
  analytics_cards_export,
} from "../../actions/cards/cards";
import { amountFormat } from "../../utils";
import ReportTable from "./ReportTable";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import BaseCurrency from "../Common/BaseCurrency";
import { useTranslation } from "react-i18next";
import { pdf } from "@react-pdf/renderer";
import MISCards from "../Common/PDF/MISPDF/MISCards";
import { saveAs } from "file-saver";
import { json2csv } from 'json-2-csv';


const Cards = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.analytics.analytics_cards);
  const pdfData = useSelector((state) => state.analytics.analytics_cards_all);

  useEffect(() => {
    dispatch(analytics_cards({ card_only: true }));
    props.setFilterObj({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.pdfURL(downloadPdf)
  }, [pdfData])

  const downloadPdf = async () => {
    const blob = await pdf((
      <MISCards data={cards?.data?.data || []} />
    )).toBlob();
    saveAs(blob, "MISCards.pdf");
  }

  ///// Dowloading CSV file method ////////
  const downloadCsv = async () => {
    const prod = cards?.data?.data?.map(ele => ({
      "Employee ID": '',
      "Card Ref No": '',
      "Employee Name": '',
      "Cost Cente": '',
      "Project": '',
      "Transaction Date": '',
      "Merchant Name": '',
      "Transaction Amount": '',
      "Wallet Name": '',
      "Merchant MCC": '',
      "Transaction Type": '',
      "Status ": '',
    }))

    json2csv(prod).then((csv) => {
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      saveAs(blob, 'Advances.csv');
    });
  }

  useEffect(() => {
    props.csvData(downloadCsv)
  }, [props.csvData])


  const statusClasses = {
    pending: "pending_approval",
  };

  const getObjs = () => {
    if (cards?.data?.response?.transaction?.length > 0) {
      return cards?.data?.response?.transaction?.[0]?.custom_fields?.filter(ele => ele.key !== "cost_center" && ele.key !== "project" && ele.key !== "description" && ele.key !== "purpose" && ele.key !== "payment_method")?.map(ele => ({
        title: ele.key?.replace("_", " "),
        dataIndex: "custom_fields",
        key: "custom_fields",
        render: (text, data) => <div>{data?.custom_fields?.find(itm => itm.key === ele.key)?.value}</div>,
      }))
    }

    return []
  }

  const columns = [
    {
      title: t('employee_id'),
      dataIndex: "employee_id",
      key: "employee_id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: t('card_ref_no'),
      dataIndex: "card_reference_no",
      key: "card_reference_no",
      render: (text) => <div>{text}</div>,
    },
    {
      title: t('employee_name'),
      dataIndex: "employee_name",
      width: 250,
      key: "employee_name",
      render: (text) => <div>{text}</div>,
    },
    ...getObjs(),
    {
      title: t('cost_center'),
      dataIndex: "cost_center",
      key: "cost_center",
    },
    {
      title: t('project'),
      dataIndex: "project",
      key: "project",
    },
    {
      title: t('transaction_date'),
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text) => <div>{text}</div>,
    },
    {
      title: t('merchant_name'),
      dataIndex: "merchant",
      key: "merchant",
      render: (text) => <div style={{ whiteSpace: "pre" }}>{text}</div>,
    },

    {
      title: t('transaction_amount'),
      dataIndex: "transaction_amount",
      key: "transaction_amount",
      render: (text, record) => (
        <div>
          <img
            src={
              record?.transaction_type === "Cr" ||
                record?.transaction_type === "E"
                ? credit
                : debit
            }
            width={10}
            alt="credit"
          />{" "}
          <BaseCurrency /> {amountFormat(text)}
        </div>
      ),
    },
    {
      title: t('wallet_name'),
      dataIndex: "wallet_name",
      key: "wallet_name",
    },
    {
      title: t('merchant_mcc'),
      dataIndex: "merchant_mcc",
      key: "merchant_mcc",
      render: (text) => <div>{text}</div>,
    },
    {
      title: t('transaction_type'),
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => (
        <div>{text === "Dr" ? "Debit" : text !== null ? "Credit" : ""}</div>
      ),
    },
    {
      title: t('approval_status'),
      dataIndex: "approval_status",
      key: "approval_status",
      render: (text) => (
        <div className={`status status_${statusClasses[text]}`}>{text}</div>
      ),
    },
  ];

  return (
    <>
      <div className="reports">
        <Divider />
      </div>
      <ReportTable
        columns={columns}
        data={cards?.data?.response?.transaction || []}
        scroll={{ x: 1900 }}
        total_records={cards?.data?.total_count}
        paginationCall={(vals) => {
          dispatch(analytics_cards({ ...vals, card_only: true }));
          dispatch(
            analytics_cards_export({
              pdf: true,
              csv: true,
              ...vals,
              card_only: true,
            })
          );
        }}
        payload={{ ...props.filterObj, ...props.apiFilters, card_only: true }}
      />
    </>
  );
};

export default Cards;
