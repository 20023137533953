import {
  DownloadOutlined,
  EllipsisOutlined,
  FilterOutlined, SearchOutlined, PlusCircleFilled
} from '@ant-design/icons';
import { Button, Col, Dropdown, Input, Row } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { headerTitles } from '../../actions/masterdata/masterdata';
import TableWrap from '../Common/Table/Table';
import PageHeader from '../Purchases/PageHeader';
import { useTranslation } from 'react-i18next';


const VirtualAcc = () => {

  const dispatch = useDispatch()

  const sortOptions = [
    {
      label: t('created_history'),
    },
    {
      label: t('creation_date'),
    },
    {
      label: t('po_number'),
    },
    {
      label: t('vendor_name'),
    },
    {
      label: t('Amount'),
    },
    {
      label: t('expected_delivery_date'),
    },
    {
      label: t('import_purchase_orders'),
    },
    {
      label: t('export_purchase_orders'),
    },
  ];

  useEffect(() => {
    dispatch(headerTitles({
      title: 'virtual_Accounts',
      description: ""
    }))
  }, [])
  const {t} = useTranslation()

  const columns = [
    {
      title: t('bank_name'),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t('ac_num'),
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: t('corporate_id'),
      dataIndex: "corporate_id",
      key: "corporate_id",
    },
    {
      title: t('swift_bic'),
      dataIndex: "swift",
      key: "swift",
    },
    {
      title: t('status'),
      dataIndex: "status",
      key: "status",
      // render: (text) => (
      //   <div className={`status status_${labels[text] ? labels[text] : text}`}>
      //     {labels[text] ? labels[text].replace(/_/g, " ") : text.replace(/_/g, " ")}
      //   </div>
      // ),
    },
    {
      title:t('action'),
      dataIndex: "",
      key: "",
    },
  ];

  return (
    <>
      <Row wrap={false} style={{ flexGrow: 1, display: 'flex' }}>
        <Col flex="auto" style={{ display: 'flex', flexGrow: '1' }}>
          <div style={{ flexGrow: 1 }}>
            <div style={{ flexGrow: 1, paddingTop: '22px' }}>
              <div style={{ paddingLeft: '7px', display: 'flex', direction: 'row', alignItems: 'center' }}>
                <Button
                  type="primary"
                  className="pp-main-button"
                  icon={
                    <div style={{ paddingRight: '10px' }}>
                      <FilterOutlined style={{ width: '17px', height: '15px' }} />
                    </div>
                  }
                >
                  <span>{t('addFilters')}</span>
                </Button>

                <div style={{ borderRight: '1px solid #7676764F', paddingLeft: '18px', height: '41px' }}></div>

                <div style={{ paddingLeft: '17px' }}>
                  <span style={{ fontSize: '20px', lineHeight: '24px', color: '#7676764F' }}>{t('no_filters')}</span>
                </div>

                <div style={{ flexGrow: 1 }}></div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div className="mr1">
                    <Input
                      className="pp-input"
                      size="large"
                      placeholder={t('search_status')}
                      prefix={<SearchOutlined style={{ color: '#B9B9B9' }} />}
                      style={{ backgroundColor: '#FAFAFA', border: '1px solid #EAEAEA', borderRadius: '5px' }}
                    />
                  </div>
                  <div className="mr1">
                    <Button
                      type="primary"
                      className="pp-main-button"
                      icon={
                        <div style={{ paddingRight: '10px' }}>
                          <PlusCircleFilled style={{ width: '17px', height: '15px' }} />
                        </div>
                      }
                    >
                      <span>{t('addNew')}</span>
                    </Button>
                  </div>
                  <div>
                    <Dropdown.Button
                      className="pp-dropdownbutton-menu"
                      style={{
                        borderRadius: '10px',
                      }}
                      disabled
                      menu={{
                        mode: 'inline',
                        inlineCollapsed: false,
                        inlineIndent: 0,
                        items: [
                          {
                            type: 'group',
                            label: (
                              <span
                                style={{ color: '#B4B4B4', fontSize: '20px', lineHeight: '45px', fontWeight: 'normal' }}
                              >
                                {t('sort_by')}
                              </span>
                            ),
                            children: sortOptions.map((option) => ({
                              label: (
                                <span
                                  style={{
                                    color: '#454545',
                                    fontSize: '20px',
                                    fontWeight: 'normal',
                                  }}
                                >
                                  {option.label}
                                </span>
                              ),
                            })),
                          },
                          { type: 'divider' },
                          {
                            label: (
                              <div style={{ paddingLeft: '7px', paddingTop: '9px', paddingBottom: '9px' }}>
                                <span
                                  style={{
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                    color: '#454545',
                                    fontWeight: 'normal',
                                  }}
                                >
                                  {t('add_new_customer')}
                                </span>
                              </div>
                            ),
                            key: '1',
                            icon: (
                              <div style={{ paddingTop: '9px', paddingBottom: '9px', display: 'flex' }}>
                                <DownloadOutlined className="pp-icon-18" />
                              </div>
                            ),
                          },
                          {
                            label: (
                              <div style={{ paddingLeft: '7px', paddingTop: '9px', paddingBottom: '9px' }}>
                                <span
                                  style={{
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                    color: '#454545',
                                    fontWeight: 'normal',
                                  }}
                                >
                                  {t('export_reports')}
                                </span>
                              </div>
                            ),
                            key: '2',
                            icon: (
                              <div style={{ paddingTop: '9px', paddingBottom: '9px', display: 'flex' }}>
                                <DownloadOutlined className="pp-icon-18" />
                              </div>
                            ),
                          },
                        ],
                        onClick: () => { },
                      }}
                      size="large"
                      icon={<EllipsisOutlined className="pp-icon-25" style={{}} />}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ flexGrow: 1, paddingTop: '22px' }}>
              <TableWrap
                columns={columns}
                data={[]}
                // selectedRows={selectedRows}
                // setSelectedRows={setSelectedRows}
                rowKey="bill_no"
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default VirtualAcc;