import { Button, Modal, Space, Table, Typography, message } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../assets/icons/delete.png";
import EditIcon from "../../../assets/icons/edit.png";
import { apis } from "../../../config/APIs.js";
import useDocumentAxios from "../../../config/useDocument.js";
import { getAllBranchList } from "../../../actions/companySettings";
const ManageOrganization = ({ hasWriteAccess }) => {
  const history = useHistory();
  const { Text } = Typography;
  const { onCall: DeleteMultiBranch, data: branchDeleteData, reset: barnchDeleteReset } = useDocumentAxios({
    api: apis.deleteMultiBranch,
    method: "post",
    baseURL: "settings"
  });
  const orgs = useSelector((state) => state?.companySetting?.orgs);
  const getAllBranchdataList = useSelector((state) => state?.companySetting?.getAllBranchList)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [deletModal, setDeleteModal] = React.useState(false);
  const [recordID, setRecordID] = React.useState();
  const [page, setPage] = React.useState(1);
  const handleEdit = (record) => {
    history.push({
      pathname: "/settings/branches/add",
      state: record?.id
    })
  }
  const columns = [
    {
      title: t('organisation_name'),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t('org_primary_email'),
      dataIndex: "primary_email",
      key: "primary_email",
    },
    {
      title: t('org_contact_email'),
      dataIndex: "primary_contact_email",
      key: "primary_contact_email",
    },
    {
      title: t('primary_contact'),
      dataIndex: "primary_phone",
      key: "primary_phone",
    },
    {
      title: t('contact_name'),
      dataIndex: "primary_contact_name",
      key: "primary_contact_name",
    },
    // {
    //   title: t('enable'),
    //   dataIndex: "is_active",
    //   key: "is_active",
    //   render: (text) => <Checkbox checked={true} />,
    // },
    {
      title: t('actions'),
      key: "action",
      render: (text, record) => (
        <Space key={record.id} size="middle">
          {
            JSON.parse(localStorage.getItem("is_kyb_completed")) ? <>
              <button onClick={(e) => { }}>
                <img src={EditIcon} alt={"logo"} className="action-logo" onClick={() => handleEdit(record)} />
              </button>
              {
                record?.parent_id ? (
                  <button onClick={(e) => {
                    setDeleteModal(true)
                    setRecordID(record)
                  }}>
                    <img src={DeleteIcon} alt={"logo"} className="action-logo" />
                  </button>
                ) : null
              }
            </> : null
          }
        </Space>
      ),
    },
  ];

  const data = [
    {
      org_name: "Bluerack Info Ltd",
      primary_email: "abhishek@forgosol.info",
      primary_contact: 8973843843,
      name: "Abhishek",
    },
  ];
  useEffect(() => {
    dispatch(headerTitles({
      title: t("manage_organisation"),
      description: t("see_organisation")
    }))

    dispatch(getAllBranchList());
  }, [])

  useEffect(() => {
    if (branchDeleteData?.error === false) {
      barnchDeleteReset()
      dispatch(getAllBranchList());
      setDeleteModal(false)
      message.success(<span className="messageText">{t("org_del_suc")}</span>);

    }
  }, [branchDeleteData])

  return (
    <>
      <Modal
        title={
          <>
            <img src={DeleteIcon} alt={"logo"} className="action-logo" />
            {t('delete_organization')}
          </>
        }
        visible={deletModal}
        onCancel={() => {
          setDeleteModal(false)
        }}
        // destroyOnClose={true}
        // footer={[]}
        className={"center-aligned-modal"}
      >
        <>
          <Text>{t('delete_role_are_u_sure_organization')}</Text>
          <br />
          <Button
            key="1"
            className="formButton mt1"
            onClick={() => DeleteMultiBranch({
              data: {
                company_id: recordID?.id
              }
            })}
          // disabled={deleteRoleResponse?.loading}
          >
            {t('delete')}
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
            onClick={() =>
              setDeleteModal(false)
            }
          >
            {t('cancel')}
          </Button>
        </>
      </Modal>
      <Button
        disabled={!hasWriteAccess || !JSON.parse(localStorage.getItem("is_kyb_completed"))}
        onClick={() => {
          history.push("/settings/branches/add");
        }}
        className="mb1 addNew primaryButton"
      >
        <img src={AddNewIcon} alt={"logo"} className="addNew-logo " />
        {t('add_new_organisation')}
      </Button>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={getAllBranchdataList?.data?.data}
        className="settingsTable"
        pagination={{
          total: getAllBranchdataList?.data?.total_count || 0,
          current: page,
          onChange: (pagee) => {
            dispatch(getAllBranchList({ page_number: pagee }));
            setPage(pagee)
          },
        }}
      />
    </>
  );
};

export default ManageOrganization;
