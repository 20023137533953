import { UserOutlined } from "@ant-design/icons";
import { Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Close from "../../../assets/icons/close.png";
import "../Currencies/currencies.css";
import { syncHistoryData } from './RootFiData';
import './RootFi.css';
import SyncHistoryModal from './SyncHistoryModal';
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import { useTranslation } from "react-i18next";
import { getSyncHistory } from "../../../actions/cards/cards";
import moment from "moment";

const OrganisationHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [selectedStepData, setSelectedStepData] = useState({});
  const [syncHistoryModalVisible, setSyncHistoryModalVisible] = useState(false);
  const syncHistoryDataResponse = useSelector(state => state?.cards?.get_sync_history);

  // console.log('Response=', syncHistoryDataResponse);

  const { Items, Beneficiaries, Bills, Invoices, Sales_Orders, Purchase_Orders } = syncHistoryDataResponse?.data?.data ? syncHistoryDataResponse?.data?.data : {};

  const categorizedData = {};

  const categorize = (data, type) => {
    data?.forEach(item => {
      if (!categorizedData[`${type} ${item?.sync_type}`]) {
        categorizedData[`${type} ${item?.sync_type}`] = [];
      }
      categorizedData[`${type} ${item?.sync_type}`].push(item);
    });
  };

  categorize(Items, 'Items');
  categorize(Beneficiaries, 'Beneficiaries');
  categorize(Bills, 'Bills');
  categorize(Invoices, 'Invoices');
  categorize(Sales_Orders, 'Sales_Orders');
  categorize(Purchase_Orders, 'Purchase_Orders');
  // categorize(beneficiaries, 'beneficiaries');

  // | ${categorizedData[category].length} items
  const stepFormattedData = Object.keys(categorizedData).map(category => ({
    title: category,
    description: `${categorizedData[category].length > 0 && moment(categorizedData[category]?.[0].created_at).format('D MMM\'YY, h:mma')}`,
    data: categorizedData[category],
    type: categorizedData[category]?.[0]?.type
  }));

  console.log({ Beneficiaries });

  useEffect(() => {
    dispatch(headerTitles({
      title: t("history"),
      description: ""
    }))

    dispatch(getSyncHistory());
  }, [])

  const formattedData = syncHistoryData.map(item => ({
    title: item.historytTitle,
    description: `${item.historyDate} | ${item.userName} | ${item.action}`,
  }));
  const totalSteps = formattedData.length;

  const customDot = <UserOutlined style={{ color: '#3f81f4', fontSize: '1.2rem' }} />;

  const handleTitleClick = (step) => {
    // console.log('Step=',step)
    setSelectedStepData(step);
    // setSyncHistoryModalVisible(true);
  }
  return (
    <section className='contentWrapper'>
      <div className="flex-between">
        <label style={{ color: 'black' }} className='history'>
          History
        </label>
        <span className="close cursor mr2">
          <img src={Close} width={20} alt={"close"} onClick={() => history.goBack()} />
        </span>
      </div>
      <div className='mt2'>

        <Steps size="small" direction="vertical" current={totalSteps - 1} className='custom-steps'>
          {stepFormattedData.map((step, index) => (
            <Steps.Step key={index}
              title={<span onClick={() => handleTitleClick(step)} style={{ color: 'black' }}>{step?.title?.replace('_', ' ')} </span>}
              description={step.description}
              style={{ cursor: 'pointer' }}
              icon={<span className="custom-icon-container">{customDot}</span>}
            />
          ))}
        </Steps>
      </div>
      {syncHistoryModalVisible && <SyncHistoryModal open={syncHistoryModalVisible}
        onCancel={() => setSyncHistoryModalVisible(false)} selectedStep={selectedStepData ? selectedStepData : ""} />}
    </section>
  )
}

export default OrganisationHistory;