import { CloudUploadOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { headerTitles, SideMenu } from '../../actions/masterdata/masterdata';
import { get_receipts, vendors } from '../../actions/purchases';
import { amountFormat } from '../../utils';
import BaseCurrency from '../Common/BaseCurrency';
import TableComponent from '../Common/TableComp/TableComp';
import TableHeader from '../Sales/TableHeader';
import CreatePayment from './Payments/CreatePayment';
import { useTranslation } from 'react-i18next';

const PaymentsMade = ({ received }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const history = useHistory();
  const location = useLocation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [filters, setFilters] = useState({});
  const data = useSelector(state => state?.payments?.made?.data?.data?.length > 0 ? state?.payments?.made?.data?.data : [])
  const [page, setPage] = React.useState(1);
  const totalRecords = useSelector((state) => state.payments?.made?.data?.total_count);

  const sortOptions = [
    {
      label: 'Date',
      filter: "payment_date"
    },
    {
      label: received ? 'Customer Name' : 'Vendor Name',
      filter: received ? "customer_name" : "vendor_name"
    },
    {
      label: 'Mode',
      filter: "payment_mode"
    },
    {
      label: 'Amount',
      filter: "total_amount"
    },
    // {
    //   label: 'Import Payments',
    //   icon: (
    //     <div>
    //       <CloudUploadOutlined className="pp-icon-18" />
    //     </div>
    //   ),
    // },
    // {
    //   label: 'Export Payments',
    //   icon: (
    //     <div>
    //       <CloudUploadOutlined className="pp-icon-18" />
    //     </div>
    //   ),
    // },
    // {
    //   label: 'Prefernces',
    //   icon: (
    //     <div>
    //       <CloudUploadOutlined className="pp-icon-18" />
    //     </div>
    //   ),
    // },
  ];

  const columns = [
    {
      title: t('date'),
      dataIndex: 'payment_date',
      key: 'payment_date',
      render: (text) => <div>{text ? moment(text).format("DD/MM/YYYY") : "-"}</div>,
    },
    received ?
      {
        title: t('customer_name'),
        dataIndex: 'customer_name',
        key: 'customer_name',
      }
      :
      {
        title: t('vendor_name'),
        dataIndex: 'vendor_name',
        key: 'vendor_name',
      },
    received ?
      {
        title: t('invoice_no'),
        dataIndex: 'invoice_no',
        key: 'invoice_no',
        render: (text, record) => text ?? record?.ref_invoice_number
      }
      :
      {
        title: t('no_bill'),
        dataIndex: 'billing_no',
        key: 'billing_no',
      },
    // {
    //   title: 'Payment Through',
    //   dataIndex: 'paid_through',
    //   key: 'paid_through',
    // },
    {
      title: t('payment_mode'),
      dataIndex: 'payment_mode',
      key: 'payment_mode',
    },
    {
      title: t('total_amount'),
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (text, record) => <div> <BaseCurrency symbol_code={record?.currency_info?.iso_code} />{record.ref_invoice_number ? amountFormat(record?.amount_paid, record?.currency_info?.decimal_scale) : amountFormat(text, record?.currency_info?.decimal_scale)}</div>,
    },
    {
      title: t('due_amount'),
      dataIndex: 'due_balance_amount',
      key: 'due_balance_amount',
      render: (text, record) => <div> <BaseCurrency symbol_code={record?.currency_info?.iso_code} />{record.ref_invoice_number ? 0 : amountFormat(text, record?.currency_info?.decimal_scale)}</div>,

    },
    {
      title: t('amount_paid'),
      dataIndex: 'amount_paid',
      key: 'amount_paid',
      render: (text, record) => <div> <BaseCurrency symbol_code={record?.currency_info?.iso_code} />{amountFormat(text, record?.currency_info?.decimal_scale)}</div>,
    },
    {
      title: t('status'),
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (text) => (
        <div className={`status status_${text}`}>{text?.replace(/_/g, " ")}</div>
      ),
    },
  ];

  useEffect(() => {

    dispatch(get_receipts({
      isPaymentsReceived: received ? true : false
    }
    ));
    dispatch(headerTitles({
      title: received ? 'payment_receive' : 'mode_payments',
      //   description: "Add payments made that you deal with in your business."
    }))
    if (location?.state?.create) {
      setOpenAddNew(true)
    }

    if (location?.state?.setSideMenu) {
      let locationSplit = location?.pathname?.split("/")
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
          childrenMenu: "/pending"
        }
      })
    }
  }, []);

  const handleSelectRow = (event, record) => {
    history.push(`/sales/payments-received/view-payment`, {
      id: record.invoice_id,
      from: location.pathname,
      isInvoice: true,
      isSales: true,
      receipt_id: record?.receipt_id,
      amountPaid: record?.amount_paid,
      placeOfSupply: record?.place_of_supply,
      prevPath: location?.pathname
    });
  }

  const handleFilter = (value) => {
    if (value === "all") {
      dispatch(get_receipts({
        isPaymentsReceived: received ? true : false
      }
      ));
      setFilters({});
    } else {
      dispatch(get_receipts({
        ...filters,
        filters: { payment_status: [value] },
        isPaymentsReceived: received ? true : false,
        isFilters: true,
      }));
      setFilters({
        ...filters,
        filters: { payment_status: [value] },
      })
    }
    setPage(1)
  }

  const handleSort = (value) => {
    dispatch(get_receipts({
      ...filters,
      page_number: 1,
      sort: {
        sort_key: value,
        sort_order: "1"
      },
      isPaymentsReceived: received ? true : false,
      isFilters: true,
    }))
    setFilters({
      ...filters,
      sort: {
        sort_key: value,
        sort_order: "1",
      },
    });
    setPage(1)
  }

  const handleSearch = (searchKey) => {
    dispatch(get_receipts({
      ...filters,
      isPaymentsReceived: received ? true : false,
      search_key: searchKey
    }))
    setFilters({
      ...filters,
      search_key: searchKey
    })
    setPage(1)
  }


  const filterOptions = [
    { label: "All", filter: "all" },
    // { label: "Payment pending", filter: "payment_pending" },
    { label: "Partially Paid", filter: "partially_paid" },
    { label: "Paid", filter: "paid" },
  ];
  return (
    <>
      <CreatePayment
        isVisible={openAddNew}
        onClose={() => {
          setOpenAddNew(false);
        }}
        isSales={true}
      // onSuccess={()=>{
      //   dispatch(
      //     paymentMade({
      //       invoice_status: "approved",
      //       payment_status: "payment_pending",
      //     })
      //   );
      // }}
      />

      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              noDD={true}
              filterMenu={filterOptions}
              handleFilter={handleFilter}
              setOpenAddNew={setOpenAddNew}
              sortOptions={sortOptions}
              handleSort={handleSort}
              onSearch={handleSearch}
              noAddNewBtn={true}
            // menu={menu} 
            />

            {/* <div className={vendorsResponse?.loading ? "shimmer" : ""} style={{ flexGrow: 1, paddingTop: '22px' }}> */}
            <div className="shimmer" style={{ flexGrow: 1, paddingTop: '22px' }}>
              <TableComponent
                rowKey="id"
                columns={columns}
                data={Array.isArray(data) ? data : []}
                // hasCheckbox={false}
                // loading={vendorsResponse?.loading}
                // scroll={{ x: 1400 }}
                selectedRows={selectedRows}
                hasCheckbox={false}
                setSelectedRows={setSelectedRows}
                handleRowClick={handleSelectRow}
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(get_receipts({
                      ...filters,
                      isPaymentsReceived: received ? true : false,
                      page_number: pagee
                    }
                    ));
                    setPage(pagee)
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PaymentsMade;
