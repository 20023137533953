import React, { useEffect } from "react";

import { Button, message, Modal, Space, Table, Typography } from 'antd';
import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import { cardPolicies } from "../../../../actions/cards/cards";
import { DeleteExpensePolicies, expense_policies_delete, expense_policies_list, ShowExpensePolicies, show_expense_policy } from "../../../../actions/expenses/expenses";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import PageHeader from "../../../PageHeader";
import './../styles/policy.css';
import AddExpense from './AddExpense';
import { hasAccess } from "../../../../utils";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

const Expense = ({ hasWriteAccess }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [deleteModel,setDeleteModal] = React.useState(false);
  const [selectedId,setSelectedId] = React.useState("");
  const selectedRow = useSelector(state => state?.expenses?.show_policy?.data?.data)
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.expenses?.policies?.data?.data);
  const total_records = useSelector((state) => state?.expenses?.policies?.data?.total_records);
  const deleteRecord = useSelector((state) => state?.expenses?.delete_policy);
  const { t } = useTranslation()
  const handleCancel = () => {
    setDeleteModal(false);
    setIsModalVisible(false);
    dispatch({
      type: ShowExpensePolicies.RESET
    })
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setDeleteModal(true);
  };

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('desc'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            disabled={!hasWriteAccess}
          // onClick={() => showEditModal(record.id)}
          ><img src={EditIcon} alt={"logo"} className="action-logo" onClick={() => {
            dispatch(show_expense_policy({
              id: record.id
            }))
            setIsModalVisible(true)
          }} /></button>
          {record.key !== '1' ?
            <button
              disabled={!hasWriteAccess}
            onClick={() => showDeleteModal(record.id)}
            ><img src={DeleteIcon} alt={"logo"} className="action-logo" /></button> : null}
        </Space>
      ),
    },
  ];

  console.log(deleteModel,"expenseDAta==>");

  useEffect(() => {
    dispatch(expense_policies_list());
    dispatch(cardPolicies({
      module_type: "EXPENSE"
    }));
    dispatch(headerTitles({
      title: 'polocies_limits',
      description: ""
    }))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deleteRecord?.data?.status) {
      dispatch(expense_policies_list());
      message.success(<span className="messageText">{deleteRecord?.data?.message}</span>)
      dispatch({
        type: DeleteExpensePolicies.RESET
      })
    }
    setDeleteModal(false);
    setSelectedId("");
    // eslint-disable-next-line
  }, [deleteRecord]);
  return (
    <>
    {deleteModel && <Modal 
    title = {
      <>
      <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
              {t('delete_field')}
      </>
    }
    className="center-aligned-modal"
    visible={deleteModel}
    onCancel={handleCancel}
    footer={[]}>
      <Text>{t('delete_sure_field')}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() =>
            dispatch(expense_policies_delete({
              id: selectedId
            }))
          }
        >
          {t('delete')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t('cancel')}
        </Button>
      
      </Modal>}
      {isModalVisible && <Modal
        title={
          <Title level={4}>{selectedRow?.id ? t('edit') : t('addNew')} {t('expense_policy')}  &nbsp;<span className="text-small">{t('enter_details')}</span></Title>
        }
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className="right-alinged-modal lg"
      >
        {isModalVisible && <AddExpense handleCancel={handleCancel} selectedRow={selectedRow} />}
      </Modal>}
      <div className="">
        {<Button
          disabled={!hasWriteAccess}
          onClick={() => setIsModalVisible(true)}
          className="mb1 addNew"><img src={AddNewIcon} alt={"logo"} className="addNew-logo" />{t('add_new_policy')}</Button>}
        <Table rowKey="id" columns={columns} dataSource={data} className="settingsTable"
          pagination={{
            total: total_records,
          }} />
      </div>
    </>
  );
};

export default Expense;

