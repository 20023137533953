import React from "react";
import { useTranslation } from "react-i18next";

const Snapshots = () => {
  const {t} = useTranslation()
  return (
  
    <div className="snap-wrap">
      <div className="snapshot">
        <div className="titles">
          <span>{t('top_spending_users')}</span>
          <span>{t('view_details')}</span>
        </div>
        <div className="card">
          <table>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{t('Amount')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A Chandram</td>
                <td>Rs. 1987.00</td>
              </tr>
              <tr>
                <td>Ajay Kumar Agarwal</td>
                <td>Rs. 1007.00</td>
              </tr>
              <tr>
                <td>Annabathuni Subba</td>
                <td>Rs. 987.00</td>
              </tr>
              <tr>
                <td>A Chandram</td>
                <td>Rs. 587.00</td>
              </tr>
              <tr>
                <td>Ajay Kumar Agarwal</td>
                <td>Rs. 1087.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="snapshot">
        <div className="titles">
          <span>{t('top_vendors')}</span>
          <span>{t('view_details')}</span>
        </div>
        <div className="card">
          <table>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{t('Amount')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A Chandram</td>
                <td>Rs. 1987.00</td>
              </tr>
              <tr>
                <td>Ajay Kumar Agarwal</td>
                <td>Rs. 1007.00</td>
              </tr>
              <tr>
                <td>Annabathuni Subba</td>
                <td>Rs. 987.00</td>
              </tr>
              <tr>
                <td>A Chandram</td>
                <td>Rs. 587.00</td>
              </tr>
              <tr>
                <td>Ajay Kumar Agarwal</td>
                <td>Rs. 1087.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="snapshot">
        <div className="titles">
          <span>{t('top_categories')}</span>
          <span>{t('view_details')}</span>
        </div>
        <div className="card">
          <table>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{t('Amount')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A Chandram</td>
                <td>Rs. 1987.00</td>
              </tr>
              <tr>
                <td>Ajay Kumar Agarwal</td>
                <td>Rs. 1007.00</td>
              </tr>
              <tr>
                <td>Annabathuni Subba</td>
                <td>Rs. 987.00</td>
              </tr>
              <tr>
                <td>A Chandram</td>
                <td>Rs. 587.00</td>
              </tr>
              <tr>
                <td>Ajay Kumar Agarwal</td>
                <td>Rs. 1087.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="snapshot">
        <div className="titles">
          <span>{t('top_violations')}</span>
          <span>{t('view_details')}</span>
        </div>
        <div className="card">
          <table>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{t('Amount')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A Chandram</td>
                <td>Rs. 1987.00</td>
              </tr>
              <tr>
                <td>Ajay Kumar Agarwal</td>
                <td>Rs. 1007.00</td>
              </tr>
              <tr>
                <td>Annabathuni Subba</td>
                <td>Rs. 987.00</td>
              </tr>
              <tr>
                <td>A Chandram</td>
                <td>Rs. 587.00</td>
              </tr>
              <tr>
                <td>Ajay Kumar Agarwal</td>
                <td>Rs. 1087.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Snapshots;
