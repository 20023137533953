import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const usePdfTable = (headers, rows, dataMapKey) => {
    const styles = StyleSheet.create({
        table: {
          display: "table",
          width: "100%",
          borderWidth: 0.5,
          borderColor: "#ccc",
          borderStyle: "solid",
          marginTop: 10,
          marginBottom: 10,
        },
        tableRow: {
          flexDirection: "row",
        },
        tableColHeader: {
          width: "115px",
          backgroundColor: "#c6d5ec",
          textAlign: "center",
          padding: 5,
          fontWeight: "bold",
          borderWidth: 0.5,
          borderColor: "#ccc",
          borderStyle: "solid",
        },
        tableCol: {
          width: "115px",
          textAlign: "center",
          padding: 5,
          borderWidth: 0.5,
          borderColor: "#ccc",
          borderStyle: "solid",
        },
      });
      
    return (
        <View style={styles.table}>
            <View style={styles.tableRow}>
                {headers?.map((header, i) => (
                    <View style={styles.tableColHeader} key={i}>
                        <Text>{header}</Text>
                    </View>
                ))}
            </View>
            {rows?.map((row, i) => (
                <View style={styles.tableRow} key={i}>
                    {dataMapKey?.map((header, j) => (
                        <View style={styles.tableCol} key={j}>
                            <Text>{row[header]}</Text>
                        </View>
                    ))}
                </View>
            ))}
        </View>
    );
};

export default usePdfTable;
