import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import { hasAccess } from "../../../utils";
import ModulesSubMenu from "./ModulesSubMenu";
import {
  Advances, Bills, CostCenter, CreditNote, Estimate, Expenses, Invoices, Items, Merchants, PaymentReceived,
  PaymentsMade, Projects, PurchaseOrders, Reports, SalesOrder, TDS, Taxes,
  Trips
} from '../../../assets/icons/Modules';

const CustomModules = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const EstimatesDataColumn = [
    {
      title: t('Expenses'),
      description: t('configure_expense'),
      to: "/settings/customisation/expenses",
      // hasAccess: hasAccess("expense_settings", "read")
      hasAccess: hasAccess("expense_settings", "read"),
      moduleIcon: <img src={Expenses} alt="Expenses Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: t('reports'),
      description: t('configure_reports'),
      to: "/settings/customisation/reports",
      hasAccess: hasAccess("reports_settings", "read"),
      moduleIcon: <img src={Reports} alt="Reports Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    },
    {
      title: t('advances'),
      description: t('configure_advance'),
      to: "/settings/customisation/advances",
      hasAccess: hasAccess("advances_settings", "read"),
      moduleIcon: <img src={Advances} alt="Advances Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    },
    {
      title: t('trips'),
      description: t('configure_trips'),
      to: "/settings/customisation/trips",
      hasAccess: hasAccess("trips_settings", "read"),
      moduleIcon: <img src={Trips} alt="Trips Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    },
  ]
  const SalesDataColumn = [
    {
      title: t('estimate'),
      description: t('customize_expense_fields'),
      to: "/settings/modules/estimates/",
      hasAccess: hasAccess("estimates_settings", "read"),
      moduleIcon: <img src={Estimate} alt="Estimate Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    },
    {
      title: t('order_sale'),
      description: t('customize_sale_fields'),
      to: "/settings/modules/sales_order/",
      hasAccess: hasAccess("sales_orders_settings", "read"),
      moduleIcon: <img src={SalesOrder} alt="SalesOrder Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: t('invoices'),
      description: t('customize_invoice_fields'),
      to: "/settings/modules/invoices/",
      hasAccess: hasAccess("invoices_settings", "read"),
      moduleIcon: <img src={Invoices} alt="Invoices Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: "Credit Note",
      description: "Customize the fields you require in credit note and set up preferences",
      to: "/settings/modules/credit-note/",
      hasAccess: hasAccess("credit_note_settings", "read"),
      moduleIcon: <img src={CreditNote} alt="Invoices Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: "Delivery Challan",
      description: "Tailor the necessary fields for the delivery challan and configure your preferences accordingly.",
      to: "/settings/modules/delivery/",
      hasAccess: hasAccess("delivery_challan_settings", "read"),
      moduleIcon: <img src={CreditNote} alt="Invoices Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: "Payment Received",
      description: "Customize the fields you require in payments received and set up preferences",
      to: "/settings/modules/payment-received/",
      hasAccess: hasAccess("payment_received_settings", "read"),
      moduleIcon: <img src={PaymentReceived} alt="Invoices Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
  ]
  const PurchaseDataColumn = [
    {
      title: t('purchase_order'),
      description: t('purchase_order_preference'),
      to: "/settings/modules/purchase_orders/",
      hasAccess: hasAccess("purchase_orders_settings", "read"),
      moduleIcon: <img src={PurchaseOrders} alt="PurchaseOrders Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: t('bills'),
      description: t('bills_preference'),
      to: "/settings/modules/bills",
      hasAccess: hasAccess("bills_settings", "read"),
      moduleIcon: <img src={Bills} alt="Bills Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: "Payment Made",
      description: "Customize the fields you require in payments made and set up preferences",
      to: "/settings/modules/payment-made/",
      hasAccess: hasAccess("payment_made_settings", "read"),
      moduleIcon: <img src={PaymentsMade} alt="Bills Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: "Material Receipts",
      description: "Customize the required fields for material receipts and adjust your preferences accordingly.",
      to: "/settings/modules/material/",
      hasAccess: hasAccess("material_receipt_settings", "read"),
      moduleIcon: <img src={CreditNote} alt="Invoices Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: "QC Check",
      description: "Customize the required fields for material receipts and adjust your preferences accordingly.",
      to: "/settings/modules/qc-check/",
      hasAccess: hasAccess("quality_check_settings", "read"),
      moduleIcon: <img src={CreditNote} alt="Invoices Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: "Returns",
      description: "Customize the required fields for material receipts and adjust your preferences accordingly.",
      to: "/settings/modules/returns/",
      hasAccess: hasAccess("returns_settings", "read"),
      moduleIcon: <img src={CreditNote} alt="Invoices Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },

  ]
  const GeneralDataColumn = [
    {
      title: t('projects'),
      description: t('project_related'),
      to: "/settings/customisation/projects",
      hasAccess: hasAccess("projects", "read"),
      moduleIcon: <img src={Projects} alt="Projects Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: t('cost_center'),
      description: t('org_cost'),
      to: "/settings/customisation/cost_center",
      hasAccess: hasAccess("cost_center", "read"),
      moduleIcon: <img src={CostCenter} alt="CostCenter Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,
    },
    {
      title: t('merchants'),
      description: t('merchant_business'),
      to: "/settings/customisation/merchant",
      hasAccess: hasAccess("merchants", "read"),
      moduleIcon: <img src={Merchants} alt="Merchants Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    },
    // {
    //   title: t('items'),
    //   description: t('sale_items'),
    //   to: "/settings/customisation/items",
    //   hasAccess: hasAccess("items", "read"),
    //   moduleIcon: <img src={Items} alt="Items Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    // },




  ]

  const TaxesDataColumn = [
    {
      title: t('taxes'),
      description: t('purchase_tax'),
      to: "/settings/customisation/taxes",
      hasAccess: hasAccess("taxes", "read"),
      moduleIcon: <img src={Taxes} alt="Taxes Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    },
    {
      title: "TDS",
      description: t('tds_slab'),
      to: "/settings/customisation/tds",
      hasAccess: hasAccess("tds", "read"),
      moduleIcon: <img src={TDS} alt="Taxes Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    },
    {
      title: "Unit",
      description: t('unit_slab'),
      to: "/settings/customisation/unit/",
      hasAccess: true,
      moduleIcon: <img src={TDS} alt="Unit Type Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    },
    {
      title: "Warehouse",
      description: t('warehouse_des'),
      to: "/settings/customisation/warehouse/",
      hasAccess: true,
      moduleIcon: <img src={TDS} alt="warehouse Icon" style={{ width: '1.4rem', height: '1.4rem' }} />,

    },
  ]

  dispatch(headerTitles({
    title: 'modules',
    description: ""
  }))
  return (
    <>

      <div className="custom_module">
        <div className="container_module">
          <div className="module_header">
            <div className="left_header">
              {t('module_name')}
            </div>
            <div className="right_header">
              {t('desc')}
            </div>
          </div>
          <div className="module_content">
            <ModulesSubMenu submenuheader={t('estimate')} submenuColumn={EstimatesDataColumn} hasAccess={hasAccess("expense_settings", "read") || hasAccess("reports_settings", "read") || hasAccess("advances_settings", "read") || hasAccess("trips_settings", "read")} />
            <ModulesSubMenu submenuheader={t('Sales')} submenuColumn={SalesDataColumn} hasAccess={hasAccess("estimates_settings", "read") || hasAccess("sales_orders_settings", "read") || hasAccess("invoices_settings", "read") || hasAccess("credit_note_settings", "read") || hasAccess("payment_received_settings", "read") || hasAccess("delivery_challan_settings", "read")} />
            <ModulesSubMenu submenuheader={t('Purchases')} submenuColumn={PurchaseDataColumn} hasAccess={hasAccess("purchase_orders_settings", "read") || hasAccess("bills_settings", "read") || hasAccess("payment_made_settings", "read") || hasAccess("material_receipt_settings", "read") || hasAccess("quality_check_settings", "read") || hasAccess("returns_settings", "read")} />
            <ModulesSubMenu submenuheader={t('general')} submenuColumn={GeneralDataColumn} hasAccess={hasAccess("projects", "read") || hasAccess("cost_center", "read") || hasAccess("merchants", "read") || hasAccess("items", "read")} />
            <ModulesSubMenu submenuheader={t('general_taxes')} submenuColumn={TaxesDataColumn} hasAccess={hasAccess("tds", "read") || hasAccess("taxes", "read")} />
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomModules