import { CloudUploadOutlined, PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';

export const menuPaids = (history) => {
  return [
    {
      label: 'Summary',
      key: 'summary',
      disabled: true,
    },
    {
      label: 'Purchase Orders',
      key: 'purchaseOrders',
      disabled: true,
    },
    {
      label: 'Bills',
      key: 'bills',
      children: [
        {
          label: 'Approvals',
          key: 'approvals',
          onClick: () => history.push("/purchases/bills/approval")
        },
        {
          label: 'My Bills',
          key: 'myBills',
          onClick: () => history.push("/purchases/bills")
        }
      ]
    },
    {
      label: 'Vendors',
      key: 'vendors',
      onClick: () => history.push("/purchases/vendors/")
    },
    {
      label: 'Vendor Credits',
      key: 'vendorCredits',
      disabled: true,
    },
    {
      label: 'Receipt',
      key: 'receipt',
      disabled: true,
    },
  ];
}

export const optionsBills = (history) => [
  {
    label: (
      <div className='table-menu-item'>
        <span className='menu-label'>
          From Computer
        </span>
      </div>
    ),
    key: '1',
    icon: (
      <div>
        <PlusCircleFilled className="pp-icon-18" />
      </div>
    ),
  },
  {
    label: (
      <div className='table-menu-item'>
        <span
          className='menu-label'
        >
          Upload Vendors
        </span>
      </div>
    ),
    key: '2',
    icon: (
      <div>
        <CloudUploadOutlined className="pp-icon-18" />
      </div>
    ),
  },
];


export const formatDate = (date) => {
  return moment(date).format("DD MMM, YYYY");
}
