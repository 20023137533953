import { Button, message, Modal, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { expenses } from "../../actions/expenses/expenses";
import { userReportLinkExp } from "../../actions/reports/reports";
import block from "../../assets/icons/block.png";
import warn from "../../assets/icons/warning.png";
import { apis } from "../../config/APIs";
import endpoint from "../../config/Axios";
import { objectToFormData } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import TableWrap from "../Common/Table/Table";
import { useTranslation } from "react-i18next";
import "./report.css";
import moment from "moment";
import endpointPayApiBeta from "../../config/AxiosPayApiBeta";
import endpointPayExpenseBeta from "../../config/AxiosPayExpense";

message.config({
  maxCount: 3,
});

const Title = Typography.Title;

const LinkExpenses = (props) => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = React.useState([]);
  const [violations, setViolations] = React.useState([]);
  const [payloadViolations, setpayloadViolations] = React.useState([]);
  const [showViolations, setShowViolations] = React.useState(false);
  const expensesResponse = useSelector(
    (state) => state.expenses.expenses || {}
  );

  React.useEffect(() => {
    dispatch(expenses({ filters: { status: ["saved"] } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: { data: transactions = [], total_count = 0 } = {} } =
    expensesResponse;
  const { t } = useTranslation();

  const columns = [
    {
      title: t("ex_id"),
      dataIndex: "transaction_number",
      key: "transaction_number",
    },
    {
      title: t("merchant"),
      dataIndex: "merchant",
      key: "merchant",
      // render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    },
    {
      title: t("dateAndTime"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <div>{moment(text).format("DD MMM YYYY HH:mm:ss")}</div>
      ),
    },
    {
      title: t("Amount"),
      dataIndex: "amount_cents",
      key: "amount_cents",
      render: (text) => (
        <div>
          <BaseCurrency /> {text}
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedData,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedData(selectedRowKeys);
    }
  };

  const handleClearList = () => {
    setSelectedData([]);
  };

  const handleAddExp = () => {
    const filteredViolations = payloadViolations
      ?.map((ele) =>
        ele?.policies?.find(
          (itm) =>
            (itm.policy_action === "WARNING" && itm?.errors?.length > 0) ||
            (itm.policy_action === "BLOCKING" && itm?.errors?.length === 0)
        )
          ? ele
          : undefined
      )
      .filter((ele) => ele);

    const payload = {};
    payload.transaction_ids = violations?.length
      ? filteredViolations?.map((ele) => ele?.transaction_id)
      : selectedData;
    payload.report_id = props.report_id;
    dispatch(userReportLinkExp({ ...payload }));
    setSelectedData([]);
    setShowViolations(false);
  };

  return (
    <div className="expenses-table">
      {selectedData.length ? (
        <>
          <Button
            type="default"
            ghost
            className="approve-add-btn mb1"
            onClick={() => handleClearList()}
          >
            {t("clear_selection")}
          </Button>
          <Button
            onClick={() => {
              endpointPayExpenseBeta
                .post(apis.validate_expense, {
                  transaction_ids: selectedData,
                })
                .then((res) => {
                  let txns = res.data?.data?.transactions || [];
                  setpayloadViolations(txns);
                  let policies = [];
                  txns?.forEach((ele) => {
                    policies = [
                      ...policies,
                      ...ele?.policies?.map(
                        (e) =>
                          (e.policy_action === "BLOCKING" ||
                            e.policy_action === "WARNING") &&
                          e.errors?.length
                      ),
                    ];
                  });

                  if (policies?.filter((ele) => ele)?.length) {
                    setShowViolations(true);
                    setViolations(txns);
                  } else {
                    handleAddExp();
                  }
                });
            }}
            className="floatRight ml1 primaryButton"
          >
            {t("add")} {selectedData.length > 1 ? selectedData.length : ""}{" "}
            {t("Expenses")}
          </Button>
        </>
      ) : null}

      <Modal
        title={<Title level={4}>{t("policy_violation")}</Title>}
        wrapClassName="exp-table"
        visible={showViolations}
        onCancel={() => {
          setShowViolations(false);
        }}
        // footer={[]}
        // footer={
        //   violations
        //     ?.map(
        //       (ele) =>
        //         ele.policies.some((policy) => policy.errors.length > 0) &&
        //         ele?.policies?.find((itm) => itm.policy_action === "WARNING")
        //     )
        //     .filter((ele) => ele).some((ele) => ele?.errors?.length > 0)
        //     ? [
        //         <Button onClick={handleAddExp} type="primary">
        //           Continue
        //         </Button>,
        //         <Button className="cancelButton ml1 mt1" onClick={() => setShowViolations(false)}>
        //           Cancel
        //         </Button>,
        //       ]
        //     : []
        // }
        footer={
          violations
            ?.map((ele) =>
              ele.policies.find(
                (policy) =>
                  policy.errors.length > 0 &&
                  policy.policy_action === "BLOCKING"
              )
            )
            .filter((ele) => ele)?.length > 0
            ? []
            : [
                <Button onClick={handleAddExp} type="primary">
                  Continue
                </Button>,
                <Button
                  className="cancelButton ml1 mt1"
                  onClick={() => setShowViolations(false)}
                >
                  Cancel
                </Button>,
              ]
        }
      >
        <div className="violations">
          {violations?.map((ele, idx) =>
            ele.policies.some((policy) => policy.errors.length > 0) ? (
              <div className="item">
                {idx + 1}. {ele.transaction_number} -
                {ele.policies?.map((itm) =>
                  itm.errors.map((e) => (
                    <span
                      className={itm?.policy_action === "WARNING" ? "warn" : ""}
                    >
                      <img
                        src={itm?.policy_action === "WARNING" ? warn : block}
                        alt=""
                        width={12}
                      />{" "}
                      {e}
                    </span>
                  ))
                )}
              </div>
            ) : null
          )}
        </div>
      </Modal>
      <TableWrap
        rowKey="id"
        columns={columns}
        data={transactions?.filter(
          (item) => !props?.transactions_id?.includes(item?.id)
        )}
        className="tableStyled"
        pagination={{
          total: total_count || 0,
          onChange: (pagee) => {
            dispatch(
              expenses({
                params: { page_number: pagee },
                filters: { status: ["saved"] },
              })
            );
          },
        }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
    </div>
  );
};

export default LinkExpenses;
