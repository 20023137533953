import {
  Button,
  Checkbox,
  Collapse, Form, Input, message, Radio, Select, Typography
} from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateExpensePolicies, create_expense_policies, EditExpensePolicies, edit_expense_policies, expense_policies_list } from "../../../../actions/expenses/expenses";
import * as yup from 'yup'
import { MinusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import useScrollToError from "../../../../config/useScrollToError";

const { Panel } = Collapse;

//  import { validationRules } from '../../../utils';
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;


const ValidationSchema = yup.lazy(values => yup.object().shape({
  name: yup.string().required("Policy name is required."),
  description: yup.string().required("Description is required."),
  defineCriteria: yup.array()
    .of(
      yup.object().shape({
        field_data_type: yup.string()
          .required('Field data type is required'),
        field_name: yup.string()
          .required('Field name is required'),
        field_value: yup.string()
          .required('Field value is required'),
        field_operator: yup.string()
          .required('Field operator is required'),
        field_condition: yup.string()
          .required('Field condition is required'),
      })
    ),
  defineConditions: yup.array().of(yup.object().shape({
    approval_type: yup.string().required(),
    data: yup.mixed().when('approval_type', {
      is: "bill_attached",
      then: yup.boolean().required(),
      otherwise: yup.array().of(yup.object().shape({
        approval_type_value: yup.string().required(),
        approval_type_condition: yup.string().required()
      }))
    })
  })).compact((v) => {
    if (v.approval_type === "bill_attached") {
      return v.data === "" ? false : true
    }
  }),
  policy_action: yup.mixed().required(),
  // employee_ids: yup.array().of(yup.string()).test('employee_ids', "Rule is required", () => {
  //   if (values.employee_ids?.length === 0 && !values.designation_id && !values.cost_center_id && !values.project_id) {
  //     return false
  //   }
  //   return true
  // })
}))

const AddExpense = (props) => {
  const dispatch = useDispatch();
  const cardPoliciesRes = useSelector((state) => state?.cards?.cardPolicies);
  const createRes = useSelector((state) => state?.expenses?.create_policies);
  const editRes = useSelector((state) => state?.expenses?.edit_policies);
  const getDetailsById = useSelector((state) => state?.expenses?.show_policy?.data?.data)
  const [chan_key, set_chan_key] = useState("employees");
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      employee_ids: [],
      is_blocked: "",
      is_warning: "",
      is_enable: true,
      policy_action: "",
      type: "expense", // report
      defineConditions: [
        {
          approval_type: "",
          // data: [{
          //   amount: "",
          //   condition: ""
          // }]
          approval_type_value: "",
          approval_type_condition: "",
        }
      ],
      defineCriteria: [{
        field_data_type: "string",
        field_name: "created_by",
        field_name_display: "",
        field_value: null,
        field_operator: "is",
        field_priority: 1,
        field_condition: "when"
      }],
      designation_id: "",
      cost_center_id: "",
      project_id: "",
      bill_attached: false
    },
    validationSchema: ValidationSchema,
    onSubmit: (vals) => {

      if (vals.designation_id && chan_key === "designation_id") {
        delete vals.employee_ids
        delete vals.cost_center_id
        delete vals.project_id
      }
      if (vals.cost_center_id && chan_key === "cost_center_id") {
        delete vals.employee_ids
        delete vals.designation_id
        delete vals.project_id
      }
      if (vals.project_id && chan_key === "project_id") {
        delete vals.employee_ids
        delete vals.designation_id
        delete vals.cost_center_id
      }
      if (vals.employee_ids?.length && chan_key === "employee_ids") {
        delete vals.designation_id
        delete vals.cost_center_id
        delete vals.project_id
      }
      vals.defineCriteria[0].field_name = chan_key
      // delete vals.action
      if (vals.defineConditions?.length > 0) {
        vals.type = "expense"
        vals.defineConditions.forEach(ele => {
          if (ele.field === "report_total") {
            vals.type = "report"
            return;
          }
        })
      }
      vals.defineConditions = vals.defineConditions?.map((ele, ind) => {
        return ({
          ...ele,
          priority: ind + 1,
        }
        )
      })
      vals.priority = 1
      if (vals.id) {
        delete vals.data;
        delete vals.approval_type;
        delete vals.expense_policy_users;
        delete vals.condition_data;
        delete vals.expense_policy_fields;
        vals.expense_policy_id = vals.id;
        dispatch(edit_expense_policies({ ...vals }))
      } else {
        dispatch(create_expense_policies({ ...vals }));
      }
    },
  });

  // Use the custom hook
  useScrollToError(Object.keys(formik?.errors)[0], formik?.errors, formik?.submitCount);
  const key_map = {
    employees: "employees",
    created_by: "employees",
    designation_id: "departments",
    cost_center_id: "cost_center",
    project_id: "projects",
  }
  useEffect(() => {
    if (createRes?.data?.error === false || editRes?.data?.error === false) {
      dispatch(expense_policies_list());
      props.handleCancel();
      formik.resetForm();
      dispatch({
        type: CreateExpensePolicies.RESET
      })
      dispatch({
        type: EditExpensePolicies.RESET
      })
      message.success(
        <span className="messageText">{createRes.data?.message || editRes.data?.message}</span>
      );
    }

    if (createRes?.data?.status === false) {
      dispatch({
        type: CreateExpensePolicies.RESET
      })
      message.error(
        <span className="messageText">{createRes.data?.message}</span>
      );
    }

    if (editRes?.data?.status === false) {
      dispatch({
        type: EditExpensePolicies.RESET
      })
      message.error(
        <span className="messageText">{editRes.data?.message}</span>
      );
    }
    // eslint-disable-next-line
  }, [createRes, editRes]);

  useEffect(() => {
    console.log(props.selectedRow?.condition_data);
    // if (props.selectedRow?.id) {
    //   let fieldsData = props.selectedRow.condition_data.map(ele => ({
    //     approval_type: Object.keys(ele)?.[0],
    //     data: ele[Object.keys(ele)?.[0]]
    //   }))
    //   const bill_attached = fieldsData?.find(ele => ele.approval_type === "bill_attached")?.data
    //   formik.setValues({
    //     ...props.selectedRow,
    //     bill_attached,
    //     defineConditions: props.selectedRow.condition_data.map(ele => ({
    //       approval_type: Object.keys(ele)?.[0],
    //       data: ele[Object.keys(ele)?.[0]],
    //       // employee_ids: [props.selectedRow?.expense_policy_users?.[0]?.id] || [],
    //       designation_id: props.selectedRow?.designation_id || "",
    //       cost_center_id: props.selectedRow?.cost_center_id || "",
    //       project_id: props.selectedRow?.project_id || "",
    //       policy_action: "act"
    //     }))
    //   })
    //   if (props.selectedRow?.expense_policy_users?.length > 0) {
    //     set_chan_key("employee_ids")
    //     formik.setFieldValue("employee_ids", [props.selectedRow?.expense_policy_users?.[0]?.id])
    //   }
    //   if (props.selectedRow?.designation_id) {
    //     set_chan_key("designation_id")
    //   }
    //   if (props.selectedRow?.cost_center_id) {
    //     set_chan_key("cost_center_id")
    //   }
    //   if (props.selectedRow?.project_id) {
    //     set_chan_key("project_id")
    //   }
    //   formik.setFieldValue("action", "actions")

    // } else {
    //   formik.resetForm()
    // }
    if (props?.selectedRow?.id) {
      formik.setValues({
        id: props?.selectedRow?.id,
        name: props?.selectedRow?.name,
        description: props?.selectedRow?.description,
        policy_action: props?.selectedRow?.policy_action,
        employee_ids: props?.selectedRow?.defineCriteria[0]?.field_value,
        defineConditions: props?.selectedRow?.defineConditions?.map(ele => ({
          id: ele?.id,
          approval_type: ele?.approval_type,
          approval_type_condition: ele?.approval_type_condition,
          approval_type_value: ele?.approval_type_value,
        })),
        defineCriteria: props?.selectedRow?.defineCriteria?.map(ele => ({
          id: ele?.id,
          field_condition: ele?.field_condition,
          field_data_type: ele?.field_data_type,
          field_name: ele?.field_name,
          field_priority: ele?.field_priority,
          field_name_display: ele?.field_name_display,
          field_operator: ele?.field_operator,
          field_value: ele?.field_value,
        }))
      })
      if (props?.selectedRow?.defineCriteria?.[0]?.field_name) {
        const field_name = props?.selectedRow?.defineCriteria?.[0]?.field_name
        set_chan_key(field_name === "created_by" ? "employees" : field_name)
      } else {
        set_chan_key("employees")
      }
    }
    // eslint-disable-next-line
  }, [props.selectedRow])

  const handleCancelButton = () => {
    formik.resetForm();
    props.handleCancel();
  };

  return (
    <>
      <Form
        layout="vertical"
        // form={form}
        onFinish={formik.handleSubmit}
        className="modal-form"
      >
        <Form.Item label={t('policy_name')} required={true}>
          <Input
            placeholder={t('default_policy')}
            onChange={formik.handleChange}
            name="name"
            value={formik.values.name}
          />
          {formik.touched.name && <div className="errorMsg">{formik.errors.name}</div>}
        </Form.Item>
        <Form.Item label={t('desc')} required={true}>
          <TextArea
            placeholder={t('enter_desc')}
            autoSize={{ minRows: 3, maxRows: 5 }}
            onChange={formik.handleChange}
            value={formik.values.description}
            name="description"
          />
          {formik.touched.description && <div className="errorMsg">{formik.errors.description}</div>}
        </Form.Item>
        <div className="dividerText">{t('define_conditions')}</div>
        <Form.Item name="checkboxGroup" style={{ marginBottom: "5px" }}>
          <Collapse
            accordion
            className="customCollapse"
            defaultActiveKey={["1"]}
          >
            <Panel header={<b>{t('define_users')} ( {t('if_any')} )</b>} key="1">
              <Text>{t('choose_who_will')}</Text>
              <div className="viewPolicyCards">
                1<div className="darkbox">{t('rule_will')}</div>
                <div className="lightbox">
                  <Select
                    placeholder={t('select')}
                    onChange={(val) => {
                      // formik.setFieldValue(chan_key, chan_key === "employee_ids" ? [] : "")
                      formik.setFieldValue(`defineCriteria.0.field_name`, val === "employees" ? "created_by" : val)
                      formik.setFieldValue(`defineCriteria.0.field_value`, "");
                      // formik.setFieldValue('defineCriteria[0].field_name', val)
                      set_chan_key(val)
                    }}
                    value={chan_key}
                  // value={formik?.values?.defineCriteria[0]?.field_name}
                  >
                    <Option value="employees">{t('specific_user')}</Option>
                    <Option value="designation_id">
                      {t('specific_grade')}
                    </Option>
                    <Option value="cost_center_id">{t('cost_center')}</Option>
                    <Option value="project_id">{t('project')}</Option>
                  </Select>
                </div>
                <div className="lightbox">
                  <Select
                    placeholder={t('select')}
                    onChange={(val) => {
                      formik.setFieldValue(`defineCriteria.0.field_value`, val)
                    }}
                    value={formik.values.defineCriteria[0].field_value}
                    showSearch
                    filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                    options={cardPoliciesRes?.data?.data?.[key_map[chan_key]]?.map((ele) => ({ label: ele.title || ele.name || ele.email, value: ele.id }))}
                  />
                </div>
              </div>
              <div className="errorMsg">{formik.touched.defineCriteria && formik.errors.defineCriteria?.length > 0 && `Please select ${key_map[chan_key]} from dropdown.`}</div>
            </Panel>
            <div className="errorMsg">{formik.touched.employee_ids && formik.errors.employee_ids}</div>
          </Collapse>
        </Form.Item>
        <Collapse accordion className="customCollapse" defaultActiveKey={["3"]}>
          <Panel header={<b>{t('define_the_limit')} ( {t('if_any')} )</b>} key="3">
            <Text>{t('create_limits')}</Text>
            {formik?.values?.defineConditions?.map((ele, i) => (
              <>
                <div className="viewPolicyCards">
                  {i + 1}
                  <div className="darkbox">{t('when')}</div>
                  <Select
                    placeholder={t('select')}
                    onChange={(val) => {
                      formik.setFieldValue(`defineConditions[${i}].approval_type`, val)
                      formik.setFieldValue(`defineConditions[${i}].approval_type_condition`, "")
                      if (val === "bill_attached") {
                        formik.setFieldValue(
                          `defineConditions[${i}].data`,
                          true
                        )
                        formik.setFieldValue(
                          `bill_attached`,
                          true
                        )
                      } else {
                        if (formik?.values?.bill_attached) {
                          // formik.setFieldValue(
                          //   `bill_attached`,
                          //   true
                          // )
                          formik.setFieldValue(
                            `defineConditions[${i}].data`,
                            ""
                          )
                        }
                        // formik.setFieldValue(
                        //   `conditions[${i}].data`,
                        //   [{
                        //     amount: "",
                        //     condition: ""
                        //   }]
                        // )
                      }
                    }
                    }
                    value={formik.values.defineConditions[i].approval_type}
                  >
                    <Option value="EXPENSE_AMOUNT">{t('expense_amount')}</Option>
                    <Option value="REPORT_TOTAL">{t('report_total')}</Option>
                    {(!formik?.values?.bill_attached || formik.values.defineConditions[i].data === true) &&
                      <Option value="BILL_ATTACHED">{t('bill_attached')}</Option>}
                  </Select>
                  {formik.values.defineConditions?.[i]?.approval_type !== "BILL_ATTACHED" ?
                    <>
                      <div className="darkbox">{t('for')}</div>
                      <Select
                        placeholder="Select "
                        // onChange={(val) =>
                        //   formik.setFieldValue(
                        //     `conditions[${i}].data[0].condition`,
                        //     val
                        //   )
                        // }
                        onChange={(val) =>
                          formik.setFieldValue(
                            `defineConditions[${i}].approval_type_condition`,
                            val
                          )
                        }
                        // value={formik.values.conditions?.[i]?.data?.[0]?.condition}
                        value={formik.values.defineConditions[i]?.approval_type_condition}
                      >
                        <Option value="LESS THAN">{t('less_than')}</Option>
                        <Option value="GREATER THAN">{t('greater_than')}</Option>
                        <Option value="LESS THAN OR EQUAL">{t('less_than_or')}</Option>
                        <Option value="GREATER THAN OR EQUAL">{t('greater_than_or')}</Option>
                        <Option value="NOT EQUAL">{t('not_equal')}</Option>
                      </Select>
                    </> : null}
                </div >
                {
                  formik.values.defineConditions?.[i]?.approval_type !== "BILL_ATTACHED" &&
                  <div className="viewPolicyCards">
                    {t('Amount')}
                    <Input
                      name="approval_type_value"
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) {
                          return;
                        }
                        // formik.setFieldValue(
                        //   `conditions[${i}].data[0].amount`,
                        //   e.target.value
                        // );
                        formik.setFieldValue(
                          `defineConditions[${i}].approval_type_value`,
                          e.target.value
                        );

                      }}
                      // value={formik.values.conditions?.[i]?.data?.[0]?.amount}
                      value={formik.values.defineConditions[i]?.approval_type_value}
                    />
                    {i > 0 && <MinusCircleOutlined className="ml3 cursor" onClick={() => {
                      let defineConditions = formik.values.defineConditions
                      defineConditions.splice(i, 1)
                      formik.setFieldValue("defineConditions", defineConditions)
                    }} />}
                  </div>
                }
              </>
            ))}
            <Button
              type="default"
              ghost
              className="approve-add-btn mt1"
              onClick={() => {
                formik.setFieldValue(`defineConditions`, [
                  ...formik.values.defineConditions,
                  {
                    approval_type: "",
                    // data: [{
                    //   amount: "",
                    //   condition: ""
                    // }]
                    approval_type_value: "",
                    approval_type_condition: ""
                  }
                ]);
              }}
            >
              {t('add_one_more')}
            </Button>
            <div className="errorMsg">{formik.touched.defineConditions && formik.errors.defineConditions?.length > 0 && t('expense_should_Empty')}</div>

          </Panel>
        </Collapse>
        <Collapse accordion className="customCollapse" defaultActiveKey={["4"]}>
          <Panel header={<b>{t('actions')}</b>} key="4">
            <Text>
              {t('select_actions')}
            </Text>
            <Radio.Group name="policy_action" onChange={formik.handleChange} value={formik.values.policy_action}>
              <Radio checked={formik.values.policy_action === "WARNING"} value="WARNING">Warning</Radio>
              <Radio checked={formik.values.policy_action === "BLOCKING"} value="BLOCKING">Blocked</Radio>
            </Radio.Group>
            {/* <Radio name="action" checked={formik.values.action} value="Blocked" onChange={(e) => {
              formik.setFieldValue("is_blocked", true)
              formik.setFieldValue("is_warning", false)
              // e.target.checked ?
              // formik.setFieldValue("action", "Blocked") : formik.setFieldValue("action",e.target.value)
              formik.setFieldValue("action", e.target.checked)
            }}>{t('blocking')}</Radio>
            <Radio name="action" checked={formik.values.action} value="Warning" onChange={(e) => {
              formik.setFieldValue("is_warning", true)
              formik.setFieldValue("is_blocked", false)
              formik.setFieldValue("action", e.target.checked)
              // e.target.checked ?
              // formik.setFieldValue("action", "Warning") : formik.setFieldValue("action",e.target.value)
              
            }}>{t('warning')}</Radio> */}
          </Panel>
          <div className="errorMsg">{formik.touched.policy_action && formik.errors.policy_action && t('block_msg')}</div>

        </Collapse>

        <Button
          key="1"
          htmlType="submit"
          className="formButton mt3 primaryButton"
          disabled={createRes?.loading}
        >
          {t('save')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt3"
          onClick={() => {
            formik.resetForm()
            handleCancelButton()
          }}
        >
          {t('cancel')}
        </Button>
      </Form >
    </>
  );
};

export default AddExpense;

