import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaDocuments, sagaFunctions, sagaFunctionsSettings } from "../../utils";
import {
  Employees,
  EmployeeById,
  AddEmployees,
  DeleteEmployees,
  UploadEmployeeDocument,
  GetEmployeeDocument,
  DeleteEmployeeDocument,
  EmployeesStatus,
  CreateEmployee,
  UpdateEmployees,
  UploadBulk,
  DownloadBulkLink,
  GetUserFields,
} from "./employees";

function getEmployees(req) {
  const isRole = req?.payload?.type;
  let parentIdParams;
  let str = ""
  req?.payload?.roleData?.forEach((ele, idx) => {
    str += `role[${idx}]=${ele}&`
  })
  const params = isRole ? `?${str}` : ""
  if (isRole) {
    if (localStorage?.getItem("switchBranch")) {
      parentIdParams = `&parent_id=${localStorage?.getItem("switchBranch")}`
    } else {
      parentIdParams = ""
    }
  } else {
    if (localStorage?.getItem("switchBranch")) {
      parentIdParams = `?parent_id=${localStorage?.getItem("switchBranch")}`
    } else {
      parentIdParams = ""
    }
  }
  delete req?.payload?.type;
  delete req?.payload?.roleData;
  return sagaFunctionsSettings(
    Employees,
    "post",
    apis.employees + params,
    req.payload,
    ["search"].includes(req.payload)
      ? {
        Authorization: localStorage.getItem("token"),
      }
      : {
        Authorization: localStorage.getItem("token"),
      }
  )();
}

function createEmployee(req) {
  return sagaFunctionsSettings(
    CreateEmployee,
    "post",
    apis.createEmployee,
    req.payload
  )();
}

function employeebyID(req) {
  return sagaFunctionsSettings(
    EmployeeById,
    "get",
    apis.employeebyID,
    req.payload
  )();
}

function updateEmployeesStatus(req) {
  return sagaFunctions(
    EmployeesStatus,
    "post",
    apis.employeesStatus,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function uploadBulkEmployees(req) {
  return sagaFunctionsSettings(UploadBulk, "post", apis.uploadUsers, req.payload, {
    // "Content-Type": "multipart/formdata",
    // Authorization: localStorage.getItem("token"),
  })();
}

function downloadBulk() {
  return sagaFunctionsSettings(DownloadBulkLink, "get", apis.importUserTemplate, {
    // Authorization: localStorage.getItem("token"),
  })();
}

function getUserFields() {
  return sagaFunctionsSettings(GetUserFields, "get", apis.userFields, {
    // Authorization: localStorage.getItem("token"),
  })();
}

function addEmployees(req) {
  return sagaFunctionsSettings(AddEmployees, "post", apis.createEmployees, req.payload, {
    // "Content-Type": "multipart/formdata",
    Authorization: localStorage.getItem("token"),
  })();
}

function updateEmployees(req) {
  return sagaFunctionsSettings(
    UpdateEmployees,
    "post",
    apis.updateEmployees,
    req.payload,
    {
      // "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function deleteEmployees(req) {
  return sagaFunctionsSettings(
    DeleteEmployees,
    "post",
    apis.deleteEmployee,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function uploadEmployeeDocument(req) {
  return sagaDocuments(
    UploadEmployeeDocument,
    "post",
    apis.createDocument,
    req.payload,
    {
      "Content-Type": "multipart/formdata"
    }
  )();
}

function getEmployeeDocument(req) {
  return sagaDocuments(
    GetEmployeeDocument,
    "get",
    apis.getDocument,
    req.payload,
    {
      "Content-Type": "multipart/formdata"
    }
  )();
}

function deleteEmployeeDocument(req) {
  let record_id = req.payload.record_id
  delete req.payload.record_id
  return sagaDocuments(
    DeleteEmployeeDocument,
    "delete",
    apis.deleteDocument + `?record_id=${record_id}`,
    req.payload,
  )();
}

export function* employeesWatcher() {
  yield takeLatest(Employees.REQUEST, getEmployees);
  yield takeLatest(EmployeesStatus.REQUEST, updateEmployeesStatus);
  yield takeLatest(AddEmployees.REQUEST, addEmployees);
  yield takeLatest(EmployeeById.REQUEST, employeebyID);
  yield takeLatest(UpdateEmployees.REQUEST, updateEmployees);
  yield takeLatest(DeleteEmployees.REQUEST, deleteEmployees);
  yield takeLatest(UploadEmployeeDocument.REQUEST, uploadEmployeeDocument);
  yield takeLatest(GetEmployeeDocument.REQUEST, getEmployeeDocument);
  yield takeLatest(DeleteEmployeeDocument.REQUEST, deleteEmployeeDocument);
  yield takeLatest(DownloadBulkLink.REQUEST, downloadBulk);
  yield takeLatest(UploadBulk.REQUEST, uploadBulkEmployees);
  yield takeLatest(GetUserFields.REQUEST, getUserFields);
}
