import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import down from "../../assets/ippo_icons/down.svg";

const InputSelect = ({
  options,
  values,
  onChange,
  setIsAll,
  isAll,
  isFilter,
  fil_key,
  isSalesPurchases,
}) => {
  const [state, setState] = useState({
    toggle: false,
    // isAll: false,
  });
  const [filterVal, setFilterVal] = useState("");
  const { t } = useTranslation();
  const itemsRef = React.useRef();

  const handleClickOutside = (event) => {
    if (
      (itemsRef.current && itemsRef.current.contains(event.target)) ||
      event.target.className === "inputSelect"
    ) {
      return;
    }
    state.toggle &&
      setState({
        ...state,
        toggle: false,
      });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [state.toggle]);

  const checkboxValue = (item) => {
    return (
      <div className="isFlex" key={item.value}>
        <input
          type="checkbox"
          checked={values?.includes(item.value)}
          onChange={(e) => {
            let vals = [...values];
            if (e.target.checked) {
              vals.push(item.value);
            } else {
              let index = vals.indexOf(item.value);
              vals.splice(index, 1);
            }
            setFilterVal("");

            onChange(vals);
            setState({
              ...state,
              [item.label]: e.target.checked,
            });

            if (vals.length !== options.length) {
              setIsAll((isA) => ({
                ...isA,
                [isSalesPurchases && fil_key === "status"
                  ? "invoice_status"
                  : fil_key]: false,
              }));
            }

            if (vals.length === options.length && options.length > 1) {
              setIsAll((isA) => ({
                ...isA,
                [isSalesPurchases && fil_key === "status"
                  ? "invoice_status"
                  : fil_key]: true,
              }));
            }
          }}
          id={item.value}
        />

        <span className="txnCompanyLogo">
          <label htmlFor={item.value} className="reconBankName">
            <span>{item.label}</span>
          </label>
        </span>
      </div>
    );
  };

  const display = state.toggle ? "block" : "none";
  return (
    <div ref={itemsRef} className="inputSelect">
      <span
        className="selectedItem"
        onClick={() => setState({ ...state, toggle: !state.toggle })}
      >
        <span className="show-all">
          {isAll?.[
            isSalesPurchases && fil_key === "status"
              ? "invoice_status"
              : fil_key
          ]
            ? "All"
            : options
                ?.filter((item) => values?.includes(item.value))
                .map((item) => item.label)
                .join(", ")}
        </span>
        <img src={down} className="icon" />
      </span>
      <div className="listOfItems" style={{ display }}>
        {isFilter && state.toggle && (
          <div className="isFlex">
            <input
              className="inputFieldHover isInputField w-100"
              value={filterVal}
              onChange={(e) => {
                setFilterVal(e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              placeholder={t("search_text")}
            />
          </div>
        )}
        {options && options.length > 0 && filterVal === "" ? (
          <div className="isFlex">
            <input
              type="checkbox"
              className="inputCheckbox"
              name="all"
              checked={
                isAll?.[
                  isSalesPurchases && fil_key === "status"
                    ? "invoice_status"
                    : fil_key
                ]
              }
              onChange={(e) => {
                if (e.target.checked) {
                  onChange(
                    options.map((item) => {
                      return item.value;
                    })
                  );
                } else {
                  onChange([]);
                }
                setIsAll((isA) => ({
                  ...isA,
                  [isSalesPurchases && fil_key === "status"
                    ? "invoice_status"
                    : fil_key]: e.target.checked,
                }));
              }}
              id={"all"}
            />
            <label htmlFor={"all"}>
              <span>{t("all")}</span>
            </label>
          </div>
        ) : (
          (options?.filter((ele) =>
            ele.label.toLowerCase().includes(filterVal.toLowerCase())
          ).length === 0 ||
            (options && options.length === 0)) && (
            <div className="noRecords">
              <span>{t("no_records_found")}</span>
            </div>
          )
        )}

        {filterVal
          ? options
              ?.filter((ele) =>
                ele.label.toLowerCase().includes(filterVal.toLowerCase())
              )
              ?.map((item) => checkboxValue(item))
          : options?.map((item) => checkboxValue(item))}
      </div>
    </div>
  );
};

export default InputSelect;
