import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaAdminFunctions, sagaFunctions, sagaNotificationFunctions } from "../../utils";
import {
  Login,
  Logout,
  ResetPassword,
  ChangePassword,
  ForgotPassword,
  Notifications,
  RequestDemo
} from "./auth";

function login(req) {
  return sagaAdminFunctions(Login, "post", apis.login, req.payload, {
    "X-Encrypt": true,
    deviceType: "web"
  }, "admin")();
}

function requestDemo(req) {
  return sagaFunctions(RequestDemo, "post", apis.inquiry, req.payload,{},"admin")();
}

function notifications(req) {
  return sagaNotificationFunctions(Notifications, "get", apis.notifications, req.payload)();
}

function logout() {
  return sagaFunctions(Logout, "post", apis.logout, {}, {}, "admin")();
}

function forgotPassword(req) {
  return sagaAdminFunctions(ForgotPassword, "post", apis.forgotPassword, req.payload, {
    // "Content-Type": "multipart/formdata",
  })();
}

function resetPassword(req) {
  return sagaAdminFunctions(ResetPassword, "post", apis.resetPassword, req.payload, {
    // "Content-Type": "multipart/formdata",
  })();
}

function changePassword(req) {
  return sagaFunctions(ChangePassword, "post", apis.changePassword, req.payload, {}, true)();
}


export function* loginWatcher() {
  yield takeLatest(Login.REQUEST, login);
  yield takeLatest(RequestDemo.REQUEST, requestDemo);
  yield takeLatest(Logout.REQUEST, logout);
  yield takeLatest(ForgotPassword.REQUEST, forgotPassword);
  yield takeLatest(ResetPassword.REQUEST, resetPassword);
  yield takeLatest(ChangePassword.REQUEST, changePassword);
  yield takeLatest(Notifications.REQUEST, notifications);
}
