import React from "react";

import {
  Button, Checkbox, message, Modal, Space, Table, Typography
} from "antd";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUserFields, deleteUserFields, DeleteUserFields, getUserFields,
  updateUserFields, UpdateUserFields,getSingleUserFields
} from "../../../../actions/companySettings";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { objectToFormData } from "../../../../utils";
import AddCustomFields from "./AddCustomField";
const { Title } = Typography;

message.config({
  maxCount: 3,
});

const CustomFields = ({ hasWriteAccess }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setIsModalType] = React.useState("center");
  const [selectedId, setSelectedId] = React.useState("");
  const [userFieldsData, setUserFieldsData] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  // const [rolesData, setCustomFieldsData] = React.useState([]);
  const userFieldsResponse = useSelector(
    (state) => state.companySetting.getUserFields || {}
  );
  const deleteUserFieldsResponse = useSelector(
    (state) => state.companySetting.deleteUserFields || {}
  );
  const addUserFieldsResponse = useSelector(
    (state) => state.companySetting.addUserFields || {}
  );
  const updateUserFieldsResponse = useSelector(
    (state) => state.companySetting.updateUserFields || {}
  );
  

  

  
  console.log("userFieldsResponse",userFieldsResponse)


  const columns = [
    {
      title: t('field'),
      dataIndex: "column_name",
      key: "column_name",
      //   render: text => <a href="/">{text}</a>,
    },
    {
      title: t('column_type'),
      dataIndex: "column_type",
      key: "column_type",
      render : (text, render) =>{
        if (text == "integer"){
          return "Number"
        }else if(text === "string"){
          return "Text Box"
        }
        else if(text === "datetime"){
          return "Date & Time"
        }
        else if(text === "document"){
          return "Document"
        }
        else if (text === "dropdown") {
          return "Drop Down"
        }
        else{
          return text
        }
      }
    },
  ];
  React.useEffect(() => {
    // dispatch(getCustomFieldsPermissions({}));
    dispatch(getUserFields({}));
    dispatch(headerTitles({
      title: 'custom_fields',
      description: ""
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const {
      data: {
          data = [] ,error='',
        status = false,
        total_count = 0,
      } = {},
    } = userFieldsResponse;

    
    if (error===false) {
      setUserFieldsData(data);
      setTotalRecords(total_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFieldsResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", error = "", status = '' } = {} } =
      addUserFieldsResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getUserFields({}));
      dispatch({
        type: AddUserFields.RESET
      })
    }
    if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: AddUserFields.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserFieldsResponse]);
  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {}, success } =
      deleteUserFieldsResponse;
    console.log(res_message, status);

    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getUserFields({}));
      dispatch({
        type: DeleteUserFields.RESET
      })
    }
    if (success === false) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: DeleteUserFields.RESET
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserFieldsResponse]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = false,error="" } = {} } =
      updateUserFieldsResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(getUserFields({}));
      dispatch({
        type: UpdateUserFields.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserFieldsResponse]);

  const showModal = () => {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("center");
  };

  const showEditModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("right");
  };

  function handleButtonClick(e) {
    showModal();
  }

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t('delete_sure_field')}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() =>
            dispatch(deleteUserFields({ id: selectedId }))
          }
        >
          {t('delete')}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t('cancel')}
        </Button>
      </>
    );
  };

  const tableAction = [
    {
      title: t('mandatory'),
      dataIndex: "is_required",
      key: "is_required",
      render: (status, record) => (
        <Checkbox
          key={record.id}
          onChange={(e) =>(
            dispatch(
              updateUserFields(
                {
                  id: record.id,
                  is_enable: record.is_enable,
                  view_in_pdf: record.view_in_pdf,
                  is_required: e.target.checked,
                  column_type:record.column_type,
                  column_name:record.column_name,
                  default_values:record?.default_values,
                  data:{
                    max_size:record.data.max_size||"",
                    doc_formats:record.data.doc_formats||""
                  }
                }
              )
            ))
          }
          checked={status}
          disabled={!hasWriteAccess}
        ></Checkbox>
      ),
    },
    // {
    //   title: "Show in PDF",
    //   dataIndex: "view_in_pdf",
    //   key: "view_in_pdf",
    //   render: (status, record) => (
    //     <Checkbox
    //       key={record.id}
    //       onChange={(e) =>
    //         dispatch(
    //           updateUserFields(
    //             objectToFormData({
    //               id: record.id,
    //               is_enable: record.is_enable,
    //               is_required: record.is_required,
    //               view_in_pdf: e.target.checked,
    //             })
    //           )
    //         )
    //       }
    //       checked={status}
    //       disabled={!hasAccess("users_and_controls", "write")}
    //     ></Checkbox>
    //   ),
    // },
    {
      title: t('enable'),
      dataIndex: "is_enable",
      key: "is_enable",
      render: (status, record) => (
        
        <Checkbox
          key={record.id}
          onChange={(e) =>
            dispatch(
              updateUserFields(
                {
                  id: record.id,
                  view_in_pdf: record.view_in_pdf,
                  is_required: record.is_required,
                  is_enable: e.target.checked,
                  column_type:record.column_type,
                  column_name:record.column_name,
                  default_values:record?.default_values,
                  data:record?.data
                }
              )
            )
          }
          
          checked={status}
          disabled={!hasWriteAccess}
        ></Checkbox>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button disabled={!hasWriteAccess} onClick={() => showEditModal(record.id)}>
            <img src={EditIcon} alt={"logo"} className="action-logo" />
          </button>
          <button disabled={!hasWriteAccess} onClick={() => showDeleteModal(record.id)}>
            <img src={DeleteIcon} alt={"logo"} className="action-logo" />
          </button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {isModalVisible && <Modal
        wrapClassName="custom-field"
        title={
          modalType === "center" ? (
            <>
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
              {t('delete_custom_field')}
            </>
          ) : (
            <Title level={4}>
              {selectedId ? t('edit_custom_field') : t('new_custom_field')} &nbsp;
              <span className="text-small">{t('enter_details')}</span>
            </Title>
          )
        }
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={
          modalType === "center"
            ? "center-aligned-modal"
            : "right-alinged-modal-small"
        }
      >
        {modalType === "center" ? (
          renderDeleteOption()
        ) : (
          isModalVisible && <AddCustomFields id={selectedId} handleCancel={handleCancel} />
        )}
      </Modal>}
      {<Button
        disabled={!hasWriteAccess}
        onClick={handleButtonClick}
        className="mb1 ml1 addNew primaryButton"
      >
        <img src={AddNewIcon} alt={"logo"} className="addNew-logo" />
        {t('addNew')}
      </Button>}
      <Table
        rowKey="id"
        columns={[...columns, ...tableAction]}
        dataSource={userFieldsData}
        className="settingsTable"
        pagination={{
          total: totalRecords || 0,
          onChange: (pagee) => dispatch(getUserFields({ page_number: pagee })),
        }}
      />
    </>
  );
};

export default CustomFields;
