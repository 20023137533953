import { ReducerFunctions } from "../../utils";
import {
  CreateCustomer,
  CreateInvoice,
  Customers,
  Invoices,
  SubmitInvoice,
  ViewCustomer,
  UploadProfile,
  ShowProfile,
  PortalTableData,
  PortalRecordDetails,
  PortalDashboard,
  PortalDashboardCumpanyList,
} from "./customers";

export const customers_reducer = ReducerFunctions(Customers, {});
export const create_customer_reducer = ReducerFunctions(CreateCustomer, {});
export const view_customer_reducer = ReducerFunctions(ViewCustomer, {});
export const create_invoice_reducer = ReducerFunctions(CreateInvoice, {});
export const invoices_reducer = ReducerFunctions(Invoices, {});
export const submit_invoice_reducer = ReducerFunctions(SubmitInvoice, {});
export const upload_profile_reducer = ReducerFunctions(UploadProfile, {});
export const show_profile_reducer = ReducerFunctions(ShowProfile, {});
export const portal_table_data_reducer = ReducerFunctions(PortalTableData, {});
export const portal_record_details_reducer = ReducerFunctions(
  PortalRecordDetails,
  {}
);
export const portal_dashboard_reducer = ReducerFunctions(PortalDashboard, {});
export const portal_dashboard_company_list_reducer = ReducerFunctions(
  PortalDashboardCumpanyList,
  {}
);
