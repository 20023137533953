import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  View,
  StyleSheet,
  Text,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import { getSymbol } from "../../../config/helper";
import { amountFormat } from "../../../utils";
import CreditPdf from "../../PDFTemplates/CreditPdf";
import InvoicePDF from "../../PDFTemplates/InvoicePDF";
import Payments from "../../PDFTemplates/Payment";
import PaymentReceivedTwo from "../../PDFTemplates/PaymentReceivedTwo";
import Purpleinv from "../../PDFTemplates/Purpleinv";
import PaymentReceived from "../../PDFTemplates/PaymentReceived";
import InvPDF from "../../PDFTemplates/invPDF";
import InvPDF2 from "../../PDFTemplates/invPDF2";
import useDocumentAxios from "../../../config/useDocument";
import { apis } from "../../../config/APIs";
const getInvoiceData = (isCN, type, invoiceInfo) => {
  const commonProperties = {
    balanceAmount: amountFormat(
      invoiceInfo?.due_balance_amount,
      invoiceInfo?.currency?.decimal_scale
    ),
    creditNoteDate: invoiceInfo?.invoice_date,
    SubTotal: invoiceInfo?.sub_total_amount,
    totalDiscount: amountFormat(
      invoiceInfo?.total_discount,
      invoiceInfo?.currency?.decimal_scale
    ),
    totalTax: amountFormat(
      invoiceInfo?.total_tax_amount,
      invoiceInfo?.currency?.decimal_scale
    ),
    totalAmount: amountFormat(
      invoiceInfo?.total_amount,
      invoiceInfo?.currency?.decimal_scale
    ),
    taxRates: getTaxRates(
      invoiceInfo?.invoice_items ?? invoiceInfo?.billing_items,
      invoiceInfo?.currency?.decimal_scale
    ),
    total_tax: invoiceInfo?.total_tax_amount,
    deliveryCharges: invoiceInfo?.delivery_charges
      ? invoiceInfo?.delivery_charges
      : 0,
    total_amount: invoiceInfo?.total_amount,
    lineItems: invoiceInfo?.invoice_items ?? invoiceInfo?.billing_items,
    tdsAmount: invoiceInfo?.tds_amount,
    gst: invoiceInfo?.company?.gst_no,
    address: invoiceInfo?.company?.address,
    terms: invoiceInfo?.invoice_payment_terms
      ? invoiceInfo?.invoice_payment_terms?.term_name
      : invoiceInfo?.vendor_payment_terms
      ? invoiceInfo?.vendor_payment_terms?.term_name
      : "custom",
    companyName: invoiceInfo?.company?.name,
    totAmt: invoiceInfo?.total_amount,
    refNo: invoiceInfo?.ref_po_no,
  };

  switch (true) {
    case isCN:
      return {
        ...commonProperties,
        CreditNo: invoiceInfo?.credit_debit_note_number,
        invoiceNo: invoiceInfo?.invoice_ref_number,
        poDue: invoiceInfo?.po_due_date,
        title: "Credit Note",
        noteTitle: "Credit Note#",
        balanceTitle: "Balance",
        numTitle: "Invoice No",
        dateTitle: "Invoice Date",
        dueTitle: "Due Date",
      };

    case type === "SALES_ORDER":
      return {
        ...commonProperties,
        CreditNo: invoiceInfo?.invoice_no,
        invoiceNo: invoiceInfo?.invoice_no,
        invoiceDate: invoiceInfo?.invoice_date,
        poDue: invoiceInfo?.po_due_date,
        title: "Sales Order",
        noteTitle: "Sales Order#",
        balanceTitle: "Balance Due",
        numTitle: "Sales No",
        dateTitle: "Date",
        dueTitle: "Expected Shipment Date",
      };

    case type === "ESTIMATE":
      return {
        ...commonProperties,
        CreditNo: invoiceInfo?.invoice_no,
        invoiceNo: invoiceInfo?.invoice_no,
        invoiceDate: invoiceInfo?.invoice_date,
        poDue: invoiceInfo?.po_due_date,
        title: "ESTIMATE",
        noteTitle: "Estimate#",
        balanceTitle: "Balance Due",
        numTitle: "Estimate No",
        dueTitle: "Expiry Date",
        dateTitle: "Date",
      };
    case type === "PURCHASE_ORDER":
      return {
        ...commonProperties,
        CreditNo: invoiceInfo?.credit_debit_note_number,
        invoiceNo: invoiceInfo?.billing_no,
        invoiceDate: invoiceInfo?.billing_date,
        amountPaid: invoiceInfo?.amount_paid,
        paymentMode: invoiceInfo?.payment_mode,
        CreditNo: invoiceInfo?.billing_no,
        poDue: invoiceInfo?.due_date,
        title: "Purchase Order",
        noteTitle: "Purchase Order #",
        balanceTitle: "Balance",
        numTitle: "PO No",
        dateTitle: "PO Date",
        dueTitle: "Expected Delivery Date",
      };
    case type === "BILL":
      return {
        ...commonProperties,
        CreditNo: invoiceInfo?.credit_debit_note_number,
        invoiceNo: invoiceInfo?.billing_no,
        invoiceDate: invoiceInfo?.billing_date,
        amountPaid: invoiceInfo?.amount_paid,
        paymentMode: invoiceInfo?.payment_mode,
        CreditNo: invoiceInfo?.billing_no,
        poDue: invoiceInfo?.due_date,
        title: "BILL",
        noteTitle: "Bill #",
        balanceTitle: "Balance",
        numTitle: "Bill No",
        dateTitle: "Bill Date",
        dueTitle: "Due Date",
      };
    // Add other cases...
    default:
      return {
        ...commonProperties,
        CreditNo: invoiceInfo?.credit_debit_note_number,
        invoiceNo: invoiceInfo?.invoice_no ?? invoiceInfo?.billing_no,
        invoiceDate: invoiceInfo?.invoice_date ?? invoiceInfo?.billing_date,
        amountPaid: invoiceInfo?.amount_paid,
        paymentMode: invoiceInfo?.payment_mode,
        poDue: invoiceInfo?.po_due_date,
        title: "Invoice",
        noteTitle: "Invoice No#",
        balanceTitle: "Balance",
        numTitle: "Invoice No",
        dateTitle: "Invoice Date",
        dueTitle: "Due Date",
      };
  }
};

function calculatePercentage(total, taxPrice) {
  let p = taxPrice / total;
  return Math.round(p * 100);
}

function getTaxRates(items) {
  const taxRates = {};
  if (!items?.length) {
    items = [];
  }

  for (let i = 0; i < items?.length; i++) {
    let per = calculatePercentage(
      parseFloat(items[i].total_amount),
      parseFloat(items[i].tax_amount)
    );
    if (per > 0)
      if (taxRates[per]) {
        taxRates[per] = taxRates[per] + parseFloat(items[i].tax_amount);
      } else {
        taxRates[per] = parseFloat(items[i].tax_amount);
      }
  }

  return taxRates;
}

const BillsTemplates = ({ template, invoiceInfo, isCN, type, company }) => {
  const invoiceData = getInvoiceData(isCN, type, invoiceInfo, company);
  const subTotalAmount = (
    parseFloat(invoiceData?.total_amount) -
    parseFloat(invoiceData?.total_tax) -
    parseFloat(invoiceData?.deliveryCharges)
  ).toFixed(2);
  const TDSAmt = amountFormat(
    parseFloat(invoiceData?.tdsAmount),
    invoiceInfo?.currency?.decimal_scale
  );
  const { onCall } = useDocumentAxios({
    api: apis.converturltobase64,
    method: "post",
  });
  const [logo, setLogo] = useState("");
  const subTds =
    Number(
      parseFloat(invoiceData?.total_amount) - parseFloat(invoiceData?.total_tax)
    ) + Number(invoiceData?.tdsAmount);
  const shippingAddressCity =
    invoiceInfo?.beneficiary_shipping_addresses?.[0]?.city;
  const shippingAddressState =
    invoiceInfo?.beneficiary_shipping_addresses?.[0]?.state;
  const shippingAddressZip =
    invoiceInfo?.beneficiary_shipping_addresses?.[0]?.zipcode;
  const shippingAddressCountry =
    invoiceInfo?.beneficiary_shipping_addresses?.[0]?.country;
  const shippingAddressPhone =
    invoiceInfo?.beneficiary_shipping_addresses?.[0]?.phone;
  const shipAddress = invoiceInfo?.beneficiary_shipping_addresses?.[0]?.address;
  const companyAddr = company?.address;
  const companyState = company?.state;
  const companyCity = company?.city;
  const companyCountry = company?.country;
  const companyPin = company?.pincode;
  const orgName = company?.name;

  const billingAddressCity =
    invoiceInfo?.beneficiary_billing_addresses?.[0]?.city ||
    invoiceInfo?.beneficiary_billing_address?.[0]?.city;
  const billingAddressState =
    invoiceInfo?.beneficiary_billing_addresses?.[0]?.state ||
    invoiceInfo?.beneficiary_billing_address?.[0]?.state;
  const billingAddresszip =
    invoiceInfo?.beneficiary_billing_addresses?.[0]?.zipcode ||
    invoiceInfo?.beneficiary_billing_address?.[0]?.zipcode;
  const billingCountry =
    invoiceInfo?.beneficiary_billing_addresses?.[0]?.country ||
    invoiceInfo?.beneficiary_billing_address?.[0]?.country;
  const billPhone =
    invoiceInfo?.beneficiary_billing_addresses?.[0]?.phone ||
    invoiceInfo?.beneficiary_billing_address?.[0]?.phone;
  const billAddress =
    invoiceInfo?.beneficiary_billing_addresses?.[0]?.address ||
    invoiceInfo?.beneficiary_billing_address?.[0]?.address;
  const benficiaryGST = invoiceInfo?.beneficiaries?.gstin;
  const bankName = invoiceInfo?.beneficiary_bank_accounts?.[0]?.bank_name;
  const ifsc = invoiceInfo?.beneficiary_bank_accounts?.[0]?.ifsc_code;
  const branch = invoiceInfo?.beneficiary_bank_accounts?.[0]?.branch;
  const bankAccount =
    invoiceInfo?.beneficiary_bank_accounts?.[0]?.account_number;
  const benefiName = invoiceInfo?.beneficiaries?.beneficiary_name;
  const benifiEmail = invoiceInfo?.beneficiaries?.email;
  const benifiCompany = invoiceInfo?.beneficiaries?.company_name;
  const benifiContact = invoiceInfo?.beneficiaries?.primary_contact;
  const styles = StyleSheet.create({
    page: {
      paddingTop: 60,
      paddingLeft: 50,
      paddingRight: 50,
      lineHeight: 1.5,
      //  fontFamily:"Roboto"
    },
  });

  useEffect(() => {
    if (company?.logo?.url) {
      onCall({
        data: {
          access_code: company?.logo?.url?.split(".com/")[1],
        },
      })
        .then((res) => {
          setLogo("data:image/png;base64," + res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [company]);
  return (
    <div>
      {(() => {
        switch (template) {
          case "template_01":
            return (
              <CreditPdf
                balanceAmount={invoiceData.balanceAmount}
                creditNoteNo={
                  invoiceData.CreditNo
                    ? invoiceData.CreditNo
                    : invoiceInfo?.invoice_no
                }
                creditDate={invoiceData.creditNoteDate}
                invoiceNo={invoiceData.invoiceNo}
                currency={
                  invoiceInfo?.currency_iso
                    ? invoiceInfo?.currency_iso
                    : getSymbol()
                }
                invoiceDate={invoiceData.invoiceDate}
                lineItems={invoiceData.lineItems}
                taxRates={invoiceData.taxRates}
                subTotal={
                  invoiceInfo?.tds_amount
                    ? subTds
                    : amountFormat(
                        subTotalAmount,
                        invoiceInfo?.currency?.decimal_scale
                      )
                }
                TDSAmt={TDSAmt}
                billingAddressCity={billingAddressCity}
                billingAddressState={billingAddressState}
                billingAddresszip={billingAddresszip}
                billPhone={billPhone}
                billAddress={billAddress}
                billingCountry={billingCountry}
                type={type}
                taxAmount={invoiceData.totalTax}
                totalAmount={invoiceData.totalAmount}
                title={invoiceData.title}
                noteTitle={invoiceData.noteTitle}
                balanceTitle={invoiceData.balanceTitle}
                numTitle={invoiceData.numTitle}
                address={company?.address}
                gst={company?.gst_no}
                dateTitle={invoiceData.dateTitle}
                discount={invoiceData.totalDiscount}
                benficiaryGST={benficiaryGST}
                benefiName={benefiName}
                benefiEmail={benifiEmail}
              />
            );
          case "template_02":
            return (
              <InvPDF
                invoiceNo={invoiceData.invoiceNo}
                lineItems={invoiceData.lineItems}
                image={logo}
                subTotal={
                  invoiceInfo?.tds_amount
                    ? subTds
                    : amountFormat(
                        subTotalAmount,
                        invoiceInfo?.currency?.decimal_scale
                      )
                }
                invoiceDate={invoiceData.invoiceDate}
                deliveryCharges={invoiceData.deliveryCharges}
                creditDate={invoiceData?.creditNoteDate}
                currency={
                  invoiceInfo?.currency_iso
                    ? invoiceInfo?.currency_iso
                    : getSymbol()
                }
                TDSAmt={TDSAmt}
                type={type}
                taxAmount={invoiceData.totalTax}
                totalAmount={invoiceData.totalAmount}
                benficiaryGST={benficiaryGST}
                title={invoiceData.title}
                billingAddressCity={billingAddressCity}
                billingAddressState={billingAddressState}
                billingAddresszip={billingAddresszip}
                billPhone={billPhone}
                billAddress={billAddress}
                primaryEmail={company?.primary_email}
                billingCountry={billingCountry}
                noteTitle={invoiceData.noteTitle}
                balanceTitle={invoiceData.balanceTitle}
                numTitle={invoiceData.numTitle}
                dateTitle={invoiceData.dateTitle}
                accountName={company?.account_name}
                accountNumber={bankAccount}
                discount={invoiceData.totalDiscount}
                companyName={company?.name}
                address={company?.address}
                bankName={bankName}
                benefiName={benefiName}
                benefiEmail={benifiEmail}
                companyAddr={companyAddr}
                companyCity={companyCity}
                companyState={companyState}
                companyCountry={companyCountry}
                companyPin={companyPin}
              />
            );
          case "template_03":
            return (
              <InvPDF2
                invoiceNo={invoiceData.invoiceNo}
                creditNo={isCN ? invoiceData.CreditNo : null}
                creditTitle={isCN ? invoiceData.creditTitle : null}
                deliveryCharges={invoiceData.deliveryCharges}
                currency={
                  invoiceInfo?.currency_iso
                    ? invoiceInfo?.currency_iso
                    : getSymbol()
                }
                lineItems={invoiceData.lineItems}
                TDSAmt={TDSAmt}
                type={type}
                subTotal={
                  invoiceInfo?.tds_amount
                    ? subTds
                    : amountFormat(
                        subTotalAmount,
                        invoiceInfo?.currency?.decimal_scale
                      )
                }
                invoiceDate={invoiceData.invoiceDate}
                creditDate={invoiceData.creditNoteDate}
                dueTitle={isCN ? null : invoiceData.dueTitle}
                taxAmount={invoiceData.totalTax}
                totalAmount={invoiceData.totalAmount}
                due_balance={invoiceData.balanceAmount}
                title={invoiceData.title}
                noteTitle={invoiceData.noteTitle}
                benefiName={benefiName}
                benefiEmail={benifiEmail}
                benficiaryGST={benficiaryGST}
                shippingAddressCity={shippingAddressCity}
                shippingAddressCountry={shippingAddressCountry}
                shippingAddressPhone={shippingAddressPhone}
                shippingAddressState={shippingAddressState}
                shippingAddressZip={shippingAddressZip}
                billingAddressCity={billingAddressCity}
                billingAddressState={billingAddressState}
                shipAddress={shipAddress}
                billingAddresszip={billingAddresszip}
                billPhone={billPhone}
                billAddress={billAddress}
                billingCountry={billingCountry}
                balanceTitle={invoiceData.balanceTitle}
                numTitle={invoiceData.numTitle}
                dateTitle={invoiceData.dateTitle}
                terms={invoiceData?.terms}
                taxRates={invoiceData.taxRates}
                poDue={invoiceData?.poDue}
                companyName={invoiceData?.companyName}
                address={invoiceData?.address}
                orgName={orgName}
                companyAddr={companyAddr}
                discount={invoiceData?.totalDiscount}
                companyCity={companyCity}
                companyState={companyState}
                companyCountry={companyCountry}
                companyPin={companyPin}
                image={logo}
              />
            );
          case "template_04":
            return (
              <InvoicePDF
                invoiceNo={invoiceData.invoiceNo}
                deliveryCharges={invoiceData.deliveryCharges}
                lineItems={invoiceData.lineItems}
                currency={
                  invoiceInfo?.currency_iso
                    ? invoiceInfo?.currency_iso
                    : getSymbol()
                }
                creditNo={isCN ? invoiceData.CreditNo : null}
                creditTitle={isCN ? invoiceData.creditTitle : null}
                subTotal={
                  invoiceInfo?.tds_amount
                    ? subTds
                    : amountFormat(
                        subTotalAmount,
                        invoiceInfo?.currency?.decimal_scale
                      )
                }
                invoiceDate={invoiceData.invoiceDate}
                TDSAmt={TDSAmt}
                type={type}
                creditDate={invoiceData.creditNoteDate}
                taxAmount={invoiceData.totalTax}
                totalAmount={invoiceData.totalAmount}
                title={invoiceData.title}
                billingAddressCity={billingAddressCity}
                billingAddressState={billingAddressState}
                billingAddresszip={billingAddresszip}
                billPhone={billPhone}
                billAddress={billAddress}
                benefiName={benefiName}
                benefiEmail={benifiEmail}
                billingCountry={billingCountry}
                noteTitle={invoiceData.noteTitle}
                balanceTitle={invoiceData.balanceTitle}
                numTitle={invoiceData.numTitle}
                dateTitle={invoiceData.dateTitle}
                logo={company?.logo}
                benficiaryGST={benficiaryGST}
                bankName={bankName}
                bankBranch={branch}
                ifsc={ifsc}
                discount={invoiceData.totalDiscount}
                bankAccount={bankAccount}
              />
            );
          case "template_05":
            return (
              <Purpleinv
                invoiceNo={invoiceData.invoiceNo}
                deliveryCharges={invoiceData.deliveryCharges}
                creditNo={isCN ? invoiceData.CreditNo : null}
                creditTitle={isCN ? invoiceData.creditTitle : null}
                lineItems={invoiceData.lineItems}
                invoiceDate={invoiceData.invoiceDate}
                currency={
                  invoiceInfo?.currency_iso
                    ? invoiceInfo?.currency_iso
                    : getSymbol()
                }
                TDSAmt={TDSAmt}
                type={type}
                totalAmount={invoiceData.totalAmount}
                subTotal={
                  invoiceInfo?.tds_amount
                    ? subTds
                    : amountFormat(
                        subTotalAmount,
                        invoiceInfo?.currency?.decimal_scale
                      )
                }
                discount={invoiceData.totalDiscount}
                taxAmount={invoiceData.totalTax}
                bankName={bankName}
                bankBranch={branch}
                ifsc={ifsc}
                bankAccount={bankAccount}
                primaryEmail={company?.primary_email}
                primaryContact={company?.primary_phone}
                cinNo={benficiaryGST}
                gstNo={company?.gst_no}
                numTitle={invoiceData.numTitle}
                dateTitle={invoiceData.dateTitle}
                benifiContact={benifiContact}
                benifiEmail={benifiEmail}
                benefiCompany={benifiCompany}
                billingAddressCity={billingAddressCity}
                billingAddressState={billingAddressState}
                billingAddresszip={billingAddresszip}
                billPhone={billPhone}
                billAddress={billAddress}
                benefiName={benefiName}
                benficiaryGST={benficiaryGST}
                billingCountry={billingCountry}
                companyName={company?.name}
                logo={company?.logo}
                address={invoiceData.address}
                accountName={company?.account_name ?? bankName}
                accountNumber={company?.account_number ?? bankAccount}
                bankAccountBranch={company?.bank_branch ?? branch}
                accountIFSC={company?.account_ifsc ?? ifsc}
              />
            );
          case "template_06":
            return (
              <PaymentReceivedTwo
                invoiceNo={invoiceData.invoiceNo}
                lineItems={invoiceData.lineItems}
                invoiceDate={invoiceData.invoiceDate}
                currency={
                  invoiceInfo?.currency_iso
                    ? invoiceInfo?.currency_iso
                    : getSymbol()
                }
                TDSAmt={TDSAmt}
                type={type}
                totalAmount={invoiceData.totAmt}
                subTotal={
                  invoiceInfo?.tds_amount
                    ? subTds
                    : amountFormat(
                        subTotalAmount,
                        invoiceInfo?.currency?.decimal_scale
                      )
                }
                discount={invoiceData.totalDiscount}
                taxAmount={invoiceData.totalTax}
                bankName={bankName}
                bankBranch={branch}
                ifsc={ifsc}
                bankAccount={bankAccount}
                primaryEmail={company?.primary_email}
                primaryContact={company?.primary_phone}
                cinNo={benficiaryGST}
                gstNo={company?.gst_no}
                numTitle={invoiceData.numTitle}
                dateTitle={invoiceData.dateTitle}
                benifiContact={benifiContact}
                benifiEmail={benifiEmail}
                benefiCompany={benifiCompany}
                billingAddressCity={billingAddressCity}
                billingAddressState={billingAddressState}
                billingAddresszip={billingAddresszip}
                billPhone={billPhone}
                billAddress={billAddress}
                benefiName={benefiName}
                benficiaryGST={benficiaryGST}
                billingCountry={billingCountry}
                companyName={company?.name}
                logo={company?.logo}
                address={invoiceData.address}
                accountName={company?.account_name ?? bankName}
                accountNumber={company?.account_number ?? bankAccount}
                bankAccountBranch={company?.bank_branch ?? branch}
                accountIFSC={company?.account_ifsc ?? ifsc}
                companyAddr={companyAddr}
                companyCity={companyCity}
                companyState={companyState}
                companyCountry={companyCountry}
                companyPin={companyPin}
                paymentDate={invoiceData.paymentDate}
                refNo={invoiceData.refNo}
                paymentMode={invoiceData.paymentMode}
                amountPaid={invoiceData.amountPaid}
                // placeOfSupply={location?.state?.placeOfSupply}
              />
            );
          case "template_07":
            return (
              <PaymentReceived
                invoiceNo={invoiceData.invoiceNo}
                lineItems={invoiceData.lineItems}
                currency={
                  invoiceInfo?.currency_iso
                    ? invoiceInfo?.currency_iso
                    : getSymbol()
                }
                invoiceDate={invoiceData.invoiceDate}
                TDSAmt={TDSAmt}
                type={type}
                totalAmount={invoiceData.totAmt}
                subTotal={
                  invoiceInfo?.tds_amount
                    ? subTds
                    : amountFormat(
                        subTotalAmount,
                        invoiceInfo?.currency?.decimal_scale
                      )
                }
                discount={invoiceData.totalDiscount}
                taxAmount={invoiceData.totalTax}
                bankName={bankName}
                bankBranch={branch}
                ifsc={ifsc}
                bankAccount={bankAccount}
                primaryEmail={company?.primary_email}
                primaryContact={company?.primary_phone}
                cinNo={benficiaryGST}
                gstNo={company?.gst_no}
                numTitle={invoiceData.numTitle}
                dateTitle={invoiceData.dateTitle}
                benifiContact={benifiContact}
                benifiEmail={benifiEmail}
                benefiCompany={benifiCompany}
                billingAddressCity={billingAddressCity}
                billingAddressState={billingAddressState}
                billingAddresszip={billingAddresszip}
                billPhone={billPhone}
                billAddress={billAddress}
                benefiName={benefiName}
                benficiaryGST={benficiaryGST}
                billingCountry={billingCountry}
                companyName={company?.name}
                logo={company?.logo}
                address={invoiceData.address}
                accountName={company?.account_name ?? bankName}
                accountNumber={company?.account_number ?? bankAccount}
                bankAccountBranch={company?.bank_branch ?? branch}
                accountIFSC={company?.account_ifsc ?? ifsc}
                paymentDate={invoiceData.paymentDate}
                refNo={invoiceData.refNo}
                amountPaid={invoiceData.amountPaid}
                companyAddr={companyAddr}
                companyCity={companyCity}
                companyState={companyState}
                companyCountry={companyCountry}
                companyPin={companyPin}
                // placeOfSupply={location?.state?.placeOfSupply}
              />
            );
          case "template_08":
            return (
              <Payments
                invoiceNo={invoiceData?.invoiceNo}
                lineItems={invoiceData?.lineItems}
                currency={
                  invoiceInfo?.currency_iso
                    ? invoiceInfo?.currency_iso
                    : getSymbol()
                }
                invoiceDate={invoiceData?.invoiceDate}
                TDSAmt={TDSAmt}
                type={type}
                totalAmount={invoiceData.totAmt}
                subTotal={
                  invoiceInfo?.tds_amount
                    ? subTds
                    : amountFormat(
                        subTotalAmount,
                        invoiceInfo?.currency?.decimal_scale
                      )
                }
                discount={invoiceData.totalDiscount}
                taxAmount={invoiceData.totalTax}
                bankName={bankName}
                bankBranch={branch}
                ifsc={ifsc}
                bankAccount={bankAccount}
                primaryEmail={company?.primary_email}
                primaryContact={company?.primary_phone}
                cinNo={benficiaryGST}
                gstNo={company?.gst_no}
                numTitle={invoiceData.numTitle}
                dateTitle={invoiceData.dateTitle}
                benifiContact={benifiContact}
                benifiEmail={benifiEmail}
                benefiCompany={benifiCompany}
                billingAddressCity={billingAddressCity}
                billingAddressState={billingAddressState}
                billingAddresszip={billingAddresszip}
                billPhone={billPhone}
                billAddress={billAddress}
                benefiName={benefiName}
                benficiaryGST={benficiaryGST}
                billingCountry={billingCountry}
                companyName={company?.name}
                logo={company?.logo}
                address={invoiceData.address}
                accountName={company?.account_name ?? bankName}
                accountNumber={company?.account_number ?? bankAccount}
                bankAccountBranch={company?.bank_branch ?? branch}
                accountIFSC={company?.account_ifsc ?? ifsc}
                companyAddr={companyAddr}
                companyCity={companyCity}
                companyState={companyState}
                companyCountry={companyCountry}
                companyPin={companyPin}
                amountPaid={invoiceData?.amountPaid}
              />
            );
          default:
            return (
              <div>
                <PDFViewer style={{ width: "100%", height: "100vh" }}>
                  <Document initialScale={1.0}>
                    <Page style={styles.page} wrap={false}>
                      <View
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ fontSize: 30 }}>
                          select default Template
                        </Text>
                      </View>
                    </Page>
                  </Document>
                </PDFViewer>
              </div>
            );
        }
      })()}
    </div>
  );
};

export default BillsTemplates;
