import React from "react";
import PropTypes from "prop-types";
import "./form.css";
import { Input } from "antd";
const InputComponent = (props) => {
  return (
    <div className="input-wrap">
      <label>{props?.label}</label>
      <Input
        placeholder={props?.placeholder}
        onChange={props.onChange}
        name={props.name}
      />
      {props.errMsg && (
        <div className="errorMsg" style={{ color: "red" }}>
          {props.errMsg}
        </div>
      )}
    </div>
  );
};

InputComponent.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  errMsg: PropTypes.string,
};

export default InputComponent;
