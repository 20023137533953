import React from "react";

const TxnSVG = ({ scale, fill, opacity }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.053"
      height="21.346"
      viewBox="0 0 23.053 21.346"
      style={{
        scale: scale ? scale : 1,
        zoom: scale ? scale : 1,
      }}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_6077"
            data-name="Rectangle 6077"
            width="23.053"
            height="21.346"
            fill={fill || "#fff"}
          />
        </clipPath>
      </defs>
      <g id="Group_96402" data-name="Group 96402" opacity="0.6">
        <g id="Group_18851" data-name="Group 18851" clip-path="url(#clip-path)">
          <path
            id="Path_20261"
            data-name="Path 20261"
            d="M6.831,0H0V18.785H6.831ZM3.415,17.077A1.708,1.708,0,1,1,5.123,15.37a1.708,1.708,0,0,1-1.708,1.708M5.123,6.832H1.708V5.978H5.123Zm0-1.708H1.708V4.27H5.123Zm0-1.708H1.708V2.562H5.123Z"
            transform="translate(0 -0.001)"
            fill={fill || "#fff"}
          />
          <path
            id="Path_20262"
            data-name="Path 20262"
            d="M205.137,17.064c-.042,0-.083.013-.126.013a1.708,1.708,0,1,1,.359-3.376,6.418,6.418,0,0,1,3.057-3.566V0H201.6V18.784h4.157a6.39,6.39,0,0,1-.616-1.72ZM203.3,2.562h3.415v.854H203.3Zm0,1.708h3.415v.854H203.3Zm0,1.708h3.415v.854H203.3Z"
            transform="translate(-193.911)"
            fill={fill || "#fff"}
          />
          <path
            id="Path_20263"
            data-name="Path 20263"
            d="M410.031,11.453V0H403.2V9.765a6.36,6.36,0,0,1,6.831,1.688Zm-5.123-8.891h3.415v.854h-3.415Zm0,1.708h3.415v.854h-3.415Zm0,1.708h3.415v.854h-3.415Z"
            transform="translate(-387.831 -0.001)"
            fill={fill || "#fff"}
          />
          <path
            id="Path_20264"
            data-name="Path 20264"
            d="M319.15,268.8a5.55,5.55,0,1,0,5.55,5.55A5.55,5.55,0,0,0,319.15,268.8Zm-1.207,8.267-2.415-2.415.6-.6,1.811,1.811,4.226-4.226.6.6Z"
            transform="translate(-301.646 -258.555)"
            fill={fill || "#fff"}
          />
        </g>
      </g>
    </svg>
  );
};

export default TxnSVG;
