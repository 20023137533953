import { ReducerFunctions } from "../../utils";
import {
  Expenses,
  AddExpense,
  UpdateExpense,
  ValidateExpense,
  ExpenseDetails,
  ExpenseCustomDetails,
  ExpenseFields,
  PrefixExpenseFields,
  ListExpensePolicies,
  DeleteExpensePolicies,
  EditExpensePolicies,
  CreateExpensePolicies,
  ShowExpensePolicies,
  ExpenseComments,
  DeleteExpense,
} from "./expenses";

export const expenses_reducer = ReducerFunctions(Expenses, {});
export const delete_expense_reducer = ReducerFunctions(DeleteExpense, {});
export const expense_comments_reducer = ReducerFunctions(ExpenseComments, {});
export const addExpense_reducer = ReducerFunctions(AddExpense, {});
export const updateExpense_reducer = ReducerFunctions(UpdateExpense, {});
export const validateExpense_reducer = ReducerFunctions(ValidateExpense, {});
export const expenseDetails_reducer = ReducerFunctions(ExpenseDetails, {});
export const expenseDetails_customFields_reducer = ReducerFunctions(
  ExpenseCustomDetails,
  {}
);
export const expenseFields_reducer = ReducerFunctions(ExpenseFields, {});
export const prefix_expenseFields_Reducer = (state = {}, action) => {
  let data = Array.isArray(action?.payload)
    ? [...action?.payload]
    : { ...action?.payload };
  console.log(action?.payload);
  switch (action?.type) {
    case PrefixExpenseFields.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PrefixExpenseFields.SUCCESS: {
      return {
        ...state,
        data: { [action?.module_type]: data?.data },
        loading: false,
        success: true,
      };
    }
    case PrefixExpenseFields.FAILED:
      return {
        ...state,
        data: { [action?.module_type]: false },
        loading: false,
        success: false,
      };
    case PrefixExpenseFields.RESET:
      return {
        loading: false,
      };

    default:
      return state;
  }
};
export const create_expensePolicies_reducer = ReducerFunctions(
  CreateExpensePolicies,
  {}
);
export const edit_expensePolicies_reducer = ReducerFunctions(
  EditExpensePolicies,
  {}
);
export const show_expensePolicies_reducer = ReducerFunctions(
  ShowExpensePolicies,
  {}
);
export const delete_expensePolicies_reducer = ReducerFunctions(
  DeleteExpensePolicies,
  {}
);
export const list_expensePolicies_reducer = ReducerFunctions(
  ListExpensePolicies,
  {}
);
