import React, { useCallback } from "react";
import * as d3 from "d3";
import { amountFormat } from "../../utils";
import { getSymbolCode, graph_millify } from "../../config/helper";

const LineGraph = (props) => {
  const symbol_code = getSymbolCode();
  const drawControlRef = useCallback(
    (node) => {
      if (node !== null) {
        let width = props.width;

        let height = props.height;

        const data2 = props.data;

        var scale = d3
          .scaleBand()
          .domain(props.data?.map((ele) => ele.day))
          .range([0, width])
          .padding(0.2);
        var scaleL = d3.scaleLinear().range([(height - 40) * 0.95, 2]);

        let LMax = d3.max(data2, function (d) {
          return d.txnValue;
        });

        scaleL.domain([0, LMax]);

        let x_axis = d3.axisBottom(scale).ticks(props.data.length).tickSize(0);
        let y_axisL = d3
          .axisLeft(scaleL)
          .tickFormat((d) => {
            return graph_millify(d, true, true);
          })
          .ticks(props.data.length)
          .tickSize(0);

        var svg = d3
          .select("#" + props.id)
          .append("svg")
          .attr("width", width)
          .attr("height", height);
        if (!props.noAxis) {
          svg
            .append("g")
            .attr("transform", `translate(10,${height - 25})`)
            .attr("style", "color:#dbe1e9")
            .call(x_axis);
          svg
            .append("g")
            .attr("transform", `translate(${40},18)`)
            .attr("style", "color:#dbe1e9")
            .call(y_axisL);
        }

        let tooltip = d3
          .select(`#${props.id}`)
          .append("div")
          .attr("class", `bar-line-tooltip isTooltip`)
          .style("display", "flex")
          .style("opacity", 0);

        let areaGradient1 = svg
          .append("defs")
          .append("linearGradient")
          .attr("id", "areaGradient1")
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");

        let areaFunction1 = d3
          .area()
          .curve(d3.curveMonotoneX)
          .x((d) => scale(d.day) + width / props.data.length / 2)
          .y0(height)
          .y1((d) => scaleL(d.txnValue) + 20);

        //Append the first stop - the color at the top
        areaGradient1
          .append("stop")
          .attr("offset", "0%")
          .attr("stop-color", "#151424")
          .attr("stop-opacity", 0.4);

        //Append the second stop - white transparant almost at the end
        areaGradient1
          .append("stop")
          .attr("offset", "100%")
          .attr("stop-color", "#ffffff")
          .attr("stop-opacity", 0);

        let line = d3
          .line()
          .x(function (d) {
            return scale(d.day) + width / props.data.length / 2;
          })
          .y(function (d) {
            return scaleL(d.txnValue) + 20;
          })
          .curve(d3.curveMonotoneX);
        svg
          .append("g")
          .append("path")
          .datum(data2)
          .attr("d", line)
          .attr("stroke", "#6ae3c7")
          .style("stroke-width", 1)
          .style("transform", "translate(10px, 0px)")
          .style("fill", "none");
        svg
          .selectAll(".dot")
          .data(data2)
          .enter()
          .append("circle")
          .attr("class", "dot")
          .attr(
            "style",
            "cursor:pointer;padding:2px;border: 1px solid #6ae3c7;"
          ) // Siva - 13.04.22 - Added cursor pointer
          .attr("cx", line.x())
          .attr("cy", line.y())
          .style("transform", "translate(10px, 0px)")
          .attr("r", 4)
          .attr("fill", "#6ae3c7")
          .on("mouseover", function (d, data) {
            const positionLeft = d.x - 225;
            const positionTop = d.y - 140;
            tooltip.transition().duration(500);
            tooltip
              .html(
                `<span>${data.label}</span>
                 <span>${symbol_code} ${amountFormat(data.txnValue)}</span>`
              )
              .style("left", `${positionLeft + "px"}`)
              .style("top", positionTop + "px")
              .style("opacity", 1);
          })
          .on("mouseout", function () {
            tooltip.transition().duration(300);
            tooltip.style("opacity", 0);
          });
        svg
          .append("g")
          .append("path")
          .attr("class", "area")
          .style("fill", "url(#areaGradient1)")
          .style("transform", "translate(10px, 0px)")
          .attr("d", areaFunction1(data2));
      }
    },
    // eslint-disable-next-line
    []
  );
  return (
    <div className="chart-wrapper graph-container">
      <div
        id={props.id}
        ref={drawControlRef}
        className="draw_graph_container"
      ></div>
      <div
        className="bar-line-tooltip isTooltip"
        style={{ display: "flex", opacity: 0, left: "147px", top: "-65px" }}
      ></div>
    </div>
  );
};

export default LineGraph;
