import React from "react";

import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Radio, Tabs, message } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateAdvanceApproval,
  advanceApproval,
  getRoles,
  updateAdvanceApproval,
} from "../../../actions/companySettings";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import { apis } from "../../../config/APIs";
import { objectToFormData } from "../../../utils";
import "./../styles/setting.css";
import AdvancedApproval from "./AdvancedApproval";
import HirarchicalApproval from "./HirarchicalApproval";
import usePayAxios from "../../../config/useAxios";

const { TabPane } = Tabs;

message.config({
  maxCount: 3,
});

const ApprovalAdvance = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [advanceApprovalData, setAdvanceApprovalData] = React.useState([]);
  const [rolesData, setRolesData] = React.useState([]);
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const advanceApprovalResponse = useSelector(
    (state) => state.companySetting.advanceApproval || {}
  );
  const updateAdvanceApprovalResponse = useSelector(
    (state) => state.companySetting.updateAdvanceApproval || {}
  );
  const rolesResponse = useSelector(
    (state) => state.companySetting.getRoles || {}
  );
  const [editMode, setEditMode] = React.useState(false);
  const [showApproval, setShowApproval] = React.useState(false);
  const [showAdvancedApproval, setShowAdvancedApproval] = React.useState(false);
  const [radioVal, setRadioVal] = React.useState("hierarchical");
  const { onCall: updateOptions } = usePayAxios({
    api: apis.hierarchyUpdateOptions,
    method: "post",
  });
  const { data, onCall } = usePayAxios({
    api: apis.hierarchyOptions,
    method: "get",
  });
  const { data: { data: advance_approvals = [], status = false } = {} } =
    advanceApprovalResponse;

  const setTab = () => {};

  React.useEffect(() => {
    dispatch(advanceApproval({ module_type: "ADVANCE_APPROVAL" }));
    dispatch(getRoles({}));
    onCall({
      params: {
        seed_type: "advances_hierarchy",
      },
    });
    dispatch(
      headerTitles({
        title: "advance_approver",
        description: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (status && advance_approvals) {
      setAdvanceApprovalData(
        advance_approvals?.map((ele, idx) => ({ ...ele, level: idx }))
      );
      setSelectedRoles(advance_approvals.map((k) => k.role_id));
      // message.success(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanceApprovalResponse]);

  React.useEffect(() => {
    if (
      data?.data?.[0]?.seed_value === "with hierarchy" &&
      data?.data?.[0]?.seed_code?.includes("_1")
    ) {
      setRadioVal("hierarchical");
    } else if (
      data?.data?.[0]?.seed_value === "Custom Approval Flow" &&
      data?.data?.[0]?.seed_code?.includes("_2")
    ) {
      setRadioVal("advanced");
    }
  }, [data]);

  // React.useEffect(() => {
  //   const { data: { response: { advance_approvals = [] } = {}, status = '', message: res_message = '', errors = '' } = {} } = updateAdvanceApprovalResponse;
  //   if (status && advance_approvals) {
  //     setAdvanceApprovalData(advance_approvals?.map((ele, idx) => ({ ...ele, level: idx })));
  //     setSelectedRoles(advance_approvals.map(k => k.role_id))
  //     message.success(<span className="messageText">{res_message}</span>);
  //     setEditMode(false);
  //     dispatch({
  //       type: UpdateAdvanceApproval.RESET,
  //     });
  //   }
  //   else if (status === false) {
  //     message.error(<span className="messageText">{errors}</span>);
  //     dispatch({
  //       type: UpdateAdvanceApproval.RESET,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updateAdvanceApprovalResponse]);

  React.useEffect(() => {
    const {
      data: {
        data: advance_approvals = [],
        status = "",
        message: res_message = "",
        error = "",
      } = {},
    } = updateAdvanceApprovalResponse;
    if (error === false) {
      dispatch(advanceApproval({ module_type: "ADVANCE_APPROVAL" }));
      message.success(<span className="messageText">{res_message}</span>);
      setEditMode(false);
      dispatch({
        type: UpdateAdvanceApproval.RESET,
      });
    } else if (status === false) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UpdateAdvanceApproval.RESET,
      });
    }
  }, [updateAdvanceApprovalResponse]);

  React.useEffect(() => {
    const { data: { data: roles = [], status = false } = {} } = rolesResponse;
    if (status) {
      setRolesData(roles);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesResponse]);

  const handleSubmit = () => {
    const dataArray = advanceApprovalData.map(({ ...rest }) => rest);
    dispatch(
      updateAdvanceApproval({
        data: [...dataArray],
        params: { module_type: "ADVANCE_APPROVAL" },
      })
    );
  };

  const handleCancel = () => {
    setAdvanceApprovalData(advance_approvals);
    setEditMode(false);
  };

  const handleApproveList = (type, key) => {
    if (type === "add") {
      setAdvanceApprovalData([
        ...advanceApprovalData,
        {
          can_approve_or_reject: false,
          can_reimburse: false,
          id: advanceApprovalData.length,
          level: advanceApprovalData.length,
          role_name: "",
        },
      ]);
    } else {
      const filterArray = advanceApprovalData.map((obj) => {
        if (obj.level === key) {
          obj.is_active = false;
        }
        return obj;
      });
      const updatedArray = filterArray.map(({ level, ...rest }, index) => {
        return { level: index, ...rest };
      });
      setAdvanceApprovalData([...updatedArray]);
    }
  };
  console.log(advanceApprovalData, "roleDetails===>");

  return (
    <>
      {showApproval ? (
        <Tabs
          onChange={setTab}
          type="card"
          size="large"
          className="custom-tabs"
        >
          <TabPane tab={t("hierarchy_approval")} key="preferences">
            <div className="tabContentWrapper">
              {showAdvancedApproval ? (
                <AdvancedApproval
                  type={"ADVANCE"}
                  inv_type={"ADVANCE_APPROVAL"}
                  approvalData={advanceApprovalData}
                  hasWriteAccess={hasWriteAccess}
                />
              ) : (
                <HirarchicalApproval
                  approvalData={advanceApprovalData}
                  setApprovalData={setAdvanceApprovalData}
                  editMode={editMode}
                  handleList={handleApproveList}
                  selectedRoles={selectedRoles}
                  setSelectedRoles={setSelectedRoles}
                  rolesData={rolesData}
                  hirarchicalApprovalCheckBoxTitle="advances"
                  hasWriteAccess={hasWriteAccess}
                />
              )}
              {editMode &&
                rolesData?.filter(
                  (ele) =>
                    ele.role_name !== "Admin" &&
                    !advanceApprovalData
                      ?.filter((ele) => ele.is_active !== false)
                      ?.map((ele) => ele.role_id)
                      ?.includes(ele.id)
                )?.length > 0 && (
                  <Button
                    type="default"
                    disabled={!editMode || !hasWriteAccess}
                    ghost
                    className="approve-add-btn mt1"
                    onClick={() => handleApproveList("add")}
                  >
                    {t("add_one_more")}
                  </Button>
                )}
              <div className="floatBottom">
                <>
                  {!editMode && !showAdvancedApproval && (
                    <Button
                      disabled={!hasWriteAccess}
                      className="formButton mt1 primaryButton"
                      onClick={() => setEditMode(true)}
                    >
                      {t("edit")}
                    </Button>
                  )}
                  {!editMode && (
                    <Button
                      key="2"
                      className="cancelButton ml1 mt1"
                      onClick={() => {
                        setShowApproval(false);
                      }}
                    >
                      {t("back")}
                    </Button>
                  )}
                </>
                {editMode && (
                  <>
                    <Button
                      disabled={!hasWriteAccess}
                      key="1"
                      className="formButton mt1 primaryButton"
                      onClick={() => handleSubmit()}
                    >
                      {t("save")}
                    </Button>
                    <Button
                      key="2"
                      className="cancelButton ml1 mt1"
                      onClick={() => handleCancel()}
                    >
                      {t("cancel")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </TabPane>
        </Tabs>
      ) : (
        <div className="tabContentWrapper">
          <h3>{t("approval_types")}</h3>
          <Radio.Group
            className="w100"
            onChange={(e) => {
              setRadioVal(e.target.value);
            }}
            value={radioVal}
            disabled={!hasWriteAccess}
          >
            <div
              className={`hierarchical-select ${
                radioVal === "hierarchical" ? "active" : ""
              }`}
              onClick={() => {
                if (hasWriteAccess) {
                  setRadioVal("hierarchical");
                  updateOptions({
                    data: {
                      seed_type: "advances_hierarchy",
                      seed_code: "ADV_1",
                      is_active: true,
                    },
                  });
                }
              }}
            >
              <Radio
                disabled={!hasWriteAccess}
                name="hierarchical"
                checked={radioVal === "hierarchical"}
                value={"hierarchical"}
              />
              <div>
                <span>{t("hierarchy_approval")}</span>
                <span>{t("approval_flow")}</span>
                {radioVal === "hierarchical" ? (
                  <ArrowRightOutlined
                    onClick={() => {
                      setShowApproval(true);
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div
              className={`hierarchical-select ${
                radioVal === "advanced" ? "active" : ""
              }`}
              onClick={() => {
                if (hasWriteAccess) {
                  setRadioVal("advanced");
                  updateOptions({
                    data: {
                      seed_type: "advances_hierarchy",
                      seed_code: "ADV_2",
                      is_active: true,
                    },
                  });
                }
              }}
            >
              <Radio
                disabled={!hasWriteAccess}
                name="advanced"
                checked={radioVal === "advanced"}
                value={"advanced"}
              />
              <div>
                <span>Advanced Approval</span>
                <span>
                  You can set up an approval flow based on one or more criteria.
                  These criteria can be chosen by you.
                </span>
                {radioVal === "advanced" ? (
                  <ArrowRightOutlined
                    onClick={() => {
                      setShowAdvancedApproval(true);
                      setShowApproval(true);
                    }}
                  />
                ) : null}
              </div>
            </div>
          </Radio.Group>
        </div>
      )}
    </>
  );
};

export default ApprovalAdvance;
