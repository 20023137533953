import { Button, Col, Row, Space, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import Close from "../../assets/icons/close.png";
import email from "../../assets/ippo_icons/email.png";
import phone from "../../assets/ippo_icons/phone.png";
import user_icon from "../../assets/ippo_icons/user_icon.png";
import { useHistory, useLocation } from 'react-router-dom';
import AddCustomer from '../Sales/AddCustomer';
import { useDispatch, useSelector } from 'react-redux';
import { UploadProfile, portalDashboard, upload_profile } from '../../actions/customers';
import AddVendor from '../Purchases/AddVendor';
import useDocumentAxios from '../../config/useDocument';
import { apis } from '../../config/APIs';
// import { PulseLoader } from "react-spinners";
const CustomerPortalProfile = ({ hasWriteAccess }) => {
    const { t } = useTranslation()
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const dashboardData = useSelector((state) => state?.customers?.portalDashboard?.data?.data)
    const showResponse = useSelector((state) => state?.customers?.upload_profile?.data)
    const profileUpdateLoader = useSelector((state) => state?.customers?.upload_profile)
    const [coustomerData, setCustomerData] = useState(location?.state?.customerData);
    const [isProfileEdit, setIsProfileEdit] = useState(false)
    const [dataBeneficiary, setDataBeneficiary] = useState(location?.state?.customerData);
    const { onCall: DeleteProfile, data: DeleteProfileData, reset: DeleteProfileReset, } = useDocumentAxios({
        api: coustomerData?.profile?.id ? apis?.profileUpdate : apis?.profileUpload,
        method: "post",
    });
    useEffect(() => {
        setCustomerData(dashboardData?.beneficiary_details || location?.state?.customerData)
    }, [dashboardData])

    useEffect(() => {
        if (DeleteProfileData?.error == false || showResponse?.error == false) {
            message.success(<span className="messageText">{DeleteProfileData?.message ? DeleteProfileData?.message : showResponse?.message}</span>)
            dispatch(portalDashboard())
            dispatch({ type: UploadProfile.RESET })
            DeleteProfileReset()
        }
    }, [DeleteProfileData, showResponse])
    const uploadButton = (
        <div className="upload_div">
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {t('upload')}
            </div>
        </div>
    );

    const handleChange = (info) => {
        if (info.file.status === 'done' || info.file.status === 'error') {
            if (info.file?.originFileObj?.type?.includes("png") || info.file?.originFileObj?.type?.includes("jpg") || info.file?.originFileObj?.type?.includes("jpeg")) {

                const formData = new FormData();

                if (coustomerData?.profile?.id) {
                    formData.append("attachmentData", JSON.stringify([
                        {
                            id: coustomerData?.profile?.id,
                            filename: info?.file?.name,
                        }
                    ]));
                    formData.append("files", info.file.originFileObj)
                    DeleteProfile({
                        data: formData,
                        params: {
                            record_id: coustomerData?.id
                        },
                        headers: {
                            "Content-Type": "multipart/formdata",
                        }
                    })
                } else {
                    formData.append("files", info.file.originFileObj);
                    formData.append("name", "profile")
                    formData.append("record_type", "beneficiary");
                    formData.append("record_id", coustomerData?.id);
                    DeleteProfile({ data: formData })
                }
            } else {
                message.error(<span className="messageText">{t('image')}</span>)
            }
        }
    };
    return (
        <>
            {/* {(profileUpdateLoader?.loading) && (
                <div className="text-center loadingDiv">
                    <PulseLoader color="#fff" />
                </div>
            )} */}
            {isProfileEdit && localStorage?.getItem("isCustomer") && <AddCustomer
                open={isProfileEdit}
                onClose={() => {
                    setIsProfileEdit(false);
                    // setDataBeneficiary(null);
                    dispatch(portalDashboard())
                }}
                dataBeneficiary={dataBeneficiary}
                isCustomerPortal={true}
            />}

            {isProfileEdit && localStorage?.getItem("isVendor") && <AddVendor
                dataBeneficiary={dataBeneficiary}
                open={isProfileEdit}
                onClose={() => {
                    setIsProfileEdit(false);
                    dispatch(portalDashboard())
                }}
                isVendorPortal={true}
            />}
            <div className="view-details">
                <div className="new-report-detail">
                    <div className="ml1 mr1 p1">
                        <Row>
                            <Col span={24} className='full_name-details'>
                                <div className='flex-between'>
                                    <div className='full_name d-flex'>
                                        <p>
                                            <b>{coustomerData?.beneficiary_name}</b>
                                        </p>
                                        <Button className='ml1' icon={<EditOutlined />}
                                            onClick={() => {
                                                setIsProfileEdit(true);
                                            }}
                                            disabled={!coustomerData?.permissions?.allow_contact_update}
                                        />
                                    </div>
                                    <div
                                        onClick={() => {
                                            history.goBack();
                                        }}
                                        style={{ width: "1.5rem", height: "1.5rem" }}
                                    >
                                        <img src={Close} alt={"close"} className='w100 h100' />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div className="m1 p1">
                        <Row>
                            <Col span={12} className="name-details">
                                <div className='profile-section customer-profile'>
                                    <Upload
                                        disabled={!coustomerData?.permissions?.allow_contact_update}
                                        name="avatar"
                                        listType="picture-circle"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        onChange={handleChange}
                                        id={"upload"}
                                    >
                                        {coustomerData?.profile?.url ? (
                                            <img
                                                src={coustomerData?.profile?.url}
                                                alt="avatar"
                                                style={{
                                                    width: "100%",
                                                    height: "100%"
                                                }}
                                            />
                                        ) : (
                                            uploadButton
                                        )}
                                    </Upload>
                                    {<Space className='mt1'>
                                        <Button icon={<EditOutlined />} onClick={() => {
                                            document.getElementById("upload")?.click()
                                        }}
                                            disabled={!coustomerData?.permissions?.allow_contact_update}
                                        />
                                        <Button icon={<DeleteOutlined />}
                                            onClick={() => {
                                                const data = {
                                                    recordId: coustomerData?.id,
                                                    ids: [coustomerData?.profile?.id]
                                                }
                                                dispatch(upload_profile(data))
                                            }}
                                            disabled={!coustomerData?.permissions?.allow_contact_update}
                                        />
                                    </Space>}
                                </div>
                                <div>
                                    <p>
                                        <b>{coustomerData?.beneficiary_name}</b>
                                    </p>
                                    <p>{coustomerData?.email}</p>
                                    <p>
                                        {coustomerData?.country_code} {coustomerData?.primary_contact}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div className="m1 p1 other-info">
                        <h4>{t('other_information')}</h4>
                        <Row className="flex-wrap">
                            <Col span={6}>
                                <p>
                                    <label>{t('name')}</label>
                                </p>
                                <p>{coustomerData?.beneficiary_name}</p>
                            </Col>
                            <Col span={6}>
                                <p>
                                    <label>{t('currency_code')}</label>
                                </p>
                                <p>{coustomerData?.currency_info?.iso_code}</p>
                            </Col>
                            <Col span={6}>
                                <p>
                                    <label>{t('payment_term')}</label>
                                </p>
                                <p>{coustomerData?.configurations?.payment_terms}</p>
                            </Col>
                            <Col span={6}>
                                <p>
                                    <label>{t('gst_treatment')}</label>
                                </p>
                                <p>{coustomerData?.gst_treatment}</p>
                            </Col>
                            <Col span={6}>
                                <p>
                                    <label>{t('gstin')}</label>
                                </p>
                                <p>{coustomerData?.gstin}</p>
                            </Col>
                            <Col span={6}>
                                <p>
                                    <label>{t('source_of_supply')}</label>
                                </p>
                                <p>{coustomerData?.tax_settings?.place_of_supply}</p>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div className="m1 p1 other-info">
                        <h4 className="mb1">{t('bank_Account_info')}</h4>
                        <Row className="flex-wrap">
                            {coustomerData?.bank_accounts?.map((bankElement, bankIndex) => (
                                <Col span={8}>
                                    {bankIndex === 0 ? <p>
                                        <label>{t('primary')}</label>
                                    </p> : <p>
                                        <label>{t('others')}</label>
                                    </p>}
                                    <p>
                                        {t('account_num')} - {bankElement?.account_number}
                                        <br />
                                        {t('ifsc_code')} - {bankElement?.ifsc_code}
                                        <br />
                                        {t('bank_name')} - {bankElement?.bank_name}
                                    </p>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <hr />
                    <div className="m1 p1 other-info">
                        <h4 className="mb1">{t('address')}</h4>
                        <Row className="flex-wrap">
                            <Col span={8}>
                                <p>
                                    <label>{t('billing_address')}</label>
                                </p>
                                {coustomerData?.billing_addresses?.map((ele) => (
                                    <p className="mb1">
                                        {ele?.address}
                                        <br />
                                        {ele?.city} - {ele?.zipcode}
                                        <br />
                                        {ele?.country}
                                        <br />
                                        Phone: {""} {ele?.phone}
                                    </p>
                                ))}
                            </Col>
                            <Col span={8}>
                                <p>
                                    <label>{t('shipping_address')}</label>
                                </p>
                                {coustomerData?.shipping_addresses?.map((ele) => (
                                    <p className="mb1">
                                        {ele?.address}
                                        <br />
                                        {ele?.city} - {ele?.zipcode}
                                        <br />
                                        {ele?.country}
                                        <br />
                                        Phone: {""} {ele?.phone}
                                    </p>
                                ))}
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div className="m1 p1 other-info">
                        <h4 className="mb1">{t('contact_persons')}</h4>
                        <Row className="flex-wrap">
                            {coustomerData?.contact_persons?.map((contactElement, constactIndex) => (
                                <Col span={8}>
                                    <div className="contact">
                                        <img src={user_icon} alt="" className="user-img" />
                                        <div>
                                            <p>
                                                {contactElement?.first_name}
                                            </p>
                                            <p className="dim">
                                                <img src={email} width={16} /> {contactElement?.email}
                                            </p>
                                            <div className="flex-between gap2">
                                                {<p className="dim">
                                                    <img src={phone} width={10} /> {contactElement?.country_code}{" "}
                                                    {contactElement?.contact_number}
                                                </p>}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerPortalProfile