import {
  Document,
  Page,
  View,
  StyleSheet,
  Text,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import moment from "moment";
import TemplateTable from "./TemplateTable";
import { columns } from "./invPDF2";

const InvoicePDF = (props) => {
  const {
    billingAddressCity,
    billingAddressState,
    billingAddresszip,
    billingCountry,
    billPhone,
    billAddress,
    deliveryCharges,
  } = props;

  // Function to form the address
  const formatAddress = () => {
    const addressComponents = [
      billAddress,
      billingAddressCity,
      billingAddressState,
      billingAddresszip,
      billPhone,
      billingCountry,
    ];

    // Filter out undefined or empty values
    const filteredAddressComponents = addressComponents.filter(Boolean);
    const address = filteredAddressComponents.join(", ");

    return address;
  };

  const formAddress = formatAddress();
  const styles = StyleSheet.create({
    page: {
      paddingTop: 60,
      paddingLeft: 50,
      paddingRight: 50,
      lineHeight: 1.5,
    },

    header: {
      flexDirection: "column",
      alignItems: "flex-end",
      fontSize: "15px",
    },
    Menu: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    Menu1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 40,
      //marginBottom: 30
    },
    Menu2: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 10,
      marginBottom: 30,
    },
    display: {
      flexDirection: "row",
      justifyContent: "space-between",
      // paddingHorizontal: 20,
      marginTop: 10,
      minWidth: "30%",
    },
    container: {
      display: "flex",
      alignItems: "flex-end",
      marginTop: 10,
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
    },
    percenatge: {
      marginTop: "10px",
      fontSize: 12,
    },
  });

  const currency =
    props?.useDummyData === true ? props?.obj?.currency : props?.currency;

  return (
    <div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          <Page style={styles.page} wrap={false}>
            <View style={styles.Menu}>
              <View>
                <Text>
                  <img
                    src={props.logo}
                    alt="Logo"
                    style={{ width: "20px", height: "20px" }}
                  />
                </Text>
              </View>
              <View style={styles.header}>
                <Text style={{ fontSize: "30px", color: "black" }}>
                  {props?.useDummyData === true
                    ? props?.obj?.title
                    : props?.title}
                </Text>
              </View>
            </View>
            <View style={styles.Menu1}>
              <View>
                <Text>BILLED TO:</Text>
                <Text
                  style={{
                    marginTop: "5px",
                    maxWidth: "180px",
                    fontSize: "12px",
                  }}
                >
                  {props?.useDummyData === true
                    ? props?.obj?.beniName
                    : props?.benefiName}
                </Text>
                <Text
                  style={{
                    marginTop: "5px",
                    maxWidth: "180px",
                    fontSize: "12px",
                  }}
                >
                  {props?.useDummyData === true
                    ? props?.obj?.beniEmail
                    : props?.benefiEmail}
                </Text>

                <Text
                  style={{
                    marginTop: "5px",
                    maxWidth: "180px",
                    fontSize: "12px",
                  }}
                >
                  TAX ID :{" "}
                  {props?.useDummyData === true
                    ? props?.obj?.gst
                    : props?.benficiaryGST}
                </Text>

                <Text
                  style={{
                    marginTop: "5px",
                    maxWidth: "180px",
                    fontSize: "12px",
                  }}
                >
                  {props?.useDummyData === true
                    ? props?.obj?.address
                    : formAddress}
                </Text>
              </View>
              <View style={{ alignItems: "flex-end" }}>
                {props?.invoiceNo || props?.useDummyData === true ? (
                  <Text style={{ fontSize: "12px" }}>
                    {props?.useDummyData === true
                      ? props?.obj?.numTitle
                      : props?.numTitle}{" "}
                    :{" "}
                    {props?.useDummyData === true
                      ? props?.obj?.invoiceNo
                      : props?.invoiceNo}
                  </Text>
                ) : null}
                {props?.invoiceDate || props?.useDummyData === true ? (
                  <Text style={{ fontSize: "12px" }}>
                    {props?.useDummyData === true
                      ? props?.obj?.dateTitle
                      : props?.dateTitle}{" "}
                    : {moment(props?.invoiceDate).format("DD-MM-YYYY")}
                  </Text>
                ) : null}
                {props?.creditNo || props?.obj?.creditNo ? (
                  <Text style={{ fontSize: "12px" }}>
                    {props?.useDummyData === true
                      ? props?.obj?.creditTitle
                      : props?.creditTitle}
                    :
                    {props?.useDummyData === true
                      ? props?.obj?.CreditNo
                      : props?.creditNo}
                  </Text>
                ) : null}
              </View>
            </View>
            <TemplateTable
              columns={columns}
              currency={currency}
              lineItems={
                props?.useDummyData === true
                  ? props?.obj?.lineItems
                  : props.lineItems
              }
              tableHeadRow={{
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
                borderBottomColor: "#000",
                borderTopStyle: "solid",
                borderTopWidth: 1,
                borderTopColor: "#000",
                borderTop: "1px solid #000",
                paddingVertical: "10px",
              }}
              tableRow={{
                //  borderBottom: "1px solid #000",
                // padding: 5,
                // fontSize: "12px",
                //  paddingVertical: "10px"
                fontSize: "12px",
                paddingVertical: "10px",
                paddingLeft: "3px",
              }}
            />

            <View style={{ display: "flex", flexDirection: "row-reverse" }}>
              <View
                style={{ ...styles.display, borderBottom: "1px solid black" }}
              >
                <View style={{ ...styles.container, marginRight: "10px" }}>
                  <Text style={styles.text}>Sub Total</Text>
                  <Text style={styles.text}>Total Discount</Text>

                  <Text style={styles.text}>Tax Amount</Text>
                  {deliveryCharges && (
                    <Text style={styles.text}>Delivery Charges</Text>
                  )}
                  {props?.type === "PURCHASE_ORDER" ||
                  props?.type === "BILL" ||
                  props?.obj?.tdsFlag === true ? (
                    <Text style={styles.text}>TDS Amount</Text>
                  ) : null}
                </View>
                <View style={{ ...styles.container, marginBottom: "10px" }}>
                  <Text style={styles.text}>
                    {props?.useDummyData === true
                      ? currency + " " + props?.obj?.subTotal
                      : props?.subTotal
                      ? currency + " " + props.subTotal
                      : currency + " " + 0}
                  </Text>
                  <Text style={styles.text}>
                    {props?.useDummyData === true
                      ? currency + " " + props?.obj?.discount
                      : props?.discount
                      ? currency + " " + props?.discount
                      : currency + " " + 0}
                  </Text>

                  <Text style={styles.text}>
                    {props?.useDummyData === true
                      ? currency + " " + props?.obj?.totalTax
                      : props?.taxAmount
                      ? currency + " " + props?.taxAmount
                      : currency + " " + 0}
                  </Text>
                  {deliveryCharges && (
                    <Text style={styles.text}>
                      {currency + " " + deliveryCharges}
                    </Text>
                  )}
                  {props?.type === "PURCHASE_ORDER" ||
                  props?.type === "BILL" ||
                  props?.obj?.tdsFlag === true ? (
                    <Text style={styles.text}>
                      {props?.useDummyData === true
                        ? currency + " " + props?.obj?.tdsAmt
                        : props?.TDSAmt
                        ? currency + " " + props?.TDSAmt
                        : currency + " " + 0}
                    </Text>
                  ) : null}
                </View>
              </View>
            </View>

            <View style={{ display: "flex", flexDirection: "row-reverse" }}>
              <View style={styles.display}>
                <View style={styles.container}>
                  <Text> Total</Text>
                </View>
                <View style={styles.container}>
                  <Text style={{ paddingLeft: "20px" }}>
                    {props?.totalAmount
                      ? currency + " " + props?.totalAmount
                      : currency + " " + 0}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "10px",
                marginBottom: "20%",
              }}
            >
              <Text>Thank you!</Text>
            </View>
            <View style={styles.Menu2}>
              <View>
                <Text style={{ fontSize: "15px" }}>PAYMENT INFORMATION</Text>

                <View>
                  <View
                    style={{
                      fontSize: 10,
                      marginTop: "5px",
                      width: 200,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ minWidth: "100px" }}>Account Number:</Text>
                    <Text>
                      {props?.useDummyData === true
                        ? props?.obj?.bankAccount
                        : props?.bankAccount}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontSize: 10,
                      marginTop: "5px",
                      width: 200,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ minWidth: "100px" }}>Account Name:</Text>
                    <Text>
                      {props?.useDummyData === true
                        ? props?.obj?.bankName
                        : props?.bankName}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontSize: 10,
                      marginTop: "5px",
                      width: 200,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ minWidth: "100px" }}>IFSC code:</Text>
                    <Text style={{ minWidth: "100px" }}>
                      {props?.useDummyData === true
                        ? props?.obj?.ifscCode
                        : props?.ifsc}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontSize: 10,
                      marginTop: "5px",
                      width: 200,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ minWidth: "100px" }}>Branch:</Text>
                    <Text>
                      {props?.useDummyData === true
                        ? props?.obj?.bankBranch
                        : props?.bankBranch}
                    </Text>
                  </View>
                </View>
              </View>
              <View></View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default InvoicePDF;
