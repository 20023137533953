import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Checkbox, Collapse, Input, message, Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateCustomer,
  create_customers,
  customers,
  view_customer,
} from "../../actions/customers";
import { get_seed_data } from "../../actions/masterdata/masterdata";
import { VendorsById, vendorsById } from "../../actions/purchases";
import { FormInput } from "../inputs";
import AddCustomerAddress from "./AddAddress";
import AddCustomerContactPerson from "./AddContactPerson";
import AddCustomerOtherInformation from "./AddOtherInformation";
import AddCustomerPaymentDetails from "./AddPaymentDetails";
// import create_customer from "./create_customer.json";
import { useTranslation } from "react-i18next";
import useScrollToError from "../../config/useScrollToError";
const { Panel } = Collapse;

const AddCustomer = ({
  open,
  onClose,
  dataBeneficiary,
  isCustomerPortal = false,
  filters,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const create_ben = useSelector((state) => state.customers?.create);
  // const beneficiary_type = useSelector((state) =>
  //   state?.seed_data?.beneficiary_type?.data?.length
  //     ? state?.seed_data?.beneficiary_type?.data?.find(
  //         (ele) => ele.seed_value === "Individual"
  //       )
  //     : {}
  // );

  const {
    reset,
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, submitCount },
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      beneficiary_name: "",
      beneficiary_type: "customer",
      email: "",
      company_name: "",
      country_code: "91",
      primary_contact: "",
      portalAccess: false,
      shippingAddressSame: true,
      tax_settings: {
        tax_applicable: true,
        tax_id: "",
        tax_percentage: 0,
        tax_type: "",
        place_of_supply: "",
      },
      configurations: {
        portalAccess: false,
        currency: "",
        payment_terms: "",
      },
      contact_persons: [
        {
          salutation: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          contact_number: "",
          country_code: "91",
        },
      ],
      billing_address: [
        {
          country: "",
          address: "",
          city: "",
          state: "",
          zipcode: "",
          phone: "",
          address_type: "billing",
        },
      ],
      shipping_address: [
        {
          country: "",
          address: "",
          city: "",
          state: "",
          zipcode: "",
          phone: "",
          address_type: "shipping",
        },
      ],
      bank_account_details: [
        {
          bank_name: "",
          account_number: "",
          ifsc_code: "",
          branch: "",
        },
      ],
      gst_treatment: "",
      gstin: "",
      pan_number: null,
      // ...create_customer
    },
    shouldUseNativeValidation: false,
  });

  // Use the custom hook
  useScrollToError(Object.keys(errors)[0], errors, submitCount);

  const currencies = useSelector((state) => state?.currencies?.data?.data);
  const onChange = () => { };

  const onSubmit = async (data, event) => {
    const contactPerson = data.contact_persons?.map((item) => item.id);
    const bankAccount = data.bank_account_details?.map((item) => item.id);
    if (!event.nativeEvent?.submitter?.id) {
      return;
    }
    if (data.shippingAddressSame) {
      data.shipping_address = JSON.parse(
        JSON.stringify(data.billing_address)
      )?.map((ele, index) => {
        if (dataBeneficiary?.shipping_addresses?.length === 0) {
          delete ele.id;
        }
        if (ele?.id) {
          return {
            ...ele,
            address_type: "shipping",
            id: dataBeneficiary?.shipping_addresses?.[index]?.id
          };
        } else {
          return {
            ...ele,
            address_type: "shipping",
          };
        }
      });
    }
    data.company_id = localStorage.getItem("company_id");
    data.contact_persons = data.contact_persons?.filter(
      (ele) => ele.salutation && ele.first_name && ele.email
    );
    if (!isCustomerPortal) {
      data.company_currency_id = currencies?.find(
        (ele) => ele.iso_code === data.configurations.currency
      )?.id;
    }
    if (data.contact_persons?.length === 0) {
      delete data.contact_persons;
    }
    data.bank_account_details = data.bank_account_details?.map((ele) =>
      ele.id
        ? {
          bank_name: ele.bank_name,
          account_number: ele?.account_number,
          ifsc_code: ele?.ifsc_code,
          branch: ele?.branch,
          id: ele?.id,
        }
        : {
          bank_name: ele.bank_name,
          account_number: ele?.account_number,
          ifsc_code: ele?.ifsc_code,
          branch: ele?.branch,
        }
    );
    dataBeneficiary?.contact_persons?.map((item) => {
      if (!contactPerson?.includes(item.id)) {
        data.contact_persons?.push({
          id: item.id,
          is_active: false,
        });
      }
    });
    dataBeneficiary?.bank_account_details?.map((item) => {
      if (!bankAccount?.includes(item.id)) {
        data.bank_account_details?.push({
          id: item.id,
          is_active: false,
        });
      }
    });
    if (dataBeneficiary?.id) data.beneficiary_id = dataBeneficiary?.id;
    data.same_as_billing_address = data?.shippingAddressSame;
    data.configurations.portalAccess = data?.portalAccess;
    delete data.shippingAddressSame;
    delete data.tax_settings.beneficiary_id;
    delete data.configurations.beneficiary_id;
    delete data?.portalAccess;
    if (isCustomerPortal) {
      delete data?.email;
      delete data?.gst_treatment;
      delete data?.gstin;
      delete data?.pan_number;
      delete data?.tax_settings?.place_of_supply;
      delete data?.configurations?.currency;
      delete data?.configurations.payment_terms;
      data.is_customer = true;
    }
    dispatch(create_customers(data));
  };
  const fetchCustomersById = useSelector(
    (state) => state.purchases.vendorsById || {}
  );

  useEffect(() => {
    if (dataBeneficiary) {
      dispatch(vendorsById(dataBeneficiary.id));
    } else {
      reset();
    }
  }, [dataBeneficiary]);
  useEffect(() => {
    if (
      fetchCustomersById?.success &&
      !fetchCustomersById?.data?.error &&
      dataBeneficiary?.id
    ) {
      setValue("beneficiary_id", dataBeneficiary?.id);
      setValue("beneficiary_name", dataBeneficiary?.beneficiary_name);
      setValue("beneficiary_type", dataBeneficiary?.beneficiary_type);
      setValue("company_name", dataBeneficiary?.company_name);
      setValue("email", dataBeneficiary?.email);
      setValue("primary_contact", dataBeneficiary?.primary_contact);
      setValue("portalAccess", dataBeneficiary?.configurations?.portalAccess);
      setValue(
        "bank_account_details",
        dataBeneficiary?.bank_accounts?.length === 0
          ? [
            {
              bank_name: "",
              account_number: "",
              ifsc_code: "",
              branch: "",
            },
          ]
          : dataBeneficiary?.bank_accounts
      );
      setValue(
        "contact_persons",
        dataBeneficiary?.contact_persons?.length === 0
          ? [
            {
              salutation: "",
              first_name: "",
              middle_name: "",
              last_name: "",
              email: "",
              contact_number: "",
              country_code: "91",
            },
          ]
          : dataBeneficiary?.contact_persons?.map((ele) => {
            delete ele.beneficiary_id;
            delete ele.company_id;
            return ele;
          })
      );
      setValue(
        "shipping_address",
        dataBeneficiary?.shipping_addresses?.length === 0
          ? [
            {
              country: "",
              address: "",
              city: "",
              state: "",
              zipcode: "",
              phone: "",
              address_type: "shipping",
            },
          ]
          : dataBeneficiary?.shipping_addresses?.map((ele) => {
            delete ele.beneficiary_id;
            delete ele.company_id;
            ele.fax = ele.fax ? ele.fax : "";
            return ele;
          })
      );
      setValue(
        "billing_address",
        dataBeneficiary?.billing_addresses?.length === 0
          ? [
            {
              country: "",
              address: "",
              city: "",
              state: "",
              zipcode: "",
              phone: "",
              address_type: "billing",
            },
          ]
          : dataBeneficiary?.billing_addresses?.map((ele) => {
            delete ele.beneficiary_id;
            delete ele.company_id;
            ele.fax = ele.fax ? ele.fax : "";
            return ele;
          })
      );
      setValue("shippingAddressSame", dataBeneficiary?.same_as_billing_address);
      setValue("tax_settings", dataBeneficiary?.tax_settings);
      setValue("pan_number", dataBeneficiary?.pan_number);
      setValue("gstin", dataBeneficiary?.gstin);
      setValue("gst_treatment", dataBeneficiary?.gst_treatment);
      setValue("configurations", dataBeneficiary?.configurations);
    }
  }, [fetchCustomersById]);

  useEffect(() => {
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "beneficiary_type",
      })
    );
    if (create_ben?.data?.error) {
      if (
        create_ben?.data?.data &&
        Object.keys(create_ben?.data?.data)?.length
      ) {
        message.error(
          <span className="messageText">
            {create_ben?.data?.data[0]?.message}
          </span>
        );
      } else {
        message.error(
          <span className="messageText">{create_ben?.data?.message}</span>
        );
      }
      dispatch({
        type: CreateCustomer.RESET,
      });
    }

    if (create_ben?.data?.error === false) {
      if (dataBeneficiary?.id) {
        dispatch(
          view_customer({
            beneficiary_id: dataBeneficiary.id,
          })
        );
        message.success(
          <span className="messageText">{t("customer_update")}</span>
        );
      } else
        message.success(
          <span className="messageText">{t("customer_create")}</span>
        );
      dispatch({
        type: CreateCustomer.RESET,
      });
      dispatch(
        customers({
          company_id: localStorage.getItem("company_id"),
          type: "customer",
          ...filters,
        })
      );
      onClose();
    }
  }, [create_ben]);

  const handleClose = () => {
    onClose();
    reset();
    dispatch({
      type: VendorsById.RESET,
    });
  };

  const validateEmail = (value) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      return "Enter a valid email address";
    }
    return undefined;
  };

  return (
    <>
      <Modal
        // closable={false}
        onCancel={handleClose}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">
                {dataBeneficiary?.id ? t("edit") : t("new")} {t("cust")}
              </span>
              <span
                style={{
                  marginLeft: "18px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#818181",
                  fontWeight: "medium",
                }}
              >
                {t("enter_details")}
              </span>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Button
                className="pp-icon-button"
                shape="circle"
                icon={<CloseCircleOutlined style={{ fontSize: '33px' }} />}
                onClick={onClose}
              />
            </div> */}
          </div>
        }
        // placement="right"
        visible={open}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="p1">
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    required={true}
                    {...register("beneficiary_name", {
                      required: t("customer_name_required"),
                      validate: (v) => {
                        if (/^[A-Za-z. ]+$/i.test(v)) {
                          return null;
                        } else {
                          return t("customer_space");
                        }
                      },
                    })}
                    errors={errors}
                    label={t("customer_name")}
                    placeholder={t("enter_customer_name")}
                  />
                </div>

                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("company_name", {
                      required: t("company_required"),
                      validate: (v) => {
                        if (/^[A-Za-z0-9_ ]+$/i.test(v)) {
                          return null;
                        } else {
                          return t("enter_valid_company");
                        }
                      },
                    })}
                    errors={errors}
                    required={true}
                    label={t("company_name")}
                    placeholder={t("enter_company_name")}
                  />
                </div>

                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("email", {
                      validate: validateEmail,
                      required: t("email_required"),
                    })}
                    errors={errors}
                    required={true}
                    label={t("emailAddr")}
                    placeholder={t("enter_email")}
                    disabled={isCustomerPortal}
                  />
                </div>

                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("primary_contact", {
                      required: t("phone_Required"),
                    })}
                    errors={errors}
                    number={true}
                    required={true}
                    label={t("phone")}
                    placeholder={t("enter_phone")}
                  />
                </div>
                {dataBeneficiary?.id ? null : (
                  <div className="pp-form-item justify-between">
                    <div className="enable-portal">
                      <span>Enable Portal?</span>
                    </div>
                    <div className="enable-portal-label">
                      <Checkbox
                        style={{ float: "left" }}
                        checked={watch("portalAccess")}
                        name={"portalAccess"}
                        onChange={(e) => {
                          setValue("portalAccess", e?.target?.checked);
                        }}
                      ></Checkbox>
                      <label>Allow portal access for this customer</label>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="additional-div">
              <div className="bg">
                <span>{t("additional_info")} (Optional)</span>
              </div>

              <div className="collapse-div">
                <Collapse
                  defaultActiveKey={["otherInformation"]}
                  onChange={onChange}
                  className="pp-collapse"
                  expandIconPosition="end"
                  expandIcon={() => <CaretDownOutlined />}
                >
                  <Panel
                    header={
                      <>
                        <div className="pp-collapse-header">
                          {t("other_information")}
                        </div>
                      </>
                    }
                    key="otherInformation"
                  >
                    <AddCustomerOtherInformation
                      control={control}
                      register={register}
                      errors={errors}
                      setError={setError}
                      watch={watch}
                      id={
                        dataBeneficiary?.configurations?.currency ? true : false
                      }
                      isCustomerPortal={isCustomerPortal}
                    />
                  </Panel>

                  <Panel
                    header={
                      <>
                        <div className="pp-collapse-header">{t("address")}</div>
                      </>
                    }
                    key="address"
                  >
                    <AddCustomerAddress
                      control={control}
                      register={register}
                      watch={watch}
                      setValue={setValue}
                      errors={errors}
                      billing_address={getValues().billing_address}
                      shipping_address={getValues().shipping_address}
                    />
                  </Panel>

                  <Panel
                    header={
                      <>
                        <div className="pp-collapse-header">
                          {t("contact_person")}
                        </div>
                      </>
                    }
                    key="contact"
                  >
                    <AddCustomerContactPerson
                      control={control}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      watch={watch}
                      contact_persons={getValues().contact_persons}
                      clearErrors={clearErrors}
                      setError={setError}
                      submitCount={submitCount}
                    />
                  </Panel>
                  <Panel
                    header={
                      <>
                        <div className="pp-collapse-header">
                          {t("payment_details")}
                        </div>
                      </>
                    }
                    key="paymentDetails"
                  >
                    <AddCustomerPaymentDetails
                      control={control}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      bank_account_details={getValues().bank_account_details}
                    />
                  </Panel>
                </Collapse>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "6rem 0px",
                  paddingLeft: "54px",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="pp-main-button "
                  id="save"
                  disabled={create_ben?.loading}
                >
                  <span>{dataBeneficiary?.id ? t("update") : t("save")}</span>
                </Button>
                <Button
                  className="pp-secondary-button"
                  style={{ marginLeft: "2rem" }}
                  onClick={handleClose}
                >
                  <span>{t("cancel")}</span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddCustomer;
