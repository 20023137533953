import { MessageOutlined, MoreOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Col, Menu, Row, Space } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { headerTitles } from '../../../actions/masterdata/masterdata';
import TableComponent from '../../Common/TableComp/TableComp';
import PageHeader from '../../Sales/PageHeader';
import TableHeader from '../../Sales/TableHeader';
import CreatePayment from './CreatePayment';
import { useTranslation } from 'react-i18next';

const CompanyTransfers = ({ hasWriteAccess }) => {
  const [openAddNew, setOpenAddNew] = useState(false);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.auth.localVariables?.language);

  const { t } = useTranslation();

  const sortOptions = [
    {
      label: t('created_history'),
    },
    {
      label: t('creation_date'),
    },
    {
      label: t('po_number'),
    },
    {
      label: t('vendor_name'),
    },
    {
      label: t('Amount'),
    },
    {
      label: t('expected_delivery_date'),
    },
    {
      label: t('import_purchase_orders'),
    },
    {
      label: t('export_purchase_orders'),
    },
  ];

  const columns = [
    {
      title: t('employee_name'),
      dataIndex: 'emp_name',
      key: 'emp_name',
    },
    {
      title: t('from_account'),
      dataIndex: 'from_account',
      key: 'from_account',
    },
    {
      title: t('to_account'),
      dataIndex: 'to_account',
      key: 'to_account',
    },
    {
      title: t('dateAndTime'),
      dataIndex: 'date',
      key: 'date',
      // render: (text) => <div>{formatDate(text)}</div>,
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      // render: (text) => <div className="table-due-date">{formatDate(text)}</div>,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
    },

    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="default" icon={<WarningOutlined />} style={{ border: 'none', color: '#E24C4B' }} />
          <Button shape="default" icon={<MessageOutlined />} style={{ border: 'none', color: '#5B87F9' }} />
          <Button shape="default" icon={<MoreOutlined />} style={{ border: 'none', color: '#5B87F9' }} />
        </Space>
      ),
    },
  ];

  useEffect(() => {

    dispatch(headerTitles({
      title: 'company_accounts',
      description: 'accounts_desc'
    }))
  }, [language])

  const sort_opts = (
    <Menu onClick={() => { }}>
      {sortOptions.map((ele, idx) => <Menu.Item key={idx}>
        {ele.label}
      </Menu.Item>)}
    </Menu>
  );
  return (
    <Row wrap={false} className="table-wrapper">
      <Col flex="auto" className="table-wrapper-col">
        <div className='flex-grow'>
          <TableHeader noDD={true} noFilter={true} setOpenAddNew={setOpenAddNew} buttonLabel={t('payment_create')} sortOptions={sort_opts} hasWriteAccess={hasWriteAccess} />
          <div style={{ paddingTop: '22px' }}>
            <TableComponent
              columns={columns}
              data={[]}
              // selectedRows={selectedRows}
              // setSelectedRows={setSelectedRows}
              rowKey="bill_no"
            />
          </div>
         {openAddNew && 
          <CreatePayment source={"PAYMENT"} open={openAddNew && hasWriteAccess} onClose={() => setOpenAddNew(false)} isCompany={true} />
         }
        </div>
      </Col>
    </Row>
  );
};

export default CompanyTransfers;