import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import ub_1 from "../../assets/images/payroll.png";

const Loans = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Loans",
        description: "",
      })
    );
  }, []);

  return (
    <div className='utility-bills payroll'>
      <div className='d-flex'>
        <div className='w35'>
          <h4>Coming Soon...</h4>
          <p></p>
          <button className='pp-main-button'>
            <span>Access Loan</span>
          </button>
          <div className='line'></div>
          <label>Loan Preferences & Settings</label>
          <span className='link'>Apply Loans</span>
          <span className='link'>Loan Statements</span>
        </div>
        {/* <img src={ub_1} alt="" className='w65' /> */}
      </div>
    </div>
  );
};

export default Loans;
